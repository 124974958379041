<!--<button mat-icon-button type="button" class="info-btn" (click)="modalService.createsamplerclose()">-->
<!--  <mat-icon class="material-symbols-outlined">info</mat-icon>-->
<!--</button>-->
<p-button class="info-btn" icon="pi pi-info" [rounded]="true" [outlined]="true" (click)="onInfoButtonClick()"/>
<button mat-icon-button type="button" class="close-btn" (click)="modalService.createsamplesaleclose()">
  <mat-icon>close</mat-icon>
</button>
<p class="sample-manager-text">{{ formcaption }}</p>
<div class="scrollable-div">
  <div class="black-background">
    <nz-tabset>
      <nz-tab nzTitle="Basic information">
        <div class="full-width-div">
          <!--      1Line-->
          <div *ngIf="selectCheckInventoryStatus" class="componentdiv">
            <label class="line1-edits-new inputlabel">{{ captionInventoryStatus }}</label>
            <nz-input-group [nzStatus]="statusinventory" nzSearch [nzAddOnAfter]="suffixIconSearch1">
              <nz-select (focus)="onBlurAll()" nzShowSearch [nzShowArrow]="true"
                class="line1-edits-new inputedit testdiv"
                [(ngModel)]="inventory">
                <nz-option *ngFor="let option of inventorystatus" [nzLabel]="option.viewValue"
                  [nzValue]="option.viewValue"></nz-option>
              </nz-select>
            </nz-input-group>
            <ng-template #suffixIconSearch1>
            </ng-template>
          </div>

          <div *ngIf="selectCheckGender" class="componentdiv">
            <label class="line1-edits-new inputlabel">{{ captionGender }}</label>
            <nz-input-group nzSearch [nzAddOnAfter]="suffixIconSearch1">
              <nz-select nzShowSearch nzAllowClear [nzShowArrow]="true" class="line1-edits-new inputedit testdiv"
                [(ngModel)]="genderstring">
                <nz-option *ngFor="let option of gender" [nzLabel]="option.viewValue"
                  [nzValue]="option.viewValue"></nz-option>
              </nz-select>
            </nz-input-group>
            <ng-template #suffixIconSearch1>
            </ng-template>
          </div>

          <div *ngIf="selectCheckSampleWorkflowStatus" class="componentdiv">
            <label class="line1-edits-new inputlabel">{{ captionSampleWorkflowStatus }}</label>
            <nz-input-group nzSearch [nzAddOnAfter]="swsbutton">
              <nz-select nzShowSearch nzAllowClear [nzShowArrow]="true" class="line1-edits-new inputedit testdiv1"
                [(ngModel)]="swsstring">
                <nz-option *ngFor="let option of iSWSResult[0]?.data" [nzLabel]="option.description"
                  [nzValue]="option.description"></nz-option>
              </nz-select>
            </nz-input-group>
            <ng-template #swsbutton>
              <button (click)="add($event, 0, null)" nz-button nzType="default" nzSearch><span nz-icon
                nzType="plus"></span></button>
            </ng-template>
          </div>

          <div *ngIf="selectCheckDepartment" class="componentdiv">
            <label class="line1-edits-new inputlabel">{{ captionDepartment }}</label>
            <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton1">
              <input disabled="true" class="line1-edits-new inputedit testdiv" type="text"
                [(ngModel)]="departmentstring"
                nz-input/>
            </nz-input-group>
            <ng-template #suffixIconButton1>
            </ng-template>
          </div>

          <div *ngIf="selectCheckRegion" class="componentdiv">
            <label class="line1-edits-new inputlabel">{{ captionRegion }}</label>
            <nz-input-group nzSearch [nzAddOnAfter]="regionbutton">
              <nz-select nzShowSearch nzAllowClear [nzShowArrow]="true" class="line1-edits-new inputedit testdiv1"
                [(ngModel)]="regionstring">
                <nz-option *ngFor="let option of iRegionResult[0]?.data" [nzLabel]="option.description"
                  [nzValue]="option.description"></nz-option>
              </nz-select>
            </nz-input-group>
            <ng-template #regionbutton>
              <button (click)="add($event, 1, null)" nz-button nzType="default" nzSearch><span nz-icon
                nzType="plus"></span></button>
            </ng-template>
          </div>
          <div *ngIf="selectCheckNotes" class="componentdiv">
            <label class="line1-edits-new inputlabel">{{ captionNotes }}</label>
            <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton1">
              <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="notesstring" nz-input/>
            </nz-input-group>
            <ng-template #suffixIconButton1>
            </ng-template>
          </div>

          <div *ngIf="selectCheckDesigner" class="componentdiv">
            <label class="line1-edits-new inputlabel">{{ captionDesigner }}</label>
            <nz-input-group [nzStatus]="statusdesigner" nzSearch [nzAddOnAfter]="suffixIconSearch1">
              <nz-select (focus)="onBlurAll()" nzShowSearch [nzShowArrow]="true"
                class="line1-edits-new inputedit testdiv"
                [(ngModel)]="designerstring">
                <nz-option *ngFor="let option of iDesignerResult[0]?.data" [nzLabel]="option.description"
                  [nzValue]="option.description"></nz-option>
              </nz-select>
            </nz-input-group>
            <ng-template #suffixIconSearch1>
            </ng-template>
          </div>

          <div *ngIf="selectCheckFabric" class="componentdiv">
            <label class="line1-edits-new inputlabel">{{ captionFabric }}</label>
            <nz-input-group nzSearch [nzAddOnAfter]="fabricbutton">
              <nz-select nzShowSearch nzAllowClear [nzShowArrow]="true" class="testdiv1"
                [(ngModel)]="fabricstring">
                <nz-option *ngFor="let option of iFabricResult[0]?.data" [nzLabel]="option.description"
                  [nzValue]="option.description"></nz-option>
              </nz-select>
            </nz-input-group>
            <ng-template #fabricbutton>
              <button (click)="add($event, 3, null)" nz-button nzType="default" nzSearch><span nz-icon
                nzType="plus"></span></button>
            </ng-template>
          </div>

          <div *ngIf="selectCheckManufacturer" class="componentdiv">
            <label class="line1-edits-new inputlabel">{{ captionManufacturer }}</label>
            <nz-input-group nzSearch [nzAddOnAfter]="manubutton">
              <nz-select nzShowSearch nzAllowClear [nzShowArrow]="true" class="testdiv1"
                [(ngModel)]="manustring">
                <nz-option *ngFor="let option of iManuResult[0]?.data" [nzLabel]="option.description"
                  [nzValue]="option.description"></nz-option>
              </nz-select>
            </nz-input-group>
            <ng-template #manubutton>
              <button (click)="add($event, 4, null)" nz-button nzType="default" nzSearch><span nz-icon
                nzType="plus"></span></button>
            </ng-template>
          </div>

          <div *ngIf="selectCheckCollection" class="componentdiv">
            <label class="line1-edits-new inputlabel">{{ captionCollection }}</label>
            <nz-input-group [nzStatus]="statuscollection" nzSearch [nzAddOnAfter]="collectionbutton">
              <nz-select (focus)="onBlurAll()" nzShowSearch [nzShowArrow]="true"
                class="line1-edits-new inputedit testdiv1"
                [(ngModel)]="collectionstring">
                <nz-option *ngFor="let option of iCollection[0]?.data" [nzLabel]="option.description"
                  [nzValue]="option.description"></nz-option>
              </nz-select>
            </nz-input-group>
            <ng-template #collectionbutton>
              <button (click)="add($event, 5, null)" nz-button nzType="default" nzSearch><span nz-icon
                nzType="plus"></span></button>
            </ng-template>
          </div>

          <div *ngIf="selectCheckStyle" class="componentdiv">
            <label class="line1-edits-new inputlabel">{{ captionStyle }}</label>
            <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton1">
              <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="stylestring" nz-input/>
            </nz-input-group>
            <ng-template #suffixIconButton1>
            </ng-template>
          </div>

          <div *ngIf="selectCheckLooksAssociated" class="componentdiv">
            <label class="line1-edits-new inputlabel">{{ captionLooksAssociated }}</label>
            <nz-input-group nzSearch [nzAddOnAfter]="associatedbutton">
              <input class="line1-edits-new inputedit testdiv1" type="text" [(ngModel)]="associatedstring" nz-input/>
            </nz-input-group>
            <ng-template #associatedbutton>
              <button (click)="add($event, 8, null)" nz-button nzType="default" nzSearch><span nz-icon
                nzType="search"></span></button>
            </ng-template>
          </div>

          <div *ngIf="selectCheckSeason" class="componentdiv">
            <label class="line1-edits-new inputlabel">{{ captionSeason }}</label>
            <nz-input-group [nzStatus]="statusseason" nzSearch [nzAddOnAfter]="suffixIconSearch1">
              <nz-select (focus)="onBlurAll()" nzShowSearch [nzShowArrow]="true"
                class="line1-edits-new inputedit testdiv"
                [(ngModel)]="seasonstring">
                <nz-option *ngFor="let option of iSeason[0]?.data" [nzLabel]="option.description"
                  [nzValue]="option.description"></nz-option>
              </nz-select>
            </nz-input-group>
            <ng-template #suffixIconSearch1>
            </ng-template>
          </div>

          <div *ngIf="selectCheckMisc" class="componentdiv">
            <label class="line1-edits-new inputlabel">{{ captionMisc }}</label>
            <nz-input-group nzSearch [nzAddOnAfter]="miscbutton">
              <nz-select nzShowSearch nzAllowClear [nzShowArrow]="true" class="testdiv1"
                [(ngModel)]="miscstring">
                <nz-option *ngFor="let option of iMisc[0]?.data" [nzLabel]="option.description"
                  [nzValue]="option.description"></nz-option>
              </nz-select>
            </nz-input-group>
            <ng-template #miscbutton>
              <button (click)="add($event, 9, null)" nz-button nzType="default" nzSearch><span nz-icon
                nzType="plus"></span></button>
            </ng-template>
          </div>
          <div *ngIf="selectCheckQuantity" class="componentdiv">
            <label class="line1-edits-new inputlabel">{{ captionQuantity }}</label>
            <nz-input-group nzSearch [nzAddOnAfter]="associatedquantity">
              <nz-input-number class="line1-edits-new inputedit testdiv" [(ngModel)]="quantitystring" [nzMin]="1"
                [nzMax]="100" [nzStep]="1"></nz-input-number>
            </nz-input-group>
            <ng-template #associatedquantity>

            </ng-template>

          </div>

          <div *ngIf="selectCheckType" class="componentdiv">
            <label class="line1-edits-new inputlabel">{{ captionType }}</label>
            <nz-input-group [nzStatus]="statustype" nzSearch [nzAddOnAfter]="typebutton">
              <nz-select (focus)="onBlurAll()" nzShowSearch [nzShowArrow]="true"
                class="line1-edits-new inputedit testdiv1"
                [(ngModel)]="typestring">
                <nz-option *ngFor="let option of iType[0]?.data" [nzLabel]="option.description"
                  [nzValue]="option.description"></nz-option>
              </nz-select>
            </nz-input-group>
            <ng-template #typebutton>
              <button (click)="add($event, 10, null)" nz-button nzType="default" nzSearch><span nz-icon
                nzType="plus"></span></button>
            </ng-template>
          </div>

          <div *ngIf="selectCheckSize" class="componentdiv">
            <label class="line1-edits-new inputlabel">{{ captionSize }}</label>
            <nz-input-group nzSearch [nzAddOnAfter]="sizebutton">
              <nz-select nzShowSearch nzAllowClear [nzShowArrow]="true" class="testdiv1"
                [(ngModel)]="sizestring">
                <nz-option *ngFor="let option of iSize[0]?.data" [nzLabel]="option.description"
                  [nzValue]="option.description"></nz-option>
              </nz-select>
            </nz-input-group>
            <ng-template #sizebutton>
              <button (click)="add($event, 11, null)" nz-button nzType="default" nzSearch><span nz-icon
                nzType="plus"></span></button>
            </ng-template>
          </div>

          <div *ngIf="selectCheckPattern" class="componentdiv">
            <label class="line1-edits-new inputlabel">{{ captionPattern }}</label>
            <nz-input-group nzSearch [nzAddOnAfter]="patternbutton">
              <nz-select nzShowSearch nzAllowClear [nzShowArrow]="true" class="testdiv1"
                [(ngModel)]="patternstring">
                <nz-option *ngFor="let option of iPattern[0]?.data" [nzLabel]="option.description"
                  [nzValue]="option.description"></nz-option>
              </nz-select>
            </nz-input-group>
            <ng-template #patternbutton>
              <button (click)="add($event, 12, null)" nz-button nzType="default" nzSearch><span nz-icon
                nzType="plus"></span></button>
            </ng-template>
          </div>

          <div *ngIf="selectCheckColor" class="componentdiv">
            <label class="line1-edits-new inputlabel">{{ captionColor }}</label>
            <nz-input-group nzSearch [nzAddOnAfter]="suffixIconSearch1">
              <nz-select nzShowSearch nzAllowClear [nzShowArrow]="true" class="line1-edits-new inputedit testdiv"
                [(ngModel)]="colorstring">
                <nz-option nzCustomContent *ngFor="let option of colorarray" [nzLabel]="option.colorname"
                  [nzValue]="option.colorcode">
                  <span class="color-box" [style.background]="option.colorcode"></span>
                  {{ option.colorname }}
                </nz-option>
              </nz-select>
            </nz-input-group>
            <ng-template #suffixIconSearch1>
            </ng-template>
          </div>

          <div *ngIf="selectCheckSizeRange" class="componentdiv">
            <label class="line1-edits-new inputlabel">{{ captionSizeRange }}</label>
            <nz-input-group nzSearch [nzAddOnAfter]="sizerangebutton">
              <nz-select nzShowSearch nzAllowClear [nzShowArrow]="true" class="testdiv1"
                [(ngModel)]="sizerangestring">
                <nz-option *ngFor="let option of iSizeRange[0]?.data" [nzLabel]="option.description"
                  [nzValue]="option.description"></nz-option>
              </nz-select>
            </nz-input-group>
            <ng-template #sizerangebutton>
              <button (click)="add($event, 13, null)" nz-button nzType="default" nzSearch><span nz-icon
                nzType="plus"></span></button>
            </ng-template>
          </div>

          <div *ngIf="selectCheckLookBook" class="componentdiv">
            <label class="line1-edits-new inputlabel">{{ captionLookBook }}</label>
            <nz-input-group nzSearch [nzAddOnAfter]="lookbookbutton">
              <nz-select nzShowSearch nzAllowClear [nzShowArrow]="true" class="testdiv1"
                [(ngModel)]="lookbookstring">
                <nz-option *ngFor="let option of ilbMainResult[0]?.data" [nzLabel]="option.description"
                  [nzValue]="option.description"></nz-option>
              </nz-select>
            </nz-input-group>
            <ng-template #lookbookbutton>
              <button (click)="add($event, 120, null)" nz-button nzType="default" nzSearch><span nz-icon
                nzType="plus"></span></button>
            </ng-template>
          </div>

          <div *ngIf="selectCheckBarcode" class="componentdiv">
            <label class="line1-edits-new inputlabel">Barcode</label>
            <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton1">
              <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="barcodestring" nz-input/>
            </nz-input-group>
            <ng-template #suffixIconButton1>
            </ng-template>
          </div>
        </div>
        <!--        <div class="black-background3-1">-->
        <!--          <div class="example-full-width-description full-width">-->
        <!--            <label class="line1-edits-new inputlabel">Description</label><br>-->
        <!--            <textarea [(ngModel)]="descriptionstring" rows="3" cols="30" class="line1-edits-new inputedit testdiv2 dopinfo"-->
        <!--              nz-input-->
        <!--            ></textarea>-->
        <!--          </div>-->
        <!--        </div>-->
      </nz-tab>
      <nz-tab nzTitle="Descriptions">
        <nz-divider nzText="Photos" nzOrientation="left"></nz-divider>
        <div class="full-width-div2">
          <div class="center-container1">
            <button mat-raised-button type="button" (click)="uploadImageFiles()">
              Upload
              <mat-icon>upload</mat-icon>
            </button>
            <button mat-raised-button type="button" (click)="onButtonAIClick()" [disabled]="AIDisabled">
              Describe items with AI
              <!--                        <mat-icon>neurology</mat-icon>-->
            </button>

            <div class="file-container">
              <mat-progress-spinner *ngIf="showprogressbar"
                class="example-margin"
                [color]="color"
                [mode]="mode"
                [value]="progressvalue"
                style="width: 50px; height: 50px; --mdc-circular-progress-size: 50px; --mdc-circular-progress-active-indicator-width: 100px;">
              </mat-progress-spinner>
              <div cdkDropList cdkDropListOrientation="horizontal" class="example-list"
                (cdkDropListDropped)="drop($event)">
                <div *ngFor="let item of selectedImageFiles; index as i" class="example-box" cdkDrag>
                  <mat-card
                    class="card-item"
                    [class.selected]="i === selectedIndex"
                    [cdkContextMenuTriggerFor]="inner"
                    (contextmenu)="selectdefaultimage = item"
                    (click)="zoomImage(i, item)">
                    <img [src]="transformImageUrl(item.url)" alt="Image"/>
                    <!--                <img [src]="item.url" alt="Image" />-->
                    <button mat-mini-fab type="button" class="close-button" [disabled]="showprogressbar"
                      (click)="deleteImageFile($event, i)">
                      <mat-icon class="custom-icon">close</mat-icon>
                    </button>
                    <img *ngIf="item.sortby === -1" class="checkboxdefault" src="assets/checkbox.png" alt="isdefault"/>
                  </mat-card>
                </div>
              </div>
            </div>
          </div>
        </div>
        <nz-divider nzText="Details" nzOrientation="left"></nz-divider>
        <div class="detaildiv">
          <div class="black-background21">
            <div class="card">
              <p-table
                #dt1
                [value]="[]"
                [paginator]="false"
                [rows]="5"
                [showCurrentPageReport]="false"
                [scrollable]="true"
                [scrollHeight]="'350px'"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [globalFilterFields]="['description']"
                [styleClass]="'p-datatable-striped ' + selectedSize.class.toString()"
                selectionMode="single"
                [tableStyle]="{'min-width': '10rem'}">
                <ng-template pTemplate="caption">
                  <div class="flex">
                    <div class="black-background">
                      <button mat-icon-button (click)="showAddEditFrame(0, null)">
                        <mat-icon>add</mat-icon>
                      </button>
                    </div>
                    <!--                    <span class="p-input-icon-left ml-auto">-->
                    <!--                      <div class="p-inputgroup">-->
                    <!--                        <span class="p-inputgroup-addon">-->
                    <!--                          <i class="pi pi-search" style="color: #000000"></i>-->
                    <!--                        </span>-->
                    <!--                        <input pInputText type="text" (input)="onInputFilter($event)" placeholder="Filter" [(ngModel)]="filtertext"/>-->
                    <!--                        <span class="p-inputgroup-addon custom-hover-red" matRipple (click)="clear(dt1)">-->
                    <!--                          <i class="pi pi-times" style="color: #000000"></i></span>-->
                    <!--                      </div>-->
                    <!--                    </span>-->
                  </div>
                </ng-template>
                <ng-template pTemplate="header">
                  <tr>
                    <th style="width:20%">Description</th>
                    <th style="width:5%">Options</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-product>
                  <tr [pSelectableRow]="product">
                    <td> {{ product.description }}</td>
                    <td>
                      <p-button label="Edit" size="small" [text]="true" severity="success">

                      </p-button>

                      <p-button label="Delete" size="small" [text]="true" severity="danger">
                      </p-button>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>
      </nz-tab>
      <nz-tab nzTitle="Prices">

      </nz-tab>

      <nz-tab nzTitle="Sample History">
        <div class="samplehistorycaption">
          <span class="samplehistorytext">Sample History</span>
        </div>
        <div class="card example-container-so  mat-elevation-z3">
          <p-table
            #dt1
            *ngIf="SelfData?.samplehistory?.[0]?.data"
            [value]="SelfData.samplehistory[0].data"
            [paginator]="false"
            [rows]="10"
            [showCurrentPageReport]="false"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [rowsPerPageOptions]="[10, 20, 50, 100]"
            [styleClass]="'p-datatable-striped'"
            selectionMode="single"
            [tableStyle]="{'min-width': '50rem'}">
            <ng-template pTemplate="header">
              <tr>
                <!--                    -->
                <th style="width:17.6%">Delivery #</th>
                <th style="width:17.6%">Company</th>
                <th style="width:17.6%">Contact</th>
                <th style="width:17.6%">Ship Date</th>
                <!--                <th>Name</th>-->
                <!--                <th>Category</th>-->
                <!--                <th>Quantity</th>-->
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-product>
              <tr [pSelectableRow]="SelfData.samplehistory[0].data">
                <td>
                  <span style="text-decoration: underline; color: #007bff;" (click)="onDeliveriClick(product)">
                    {{ product?.["Delivery"] }}
                  </span>
                </td>
                <td>
                  {{ product?.["Company"] }}
                </td>
                <td>
                  {{ product?.["Contact"] }}
                </td>
                <td>
                  {{ formatDate(product?.["Ship Date"]) }}
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </nz-tab>
    </nz-tabset>

    <!--    <div class="black-background3-2">-->
    <!--      <div class="full-width-div1">-->
    <!--        <div class="example-full-width form-field-spacing1">-->
    <!--          <label class="line1-edits-new inputlabel">{{ captionRetailPriceEUR }}</label>-->
    <!--          <nz-input-group nzSearch [nzAddOnAfter]="associatedquantity">-->
    <!--            <nz-input-number class="line1-edits-new inputedit testdiv" [(ngModel)]="retailPriceEURstring" [nzMin]="0"-->
    <!--              [nzStep]="0.01"></nz-input-number>-->
    <!--          </nz-input-group>-->
    <!--          <ng-template #associatedquantity>-->

    <!--          </ng-template>-->
    <!--        </div>-->

    <!--        <div class="example-full-width form-field-spacing1">-->
    <!--          <label class="line1-edits-new inputlabel">{{ captionRetailPriceUSD }}</label>-->
    <!--          <nz-input-group nzSearch [nzAddOnAfter]="associatedquantity">-->
    <!--            <nz-input-number class="line1-edits-new inputedit testdiv" [(ngModel)]="retailPriceUSDstring" [nzMin]="0"-->
    <!--              [nzStep]="0.01"></nz-input-number>-->
    <!--          </nz-input-group>-->
    <!--          <ng-template #associatedquantity>-->

    <!--          </ng-template>-->
    <!--        </div>-->

    <!--        <div class="example-full-width form-field-spacing1">-->
    <!--          <label class="line1-edits-new inputlabel">{{ captionWholesaleEUR }}</label>-->
    <!--          <nz-input-group nzSearch [nzAddOnAfter]="associatedquantity">-->
    <!--            <nz-input-number class="line1-edits-new inputedit testdiv" [(ngModel)]="wholesaleEURstring" [nzMin]="0"-->
    <!--              [nzStep]="0.01"></nz-input-number>-->
    <!--          </nz-input-group>-->
    <!--          <ng-template #associatedquantity>-->

    <!--          </ng-template>-->
    <!--        </div>-->

    <!--        <div class="example-full-width form-field-spacing1">-->
    <!--          <label class="line1-edits-new inputlabel">{{ captionWholesaleUSD }}</label>-->
    <!--          <nz-input-group nzSearch [nzAddOnAfter]="associatedquantity">-->
    <!--            <nz-input-number class="line1-edits-new inputedit testdiv" [(ngModel)]="wholesaleUSDstring" [nzMin]="0"-->
    <!--              [nzStep]="0.01"></nz-input-number>-->
    <!--          </nz-input-group>-->
    <!--          <ng-template #associatedquantity>-->

    <!--          </ng-template>-->
    <!--        </div>-->

    <!--        <div class="example-full-width form-field-spacing1">-->
    <!--          <label class="line1-edits-new inputlabel">{{ captionSalePriceEUR }}</label>-->
    <!--          <nz-input-group nzSearch [nzAddOnAfter]="associatedquantity">-->
    <!--            <nz-input-number class="line1-edits-new inputedit testdiv" [(ngModel)]="salePriceEURstring" [nzMin]="0"-->
    <!--              [nzStep]="0.01"></nz-input-number>-->
    <!--          </nz-input-group>-->
    <!--          <ng-template #associatedquantity>-->

    <!--          </ng-template>-->
    <!--        </div>-->

    <!--        <div class="example-full-width form-field-spacing1">-->
    <!--          <label class="line1-edits-new inputlabel">{{ captionSalePriceUSD }}</label>-->
    <!--          <nz-input-group nzSearch [nzAddOnAfter]="associatedquantity">-->
    <!--            <nz-input-number class="line1-edits-new inputedit testdiv" [(ngModel)]="salePriceUSDstring" [nzMin]="0"-->
    <!--              [nzStep]="0.01"></nz-input-number>-->
    <!--          </nz-input-group>-->
    <!--          <ng-template #associatedquantity>-->

    <!--          </ng-template>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      &lt;!&ndash;        2 Line&ndash;&gt;-->
    <!--    </div>-->
  </div>

  <!--    AI da atvirtva-->

  <!--  <div class="black-background3">-->

  <!--    -->
  <!--  </div>-->
</div>

<div class="footerdiv">

  <div class="button-container">
    <button
      mat-raised-button
      class="button-settings"
      type="button"
      *ngIf="isVisible"
      (click)="clearallfield()"
    >
      Clear All
    </button>
    <button
      mat-raised-button
      class="button-settings"
      type="button"
      *ngIf="isVisible"
      (click)="submit(3)"
    >
      Create & Add
    </button>
    <button mat-raised-button class="button-settings" type="button" [disabled]="!SelfData.updatebuttonisenabled"
      (click)="submit(2)">
      {{ createbuttoncaption }}
    </button>
  </div>


  <div class="button-container1">
    <!--      <mat-checkbox [(ngModel)]="isChecked" [ngModelOptions]="{standalone: true}" (change)="onCheckboxChange($event)">Global Sample</mat-checkbox>-->
    <button mat-raised-button class="button-settings" type="button" (click)="op.toggle($event)">
      Component Fields
    </button>
  </div>

</div>

<ng-template #inner>
  <div class="example-menu" cdkMenu>
    <button class="example-menu-item" cdkMenuItem (click)="onDefaultImageClick(selectdefaultimage)">Use as Default
    </button>
    <!--    <button class="example-menu-item" cdkMenuItem>Copy</button>-->
    <!--    <button class="example-menu-item" cdkMenuItem>Paste</button>-->
  </div>
</ng-template>

<p-overlayPanel #op>
  <div class="mainop">
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckInventoryStatus">Inventory Status</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckGender">Gender</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckSampleWorkflowStatus">Sample Workflow Status</label>
    </div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckRegion">Region</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckNotes">Notes</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckDesigner" disabled="true">Designer</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckCollection" disabled="true">Collection</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckSeason" disabled="true">Season</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckType" disabled="true">Type</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckFabric">Fabric</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckManufacturer">Manufacturer</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckStyle">Style</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckLooksAssociated">Looks Associated</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckMisc">Misc</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckSize">Size</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckSizeRange">Size Range</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckPattern">Pattern</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckQuantity">Quantity</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckColor">Color</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckBarcode">Barcode</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckDepartment">Department</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckLookBook">Look Book</label></div>
  </div>
  <div class="applypanel">
    <p-button label="Apply" [rounded]="true" severity="success" (onClick)="closeOverlayPanel()"></p-button>
  </div>
</p-overlayPanel>
