<button mat-icon-button type="button" class="close-btn-look" (click)="modalService.createlooklose()">
  <mat-icon>close</mat-icon>
</button>
<p class="sample-manager-text-look">Look</p>

<div class="scrollable-div-look">
  <div class="form-field-spacing-look">
    <label class="line1-edits-new inputlabel">Description</label>
    <nz-input-group [nzStatus]="statusdescription" nzSearch>
      <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="descriptionstring" nz-input/>
    </nz-input-group>

    <!--      <mat-form-field class="selector-full-width-look" appearance="fill">-->
    <!--        <mat-label>Description</mat-label>-->
    <!--        <input matInput [formControl]="descriptionFormControl" required/>-->
    <!--        <mat-error *ngIf="descriptionFormControl.hasError('required')">-->
    <!--          Description is <strong>required</strong>-->
    <!--        </mat-error>-->
    <!--      </mat-form-field>-->
  </div>

  <div class="black-background-look">
    <div class="full-width-caption">
      <p class="caption-text">Filter Parameters</p>

      <div class="full-width-div-look">

        <div class="example-full-width form-field-spacing">
          <label class="line1-edits-new inputlabel">Designer</label>
          <nz-input-group nzSearch>
            <nz-select [nzAllowClear]="true" nzShowSearch class="line1-edits-new inputedit testdiv"
              [(ngModel)]="designerstring">
              <nz-option *ngFor="let option of iDesignerResult[0]?.data" [nzLabel]="option.description"
                [nzValue]="option.description"></nz-option>
            </nz-select>
          </nz-input-group>

        </div>

        <!--          <mat-form-field class="selector-full-width" appearance="fill">-->
        <!--            <mat-label>Designer</mat-label>-->
        <!--            <input type="text" matInput placeholder="Select or type..." [matAutocomplete]="desinger"-->
        <!--                   [formControl]="desingerFormControl"-->
        <!--                   (input)="onInputChange($event, 'desingerFormControl')"-->
        <!--                   #desingerInput="matAutocompleteTrigger"/>-->
        <!--            <mat-autocomplete #desinger="matAutocomplete" (optionSelected)="onOptionSelected($event, 'desingerFormControl')">-->
        <!--              <mat-option *ngFor="let item of filteredDesignerOptions | async" [value]="item">-->
        <!--                {{ item }}-->
        <!--              </mat-option>-->
        <!--            </mat-autocomplete>-->
        <!--            <button mat-stroked-button *ngIf="showButton['desingerFormControl']" type="button" class="add-button-look"-->
        <!--                    matSuffix (click)="clearValue($event, 'desingerFormControl', desingerInput)">-->
        <!--              <mat-icon class="center-icon-look">close</mat-icon>-->
        <!--            </button>-->
        <!--          </mat-form-field>-->

        <div class="example-full-width form-field-spacing">
          <label class="line1-edits-new inputlabel">Collection</label>
          <nz-input-group nzSearch>
            <nz-select [nzAllowClear]="true" nzShowSearch [nzShowArrow]="true" class="line1-edits-new inputedit testdiv"
              [(ngModel)]="collectionstring">
              <nz-option *ngFor="let option of iCollection[0]?.data" [nzLabel]="option.description"
                [nzValue]="option.description"></nz-option>
            </nz-select>
          </nz-input-group>

        </div>

        <!--        <mat-form-field class="selector-full-width" appearance="fill">-->
        <!--          <mat-label>Collection</mat-label>-->
        <!--          <input type="text" matInput placeholder="Select or type..." [matAutocomplete]="collection"-->
        <!--            [formControl]="collectionFormControl"-->
        <!--            (input)="onInputChange($event, 'collectionFormControl')"-->
        <!--            #collectionInput="matAutocompleteTrigger"/>-->

        <!--          <button mat-stroked-button *ngIf="showButton['collectionFormControl']" type="button" class="add-button-look"-->
        <!--            matSuffix-->
        <!--            (click)="clearValue($event, 'collectionFormControl', collectionInput)">-->
        <!--            <mat-icon class="center-icon-look">close</mat-icon>-->
        <!--          </button>-->
        <!--          <mat-autocomplete #collection="matAutocomplete"-->
        <!--            (optionSelected)="onOptionSelected($event, 'collectionFormControl')">-->
        <!--            <mat-option *ngFor="let item of filteredCollectionOptions | async" [value]="item">-->
        <!--              {{ item }}-->
        <!--            </mat-option>-->
        <!--          </mat-autocomplete>-->

        <!--        </mat-form-field>-->

        <div class="example-full-width form-field-spacing">
          <label class="line1-edits-new inputlabel">Season</label>
          <nz-input-group nzSearch>
            <nz-select [nzAllowClear]="true" nzShowSearch [nzShowArrow]="true" class="line1-edits-new inputedit testdiv"
              [(ngModel)]="seasonstring">
              <nz-option *ngFor="let option of iSeason[0]?.data" [nzLabel]="option.description"
                [nzValue]="option.description"></nz-option>
            </nz-select>
          </nz-input-group>

        </div>

        <!--        <mat-form-field class="selector-full-width" appearance="fill">-->
        <!--          <mat-label>Season</mat-label>-->
        <!--          <input type="text" matInput placeholder="Select or type..." [matAutocomplete]="season"-->
        <!--            [formControl]="seasonFormControl"-->
        <!--            (input)="onInputChange($event, 'seasonFormControl')"-->
        <!--            #seasonInput="matAutocompleteTrigger"/>-->
        <!--          <button mat-stroked-button *ngIf="showButton['seasonFormControl']" type="button" class="add-button-look"-->
        <!--            matSuffix-->
        <!--            (click)="clearValue($event, 'seasonFormControl', seasonInput)">-->
        <!--            <mat-icon class="center-icon-look">close</mat-icon>-->
        <!--          </button>-->
        <!--          <mat-autocomplete #season="matAutocomplete" (optionSelected)="onOptionSelected($event, 'seasonFormControl')">-->
        <!--            <mat-option-->
        <!--              *ngFor="let item of filteredSeasonOptions | async"-->
        <!--              [value]="item">-->
        <!--              {{ item }}-->
        <!--            </mat-option>-->
        <!--          </mat-autocomplete>-->
        <!--        </mat-form-field>-->

        <div class="example-full-width form-field-spacing">
          <label class="line1-edits-new inputlabel">Type</label>
          <nz-input-group nzSearch>
            <nz-select [nzAllowClear]="true" nzShowSearch [nzShowArrow]="true" class="line1-edits-new inputedit testdiv"
              [(ngModel)]="typestring">
              <nz-option *ngFor="let option of iType[0]?.data" [nzLabel]="option.description"
                [nzValue]="option.description"></nz-option>
            </nz-select>
          </nz-input-group>
        </div>

        <!--        <mat-form-field class="selector-full-width" appearance="fill">-->
        <!--          <mat-label>Type</mat-label>-->
        <!--          <input type="text" matInput placeholder="Select or type..." [matAutocomplete]="type"-->
        <!--            [formControl]="typeFormControl"-->
        <!--            (input)="onInputChange($event, 'typeFormControl')"-->
        <!--            #typeInput="matAutocompleteTrigger"/>-->
        <!--          <button mat-stroked-button *ngIf="showButton['typeFormControl']" type="button" class="add-button-look"-->
        <!--            matSuffix-->
        <!--            (click)="clearValue($event, 'typeFormControl', typeInput)">-->
        <!--            <mat-icon class="center-icon-look">close</mat-icon>-->
        <!--          </button>-->
        <!--          <mat-autocomplete #type="matAutocomplete" (optionSelected)="onOptionSelected($event, 'typeFormControl')">-->
        <!--            <mat-option *ngFor="let item of filteredTypeOptions | async" [value]="item">-->
        <!--              {{ item }}-->
        <!--            </mat-option>-->
        <!--          </mat-autocomplete>-->
        <!--        </mat-form-field>-->

      </div>

      <div class="full-width-div1-look">

        <div class="example-full-width form-field-spacing">
          <label class="line1-edits-new inputlabel">Color</label>
          <nz-input-group nzSearch>
            <nz-select nzShowSearch nzAllowClear [nzShowArrow]="true" class="line1-edits-new inputedit testdiv"
              [(ngModel)]="colorstring">
              <nz-option nzCustomContent *ngFor="let option of colorarray" [nzLabel]="option.colorname"
                [nzValue]="option.colorcode">
                <span class="color-box" [style.background]="option.colorcode"></span>
                {{ option.colorname }}
              </nz-option>
            </nz-select>
          </nz-input-group>
        </div>

        <!--        <mat-form-field class="selector-full-width" appearance="fill">-->
        <!--          <mat-label>Color</mat-label>-->
        <!--          <input type="text" matInput placeholder="Select or type..." [matAutocomplete]="color"-->
        <!--            [formControl]="colorControl"-->
        <!--            (input)="onInputChange($event, 'colorControl')"-->
        <!--            #colorInput="matAutocompleteTrigger"/>-->
        <!--          <button mat-stroked-button *ngIf="showButton['colorControl']" type="button" class="add-button-look" matSuffix-->
        <!--            (click)="clearValue($event, 'colorControl', colorInput)">-->
        <!--            <mat-icon class="center-icon-look">close</mat-icon>-->
        <!--          </button>-->
        <!--          <mat-autocomplete #color="matAutocomplete" (optionSelected)="onOptionSelected($event, 'colorControl')">-->
        <!--            <mat-option *ngFor="let color of colorarray" [value]="color.colorname">-->
        <!--              <span class="color-box-look"-->
        <!--                [style.background]="color.colorcode">-->
        <!--              </span>-->
        <!--              {{ color.colorname }}-->
        <!--            </mat-option>-->
        <!--          </mat-autocomplete>-->
        <!--        </mat-form-field>-->

        <div class="example-full-width form-field-spacing">
          <label class="line1-edits-new inputlabel">Size</label>
          <nz-input-group nzSearch>
            <nz-select nzShowSearch nzAllowClear [nzShowArrow]="true" class="testdiv"
              [(ngModel)]="sizestring">
              <nz-option *ngFor="let option of iSize[0]?.data" [nzLabel]="option.description"
                [nzValue]="option.description"></nz-option>
            </nz-select>
          </nz-input-group>

        </div>

<!--        <mat-form-field class="selector-full-width" appearance="fill">-->
<!--          <mat-label>Size</mat-label>-->
<!--          <input type="text" matInput placeholder="Select or type..." [matAutocomplete]="size"-->
<!--            [formControl]="sizeControl"-->
<!--            (input)="onInputChange($event, 'sizeControl')"-->
<!--            #sizeInput="matAutocompleteTrigger"/>-->
<!--          <button mat-stroked-button *ngIf="showButton['sizeControl']" type="button" class="add-button-look" matSuffix-->
<!--            (click)="clearValue($event, 'sizeControl', sizeInput)">-->
<!--            <mat-icon class="center-icon-look">close</mat-icon>-->
<!--          </button>-->
<!--          <mat-autocomplete #size="matAutocomplete" (optionSelected)="onOptionSelected($event, 'sizeControl')">-->
<!--            <mat-option-->
<!--              *ngFor="let item of filteredSizeOptions | async" [value]="item">-->
<!--              {{ item }}-->
<!--            </mat-option>-->
<!--          </mat-autocomplete>-->
<!--        </mat-form-field>-->

        <div class="example-full-width form-field-spacing">
          <label class="line1-edits-new inputlabel">Fabric</label>
          <nz-input-group nzSearch>
            <nz-select nzShowSearch nzAllowClear [nzShowArrow]="true" class="testdiv"
              [(ngModel)]="fabricstring">
              <nz-option *ngFor="let option of iFabricResult[0]?.data" [nzLabel]="option.description"
                [nzValue]="option.description"></nz-option>
            </nz-select>
          </nz-input-group>
        </div>

<!--        <mat-form-field class="selector-full-width" appearance="fill">-->
<!--          <mat-label>Fabric</mat-label>-->
<!--          <input type="text" matInput placeholder="Select or type..." [matAutocomplete]="fabric"-->
<!--            [formControl]="fabricControl"-->
<!--            (input)="onInputChange($event, 'fabricControl')"-->
<!--            #fabricInput="matAutocompleteTrigger"/>-->
<!--          <button mat-stroked-button *ngIf="showButton['fabricControl']" type="button" class="add-button-look" matSuffix-->
<!--            (click)="clearValue($event, 'fabricControl', fabricInput)">-->
<!--            <mat-icon class="center-icon-look">close</mat-icon>-->
<!--          </button>-->
<!--          <mat-autocomplete #fabric="matAutocomplete" (optionSelected)="onOptionSelected($event, 'fabricControl')">-->
<!--            <mat-option *ngFor="let item of filteredFabricOptions | async" [value]="item">-->
<!--              {{ item }}-->
<!--            </mat-option>-->
<!--          </mat-autocomplete>-->
<!--        </mat-form-field>-->

        <div class="example-full-width form-field-spacing">
          <label class="line1-edits-new inputlabel">Gender</label>
          <nz-input-group nzSearch [nzAddOnAfter]="suffixIconSearch1">
            <nz-select nzShowSearch nzAllowClear [nzShowArrow]="true" class="line1-edits-new inputedit testdiv"
              [(ngModel)]="genderstring">
              <nz-option *ngFor="let option of gender" [nzLabel]="option.viewValue"
                [nzValue]="option.viewValue"></nz-option>
            </nz-select>
          </nz-input-group>
          <ng-template #suffixIconSearch1>
          </ng-template>
        </div>

<!--        <mat-form-field class="selector-full-width" appearance="fill">-->
<!--          <mat-label>Gender</mat-label>-->
<!--          <input type="text" matInput placeholder="Select or type..." [matAutocomplete]="gender1"-->
<!--            [formControl]="genderControl"-->
<!--            (input)="onInputChange($event, 'genderControl')"-->
<!--            #genderInput="matAutocompleteTrigger"/>-->
<!--          <button mat-stroked-button *ngIf="showButton['genderControl']" type="button" class="add-button-look" matSuffix-->
<!--            (click)="clearValue($event, 'genderControl', genderInput)">-->
<!--            <mat-icon class="center-icon-look">close</mat-icon>-->
<!--          </button>-->
<!--          <mat-autocomplete #gender1="matAutocomplete" (optionSelected)="onOptionSelected($event, 'genderControl')">-->
<!--            <mat-option *ngFor="let gender of gender" [value]="gender.viewValue">-->
<!--              {{ gender.viewValue }}-->
<!--            </mat-option>-->
<!--          </mat-autocomplete>-->
<!--        </mat-form-field>-->

      </div>

    </div>
    <button mat-raised-button type="button" class="view-more-btn1-look" (click)="onFilterButtonClick()">
      <mat-icon class="icon-spacing icon-scale">search</mat-icon>
      Search
    </button>
  </div>

  <div class="black-background1-look" cdkDropListGroup>

    <div class="child-40-look full-width-caption-look">
      <p class="caption-text-look">Samples List ({{ iSampleDataResult[0].data.length }})</p>
      <div class="subdiv-look">

        <div cdkDropList [cdkDropListData]="iSampleDataResult[0].data" class="example-list"
          (cdkDropListDropped)="drop($event)">
          <div class="example-box" *ngFor="let sample of iSampleDataResult[0].data" cdkDrag
            (dblclick)=ondbleclick(sample)>
            <div class="designer-and-image">
              <ng-container *ngIf="transformImageUrl(sample?.['Image Link'])" >
                <img [src]="transformImageUrl(sample?.['Image Link'])"  alt="image description" class="image-size-80x80-look">
              </ng-container>
              <div class="designer-details">
                <span class="designer-name">{{ sample?.['Collection'] }}</span>
                <span class="designer-name">{{ sample?.['Designer'] }}</span>
                <span class="designer-name">{{ sample?.['Season'] }}</span>
                <span class="color-box-look" [style.background]="sample?.['colorcode']"></span>
              </div>
            </div>
            <img *cdkDragPreview [src]="transformImageUrl(sample?.['Image Link'])"  [alt]="sample?.['Gender']">
          </div>
        </div>

      </div>
    </div>

    <div class="child-60-look full-width-caption-look">
      <p class="caption-text-look">Selected Samples ({{ iSampleSelectedResult[0].data.length }})</p>
      <div class="subdiv-look">

        <div *ngIf="iSampleSelectedResult && iSampleSelectedResult.length > 0" cdkDropList
          [cdkDropListData]="iSampleSelectedResult[0].data" class="example-list" (cdkDropListDropped)="drop($event)">
          <div class="example-box" *ngFor="let sample of iSampleSelectedResult[0].data" cdkDrag>
            <div class="designer-and-image">
              <ng-container *ngIf="transformImageUrl(sample?.['Image Link'])" >
                <img [src]="transformImageUrl(sample?.['Image Link'])" alt="image description" class="image-size-80x80-look">
              </ng-container>
              <div class="designer-details">
                <span class="designer-name">{{ sample?.['Collection'] }}</span>
                <span class="designer-name">{{ sample?.['Designer'] }}</span>
                <span class="designer-name">{{ sample?.['Season'] }}</span>
                <span class="color-box-look" [style.background]="sample?.['colorcode']"></span>
              </div>
            </div>
            <img *cdkDragPreview [src]="transformImageUrl(sample?.['Image Link'])"  [alt]="sample?.['Gender']">
          </div>
        </div>

      </div>
    </div>

  </div>

</div>
<div class="button-container-look">
  <!--        <button mat-raised-button class="button-settings" type="submit" *ngIf="isVisible">Create & Add</button>-->
  <button mat-raised-button class="button-settings-look" type="button" (click)="submit()">
    {{ createbuttoncaption }}
  </button>
</div>


<p-confirmDialog #cd [style]="{width: '25vw'}" [closable]="true">
  <ng-template pTemplate="footer">
    <div class="button-container-rr">
      <button mat-raised-button class="button-secondary" style="margin-right: 10px; width: 10%"
        (click)="cd.reject()">
        No
      </button>
      <button mat-raised-button class="button-primary" style="width: 10%" (click)="cd.accept()">
        Yes
      </button>
    </div>
  </ng-template>
</p-confirmDialog>
