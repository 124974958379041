import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder} from "@angular/forms";
import {HashService} from "../../services/hash.service";
import {MessageboxComponent} from "../../modals/messagebox/messagebox.component";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ConfirmationService, ConfirmEventType, MessageService} from "primeng/api";
import {NgxImageCompressService} from "ngx-image-compress";
import { HttpClient } from "@angular/common/http";
import {GetlbCatalogs, GetlbCatalogsData, LbCatalogData, LbCatalogDetails, SelfData} from "../../models/struct";
import {Table} from "primeng/table";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-createvasiadialog',
  templateUrl: './createvasiadialog.component.html',
  styleUrls: ['./createvasiadialog.component.scss']
})
export class CreatevasiadialogComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('dt1') dt1!: Table;
  filtertext: string = ""
  ilbResult: GetlbCatalogs[] = [];
  datasource: GetlbCatalogsData[] = [];
  currancecatalog: GetlbCatalogsData[] = [];
  showdialog: boolean = false

  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
      ? SelfData.account[0].data[0].id
      : SelfData.account[0].data[0].parentid;
  }

  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private serviceservice: ServiceService,
    private yourappsService: YourappsserviceService,
    private _snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef,
    private messageService: MessageService,
    private imageCompressService: NgxImageCompressService,
    private confirmationService: ConfirmationService,
    private http: HttpClient,
    private el: ElementRef
  ) {
    this.serviceservice
      .selectcatalogs({accountid: this.getAccountID()})
      .subscribe((value) => {
        this.ilbResult[0] = value;
        this.SelfData.lb = this.ilbResult;
        this.datasource = this.SelfData.lb[0].data
      });
  }

  onButtonCloseClick() {
    this.modalService.closeVasia()
    // this.modalService.createsendoutclose()
  }

  onInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;
    this.dt1.filterGlobal(value, 'contains');
  }

  clear(table: Table) {
    table.clear();
    this.filtertext = ""
  }

  private subscription?: Subscription;
  private subscription1?: Subscription;
  onButtonAddClick() {
    this.showdialog = false
    this.subscription = this.yourappsService.reloadlblist.subscribe((value) => {
      if (this.subscription) {
        this.subscription.unsubscribe();
        this.serviceservice
          .selectcatalogs({accountid: this.getAccountID()})
          .subscribe((value) => {
            this.ilbResult[0] = value;
            this.SelfData.lb = this.ilbResult;
            this.datasource = this.SelfData.lb[0].data
          });
      }
    });
    this.yourappsService.setLCItem(null, 0)
    this.modalService.openVasiaFrame()
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.showdialog = true
  }

  onButtonEditClick(item: any) {
    this.showdialog = false
    this.subscription = this.yourappsService.reloadlblist.subscribe((value) => {
      if (this.subscription) {
        this.subscription.unsubscribe();
        this.serviceservice
          .selectcatalogs({accountid: this.getAccountID()})
          .subscribe((value) => {
            this.ilbResult[0] = value;
            this.SelfData.lb = this.ilbResult;
            this.datasource = this.SelfData.lb[0].data
          });
      }
    });

    // this.subscription1 = this.yourappsService.lcitem$.subscribe((value) => {
    //   if (this.subscription1) {
    //
    //   }
    // });
    this.yourappsService.setLCItem(item, 1)
    this.modalService.openVasiaFrame()
  }

  onDeleteButtonClick(item: any) {
    this.showdialog = true
    this.cdr.detectChanges()
    this.confirmationService.confirm({
      message: `<strong>Are you sure you want to delete this catalog?</strong>`,
      header: 'Delete Catalog',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        const catalogDetails: LbCatalogDetails = {
          id: item.id, // Replace with actual id
          description: "",
          accountid: this.getAccountID(),
          designerid:  0,
          seasonid:  0,
          isactivity: false,
          ctrl: 2
        };
        this.serviceservice.lbcatalogs(catalogDetails).subscribe({
          next: (value: LbCatalogData) => {
            this.serviceservice
              .selectcatalogs({accountid: this.getAccountID()})
              .subscribe((value) => {
                this.ilbResult[0] = value;
                this.SelfData.lb = this.ilbResult;
                this.datasource = this.SelfData.lb[0].data
              });
          },
          error: (error: any) => {
            console.error('Error occurred:', error);
          },
          complete: () => {
            console.log('Subscription completed');
          }
        });
      },
      reject: (type: any) => {
        if (type == ConfirmEventType.REJECT) {

        } else if (type == ConfirmEventType.CANCEL) {
        }
      }

    });
    setTimeout(() => {
      this.applyLocalStyles();
    }, 0);
  }

  private applyLocalStyles() {
    setTimeout(() => {
      const messageElements = document.querySelectorAll('.p-confirm-dialog-message');
      messageElements.forEach((element) => {
        element.setAttribute('style', 'font-size: 16px; color: red;');
      });
    }, 0);

    const iconElements = document.querySelectorAll('.p-confirm-dialog-icon');
    iconElements.forEach((element) => {
      element.setAttribute('style', 'color: red');
    });
  }

  onButtonMenu1Click(item: any) {
    this.showdialog = false
    this.subscription = this.yourappsService.reloadlblist.subscribe((value) => {
      if (this.subscription) {
        this.subscription.unsubscribe();
        this.serviceservice
          .selectcatalogs({accountid: this.getAccountID()})
          .subscribe((value) => {
            this.ilbResult[0] = value;
            this.SelfData.lb = this.ilbResult;
            this.datasource = this.SelfData.lb[0].data
          });
      }
    });
    this.yourappsService.setLCItem(item, 2)
    this.modalService.openVasiaFrame()
  }

  onButtonMenu2Click(item: any) {
    this.showdialog = false
    this.subscription = this.yourappsService.reloadlblist.subscribe((value) => {
      if (this.subscription) {
        this.subscription.unsubscribe();
        this.serviceservice
          .selectcatalogs({accountid: this.getAccountID()})
          .subscribe((value) => {
            this.ilbResult[0] = value;
            this.SelfData.lb = this.ilbResult;
            this.datasource = this.SelfData.lb[0].data
          });
      }
    });
    this.yourappsService.setLCItem(item, 3)
    this.modalService.openVasiaFrame()
  }

  onButtonMenu3Click(item: any) {
    this.showdialog = false
    this.subscription = this.yourappsService.reloadlblist.subscribe((value) => {
      if (this.subscription) {
        this.subscription.unsubscribe();
        this.serviceservice
          .selectcatalogs({accountid: this.getAccountID()})
          .subscribe((value) => {
            this.ilbResult[0] = value;
            this.SelfData.lb = this.ilbResult;
            this.datasource = this.SelfData.lb[0].data
          });
      }
    });
    this.yourappsService.setLCItem(item, 4)
    this.modalService.openVasiaFrame()
  }

  calculateCustomerTotal(name: string) {
    let total = 0;

    if (this.datasource) {
      for (let customer of this.datasource) {
        if (customer.designer === name) {
          total++;
        }
      }
    }

    return total;
  }

  protected readonly SelfData = SelfData;

  onInfoButtonClick() {
    window.open('https://fm.welcomeapps.net/FAQ/CatOfItems.html', '_blank');
  }
}

