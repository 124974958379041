import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import * as moment from "moment-timezone";
import {SelfData, SetSampleListData, TagsListData, transformImageUrl} from "../../models/struct";
import {Table} from "primeng/table";
import {ModalserviceService} from "../../services/modalservice.service";
import {forkJoin, Subscription} from "rxjs";
import {FormBuilder} from "@angular/forms";
import {HashService} from "../../services/hash.service";
import {MessageboxComponent} from "../../modals/messagebox/messagebox.component";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-outlets',
  templateUrl: './outlets.component.html',
  styleUrl: './outlets.component.scss'
})
export class OutletsComponent implements OnInit, OnDestroy {
  sizes!: any[];
  @ViewChild(Table) dataTable?: Table;
  iOutletsResult: TagsListData[] = []
  selectedSize: any = '';
  constructor(public modalService: ModalserviceService,
              public fb: FormBuilder,
              private hashService: HashService,
              public messageBox: MessageboxComponent,
              private serviceservice: ServiceService,
              private yourappsService: YourappsserviceService,
              private _snackBar: MatSnackBar,
              private http: HttpClient,
              private cdr: ChangeDetectorRef) {
    this.sizes = [
      {name: 'Small', class: 'p-datatable-sm'}
    ];
    this.selectedSize = this.sizes[0]
    setTimeout(() => {
      SelfData.spinnerpossition = true
    });

    forkJoin({
      outlets: this.serviceservice.selectOutlets({accountid: this.getAccountID()}),
    }).subscribe(({ outlets }) => {
      this.iOutletsResult = outlets.data;
      console.log("this.iOutletsResult", this.iOutletsResult)
      setTimeout(() => {
        SelfData.spinnerpossition = false
      });
    });
  }

  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
      ? SelfData.account[0].data[0].id
      : SelfData.account[0].data[0].parentid;
  }

  ngOnInit(): void {
  }

  protected readonly transformImageUrl = transformImageUrl;
  protected readonly SelfData = SelfData;
  currentSample: SetSampleListData | null = null;

  private subscription?: Subscription;

  onOutletAddClick() {
    this.yourappsService.setSelectedOutLetList([], 0)
    this.yourappsService.getReloadOutLetList([], 0)
    this.subscription = this.yourappsService.reloadOutLetList$.subscribe((value) => {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      if (value.type === 1) {
        forkJoin({
          outlets: this.serviceservice.selectOutlets({accountid: this.getAccountID()}),
        }).subscribe(({ outlets }) => {
          this.iOutletsResult = outlets.data;
          console.log("this.iOutletsResult", this.iOutletsResult)
        });
      }
    })
    this.modalService.orOpen()
  }

  editOutlet(product: any) {
    this.yourappsService.setSelectedOutLetList(product, 1)
    this.yourappsService.getReloadOutLetList([], 0)
    this.subscription = this.yourappsService.reloadOutLetList$.subscribe((value) => {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      if (value.type === 1) {
        forkJoin({
          outlets: this.serviceservice.selectOutlets({accountid: this.getAccountID()}),
        }).subscribe(({ outlets }) => {
          this.iOutletsResult = outlets.data;
          console.log("this.iOutletsResult", this.iOutletsResult)
        });
      }
    })
    this.modalService.orOpen()
  }

  deleteOutlet(product: any) {

  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
