<button mat-icon-button type="button" class="close-btn"  (click)="modalService.closeEmailAdd()">
  <mat-icon>close</mat-icon>
</button>
<span class="sample-manager-editor">Add Email

</span>

<div class="example-full-width">
  <label class="inputlabel">E-Mail<span class="red-text"> *</span></label>
  <nz-input-group nzSearch>
    <input class="line1-edits-new inputedit testdiv" type="email" [(ngModel)]="emailstring" nz-input/>
  </nz-input-group>
</div>

<div class="button-container">
  <button
    mat-raised-button
    class="button-settings"
    type="button" (click)="modalService.closeEmailAdd()">
    Cancel
  </button>
  <button mat-raised-button class="button-settings" type="button" (click)="onOKClick()">
    OK
  </button>
</div>
