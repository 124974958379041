import { Component } from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";

@Component({
  selector: 'app-createlook',
  templateUrl: './createlook.component.html',
  styleUrls: ['./createlook.component.scss']
})
export class CreatelookComponent {

  constructor(public modalService: ModalserviceService) {}

}
