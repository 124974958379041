import {AfterViewInit, Component, OnDestroy, OnInit, Self} from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder} from "@angular/forms";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MessageService} from "primeng/api";
import {SelfData} from "../../models/struct";
import {comment} from "postcss";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-rrcommentdialog',
  templateUrl: './rrcommentdialog.component.html',
  styleUrls: ['./rrcommentdialog.component.scss']
})
export class RrcommentdialogComponent implements OnInit, AfterViewInit, OnDestroy {
  commenttext: string = "";
  constructor(
      public modalService: ModalserviceService,
      public fb: FormBuilder,
      private serviceservice: ServiceService,
      private yourappsService: YourappsserviceService,
      private _snackBar: MatSnackBar,
      private messageService: MessageService
  ) {

  }
  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private subscription?: Subscription;
  ngOnInit(): void {
    this.subscription = this.yourappsService.getFooterText$.subscribe((value: string) => {
      // if (this.subscription) {
      if (value != '') {
        this.commenttext = value
        console.log("this.commenttext", this.commenttext)
      }
      // }
      // this.subscription?.unsubscribe()
    });
  }

  submit() {
    this.yourappsService.getReloadSendOutReturnList(this.commenttext)
    this.modalService.closeRRC()
  }

  protected readonly SelfData = SelfData;
  protected readonly Self = Self;
}
