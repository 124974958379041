<div class="center-content">
  <mat-tab-group animationDuration="300ms">
    <mat-tab label="Standard Fields">
      <div class="black-background2">
        <section class="example-container" tabindex="0">
          <table mat-table [dataSource]="setdataSource">
            <ng-container matColumnDef="id">
              <th
                class="field1 mat-header-row"
                mat-header-cell
                *matHeaderCellDef
              >
                No
              </th>
              <td
                mat-cell
                *matCellDef="let element"
                (dblclick)="onCellDblClick(element)"
              >
                {{ element.id }}
              </td>
            </ng-container>
            <ng-container matColumnDef="fieldlabel">
              <th
                class="field2 mat-header-row"
                mat-header-cell
                *matHeaderCellDef
              >
                Field Label
              </th>
              <td
                mat-cell
                *matCellDef="let element"
                (dblclick)="onCellDblClick(element)"
              >
                {{ element.fieldlabel }}
              </td>
            </ng-container>
            <ng-container matColumnDef="type">
              <th
                class="field3 mat-header-row"
                mat-header-cell
                *matHeaderCellDef
              >
                Type
              </th>
              <td mat-cell *matCellDef="let element" class="icon-buttons">
                <mat-icon>{{ iconMap[element.fieldtype] }}</mat-icon>
              </td>
            </ng-container>

            <ng-container matColumnDef="requered">
              <th
                class="field3 mat-header-row"
                mat-header-cell
                *matHeaderCellDef
              >
                Requered
              </th>
              <td mat-cell *matCellDef="let element" class="icon-buttons">
                <mat-icon *ngIf="element.requered">done</mat-icon>
              </td>
            </ng-container>

            <ng-container matColumnDef="actions">
              <th
                class="field3 mat-header-row"
                mat-header-cell
                *matHeaderCellDef
              ></th>
              <td mat-cell *matCellDef="let element" class="icon-buttons">
                <div class="button-container">
                  <button
                    mat-button
                    class="button-edit"
                    (click)="
                      showAddEditFrame(
                        1,
                        element,
                        element.id,
                        element.description
                      )
                    "
                  >
                    Edit
                  </button>
                </div>
              </td>
            </ng-container>
            <tr
              mat-header-row
              *matHeaderRowDef="displayedColumns; sticky: true"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
          <mat-paginator
            #paginator
            class="sticky-paginator"
            [pageSizeOptions]="[8, 20, 50, 100]"
            aria-label="Select page of periodic elements"
          >
          </mat-paginator>
        </section>
      </div>
    </mat-tab>
    <mat-tab label="Custom Fields">
      <div class="black-background">
        <button mat-mini-fab (click)="showAddEditFrame(0, null)">
          <mat-icon>add</mat-icon>
        </button>
      </div>
      <div class="black-background2">
        <section class="example-container" tabindex="0">
          <table mat-table [dataSource]="setCustomdataSource">
            <!--            <ng-container matColumnDef="id">-->
            <!--              <th class="field1 mat-header-row" mat-header-cell *matHeaderCellDef>-->
            <!--                No-->
            <!--              </th>-->
            <!--              <td-->
            <!--                mat-cell-->
            <!--                *matCellDef="let element"-->
            <!--                (dblclick)="onCellDblClick(element)">-->
            <!--                {{ element.id }}-->
            <!--              </td>-->
            <!--            </ng-container>-->
            <ng-container matColumnDef="fieldlabel">
              <th
                class="field2 mat-header-row"
                mat-header-cell
                *matHeaderCellDef
              >
                Field Label
              </th>
              <td
                mat-cell
                *matCellDef="let element"
                (dblclick)="onCellDblClick(element)"
              >
                {{ element.fieldlabel }}
              </td>
            </ng-container>
            <ng-container matColumnDef="type">
              <th
                class="field3 mat-header-row"
                mat-header-cell
                *matHeaderCellDef
              >
                Type
              </th>
              <td mat-cell *matCellDef="let element" class="icon-buttons">
                <mat-icon>{{ iconMap[element.fieldtype] }}</mat-icon>
              </td>
            </ng-container>

            <ng-container matColumnDef="requered">
              <th
                class="field4 mat-header-row"
                mat-header-cell
                *matHeaderCellDef
              >
                Requered
              </th>
              <td mat-cell *matCellDef="let element" class="icon-buttons">
                <mat-icon *ngIf="element.requered">done</mat-icon>
              </td>
            </ng-container>
            <ng-container matColumnDef="actions">
              <th
                class="field3 mat-header-row"
                mat-header-cell
                *matHeaderCellDef
              ></th>
              <td mat-cell *matCellDef="let element" class="icon-buttons">
                <div class="button-container">
                  <button
                    mat-button
                    class="button-edit"
                    (click)="
                      showAddEditFrame(
                        2,
                        element,
                        element.id,
                        element.description
                      )
                    "
                  >
                    Edit
                  </button>
                  <button
                    mat-button
                    class="button-delete"
                    (click)="deleterecord(element.id)"
                  >
                    Delete
                  </button>
                </div>
              </td>
            </ng-container>
            <tr
              mat-header-row
              *matHeaderRowDef="displayedColumns; sticky: true"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
          <mat-paginator
            #customPaginator
            class="sticky-paginator"
            [pageSizeOptions]="[8, 20, 50, 100]"
            aria-label="Select page of periodic elements"
          >
          </mat-paginator>
        </section>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
