import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ModalserviceService } from '../../services/modalservice.service';
import { HashService } from '../../services/hash.service';
import { MessageboxComponent } from '../../modals/messagebox/messagebox.component';
import { ServiceService } from '../../services/service.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { YourappsserviceService } from '../../services/yourappsservice.service';
import { SelfData, SetSampleListData } from '../../models/struct';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-selectedsampledialog',
  templateUrl: './selectedsampledialog.component.html',
  styleUrls: ['./selectedsampledialog.component.scss'],
})
export class SelectedsampledialogComponent implements OnInit, AfterViewInit {
  myForm: FormGroup;
  sizes!: any[];

  selectedSize: any = '';
  displayedColumns: string[] = [
    'checkbox',
    'actions',
    'Designer',
    'Type',
    'Description',
    'Image',
  ];
  dataSource = new MatTableDataSource<SetSampleListData>(
    SelfData.checkedsample
  );
  @ViewChild(MatPaginator) paginator: MatPaginator | null = null;
  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private hashService: HashService,
    public messageBox: MessageboxComponent,
    private serviceservice: ServiceService,
    private _snackBar: MatSnackBar,
    private yourappsService: YourappsserviceService,
    private ngZone: NgZone,
    private cdRef: ChangeDetectorRef
  ) {
    this.myForm = new FormGroup({});
    this.sizes = [
      { name: 'Small', class: 'p-datatable-sm' }
    ];
    this.selectedSize = this.sizes[0]
  }

  public get setdataSource() {
    return this.dataSource;
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<SetSampleListData>(
      SelfData.checkedsample
    );

    for (let element of this.dataSource.data) {
      element['checked'] = !!SelfData.checkedsample.find(
        (sample) => sample['barcode'] === element['barcode']
      );
    }
  }

  onSelectedItemCLick(barcode: string) {
    this.yourappsService.setSelectedSample(barcode);
  }

  updateCheckedSamples(element: SetSampleListData) {
    if (element['checked']) {
      SelfData.checkedsample.push(element);
    } else {
      const index = SelfData.checkedsample.findIndex(
        (sample) => sample['barcode'] === element['barcode']
      );
      if (index !== -1) {
        SelfData.checkedsample.splice(index, 1);
      }
    }
    this.dataSource = new MatTableDataSource<SetSampleListData>(
      SelfData.checkedsample
    );
    this.dataSource.paginator = this.paginator;
    localStorage.setItem(
      'checkedSamples',
      JSON.stringify(SelfData.checkedsample)
    );
  }

  submit() {
    // Your form submission logic
  }

  protected readonly SelfData = SelfData;
}
