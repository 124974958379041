import {AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {DOCUMENT} from "@angular/common";
import {DeviceDetectorService} from "ngx-device-detector";
import {ModalserviceService} from "../../services/modalservice.service";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {GetSendOutSamples, PrcPullsheetAcceptedInput, SelfData} from "../../models/struct";
import {Subscription} from "rxjs";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import SignaturePad from "signature_pad";
import {MessageService} from "primeng/api";
import {ServiceService} from "../../services/service.service";
import {HashService} from "../../services/hash.service";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: 'app-pullsheet',
  templateUrl: './pullsheet.component.html',
  styleUrls: ['./pullsheet.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0 }), animate(300)]),
      transition(':leave', [animate(300, style({ opacity: 0 }))]),
    ]),
  ],
})

export class PullsheetComponent implements OnInit, OnDestroy, AfterViewInit {
  deviceClass: string = "";
  footerClass: string = "";
  deviceClassAddress: string = "";
  imageUrl: string = '';
  signatureimage: string = '';
  isLandscape: boolean = false;
  dateofpull: Date | null = null;
  dateoffitting: Date | null = null;
  dateofse: Date | null = null;
  dateofer: Date | null = null;
  dateoffr: Date | null = null;
  extdate: Date | null = null;
  numbervalue: string = ""
  numbervalue1: string = ""
  textvalue: string = ""
  textvalue1: string = ""
  textvalue2: string = ""
  textvalue3: string = ""
  textvalue4: string = ""
  textvalue5: string = ""
  textvalue6: string = ""
  textvalue7: string = ""
  textvalue8: string = ""
  signatureImg: string = "";
  selectedFile: File | null = null;
  isDesktopDevice: boolean = this.deviceService.isDesktop();

  constructor(private renderer: Renderer2, @Inject(DOCUMENT) private document: Document,
              private yourappsService: YourappsserviceService,
              private messageService: MessageService,
              private serviceservice: ServiceService,
              private hashService: HashService,
              private http: HttpClient,
              private deviceService: DeviceDetectorService, public modalService: ModalserviceService) {
    this.setDeviceClass();
  }

  setDeviceClass(): void {
    this.deviceClass = this.deviceService.isMobile() ? 'mobilemaincard' : 'pcmaincard';
    this.footerClass = this.deviceService.isMobile() ? 'mobilebutton-container-dm-ps' : 'pcbutton-container-dm-ps';
    this.deviceClassAddress = this.deviceService.isMobile() ? 'mobileaddressdiv' : 'pcaddressdiv';
    // this.isLandscape = window.innerWidth > window.innerHeight ? 'islandscape' : 'isnlandscape';
  }

  ngAfterViewInit(): void {
  }

  base64ToFile(base64: string, filename: string): File {
    const byteString = atob(base64.split(',')[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }

    return new File([uint8Array], filename, { type: 'image/png' }); // assuming the image is PNG format
  }

  private subscription?: Subscription;

  private signaturesubscription?: Subscription;
  ngOnInit() {
    this.subscription = this.yourappsService.logoimage$.subscribe((image) => {
      this.imageUrl = image
      console.log("this.imageUrl", this.imageUrl)
    });
    this.renderer.setStyle(this.document.body, 'background-color', 'rgba(150,150,150,0.5)');
    this.setOrientation();
    window.addEventListener('resize', this.setOrientation.bind(this))
    this.signaturesubscription = this.yourappsService.signatureImage$.subscribe((image: string) => {
      if (this.signaturesubscription) {
        if (image != '') {
          console.log(image)
          this.signatureImg = image
        }
      }
    });

    // window.addEventListener('resize', () => {
    //   // this.isLandscape = window.innerWidth > window.innerHeight ? 'islandscape' : 'isnlandscape';
    // });
  }

  ngOnDestroy(): void {
    if (this.signaturesubscription) {
      this.signaturesubscription.unsubscribe();
    }

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  setOrientation() {
    this.isLandscape = window.innerWidth > window.innerHeight;
  }

  onSignatureClick() {
    this.modalService.openSignature()
  }

  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
        ? SelfData.account[0].data[0].id
        : SelfData.account[0].data[0].parentid;
  }

  uploadSignature(
      file: File,
      accountId: number,
      callback: (success: boolean) => void
  ) {
    const formData = new FormData();

    const updatedFile = new File(
        [file],
        this.hashService.md5(accountId.toString()) + '.png',
        {type: file.type}
    );

    formData.append('image', updatedFile, updatedFile.name);
    formData.append('accountId', accountId.toString());

    this.http.post('http://108.178.189.70:6330/updatesignature', formData).subscribe({
      next: (response) => {
        console.log('Image uploaded successfully', response);
        callback(true);
      },
      error: (error) => {
        console.error('Image upload failed', error);
        callback(false);
      },
      complete: () => {
        // This block is optional and will be executed once the observable completes.
        // If you have no specific logic to run upon completion, you can omit it.
        console.log('Upload operation completed');
      }
    });
  }

  onButtonAcceptClick() {
    if (!this.dateofpull) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: 'Error',
        detail: 'You must indicate "Date of Pull"',
        life: 10000,
      });
      return
    }

    if (!this.dateoffitting) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: 'Error',
        detail: 'You must indicate "Date of Fitting"',
        life: 10000,
      });
      return
    }

    if (!this.dateofse) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: 'Error',
        detail: 'You must indicate "Date of Shoot/Event"',
        life: 10000,
      });
      return
    }

    if (!this.dateofer) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: 'Error',
        detail: 'You must indicate "Date of Edits Return"',
        life: 10000,
      });
      return
    }

    if (!this.dateoffr) {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: 'Error',
        detail: 'You must indicate "Date of Final Return"',
        life: 10000,
      });
      return
    }

    if (this.numbervalue === "") {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: 'Error',
        detail: 'You must indicate "# of Garment Bags"',
        life: 10000,
      });
      return
    }

    if (this.numbervalue1 === "") {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: 'Error',
        detail: 'You must indicate "# of Tote Bags"',
        life: 10000,
      });
      return
    }

    if (this.signatureImg === "") {
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary: 'Error',
        detail: 'You must indicate "Signature"',
        life: 10000,
      });
      return
    }

    const inputData: PrcPullsheetAcceptedInput = {
      id: 0,
      pullsheetid: SelfData.pullsheetlist[0].data[0].id,
      dateofpull: this.dateofpull,
      dateodfitting: this.dateoffitting,  // Assuming this is a nullable value
      dateofse: this.dateofse,
      dateoder: this.dateofer,
      dateoffr: this.dateoffr,
      gb: this.numbervalue.toString(),
      tb: this.numbervalue1.toString(),
      stylistname: this.textvalue,
      publication: this.textvalue1,
      confirms: this.textvalue2,
      phonenumber: this.textvalue3.toString(),
      email: this.textvalue4,
      cc: this.textvalue5,
      expdate: this.extdate,
      securitycode: this.textvalue6.toString(),
      bilingzipcode: this.textvalue7.toString(),
      printname: this.textvalue8,
      ctrl: 0,
      key: SelfData.pullsheetlist[0].data[0].key
    };
    this.serviceservice.prcPullsheetAccepted(inputData).subscribe({
      next: (response) => {
        console.log(response);
        this.selectedFile = this.base64ToFile(this.signatureImg, "signature");
        this.uploadSignature(this.selectedFile!, response.data,
            (success: boolean) => {
              if (success) {
                this.messageService.add({
                  key: 'bc',
                  severity: 'success',
                  summary: 'Success',
                  detail: "Data saved",
                  life: 5000,
                });
                // Image upload succeeded, do something
                window.location.reload();
              } else {
                // Image upload failed, handle the error
              }
            })
        // Do something with the response
      },
      error: (error) => {
        console.error('Error while sending data:', error);
      },
      complete: () => {
        // This block is optional and will be executed once the observable completes
        console.log('Completed');
      }
    });
  }

  iSendOutSample: GetSendOutSamples[] = [];
  onButtonViewItems() {
    this.serviceservice
        .getsendoutsaples({ sendoutid:  SelfData.pullsheetlist[0].data[0].sendoutid})
        .subscribe((value) => {
          this.iSendOutSample[0] = value;
          SelfData.sendoutsamples = this.iSendOutSample
          this.modalService.openSSL()
          console.log("SelfData.sendoutsamples", SelfData.sendoutsamples)
          // SelfData.addres = this.iAddressResult;
        });
  }

  protected readonly SelfData = SelfData;
}


