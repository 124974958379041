import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder} from "@angular/forms";
import {HashService} from "../../services/hash.service";
import {MessageboxComponent} from "../../modals/messagebox/messagebox.component";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {ConfirmationService, MessageService} from "primeng/api";
import {MatSnackBar} from "@angular/material/snack-bar";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {SelfData} from "../../models/struct";

@Component({
  selector: 'app-emailadddialog',
  templateUrl: './emailadddialog.component.html',
  styleUrl: './emailadddialog.component.scss'
})
export class EmailadddialogComponent implements OnInit, AfterViewInit {
  emailstring: string = "";

  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private hashService: HashService,
    public messageBox: MessageboxComponent,
    private serviceservice: ServiceService,
    private yourappsService: YourappsserviceService,
    private confirmationService: ConfirmationService,
    private _snackBar: MatSnackBar,
    private notification: NzNotificationService,
    private messageService: MessageService
  ) {

  }
  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    this.yourappsService.setEmail("", 0)
  }

  protected readonly SelfData = SelfData;

  onOKClick() {
    if (this.emailstring.trim() === "") {
      this.notification.error(
        '<strong class="notification-error">Information</strong>',
        '<strong class="notification-error">Email is required</strong>'
      );
      return
    }
    this.yourappsService.setEmail(this.emailstring, 1)
    this.modalService.closeEmailAdd()
  }
}
