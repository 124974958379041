<p-button class="info-btn" icon="pi pi-info" [rounded]="true" [outlined]="true" (click)="onInfoButtonClick()"/>
<button mat-icon-button type="button" class="close-btn" (click)="modalService.closeCPS()">
    <mat-icon>close</mat-icon>
</button>
<p class="sample-manager-text-rrc">Create Pull Sheet</p>

<div class="row">
    <div class="forma-group">
        <mat-form-field class="selector-full-width-cps" appearance="fill">
            <mat-label>Email</mat-label>
            <input type="email" matInput [formControl]="emailFormControl"/>
            <mat-error *ngIf="emailFormControl.hasError('email')">
                Please enter a valid email address
            </mat-error>
        </mat-form-field>
    </div>

    <div class="forma-group">
        <mat-form-field class="selector-full-width-cps" appearance="fill">
            <mat-label>Phone</mat-label>
            <input type="tel" matInput [formControl]="phoneControl"/>
        </mat-form-field>
    </div>

    <div class="forma-group">
        <mat-form-field class="selector-full-width-cps" appearance="fill">
            <mat-label>Send Out</mat-label>
            <input type="text" matInput [formControl]="sendoutControl" [readonly]="true"/>
            <button mat-stroked-button type="button" class="add-button" [disabled]="buttondisabled" matSuffix (click)="onButtonAddClick()">
                Add SendOut
                <!--                <mat-icon class="center-icon">add</mat-icon>-->
            </button>
        </mat-form-field>
    </div>
</div>

<div class="card-email">
    <p-editor [(ngModel)]="text" [style]="{ height: '185px' }">
        <!--        <ng-template pTemplate="header">-->
        <!--        <span class="ql-formats">-->
        <!--            <button type="button" class="ql-bold" aria-label="Bold"></button>-->
        <!--            <button type="button" class="ql-italic" aria-label="Italic"></button>-->
        <!--            <button type="button" class="ql-underline" aria-label="Underline"></button>-->
        <!--        </span>-->
        <!--        </ng-template>-->
    </p-editor>
</div>

<div class="button-container-label">
    <button mat-raised-button class="button-settings" type="button" (click)="onButtonSendClick()">
        Send
    </button>
</div>
