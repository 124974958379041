<div class="card example-container-so  mat-elevation-z3">
  <!--  [(selection)]="selectedElement"-->
  <!--  (onRowSelect)="createSendOut(selectedElement)"-->
  <p-table
    #dt1
    [value]="SelfData.sendoutpendinglist[0].data"
    [paginator]="true"
    [rows]="10"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [rowsPerPageOptions]="[10, 20, 50, 100]"
    [styleClass]="'p-datatable-striped mat-elevation-z3 ' + selectedSize.class.toString()"
    selectionMode="single"
    [globalFilterFields]="['Delivery', 'Type of Dispatch', 'Creation date', 'company', 'Contact', 'celebriti', 'publication', 'Editor/Stylist', 'Celeb/Influenc/Model', 'Project/Event', 'Sendout Status']"
    [tableStyle]="{'min-width': '50rem'}">
    <ng-template pTemplate="caption">
      <div class="flex">
        <!--                    <span class="p-inputgroup-addon custom-hover" matRipple (click)="onButtonAddClick()">-->
        <!--                        <i class="pi pi-plus" style="color: #000000"></i></span>-->
        <span class="p-input-icon-left ml-auto">
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">
              <i class="pi pi-search" style="color: #000000"></i>
            </span>
            <input pInputText type="text" (input)="onInput($event)" placeholder="Filter" [(ngModel)]="filtertext"/>
            <span class="p-inputgroup-addon custom-hover-red" matRipple (click)="clear(dt1)">
              <i class="pi pi-times" style="color: #000000"></i></span>
          </div>
        </span>

      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <!--                    -->
        <th style="width: 0.5%">
        </th>
        <th style="width:6%">Options</th>
        <th style="width:17.6%">Delivery #<br>Type of Dispatch<br>Quantity</th>
        <th style="width:17.6%">Creation Data</th>
        <th style="width:17.6%">Company<br>Contact<br>Client</th>
        <th style="width:17.6%">Publication<br>Editor/Stylist</th>
        <th style="width:17.6%">Celeb/Influenc/Model<br>Project/Event</th>
        <th style="width:8%">Sendout Status</th>
        <!--                <th>Name</th>-->
        <!--                <th>Category</th>-->
        <!--                <th>Quantity</th>-->
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-product>
      <tr *ngIf="isDataLoaded && SelfData?.sendoutpendinglist?.[0]?.data" [pSelectableRow]="SelfData.sendoutpendinglist[0].data">
        <td>
          <div class="scdivsl"></div>
        </td>
        <td>

          <!--          <p-splitButton *ngIf="SelfData.selectsendoutmode == 0" [matMenuTriggerFor]="menu" severity="info"  (click)="selectedElement = product"-->
          <!--            label="select" styleClass="p-button-outlined p-button-secondary mr-2 mb-2"></p-splitButton>-->

          <button nzTrigger="click" *ngIf="SelfData.selectsendoutmode == 0" nz-button nz-dropdown [nzDropdownMenu]="menu4" (click)="selectedElement = product">
            select
            <span nz-icon nzType="down"></span>
          </button>

          <!--          <p-splitButton *ngIf="SelfData.selectsendoutmode == 1"  severity="info" (click)=onButtobSelectClick(product)-->
          <!--            label="select" styleClass="p-button-outlined p-button-secondary mr-2 mb-2"></p-splitButton>-->

          <button *ngIf="SelfData.selectsendoutmode == 1" nz-button (click)=onButtobSelectClick(product)>
            select
          </button>

        </td>
        <td>
          <span style="text-decoration: underline; color: #007bff;" (click)="createSendOut(product)">{{ product?.["Delivery"] }}</span><br>
          {{ product?.["Type of Dispatch"] }}<br>
          {{ product?.["itemscount"] }}
        </td>
        <td>{{ formatDate(product?.["Creation date"]) }}</td>
        <td>
          {{ product?.["company"] }}<br>
          {{ product?.["Contact"] }}<br>
          <div class="celebriti">
            <strong>{{ product?.["celebriti"] }}</strong>
            <button *ngIf="product?.celebriti" mat-icon-button class="center-icon"
              (click)="onButtonCelebritiClick(product); op.toggle($event)">
              <mat-icon class="scaled-icon">account_circle</mat-icon>
            </button>
          </div>
        </td>
        <td>{{ product?.["publication"] }}<br>{{ product?.["Editor/Stylist"] }}</td>
        <td>{{ product?.["Celeb/Influenc/Model"] }}<br>{{ product?.["Project/Event"] }}</td>
        <td>{{ product?.["Sendout Status"] }}</td>
        <!--                <td>{{product.category}}</td>-->
        <!--                <td>{{product.quantity}}</td>-->
      </tr>
    </ng-template>
  </p-table>
</div>

<nz-dropdown-menu #menu4="nzDropdownMenu">
  <ul nz-menu>
    <li nz-menu-item (click)="createSendOut(selectedElement)">Edit SendOut</li>
    <li nz-menu-item *ngIf="selectedElement && selectedElement['sendouttypeid'] === 3" (click)="createsendoutpurchase(selectedElement)">View Purchase Samples</li>
    <li nz-menu-item (click)="createSendOutPrint(selectedElement)">Print Page</li>
    <li nz-menu-item (click)="createRRList(selectedElement)">Return/Recover Samples</li>
    <li nz-menu-item (click)="createRequestList(selectedElement)">Edit Associated Request</li>
    <li nz-menu-item (click)="onButtonPullSheetClick(selectedElement)">Pull Sheet</li>
  </ul>
</nz-dropdown-menu>

<p-overlayPanel #op>
  <mat-card class="overlay-card">
    <div *ngIf="progressSpinnerShow" class="spinner">
      <p-progressSpinner></p-progressSpinner>
    </div>
    <div *ngIf="!progressSpinnerShow" class="black-background-social">
      <div class="child-40">
        <div class="content-wrapper">
          <div class="thema-image shadow-4" [style.background-image]="'url(' + defaultimagelink + ')'"></div>
        </div>
      </div>

      <div class="child-60">
        <div class="top-center-full-width">
          <span class="centered-text">{{ defaultname }}</span>
        </div>
        <mat-divider class="black-divider"></mat-divider>
        <div class="black-background-caption">
          <div class="caption-40">
            <span class="left-text-caption">CHANNEL</span>
          </div>
          <div class="caption-60">
            <span class="right-text-caption">FOLLOWERS</span>
          </div>
        </div>
        <mat-divider class="black-divider"></mat-divider>
        <div class="centered-div-social" *ngFor="let item of sortedSMedia(); let i = index"
          [ngStyle]="{ 'background-color': getColor(i) }">
          <span class="left-text" (click)="openLink(item.link)">{{ item.platform }}</span>
          <span class="right-text">{{ item.subscribers | formatCount }}</span>
        </div>

      </div>
    </div>

  </mat-card>
</p-overlayPanel>

<p-confirmDialog *ngIf="showdialog" #cd [style]="{width: '25vw'}" [closable]="true">
  <ng-template pTemplate="footer">
    <div class="button-container-request">
      <button mat-raised-button class="button-secondary" style="margin-right: 10px; width: 50%" (click)="cd.reject()">
        No
      </button>
      <button mat-raised-button class="button-primary" style="margin-right: 0; width: 50%" (click)="cd.accept()">
        Yes
      </button>
    </div>
  </ng-template>
</p-confirmDialog>
