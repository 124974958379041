import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef, OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ModalserviceService} from '../../services/modalservice.service';
import {MessageboxComponent} from '../../modals/messagebox/messagebox.component';
import {ServiceService} from '../../services/service.service';
import {YourappsserviceService} from '../../services/yourappsservice.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NgxImageCompressService} from 'ngx-image-compress';
import {HttpClient} from '@angular/common/http';
import {MatStepper} from '@angular/material/stepper';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {LbCatalogData, LbCatalogDetails, SelfData, SendOut1Data, SetSampleList} from '../../models/struct';
import {ConfirmationService, ConfirmEventType, MessageService} from "primeng/api";
import {forkJoin, Observable, sample, Subscription} from "rxjs";
import {Sendoutstep1Component} from "../../pages/sendoutstep1/sendoutstep1.component";
import {error} from "@ant-design/icons-angular";

@Component({
  selector: 'app-createsendoutdialog',
  templateUrl: './createsendoutdialog.component.html',
  styleUrls: ['./createsendoutdialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('buttonAnimation', [
      state(
        '0',
        style({
          opacity: 0.5,
          transform: 'scale(1)',
        })
      ),
      state(
        '1',
        style({
          opacity: 1,
          transform: 'scale(1)',
        })
      ),
      transition('* => *', animate('300ms ease-out')),
    ]),
    trigger('buttonAnimation1', [
      state(
        '0',
        style({
          opacity: 0.5,
          transform: 'scale(1)',
        })
      ),
      state(
        '1',
        style({
          opacity: 1,
          transform: 'scale(1)',
        })
      ),
      transition('* => *', animate('300ms ease-out')),
    ]),
    trigger('fadeInOut', [
      state('in', style({opacity: 1})),
      transition(':enter', [style({opacity: 0}), animate(300)]),
      transition(':leave', [animate(300, style({opacity: 0}))]),
    ]),
  ],
})
export class CreatesendoutdialogComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('stepper') stepper!: MatStepper;
  @ViewChild(Sendoutstep1Component) sendoutstep1!: Sendoutstep1Component;
  myForm: FormGroup;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  stepIndex = 0;
  iSampleDataResult: SetSampleList[] = [];
  selectedid: number = -1
  isVisible: boolean = true;
  formcaption: string = ""
  tdata: any = null;
  showdialog: boolean = false
  createbuttoncaption = 'Next';
  private subscription?: Subscription;

  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    public messageBox: MessageboxComponent,
    private serviceservice: ServiceService,
    private yourappsService: YourappsserviceService,
    private messageService: MessageService,
    private cdr: ChangeDetectorRef,
    private confirmationService: ConfirmationService
  ) {
    console.log("modalService.isSendOutStep3$", modalService.isSendOutStep3$)
    if (SelfData.sendoutopentype === 0) {
      this.formcaption = "Create Send Out"
      this.createbuttoncaption = "Next"
      this.stepIndex = 0
      modalService.createSendOutStep1();
      modalService.closeSendOutStep2();
      modalService.closeSendOutStep3();
    } else if (SelfData.sendoutopentype === 1) {
      if (SelfData.sendoutstep === 0) {
        this.formcaption = "Create Send Out"
        this.stepIndex = 0
        this.modalService.createSendOutStep1();
        this.modalService.closeSendOutStep2();
        modalService.closeSendOutStep3();
      } else if (SelfData.sendoutstep === 1) {
        this.stepIndex = 1
        this.modalService.closeSendOutStep1();
        this.modalService.createSendOutStep2();
        modalService.closeSendOutStep3();
      } else if (SelfData.sendoutstep === 2) {
        this.stepIndex = 2
        this.modalService.closeSendOutStep1();
        this.modalService.closeSendOutStep2();

        console.log("2")
        this.modalService.createSendOutStep3();
      }
      this.formcaption = "Edit Send Out"
      this.createbuttoncaption = "Update"
    } else if (SelfData.sendoutopentype === 2) {
      modalService.createSendOutStep1();
      modalService.closeSendOutStep2();
      modalService.closeSendOutStep3();
      this.formcaption = "Create Request"
      if (SelfData.sendoutstep === 0) {
        this.stepIndex = 0
        this.modalService.createSendOutStep1();
        this.modalService.closeSendOutStep2();
        modalService.closeSendOutStep3();
      } else if (SelfData.sendoutstep === 1) {
        this.stepIndex = 1
        this.modalService.closeSendOutStep1();
        this.modalService.createSendOutStep2();
        modalService.closeSendOutStep3();
      } else if (SelfData.sendoutstep === 2) {
        this.stepIndex = 2
        this.modalService.closeSendOutStep1();
        this.modalService.closeSendOutStep2();
        console.log("3")
        this.modalService.createSendOutStep3();
      }
    }
    this.myForm = this.fb.group({});
    this.firstFormGroup = this.fb.group({
      firstCtrl: ['', Validators.required],
    });
    this.secondFormGroup = this.fb.group({
      secondCtrl: ['', Validators.required],
    });


    var accountid: number = 0;
    if (SelfData.account[0].data[0].parentid == 0) {
      accountid = SelfData.account[0].data[0].id;
    } else {
      accountid = SelfData.account[0].data[0].parentid;
    }
    this.serviceservice.setSampleList({accountid: accountid, page: 1, pageSize: 1000000, forceRefresh: false, samplestyle: SelfData.samplestyle}).subscribe({
      next: (value) => {
        if (value) {
          this.iSampleDataResult[0] = value;
          SelfData.sampledata = this.iSampleDataResult;
        } else {
          console.log('The response is empty or undefined.');
        }
      },
      error: (error) => {
        console.error('Error occurred during the subscription:', error);
      },
    });
  }

//     if (this.selectedid == 3) {
//     this.modalService.invoiceOpen()
// }
  nextClick() {
    if (this.sendoutstep1) {
      this.sendoutstep1.checkValidation();

      // If errormode is true, abort further execution
      if (SendOut1Data.errormode) {
        console.log("SendOut1Data.errormode", SendOut1Data.errormode);
        return;
      }

      // Continue with the rest of the function if validation passed
    }
    // if (SendOut1Data.errormode) {
    //   console.log("SendOut1Data.errormode", SendOut1Data.errormode);
    // } else {
    //   console.log("SendOut1Data.errormode", SendOut1Data.errormode);
    //   return; // Abort the function if SendOut1Data.errormode is falsy
    // }
    if (SelfData.setSelectedSample.some(sample => sample["isok"] === 0)) {
      this.messageService.add({
        key: 'tc',
        severity: 'error',
        summary: 'Information',
        // detail: `Sample ${barcodeValue} already scanned by user ${foundData['user']} for a new delivery or reservation.`,
        detail: `Some items from the list are on loan!`,
        life: 10000,
      });
    } else {
      console.log("SelfData.sendoutopentype", SelfData.sendoutopentype, SelfData.saveonclose)
      if (SelfData.sendoutopentype === 0) {
        if (SelfData.saveonclose === undefined) {
          SelfData.saveonclose = true
        }
        if (this.stepIndex == 0) {
          this.modalService.closeSendOutStep1();
          this.modalService.createSendOutStep2();
        }
        if (this.stepIndex < 2) {
          this.stepIndex += 1;
          if (this.stepIndex === 2) {
            if (this.selectedid != 3) {
              SelfData.saveonclose = true
              this.modalService.closeSendOutStep1();
              this.modalService.closeSendOutStep2();
              this.modalService.createSendOutStep3();
              this.createbuttoncaption = 'Done';
            } else {
              this.yourappsService.setSelectedSamples(SelfData.sampledata)
              this.stepIndex -= 1;
              this.subscription = this.yourappsService.invoiceCreateSend.subscribe((value) => {
                if (this.subscription) {
                  this.modalService.closeSendOutStep1();
                  this.modalService.closeSendOutStep2();
                  this.modalService.createSendOutStep3();
                  this.createbuttoncaption = 'Done';
                  this.stepIndex = 2
                  this.subscription.unsubscribe()
                }
              })
              this.modalService.invoiceOpen()
            }
          } else {
            this.createbuttoncaption = 'Next';
          }

        } else {
          this.submit(0);
        }
      } else {
        SelfData.saveonclose = true
        this.modalService.createsendoutclose()
      }
    }
  }

  backClick() {
    if (SelfData.setSelectedSample.some(sample => sample["isok"] === 0)) {
      this.messageService.add({
        key: 'tc',
        severity: 'error',
        summary: 'Information',
        // detail: `Sample ${barcodeValue} already scanned by user ${foundData['user']} for a new delivery or reservation.`,
        detail: `Some items from the list are on loan!`,
        life: 10000,
      });
    } else {
      if (this.stepIndex == 1) {
        this.modalService.createSendOutStep1();
        this.modalService.closeSendOutStep2();
        this.modalService.closeSendOutStep3();
      } else if (this.stepIndex == 2) {
        this.modalService.closeSendOutStep1();
        this.modalService.createSendOutStep2();
        this.modalService.closeSendOutStep3();
      }
      this.stepIndex -= 1;
      this.createbuttoncaption = 'Next';
    }
  }

  submit(type: number = 0) {
    this.modalService.createsendoutclose()
  }

  ngAfterViewInit() {
  }

  ngOnInit(): void {
    this.yourappsService.selectedid$.subscribe((value) => {
      this.selectedid = value
    })
  }

  onButtonCloseClick() {
    console.log("SelfData.selectadpage", SelfData.selectadpage)
    if (SelfData.selectadpage === 3) {
      SendOut1Data.id = 0
      SendOut1Data.reset()
      this.modalService.createsendoutclose()
      return
    } else {
      this.showdialog = true
      this.cdr.detectChanges()
      this.confirmationService.confirm({
        message: `<strong>Would you like to Cancel or Save this Send Out form?</strong>`,
        header: 'Information',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          SelfData.saveonclose = true
          SelfData.scannedlist = [{code: 0, errorMessage: '', data: []}];
          SelfData.setSelectedSample = [];
          SendOut1Data.check1 = false;
          SendOut1Data.check2 = false;
          SendOut1Data.check3 = false;
          SendOut1Data.departmentFormControl = '';
          SendOut1Data.shipFormControl = '';
          SendOut1Data.shiptoControl = '';
          SendOut1Data.todControl = '';
          SendOut1Data.peControl = '';
          SendOut1Data.companynameControl = '';
          SendOut1Data.addresslabelControl = '';
          SendOut1Data.costofshippingControl = '';
          SendOut1Data.cimControl = '';
          SendOut1Data.publicationControl = '';
          SendOut1Data.estylistControl = '';
          SendOut1Data.photographerControl = '';
          SendOut1Data.pelControl = '';
          SendOut1Data.petControl = '';
          SendOut1Data.issueControl = '';
          SendOut1Data.trackingNumberControl = '';
          SendOut1Data.returnControl = '';
          SendOut1Data.notesControl = '';
          SendOut1Data.senderFormControl = '';
          SendOut1Data.requestedbyFormControl = '';
          SendOut1Data.sendOutTypeControl = '';
          SendOut1Data.currancyControl = '';
          SendOut1Data.reservationdateControl = null;
          SendOut1Data.returndateControl = null;
          SendOut1Data.datetimeCtrl = null;
          SendOut1Data.datetimeCtrl1 = null;
          SendOut1Data.datetimeCtrl2 = null;
          SendOut1Data.datetimeCtrl3 = null;
          SendOut1Data.datetimeCtrl4 = null;
          SendOut1Data.celebriti = '';
          SendOut1Data.id = 0
          SelfData.scannedselectedlist = [];
          SendOut1Data.reset()
          this.modalService.createsendoutclose()
        },
        reject: (type: any) => {
          if (type == ConfirmEventType.REJECT) {
            SelfData.saveonclose = undefined
            SelfData.scannedlist = [{code: 0, errorMessage: '', data: []}];
            SelfData.setSelectedSample = [];
            SendOut1Data.check1 = false;
            SendOut1Data.check2 = false;
            SendOut1Data.check3 = false;
            SendOut1Data.departmentFormControl = '';
            SendOut1Data.shipFormControl = '';
            SendOut1Data.shiptoControl = '';
            SendOut1Data.todControl = '';
            SendOut1Data.peControl = '';
            SendOut1Data.companynameControl = '';
            SendOut1Data.addresslabelControl = '';
            SendOut1Data.costofshippingControl = '';
            SendOut1Data.cimControl = '';
            SendOut1Data.publicationControl = '';
            SendOut1Data.estylistControl = '';
            SendOut1Data.photographerControl = '';
            SendOut1Data.pelControl = '';
            SendOut1Data.petControl = '';
            SendOut1Data.issueControl = '';
            SendOut1Data.trackingNumberControl = '';
            SendOut1Data.returnControl = '';
            SendOut1Data.notesControl = '';
            SendOut1Data.senderFormControl = '';
            SendOut1Data.requestedbyFormControl = '';
            SendOut1Data.sendOutTypeControl = '';
            SendOut1Data.currancyControl = '';
            SendOut1Data.reservationdateControl = null;
            SendOut1Data.returndateControl = null;
            SendOut1Data.datetimeCtrl = null;
            SendOut1Data.datetimeCtrl1 = null;
            SendOut1Data.datetimeCtrl2 = null;
            SendOut1Data.datetimeCtrl3 = null;
            SendOut1Data.datetimeCtrl4 = null;
            SendOut1Data.celebriti = '';
            if (SelfData.sendoutid > 0) {
              const fields = {
                ctrl: 2,
                id: SelfData.sendoutid,
              };
              this.serviceservice
                .prcDeleteSendOut(fields)
                .subscribe((value) => {
                  SelfData.sendoutid = 0
                  if (this.SelfData.fullPage) {
                    this.yourappsService.getReloadSendOutList(null, 1)
                  }
                });
            }
            if (SelfData.scannedselectedlist && SelfData.scannedselectedlist.length > 0) {
              // Explicitly define the type for deleteRequests
              const deleteRequests: Observable<any>[] = [];

              // ციკლით გავიაროთ scannedselectedlist
              SelfData.scannedselectedlist.forEach(element => {
                // შევქმნათ და შევინახოთ თითოეული მოთხოვნა, პარამეტრები აიღოს element-დან
                const request = this.serviceservice
                  .deletefromscannlist({
                    ctrl: 0,
                    userid: element.userid,
                    accountid: element.accountid,
                    barcode: element.barcode
                  });

                deleteRequests.push(request);
              });

              // გამოვიყენოთ forkJoin ყველა მოთხოვნის ერთდროულად შესასრულებლად
              forkJoin(deleteRequests).subscribe(
                results => {
                  // ყველა მოთხოვნა დასრულდა
                  console.log('All delete requests completed', results);
                  // გავასუფთაოთ scannedselectedlist
                  SelfData.scannedselectedlist = [];
                },
                error => {
                  console.error('Error deleting items:', error);
                }
              );
            }
            SendOut1Data.id = 0
            SendOut1Data.reset()
            this.modalService.createsendoutclose()
          } else if (type == ConfirmEventType.CANCEL) {

          }
        }
      })
    }
    // this.yourappsService.setOKOption(3);
    // this.messageBox.modalService.openMessageBox(
    //   'Information',
    //   'Would you like to Cancel or Save this Send Out form? ',
    //   'Cancel',
    //   'Save'
    // );
    // this.modalService.createsendoutclose()
  }

  protected readonly SelfData = SelfData;

  ngOnDestroy(): void {
    SelfData.setSelectedSample = [];
    SelfData.sendoutid = 0
    SelfData.saveonclose = true
  }

  onInfoButtonClick() {
    window.open('https://fm.welcomeapps.net/FAQ/CreateSendOut.html', '_blank');
  }
}
