import {AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {SelfData, TagsListData} from "../../models/struct";
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder} from "@angular/forms";
import {HashService} from "../../services/hash.service";
import {MessageboxComponent} from "../../modals/messagebox/messagebox.component";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {HttpClient} from "@angular/common/http";
import * as moment from "moment-timezone";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {forkJoin, Subscription} from "rxjs";
import {Table} from "primeng/table";

@Component({
  selector: 'app-supplierframedialog',
  templateUrl: './supplierframedialog.component.html',
  styleUrl: './supplierframedialog.component.scss'
})
export class SupplierframedialogComponent implements OnInit, AfterViewInit {
  firstname: string = "";
  lastname: string = "";
  email: string = "";
  phone: string = "";
  suppliername: string = "";
  defaultmarkup: string = "";
  descriptionstring: string = "";
  companyname: string = "";
  mobile: string = "";
  website: string = "";
  instagram: string = "";
  facebook: string = "";
  tiktok: string = "";
  twitter: string = "";
  youtube: string = "";
  countries: any[] = [];
  states: any[] = [];
  pcountries: any[] = [];
  pstates: any[] = [];
  countrystring: string = "";
  pcountrystring: string = "";
  usedifferent: boolean = true;
  pzipcode: string = "";
  pstatesstring: string = "";
  pcity: string = "";
  psuburb: string = "";
  paddress2: string = "";
  paddress: string = "";
  address: string = "";
  address2: string = "";
  suburb: string = "";
  city: string = "";
  zipcode: string = "";
  statesstring: string = "";
  caption: string = "Add Supplier"
  iProductsResult: TagsListData[] = []
  setid: number = 0;
  opermode: number = 0;
  private subscription?: Subscription;
  selectedSalesReport: any;
  sizes!: any[];
  @ViewChild(Table) dataTable?: Table;
  selectedSize: any = ''
  viewmode: boolean = false;


  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
      ? SelfData.account[0].data[0].id
      : SelfData.account[0].data[0].parentid;
  }

  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private hashService: HashService,
    public messageBox: MessageboxComponent,
    private serviceservice: ServiceService,
    private yourappsService: YourappsserviceService,
    private notification: NzNotificationService,
    private _snackBar: MatSnackBar,
    private http: HttpClient,
    private cdr: ChangeDetectorRef
  ) {
    this.setid = 0
    this.opermode = 0
    this.sizes = [
      {name: 'Small', class: 'p-datatable-sm'}
    ];
    this.selectedSize = this.sizes[0]
    this.subscription = this.yourappsService.selectedSupplierList$.subscribe((value) => {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }

      if (value?.type === 1) {
        if (SelfData.formstyle === 1) {
          this.caption = "Edit Supplier"
          this.opermode = 1;
          this.viewmode = false
        }
        if (SelfData.formstyle === 2) {
          this.caption = "View Supplier"
          this.opermode = 1;
          this.viewmode = true
        }

        let data: any = value.data;

        // ✅ Проверяем, массив ли data, и берем первый элемент, если так
        if (Array.isArray(data) && data.length > 0) {
          data = data[0];
        }

        // ✅ Проверяем, является ли data объектом перед заполнением переменных
        if (data && typeof data === 'object') {
          this.setid = data.id ?? 0;
          this.suppliername = data.SupplierName ?? "";
          this.defaultmarkup = data.DefaultMarkup ?? "";
          this.descriptionstring = data.Description ?? "";
          this.firstname = data.FirstName ?? "";
          this.lastname = data.LastName ?? "";
          this.companyname = data.CompanyName ?? "";
          this.email = data.Email ?? "";
          this.phone = data.Phone ?? "";
          this.mobile = data.Mobile ?? "";
          this.website = data.Website ?? "";
          this.instagram = data.Instagram ?? "";
          this.facebook = data.Facebook ?? "";
          this.tiktok = data.TikTok ?? "";
          this.twitter = data.Twitter ?? "";
          this.youtube = data.YouTube ?? "";

          // 📍 Физический адрес магазина
          this.address = data.StoreStreetAddress ?? "";
          this.address2 = data.StoreStreetAddress2 ?? "";
          this.suburb = data.StoreSuburb ?? "";
          this.city = data.StoreCity ?? "";
          this.zipcode = data.StoreZipCode ?? "";
          this.statesstring = data.StoreState ?? "";
          this.countrystring = data.StoreCountry ?? "";

          // 📍 Почтовый адрес (если отличается от магазина)
          this.usedifferent = data.MailingSameAsStore;
          this.paddress = data.MailingStreetAddress ?? "";
          this.paddress2 = data.MailingStreetAddress2 ?? "";
          this.psuburb = data.MailingSuburb ?? "";
          this.pcity = data.MailingCity ?? "";
          this.pzipcode = data.MailingZipCode ?? "";
          this.pstatesstring = data.MailingState ?? "";
          this.pcountrystring = data.MailingCountry ?? "";

          console.log("✅ Объект после обработки:", data);

          setTimeout(() => {
            SelfData.spinnerpossition = true
          });
          forkJoin({
            products: this.serviceservice.selectSupplierHistory(this.setid),
          }).subscribe(({products}) => {
            this.iProductsResult = products.data;
            console.log("this.iProductsResult", this.iProductsResult)
            setTimeout(() => {
              SelfData.spinnerpossition = false
            });
          });
        } else {
          console.error("❌ Ошибка: data не является объектом", data);
        }
      }
    });

  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    this.http.get<any>('assets/countries.json').subscribe((data) => {
      this.countries = data.countries;
      if (this.opermode === 0) {
        this.countrystring = "United States"
      }
      this.pcountries = data.countries;
      if (this.opermode === 0) {
        this.pcountrystring = "United States"
      }
      // this.countriControl.setValue('United States');
      // this.countri1Control.setValue('United States');
      // this.countri2Control.setValue('United States');
      this.http.get<any>('assets/us-states.json').subscribe((data) => {
        this.states = data;
        this.onOptionSelected(this.countrystring)
        this.pstates = data;
        this.onOptionSelectedp(this.pcountrystring)
      })
    })
  }

  onSupplierClick() {
    let tid: number = this.setid
    let tctrl: number = this.opermode
    this.serviceservice.prcSuppliersSale({
      id: tid,
      supplierName: this.suppliername,
      ctrl: tctrl,
      firstName: this.firstname,
      lastName: this.lastname,
      email: this.email,
      phone: this.phone,
      defaultMarkup: parseFloat(this.defaultmarkup) || 0,  // ✅ Фикс ошибки
      description: this.descriptionstring,
      companyName: this.companyname,
      mobile: this.mobile,
      website: this.website,
      instagram: this.instagram,
      facebook: this.facebook,
      tikTok: this.tiktok,
      twitter: this.twitter,
      youTube: this.youtube,
      storeCountry: this.countrystring,
      mailingCountry: this.pcountrystring,
      mailingSameAsStore: this.usedifferent,
      mailingZipCode: this.pzipcode,
      mailingState: this.pstatesstring,
      mailingCity: this.pcity,
      mailingSuburb: this.psuburb,
      mailingStreetAddress2: this.paddress2,
      mailingStreetAddress: this.paddress,
      storeStreetAddress: this.address,
      storeStreetAddress2: this.address2,
      storeSuburb: this.suburb,
      storeCity: this.city,
      storeZipCode: this.zipcode,
      storeState: this.statesstring,
      accountid: this.getAccountID(),
      isactivity: true
    }).subscribe(response => {
      console.log('API Response:', response);
      this.modalService.suppliersFrameClose()
      this.notification.create(
        "success",
        'System Message',
        'Data saved successfully.',
        {nzDuration: 4000}
      );
      this.yourappsService.getReloadSupplierList([], 1)
    });
  }

  onOptionSelected(event: string): void {
    const countryId = this.getCountryIdByName(event);
    this.states = [];
    this.statesstring = "";
    if (countryId == 187) {
      this.http.get<any>('assets/us-states.json').subscribe((data) => {
        this.states = data;
      });
    }
  }

  getCountryIdByName(countryName: string): number | null {
    const country = this.countries.find((c) => c.country === countryName);
    return country ? country.id : null;
  }

  onOptionSelectedp(event: string) {
    const countryId = this.getCountryIdByName(event);
    this.pstates = [];
    this.statesstring = "";
    if (countryId == 187) {
      this.http.get<any>('assets/us-states.json').subscribe((data) => {
        this.pstates = data;
      });
    }
  }

  calculateTotal() {
    let total = 0;

    if (this.iProductsResult) {
      for (let customer of this.iProductsResult) {
        total += customer['Quantity'];
        // if (customer['ordertotal'] === name) {
        //   total++;
        // }
      }
    }

    return total;
  }

  calculateTotal1() {
    let total = 0;

    if (this.iProductsResult) {
      for (let customer of this.iProductsResult) {
        total += (customer['UnitPrice'] * customer['Quantity']);
        // if (customer['ordertotal'] === name) {
        //   total++;
        // }
      }
    }

    return total;
  }

  calculateTotal2() {
    let total = 0;

    if (this.iProductsResult) {
      for (let customer of this.iProductsResult) {
        total += customer['DiscountAmount'];
        // if (customer['ordertotal'] === name) {
        //   total++;
        // }
      }
    }

    return total;
  }

  protected readonly SelfData = SelfData;
}
