<p-toast *ngIf="!isDesktopDevice" position="top-center" key="tc" [style]="{ width: '90vw' }"></p-toast>
<p-toast *ngIf="isDesktopDevice" position="top-center" key="tc" [style]="{ width: '30vw' }"></p-toast>
<div *ngIf="SelfData?.lbcollections && SelfData.lbcollections.length > 0 && SelfData.lbcollections[0]?.data" class="bodydiv">
    <mat-card [ngClass]="deviceClass">
<!--      <div class="favorite">-->
<!--        <span class="custom-hover" id="myElement" matRipple (click)="onButtonFavoriteClick()">-->
<!--          Get notifications for the new Look Books from this designer-->
<!--        </span>-->
<!--      </div>-->
        <mat-card-content class="cards-container">
            <div *ngFor="let card of SelfData.lbcollections[0].data" class="inner-card">
                <mat-card [matBadge]="card.imagecount" class="subcard" (click)="onCollectionClick(card)">
                    <img class="image-size-80x80-dm" [src]="card.imagelink" [alt]="card.description">

                    <span class="collection">{{ card.description }}</span>
                </mat-card>
            </div>
        </mat-card-content>
        <div class="feshendiv">
            <div class="left-text-qr">
                <a href="http://www.fashion-m.com" target="_blank">fashion-m.com</a>
            </div>
        </div>
    </mat-card>
</div>

<app-favorite class="centered-messagebox" *ngIf="modalService.isfavoriteVisibled$ | async" @fadeInOut>
  <app-favoritedialog></app-favoritedialog>
</app-favorite>
