import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder} from "@angular/forms";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {ConfirmationService, ConfirmEventType, MessageService} from "primeng/api";
import {NgxImageCompressService} from "ngx-image-compress";
import { HttpClient } from "@angular/common/http";
import {DatePipe} from "@angular/common";
import {GetCCListData, GetlbSentListData, RequestSampleRequest, SelfData, SendOut1Data} from "../../models/struct";

@Component({
  selector: 'app-cclistdialog',
  templateUrl: './cclistdialog.component.html',
  styleUrl: './cclistdialog.component.scss'
})
export class CclistdialogComponent implements OnInit, AfterViewInit, OnDestroy {
  imageurl: string = ""
  datasource: GetCCListData[] = [];
  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private serviceservice: ServiceService,
    private yourappsService: YourappsserviceService,
    private cdr: ChangeDetectorRef,
    private messageService: MessageService,
    private imageCompressService: NgxImageCompressService,
    private confirmationService: ConfirmationService,
    private http: HttpClient,
    private datePipe: DatePipe,
    private el: ElementRef
  ) {

  }

  onButtonCloseClick() {
    this.modalService.closeCC()
  }

  formatDate(dateString: string): string {
    // Convert and format the date
    return <string>this.datePipe.transform(dateString, 'yyyy-MM-dd HH:mm');
  }

  ngAfterViewInit(): void {
    // this.imageurl = SelfData.cc?.[0]?.data?.[0]?.filedir;
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    this.imageurl = SelfData.cc?.[0]?.data?.[0]?.filedir;
    this.datasource = SelfData.cc?.[0].data
  }

  onButtonAcceptClick(catalog: any) {
    this.confirmationService.confirm({
      message: `<strong>Would you like to accept this Request?</strong>`,
      header: 'Information',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.yourappsService.selectAcceptedItems(catalog, 1)
        this.modalService.closeCC()
      },
      reject: (type: any) => {
        if (type == ConfirmEventType.REJECT) {

        } else if (type == ConfirmEventType.CANCEL) {

        }
      }
    })
  }
}
