import {AfterViewInit, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import {HashService} from "../../services/hash.service";
import {MessageboxComponent} from "../../modals/messagebox/messagebox.component";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SelfData} from "../../models/struct";

// @Component({
//   selector: 'app-printerframedialog',
//   templateUrl: './printerframedialog.component.html',
//   styleUrls: ['./printerframedialog.component.scss']
// })

@Component({
  selector: 'app-printerframedialog',
  template: `
    <app-deliverymemo *ngIf="printertype === 0"></app-deliverymemo>
    <app-proformainvoice *ngIf="printertype === 1"></app-proformainvoice>
    <app-addresslabel *ngIf="printertype === 2"></app-addresslabel>
    <app-barcodeprint *ngIf="printertype === 3"></app-barcodeprint>
    <app-barcodeprintsingle *ngIf="printertype === 4"></app-barcodeprintsingle>
    <app-pullsheetprinter *ngIf="printertype === 5"></app-pullsheetprinter>
    <!-- ... and so on for each type -->
  `,
})
export class PrinterframedialogComponent implements OnInit, AfterViewInit {
  printertype: number = 0;
  myForm: FormGroup = new FormGroup({});
  constructor(
      public modalService: ModalserviceService,
      public fb: FormBuilder,
      private hashService: HashService,
      public messageBox: MessageboxComponent,
      private serviceservice: ServiceService,
      private yourappsService: YourappsserviceService,
      private _snackBar: MatSnackBar,
      private cd: ChangeDetectorRef
  ) {
    this.printertype = SelfData.selectadpage1
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
  }

  submit() {

  }

}
