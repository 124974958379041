<p-toast *ngIf="!isDesktopDevice" position="top-center" key="tc" [style]="{ width: '90vw' }"></p-toast>
<p-toast *ngIf="isDesktopDevice" position="top-center" key="tc" [style]="{ width: '30vw' }"></p-toast>
<div class="bodydiv">
    <mat-card [ngClass]="deviceClass">
        <mat-card-content class="cards-container">
            <smart-carousel *ngIf="!isDesktopDevice"  theme="fm" class="carousel bottom-top" #carousel [class.mobile]="true" [autoPlay]="true" [slideShow]="false" [loop]="true" [hideIndicators]="false"
                    [keyboard]="false" [displayMode]="'3d'" [interval]="10000"></smart-carousel>
            <smart-carousel *ngIf="isDesktopDevice"  theme="fm" class="carousel bottom-top" #carousel [class.imageselect]="true" [autoPlay]="true" [slideShow]="false" [loop]="true" [hideIndicators]="false"
                    [keyboard]="false" [displayMode]="'3d'" [interval]="10000"></smart-carousel>

            <div *ngIf="!isDesktopDevice" class="button-container-left">
                <mat-checkbox (change)="onCheckboxChange($event)" [class.onlyimageview]="true" [(ngModel)]="isChecked" [disabled]="isDisabled">
                    {{ checkboxcaption }}
                </mat-checkbox>
            </div>

            <div *ngIf="isDesktopDevice" class="checkbox">
                <mat-checkbox (change)="onCheckboxChange($event)" [class.onlyimageview]="true" [(ngModel)]="isChecked" [disabled]="isDisabled">
                    {{ checkboxcaption }}
                </mat-checkbox>
            </div>

            <div *ngIf="isDesktopDevice" [class.imageselect]="true" class="button-container">
                <button mat-raised-button [matBadge]="selectedImages.length" [matBadgeHidden]="hidden" [matMenuTriggerFor]="menu" class="button-settings" type="button">
                    Confirm
                </button>
            </div>

            <div *ngIf="!isDesktopDevice" [class.mobile]="true" class="button-container">
                <button mat-raised-button [matBadge]="selectedImages.length" [matBadgeHidden]="hidden" [matMenuTriggerFor]="menu" class="button-settings" type="button">
                    Confirm
                </button>
            </div>

            <!--            <span class="button-container-left">Total selected ({{ selectedImages.length }})</span>-->
            <!--            <smart-carousel #carousel [class.mobile]="true" id="carouselSquare" [keyboard]="true" [slideShow]="false" [autoPlay]="true" [loop]="true"></smart-carousel>-->
        </mat-card-content>
        <div class="feshendiv">
            <div class="left-text-qr">
                <a href="http://www.fashion-m.com" target="_blank">fashion-m.com</a>
            </div>
        </div>
    </mat-card>
</div>

<p-confirmDialog *ngIf="isDesktopDevice" #cd [style]="{width: '25vw'}" [closable]="true">
    <ng-template pTemplate="footer">
        <div class="button-container-rr">
            <button mat-raised-button class="button-secondary" style="margin-right: 10px; width: 10%" (click)="cd.reject()">
                No
            </button>
            <button mat-raised-button class="button-primary" style="width: 10%" (click)="cd.accept()">
                Yes
            </button>
        </div>
    </ng-template>
</p-confirmDialog>

<p-confirmDialog *ngIf="!isDesktopDevice" #cd [style]="{width: '90vw'}" [closable]="true">
    <ng-template pTemplate="footer">
        <div class="button-container-rr">
            <button mat-raised-button class="button-secondary" style="margin-right: 10px; width: 10%" (click)="cd.reject()">
                No
            </button>
            <button mat-raised-button class="button-primary" style="width: 10%" (click)="cd.accept()">
                Yes
            </button>
        </div>
    </ng-template>
</p-confirmDialog>

<mat-menu #menu="matMenu">
    <button mat-menu-item (click)="onButtonUploadClick()">
        <span>Confirm</span>
    </button>
    <button mat-menu-item (click)="onButtonViewClick()">
        <span>View</span>
    </button>
</mat-menu>
