import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IdleService {
  private idle$ = new Subject<boolean>();
  private idleTimeout: any;
  private timeoutDuration = 600000; // 10 минут

  constructor() {
    this.setupIdleDetection();
  }

  private setupIdleDetection() {
    const events = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];

    events.forEach(event => {
      document.addEventListener(event, () => this.resetTimer());
    });

    this.resetTimer();
  }

  private resetTimer() {
    clearTimeout(this.idleTimeout);
    this.idle$.next(false);

    this.idleTimeout = setTimeout(() => {
      this.idle$.next(true);
    }, this.timeoutDuration);
  }

  getIdleStatus() {
    return this.idle$.asObservable();
  }
}
