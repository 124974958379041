import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TopPanelComponent} from './top/top-panel/top-panel.component';
import {MainFrameComponent} from './main/main-frame/main-frame.component';
import {MatCardModule} from '@angular/material/card';
import {MainMenuComponent} from './top/main-menu/main-menu.component';
import {MatMenuModule} from '@angular/material/menu';
import {ClrDropdownModule} from '@clr/angular';
import {MainTabsComponent} from './top/main-tabs/main-tabs.component';
import {MatTabsModule} from '@angular/material/tabs';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatIconModule} from '@angular/material/icon';
import {YourappsComponent} from './pages/yourapps/yourapps.component';
import {MatDividerModule} from '@angular/material/divider';
import {MatButtonModule} from '@angular/material/button';
import {WhatsnewComponent} from './pages/whatsnew/whatsnew.component';
import {SupportComponent} from './pages/support/support.component';
import {MatInputModule} from '@angular/material/input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LoginComponent} from './modals/login/login.component';
import {LogindialogComponent} from './dialogs/logindialog/logindialog.component';
import {RegistrationComponent} from './modals/registration/registration.component';
import {RegistrationdialogComponent} from './dialogs/registrationdialog/registrationdialog.component';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {MessageboxComponent} from './modals/messagebox/messagebox.component';
import {MessageboxdialogComponent} from './dialogs/messageboxdialog/messageboxdialog.component';
import {ProgrammsComponent} from './toolbar/programms/programms.component';
import {ProfileComponent} from './modals/profile/profile.component';
import {ProfiledialogComponent} from './dialogs/profiledialog/profiledialog.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {RegistrationframeComponent} from './main/registrationframe/registrationframe.component';
import {EditprofileComponent} from './modals/editprofile/editprofile.component';
import {EditprofiledialogComponent} from './dialogs/editprofiledialog/editprofiledialog.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {CodeComponent} from './modals/code/code.component';
import {CodedialogComponent} from './dialogs/codedialog/codedialog.component';
import {SamplesComponent} from './pages/samples/samples.component';
import {SamplemanagerComponent} from './pages/samplemanager/samplemanager.component';
import {CreatesampleComponent} from './modals/createsample/createsample.component';
import {CreatesampledialogComponent} from './dialogs/createsampledialog/createsampledialog.component';
import {SwsComponent} from './modals/sws/sws.component';
import {SwsdialogComponent} from './dialogs/swsdialog/swsdialog.component';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {AddeditframeComponent} from './modals/addeditframe/addeditframe.component';
import {AddeditframedialogComponent} from './dialogs/addeditframedialog/addeditframedialog.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {CollectionComponent} from './modals/collection/collection.component';
import {CollectiondialogComponent} from './dialogs/collectiondialog/collectiondialog.component';
import {SeasonComponent} from './modals/season/season.component';
import {SeasondialogComponent} from './dialogs/seasondialog/seasondialog.component';
import {ImagezoomComponent} from './modals/imagezoom/imagezoom.component';
import {ImagezoomdialogComponent} from './dialogs/imagezoomdialog/imagezoomdialog.component';
import {SettingsComponent} from './modals/settings/settings.component';
import {SettingsdialogComponent} from './dialogs/settingsdialog/settingsdialog.component';
import {SamplefieldsdialogComponent} from './dialogs/samplefieldsdialog/samplefieldsdialog.component';
import {FieldseditorComponent} from './modals/fieldseditor/fieldseditor.component';
import {FieldseditordialogComponent} from './dialogs/fieldseditordialog/fieldseditordialog.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {SamplelistheaderComponent} from './pages/samplelistheader/samplelistheader.component';
import {SamplelisttableComponent} from './pages/samplelisttable/samplelisttable.component';
import {SamplelistgridComponent} from './pages/samplelistgrid/samplelistgrid.component';
import {QRCodeModule} from 'angularx-qrcode';
import {CreatecontactsComponent} from './modals/createcontacts/createcontacts.component';
import {CreatecontactsdialogComponent} from './dialogs/createcontactsdialog/createcontactsdialog.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatRadioModule} from '@angular/material/radio';
import {CreatecompanyComponent} from './modals/createcompany/createcompany.component';
import {CreatecompanydialogComponent} from './dialogs/createcompanydialog/createcompanydialog.component';
import {ContactfieldsdialogComponent} from './dialogs/contactfieldsdialog/contactfieldsdialog.component';
import {AddressComponent} from './modals/address/address.component';
import {AddressdialogComponent} from './dialogs/addressdialog/addressdialog.component';
import {NotificationsettingsComponent} from './modals/notificationsettings/notificationsettings.component';
import {
  NotificationsettingsdialogComponent
} from './dialogs/notificationsettingsdialog/notificationsettingsdialog.component';
import {CreatesendoutComponent} from './modals/createsendout/createsendout.component';
import {CreatesendoutdialogComponent} from './dialogs/createsendoutdialog/createsendoutdialog.component';
import {MatStepperModule} from '@angular/material/stepper';
import {Sendoutstep1Component} from './pages/sendoutstep1/sendoutstep1.component';
import {MatDatetimepickerModule} from '@mat-datetimepicker/core';
import {MatDialogModule} from '@angular/material/dialog';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatMomentDatetimeModule} from '@mat-datetimepicker/moment';
import {DragdropComponent} from './modals/dragdrop/dragdrop.component';
import {DragdropdialogComponent} from './dialogs/dragdropdialog/dragdropdialog.component';
import {CdkDrag, CdkDragPreview, CdkDropList, CdkDropListGroup} from '@angular/cdk/drag-drop';
import {EditlabelComponent} from './modals/editlabel/editlabel.component';
import {EditlabeldialogComponent} from './dialogs/editlabeldialog/editlabeldialog.component';
import {Sendoutstep2Component} from './pages/sendoutstep2/sendoutstep2.component';
import {BarcodeComponent} from './barcode/barcode.component';
import {SelectedsampleComponent} from './modals/selectedsample/selectedsample.component';
import {SelectedsampledialogComponent} from './dialogs/selectedsampledialog/selectedsampledialog.component';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ToastModule} from 'primeng/toast';
import {RippleModule} from 'primeng/ripple';
import {ButtonModule} from 'primeng/button';
import {InventaryComponent} from './modals/inventary/inventary.component';
import {InventarydialogComponent} from './dialogs/inventarydialog/inventarydialog.component';
import {InvenstorylisttableComponent} from './pages/invenstorylisttable/invenstorylisttable.component';
import {CreatelookComponent} from './modals/createlook/createlook.component';
import {CreatelookdialogComponent} from './dialogs/createlookdialog/createlookdialog.component';
import {LooklistComponent} from './pages/looklist/looklist.component';
import {LookComponent} from './modals/look/look.component';
import {LookdialogComponent} from './dialogs/lookdialog/lookdialog.component';
import {ContactslisttableComponent} from './pages/contactslisttable/contactslisttable.component';
import {OverlayPanelModule} from "primeng/overlaypanel";
import {FormatCountPipe} from './services/format-count.pipe';
import {InvoiceComponent} from './modals/invoice/invoice.component';
import {InvoicedialogComponent} from './dialogs/invoicedialog/invoicedialog.component';
import {Sendoutstep3Component} from './pages/sendoutstep3/sendoutstep3.component';
import {DeleteframeComponent} from './modals/deleteframe/deleteframe.component';
import {DeleteframedialogComponent} from './dialogs/deleteframedialog/deleteframedialog.component';
import {PrinterframeComponent} from './modals/printerframe/printerframe.component';
import {PrinterframedialogComponent} from './dialogs/printerframedialog/printerframedialog.component';
import {DeliverymemoComponent} from './printer/deliverymemo/deliverymemo.component';
import {ProformainvoiceComponent} from './printer/proformainvoice/proformainvoice.component';
import {AddresslabelComponent} from './printer/addresslabel/addresslabel.component';
import {BarcodeprintComponent} from './printer/barcodeprint/barcodeprint.component';
import {EditorComponent} from "./modals/editor/editor.component";
import {EditordialogComponent} from "./dialogs/editordialog/editordialog.component";
import {EmailframeComponent} from './modals/emailframe/emailframe.component';
import {EmailframedialogComponent} from './dialogs/emailframedialog/emailframedialog.component';
import {BarcodeprintsingleComponent} from './printer/barcodeprintsingle/barcodeprintsingle.component';
import {SendoutlistComponent} from './pages/sendoutlist/sendoutlist.component';
import {TableModule} from "primeng/table";
import {SelectButtonModule} from "primeng/selectbutton";
import {ReturnreciverComponent} from './modals/returnreciver/returnreciver.component';
import {ReturnreciverdialogComponent} from './dialogs/returnreciverdialog/returnreciverdialog.component';
import {InputTextModule} from "primeng/inputtext";
import {RrcommentComponent} from './modals/rrcomment/rrcomment.component';
import {RrcommentdialogComponent} from './dialogs/rrcommentdialog/rrcommentdialog.component';
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {CustomCurrencyPipe} from './custom-currency.pipe';
import {CurrencyPipe, DatePipe} from "@angular/common";
import {PullsheetComponent} from './pages/pullsheet/pullsheet.component';
import {CalendarModule} from "primeng/calendar";
import {InputNumberModule} from "primeng/inputnumber";
import {SignatureframeComponent} from './modals/signatureframe/signatureframe.component';
import {SignatureframedialogComponent} from './dialogs/signatureframedialog/signatureframedialog.component';
import {PullsheettemplateComponent} from './modals/pullsheettemplate/pullsheettemplate.component';
import {PullsheettemplatedialogComponent} from './dialogs/pullsheettemplatedialog/pullsheettemplatedialog.component';
import {PullsheetprinterComponent} from './printer/pullsheetprinter/pullsheetprinter.component';
import {CreatepullsheetComponent} from './modals/createpullsheet/createpullsheet.component';
import {CreatepullsheetdialogComponent} from './dialogs/createpullsheetdialog/createpullsheetdialog.component';
import {SelectsendoutComponent} from './modals/selectsendout/selectsendout.component';
import {SelectsendoutdialogComponent} from './dialogs/selectsendoutdialog/selectsendoutdialog.component';
import {AcceptedpullsheetComponent} from './pages/acceptedpullsheet/acceptedpullsheet.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {SendoutsamplelistComponent} from './modals/sendoutsamplelist/sendoutsamplelist.component';
import {SendoutsamplelistdialogComponent} from './dialogs/sendoutsamplelistdialog/sendoutsamplelistdialog.component';
import {SelectpullsheetComponent} from './pages/selectpullsheet/selectpullsheet.component';
import {Sendoutsamplelist1Component} from './modals/sendoutsamplelist1/sendoutsamplelist1.component';
import {Sendoutsamplelistdialog1Component} from './dialogs/sendoutsamplelistdialog1/sendoutsamplelistdialog1.component';
import {QrcodescanComponent} from './pages/qrcodescan/qrcodescan.component';
import {CelebritiframeComponent} from './modals/celebritiframe/celebritiframe.component';
import {CelebritiframedialogComponent} from './dialogs/celebritiframedialog/celebritiframedialog.component';
import {CreaterequestComponent} from './modals/createrequest/createrequest.component';
import {CreaterequestdialogComponent} from './dialogs/createrequestdialog/createrequestdialog.component';
import {CreatevasiaComponent} from './modals/createvasia/createvasia.component';
import {CreatevasiadialogComponent} from './dialogs/createvasiadialog/createvasiadialog.component';
import {CreatevasiaframeComponent} from './modals/createvasiaframe/createvasiaframe.component';
import {CreatevasiaframedialogComponent} from './dialogs/createvasiaframedialog/createvasiaframedialog.component';
import {ProgressBarModule} from "smart-webcomponents-angular/progressbar";
import {CreatelbdialogComponent} from './dialogs/createlbdialog/createlbdialog.component';
import {CreatelbComponent} from './modals/createlb/createlb.component';
import {CreatelbframeComponent} from './modals/createlbframe/createlbframe.component';
import {CreatelbframedialogComponent} from './dialogs/createlbframedialog/createlbframedialog.component';
import {CreateimageviewsComponent} from './modals/createimageviews/createimageviews.component';
import {CreateimageviewsdialogComponent} from './dialogs/createimageviewsdialog/createimageviewsdialog.component';
import {CarouselModule} from "smart-webcomponents-angular/carousel";
import {GalleriaModule} from 'primeng/galleria';
import {CreatelsbComponent} from './modals/createlsb/createlsb.component';
import {CreatelsbdialogComponent} from './dialogs/createlsbdialog/createlsbdialog.component';
import {CreatelsbframeComponent} from './modals/createlsbframe/createlsbframe.component';
import {CreatelsbframedialogComponent} from './dialogs/createlsbframedialog/createlsbframedialog.component';
import {CreateciComponent} from './modals/createci/createci.component';
import {CreatecidialogComponent} from './dialogs/createcidialog/createcidialog.component';
import {CreatevcimagesComponent} from './modals/createvcimages/createvcimages.component';
import {CreatevcimagesdialogComponent} from './dialogs/createvcimagesdialog/createvcimagesdialog.component';
import {LookbookComponent} from './pages/lookbook/lookbook.component';
import {CatalogsComponent} from './pages/catalogs/catalogs.component';
import {CardModule} from "primeng/card";
import {CardViewModule} from "smart-webcomponents-angular/cardview";
import {ImageviewsComponent} from './pages/imageviews/imageviews.component';
import {MatBadgeModule} from "@angular/material/badge";
import {AcceptedimagesComponent} from './pages/acceptedimages/acceptedimages.component';
import {FavoriteComponent} from './modals/favorite/favorite.component';
import {FavoritedialogComponent} from './dialogs/favoritedialog/favoritedialog.component';
import {CatalogsdialogComponent} from './dialogs/catalogsdialog/catalogsdialog.component';
import {CatalogslistComponent} from './modals/catalogslist/catalogslist.component';
import {Catalogslist1Component} from './modals/catalogslist1/catalogslist1.component';
import {Catalogs1dialogComponent} from './dialogs/catalogs1dialog/catalogs1dialog.component';
import {NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer';
import {ImageviewsacceptedComponent} from './pages/imageviewsaccepted/imageviewsaccepted.component';
import {Createimageviews1Component} from './modals/createimageviews1/createimageviews1.component';
import {Createimageviews1dialogComponent} from './dialogs/createimageviews1dialog/createimageviews1dialog.component';
import {Createvcimages1Component} from './modals/createvcimages1/createvcimages1.component';
import {Createvcimages1dialogComponent} from './dialogs/createvcimages1dialog/createvcimages1dialog.component';
import {Createlsb1Component} from './modals/createlsb1/createlsb1.component';
import {Createlsb1dialogComponent} from './dialogs/createlsb1dialog/createlsb1dialog.component';
import {CreateviComponent} from './modals/createvi/createvi.component';
import {CreatevidialogComponent} from './dialogs/createvidialog/createvidialog.component';
import {RequestlistComponent} from './pages/requestlist/requestlist.component';
import {CreatesrComponent} from './modals/createsr/createsr.component';
import {CreatesrdialogComponent} from './dialogs/createsrdialog/createsrdialog.component';
import {PrintrequestlistComponent} from './pages/printrequestlist/printrequestlist.component';
import {LatelistComponent} from './pages/latelist/latelist.component';
import {AiframeComponent} from './modals/aiframe/aiframe.component';
import {AiframedialogComponent} from './dialogs/aiframedialog/aiframedialog.component';
import {AichoosComponent} from './modals/aichoos/aichoos.component';
import {AichoosdialogComponent} from './dialogs/aichoosdialog/aichoosdialog.component';
import {MatFormFieldModule} from "@angular/material/form-field";
import {EditorModule} from "primeng/editor";
import {CdllistComponent} from './pages/cdllist/cdllist.component';
import {CclistComponent} from './modals/cclist/cclist.component';
import {CclistdialogComponent} from './dialogs/cclistdialog/cclistdialog.component';
import {FilterpanelComponent} from './pages/filterpanel/filterpanel.component';
import {SprpanelComponent} from './reporting/sprpanel/sprpanel.component';
import {NgxDaterangepickerMd} from "ngx-daterangepicker-material";
import {RadioButtonModule} from "primeng/radiobutton";
import {SplitButtonModule} from "primeng/splitbutton";
import {MatSliderModule} from "@angular/material/slider";
import {FullsendoutComponent} from './modals/fullsendout/fullsendout.component';
import {FullsendoutdialogComponent} from './dialogs/fullsendoutdialog/fullsendoutdialog.component';
import {FullrequestComponent} from './modals/fullrequest/fullrequest.component';
import {FullrequestdialogComponent} from './dialogs/fullrequestdialog/fullrequestdialog.component';
import {CustomMaskDirective} from './custom-mask.directive';
import {ScannerformComponent} from './modals/scannerform/scannerform.component';
import {ScannerformdialogComponent} from './dialogs/scannerformdialog/scannerformdialog.component';
import {PaginatorModule} from "primeng/paginator";
import {CdkContextMenuTrigger, CdkMenu, CdkMenuItem} from "@angular/cdk/menu";
import {en_US, NZ_I18N} from "ng-zorro-antd/i18n";
import {NzInputDirective, NzInputGroupComponent} from "ng-zorro-antd/input";
import {NzButtonComponent} from "ng-zorro-antd/button";
import {NzIconModule, NZ_ICONS, NzIconService} from 'ng-zorro-antd/icon';
import {EyeInvisibleOutline, SyncOutline, UserOutline} from '@ant-design/icons-angular/icons';
import {NzOptionComponent, NzSelectComponent} from "ng-zorro-antd/select";
import {NzInputNumberComponent} from "ng-zorro-antd/input-number";
import {NzDatePickerComponent} from "ng-zorro-antd/date-picker";
import {NzDatePickerModule} from "ng-zorro-antd/date-picker";
import {NzDropDownDirective, NzDropdownMenuComponent} from "ng-zorro-antd/dropdown";
import {NzMenuDirective, NzMenuItemComponent, NzSubMenuComponent} from "ng-zorro-antd/menu";
import {NzSpaceItemDirective} from "ng-zorro-antd/space";
import {SendoutpendinglistComponent} from './pages/sendoutpendinglist/sendoutpendinglist.component';
import {EmailEditorModule} from "angular-email-editor";
import { EmaileditorComponent } from './modals/emaileditor/emaileditor.component';
import { EmaileditordialogComponent } from './dialogs/emaileditordialog/emaileditordialog.component';
import {NzTabComponent, NzTabSetComponent} from "ng-zorro-antd/tabs";
import { EmailcontactsComponent } from './modals/emailcontacts/emailcontacts.component';
import { EmailcontactsdialogComponent } from './dialogs/emailcontactsdialog/emailcontactsdialog.component';
import { EmailaddComponent } from './modals/emailadd/emailadd.component';
import { EmailadddialogComponent } from './dialogs/emailadddialog/emailadddialog.component';
import { EmailsignComponent } from './modals/emailsign/emailsign.component';
import { EmailsigndialogComponent } from './dialogs/emailsigndialog/emailsigndialog.component';
import {NzTagComponent} from "ng-zorro-antd/tag";
import {NzNoAnimationDirective} from "ng-zorro-antd/core/no-animation";
import {NzDividerComponent} from "ng-zorro-antd/divider";
import { ContactemaillistComponent } from './pages/contactemaillist/contactemaillist.component';
import {NzProgressComponent} from "ng-zorro-antd/progress";
import { EmaildetailsviewComponent } from './modals/emaildetailsview/emaildetailsview.component';
import { EmaildetailsviewdialogComponent } from './dialogs/emaildetailsviewdialog/emaildetailsviewdialog.component';
import { ComingsoonComponent } from './modals/comingsoon/comingsoon.component';
import { ComingsoondialogComponent } from './dialogs/comingsoondialog/comingsoondialog.component';
import { SopanelComponent } from './reporting/sopanel/sopanel.component';
import {NzCheckboxComponent} from "ng-zorro-antd/checkbox";
import { ShipdateComponent } from './modals/shipdate/shipdate.component';
import { ShipdatedialogComponent } from './dialogs/shipdatedialog/shipdatedialog.component';
import {ScheduleComponent} from "./modals/schedule/schedule.component";
import {ScheduledialogComponent} from "./dialogs/scheduledialog/scheduledialog.component";
import { SaleComponent } from './pages/sale/sale.component';
import { SaleMainMenuComponent } from './top/sale-main-menu/sale-main-menu.component';
import { CreatesampleSaleComponent } from './modals/createsample-sale/createsample-sale.component';
import { CreatesampledialogsSaleComponent } from './dialogs/createsampledialogs-sale/createsampledialogs-sale.component';
import {DividerModule} from "primeng/divider";
import { TopPanelSaleComponent } from './top/top-panel-sale/top-panel-sale.component';
import { MainmenuComponent } from './layout/mainmenu/mainmenu.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import {NzTooltipDirective} from "ng-zorro-antd/tooltip";
import { HomeComponent } from './pages/home/home.component';
import { SetupgeneralComponent } from './pages/setupgeneral/setupgeneral.component';
import {NzRadioComponent, NzRadioGroupComponent} from "ng-zorro-antd/radio";
import { OutletsComponent } from './pages/outlets/outlets.component';
import { TestprinterComponent } from './modals/testprinter/testprinter.component';
import { TestprinterdialogComponent } from './dialogs/testprinterdialog/testprinterdialog.component';
import { OrComponent } from './modals/or/or.component';
import { OrdialogComponent } from './dialogs/ordialog/ordialog.component';
import {NzStepComponent, NzStepsComponent} from "ng-zorro-antd/steps";
import { TaxesComponent } from './modals/taxes/taxes.component';
import { TaxesdialogComponent } from './dialogs/taxesdialog/taxesdialog.component';
import {CustomersComponent} from "./pages/customers/customers.component";
import { CustomersframeComponent } from './modals/customersframe/customersframe.component';
import { CustomersframedialogComponent } from './dialogs/customersframedialog/customersframedialog.component';
import {NzSwitchComponent} from "ng-zorro-antd/switch";
import { SuppliersComponent } from './pages/suppliers/suppliers.component';
import { SupplierframeComponent } from './modals/supplierframe/supplierframe.component';
import { SupplierframedialogComponent } from './dialogs/supplierframedialog/supplierframedialog.component';
import { ProductsComponent } from './pages/products/products.component';
import { ProductframeComponent } from './modals/productframe/productframe.component';
import { ProductframedialogComponent } from './dialogs/productframedialog/productframedialog.component';
import {NzSpinComponent} from "ng-zorro-antd/spin";
import { ScComponent } from './pages/sc/sc.component';
import { ScframeComponent } from './modals/scframe/scframe.component';
import { ScframedialogComponent } from './dialogs/scframedialog/scframedialog.component';
import { SsComponent } from './pages/ss/ss.component';
import { SsframeComponent } from './modals/ssframe/ssframe.component';
import { SsframedialogComponent } from './dialogs/ssframedialog/ssframedialog.component';
import {NzColDirective, NzRowDirective} from "ng-zorro-antd/grid";
import { SrframeComponent } from './modals/srframe/srframe.component';
import { SrframedialogComponent } from './dialogs/srframedialog/srframedialog.component';
import {
  SoundOutline,
  CaretRightOutline,
  PlayCircleOutline,
  PauseOutline,
  StopOutline,
  BorderOuterOutline,
  RightOutline,
  FileMarkdownOutline,
  ReloadOutline,  // Добавляем reload
} from '@ant-design/icons-angular/icons';
import { AesComponent } from './modals/aes/aes.component';
import { AesdialogComponent } from './dialogs/aesdialog/aesdialog.component';
import { SalesreportComponent } from './pages/salesreport/salesreport.component';
import { UserslistComponent } from './pages/userslist/userslist.component';
import { UserComponent } from './modals/user/user.component';
import { UserdialogComponent } from './dialogs/userdialog/userdialog.component';
import { ProfilelistComponent } from './modals/profilelist/profilelist.component';
import { ProfilelistdialogComponent } from './dialogs/profilelistdialog/profilelistdialog.component';
import { ProfileframeComponent } from './modals/profileframe/profileframe.component';
import { ProfileframedialogComponent } from './dialogs/profileframedialog/profileframedialog.component';
import {NzTreeComponent} from "ng-zorro-antd/tree";
import {
  NzAutocompleteComponent,
  NzAutocompleteOptionComponent,
  NzAutocompleteTriggerDirective
} from "ng-zorro-antd/auto-complete";
import { SendoutreportComponent } from './pages/sendoutreport/sendoutreport.component';

const icons = [EyeInvisibleOutline, UserOutline, SoundOutline,
  CaretRightOutline,
  PlayCircleOutline,
  PauseOutline,
  StopOutline,
  RightOutline,
  BorderOuterOutline,
  FileMarkdownOutline,
  ReloadOutline];

@NgModule({
  declarations: [
    AppComponent,
    TopPanelComponent,
    MainFrameComponent,
    MainMenuComponent,
    MainTabsComponent,
    YourappsComponent,
    WhatsnewComponent,
    SupportComponent,
    LoginComponent,
    LogindialogComponent,
    RegistrationComponent,
    RegistrationdialogComponent,
    MessageboxComponent,
    MessageboxdialogComponent,
    ProgrammsComponent,
    ProfileComponent,
    ProfiledialogComponent,
    RegistrationframeComponent,
    EditprofileComponent,
    EditprofiledialogComponent,
    CodeComponent,
    CodedialogComponent,
    SamplesComponent,
    SamplemanagerComponent,
    CreatesampleComponent,
    CreatesampledialogComponent,
    SwsComponent,
    SwsdialogComponent,
    AddeditframeComponent,
    AddeditframedialogComponent,
    CollectionComponent,
    CollectiondialogComponent,
    SeasonComponent,
    SeasondialogComponent,
    ImagezoomComponent,
    ImagezoomdialogComponent,
    SettingsComponent,
    SettingsdialogComponent,
    SamplefieldsdialogComponent,
    FieldseditorComponent,
    FieldseditordialogComponent,
    SamplelistheaderComponent,
    SamplelisttableComponent,
    SamplelistgridComponent,
    CreatecontactsComponent,
    CreatecontactsdialogComponent,
    CreatecompanyComponent,
    CreatecompanydialogComponent,
    ContactfieldsdialogComponent,
    AddressComponent,
    AddressdialogComponent,
    NotificationsettingsComponent,
    NotificationsettingsdialogComponent,
    CreatesendoutComponent,
    CreatesendoutdialogComponent,
    Sendoutstep1Component,
    DragdropComponent,
    DragdropdialogComponent,
    EditlabelComponent,
    EditlabeldialogComponent,
    Sendoutstep2Component,
    BarcodeComponent,
    SelectedsampleComponent,
    SelectedsampledialogComponent,
    InventaryComponent,
    InventarydialogComponent,
    InvenstorylisttableComponent,
    CreatelookComponent,
    CreatelookdialogComponent,
    LooklistComponent,
    LookComponent,
    LookdialogComponent,
    ContactslisttableComponent,
    FormatCountPipe,
    InvoiceComponent,
    InvoicedialogComponent,
    Sendoutstep3Component,
    DeleteframeComponent,
    DeleteframedialogComponent,
    PrinterframeComponent,
    PrinterframedialogComponent,
    DeliverymemoComponent,
    ProformainvoiceComponent,
    AddresslabelComponent,
    BarcodeprintComponent,
    EditorComponent,
    EditordialogComponent,
    EmailframeComponent,
    EmailframedialogComponent,
    BarcodeprintsingleComponent,
    SendoutlistComponent,
    ReturnreciverComponent,
    ReturnreciverdialogComponent,
    RrcommentComponent,
    RrcommentdialogComponent,
    CustomCurrencyPipe,
    PullsheetComponent,
    SignatureframeComponent,
    SignatureframedialogComponent,
    PullsheettemplateComponent,
    PullsheettemplatedialogComponent,
    PullsheetprinterComponent,
    CreatepullsheetComponent,
    CreatepullsheetdialogComponent,
    SelectsendoutComponent,
    SelectsendoutdialogComponent,
    AcceptedpullsheetComponent,
    SendoutsamplelistComponent,
    SendoutsamplelistdialogComponent,
    SelectpullsheetComponent,
    Sendoutsamplelist1Component,
    Sendoutsamplelistdialog1Component,
    QrcodescanComponent,
    CelebritiframeComponent,
    CelebritiframedialogComponent,
    CreaterequestComponent,
    CreaterequestdialogComponent,
    CreatevasiaComponent,
    CreatevasiadialogComponent,
    CreatevasiaframeComponent,
    CreatevasiaframedialogComponent,
    CreatelbdialogComponent,
    CreatelbComponent,
    CreatelbframeComponent,
    CreatelbframedialogComponent,
    CreateimageviewsComponent,
    CreateimageviewsdialogComponent,
    CreatelsbComponent,
    CreatelsbdialogComponent,
    CreatelsbframeComponent,
    CreatelsbframedialogComponent,
    CreateciComponent,
    CreatecidialogComponent,
    CreatevcimagesComponent,
    CreatevcimagesdialogComponent,
    LookbookComponent,
    CatalogsComponent,
    ImageviewsComponent,
    AcceptedimagesComponent,
    FavoriteComponent,
    FavoritedialogComponent,
    CatalogsdialogComponent,
    CatalogslistComponent,
    Catalogslist1Component,
    Catalogs1dialogComponent,
    ImageviewsacceptedComponent,
    Createimageviews1Component,
    Createimageviews1dialogComponent,
    Createvcimages1Component,
    Createvcimages1dialogComponent,
    Createlsb1Component,
    Createlsb1dialogComponent,
    CreateviComponent,
    CreatevidialogComponent,
    RequestlistComponent,
    CreatesrComponent,
    CreatesrdialogComponent,
    PrintrequestlistComponent,
    LatelistComponent,
    AiframeComponent,
    AiframedialogComponent,
    AichoosComponent,
    AichoosdialogComponent,
    CdllistComponent,
    CclistComponent,
    CclistdialogComponent,
    FilterpanelComponent,
    SprpanelComponent,
    FullsendoutComponent,
    FullsendoutdialogComponent,
    FullrequestComponent,
    FullrequestdialogComponent,
    CustomMaskDirective,
    ScannerformComponent,
    ScannerformdialogComponent,
    SendoutpendinglistComponent,
    EmaileditorComponent,
    EmaileditordialogComponent,
    EmailcontactsComponent,
    EmailcontactsdialogComponent,
    EmailaddComponent,
    EmailadddialogComponent,
    EmailsignComponent,
    EmailsigndialogComponent,
    ContactemaillistComponent,
    EmaildetailsviewComponent,
    EmaildetailsviewdialogComponent,
    ComingsoonComponent,
    ComingsoondialogComponent,
    SopanelComponent,
    ShipdateComponent,
    ShipdatedialogComponent,
    ScheduleComponent,
    ScheduledialogComponent,
    SaleComponent,
    SaleMainMenuComponent,
    CreatesampleSaleComponent,
    CreatesampledialogsSaleComponent,
    TopPanelSaleComponent,
    MainmenuComponent,
    SidebarComponent,
    HomeComponent,
    SetupgeneralComponent,
    OutletsComponent,
    TestprinterComponent,
    TestprinterdialogComponent,
    OrComponent,
    OrdialogComponent,
    TaxesComponent,
    TaxesdialogComponent,
    CustomersComponent,
    CustomersframeComponent,
    CustomersframedialogComponent,
    SuppliersComponent,
    SupplierframeComponent,
    SupplierframedialogComponent,
    ProductsComponent,
    ProductframeComponent,
    ProductframedialogComponent,
    ScComponent,
    ScframeComponent,
    ScframedialogComponent,
    SsComponent,
    SsframeComponent,
    SsframedialogComponent,
    SrframeComponent,
    SrframedialogComponent,
    AesComponent,
    AesdialogComponent,
    SalesreportComponent,
    UserslistComponent,
    UserComponent,
    UserdialogComponent,
    ProfilelistComponent,
    ProfilelistdialogComponent,
    ProfileframeComponent,
    ProfileframedialogComponent,
    SendoutreportComponent,
  ],
  bootstrap: [AppComponent],
  imports: [BrowserModule,
    NzIconModule.forChild(icons),
    AppRoutingModule,
    NzIconModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatMenuModule,
    ClrDropdownModule,
    MatTabsModule,
    MatButtonToggleModule,
    MatIconModule,
    MatDividerModule,
    MatButtonModule,
    MatInputModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatSnackBarModule,
    FormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    QRCodeModule,
    MatExpansionModule,
    MatRadioModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatMomentDateModule,
    MatMomentDatetimeModule,
    MatDatetimepickerModule,
    CdkDropList,
    CdkDrag,
    ToastModule,
    RippleModule,
    ButtonModule,
    CdkDragPreview,
    CdkDropListGroup,
    OverlayPanelModule,
    EditorModule,
    TableModule,
    SelectButtonModule,
    InputTextModule,
    ConfirmDialogModule,
    CalendarModule,
    InputNumberModule,
    MatRippleModule,
    MatProgressSpinnerModule,
    ProgressSpinnerModule,
    ProgressBarModule,
    CarouselModule,
    GalleriaModule,
    CardModule,
    CardViewModule,
    MatBadgeModule,
    MatFormFieldModule,
    NgxExtendedPdfViewerModule,
    NgxDaterangepickerMd.forRoot(),
    RadioButtonModule,
    SplitButtonModule,
    MatSliderModule,
    PaginatorModule,
    CdkMenu,
    CdkMenuItem,
    CdkContextMenuTrigger,
    NzInputGroupComponent,
    NzInputDirective,
    NzButtonComponent,
    NzDatePickerModule,
    NzInputDirective,
    NzSelectComponent, NzOptionComponent, NzInputNumberComponent, NzDatePickerComponent, NzDropDownDirective, NzDropdownMenuComponent, NzMenuDirective, NzMenuItemComponent, NzSpaceItemDirective, EmailEditorModule, NzTabSetComponent, NzTabComponent, NzTagComponent, NzNoAnimationDirective, NzDividerComponent, NzProgressComponent, NzCheckboxComponent, DividerModule, NzSubMenuComponent, NzTooltipDirective, NzRadioComponent, NzRadioGroupComponent, NzStepsComponent, NzStepComponent, NzSwitchComponent, NzSpinComponent, NzRowDirective, NzColDirective, NzTreeComponent, NzAutocompleteComponent, NzAutocompleteOptionComponent, NzAutocompleteTriggerDirective],
  providers: [
    {provide: NZ_I18N, useValue: en_US},
    {provide: NZ_ICONS, useValue: icons},
    { provide: NzIconService, useClass: NzIconService },
    MessageboxComponent, MainFrameComponent, MessageService, ConfirmationService, CurrencyPipe, DatePipe,
    provideHttpClient(withInterceptorsFromDi()),
  ]
})
export class AppModule {
  constructor(private iconService: NzIconService) {
    this.iconService.addIcon(SyncOutline, ReloadOutline);
  }
}
