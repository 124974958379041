<div class="card">
  <div class="black-background-blt">
    <div class="example-container-blt">
      <div class="headerdiv">
        <samp class="titlediv">Outlets and Registers</samp>
      </div>
      <div class="headerdiv1">
        <div class="headerdiv">
<!--          <p-button label="Add outlet" icon="pi pi-plus" [rounded]="false" [outlined]="true" />-->
          <button nz-button nzType="default" (click)="onOutletAddClick()">Add outlet</button>
        </div>
      </div>

      <div class="griddiv">
        <p-table
          [value]="iOutletsResult"
          [paginator]="true"
          [rows]="10"
          [showCurrentPageReport]="true"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          [rowsPerPageOptions]="[10, 20, 50, 100]"
          [styleClass]="'p-datatable-striped mat-elevation-z1 ' + selectedSize.class.toString()"
          selectionMode="single"
          [tableStyle]="{'min-width': '50rem'}">

          <ng-template pTemplate="header">
            <tr>
              <th style="width:4%">Outlet Name</th>
              <th style="width:4%">Register Name</th>
              <th style="width:0%">Actions</th>  <!-- Добавлен заголовок для кнопки -->
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-product>
            <tr>
              <td>{{ product["OutletName"] }}</td>
              <td>{{ product["Registers"] }}</td>
              <td>
                <button nz-button nzType="text" nzSize = "small" nzShape="circle" (click)="editOutlet(product)">
                  <span nz-icon [nzType]="'edit'" [nzTheme]="'fill'"></span>
                </button>
                <button nz-button nzType="text" nzSize="small" nzShape="circle" (click)="deleteOutlet(product)">
                  <span nz-icon [nzType]="'delete'" [nzTheme]="'fill'" style="color: red;"></span>
                </button>
              </td>
            </tr>
          </ng-template>

        </p-table>
        <div class="fullline">

        </div>
        <div *ngIf="SelfData.fullPage" class="nextpage">
          <app-sendoutstep1></app-sendoutstep1>
        </div>
      </div>

    </div>
  </div>
</div>

<nz-dropdown-menu #menu4="nzDropdownMenu">
  <ul nz-menu>
<!--    <li nz-menu-item *ngIf="currentSample?.['isok'] == 0" (click)="onButtonReturnScanClick(currentSample)">Return & Scan</li>-->
<!--    <li nz-menu-item (click)="onViewButtonClick(currentSample)">View Details</li>-->
<!--    <li nz-menu-item (click)="onButtonDesignerClick()">Choose more samples from this designer.</li>-->
<!--    <li nz-menu-item *ngIf="SelfData.sendoutmode === 0" (click)="onPrintBarcodeClick(currentSample?.['barcode'])">Print Barcode</li>-->
<!--    <li nz-menu-item (click)="removeSelectedSample(currentSample?.['barcode'])">Remove Sample</li>-->
<!--    &lt;!&ndash;    <li nz-menu-item *ngIf="currentSample?.['itemstatus'] == 0" (click)="restoreSelectedSample(currentSample?.['barcode'])">Restore Sample</li>&ndash;&gt;-->
<!--    <li nz-menu-item *ngIf="currentSample?.['requested count'] > 1" (click)="onSameRequestClick(currentSample)">Same Request</li>-->
  </ul>
</nz-dropdown-menu>
