import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Table} from "primeng/table";
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder} from "@angular/forms";
import {HashService} from "../../services/hash.service";
import {MessageboxComponent} from "../../modals/messagebox/messagebox.component";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {HttpClient} from "@angular/common/http";
import * as moment from "moment-timezone";
import {SelfData, SetSampleListData, TagsListData, transformImageUrl} from "../../models/struct";
import {tz} from "moment-timezone";
import {forkJoin, Subscription} from "rxjs";

interface TimeZone {
  viewvalue: string;
  value: string;
}


@Component({
  selector: 'app-suppliers',
  templateUrl: './suppliers.component.html',
  styleUrl: './suppliers.component.scss'
})
export class SuppliersComponent implements OnInit, OnDestroy {
  @ViewChild('dt1') dt1!: Table;
  filtertext: string = ""
  timezone: TimeZone[] = [];
  countries: any[] = [];
  states: any[] = [];
  pcountries: any[] = [];
  pstates: any[] = [];
  sizes!: any[];
  iSupplierResult: TagsListData[] = []
  @ViewChild(Table) dataTable?: Table;
  selectedSize: any = '';
  currentSample: SetSampleListData | null = null;


  constructor(public modalService: ModalserviceService,
              public fb: FormBuilder,
              private hashService: HashService,
              public messageBox: MessageboxComponent,
              private serviceservice: ServiceService,
              private yourappsService: YourappsserviceService,
              private _snackBar: MatSnackBar,
              private http: HttpClient,
              private cdr: ChangeDetectorRef) {
    const timezoneNames = moment.tz.names();
    this.sizes = [
      {name: 'Small', class: 'p-datatable-sm'}
    ];
    this.selectedSize = this.sizes[0]

    this.timezone = timezoneNames.map((tz) => {
      const offset = moment.tz(tz).utcOffset(); // Смещение в минутах
      const hours = Math.floor(Math.abs(offset) / 60).toString().padStart(2, '0');
      const minutes = Math.abs(offset) % 60 === 0 ? '00' : (Math.abs(offset) % 60).toString().padStart(2, '0');
      const formattedOffset = `${offset >= 0 ? '+' : '-'}${hours}:${minutes}`;
      return { viewvalue: `(GMT${formattedOffset}) ${tz}`, value: formattedOffset };
    });
    this.selectedSize = this.sizes[0]
    setTimeout(() => {
      SelfData.spinnerpossition = true
    });
    forkJoin({
      suppliers: this.serviceservice.selectSuppliers(this.getAccountID()),
    }).subscribe(({ suppliers }) => {
      this.iSupplierResult = suppliers.data;
      console.log("this.iSupplierResult", this.iSupplierResult)
      this.selectedSize = this.sizes[0]
      setTimeout(() => {
        SelfData.spinnerpossition = false
      });
    });
  }

  ngOnInit(): void {
  }

  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
      ? SelfData.account[0].data[0].id
      : SelfData.account[0].data[0].parentid;
  }

  private subscription?: Subscription;

  onSupplierClick() {
    this.yourappsService.setSelectedSupplierList([], 0)
    this.yourappsService.getReloadSupplierList([], 0)
    this.subscription = this.yourappsService.reloadSupplierList$.subscribe((value) => {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      if (value.type === 1) {
        forkJoin({
          suppliers: this.serviceservice.selectSuppliers(this.getAccountID()),
        }).subscribe(({ suppliers }) => {
          this.iSupplierResult = suppliers.data;
          console.log("this.iSupplierResult", this.iSupplierResult)
        });
      }
    })
    this.modalService.suppliersFrameOpen()
  }

  protected readonly transformImageUrl = transformImageUrl;
  protected readonly SelfData = SelfData;

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  editSupplier(product: any) {
    this.yourappsService.setSelectedSupplierList(product, 1)
    this.yourappsService.getReloadSupplierList([], 0)
    this.subscription = this.yourappsService.reloadSupplierList$.subscribe((value) => {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      if (value.type === 1) {
        forkJoin({
          suppliers: this.serviceservice.selectSuppliers(this.getAccountID()),
        }).subscribe(({ suppliers }) => {
          this.iSupplierResult = suppliers.data;
          console.log("this.iSupplierResult", this.iSupplierResult)
        });
      }
    })
    SelfData.formstyle = 1
    this.modalService.suppliersFrameOpen()
  }

  deleteSupplier(product: any) {

  }

  onInput(event: any) {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;
    this.dt1.filterGlobal(value, 'contains');
  }

  clear(table: Table) {
    table.clear();
    this.filtertext = ""
  }

  onRowSelect(event: any) {
    this.viewCustomer(event.data)
  }


  onRowUnselect(event: any) {
    console.log('Row unselected:', event.data);
    // Ваша логика обработки отмены выбора
  }

  viewCustomer(product: any) {
    this.yourappsService.setSelectedSupplierList(product, 1)
    this.yourappsService.getReloadSupplierList([], 0)
    this.subscription = this.yourappsService.reloadSupplierList$.subscribe((value) => {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      if (value.type === 1) {
        forkJoin({
          suppliers: this.serviceservice.selectSuppliers(this.getAccountID()),
        }).subscribe(({ suppliers }) => {
          this.iSupplierResult = suppliers.data;
          console.log("this.iSupplierResult", this.iSupplierResult)
        });
      }
    })
    SelfData.formstyle = 2
    this.modalService.suppliersFrameOpen()
  }
}
