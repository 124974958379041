<button mat-icon-button type="button" class="close-btn"  (click)="modalService.closeEmailEditor()">
  <mat-icon>close</mat-icon>
</button>
<p class="sample-manager-editor">Contact Mailing</p>
<nz-tabset [(nzSelectedIndex)]="selectedIndex" (nzSelectedIndexChange)="onTabChange($event)" class="tabclass">
  <nz-tab nzTitle="SET UP">
    <div class="headerdiv">
      <button [disabled]="tabsdisabled" class="nextbutton" nz-button nzType="primary" (click)="goToNextTab()">NEXT ></button>
    </div>
    <div class="setuptab">
      <div class="example-full-width">
        <label class="inputlabel">Subject<span class="red-text"> *</span></label>
        <nz-input-group nzSearch>
          <input (ngModelChange)="checkFormFields()" class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="subjectstring" nz-input/>
        </nz-input-group>
      </div>
      <div class="div1">
        <div class="example-full-width">
          <label class="inputlabel">Send From Name<span class="red-text"> *</span></label>
          <nz-input-group nzSearch>
            <input (ngModelChange)="checkFormFields()" class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="fromnamestring" nz-input/>
          </nz-input-group>
        </div>
        <div class="example-full-width">
          <label class="inputlabel">Send From Email<span class="red-text"> *</span></label>
          <nz-input-group nzSearch>
            <input (ngModelChange)="checkFormFields()" class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="fromemailstring" nz-input/>
          </nz-input-group>
        </div>
      </div>
      <div class="div1">
        <div class="example-full-width">
          <label class="inputlabel">Reply-To Email<span class="red-text"> *</span></label>
          <nz-input-group nzSearch>
            <input (ngModelChange)="checkFormFields()" class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="replytoemail" nz-input/>
          </nz-input-group>
        </div>
        <div class="example-full-width">
          <label class="inputlabel">Internal Notes</label>
          <nz-input-group nzSearch>
            <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="internalnotes" nz-input/>
          </nz-input-group>
        </div>
      </div>
      <div class="center-container1">
        <label class="inputlabel">Attachment</label>
        <div class="attachment-section">
          <button mat-raised-button type="button" (click)="uploadFiles()">
            Browse
            <mat-icon>upload</mat-icon>
          </button>
        </div>
        <div class="file-info">
          <span class="inputlabel">Current size: ({{ currentSize }})</span>
          <span class="inputlabel max-size">Maximum size: 5 Mb</span>
        </div>
        <div class="file-container">
          <div *ngFor="let file of selectedFiles; let i = index" class="file-item">
            {{ file.name }} ({{ formatSize(file.size) }})
            <img
              src="assets/ic-delete-file.svg"
              alt="Delete file"
              class="delete-icon"
              (click)="deleteFile($event, i)"
            />
          </div>
        </div>
      </div>
    </div>
  </nz-tab>
  <nz-tab nzTitle="DESIGN" nzForceRender [nzDisabled]="tabsdisabled">
    <div class="headerdiv">
      <button class="nextbutton" nz-button nzType="primary" (click)="goToNextTab()">NEXT ></button>
      <button class="backbutton" nz-button nzType="primary" (click)="goToBackTab()">< BACK</button>
    </div>
    <div class="container">
      <!-- Email editor -->
      <email-editor
        [options]="options"
        [scriptUrl]="scriptUrl"
        (loaded)="editorLoaded($event)"
        (ready)="editorReady($event)"
        #editor
      ></email-editor>
    </div>
  </nz-tab>
  <nz-tab nzTitle="RECIPIENTS" [nzDisabled]="tabsdisabled">
    <div class="headerdiv">
      <button class="nextbutton" nz-button nzType="primary" (click)="goToNextTab()">NEXT ></button>
      <button class="backbutton" nz-button nzType="primary" (click)="goToBackTab()">< BACK</button>
    </div>
    <div class="recipientsdiv">
      <button mat-raised-button class="view-more-btn" (click)="onEmailContactsClick()">
        <mat-icon class="icon-spacing icon-scale">add</mat-icon>
        Add From Contacts
      </button>
      <!--      <button mat-raised-button class="view-more-btn-clear" type="button" (click)="onButtonClearAllClick()">-->
      <!--        Clear All-->
      <!--      </button>-->
      <button mat-raised-button class="view-more-btn1" (click)="onEmailSignClick()">
        <mat-icon class="icon-spacing icon-scale">add</mat-icon>
        Add Email Addresses
      </button>
    </div>

    <div class="card example-container-so">
      <p-table
        #dt1
        *ngIf="selectedemails?.[0]?.data"
        [value]="selectedemails[0].data"
        [paginator]="true"
        [rows]="100"
        [scrollable]="true"
        [scrollHeight]="'calc(60svh - 150px)'"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [rowsPerPageOptions]="[100, 500, 1000]"
        [styleClass]="'p-datatable-striped p-datatable-sm'"
        [globalFilterFields]="['Contact Name', 'Phone Number', 'Creation date', 'email', 'Job Title', 'Category', 'Job Department']"
        selectionMode="single"
        [tableStyle]="{'min-width': '50rem'}">
        <ng-template pTemplate="caption">
          <div class="flex">
            <span class="p-input-icon-left ml-auto">
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                  <i class="pi pi-search" style="color: #000000"></i>
                </span>
                <input pInputText type="text" (input)="onInput($event)" placeholder="Filter" [(ngModel)]="filtertext"/>
                <span class="p-inputgroup-addon custom-hover-red" matRipple (click)="clear(dt1)">
                  <i class="pi pi-times" style="color: #000000"></i>
                </span>
              </div>
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th style="width:7.5%">Options</th>
            <th style="width:17.6%">Contact Name</th>
            <th style="width:17.6%">Phone Number</th>
            <th style="width:17.6%">Email</th>
            <th style="width:17.6%">Job Title</th>
            <th style="width:10%">Category</th>
            <th style="width:8%">Job Department</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
          <tr [pSelectableRow]="product">
            <td>
              <button type="button" nz-button (click)="removeProduct(product)">
                Remove
              </button>
            </td>
            <td>{{product?.["Contact Name"]}}</td>
            <td>{{product?.["Phone Number"]}}</td>
            <td>{{product?.["email"]}}</td>
            <td>{{product?.["Job Title"]}}</td>
            <td>{{product?.["Category"]}}</td>
            <td>{{product?.["Job Department"]}}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>

  </nz-tab>
  <nz-tab nzTitle="SEND" [nzDisabled]="tabsdisabled">
    <div class="headerdiv">
      <button class="shchedulebutton" nz-button nzType="primary" (click)="goToSchedule()">SCHEDULE</button>
      <button class="nextbutton" nz-button nzType="primary" (click)="goToSend()">SEND</button>
      <button class="backbutton" nz-button nzType="primary" (click)="goToBackTab()">< BACK</button>
    </div>
    <div class="header">
      <span class="subjectdiv">{{subjectstring}}</span>
      <nz-divider></nz-divider>
      <span class="subdiv">
        From: {{ fromnamestring }}
        <span *ngIf="fromemailstring">&lt;{{ fromemailstring }}&gt;</span>
        <span class="spacer"></span> <!-- Динамический отступ -->
        <span *ngIf="replytoemail">Reply to: {{ replytoemail }}</span><br>
      </span>
      <span class="subdiv">
        Selected Recipients: ({{ selectedemails?.[0]?.data?.length }})
      </span>
    </div>
    <div class="previewdiv" #previewDiv style="width: 100%; height: auto; border: none;"></div>
  </nz-tab>
</nz-tabset>

<p-confirmDialog *ngIf="showdialog" #cd [style]="{width: '25vw'}" [closable]="true">
  <ng-template pTemplate="footer">
    <div class="button-container-request">
      <button mat-raised-button class="button-secondary" style="margin-right: 10px; width: 50%" (click)="cd.reject()">
        No
      </button>
      <button mat-raised-button class="button-primary" style="margin-right: 0; width: 50%" (click)="cd.accept()">
        Yes
      </button>
    </div>
  </ng-template>
</p-confirmDialog>
