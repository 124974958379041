<button mat-icon-button type="button" class="close-btn" (click)="modalService.productFrameClose()">
  <mat-icon>close</mat-icon>
</button>
<p class="sample-manager-customer">{{ caption }}</p>
<div class="maindiv">
  <nz-tabset>
    <nz-tab nzTitle="Product">
      <div class="componentsdiv">
        <div class="componentsdiv2">
          <label class="txtdiv">Name</label>
          <nz-input-group>
            <input class="cmdiv" type="text" [(ngModel)]="productname" nz-input/>
          </nz-input-group>

        </div>
        <div class="componentsdiv2">
          <label class="txtdiv">Brand</label>
          <nz-input-group [nzAddOnAfter]="suffixIconButton1">
            <nz-select nzShowSearch nzAllowClear [nzShowArrow]="true" class="cmdiv"
              [(ngModel)]="brand">
              <nz-option *ngFor="let option of iBrandsResult"
                [nzLabel]="option['description']"
                [nzValue]="option['description']">
              </nz-option>
            </nz-select>
          </nz-input-group>
          <ng-template #suffixIconButton1>
            <button class="buttonclass" nz-button nzType="default" nzSearch (click)="onBrandClick()">
              <!--              <span nz-icon nzType="unordered-list" nzTheme="outline"></span>-->
              <i nz-icon nzType="ellipsis" nzTheme="outline"></i>
            </button>
          </ng-template>
        </div>
      </div>

      <div class="componentsdiv1-2">
        <div class="componentsdiv2-1">
          <label class="txtdiv">Description</label>
          <div class="card-email">
            <p-editor [(ngModel)]="description" [style]="{ height: '185px' }">
              <!--        <ng-template pTemplate="header">-->
              <!--        <span class="ql-formats">-->
              <!--            <button type="button" class="ql-bold" aria-label="Bold"></button>-->
              <!--            <button type="button" class="ql-italic" aria-label="Italic"></button>-->
              <!--            <button type="button" class="ql-underline" aria-label="Underline"></button>-->
              <!--        </span>-->
              <!--        </ng-template>-->
            </p-editor>
          </div>
        </div>
      </div>

      <div class="componentsdiv">
        <div class="componentsdiv2-1">
          <label class="txtdiv2"><span class="onlybold">Tags</span> Describe the product using keywords for easy
            filtering</label>
          <nz-select
            [(ngModel)]="listOfTagOptions"
            nzMode="tags"
            [nzTokenSeparators]="[',']"
          >
            <nz-option *ngFor="let option of iTagsResult" [nzLabel]="option['description']"
              [nzValue]="option['description']"></nz-option>
          </nz-select>
        </div>
      </div>

      <div class="componentsdiv">
        <div class="componentsdiv2-1">
          <label class="txtdiv2"><span class="onlybold">Product categories</span> Use category levels to filter your
            sales and inventory reports.</label>
          <nz-input-group [nzAddOnAfter]="suffixCategoryIconButton">
            <nz-select nzShowSearch nzAllowClear [nzShowArrow]="true" class="cmdiv"
              [(ngModel)]="selectCategories">
              <nz-option *ngFor="let option of iCategoriesResult" [nzLabel]="option['description']"
                [nzValue]="option['description']"></nz-option>
            </nz-select>
          </nz-input-group>
          <ng-template #suffixCategoryIconButton>
            <button class="buttonclass" nz-button nzType="default" nzSearch (click)="onCategoryClick()">
              <!--              <span nz-icon nzType="unordered-list" nzTheme="outline"></span>-->
              <i nz-icon nzType="ellipsis" nzTheme="outline"></i>
            </button>
          </ng-template>
        </div>
      </div>

      <div class="componentsdiv3-3">
        <div class="componentsdiv3-4">
          <label class="txtdiv2"><span class="onlybold">Color</span></label>
          <nz-input-group nzSearch>
            <!--        <nz-select nzMode="tags" [nzShowArrow]="true" [nzTokenSeparators]="[',']" class="cmdiv"-->
            <!--          [(ngModel)]="colorstring">-->
            <nz-select class="cmdiv"
              [(ngModel)]="colorstring"
              nzMode="tags"
              [nzTokenSeparators]="[',']"
            >

              <nz-option nzCustomContent *ngFor="let option of colorarray" [nzLabel]="option.colorname"
                [nzValue]="option.colorname">
                <span class="color-box" [style.background]="option.colorcode"></span>
                {{ option.colorname }}
              </nz-option>
            </nz-select>
          </nz-input-group>
        </div>
        <div class="componentsdiv3-4">
          <label class="txtdiv2"><span class="onlybold">Materials</span></label>
          <nz-input-group [nzAddOnAfter]="suffixMaterialIconButton">
            <nz-select nzShowSearch nzAllowClear [nzShowArrow]="true" class="cmdiv"
              [(ngModel)]="selectMaterials">
              <nz-option *ngFor="let option of iMaterialsResult" [nzLabel]="option['description']"
                [nzValue]="option['description']"></nz-option>
            </nz-select>
          </nz-input-group>
          <ng-template #suffixMaterialIconButton>
            <button class="buttonclass" nz-button nzType="default" nzSearch (click)="onMaterialClick()">
              <!--              <span nz-icon nzType="unordered-list" nzTheme="outline"></span>-->
              <i nz-icon nzType="ellipsis" nzTheme="outline"></i>
            </button>
          </ng-template>
        </div>
        <div class="componentsdiv3-4">
          <label class="txtdiv2"><span class="onlybold">SKU code</span></label>
          <nz-input-group>
            <input class="cmdiv" type="number" [(ngModel)]="skucode" nz-input/>
          </nz-input-group>
        </div>
      </div>

      <div class="componentsdiv3-3">
        <div class="componentsdiv3-4">
          <label class="txtdiv2"><span class="onlybold">Supplier</span></label>
          <nz-input-group [nzAddOnAfter]="suffixSupplierIconButton">
            <nz-select nzShowSearch nzAllowClear [nzShowArrow]="true" class="cmdiv"
              [(ngModel)]="selectsupplier">
              <nz-option *ngFor="let option of iSuppliersResult"
                [nzLabel]="option['SupplierName']"
                [nzValue]="option['SupplierName']">
              </nz-option>
            </nz-select>
          </nz-input-group>

          <ng-template #suffixSupplierIconButton>
            <button class="buttonclass" nz-button nzType="default" (click)="onSupplierClick()">
              <i nz-icon nzType="ellipsis" nzTheme="outline"></i>
            </button>
          </ng-template>
        </div>
        <div class="componentsdiv3-4">
          <label class="txtdiv2"><span class="onlybold">Supplier code</span></label>
          <nz-input-group>
            <input class="cmdiv" type="text" [(ngModel)]="suppliercode" nz-input/>
          </nz-input-group>
        </div>
        <div class="componentsdiv3-4">
          <label class="txtdiv2"><span class="onlybold">Supplier price</span></label>
          <nz-input-group>
            <nz-input-number class="cmdiv" [(ngModel)]="supplierprice" [nzMin]="0" [nzStep]="1"></nz-input-number>
          </nz-input-group>
        </div>
      </div>

      <div class="componentsdiv3-3">
        <div class="componentsdiv3-4">
          <label class="txtdiv2"><span class="onlybold">Stone weight, ct.</span></label>
          <nz-input-group>
            <nz-input-number class="cmdiv" [(ngModel)]="stoneweight" [nzMin]="0" [nzStep]="1"></nz-input-number>
          </nz-input-group>
        </div>
        <div class="componentsdiv3-4">
          <label class="txtdiv2"><span class="onlybold">Net weight per item, gr</span></label>
          <nz-input-group>
            <nz-input-number class="cmdiv" [(ngModel)]="netweight" [nzMin]="0" [nzStep]="1"></nz-input-number>
          </nz-input-group>
        </div>
        <div class="componentsdiv3-4">
          <label class="txtdiv2"><span class="onlybold">Size</span></label>
          <nz-input-group>
            <nz-input-number class="cmdiv" [(ngModel)]="size" [nzMin]="0" [nzStep]="1"></nz-input-number>
          </nz-input-group>
        </div>
      </div>

      <div class="componentsdiv">
        <div class="componentsdiv2-1">
          <label class="txtdiv2"><span class="onlybold">Default Sales Tax</span></label>
          <nz-input-group [nzAddOnAfter]="suffixIconButton">
            <nz-select nzShowSearch [nzShowArrow]="true" class="cmdiv"
              [(ngModel)]="selecttaxe">
              <nz-option *ngFor="let option of iTaxResult" [nzLabel]="option['displayvalue']"
                [nzValue]="option['displayvalue']"></nz-option>
            </nz-select>
          </nz-input-group>
          <ng-template #suffixIconButton>
            <button class="buttonclass" nz-button nzType="default" nzSearch>
              <!--              <span nz-icon nzType="unordered-list" nzTheme="outline"></span>-->
              <i nz-icon nzType="ellipsis" nzTheme="outline"></i>
            </button>
          </ng-template>
        </div>
      </div>

      <div class="componentsdiv4-4">
        <div class="componentsdiv4-5">
          <label class="txtdiv2"><span class="onlybold">Retail price</span></label>
          <nz-input-group>
            <nz-input-number class="cmdiv"
              [(ngModel)]="retailprice"
              (ngModelChange)="recalculateFromRetailPrice(retailprice, 1)"
              [nzMin]="0"
              [nzStep]="1"></nz-input-number>
          </nz-input-group>
        </div>
        <div class="componentsdiv4-5">
          <label class="txtdiv2"><span class="onlybold">Markup</span></label>
          <nz-input-group>
            <nz-input-number class="cmdiv"
              [(ngModel)]="markup"
              [nzFormatter]="formatterPercent"
              (ngModelChange)="recalculateFromMarkup(markup, 1)"
              [nzMin]="0"
              [nzStep]="1"></nz-input-number>
          </nz-input-group>
        </div>
        <div class="componentsdiv4-5">
          <label class="txtdiv2"><span class="onlybold">Margin</span></label>
          <nz-input-group>
            <nz-input-number class="cmdiv"
              [(ngModel)]="margin"
              [nzFormatter]="formatterPercent"
              (ngModelChange)="recalculateFromMargin(margin, 1)"
              [nzMin]="0"
              [nzStep]="1"></nz-input-number>
          </nz-input-group>
        </div>
        <div class="componentsdiv4-5">
          <label class="txtdiv2"><span class="onlybold">Tax</span></label>
          <label class="cmdiv"><span class="onlybold">${{ taxvalue | number:'1.2-2' }}</span></label>
        </div>
      </div>

      <div class="componentsdiv4-4">
        <div class="componentsdiv4-5">
          <label class="txtdiv2"><span class="onlybold">Wholesale price</span></label>
          <nz-input-group>
            <nz-input-number class="cmdiv"
              [(ngModel)]="wholesaleprice"
              (ngModelChange)="recalculateFromRetailPrice(wholesaleprice, 2)"
              [nzMin]="0"
              [nzStep]="1"></nz-input-number>
          </nz-input-group>
        </div>
        <div class="componentsdiv4-5">
          <label class="txtdiv2"><span class="onlybold">Markup</span></label>
          <nz-input-group>
            <nz-input-number class="cmdiv"
              [(ngModel)]="wmarkup"
              [nzFormatter]="formatterPercent"
              (ngModelChange)="recalculateFromMarkup(wmarkup, 2)"
              [nzMin]="0"
              [nzStep]="1"></nz-input-number>
          </nz-input-group>
        </div>
        <div class="componentsdiv4-5">
          <label class="txtdiv2"><span class="onlybold">Margin</span></label>
          <nz-input-group>
            <nz-input-number class="cmdiv"
              [(ngModel)]="wmargin"
              [nzFormatter]="formatterPercent"
              (ngModelChange)="recalculateFromMargin(wmargin, 2)"
              [nzMin]="0"
              [nzStep]="1"></nz-input-number>
          </nz-input-group>
        </div>
        <div class="componentsdiv4-5">
          <!--      <label class="txtdiv2"><span class="onlybold">Tax</span></label>-->
          <!--      <label class="cmdiv"><span class="onlybold">${{ wtaxvalue | number:'1.2-2' }}</span></label>-->
        </div>
      </div>

      <div class="componentsdiv4-4">
        <div class="componentsdiv4-5">
          <label class="txtdiv2"><span class="onlybold">Low Inventory</span></label>
          <nz-input-group>
            <nz-input-number class="cmdiv"
              [(ngModel)]="minimumquantity"
              [nzMin]="0"
              [nzStep]="1"></nz-input-number>
          </nz-input-group>
        </div>
        <div class="componentsdiv4-5">

        </div>
        <div class="componentsdiv4-5">

        </div>
        <div class="componentsdiv4-5">
          <!--      <label class="txtdiv2"><span class="onlybold">Tax</span></label>-->
          <!--      <label class="cmdiv"><span class="onlybold">${{ wtaxvalue | number:'1.2-2' }}</span></label>-->
        </div>
      </div>

      <div class="componentsdiv">
        <div class="componentsdiv2-1 center-container1">
          <button mat-raised-button type="button" (click)="uploadImageFiles()">
            Upload Image
            <mat-icon>upload</mat-icon>
          </button>

          <div class="file-container">
            <mat-progress-spinner *ngIf="showprogressbar"
              class="example-margin"
              [color]="color"
              [mode]="mode"
              [value]="progressvalue"
              style="width: 50px; height: 50px; --mdc-circular-progress-size: 50px; --mdc-circular-progress-active-indicator-width: 100px;">
            </mat-progress-spinner>
            <div cdkDropList cdkDropListOrientation="horizontal" class="example-list"
              (cdkDropListDropped)="drop($event)">
              <div *ngFor="let item of selectedImageFiles; index as i" class="example-box" cdkDrag>
                <mat-card
                  class="card-item"
                  [class.selected]="i === selectedIndex"
                  [cdkContextMenuTriggerFor]="inner"
                  (contextmenu)="selectdefaultimage = item"
                  (click)="zoomImage(i, item)">
                  <img [src]="transformImageUrl(item.url)" alt="Image"/>
                  <!--                <img [src]="item.url" alt="Image" />-->
                  <button mat-mini-fab type="button" class="close-button" [disabled]="showprogressbar"
                    (click)="deleteImageFile($event, i)">
                    <mat-icon class="custom-icon">close</mat-icon>
                  </button>
                  <img *ngIf="item.sortby === -1" class="checkboxdefault" src="assets/checkbox.png" alt="isdefault"/>
                </mat-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nz-tab>
    <nz-tab *ngIf="SelfData.formstyle > 0" nzTitle="History">
      <div class="griddiv">
        <p-table
          #dt1
          [value]=iProductsResult
          [paginator]="true"
          [rows]="10"
          rowGroupMode="subheader"
          groupRowsBy="designer"
          selectionMode="single"
          [(selection)]="selectedSalesReport"
          [showCurrentPageReport]="true"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          [rowsPerPageOptions]="[10, 20, 50, 100]"
          [styleClass]="'p-datatable-selectable p-datatable-striped mat-elevation-z1 ' + (selectedSize?.class || '')"
          [tableStyle]="{'min-width': '50rem'}">

          <ng-template pTemplate="header">
            <tr>
              <th style="width: 0.5%"></th>
<!--              <th style="width: 1.5%">Image</th>-->
<!--              <th style="width: 9%">Product Name</th>-->
              <th style="width: 6%">User</th>
              <th style="width: 6%">Date</th>
              <th style="width: 1%">Quentity</th>
              <th style="width: 1%">Discount (%)</th>
              <th style="width: 1%">Discount Amount</th>
              <th style="width: 2%">Price</th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-product>
            <tr>
              <td>
                <span nz-icon
                  [nzType]="product['source_indicator'] === 2 ? 'caret-right' : 'caret-left'"
                  [style.color]="product['source_indicator'] === 2 ? 'green' : 'red'">
                </span>
              </td>
<!--              <td>-->
<!--                <img-->
<!--                  *ngIf="product?.imagelink"-->
<!--                  [src]="transformImageUrl(product.imagelink)"-->
<!--                  alt="Product Image"-->
<!--                  class="image-size-80x80 shadow-4"-->
<!--                  onerror="this.src='assets/images/placeholder.jpg';">-->
<!--              </td>-->
<!--              <td>{{ product["name"] }}</td>-->
              <td>{{ product["User"] }}</td>
              <td>{{ product.regdate | date:'yyyy-MM-dd HH:mm' }}</td>
              <td>{{ product["quantity"] }}</td>
              <td>{{ product.discountPercentage | number:'1.2-2' }}%</td>
              <td>{{ product.discountAmount | currency:'USD':'symbol':'1.2-2' }}</td>
              <td>{{ product.unitPrice | currency:'USD':'symbol':'1.2-2' }}</td>
            </tr>
          </ng-template>
          <ng-template pTemplate="groupfooter" let-product>
            <tr>

<!--              <td colspan="4" class="text-right font-bold pr-3">{{calculateTotal() }}</td>-->
<!--              <td colspan="1" class="text-right font-bold pr-3">{{calculateTotal1() | currency:'USD':'symbol':'1.2-2'}}</td>-->
<!--              <td colspan="1" class="text-right font-bold pr-3">{{calculateTotal2() | currency:'USD':'symbol':'1.2-2'}}</td>-->
              <!--          <td colspan="8" class="text-right font-bold pr-3">Total Catalogs: </td>-->
            </tr>
          </ng-template>
        </p-table>
        <div class="fullline">

        </div>
      </div>
    </nz-tab>
  </nz-tabset>
</div>

<div class="footerdiv">
  <button [disabled]="viewmode" nz-button nzType="default" (click)="onProductClick()">{{ caption }}</button>
</div>

<ng-template #inner>
  <div class="example-menu" cdkMenu>
    <button class="example-menu-item" cdkMenuItem (click)="onDefaultImageClick(selectdefaultimage)">Use as Default
    </button>
    <!--    <button class="example-menu-item" cdkMenuItem>Copy</button>-->
    <!--    <button class="example-menu-item" cdkMenuItem>Paste</button>-->
  </div>
</ng-template>
