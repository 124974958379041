import { Component } from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";

@Component({
  selector: 'app-emailadd',
  templateUrl: './emailadd.component.html',
  styleUrl: './emailadd.component.scss'
})
export class EmailaddComponent {
  constructor(public modalService: ModalserviceService) {}
}
