import { Component } from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";

@Component({
  selector: 'app-aichoos',
  templateUrl: './aichoos.component.html',
  styleUrls: ['./aichoos.component.scss']
})
export class AichoosComponent {
  constructor(public modalService: ModalserviceService) {}
}
