import { Injectable } from '@angular/core';
import * as qz from 'qz-tray';

@Injectable({
  providedIn: 'root'
})
export class QzTrayService {

  constructor() {
    qz.api.setPromiseType((promise) => new Promise(promise));
  }

  async connect(): Promise<void> {
    try {
      await qz.websocket.connect({
        port: {
          secure: [8181, 8282, 8383, 8484],   // Возможные порты для HTTPS (wss)
          insecure: [8181, 8282, 8383, 8484] // Возможные порты для HTTP (ws)
        }
      });
      console.log('QZ Tray connected!');
    } catch (error) {
      console.error('Failed to connect to QZ Tray:', error);
    }
  }

  async disconnect() {
    if (qz.websocket.isActive()) {
      await qz.websocket.disconnect();
      console.log('QZ Tray disconnected.');
    }
  }

  async print(data: any) {
    const config = qz.configs.create('Zebra Technologies ZTC ZP 450-200dpi', {
      altPrinting: true,
      rasterize: false,
      forceRaw: true,
      encoding: 'UTF-8',  // ← Добавлено здесь
      options: {
        forceRaw: true,
        language: 'ZPL'   // ← Явное указание языка
      }
    } as qz.PrinterOptions);

    console.log('Конфиг печати:', config);

    try {
      console.log('Отправка данных на печать:', data);
      const result = await qz.print(config, data);
      console.log('Результат печати:', result);

      return result;
    } catch (error) {
      console.error('Print job failed:', error);
    }
  }
}
