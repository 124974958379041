import { Component } from '@angular/core';
import { ModalserviceService } from '../../services/modalservice.service';
import {animate, state, style, transition, trigger} from "@angular/animations";

@Component({
  selector: 'app-createsendout',
  templateUrl: './createsendout.component.html',
  styleUrls: ['./createsendout.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0 }), animate(300)]),
      transition(':leave', [animate(300, style({ opacity: 0 }))]),
    ]),
  ],
})
export class CreatesendoutComponent {
  constructor(public modalService: ModalserviceService) {}
}
