import {Component, OnInit} from '@angular/core';
import {ModalserviceService} from '../../services/modalservice.service';
import {FormBuilder, FormControl, FormGroup, Validators,} from '@angular/forms';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {HashService} from '../../services/hash.service';
import {LoginResult, SelfData, transformImageUrl} from '../../models/struct';
import {MessageboxComponent} from '../../modals/messagebox/messagebox.component';
import {ServiceService} from '../../services/service.service';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition,} from '@angular/material/snack-bar';
import {IpService} from '../../services/ip.service';
import {YourappsserviceService} from '../../services/yourappsservice.service';
import {RegistrationframeComponent} from '../../main/registrationframe/registrationframe.component';
import { HttpClient } from '@angular/common/http';
import {NgxImageCompressService} from 'ngx-image-compress';
import {MessageService} from "primeng/api";
import {LocalStorageService} from "../../services/local-storage.service";

@Component({
  selector: 'app-editprofiledialog',
  templateUrl: './editprofiledialog.component.html',
  styleUrls: ['./editprofiledialog.component.scss'],
  providers: [RegistrationframeComponent],
})
export class EditprofiledialogComponent implements OnInit {
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  ipAddress: string | undefined;
  operatingSystem: string | undefined;
  browser: string | undefined;
  selectedFile: File | null = null;
  imageUrl: string = '';
  firstNameFormControl = new FormControl('', [Validators.required]);
  lastNameFormControl = new FormControl('', Validators.required);
  passwordFormControl = new FormControl({ value: '', disabled: true }, [
    Validators.required,
    Validators.minLength(6),
  ]);
  newpasswordFormControl = new FormControl({ value: '', disabled: true }, [
    Validators.required,
    Validators.minLength(6),
  ]);
  conffirmpasswordFormControl = new FormControl({ value: '', disabled: true }, [
    Validators.required,
    Validators.minLength(6),
  ]);

  toppings = this.fb.group({
    check1: { value: false, disabled: false },
  });

  myForm: FormGroup = new FormGroup({});
  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private hashService: HashService,
    public messageBox: MessageboxComponent,
    private serviceservice: ServiceService,
    private _snackBar: MatSnackBar,
    private ipService: IpService,
    private yourappsService: YourappsserviceService,
    private rf: RegistrationframeComponent,
    private http: HttpClient,
    private imageCompressService: NgxImageCompressService,
    private localStorageService: LocalStorageService,
    private messageService: MessageService
  ) {
    // this.outputString = this.hashService.md5(this.inputString);
    // console.dir(this.outputString)
    this.firstNameFormControl.setValue(
      SelfData.account[0]?.data?.[0]?.firstname
    );
    this.lastNameFormControl.setValue(SelfData.account[0]?.data?.[0]?.lastname);
    this.yourappsService.reloadAvatarImage.subscribe(() => {
      this.LoadAvatarImage();
    });
  }

  ngOnInit(): void {
    this.ipService.getIpAddress().subscribe(
      (data) => {
        this.ipAddress = data.ip;
        console.log('User IP Address:', this.ipAddress);
      },
      (error) => {
        console.error('Error fetching IP address:', error);
      }
    );
    this.operatingSystem = this.detectOperatingSystem();
    console.log('User Operating System:', this.operatingSystem);
    this.browser = this.detectBrowser();
    console.log('User Browser:', this.browser);
    this.LoadAvatarImage();
  }

  detectBrowser(): string {
    const userAgent = window.navigator.userAgent;
    let browserName = 'Unknown';

    if (/MSIE|Trident/.test(userAgent)) {
      browserName = 'Internet Explorer';
    } else if (/Edge\//.test(userAgent)) {
      browserName = 'Microsoft Edge';
    } else if (/Edg\//.test(userAgent)) {
      browserName = 'Microsoft Edge Chromium';
    } else if (/Chrome\//.test(userAgent)) {
      browserName = 'Google Chrome';
    } else if (/Firefox\//.test(userAgent)) {
      browserName = 'Mozilla Firefox';
    } else if (/Safari\//.test(userAgent)) {
      browserName = 'Apple Safari';
    } else if (/Opera\//.test(userAgent)) {
      browserName = 'Opera';
    } else if (/OPR\//.test(userAgent)) {
      browserName = 'Opera Chromium';
    }

    return browserName;
  }

  detectOperatingSystem(): string {
    const userAgent = window.navigator.userAgent;
    let os = 'Unknown';

    if (userAgent.indexOf('Win') !== -1) {
      os = 'Windows';
    } else if (userAgent.indexOf('Mac') !== -1) {
      os = 'macOS';
    } else if (
      userAgent.indexOf('X11') !== -1 ||
      userAgent.indexOf('Linux') !== -1
    ) {
      os = 'Linux';
    } else if (
      userAgent.indexOf('iPhone') !== -1 ||
      userAgent.indexOf('iPad') !== -1
    ) {
      os = 'iOS';
    } else if (userAgent.indexOf('Android') !== -1) {
      os = 'Android';
    }

    return os;
  }

  iLoginResult: LoginResult[] = [];

  uploadImage(
    file: File,
    accountId: number,
    callback: (success: boolean) => void
  ) {
    const formData = new FormData();

    const updatedFile = new File(
      [file],
      this.hashService.md5(accountId.toString()) + '.png',
      { type: file.type }
    );

    formData.append('image', updatedFile, updatedFile.name);
    formData.append('accountId', accountId.toString());

    this.http.post('http://108.178.189.70:6330/updateimage', formData).subscribe(
      (response) => {
        console.log('Image uploaded successfully', response);
        callback(true);
      },
      (error) => {
        console.error('Image upload failed', error);
        callback(false);
      }
    );
  }

  submit() {
    if (this.selectedFile !== null) {
      this.uploadImage(
        this.selectedFile,
        SelfData.account[0].data[0].id,
        (success: boolean) => {
          if (success) {
            this.yourappsService.reloadAvatarImage.next();
            // Image upload succeeded, do something
          } else {
            // Image upload failed, handle the error
          }
        }
      );
    }
    this.serviceservice
      .editaccount({
        firstname: this.firstNameFormControl.value?.trim(),
        lastname: this.lastNameFormControl.value?.trim(),
        accountid: SelfData.account[0].data[0].id,
      })
      .subscribe((loginAuth) => {
        if (!this.toppings.value.check1) {
          this.modalService.profileeditclose();
          this.serviceservice
            .reloaduserdate({
              id: SelfData.account[0].data[0].id,
            })
            .subscribe((loginAuth) => {
              this.iLoginResult[0] = loginAuth;
              SelfData.account = this.iLoginResult;
              this.localStorageService.setItem('myAccountArray', SelfData.account);
              this.yourappsService.setAccountName(
                SelfData.account[0].data[0].firstname +
                  ' ' +
                  SelfData.account[0].data[0].lastname
              );
              this.yourappsService.reloadDataRequest.next();
              this.yourappsService.reloadAvatarImage.next();
            });
        }
      });
    if (this.toppings.value.check1) {
      // Check if the current password is correct.
      if (!this.passwordFormControl.valid) {
        this.yourappsService.setOKOption(0);
        this.messageBox.modalService.openMessageBox(
          'Information',
          'The specified password is incorrect.',
          'Cancel',
          'OK'
        );
        return;
      }
      var currpassword: string = this.passwordFormControl.value?.trim() ?? '';
      if (
        this.hashService.md5(currpassword) !==
        SelfData.account[0].data[0].password
      ) {
        // do something if the passwords do not match
        this.yourappsService.setOKOption(0);
        this.messageBox.modalService.openMessageBox(
          'Information',
          'The specified password does not match the current password',
          'Cancel',
          'OK'
        );
        return;
      }
      // Check if the new password is valid.
      if (!this.newpasswordFormControl.valid) {
        this.yourappsService.setOKOption(0);
        this.messageBox.modalService.openMessageBox(
          'Information',
          'The new password is invalid.',
          'Cancel',
          'OK'
        );
        return;
      }

      // Check if the new password and confirmation password match.
      if (
        this.newpasswordFormControl.value !==
        this.conffirmpasswordFormControl.value
      ) {
        this.yourappsService.setOKOption(0);
        this.messageBox.modalService.openMessageBox(
          'Information',
          'The new password and confirmation password do not match.',
          'Cancel',
          'OK'
        );
        return;
      }

      // Submit the form.
      this.serviceservice
        .changepassword({
          ctrl: 0,
          accountid: SelfData.account[0].data[0].id,
          password: this.newpasswordFormControl.value?.trim(),
          email: SelfData.account[0].data[0].email,
          code: 0,
        })
        .subscribe((loginAuth) => {
          this.openSnackBar();
          this.modalService.codeopen();
          this.modalService.profileeditclose();
          this.serviceservice
            .reloaduserdate({
              id: SelfData.account[0].data[0].id,
            })
            .subscribe((loginAuth) => {
              this.iLoginResult[0] = loginAuth;
              SelfData.account = this.iLoginResult;
            });
        });
    }
  }
  openSnackBar() {
    // Get the email address from the account data.
    const email = SelfData.account[0].data[0].email;

    // Open the snack bar.
    this.messageService.add({
      key: 'tc',
      severity: 'warn',
      summary: 'Information',
      detail: `Your confirmation code has been sent by email to [${email}].
    Please check your email and enter the code when prompted.`,
      life: 86400000,
    });
    // this._snackBar.open(
    //   `Your confirmation code has been sent by email to [${email}].
    // Please check your email and enter the code when prompted.`,
    //   'Done',
    //   {
    //     horizontalPosition: this.horizontalPosition,
    //     verticalPosition: this.verticalPosition,
    //     duration: 10000,
    //   }
    // );
  }
  showOptions(event: MatCheckboxChange): void {
    console.dir(event.source.id);
    if (event.source.id === 'check1') {
      if (event.checked) {
        this.passwordFormControl.enable();
        this.newpasswordFormControl.enable();
        this.conffirmpasswordFormControl.enable();
      } else {
        this.passwordFormControl.disable();
        this.newpasswordFormControl.disable();
        this.conffirmpasswordFormControl.disable();
      }
    }
  }

  onFileSelected(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0) {
      const file = inputElement.files[0];
      this.compressImage(file);
    } else {
      this.selectedFile = null;
    }
  }

  compressImage(file: File) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const image = e.target.result;
      this.imageCompressService.compressFile(image, -1, 50, 30).then(
        (result: string) => {
          // Set the compressed image as the src for the image element
          const imageElement = document.querySelector(
            '.account-image1'
          ) as HTMLImageElement;
          imageElement.src = result;

          // Convert the compressed image (base64) back to a File object and set it to selectedFile
          this.selectedFile = this.base64ToFile(
            result,
            file.name,
            file.type
          );
        },
        (error: any) => {
          console.error('Error compressing image:', error);
        }
      );
    };
    reader.readAsDataURL(file);
  }

  base64ToFile(dataURI: string, fileName: string, mimeType: string): File {
    const byteString = atob(dataURI.split(',')[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    return new File([int8Array], fileName, { type: mimeType });
  }

  LoadAvatarImage() {
    const timestamp = new Date().getTime(); // Get the current timestamp
    if (SelfData.account[0]?.data[0]?.imageLink) {
      this.imageUrl = transformImageUrl(`${SelfData.account[0].data[0].imageLink}?${timestamp}`); // serveri
      // this.imageUrl = `${SelfData.account[0].data[0].imageLink}?${timestamp}`; // lokali
    } else {
      this.imageUrl = ''; // Set imageUrl to an empty string if imageLink is undefined
    }
  }
}
