import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { SelfData, SetSampleList } from '../../models/struct';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { YourappsserviceService } from '../../services/yourappsservice.service';
import { ModalserviceService } from '../../services/modalservice.service';
import { MessageboxComponent } from '../../modals/messagebox/messagebox.component';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-samplelistgrid',
  templateUrl: './samplelistgrid.component.html',
  styleUrls: ['./samplelistgrid.component.scss'],
})
export class SamplelistgridComponent implements AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator | null = null;
  @ViewChild('qrCode') qrCode!: ElementRef;
  showQRCode: boolean = false;
  iSampleDataResult: SetSampleList[] = [];
  selectedElement: any;
  qrcodedata: string = '';
  constructor(
    private cdRef: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private yourappsService: YourappsserviceService,
    public messageBox: MessageboxComponent,
    public modalService: ModalserviceService
  ) {
    this.iSampleDataResult = SelfData.sampledata;
  }

  getBackgroundImageStyle(url: string): SafeStyle {
    console.log(this.sanitizer.bypassSecurityTrustStyle(`url(${url})`));
    return this.sanitizer.bypassSecurityTrustStyle(`url(${url})`);
  }
  ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }

  onButtonDeleteClick(element: any) {
    this.yourappsService.setOKOption(2);
    SelfData.sampleselecteddata = element;
    this.messageBox.modalService.openMessageBox(
      'Information',
      'Are you sure you want to delete this entry?',
      'No',
      'Yes'
    );
  }

  onEditButtonClick(element: any) {
    SelfData.sampleselecteddata = element;
    SelfData.updatebuttonisenabled = true
    this.yourappsService.selectSampleData(element);
    this.modalService.createsampleropen();
  }
  printQRCode() {
    const printWindow = window.open('', '', 'width=800,height=600'); // Set the desired width and height

    if (printWindow) {
      printWindow.document.open();
      printWindow.document.write(
        `<html><head><title>Print QR Code</title></head><body>${this.qrCode.nativeElement.innerHTML}</body></html>`
      );
      printWindow.document.close();
      printWindow.focus();
      printWindow.print();
      printWindow.close();
    } else {
      console.error('Failed to open print window');
    }
  }

  setqrcodedata(value: string) {
    this.qrcodedata = value;
    console.log(value);
    this.printQRCode();
  }
}
