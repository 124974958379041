<button
  mat-mini-fab
  type="button"
  class="close-btn"
  (click)="modalService.closeimagezoom()"
>
  <mat-icon>close</mat-icon>
</button>

<div class="image-container">
  <img [src]="imageUrl" alt="Full Image" />
</div>
