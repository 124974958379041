import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CarouselComponent} from "smart-webcomponents-angular/carousel";
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder} from "@angular/forms";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {ConfirmationService, ConfirmEventType, MessageService} from "primeng/api";
import {DeviceDetectorService} from "ngx-device-detector";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {GetLBAccepts, SelfData, transformImageUrl} from "../../models/struct";

@Component({
    selector: 'app-createimageviews1dialog',
    templateUrl: './createimageviews1dialog.component.html',
    styleUrls: ['./createimageviews1dialog.component.scss']
})
export class Createimageviews1dialogComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('carousel', {read: CarouselComponent, static: false}) carousel!: CarouselComponent;
    images: any[] | undefined;
    isDesktopDevice: boolean = this.deviceService.isDesktop();
    responsiveOptions: any[] | undefined;
    isChecked: boolean = false;
    isDisabled = false;
    checkboxcaption: string = "Select";
    isVisibled: boolean = false;
    iLBAcceptsResult: GetLBAccepts[] = [];
    pageindex: number = 0
    selectedImages: {
        index: number;
        file: File | null;
        url: string;
        filename: string;
        lbcatalogid: number;
        colection: string;
        designer: string;
        catalog: string;
        catalogyear: number;
        seasoncode: string;
        oldchecked: boolean;
    }[] = []

    filteredImages: {
        index: number;
        file: File | null;
        url: string;
        filename: string;
        lbcatalogid: number;
        colection: string;
        designer: string;
        catalog: string;
        catalogyear: number;
        seasoncode: string;
        oldchecked: boolean;
    }[] = [];

    constructor(
        public modalService: ModalserviceService,
        public fb: FormBuilder,
        private serviceservice: ServiceService,
        private yourappsService: YourappsserviceService,
        private cdr: ChangeDetectorRef,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        private deviceService: DeviceDetectorService,
        private el: ElementRef
    ) {
        this.setDeviceClass();
        this.loadImageList(SelfData.tlbcatalogid)
    }

    setDeviceClass(): void {
        this.deviceClass = this.deviceService.isMobile() ? 'mobilemaincard' : 'pcmaincard';
        this.footerClass = this.deviceService.isMobile() ? 'mobilebutton-container-dm-ps' : 'pcbutton-container-dm-ps';
        this.deviceClassAddress = this.deviceService.isMobile() ? 'mobileaddressdiv' : 'pcaddressdiv';
        // this.isLandscape = window.innerWidth > window.innerHeight ? 'islandscape' : 'isnlandscape';
    }

    deviceClass: string = "";
    footerClass: string = "";
    deviceClassAddress: string = "";
    isLandscape: boolean = false;
    showdialog: boolean = true;

    loadImageList(lbcatalogid: number): void {
        this.selectedImages = []
        this.filteredImages = []
        if (SelfData.tselectedimagefiles.length > 0) {
            SelfData.tselectedimagefiles.forEach((imageinfo, index: number) => {
                const catalog = imageinfo.catalog;
                const catalogyear = imageinfo.catalogyear;
                const colection = imageinfo.colection;
                const designer = imageinfo.designer;
                const filedir = transformImageUrl(imageinfo.url);
                // const filedir = imageinfo.url;
                const filename = imageinfo.filename;
                const lbcatalogid = imageinfo.lbcatalogid;
                const seasoncode = imageinfo.seasoncode;
                const oldcheck = imageinfo.oldchecked;
                this.selectedImages.push({
                    index: index, // This line uses the index provided by forEach
                    filename: filename,
                    lbcatalogid: lbcatalogid,
                    url: filedir,
                    catalog: catalog,
                    catalogyear: catalogyear,
                    colection: colection,
                    designer: designer,
                    file: null,
                    seasoncode: seasoncode,
                    oldchecked: oldcheck
                });

                this.filteredImages.push({
                    index: index, // This line uses the index provided by forEach
                    filename: filename,
                    lbcatalogid: lbcatalogid,
                    url: filedir,
                    catalog: catalog,
                    catalogyear: catalogyear,
                    colection: colection,
                    designer: designer,
                    file: null,
                    seasoncode: seasoncode,
                    oldchecked: oldcheck
                });
            });
            this.selectedImages = this.selectedImages.map(item => ({
                ...item,
                index: Number(item.index) // Convert index to a number
            }));

            this.filteredImages = this.filteredImages.map(item => ({
                ...item,
                index: Number(item.index) // Convert index to a number
            }));
        }

        this.isVisibled = true
        if (this.findImageByIndex(0)) {
            this.isChecked = true
            if (this.findAlreadyImageByIndex(0)) {
                this.checkboxcaption = "Already sent"
                this.isDisabled = true
            } else {
                this.checkboxcaption = "Remove"
                this.isDisabled = false
            }
        } else {
            this.isChecked = false
            this.isDisabled = false
            this.checkboxcaption = "Select"
        }
        setTimeout(() => {
            this.init(this.selectedImages.length);
        });

    }

    init(imagecount: number): void {
        // init code.
        const that = this,
            basePath = './../../../src/images/';
        if (this.carousel) {
            this.carousel.dataSource = generateDataSource(imagecount, this.selectedImages);
        } else {
            console.error("Carousel is undefined or null");
        }

        function generateDataSource(items: number, selectedImageFiles: {
            file: File | null;
            url: string;
            filename: string;
            lbcatalogid: number;
            colection: string;
            designer: string;
            catalog: string;
            catalogyear: number;
            seasoncode: string
        }[]) {
            return Array(items).fill({}).map((element, index) => {
                // Use the image from selectedImageFiles if available; otherwise, use a placeholder
                var headlines: string = ""
                const imageUrl = index < selectedImageFiles.length && selectedImageFiles[index].file ? selectedImageFiles[index].url : selectedImageFiles[index].url;
                if (SelfData.lbmainlist[0].data[0].lbtype === 0) {
                    headlines = ""
                } else {
                    headlines = index < selectedImageFiles.length && selectedImageFiles[index].file ? selectedImageFiles[index].catalog : selectedImageFiles[index].catalog;
                }

                // return {image: imageUrl, label: headlines};
                return {image: transformImageUrl(imageUrl), label: headlines};
            });
        }
    }

    findAlreadyImageByIndex(index: number) {
        index = Number(index);
        let lbcatalogid = Number(this.selectedImages[index].lbcatalogid);
        let filename = this.selectedImages[index].filename;
        const imageIndex = this.filteredImages.findIndex(image => image.filename === filename && image.lbcatalogid === lbcatalogid && image.oldchecked);
        console.log("imageIndex", imageIndex)
        return this.filteredImages.find(image => image.filename === filename && image.lbcatalogid === lbcatalogid && image.oldchecked);
    }

    findImageByIndex(index: number) {
        index = Number(index);
        let lbcatalogid = Number(this.selectedImages[index].lbcatalogid);
        let filename = this.selectedImages[index].filename;
        return this.filteredImages.find(image => image.filename === filename && image.lbcatalogid === lbcatalogid);
    }

    removeImageByIndex(index: number) {
        index = Number(index);
        let lbcatalogid = Number(this.selectedImages[index].lbcatalogid);
        let filename = this.selectedImages[index].filename;
        // Find the index of the image in the selectedImages array
        const imageIndex = this.selectedImages.findIndex(image => image.filename === filename && image.lbcatalogid === lbcatalogid);
        if (imageIndex !== -1) {
            // If the image is found, remove it using splice
            this.filteredImages.splice(imageIndex, 1);
        }
    }

    ngAfterViewInit(): void {
        this.pageindex = 0
        this.carousel.addEventListener('changing', (event: Event) => {
            // Use type assertion to assert the event as a CustomEvent
            const customEvent = event as CustomEvent;
            const detail = customEvent.detail,
                index = detail.index,
                previousIndex = detail.previousIndex;
            this.pageindex = index

            console.log(this.pageindex)
            if (this.findImageByIndex(index)) {
                this.isChecked = true
                this.cdr.detectChanges()
                if (this.findAlreadyImageByIndex(index)) {
                    this.checkboxcaption = "Already sent"
                    this.isDisabled = true
                } else {
                    this.checkboxcaption = "Remove"
                    this.isDisabled = false
                }
            } else {
                this.isChecked = false
                this.checkboxcaption = "Select"
                this.isDisabled = false
            }
        });
    }

    ngOnDestroy(): void {
    }

    ngOnInit(): void {
        this.showdialog = true
    }

    onCheckboxChange(event: MatCheckboxChange) {
        if (event.checked) {
            this.checkboxcaption = "Remove"
            this.isDisabled = false
            const index = this.pageindex;
            const selectedFile = this.selectedImages[index];

            if (selectedFile) {
                const imageUrl = selectedFile.url;
                const imagefilename = selectedFile.filename;
                const lbcatalogid = selectedFile.lbcatalogid;
                const catalog = selectedFile.catalog;
                const catalogyear = selectedFile.catalogyear;
                const colection = selectedFile.colection;
                const designer = selectedFile.designer;
                const seasoncode = selectedFile.seasoncode;
                const oldcheck = selectedFile.oldchecked;

                this.filteredImages.push({
                    index: index,
                    filename: imagefilename,
                    lbcatalogid: lbcatalogid,
                    url: imageUrl,
                    catalog: catalog,
                    catalogyear: catalogyear,
                    colection: colection,
                    designer: designer,
                    file: null,
                    seasoncode: seasoncode,
                    oldchecked: oldcheck
                });

                this.filteredImages = this.filteredImages.map(item => ({
                    ...item,
                    index: Number(item.index) // Convert index to a number
                }));
            } else {
                // Handle the scenario where selectedFile is undefined
                console.error('Selected file not found at index', index);
            }
            this.messageService.add({
                key: 'tc',
                severity: 'success',
                summary: 'Success',
                detail: 'Item added to list',
                life: 2000,
            });
        } else {
            this.checkboxcaption = "Select"
            this.removeImageByIndex(this.pageindex)
            this.messageService.add({
                key: 'tc',
                severity: 'success',
                summary: 'Success',
                detail: 'Item removed from list',
                life: 2000,
            });
        }
    }

    onButtonCloseClick() {
        this.modalService.closeiv1frame()
    }

    onButtonConfirmClick() {
        this.confirmationService.confirm({
            message: `<strong>Save selected photos?</strong>`,
            header: 'Confirm & Save?',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.yourappsService.returnSelectedFiles(this.filteredImages, 1)
                this.modalService.closeiv1frame()
            },
            reject: (type: any) => {
                if (type == ConfirmEventType.REJECT) {

                } else if (type == ConfirmEventType.CANCEL) {
                }
            }

        });
        setTimeout(() => {
            this.applyLocalRestoreStyles();
        }, 0);
    }

    private applyLocalRestoreStyles() {
        setTimeout(() => {
            const messageElements = document.querySelectorAll('.p-confirm-dialog-message');
            messageElements.forEach((element) => {
                element.setAttribute('style', 'font-size: 16px; color: #343a40;');
            });
        }, 0);

        const iconElements = document.querySelectorAll('.p-confirm-dialog-icon');
        iconElements.forEach((element) => {
            element.setAttribute('style', 'color: #343a40');
        });
    }
}
