<div class="top-div">
  <div class="centered-div" #centeredDiv>
    <table class="full-width-table">
      <tr>
        <td>
          <mat-card class="custom-mat-card">
            <div class="top-left-text">{{ caption }}</div>
            <!-- Add the form below the top-left-text div -->

              <div class="example-full-width">
                <label class="line1-edits-new inputlabel">E-Mail</label>
                <nz-input-group [nzStatus]="statusemail" nzSize="default" type="" class="p-float-label line1-edits-new inputedit" [nzSuffix]="suffixIconSearch1">
                  <input type="email" [(ngModel)]="email" nz-input/>
                </nz-input-group>
                <ng-template #suffixIconSearch1>
                </ng-template>
              </div>

            <div class="example-full-width-subject">
              <label class="line1-edits-new inputlabel">Subject</label>
              <nz-input-group [nzStatus]="statussubject"  nzSize="default" type="" class="p-float-label line1-edits-new inputedit" [nzSuffix]="suffixIconSearch1">
                <input type="text" [(ngModel)]="subject" nz-input/>
              </nz-input-group>
              <ng-template #suffixIconSearch1>
              </ng-template>
            </div>
<!--              <mat-form-field class="example-full-width" appearance="fill">-->
<!--                <mat-label>Email</mat-label>-->
<!--                <input type="email" matInput [formControl]="emailFormControl" />-->
<!--                <mat-error-->
<!--                  *ngIf="-->
<!--                    emailFormControl.hasError('email') &&-->
<!--                    !emailFormControl.hasError('required')-->
<!--                  "-->
<!--                >-->
<!--                  Please enter a valid email address-->
<!--                </mat-error>-->
<!--                <mat-error *ngIf="emailFormControl.hasError('required')">-->
<!--                  Email is <strong>required</strong>-->
<!--                </mat-error>-->
<!--              </mat-form-field>-->
<!--              <mat-form-field-->
<!--                class="example-full-width-subject"-->
<!--                appearance="fill"-->
<!--              >-->
<!--                <mat-label>Subject</mat-label>-->
<!--                <input matInput [formControl]="subjectFormControl" />-->
<!--                <mat-error *ngIf="subjectFormControl.hasError('required')">-->
<!--                  Subject is <strong>Empty</strong>-->
<!--                </mat-error>-->
<!--              </mat-form-field>-->
            <div class="example-full-width-description">
              <label class="line1-edits-new inputlabel">Description</label><br>
              <textarea [nzStatus]="statusdescription" [(ngModel)]="description" rows="5" cols="30" class="line1-edits-new inputedit testdiv1 dopinfo"
                nz-input
              ></textarea>
            </div>
<!--              <mat-form-field-->
<!--                  class="example-full-width-description"-->
<!--                appearance="fill"-->
<!--              >-->
<!--                <mat-label>Description</mat-label>-->
<!--                <textarea-->
<!--                  matInput-->
<!--                  [formControl]="descriptionFormControl"-->
<!--                ></textarea>-->
<!--                <mat-error *ngIf="descriptionFormControl.hasError('required')">-->
<!--                  Description is <strong>Empty</strong>-->
<!--                </mat-error>-->
<!--              </mat-form-field>-->
            <mat-card-content class="truncate-text"> </mat-card-content>
            <div class="view-more-btn">
<!--              <button type="button" label="Submit" class="bgcolor bs1 button-settings-footer" pButton matRipple-->
<!--                (click)="onOKClick()">-->
<!--              </button>-->
<!--              <p-button label="Submit" severity="contrast" (click)="onOKClick()"/>-->
              <button nz-button nzSize="default" nzType="default" (click)="onOKClick()">Submit</button>
            </div>
<!--            <button mat-raised-button class="view-more-btn">Submit</button>-->
          </mat-card>
        </td>
      </tr>
    </table>
    <table class="full-width-tableEmpty">
      <tr>
        <td></td>
        <td></td>
      </tr>
    </table>
  </div>
</div>
