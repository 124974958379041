<form [formGroup]="myForm" (ngSubmit)="submit()" autocomplete="off">
  <button
    mat-icon-button
    type="button"
    class="close-btn"
    (click)="modalService.closeEditLabel()"
  >
    <mat-icon>close</mat-icon>
  </button>
  <p class="sample-manager-text">Edit Address Label</p>
  <div class="scrollable-div-label horizontal-container-label">
    <div class="black-background-lavel">
      <div class="example-full-width form-field-spacing-el">
        <mat-form-field class="selector-full-width" appearance="fill">
          <mat-label>Attn</mat-label>
          <input matInput [formControl]="attnContro" />
        </mat-form-field>

        <mat-form-field class="selector-full-width" appearance="fill">
          <mat-label>Care Of</mat-label>
          <input matInput [formControl]="careofContro" />
        </mat-form-field>

        <mat-form-field class="selector-full-width" appearance="fill">
          <mat-label>Company Name</mat-label>
          <input matInput [formControl]="companyname" />
        </mat-form-field>

        <mat-form-field class="selector-full-width" appearance="fill">
          <mat-label>Address1</mat-label>
          <input matInput [formControl]="addressaddress1" />
        </mat-form-field>

        <mat-form-field class="selector-full-width" appearance="fill">
          <mat-label>Address2</mat-label>
          <input matInput [formControl]="addressaddress2" />
        </mat-form-field>

        <mat-form-field class="selector-full-width" appearance="fill">
          <mat-label>Direct Telephone</mat-label>
          <input matInput [formControl]="mobile" />
        </mat-form-field>

        <mat-form-field class="selector-full-width" appearance="fill">
          <mat-label>Email</mat-label>
          <input matInput type="email" [formControl]="email" />
        </mat-form-field>

        <mat-form-field class="selector-full-width" appearance="fill">
          <mat-label>Tax ID</mat-label>
          <input matInput [formControl]="taxid" />
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="button-container-label">
    <!--    <button mat-raised-button class="button-settings" type="button">-->
    <!--      Cancel-->
    <!--    </button>-->
    <!--        <button mat-raised-button class="button-settings" type="submit" *ngIf="isVisible">Create & Add</button>-->
    <button mat-raised-button class="button-settings" type="submit">
      Update
    </button>
  </div>
</form>
