import { Component } from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";

@Component({
  selector: 'app-pullsheettemplate',
  templateUrl: './pullsheettemplate.component.html',
  styleUrls: ['./pullsheettemplate.component.scss']
})
export class PullsheettemplateComponent {
  constructor(public modalService: ModalserviceService) {}
}
