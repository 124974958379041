import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder} from "@angular/forms";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ConfirmationService, ConfirmEventType, MessageService} from "primeng/api";
import {NgxImageCompressService} from "ngx-image-compress";
import { HttpClient } from "@angular/common/http";
import {
    GetLBMain,
    GetLBMainData, LbCatalogData,
    LbCatalogDetails, LbMainData, LbMainDetails,
    SelfData
} from "../../models/struct";
import {Table} from "primeng/table";
import {Subscription} from "rxjs";

@Component({
    selector: 'app-createlbdialog',
    templateUrl: './createlbdialog.component.html',
    styleUrls: ['./createlbdialog.component.scss']
})
export class CreatelbdialogComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('dt1') dt1!: Table;
    filtertext: string = ""
    showdialog: boolean = false
    datasource: GetLBMainData[] = [];
    currancecatalog: GetLBMainData[] = [];
    ilbMainResult: GetLBMain[] = [];

    private getAccountID(): number {
        return SelfData.account[0].data[0].parentid === 0
            ? SelfData.account[0].data[0].id
            : SelfData.account[0].data[0].parentid;
    }

    constructor(
        public modalService: ModalserviceService,
        public fb: FormBuilder,
        private serviceservice: ServiceService,
        private yourappsService: YourappsserviceService,
        private _snackBar: MatSnackBar,
        private cdr: ChangeDetectorRef,
        private messageService: MessageService,
        private imageCompressService: NgxImageCompressService,
        private confirmationService: ConfirmationService,
        private http: HttpClient,
        private el: ElementRef
    ) {
        this.serviceservice
            .selectlbmain({accountid: this.getAccountID()})
            .subscribe((value) => {
                this.ilbMainResult[0] = value;
                SelfData.lbmain = this.ilbMainResult;
                this.datasource = SelfData.lbmain[0].data
            });
    }

    onInput(event: Event) {
        const inputElement = event.target as HTMLInputElement;
        const value = inputElement.value;
        this.dt1.filterGlobal(value, 'contains');
    }

    clear(table: Table) {
        table.clear();
        this.filtertext = ""
    }


    ngAfterViewInit(): void {
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    ngOnInit(): void {
        this.showdialog = true
    }

    onButtonCloseClick() {
        this.modalService.closelb()
    }

    onButtonEditClick(item: any) {
        this.showdialog = false
        SelfData.lbmode = item.id
        SelfData.lbdescription = item.description
        if (item.lbtype === 0) {
            this.subscription = this.yourappsService.reloadclblist.subscribe((value) => {
                if (this.subscription) {
                    this.subscription.unsubscribe();
                    this.serviceservice
                        .selectlbmain({accountid: this.getAccountID()})
                        .subscribe((value) => {
                            this.ilbMainResult[0] = value;
                            SelfData.lbmain = this.ilbMainResult;
                            this.datasource = SelfData.lbmain[0].data
                            this.messageService.add({
                                key: 'tc',
                                severity: 'success',
                                summary: 'Success',
                                detail: 'Completes successfully',
                                life: 5000,
                            });
                        });
                }
            });
            this.modalService.openlbframe()
        } else if (item.lbtype === 1) {
            this.yourappsService.setReturnedImages(null, 0)
            this.yourappsService.setLCItem(item, 1)
            this.subscription = this.yourappsService.reloadclblist.subscribe((value) => {
                if (this.subscription) {
                    this.subscription.unsubscribe();
                    this.serviceservice
                        .selectlbmain({accountid: this.getAccountID()})
                        .subscribe((value) => {
                            this.ilbMainResult[0] = value;
                            SelfData.lbmain = this.ilbMainResult;
                            this.datasource = SelfData.lbmain[0].data
                            this.messageService.add({
                                key: 'tc',
                                severity: 'success',
                                summary: 'Success',
                                detail: 'Completes successfully',
                                life: 5000,
                            });
                        });
                }
            });
            this.modalService.openvcframe()
        }
    }

    onButtonViewClick(item: any) {
        this.showdialog = false
        SelfData.lbmode = item.id
        SelfData.lbdescription = item.description
        if (item.lbtype === 0) {
            this.subscription = this.yourappsService.reloadclblist.subscribe((value) => {
                if (this.subscription) {
                    this.subscription.unsubscribe();
                    this.serviceservice
                        .selectlbmain({accountid: this.getAccountID()})
                        .subscribe((value) => {
                            this.ilbMainResult[0] = value;
                            SelfData.lbmain = this.ilbMainResult;
                            this.datasource = SelfData.lbmain[0].data
                            this.messageService.add({
                                key: 'tc',
                                severity: 'success',
                                summary: 'Success',
                                detail: 'Completes successfully',
                                life: 5000,
                            });
                        });
                }
            });
            this.modalService.openlbframe()
        } else if (item.lbtype === 1) {
            this.yourappsService.setReturnedImages(null, 0)
            this.yourappsService.setLCItem(item, 2)
            this.subscription = this.yourappsService.reloadclblist.subscribe((value) => {
                if (this.subscription) {
                    this.subscription.unsubscribe();
                    this.serviceservice
                        .selectlbmain({accountid: this.getAccountID()})
                        .subscribe((value) => {
                            this.ilbMainResult[0] = value;
                            SelfData.lbmain = this.ilbMainResult;
                            this.datasource = SelfData.lbmain[0].data
                            this.messageService.add({
                                key: 'tc',
                                severity: 'success',
                                summary: 'Success',
                                detail: 'Completes successfully',
                                life: 5000,
                            });
                        });
                }
            });
            this.modalService.openvcframe()
        }
    }

    onDeleteButtonClick(item: any) {
        this.showdialog = true
        this.cdr.detectChanges()
        this.confirmationService.confirm({
            message: `<strong>Are you sure you want to delete this look book?</strong>`,
            header: 'Delete Catalog',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                const catalogDetails: LbMainDetails = {
                    id: item.id,
                    description: "",
                    fields: "",
                    accountid: this.getAccountID(),
                    isactivity: false,
                    ctrl: 2,
                    lbtype: 0
                };
                this.serviceservice.prclbmain(catalogDetails).subscribe({
                    next: (value: LbMainData) => {
                        this.serviceservice
                            .selectlbmain({accountid: this.getAccountID()})
                            .subscribe((value) => {
                                this.ilbMainResult[0] = value;
                                SelfData.lbmain = this.ilbMainResult;
                                this.datasource = SelfData.lbmain[0].data
                                this.messageService.add({
                                    key: 'tc',
                                    severity: 'success',
                                    summary: 'Success',
                                    detail: 'Completes successfully',
                                    life: 5000,
                                });
                            });
                    },
                    error: (error: any) => {
                        console.error('Error occurred:', error);
                    },
                    complete: () => {
                        console.log('Subscription completed');
                    }
                });
            },
            reject: (type: any) => {
                if (type == ConfirmEventType.REJECT) {

                } else if (type == ConfirmEventType.CANCEL) {
                }
            }

        });
        setTimeout(() => {
            this.applyLocalStyles();
        }, 0);
    }

    private applyLocalStyles() {
        setTimeout(() => {
            const messageElements = document.querySelectorAll('.p-confirm-dialog-message');
            messageElements.forEach((element) => {
                element.setAttribute('style', 'font-size: 16px; color: red;');
            });
        }, 0);

        const iconElements = document.querySelectorAll('.p-confirm-dialog-icon');
        iconElements.forEach((element) => {
            element.setAttribute('style', 'color: red');
        });
    }

    private subscription?: Subscription;

    onButtonAddClick() {
        this.showdialog = false
        SelfData.lbmode = 0
        SelfData.lbdescription = ""
        this.subscription = this.yourappsService.reloadclblist.subscribe((value) => {
            if (this.subscription) {
                this.subscription.unsubscribe();
                this.serviceservice
                    .selectlbmain({accountid: this.getAccountID()})
                    .subscribe((value) => {
                        this.ilbMainResult[0] = value;
                        SelfData.lbmain = this.ilbMainResult;
                        this.datasource = SelfData.lbmain[0].data
                        this.messageService.add({
                            key: 'tc',
                            severity: 'success',
                            summary: 'Success',
                            detail: 'Completes successfully',
                            life: 5000,
                        });
                    });
            }
        });
        this.modalService.openlbframe()
    }

    onButtonCustomImageClick(){
        this.showdialog = false
        SelfData.lbmode = 0
        SelfData.lbdescription = ""
        this.subscription = this.yourappsService.reloadclblist.subscribe((value) => {
            if (this.subscription) {
                this.subscription.unsubscribe();
                this.serviceservice
                    .selectlbmain({accountid: this.getAccountID()})
                    .subscribe((value) => {
                        this.ilbMainResult[0] = value;
                        SelfData.lbmain = this.ilbMainResult;
                        this.datasource = SelfData.lbmain[0].data
                        this.messageService.add({
                            key: 'tc',
                            severity: 'success',
                            summary: 'Success',
                            detail: 'Completes successfully',
                            life: 5000,
                        });
                    });
            }
        });
        this.yourappsService.setSelectedImages(null, 0)
        this.modalService.openciframe()
    }

    protected readonly SelfData = SelfData;

    onSelectButtonClick(catalog: any) {

        this.confirmationService.confirm({
            message: `<strong>Would you like to select this Look Book?</strong>`,
            header: 'Select Look Book',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.yourappsService.selectedLookBook(catalog, 1)
                this.modalService.closelb()
            },
            reject: (type: any) => {
                if (type == ConfirmEventType.REJECT) {

                } else if (type == ConfirmEventType.CANCEL) {
                }
            }

        });
        setTimeout(() => {
            this.applyLocalRestoreStyles();
        }, 0);


    }

    private applyLocalRestoreStyles() {
        setTimeout(() => {
            const messageElements = document.querySelectorAll('.p-confirm-dialog-message');
            messageElements.forEach((element) => {
                element.setAttribute('style', 'font-size: 16px; color: #343a40;');
            });
        }, 0);

        const iconElements = document.querySelectorAll('.p-confirm-dialog-icon');
        iconElements.forEach((element) => {
            element.setAttribute('style', 'color: #343a40');
        });
    }

  onInfoButtonClick() {
    window.open('https://fm.welcomeapps.net/FAQ/LookBookList.html', '_blank');
  }
}
