<p-button class="info-btn" icon="pi pi-info" [rounded]="true" [outlined]="true" (click)="onInfoButtonClick()"/>
<button mat-icon-button type="button" class="close-btn" (click)="modalService.closePullSheetTemplate()">
    <mat-icon>close</mat-icon>
</button>
<p class="sample-manager-text">Template Settings</p>

<div class="black-background-contact">
    <div class="full-width-caption">
        <div class="captionposition">
            <p class="caption-text">Main Information</p>
            <p class="caption-text">Logo</p>
        </div>
        <div class="full-width-div-contact">
            <div class="mainrow-user">
                <div class="child-1-user">
                    <div class="row">
                        <div class="forma-group">
                            <mat-form-field class="example-full-width" appearance="fill">
                                <mat-label>PR-Manager</mat-label>
                                <input type="text" matInput [formControl]="prmanagerFormControl"
                                       placeholder="Enter name"/>
                            </mat-form-field>
                        </div>

                        <div class="forma-group">
                            <mat-form-field class="example-full-width" appearance="fill">
                                <mat-label>Phone</mat-label>
                                <input type="tel" matInput [formControl]="phoneFormControl"
                                       placeholder="Enter phone"/>
                            </mat-form-field>
                        </div>

                        <div class="forma-group">
                            <mat-form-field class="example-full-width" appearance="fill">
                                <mat-label>Email</mat-label>
                                <input type="email" matInput [formControl]="emailFormControl"
                                       placeholder="Enter email"/>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="forma-group">
                            <mat-form-field class="example-full-width" appearance="fill">
                                <mat-label>PR-Assistant</mat-label>
                                <input type="text" matInput [formControl]="prassistentFormControl"
                                       placeholder="Enter name"/>
                            </mat-form-field>
                        </div>

                        <div class="forma-group">
                            <mat-form-field class="example-full-width" appearance="fill">
                                <mat-label>Phone</mat-label>
                                <input type="tel" matInput [formControl]="assistentphoneFormControl"
                                       placeholder="Enter phone"/>
                            </mat-form-field>
                        </div>

                        <div class="forma-group">
                            <mat-form-field class="example-full-width" appearance="fill">
                                <mat-label>Email</mat-label>
                                <input type="email" matInput [formControl]="assistentemailFormControl"
                                       placeholder="Enter email"/>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="child-2-user">
                    <div class="account-image-container shadow-4">
                        <img *ngIf="imageUrl === ''" class="account-image1" src="assets/noimage.png" alt="noimage"/>
                        <img *ngIf="imageUrl !== ''" class="account-image1" [src]="imageUrl" alt="Avatar"/>
                        <input type="file" (change)="onFileSelected($event)" accept="image/*" style="display: none"
                               #fileInput/>
                        <p class="m-0">
                            <button mat-icon-button type="button" class="close-btn" (click)="fileInput.click()">
                                <mat-icon>add_photo_alternate</mat-icon>
                            </button>
                            <!--                <span class="p-inputgroup-addon button-round-hover" matRipple (click)="fileInput.click()">-->
                            <!--                    <i class="pi pi-image button-font-size" style="color: #ffffff"></i></span>-->
                        </p>
                        <!--            <button mat-icon-button type="button" class="icon-btn" (click)="fileInput.click()">-->
                        <!--                <mat-icon>edit</mat-icon>-->
                        <!--            </button>-->
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<div class="black-background-contact1">
    <div class="full-width-caption">
        <div class="captionposition">
            <p class="caption-text">Payment</p>
        </div>
        <div class="full-width-div-contact">
            <div class="row">
                <div class="forma-group">
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Late Fees</mat-label>
                        <input type="" matInput [formControl]="value1FormControl"
                               placeholder="Enter amount"/>
                    </mat-form-field>
                    <p class="firsttext"> per day</p>
                </div>

                <div class="forma-group">
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Dry-Cleaning Fee</mat-label>
                        <input type="" matInput [formControl]="value2FormControl"
                               placeholder="Enter amount"/>
                    </mat-form-field>
                    <p class="firsttext"> per item</p>
                </div>

                <div class="forma-group">
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Minor / Total Damage Fee</mat-label>
                        <input type="" matInput [formControl]="value3FormControl"
                               placeholder="Enter value"/>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="forma-group1">
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Lost Item Fee:</mat-label>
                        <input type="" matInput [formControl]="value4FormControl"
                               placeholder="Enter value"/>
                    </mat-form-field>
                </div>

                <div class="forma-group1">
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label> Missing Garment Bags & Tote Bags</mat-label>
                        <input type="" matInput [formControl]="value5FormControl"
                               placeholder="Enter amount"/>
                    </mat-form-field>
                    <p class="firsttext">per bag</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="black-background-contact2">
    <div class="full-width-caption">
        <div class="captionposition">
            <p class="caption-text">Social Media</p>
        </div>
        <div class="full-width-div-contact">
            <div class="row">
                <div class="forma-group">
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Instagram</mat-label>
                        <input type="text" matInput [formControl]="value6FormControl"
                               placeholder="Enter Instagram address"/>
                    </mat-form-field>
                </div>
                <div class="forma-group">
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>FaceBook</mat-label>
                        <input type="text" matInput [formControl]="value7FormControl"
                               placeholder="Enter FaceBook address"/>
                    </mat-form-field>
                </div>
                <div class="forma-group">
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>TikTok</mat-label>
                        <input type="text" matInput [formControl]="value8FormControl"
                               placeholder="Enter TikTok address"/>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="forma-group">
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>X (Twitter)</mat-label>
                        <input type="text" matInput [formControl]="value9FormControl"
                               placeholder="Enter X (Twitter) address"/>
                    </mat-form-field>
                </div>
                <div class="forma-group">
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Web Site</mat-label>
                        <input type="text" matInput [formControl]="value10FormControl"
                               placeholder="Enter Web Site address"/>
                    </mat-form-field>
                </div>
                <div class="forma-group">
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Online store</mat-label>
                        <input type="text" matInput [formControl]="value11FormControl"
                               placeholder="Enter Online store address"/>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
</div>

<button mat-raised-button class="view-more-btn1" (click)="onSubmit()">
    Save
</button>
<mat-divider class="card-bottom-divider"></mat-divider>
