import { Component } from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";

@Component({
  selector: 'app-srframe',
  templateUrl: './srframe.component.html',
  styleUrl: './srframe.component.scss'
})
export class SrframeComponent {
  constructor(public modalService: ModalserviceService) {}
}
