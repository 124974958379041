<button mat-icon-button type="button" class="close-btn" (click)="onCloseButtonClick()">
  <mat-icon>close</mat-icon>
</button>
<p class="sample-manager-customer">{{ caption }}</p>
<div class="maindiv">
  <div class="headerdiv1">
    <div class="headerdiv">
      <!--          <p-button label="Add outlet" icon="pi pi-plus" [rounded]="false" [outlined]="true" />-->
      <button nz-button nzType="default" (click)="onAddProfileClick()">Add Profile</button>
      <ng-template #suffixTemplateInfo>
        <span nz-icon nzType="search"></span>
      </ng-template>
    </div>
  </div>
  <div class="componentsdiv1-2">
    <div class="componentsdiv2-1">
      <p-table
        [value]="profilesResult"
        [paginator]="false"
        [rows]="10000"
        [showCurrentPageReport]="false"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [styleClass]="'p-datatable-striped mat-elevation-z1 ' + (selectedSize?.class || '')"
        selectionMode="single"
        [tableStyle]="{'min-width': '21.5rem'}">

        <ng-template pTemplate="header">
          <tr>
            <th style="width: 15%">Profile Name</th>
            <th style="width: 2%"></th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-product>
          <tr>

            <td>
              {{ product.description }}
            </td>

            <td>
              <button nz-button nzType="text" nzSize = "small" nzShape="circle" (click)="editProfile(product)">
                <span nz-icon [nzType]="'edit'" [nzTheme]="'fill'"></span>
              </button>
              <button nz-button nzType="text" nzSize="small" nzShape="circle" (click)="deleteProfile(product)">
                <span nz-icon [nzType]="'delete'" [nzTheme]="'fill'" style="color: red;"></span>
              </button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
