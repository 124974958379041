import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  GetCelebritiList,
  GetScannedList, GetSendOutList,
  GetSendOutListData,
  GetSendOutReturnList,
  GetSocialMediaList,
  GetSocialMediaListData,
  PrcCreateSendOutToRequestResponse,
  SelfData, SendOut1Data,
  SetSampleListData, Speener
} from "../../models/struct";
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder} from "@angular/forms";
import {HashService} from "../../services/hash.service";
import {MessageboxComponent} from "../../modals/messagebox/messagebox.component";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {DatePipe} from "@angular/common";
import {ConfirmationService, ConfirmEventType, MessageService} from "primeng/api";
import {MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {Table} from "primeng/table";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-sendoutlist',
  templateUrl: './sendoutlist.component.html',
  styleUrls: ['./sendoutlist.component.scss'],
  providers: [DatePipe]
})
export class SendoutlistComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator | null = null;
  @ViewChild('dt1') dt1!: Table;
  filtertext: string = ""
  dataSource = new MatTableDataSource<GetSendOutListData>(SelfData.sendoutlist[0].data);
  selectedElement: any;
  defaultimagelink: string = ""
  defaultname: string = ""
  progressSpinnerShow: Boolean = true
  showdialog: boolean = false;
  displayedColumns: string[] = [
    'sc',
    'actions',
    'Designer',
    'BarCode',
    'Collection',
    'Season',
    'Type',
    'Color',
  ];

  sizes!: any[];

  selectedSize: any = '';

  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private hashService: HashService,
    private serviceservice: ServiceService,
    private yourappsService: YourappsserviceService,
    private cd: ChangeDetectorRef,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private datePipe: DatePipe
  ) {
    this.sizes = [
      {name: 'Small', class: 'p-datatable-sm'}
    ];
    this.selectedSize = this.sizes[0]
    this.dataSource = new MatTableDataSource<GetSendOutListData>(
      SelfData.sendoutlist[0].data
    );
  }

  formatDate(date: string): string {
    return <string>this.datePipe.transform(date, 'dd/MMM/yy hh:mm a', 'UTC');
  }

  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
      ? SelfData.account[0].data[0].id
      : SelfData.account[0].data[0].parentid;
  }

  public get setdataSource() {
    return this.dataSource;
  }


  ngAfterViewInit(): void {
    this.dataSource = new MatTableDataSource<GetSendOutListData>(
      SelfData.sendoutlist[0].data
    );
    this.dataSource.paginator = this.paginator;
  }


  ngOnInit(): void {
    console.log("SelfData.sendoutlist", SelfData.sendoutlist[0].data)
    this.yourappsService.reloadSendoutList$.subscribe((value) => {
      console.log("Received value:", value.type);
      if (value.type === 1) {
        this.modalService.closespr()
        this.modalService.closeso();
        SelfData.selectsendoutmode = 0
        Speener.sendoutvisiblemode = true
        this.serviceservice
          .getSendOutlist({accountid: this.getAccountID()})
          .subscribe((value) => {
            this.iSendOutList1[0] = value;
            Speener.sendoutvisiblemode = false
            SelfData.sendoutlist = this.iSendOutList1;
            console.log("SelfData.sendoutlist", SelfData.sendoutlist[0].data)
            this.yourappsService.getReloadSendOutList(null, 0)
          });
      }
    });
  }

  onInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;
    this.dt1.filterGlobal(value, 'contains');
  }

  clear(table: Table) {
    table.clear();
    this.filtertext = ""
  }

  private subscription2?: Subscription;
  iSendOutList1: GetSendOutList[] = [];

  createSendOut(element: any) {
    SelfData.sendoutopentype = 1;
    SelfData.sendoutstep = 0;
    SelfData.sendoutmode = 0
    SelfData.saveonclose = true
    SelfData.fullPage = true
    SelfData.sendoutid = element?.["id"]
    SelfData.sendoutgroupecaption = "Send Out Details";
    SelfData.scannedlist = [{code: 0, errorMessage: '', data: []}];
    this.serviceservice
      .selectsendoutsamples({
        sendoutid: element.id,
      })
      .subscribe((value1) => {
        this.subscription2 = this.yourappsService.reloadSendoutList$.subscribe((value) => {
          console.log("Received value:", value.type);
          if (this.subscription2) {
            this.subscription2.unsubscribe();
          }
          if (value.type === 1) {
            this.modalService.closespr()
            this.modalService.closeso();
            SelfData.selectsendoutmode = 0
            this.modalService.sendoutlistclose();
            Speener.sendoutvisiblemode = true
            this.serviceservice
              .getSendOutlist({accountid: this.getAccountID()})
              .subscribe((value) => {
                this.iSendOutList1[0] = value;
                Speener.sendoutvisiblemode = false
                this.modalService.sendoutlistopen();
                SelfData.sendoutlist = this.iSendOutList1;
              });
            this.modalService.samplemanageropen();
            this.yourappsService.setSampleAndContavcts(false, false, true, false, false, false, false, false, false);
          }
        });
        this.iSendOutList[0] = value1;
        SelfData.scannedlist = this.iSendOutList;
        console.log("SelfData.scannedlist", SelfData.scannedlist)
        if (SelfData.scannedlist[0].data.length > 0) {
          this.selectedsample = []
          for (const item of SelfData.scannedlist[0].data) {
            const newSample: SetSampleListData = {
              "ID": item["ID"],
              "Inventory Status": item["Inventory Status"],
              "Gender": item["Gender"],
              "Sample Workflow Status": item["Sample Workflow Status"],
              "Account ID": item["accountid"],
              "Region": item["Region"],
              "Notes": item["Notes"],
              "Designer": item["Designer"],
              "Fabric": item["Fabric"],
              "Manufacturer": item["Manufacturer"],
              "Collection": item["Collection"],
              "Style": item["Style"],
              "Looks Associated": item["Looks Associated"],
              "Season": item["Season"],
              "Misc": item["Misc"],
              "Quantity": item["Quantity"],
              "Type": item["Type"],
              "Size": item["Size"],
              "Size Range": item["Size Range"],
              "Pattern": item["Pattern"],
              "Color": item["Color"],
              "Color Code": item["colorcode"],
              "Retail Price EUR": item["Retail Price EUR"],
              "Retail Price USD": item["Retail Price USD"],
              "Wholesale EUR": item["Wholesale EUR"],
              "Wholesale USD": item["Wholesale USD"],
              "Sale Price EUR": item["Sale Price EUR"],
              "Sale Price USD": item["Sale Price USD"],
              "Prices ID": item["pricesid"],
              "Description": item["Description"],
              "Storage ID": item["storageid"],
              "Registration Date": item["regdate"],
              "Current Date": item["curdate"],
              "User ID": item["userid"],
              "Is Active": item["isactiviti"],
              "Image Link": item["Image Link"],
              "barcode": item["barcode"],
              "User": item["user"],
              "Barcode Prefix": item["barcodeprefix"],
              "Selected Sample ID": item["selectedsampleid"],
              "itemcount": item["itemcount"] || 1
            };
            this.selectedsample.push(newSample);

          }
          // ... any other operations you need to perform on items
          // }
          SelfData.setSelectedSample = this.selectedsample
        }
        this.yourappsService.selectedSendOut(element);
        this.modalService.createfullsendoutopen()
        // this.modalService.createsendoutopen();
      });

    // this.yourappsService.setSampleAndContavcts(false, false, true)
  }

  iSendOutList: GetScannedList[] = [];

  createSendOutSamplse(element: any) {
    SelfData.sendoutopentype = 1
    SelfData.sendoutstep = 1;
    SelfData.sendoutmode = 0
    SelfData.saveonclose = false
    SelfData.sendoutid = element?.["id"]
    SelfData.sendoutgroupecaption = "Send Out Details";
    SelfData.scannedlist = [{code: 0, errorMessage: '', data: []}];
    this.serviceservice
      .selectsendoutsamples({
        sendoutid: element.id,
      })
      .subscribe((value) => {
        this.iSendOutList[0] = value;
        SelfData.scannedlist = this.iSendOutList;
        this.yourappsService.selectedSendOut(element);
        this.modalService.createsendoutopen();
      });
  }

  createRequestList(item: any) {
    console.log("item", item)
    if (item.sendoutid > 0) {
      this.messageService.add({
        key: 'tc',
        severity: 'success',
        summary: 'Information',
        // detail: `Sample ${barcodeValue} already scanned by user ${foundData['user']} for a new delivery or reservation.`,
        detail: `A Request has already been created on this Send Out`,
        life: 10000,
      });
      return
    }
    this.showdialog = true
    this.cd.detectChanges()
    this.confirmationService.confirm({
      message: `<strong>Do you want to create a Request?</strong>`,
      header: 'Information',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.serviceservice.prcCreateSendOutToRequest(item?.id).subscribe({
          next: (response: PrcCreateSendOutToRequestResponse) => {
            console.log('Response:', response);
            this.messageService.add({
              key: 'tc',
              severity: 'success',
              summary: 'Information',
              // detail: `Sample ${barcodeValue} already scanned by user ${foundData['user']} for a new delivery or reservation.`,
              detail: `Request created successfully`,
              life: 10000,
            });
            // Handle the response
          },
          error: (error) => {
            console.error('Error:', error);
            // Handle the error
          }
        });

      },
      reject: (type: any) => {
        if (type == ConfirmEventType.REJECT) {
        } else if (type == ConfirmEventType.CANCEL) {
        }
      }
    })
  }

  selectedsample: SetSampleListData[] = [];

  createSendOutPrint(element: any) {
    SelfData.selectadpage = 3
    SelfData.sendoutopentype = 1
    SelfData.sendoutstep = 2;
    SelfData.saveonclose = false
    SelfData.sendoutid = element?.["id"]
    this.serviceservice
      .selectsendoutsamples({
        sendoutid: element.id,
      })
      .subscribe((value) => {
        console.log("element",element)
        this.iSendOutList[0] = value;
        SelfData.scannedlist = this.iSendOutList;
        this.yourappsService.selectedSendOut(element);
        this.modalService.createsendoutopen();

        if (SelfData.scannedlist[0].data.length > 0) {
          this.selectedsample = []
          for (const item of SelfData.scannedlist[0].data) {
            const newSample: SetSampleListData = {
              "ID": item["ID"],
              "Inventory Status": item["Inventory Status"],
              "Gender": item["Gender"],
              "Sample Workflow Status": item["Sample Workflow Status"],
              "Account ID": item["accountid"],
              "Region": item["Region"],
              "Notes": item["Notes"],
              "Designer": item["Designer"],
              "Fabric": item["Fabric"],
              "Manufacturer": item["Manufacturer"],
              "Collection": item["Collection"],
              "Style": item["Style"],
              "Looks Associated": item["Looks Associated"],
              "Season": item["Season"],
              "Misc": item["Misc"],
              "Quantity": item["Quantity"],
              "Type": item["Type"],
              "Size": item["Size"],
              "Size Range": item["Size Range"],
              "Pattern": item["Pattern"],
              "Color": item["Color"],
              "Color Code": item["colorcode"],
              "Retail Price EUR": item["Retail Price EUR"],
              "Retail Price USD": item["Retail Price USD"],
              "Wholesale EUR": item["Wholesale EUR"],
              "Wholesale USD": item["Wholesale USD"],
              "Sale Price EUR": item["Sale Price EUR"],
              "Sale Price USD": item["Sale Price USD"],
              "Prices ID": item["pricesid"],
              "Description": item["Description"],
              "Storage ID": item["storageid"],
              "Registration Date": item["regdate"],
              "Current Date": item["curdate"],
              "User ID": item["userid"],
              "Is Active": item["isactiviti"],
              "Image Link": item["Image Link"],
              "barcode": item["barcode"],
              "User": item["user"],
              "Barcode Prefix": item["barcodeprefix"],
              "Selected Sample ID": item["selectedsampleid"],
              "itemcount": item["itemcount"] || 1
            };
            this.selectedsample.push(newSample);

          }
          // ... any other operations you need to perform on items
          // }
          SelfData.setSelectedSample = this.selectedsample
        }
      });
  }

  createsendoutpurchase(element: any) {
    SelfData.selcurrancy = element["currancy"]
    if (element?.["sendouttypeid"] == 3) {
      this.serviceservice
        .selectsendoutsamples({
          sendoutid: element.id,
        })
        .subscribe((value) => {
          this.iSendOutList[0] = value;
          SelfData.scannedlist = this.iSendOutList;
          this.yourappsService.selectedSendOut(element);
          if (SelfData.scannedlist[0].data.length > 0) {
            this.selectedsample = []
            for (const item of SelfData.scannedlist[0].data) {
              const newSample: SetSampleListData = {
                "ID": item["ID"],
                "Inventory Status": item["Inventory Status"],
                "Gender": item["Gender"],
                "Sample Workflow Status": item["Sample Workflow Status"],
                "Account ID": item["accountid"],
                "Region": item["Region"],
                "Notes": item["Notes"],
                "Designer": item["Designer"],
                "Fabric": item["Fabric"],
                "Manufacturer": item["Manufacturer"],
                "Collection": item["Collection"],
                "Style": item["Style"],
                "Looks Associated": item["Looks Associated"],
                "Season": item["Season"],
                "Misc": item["Misc"],
                "Quantity": item["Quantity"],
                "Type": item["Type"],
                "Size": item["Size"],
                "Size Range": item["Size Range"],
                "Pattern": item["Pattern"],
                "Color": item["Color"],
                "Color Code": item["colorcode"],
                "Retail Price EUR": item["Retail Price EUR"],
                "Retail Price USD": item["Retail Price USD"],
                "Wholesale EUR": item["Wholesale EUR"],
                "Wholesale USD": item["Wholesale USD"],
                "Sale Price EUR": item["Sale Price EUR"],
                "Sale Price USD": item["Sale Price USD"],
                "Prices ID": item["pricesid"],
                "Description": item["Description"],
                "Storage ID": item["storageid"],
                "Registration Date": item["regdate"],
                "Current Date": item["curdate"],
                "User ID": item["userid"],
                "Is Active": item["isactiviti"],
                "Image Link": item["Image Link"],
                "barcode": item["barcode"],
                "User": item["user"],
                "Barcode Prefix": item["barcodeprefix"],
                "Selected Sample ID": item["selectedsampleid"],
                "itemcount": item["itemcount"] || 1
              };
              this.selectedsample.push(newSample);

            }
            // ... any other operations you need to perform on items
            // }
            SelfData.setSelectedSample = this.selectedsample
            this.yourappsService.setSelectedSamples(SelfData.sampledata)
            this.modalService.invoiceOpen()
          }
        });


    }
  }

  onButtobSelectClick(item: any) {
    this.yourappsService.setSelectedSendOut(item)
    this.modalService.closeSSO()
  }

  iSendOutReturnList: GetSendOutReturnList[] = [];

  createRRList(element: any) {
    SelfData.selcurrancy = element["currancy"]
    SelfData.sendoutopentype = 1
    SelfData.sendoutstep = 2;
    SelfData.saveonclose = false
    console.log("3", element?.["id"])
    SelfData.sendoutid = element?.["id"]
    this.serviceservice
      .selectsendoutreturnsamples({
        sendoutid: element.id,
      })
      .subscribe((value) => {
        console.log("Value", value)
        this.iSendOutReturnList[0] = value;
        SelfData.getsendoutreturnlist = this.iSendOutReturnList;
        this.yourappsService.selectedSendOut(element);
        this.modalService.openRR();

        if (SelfData.getsendoutreturnlist[0].data.length > 0) {
          this.selectedsample = []
          for (const item of SelfData.getsendoutreturnlist[0].data) {
            const newSample: SetSampleListData = {
              "ID": item["ID"],
              "Inventory Status": item["Inventory Status"],
              "Gender": item["Gender"],
              "Sample Workflow Status": item["Sample Workflow Status"],
              "Account ID": item["accountid"],
              "Region": item["Region"],
              "Notes": item["Notes"],
              "Designer": item["Designer"],
              "Fabric": item["Fabric"],
              "Manufacturer": item["Manufacturer"],
              "Collection": item["Collection"],
              "Style": item["Style"],
              "Looks Associated": item["Looks Associated"],
              "Season": item["Season"],
              "Misc": item["Misc"],
              "Quantity": item["Quantity"],
              "Type": item["Type"],
              "Size": item["Size"],
              "Size Range": item["Size Range"],
              "Pattern": item["Pattern"],
              "Color": item["Color"],
              "Color Code": item["colorcode"],
              "Retail Price EUR": item["Retail Price EUR"],
              "Retail Price USD": item["Retail Price USD"],
              "Wholesale EUR": item["Wholesale EUR"],
              "Wholesale USD": item["Wholesale USD"],
              "Sale Price EUR": item["Sale Price EUR"],
              "Sale Price USD": item["Sale Price USD"],
              "Prices ID": item["pricesid"],
              "Description": item["Description"],
              "Storage ID": item["storageid"],
              "Registration Date": item["regdate"],
              "Current Date": item["curdate"],
              "User ID": item["userid"],
              "Is Active": item["isactiviti"],
              "Image Link": item["Image Link"],
              "barcode": item["barcode"],
              "User": item["user"],
              "Barcode Prefix": item["barcodeprefix"],
              "Selected Sample ID": item["selectedsampleid"],
              "sendOutType": item["sendOutType"] || 0,
              "comment": item["comment"],
              "samplestatus": item["samplestatus"],
              "sendoutid": item["sendoutid"] || 0,
              "currancy": item["currancy"]
            };
            this.selectedsample.push(newSample);

          }
          // ... any other operations you need to perform on items
          // }
          SelfData.setSelectedSample = this.selectedsample
          console.log("SelfData.setSelectedSample", SelfData.setSelectedSample)
        }
      });
  }

  onButtonCelebritiClick(element: any) {
    this.progressSpinnerShow = true
    this.loadSMData(element?.["contactid"], element?.["celebriti"])
  }

  onButtonPullSheetClick(item: any) {
    if (item.pullsheet === 1) {
      this.messageService.add({
        key: 'tc',
        severity: 'info',
        summary: 'Information',
        detail: 'Pull Sheet is already sent.',
        life: 10000,
      });
    } else {
      this.yourappsService.setSelectedSendOut(item)
      this.modalService.openCPS()
    }
  }

  showCelebriti(
    event: MouseEvent,
    autoInput: MatAutocompleteTrigger | null
  ): void {
    event.stopPropagation(); // This will stop the event from bubbling up
    if (autoInput != null) {
      autoInput.closePanel();
    }

  }

  getIdForDescription(dataArray: GetCelebritiList[], descriptionToFind: string): number | null {
    for (let dataObj of dataArray) {
      if (dataObj && dataObj.data) {
        let found = dataObj.data.find(item => item.description === descriptionToFind);
        if (found) {
          return found.smid;
        }
      }
    }
    return null;
  }

  iCelebritiResult: GetCelebritiList[] = [];
  iSMList: GetSocialMediaList[] = [];

  loadSMData(contactid: number, celebriti: string) {
    this.serviceservice
      .selectcelebriti({contactid: contactid})
      .subscribe((value) => {
        this.iCelebritiResult = [];
        this.iCelebritiResult[0] = value;
        SelfData.celebriti = this.iCelebritiResult;
        SelfData.contactid = contactid
        let smid = this.getIdForDescription(this.iCelebritiResult, celebriti) || 0;
        this.serviceservice.setSMList({id: smid})
          .subscribe({
            next: (value) => {
              if (value && value.data && Array.isArray(value.data)) {
                this.iSMList[0] = value;
                SelfData.smedia = this.iSMList;
                const platforms = ["Instagram", "FaceBook", "TikTok", "YouTube", "Twitter"];
                for (const platform of platforms) {
                  const foundPlatform = SelfData.smedia[0].data.find(item => item.platform === platform);
                  if (foundPlatform) {
                    this.defaultimagelink = foundPlatform.avatarLink;
                    this.defaultname = foundPlatform.name;
                    console.log("this.defaultimagelink", this.defaultimagelink)
                    break;
                  }
                }
                this.progressSpinnerShow = false
              } else {
                console.log('The response is empty, lacks a data property, or the data property is not an array.');
              }
            },
            error: (error) => {
              console.error('Error occurred during the subscription:', error);
            },
          });
      });
  }

  sortedSMedia(): GetSocialMediaListData[] {
    if (this.SelfData && this.SelfData.smedia && this.SelfData.smedia[0] && Array.isArray(this.SelfData.smedia[0].data)) {
      return [...this.SelfData.smedia[0].data].sort((a, b) => b.subscribers - a.subscribers);
    }
    return []; // default to empty array if data isn't available
  }

  openLink(link: string): void {
    window.open(link, "_blank");  // This opens the link in a new window or tab.
    // window.location.href = link;  // Use this instead if you want to navigate to the link in the same window.
  }

  getColor(index: number): string {
    return index % 2 === 0 ? '#efefef' : '#dcdcdc';
  }

  protected readonly SelfData = SelfData;

  ngOnDestroy(): void {
    this.modalService.sendoutlistclose();
  }

  onInfoButtonClick() {
    window.open('https://fm.welcomeapps.net/FAQ/SendOutMana.html', '_blank');
  }

  subscription?: Subscription;
  createshipdate(element: any) {
    this.subscription = this.yourappsService.shipdate$.subscribe((value: { setdate: Date, type: number }) => {
      if (value.type > 0) {
        // Проверяем массив SelfData.sendoutlist[0].data
        const foundItem = SelfData.sendoutlist[0]?.data?.find((item: any) => item.id === element.id);

        if (foundItem) {
            this.messageService.add({
              key: 'tc',
              severity: 'success',
              summary: 'Information',
              detail: 'Ship date edited',
              life: 2000,
            });
          // Присваиваем значения, если элемент найден
          foundItem.shipdate = value.setdate;
          foundItem.shipdatecheck = true;

          const fields = {
            ctrl: 4,
            id: element.id,

            shipdate: value.setdate
          };
          console.log("SendOut1Data", fields)
          this.serviceservice
            .prcSendOutShip(fields)
            .subscribe((value) => {
              this.yourappsService.getReloadSendOutList(null, 1)
              SelfData.sendoutid = value.data
            });
        } else {

        }
        // Устанавливаем ship date через сервис
        this.yourappsService.setShipDate(new Date(), 0);
      }
    });

    // Открытие модального окна и обработка элемента
    this.yourappsService.selectedSendOut(element);
    this.modalService.openshipdate();
  }
}
