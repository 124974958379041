import { Component } from '@angular/core';
import { ModalserviceService } from '../../services/modalservice.service';

@Component({
  selector: 'app-createcompany',
  templateUrl: './createcompany.component.html',
  styleUrls: ['./createcompany.component.scss'],
})
export class CreatecompanyComponent {
  constructor(public modalService: ModalserviceService) {}
}
