<button mat-icon-button type="button" class="close-btn" (click)="modalService.closeEmailDetailsView()">
  <mat-icon>close</mat-icon>
</button>
<span class="sample-manager-editor">Detail View
  <div *ngIf="samplewait">
    <p-progressSpinner class="spinner1" styleClass="w-2rem h-2rem" strokeWidth="5"
      fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
  </div>
</span>
<div class="progressdiv">
<!--  <div class="numbersdiv">-->
<!--    <div class="textdiv2">-->
<!--      Successfull Deliveries-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="textdiv3">-->
<!--    Unique Opens-->
<!--  </div>-->
  <div class="mainprocessdiv">
    <nz-progress nzPercent="{{sendedcount}}" nzType="circle"></nz-progress>
    <nz-progress nzPercent="{{openedcount}}" nzType="circle"></nz-progress>
  </div>
  <div class="numbersdiv">
    <div class="textdiv2">
      Successfull Deliveries
<!--      {{ fullsendedcount }} - ({{ fullsendedprocentcount }}%)-->
    </div>
    <div class="textdiv3">
      Unique Opens
<!--      {{ fullopenedcount }} - ({{ fullopenedprocentcount }}%)-->
    </div>
  </div>
</div>

<div class="black-background2">

  <div class="card example-container-cn mat-elevation-z3">
    <p-table
      #dt1
      [value]="firstContactsData"
      [paginator]="true"
      [rows]="10"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [rowsPerPageOptions]="[10, 20, 50, 100]"
      [styleClass]="'p-datatable-striped mat-elevation-z3 ' + selectedSize.class.toString()"
      selectionMode="single"
      [globalFilterFields]="['Department', 'Email Type', 'Subject', 'Notes', 'Created By', 'Date Created', 'Date Sent', 'Recipients', 'From Name', 'From Email', 'Reply-To Email']"
      [tableStyle]="{'min-width': '50rem'}">
      <ng-template pTemplate="caption">
        <div class="flex">
          <!--                    <span class="p-inputgroup-addon custom-hover" matRipple (click)="onButtonAddClick()">-->
          <!--                        <i class="pi pi-plus" style="color: #000000"></i></span>-->
          <span class="p-input-icon-left ml-auto">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <i class="pi pi-search" style="color: #000000"></i>
              </span>
              <input pInputText type="text" (input)="onInput($event)" placeholder="Filter" [(ngModel)]="filtertext"/>
              <span class="p-inputgroup-addon custom-hover-red" matRipple (click)="clear(dt1)">
                <i class="pi pi-times" style="color: #000000"></i></span>
            </div>
          </span>

        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <!--                    -->
          <th style="width:0.5%">Options</th>
          <th style="width:6%">Contact Name</th>
          <th style="width:5%">Job Title</th>
          <th style="width:10%">Job Department</th>
          <th style="width:2%">Category</th>
          <th style="width:6%">Email</th>
          <th style="width:5%">Date Sent</th>
          <th style="width:15%">Delivery Response</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-product>
        <tr [pSelectableRow]="product">
          <td>
            <button type="button" nzTrigger="click" nz-button nz-dropdown>
              select
              <span nz-icon nzType="down"></span>
            </button>
          </td>
          <td>{{ product?.["Contact Name"] }}</td>
          <td>{{ product?.["Job Title"] }}</td>
          <td>{{ product?.["Job Department"] }}</td>
          <td>{{ product["Category"] }}</td>
          <td>{{ product["email"] }}</td>
          <td>{{ formatCustomDate(product?.["createdate"]) }}</td>
          <td
            [ngStyle]="{'color': product['deliveristatus'] === 1 ? 'green' : (product['deliveristatus'] === 2 ? 'red' : 'inherit')}">
            {{ product['sentstatus'] | slice:0:150 }}{{ product['sentstatus'].length > 150 ? '...' : '' }}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
