import { Component } from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";

@Component({
  selector: 'app-catalogslist1',
  templateUrl: './catalogslist1.component.html',
  styleUrls: ['./catalogslist1.component.scss']
})
export class Catalogslist1Component {
  constructor(public modalService: ModalserviceService) {}
}
