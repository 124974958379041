import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2
} from '@angular/core';
import {Subscription} from "rxjs";
import {GetlbCatalogs, GetlbCatalogsData, Getlbseasonlist, GetlbseasonlistData} from "../../models/struct";
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder} from "@angular/forms";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ConfirmationService, ConfirmEventType, MessageService} from "primeng/api";
import {NgxImageCompressService} from "ngx-image-compress";
import { HttpClient } from "@angular/common/http";
import {DOCUMENT} from "@angular/common";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";

@Component({
  selector: 'app-catalogs1dialog',
  templateUrl: './catalogs1dialog.component.html',
  styleUrls: ['./catalogs1dialog.component.scss']
})
export class Catalogs1dialogComponent implements OnInit, AfterViewInit, OnDestroy {
  private subscription?: Subscription;
  iSeasonToResult: GetlbCatalogs[] = [{Code: 0, ErrorMessage: '', data: []}];
  iDeletedToResult: GetlbCatalogs[] = [{Code: 0, ErrorMessage: '', data: []}];
  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private serviceservice: ServiceService,
    private yourappsService: YourappsserviceService,
    private _snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef,
    private messageService: MessageService,
    private imageCompressService: NgxImageCompressService,
    private http: HttpClient,
    private confirmationService: ConfirmationService,
    private renderer: Renderer2, @Inject(DOCUMENT) private document: Document,
    private el: ElementRef
  ) {

  }
  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.subscription = this.yourappsService.setColectionList$.subscribe((value: { data: any, type: number }) => {
      if (value.data) {
        this.iDeletedToResult[0].data = []
        if (value.type === 1) {
          this.iSeasonToResult = value.data
        }
      }
    })
  }

  onButtonCloseClick() {
    this.modalService.closeCatalogsList1()
  }

  onButtonSelectAllClick() {
    this.iDeletedToResult[0].data = this.iSeasonToResult[0].data
    this.iSeasonToResult[0].data = []
  }

  dropseason(event: CdkDragDrop<GetlbCatalogsData[]>): void {
    // Ensure both the source and destination data are actually arrays
    const previousContainerData: GetlbCatalogsData[] = event.previousContainer.data instanceof Array ? event.previousContainer.data : [];
    const containerData: GetlbCatalogsData[] = event.container.data instanceof Array ? event.container.data : [];

    if (event.previousContainer === event.container) {
      // Reordering within the same list
      moveItemInArray(containerData, event.previousIndex, event.currentIndex);
    } else {
      // Transferring between different lists
      this.transferArrayItemSeason(previousContainerData, containerData, event.previousIndex, event.currentIndex);
    }
  }

  transferArrayItemSeason(sourceArray: GetlbCatalogsData[], targetArray: GetlbCatalogsData[], fromIndex: number, toIndex: number) {
    if (!Array.isArray(sourceArray) || !Array.isArray(targetArray)) {
      console.log('Error: Attempting to transfer items between non-array types.');
      return;
    }
    const [item] = sourceArray.splice(fromIndex, 1);
    targetArray.splice(toIndex, 0, item);
  }

  onItemClick(item: any, i: number) {
    console.log(item)
    this.yourappsService.setImageList(item, 2)
    this.modalService.openivframe()
  }

  onCatalogRemoveClick(item: any, i: number) {
    this.iDeletedToResult[0].data.push(item)
    this.iSeasonToResult[0].data.splice(i, 1);
  }

  onDeleteButtonClick() {
    this.confirmationService.confirm({
      message: `<strong>Send updated data?</strong>`,
      header: 'Add Catalogs',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.yourappsService.returnCollectionList(this.iDeletedToResult, 1)
        this.modalService.closeCatalogsList1()
      },
      reject: (type: any) => {
        if (type == ConfirmEventType.REJECT) {
          this.yourappsService.returnCollectionList(null, 2)
          this.modalService.closeCatalogsList1()
        } else if (type == ConfirmEventType.CANCEL) {

        }
      }

    });
    setTimeout(() => {
      this.applyLocalRestoreStyles();
    }, 0);
  }

  private applyLocalStyles() {
    setTimeout(() => {
      const messageElements = document.querySelectorAll('.p-confirm-dialog-message');
      messageElements.forEach((element) => {
        element.setAttribute('style', 'font-size: 16px; color: red;');
      });
    }, 0);

    const iconElements = document.querySelectorAll('.p-confirm-dialog-icon');
    iconElements.forEach((element) => {
      element.setAttribute('style', 'color: red');
    });
  }

  private applyLocalRestoreStyles() {
    setTimeout(() => {
      const messageElements = document.querySelectorAll('.p-confirm-dialog-message');
      messageElements.forEach((element) => {
        element.setAttribute('style', 'font-size: 16px; color: #343a40;');
      });
    }, 0);

    const iconElements = document.querySelectorAll('.p-confirm-dialog-icon');
    iconElements.forEach((element) => {
      element.setAttribute('style', 'color: #343a40');
    });
  }

}
