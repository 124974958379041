<button mat-icon-button type="button" class="close-btn" (click)="modalService.closePrinterFrame()">
    <mat-icon>close</mat-icon>
</button>

<div class="bodydiv">
    <div class="page">
        <mat-card id="pcmaincard" [ngClass]="deviceClass">
            <mat-card-content>
                <div class="mainrow-pull">
                    <div class="child-1-pull">
                        <div class="maindiv">
                            <div class="companyname">
                                <span>TATA PR</span>
                            </div>
                            <div [ngClass]="[deviceClassAddress, isLandscape ? 'islandscape' : 'isnotlandscape']">
                                <span>291 S La Cienega Blvd Suite 401 Beverly Hills, CA 90211</span>
                                <div class="divcontacts">
                                    <span>TATA: (818) 458 0108 tata-pr&#64;tata-la.com</span>
                                    <span>OLIVIA: (562) 315 6051 pr-assistant&#64;tata-la.com</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="child-2-pull">
                        <img *ngIf="imageUrl === ''" class="account-image-pull" src="assets/tatalogo.png"
                             alt="noimage"/>
                    </div>
                </div>
                <div class="blackline"></div>
                <div class="line3">
                    <div class="option1">
                        <span [ngClass]="[deviceClassAddress, 'option1_1', isLandscape ? 'islandscapedate' : 'isnotlandscapedate']">Date of Pull:</span>
                    </div>
                    <div class="option1">
                        <span [ngClass]="[deviceClassAddress, 'option1_1', isLandscape ? 'islandscapedate' : 'isnotlandscapedate']">Date of Fitting:</span>
                    </div>
                    <div class="option1">
                        <span [ngClass]="[deviceClassAddress, 'option1_1', isLandscape ? 'islandscapedate' : 'isnotlandscapedate']">Date of Shoot/Event:</span>
                    </div>
                    <div class="option1">
                        <span [ngClass]="[deviceClassAddress, 'option1_1', isLandscape ? 'islandscapedate' : 'isnotlandscapedate']">Date of Edits Return:</span>
                    </div>
                    <div class="option1">
                        <span [ngClass]="[deviceClassAddress, 'option1_1', isLandscape ? 'islandscapedate' : 'isnotlandscapedate']">Date of Final Return:</span>
                    </div>
                </div>
                <div class="line4">
                    <div class="option1">
                        <span [ngClass]="[deviceClassAddress, 'option1_1', isLandscape ? 'islandscapedate' : 'isnotlandscapedate']"># of Garment Bags:</span>
                    </div>
                    <div class="option1">
                        <span [ngClass]="[deviceClassAddress, 'option1_1', isLandscape ? 'islandscapedate' : 'isnotlandscapedate']"># of Tote Bags:</span>
                    </div>
                </div>
                <div class="line5">
                    <div class="line5_1">
                        <span>PRESS REQUIREMENTS</span>
                    </div>
                </div>
                <div [ngClass]="[deviceClassAddress, 'line5', isLandscape ? 'islandscapedate' : 'isnotlandscapedate']">
                    <span>This letter is to verify that (Stylist’s name) _____________________________ is styling a VIP or
                        Editorial feature or shoot for (Publication/Celebrity) __________________________.</span>
                    <br>
                    This letter confirms that I, ___________________________,
                    will take full responsibility for all samples while out on loan, and will return all items
                    within the
                    scheduled appointment date/time to the TATA PR office: 291 S La Cienega Blvd, Suite 401, Beverly
                    Hills, CA 90211
                </div>
                <div class="line5">
                    <span>TATA PR & Designers reserves the right to share credited content online and across media
                        platforms.</span>
                </div>
                <div class="line5">
                    <div class="line5_1">
                        <span style="text-decoration: underline;">Red Carpet or VIP Placement:</span>
                    </div>
                    <span>● Must provide images of talent in pulled items; this includes red carpet images</span>
                    <span>● Inform Tata & Olivia via text as soon as talent is out the door w/ what they are wearing</span>
                    <span>● Talent must provide credit via tagging on Instagram: &#64;tata__la + designers handles must be included</span>
                    <span>● Stylists may not use wardrobe pulled/approved for one talent to dress another talent without prior written approval by Tata or Olivia</span>
                </div>
                <div class="line5">
                    <div class="line5_1">
                        <span style="text-decoration: underline;">Editorial:</span>
                    </div>
                    <span>● Email Tata & Olivia preview images from the shoot within 48 hours of the shoot, we will not release images until given approval. You must let us know at the time you return, or within 24 hours of the shoot,
what pieces are confirmed. Email this info to <strong>tata-pr&#64;tata-la.com</strong> and <strong>pr-assistant&#64;tata-la.com</strong></span>
                    <span>● The designer in print will be credited, along with agency, contact Tata or Olivia for credit information</span>
                    <span>● Provide 2 copies of publication or contact information for the person who can provide copies, as well as month, issue, number and talent in spread</span>
                    <span>● Prior to pull, you must provide a copy of the pull letter (LOR) to verify the shoot and placement</span>
                    <span>● Stylists may not change talent wearing garments without prior written approval by Tata or Olivia</span>
                </div>
                <div class="line5">
                    <div class="line5_1">
                        <span>PRE PULL:</span>
                    </div>
                </div>
                <div class="line5">
                <span [ngClass]="[deviceClassAddress, 'option1_1', isLandscape ? 'islandscapedate' : 'isnotlandscapedate']">DEPOSIT OF $250.00 MUST BE SUBMITTED PRIOR TO PULL. YOU MUST PROVIDE A VALID DRIVER’S LICENSE AND CREDIT CARD, WE WILL MAKE
 PHOTOCOPIES FOR OUR RECORDS.</span>
                </div>
                <div class="line5">
                    <span>Deposit Fee will be refunded on the final return date in full if no expenses are incurred. </span>
                </div>
                <div class="line5">
                    <div class="line5_1">
                        <span>RETURNS / CHARGES:</span>
                    </div>
                </div>
                <div class="line5">
                    <span><u>The list provided below outlines fees that will be automatically deducted from the Deposit
                        amount
                        accordingly.</u> All additional fees incurred will be owed by the Stylist to TATA PR upon the
                        final
                        return date, or after sufficient effort has been made to collect the garments, and will be
                        charged
                        to the credit card provided.</span>
                </div>
                <div class="line5">
                    <span>Samples are sent under our current terms and conditions. You are fully responsible for their
                        return
                        in the
                        supplied condition. This includes returning items in the original garment bags, tote bags, and
                        hangers
                        they were received in; failure to do so will result in a fee. All lost and/or damaged items will
                        be
                        charged
                        the full retail price.</span>
                </div>
                <div class="line5">
                    <span>ALL ALTERATIONS need to be previously approved and agreed on by TATA PR (TATA PR cannot and will
                        not cover the cost of the alterations)</span>
                </div>
                <div class="line5">
                    <span>Late fee charges will begin accumulating after 5:00pm on the day of the agreed upon date of final
                        return.
                        Any pull or item from a pull that is returned late will be charged additional fees if we are not
                        notified of an
                        updated return date prior to 5:00pm on the day of the agreed upon date of final return ($50 /
                        day).
                        You
                        may only update the final return date once. NO EXCEPTIONS.</span>
                </div>

                <div class="line5">
                    <span>All worn items must be dry cleaned before return. Dry-cleaning expenses will be administered for
                        worn
                        or
                        dirtied garments ($30 / item) if not dry-cleaned upon return to TATA PR. Dry cleaned items must
                        be
                        returned in the dry cleaning packaging.</span>
                </div>
                <div class="line5">
                    <span>Waiver of any single part of this agreement in no way, shape or form negates any other section of
                        this
                        agreement</span>
                </div>
                <div class="line5">
                    <span>No other agreement, verbal, supposed, implied or otherwise shall in ANY way shape or form and
                        shall
                        for
                        no reason override ANY section of the TATA PR contract and paperwork.</span>
                </div>
                <div class="line5 line5">
                    <p *ngIf="!printerpage"><strong>• Late Fees:</strong> $50 per day</p>
                    <span *ngIf="printerpage"><strong>• Late Fees:</strong> $50 per day</span>
                    <p *ngIf="!printerpage"><strong>• Dry-Cleaning Fee:</strong> $30 per item</p>
                    <span *ngIf="printerpage"><strong>• Dry-Cleaning Fee:</strong> $30 per item</span>
                    <p *ngIf="!printerpage"><strong>• Minor / Total Damage Fee:</strong> Full Retail Price</p>
                    <span *ngIf="printerpage"><strong>• Minor / Total Damage Fee:</strong> Full Retail Price</span>
                    <p *ngIf="!printerpage"><strong>• Lost Item Fee:</strong> Full Retail price</p>
                    <span *ngIf="printerpage"><strong>• Lost Item Fee:</strong> Full Retail price</span>
                    <p *ngIf="!printerpage"><strong>• Missing Garment Bags & Tote Bags:</strong> $5 per bag</p>
                    <span *ngIf="printerpage"><strong>• Missing Garment Bags & Tote Bags:</strong> $5 per bag</span>
                </div>
                <div class="blacklineshort"></div>
                <div class="line5" style="text-align: center;">
                    <p *ngIf="!printerpage"><strong>By accepting these items for loan and providing us with your credit card and
                        personal data information, you are authorizing TATA PR to charge your credit
                        card for the deposit fee and all remaining balances due. All balances owed to
                        TATA PR will be charged immediately.</strong></p>
                    <span *ngIf="printerpage"><strong>By accepting these items for loan and providing us with your credit card and
                        personal data information, you are authorizing TATA PR to charge your credit
                        card for the deposit fee and all remaining balances due. All balances owed to
                        TATA PR will be charged immediately.</strong></span>
                </div>
                <div class="line5">
                    <div class="line5_1">
                        <p *ngIf="!printerpage">PERSONAL INFO</p>
                        <span *ngIf="printerpage">PERSONAL INFO</span>
                    </div>
                </div>
                <div class="line6">
                    <p *ngIf="!printerpage">Phone Number: ____________________________ </p>
                    <span *ngIf="printerpage">Phone Number: ____________________________ </span>
                </div>
                <div class="line7">
                    <p *ngIf="!printerpage">Email: ____________________________ </p>
                    <span *ngIf="printerpage">Email: ____________________________ </span>
                </div>
                <div class="line5">
                    <div class="line5_1">
                        <p *ngIf="!printerpage">BILLING INFO</p>
                        <span *ngIf="printerpage">BILLING INFO</span>
                    </div>
                </div>
                <div class="line6">
                    <p *ngIf="!printerpage">CC #: ____________________________ </p>
                    <span *ngIf="printerpage">CC #: ____________________________ </span>
                </div>
                <div class="line7">
                    <p *ngIf="!printerpage">Exp. Date: ____________________________ </p>
                    <span *ngIf="printerpage">Exp. Date: ____________________________ </span>
                </div>
                <div class="line7">
                    <p *ngIf="!printerpage">Security Code: ____________________________ </p>
                    <span *ngIf="printerpage">Security Code: ____________________________ </span>
                </div>
                <div class="line7">
                    <p *ngIf="!printerpage">Billing Zip Code: ____________________________ </p>
                    <span *ngIf="printerpage">Billing Zip Code: ____________________________ </span>
                </div>
                <div class="line5">
                    <div class="line5_1">
                        <p *ngIf="!printerpage">SIGNATURES</p>
                        <span *ngIf="printerpage">SIGNATURES</span>
                    </div>
                </div>
                <div class="line7">
                    <p *ngIf="!printerpage">(Print Name) ____________________________ </p>
                    <span *ngIf="printerpage">(Print Name) ____________________________ </span>
                </div>
                <div class="line7">
                    <p *ngIf="!printerpage">(Signature) ____________________________ </p>
                    <span *ngIf="printerpage">(Signature) ____________________________ </span>
                </div>
                <div class="footerdiv">

                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>

<div class="button-container-dm">
    <button mat-raised-button class="button-settings-dm" type="button" (click)="onButtonPrintClick()">
        Print
        <mat-icon>print</mat-icon>
    </button>
</div>
