import {AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {MatStepper} from "@angular/material/stepper";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {SelfData, SendOut1Data, SetSampleList} from "../../models/struct";
import {forkJoin, Observable, Subscription} from "rxjs";
import {ModalserviceService} from "../../services/modalservice.service";
import {MessageboxComponent} from "../../modals/messagebox/messagebox.component";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {ConfirmationService, ConfirmEventType, MessageService} from "primeng/api";

@Component({
  selector: 'app-fullsendoutdialog',
  templateUrl: './fullsendoutdialog.component.html',
  styleUrl: './fullsendoutdialog.component.scss'
})
export class FullsendoutdialogComponent implements OnInit, AfterViewInit {
  @ViewChild('stepper') stepper!: MatStepper;
  myForm: FormGroup;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  stepIndex = 0;
  iSampleDataResult: SetSampleList[] = [];
  selectedid: number = -1
  isVisible: boolean = true;
  formcaption: string = ""
  tdata: any = null;
  showdialog: boolean = false
  createbuttoncaption = 'Next';
  private subscription?: Subscription;
  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    public messageBox: MessageboxComponent,
    private serviceservice: ServiceService,
    private yourappsService: YourappsserviceService,
    private messageService: MessageService,
    private cdr: ChangeDetectorRef,
    private confirmationService: ConfirmationService
  ) {
    if (SelfData.sendoutopentype === 0) {
      this.formcaption = "Create Send Out"
      this.createbuttoncaption = "Next"
      this.stepIndex = 0
      modalService.createSendOutStep1();
      modalService.closeSendOutStep2();
      modalService.closeSendOutStep3();
    } else if (SelfData.sendoutopentype === 1) {
      if (SelfData.sendoutstep === 0) {
        this.formcaption = "Create Send Out"
        this.stepIndex = 0
        this.modalService.createSendOutStep1();
        this.modalService.closeSendOutStep2();
        modalService.closeSendOutStep3();
      } else if (SelfData.sendoutstep === 1) {
        this.stepIndex = 1
        this.modalService.closeSendOutStep1();
        this.modalService.createSendOutStep2();
        modalService.closeSendOutStep3();
      } else if (SelfData.sendoutstep === 2) {
        this.stepIndex = 2
        this.modalService.closeSendOutStep1();
        this.modalService.closeSendOutStep2();

        console.log("2")
        this.modalService.createSendOutStep3();
      }
      this.formcaption = "Edit Send Out"
      this.createbuttoncaption = "Update"
    } else if (SelfData.sendoutopentype === 2) {
      modalService.createSendOutStep1();
      modalService.closeSendOutStep2();
      modalService.closeSendOutStep3();
      this.formcaption = "Create Request"
      if (SelfData.sendoutstep === 0) {
        this.stepIndex = 0
        this.modalService.createSendOutStep1();
        this.modalService.closeSendOutStep2();
        modalService.closeSendOutStep3();
      } else if (SelfData.sendoutstep === 1) {
        this.stepIndex = 1
        this.modalService.closeSendOutStep1();
        this.modalService.createSendOutStep2();
        modalService.closeSendOutStep3();
      } else if (SelfData.sendoutstep === 2) {
        this.stepIndex = 2
        this.modalService.closeSendOutStep1();
        this.modalService.closeSendOutStep2();
        this.modalService.createSendOutStep3();
      }
    }
    this.myForm = this.fb.group({});
    this.firstFormGroup = this.fb.group({
      firstCtrl: ['', Validators.required],
    });
    this.secondFormGroup = this.fb.group({
      secondCtrl: ['', Validators.required],
    });


    var accountid: number = 0;
    if (SelfData.account[0].data[0].parentid == 0) {
      accountid = SelfData.account[0].data[0].id;
    } else {
      accountid = SelfData.account[0].data[0].parentid;
    }
    this.serviceservice.setSampleList({accountid: accountid, page: 1, pageSize: 1000000, forceRefresh: false, samplestyle: SelfData.samplestyle}).subscribe({
      next: (value) => {
        if (value) {
          this.iSampleDataResult[0] = value;
          SelfData.sampledata = this.iSampleDataResult;
        } else {
          console.log('The response is empty or undefined.');
        }
      },
      error: (error) => {
        console.error('Error occurred during the subscription:', error);
      },
    });
  }

//     if (this.selectedid == 3) {
//     this.modalService.invoiceOpen()
// }
  nextClick() {
    if (SelfData.setSelectedSample.some(sample => sample["isok"] === 0)) {
      this.messageService.add({
        key: 'tc',
        severity: 'error',
        summary: 'Information',
        // detail: `Sample ${barcodeValue} already scanned by user ${foundData['user']} for a new delivery or reservation.`,
        detail: `Some items from the list are on loan!`,
        life: 10000,
      });
    } else {
      console.log("SelfData.sendoutopentype",SelfData.sendoutopentype)
      if (SelfData.sendoutopentype === 0) {

        if (this.stepIndex == 0) {
          this.modalService.closeSendOutStep1();
          this.modalService.createSendOutStep2();
        }
        if (this.stepIndex < 2) {
          this.stepIndex += 1;
          if (this.stepIndex === 2) {
            if (this.selectedid != 3) {
              this.modalService.closeSendOutStep1();
              this.modalService.closeSendOutStep2();
              this.modalService.createSendOutStep3();
              this.createbuttoncaption = 'Done';
            } else {
              this.yourappsService.setSelectedSamples(SelfData.sampledata)
              this.stepIndex -= 1;
              this.subscription = this.yourappsService.invoiceCreateSend.subscribe((value) => {
                if (this.subscription) {
                  this.modalService.closeSendOutStep1();
                  this.modalService.closeSendOutStep2();
                  this.modalService.createSendOutStep3();
                  this.createbuttoncaption = 'Done';
                  this.stepIndex = 2
                  this.subscription.unsubscribe()
                }
              })
              this.modalService.invoiceOpen()
            }
          } else {
            this.createbuttoncaption = 'Next';
          }

        } else {
          this.submit(0);
        }
      } else {
        this.modalService.createsendoutclose()
      }
    }
  }

  backClick() {

  }

  submit(type: number = 0) {
    this.modalService.createsendoutclose()
  }

  ngAfterViewInit() {
  }

  ngOnInit(): void {
    // console.log("SendOut1Data.id", SendOut1Data.)
    this.yourappsService.selectedid$.subscribe((value) => {
      this.selectedid = value
    })
  }

  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
      ? SelfData.account[0].data[0].id
      : SelfData.account[0].data[0].parentid;
  }

  onButtonCloseClick() {
    this.showdialog = true
    this.cdr.detectChanges()
    this.confirmationService.confirm({
      message: `<strong>Would you like to save this change?</strong>`,
      header: 'Information',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        SelfData.closesendout = false
        SelfData.saveonclose = true
        SelfData.fullPage = true
        SelfData.scannedlist = [{code: 0, errorMessage: '', data: []}];
        SelfData.setSelectedSample = [];
        SelfData.scannedselectedlist = [];
        this.modalService.createfullsendoutclose()

      },
      reject: (type: any) => {
        if (type == ConfirmEventType.REJECT) {
          SelfData.closesendout = false
          SelfData.saveonclose = false
          SelfData.fullPage = false
          SelfData.scannedlist = [{code: 0, errorMessage: '', data: []}];
          SelfData.setSelectedSample = [];
          SendOut1Data.check1 = false;
          SendOut1Data.check2 = false;
          SendOut1Data.check3 = false;
          SendOut1Data.departmentFormControl = '';
          SendOut1Data.shipFormControl = '';
          SendOut1Data.shiptoControl = '';
          SendOut1Data.todControl = '';
          SendOut1Data.peControl = '';
          SendOut1Data.companynameControl = '';
          SendOut1Data.addresslabelControl = '';
          SendOut1Data.costofshippingControl = '';
          SendOut1Data.cimControl = '';
          SendOut1Data.publicationControl = '';
          SendOut1Data.estylistControl = '';
          SendOut1Data.photographerControl = '';
          SendOut1Data.pelControl = '';
          SendOut1Data.petControl = '';
          SendOut1Data.issueControl = '';
          SendOut1Data.trackingNumberControl = '';
          SendOut1Data.returnControl = '';
          SendOut1Data.notesControl = '';
          SendOut1Data.senderFormControl = '';
          SendOut1Data.requestedbyFormControl = '';
          SendOut1Data.sendOutTypeControl = '';
          SendOut1Data.currancyControl = '';
          SendOut1Data.reservationdateControl = null;
          SendOut1Data.returndateControl = null;
          SendOut1Data.datetimeCtrl = null;
          SendOut1Data.datetimeCtrl1 = null;
          SendOut1Data.datetimeCtrl2 = null;
          SendOut1Data.datetimeCtrl3 = null;
          SendOut1Data.datetimeCtrl4 = null;
          SendOut1Data.celebriti = '';
          if (SelfData.scannedselectedlist && SelfData.scannedselectedlist.length > 0) {
            // Explicitly define the type for deleteRequests
            const deleteRequests: Observable<any>[] = [];

            // ციკლით გავიაროთ scannedselectedlist
            SelfData.scannedselectedlist.forEach(element => {
              // შევქმნათ და შევინახოთ თითოეული მოთხოვნა, პარამეტრები აიღოს element-დან
              const request = this.serviceservice
                .deletefromscannlist({
                  ctrl: 0,
                  userid: element.userid,
                  accountid: element.accountid,
                  barcode: element.barcode
                });

              deleteRequests.push(request);
            });

            // გამოვიყენოთ forkJoin ყველა მოთხოვნის ერთდროულად შესასრულებლად
            forkJoin(deleteRequests).subscribe(
              results => {
                // ყველა მოთხოვნა დასრულდა
                console.log('All delete requests completed', results);
                // გავასუფთაოთ scannedselectedlist
                SelfData.scannedselectedlist = [];
              },
              error => {
                console.error('Error deleting items:', error);
              }
            );
          }
          this.modalService.createfullsendoutclose()
        } else if (type == ConfirmEventType.CANCEL) {
        }
      }
    })
    // this.yourappsService.setOKOption(3);
    // this.messageBox.modalService.openMessageBox(
    //   'Information',
    //   'Would you like to Cancel or Save this Send Out form? ',
    //   'Cancel',
    //   'Save'
    // );
    // this.modalService.createsendoutclose()
  }

}
