import { Pipe, PipeTransform } from '@angular/core';
import {CurrencyPipe} from "@angular/common";

@Pipe({
  name: 'customCurrency'
})
export class CustomCurrencyPipe implements PipeTransform {

  constructor(private currencyPipe: CurrencyPipe) {}

  transform(value: any, currencyCode?: string, display?: 'code' | 'symbol' | 'symbol-narrow' | boolean, digitsInfo: string = '1.2-2', locale?: string): string | null {
    // Default to 'symbol-narrow' and ensure two decimal places.
    const transformed = this.currencyPipe.transform(value, currencyCode, 'symbol-narrow', digitsInfo, locale);

    if (transformed) {
      // Regex to find the symbol and the numeric value
      const regex = /^([^\d]*)([\d.,]+)$/;
      const match = transformed.match(regex);

      // If match is found, rearrange. Else, return as-is.
      return match ? `${match[2]}${match[1]}` : transformed;
    }
    return null;
  }
}
