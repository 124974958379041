import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  Self,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {ModalserviceService} from '../../services/modalservice.service';
import {HashService} from '../../services/hash.service';
import {MessageboxComponent} from '../../modals/messagebox/messagebox.component';
import {ServiceService} from '../../services/service.service';
import {YourappsserviceService} from '../../services/yourappsservice.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NgxImageCompressService} from 'ngx-image-compress';
import { HttpClient } from '@angular/common/http';
import {
  GetCelebritiList, GetCelebritiListData,
  GetCompanyList,
  GetCurrancyList, GetSMList, GetSocialMediaList, GetSocialMediaListData,
  GetSystemUsers, GetSystemUsersData,
  GettodList,
  SelfData, SendOut1Data,
  SetContactsList,
  SetContactsListData,
} from '../../models/struct';
import {
  MatAutocomplete,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import {map, Observable, of, startWith, Subscription} from 'rxjs';
import {MatOptionSelectionChange} from '@angular/material/core';
import {MatSelectChange} from "@angular/material/select";
import {setHours} from "date-fns";
import {NzNotificationService} from "ng-zorro-antd/notification";

interface SendOutType {
  id: number;
  value: string;
}

@Component({
  selector: 'app-sendoutstep1',
  templateUrl: './sendoutstep1.component.html',
  styleUrls: ['./sendoutstep1.component.scss'],
})

export class Sendoutstep1Component implements OnInit, AfterViewInit, OnDestroy {
  checkboxvalue?: boolean;
  onshowframe: boolean = false
  // group: FormGroup;
  disableedits: boolean = false
  iSystemUsersResult: GetSystemUsers[] = [];
  iSystemUsersRequstedResult: GetSystemUsers[] = [];
  iContactsResult: SetContactsList[] = [];
  iReturnResult: SetContactsList[] = [];
  iCurrancyResult: GetCurrancyList[] = [];
  iTODResult: GettodList[] = [];
  iPEResult: GettodList[] = [];
  // iCelebritiResult: GetCelebritiList[] = [{Code: 0, ErrorMessage: '', data: []}];
  iCelebritiResult: GetCelebritiList[] = [];
  optionsCelebriti: GetCelebritiListData[] = [];
  iSMResult: GetSMList[] = [];
  iIssueResult: GettodList[] = [];
  iCompanyResult: GetCompanyList[] = [];
  tdata: any = null;
  defaultimagelink: string = ""
  defaultname: string = ""
  smid: number = 0
  returnedenabled: boolean = true
  returneddateenabled: boolean = true

  sendouttype: SendOutType[] = [
    {id: 1, value: 'Loan'},
    {id: 2, value: 'Transfer'},
    {id: 3, value: 'Reservation'},
    {id: 4, value: 'Purchase'},
    {id: 5, value: 'Return To Vendor'},
    {id: 6, value: 'Send To Archive'},
    {id: 7, value: 'Write Off'},
    {id: 8, value: 'Gift'},
  ];

  showeditbutton = false;
  showreturneditbutton = false;
  showsmbutton = false;
  isButtonDisabled: boolean = true;
  selectedrecipient: string = ""

  check1 = new FormControl(false);
  check2 = new FormControl(false);
  check3 = new FormControl(false);
  // departmentFormControl = new FormControl({value: '', disabled: true}, [
  //   Validators.required,
  // ]);
  shipFormControl = new FormControl({value: '', disabled: true}, [
    Validators.required,
  ]);
  shiptoControl = new FormControl({value: '', disabled: true}, [
    Validators.required,
  ]);
  // todControl = new FormControl({value: '', disabled: false}, [
  //   Validators.required,
  // ]);
  // peControl = new FormControl({value: '', disabled: false});
  // companynameControl = new FormControl('', Validators.required);
  addresslabelControl = new FormControl({value: '', disabled: true}, [
    Validators.required,
  ]);
  // costofshippingControl = new FormControl<string>('');
  // cimControl = new FormControl<string>('');
  // publicationControl = new FormControl<string>('');
  // estylistControl = new FormControl<string>('');
  // photographerControl = new FormControl<string>('');
  // pelControl = new FormControl<string>('');
  // petControl = new FormControl<string>('');
  // issueControl = new FormControl<string>('');
  // trackingNumberControl = new FormControl<string>('');
  // returnControl = new FormControl<string>('');
  // notesControl = new FormControl<string>('');
  // celebritiControl = new FormControl<string>('');
  // filteredCompanyNameOptions?: Observable<string[]>
  //
  // senderFormControl = new FormControl('', Validators.required);
  // requestedbyFormControl = new FormControl('', Validators.required);
  //
  // sendOutTypeControl = new FormControl('', Validators.required);
  // currancyControl = new FormControl('', Validators.required);
  // reservationdateControl = new FormControl('', Validators.required);
  // returndateControl = new FormControl('', Validators.required);

  filteredContactsOptions?: Observable<string[]>;
  filteredTODOptions?: Observable<string[]>;
  filteredPEOptions?: Observable<string[]>;
  filteredReturnOptions?: Observable<string[]>;
  filteredCelebritiOptions?: Observable<string[]>;

  contactsoptions: string[] = [];
  todoptions: string[] = [];
  peoptions: string[] = [];
  returnoptions: string[] = [];
  companyoptions: string[] = [];
  celebritioptions: string[] = [];

  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private hashService: HashService,
    public messageBox: MessageboxComponent,
    private serviceservice: ServiceService,
    private yourappsService: YourappsserviceService,
    private _snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef,
    private imageCompressService: NgxImageCompressService,
    private notification: NzNotificationService,
    private http: HttpClient
  ) {
    if (this.subscription4) {
      this.subscription4.unsubscribe();
    }
    this.check2.disable();
    this.check3.disable();
    // this.group = new FormGroup({
    //   datetimeCtrl: new FormControl(''),
    //   datetimeCtrl1: new FormControl(''),
    //   datetimeCtrl2: new FormControl(''),
    //   datetimeRange: new FormGroup({
    //     start: new FormControl(''),
    //     end: new FormControl('')
    //   }),
    //   datetimeCtrl3: new FormControl('')
    // });
    this.departmentstring = SelfData.account[0].data[0].company;

    var accountid: number = 0;
    if (SelfData.account[0].data[0].parentid == 0) {
      accountid = SelfData.account[0].data[0].id;
    } else {
      accountid = SelfData.account[0].data[0].parentid;
    }

    this.serviceservice
      .setSystemUsers({accountid: accountid})
      .subscribe((value) => {
        this.iSystemUsersResult[0] = value;
        this.iSystemUsersRequstedResult[0] = value;
        SelfData.systemuser = this.iSystemUsersResult;
      });

    this.serviceservice
      .setTODList({accountid: accountid})
      .subscribe((value) => {
        this.iTODResult = [];
        // this.resetResults()
        this.iTODResult[0] = value;
        SelfData.tod = this.iTODResult;
        // this.filteredTODOptions = this.setDataAndFilterDescription(
        //   this.todControl,
        //   value,
        //   this.todoptions,
        //   2
        // );
      });

    this.serviceservice
      .setPEList({accountid: accountid})
      .subscribe((value) => {
        this.iPEResult = [];
        // this.resetResults()
        this.iPEResult[0] = value;
        SelfData.pe = this.iPEResult;
        // this.filteredPEOptions = this.setDataAndFilterDescription(
        //   this.peControl,
        //   value,
        //   this.peoptions,
        //   3
        // );
      });

    this.serviceservice
      .selectcelebriti({contactid: SelfData.contactdata[0]?.data[0]?.["id"]})
      .subscribe((value) => {
        this.iCelebritiResult = [];
        console.log("Value", value)
        this.iCelebritiResult[0] = value;
        SelfData.celebriti = this.iCelebritiResult;
        this.updateOptions()
      });

    this.serviceservice
      .setIssueList({accountid: accountid})
      .subscribe((value) => {
        this.iIssueResult = [];
        // this.resetResults()
        this.iIssueResult[0] = value;
        SelfData.issue = this.iIssueResult;
        // this.filteredPEOptions = this.setDataAndFilterDescription(
        //   this.peControl,
        //   value,
        //   this.peoptions,
        //   3
        // );
      });

    this.serviceservice
      .setContactsList({accountid: accountid, contactsid: 0})
      .subscribe({
        next: (value) => {
          if (value) {
            this.iContactsResult[0] = value;
            SelfData.contactdata = this.iContactsResult;
            // this.filteredContactsOptions = this.setDataAndFilter(
            //   this.companynameControl,
            //   value,
            //   this.contactsoptions,
            //   0
            // );
            this.returnoptions = []
            // this.filteredReturnOptions = this.setDataAndFilter(
            //   this.returnControl,
            //   value,
            //   this.returnoptions,
            //   1
            // );
          } else {
            console.log('The response is empty or undefined.');
          }
        },
        error: (error) => {
          console.error('Error occurred during the subscription:', error);
        },
      });

    this.serviceservice.setCompanyList({accountid: accountid}).subscribe({
      next: (value: GetCompanyList) => {
        if (value && value.data) {
          this.iCompanyResult[0] = value;
          SelfData.company = this.iCompanyResult;
          // this.filteredCompanyNameOptions = this.setFullDataFilter(
          //   this.companynameControl,
          //   value.data,
          //   this.companyoptions,
          //   0
          // );
        } else {
          console.log('The response is empty or undefined.');
        }
      },
      error: (error) => {
        console.error('Error occurred during the subscription:', error);
      },
    });

    this.serviceservice
      .setCurrancyList({accountid: accountid})
      .subscribe((value) => {
        this.iCurrancyResult.push(value);
        SelfData.currancy = this.iCurrancyResult;
      });

    this.serviceservice.selectHistory(SelfData.account[0].data[0].id).subscribe(response => {
      const record = response.data[0];
      console.log('History records retrieved:', record);
// Extract the individual properties
      const currency = record.currency;
      const reservationdate = record.reservationdate;
      const expreturndate = record.expreturndate;
      const sender = record.sender;
      // const recipient = record.recipient;
      // const returnpoint = record.returnpoint;
      const sendouttype = record.sendouttype;
      // const requestedby = record.requestedby;

// Now you can use these variables as needed
      let currentDate = new Date();
      let reservationDate = new Date(currentDate);
      reservationDate.setDate(currentDate.getDate() + 14); // Adds 14 days (2 weeks)
      reservationDate.setHours(10, 0, 0, 0); // Hours, minutes, seconds, milliseconds

// Do the same for expreturndate if it's also 2 weeks from now at 10:00 AM
      let expreturndate1 = new Date(reservationDate); // Same as reservationDate
      this.currencystring = currency
      this.reservationdate = new Date(expreturndate1);
      this.expreturndate = new Date(expreturndate1);
      this.senderstring = sender
      // this.recipiendstring = recipient
      // this.returnedstring = returnpoint
      this.sendouttypestring = sendouttype
      // this.requestbystring = requestedby
      console.log('Currency:', currency);
      console.log('Reservation Date:', reservationdate);
      console.log('Expected Return Date:', expreturndate);
      console.log('Sender:', sender);
      // console.log('Recipient:', recipient);
      // console.log('Return Point:', returnpoint);
      console.log('Sendout Type:', sendouttype);
      // console.log('Requested By:', requestedby);
    });
  }

  private setDataAndFilter(
    control: FormControl,
    response: any,
    options: string[],
    type: number
  ) {
    let results = response.data;
    for (let data of results) {
      options.push(data.firstname + ' ' + data.lastname); // use firstname and lastname
    }
    return control.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || '', type))
    );
  }

  private setFullDataFilter(
    control: FormControl,
    response: any,
    options: string[],
    type: number
  ) {
    for (let data of response) {
      options.push(data.companyname);
    }
    return control.valueChanges.pipe(
      startWith(''),
      map((value) => this._myfilter(value || '', type))
    );
  }

  private setDataAndFilterDescription(
    control: FormControl,
    response: any,
    options: string[],
    type: number
  ) {
    let results = response.data;
    for (let data of results) {
      options.push(data.description); // use firstname and lastname
    }
    return control.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || '', type))
    );
  }

  private _filter(value: string, type: number): string[] {
    const filterValue = value.toLowerCase();

    const options = [
      this.contactsoptions,
      this.returnoptions,
      // this.todoptions,
      this.companyoptions,
    ];

    if (type >= 0 && type < options.length) {
      return options[type].filter((option) =>
        option.toLowerCase().includes(filterValue)
      );
    } else {
      console.warn(`Unexpected filter type: ${type}`);
      return [];
    }
  }

  private _myfilter(value: string, type: number): string[] {
    const filterValue = value.toLowerCase();

    // replace 'this.companyoptions' with the correct array based on 'type'
    return this.companyoptions.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  searchItem(
    name: string,
    contacts: SetContactsList[]
  ): SetContactsListData | null {
    for (let contact of contacts) {
      let found = contact.data.find(
        (d) => d?.['firstname'] + ' ' + d?.['lastname'] === name
      );

      if (found) {
        return found;
      }
    }

    return null;
  }

  searchUser(
    name: string,
    users: GetSystemUsers[]
  ): GetSystemUsersData | null {
    for (let user of users) {
      let found = user.data.find(
        (d) => d?.['firstname'] + ' ' + d?.['lastname'] === name
      );

      if (found) {
        return found;
      }
    }

    return null;
  }

  onSenderSelect(event: string) {
    var recaddress: string | undefined;
    let result = this.searchUser(event, this.iSystemUsersResult);
    recaddress = result?.company + '\n' +
      result?.address1 + '\n' + result?.address2 + '\n' +
      result?.city + ' ' + result?.state + ' ' + result?.zipcode + '\n' +
      result?.phone
    this.shipFormControl.setValue(recaddress!!)
  }

  getIdForDescription(dataArray: GetCelebritiList[], descriptionToFind: string): number | null {
    for (let dataObj of dataArray) {
      if (dataObj && dataObj.data) {
        let found = dataObj.data.find(item => item.description === descriptionToFind);
        if (found) {
          return found.smid;
        }
      }
    }
    return null;
  }

  onCelebritiSelected(event: MatOptionSelectionChange) {
    this.smid = this.getIdForDescription(this.iCelebritiResult, event.source.value) || 0;
    if (event.isUserInput) {
      this.showsmbutton = true;
      this.loadSMData()
    }
  }

  onCelebritiBackSelected(celebriti: string) {
    console.log("iCelebritiResult", this.iCelebritiResult)
    this.smid = this.getIdForDescription(this.iCelebritiResult, celebriti) || 0;

    this.showsmbutton = true;
    this.loadSMData()

  }

  onItemSelect(event: MatOptionSelectionChange, type: number) {

  }

  onOptionSelected(event: MatOptionSelectionChange, type: number) {
    var recaddress: string = '';
    var recaddresslabel: string = '';

    if (event.isUserInput) {
      if (type == 0) {
        this.showeditbutton = true;
      } else if (type == 1) {
        this.showreturneditbutton = true;
      }

      this.selectedrecipient = event.source.value

      let result = this.searchItem(event.source.value, this.iContactsResult);
      this.serviceservice
        .selectcelebriti({contactid: result?.['id']})
        .subscribe((value) => {
          this.iCelebritiResult = [];
          this.iCelebritiResult[0] = value;
          SelfData.celebriti = this.iCelebritiResult;
          SelfData.contactid = result?.['id']
          this.updateOptions()
        });
      recaddress =
        event.source.value +
        '\n' +
        'c/o ' +
        result?.['addresscareof'] +
        '\n' +
        result?.['addressaddress1'] +
        '\n' +
        result?.['addresscity'] +
        ' ' +
        result?.['addressstate'] +
        +' ' +
        result?.['addresszipcode'] +
        ' ' +
        result?.['addresscountry'] +
        '\n' +
        result?.['email'] +
        '\n' +
        result?.['mobile'];
      recaddresslabel =
        'Attn: ' +
        event.source.value +
        '\n' +
        result?.['addresscareof'] +
        '\n' +
        result?.['companyname'] +
        '\n' +
        result?.['addressaddress1'] +
        '\n' +
        result?.['addresscity'] +
        ' ' +
        result?.['addressstate'] +
        +' ' +
        result?.['addresszipcode'] +
        ' ' +
        result?.['addresscountry'] +
        '\n' +
        'Tel: ' +
        result?.['mobile'] +
        '\n' +
        'Email: ' +
        result?.['email'] +
        '\n' +
        result?.['taxid'];
      this.shiptoControl.setValue(recaddress);
      this.addresslabelControl.setValue(recaddresslabel);
      // this.addresslabelControl.setValue(event.source.value.department)
    }
  }

  onChange(event: Event | string | null, type: number) {
    let selectedValue: string = '';

    if (typeof event === 'string') {
      selectedValue = event;
    } else if (event instanceof Event && event.target) {
      selectedValue = (event.target as HTMLSelectElement).value;
    }
    // If event is null, selectedValue remains an empty string

    console.log('Selected recipient:', selectedValue);
    this.selectedrecipient = selectedValue
    if (selectedValue === '') {
      if (type === 0) {
        this.showeditbutton = false;
        this.iCelebritiResult = [];
        this.updateOptions()
      } else if (type === 1) {
        this.showreturneditbutton = false;
      }
    }

    var recaddress: string = '';
    var recaddresslabel: string = '';

    if (selectedValue.trim() !="") {
      if (type == 0) {
        this.showeditbutton = true;
      } else if (type == 1) {
        this.showreturneditbutton = true;
      }
      let result = this.searchItem(selectedValue, this.iContactsResult);
      if (result) {
        this.serviceservice
          .selectcelebriti({contactid: result?.['id']})
          .subscribe((value) => {
            this.iCelebritiResult = [];
            this.iCelebritiResult[0] = value;
            SelfData.celebriti = this.iCelebritiResult;
            SelfData.contactid = result?.['id']
            this.updateOptions()
          });
      }
      recaddress =
        selectedValue +
        '\n' +
        'c/o ' +
        result?.['addresscareof'] +
        '\n' +
        result?.['addressaddress1'] +
        '\n' +
        result?.['addresscity'] +
        ' ' +
        result?.['addressstate'] +
        +' ' +
        result?.['addresszipcode'] +
        ' ' +
        result?.['addresscountry'] +
        '\n' +
        result?.['email'] +
        '\n' +
        result?.['mobile'];
      recaddresslabel =
        'Attn: ' +
        selectedValue +
        '\n' +
        result?.['addresscareof'] +
        '\n' +
        result?.['companyname'] +
        '\n' +
        result?.['addressaddress1'] +
        '\n' +
        result?.['addresscity'] +
        ' ' +
        result?.['addressstate'] +
        +' ' +
        result?.['addresszipcode'] +
        ' ' +
        result?.['addresscountry'] +
        '\n' +
        'Tel: ' +
        result?.['mobile'] +
        '\n' +
        'Email: ' +
        result?.['email'] +
        '\n' +
        result?.['taxid'];
      this.shiptoControl.setValue(recaddress);
      this.addresslabelControl.setValue(recaddresslabel);
      // this.addresslabelControl.setValue(event.source.value.department)
    }

  }

  addCompany(
    event: MouseEvent,
    type: number,
    autoInput: MatAutocompleteTrigger | null
  ): void {
    this.onshowframe = true
    // event.stopPropagation(); // This will stop the event from bubbling up
    SelfData.modaltype = type;
    // if (autoInput != null) {
    //   autoInput.closePanel();
    // }
    SelfData.contactdata = [];
    this.yourappsService.selectSampleData(null);
    this.yourappsService.selectContact(null, 0)
    this.modalService.createcontactopen();

  }

  showCelebriti(
    event: MouseEvent,
    autoInput: MatAutocompleteTrigger | null
  ): void {
    event.stopPropagation(); // This will stop the event from bubbling up
    if (autoInput != null) {
      autoInput.closePanel();
    }

  }

  addPublication(
    event: MouseEvent,
    type: number,
    autoInput: MatAutocompleteTrigger | null
  ): void {
    event.stopPropagation(); // This will stop the event from bubbling up
    SelfData.modaltype = type;
    if (autoInput != null) {
      autoInput.closePanel();
    }
    this.modalService.createcompanyopen();
  }

  opendragdropframe(
    event: MouseEvent,
    type: number,
    autoInput: MatAutocompleteTrigger | null
  ) {
    event.stopPropagation(); // This will stop the event from bubbling up
    SelfData.modaltype = type;
    if (autoInput != null) {
      autoInput.closePanel();
    }
    switch (SelfData.modaltype) {
      case -1:
        SelfData.modalformcaption = 'Type of Dispatch';
        this.yourappsService.setTod(this.iTODResult);
        break;
      case -2:
        SelfData.modalformcaption = 'Project/Event';
        this.yourappsService.setTod(this.iPEResult);
        break;
      case -3:
        SelfData.modalformcaption = 'Issue';
        this.yourappsService.setTod(this.iIssueResult);
        break;
      case -4:
        SelfData.modalformcaption = 'Celebrity';
        this.yourappsService.setTod(this.iCelebritiResult);
        break;
    }
    this.modalService.createDragDrop();
  }

  onButtonEditLabelClick() {
    this.yourappsService.returnAddressLabel(null, 0)
    let result = this.searchItem(this.selectedrecipient, this.iContactsResult);
    console.log("result",result)
    if (result) {
      this.subscription4 = this.yourappsService.retaddreslabel$.subscribe((value: { data: any, type: number }) => {
        if (this.subscription4) {
          this.subscription4.unsubscribe();
          if (value.type === 1) {
            if (value.data) {
              console.log("value.data", value.data);
              var recaddresslabel: string = '';
              recaddresslabel =
                'Attn: ' +
                value.data[0]?.['fullname'] +
                '\n' +
                value.data[0]?.['careof'] +
                '\n' +
                value.data[0]?.['companyname'] +
                '\n' +
                value.data[0]?.['address1'] +
                '\n' +
                value.data[0]?.['address2'] +
                '\n' +
                'Tel: ' +
                value.data[0]?.['mobile'] +
                '\n' +
                'Email: ' +
                value.data[0]?.['email'] +
                '\n' +
                value.data[0]?.['taxid'];
              this.addresslabelControl.setValue(recaddresslabel);
            }
          }
        }
      })
      this.yourappsService.selectedAddressLabel([result], 1); // Оберните в массив
      this.modalService.openEditlabel();
    }

  }

  ngAfterViewInit(): void {
  }

  determineAccountId(): number {
    if (SelfData.account[0].data[0].parentid == 0) {
      return SelfData.account[0].data[0].id;
    } else {
      return SelfData.account[0].data[0].parentid;
    }
  }

  resetResults(): void {
  }

  // clearTOD() {
  //   this.filteredTODOptions = of([]);
  //   this.todControl.reset();
  // }

  checkValidation() {
    SendOut1Data.errormode = false
    if (this.senderstring === null) {
      this.notification.error(
        '<strong class="notification-error">Information</strong>',
        '<strong class="notification-error">Sender is required</strong>'
      );
      this.statussender = 'error';
      SendOut1Data.errormode = true
      return;
    } else {
      this.statussender = '';
    }
    if (this.reservationdate === null) {
      this.notification.error(
        '<strong class="notification-error">Information</strong>',
        '<strong class="notification-error">Reservation Date is required</strong>'
      );
      this.statusreservation =  'error';
      SendOut1Data.errormode = true
      return;
    } else {
      this.statusreservation = '';
    }

    if (this.expreturndate === null && SelfData.selectedSendouttype >= 3) {
      this.notification.error(
        '<strong class="notification-error">Information</strong>',
        '<strong class="notification-error">Exp. Return Date is required</strong>'
      );
      this.statusreturneddate =  'error';
      SendOut1Data.errormode = true
      return;
    } else {
      this.statusreturneddate = '';
    }
    if (this.recipiendstring === null || this.recipiendstring.trim() === "") {
      this.notification.error(
        '<strong class="notification-error">Information</strong>',
        '<strong class="notification-error">Recipient is required</strong>'
      );
      this.statusrecipiend = 'error';
      SendOut1Data.errormode = true
      return;
    } else {
      this.statusrecipiend = '';
    }
    if (this.returnedstring === null || this.recipiendstring.trim() === "") {
      this.notification.error(
        '<strong class="notification-error">Information</strong>',
        '<strong class="notification-error">Return Point of Contact is required</strong>'
      );
      this.statusreturned = 'error';
      SendOut1Data.errormode = true
      return;
    } else {
      this.statusreturned = '';
    }
    if (this.sendouttypestring === null) {
      this.notification.error(
        '<strong class="notification-error">Information</strong>',
        '<strong class="notification-error">Send Out Type is required</strong>'
      );
      this.statussendouttype = 'error';
      SendOut1Data.errormode = true
      return;
    } else {
      this.statussendouttype = '';
    }

    if (this.currencystring === null) {
      this.notification.error(
        '<strong class="notification-error">Information</strong>',
        '<strong class="notification-error">Currency is required</strong>'
      );
      this.statussecurrency = 'error';
      SendOut1Data.errormode = true
      return;
    } else {
      this.statussecurrency = '';
    }

    if (this.requestbystring === null || this.recipiendstring.trim() === "") {
      this.notification.error(
        '<strong class="notification-error">Information</strong>',
        '<strong class="notification-error">Requested By is required</strong>'
      );
      this.statusrequestby = 'error';
      SendOut1Data.errormode = true
      return;
    } else {
      this.statusrequestby = '';
    }

    if (this.dispatchstring === null || this.dispatchstring.trim() === "") {
      this.notification.error(
        '<strong class="notification-error">Information</strong>',
        '<strong class="notification-error">Type of Dispatch is required</strong>'
      );
      this.statusdispatch = 'error';
      SendOut1Data.errormode = true
      return;
    } else {
      this.statusdispatch = '';
    }
  }

  ngOnDestroy(): void {
    if (this.subscription4) {
      this.subscription4.unsubscribe();
    }
    if (this.onshowframe) {return}
    console.log("SelfData.saveonclose", SelfData.saveonclose, "SelfData.sendoutmode", SelfData.sendoutmode, "SelfData.closesendout", SelfData.closesendout)
    this.serviceservice.prcHistory({
      userid: SelfData.account[0].data[0].id,
      formtype: SelfData.sendoutmode,
      sender: this.senderstring,
      recipient: this.recipiendstring,
      returnpoint: this.returnedstring,
      sendouttype: this.sendouttypestring,
      currency: this.currencystring,
      reservationdate: this.reservationdate instanceof Date ? this.reservationdate : new Date(),
      expreturndate: this.expreturndate instanceof Date ? this.expreturndate : new Date(),
      requestedby: this.requestbystring
    }).subscribe(response => {
      console.log('History record processed:', response);
    });
    if ((SelfData.saveonclose && SelfData.sendoutmode === 0) || SelfData.closesendout) {
      console.log("AQ1")
      // SendOut1Data.check1 = this.check1.value;
      // SendOut1Data.check2 = this.check2.value;
      // SendOut1Data.check3 = this.check3.value;
      // SendOut1Data.departmentFormControl = this.departmentFormControl.value;
      // SendOut1Data.shipFormControl = this.shipFormControl.value;
      // SendOut1Data.shiptoControl = this.shiptoControl.value;
      // SendOut1Data.todControl = this.todControl.value;
      // SendOut1Data.peControl = this.peControl.value;
      // SendOut1Data.companynameControl = this.companynameControl.value;
      // SendOut1Data.addresslabelControl = this.addresslabelControl.value;
      // SendOut1Data.costofshippingControl = this.costofshippingControl.value;
      // SendOut1Data.cimControl = this.cimControl.value;
      // SendOut1Data.publicationControl = this.publicationControl.value;
      // SendOut1Data.estylistControl = this.estylistControl.value;
      // SendOut1Data.photographerControl = this.photographerControl.value;
      // SendOut1Data.pelControl = this.pelControl.value;
      // SendOut1Data.petControl = this.petControl.value;
      // SendOut1Data.issueControl = this.issueControl.value;
      // SendOut1Data.trackingNumberControl = this.trackingNumberControl.value;
      // SendOut1Data.returnControl = this.returnControl.value;
      // SendOut1Data.notesControl = this.notesControl.value;
      // SendOut1Data.senderFormControl = this.senderFormControl.value;
      // SendOut1Data.requestedbyFormControl = this.requestedbyFormControl.value;
      // SendOut1Data.sendOutTypeControl = this.sendOutTypeControl.value;
      // SendOut1Data.currancyControl = this.currancyControl.value;
      // if (this.reservationdateControl.value) {
      //   SendOut1Data.reservationdateControl = new Date(this.reservationdateControl.value);
      // } else {
      //   SendOut1Data.reservationdateControl = null;
      // }
      // if (this.returndateControl.value) {
      //   SendOut1Data.reservationdateControl = new Date(this.returndateControl.value);
      // } else {
      //   SendOut1Data.reservationdateControl = null;
      // }
      // SendOut1Data.datetimeCtrl = this.group.get('datetimeCtrl')?.value;
      // SendOut1Data.datetimeCtrl1 = this.group.get('datetimeCtrl1')?.value;
      // SendOut1Data.datetimeCtrl2 = this.group.get('datetimeCtrl2')?.value;
      // SendOut1Data.datetimeCtrl3 = this.group.get('datetimeCtrl3')?.value;
      // SendOut1Data.datetimeCtrl4 = this.group.get('datetimeCtrl3')?.value;
      // SendOut1Data.celebriti = this.celebritiControl.value;

      SendOut1Data.check1 = this.check1.value;
      SendOut1Data.check2 = this.check2.value;
      SendOut1Data.check3 = this.check3.value;
      SendOut1Data.departmentFormControl = this.departmentstring; // Replace with string property
      SendOut1Data.senderFormControl = this.senderstring; // Replace with string property
      SendOut1Data.returnControl = this.returnedstring; // Replace with string property
      SendOut1Data.companynameControl = this.recipiendstring; // Replace with string property
      SendOut1Data.shiptoControl = this.shiptoControl.value; // Replace with string property
      SendOut1Data.sendOutTypeControl = this.sendouttypestring; // Replace with string property
      SendOut1Data.currancyControl = this.currencystring; // Replace with string property
      SendOut1Data.requestedbyFormControl = this.requestbystring; // Replace with string property
      SendOut1Data.costofshippingControl = this.costofshipping; // Replace with string property
      SendOut1Data.trackingNumberControl = this.trackingNumber; // Replace with string property
      SendOut1Data.cimControl = this.cimsstring; // Replace with string property
      SendOut1Data.peControl = this.pestring; // Replace with string property
      SendOut1Data.publicationControl = this.publicationstring; // Replace with string property
      SendOut1Data.issueControl = this.issuestring; // Replace with string property
      SendOut1Data.estylistControl = this.esstring; // Replace with string property
      SendOut1Data.photographerControl = this.photograferstring; // Replace with string property
      SendOut1Data.pelControl = this.pelstring; // Replace with string property
      SendOut1Data.petControl = this.petstring; // Replace with string property
      SendOut1Data.notesControl = this.descriptionstring; // Replace with string property
      SendOut1Data.celebriti = this.clientsstring; // Replace with string property
      SendOut1Data.todControl = this.dispatchstring; // Replace with string property

// Handle date and time-related fields
      SendOut1Data.reservationdateControl = this.reservationdate;
      SendOut1Data.returndateControl = this.expreturndate;
      SendOut1Data.datetimeCtrl1 = this.expreturndate;
      SendOut1Data.datetimeCtrl2 = this.runthroughdate;
      if (this.pedsDate && this.pedsDate.length > 1) {
        SendOut1Data.datetimeCtrl3 = this.pedsDate[0];
        SendOut1Data.datetimeCtrl4 = this.pedsDate[1];
      } else {
        SendOut1Data.datetimeCtrl3 = null; // or some default value
        SendOut1Data.datetimeCtrl4 = null; // or some default value
      }
      let actrl: number = 0
      let aid: number = 0
      if (this.SelfData.fullPage) {
        actrl = 1
        aid = SendOut1Data.id
      } else {
        actrl = 0
        aid = 0
      }


      const fields = {
        ctrl: actrl,
        id: aid,
        check1: SendOut1Data.check1 || false,
        check2: SendOut1Data.check2 || false,
        check3: SendOut1Data.check3 || false,
        department: SendOut1Data.departmentFormControl || '',
        ship: SendOut1Data.shipFormControl || '',
        shipto: SendOut1Data.shiptoControl || '',
        tod: SendOut1Data.todControl || '',
        pe: SendOut1Data.peControl || '',
        companyname: SendOut1Data.companynameControl || '',
        addresslabel: SendOut1Data.addresslabelControl || '',
        costofshipping: SendOut1Data.costofshippingControl || '',
        cim: SendOut1Data.cimControl || '',
        publication: SendOut1Data.publicationControl || '',
        estylist: SendOut1Data.estylistControl || '',
        photographer: SendOut1Data.photographerControl || '',
        pel: SendOut1Data.pelControl || '',
        pet: SendOut1Data.petControl || '',
        issue: SendOut1Data.issueControl || '',
        trackingNumber: SendOut1Data.trackingNumberControl || '',
        return: SendOut1Data.returnControl || '',
        notes: SendOut1Data.notesControl || '',
        sender: SendOut1Data.senderFormControl || '',
        requestedby: SendOut1Data.requestedbyFormControl || '',
        sendOutType: SendOut1Data.sendOutTypeControl || '',
        currancy: SendOut1Data.currancyControl || '',
        reservationdate: SendOut1Data.reservationdateControl,
        returndate: SendOut1Data.returndateControl,
        datetimeCtrl: SendOut1Data.datetimeCtrl,
        datetimeCtrl1: SendOut1Data.datetimeCtrl1,
        datetimeCtrl2: SendOut1Data.datetimeCtrl2,
        datetimeCtrl3: SendOut1Data.datetimeCtrl3,
        datetimeCtrl4: SendOut1Data.datetimeCtrl4,
        accountid: this.getAccountID(),
        userid: SelfData.account[0].data[0].id,
        sendouttypeid: SelfData.selectedSendouttype, //droebit
        isactivity: true,
        celebriti: SendOut1Data.celebriti || ""
      };

      // let actrl1: number = 0
      // let aid1: number = 0
      // if (SelfData.sendoutpendingid > 0) {
      //   actrl1 = 1
      //   aid1 = SelfData.sendoutpendingid
      // } else {
      //   actrl1 = 0
      //   aid1 = 0
      // }

      // const fields1 = {
      //   ctrl: actrl1,
      //   id: aid1,
      //   check1: SendOut1Data.check1 || false,
      //   check2: SendOut1Data.check2 || false,
      //   check3: SendOut1Data.check3 || false,
      //   department: SendOut1Data.departmentFormControl || '',
      //   ship: SendOut1Data.shipFormControl || '',
      //   shipto: SendOut1Data.shiptoControl || '',
      //   tod: SendOut1Data.todControl || '',
      //   pe: SendOut1Data.peControl || '',
      //   companyname: SendOut1Data.companynameControl || '',
      //   addresslabel: SendOut1Data.addresslabelControl || '',
      //   costofshipping: SendOut1Data.costofshippingControl || '',
      //   cim: SendOut1Data.cimControl || '',
      //   publication: SendOut1Data.publicationControl || '',
      //   estylist: SendOut1Data.estylistControl || '',
      //   photographer: SendOut1Data.photographerControl || '',
      //   pel: SendOut1Data.pelControl || '',
      //   pet: SendOut1Data.petControl || '',
      //   issue: SendOut1Data.issueControl || '',
      //   trackingNumber: SendOut1Data.trackingNumberControl || '',
      //   return: SendOut1Data.returnControl || '',
      //   notes: SendOut1Data.notesControl || '',
      //   sender: SendOut1Data.senderFormControl || '',
      //   requestedby: SendOut1Data.requestedbyFormControl || '',
      //   sendOutType: SendOut1Data.sendOutTypeControl || '',
      //   currancy: SendOut1Data.currancyControl || '',
      //   reservationdate: SendOut1Data.reservationdateControl,
      //   returndate: SendOut1Data.returndateControl,
      //   datetimeCtrl: SendOut1Data.datetimeCtrl,
      //   datetimeCtrl1: SendOut1Data.datetimeCtrl1,
      //   datetimeCtrl2: SendOut1Data.datetimeCtrl2,
      //   datetimeCtrl3: SendOut1Data.datetimeCtrl3,
      //   datetimeCtrl4: SendOut1Data.datetimeCtrl4,
      //   accountid: this.getAccountID(),
      //   userid: SelfData.account[0].data[0].id,
      //   sendouttypeid: SelfData.selectedSendouttype, //droebit
      //   isactivity: true,
      //   celebriti: SendOut1Data.celebriti || ""
      // };

      console.log("fields", fields)

        this.serviceservice
          .prcSendOut(fields)
          .subscribe((value) => {
            if (this.SelfData.fullPage) {
              this.yourappsService.getReloadSendOutList(null, 1)
            }
            SelfData.closesendout = false
            SelfData.saveonclose = false
            SelfData.sendoutid = value.data
            console.log("SelfData.sendoutid", SelfData.sendoutid)
            // SelfData.saveonclose = false
          });

      // this.serviceservice
      //   .prcSendOutPending(fields1)
      //   .subscribe((value) => {
      //     SelfData.sendoutpendingid = value.data
      //   });
      this.subscription?.unsubscribe();
      this.subscription1?.unsubscribe();
      this.subscription2?.unsubscribe();
    } else if (SelfData.saveonclose && SelfData.sendoutmode === 1) {
      console.log("AQ2")
      // SendOut1Data.check1 = this.check1.value;
      // SendOut1Data.check2 = this.check2.value;
      // SendOut1Data.check3 = this.check3.value;
      // SendOut1Data.departmentFormControl = this.departmentFormControl.value;
      // SendOut1Data.shipFormControl = this.shipFormControl.value;
      // SendOut1Data.shiptoControl = this.shiptoControl.value;
      // SendOut1Data.todControl = this.todControl.value;
      // SendOut1Data.peControl = this.peControl.value;
      // SendOut1Data.companynameControl = this.companynameControl.value;
      // SendOut1Data.addresslabelControl = this.addresslabelControl.value;
      // SendOut1Data.costofshippingControl = this.costofshippingControl.value;
      // SendOut1Data.cimControl = this.cimControl.value;
      // SendOut1Data.publicationControl = this.publicationControl.value;
      // SendOut1Data.estylistControl = this.estylistControl.value;
      // SendOut1Data.photographerControl = this.photographerControl.value;
      // SendOut1Data.pelControl = this.pelControl.value;
      // SendOut1Data.petControl = this.petControl.value;
      // SendOut1Data.issueControl = this.issueControl.value;
      // SendOut1Data.trackingNumberControl = this.trackingNumberControl.value;
      // SendOut1Data.returnControl = this.returnControl.value;
      // SendOut1Data.notesControl = this.notesControl.value;
      // SendOut1Data.senderFormControl = this.senderFormControl.value;
      // SendOut1Data.requestedbyFormControl = this.requestedbyFormControl.value;
      // SendOut1Data.sendOutTypeControl = this.sendOutTypeControl.value;
      // SendOut1Data.currancyControl = this.currancyControl.value;
      // if (this.reservationdateControl.value) {
      //   SendOut1Data.reservationdateControl = new Date(this.reservationdateControl.value);
      // } else {
      //   SendOut1Data.reservationdateControl = null;
      // }
      // if (this.returndateControl.value) {
      //   SendOut1Data.reservationdateControl = new Date(this.returndateControl.value);
      // } else {
      //   SendOut1Data.reservationdateControl = null;
      // }
      // SendOut1Data.datetimeCtrl = this.group.get('datetimeCtrl')?.value;
      // SendOut1Data.datetimeCtrl1 = this.group.get('datetimeCtrl1')?.value;
      // SendOut1Data.datetimeCtrl2 = this.group.get('datetimeCtrl2')?.value;
      // SendOut1Data.datetimeCtrl3 = this.group.get('datetimeCtrl3')?.value;
      // SendOut1Data.datetimeCtrl4 = this.group.get('datetimeCtrl3')?.value;
      // SendOut1Data.celebriti = this.celebritiControl.value;
      SendOut1Data.check1 = this.check1.value;
      SendOut1Data.check2 = this.check2.value;
      SendOut1Data.check3 = this.check3.value;
      SendOut1Data.departmentFormControl = this.departmentstring; // Replaced with string property
      SendOut1Data.shipFormControl = this.shipFormControl.value; // Assuming you have a corresponding string property for shipFormControl
      SendOut1Data.shiptoControl = this.shiptoControl.value; // Replaced with string property
      SendOut1Data.todControl = this.dispatchstring; // Assuming you have a corresponding string property for todControl
      SendOut1Data.peControl = this.pestring; // Replaced with string property
      SendOut1Data.companynameControl = this.recipiendstring; // Assuming you have a corresponding string property for companynameControl
      SendOut1Data.addresslabelControl = this.addresslabelControl.value; // Assuming you have a corresponding string property for addresslabelControl
      SendOut1Data.costofshippingControl = this.costofshipping; // Replaced with string property
      SendOut1Data.cimControl = this.cimsstring; // Replaced with string property
      SendOut1Data.publicationControl = this.publicationstring; // Replaced with string property
      SendOut1Data.estylistControl = this.esstring; // Replaced with string property
      SendOut1Data.photographerControl = this.photograferstring; // Replaced with string property
      SendOut1Data.pelControl = this.pelstring; // Replaced with string property
      SendOut1Data.petControl = this.petstring; // Replaced with string property
      SendOut1Data.issueControl = this.issuestring; // Replaced with string property
      SendOut1Data.trackingNumberControl = this.trackingNumber; // Replaced with string property
      SendOut1Data.returnControl = this.returnedstring; // Replaced with string property
      SendOut1Data.notesControl = this.descriptionstring; // Replaced with string property
      SendOut1Data.senderFormControl = this.senderstring; // Replaced with string property
      SendOut1Data.requestedbyFormControl = this.requestbystring; // Replaced with string property
      SendOut1Data.sendOutTypeControl = this.sendouttypestring; // Replaced with string property
      SendOut1Data.currancyControl = this.currencystring; // Replaced with string property

// Handle date and time-related fields
      SendOut1Data.datetimeCtrl = this.reservationdate; // Assuming datetimeCtrl is a property
      SendOut1Data.datetimeCtrl1 = this.expreturndate; // Assuming datetimeCtrl1 is a property
      SendOut1Data.datetimeCtrl2 = this.runthroughdate; // Replaced with date property
      SendOut1Data.datetimeCtrl3 = this.pedsDate ? this.pedsDate[0] : null; // First date in pedsDate array
      SendOut1Data.datetimeCtrl4 = this.pedsDate ? this.pedsDate[1] : null; // Second date in pedsDate array
      SendOut1Data.celebriti = this.clientsstring; // Replaced with string property

      const fields = {
        ctrl: 0,
        id: 0,
        check1: SendOut1Data.check1 || false,
        check2: SendOut1Data.check2 || false,
        check3: SendOut1Data.check3 || false,
        department: SendOut1Data.departmentFormControl || '',
        ship: SendOut1Data.shipFormControl || '',
        shipto: SendOut1Data.shiptoControl || '',
        tod: SendOut1Data.todControl || '',
        pe: SendOut1Data.peControl || '',
        companyname: SendOut1Data.companynameControl || '',
        addresslabel: SendOut1Data.addresslabelControl || '',
        costofshipping: SendOut1Data.costofshippingControl || '',
        cim: SendOut1Data.cimControl || '',
        publication: SendOut1Data.publicationControl || '',
        estylist: SendOut1Data.estylistControl || '',
        photographer: SendOut1Data.photographerControl || '',
        pel: SendOut1Data.pelControl || '',
        pet: SendOut1Data.petControl || '',
        issue: SendOut1Data.issueControl || '',
        trackingNumber: SendOut1Data.trackingNumberControl || '',
        return: SendOut1Data.returnControl || '',
        notes: SendOut1Data.notesControl || '',
        sender: SendOut1Data.senderFormControl || '',
        requestedby: SendOut1Data.requestedbyFormControl || '',
        sendOutType: SendOut1Data.sendOutTypeControl || '',
        currancy: SendOut1Data.currancyControl || '',
        reservationdate: SendOut1Data.reservationdateControl,
        returndate: SendOut1Data.returndateControl,
        datetimeCtrl: SendOut1Data.datetimeCtrl,
        datetimeCtrl1: SendOut1Data.datetimeCtrl1,
        datetimeCtrl2: SendOut1Data.datetimeCtrl2,
        datetimeCtrl3: SendOut1Data.datetimeCtrl3,
        datetimeCtrl4: SendOut1Data.datetimeCtrl4,
        accountid: this.getAccountID(),
        userid: SelfData.account[0].data[0].id,
        sendouttypeid: SelfData.selectedSendouttype, //droebit
        isactivity: true,
        celebriti: SendOut1Data.celebriti || "",
        sendoutid: 0,
        isaccepted: false
      };

      console.log("fields", fields)

      this.serviceservice
        .prcRequest(fields)
        .subscribe((value) => {
          SelfData.sendoutid = value.data
          // SelfData.saveonclose = false
        });

      this.subscription?.unsubscribe();
      this.subscription1?.unsubscribe();
      this.subscription2?.unsubscribe();
    }
  }

  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
      ? SelfData.account[0].data[0].id
      : SelfData.account[0].data[0].parentid;
  }

  private subscription?: Subscription;
  private subscription1?: Subscription;
  private subscription2?: Subscription;
  private subscription3?: Subscription;
  private subscription4?: Subscription;

  ngOnInit(): void {

    this.yourappsService.currentSendOutData.subscribe((data) => {
      if (SelfData.sendoutopentype === 0) {
        data === null
      }
      if (data != null) {
        SendOut1Data.id = data.id
        SendOut1Data.check1 = data.check1
        SendOut1Data.check2 = data.check2
        SendOut1Data.check3 = data.check3
        SendOut1Data.shipFormControl = data?.["ship"]
        SendOut1Data.shiptoControl = data?.["shipto"]
        SendOut1Data.todControl = data?.["tod"]
        SendOut1Data.peControl = data?.["pe"]
        SendOut1Data.companynameControl = data?.["companyname"]
        SendOut1Data.addresslabelControl = data?.["addresslabel"]
        SendOut1Data.costofshippingControl = data?.["costofshipping"]
        SendOut1Data.cimControl = data?.["cim"]
        SendOut1Data.publicationControl = data?.["publication"]
        SendOut1Data.estylistControl = data?.["estylist"]
        SendOut1Data.photographerControl = data?.["photographer"]
        SendOut1Data.pelControl = data?.["pel"]
        SendOut1Data.petControl = data?.["pet"]
        SendOut1Data.issueControl = data?.["issue"]
        SendOut1Data.trackingNumberControl = data?.["trackingNumber"]
        SendOut1Data.returnControl = data?.["return"]
        SendOut1Data.notesControl = data?.["notes"]
        SendOut1Data.senderFormControl = data?.["sender"]
        SendOut1Data.requestedbyFormControl = data?.["requestedby"]
        SendOut1Data.sendOutTypeControl = data?.["sendOutType"]
        SendOut1Data.currancyControl = data?.["currancy"]
        SendOut1Data.reservationdateControl = data?.["reservationdate"]
        SendOut1Data.returndateControl = data?.["returndate"]
        SendOut1Data.datetimeCtrl = data?.["datetimeCtrl"]
        SendOut1Data.datetimeCtrl1 = data?.["datetimeCtrl1"]
        SendOut1Data.datetimeCtrl2 = data?.["datetimeCtrl2"]
        SendOut1Data.datetimeCtrl3 = data?.["datetimeCtrl3"]
        SendOut1Data.celebriti = data?.["celebriti"]
        SelfData.contactid = data?.["contactid"]
      } else {
        // SendOut1Data.check1 = false;
        // SendOut1Data.check2 = false;
        // SendOut1Data.check3 = false;
        // SendOut1Data.departmentFormControl = '';
        // SendOut1Data.shipFormControl = '';
        // SendOut1Data.shiptoControl = '';
        // SendOut1Data.todControl = '';
        // SendOut1Data.peControl = '';
        // SendOut1Data.companynameControl = '';
        // SendOut1Data.addresslabelControl = '';
        // SendOut1Data.costofshippingControl = '';
        // SendOut1Data.cimControl = '';
        // SendOut1Data.publicationControl = '';
        // SendOut1Data.estylistControl = '';
        // SendOut1Data.photographerControl = '';
        // SendOut1Data.pelControl = '';
        // SendOut1Data.petControl = '';
        // SendOut1Data.issueControl = '';
        // SendOut1Data.trackingNumberControl = '';
        // SendOut1Data.returnControl = '';
        // SendOut1Data.notesControl = '';
        // SendOut1Data.senderFormControl = '';
        // SendOut1Data.requestedbyFormControl = '';
        // SendOut1Data.sendOutTypeControl = '';
        // SendOut1Data.currancyControl = '';
        // SendOut1Data.reservationdateControl = null;
        // SendOut1Data.returndateControl = null;
        // SendOut1Data.datetimeCtrl = null;
        // SendOut1Data.datetimeCtrl1 = null;
        // SendOut1Data.datetimeCtrl2 = null;
        // SendOut1Data.datetimeCtrl3 = null;
        // SendOut1Data.datetimeCtrl4 = null;
        // SendOut1Data.celebriti = '';
      }
    })
    console.log("SendOut1Data", SendOut1Data.id)
    this.yourappsService.setSelectedID(-1)
    this.check1.setValue(SendOut1Data.check1);
    this.check2.setValue(SendOut1Data.check2);
    this.check3.setValue(SendOut1Data.check3);
    // this.departmentFormControl.setValue(SendOut1Data.departmentFormControl);
    this.shipFormControl.setValue(SendOut1Data.shipFormControl);
    this.shiptoControl.setValue(SendOut1Data.shiptoControl);
    this.addresslabelControl.setValue(SendOut1Data.addresslabelControl);
    // this.todControl.setValue(SendOut1Data.todControl);
    // this.peControl.setValue(SendOut1Data.peControl);
    // this.companynameControl.setValue(SendOut1Data.companynameControl);
    // this.addresslabelControl.setValue(SendOut1Data.addresslabelControl);
    // this.costofshippingControl.setValue(SendOut1Data.costofshippingControl);
    // this.cimControl.setValue(SendOut1Data.cimControl);
    // this.publicationControl.setValue(SendOut1Data.publicationControl);
    // this.estylistControl.setValue(SendOut1Data.estylistControl);
    // this.photographerControl.setValue(SendOut1Data.photographerControl);
    // this.pelControl.setValue(SendOut1Data.pelControl);
    // this.petControl.setValue(SendOut1Data.petControl);
    // this.issueControl.setValue(SendOut1Data.issueControl);
    // this.trackingNumberControl.setValue(SendOut1Data.trackingNumberControl);
    // this.returnControl.setValue(SendOut1Data.returnControl);
    // this.notesControl.setValue(SendOut1Data.notesControl);
    // this.senderFormControl.setValue(SendOut1Data.senderFormControl);
    // this.requestedbyFormControl.setValue(SendOut1Data.requestedbyFormControl);
    // this.sendOutTypeControl.setValue(SendOut1Data.sendOutTypeControl);
    // this.currancyControl.setValue(SendOut1Data.currancyControl);
    // if (SendOut1Data.reservationdateControl) {
    //   this.reservationdateControl.setValue(SendOut1Data.reservationdateControl.toISOString());
    // } else {
    //   this.reservationdateControl.setValue(null);
    // }
    // if (SendOut1Data.returndateControl) {
    //   this.reservationdateControl.setValue(SendOut1Data.returndateControl.toISOString());
    // } else {
    //   this.reservationdateControl.setValue(null);
    // }

    this.dispatchstring = SendOut1Data.todControl ?? ''; // Provide default empty string if null
    this.pestring = SendOut1Data.peControl ?? ''; // Provide default empty string if null
    this.recipiendstring = SendOut1Data.companynameControl ?? ''; // Provide default empty string if null
    this.costofshipping = SendOut1Data.costofshippingControl ?? ''; // Provide default empty string if null
    this.cimsstring = SendOut1Data.cimControl ?? ''; // Provide default empty string if null
    this.publicationstring = SendOut1Data.publicationControl ?? ''; // Provide default empty string if null
    this.esstring = SendOut1Data.estylistControl ?? ''; // Provide default empty string if null
    this.photograferstring = SendOut1Data.photographerControl ?? ''; // Provide default empty string if null
    this.pelstring = SendOut1Data.pelControl ?? ''; // Provide default empty string if null
    this.petstring = SendOut1Data.petControl ?? ''; // Provide default empty string if null
    this.issuestring = SendOut1Data.issueControl ?? ''; // Provide default empty string if null
    this.trackingNumber = SendOut1Data.trackingNumberControl ?? ''; // Provide default empty string if null
    this.returnedstring = SendOut1Data.returnControl ?? ''; // Provide default empty string if null
    this.descriptionstring = SendOut1Data.notesControl ?? ''; // Provide default empty string if null
    this.senderstring = SendOut1Data.senderFormControl ?? ''; // Provide default empty string if null
    this.requestbystring = SendOut1Data.requestedbyFormControl ?? ''; // Provide default empty string if null
    this.sendouttypestring = SendOut1Data.sendOutTypeControl ?? ''; // Provide default empty string if null
    this.currencystring = SendOut1Data.currancyControl ?? ''; // Provide default empty string if null

    if (SendOut1Data.reservationdateControl) {
      this.reservationdate = new Date(SendOut1Data.reservationdateControl);
    } else {
      this.reservationdate = null;
    }
    if (SendOut1Data.returndateControl) {
      this.expreturndate = new Date(SendOut1Data.returndateControl);
    } else {
      this.expreturndate = null;
    }

    // this.reservationdate = SendOut1Data.datetimeCtrl;
    // this.expreturndate = SendOut1Data.datetimeCtrl1;
    this.runthroughdate = SendOut1Data.datetimeCtrl2;
    this.pedsDate = [
      SendOut1Data.datetimeCtrl3,
      SendOut1Data.datetimeCtrl4
    ];

// Assign the celebrity control value to the clientsstring
    this.clientsstring = SendOut1Data.celebriti ?? '';

// Check if the clientsstring is not empty and perform the service call
    if (this.clientsstring.trim() !== "") {
      this.serviceservice
        .selectcelebriti({ contactid: SelfData.contactid })
        .subscribe((value) => {
          this.iCelebritiResult = [];
          this.iCelebritiResult[0] = value;
          SelfData.celebriti = this.iCelebritiResult;
          this.updateOptions();
          this.onCelebritiBackSelected(this.clientsstring.trim() || "");
        });
    }

    // this.group.get('datetimeCtrl')?.setValue(SendOut1Data.datetimeCtrl);
    // this.group.get('datetimeCtrl1')?.setValue(SendOut1Data.datetimeCtrl1);
    // this.group.get('datetimeCtrl2')?.setValue(SendOut1Data.datetimeCtrl2);
    // this.group.get('datetimeCtrl3')?.setValue(SendOut1Data.datetimeCtrl3);
    // this.celebritiControl.setValue(SendOut1Data.celebriti)
    // if (this.celebritiControl.value?.trim() !== "") {
    //   this.serviceservice
    //     .selectcelebriti({contactid: SelfData.contactid})
    //     .subscribe((value) => {
    //       this.iCelebritiResult = [];
    //       this.iCelebritiResult[0] = value;
    //       SelfData.celebriti = this.iCelebritiResult;
    //       this.updateOptions()
    //       this.onCelebritiBackSelected(this.celebritiControl.value?.trim() || "")
    //     });
    // }
    this.subscription1 = this.yourappsService.contact$.subscribe((value) => {
      if (this.subscription1) {
        this.serviceservice
          .setContactsList({accountid: this.getAccountID(), contactsid: 0})
          .subscribe({
            next: (value) => {
              if (value) {
                this.iContactsResult[0] = value;
                SelfData.contactdata = this.iContactsResult;
                // this.filteredContactsOptions = this.setDataAndFilter(
                //   this.companynameControl,
                //   value,
                //   this.contactsoptions,
                //   0
                // );
                this.returnoptions = []
                // this.filteredReturnOptions = this.setDataAndFilter(
                //   this.returnControl,
                //   value,
                //   this.returnoptions,
                //   1
                // );
              } else {
                console.log('The response is empty or undefined.');
              }
            },
            error: (error) => {
              console.error('Error occurred during the subscription:', error);
            },
          });
        this.subscription1?.unsubscribe();
      }

      this.subscription2 = this.yourappsService.company$.subscribe((value) => {
        if (this.subscription2) {
          this.serviceservice.setCompanyList({accountid: this.getAccountID()}).subscribe({
            next: (value: GetCompanyList) => {
              if (value && value.data) {
                this.iCompanyResult = []
                this.iCompanyResult[0] = value;
                SelfData.company = this.iCompanyResult;
                // this.filteredCompanyNameOptions = this.setFullDataFilter(
                //   this.companynameControl,
                //   value.data,
                //   this.companyoptions,
                //   0
                // );
              } else {
                console.log('The response is empty or undefined.');
              }
            },
            error: (error) => {
              console.error('Error occurred during the subscription:', error);
            },
          });
        }
        this.subscription2?.unsubscribe();
      });

    })


    this.subscription = this.yourappsService.reloadDDArrayList.subscribe(
      (description) => {
        let accountid: number = this.determineAccountId();
        if (SelfData.modaltype == -1) {
          this.iTODResult = [];
          // this.clearTOD();
          this.iTODResult = SelfData.tod;
          // this.filteredTODOptions = this.setDataAndFilterDescription(
          //   this.todControl,
          //   this.iTODResult[0],
          //   this.todoptions,
          //   2
          // );
        } else if (SelfData.modaltype == -2) {
          this.iPEResult = [];
          // this.clearTOD();

          this.iPEResult = [];
          this.iPEResult = SelfData.pe;
          // this.filteredTODOptions = this.setDataAndFilterDescription(
          //   this.peControl,
          //   this.iPEResult[0],
          //   this.peoptions,
          //   3
          // );
        }

        // switch (SelfData.modaltype) {
        //   case -1:
        //     this.reloadtodlist(accountid);
        //     break;
        // }
      }
    );
    this.subscription3 = this.yourappsService.reloadCelebritiList.subscribe(
      (value) => {
        this.serviceservice
          .selectcelebriti({contactid: SelfData.contactid})
          .subscribe((value) => {
            this.iCelebritiResult = [];
            this.iCelebritiResult[0] = value
            SelfData.celebriti = this.iCelebritiResult;
            this.subscription3?.unsubscribe()
            this.updateOptions()
          });
      })
  }

  updateOptions() {
    this.optionsCelebriti = this.iCelebritiResult[0]?.data || [];
  }

  // onSelectionChange(event: Event | string | null) {
  //   const selectedIndex = this.sendouttype.findIndex(item => item.value === event);
  //   this.yourappsService.setSelectedID(selectedIndex)
  //   console.log("selectedIndex", selectedIndex)
  //   SelfData.selectedSendouttype = selectedIndex
  //   const datetimeCtrl1 = this.group.get('datetimeCtrl1');
  //   if (selectedIndex >= 3 && selectedIndex <= 7) {
  //     this.returnedenabled = false
  //     this.returneddateenabled = false
  //     datetimeCtrl1?.disable();
  //     this.returnControl.disable();
  //   } else {
  //     datetimeCtrl1?.enable();
  //     this.returnControl.enable();
  //     this.returnedenabled = true
  //     this.returneddateenabled = true
  //   }
  // }

  onSelectionChange(event: Event | string | null) {
    const selectedIndex = this.sendouttype.findIndex((item: { value: string; }) => item.value === (typeof event === 'string' ? event : ''));
    this.yourappsService.setSelectedID(selectedIndex);
    console.log("selectedIndex", selectedIndex);
    SelfData.selectedSendouttype = selectedIndex;

    // Handling the datetime control and return control based on the selected index
    if (selectedIndex >= 3 && selectedIndex <= 7) {
      this.returnedenabled = false;
      this.returneddateenabled = false;
      this.expreturndate = null; // Disable and clear the return date if necessary
      // this.returnControl.disable();
    } else {
      this.returnedenabled = true;
      this.returneddateenabled = true;
      // this.returnControl.enable();
    }
  }

//   onCheckboxChange(event: any) {
//     const datetimeCtrl1 = this.group.get('datetimeCtrl');
//     const datetimeCtrl2 = this.group.get('datetimeCtrl2');
//     const datetimeCtrl3 = this.group.get('datetimeCtrl3');
//     this.checkboxvalue = event.checked
//     if (event.checked) {
//       console.log("Checkbox is checked");
//       this.returnControl.disable()
//       datetimeCtrl1?.disable();
//       datetimeCtrl2?.disable();
//       datetimeCtrl3?.disable();
//       this.isButtonDisabled = false
//       this.group.get('datetimeRange')?.disable();
//
// // Or enabling individual controls within the range
//       this.group.get('datetimeRange')?.get('start')?.disable();
//       this.group.get('datetimeRange')?.get('end')?.disable();
//
// // Enabling the single date control
//       this.group.get('datetimeCtrl3')?.disable();
//     } else {
//       console.log("Checkbox is unchecked");
//       this.isButtonDisabled = true
//       this.returnControl.enable()
//       datetimeCtrl1?.enable();
//       datetimeCtrl2?.enable();
//       datetimeCtrl3?.enable();
//       this.group.get('datetimeRange')?.enable();
//
// // Or enabling individual controls within the range
//       this.group.get('datetimeRange')?.get('start')?.enable();
//       this.group.get('datetimeRange')?.get('end')?.enable();
//
// // Enabling the single date control
//       this.group.get('datetimeCtrl3')?.enable();
//     }
//   }

  onCheckboxChange(event: any) {
    this.checkboxvalue = event.checked;

    if (event.checked) {
      console.log("Checkbox is checked");
      this.isButtonDisabled = false;

      // Disable controls and clear date values
      this.returnedenabled = false;
      // this.reservationdate = null;
      // this.expreturndate = null;
      // this.runthroughdate = null;
      // this.pedsDate = [null, null]; // Clearing both dates by setting to null
    } else {
      console.log("Checkbox is unchecked");
      this.isButtonDisabled = true;

      // Enable controls and set them to default or existing values
      this.returnedenabled = true;
    }
  }

  // reloadtodlist(accountid: number): void {
  //   this.iTODResult = [];
  //   SelfData.tod = [];
  //   this.serviceservice
  //     .setTODList({accountid: accountid})
  //     .subscribe((value) => {
  //       this.iTODResult[0] = value; // Assign value.data instead of value
  //       SelfData.tod = this.iTODResult;
  //
  //       this.filteredTODOptions = this.setDataAndFilterDescription(
  //         this.todControl,
  //         value,
  //         this.todoptions,
  //         2
  //       );
  //     });
  // }

  iSMList: GetSocialMediaList[] = [];

  loadSMData() {
    this.serviceservice.setSMList({id: this.smid})
      .subscribe({
        next: (value) => {
          if (value && value.data && Array.isArray(value.data)) {
            this.iSMList[0] = value;
            SelfData.smedia = this.iSMList;
            console.log(SelfData.smedia)
            const platforms = ["Instagram", "FaceBook", "TikTok", "YouTube", "Twitter"];
            for (const platform of platforms) {
              const foundPlatform = SelfData.smedia[0].data.find(item => item.platform === platform);
              if (foundPlatform) {
                this.defaultimagelink = foundPlatform.avatarLink;
                this.defaultname = foundPlatform.name;
                break;
              }
            }
          } else {
            console.log('The response is empty, lacks a data property, or the data property is not an array.');
          }
        },
        error: (error) => {
          console.error('Error occurred during the subscription:', error);
        },
      });
  }


  sortedSMedia(): GetSocialMediaListData[] {
    if (this.SelfData && this.SelfData.smedia && this.SelfData.smedia[0] && Array.isArray(this.SelfData.smedia[0].data)) {
      return [...this.SelfData.smedia[0].data].sort((a, b) => b.subscribers - a.subscribers);
    }
    return []; // default to empty array if data isn't available
  }

  openLink(link: string): void {
    window.open(link, "_blank");  // This opens the link in a new window or tab.
    // window.location.href = link;  // Use this instead if you want to navigate to the link in the same window.
  }

  getColor(index: number): string {
    return index % 2 === 0 ? '#efefef' : '#dcdcdc';
  }

  protected readonly event = event;
  protected readonly SelfData = SelfData;
  statussender: '' | 'error' | 'warning' = '';
  statusrecipiend: '' | 'error' | 'warning' = '';
  statusreturned: '' | 'error' | 'warning' = '';
  statussendouttype: '' | 'error' | 'warning' = '';
  statussecurrency: '' | 'error' | 'warning' = '';
  statusreservation: '' | 'error' | 'warning' = '';
  statusreturneddate: '' | 'error' | 'warning' = '';
  statusrequestby: '' | 'error' | 'warning' = '';
  statusdispatch: '' | 'error' | 'warning' = '';

  departmentstring: string = ""
  senderstring: string = ""
  returnedstring: string = ""
  recipiendstring: string = ""
  sendouttypestring: string = ""
  currencystring: string = ""
  requestbystring: string = ""
  timeDefaultValue = setHours(new Date(), 0);
  timeDefaultValueReturned = setHours(new Date(), 0);
  cimsstring: string = ""
  pestring: string = ""
  publicationstring: string = ""
  issuestring: string = ""
  esstring: string = ""
  photograferstring: string = ""
  pelstring: string = ""
  petstring: string = ""
  clientsstring: string = ""
  dispatchstring: string = ""
  costofshipping: string = ""
  trackingNumber: string = ""
  runthroughdate = null
  pedsDate: Date[] | null = null;
  descriptionstring: string = "";
  reservationdate: Date | Date[] | null = null;
  expreturndate: Date | Date[] | null = null;
  onBlurAll() {

  }

  onDateChange(result: Date): void {
    this.reservationdate = result || null
    console.log('Selected Time: ', result);
  }

  onOk(result: Date | Date[] | null): void {
    this.reservationdate = result || null
    console.log('onOk', result);
  }

  onDateChange1(result: Date): void {
    this.expreturndate = result || null
    console.log('Selected Time: ', result);
  }

  onOk1(result: Date | Date[] | null): void {
    this.expreturndate = result || null
    console.log('onOk', result);
  }

  onCalendarChange(result: Array<Date | null>): void {
    console.log('onCalendarChange', result);
  }
}
