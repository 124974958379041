<div class="mainpanel" [formGroup]="group">
  <div class="background-filter">
    <div class="child-60">
      <div class="edits">
        <mat-form-field *ngIf="selectcheckboxShipDatebool" class="form-field-spacing-contact" appearance="fill">
          <mat-label>Ship Date</mat-label>
          <mat-date-range-input [rangePicker]="picker" [formGroup]="dateRange">
            <input matStartDate formControlName="start" placeholder="Start date">
            <input matEndDate formControlName="end" placeholder="End date">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>

        <mat-form-field *ngIf="selectcheckboxDepartmentbool" class="form-field-spacing-contact" appearance="fill">
          <mat-label>Department</mat-label>
          <input matInput [formControl]="departmentFormControl" required/>
        </mat-form-field>

        <mat-form-field *ngIf="selectcheckboxDesignerbool" class="form-field-spacing-contact" appearance="fill">
          <mat-label>Desinger</mat-label>
          <input type="text" matInput placeholder="Select or type..." [matAutocomplete]="auto2"
            [formControl]="desingerFormControl"
            #autoInput2="matAutocompleteTrigger"/>

          <mat-autocomplete #auto2="matAutocomplete">
            <mat-option
              *ngFor="let item of filteredDesignerOptions | async"
              [value]="item">
              {{ item }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field *ngIf="selectcheckboxSeasonbool" class="form-field-spacing-contact" appearance="fill">
          <mat-label>Season</mat-label>
          <input type="text" matInput placeholder="Select or type..." [matAutocomplete]="auto7"
            [formControl]="seasonFormControl"
            #autoInput7="matAutocompleteTrigger"/>
          <mat-autocomplete #auto7="matAutocomplete">
            <mat-option
              *ngFor="let item of filteredSeasonOptions | async"
              [value]="item">
              {{ item }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field *ngIf="selectcheckboxCollectionbool" class="form-field-spacing-contact" appearance="fill">
          <mat-label>Collection</mat-label>
          <input type="text" matInput placeholder="Select or type..." [matAutocomplete]="collection"
            [formControl]="collectionFormControl"
            #collectionInput="matAutocompleteTrigger"/>

          <mat-autocomplete #collection="matAutocomplete">
            <mat-option *ngFor="let item of filteredCollectionOptions | async" [value]="item">
              {{ item }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field *ngIf="selectcheckboxColorbool" class="form-field-spacing-contact" appearance="fill">
          <mat-label>Color</mat-label>
          <mat-select [formControl]="colorControl">
            <mat-option
              *ngFor="let color of colorarray"
              [value]="color.colorname">
              <span class="color-box" [style.background]="color.colorcode">
              </span>
              {{ color.colorname }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="selectcheckboxCountryOfOriginbool" class="form-field-spacing-contact" appearance="fill">
          <mat-label>Country of Origin</mat-label>
          <input type="text" matInput placeholder="Select or type..." [matAutocomplete]="auto27"
            [formControl]="countri2Control"/>
          <mat-autocomplete #auto27="matAutocomplete">
            <mat-option *ngFor="let item of filteredCountries | async" [value]="item.country">
              {{ item.country }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field *ngIf="selectcheckboxEditorStylistbool" class="form-field-spacing-contact" appearance="fill">
          <mat-label>Editor/Stylist</mat-label>
          <input matInput [formControl]="estylistControl"/>
        </mat-form-field>

        <mat-form-field *ngIf="selectcheckboxEventbool" class="form-field-spacing-contact" appearance="fill">
          <mat-label>Event</mat-label>
          <input type="text" matInput placeholder="Select or type..." [matAutocomplete]="auto44"
            [formControl]="peControl" #autoInput4="matAutocompleteTrigger"/>

          <mat-autocomplete #auto44="matAutocomplete">
            <mat-option *ngFor="let item of iPEResult[0]?.data" [value]="item.description">
              {{ item.description }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field *ngIf="selectcheckboxFabricbool" class="form-field-spacing-contact" appearance="fill">
          <mat-label>Fabric</mat-label>
          <input type="text" matInput placeholder="Select or type..." [matAutocomplete]="fabric"
            [formControl]="fabricControl"
            #fabricInput="matAutocompleteTrigger"/>

          <mat-autocomplete #fabric="matAutocomplete">
            <mat-option *ngFor="let item of filteredFabricOptions | async" [value]="item">
              {{ item }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field *ngIf="selectcheckboxGenderbool" class="form-field-spacing-contact" appearance="fill">
          <mat-label>Gender</mat-label>
          <input type="text" matInput placeholder="Select or type..." [matAutocomplete]="gender1"
            [formControl]="genderControl"
            #genderInput="matAutocompleteTrigger"/>
          <mat-autocomplete #gender1="matAutocomplete">
            <mat-option *ngFor="let gender of gender" [value]="gender.viewValue">
              {{ gender.viewValue }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field *ngIf="selectsamblestatusbool" class="form-field-spacing-contact" appearance="fill">
          <mat-label>Sample Status</mat-label>
          <input type="text" matInput placeholder="Select or type..." [matAutocomplete]="sample1"
            [formControl]="selectsamplestatus"
            #sampleInput="matAutocompleteTrigger"/>
          <mat-autocomplete #sample1="matAutocomplete">
            <mat-option *ngFor="let sample of samplestatus" [value]="sample.value">
              {{ sample.value }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <div>
          <mat-checkbox *ngIf="selectcheckboxIncludeStaffLoansbool" class="form-field-spacing-contact" [formControl]="check1">Include Staff Loans
          </mat-checkbox>
        </div>

        <mat-form-field *ngIf="selectcheckboxManufacturerbool" class="form-field-spacing-contact" appearance="fill">
          <mat-label>Manufacturer</mat-label>
          <input type="text" matInput placeholder="Select or type..." [matAutocomplete]="auto4"
            [formControl]="manuControl" #autoInput4="matAutocompleteTrigger"/>
          <mat-autocomplete #auto4="matAutocomplete">
            <mat-option
              *ngFor="let item of filteredManuOptions | async"
              [value]="item">
              {{ item }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field *ngIf="selectcheckboxMethodOfDispatchbool" class="form-field-spacing-contact" appearance="fill">
          <mat-label>Method of Dispatch</mat-label>
          <input type="text" matInput placeholder="Select or type..." [matAutocomplete]="auto300"
            [formControl]="todControl" #autoInput3="matAutocompleteTrigger"/>
          <mat-autocomplete #auto300="matAutocomplete">
            <mat-option *ngFor="let item of iTODResult[0]?.data" [value]="item.description">
              {{ item.description }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field *ngIf="selectcheckboxMiscbool" class="form-field-spacing-contact" appearance="fill">
          <mat-label>Misc</mat-label>
          <input type="text" matInput placeholder="Select or type..." [matAutocomplete]="auto8"
            [formControl]="miscControl" #autoInput8="matAutocompleteTrigger"/>
          <mat-autocomplete #auto8="matAutocomplete">
            <mat-option
              *ngFor="let item of filteredMisceOptions | async"
              [value]="item">
              {{ item }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field *ngIf="selectcheckboxModelCelebbool" class="form-field-spacing-contact" appearance="fill">
          <mat-label>Model/Celeb</mat-label>
          <input matInput [formControl]="cimControl"/>
        </mat-form-field>

        <mat-form-field *ngIf="selectcheckboxPatternbool" class="form-field-spacing-contact" appearance="fill">
          <mat-label>Look Book</mat-label>
          <input type="text" matInput placeholder="Select or type..." [matAutocomplete]="auto11"
            [formControl]="patternControl" #autoInput11="matAutocompleteTrigger">
          <mat-autocomplete #auto11="matAutocomplete">
            <mat-option *ngFor="let item of filteredPatternOptions | async" [value]="item">
              {{ item }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field *ngIf="selectcheckboxPhotographerbool" class="form-field-spacing-contact" appearance="fill">
          <mat-label>Photographer</mat-label>
          <input matInput [formControl]="photographerControl"/>
        </mat-form-field>

        <mat-form-field *ngIf="selectcheckboxPublicationbool" class="form-field-spacing-contact" appearance="fill">
          <mat-label>Publication</mat-label>
          <input type="text" matInput placeholder="Select or type..." [matAutocomplete]="auto6"
            [formControl]="publicationControl" #autoInput6="matAutocompleteTrigger"/>
          <mat-autocomplete #auto6="matAutocomplete">
            <mat-option *ngFor="let item of filteredCompanyNameOptions | async" [value]="item">
              {{ item }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field *ngIf="selectcheckboxRecipientbool" class="form-field-spacing-contact" appearance="fill">
          <mat-label>Recipient</mat-label>
          <input type="text" matInput placeholder="Select or type..." [matAutocomplete]="auto100"
            [formControl]="companynameControl"
            #autoInput1="matAutocompleteTrigger"/>
          <mat-autocomplete #auto100="matAutocomplete">
            <mat-option
              *ngFor="let item of filteredContactsOptions | async"
              [value]="item">
              {{ item }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field *ngIf="selectcheckboxRequestedBybool" class="form-field-spacing-contact" appearance="fill">
          <mat-label>Requested By</mat-label>
          <mat-select [formControl]="requestedbyFormControl">
            <mat-option
              *ngFor="let systemuser of iSystemUsersRequstedResult"
              [value]="                  systemuser.data[0].firstname +
                  ' ' +
                  systemuser.data[0].lastname
                "
            >
              {{
                systemuser.data[0].firstname +
                ' ' +
                systemuser.data[0].lastname
              }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="selectcheckboxSenderbool" class="form-field-spacing-contact" appearance="fill">
          <mat-label>Sender</mat-label>
          <mat-select [formControl]="senderFormControl">
            <mat-option *ngFor="let systemuser of iSystemUsersResult"
              [value]="systemuser.data[0].firstname + ' ' + systemuser.data[0].lastname">
              {{ systemuser.data[0].firstname + ' ' + systemuser.data[0].lastname }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="selectcheckboxShootLocationbool" class="form-field-spacing-contact" appearance="fill">
          <mat-label>Shoot Location</mat-label>
          <input matInput [formControl]="pelControl"/>
        </mat-form-field>

        <mat-form-field *ngIf="selectcheckboxRegionbool" class="form-field-spacing-contact" appearance="fill">
          <mat-label>Region</mat-label>
          <input type="text" matInput placeholder="Select or type..." [matAutocomplete]="auto1"
            [formControl]="myRegionControl" #autoInput1="matAutocompleteTrigger"/>
          <mat-autocomplete #auto1="matAutocomplete">
            <mat-option
              *ngFor="let item of filteredRegionOptions | async"
              [value]="item">
              {{ item }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field *ngIf="selectcheckboxSizebool" class="form-field-spacing-contact" appearance="fill">
          <mat-label>Size</mat-label>
          <input type="text" matInput placeholder="Select or type..." [matAutocomplete]="auto10"
            [formControl]="sizeControl" #autoInput10="matAutocompleteTrigger"/>
          <mat-autocomplete #auto10="matAutocomplete">
            <mat-option
              *ngFor="let item of filteredSizeOptions | async"
              [value]="item">
              {{ item }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field *ngIf="selectcheckboxSizeRangebool" class="form-field-spacing-contact" appearance="fill">
          <mat-label>Size Range</mat-label>
          <input type="text" matInput placeholder="Select or type..." [matAutocomplete]="auto12"
            [formControl]="sizerangeControl" #autoInput12="matAutocompleteTrigger"/>
          <mat-autocomplete #auto12="matAutocomplete">
            <mat-option
              *ngFor="let item of filteredSizeRangeOptions | async"
              [value]="item">
              {{ item }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field *ngIf="selectcheckboxStoryThemebool" class="form-field-spacing-contact" appearance="fill">
          <mat-label>Story Theme</mat-label>
          <input matInput [formControl]="petControl"/>
        </mat-form-field>

        <mat-form-field *ngIf="selectcheckboxStyleNumberbool" class="form-field-spacing-contact" appearance="fill">
          <mat-label>Style</mat-label>
          <input type="text" matInput placeholder="Select or type..." [matAutocomplete]="auto60"
            [formControl]="styleControl" #autoInput6="matAutocompleteTrigger"/>

          <mat-autocomplete #auto60="matAutocomplete">
            <mat-option *ngFor="let item of filteredStyleOptions | async" [value]="item">
              {{ item }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field *ngIf="selectcheckboxTypebool" class="form-field-spacing-contact" appearance="fill">
          <mat-label>Type</mat-label>
          <input type="text" matInput placeholder="Select or type..." [matAutocomplete]="auto9"
            [formControl]="typeFormControl" #autoInput9="matAutocompleteTrigger"/>
          <mat-autocomplete #auto9="matAutocomplete">
            <mat-option
              *ngFor="let item of filteredTypeOptions | async"
              [value]="item">
              {{ item }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

      </div>
    </div>
    <div class="child-40">
      <div class="buttonspanel">
        <p-button icon="pi pi-plus" [rounded]="true" severity="secondary" [outlined]="true" (click)="op.toggle($event)"></p-button>
        <p-button label="Apply" [rounded]="true" severity="success" (click)="onApplyClick()"></p-button>
      </div>
    </div>
  </div>
</div>

<p-overlayPanel #op>
  <div class="mainop">
    <!-- Collection -->
    <div class="option">
      <mat-checkbox class="form-field-spacing-contact" [formControl]="selectcheckboxCollection">Collection</mat-checkbox>
    </div>
    <!-- Department -->
    <div class="option">
      <mat-checkbox class="form-field-spacing-contact" [formControl]="selectcheckboxDepartment">Department</mat-checkbox>
    </div>
    <!-- Designer -->
    <div class="option">
      <mat-checkbox class="form-field-spacing-contact" [formControl]="selectcheckboxDesigner">Designer</mat-checkbox>
    </div>
    <!-- Season -->
    <div class="option">
      <mat-checkbox class="form-field-spacing-contact" [formControl]="selectcheckboxSeason">Season</mat-checkbox>
    </div>
    <!-- Ship Date -->
    <div class="option">
      <mat-checkbox class="form-field-spacing-contact" [formControl]="selectcheckboxShipDate">Ship Date</mat-checkbox>
    </div>
    <!-- Color -->
    <div class="option">
      <mat-checkbox class="form-field-spacing-contact" [formControl]="selectcheckboxColor">Color</mat-checkbox>
    </div>
    <!-- Country of Origin -->
    <div class="option">
      <mat-checkbox class="form-field-spacing-contact" [formControl]="selectcheckboxCountryOfOrigin">Country of Origin</mat-checkbox>
    </div>
    <!-- Editor/Stylist -->
    <div class="option">
      <mat-checkbox class="form-field-spacing-contact" [formControl]="selectcheckboxEditorStylist">Editor/Stylist</mat-checkbox>
    </div>
    <!-- Event -->
    <div class="option">
      <mat-checkbox class="form-field-spacing-contact" [formControl]="selectcheckboxEvent">Event</mat-checkbox>
    </div>
    <!-- Fabric -->
    <div class="option">
      <mat-checkbox class="form-field-spacing-contact" [formControl]="selectcheckboxFabric">Fabric</mat-checkbox>
    </div>
    <!-- Gender -->
    <div class="option">
      <mat-checkbox class="form-field-spacing-contact" [formControl]="selectcheckboxGender">Gender</mat-checkbox>
    </div>
    <!-- Include Staff Loans -->
    <div class="option">
      <mat-checkbox class="form-field-spacing-contact" [formControl]="selectcheckboxIncludeStaffLoans">Include Staff Loans</mat-checkbox>
    </div>
    <!-- Manufacturer -->
    <div class="option">
      <mat-checkbox class="form-field-spacing-contact" [formControl]="selectcheckboxManufacturer">Manufacturer</mat-checkbox>
    </div>
    <!-- Method of Dispatch -->
    <div class="option">
      <mat-checkbox class="form-field-spacing-contact" [formControl]="selectcheckboxMethodOfDispatch">Method of Dispatch</mat-checkbox>
    </div>
    <!-- Misc -->
    <div class="option">
      <mat-checkbox class="form-field-spacing-contact" [formControl]="selectcheckboxMisc">Misc</mat-checkbox>
    </div>
    <!-- Model/Celeb -->
    <div class="option">
      <mat-checkbox class="form-field-spacing-contact" [formControl]="selectcheckboxModelCeleb">Model/Celeb</mat-checkbox>
    </div>
    <!-- Pattern -->
    <div class="option">
      <mat-checkbox class="form-field-spacing-contact" [formControl]="selectcheckboxPattern">Look Book</mat-checkbox>
    </div>
    <!-- Photographer -->
    <div class="option">
      <mat-checkbox class="form-field-spacing-contact" [formControl]="selectcheckboxPhotographer">Photographer</mat-checkbox>
    </div>
    <!-- Publication -->
    <div class="option">
      <mat-checkbox class="form-field-spacing-contact" [formControl]="selectcheckboxPublication">Publication</mat-checkbox>
    </div>
    <!-- Recipient -->
    <div class="option">
      <mat-checkbox class="form-field-spacing-contact" [formControl]="selectcheckboxRecipient">Recipient</mat-checkbox>
    </div>
    <!-- Region -->
    <div class="option">
      <mat-checkbox class="form-field-spacing-contact" [formControl]="selectcheckboxRegion">Region</mat-checkbox>
    </div>
    <!-- Requested By -->
    <div class="option">
      <mat-checkbox class="form-field-spacing-contact" [formControl]="selectcheckboxRequestedBy">Requested By</mat-checkbox>
    </div>
    <!-- Sender -->
    <div class="option">
      <mat-checkbox class="form-field-spacing-contact" [formControl]="selectcheckboxSender">Sender</mat-checkbox>
    </div>
    <!-- Shoot Location -->
    <div class="option">
      <mat-checkbox class="form-field-spacing-contact" [formControl]="selectcheckboxShootLocation">Shoot Location</mat-checkbox>
    </div>
    <!-- Size -->
    <div class="option">
      <mat-checkbox class="form-field-spacing-contact" [formControl]="selectcheckboxSize">Size</mat-checkbox>
    </div>
    <!-- Size Range -->
    <div class="option">
      <mat-checkbox class="form-field-spacing-contact" [formControl]="selectcheckboxSizeRange">Size Range</mat-checkbox>
    </div>
    <!-- Story Theme -->
    <div class="option">
      <mat-checkbox class="form-field-spacing-contact" [formControl]="selectcheckboxStoryTheme">Story Theme</mat-checkbox>
    </div>
    <!-- Style # -->
    <div class="option">
      <mat-checkbox class="form-field-spacing-contact" [formControl]="selectcheckboxStyleNumber">Style #</mat-checkbox>
    </div>
    <!-- Type -->
    <div class="option">
      <mat-checkbox class="form-field-spacing-contact" [formControl]="selectcheckboxType">Type</mat-checkbox>
    </div>

    <div class="option">
      <mat-checkbox class="form-field-spacing-contact" [formControl]="selectsamplestatus">Sample Status</mat-checkbox>
    </div>
  </div>
  <div class="applypanel">
    <p-button label="Apply" [rounded]="true" severity="success" (onClick)="closeOverlayPanel()"></p-button>
  </div>
</p-overlayPanel>
