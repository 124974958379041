import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder} from "@angular/forms";
import {HashService} from "../../services/hash.service";
import {MessageboxComponent} from "../../modals/messagebox/messagebox.component";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {ConfirmationService, ConfirmEventType, MessageService} from "primeng/api";
import {
  emailaccount,
  GetRequestList, PrcContactemail,
  PrcContactemailOutput,
  PrcPullsheetAcceptedInput,
  SelfData,
  Speener
} from "../../models/struct";
import {Table} from "primeng/table";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-emailcontactsdialog',
  templateUrl: './emailcontactsdialog.component.html',
  styleUrl: './emailcontactsdialog.component.scss'
})
export class EmailcontactsdialogComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('dt1') dt1!: Table;
  filtertext: string = ""
  emailcount: number = 0
  filteredValue: any[] = [];
  private subscription?: Subscription;
  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private hashService: HashService,
    public messageBox: MessageboxComponent,
    private serviceservice: ServiceService,
    private yourappsService: YourappsserviceService,
    private confirmationService: ConfirmationService,
    private _snackBar: MatSnackBar,
    private notification: NzNotificationService,
    private messageService: MessageService
  ) {

  }

  ngAfterViewInit(): void {
  }

  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
      ? SelfData.account[0].data[0].id
      : SelfData.account[0].data[0].parentid;
  }

  iContactList: emailaccount[] = [];

  ngOnInit(): void {
    this.serviceservice
      .getEmailContacts({accountid: this.getAccountID()})
      .subscribe((value) => {
        // SelfData.requesttlist = []
        this.iContactList = []
        this.iContactList[0] = value;
        SelfData.emailcontacttlist = this.iContactList;
        this.filteredValue = SelfData.emailcontacttlist[0].data
        this.emailcount = SelfData.emailcontacttlist[0].data.length
        this.samplewait = false
      });
  }

  onInput(event: any) {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;
    this.dt1.filterGlobal(value, 'contains');

  }

  clear(table: Table) {
    table.clear();
    this.filtertext = ""
    this.emailcount = SelfData.emailcontacttlist[0].data.length
  }

  protected readonly SelfData = SelfData;
  samplewait = true

  onSelectedItemCLick(product: any) {
    if (product["Has Email"] === 0) {
      this.confirmationService.confirm({
        message: `<strong>This contact doesn't have an email. Want to add one now?</strong>`,
        header: 'Information',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          var email: string = ""
          this.yourappsService.setEmail("", 0)
          this.subscription = this.yourappsService.editemail$.subscribe((value: { value: string, type: number }) => {
            if (value.type === 1) {
              if (this.subscription) {
                email = value.value
                this.subscription.unsubscribe();
                for (const emailcontact of SelfData.emailcontacttlist) {
                  const foundItem = emailcontact.data.find((item) => item['id'] === product['id']);
                  if (foundItem) {
                    // Присвоить новое значение полю
                    foundItem['email'] = value.value;
                    foundItem["Has Email"]  = 1;
                    break; // Прекратить поиск после нахождения первого совпадения
                  }
                }
                const inputData: PrcContactemail = {
                  id: product['id'],
                  email: email,
                }
                this.serviceservice.prccontactemail(inputData).subscribe({
                  next: (response) => {
                    console.log(response);
                    // Do something with the response
                  },
                  error: (error) => {
                    console.error('Error while sending data:', error);
                  },
                  complete: () => {
                    // This block is optional and will be executed once the observable completes
                    console.log('Completed');
                  }
                });
                console.log("product", product)
                console.log("value", value)
              }
            }
          })

          this.modalService.openEmailAdd()
        },
        reject: (type: any) => {
          if (type == ConfirmEventType.REJECT) {

          } else if (type == ConfirmEventType.CANCEL) {

          }
        }
      })
    } else {
      // console.log(product)
      this.yourappsService.setSelectedEmail(product, 1)

    }
  }

  onFilter(event: any): void {
    this.emailcount = event.filteredValue.length; // Получаем количество отфильтрованных записей
    this.filteredValue = event.filteredValue; // Сохраняем отфильтрованные значения
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onAddAllClick(): void {
    this.confirmationService.confirm({
      message: `<strong>Do you want to add ${this.emailcount} emails?</strong>`,
      header: 'Information',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.filteredValue.forEach((product: any) => {
          if (product["Has Email"] === 1) {
            this.yourappsService.setSelectedEmail(product, 2);
          }
        });
        this.messageService.add({
          key: 'tc',
          severity: 'success',
          summary: 'Information',
          // detail: `Sample ${barcodeValue} already scanned by user ${foundData['user']} for a new delivery or reservation.`,
          detail: `Added ${this.emailcount} emails to the list`,
          life: 3000,
        });
      },
      reject: (type: any) => {
        if (type === ConfirmEventType.REJECT) {
          // Действие при отказе
        } else if (type === ConfirmEventType.CANCEL) {
          // Действие при отмене
        }
      }
    });
  }
}
