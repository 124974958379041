import { ChangeDetectorRef, Component } from '@angular/core';
import { ModalserviceService } from '../../services/modalservice.service';
import { FormBuilder } from '@angular/forms';
import { HashService } from '../../services/hash.service';
import { MessageboxComponent } from '../../modals/messagebox/messagebox.component';
import { ServiceService } from '../../services/service.service';
import { YourappsserviceService } from '../../services/yourappsservice.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-samplelistheader',
  templateUrl: './samplelistheader.component.html',
  styleUrls: ['./samplelistheader.component.scss'],
})
export class SamplelistheaderComponent {
  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private hashService: HashService,
    public messageBox: MessageboxComponent,
    private serviceservice: ServiceService,
    private yourappsService: YourappsserviceService,
    private _snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef
  ) {}

  showGrid() {
    this.modalService.closeSampleLabel();
    this.modalService.createSampleGrid();
  }

  showTable() {
    this.modalService.createSampleLabel();
    this.modalService.closeSampleGrid();
  }
}
