<div class="topheader">
  <button type="button" nz-button nzType="default" (click)="op.toggle($event)">Grid Fields</button>
  <p-button class="info-btn" icon="pi pi-info" [rounded]="true" [outlined]="true" (click)="onInfoButtonClick()"/>
</div>
<div class="headerdiv">
  <div class="components-container">
    <nz-select *ngIf="selectCheckDesigner"
      nzPlaceHolder="Designer"
      nzShowSearch
      nzAllowClear
      [nzShowArrow]="true"
      class="componentdiv"
      [(ngModel)]="selectCheckDesignerString"
      (ngModelChange)="onInputDesigner($event)">
      <nz-option
        *ngFor="let option of iDesignerResult[0]?.data"
        [nzLabel]="option.description"
        [nzValue]="option.description"
      ></nz-option>
    </nz-select>

    <nz-select *ngIf="selectCheckCollection"
      nzPlaceHolder="Collection"
      nzShowSearch
      nzAllowClear
      [nzShowArrow]="true"
      class="componentdiv"
      [(ngModel)]="selectCheckCollectionString"
      (ngModelChange)="onInputDesigner($event)">
      <nz-option
        *ngFor="let option of iCollection[0]?.data"
        [nzLabel]="option.description"
        [nzValue]="option.description"
      ></nz-option>
    </nz-select>

    <nz-select *ngIf="selectCheckSeason" nzPlaceHolder="Season" nzShowSearch nzAllowClear [nzShowArrow]="true" class="componentdiv"
      [(ngModel)]="selectCheckSeasonString" (ngModelChange)="onInputDesigner($event)">
      <nz-option *ngFor="let option of iSeason[0]?.data" [nzLabel]="option.description"
        [nzValue]="option.description"></nz-option>
    </nz-select>

    <nz-select *ngIf="selectCheckType" nzPlaceHolder="Type" nzShowSearch nzAllowClear [nzShowArrow]="true" class="componentdiv"
      [(ngModel)]="selectCheckTypeString" (ngModelChange)="onInputDesigner($event)">
      <nz-option *ngFor="let option of iType[0]?.data" [nzLabel]="option.description"
        [nzValue]="option.description"></nz-option>
    </nz-select>

    <nz-select *ngIf="selectCheckStyle" nzPlaceHolder="Style" nzShowSearch nzAllowClear [nzShowArrow]="true" class="componentdiv"
      [(ngModel)]="selectCheckStyleString" (ngModelChange)="onInputDesigner($event)">
      <nz-option *ngFor="let option of iStyle[0]?.data" [nzLabel]="option.description"
        [nzValue]="option.description"></nz-option>
    </nz-select>

    <nz-select (ngModelChange)="onInputColor($event)" *ngIf="selectCheckColor" nzPlaceHolder="Color" nzShowSearch nzAllowClear [nzShowArrow]="true" class="componentdiv"
      [(ngModel)]="selectCheckColorString">
      <nz-option nzCustomContent *ngFor="let option of colorarray" [nzLabel]="option.colorname"
        [nzValue]="option.colorname">
        <span class="color-box" [style.background]="option.colorcode"></span>
        {{ option.colorname }}
      </nz-option>
    </nz-select>

    <nz-select *ngIf="selectCheckSampleWorkflowStatus" nzPlaceHolder="Sample Workflow Status" nzShowSearch nzAllowClear [nzShowArrow]="true" class="componentdiv"
      [(ngModel)]="selectCheckSampleWorkflowStatusString" (ngModelChange)="onInputDesigner($event)">
      <nz-option *ngFor="let option of iSWSResult[0]?.data" [nzLabel]="option.description"
        [nzValue]="option.description"></nz-option>
    </nz-select>

    <nz-select *ngIf="selectCheckRegion" nzPlaceHolder="Region" nzShowSearch nzAllowClear [nzShowArrow]="true" class="componentdiv"
      [(ngModel)]="selectCheckRegionString" (ngModelChange)="onInputDesigner($event)">
      <nz-option *ngFor="let option of iRegionResult[0]?.data" [nzLabel]="option.description"
        [nzValue]="option.description"></nz-option>
    </nz-select>

    <nz-select *ngIf="selectCheckFabric" nzPlaceHolder="Fabric" nzShowSearch nzAllowClear [nzShowArrow]="true" class="componentdiv"
      [(ngModel)]="selectCheckFabricString" (ngModelChange)="onInputDesigner($event)">
      <nz-option *ngFor="let option of iFabricResult[0]?.data" [nzLabel]="option.description"
        [nzValue]="option.description"></nz-option>
    </nz-select>

    <nz-select *ngIf="selectCheckManufacturer" nzPlaceHolder="Manufacturer" nzShowSearch nzAllowClear [nzShowArrow]="true" class="componentdiv"
      [(ngModel)]="selectCheckManufacturerString" (ngModelChange)="onInputDesigner($event)">
      <nz-option *ngFor="let option of iManuResult[0]?.data" [nzLabel]="option.description"
        [nzValue]="option.description"></nz-option>
    </nz-select>

    <nz-select *ngIf="selectCheckMisc" nzPlaceHolder="Misc" nzShowSearch nzAllowClear [nzShowArrow]="true" class="componentdiv"
      [(ngModel)]="selectCheckMiscString" (ngModelChange)="onInputDesigner($event)">
      <nz-option *ngFor="let option of iMisc[0]?.data" [nzLabel]="option.description"
        [nzValue]="option.description"></nz-option>
    </nz-select>

    <nz-select *ngIf="selectCheckSize" nzPlaceHolder="Size" nzShowSearch nzAllowClear [nzShowArrow]="true" class="componentdiv"
      [(ngModel)]="selectCheckSizeString" (ngModelChange)="onInputDesigner($event)">
      <nz-option *ngFor="let option of iSize[0]?.data" [nzLabel]="option.description"
        [nzValue]="option.description"></nz-option>
    </nz-select>

    <nz-select *ngIf="selectCheckSizeRange" nzPlaceHolder="Size Range" nzShowSearch nzAllowClear [nzShowArrow]="true" class="componentdiv"
      [(ngModel)]="selectCheckSizeRangeString" (ngModelChange)="onInputDesigner($event)">
      <nz-option *ngFor="let option of iSizeRange[0]?.data" [nzLabel]="option.description"
        [nzValue]="option.description"></nz-option>
    </nz-select>

    <nz-select *ngIf="selectCheckPattern" nzPlaceHolder="Pattern" nzShowSearch nzAllowClear [nzShowArrow]="true" class="componentdiv"
      [(ngModel)]="selectCheckPatternstring" (ngModelChange)="onInputDesigner($event)">
      <nz-option *ngFor="let option of iPattern[0]?.data" [nzLabel]="option.description"
        [nzValue]="option.description"></nz-option>
    </nz-select>

    <nz-select (ngModelChange)="onInputGender($event)" *ngIf="selectCheckGender" nzPlaceHolder="Gender" nzShowSearch nzAllowClear [nzShowArrow]="true" class="componentdiv"
      [(ngModel)]="selectCheckgenderString">
      <nz-option *ngFor="let option of gender" [nzLabel]="option.value"
        [nzValue]="option.value"></nz-option>
    </nz-select>

    <input (input)="onInputDesigner(selectCheckNotesString)" class="componentdiv" *ngIf="selectCheckNotes" nz-input placeholder="Notes" [(ngModel)]="selectCheckNotesString" />
    <input (input)="onInputDesigner(selectCheckDescriptionString)" class="componentdiv" *ngIf="selectCheckDescription" nz-input placeholder="Description" [(ngModel)]="selectCheckDescriptionString" />
    <input (input)="onInputDesigner(selectCheckBarcodeString)" class="componentdiv" *ngIf="selectCheckBarcode" nz-input placeholder="Barcode" [(ngModel)]="selectCheckBarcodeString" />

    <button class="componentdiv" type="button" nz-button nzType="default" (click)="onClearAllFilters()">Clear All Filters</button>
  </div>
</div>
<div class="black-background2">
  <!--  [(selection)]="selectedElement"-->
  <!--  (onRowSelect)="onEditButtonClick(selectedElement)"-->
  <div class="card example-container-sm mat-elevation-z3">
    <p-table
      [value]="iSendOutReportResult[0]?.data ?? []"
      #dt1
      [paginator]="true"
      [rows]="10"
      [first]="first"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [rowsPerPageOptions]="[10, 20, 50, 100]"
      [styleClass]="'p-datatable-striped ' + selectedSize.class.toString()"
      selectionMode="single"
      (onPage)="pageChange($event)"
      [globalFilterFields]="['Designer', 'barcode', 'Collection', 'Season', 'Type', 'Color', 'Description']"
      [tableStyle]="{'min-width': '50rem '}">

<!--      <ng-template pTemplate="caption">-->
<!--        <div class="flex">-->
<!--          <p-button class="info-btn" icon="pi pi-info" [rounded]="true" [outlined]="true" (click)="onInfoButtonClick()"/>-->
<!--          <span class="p-input-icon-left ml-auto">-->
<!--            <div class="p-inputgroup">-->
<!--              <span class="p-inputgroup-addon">-->
<!--                <i class="pi pi-search" style="color: #000000"></i>-->
<!--              </span>-->
<!--              <input pInputText type="text" (input)="onInput($event)" placeholder="Filter" [(ngModel)]="filtertext"/>-->
<!--              <span class="p-inputgroup-addon custom-hover-red" matRipple (click)="clear(dt1)">-->
<!--                <i class="pi pi-times" style="color: #000000"></i></span>-->
<!--            </div>-->
<!--          </span>-->
<!--        </div>-->
<!--      </ng-template>-->

      <ng-template pTemplate="header">
        <tr>
          <th style="width:0%"></th>
          <th style="width:0%"></th>
          <th style="width:4%">Options</th>
          <th style="width:10%">Designer</th>
          <th style="width:5%">Image</th>
          <th style="width:10%">Collection</th>
          <th style="width:8%">Season</th>
          <th style="width:8%">Type</th>
          <th style="width:8%">Size</th>
          <th style="width:8%">Color</th>
          <th style="width:30%">Description</th>
          <th style="width:0%">Barcode</th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-product>
        <tr [pSelectableRow]="product">
          <td>
            <div
              class="scdiv"
              [ngClass]="{
                'green': product['samplestatus'] === -1,
                'red': product['samplestatus'] === 0,
                'yellow': product['samplestatus'] === 1
                }">
            </div>
          </td>
          <td>
            <div class="checkbox-container">
              <mat-checkbox
                [(ngModel)]="product['checked']"
                (ngModelChange)="updateCheckedSamples(product)"
              ></mat-checkbox>
            </div>
          </td>
          <td>
            <div class="button-container-table">
              <!--              <p-button *ngIf="SelfData.selectlookmode != 0"-->
              <!--                label="Select" size="small" [outlined]="true" severity="secondary"-->
              <!--                [matMenuTriggerFor]="menu"-->
              <!--                (click)="selectedElement = product">-->
              <!--              </p-button>-->
              <button nzTrigger="click" *ngIf="SelfData.selectlookmode != 0" nz-button nz-dropdown
                [nzDropdownMenu]="menu4" (click)="selectedElement = product">
                select
                <span nz-icon nzType="down"></span>
              </button>
            </div>
          </td>
          <td (click)="onEditButtonClick(product)">{{ product?.["Designer"]}}</td>
          <td><img [src]="transformImageUrl(product['Image Link'])" alt="Image" class="image-size-80x80 shadow-4"
            (click)="onImageClick(product)"></td>
          <td (click)="onEditButtonClick(product)">{{ product?.["Collection"] }}</td>
          <td (click)="onEditButtonClick(product)">{{ product?.["Season"] }}</td>
          <td (click)="onEditButtonClick(product)">{{ product["Type"] }}</td>
          <td (click)="onEditButtonClick(product)">{{ product["Size"] }}</td>
          <td (click)="onEditButtonClick(product)">{{ product["Color"] }}</td>
          <td (click)="onEditButtonClick(product)">{{ product["Description"] }}</td>
          <td (click)="onEditButtonClick(product)">
            <app-barcode [element]="product?.barcode" [background]="'rgba(255,255,255,0)'"
              class="barcode-component"></app-barcode>
          </td>
        </tr>
      </ng-template>

      <p-paginator #paginator></p-paginator>
    </p-table>
  </div>
</div>

<nz-dropdown-menu #menu4="nzDropdownMenu">
  <ul nz-menu>
    <li nz-menu-item (click)="onEditButtonClick(selectedElement)">View & Edit details</li>
    <li nz-menu-item (click)="setqrcodedata(selectedElement?.['barcode'])">Print BarCode Labels</li>
    <li nz-menu-item (click)="onDuplicateButtonClick(selectedElement)">Create Duplicate</li>
    <li nz-menu-item (click)="onButtonDeleteClick(selectedElement)">Delete</li>
  </ul>
</nz-dropdown-menu>

<p-confirmDialog #cd [style]="{width: '25vw'}" [closable]="true">
  <ng-template pTemplate="footer">
    <div class="button-container-request">
      <button mat-raised-button class="button-secondary" style="margin-right: 10px; width: 50%" (click)="cd.reject()">
        No
      </button>
      <button mat-raised-button class="button-primary" style="margin-right: 0; width: 50%" (click)="cd.accept()">
        Yes
      </button>
    </div>
  </ng-template>
</p-confirmDialog>

<div
  #qrCode
  class="qrcodeImage"
  [style.display]="showQRCode ? 'block' : 'none'"
>
  <!--  <qrcode-->
  <!--    [qrdata]="qrcodedata"-->
  <!--    [allowEmptyString]="true"-->
  <!--    [cssClass]="'center'"-->
  <!--    [colorDark]="'#000000'"-->
  <!--    [colorLight]="'#ffffffff'"-->
  <!--    [elementType]="'img'"-->
  <!--    [errorCorrectionLevel]="'H'"-->
  <!--  >-->
  <!--  </qrcode>-->
</div>
<p-overlayPanel #op>
  <div class="mainop">
<!--    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckInventoryStatus">Inventory Status</label></div>-->
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckGender">Gender</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckSampleWorkflowStatus">Sample Workflow Status</label>
    </div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckRegion">Region</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckNotes">Notes</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckDesigner">Designer</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckCollection">Collection</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckSeason">Season</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckType">Type</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckFabric">Fabric</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckManufacturer">Manufacturer</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckStyle">Style</label></div>
<!--    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckLooksAssociated">Looks Associated</label></div>-->
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckMisc">Misc</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckSize">Size</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckSizeRange">Size Range</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckPattern">Pattern</label></div>
<!--    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckQuantity">Quantity</label></div>-->
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckColor">Color</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckBarcode">Barcode</label></div>
<!--    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckDepartment">Department</label></div>-->
<!--    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckLookBook">Look Book</label></div>-->
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckDescription">Description</label></div>
  </div>
  <div class="applypanel">
    <p-button label="Apply" [rounded]="true" severity="success" (onClick)="closeOverlayPanel()"></p-button>
  </div>
</p-overlayPanel>
