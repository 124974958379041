import {AfterViewInit, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {PrcAddress, SelfData, TagsListData} from "../../models/struct";
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder} from "@angular/forms";
import {HashService} from "../../services/hash.service";
import {MessageboxComponent} from "../../modals/messagebox/messagebox.component";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {MatSnackBar} from "@angular/material/snack-bar";
import {HttpClient} from "@angular/common/http";
import {forkJoin, Subscription} from "rxjs";

@Component({
  selector: 'app-userdialog',
  templateUrl: './userdialog.component.html',
  styleUrl: './userdialog.component.scss'
})
export class UserdialogComponent implements OnInit, AfterViewInit {
  firstname: string = "";
  lastname: string = "";
  email: string = "";
  phone: string = "";
  suppliername: string = "";
  defaultmarkup: string = "";
  descriptionstring: string = "";
  companyname: string = "";
  mobile: string = "";
  website: string = "";
  instagram: string = "";
  facebook: string = "";
  tiktok: string = "";
  twitter: string = "";
  youtube: string = "";
  countries: any[] = [];
  states: any[] = [];
  pcountries: any[] = [];
  pstates: any[] = [];
  countrystring: string = "";
  pcountrystring: string = "";
  usedifferent: boolean = true;
  pzipcode: string = "";
  pstatesstring: string = "";
  pcity: string = "";
  psuburb: string = "";
  paddress2: string = "";
  paddress: string = "";
  address: string = "";
  address2: string = "";
  suburb: string = "";
  city: string = "";
  zipcode: string = "";
  statesstring: string = "";
  caption: string = "Add User"
  setid: number = 0;
  opermode: number = 0;
  profilesResult: TagsListData[] = []
  selectProfile: string = "";
  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
      ? SelfData.account[0].data[0].id
      : SelfData.account[0].data[0].parentid;
  }
  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private hashService: HashService,
    public messageBox: MessageboxComponent,
    private serviceservice: ServiceService,
    private yourappsService: YourappsserviceService,
    private notification: NzNotificationService,
    private _snackBar: MatSnackBar,
    private http: HttpClient,
    private cdr: ChangeDetectorRef
  ) {
    forkJoin({
      profiles: this.serviceservice.selectProfiles(this.getAccountID()),
    }).subscribe(({profiles}) => {
      this.profilesResult = profiles.data;
      console.log("this.profilesResult", this.profilesResult)
    });
  }
  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    this.http.get<any>('assets/countries.json').subscribe((data) => {
      this.countries = data.countries;
      if (this.opermode === 0) {
        this.countrystring = "United States"
      }
      this.pcountries = data.countries;
      if (this.opermode === 0) {
        this.pcountrystring = "United States"
      }
      // this.countriControl.setValue('United States');
      // this.countri1Control.setValue('United States');
      // this.countri2Control.setValue('United States');
      this.http.get<any>('assets/us-states.json').subscribe((data) => {
        this.states = data;
        this.onOptionSelected(this.countrystring)
      })
    })
  }

  onOptionSelected(event: string): void {
    const countryId = this.getCountryIdByName(event);
    this.states = [];
    this.statesstring = "";
    if (countryId == 187) {
      this.http.get<any>('assets/us-states.json').subscribe((data) => {
        this.states = data;
      });
    }
  }

  getCountryIdByName(countryName: string): number | null {
    const country = this.countries.find((c) => c.country === countryName);
    return country ? country.id : null;
  }

  private subscription?: Subscription

  onProfileClick() {
    this.yourappsService.setSelectedProfileList([], 0)
    this.yourappsService.getReloadProfileList([], 0)
    this.subscription = this.yourappsService.reloadProfileList$.subscribe((value) => {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      if (value.type === 1) {
        forkJoin({
          profiles: this.serviceservice.selectProfiles(this.getAccountID()),
        }).subscribe(({profiles}) => {
          this.profilesResult = profiles.data;
          setTimeout(() => {
            SelfData.spinnerpossition = false
          });
          console.log("this.profilesResult", this.profilesResult)
        });
      }
    })
    this.modalService.profilelistOpen()
  }

  onAddUserClick() {
    this.serviceservice
      .saleaccount({
        Ctrl: 0,
        FirstName: this.firstname?.trim(),
        LastName: this.lastname.trim(),
        SetPasswprd: '',
        SetEmail: this.email.trim(),
        JobTitle: "",
        Industry: "",
        Country: this.countrystring.trim(),
        Company: "",
        Website: "",
        Noe: "",
        Phone: this.phone?.trim(),
        AboutUS: "",
        RPMonitoring: false as boolean,
        InternalTracking: false as boolean,
        ChinaKOLAnalytics: false as boolean,
        Events: false as boolean,
        Samples: false as boolean,
        DigitalShowroom: false as boolean,
        BrandBenchmark: false as boolean,
        ProductionServices: false as boolean,
        Contacts: false as boolean,
        IWant: false as boolean,
        Type: 1,
        Activity: true,
        parentid: 0,
        addressid: 0,
        referalcode: "",
        profileid: 1,
        jewelry: false as boolean,
      })
      .subscribe((RegisterResult) => {
        this.modalService.userClose()
      })
  }
}
