import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder} from "@angular/forms";
import {HashService} from "../../services/hash.service";
import {MessageboxComponent} from "../../modals/messagebox/messagebox.component";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
  selector: 'app-pullsheetprinter',
  templateUrl: './pullsheetprinter.component.html',
  styleUrls: ['./pullsheetprinter.component.scss']
})
export class PullsheetprinterComponent implements OnInit, AfterViewInit {
  deviceClass: string = "";
  deviceClassAddress: string = "";
  imageUrl: string = '';
  printerpage: boolean = false
  isLandscape: boolean = false;
  textvalue: string = ""
  signatureImg: string = "";
  constructor(
      public modalService: ModalserviceService,
      public fb: FormBuilder,
      private hashService: HashService,
      public messageBox: MessageboxComponent,
      private serviceservice: ServiceService,
      private yourappsService: YourappsserviceService,
      private cdr: ChangeDetectorRef,
      private deviceService: DeviceDetectorService,
      private el: ElementRef, private renderer: Renderer2

  ) {
    this.setDeviceClass();
  }
  setDeviceClass(): void {
    this.deviceClass = this.deviceService.isMobile() ? 'mobilemaincard' : 'pcmaincard';
    this.deviceClassAddress = this.deviceService.isMobile() ? 'mobileaddressdiv' : 'pcaddressdiv';
    // this.isLandscape = window.innerWidth > window.innerHeight ? 'islandscape' : 'isnlandscape';
  }
  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
  }

  onButtonPrintClick() {
    this.modalService.closePrinterFrame()
    this.printerpage = true;
    this.cdr.detectChanges();

    const el = document.getElementById('pcmaincard');
    if (!el) {
      console.error('Element not found!');
      return;
    }

    const clonedEl = el.cloneNode(true) as HTMLElement;

    const iframe = document.createElement('iframe');
    document.body.appendChild(iframe);

    const printerStyleLink = document.createElement('link');
    printerStyleLink.rel = 'stylesheet';
    printerStyleLink.href = '/assets/pullsheetprinter.component.css';

    iframe.contentWindow!.document.head.appendChild(printerStyleLink);

    iframe.contentWindow!.document.body.appendChild(clonedEl);

    let lastTime = Date.now();

    const timer = setInterval(() => {
      const currentTime = Date.now();
      const elapsedTime = currentTime - lastTime;

      // If elapsed time is greater than a certain amount (like 1000ms for 1 second),
      // assume that print dialog was open during this period.
      if (elapsedTime > 1000) {
        console.log('Print dialog was likely closed');
        this.printerpage = false;
        this.cdr.detectChanges();
        clearInterval(timer);
        // Do what you need to do after the dialog has been closed
      }

      lastTime = currentTime;
    }, 100);

    setTimeout(() => {
      iframe.contentWindow!.focus();
      iframe.contentWindow!.print();
      document.body.removeChild(iframe);
    }, 1000);
  }

}
