import { Component } from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";

@Component({
  selector: 'app-catalogslist',
  templateUrl: './catalogslist.component.html',
  styleUrls: ['./catalogslist.component.scss']
})
export class CatalogslistComponent {
  constructor(public modalService: ModalserviceService) {}
}
