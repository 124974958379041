<button mat-icon-button type="button" class="close-btn" (click)="modalService.inventaryclose()">
  <mat-icon>close</mat-icon>
</button>
<span class="sample-manager-text-i">View Inventory
  <div *ngIf="samplewait">
  <p-progressSpinner class="spinner1" styleClass="w-2rem h-2rem" strokeWidth="5"
    fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
  </div>
</span>

<div class="top-div-i">
<!--  <p-progressSpinner *ngIf="Speener.samplevisiblemode" class="spinner" styleClass="w-3rem h-3rem" strokeWidth="5"-->
<!--    fill="var(&#45;&#45;surface-ground)" animationDuration=".5s"></p-progressSpinner>-->
  <div class="centered-container-i">
    <app-invenstorylisttable *ngIf="modalService.isInventoryLabel$ | async"></app-invenstorylisttable>
  </div>
</div>
