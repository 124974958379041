import {AfterViewInit, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {ModalserviceService} from "../../services/modalservice.service";
import {HashService} from "../../services/hash.service";
import {MessageboxComponent} from "../../modals/messagebox/messagebox.component";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatRadioChange} from "@angular/material/radio";
import {SelfData} from "../../models/struct";

@Component({
  selector: 'app-deleteframedialog',
  templateUrl: './deleteframedialog.component.html',
  styleUrls: ['./deleteframedialog.component.scss']
})
export class DeleteframedialogComponent implements OnInit, AfterViewInit {
  myForm: FormGroup = new FormGroup({});
  isTextareaDisabled = true;
  buttonenable?: boolean = true;
  selectedreason: string = ""

  constructor(
      public modalService: ModalserviceService,
      public fb: FormBuilder,
      private hashService: HashService,
      public messageBox: MessageboxComponent,
      private serviceservice: ServiceService,
      private yourappsService: YourappsserviceService,
      private _snackBar: MatSnackBar,
      private cd: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
  }

  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
        ? SelfData.account[0].data[0].id
        : SelfData.account[0].data[0].parentid;
  }

  submit() {
  this.modalService.closeDeleteFrame()
    const fields = {
      sampleid: SelfData.selDeleteSimpleID,
      deletetype: 1,
      userid: SelfData.account[0].data[0].id,
      accountid: this.getAccountID(),
      reason: this.selectedreason,
    }
    this.serviceservice
        .prcDeleteSamples(fields)
        .subscribe((value) => {
          this.yourappsService.removedSample(SelfData.selectedbarcode)
        });
  }

  onRadioChange(event: MatRadioChange): void {
    const selectedValue = event.value;
    console.log('Selected value:', selectedValue);
    const reasons = [
      "Sample is not available",           // index 0
      "Sample is in another delivery",     // index 1
      "Sample is on another request",      // index 2
      "I made a mistake",                  // index 3
      "Sample is lost",                    // index 4
      "Sample is damaged",                 // index 5
      "Sample will be substituted",        // index 6
      "Other"                              // index 7
    ];
    this.selectedreason = reasons[selectedValue - 1];

    console.log(this.selectedreason)

    this.isTextareaDisabled = selectedValue !== '8';
    this.buttonenable = false
    this.cd.detectChanges();
    // You can perform further actions based on the selected value
  }

}
