import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { ModalserviceService } from '../../services/modalservice.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { HashService } from '../../services/hash.service';
import { MessageboxComponent } from '../../modals/messagebox/messagebox.component';
import { ServiceService } from '../../services/service.service';
import { YourappsserviceService } from '../../services/yourappsservice.service';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { NgxImageCompressService } from 'ngx-image-compress';
import { HttpClient } from '@angular/common/http';
import { GetNotSettings, SelfData } from '../../models/struct';

@Component({
  selector: 'app-notificationsettingsdialog',
  templateUrl: './notificationsettingsdialog.component.html',
  styleUrls: ['./notificationsettingsdialog.component.scss'],
})
export class NotificationsettingsdialogComponent
  implements OnInit, AfterViewInit
{
  myForm: FormGroup;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  rsMonday = new FormControl(false);
  rsTuesday = new FormControl(false);
  rsWednesday = new FormControl(false);
  rsThursday = new FormControl(false);
  rsFriday = new FormControl(false);
  rsSaturday = new FormControl(false);
  rsSunday = new FormControl(false);
  osMonday = new FormControl(false);
  osTuesday = new FormControl(false);
  osWednesday = new FormControl(false);
  osThursday = new FormControl(false);
  osFriday = new FormControl(false);
  osSaturday = new FormControl(false);
  osSunday = new FormControl(false);
  email = new FormControl(true);
  push = new FormControl(true);
  check1 = new FormControl(false);
  check2 = new FormControl(false);
  check3 = new FormControl(false);
  check4 = new FormControl(false);
  check5 = new FormControl(false);
  iNotSettingsResult: GetNotSettings[] = [];
  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private hashService: HashService,
    public messageBox: MessageboxComponent,
    private serviceservice: ServiceService,
    private yourappsService: YourappsserviceService,
    private _snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef,
    private imageCompressService: NgxImageCompressService,
    private http: HttpClient
  ) {
    this.myForm = this.fb.group({});
    if (SelfData && SelfData.notificationsettings && SelfData.notificationsettings.length > 0 && SelfData.notificationsettings[0].data && SelfData.notificationsettings[0].data.length > 0) {
      this.rsMonday.setValue(SelfData.notificationsettings[0].data[0].rsMonday);
      this.rsTuesday.setValue(SelfData.notificationsettings[0].data[0].rsTuesday);
      this.rsWednesday.setValue(SelfData.notificationsettings[0].data[0].rsWednesday);
      this.rsThursday.setValue(
        SelfData.notificationsettings[0].data[0].rsThursday
      );
      this.rsFriday.setValue(SelfData.notificationsettings[0].data[0].rsFriday);
      this.rsSaturday.setValue(
        SelfData.notificationsettings[0].data[0].rsSaturday
      );
      this.rsSunday.setValue(SelfData.notificationsettings[0].data[0].rsSunday);
      this.osMonday.setValue(SelfData.notificationsettings[0].data[0].osMonday);
      this.osTuesday.setValue(SelfData.notificationsettings[0].data[0].osTuesday);
      this.osWednesday.setValue(
        SelfData.notificationsettings[0].data[0].osWednesday
      );
      this.osThursday.setValue(
        SelfData.notificationsettings[0].data[0].osThursday
      );
      this.osFriday.setValue(SelfData.notificationsettings[0].data[0].osFriday);
      this.osSaturday.setValue(
        SelfData.notificationsettings[0].data[0].osSaturday
      );
      this.osSunday.setValue(SelfData.notificationsettings[0].data[0].osSunday);
      this.email.setValue(SelfData.notificationsettings[0].data[0].email);
      this.push.setValue(SelfData.notificationsettings[0].data[0].push);
      this.check1.setValue(SelfData.notificationsettings[0].data[0].check1);
      this.check2.setValue(SelfData.notificationsettings[0].data[0].check2);
      this.check3.setValue(SelfData.notificationsettings[0].data[0].check3);
      this.check4.setValue(SelfData.notificationsettings[0].data[0].check4);
      this.check5.setValue(SelfData.notificationsettings[0].data[0].check5);
    } else {
      // Обработка ситуации, когда данные отсутствуют или не соответствуют ожидаемой структуре
    }
    // this.rsMonday.setValue(SelfData.notificationsettings[0].data[0].rsMonday);
    // this.rsTuesday.setValue(SelfData.notificationsettings[0].data[0].rsTuesday);
    // this.rsWednesday.setValue(
    //   SelfData.notificationsettings[0].data[0].rsWednesday
    // );

  }
  ngAfterViewInit(): void {}

  ngOnInit(): void {}

  determineAccountId(): number {
    if (SelfData.account[0].data[0].parentid == 0) {
      return SelfData.account[0].data[0].id;
    } else {
      return SelfData.account[0].data[0].parentid;
    }
  }
  submit(type: number = 0) {
    var accountid = this.determineAccountId();
    var homeinfoid: number = 0;
    var otherinfoid: number = 0;
    const settings = {
      rsMonday: this.rsMonday.value || false,
      rsTuesday: this.rsTuesday.value || false,
      rsWednesday: this.rsWednesday.value || false,
      rsThursday: this.rsThursday.value || false,
      rsFriday: this.rsFriday.value || false,
      rsSaturday: this.rsSaturday.value || false,
      rsSunday: this.rsSunday.value || false,
      osMonday: this.osMonday.value || false,
      osTuesday: this.osTuesday.value || false,
      osWednesday: this.osWednesday.value || false,
      osThursday: this.osThursday.value || false,
      osFriday: this.osFriday.value || false,
      osSaturday: this.osSaturday.value || false,
      osSunday: this.osSunday.value || false,
      email: this.email.value || true,
      push: this.push.value || true,
      check1: this.check1.value || false,
      check2: this.check2.value || false,
      check3: this.check3.value || false,
      check4: this.check4.value || false,
      check5: this.check5.value || false,
      accountid: accountid,
    };
    this.modalService.createnotsettingsclose();
    this.serviceservice
      .prcNotificationSettings(settings)
      .subscribe((response) => {
        this.serviceservice
          .setNotSettings({ accountid: accountid })
          .subscribe((value) => {
            this.iNotSettingsResult[0] = value;
            SelfData.notificationsettings = this.iNotSettingsResult;
          });
      });
  }

  sendLaTestMessage() {
    this.openSnackBar(this.anonymizeEmail(SelfData.account[0].data[0].email));
  }

  openSnackBar(email: string) {
    // Get the email address from the account data.

    // Open the snack bar.
    this._snackBar.open(`Test email has been sent to: ${email}`, 'Done', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 10000,
    });
  }

  anonymizeEmail(email: string): string {
    let [localPart, domain] = email.split('@');

    if (localPart.length > 2) {
      localPart =
        localPart[0] +
        '*'.repeat(localPart.length - 2) +
        localPart[localPart.length - 1];
    }

    return `${localPart}@${domain}`;
  }
}
