<div class="bg-black/40 fixed top-0 right-0 left-0 bottom-0" (click)="modalService.closeCatalogsList()">
</div>

<div class="modal-container">
  <ng-content></ng-content>
</div>

<p-confirmDialog #cd [style]="{width: '25vw'}" [closable]="true">
  <ng-template pTemplate="footer">
    <div class="button-container-rr">
      <button mat-raised-button class="button-secondary" style="margin-right: 10px; width: 10%"
        (click)="cd.reject()">
        No
      </button>
      <button mat-raised-button class="button-primary" style="width: 10%" (click)="cd.accept()">
        Yes
      </button>
    </div>
  </ng-template>
</p-confirmDialog>
