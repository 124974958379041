import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatCount'
})
export class FormatCountPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (value >= 1000000) {
      return (value / 1000000).toFixed(1) + 'M';
    } else if (value >= 1000) {
      return (value / 1000).toFixed(1) + 'k';
    }
    return value;
  }
}
