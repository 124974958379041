import {
  AfterViewInit,
  ChangeDetectorRef,
  Component, Inject,
  NgZone,
  OnInit, Renderer2,
  ViewChild,
} from '@angular/core';
import { ModalserviceService } from '../../services/modalservice.service';
import { FormBuilder } from '@angular/forms';
import { HashService } from '../../services/hash.service';
import { MessageboxComponent } from '../../modals/messagebox/messagebox.component';
import { ServiceService } from '../../services/service.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { YourappsserviceService } from '../../services/yourappsservice.service';
import {
  GetDesignerList,
  GetFabricList,
  GetSeasonList,
  GetTypeList,
  SelfData,
  SetSampleListData, transformImageUrl
} from '../../models/struct';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import {Table} from "primeng/table";
import {DOCUMENT} from "@angular/common";

interface ColorArray {
  colorname: string;
  colorcode: string;
}

@Component({
  selector: 'app-invenstorylisttable',
  templateUrl: './invenstorylisttable.component.html',
  styleUrls: ['./invenstorylisttable.component.scss'],
})
export class InvenstorylisttableComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator | null = null;
  @ViewChild('dt1') dt1!: Table;
  filterDesignertext: string = ""
  filterColortext: string = ""
  filterTypetext: string = ""
  filterFabrictext: string = ""
  filtertext: string = ""
  first: number = 0;
  sizes!: any[];
  iDesignerResult: GetDesignerList[] = [];
  iType: GetTypeList[] = [];
  iFabricResult: GetFabricList[] = [];
  iSeason: GetSeasonList[] = [];

  colorarray: ColorArray[] = [
    {colorname: 'White', colorcode: '#ffffff'},
    {colorname: 'Black ', colorcode: '#000000'},
    {colorname: 'Yellow', colorcode: '#FFFF00'},
    {colorname: 'Purple', colorcode: '#800080'},
    {colorname: 'Orange ', colorcode: '#FFA500'},
    {colorname: 'Blue ', colorcode: '#2196f3'},
    {colorname: 'Light Blue ', colorcode: '#03a9f4'},
    {colorname: 'Red', colorcode: '#ff0000'},
    {colorname: 'Buff', colorcode: '#DAA06D'},
    // {colorname: 'Gray ', colorcode: '#808080'},
    {colorname: 'Grey ', colorcode: '#808080'},
    {colorname: 'Green ', colorcode: '#00FF00'},
    {colorname: 'Pink', colorcode: '#FFC0CB'},
    {colorname: 'Violet', colorcode: '#8F00FF'},
    {colorname: 'Brown', colorcode: '#964B00'},
    {colorname: 'Silver', colorcode: '#C0C0C0'},
    {colorname: 'Cream', colorcode: '#FFFDD0'},
    {colorname: 'Gold', colorcode: '#FFD700'},
    {colorname: 'Beige', colorcode: '#F5F5DC'},
    {colorname: 'Navy', colorcode: '#202A44'},
    {colorname: 'Mint', colorcode: '#A8E8D7'},
  ];

  selectedSize: any = '';

  showQRCode: boolean = false;
  selectedElement: any;
  ELEMENT_DATA: SetSampleListData[] = [];
  originalData: SetSampleListData[] = []

  options: string[] = [];
  displayedColumns: string[] = [
    'actions',
    'Designer',
    'BarCode',
    'Collection',
    'Season',
    'Type',
    'Color',
    'Description',
    'Image',
  ];
  dataSource = new MatTableDataSource<SetSampleListData>(this.ELEMENT_DATA);
  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private hashService: HashService,
    public messageBox: MessageboxComponent,
    private serviceservice: ServiceService,
    private _snackBar: MatSnackBar,
    private yourappsService: YourappsserviceService,
    private ngZone: NgZone,
    private renderer: Renderer2, @Inject(DOCUMENT) private document: Document,
    private cdRef: ChangeDetectorRef
  ) {
    var accountid: number = 0;
    if (SelfData.account[0].data[0].parentid == 0) {
      accountid = SelfData.account[0].data[0].id;
    } else {
      accountid = SelfData.account[0].data[0].parentid;
    }

    this.serviceservice
      .setDesignerList({accountid: accountid})
      .subscribe((desingervalue) => {
        this.iDesignerResult[0] = desingervalue;
        SelfData.designer = this.iDesignerResult;
        console.log("SelfData.designer", SelfData.designer)
        // this.filteredDesignerOptions = this.setDataAndFilter(
        //   this.desingerFormControl,
        //   desingervalue,
        //   this.designerptions,
        //   2
        // );
      });

    this.serviceservice
      .setTypeList({accountid: accountid})
      .subscribe((value) => {
        this.iType.push(value);
        SelfData.type = this.iType;
        // this.typeoptions = this.iType[0].data.map((item) => item.description);
        // this.filteredTypeOptions = this.typeFormControl.valueChanges.pipe(
        //   startWith(''),
        //   map((value) => this._filter(value || '', 10))
        // );
      });

    this.serviceservice
      .setFabricList({accountid: accountid})
      .subscribe((fabricvalue) => {
        this.iFabricResult[0] = fabricvalue;
        SelfData.fabric = this.iFabricResult;
        // this.filteredFabricOptions = this.setDataAndFilter(
        //   this.fabricControl,
        //   fabricvalue,
        //   this.fabricoptions,
        //   3
        // );
      });

    this.serviceservice
      .setSeasonList({accountid: accountid})
      .subscribe((seasonvalue) => {
        this.iSeason[0] = seasonvalue;
        SelfData.season = this.iSeason;
        // this.filteredSeasonOptions = this.setDataAndFilter(
        //   this.seasonFormControl,
        //   seasonvalue,
        //   this.seasonoptions,
        //   6
        // );
      });

    this.sizes = [
      { name: 'Small', class: 'p-datatable-sm' }
    ];
    this.selectedSize = this.sizes[0]
    this.ELEMENT_DATA = SelfData.sampledata[0].data;
    this.dataSource = new MatTableDataSource<SetSampleListData>(
      this.ELEMENT_DATA
    );
  }

  public get setdataSource() {
    return this.dataSource;
  }
  ngAfterViewInit(): void {
    this.ELEMENT_DATA = [...SelfData.sampledata[0].data];
    this.dataSource = new MatTableDataSource<SetSampleListData>(
      this.ELEMENT_DATA
    );
    this.dataSource.paginator = this.paginator;
  }

  onSelectedItemCLick(barcode: string, items: any) {
    items["itemstatus"] = 1;
    this.yourappsService.setSelectedSample(barcode);
  }

  onInput(event: Event) {
    console.log("filterDesignertext", this.filterDesignertext)
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;
    this.dataSource.data = SelfData.sampledata[0].data;
    this.filterData()
  }

  onInputDesigner(value: string) {
    // this.dt1.filterGlobal(value, 'contains');
    this.filterDesignertext = value
    this.dataSource.data = SelfData.sampledata[0].data;
    this.filterData()
  }

  onInputColor(selectedOption: any): void {
    if (selectedOption) {
      console.log('Выбранный цвет:', selectedOption.colorname); // Метка (имя цвета)
      console.log('Код цвета:', selectedOption.colorcode); // Значение (код цвета)
      this.filterColortext = selectedOption.colorname;
    } else {
      console.log('Цвет не выбран или поле было очищено');
      this.filterColortext = ''; // Или другой обработчик для очистки
    }

    console.log("filterColortext", this.filterColortext);
    this.dataSource.data = SelfData.sampledata[0].data;
    this.filterData();
  }

  onInputType(value: string) {
    // this.dt1.filterGlobal(value, 'contains');
    this.filterTypetext = value
    this.dataSource.data = SelfData.sampledata[0].data;
    this.filterData()
  }

  onInputFabric(value: string) {
    // this.dt1.filterGlobal(value, 'contains');
    this.filterFabrictext = value
    this.dataSource.data = SelfData.sampledata[0].data;
    this.filterData()
  }

  onInputSeason(value: string) {
    // this.dt1.filterGlobal(value, 'contains');
    this.filtertext = value
    this.dataSource.data = SelfData.sampledata[0].data;
    this.filterData()
  }

  clearDesigner() {
    this.filterDesignertext = ""
    this.filterData()
  }
  clearColor() {
    this.filterColortext = ""
    this.filterData()
  }
  clearType() {
    this.filterTypetext = ""
    this.filterData()
  }
  clearFabric() {
    this.filterFabrictext = ""
    this.filterData()
  }
  clearSeason() {
    this.filtertext = ""
    this.filterData()
  }

  filterData(): void {
    console.log("originalData перед фильтрацией:", SelfData.originalData);

    // Копируем данные для фильтрации
    const dataToFilter = [...SelfData.originalData];
    SelfData.sampledata[0].data = dataToFilter;

    const filterDesignerTextLower = this.filterDesignertext?.toLowerCase().trim() ?? "";
    const filterColorTextLower = this.filterColortext?.toLowerCase().trim() ?? "";
    const filterTypeTextLower = this.filterTypetext?.toLowerCase().trim() ?? "";
    const filterFabricTextLower = this.filterFabrictext?.toLowerCase().trim() ?? "";
    const filterTextLower = this.filtertext?.toLowerCase().trim() ?? "";

    // console.log("Значения фильтров:", {
    //   filterDesignerTextLower,
    //   filterColorTextLower,
    //   filterTypeTextLower,
    //   filterFabricTextLower,
    //   filterTextLower
    // });

    // Фильтруем данные с логами
    const filteredData = dataToFilter.filter(item => {
      const matchesDesigner = this.filterDesignertext ? item['Designer']?.toString().toLowerCase().includes(filterDesignerTextLower) : true;
      const matchesColor = this.filterColortext ? item['Color']?.toString().toLowerCase().includes(filterColorTextLower) : true;
      const matchesType = this.filterTypetext ? item['Type']?.toString().toLowerCase().includes(filterTypeTextLower) : true;
      const matchesFabric = this.filterFabrictext ? item['Fabric']?.toString().toLowerCase().includes(filterFabricTextLower) : true;
      const matchesFilter = this.filtertext ? item['Season']?.toString().toLowerCase().includes(filterTextLower) : true;

      // Логируем данные для текущего элемента
      // console.log("Проверка элемента:", {
      //   itemDesigner: item['Designer']?.toString().toLowerCase(),
      //   itemColor: item['Color']?.toString().toLowerCase(),
      //   itemType: item['Type']?.toString().toLowerCase(),
      //   itemFabric: item['Fabric']?.toString().toLowerCase(),
      //   itemSeason: item['Season']?.toString().toLowerCase(),
      //   matchesDesigner,
      //   matchesColor,
      //   matchesType,
      //   matchesFabric,
      //   matchesFilter
      // });

      return matchesDesigner && matchesColor && matchesType && matchesFabric && matchesFilter;
    });

    console.log("filteredData после фильтрации:", filteredData);

    // Обновляем SelfData после фильтрации
    SelfData.sampledata[0].data = filteredData;
    this.first = 0;
  }


  onImageClick(item: any) {
    // console.log("item", item)
    this.showFullWindowImage(transformImageUrl(item["FullImage Link"]))
    // this.showFullWindowImage(item["FullImage Link"])

  }

  showFullWindowImage(imageUrl: string): void {
    // Create the elements for the modal, image, and close button
    const modal = this.renderer.createElement('div');
    this.renderer.addClass(modal, 'preview-modal');

    const img = this.renderer.createElement('img');
    this.renderer.addClass(img, 'preview-image');
    this.renderer.setAttribute(img, 'src', imageUrl);
    this.renderer.appendChild(modal, img);

    const closeButton = this.renderer.createElement('button');
    this.renderer.addClass(closeButton, 'close-preview');
    closeButton.innerHTML = '&times;';  // or use an SVG/icon for the close button
    this.renderer.appendChild(modal, closeButton);

    // Append the modal to the body
    this.renderer.appendChild(this.document.body, modal);

    // Add a click listener to the close button to remove the modal
    this.renderer.listen(closeButton, 'click', (event) => {
      event.stopPropagation();  // Prevent the event from closing the modal immediately
      this.renderer.removeChild(this.document.body, modal);
    });

    // Optionally, add a click listener to the modal to close it if the user clicks outside the image
    // this.renderer.listen(modal, 'click', () => {
    //   this.renderer.removeChild(this.document.body, modal);
    // });

    // this.renderer.listen(img, 'click', () => {
    //   this.zoomScale = this.zoomScale === 1 ? 2 : 1; // Toggle between 1x and 2x zoom
    //   this.renderer.setStyle(img, 'transform', `scale(${this.zoomScale})`);
    //   this.renderer.setStyle(img, 'cursor', this.zoomScale === 1 ? 'zoom-in' : 'zoom-out');
    // });
  }

  ngOnInit(): void {
    // this.originalData = SelfData.sampledata[0].data;
    // console.log("this.originalData", this.originalData)
    // this.originalData.push(SelfData.sampledata[0].data)
  }

  onfilter() {
    this.first = 0;
    console.log(this.first)
  }

  protected readonly SelfData = SelfData;
  designerstring: string | null = null;
  colorstring: String = "";
  typestring: String = "";
  fabricstring: String = "";
  seasonstring: String = "";

  pageChange(event: { first: number }) {
    this.first = event.first;
    console.log(this.first)
  }

  protected readonly transformImageUrl = transformImageUrl;
}
