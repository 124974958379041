import { Component, ViewEncapsulation } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { MainFrameComponent } from '../../main/main-frame/main-frame.component';
import { ModalserviceService } from '../../services/modalservice.service';

@Component({
  selector: 'app-main-tabs',
  templateUrl: './main-tabs.component.html',
  styleUrls: ['./main-tabs.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MainTabsComponent {
  selectedTabIndex: number | undefined;

  constructor(
    private router: Router,
    private mainframe: MainFrameComponent,
    public modalService: ModalserviceService
  ) {}

  onTabChanged(event: MatTabChangeEvent): void {
    this.mainframe.selectedTabIndex = event.index;
    this.selectedTabIndex = event.index;
  }
}
