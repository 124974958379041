<form [formGroup]="myForm" (ngSubmit)="submit()" autocomplete="off">
  <button
    mat-icon-button
    type="button"
    class="close-btn"
    (click)="modalService.codeclose()"
  >
    <mat-icon>close</mat-icon>
  </button>

  <mat-form-field class="example-full-width" appearance="fill">
    <mat-label>Confirmation code</mat-label>
    <input matInput [formControl]="codeFormControl" required />
    <mat-error *ngIf="codeFormControl.hasError('required')">
      Confirmation code is <strong>required</strong>
    </mat-error>
  </mat-form-field>

  <div class="button-container">
    <button mat-flat-button type="button" (click)="modalService.codeclose()">
      Cancel
    </button>
    <button mat-flat-button type="button">Re-Send</button>
    <button mat-flat-button type="submit">OK</button>
  </div>
</form>
