import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder} from "@angular/forms";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MessageService} from "primeng/api";
import {SelfData} from "../../models/struct";

@Component({
  selector: 'app-sendoutsamplelistdialog',
  templateUrl: './sendoutsamplelistdialog.component.html',
  styleUrls: ['./sendoutsamplelistdialog.component.scss']
})
export class SendoutsamplelistdialogComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
      public modalService: ModalserviceService,
      public fb: FormBuilder,
      private serviceservice: ServiceService,
      private yourappsService: YourappsserviceService,
      private messageService: MessageService
  ) {

  }
  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
  }

  protected readonly SelfData = SelfData;
}
