export interface Jobslist {
  id: number;
  Description: string;
}

export interface Industrylist {
  id: number;
  Description: string;
}

export interface Countrylist {
  id: number;
  Description: string;
}

export interface Noelist {
  id: number;
  Description: string;
}

export interface Aboutuslist {
  id: number;
  Description: string;
}

/*
,[operationtype]
      ,[ipaddress]
      ,[os]
      ,[browser]
      ,[userid]
 */
export interface UserAuth {
  SetEmail: string | undefined;
  SetPasswprd: string | undefined;
  operationtype: number | undefined;
  ipaddress: string | undefined;
  os: string | undefined;
  browser: string | undefined;
}

export interface reloaddate {
  id: number | undefined;
}

export interface sendoutsamplelist {
  sendoutid: number | undefined;
}

export interface requestsamplelist {
  requestid: number | undefined;
}

export interface latelist {
  sendoutid: number | undefined;
}

export interface AIAccount {
  setmessage: string | undefined;
  seturl: string | undefined;
  setdetail: string | undefined;
}

export interface SetAIAnswer {
  Code:         number;
  ErrorMessage: string;
  data:         Data;
}

export interface Data {
  message:       Message;
  finish_reason: string;
  index:         number;
}

export interface Message {
  role:    string;
  content: string;
}

export interface scannedcountaccount {
  userid: number | undefined;
  accountid: number | undefined;
}

export interface ChangePassDetails {
  ctrl: number | undefined;
  accountid: number | undefined;
  password: string | undefined;
  email: string | undefined;
  code: number | undefined;
}

export interface EditAccountDetails {
  firstname: string | undefined;
  lastname: string | undefined;
  accountid: number | undefined;
}

export interface Designer {
  id: number | undefined;
  operid: number | undefined;
  userid: number | undefined;
  isactivity: boolean | undefined;
  description: string | undefined;
}

export interface DesignerInfo {
  Code: number;
  ErrorMessage: string;
  data: DataArray[];
}

export interface DataArray {
  id: number;
  description: string;
  userid: number;
  isactivity: boolean;
  // regdate:     Date;
}

export interface DemoAccaunt {
  Ctrl: number;
  FirstName: string;
  LastName: string;
  SetPasswprd: string;
  SetEmail: string | undefined;
  JobTitle: string;
  Industry: string;
  Country: string;
  Company: string;
  Website: string;
  Noe: string;
  Phone: string | undefined;
  AboutUS: string;
  RPMonitoring: boolean | undefined;
  InternalTracking: boolean | undefined;
  ChinaKOLAnalytics: boolean | undefined;
  Events: boolean | undefined;
  Samples: boolean | undefined;
  DigitalShowroom: boolean | undefined;
  BrandBenchmark: boolean | undefined;
  ProductionServices: boolean | undefined;
  Contacts: boolean | undefined;
  IWant: boolean | undefined;
  Type: number;
  Activity: boolean | undefined;
  parentid: number | undefined;
  addressid: number | undefined;
  referalcode: string;
}

export interface SaleAccaunt {
  Ctrl: number;
  FirstName: string;
  LastName: string;
  SetPasswprd: string;
  SetEmail: string | undefined;
  JobTitle: string;
  Industry: string;
  Country: string;
  Company: string;
  Website: string;
  Noe: string;
  Phone: string | undefined;
  AboutUS: string;
  RPMonitoring: boolean | undefined;
  InternalTracking: boolean | undefined;
  ChinaKOLAnalytics: boolean | undefined;
  Events: boolean | undefined;
  Samples: boolean | undefined;
  DigitalShowroom: boolean | undefined;
  BrandBenchmark: boolean | undefined;
  ProductionServices: boolean | undefined;
  Contacts: boolean | undefined;
  IWant: boolean | undefined;
  Type: number;
  Activity: boolean | undefined;
  parentid: number | undefined;
  addressid: number | undefined;
  referalcode: string;
  profileid: number,
  jewelry: boolean | undefined;
}

export interface LoginAccaunt {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  jobtitle: string;
  industry: string;
  country: string;
  company: string;
  website: string;
  numberofemployees: string;
  phone: string;
  aboutus: string;
  rpmonitoring: boolean;
  internaltracking: boolean;
  chinakolanalytics: boolean;
  events: boolean;
  samples: boolean;
  digitalshowroom: boolean;
  brandBenchmark: boolean;
  productionservices: boolean;
  contacts: boolean;
  iwant: boolean;
  type: number;
  regdate: Date;
  parentid: number;
  password: string;
  imageLink: string;
  code: string;
  barcodeprefix: string;
  mycode: string;
  referalcode: string;
}

export interface RegisterResult {
  Code: number;
  data: string;
}

export interface LoginResult {
  Code: number;
  ErrorMessage: string;
  data: LoginAccaunt[];
}

export interface ChangePasswordData {
  Code: number;
  ErrorMessage: string;
  data: number;
}

export class SendOut1Data {
  static id: number | 0;
  static check1: boolean | null = false;
  static check2: boolean | null = false;
  static check3: boolean | null = false;
  static departmentFormControl: string | null = '';
  static shipFormControl: string | null = '';
  static shiptoControl: string | null = '';
  static todControl: string | null = '';
  static peControl: string | null = '';
  static companynameControl: string | null = '';
  static addresslabelControl: string | null = '';
  static costofshippingControl: string | null = '';
  static cimControl: string | null = '';
  static publicationControl: string | null = '';
  static estylistControl: string | null = '';
  static photographerControl: string | null = '';
  static pelControl: string | null = '';
  static petControl: string | null = '';
  static issueControl: string | null = '';
  static trackingNumberControl: string | null = '';
  static returnControl: string | null = '';
  static notesControl: string | null = '';
  static senderFormControl: string | null = '';
  static requestedbyFormControl: string | null = '';
  static sendOutTypeControl: string | null = '';
  static currancyControl: string | null = '';
  static reservationdateControl: any = null;
  static returndateControl: any = null;
  static datetimeCtrl: any = null;
  static datetimeCtrl1: any = null;
  static datetimeCtrl2: any = null;
  static datetimeCtrl3: any = null;
  static datetimeCtrl4: any = null;
  static celebriti: string | null = '';
  static errormode: boolean | null = false;
  static samplestyle: number | 0;
  static reset() {
    this.id = 0;
    this.check1 = false;
    this.check2 = false;
    this.check3 = false;
    this.departmentFormControl = '';
    this.shipFormControl = '';
    this.shiptoControl = '';
    this.todControl = '';
    this.peControl = '';
    this.companynameControl = '';
    this.addresslabelControl = '';
    this.costofshippingControl = '';
    this.cimControl = '';
    this.publicationControl = '';
    this.estylistControl = '';
    this.photographerControl = '';
    this.pelControl = '';
    this.petControl = '';
    this.issueControl = '';
    this.trackingNumberControl = '';
    this.returnControl = '';
    this.notesControl = '';
    this.senderFormControl = '';
    this.requestedbyFormControl = '';
    this.sendOutTypeControl = '';
    this.currancyControl = '';
    this.reservationdateControl = null;
    this.returndateControl = null;
    this.datetimeCtrl = null;
    this.datetimeCtrl1 = null;
    this.datetimeCtrl2 = null;
    this.datetimeCtrl3 = null;
    this.datetimeCtrl4 = null;
    this.celebriti = '';
  }
}

export interface PrcDeleteSamplesFields {
  sampleid: number;
  deletetype: number;
  userid: number;
  accountid: number;
  reason: string;
}

export interface ProcessEmailFields {
  ctrl: number;
  id: number;
  FromName: string;
  FromEmail: string;
  ReplyTo?: string;
  selectedemails: emailaccountData[];
  sendertype: string;
  email: string;
  subject: string;
  accountid: number;
  notes: string;
  isactivity: boolean;
  issent: boolean;
}

export interface SelectedEmail {
  Code: number; // Код ответа или идентификатор
  ErrorMessage: string; // Сообщение об ошибке или статус
  data: emailaccountData[]; // Массив данных учетных записей
}

// export interface SelectedEmailData {
//   id?: number; // ID записи
//   email: string; // Email адрес
//   [key: string]: any; // Для дополнительных полей
// }

export interface ProcessEmailResponse {
  success: boolean;
  returnValue?: number;
  error?: string;
}

export interface PrcDeleteSamplesResponse {
  Code: number;
  ErrorMessage: string;
  data: number; // This assumes that the server returns the insertedID as "data"
}

export class Speener {
  static requestvisiblemode: boolean = true
  static sendoutvisiblemode: boolean = true
  static samplevisiblemode: boolean = true
  static lookbookvisiblemode: boolean = true
  static cmvisiblemode: boolean = true
}

export class SelfData {
  static email: string | undefined;
  static account: LoginResult[] = [];
  static generalsetup: TagsList[] = [];
  static sws: GetSWSList[] = [];
  static lb: GetlbCatalogs[] = [];
  static linkedimages: GetLinkedImages[] = [];
  static tlb: GetlbCatalogs[] = [];
  static lbmain: GetLBMain[] = [];
  static lbsent: GetlbSentList[] = [];
  static lbvisibled: GetCheckVisibled[] = [];
  static lbdesignerlist: Getlbdesignerlist[] = [];
  static region: GetRegionList[] = [];
  static designer: GetRegionList[] = [];
  static fabric: GetFabricList[] = [];
  static manu: GetManuList[] = [];
  static collection: GetCollectionList[] = [];
  static season: GetSeasonList[] = [];
  static sendoutlist: GetSendOutList[] = [];
  static cmlist: clientmails[] = [];
  static samplehistory: samplehistory[] = [];
  static gridgields: setgridfields[] = [];
  static customergroups: setcustomergroups[] = [];
  static sendoutpendinglist: GetSendOutList[] = [];
  static requesttlist: GetRequestList[] = [];
  static emailcontacttlist: emailaccount[] = [];
  static style: GetStyleList[] = [];
  static contact: GetStyleList[] = [];
  static estylist: GetStyleList[] = [];
  static sendouttype: GetStyleList[] = [];
  static cim: GetStyleList[] = [];
  static sender: GetStyleList[] = [];
  static publication: GetStyleList[] = [];
  static client: GetStyleList[] = [];
  static misc: GetMiscList[] = [];
  static type: GetTypeList[] = [];
  static size: GetSizeList[] = [];
  static sizerange: GetSizeRangeList[] = [];
  static pattern: GetPatternList[] = [];
  static photographer: GetPatternList[] = [];
  static systemfields: GetSystemFields[] = [];
  static systemContactsfields: GetSystemFields[] = [];
  static scannedlist: GetScannedList[] = [];
  static scannedfulllist: GetScannedList[] = [];
  static scannedselectedlist: GetSelectedScannedListData[] = [];
  static requestlist: GetRequestList[] = [];
  static latelist: GetLateList[] = [];
  static getsendoutreturnlist: GetSendOutReturnList[] = [];
  static customfields: GetCustomFields[] = [];
  static contactcustomfields: GetContactCustomFields[] = [];
  static cfv: SetCustomFieldsValue[] = [];
  static userfilter: GetUserFilterList[] = [];
  static sampledata: SetSampleList[] = [];
  static originalData: SetSampleListData[] = [];
  static originalsoReportData: SetsorList[] = [];
  static iSendOutReportResult: SetsorList[] = [];
  static lookname: GetLookNameList[] = [];
  static looks: GetLookList[] = [];
  static samplelookdata: SetSampleList[] = [];
  static samplelookeselecteddata: SetSampleList[] = [];
  static contactdata: SetSampleList[] = [];
  static socialmedia: GetSocialMediaList[] = [];
  static smedia: GetSocialMediaList[] = [];
  static pronoun: GetPronounList[] = [];
  static barcode: GetBarcodeList[] = [];
  static contcategory: GetContCategoryList[] = [];
  static contjd: GetContjdList[] = [];
  static currancy: GetCurrancyList[] = [];
  static conttype: GetContTypeList[] = [];
  static comptype: GetCompTypeList[] = [];
  static contregion: GetContRegionList[] = [];
  static contrb: GetContRbList[] = [];
  static contac: GetContacList[] = [];
  static contcg: GetContcgList[] = [];
  static addres: GetAddressList[] = [];
  static company: GetCompanyList[] = [];
  static systemuser: GetSystemUsers[] = [];
  static notificationsettings: GetNotSettings[] = [];
  static contacts: SetContactsList[] = [];
  static tod: GettodList[] = [];
  static pe: GetpeList[] = [];
  static celebriti: GetCelebritiList[] = [];
  static cc: GetCCList[] = [];
  static sm: GetSMList[] = [];
  static issue: GetissueList[] = [];
  static checkedsample: SetSampleListData[] = [];
  static setSelectedSample: SetSampleListData[] = [];
  static fieldtype: number | undefined;
  static formcontrol: number | undefined;
  static userid: number | undefined;
  static selfpassword: string | undefined;
  static modalformcaption: string | undefined;
  static modaltype: number | undefined;
  static sampleselecteddata: any | undefined;
  static frame: number;
  static selectedddid: number;
  static selectlookmode: number;
  static selectsendoutmode: number;
  static imageUrl: string;
  static createsamplebutton: boolean;
  static scannlistshower: boolean;
  static updatebuttonisenabled: boolean = true;
  static isduplicate: boolean = false;
  static sendoutid: number;
  static sendoutpendingid: number;
  static isacceptedstatus: boolean = true;
  static selDeleteSimpleID: number;
  static selselectedIndex: number;
  static selselectedIndex1: number;
  static selectadpage: number;
  static selectadpage1: number;
  static edotedtext: string;
  static selectedbarcode: string;
  static setSample: SetSampleListData[] = [];
  static saveonclose: boolean | undefined;
  static closesendout: boolean | undefined;
  static fullPage: boolean;
  static sendoutopentype: number;
  static operid: number;
  static opertext: string;
  static sendoutstep: number = 0;
  static contactid: number = 0;
  static selcurrancy: string;
  static signatureimage: string = "";
  static sendoutgroupecaption: string = "";
  static pullsheetlist: SetPullSheetList[] = [];
  static qrresult: GetQRResult[] = [];
  static lbsents: GetLBSents[] = [];
  static originalimages: GetOriginalImages[] = [];
  static printrequestlist: GetLBSents[] = [];
  static printlatesendout: GetLateSendOut[] = [];
  static lbmainlist: GetLBMainList[] = [];
  static lbdesigners: GetLBDesigners[] = [];
  static lbaccepts: GetLBAccepts[] = [];
  static lbcollections: GetLBCollections[] = [];
  static selectpullsheetlist: SetPullSheetList[] = [];
  static sendoutsamples: GetSendOutSamples[] = [];
  static sendoutmode: number = 0;
  static lbmode: number = 0;
  static lbdescription: string = "";
  static trackingNumber: string = "";
  static lbkey: string = "";
  static lbframetype: number = 0;
  static tlbmainid: number = 0;
  static tdesignerid: number = 0;
  static tlbcatalogid: number = 0;
  static sendoutrequestid: number = 0;
  static selectedSendouttype: number = 0;
  static waitpanel: boolean = true;
  static tlburl: string = "";
  static tcustomimages: { Code: number, ErrorMessage: string, data: any[] } = { Code: 0, ErrorMessage: '', data: [] };
  static tselectedimagefiles: SetSelectedFiles[] = []
  static selectedsample: SetSampleListData[] = [];
  static componentFieldData: setcomponentfieldsData[] = [];
  static taxesData: settaxefieldsData[] = [];
  static reportmode: number = -1;
  static samplespage: number = 0;
  static samplestyle: number = 0;
  static spinnerpossition: boolean = false
  static totalorder: number = 0;
  static returnorder: boolean = false;
  static caption: string = "";
  static formstyle: number = 0;
  static guid: string = "";
}

export class CurrData {
  static id: number | undefined;
  static operationType: number | undefined;
  static addeditValue: string | undefined;
  static addeditButtonCaption: string | undefined;
}

export interface GetSWSAccont {
  accountid: number | undefined;
}

export interface prcswsaccount {
  ctrl: number | undefined;
  description: string;
  accountid: number | undefined;
  id: number | undefined;
}

export interface GetSWSList {
  Code: number;
  ErrorMessage: string;
  data: GetSWSListData[];
}

export interface DataArray {
  id: number;
  description: string;
  // regdate:     Date;
}

export interface GetSWSListData {
  id: number;
  description: string;
  accountid: number;
}

export interface Prcswslist {
  Code: number;
  ErrorMessage: string;
  data: number;
}

export interface PrcCreateRequestToSendOutResponse {
  Code: number;
  ErrorMessage: string;
  data: any; // Replace 'any' with a more specific type if available
}

export interface PrcCreateSendOutToRequestResponse {
  Code: number;
  ErrorMessage: string;
  data: any; // Replace 'any' with a more specific type if available
}

export interface Prcrequestlist {
  Code: number;
  ErrorMessage: string;
  data: number;
}

export interface LbSelectedDesignerResponse {
    Code: number;
    ErrorMessage: string;
    data: any;
}

export interface LbSelectedSeasonRequest {
    description: string | undefined;
    seasoncode: string | undefined;
    seasonyear: number;
    accountid: number;
    desingerid: number;
    catalogid: number;
    lbmainid: number;
    ctrl: number;
    id: number;
}

export interface LbSelectedSeasonResponse {
    Code: number;
    ErrorMessage: string;
    data: any; // Replace 'any' with the actual data structure returned by the stored procedure
}

export interface PrcProductsSaleRequest {
  ctrl: number; // 0 = Insert, 1 = Update, 2 = Deactivate
  id?: number;
  name: string;
  brand?: string;
  description?: string;
  tags?: string;
  product_categories?: string;
  color?: string;
  materials?: string;
  sku_code?: string;
  supplier?: string;
  supplier_code?: string;
  supplier_price?: number;
  default_sales_tax?: string;
  supply_price?: number;
  markup?: number;
  margin?: number;
  tax?: number;
  retail_price?: number;
  imagelink?: string;
  userid: number;
  markupw?: number;
  marginw?: number;
  taxw?: number;
  wholesale_price?: number;
  minimumquantity?: number;
  stoneweight?: number;
  netweight?: number;
  size?: number;
}

export interface PrcProductsSaleResponse {
  Code: number;
  ErrorMessage: string;
  data: any;
}

export interface PrcOrderSaleRequest {
  id?: number;
  supplierid: number;
  supplier: string;
  accountid: number;
  ordering_for?: string;
  deliver_to?: string;
  delivery_date?: any; // Дата в формате ISO
  order_number: string;
  supplier_invoice_number?: string;
  note?: string;
  subtotal: number;
  total_discount: number;
  shipping_cost: number;
  import_duty: number;
  order_total: number;
  ctrl: number;
}

export interface PrcOrderSaleResponse {
  Code: number;
  ErrorMessage: string;
  data: any;
}

export interface PrcProductsInSaleRequest {
  id?: number;
  tbl_order_sale_inid: number;
  accountid: number;
  name: string;
  code: string;
  sku_code: string;
  quantity: number;
  unitPrice: number;
  discountPercentage: number;
  discountAmount: number;
  totalBeforeDiscount: number;
  totalAfterDiscount: number;
  imagelink: string;
  ctrl: number;
  productid: number;
  wholesaleprice: number;
  totalwholesale: number;
}

export interface PrcProductsOutSaleRequest {
  id?: number;
  tbl_order_sale_id: number;
  accountid: number;
  name: string;
  code: string;
  sku_code: string;
  quantity: number;
  unitPrice: number;
  discountPercentage: number;
  discountAmount: number;
  totalBeforeDiscount: number;
  totalAfterDiscount: number;
  imagelink: string;
  ctrl: number;
  productid: number;
  salaroid: number;
  customerid: number;
  orderstatus: number
}

export interface PrcProductsInSaleResponse {
  Code: number;
  ErrorMessage: string;
  data: any;
}

export interface Prccelebritilist {
  Code: number;
  ErrorMessage: string;
  data: number;
}

export interface Prcsmlist {
  Code: number;
  ErrorMessage: string;
  data: number;
}

export interface PrcSendOutReturnlist {
  Code: number;
  ErrorMessage: string;
  data: number;
}

export interface SendOutSampleRequest {
  ctrl: number;
  sampleid: number;
  accountid: number;
  userid: number;
  sendoutid: number;
  itemcount: number;
}

export interface RequestSampleRequest {
  ctrl: number;
  sampleid: number;
  accountid: number;
  userid: number;
  sendoutid: number;
  itemcount: number;
  isaccepted: boolean;
}

export interface PrcSuppliersSaleRequest {
  id?: number;
  supplierName: string;
  defaultMarkup?: number;
  description?: string;
  firstName?: string;
  lastName?: string;
  companyName?: string;
  email?: string;
  phone?: string;
  mobile?: string;
  website?: string;
  instagram?: string;
  facebook?: string;
  tikTok?: string;
  twitter?: string;
  youTube?: string;
  storeStreetAddress?: string;
  storeStreetAddress2?: string;
  storeSuburb?: string;
  storeCity?: string;
  storeZipCode?: string;
  storeState?: string;
  storeCountry?: string;
  mailingSameAsStore?: boolean;
  mailingStreetAddress?: string;
  mailingStreetAddress2?: string;
  mailingSuburb?: string;
  mailingCity?: string;
  mailingZipCode?: string;
  mailingState?: string;
  mailingCountry?: string;
  accountid?: number;
  isactivity?: boolean;
  ctrl: number;
}

export interface PrcBrandSaleRequest {
  id?: number;
  description: string;
  userid: number;
  ctrl: number;
}

export interface PrcBrandSaleResponse {
  Code: number;
  ErrorMessage: string;
  data: number;
}

export interface PrcSuppliersSaleResponse {
  Code: number;
  ErrorMessage: string;
  data: any;
}

export interface UserPreferencesRequest {
  ctrl: number;
  userID: number;
  selectCheckboxCollection: boolean;
  selectCheckboxDepartment: boolean;
  selectCheckboxDesigner: boolean;
  selectCheckboxSeason: boolean;
  selectCheckboxShipDate: boolean;
  selectCheckboxColor: boolean;
  selectCheckboxCountryOfOrigin: boolean;
  selectCheckboxEditorStylist: boolean;
  selectCheckboxEvent: boolean;
  selectCheckboxFabric: boolean;
  selectCheckboxGender: boolean;
  selectCheckboxIncludeStaffLoans: boolean;
  selectCheckboxManufacturer: boolean;
  selectCheckboxMethodOfDispatch: boolean;
  selectCheckboxMisc: boolean;
  selectCheckboxModelCeleb: boolean;
  selectCheckboxPattern: boolean;
  selectCheckboxPhotographer: boolean;
  selectCheckboxPublication: boolean;
  selectCheckboxRecipient: boolean;
  selectCheckboxRegion: boolean;
  selectCheckboxRequestedBy: boolean;
  selectCheckboxSender: boolean;
  selectCheckboxShootLocation: boolean;
  selectCheckboxSize: boolean;
  selectCheckboxSizeRange: boolean;
  selectCheckboxStoryTheme: boolean;
  selectCheckboxStyleNumber: boolean;
  selectCheckboxType: boolean;
  selectSampleStatus: boolean;
}

export interface UserPreferencesResponse {
  Code: number;
  ErrorMessage: string;
  data: number[];
}

export interface Prcregionlist {
  Code: number;
  ErrorMessage: string;
  data: number;
}

export interface GetRegionAccont {
  accountid: number | undefined;
}

export interface GetRegionList {
  Code: number;
  ErrorMessage: string;
  data: GetRegionListData[];
}

export interface GetRegionListData {
  id: number;
  description: string;
  accountid: number;
}

export interface GetDesignerAccont {
  accountid: number | undefined;
}

export interface GetBarcodeAccount {
  accountid: number | undefined;
}

export interface GetDesignerList {
  Code: number;
  ErrorMessage: string;
  data: GetDesignerListData[];
}

export interface GetDesignerListData {
  id: number;
  description: string;
  accountid: number;
}

export interface GetBarcodeList {
  Code: number;
  ErrorMessage: string;
  data: GetBarcodeListData[];
}

export interface GetBarcodeListData {
  id: number;
  description: string;
  accountid: number;
}

export interface Prcdesignerlist {
  Code: number;
  ErrorMessage: string;
  data: number;
}

export interface GetFabricAccont {
  accountid: number | undefined;
}

export interface GetFabricList {
  Code: number;
  ErrorMessage: string;
  data: GetFabricListData[];
}

export interface GetFabricListData {
  id: number;
  description: string;
  accountid: number;
}

export interface Prcfabriclist {
  Code: number;
  ErrorMessage: string;
  data: number;
}

export interface GetManuAccont {
  accountid: number | undefined;
}

export interface GetManuList {
  Code: number;
  ErrorMessage: string;
  data: GetManuListData[];
}

export interface GetManuListData {
  id: number;
  description: string;
  accountid: number;
}

export interface Prcmanulist {
  Code: number;
  ErrorMessage: string;
  data: number;
}

export interface GetCollectionAccont {
  accountid: number | undefined;
}

export interface GetCollectionList {
  Code: number;
  ErrorMessage: string;
  data: GetCollectionListData[];
}

export interface GetCollectionListData {
  id: number;
  description: string;
  accountid: number;
  desinger: string;
  desingerid: number;
  season: string;
  seasonid: number;
}

export interface Prccollectionlist {
  Code: number;
  ErrorMessage: string;
  data: number;
}

export interface GetSeasonAccont {
  accountid: number | undefined;
}

export interface GetSendOutAccont {
  accountid: number | undefined;
}

export interface GetCurSendOutAccont {
  sendoutid: number | undefined;
}

export interface GetContactAccount {
  accountid: number | undefined;
}

export interface GetSampleId {
  sampleid: number | undefined;
}

export interface GetGridFields {
  formname: string;
  userid: number | undefined;
  accountid: number | undefined;
}

export interface GetCustomerGrpupes {
  accountid: number | undefined;
}

export interface GetCMAccountId {
  accountid: number | undefined;
}

export interface GetCMDetailsAccountId {
  tblsetupid: number | undefined;
}

export interface GetRequestAccount {
  accountid: number | undefined;
}

export interface GetSeasonList {
  Code: number;
  ErrorMessage: string;
  data: GetSeasonListData[];
}

export interface GetSeasonListData {
  id: number;
  seasoncode: string;
  seasonyear: string;
  description: string;
  accountid: number;
}

export interface Prcseasonlist {
  Code: number;
  ErrorMessage: string;
  data: number;
}

export interface GetStyleAccont {
  accountid: number | undefined;
}

export interface GetStyleList {
  Code: number;
  ErrorMessage: string;
  data: GetStyleListData[];
}

export interface GetStyleListData {
  id: number;
  description: string;
  accountid: number;
}

export interface Prcstylelist {
  Code: number;
  ErrorMessage: string;
  data: number;
}

export interface GetMiscAccont {
  accountid: number | undefined;
}

export interface GetMiscList {
  Code: number;
  ErrorMessage: string;
  data: GetMiscListData[];
}

export interface GetMiscListData {
  id: number;
  description: string;
  accountid: number;
}

export interface Prcmisclist {
  Code: number;
  ErrorMessage: string;
  data: number;
}

export interface PrclookAccount {
  Code: number;
  ErrorMessage: string;
  data: number;
}

export interface PrclooklistAccount {
  Code: number;
  ErrorMessage: string;
  data: number;
}

export interface GetTypeAccont {
  accountid: number | undefined;
}

export interface GetTypeList {
  Code: number;
  ErrorMessage: string;
  data: GetTypeListData[];
}

export interface GetTypeListData {
  id: number;
  description: string;
  accountid: number;
}

export interface Prctypelist {
  Code: number;
  ErrorMessage: string;
  data: number;
}

export interface GetSizeAccont {
  accountid: number | undefined;
}

export interface GetSizeList {
  Code: number;
  ErrorMessage: string;
  data: GetSizeListData[];
}

export interface GetSizeListData {
  id: number;
  description: string;
  accountid: number;
}

export interface Prcsizelist {
  Code: number;
  ErrorMessage: string;
  data: number;
}

export interface GetPatternAccont {
  accountid: number | undefined;
}

export interface GetPatternList {
  Code: number;
  ErrorMessage: string;
  data: GetPatternListData[];
}

export interface GetPatternListData {
  id: number;
  description: string;
  accountid: number;
}

export interface Prcpatternlist {
  Code: number;
  ErrorMessage: string;
  data: number;
}

export interface GetSizeRangeAccont {
  accountid: number | undefined;
}

export interface GetSizeRangeList {
  Code: number;
  ErrorMessage: string;
  data: GetSizeRangeListData[];
}

export interface GetSizeRangeListData {
  id: number;
  description: string;
  accountid: number;
}

export interface Prcsizerangelist {
  Code: number;
  ErrorMessage: string;
  data: number;
}

export interface PrcSampleList {
  Code: number;
  ErrorMessage: string;
  data: number;
}

export interface PrcSystemFieldsList {
  Code: number;
  ErrorMessage: string;
  data: number;
}

export interface PrcContactsSystemFieldsList {
  Code: number;
  ErrorMessage: string;
  data: number;
}

export interface GetSystemFields {
  Code: number;
  ErrorMessage: string;
  data: GetSystemFieldsData[];
}

export interface GetSystemContactsFields {
  Code: number;
  ErrorMessage: string;
  data: GetSystemContactsFieldsData[];
}

export interface GetCustomFields {
  Code: number;
  ErrorMessage: string;
  data: GetCustomFieldsData[];
}

export interface GetContactCustomFields {
  Code: number;
  ErrorMessage: string;
  data: GetContactCustomFieldsData[];
}

export interface GetContactCustomFieldsData {
  id: number;
  framename: Framename;
  fieldlabel: string;
  accountid: number;
  requered: boolean;
  fieldtype: number;
  controllertype: number;
  minnumber: number;
  maxnumber: number;
  isfloat: boolean;
  step: number;
  userid: number;
  regdate: Date;
  curdate: Date;
  description: string;
}

export interface GetCustomFieldsData {
  id: number;
  framename: Framename;
  fieldlabel: string;
  accountid: number;
  requered: boolean;
  fieldtype: number;
  controllertype: number;
  minnumber: number;
  maxnumber: number;
  isfloat: boolean;
  step: number;
  userid: number;
  regdate: Date;
  curdate: Date;
  description: string;
}

export interface GetSystemFieldsData {
  id: number;
  framename: Framename;
  fieldlabel: string;
  accountid: number;
  requered: boolean;
  fieldtype: number;
  controllertype: number;
  minnumber: number;
  maxnumber: number;
  isfloat: boolean;
  step: number;
  userid: number;
  regdate: Date;
  curdate: Date;
  description: string;
}

export interface GetSystemContactsFieldsData {
  id: number;
  framename: Framename;
  fieldlabel: string;
  accountid: number;
  requered: boolean;
  fieldtype: number;
  controllertype: number;
  minnumber: number;
  maxnumber: number;
  isfloat: boolean;
  step: number;
  userid: number;
  regdate: Date;
  curdate: Date;
  description: string;
}

export interface GetSystemFieldsFields {
  ctrl: number;
  id: number;
  framename: Framename;
  fieldlabel: string;
  accountid: number;
  required: boolean;
  fieldtype: number;
  controllertype: number;
  minnumber: number;
  maxnumber: number;
  isfloat: boolean;
  step: number;
  userid: number;
  curdate: Date;
  description: string;
}

export interface GetCustomFieldsFields {
  ctrl: number;
  id: number;
  framename: Framename;
  fieldlabel: string;
  accountid: number;
  required: boolean;
  fieldtype: number;
  controllertype: number;
  minnumber: number;
  maxnumber: number;
  isfloat: boolean;
  step: number;
  userid: number;
  curdate: Date;
  description: string;
}

export enum Framename {
  Createsampledialog = 'createsampledialog',
}

export interface GetSystemFieldsParams {
  accountid: number | undefined;
  framename: string | undefined;
}

export interface GetSystemContactsFieldsParams {
  accountid: number | undefined;
  framename: string | undefined;
}

export interface GetCustomFieldsParams {
  accountid: number | undefined;
  framename: string | undefined;
}

export interface GetContactCustomFieldsParams {
  accountid: number | undefined;
  framename: string | undefined;
}

export interface SetCFVParams {
  accountid: number | undefined;
  framename: string | undefined;
  sampleid: number | undefined;
}

export interface SetSampleParams {
  accountid: number | undefined;
  page: number | undefined;
  pageSize: number | undefined;
  forceRefresh: boolean;
  samplestyle: number;
}

export interface SetsorParams {
  accountid: number | undefined;
  forceRefresh: boolean;
}

export interface SetContactsParams {
  accountid: number | undefined;
  contactsid: number | undefined;
}
export interface SetCustomFieldsValue {
  Code: number;
  ErrorMessage: string;
  data: SetCustomFieldsValueData[];
}

export interface SetCustomFieldsValueData {
  id: number;
  controllername: string;
  accountid: number;
  framename: string;
  value: string;
  sampleid: number;
  regdate: Date;
}

export interface Prccfvlist {
  Code: number;
  ErrorMessage: string;
  data: number;
}

export interface PrcCompany {
  Code: number;
  ErrorMessage: string;
  data: number;
}

export interface PrcContacts {
  Code: number;
  ErrorMessage: string;
  data: number;
}
export interface PrcAddress {
  Code: number;
  ErrorMessage: string;
  data: any;
}

export interface PrcNotificationSettings {
  Code: number;
  ErrorMessage: string;
  data: any;
}

export interface Prcpriceslist {
  Code: number;
  ErrorMessage: string;
  data: number;
}

export interface prcSendOutList {
  Code: number;
  ErrorMessage: string;
  data: number;
}

export interface prcRequestList {
  Code: number;
  ErrorMessage: string;
  data: number;
}

export interface GetPDFImages {
  Code:         number;
  ErrorMessage: string;
  data:         GetPDFImagesData[];
}

export interface GetPDFImagesData {
  filename: string;
  fileurl:  string;
}

export interface PrcSendOutFields {
  ctrl: number;
  id: number;
  check1: boolean;
  check2: boolean;
  check3: boolean;
  department: string;
  ship: string;
  shipto: string;
  tod: string;
  pe: string;
  companyname: string;
  addresslabel: string;
  costofshipping: string;
  cim: string;
  publication: string;
  estylist: string;
  photographer: string;
  pel: string;
  pet: string;
  issue: string;
  trackingNumber: string;
  return: string;
  notes: string;
  requestedby: string;
  sendOutType: string;
  currancy: string;
  reservationdate: Date;
  returndate: Date;
  datetimeCtrl: Date;
  datetimeCtrl1: Date;
  datetimeCtrl2: Date;
  datetimeCtrl3: Date;
  datetimeCtrl4: Date;
  accountid: number;
  userid: number;
  sendouttypeid: number;
  isactivity: boolean;
  celebriti: string;
}

export interface PrcSendOutShipFields {
  ctrl: number;
  id: number;
  shipdate: Date;
}

export interface PrcDeleteSendOutFields {
  ctrl: number;
  id: number;
}

export interface PrcRequestFields {
  ctrl: number;
  id: number;
  check1: boolean;
  check2: boolean;
  check3: boolean;
  department: string;
  ship: string;
  shipto: string;
  tod: string;
  pe: string;
  companyname: string;
  addresslabel: string;
  costofshipping: string;
  cim: string;
  publication: string;
  estylist: string;
  photographer: string;
  pel: string;
  pet: string;
  issue: string;
  trackingNumber: string;
  return: string;
  notes: string;
  requestedby: string;
  sendOutType: string;
  currancy: string;
  reservationdate: Date;
  returndate: Date;
  datetimeCtrl: Date;
  datetimeCtrl1: Date;
  datetimeCtrl2: Date;
  datetimeCtrl3: Date;
  datetimeCtrl4: Date;
  accountid: number;
  userid: number;
  sendouttypeid: number;
  isactivity: boolean;
  celebriti: string;
  sendoutid: number;
  isaccepted: boolean
}

export interface SetSampleList {
  Code: number;
  ErrorMessage: string;
  data: SetSampleListData[];
}

export interface SetSampleListData {
  [key: string]: any; // or unknown
}

export interface SetsorList {
  Code: number;
  ErrorMessage: string;
  data: SetsorListData[];
}

export interface SetsorListData {
  [key: string]: any; // or unknown
}

export interface checksampleaccount {

}

export interface checksample {
  Code: number;
  ErrorMessage: string;
  data: checksampleData[];
}

export interface checksampleData {
  [key: string]: any; // or unknown
}

export interface SetSendoutSampleList {
  Code: number;
  ErrorMessage: string;
  data: SetSendoutSampleListData[];
}

export interface SetSendoutSampleListData {
  [key: string]: any; // or unknown
}

export interface SetContactsList {
  Code: number;
  ErrorMessage: string;
  data: SetContactsListData[];
}

export interface SetContactsListData {
  [key: string]: any; // or unknown
}
export interface GetImageList {
  Code: number;
  ErrorMessage: string;
  data: GetImageListData[];
}

export interface GetImageListData {
  imagelink: string;
}

export interface GetLBImageList {
  Code:         number;
  ErrorMessage: string;
  data:         GetLBImageListData[];
}

export interface GetLBImageListData {
  accountid:   number;
  userid:      number;
  filename:    string;
  filedir:     string;
  operdate:    Date;
  isactivity:  boolean;
  lbcatalogid: number;
  colection:   string;
  designer:    string;
  catalog:     string;
  catalogyear: number;
  seasoncode:  string;
}

export interface GetLBCImageList {
  Code:         number;
  ErrorMessage: string;
  data:         GetLBCImageListData[];
}

export interface GetLBCImageListData {
  id:          number;
  accountid:   number;
  userid:      number;
  filename:    string;
  filedir:     string;
  operdate:    Date;
  isactivity:  boolean;
  lbcatalogid: number;
  lbmainid:    number;
  description: string;
}


export interface setImageParams {
  accountid: number | undefined;
  sampleid: number | undefined;
}

export interface delImageParams {
  ctrl: number | undefined
  accountid: number | undefined;
  sampleid: number | undefined;
  filename: string | undefined;
}

export interface setLBImageParams {
  lbcatalogid: number | undefined;
}

export interface GetLBImages {
  Code:         number;
  ErrorMessage: string;
  data:         GetLBImagesData[];
}

export interface GetLBImagesData {
  accountid:   number;
  userid:      number;
  filename:    string;
  filedir:     string;
  operdate:    Date;
  isactivity:  boolean;
  lbcatalogid: number;
  colection:   string;
  designer:    string;
  catalog:     string;
  catalogyear: number;
}

export interface SetSelectedFiles {
  index: number;
  file: File | null;
  url: string;
  filename: string;
  lbcatalogid: number;
  colection: string;
  designer: string;
  catalog: string;
  catalogyear: number;
  seasoncode: string;
  oldchecked: boolean;
}

export interface SetSelectedFilesV {
  file: File | null;
  url: string;
  filename: string;
  lbcatalogid: number;
  colection: string;
  designer: string;
  catalog: string;
  catalogyear: number;
  seasoncode: string;
  islinked: boolean;
  simpleid: number;
  lbmainid: number;
  description: string;
  barcode: string
}

export interface setLBCImageParams {
  lbmainid: number | undefined;
}

export interface GetPronounAccont {
  accountid: number | undefined;
}

export interface GetPronounList {
  Code: number;
  ErrorMessage: string;
  data: GetPronounListData[];
}

export interface GetPronounListData {
  id: number;
  description: string;
  accountid: number;
}

export interface Prcpronounlist {
  Code: number;
  ErrorMessage: string;
  data: number;
}

export interface GetContCategoryAccont {
  accountid: number | undefined;
}

export interface GetContCategoryList {
  Code: number;
  ErrorMessage: string;
  data: GetContCategoryListData[];
}

export interface GetContCategoryListData {
  id: number;
  description: string;
  accountid: number;
}

export interface Prccontcategorylist {
  Code: number;
  ErrorMessage: string;
  data: number;
}

export interface GetContjdAccont {
  accountid: number | undefined;
}

export interface GetContjdList {
  Code: number;
  ErrorMessage: string;
  data: GetContjdListData[];
}

export interface GetContjdListData {
  id: number;
  description: string;
  accountid: number;
}

export interface Prccontjdlist {
  Code: number;
  ErrorMessage: string;
  data: number;
}

export interface prcsocketlist {
  Code: number;
  ErrorMessage: string;
  data: number;
}

export interface GetContTypeAccont {
  accountid: number | undefined;
}

export interface GetContTypeList {
  Code: number;
  ErrorMessage: string;
  data: GetContTypeListData[];
}

export interface GetContTypeListData {
  id: number;
  description: string;
  accountid: number;
}

export interface Prcconttypelist {
  Code: number;
  ErrorMessage: string;
  data: number;
}

export interface GetContRegionAccont {
  accountid: number | undefined;
}

export interface GetContRegionList {
  Code: number;
  ErrorMessage: string;
  data: GetContRegionListData[];
}

export interface GetContRegionListData {
  id: number;
  description: string;
  accountid: number;
}

export interface Prccontregionlist {
  Code: number;
  ErrorMessage: string;
  data: number;
}

export interface GetContRbAccont {
  accountid: number | undefined;
}

export interface GetContRbList {
  Code: number;
  ErrorMessage: string;
  data: GetContRbListData[];
}

export interface GetContRbListData {
  id: number;
  description: string;
  accountid: number;
}

export interface Prccontrblist {
  Code: number;
  ErrorMessage: string;
  data: number;
}

export interface GetContacAccont {
  accountid: number | undefined;
}

export interface GetContacList {
  Code: number;
  ErrorMessage: string;
  data: GetContacListData[];
}

export interface GetContacListData {
  id: number;
  description: string;
  accountid: number;
}

export interface Prccontaclist {
  Code: number;
  ErrorMessage: string;
  data: number;
}

export interface GetContcgAccont {
  accountid: number | undefined;
}

export interface GetContcgList {
  Code: number;
  ErrorMessage: string;
  data: GetContcgListData[];
}

export interface GetContcgListData {
  id: number;
  description: string;
  accountid: number;
}

export interface Prccontcglist {
  Code: number;
  ErrorMessage: string;
  data: number;
}

export interface GetCompTypeAccont {
  accountid: number | undefined;
}

export interface GetCompTypeList {
  Code: number;
  ErrorMessage: string;
  data: GetCompTypeListData[];
}

export interface GetCompTypeListData {
  id: number;
  description: string;
  accountid: number;
}

export interface Prccomptypelist {
  Code: number;
  ErrorMessage: string;
  data: number;
}

export interface GetAddressAccont {
  accountid: number | undefined;
  contactsid: number | undefined;
}

export interface setPullSheet {
  key: string;
}

export interface getPullSheet {
  accountid: number;
  email:     string;
  date:      Date | null;
  period:    string;
}

export interface SetPullSheetList {
  Code:         number;
  ErrorMessage: string;
  data:         SetPullSheetListData[];
}

export interface SetPullSheetListData {
  id:            number;
  email:         string;
  key:           string;
  accountid:     number;
  userid:        number;
  sendoutid:     number;
  status:        number;
  isactivity:    boolean;
  prmanager:     string;
  prphone:       string;
  premail:       string;
  asistant:      string;
  asiphone:      string;
  asiemail:      string;
  latefees:      string;
  cleaning:      string;
  minor:         string;
  lost:          string;
  missing:       string;
  instagram:     string;
  logoname:      string;
  deposite:      string;
  company:       string;
  address1:      string;
  address2:      string;
  city:          string;
  state:         string;
  zipcode:       string;
  imagelink:     string;
  pullsheetid:   number;
  dateofpull:    Date;
  dateodfitting: Date;
  dateofse:      Date;
  dateoder:      Date;
  dateoffr:      Date;
  gb:            string;
  tb:            string;
  stylistname:   string;
  publication:   string;
  confirms:      string;
  phonenumber:   string;
  acceptedemail: string;
  cc:            string;
  expdate:       Date;
  securitycode:  string;
  bilingzipcode: string;
  printname:     string;
  signaturelink: string;
}


export interface GetNewAddressAccont {
  id: number | undefined;
}

export interface GetAddressList {
  Code: number;
  ErrorMessage: string;
  data: GetAddressListData[];
}

export interface GetAddressListData {
  id: number;
  careof: string;
  country: string;
  address1: string;
  email: string;
  otheremail: string;
  address2: string;
  phone: string;
  generalphone: string;
  mobile1: string;
  mobile2: string;
  city: string;
  state: string;
  zipcode: string;
  regdate: Date;
  isactivity: boolean;
  contactsid: number;
  description: string;
}

export interface GetNotSettingsAccont {
  accountid: number | undefined;
}
export interface GetNotSettings {
  code: number;
  errorMessage: string;
  data: getNotSettingsData[];
}

export interface getNotSettingsData {
  id: number;
  rsMonday: boolean;
  rsTuesday: boolean;
  rsWednesday: boolean;
  rsThursday: boolean;
  rsFriday: boolean;
  rsSaturday: boolean;
  rsSunday: boolean;
  osMonday: boolean;
  osTuesday: boolean;
  osWednesday: boolean;
  osThursday: boolean;
  osFriday: boolean;
  osSaturday: boolean;
  osSunday: boolean;
  email: boolean;
  push: boolean;
  check1: boolean;
  check2: boolean;
  check3: boolean;
  check4: boolean;
  check5: boolean;
  accountid: number;
  regdate: Date;
}

export interface GetSystemUsersAccont {
  accountid: number | undefined;
}
export interface GetSystemUsers {
  code: number;
  errorMessage: string;
  data: GetSystemUsersData[];
}

export interface GetSystemUsersData {
  id:                 number;
  firstname:          string;
  lastname:           string;
  password:           string;
  email:              string;
  jobtitle:           string;
  industry:           string;
  company:            string;
  website:            string;
  numberofemployees:  string;
  phone:              string;
  aboutus:            string;
  rpmonitoring:       boolean;
  internaltracking:   boolean;
  chinakolanalytics:  boolean;
  events:             boolean;
  samples:            boolean;
  digitalshowroom:    boolean;
  brandbenchmark:     boolean;
  productionservices: boolean;
  contacts:           boolean;
  iwant:              boolean;
  type:               number;
  regdate:            Date;
  isactivity:         boolean;
  parentid:           number;
  barcodeprefix:      string;
  addressid:          number;
  careof:             string;
  country:            string;
  address1:           string;
  otheremail:         string;
  address2:           string;
  generalphone:       string;
  mobile1:            string;
  mobile2:            string;
  city:               string;
  state:              string;
  zipcode:            string;
  contactsid:         number;
  accountid:          number;
  description:        string;
}

export interface GetCurrancyAccont {
  accountid: number | undefined;
}

export interface GetCurrancyList {
  Code: number;
  ErrorMessage: string;
  data: GetCurrancyListData[];
}

export interface GetCurrancyListData {
  id: number;
  description: string;
  accountid: number;
}

export interface PrcCurrancylist {
  Code: number;
  ErrorMessage: string;
  data: number;
}

export interface GettodAccont {
  accountid: number | undefined;
}

export interface GettodList {
  Code: number;
  ErrorMessage: string;
  data: GettodListData[];
}

export interface GettodListData {
  id: number;
  description: string;
  accountid: number;
}

export interface Prctodlist {
  Code: number;
  ErrorMessage: string;
  data: number;
}

export interface GetpeAccont {
  accountid: number | undefined;
}

export interface GetLookAccount {
  accountid: number | undefined;
  designer: string;
  collection: string;
  season: string;
  gender: string;
  type: string;
  color: string;
  size: string;
  fabric: string;
}

export interface GetLookListAccount {
  accountid: number | undefined;
  lookid: number | undefined;
}

export interface GetLookNameList {
  code:         number;
  errorMessage: string;
  data:         GetLookNameListData[];
}

export interface GetLookNameListData {
  id:          number;
  description: string;
}

export interface GetLookList {
  code:         number;
  errorMessage: string;
  data:         GetLookListData[];
}

export interface GetLookListData {
  id:                   number;
  description:          string;
  accountid:            number;
  isactivity:           boolean;
  operdate:             Date;
  selectedsampleid:     number;
  inventoryStatus:      string;
  gender:               string;
  sampleWorkflowStatus: string;
  region:               string;
  notes:                string;
  designer:             string;
  fabric:               string;
  manufacturer:         string;
  collection:           string;
  style:                string;
  looksAssociated:      string;
  season:               string;
  misc:                 string;
  quantity:             number;
  type:                 string;
  size:                 string;
  sizeRange:            string;
  pattern:              string;
  color:                string;
  colorcode:            string;
  retailPriceEUR:       number;
  retailPriceUSD:       number;
  wholesaleEUR:         number;
  wholesaleUSD:         number;
  salePriceEUR:         number;
  salePriceUSD:         number;
  pricesid:             number;
  sampleDescription:    string;
  storageid:            number;
  regdate:              Date;
  curdate:              Date;
  userid:               number;
  isactiviti:           boolean;
  imageLink:            string;
  barcode:              string;
  user:                 string;
  barcodeprefix:        string;
}


export interface GetpeList {
  Code: number;
  ErrorMessage: string;
  data: GetpeListData[];
}

export interface GetpeListData {
  id: number;
  description: string;
  accountid: number;
}

export interface Prcpelist {
  Code: number;
  ErrorMessage: string;
  data: number;
}

export interface GetissueAccont {
  accountid: number | undefined;
}

export interface GetissueList {
  Code: number;
  ErrorMessage: string;
  data: GetissueListData[];
}

export interface GetissueListData {
  id: number;
  description: string;
  accountid: number;
}

export interface Prcissuelist {
  Code: number;
  ErrorMessage: string;
  data: number;
}

export interface GetCompanyAccont {
  accountid: number | undefined;
}
export interface GetCompanyList {
  code: number;
  errorMessage: string;
  data: GetCompanyListData[];
}

export interface GetCompanyListData {
  id: number;
  companyname: string;
  phone: string;
  publicationcompany: string;
  website: string;
  email: string;
  typeid: number;
  einvoicingcode: string;
  marker: string;
  taxid: string;
  manufacturecode: string;
  notes: string;
  instagram: string;
  facebook: string;
  tiktok: string;
  youtube: string;
  twitter: string;
  linkedin: string;
  accountid: number;
  userid: number;
  regdate: Date;
  isactivity: boolean;
  addressid: number;
  careof: string;
  country: string;
  address1: string;
  addresemail: string;
  otheremail: string;
  address2: string;
  addressphone: string;
  generalphone: string;
  mobile1: string;
  mobile2: string;
  city: string;
  state: string;
  zipcode: string;
  contactsid: number;
  description: string;
}

export interface GetScannerCount {
  code:         number;
  errorMessage: string;
  data:         GetScannerCountData[];
}

export interface GetScannerCountData {
  scannedcount: number;
}

export interface scannedlistaccount {
  accountid: number | undefined;
}

export interface deletescanlistaccount {
  ctrl:      number | undefined;
  userid:    number | undefined;
  accountid: number | undefined;
  barcode:   string | undefined;
}

export interface GetScannedList {
  code:         number;
  errorMessage: string;
  data:         GetScannedListData[];
}

export interface GetSelectedScannedListData {
  userid:        number;
  accountid:     number;
  barcode:       string;
}

export interface GetScannedListData {
  ID:                       number;
  "Inventory Status":       string;
  Gender:                   string;
  "Sample Workflow Status": string;
  accountid:                number;
  Region:                   string;
  Notes:                    string;
  Designer:                 string;
  Fabric:                   string;
  Manufacturer:             string;
  Collection:               string;
  Style:                    string;
  "Looks Associated":       string;
  Season:                   string;
  Misc:                     string;
  Quantity:                 number;
  Type:                     string;
  Size:                     string;
  "Size Range":             string;
  Pattern:                  string;
  Color:                    string;
  colorcode:                string;
  "Retail Price EUR":       number;
  "Retail Price USD":       number;
  "Wholesale EUR":          number;
  "Wholesale USD":          number;
  "Sale Price EUR":         number;
  "Sale Price USD":         number;
  pricesid:                 number;
  Description:              string;
  storageid:                number;
  regdate:                  Date;
  curdate:                  Date;
  userid:                   number;
  isactiviti:               boolean;
  "Image Link":             string;
  barcode:                  string;
  user:                     string;
  barcodeprefix:            string;
  selectedsampleid:         number;
  operid:                   number;
  itemcount:                number;
  itemstatus:               number;
  isaccepted:               boolean;
}

export interface socialmediaaccount {
  contactid: number | undefined;
}

export interface smediaaccount {
  id: number | undefined;
}

export interface GetSocialMediaList {
  Code:         number;
  ErrorMessage: string;
  data:         GetSocialMediaListData[];
}

export interface GetSocialMediaListData {
  link:        string;
  platform:    string;
  name:        string;
  subscribers: number;
  avatarLink:  string;
}

export interface samplehistory {
  Code: number;
  ErrorMessage: string;
  data: samplehistoryData[];
}

export interface samplehistoryData {
  [key: string]: any; // or unknown
}

export interface setgridfields {
  Code: number;
  ErrorMessage: string;
  data: setgridfieldsData[];
}

export interface setgridfieldsData {
  [key: string]: any; // or unknown
}

export interface setcustomergroups {
  Code: number;
  ErrorMessage: string;
  data: setcustomergroupsData[];
}

export interface setcustomergroupsData {
  [key: string]: any; // or unknown
}

export interface setcomponentfields {
  Code: number;
  ErrorMessage: string;
  data: setcomponentfieldsData[];
}

export interface setcomponentfieldsData {
  [key: string]: any; // or unknown
}

export interface settaxefields {
  Code: number;
  ErrorMessage: string;
  data: settaxefieldsData[];
}

export interface settaxefieldsData {
  [key: string]: any; // or unknown
}

export interface clientmails {
  Code: number;
  ErrorMessage: string;
  data: clientmailsData[];
}

export interface clientmailsData {
  [key: string]: any; // or unknown
}

export interface maildetailsview {
  Code: number;
  ErrorMessage: string;
  data: maildetailsviewData[];
}

export interface maildetailsviewData {
  [key: string]: any; // or unknown
}

export interface emailaccount {
  Code: number;
  ErrorMessage: string;
  data: emailaccountData[];
}

export interface emailaccountData {
  [key: string]: any; // or unknown
}

export interface GetSendOutList {
  Code:         number;
  ErrorMessage: string;
  data:         GetSendOutListData[];
}

export interface GetSendOutListData {
  id:                       number;
  itemscount:               number;
  "Inventory Status":       string;
  Gender:                   string;
  "Sample Workflow Status": string;
  accountid:                number;
  Region:                   string;
  Notes:                    string;
  Designer:                 string;
  Fabric:                   string;
  Manufacturer:             string;
  Collection:               string;
  Style:                    string;
  "Looks Associated":       string;
  Season:                   string;
  Misc:                     string;
  Quantity:                 number;
  Type:                     string;
  Size:                     string;
  "Size Range":             string;
  Pattern:                  string;
  Color:                    string;
  colorcode:                string;
  "Retail Price EUR":       number;
  "Retail Price USD":       number;
  "Wholesale EUR":          number;
  "Wholesale USD":          number;
  "Sale Price EUR":         number;
  "Sale Price USD":         number;
  pricesid:                 number;
  Description:              string;
  storageid:                number;
  regdate:                  Date;
  curdate:                  Date;
  userid:                   number;
  isactiviti:               boolean;
  "Image Link":             string;
  barcode:                  string;
  user:                     string;
  barcodeprefix:            string;
  selectedsampleid:         number;
  operid:                   number;
  ItemCount:                number;
  sender:                   string;
  pullsheet:                number;
  contactid:                number;
  celebriti:                string;
  sendoutid:                number;
  completed:                boolean;
  shipdate:                 Date;
  shipdatecheck:            boolean;
}

export interface GetRequestList {
  Code:         number;
  ErrorMessage: string;
  data:         GetRequestListData[];
}

export interface GetRequestListData {
  id:                       number;
  "Inventory Status":       string;
  Gender:                   string;
  "Sample Workflow Status": string;
  accountid:                number;
  Region:                   string;
  Notes:                    string;
  Designer:                 string;
  Fabric:                   string;
  Manufacturer:             string;
  Collection:               string;
  Style:                    string;
  "Looks Associated":       string;
  Season:                   string;
  Misc:                     string;
  Quantity:                 number;
  Type:                     string;
  Size:                     string;
  "Size Range":             string;
  Pattern:                  string;
  Color:                    string;
  colorcode:                string;
  "Retail Price EUR":       number;
  "Retail Price USD":       number;
  "Wholesale EUR":          number;
  "Wholesale USD":          number;
  "Sale Price EUR":         number;
  "Sale Price USD":         number;
  pricesid:                 number;
  Description:              string;
  storageid:                number;
  regdate:                  Date;
  curdate:                  Date;
  userid:                   number;
  isactiviti:               boolean;
  "Image Link":             string;
  barcode:                  string;
  user:                     string;
  barcodeprefix:            string;
  selectedsampleid:         number;
  operid:                   number;
  ItemCount:                number;
  sender:                   string;
  pullsheet:                number;
  contactid:                number;
  celebriti:                string;
  sendoutid:                number;
  itemstatus:               number;
  isaccepted:               boolean;
}

export interface GetLateList {
  Code:         number;
  ErrorMessage: string;
  data:         GetLateListData[];
}

export interface GetLateListData {
  id:                       number;
  "Inventory Status":       string;
  Gender:                   string;
  "Sample Workflow Status": string;
  accountid:                number;
  Region:                   string;
  Notes:                    string;
  Designer:                 string;
  Fabric:                   string;
  Manufacturer:             string;
  Collection:               string;
  Style:                    string;
  "Looks Associated":       string;
  Season:                   string;
  Misc:                     string;
  Quantity:                 number;
  Type:                     string;
  Size:                     string;
  "Size Range":             string;
  Pattern:                  string;
  Color:                    string;
  colorcode:                string;
  "Retail Price EUR":       number;
  "Retail Price USD":       number;
  "Wholesale EUR":          number;
  "Wholesale USD":          number;
  "Sale Price EUR":         number;
  "Sale Price USD":         number;
  pricesid:                 number;
  Description:              string;
  storageid:                number;
  regdate:                  Date;
  curdate:                  Date;
  userid:                   number;
  isactiviti:               boolean;
  "Image Link":             string;
  barcode:                  string;
  user:                     string;
  barcodeprefix:            string;
  selectedsampleid:         number;
  operid:                   number;
  ItemCount:                number;
  sender:                   string;
  pullsheet:                number;
  contactid:                number;
  celebriti:                string;
  sendoutid:                number;
}

export interface GetSendOutReturnList {
  Code:         number;
  ErrorMessage: string;
  data:         GetSendOutReturnListData[];
}

export interface GetSendOutReturnListData {
  ID:                       number;
  "Inventory Status":       string;
  Gender:                   string;
  "Sample Workflow Status": string;
  accountid:                number;
  Region:                   string;
  Notes:                    string;
  Designer:                 string;
  Fabric:                   string;
  Manufacturer:             string;
  Collection:               string;
  Style:                    string;
  "Looks Associated":       string;
  Season:                   string;
  Misc:                     string;
  Quantity:                 number;
  Type:                     string;
  Size:                     string;
  "Size Range":             string;
  Pattern:                  string;
  Color:                    string;
  colorcode:                string;
  "Retail Price EUR":       number;
  "Retail Price USD":       number;
  "Wholesale EUR":          number;
  "Wholesale USD":          number;
  "Sale Price EUR":         number;
  "Sale Price USD":         number;
  pricesid:                 number;
  Description:              string;
  storageid:                number;
  regdate:                  Date;
  curdate:                  Date;
  userid:                   number;
  isactiviti:               boolean;
  "Image Link":             string;
  barcode:                  string;
  user:                     string;
  barcodeprefix:            string;
  selectedsampleid:         number;
  sendOutType:              number;
  comment:                  string;
  samplestatus:             string;
  sendoutid:                number;
  amount:                   number;
  discount:                 number;
  totalprice:               number;
  currancy:                 string;
}

export interface PrcPstemplateInput {
  ctrl: number;
  prmanager: string;
  prphone: string;
  premail: string;
  asistant: string;
  asiphone: string;
  asiemail: string;
  latefees: string;
  cleaning: string;
  minor: string;
  lost: string;
  missing: string;
  instagram: string;
  id: number;
  accountid: number;
  logoname: string;
  deposite: string;
  facebook: string;
  tiktok: string;
  twitter: string;
  website: string;
  onlinestore: string;
}

export interface PrcPstemplateOutput {
  Code: number;
  ErrorMessage: string;
  data: any; // Define a specific type if you know the structure of the output
}


export interface PrcContactemail {
  id: number;
  email: string
}

export interface PrcContactemailOutput {
  Code: number;
  ErrorMessage: string;
  data: any; // Define a specific type if you know the structure of the output
}

export interface PrcSetup {
  ctrl: number;
  id?: number;
  accountid?: number;
  fromname?: string;
  frommail?: string;
  replyto?: string;
  subject?: string;
  notes?: string;
  isactivity?: boolean;
}

export interface PrcSetupOutput {
  Code: number;
  ErrorMessage: string;
  data: any; // Define a specific type if you know the structure of the output
}

export interface PrcContEmails {
  ctrl: number;
  id?: number;
  Category?: string;
  ContactName?: string;
  HasEmail?: number;
  JobDepartment?: string;
  JobTitle?: string;
  PhoneNumber?: string;
  accountid?: number;
  email?: string;
  deliveristatus?: number;
  openstatus?: number;
  tblsetupid?: number;
}

export interface PrcContEmailsOutput {
  Code: number;
  ErrorMessage: string;
  data: any; // Define a specific type if you know the structure of the output
}

export interface PrcPullsheetAcceptedInput {
  id: number;
  pullsheetid: number;
  dateofpull: Date | null;
  dateodfitting: Date | null;
  dateofse: Date | null;
  dateoder: Date | null;
  dateoffr: Date | null;
  gb: string;
  tb: string;
  stylistname: string;
  publication: string;
  confirms: string;
  phonenumber: string;
  email: string;
  cc: string;
  expdate: Date | null;
  securitycode: string;
  bilingzipcode: string;
  printname: string;
  ctrl: number;
  key: string;
}

export interface PrcSaleGeneralInput {
  storename: string;
  defaultcurrency: string;
  timezone: string;
  defaultsalestax: string;
  taxrate: number;
  taxexclusivedisplayprices: boolean;
  imregisteredforvat: boolean;
  vataccountnumber: string;
  generateskusbynumber: boolean;
  currentsequencenumber: string;
  usersettings1: boolean;
  usersettings2: boolean;
  usersettings3: boolean;
  usersettings4: boolean;
  automaticallyaddcustomers: boolean;
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  website: string;
  instagram: string;
  facebook: string;
  tiktok: string;
  twitter: string;
  youtube: string;
  street: string;
  street1: string;
  suburb: string;
  city: string;
  zipcode: string;
  state: string;
  country: string;
  pstreet: string;
  pstreet1: string;
  psuburb: string;
  pcity: string;
  pzipcode: string;
  pstate: string;
  pcountry: string;
  accountid: number;
  userid: number;
  usedifferent: boolean;
}

export interface PrcPullsheetAcceptedOutput {
  // Define the fields for output here, e.g.,
  Code: number;
  ErrorMessage: string;
  data: any; // Replace 'any' with a more specific type if possible.
}

export interface PrcSaleGeneralOutput {
  // Define the fields for output here, e.g.,
  Code: number;
  ErrorMessage: string;
  data: any; // Replace 'any' with a more specific type if possible.
}

export interface PullSheetDetails {
  email: string;
  subject: string;
  message: string;
  key: string;
  accountid: number;
  userid: number;
  sendoutid: number;
  status: number;
  isactivity: boolean;
  ctrl: number;
}

export interface RequestListDetails {
  email: string;
  subject: string;
  message: string;
  key: string;
  accountid: number;
  userid: number;
  sendoutid: number;
  status: number;
  isactivity: boolean;
  ctrl: number;
  from: string;
}

export interface lbsentDetails {
  email: string;
  phone: string;
  subject: string;
  message: string;
  key: string;
  accountid: number;
  userid: number;
  lbmainid: number;
  from: string;
  isactivity: boolean;
  ctrl: number;
  id: number;
  name: string;
}

export interface SmsDetails {
  to: string;
  from: string;
  text: string;
  key: string;
  accountid: number;
  userid: number;
  sendoutid: number;
  status: number;
  isactivity: boolean;
  ctrl: number;
}

export interface PullSheetData {
  Code: number;
  ErrorMessage: string;
  data: number;
}

export interface lbsentData {
  Code: number;
  ErrorMessage: string;
  data: number;
}

export interface GetSendOutSamples {
  Code:         number;
  ErrorMessage: string;
  data:         GetSendOutSamplesData[];
}

export interface GetSendOutSamplesData {
  id:           number;
  sampleid:     number;
  accountid:    number;
  userid:       number;
  sendoutid:    number;
  itemcount:    number | null;
  Collection:   string;
  Season:       string;
  Designer:     string;
  Type:         string;
  Color:        string;
  "Image Link": string;
}

export interface sendoutsampleid {
  sendoutid: number;
}

export interface GetQRResult {
  Code:         number;
  ErrorMessage: string;
  data:         GetQRResultData[];
}

export interface GetQRResultData {
  id:           number;
  imagelink:    string;
  instagram:    string;
  facebook:     string;
  tiktok:       string;
  twitter:      string;
  website:      string;
  onlintestore: string;
}

export interface qraccount {
  accountid: number | undefined;
  sampleid: number | undefined;
}

export interface GetCelebritiList {
  Code:         number;
  ErrorMessage: string;
  data:         GetCelebritiListData[];
}

export interface GetCelebritiListData {
  id:          number;
  description: string;
  contactid:   number;
  accountid:   number;
  isactivity:  boolean;
  regdate:     Date;
  smid:        number;
}

export interface celebritiaaccount {
  contactid: number | undefined;
}

export interface GetSMList {
  Code:         number;
  ErrorMessage: string;
  data:         GetSMListData[];
}

export interface GetSMListData {
  id:           number;
  sm_instagram: string;
  sm_tiktok:    string;
  sm_twitter:   string;
  sm_facebook:  string;
  sm_youtube:   string;
  celebritiid:  number;
  regdate:      Date;
}

export interface smaccount {
  celebritiid: number | undefined;
}

export interface LbCatalogDetails {
  id: number;
  description: string;
  accountid: number;
  designerid: number;
  seasonid: number;
  isactivity: boolean;
  ctrl: number;
}

export interface LbTempCatalogDetails {
  id: number;
  description: string;
  accountid: number;
  designerid: number;
  seasonid: number;
  isactivity: boolean;
  ctrl: number;
  lbmainid: number;
}

export interface LbCatalogData {
  Code: number;
  ErrorMessage: string;
  data: any;
}

export interface FavoritesData {
  email: string;
  phone: string;
  designerid: number;
  ctrl: number;
  key: string;
}

export interface FavoritesValue {
  Code: number;
  ErrorMessage: string;
  data: any;
}

export interface CdlData {
  ctrl: number;
  id: number;
  description: string;
}

// Define the CdlResponse type for the expected response from the /cdl endpoint
export interface CdlResponse {
  Code: number;
  ErrorMessage: string;
  data: any; // You can specify the actual type of the data property
}

export interface LbSelectedDesigner {
    Code: number;
    ErrorMessage: string;
    data: any;
}

export interface ProfileSaleDetails {
  ctrl: number;
  id?: number;
  description: string;
  isactivity?: boolean | number;
  accountid: number;
}

export interface ProfileSale {
  Code: number;
  ErrorMessage: string;
  data: number;
}

export interface PermissionsSaleDetails {
  ctrl: number;
  id?: number;
  tbl_profile_saleid: number;
  home?: boolean | number;
  sales?: boolean | number;
  sell?: boolean | number;
  credit?: boolean | number;
  rent?: boolean | number;
  gift?: boolean | number;
  catalog?: boolean | number;
  products?: boolean | number;
  suppliers?: boolean | number;
  inventory?: boolean | number;
  stock_control?: boolean | number;
  customers?: boolean | number;
  reporting?: boolean | number;
  sales_report?: boolean | number;
  settings?: boolean | number;
  users?: boolean | number;
  general?: boolean | number;
  outlets_and_registers?: boolean | number;
  precious_metals_prices?: boolean | number;
}

export interface PermissionsSale {
  Code: number;
  ErrorMessage: string;
  data: number;
}

export interface DesignerAccountDetails {
    designer: string;
    designerid: number;
    accountid: number;
    lbmainid: number;
    ctrl: number;
}

export interface LbMainDetails {
  id: number;
  description: string;
  fields: string;
  accountid: number;
  isactivity: boolean;
  ctrl: number;
  lbtype: number;
}

export interface LbSendAccept {
  lbmainid: number;
  key: string;
}

export interface LbSendAcceptData {
  Code: number;
  ErrorMessage: string;
  data: any;
}

export interface LbMainData {
  Code: number;
  ErrorMessage: string;
  data: any;
}

export interface GetlbCatalogs {
  Code:         number;
  ErrorMessage: string;
  data:         GetlbCatalogsData[];
}

export interface lbCatalogsAccount {
  accountid: number | undefined;
}

export interface lbCatalogsAccount1 {
    accountid: number | undefined;
    lbmainid: number | undefined;
}

export interface GetlbCatalogsData {
  id:         number;
  collection: string;
  accountid:  number;
  isactivity: boolean;
  operdate:   Date;
  desingerid: number;
  seasonid:   number;
  seasoncode: string;
  seasonyear: string;
  season:     string;
  designer:   string;
  catalogid:  number;
}

export interface GetLBMain {
  Code:         number;
  ErrorMessage: string;
  data:         GetLBMainData[];
}

export interface GetLBMainData {
  id:          number;
  description: string;
  fields:      string;
  accountid:   number;
  isactivity:  boolean;
  operdate:    Date;
  lbtype:      string;
}

export interface Getlbdesignerlist {
  Code:         number;
  ErrorMessage: string;
  data:         GetlbdesignerlistData[];
}

export interface GetlbdesignerlistData {
  designer:   string;
  desingerid: number;
}

export interface lbSeasonAccount {
  accountid: number | undefined;
  desingerid: number | undefined;
}

export interface lbSelectedSeasonAccount {
  accountid: number | undefined;
    lbmainid: number | undefined;
}

export interface Getlbseasonlist {
  Code:         number;
  ErrorMessage: string;
  data:         GetlbseasonlistData[];
}

export interface GetlbseasonlistData {
  id: number
  description: string;
  seasoncode:  string;
  seasonyear:  number;
  accountid: number;
  desingerid: number;
  catalogid: number;
}

export interface GetlbSentList {
  Code:         number;
  ErrorMessage: string;
  data:         GetlbSentListData[];
}

export interface GetCheckVisibled {
  Code:         number;
  ErrorMessage: string;
  data:         GetCheckVisibledData[];
}

export interface GetCheckVisibledData {
  visibled:        number;
  visibledcount:   number;
}

export interface LbSentData {
  Code: number;
  ErrorMessage: string;
  data: any;
}

export interface LbSentDetails {
  email: string;
  phone: string;
  key: string;
  accountid: number;
  userid: number;
  lbmainid: number;
  isactivity: boolean;
  ctrl: number;
  id: number;
  name: string;
}

export interface GetlbSentListData {
  id:          number;
  email:       string;
  phone:       string;
  accountid:   number;
  userid:      number;
  lbmainid:    number;
  isactivity:  boolean;
  operdate:    Date;
  description: string;
  user:        string;
  accepteddate: Date;
  accepted:   number;
  name:       string;
  visibled:   boolean;
  key:        string;
}

export interface GetCustomImages {
  Code:         number;
  ErrorMessage: string;
  data:         GetCustomImagesData[];
}

export interface ciAccount {
  accountid: number | undefined;
  designerid: number | undefined;
}

export interface GetCustomImagesData {
  accountid:   number;
  userid:      number;
  filename:    string;
  filedir:     string;
  operdate:    Date;
  isactivity:  boolean;
  lbcatalogid: number;
  colection:   string;
  designer:    string;
  catalog:     string;
  catalogyear: number;
  seasoncode:  string;
}

export interface lbSentAccount {
  key: string | undefined;
}

export interface latesendoutAccount {
  key: string | undefined;
}

export interface GetLBSents {
  Code:         number;
  ErrorMessage: string;
  data:         GetLBSentsData[];
}

export interface GetLBSentsData {
  id:         number;
  email:      string;
  phone:      string;
  accountid:  number;
  userid:     number;
  lbmainid:   number;
  isactivity: boolean;
  operdate:   Date;
  key:        string;
  sendoutid:  number;
}

export interface GetLateSendOut {
  Code:         number;
  ErrorMessage: string;
  data:         GetLateSendOutData[];
}

export interface GetLateSendOutData {
  id:         number;
  email:      string;
  phone:      string;
  accountid:  number;
  userid:     number;
  lbmainid:   number;
  isactivity: boolean;
  operdate:   Date;
  key:        string;
  sendoutid:  number;
}

export interface lbMainListAccount {
  id: number | undefined;
}

export interface GetLBMainList {
  Code:         number;
  ErrorMessage: string;
  data:         GetLBMainListData[];
}

export interface GetLBMainListData {
  id:          number;
  description: string;
  fields:      string;
  accountid:   number;
  isactivity:  boolean;
  operdate:    Date;
  lbtype:      number;
}

export interface PrcHistoryList {
  Code: number;
  ErrorMessage: string;
  data: number;
}

export interface SetHistoryList {
  Code: number;
  ErrorMessage: string;
  data: HistoryRecord[];
}

export interface HistoryRecord {
  id: number;
  userid: number;
  formtype: number;
  sender: string;
  recipient: string;
  returnpoint: string;
  sendouttype: string;
  currency: string;
  reservationdate: string; // Use Date if you plan to parse it
  expreturndate: string; // Use Date if you plan to parse it
  requestedby: string;
}

export interface TagsList {
  Code: number;
  ErrorMessage: string;
  data: TagsListData[];
}

export interface TagsListData {
  [key: string]: any; // or unknown
}

export interface lbDesignerstAccount {
  lbmainid: number | undefined;
}

export interface GetLBDesigners {
  Code:         number;
  ErrorMessage: string;
  data:         GetLBDesignersData[];
}

export interface GetLBDesignersData {
  id:         number;
  designer:   string;
  designerid: number;
  accountid:  number;
  lbmainid:   number;
  catalogcount:   number;
}

export interface lbCollectionsAccount {
  desingerid: number | undefined;
  lbmainid: number | undefined;
}

export interface GetLBCollections {
  Code:         number;
  ErrorMessage: string;
  data:         GetLBCollectionsData[];
}

export interface GetLBCollectionsData {
  id:          number;
  description: string;
  seasoncode:  string;
  seasonyear:  number;
  accountid:   number;
  desingerid:  number;
  catalogid:   number;
  lbmainid:    number;
  imagelink:   string;
  imagecount:   number;
}

export interface linkedImagesAccounts {
  accountid: number | undefined;
}

export interface GetLinkedImages {
  Code:         number;
  ErrorMessage: string;
  data:         GetLinkedImagesData[];
}

export interface GetLinkedImagesData {
  id:         number;
  imagelink:  string;
  filetype:   number;
  accountid:  number;
  userid:     number;
  sampleid:   number;
  regdate:    Date;
  isactiviti: boolean;
  filename:   string;
  barcode:    string;
}

export interface lbAcceptsAccount {
  lbmainid: number | undefined;
  key: string | undefined;
}

export interface GetLBAccepts {
  Code:         number;
  ErrorMessage: string;
  data:         GetLBAcceptsData[];
}

export interface GetLBAcceptsData {
  id:          number;
  lbmainid:    number;
  filename:    string;
  filedir:     string;
  operdate:    Date;
  isactivity:  boolean;
  lbcatalogid: number;
  colection:   string;
  designer:    string;
  catalog:     string;
  catalogyear: number;
  description: string;
}


export interface PrcLbAcceptedRequest {
  lbmainid?: number;
  accounid?: number;
  userid?: number;
  filename?: string;
  filedir?: string;
  lbcatalogid?: number;
  colection?: string;
  designer?: string;
  catalog?: string;
  catalogyear?: number;
  ctrl?: number;
  key?: string;
}

export interface PrcLbAcceptedResponse {
  // Define the structure of your response here
  Code: number;
  ErrorMessage: string;
  data: any; // Replace 'any' with the appropriate type based on your backend response
}

export interface OutletSettingsDetails {
  id?: number;
  street1?: string;
  street2?: string;
  suburb?: string;
  city?: string;
  zipCode?: string;
  state?: string;
  country?: string;
  timeZone?: string;
  outletName?: string;
  registers?: string;
  paymentTypeCash?: string;
  paymentTypeOther?: string;
  paymentTypeCashIndex?: number;
  paymentTypeOtherIndex?: number;
  onAccountBalance?: string;
  balanceLimit?: number;
  loyaltyEarnedPerSale?: number;
  isActivity?: boolean;
  ctrl: number;
  accountid: number;
}

export interface OutletSettingsResponse {
  Code: number;
  ErrorMessage: string;
  data: any;
}

export interface CustomerDetails {
  id?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  customerGroup?: string;
  optInMarketing?: boolean;
  mailingStreetAddress?: string;
  mailingStreetAddress2?: string;
  mailingSuburb?: string;
  mailingCity?: string;
  mailingZipCode?: string;
  mailingState?: string;
  mailingCountry?: string;
  postalStreetAddress?: string;
  postalStreetAddress2?: string;
  postalSuburb?: string;
  postalCity?: string;
  postalZipCode?: string;
  postalState?: string;
  postalCountry?: string;
  customerCode?: string;
  companyName?: string;
  dateOfBirth?: any;
  gender?: string;
  website?: string;
  instagram?: string;
  facebook?: string;
  tiktok?: string;
  twitter?: string;
  youtube?: string;
  notes?: string;
  allowOnAccountBalance?: string;
  balanceLimit?: number;
  taxRate?: string;
  loyaltyEnabled?: boolean;
  customField1?: string;
  customField2?: string;
  customField3?: string;
  customField4?: string;
  ctrl: number;
  accountid: number;
  usedifferent: boolean
  discount: number
  wholesale: boolean
}

export interface CustomerResponse {
  Code: number;
  ErrorMessage: string;
  data: any;
}

export interface OrderDetails {
  id?: number;
  outlet: number;
  openclose: number;
  customer: string;
  customerid: number;
  subtotalofproducts: number;
  totaldiscount: number;
  shipingcost: number;
  salestax: number;
  saletaxdisplay: string;
  ordertotal: number;
  paymenttype: number;
  paymentdisplay: string;
  change: number;
  note?: string;
  accountid: number;
  isactivity: boolean;
  paymentstatus: number;
  ctrl: number;
}

export interface OrderResponse {
  Code: number;
  ErrorMessage: string;
  data: any; // Можешь заменить 'any' на более конкретный тип, если знаешь, что API возвращает
}

export interface originalimagesaccount {
  accountid: number;
  sampleid: number;
}

export interface GetOriginalImages {
  Code:         number;
  ErrorMessage: string;
  data:         GetOriginalImagesData[];
}

export interface GetOriginalImagesData {
  id:         number;
  imagelink:  string;
  filetype:   number;
  accountid:  number;
  userid:     number;
  sampleid:   number;
  regdate:    Date;
  isactiviti: boolean;
  filename:   string;
}

export interface ccaaccount {
  filedir: string | undefined;
}

export interface GetCCList {
  Code:         number;
  ErrorMessage: string;
  data:         GetCCListData[];
}

export interface GetCCListData {
  id:          number;
  lbmainid:    number;
  filename:    string;
  filedir:     string;
  lbcatalogid: number;
  colection:   string;
  designer:    string;
  catalog:     string;
  catalogyear: number;
  key:         string;
  isaccepted:  boolean;
  operdate:    Date;
  email:       string;
  name:        string;
  phone:       string;
}

export interface GetUserFilterList {
  Code:         number;
  ErrorMessage: string;
  data:         GetUserFilterListData[];
}

export interface collectionsParams {
  id: number | undefined;
  accountid: number | undefined;
}

export interface GetCollectionValues {
  Code:         number;
  ErrorMessage: string;
  data:         GetCollectionValuesData[];
}

export interface GetCollectionValuesData {
  id:          number;
  description: string;
  accountid:   number;
  isactivity:  boolean;
  operdate:    Date;
  desinger:    string;
  desingerid:  number;
  season:      string;
  seasonid:    number;
}

export interface GetUserFilterListData {
  UserID:                          number;
  OperDate:                        Date;
  SelectCheckboxCollection:        boolean;
  SelectCheckboxDepartment:        boolean;
  SelectCheckboxDesigner:          boolean;
  SelectCheckboxSeason:            boolean;
  SelectCheckboxShipDate:          boolean;
  SelectCheckboxColor:             boolean;
  SelectCheckboxCountryOfOrigin:   boolean;
  SelectCheckboxEditorStylist:     boolean;
  SelectCheckboxEvent:             boolean;
  SelectCheckboxFabric:            boolean;
  SelectCheckboxGender:            boolean;
  SelectCheckboxIncludeStaffLoans: boolean;
  SelectCheckboxManufacturer:      boolean;
  SelectCheckboxMethodOfDispatch:  boolean;
  SelectCheckboxMisc:              boolean;
  SelectCheckboxModelCeleb:        boolean;
  SelectCheckboxPattern:           boolean;
  SelectCheckboxPhotographer:      boolean;
  SelectCheckboxPublication:       boolean;
  SelectCheckboxRecipient:         boolean;
  SelectCheckboxRegion:            boolean;
  SelectCheckboxRequestedBy:       boolean;
  SelectCheckboxSender:            boolean;
  SelectCheckboxShootLocation:     boolean;
  SelectCheckboxSize:              boolean;
  SelectCheckboxSizeRange:         boolean;
  SelectCheckboxStoryTheme:        boolean;
  SelectCheckboxStyleNumber:       boolean;
  SelectCheckboxType:              boolean;
  SelectSampleStatus:              boolean;
}

export function prefixZeros(value: number): string {
  // Ensure the value is between 1 and 999999999
  if (value < 1 || value > 9999999999) {
    throw new Error('Value must be between 1 and 999999999');
  }

  // Convert the number to a string
  const valueStr = value.toString();

  // Calculate the number of zeros needed
  const zerosNeeded = 10 - valueStr.length;

  // Create a string with the necessary zeros
  const zeroPrefix = '0'.repeat(zerosNeeded);

  // Return the zero-prefixed string
  return zeroPrefix + valueStr;
}

export function formatDate(date: Date): string {
  const day = String(date.getDate()).padStart(2, '0');
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const month = monthNames[date.getMonth()];
  const year = String(date.getFullYear()).slice(2);  // Get the last two digits of the year
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${day}/${month}/${year} ${hours}:${minutes}`;
}

export function transformImageUrl(url: string): string {
  if (servermode.server) {
    if (url && url.startsWith('http://108.178.189.70:6330/')) {
      // console.log("url", url)
      // console.log("return",  url.replace('http://108.178.189.70:6330/', '/api/local/'))
      return url.replace('http://108.178.189.70:6330/', '/api/local/');
    }
  }
  return url;
}

export class servermode {
  static server: boolean | null = false;
}
