import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder, FormControl} from "@angular/forms";
import {HashService} from "../../services/hash.service";
import {MessageboxComponent} from "../../modals/messagebox/messagebox.component";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MessageService} from "primeng/api";
import {SelfData, SendOut1Data} from "../../models/struct";

@Component({
  selector: 'app-sendoutstep3',
  templateUrl: './sendoutstep3.component.html',
  styleUrls: ['./sendoutstep3.component.scss']
})
export class Sendoutstep3Component implements OnInit, AfterViewInit, OnDestroy {
  public value1?: String;
  public value2?: String;
  public value3?: String;
  public value4?: String;
  editmode: boolean = false;
  qrcodedata: string = '';
  trackingNumberControl = new FormControl<string>('');
  buttonenabled: boolean = false
  curdata: any = []
  constructor(
      public modalService: ModalserviceService,
      public fb: FormBuilder,
      private serviceservice: ServiceService,
      private yourappsService: YourappsserviceService,
      private _snackBar: MatSnackBar,
      private messageService: MessageService,
      private cdr: ChangeDetectorRef
  ) {

  }
  ngAfterViewInit(): void {
    console.log("SelfData.selectadpage", SelfData.selectadpage)
  }

  ngOnDestroy(): void {
    if (SelfData.saveonclose && this.editmode) {
      let actrl: number = 0
      let aid: number = 0
      if (this.editmode) {
        actrl = 1
        aid = SendOut1Data.id
      } else {
        actrl = 0
        aid = 0
      }
      const fields = {
        ctrl: actrl,
        id: aid,
        check1: SendOut1Data.check1 || false,
        check2: SendOut1Data.check2 || false,
        check3: SendOut1Data.check3 || false,
        department: SendOut1Data.departmentFormControl || '',
        ship: SendOut1Data.shipFormControl || '',
        shipto: SendOut1Data.shiptoControl || '',
        tod: SendOut1Data.todControl || '',
        pe: SendOut1Data.peControl || '',
        companyname: SendOut1Data.companynameControl || '',
        addresslabel: SendOut1Data.addresslabelControl || '',
        costofshipping: SendOut1Data.costofshippingControl || '',
        cim: SendOut1Data.cimControl || '',
        publication: SendOut1Data.publicationControl || '',
        estylist: SendOut1Data.estylistControl || '',
        photographer: SendOut1Data.photographerControl || '',
        pel: SendOut1Data.pelControl || '',
        pet: SendOut1Data.petControl || '',
        issue: SendOut1Data.issueControl || '',
        trackingNumber: this.trackingNumberControl.value || '',
        return: SendOut1Data.returnControl || '',
        notes: SendOut1Data.notesControl || '',
        sender: SendOut1Data.senderFormControl || '',
        requestedby: SendOut1Data.requestedbyFormControl || '',
        sendOutType: SendOut1Data.sendOutTypeControl || '',
        currancy: SendOut1Data.currancyControl || '',
        reservationdate: SendOut1Data.reservationdateControl,
        returndate: SendOut1Data.returndateControl,
        datetimeCtrl: SendOut1Data.datetimeCtrl,
        datetimeCtrl1: SendOut1Data.datetimeCtrl1,
        datetimeCtrl2: SendOut1Data.datetimeCtrl2,
        datetimeCtrl3: SendOut1Data.datetimeCtrl3,
        datetimeCtrl4: SendOut1Data.datetimeCtrl4,
        accountid: this.getAccountID(),
        userid: SelfData.account[0].data[0].id,
        sendouttypeid: SelfData.selectedSendouttype, //droebit
        isactivity: true,
        celebriti: SendOut1Data.celebriti || ""
      };
      console.log("SendOut1Data", fields)
      this.serviceservice
        .prcSendOut(fields)
        .subscribe((value) => {
          this.yourappsService.getReloadSendOutList(null, 1)
          SelfData.sendoutid = value.data
        });
    } else {
      this.yourappsService.getReloadSendOutList(null, 1)
    }
    console.log("OnSendOut3 Destr")
  }

  ngOnInit(): void {
    this.yourappsService.setPrintBarcode$.subscribe((value: { data: any, type: number }) => {
       // Check if any value is received
      if (value.data) {
        if (value.type === 1) {
          this.modalService.closePrinterFrame()
          this.cdr.detectChanges()
          console.log("Received value", value);
          SelfData.selectadpage = 3
          //
          setTimeout(() => {
            this.modalService.openPrinterFrame();
          }, 10);
          this.cdr.detectChanges()
          this.yourappsService.getPrintBarcode(null, 0)
        }
      }
    });
    SelfData.saveonclose = true
    this.value1 = SelfData.account[0].data[0].barcodeprefix + "-" + SelfData.sendoutid
    this.value2 = SelfData.account[0].data[0].barcodeprefix + "-" + SelfData.sendoutid
    this.value3 = SelfData.account[0].data[0].barcodeprefix + "-" + SelfData.sendoutid
    this.value4 = SelfData.account[0].data[0].barcodeprefix + "-" + SelfData.sendoutid

    this.yourappsService.currentSendOutData.subscribe((data) => {
      console.log("data", data)
      this.curdata = data
      // if (data.pullsheet === 0) {
      //   this.buttonenabled = false
      // } else {
      //   this.buttonenabled = true
      // }
      if (data != null) {
        this.editmode = true
        SendOut1Data.id = data.id
        SendOut1Data.check1 = data.check1
        SendOut1Data.check2 = data.check2
        SendOut1Data.check3 = data.check3
        SendOut1Data.shipFormControl = data?.["ship"]
        SendOut1Data.shiptoControl = data?.["shipto"]
        SendOut1Data.todControl = data?.["tod"]
        SendOut1Data.peControl = data?.["pe"]
        SendOut1Data.companynameControl = data?.["companyname"]
        SendOut1Data.addresslabelControl = data?.["addresslabel"]
        SendOut1Data.costofshippingControl = data?.["costofshipping"]
        SendOut1Data.cimControl = data?.["cim"]
        SendOut1Data.publicationControl = data?.["publication"]
        SendOut1Data.estylistControl = data?.["estylist"]
        SendOut1Data.photographerControl = data?.["photographer"]
        SendOut1Data.pelControl = data?.["pel"]
        SendOut1Data.petControl = data?.["pet"]
        SendOut1Data.issueControl = data?.["issue"]
        SendOut1Data.trackingNumberControl = data?.["trackingNumber"]
        SendOut1Data.returnControl = data?.["return"]
        SendOut1Data.notesControl = data?.["notes"]
        SendOut1Data.senderFormControl = data?.["sender"]
        SendOut1Data.requestedbyFormControl = data?.["requestedby"]
        SendOut1Data.sendOutTypeControl = data?.["sendOutType"]
        SendOut1Data.currancyControl = data?.["currancy"]
        SendOut1Data.reservationdateControl = data?.["reservationdate"]
        SendOut1Data.returndateControl = data?.["returndate"]
        SendOut1Data.datetimeCtrl = data?.["datetimeCtrl"]
        SendOut1Data.datetimeCtrl1 = data?.["datetimeCtrl1"]
        SendOut1Data.datetimeCtrl2 = data?.["datetimeCtrl2"]
        SendOut1Data.datetimeCtrl3 = data?.["datetimeCtrl3"]

      } else {
        console.log("essssss", SendOut1Data)
        const fields = {
          ctrl: 3,
          id: SelfData.sendoutid,
          check1: SendOut1Data.check1 || false,
          check2: SendOut1Data.check2 || false,
          check3: SendOut1Data.check3 || false,
          department: SendOut1Data.departmentFormControl || '',
          ship: SendOut1Data.shipFormControl || '',
          shipto: SendOut1Data.shiptoControl || '',
          tod: SendOut1Data.todControl || '',
          pe: SendOut1Data.peControl || '',
          companyname: SendOut1Data.companynameControl || '',
          addresslabel: SendOut1Data.addresslabelControl || '',
          costofshipping: SendOut1Data.costofshippingControl || '',
          cim: SendOut1Data.cimControl || '',
          publication: SendOut1Data.publicationControl || '',
          estylist: SendOut1Data.estylistControl || '',
          photographer: SendOut1Data.photographerControl || '',
          pel: SendOut1Data.pelControl || '',
          pet: SendOut1Data.petControl || '',
          issue: SendOut1Data.issueControl || '',
          trackingNumber: SendOut1Data.trackingNumberControl || '',
          return: SendOut1Data.returnControl || '',
          notes: SendOut1Data.notesControl || '',
          sender: SendOut1Data.senderFormControl || '',
          requestedby: SendOut1Data.requestedbyFormControl || '',
          sendOutType: SendOut1Data.sendOutTypeControl || '',
          currancy: SendOut1Data.currancyControl || '',
          reservationdate: SendOut1Data.reservationdateControl,
          returndate: SendOut1Data.returndateControl,
          datetimeCtrl: SendOut1Data.datetimeCtrl,
          datetimeCtrl1: SendOut1Data.datetimeCtrl1,
          datetimeCtrl2: SendOut1Data.datetimeCtrl2,
          datetimeCtrl3: SendOut1Data.datetimeCtrl3,
          datetimeCtrl4: SendOut1Data.datetimeCtrl4,
          accountid: this.getAccountID(),
          userid: SelfData.account[0].data[0].id,
          sendouttypeid: SelfData.selectedSendouttype, //droebit
          isactivity: true,
          celebriti: SendOut1Data.celebriti || ""
        };

        this.serviceservice
          .prcSendOut(fields)
          .subscribe((value) => {
            SelfData.sendoutid = value.data
            console.log("SelfData.sendoutid>>>>>>>>>>>>>>>>>>>>>>>>>>>", SelfData.sendoutid)
            SelfData.saveonclose = true
          });
        this.editmode = false
        // SendOut1Data.check1 = false;
        // SendOut1Data.check2 = false;
        // SendOut1Data.check3 = false;
        // SendOut1Data.departmentFormControl = '';
        // SendOut1Data.shipFormControl = '';
        // SendOut1Data.shiptoControl = '';
        // SendOut1Data.todControl = '';
        // SendOut1Data.peControl = '';
        // SendOut1Data.companynameControl = '';
        // SendOut1Data.addresslabelControl = '';
        // SendOut1Data.costofshippingControl = '';
        // SendOut1Data.cimControl = '';
        // SendOut1Data.publicationControl = '';
        // SendOut1Data.estylistControl = '';
        // SendOut1Data.photographerControl = '';
        // SendOut1Data.pelControl = '';
        // SendOut1Data.petControl = '';
        // SendOut1Data.issueControl = '';
        // SendOut1Data.trackingNumberControl = '';
        // SendOut1Data.returnControl = '';
        // SendOut1Data.notesControl = '';
        // SendOut1Data.senderFormControl = '';
        // SendOut1Data.requestedbyFormControl = '';
        // SendOut1Data.sendOutTypeControl = '';
        // SendOut1Data.currancyControl = '';
        // SendOut1Data.reservationdateControl = null;
        // SendOut1Data.returndateControl = null;
        // SendOut1Data.datetimeCtrl = null;
        // SendOut1Data.datetimeCtrl1 = null;
        // SendOut1Data.datetimeCtrl2 = null;
        // SendOut1Data.datetimeCtrl3 = null;
        // SendOut1Data.datetimeCtrl4 = null;
      }
      this.trackingNumberControl.setValue(SendOut1Data.trackingNumberControl ?? '');
    })

    console.log("OnSendOut3 Init")
  }

  onButtonPrinterClick(reportType: number) {
    // console.log("reportType", reportType)
    SelfData.selectadpage1 = reportType
    this.modalService.openPrinterFrame()
  }

  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
        ? SelfData.account[0].data[0].id
        : SelfData.account[0].data[0].parentid;
  }

  onButtonPullSheetClick() {
    if (this.curdata.pullsheet === 1) {
      this.messageService.add({
        key: 'tc',
        severity: 'info',
        summary: 'Information',
        detail: 'Pull Sheet is already sent.',
        life: 10000,
      });
    } else {
      this.yourappsService.setSelectedSendOut(this.curdata)
      this.modalService.openCPS()
    }
  }

  protected readonly SelfData = SelfData;
}
