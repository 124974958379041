import { Component } from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";

@Component({
  selector: 'app-returnreciver',
  templateUrl: './returnreciver.component.html',
  styleUrls: ['./returnreciver.component.scss']
})
export class ReturnreciverComponent {
  constructor(public modalService: ModalserviceService) {}
}
