import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {Table} from "primeng/table";
import {SelfData, TagsListData} from "../../models/struct";
import {ModalserviceService} from "../../services/modalservice.service";
import {HashService} from "../../services/hash.service";
import {MessageboxComponent} from "../../modals/messagebox/messagebox.component";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {HttpClient} from "@angular/common/http";
import {forkJoin, Subscription} from "rxjs";

@Component({
  selector: 'app-ss',
  templateUrl: './ss.component.html',
  styleUrl: './ss.component.scss'
})
export class SsComponent implements OnInit {
  @ViewChild('dt1') dt1!: Table;
  filtertext: string = ""
  sizes!: any[];
  @ViewChild(Table) dataTable?: Table;
  selectedSize: any = ''
  iOrderOutResult: TagsListData[] = []

  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
      ? SelfData.account[0].data[0].id
      : SelfData.account[0].data[0].parentid;
  }

  constructor(public modalService: ModalserviceService,
              private hashService: HashService,
              public messageBox: MessageboxComponent,
              private serviceservice: ServiceService,
              private yourappsService: YourappsserviceService,
              private _snackBar: MatSnackBar,
              private http: HttpClient,
              private cdr: ChangeDetectorRef) {
    this.sizes = [
      {name: 'Small', class: 'p-datatable-sm'}
    ];
    this.selectedSize = this.sizes[0]
    setTimeout(() => {
      SelfData.spinnerpossition = true
    });
    forkJoin({
      oerderin: this.serviceservice.selectOrderout({accountid: this.getAccountID()}),
    }).subscribe(({oerderin}) => {
      this.iOrderOutResult = oerderin.data;
      console.log("this.iOrderOutResult", this.iOrderOutResult)
      setTimeout(() => {
        SelfData.spinnerpossition = false
      });
    });
  }

  ngOnInit(): void {

  }

  onAddProductClick() {
    this.yourappsService.setSelectedOrderOutList([], 0)
    this.yourappsService.getReloadOrderOutList([], 0)
    this.subscription = this.yourappsService.reloadOrderOutList$.subscribe((value) => {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      if (value.type === 1) {
        forkJoin({
          oerderin: this.serviceservice.selectOrderout({accountid: this.getAccountID()}),
        }).subscribe(({oerderin}) => {
          this.iOrderOutResult = oerderin.data;
          console.log("this.iOrderOutResult", this.iOrderOutResult)
        });
      }
    })

    this.modalService.ssFrameOpen()
  }

  private subscription?: Subscription

  editProduct(product: any) {
    this.SelfData.returnorder = false
    this.yourappsService.setSelectedOrderOutList(product, 1)
    this.yourappsService.getReloadOrderOutList([], 0)
    this.subscription = this.yourappsService.reloadOrderOutList$.subscribe((value) => {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      if (value.type === 1) {
        forkJoin({
          oerderin: this.serviceservice.selectOrderout({accountid: this.getAccountID()}),
        }).subscribe(({oerderin}) => {
          this.iOrderOutResult = oerderin.data;
          console.log("this.iOrderOutResult", this.iOrderOutResult)
        });
      }
    })
    this.modalService.ssFrameOpen()
  }

  deleteProduct(product: any) {

  }

  protected readonly SelfData = SelfData;

  returnProduct(product: any) {
    this.SelfData.returnorder = true
    this.yourappsService.setSelectedOrderOutList(product, 1)
    this.yourappsService.getReloadOrderOutList([], 0)
    this.subscription = this.yourappsService.reloadOrderOutList$.subscribe((value) => {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      if (value.type === 1) {
        forkJoin({
          oerderin: this.serviceservice.selectOrderout({accountid: this.getAccountID()}),
        }).subscribe(({oerderin}) => {
          this.iOrderOutResult = oerderin.data;
        });
      }
    })
    this.modalService.ssFrameOpen()
  }

  onInput(event: any) {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;
    this.dt1.filterGlobal(value, 'contains');
  }

  clear(table: Table) {
    table.clear();
    this.filtertext = ""
  }
}
