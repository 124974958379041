import { Component } from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";

@Component({
  selector: 'app-createci',
  templateUrl: './createci.component.html',
  styleUrls: ['./createci.component.scss']
})
export class CreateciComponent {
  constructor(public modalService: ModalserviceService) {}
}
