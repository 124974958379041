import {Component, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {ModalserviceService} from '../../services/modalservice.service';
import {MessageboxComponent} from '../../modals/messagebox/messagebox.component';
import {LoginResult, SelfData} from '../../models/struct';
import {ServiceService} from '../../services/service.service';
import {YourappsserviceService} from '../../services/yourappsservice.service';
import {IpService} from '../../services/ip.service';
import {LocalStorageService} from '../../services/local-storage.service';
import {NzNotificationService} from "ng-zorro-antd/notification";

@Component({
  selector: 'app-logindialog',
  templateUrl: './logindialog.component.html',
  styleUrls: ['./logindialog.component.scss'],
  providers: [MessageboxComponent],
})
export class LogindialogComponent implements OnInit {
  ipAddress: string | undefined;
  operatingSystem: string | undefined;
  browser: string | undefined;
  hide = true;
  passwordVisible = false;
  email: string = "";
  password: string = "";

  statusemail: '' | 'error' | 'warning' = '';
  statuspassword: '' | 'error' | 'warning' = '';

  subjectFormControl = new FormControl('', Validators.required);
  descriptionFormControl = new FormControl('', Validators.required);

  togglePasswordVisibility(): void {
    this.hide = !this.hide;
  }

  iLoginResult: LoginResult[] = [];

  constructor(
    public modalService: ModalserviceService,
    public messageBox: MessageboxComponent,
    private serviceservice: ServiceService,
    private yourappsService: YourappsserviceService,
    private ipService: IpService,
    private notification: NzNotificationService,
    private localStorageService: LocalStorageService
  ) {
    const savedData = this.localStorageService.getItem('myAccountArray');
    if (savedData) {
      SelfData.account = savedData;
      // console.dir(SelfData.account)
    }
  }

  fetchIpAddress() {
    this.ipService.getIpAddress().subscribe({
      next: (data) => {
        this.ipAddress = data.ip;
        console.log('User IP Address:', this.ipAddress);
      },
      error: (error) => {
        console.error('Error fetching IP address:', error);
      }
    });
  }

  ngOnInit(): void {
    this.fetchIpAddress();
    this.operatingSystem = this.detectOperatingSystem();
    console.log('User Operating System:', this.operatingSystem);
    this.browser = this.detectBrowser();
    console.log('User Browser:', this.browser);
  }

  detectBrowser(): string {
    const userAgent = window.navigator.userAgent;
    let browserName = 'Unknown';

    if (/MSIE|Trident/.test(userAgent)) {
      browserName = 'Internet Explorer';
    } else if (/Edge\//.test(userAgent)) {
      browserName = 'Microsoft Edge';
    } else if (/Edg\//.test(userAgent)) {
      browserName = 'Microsoft Edge Chromium';
    } else if (/Chrome\//.test(userAgent)) {
      browserName = 'Google Chrome';
    } else if (/Firefox\//.test(userAgent)) {
      browserName = 'Mozilla Firefox';
    } else if (/Safari\//.test(userAgent)) {
      browserName = 'Apple Safari';
    } else if (/Opera\//.test(userAgent)) {
      browserName = 'Opera';
    } else if (/OPR\//.test(userAgent)) {
      browserName = 'Opera Chromium';
    }

    return browserName;
  }

  detectOperatingSystem(): string {
    const userAgent = window.navigator.userAgent;
    let os = 'Unknown';

    if (userAgent.indexOf('Win') !== -1) {
      os = 'Windows';
    } else if (userAgent.indexOf('Mac') !== -1) {
      os = 'macOS';
    } else if (
      userAgent.indexOf('X11') !== -1 ||
      userAgent.indexOf('Linux') !== -1
    ) {
      os = 'Linux';
    } else if (
      userAgent.indexOf('iPhone') !== -1 ||
      userAgent.indexOf('iPad') !== -1
    ) {
      os = 'iOS';
    } else if (userAgent.indexOf('Android') !== -1) {
      os = 'Android';
    }

    return os;
  }

  createMainPage() {

    this.statusemail = this.email === '' ? 'error' : '';
    this.statuspassword = this.password === '' ? 'error' : '';
    if (this.email.trim() === "") {
      this.notification.error(
        '<strong class="notification-error">Information</strong>',
        '<strong class="notification-error">Please enter a valid email address</strong>'
      );
      return
    }
    if (this.password.trim() === "") {
      this.notification.error(
        '<strong class="notification-error">Information</strong>',
        '<strong class="notification-error">Please enter password</strong>'
      );
      return;
    }

    // if (this.emailFormControl.invalid) {
    //   this.yourappsService.setOKOption(0);
    //   this.messageBox.modalService.openMessageBox(
    //     'Information',
    //     'Please enter a valid email address',
    //     'Cancel',
    //     'OK'
    //   );
    // } else if (this.passwordFormControl.value?.trim() == '') {
    //   this.yourappsService.setOKOption(0);
    //   this.messageBox.modalService.openMessageBox(
    //     'Information',
    //     'You must specify the Password!',
    //     'Cancel',
    //     'OK'
    //   );
    // } else {
    this.serviceservice
      .checkuser({
        SetEmail: this.email?.trim(),
        SetPasswprd: this.password?.trim(),
        operationtype: 0,
        ipaddress: this.ipAddress,
        os: this.operatingSystem,
        browser: this.browser,
      })
      .subscribe((loginAuth) => {
        if (loginAuth.Code == 0) {
          this.notification.error(
            '<strong class="notification-error">Information</strong>',
            `<strong class="notification-error">${loginAuth.ErrorMessage}</strong>`
          );
        } else {
          this.yourappsService.reloadAvatarImage.next();
          SelfData.selfpassword = this.password?.trim();
          this.iLoginResult[0] = loginAuth;
          SelfData.account = this.iLoginResult;
          this.saveDateToStorage();
          let programmscount = 0;
          if (
            this.iLoginResult[0].data[0].rpmonitoring ||
            this.iLoginResult[0].data[0].events ||
            this.iLoginResult[0].data[0].samples ||
            this.iLoginResult[0].data[0].contacts
          ) {
            if (this.iLoginResult[0].data[0].rpmonitoring) {
              this.modalService.rpmonitoringshow();
              programmscount += 1;
            } else {
              this.modalService.rpmonitorinclose();
            }
            if (this.iLoginResult[0].data[0].events) {
              this.modalService.eventsshow();
              programmscount += 1;
            } else {
              this.modalService.eventsclose();
            }
            if (this.iLoginResult[0].data[0].samples) {
              this.modalService.samplesshow();
              programmscount += 1;
            } else {
              this.modalService.samplesclose();
            }
            if (this.iLoginResult[0].data[0].contacts) {
              this.modalService.contactsshow();
              programmscount += 1;
            } else {
              this.modalService.contactsclose();
            }
            this.yourappsService.updateProgrammsCount(programmscount);
            this.yourappsService.setAccountName(
              this.iLoginResult[0].data[0].firstname +
              ' ' +
              this.iLoginResult[0].data[0].lastname
            );
            this.yourappsService.reloadAvatarImage.next();
            this.modalService.programmsshow();
            this.yourappsService.setAuth(true);
            this.modalService.close();
          } else {
            this.modalService.programmsclose();
            this.modalService.rpmonitorinclose();
            this.modalService.eventsclose();
            this.modalService.samplesclose();
            this.modalService.contactsclose();
            this.yourappsService.setAuth(false);
            this.yourappsService.setAccountName('');
          }
        }
      });
  }

  saveDateToStorage(): void {
    this.localStorageService.setItem('myAccountArray', SelfData.account);
  }

  changeemail(value: string | undefined) {
    this.email = value || ""
    // const input = document.getElementById(
    //   'loginEmail'
    // ) as HTMLInputElement | null;
    // if (input != null) {
    //   if (typeof value === 'string') {
    //     input.value = value;
    //     this.emailFormControl.setValue(value)
    //   }
    // }
  }
}
