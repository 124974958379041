import {Component, OnDestroy, OnInit} from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";
import {SelfData} from "../../models/struct";

@Component({
  selector: 'app-signatureframe',
  templateUrl: './signatureframe.component.html',
  styleUrls: ['./signatureframe.component.scss']
})
export class SignatureframeComponent implements OnInit, OnDestroy {
  constructor(public modalService: ModalserviceService) {
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
  }
}
