import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import {DOCUMENT} from "@angular/common";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {ConfirmationService, ConfirmEventType, MessageService} from "primeng/api";
import {ServiceService} from "../../services/service.service";
import {HashService} from "../../services/hash.service";
import { HttpClient } from "@angular/common/http";
import {Router} from "@angular/router";
import {DeviceDetectorService} from "ngx-device-detector";
import {ModalserviceService} from "../../services/modalservice.service";
import {GetLBAccepts, LbMainData, LbMainDetails, LbSendAccept, SelfData, SetSelectedFiles} from "../../models/struct";
import {CarouselComponent} from "smart-webcomponents-angular/carousel";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {lastValueFrom, Subscription} from "rxjs";
import {animate, state, style, transition, trigger} from "@angular/animations";

@Component({
  selector: 'app-imageviews',
  templateUrl: './imageviews.component.html',
  styleUrls: ['./imageviews.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0 }), animate(300)]),
      transition(':leave', [animate(300, style({ opacity: 0 }))]),
    ]),
  ],
})
export class ImageviewsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('carousel', {read: CarouselComponent, static: false}) carousel!: CarouselComponent;
  isDesktopDevice: boolean = this.deviceService.isDesktop();
  isVisibled: boolean = false;
  isChecked: boolean = false;
  isDisabled = false;
  checkboxcaption: string = "Select";
  pageindex: number = 0
  private subscription?: Subscription;
  selectedImages: {
    index: number;
    file: File | null;
    url: string;
    filename: string;
    lbcatalogid: number;
    colection: string;
    designer: string;
    catalog: string;
    catalogyear: number;
    seasoncode: string;
    oldchecked: boolean;
  }[] = []
  selectedImageFiles: {
    file: File | null;
    url: string;
    filename: string;
    lbcatalogid: number;
    colection: string;
    designer: string;
    catalog: string;
    catalogyear: number;
    seasoncode: string;
  }[] = [];

  filteredImages: {
    index: number;
    file: File | null;
    url: string;
    filename: string;
    lbcatalogid: number;
    colection: string;
    designer: string;
    catalog: string;
    catalogyear: number;
    seasoncode: string;
    oldchecked: boolean;
  }[] = [];

  constructor(private renderer: Renderer2, @Inject(DOCUMENT) private document: Document,
              private yourappsService: YourappsserviceService,
              private messageService: MessageService,
              private serviceservice: ServiceService,
              private hashService: HashService,
              private http: HttpClient,
              private router: Router,
              private cdr: ChangeDetectorRef,
              private confirmationService: ConfirmationService,
              private deviceService: DeviceDetectorService, public modalService: ModalserviceService) {
    this.setDeviceClass();
    this.loadImageList(SelfData.tlbcatalogid)

  }

  setDeviceClass(): void {
    this.deviceClass = this.deviceService.isMobile() ? 'mobilemaincard' : 'pcmaincard';
    this.footerClass = this.deviceService.isMobile() ? 'mobilebutton-container-dm-ps' : 'pcbutton-container-dm-ps';
    this.deviceClassAddress = this.deviceService.isMobile() ? 'mobileaddressdiv' : 'pcaddressdiv';
    // this.isLandscape = window.innerWidth > window.innerHeight ? 'islandscape' : 'isnlandscape';
  }

  deviceClass: string = "";
  footerClass: string = "";
  deviceClassAddress: string = "";
  isLandscape: boolean = false;
  hidden: boolean = true;
  iLBAcceptsResult: GetLBAccepts[] = [];
  showdialog: boolean = true;

  loadImageList(lbcatalogid: number): void {
    this.selectedImageFiles = []
    if (SelfData.lbmainlist[0].data[0].lbtype === 0) {
      this.serviceservice.setLBImages({lbcatalogid: lbcatalogid})
        .subscribe({
          next: (value: any) => {
            if (value.data.length > 0) {
              value.data.forEach((imageinfo: any) => {
                const catalog = imageinfo.catalog
                const catalogyear = imageinfo.catalogyear
                const colection = imageinfo.colection
                const designer = imageinfo.designer
                const filedir = imageinfo.filedir
                const filename = imageinfo.filename
                const lbcatalogid = imageinfo.lbcatalogid
                const seasoncode = imageinfo.seasoncode
                this.selectedImageFiles.push({
                  file: null,
                  url: filedir,
                  filename: filename,
                  catalog: catalog,
                  catalogyear: catalogyear,
                  colection: colection,
                  designer: designer,
                  lbcatalogid: lbcatalogid,
                  seasoncode: seasoncode
                });
              })
              this.selectedImages = []
              if (SelfData.lbaccepts[0].data.length > 0) {
                SelfData.lbaccepts[0].data.forEach((imageinfo, index: number) => {
                  const catalog = imageinfo.catalog;
                  const catalogyear = imageinfo.catalogyear;
                  const colection = imageinfo.colection;
                  const designer = imageinfo.designer;
                  const filedir = imageinfo.filedir;
                  const filename = imageinfo.filename;
                  const lbcatalogid = imageinfo.lbcatalogid;
                  const seasoncode = "seasoncode";
                  this.selectedImages.push({
                    index: index, // This line uses the index provided by forEach
                    filename: filename,
                    lbcatalogid: lbcatalogid,
                    url: filedir,
                    catalog: catalog,
                    catalogyear: catalogyear,
                    colection: colection,
                    designer: designer,
                    file: null,
                    seasoncode: seasoncode,
                    oldchecked: true
                  });
                });
                this.selectedImages = this.selectedImages.map(item => ({
                  ...item,
                  index: Number(item.index) // Convert index to a number
                }));
              }
              this.hidden = this.selectedImages.length === 0
              SelfData.tselectedimagefiles = this.selectedImages
              this.isVisibled = true
              if (this.findImageByIndex(0)) {
                this.isChecked = true
                if (this.findAlreadyImageByIndex(0)) {
                  this.checkboxcaption = "Already sent"
                  this.isDisabled = true
                } else {
                  this.checkboxcaption = "Remove"
                  this.isDisabled = true
                }
              } else {
                this.isChecked = false
                this.isDisabled = false
                this.checkboxcaption = "Select"
              }
            } else {
              // this.isVisibled = false
              this.selectedImageFiles = []
            }
            this.init(this.selectedImageFiles.length)
          },
          error: (error) => {
            console.error('Error occurred during the subscription:', error);
          },
        });
    } else if (SelfData.lbmainlist[0].data[0].lbtype === 1) {
      if (SelfData.tcustomimages.data && SelfData.tcustomimages.data.length > 0) {
        SelfData.tcustomimages.data.forEach((imageinfo: any) => {
          const catalog = imageinfo.catalog
          const catalogyear = imageinfo.catalogyear
          const colection = imageinfo.colection
          const designer = imageinfo.designer
          const filedir = imageinfo.filedir
          const filename = imageinfo.filename
          const lbcatalogid = imageinfo.lbcatalogid
          const seasoncode = imageinfo.seasoncode
          this.selectedImageFiles.push({
            file: null,
            url: filedir,
            filename: filename,
            catalog: catalog,
            catalogyear: catalogyear,
            colection: colection,
            designer: designer,
            lbcatalogid: lbcatalogid,
            seasoncode: seasoncode
          });
        })
        this.selectedImages = []
        this.serviceservice.selectlbaccepts({lbmainid: SelfData.tlbmainid, key: SelfData.lbkey})
          .subscribe((value) => {
            this.iLBAcceptsResult[0] = value
            SelfData.lbaccepts = this.iLBAcceptsResult;
            if (SelfData.lbaccepts[0].data.length > 0) {
              SelfData.lbaccepts[0].data.forEach((imageinfo, index: number) => {
                const catalog = imageinfo.catalog;
                const catalogyear = imageinfo.catalogyear;
                const colection = imageinfo.colection;
                const designer = imageinfo.designer;
                const filedir = imageinfo.filedir;
                const filename = imageinfo.filename;
                const lbcatalogid = imageinfo.lbcatalogid;
                const seasoncode = "seasoncode";
                this.selectedImages.push({
                  index: index, // This line uses the index provided by forEach
                  filename: filename,
                  lbcatalogid: lbcatalogid,
                  url: filedir,
                  catalog: catalog,
                  catalogyear: catalogyear,
                  colection: colection,
                  designer: designer,
                  file: null,
                  seasoncode: seasoncode,
                  oldchecked: true
                });
              });
              this.selectedImages = this.selectedImages.map(item => ({
                ...item,
                index: Number(item.index) // Convert index to a number
              }));
            }
            this.hidden = this.selectedImages.length === 0

            this.isVisibled = true
            if (this.findImageByIndex(0)) {
              this.isChecked = true
              if (this.findAlreadyImageByIndex(0)) {
                this.checkboxcaption = "Already sent"
                this.isDisabled = true
              } else {
                this.checkboxcaption = "Remove"
                this.isDisabled = false
              }
            } else {
              this.isChecked = false
              this.isDisabled = false
              this.checkboxcaption = "Select"
            }
          })

        // this.init(this.selectedImageFiles.length)
      } else {
        // this.isVisibled = false
        this.selectedImageFiles = []
      }
      setTimeout(() => {
        this.init(this.selectedImageFiles.length);
      });
    }
  }

  onCheckboxChange(event: MatCheckboxChange) {
    if (event.checked) {
      this.checkboxcaption = "Remove"
      this.isDisabled = false
      const index = this.pageindex;
      const selectedFile = this.selectedImageFiles[index];

      if (selectedFile) {
        const imageUrl = selectedFile.url;
        const imagefilename = selectedFile.filename;
        const lbcatalogid = selectedFile.lbcatalogid;
        const catalog = selectedFile.catalog;
        const catalogyear = selectedFile.catalogyear;
        const colection = selectedFile.colection;
        const designer = selectedFile.designer;
        const seasoncode = selectedFile.seasoncode;

        this.selectedImages.push({
          index: index,
          filename: imagefilename,
          lbcatalogid: lbcatalogid,
          url: imageUrl,
          catalog: catalog,
          catalogyear: catalogyear,
          colection: colection,
          designer: designer,
          file: null,
          seasoncode: seasoncode,
          oldchecked: false
        });

        this.selectedImages = this.selectedImages.map(item => ({
          ...item,
          index: Number(item.index) // Convert index to a number
        }));
      } else {
        // Handle the scenario where selectedFile is undefined
        console.error('Selected file not found at index', index);
      }
      this.messageService.add({
        key: 'tc',
        severity: 'success',
        summary: 'Success',
        detail: 'Item added to list',
        life: 2000,
      });
    } else {
      this.checkboxcaption = "Select"
      this.removeImageByIndex(this.pageindex)
      this.messageService.add({
        key: 'tc',
        severity: 'success',
        summary: 'Success',
        detail: 'Item removed from list',
        life: 2000,
      });
    }

    this.hidden = this.selectedImages.length === 0
    // You can now use event.checked, which is a boolean indicating the checkbox's state
  }

  findAlreadyImageByIndex(index: number) {
    index = Number(index);
    let lbcatalogid = Number(this.selectedImageFiles[index].lbcatalogid);
    let filename = this.selectedImageFiles[index].filename;
    const imageIndex = this.selectedImages.findIndex(image => image.filename === filename && image.lbcatalogid === lbcatalogid && image.oldchecked);
    console.log("imageIndex", imageIndex)
    return this.selectedImages.find(image => image.filename === filename && image.lbcatalogid === lbcatalogid && image.oldchecked);
  }

  findImageByIndex(index: number) {
    index = Number(index);
    let lbcatalogid = Number(this.selectedImageFiles[index].lbcatalogid);
    let filename = this.selectedImageFiles[index].filename;
    return this.selectedImages.find(image => image.filename === filename && image.lbcatalogid === lbcatalogid);
  }

  removeImageByIndex(index: number) {
    index = Number(index);
    let lbcatalogid = Number(this.selectedImageFiles[index].lbcatalogid);
    let filename = this.selectedImageFiles[index].filename;
    // Find the index of the image in the selectedImages array
    const imageIndex = this.selectedImages.findIndex(image => image.filename === filename && image.lbcatalogid === lbcatalogid);

    if (imageIndex !== -1) {
      // If the image is found, remove it using splice
      this.selectedImages.splice(imageIndex, 1);
    }
  }

  filterImages(): void {
    this.filteredImages = this.selectedImages.filter(image => !image.oldchecked);
  }

  onButtonUploadClick() {
    this.showdialog = true
    this.cdr.detectChanges()
    this.filterImages()
    if (this.filteredImages.length === 0) {
      this.messageService.add({
        key: 'tc',
        severity: 'error',
        summary: 'Information',
        detail: 'You must select at least one photo.',
        life: 5000,
      });
      return
    }

    this.confirmationService.confirm({
      message: `<strong>Save & Send Selected Photos?</strong>`,
      header: 'Confirm & Send',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        const fileDataPromises = this.filteredImages.map(filedate => {
          const fileData = {
            lbmainid: SelfData.tlbmainid,
            accounid: 0,
            userid: 0,
            filename: filedate.filename,
            filedir: filedate.url,
            lbcatalogid: filedate.lbcatalogid,
            colection: filedate.colection,
            designer: filedate.designer,
            catalog: filedate.catalog,
            catalogyear: filedate.catalogyear,
            ctrl: 0,
            key: SelfData.lbkey
          };

          return lastValueFrom(this.serviceservice.prcLbAccepted(fileData));
        });

        Promise.all(fileDataPromises)
          .then(() => {

              const fields: LbSendAccept = {
                lbmainid: SelfData.tlbmainid,
                key: SelfData.lbkey
              };
              this.serviceservice.sendtocontactsaccept(fields).subscribe({
                next: (value: LbMainData) => {

                },
                error: (error: any) => {
                  console.error('Error occurred:', error);
                },
                complete: () => {
                  console.log('Subscription completed');
                }
              })

            // All requests have completed successfully
            this.messageService.add({
              key: 'tc',
              severity: 'success',
              summary: 'Success',
              detail: 'Completes successfully',
              life: 5000
            });

            window.location.href = SelfData.tlburl;
          })
          .catch(error => {
            console.error('An error occurred:', error);
            // Handle error
          });
      },
      reject: (type: any) => {
        if (type == ConfirmEventType.REJECT) {

        } else if (type == ConfirmEventType.CANCEL) {
        }
      }

    });
    setTimeout(() => {
      this.applyLocalRestoreStyles();
    }, 0);
  }

  onButtonViewClick(){
    this.subscription = this.yourappsService.retSelectedFiles$.subscribe((value: { data: SetSelectedFiles[], type: number }) => {
      if (this.subscription) {
        this.subscription.unsubscribe();
        if (value.data) {
          if (value.type === 1) {
            this.selectedImages = []
            value.data.forEach((fileinfo, index: number) => {
              const catalog = fileinfo.catalog;
              const catalogyear = fileinfo.catalogyear;
              const colection = fileinfo.colection;
              const designer = fileinfo.designer;
              const filedir = fileinfo.url;
              const filename = fileinfo.filename;
              const lbcatalogid = fileinfo.lbcatalogid;
              const seasoncode = fileinfo.seasoncode;
              const oldcheck = fileinfo.oldchecked;
              this.selectedImages.push({
                index: index, // This line uses the index provided by forEach
                filename: filename,
                lbcatalogid: lbcatalogid,
                url: filedir,
                catalog: catalog,
                catalogyear: catalogyear,
                colection: colection,
                designer: designer,
                file: null,
                seasoncode: seasoncode,
                oldchecked: oldcheck
              });
            })
            this.isVisibled = true
            if (this.findImageByIndex(0)) {
              this.isChecked = true
              if (this.findAlreadyImageByIndex(0)) {
                this.checkboxcaption = "Already sent"
                this.isDisabled = true
              } else {
                this.checkboxcaption = "Remove"
                this.isDisabled = false
              }
            } else {
              this.isChecked = false
              this.isDisabled = false
              this.checkboxcaption = "Select"
            }
            this.yourappsService.returnSelectedFiles(null, 0)
          }
        }
      }
    })
    this.showdialog = false
    SelfData.tselectedimagefiles = this.selectedImages
    this.modalService.openiv1frame()
  }

  private applyLocalRestoreStyles() {
    setTimeout(() => {
      const messageElements = document.querySelectorAll('.p-confirm-dialog-message');
      messageElements.forEach((element) => {
        element.setAttribute('style', 'font-size: 16px; color: #343a40;');
      });
    }, 0);

    const iconElements = document.querySelectorAll('.p-confirm-dialog-icon');
    iconElements.forEach((element) => {
      element.setAttribute('style', 'color: #343a40');
    });
  }

  init(imagecount: number): void {
    // init code.
    const that = this,
      basePath = './../../../src/images/';
    if (this.carousel) {
      this.carousel.dataSource = generateDataSource(imagecount, this.selectedImageFiles);
    } else {
      console.error("Carousel is undefined or null");
    }

    function generateDataSource(items: number, selectedImageFiles: {
      file: File | null;
      url: string;
      filename: string;
      lbcatalogid: number;
      colection: string;
      designer: string;
      catalog: string;
      catalogyear: number;
      seasoncode: string
    }[]) {
      return Array(items).fill({}).map((element, index) => {
        // Use the image from selectedImageFiles if available; otherwise, use a placeholder
        var headlines: string = ""
        const imageUrl = index < selectedImageFiles.length && selectedImageFiles[index].file ? selectedImageFiles[index].url : selectedImageFiles[index].url;
        if (SelfData.lbmainlist[0].data[0].lbtype === 0) {
          headlines = ""
        } else {
          headlines = index < selectedImageFiles.length && selectedImageFiles[index].file ? selectedImageFiles[index].catalog : selectedImageFiles[index].catalog;
        }

        return {image: imageUrl, label: headlines};
      });
    }
  }

  ngAfterViewInit(): void {
    this.selectedImages = []
    this.pageindex = 0
    this.carousel.addEventListener('changing', (event: Event) => {
      // Use type assertion to assert the event as a CustomEvent
      const customEvent = event as CustomEvent;
      const detail = customEvent.detail,
        index = detail.index,
        previousIndex = detail.previousIndex;
      this.pageindex = index

      if (this.findImageByIndex(index)) {
        this.isChecked = true
        this.cdr.detectChanges()
        if (this.findAlreadyImageByIndex(index)) {
          this.checkboxcaption = "Already sent"
          this.isDisabled = true
        } else {
          this.checkboxcaption = "Remove"
          this.isDisabled = false
        }
      } else {
        this.isChecked = false
        this.checkboxcaption = "Select"
        this.isDisabled = false
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  setOrientation() {
    this.isLandscape = window.innerWidth > window.innerHeight;
    window.addEventListener("orientationchange", function () {
      if (screen.orientation) {
        (screen.orientation as any).lock("portrait-primary").catch(function (error: any) {
          console.log("Orientation lock not allowed:", error);
        });
      }
    })
  }

  ngOnInit(): void {
    this.showdialog = true
    this.renderer.setStyle(this.document.body, 'background-color', 'rgba(150,150,150,0.5)');
    this.setOrientation();
    window.addEventListener('resize', this.setOrientation.bind(this))
  }
}
