import { Component } from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";

@Component({
  selector: 'app-selectsendout',
  templateUrl: './selectsendout.component.html',
  styleUrls: ['./selectsendout.component.scss']
})
export class SelectsendoutComponent {

  constructor(public modalService: ModalserviceService) {}

}
