<p-toast position="top-center" key="tc" [style]="{ width: '40vw' }"></p-toast>
<p-toast position="bottom-right"></p-toast>

<div class="main-container">
  <div class="fixed-elements">
    <app-top-panel></app-top-panel>
    <app-main-menu></app-main-menu>
  </div>

  <div class="scrollable-content">
    <app-samplemanager
      *ngIf="modalService.isSampleManagerVisibled$ | async"
    ></app-samplemanager>
    <!-- Add other components for different tabs here -->
  </div>

  <app-fieldseditor
    class="centered-messagebox"
    *ngIf="modalService.isFieldEditor$ | async"
    @fadeInOut
  >
    <app-fieldseditordialog></app-fieldseditordialog>
    <!--  <app-messageboxdialog></app-messageboxdialog>-->
  </app-fieldseditor>

  <app-createsendout class="centered-messagebox" *ngIf="modalService.isSendOutVisibled$ | async" @fadeInOut>
    <app-createsendoutdialog></app-createsendoutdialog>
  </app-createsendout>

  <app-fullsendout class="centered-messagebox" *ngIf="modalService.isFullSendOutVisibled$ | async" @fadeInOut>
    <app-fullsendoutdialog></app-fullsendoutdialog>
  </app-fullsendout>

  <app-createrequest class="centered-messagebox" *ngIf="modalService.isRequestVisibled$ | async" @fadeInOut>
    <app-createrequestdialog></app-createrequestdialog>
    <!--  <app-messageboxdialog></app-messageboxdialog>-->
  </app-createrequest>

  <app-fullrequest class="centered-messagebox" *ngIf="modalService.isFullRequestVisibled$ | async" @fadeInOut>
    <app-fullrequestdialog></app-fullrequestdialog>
  </app-fullrequest>

  <app-sprpanel class="centered-messagebox" *ngIf="modalService.isprVisibled$ | async" @fadeInOut>
    <app-filterpanel></app-filterpanel>
  </app-sprpanel>

  <app-sopanel class="centered-messagebox" *ngIf="modalService.isoVisibled$ | async" @fadeInOut>
<!--    <app-filterpanel></app-filterpanel>-->
  </app-sopanel>

  <app-profile
    class="centered-messagebox"
    *ngIf="modalService.isProfileVisibled$ | async"
    @fadeInOut>
    <app-profiledialog></app-profiledialog>
    <!--  <app-messageboxdialog></app-messageboxdialog>-->
  </app-profile>

  <app-code
    class="centered-messagebox"
    *ngIf="modalService.isCodeVisibled$ | async"
    @fadeInOut
  >
    <app-codedialog></app-codedialog>
    <!--  <app-messageboxdialog></app-messageboxdialog>-->
  </app-code>

  <app-editprofile
    class="centered-messagebox"
    *ngIf="modalService.isProfileEditVisibled$ | async"
    @fadeInOut
  >
    <app-editprofiledialog></app-editprofiledialog>
    <!--  <app-messageboxdialog></app-messageboxdialog>-->
  </app-editprofile>

  <app-settings class="centered-messagebox" *ngIf="modalService.isSettings$ | async" @fadeInOut>
    <app-settingsdialog></app-settingsdialog>
  </app-settings>

  <app-dragdrop class="centered-messagebox" *ngIf="modalService.isDragDrop$ | async" @fadeInOut>
    <app-dragdropdialog></app-dragdropdialog>
  </app-dragdrop>

  <app-createcontacts
    class="centered-messagebox"
    *ngIf="modalService.isContactVisibled$ | async"
    @fadeInOut
  >
    <app-createcontactsdialog></app-createcontactsdialog>
  </app-createcontacts>

  <app-createcompany class="centered-messagebox" *ngIf="modalService.isCompanyVisibled$ | async" @fadeInOut>
    <app-createcompanydialog></app-createcompanydialog>
    <!--  <app-messageboxdialog></app-messageboxdialog>-->
  </app-createcompany>

  <app-address class="centered-messagebox" *ngIf="modalService.isAddressVisibled$ | async" @fadeInOut>
    <app-addressdialog></app-addressdialog>
    <!--  <app-messageboxdialog></app-messageboxdialog>-->
  </app-address>

  <app-notificationsettings
    class="centered-messagebox"
    *ngIf="modalService.isNotificationSettingsVisibled$ | async"
    @fadeInOut
  >
    <app-notificationsettingsdialog></app-notificationsettingsdialog>
    <!--  <app-messageboxdialog></app-messageboxdialog>-->
  </app-notificationsettings>

  <app-imagezoom
    class="centered-messagebox"
    *ngIf="modalService.isImageZoom$ | async"
    @fadeInOut
  >
    <app-imagezoomdialog></app-imagezoomdialog>
  </app-imagezoom>

  <app-editlabel
    class="centered-messagebox"
    *ngIf="modalService.isEditLabelVisibled$ | async"
    @fadeInOut
  >
    <app-editlabeldialog></app-editlabeldialog>
  </app-editlabel>

  <app-selectedsample
    class="centered-messagebox"
    *ngIf="modalService.isSSVisibled$ | async"
    @fadeInOut
  >
    <app-selectedsampledialog></app-selectedsampledialog>
    <!--  <app-messageboxdialog></app-messageboxdialog>-->
  </app-selectedsample>

  <app-inventary
    class="centered-messagebox"
    *ngIf="modalService.isInventaryVisibled$ | async"
    @fadeInOut
  >
    <app-inventarydialog></app-inventarydialog>
  </app-inventary>


  <app-createlook class="centered-messagebox" *ngIf="modalService.isLookVisibled$ | async" @fadeInOut>
    <app-createlookdialog></app-createlookdialog>
    <!--  <app-messageboxdialog></app-messageboxdialog>-->
  </app-createlook>

  <app-look class="centered-messagebox" *ngIf="modalService.isLookDialogVisibled$ | async" @fadeInOut>
    <app-lookdialog></app-lookdialog>
    <!--  <app-messageboxdialog></app-messageboxdialog>-->
  </app-look>

  <app-deleteframe class="centered-messagebox" *ngIf="modalService.isDeleteVisibled$ | async" @fadeInOut>
    <app-deleteframedialog></app-deleteframedialog>
  </app-deleteframe>

  <app-emaileditor class="centered-messagebox" *ngIf="modalService.isEmailEditorVisibled$ | async" @fadeInOut>
    <app-emaileditordialog></app-emaileditordialog>
  </app-emaileditor>

  <app-emaildetailsview class="centered-messagebox" *ngIf="modalService.isEmailDetailsViewVisibled$ | async" @fadeInOut>
    <app-emaildetailsviewdialog></app-emaildetailsviewdialog>
  </app-emaildetailsview>

  <app-emailsign class="centered-messagebox" *ngIf="modalService.isEmailSignVisibled$ | async" @fadeInOut>
    <app-emailsigndialog></app-emailsigndialog>
  </app-emailsign>

  <app-returnreciver class="centered-messagebox" *ngIf="modalService.isRRVisibled$ | async" @fadeInOut>
    <app-returnreciverdialog></app-returnreciverdialog>
  </app-returnreciver>

  <app-createvi class="centered-messagebox" *ngIf="modalService.isviVisibled$ | async" @fadeInOut>
    <app-createvidialog></app-createvidialog>
  </app-createvi>

  <app-scannerform class="centered-messagebox" *ngIf="modalService.isScannVisibled$ | async" @fadeInOut>
    <app-scannerformdialog></app-scannerformdialog>
  </app-scannerform>

  <app-createsample class="centered-messagebox" *ngIf="modalService.isSampleVisibled$ | async" @fadeInOut>
    <app-createsampledialog></app-createsampledialog>
    <!--  <app-messageboxdialog></app-messageboxdialog>-->
  </app-createsample>

  <app-sws class="centered-messagebox" *ngIf="modalService.isSWS$ | async" @fadeInOut>
    <app-swsdialog></app-swsdialog>
  </app-sws>

  <app-collection class="centered-messagebox" *ngIf="modalService.isCollection$ | async" @fadeInOut>
    <app-collectiondialog></app-collectiondialog>
    <!--  <app-messageboxdialog></app-messageboxdialog>-->
  </app-collection>

  <app-addeditframe class="centered-messagebox" *ngIf="modalService.isAddEdit$ | async" @fadeInOut>
    <app-addeditframedialog></app-addeditframedialog>
    <!--  <app-messageboxdialog></app-messageboxdialog>-->
  </app-addeditframe>

  <app-createvasia class="centered-messagebox" *ngIf="modalService.isVasiaVisibled$ | async" @fadeInOut>
    <app-createvasiadialog></app-createvasiadialog>
  </app-createvasia>

  <app-createvasiaframe class="centered-messagebox" *ngIf="modalService.isVasiaFrameVisibled$ | async" @fadeInOut>
    <app-createvasiaframedialog></app-createvasiaframedialog>
  </app-createvasiaframe>

  <app-season class="centered-messagebox" *ngIf="modalService.isSeason$ | async" @fadeInOut>
    <app-seasondialog></app-seasondialog>
  </app-season>

  <app-createlsb class="centered-messagebox" *ngIf="modalService.islsbVisibled$ | async" @fadeInOut>
    <app-createlsbdialog></app-createlsbdialog>
  </app-createlsb>

  <app-createlsb1 class="centered-messagebox" *ngIf="modalService.islsb1Visibled$ | async" @fadeInOut>
    <app-createlsb1dialog></app-createlsb1dialog>
  </app-createlsb1>

  <app-createlsbframe class="centered-messagebox" *ngIf="modalService.islsbframeVisibled$ | async" @fadeInOut>
    <app-createlsbframedialog></app-createlsbframedialog>
  </app-createlsbframe>

  <app-createlb class="centered-messagebox" *ngIf="modalService.islbVisibled$ | async" @fadeInOut>
    <app-createlbdialog></app-createlbdialog>
  </app-createlb>

  <app-createlbframe class="centered-messagebox" *ngIf="modalService.islbframeVisibled$ | async" @fadeInOut>
    <app-createlbframedialog></app-createlbframedialog>
  </app-createlbframe>

  <app-createvcimages class="centered-messagebox" *ngIf="modalService.isvcVisibled$ | async" @fadeInOut>
    <app-createvcimagesdialog></app-createvcimagesdialog>
  </app-createvcimages>

  <app-createvcimages1 class="centered-messagebox" *ngIf="modalService.isvc1Visibled$ | async" @fadeInOut>
    <app-createvcimages1dialog></app-createvcimages1dialog>
  </app-createvcimages1>

  <app-createci class="centered-messagebox" *ngIf="modalService.isCIVisibled$ | async" @fadeInOut>
    <app-createcidialog></app-createcidialog>
  </app-createci>

  <app-rrcomment class="centered-messagebox" *ngIf="modalService.isRRCVisibled$ | async" @fadeInOut>
    <app-rrcommentdialog></app-rrcommentdialog>
  </app-rrcomment>

  <app-pullsheettemplate class="centered-messagebox" *ngIf="modalService.isPSTVisibled$ | async" @fadeInOut>
    <app-pullsheettemplatedialog></app-pullsheettemplatedialog>
  </app-pullsheettemplate>

  <app-invoice class="centered-messagebox" *ngIf="modalService.isInvoiceVisibled$ | async" @fadeInOut>
    <app-invoicedialog></app-invoicedialog>
    <!--  <app-messageboxdialog></app-messageboxdialog>-->
  </app-invoice>

  <app-signatureframe class="centered-messagebox" *ngIf="modalService.isSignatureVisibled$ | async" @fadeInOut>
    <app-signatureframedialog></app-signatureframedialog>
  </app-signatureframe>

  <app-createpullsheet class="centered-messagebox" *ngIf="modalService.isCPSVisibled$ | async" @fadeInOut>
    <app-createpullsheetdialog></app-createpullsheetdialog>
  </app-createpullsheet>

  <app-selectsendout class="centered-messagebox" *ngIf="modalService.isSSOVisibled$ | async" @fadeInOut>
    <app-selectsendoutdialog></app-selectsendoutdialog>
  </app-selectsendout>

  <app-createsr class="centered-messagebox" *ngIf="modalService.isSRVisibled$ | async" @fadeInOut>
    <app-createsrdialog></app-createsrdialog>
  </app-createsr>

  <app-sendoutsamplelist1 class="centered-messagebox" *ngIf="modalService.isSSLVisibled$ | async" @fadeInOut>
    <app-sendoutsamplelistdialog1></app-sendoutsamplelistdialog1>
  </app-sendoutsamplelist1>

  <app-printerframe class="centered-messagebox" *ngIf="modalService.isPrinterVisibled$ | async" @fadeInOut>
    <app-printerframedialog></app-printerframedialog>
  </app-printerframe>

  <app-celebritiframe class="centered-messagebox" *ngIf="modalService.isCFVisibled$ | async" @fadeInOut>
    <app-celebritiframedialog></app-celebritiframedialog>
  </app-celebritiframe>

  <app-messagebox class="centered-messagebox" *ngIf="modalService.isMessageBoxVisible$ | async" @fadeInOut>
    <app-messageboxdialog></app-messageboxdialog>
  </app-messagebox>

  <app-favorite class="centered-messagebox" *ngIf="modalService.isfavoriteVisibled$ | async" @fadeInOut>
    <app-favoritedialog></app-favoritedialog>
  </app-favorite>

  <app-emailframe class="centered-messagebox" *ngIf="modalService.isEmailVisibled$ | async" @fadeInOut>
    <app-emailframedialog></app-emailframedialog>
  </app-emailframe>

  <app-emailadd class="centered-messagebox" *ngIf="modalService.isEmailAddVisibled$ | async" @fadeInOut>
    <app-emailadddialog></app-emailadddialog>
  </app-emailadd>

  <app-emailcontacts class="centered-messagebox" *ngIf="modalService.isEmailContactsVisibled$ | async" @fadeInOut>
    <app-emailcontactsdialog></app-emailcontactsdialog>
  </app-emailcontacts>

  <app-editor class="centered-messagebox" *ngIf="modalService.isEditorVisibled$ | async" @fadeInOut>
    <app-editordialog></app-editordialog>
  </app-editor>

  <app-catalogslist class="centered-messagebox" *ngIf="modalService.iscatalogslistVisibled$ | async" @fadeInOut>
    <app-catalogsdialog></app-catalogsdialog>
  </app-catalogslist>

  <app-catalogslist1 class="centered-messagebox" *ngIf="modalService.iscatalogslist1Visibled$ | async" @fadeInOut>
    <app-catalogs1dialog></app-catalogs1dialog>
  </app-catalogslist1>

  <app-createimageviews class="centered-messagebox" *ngIf="modalService.isimageviewVisibled$ | async" @fadeInOut>
    <app-createimageviewsdialog></app-createimageviewsdialog>
  </app-createimageviews>

  <app-aichoos class="centered-messagebox" *ngIf="modalService.iAIChoosVisibled$ | async" @fadeInOut>
    <app-aichoosdialog></app-aichoosdialog>
  </app-aichoos>

  <app-aiframe class="centered-messagebox" *ngIf="modalService.iAIVisibled$ | async" @fadeInOut>
    <app-aiframedialog></app-aiframedialog>
  </app-aiframe>

  <app-cclist class="centered-messagebox" *ngIf="modalService.iCCVisibled$ | async" @fadeInOut>
    <app-cclistdialog></app-cclistdialog>
  </app-cclist>

  <app-comingsoon class="centered-messagebox" *ngIf="modalService.icsVisibled$ | async" @fadeInOut>
    <app-comingsoondialog></app-comingsoondialog>
  </app-comingsoon>

  <app-schedule class="centered-messagebox" *ngIf="modalService.ischeduleVisibled$ | async" @fadeInOut>
    <app-scheduledialog></app-scheduledialog>
  </app-schedule>

  <app-shipdate class="centered-messagebox" *ngIf="modalService.ishipdateVisibled$ | async" @fadeInOut>
    <app-shipdatedialog></app-shipdatedialog>
  </app-shipdate>

  <app-testprinter class="centered-messagebox" *ngIf="modalService.iTestPrinterVisibled$ | async" @fadeInOut>
    <app-testprinterdialog></app-testprinterdialog>
  </app-testprinter>

</div>
