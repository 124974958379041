import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  Countrylist,
  Industrylist,
  Jobslist,
  LoginResult,
  Noelist,
  RegisterResult,
  SelfData,
} from '../../models/struct';
import { ModalserviceService } from '../../services/modalservice.service';
import { ServiceService } from '../../services/service.service';
import { YourappsserviceService } from '../../services/yourappsservice.service';

@Component({
  selector: 'app-registrationframe',
  templateUrl: './registrationframe.component.html',
  styleUrls: ['./registrationframe.component.scss'],
})
export class RegistrationframeComponent implements OnInit {
  emailFormControl = new FormControl({ value: '', disabled: true }, [
    Validators.required,
    Validators.email,
  ]);
  firstNameFormControl = new FormControl({ value: '', disabled: true }, [
    Validators.required,
  ]);
  jobControl = new FormControl(
    { value: '', disabled: true },
    Validators.required
  );
  industryControl = new FormControl(
    { value: '', disabled: true },
    Validators.required
  );
  lastNameFormControl = new FormControl(
    { value: '', disabled: true },
    Validators.required
  );
  companyFormControl = new FormControl(
    { value: '', disabled: true },
    Validators.required
  );
  countryFormControl = new FormControl(
    { value: '', disabled: true },
    Validators.required
  );
  noeFormControl = new FormControl(
    { value: '', disabled: true },
    Validators.required
  );
  companyWebsiteFormControl = new FormControl(
    { value: '', disabled: true },
    Validators.required
  );
  companyPhone = new FormControl(
    { value: '', disabled: true },
    Validators.pattern(/^\d+$/)
  );
  howdidFormControl = new FormControl(
    { value: '', disabled: true },
    Validators.required
  );
  toppings = this.fb.group({
    check1: {
      value: SelfData.account[0]?.data?.[0]?.rpmonitoring,
      disabled: true,
    },
    check2: { value: SelfData.account[0]?.data?.[0]?.events, disabled: true },
    check3: { value: SelfData.account[0]?.data?.[0]?.samples, disabled: true },
    check4: { value: SelfData.account[0]?.data?.[0]?.contacts, disabled: true },
    check5: { value: false, disabled: true },
    check1_1: {
      value: SelfData.account[0]?.data?.[0]?.samples,
      disabled: true,
    },
    check1_2: {
      value: SelfData.account[0]?.data?.[0]?.contacts,
      disabled: true,
    },
    check1_3: { value: false, disabled: true },
    check1_4: { value: false, disabled: true },
  });

  iJobList: Jobslist[] = [];
  iIndustry: Industrylist[] = [];
  iCountry: Countrylist[] = [];
  iNoe: Noelist[] = [];
  iAboutUS: Noelist[] = [];

  myForm: FormGroup = new FormGroup({});

  firstname: string = '';
  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    public serviceservice: ServiceService,
    private yourappsService: YourappsserviceService
  ) {
    this.firstNameFormControl.setValue(
      SelfData.account[0]?.data?.[0]?.firstname
    );
    this.lastNameFormControl.setValue(SelfData.account[0]?.data?.[0]?.lastname);
    this.emailFormControl.setValue(SelfData.account[0]?.data?.[0]?.email);
    this.jobControl.setValue(SelfData.account[0]?.data?.[0]?.jobtitle);
    this.industryControl.setValue(SelfData.account[0]?.data?.[0]?.industry);
    this.countryFormControl.setValue(SelfData.account[0]?.data?.[0]?.country);
    this.companyFormControl.setValue(SelfData.account[0]?.data?.[0]?.company);
    this.companyWebsiteFormControl.setValue(
      SelfData.account[0]?.data?.[0]?.website
    );
    this.noeFormControl.setValue(
      SelfData.account[0]?.data?.[0]?.numberofemployees
    );
    this.companyPhone.setValue(SelfData.account[0]?.data?.[0]?.phone);
    this.howdidFormControl.setValue(SelfData.account[0]?.data?.[0]?.aboutus);
    this.toppings.value.check1 = SelfData.account[0]?.data?.[0]?.rpmonitoring;
    this.toppings.value.check2 = SelfData.account[0]?.data?.[0]?.events;
    this.toppings.value.check1_1 = SelfData.account[0]?.data?.[0]?.samples;
    this.toppings.value.check1_2 = SelfData.account[0]?.data?.[0]?.contacts;

    this.yourappsService.reloadDataRequest.subscribe(() => {
      this.reloadDate();
    });
  }

  reloadDate() {
    console.dir(SelfData.account);
    this.firstNameFormControl.setValue(
      SelfData.account[0]?.data?.[0]?.firstname
    );
    this.lastNameFormControl.setValue(SelfData.account[0]?.data?.[0]?.lastname);
    this.emailFormControl.setValue(SelfData.account[0]?.data?.[0]?.email);
    this.jobControl.setValue(SelfData.account[0]?.data?.[0]?.jobtitle);
    this.industryControl.setValue(SelfData.account[0]?.data?.[0]?.industry);
    this.countryFormControl.setValue(SelfData.account[0]?.data?.[0]?.country);
    this.companyFormControl.setValue(SelfData.account[0]?.data?.[0]?.company);
    this.companyWebsiteFormControl.setValue(
      SelfData.account[0]?.data?.[0]?.website
    );
    this.noeFormControl.setValue(
      SelfData.account[0]?.data?.[0]?.numberofemployees
    );
    this.companyPhone.setValue(SelfData.account[0]?.data?.[0]?.phone);
    this.howdidFormControl.setValue(SelfData.account[0]?.data?.[0]?.aboutus);
    this.toppings.value.check1 = SelfData.account[0]?.data?.[0]?.rpmonitoring;
    this.toppings.value.check2 = SelfData.account[0]?.data?.[0]?.events;
    this.toppings.value.check1_1 = SelfData.account[0]?.data?.[0]?.samples;
    this.toppings.value.check1_2 = SelfData.account[0]?.data?.[0]?.contacts;
  }
  ngOnInit(): void {
    this.serviceservice.getAll().subscribe((iJobList) => {
      this.iJobList = iJobList;
    });
    this.serviceservice.getAllIndustry().subscribe((iIndustryList) => {
      this.iIndustry = iIndustryList;
    });
    this.serviceservice.getAllCountry().subscribe((iCountryList) => {
      this.iCountry = iCountryList;
    });
    this.serviceservice.getAllNoe().subscribe((iNoeList) => {
      this.iNoe = iNoeList;
    });
    this.serviceservice.getAllAboutus().subscribe((iAboutUSList) => {
      this.iAboutUS = iAboutUSList;
    });
    this.myForm = this.fb.group({
      // Your form controls go here
      toppings: this.fb.group({
        check1: [true],
        check2: [false],
        // ...
      }),
      clicking: this.fb.group({
        check2_1: [false],
        check2_2: [false],
        // ...
      }),
    });
  }
}
