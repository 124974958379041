<div class="black-background2">
  <div class="row">
    <div class="forma-group">
      <p-table
        #dt1
        [value]="collectionListData"
        [paginator]="true"
        [rows]="10"
        [showCurrentPageReport]="true"
        [scrollable]="true"
        [scrollHeight]="'400px'"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [rowsPerPageOptions]="[10, 20, 50, 100]"
        [styleClass]="'p-datatable-striped mat-elevation-z3 p-datatable-sm tablepossition'"
        selectionMode="single"
        [globalFilterFields]="['description']"
        [tableStyle]="{'min-width': '30%'}">
        <ng-template pTemplate="caption">
          <div class="flex">
            <span class="captionStype">Collections</span>
            <span class="p-input-icon-left ml-auto">
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                  <i class="pi pi-search" style="color: #000000"></i>
                </span>
                <input pInputText type="text" (input)="onInput($event)" placeholder="Filter" [(ngModel)]="filtertext"/>
                <span class="p-inputgroup-addon custom-hover-red" matRipple (click)="clear(dt1)">
                  <i class="pi pi-times" style="color: #000000"></i></span>
              </div>
            </span>

          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <!--                    -->
            <th style="width:15%">Description</th>
            <!--          <th style="width:10%">Designer</th>-->
            <th style="width:1%"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
          <tr [pSelectableRow]="product">
            <td>{{product.description}}</td>
            <td>
              <div class="optionbuttons">

                <p-button label="Edit" size="small" [text]="true" severity="success" (click)="onButtonCollectionEditClick(product)">
                </p-button>

              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>

    <div class="forma-group">
      <p-table
        #dt2
        [value]="designerListData"
        [paginator]="true"
        [rows]="10"
        [showCurrentPageReport]="true"
        [scrollable]="true"
        [scrollHeight]="'400px'"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [rowsPerPageOptions]="[10, 20, 50, 100]"
        [styleClass]="'p-datatable-striped mat-elevation-z3 p-datatable-sm tablepossition'"
        selectionMode="single"
        [globalFilterFields]="['description']"
        [tableStyle]="{'min-width': '30px'}">
        <ng-template pTemplate="caption">
          <div class="flex">
            <span class="captionStype">Designer</span>
            <span class="p-input-icon-left ml-auto">
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                  <i class="pi pi-search" style="color: #000000"></i>
                </span>
                <input pInputText type="text" (input)="onInput1($event)" placeholder="Filter" [(ngModel)]="filtertext1"/>
                <span class="p-inputgroup-addon custom-hover-red" matRipple (click)="clear1(dt2)">
                  <i class="pi pi-times" style="color: #000000"></i></span>
              </div>
            </span>

          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <!--                    -->
            <th style="width:15%">Description</th>
            <!--          <th style="width:10%">Designer</th>-->
            <th style="width:1%"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
          <tr [pSelectableRow]="product">
            <td>{{product.description}}</td>
            <td>
              <div class="optionbuttons">

                <p-button label="Edit" size="small" [text]="true" severity="success" (click)="onButtonDesignerEditClick(product)">
                </p-button>

              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>

    <div class="forma-group">
      <p-table
        #dt3
        [value]="seasonListData"
        [paginator]="true"
        [rows]="10"
        [showCurrentPageReport]="true"
        [scrollable]="true"
        [scrollHeight]="'400px'"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [rowsPerPageOptions]="[10, 20, 50, 100]"
        [styleClass]="'p-datatable-striped mat-elevation-z3 p-datatable-sm tablepossition'"
        selectionMode="single"
        [globalFilterFields]="['description', 'seasoncode', 'seasonyear']"
        [tableStyle]="{'min-width': '30px'}">
        <ng-template pTemplate="caption">
          <div class="flex">
            <span class="captionStype">Season</span>
            <span class="p-input-icon-left ml-auto">
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                  <i class="pi pi-search" style="color: #000000"></i>
                </span>
                <input pInputText type="text" (input)="onInput2($event)" placeholder="Filter" [(ngModel)]="filtertext2"/>
                <span class="p-inputgroup-addon custom-hover-red" matRipple (click)="clear2(dt3)">
                  <i class="pi pi-times" style="color: #000000"></i></span>
              </div>
            </span>

          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <!--                    -->
            <th style="width:15%">Description</th>
            <th style="width:15%">Season Code</th>
            <th style="width:15%">Season Year</th>
            <!--          <th style="width:10%">Designer</th>-->
            <th style="width:1%"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
          <tr [pSelectableRow]="product">
            <td>{{product.description}}</td>
            <td>{{product.seasoncode}}</td>
            <td>{{product.seasonyear}}</td>
            <td>
              <div class="optionbuttons">

                <p-button label="Edit" size="small" [text]="true" severity="success" (click)="onButtonSeasonEditClick(product)">
                </p-button>

              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>

  </div>
</div>
