import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MessageService} from "primeng/api";
import {Subscription} from "rxjs";
import {HashService} from "../../services/hash.service";
import {PullSheetData, PullSheetDetails, SelfData, SmsDetails} from "../../models/struct";

@Component({
  selector: 'app-createpullsheetdialog',
  templateUrl: './createpullsheetdialog.component.html',
  styleUrls: ['./createpullsheetdialog.component.scss']
})
export class CreatepullsheetdialogComponent implements OnInit, AfterViewInit, OnDestroy {
  emailFormControl = new FormControl('', [
    Validators.email,
  ]);
  sendoutControl = new FormControl<string>('');
  phoneControl = new FormControl<string>('');
  sendoutid: number = 0
  text: string = "";
  buttondisabled: boolean = false
  mailissend: boolean = false

  constructor(
      public modalService: ModalserviceService,
      public fb: FormBuilder,
      private serviceservice: ServiceService,
      private hashService: HashService,
      private yourappsService: YourappsserviceService,
      private messageService: MessageService
  ) {

  }

  getRandomNumber(): number {
    return Math.floor(Math.random() * 999999999);
  }

  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
        ? SelfData.account[0].data[0].id
        : SelfData.account[0].data[0].parentid;
  }

  private subscription?: Subscription;
  onButtonAddClick() {
    this.subscription = this.yourappsService.selsendout.subscribe(value => {
      if (this.subscription) {
        this.sendoutControl.setValue(value?.["Delivery"])
        this.sendoutid = value?.["id"]
        this.subscription?.unsubscribe()
      }
    });
    this.modalService.openSSO()
  }

  onButtonSendClick(){
    this.mailissend = false
    if (this.emailFormControl.value?.trim() === "" && this.phoneControl.value?.trim() === "") {
      this.messageService.add({
        key: 'tc',
        severity: 'error',
        summary: 'Information',
        detail: 'Please provide either an email address or a phone number.',
        life: 10000,
      });
      return
    }
    if (this.emailFormControl.value?.trim() !== "" && this.emailFormControl.invalid) {
      this.messageService.add({
        key: 'tc',
        severity: 'error',
        summary: 'Information',
        detail: 'Incorrect email',
        life: 10000,
      });
      return
    }
    let key = this.hashService.md5(this.getRandomNumber().toString())

    if (this.emailFormControl.value?.trim() !== "") {
      const data: PullSheetDetails = {
        email: this.emailFormControl.value?.trim() || "",
        subject: SelfData.account[0].data[0].company + " - Pull Sheet" ,
        message: this.text.trim(),
        key: key,
        accountid: this.getAccountID(),
        userid: SelfData.account[0].data[0].id,
        sendoutid: this.sendoutid,
        status: 0,
        isactivity: true,
        ctrl: 0
      };
      this.serviceservice.pullSheet(data).subscribe({
        next: (response: PullSheetData) => {
          this.messageService.add({
            key: 'tc',
            severity: 'success',
            summary: 'E-mail Sent',
            detail: '\n' +
                'Notice! The text contains a link and this email may end up in the Junk/Spam on the other side. \n' +
                'It would be nice if you tell the other party to check Junk/Spam if they don\'t see the email.',
            life: 10000,
          });
          this.mailissend = true
          this.modalService.closeCPS()
        },
        error: error => {
          console.error('Error:', error);
        }
      });
    }
    if (this.phoneControl.value?.trim() !== "") {
      const data: SmsDetails = {
        to: this.phoneControl.value?.trim() || "",
        from: "13175970635" ,
        text: SelfData.account[0].data[0].company + "\n" + 'Please see "Pull Sheet" attachment' + "\n",
        key: key,
        accountid: this.getAccountID(),
        userid: SelfData.account[0].data[0].id,
        sendoutid: this.sendoutid,
        status: 0,
        isactivity: true,
        ctrl: 0
      };
      this.serviceservice.smssend(data).subscribe({
        next: (response: PullSheetData) => {
          if (!this.mailissend)
          {
            this.messageService.add({
              key: 'tc',
              severity: 'success',
              summary: 'The message has been sent.',
              detail: '\n' +
                'Notice! The message has been sent to the specified number.',
              life: 10000,
            });
          }
          this.modalService.closeCPS()
        },
        error: error => {
          console.error('Error:', error);
        }
      });
    }
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription?.unsubscribe()
      this.yourappsService.setSelectedSendOut(null)
    }
  }

  ngOnInit(): void {
    this.subscription = this.yourappsService.selsendout.subscribe(value => {
      if (value) {
        this.buttondisabled = true
        console.log("VALUE", value)
        this.sendoutid = value?.["id"]
        this.sendoutControl.setValue(value?.["Delivery"])
      } else {
        console.log("NULL")
        this.buttondisabled = false
      }

        // this.sendoutControl.setValue(value?.["Delivery"])
        // this.sendoutid = value?.["id"]
        // this.subscription?.unsubscribe()

    });
  }

  onInfoButtonClick() {
    window.open('https://fm.welcomeapps.net/FAQ/CreatePull.html', '_blank');
  }
}
