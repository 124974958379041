<button mat-icon-button type="button" class="close-btn" (click)="modalService.userClose()">
  <mat-icon>close</mat-icon>
</button>
<p class="sample-manager-customer">{{ caption }}</p>
<div class="maindiv">
  <div class="componentsdiv">
    <div class="componentsdiv2">
      <label class="txtdiv">First name</label>
      <nz-input-group>
        <input class="cmdiv" type="text" [(ngModel)]="firstname" nz-input/>
      </nz-input-group>

    </div>
    <div class="componentsdiv2">
      <label class="txtdiv">Last name</label>
      <nz-input-group>
        <input class="cmdiv" type="text" [(ngModel)]="lastname" nz-input/>
      </nz-input-group>
    </div>
  </div>

  <div class="componentsdiv">
    <div class="componentsdiv2">
      <label class="txtdiv">Email</label>
      <nz-input-group>
        <input class="cmdiv" type="email" [(ngModel)]="email" nz-input/>
      </nz-input-group>
    </div>
    <div class="componentsdiv2">
      <label class="txtdiv">Phone</label>
      <nz-input-group>
        <input class="cmdiv" type="tel" [(ngModel)]="phone" nz-input/>
      </nz-input-group>
    </div>
  </div>

  <div class="componentsdiv">
    <div class="componentsdiv2">
      <label class="txtdiv">Instagram</label>
      <nz-input-group>
        <input class="cmdiv" type="tel" [(ngModel)]="instagram" nz-input/>
      </nz-input-group>
    </div>
    <div class="componentsdiv2">
      <label class="txtdiv">Facebook</label>
      <nz-input-group>
        <input class="cmdiv" type="text" [(ngModel)]="facebook" nz-input/>
      </nz-input-group>
    </div>
  </div>

  <!--            5line-->

  <div class="componentsdiv">
    <div class="componentsdiv2">
      <label class="txtdiv">TikTok</label>
      <nz-input-group>
        <input class="cmdiv" type="tel" [(ngModel)]="tiktok" nz-input/>
      </nz-input-group>
    </div>
    <div class="componentsdiv2">
      <label class="txtdiv">X (Twitter)</label>
      <nz-input-group>
        <input class="cmdiv" type="text" [(ngModel)]="twitter" nz-input/>
      </nz-input-group>
    </div>
  </div>

  <!--            6line-->

  <div class="componentsdiv">
    <div class="componentsdiv2">
      <label class="txtdiv">YouTube</label>
      <nz-input-group>
        <input class="cmdiv" type="tel" [(ngModel)]="youtube" nz-input/>
      </nz-input-group>
    </div>
    <div class="componentsdiv2">

    </div>
  </div>

  <div class="dividerdiv">
    <nz-divider nzPlain></nz-divider>
  </div>

  <div class="componentsdiv">
    <div class="componentsdiv2">
      <label class="txtdiv">Street address</label>
      <nz-input-group>
        <input class="cmdiv" type="text" [(ngModel)]="address" nz-input/>
      </nz-input-group>

    </div>
    <div class="componentsdiv2">
      <label class="txtdiv">Street address2</label>
      <nz-input-group>
        <input class="cmdiv" type="text" [(ngModel)]="address2" nz-input/>
      </nz-input-group>
    </div>
  </div>
  <!--            3line-->

  <div class="componentsdiv">
    <div class="componentsdiv2">
      <label class="txtdiv">Suburb</label>
      <nz-input-group>
        <input class="cmdiv" type="text" [(ngModel)]="suburb" nz-input/>
      </nz-input-group>

    </div>
    <div class="componentsdiv2">
      <label class="txtdiv">City</label>
      <nz-input-group>
        <input class="cmdiv" type="text" [(ngModel)]="city" nz-input/>
      </nz-input-group>
    </div>
  </div>

  <!--            4line-->
  <div class="componentsdiv">
    <div class="componentsdiv2">
      <label class="txtdiv">ZIP code</label>
      <nz-input-group>
        <input class="cmdiv" type="text" [(ngModel)]="zipcode" nz-input/>
      </nz-input-group>

    </div>
    <div class="componentsdiv2">
      <label class="txtdiv">State</label>
      <nz-input-group nzSearch>
        <nz-select nzShowSearch [nzShowArrow]="true" class="cmdiv"
          [(ngModel)]="statesstring">
          <nz-option *ngFor="let option of (states ?? [])" [nzLabel]="option['state']"
            [nzValue]="option['state']"></nz-option>
        </nz-select>
      </nz-input-group>
    </div>
  </div>

  <!--            5line-->
  <div class="componentsdiv">
    <div class="componentsdiv2">
      <label class="txtdiv">Country</label>
      <nz-select nzShowSearch [nzShowArrow]="true" class="cmdiv"
        (ngModelChange)="onOptionSelected($event)"
        [(ngModel)]="countrystring">
        <nz-option *ngFor="let option of (countries ?? [])" [nzLabel]="option['country']"
          [nzValue]="option['country']"></nz-option>
      </nz-select>

    </div>
    <div class="componentsdiv2">

    </div>
  </div>

  <div class="dividerdiv">
    <nz-divider nzPlain></nz-divider>
  </div>

  <div class="componentsdiv">
    <div class="componentsdiv2">
      <label class="txtdiv">Profile</label>
      <nz-input-group [nzAddOnAfter]="suffixCategoryIconButton">
        <nz-select nzShowSearch nzAllowClear [nzShowArrow]="true" class="cmdiv"
          [(ngModel)]="selectProfile">
          <nz-option *ngFor="let option of profilesResult" [nzLabel]="option['description']"
            [nzValue]="option['description']"></nz-option>
        </nz-select>
      </nz-input-group>
      <ng-template #suffixCategoryIconButton>
        <button class="buttonclass" nz-button nzType="default" nzSearch (click)="onProfileClick()">
          <!--              <span nz-icon nzType="unordered-list" nzTheme="outline"></span>-->
          <i nz-icon nzType="ellipsis" nzTheme="outline"></i>
        </button>
      </ng-template>
    </div>
    <div class="componentsdiv2">

    </div>
  </div>

</div>

<div class="footerdiv">
  <button nz-button nzType="default" (click)="onAddUserClick()">{{caption}}</button>
</div>
