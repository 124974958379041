import { Component } from '@angular/core';
import { ModalserviceService } from '../../services/modalservice.service';

@Component({
  selector: 'app-collection',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.scss'],
})
export class CollectionComponent {
  constructor(public modalService: ModalserviceService) {}
}
