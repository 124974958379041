import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatStepper} from "@angular/material/stepper";
import {FormBuilder, FormGroup} from "@angular/forms";
import {ModalserviceService} from "../../services/modalservice.service";
import {HashService} from "../../services/hash.service";
import {MessageboxComponent} from "../../modals/messagebox/messagebox.component";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ConfirmationService, ConfirmEventType, MessageService} from "primeng/api";
import {NgxImageCompressService} from "ngx-image-compress";
import { HttpClient } from "@angular/common/http";
import {RequestSampleRequest, SelfData, SendOut1Data, SetSampleList} from "../../models/struct";

@Component({
    selector: 'app-createrequestdialog',
    templateUrl: './createrequestdialog.component.html',
    styleUrls: ['./createrequestdialog.component.scss']
})
export class CreaterequestdialogComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('stepper') stepper!: MatStepper;
    myForm: FormGroup;
    stepIndex = 0;
    selectedid: number = -1
    showdialog: boolean = false
    createbuttoncaption = 'Next';
    iSampleDataResult: SetSampleList[] = [];
    showacceptbutton: boolean = false
    acceptbuttoncaption = "Accept"

    constructor(
        public modalService: ModalserviceService,
        public fb: FormBuilder,
        private hashService: HashService,
        public messageBox: MessageboxComponent,
        private serviceservice: ServiceService,
        private yourappsService: YourappsserviceService,
        private cdr: ChangeDetectorRef,
        private messageService: MessageService,
        private imageCompressService: NgxImageCompressService,
        private confirmationService: ConfirmationService,
        private el: ElementRef
    ) {
        SelfData.saveonclose = false
        this.myForm = this.fb.group({});
        if (SelfData.sendoutopentype === 0) {
            this.createbuttoncaption = "Next"
            this.stepIndex = 0
            modalService.createSendOutStep1();
            modalService.closeSendOutStep2();
        } else {
            if (SelfData.sendoutstep === 0) {
                this.stepIndex = 0
                this.modalService.createSendOutStep1();
                this.modalService.closeSendOutStep2();
            } else if (SelfData.sendoutstep === 1) {
                this.stepIndex = 1
                this.modalService.closeSendOutStep1();
                this.modalService.createSendOutStep2();
            } else if (SelfData.sendoutstep === 2) {
                this.stepIndex = 2
                this.modalService.closeSendOutStep1();
                this.modalService.closeSendOutStep2();
                this.modalService.createSendOutStep3();
            }
            this.acceptbuttoncaption = "Accept"
            this.createbuttoncaption = "Update"
            this.showacceptbutton = true
        }

        var accountid: number = 0;
        if (SelfData.account[0].data[0].parentid == 0) {
            accountid = SelfData.account[0].data[0].id;
        } else {
            accountid = SelfData.account[0].data[0].parentid;
        }

        this.serviceservice.setSampleList({accountid: accountid, page: 1, pageSize: 1000000, forceRefresh: false, samplestyle: SelfData.samplestyle}).subscribe({
            next: (value) => {
                if (value) {
                    this.iSampleDataResult[0] = value;
                    SelfData.sampledata = this.iSampleDataResult;
                } else {
                    console.log('The response is empty or undefined.');
                }
            },
            error: (error) => {
                console.error('Error occurred during the subscription:', error);
            },
        });
    }

    ngAfterViewInit(): void {
    }

    ngOnInit(): void {
    }

    private getAccountID(): number {
        return SelfData.account[0].data[0].parentid === 0
            ? SelfData.account[0].data[0].id
            : SelfData.account[0].data[0].parentid;
    }

    onButtonAcceptClick() {
        this.showdialog = true
        this.cdr.detectChanges()
        this.confirmationService.confirm({
            message: `<strong>Would you like to accept this Request?</strong>`,
            header: 'Information',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                const requestData: RequestSampleRequest = {
                    ctrl: 2,
                    sampleid: 0,
                    accountid: this.getAccountID(),
                    userid: SelfData.account[0].data[0].id,
                    sendoutid: SelfData.sendoutid,
                    itemcount: 0,
                    isaccepted: false
                };

                this.serviceservice.prcRequestSamples(requestData).subscribe(response => {
                    // Loop through the selectedsample array
                    SelfData.setSelectedSample.forEach(sample => {
                        const requestData: RequestSampleRequest = {
                            ctrl: 0,
                            sampleid: sample?.["ID"],
                            accountid: this.getAccountID(),
                            userid: SelfData.account[0].data[0].id,
                            sendoutid: SelfData.sendoutid,
                            itemcount: sample?.["ItemCount"],
                            isaccepted: sample?.["isaccepted"]
                        };

                        this.serviceservice.prcRequestSamples(requestData).subscribe(response => {
                            console.log("!2!")
                        });
                    });
                    const fields = {
                        ctrl: 2,
                        id: SelfData.sendoutid,
                        check1: false,
                        check2: false,
                        check3: false,
                        department: '',
                        ship: '',
                        shipto: '',
                        tod: '',
                        pe: '',
                        companyname: '',
                        addresslabel: '',
                        costofshipping: '',
                        cim: '',
                        publication: '',
                        estylist: '',
                        photographer: '',
                        pel: '',
                        pet: '',
                        issue: '',
                        trackingNumber: '',
                        return: '',
                        notes: '',
                        sender: '',
                        requestedby: '',
                        sendOutType: '',
                        currancy: '',
                        reservationdate: SendOut1Data.reservationdateControl,
                        returndate: SendOut1Data.returndateControl,
                        datetimeCtrl: SendOut1Data.datetimeCtrl,
                        datetimeCtrl1: SendOut1Data.datetimeCtrl1,
                        datetimeCtrl2: SendOut1Data.datetimeCtrl2,
                        datetimeCtrl3: SendOut1Data.datetimeCtrl3,
                        datetimeCtrl4: SendOut1Data.datetimeCtrl4,
                        accountid: this.getAccountID(),
                        userid: SelfData.account[0].data[0].id,
                        sendouttypeid: SelfData.selectedSendouttype, //droebit
                        isactivity: true,
                        celebriti: "",
                        sendoutid: 0,
                        isaccepted: true
                    };
                    this.serviceservice
                        .prcRequest(fields)
                        .subscribe((value) => {
                            SelfData.sendoutid = value.data
                            SelfData.saveonclose = true
                            SelfData.isacceptedstatus = true
                            this.showdialog = false
                            this.cdr.detectChanges()
                            this.modalService.closeReques()
                            this.yourappsService.getReloadRequestList()
                        });
                });
            },
            reject: (type: any) => {
                if (type == ConfirmEventType.REJECT) {
                    SelfData.saveonclose = false
                    this.showdialog = false
                    this.cdr.detectChanges()
                } else if (type == ConfirmEventType.CANCEL) {
                    SelfData.saveonclose = false
                    this.showdialog = false
                    this.cdr.detectChanges()
                }
            }
        })
    }

    nextClick() {
        if (SelfData.setSelectedSample.some(sample => sample["isok"] === 0)) {
            this.messageService.add({
                key: 'tc',
                severity: 'error',
                summary: 'Information',
                // detail: `Sample ${barcodeValue} already scanned by user ${foundData['user']} for a new delivery or reservation.`,
                detail: `Some items from the list are on loan!`,
                life: 10000,
            });
        } else {
            if (SelfData.sendoutopentype === 0) {
                SelfData.saveonclose = true
                if (this.stepIndex == 0) {
                    this.modalService.closeSendOutStep1();
                    this.modalService.createSendOutStep2();
                }
              console.log("SelfData.sendoutopentype", SelfData.sendoutopentype)
              console.log("this.stepIndex", this.stepIndex)
                if (this.stepIndex < 1) {
                    this.stepIndex += 1;
                    if (this.stepIndex === 1) {
                        if (this.selectedid != 2) {
                            this.modalService.closeSendOutStep1();
                            this.modalService.createSendOutStep2();
                            this.createbuttoncaption = 'Create';
                        } else {
                            this.yourappsService.setSelectedSamples(SelfData.sampledata)
                            this.stepIndex -= 1;
                            this.modalService.invoiceOpen()
                        }
                    } else {
                        this.showacceptbutton = false
                        this.createbuttoncaption = 'Next';
                    }

                } else {
                    this.submit(0);
                }
            } else {
                this.modalService.closeReques()
            }
        }
    }

    backClick() {
        if (SelfData.setSelectedSample.some(sample => sample["isok"] === 0)) {
            this.messageService.add({
                key: 'tc',
                severity: 'error',
                summary: 'Information',
                // detail: `Sample ${barcodeValue} already scanned by user ${foundData['user']} for a new delivery or reservation.`,
                detail: `Some items from the list are on loan!`,
                life: 10000,
            });
        } else {
            if (this.stepIndex == 1) {
                this.modalService.createSendOutStep1();
                this.modalService.closeSendOutStep2();
                this.modalService.closeSendOutStep3();
            } else if (this.stepIndex == 2) {
                this.modalService.closeSendOutStep1();
                this.modalService.createSendOutStep2();
                this.modalService.closeSendOutStep3();
            }
            this.stepIndex -= 1;
            this.createbuttoncaption = 'Next';
        }
    }

    submit(type: number = 0) {
      this.modalService.closeReques()
      this.yourappsService.getReloadRequestList()
    }

    onButtonCloseClick() {
        if (SelfData.sendoutrequestid > 0) {
            this.modalService.closeReques()
            return
        }
        this.showdialog = true
        this.cdr.detectChanges()
        this.confirmationService.confirm({
            message: `<strong>Would you like to Cancel or Save this Request form?</strong>`,
            header: 'Information',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                SelfData.saveonclose = true
                this.cdr.detectChanges()
                this.modalService.closeReques()
            },
            reject: (type: any) => {
                if (type == ConfirmEventType.REJECT) {
                    SelfData.saveonclose = false
                    this.cdr.detectChanges()
                    this.modalService.closeReques()
                } else if (type == ConfirmEventType.CANCEL) {

                }
            }
        })

        // this.modalService.createsendoutclose()
    }

    ngOnDestroy() {
      this.modalService.closeSendOutStep3();
    }

  protected readonly SelfData = SelfData;

  onInfoButtonClick() {
    window.open('https://fm.welcomeapps.net/FAQ/CreateRequest.html', '_blank');
  }
}
