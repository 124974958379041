import {AfterViewInit, ChangeDetectorRef, Component, NgZone, OnInit, ViewChild} from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder} from "@angular/forms";
import {HashService} from "../../services/hash.service";
import {MessageboxComponent} from "../../modals/messagebox/messagebox.component";
import {ServiceService} from "../../services/service.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {
  GetAddressListData, GetCompanyList,
  GetSocialMediaList, PrcContacts,
  SelfData,
  SetContactsList,
  SetContactsListData,
  SetSampleListData, Speener
} from "../../models/struct";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {Table} from "primeng/table";
import {Subscription} from "rxjs";
import {ConfirmationService, ConfirmEventType} from "primeng/api";

@Component({
  selector: 'app-contactslisttable',
  templateUrl: './contactslisttable.component.html',
  styleUrls: ['./contactslisttable.component.scss']
})
export class ContactslisttableComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator | null = null;
  @ViewChild('dt1') dt1!: Table;
  filtertext: string = ""
  private subscription?: Subscription;
  currancecontact: SetContactsListData[] = [];
  dataSource = new MatTableDataSource<SetContactsListData>(SelfData.contactdata[0].data);
  displayedColumns: string[] = [
    'checkbox',
    'actions',
    'firstname',
    'lastname',
    'companyname',
    'email',
    'jobtitle',
    'category',
    'address1',
    'address2',
    'city',
    'state',
    'zip',
    'directtelephone',
  ];

  defaultimagelink: string = ""
  defaultname: string = ""

  sizes!: any[];

  selectedSize: any = '';

  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private hashService: HashService,
    public messageBox: MessageboxComponent,
    private serviceservice: ServiceService,
    private _snackBar: MatSnackBar,
    private yourappsService: YourappsserviceService,
    private ngZone: NgZone,
    private confirmationService: ConfirmationService,
    private cdRef: ChangeDetectorRef
  ) {

    this.sizes = [
      { name: 'Small', class: 'p-datatable-sm' }
    ];
    this.selectedSize = this.sizes[0]

    this.dataSource = new MatTableDataSource<SetContactsListData>(
      SelfData.contactdata[0].data
    );
  }

  public get setdataSource() {
    return this.dataSource;
  }

  get firstContactsData(): SetContactsListData[] {
    if (this.SelfData && this.SelfData.contactdata && this.SelfData.contactdata[0] && this.SelfData.contactdata[0].data) {
      return this.SelfData.contactdata[0].data;
    }
    return [];
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
      ? SelfData.account[0].data[0].id
      : SelfData.account[0].data[0].parentid;
  }

  onInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;
    this.dt1.filterGlobal(value, 'contains');
  }

  clear(table: Table) {
    table.clear();
    this.filtertext = ""
  }

  iSMList: GetSocialMediaList[] = [];

  loadSocialMediaData(element: any) {
    this.serviceservice.setSocialMediaList({ contactid: element?.["id"] })
      .subscribe({
        next: (value) => {
          if (value) {
            this.iSMList[0] = value;
            SelfData.socialmedia = this.iSMList;
            console.log(SelfData.socialmedia)
            const platforms = ["Instagram", "FaceBook", "TikTok", "YouTube", "Twitter"];
            for (const platform of platforms) {
              const foundPlatform = SelfData.socialmedia[0].data.find(item => item.platform === platform);
              if (foundPlatform) {
                this.defaultimagelink = foundPlatform.avatarLink;
                this.defaultname = foundPlatform.name
                break;
              }
            }
          } else {
            console.log('The response is empty or undefined.');
          }
        },
        error: (error) => {
          console.error('Error occurred during the subscription:', error);
        },
      });
  }

  openLink(link: string): void {
    window.open(link, "_blank");  // This opens the link in a new window or tab.
    // window.location.href = link;  // Use this instead if you want to navigate to the link in the same window.
  }

  getColor(index: number): string {
    return index % 2 === 0 ? '#efefef' : '#dcdcdc';
  }

  iContactsResult: SetContactsList[] = [];

  onButtonEditViewClic(item: any, type: number) {
    this.yourappsService.contact$.subscribe((value) => {
        Speener.sendoutvisiblemode = true
        this.modalService.samplemanageropen();
        this.yourappsService.setSampleAndContavcts(false, true, false, false, false, false, false, false, false);
        this.serviceservice.setContactsList({accountid: this.getAccountID(), contactsid: 0})
          .subscribe({
            next: (value) => {
              if (value) {
                this.iContactsResult[0] = value;
                Speener.sendoutvisiblemode = false
                SelfData.contactdata = this.iContactsResult;
                this.modalService.createContactLabel()

              } else {
                console.log('The response is empty or undefined.');
              }
            },
            error: (error) => {
              console.error('Error occurred during the subscription:', error);
            },
          });
    })
    this.yourappsService.selectContact(item, type)
    this.modalService.createcontactopen();
  }

  onDeleteButtonClick(item: any) {
    this.confirmationService.confirm({
      message: `<strong>Are you sure you want to delete this contact?</strong>`,
      header: 'Delete Contact',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        Speener.sendoutvisiblemode = true
        console.log("item", item.id)
        this.serviceservice.prcContacts({
          ctrl: 2,
          id: item.id,
          department: '', // You can replace with appropriate form control value
          pronoumid: 0,   // You can replace with appropriate form control value
          salutation: '',
          spouse: '',
          firstname: '',
          assistantname: '',
          lastname: '',
          assistanttitle: '',
          mobile: '',
          assistantmobile1: '',
          assistantmobile2: '',
          email: '',
          alternativeemail: '',
          assistentemail: '',
          einvoicingcode: '',
          vipcelebrityemail: '',
          comments: '',
          sidenotes: '',
          jobtitle: '',
          categoryid: 0,
          jobdepartmentid: 0,
          typeid: 0,
          regionid: 0,
          refferedbyid: 0,
          associatedcontactsid: 0,
          contactgroupsid: 0,
          instagram: '',
          facebook: '',
          tiktok: '',
          youtube: '',
          twitter: '',
          linkedin: '',
          primaryaddresstype: 0,  // You can replace with appropriate form control value
          companyinfoid: 0,  // You can replace with appropriate form control value
          homeinfoid: 0,  // You can replace with appropriate form control value
          otherinfo: 0,  // You can replace with appropriate form control value
          activity: false,
          accountid: this.getAccountID(),
          taxid: "",
          companyname: "",
          companycareof: "",
          companyaddress1: "",
          companyaddress2: "",
          companycity: "",
          companystate: "",
          companyzipcode: "",
          companycountry: "",
          companydirectphone: "",
          companywebsite: "",
          companyemail: "",
          companyotheremail: "",
          cardname: "",
          cardnumber: "",
          expdate: "",
          cvccode: "",
          billingaddress1: "",
          billingaddress2: "",
          billingcity: "",
          billingcountry: "",
          billingstate: "",
          billingzipcode: "",
        })
          .subscribe((value: PrcContacts) => {
            this.serviceservice.setContactsList({accountid: this.getAccountID(), contactsid: 0})
              .subscribe({
                next: (value) => {
                  if (value) {
                    this.iContactsResult[0] = value;
                    Speener.sendoutvisiblemode = false
                    SelfData.contactdata = this.iContactsResult;
                    this.modalService.createContactLabel()

                  } else {
                    console.log('The response is empty or undefined.');
                  }
                },
                error: (error) => {
                  console.error('Error occurred during the subscription:', error);
                },
              });
          });
      },
      reject: (type: any) => {
        if (type == ConfirmEventType.REJECT) {

        } else if (type == ConfirmEventType.CANCEL) {

        }
      }

    });
    setTimeout(() => {
      this.applyLocalStyles();
    }, 0);
  }

  private applyLocalStyles() {
    setTimeout(() => {
      const messageElements = document.querySelectorAll('.p-confirm-dialog-message');
      messageElements.forEach((element) => {
        element.setAttribute('style', 'font-size: 16px; color: red;');
      });
    }, 0);

    const iconElements = document.querySelectorAll('.p-confirm-dialog-icon');
    iconElements.forEach((element) => {
      element.setAttribute('style', 'color: red');
    });
  }

  ngOnInit(): void {
  }

  protected readonly SelfData = SelfData;

  onInfoButtonClick() {
    window.open('https://fm.welcomeapps.net/FAQ/ContactManager.html', '_blank');
  }
}
