import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder} from "@angular/forms";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MessageService} from "primeng/api";
import SignaturePad from "signature_pad";
import {SelfData} from "../../models/struct";


@Component({
  selector: 'app-signatureframedialog',
  templateUrl: './signatureframedialog.component.html',
  styleUrls: ['./signatureframedialog.component.scss']
})
export class SignatureframedialogComponent implements OnInit, AfterViewInit, OnDestroy {

  drawComplete() {
    // will be notified of szimek/signature_pad's onEnd event
    console.log('Finished drawing');
  }

  drawStart() {
    // will be notified of szimek/signature_pad's onBegin event
    console.log('Started drawing');
  }

  signatureNeeded!: boolean;
  signaturePad!: SignaturePad;
  @ViewChild('canvas') canvasEl!: ElementRef;
  signatureImg!: string;

  startDrawing(event: Event) {
    // works in device not in browser
  }

  moved(event: Event) {
    // works in device not in browser
  }

  clearPad() {
    this.signaturePad.clear();
  }

  constructor(
      public modalService: ModalserviceService,
      public fb: FormBuilder,
      private serviceservice: ServiceService,
      private yourappsService: YourappsserviceService,
      private _snackBar: MatSnackBar,

      private messageService: MessageService
  ) {

  }

  savePad() {
    const base64Data = this.signaturePad.toDataURL();
    // SelfData.signatureimage = base64Data
    this.yourappsService.setSignatureImage(base64Data)
    this.modalService.closeSignature()
    // this.signatureNeeded = this.signaturePad.isEmpty();
    // if (!this.signatureNeeded) {
    //   this.signatureNeeded = false;
    // }
  }



  ngAfterViewInit(): void {
    this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
  }

}
