import { Component } from '@angular/core';
import { ModalserviceService } from '../../services/modalservice.service';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent {
  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder
  ) {}
}
