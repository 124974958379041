<button mat-icon-button type="button" class="close-btn" (click)="modalService.closelsbframe()">
    <mat-icon>close</mat-icon>
</button>
<p class="sample-manager-text-rrc">Create Look Book Sender</p>

<div class="row">
    <div class="forma-group">
        <mat-form-field class="selector-full-width-cps" appearance="fill">
            <mat-label>Email</mat-label>
            <input type="email" matInput [formControl]="emailFormControl"/>
            <mat-error *ngIf="emailFormControl.hasError('email')">
                Please enter a valid email address
            </mat-error>
        </mat-form-field>
    </div>

  <div class="forma-group">
    <mat-form-field class="selector-full-width-cps" appearance="fill">
      <mat-label>Name</mat-label>
      <input type="text" matInput [formControl]="nameControl"/>
    <mat-error *ngIf="nameControl.hasError('required')">
      Name is <strong>required</strong>
    </mat-error>
    </mat-form-field>
  </div>
</div>

<div class="row">
  <div class="forma-group">
    <mat-form-field class="selector-full-width-cps" appearance="fill">
      <mat-label>Phone</mat-label>
      <input type="tel" matInput [formControl]="phoneControl"/>
    </mat-form-field>
  </div>

  <div class="forma-group">
    <mat-form-field class="selector-full-width-cps" appearance="fill">
      <mat-label>Look Book</mat-label>
      <input type="text" matInput [formControl]="sendoutControl" [readonly]="true"/>
      <button mat-stroked-button type="button" class="add-button" [disabled]="buttondisabled" matSuffix (click)="onButtonAddClick()">
        Add Look Book
        <!--                <mat-icon class="center-icon">add</mat-icon>-->
      </button>
      <mat-error *ngIf="sendoutControl.hasError('required')">
        Look Book is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </div>
</div>

<div class="card-email">
    <p-editor [(ngModel)]="text" [style]="{ height: '185px' }">
        <!--        <ng-template pTemplate="header">-->
        <!--        <span class="ql-formats">-->
        <!--            <button type="button" class="ql-bold" aria-label="Bold"></button>-->
        <!--            <button type="button" class="ql-italic" aria-label="Italic"></button>-->
        <!--            <button type="button" class="ql-underline" aria-label="Underline"></button>-->
        <!--        </span>-->
        <!--        </ng-template>-->
    </p-editor>
</div>

<div class="button-container-label">
    <button mat-raised-button class="button-settings" type="button" (click)="onButtonSendClick()">
        Send
    </button>
</div>


<p-confirmDialog *ngIf="showdialog" #cd [style]="{width: '30vw'}" [closable]="true">
  <ng-template pTemplate="footer">
    <div class="button-container-rr">
      <button mat-raised-button class="button-settings1" style="margin-right: 10px; width: 25%"
        (click)="btnNoClick(); cd.reject()">
        No
      </button>
      <button mat-raised-button class="button-settings1" style="margin-right: 10px; width: 40%"
        (click)="btnShowClick(); cd.reject()">
        <span *ngIf="messagemode === 0" >Show me old collections</span>
        <span *ngIf="messagemode === 1" >Show me new collections</span>
      </button>
      <button mat-raised-button class="button-settings1" style="width: 25%" (click)="cd.accept()">
        Send anyway
      </button>
    </div>
  </ng-template>
</p-confirmDialog>
