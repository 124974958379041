<button mat-icon-button type="button" class="close-btn"  (click)="modalService.closeEmailContacts()">
  <mat-icon>close</mat-icon>
</button>
<span class="sample-manager-editor">Contacts List
  <div *ngIf="samplewait">
    <p-progressSpinner class="spinner1" styleClass="w-2rem h-2rem" strokeWidth="5"
      fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
  </div>
</span>
<div class="card example-container-so">
  <p-table
    #dt1
    *ngIf="SelfData?.emailcontacttlist?.[0]?.data"
    [value]="SelfData.emailcontacttlist[0].data"
    [paginator]="true"
    [rows]="100"
    [scrollable]="true"
    [scrollHeight]="'calc(70svh - 200px)'"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [rowsPerPageOptions]="[100, 500, 1000]"
    [styleClass]="'p-datatable-striped p-datatable-sm'"
    [globalFilterFields]="['Contact Name', 'Phone Number', 'Creation date', 'email', 'Job Title', 'Category', 'Job Department']"
    selectionMode="single"
    (onFilter)="onFilter($event)"
    [tableStyle]="{'min-width': '50rem'}">
    <ng-template pTemplate="caption">
      <div class="flex">
        <!--                    <span class="p-inputgroup-addon custom-hover" matRipple (click)="onButtonAddClick()">-->
        <!--                        <i class="pi pi-plus" style="color: #000000"></i></span>-->
        <button type="button" nz-button (click)="onAddAllClick()">
          Add All ({{emailcount}} Emails)
        </button>
        <span class="p-input-icon-left ml-auto">
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">
              <i class="pi pi-search" style="color: #000000"></i>
            </span>
            <input pInputText type="text" (input)="onInput($event)" placeholder="Filter" [(ngModel)]="filtertext"/>
            <span class="p-inputgroup-addon custom-hover-red" matRipple (click)="clear(dt1)">
              <i class="pi pi-times" style="color: #000000"></i></span>
          </div>
        </span>

      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th style="width:3%"></th>
        <th style="width:7.5%">Options</th>
        <th style="width:17.6%">Contact Name</th>
        <th style="width:17.6%">Phone Number</th>
        <th style="width:17.6%">Email</th>
        <th style="width:17.6%">Job Title</th>
        <th style="width:10%">Category</th>
        <th style="width:8%">Job Department</th>
        <!--                <th>Name</th>-->
        <!--                <th>Category</th>-->
        <!--                <th>Quantity</th>-->
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-product>
      <tr [pSelectableRow]="SelfData.emailcontacttlist[0].data">
        <td>
          <ng-container *ngIf="product?.['Has Email'] == 1">
            <i class="pi pi-check" style="color: green"></i> <!-- Зеленая галочка -->
          </ng-container>
          <ng-container *ngIf="product?.['Has Email'] == 0">
            <i class="pi pi-exclamation-triangle" style="color: orange"></i> <!-- Желтый восклицательный знак -->
          </ng-container>
        </td>
        <td>
          <button  type="button" nz-button (click)="onSelectedItemCLick(product)">
            Add
          </button>
        </td>
        <td>{{product?.["Contact Name"]}}</td>
        <td>{{product?.["Phone Number"]}}</td>
        <td>{{product?.["email"]}}</td>
        <td>{{product?.["Job Title"]}}</td>
        <td>{{product?.["Category"]}}</td>
        <td>{{product?.["Job Department"]}}</td>
      </tr>
    </ng-template>
  </p-table>
</div>


<p-confirmDialog #cd [style]="{width: '25vw'}" [closable]="true">
  <ng-template pTemplate="footer">
    <div class="button-container-request">
      <button mat-raised-button class="button-secondary" style="margin-right: 10px; width: 50%" (click)="cd.reject()">
        No
      </button>
      <button mat-raised-button class="button-primary" style="margin-right: 0; width: 50%" (click)="cd.accept()">
        Yes
      </button>
    </div>
  </ng-template>
</p-confirmDialog>
