import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {DateRanges} from "ngx-daterangepicker-material/daterangepicker.component";
import moment from "moment";
import {
  GetBarcodeList,
  GetCollectionList, GetCompanyList,
  GetDesignerList, GetFabricList, GetImageList, GetLBMain, GetManuList, GetMiscList, GetRegionList,
  GetSeasonList, GetSizeRangeList, GetStyleList, GetSWSList,
  GetSystemFields, GetSystemUsers, GettodList,
  GetTypeList,
  SelfData, SetContactsList, SetCustomFieldsValue, UserPreferencesRequest
} from "../../models/struct";
import {map, Observable, of, startWith} from "rxjs";
import {ModalserviceService} from "../../services/modalservice.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {ServiceService} from "../../services/service.service";
import { HttpClient } from "@angular/common/http";
import {OverlayPanel} from "primeng/overlaypanel";

interface ColorArray {
  colorname: string;
  colorcode: string;
}

interface Gender {
  value: string;
  viewValue: string;
}

interface SampleStatus {
  value: string;
}

@Component({
  selector: 'app-filterpanel',
  templateUrl: './filterpanel.component.html',
  styleUrl: './filterpanel.component.scss'
})
export class FilterpanelComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('op') overlayPanel!: OverlayPanel;
  colorarray: ColorArray[] = [
    {colorname: 'White', colorcode: '#ffffff'},
    {colorname: 'Black ', colorcode: '#000000'},
    {colorname: 'Yellow', colorcode: '#FFFF00'},
    {colorname: 'Purple', colorcode: '#800080'},
    {colorname: 'Orange ', colorcode: '#FFA500'},
    {colorname: 'Blue ', colorcode: '#2196f3'},
    {colorname: 'Light Blue ', colorcode: '#03a9f4'},
    {colorname: 'Red', colorcode: '#ff0000'},
    {colorname: 'Buff', colorcode: '#DAA06D'},
    {colorname: 'Gray ', colorcode: '#808080'},
    {colorname: 'Green ', colorcode: '#00FF00'},
    {colorname: 'Pink', colorcode: '#FFC0CB'},
    {colorname: 'Violet', colorcode: '#8F00FF'},
    {colorname: 'Brown', colorcode: '#964B00'},
    {colorname: 'Silver', colorcode: '#C0C0C0'},
    {colorname: 'Cream', colorcode: '#FFFDD0'},
    {colorname: 'Gold', colorcode: '#FFD700'},
    {colorname: 'Beige', colorcode: '#F5F5DC'},
    {colorname: 'Navy', colorcode: '#202A44'},
  ];

  gender: Gender[] = [
    {value: 'Man', viewValue: 'Man'},
    {value: 'Unisex', viewValue: 'Unisex'},
    {value: 'Women', viewValue: 'Women'},
  ];

  samplestatus: SampleStatus[] = [
    {value: 'Returned'},
    {value: 'Loan'},
    {value: 'Pending'},
  ];

  countries: any[] = [];
  states: any[] = [];

  group: FormGroup;
  departmentFormControl = new FormControl({value: '', disabled: true}, [
    Validators.required,
  ]);
  desingerFormControl = new FormControl({value: '', disabled: false}, []);
  collectionFormControl = new FormControl({value: '', disabled: false}, []);
  seasonFormControl = new FormControl({value: '', disabled: false}, []);
  cimControl = new FormControl<string>('');
  typeFormControl = new FormControl({value: '', disabled: false}, []);
  colorControl = new FormControl({value: '', disabled: false}, []);
  sizeControl = new FormControl({value: '', disabled: false}, []);
  fabricControl = new FormControl({value: '', disabled: false}, []);
  genderControl = new FormControl({value: '', disabled: false}, []);
  companynameControl = new FormControl('', []);
  countri2Control = new FormControl<string>('');
  estylistControl = new FormControl<string>('');
  pelControl = new FormControl<string>('');
  peControl = new FormControl({value: '', disabled: false});
  manuControl = new FormControl<string>('');
  check1 = new FormControl(false);
  todControl = new FormControl({value: '', disabled: false}, []);
  miscControl = new FormControl<string>('');
  patternControl = new FormControl<string>('');
  photographerControl = new FormControl<string>('');
  publicationControl = new FormControl<string>('');
  senderFormControl = new FormControl('', []);
  requestedbyFormControl = new FormControl('', []);
  sizerangeControl = new FormControl<string>('');
  myRegionControl = new FormControl<string>('');
  petControl = new FormControl<string>('');
  styleControl = new FormControl<string>('');

  selectcheckboxCollectionbool: boolean = true;
  selectcheckboxDepartmentbool: boolean = true;
  selectcheckboxDesignerbool: boolean = true;
  selectcheckboxSeasonbool: boolean = true;
  selectcheckboxShipDatebool: boolean = true;
  selectcheckboxColorbool: boolean = false;
  selectcheckboxCountryOfOriginbool: boolean = false;
  selectcheckboxEditorStylistbool: boolean = false;
  selectcheckboxEventbool: boolean = false;
  selectcheckboxFabricbool: boolean = false;
  selectcheckboxGenderbool: boolean = false;
  selectcheckboxIncludeStaffLoansbool: boolean = false;
  selectcheckboxManufacturerbool: boolean = false;
  selectcheckboxMethodOfDispatchbool: boolean = false;
  selectcheckboxMiscbool: boolean = false;
  selectcheckboxModelCelebbool: boolean = false;
  selectcheckboxPatternbool: boolean = false;
  selectcheckboxPhotographerbool: boolean = false;
  selectcheckboxPublicationbool: boolean = false;
  selectcheckboxRecipientbool: boolean = false;
  selectcheckboxRegionbool: boolean = false;
  selectcheckboxRequestedBybool: boolean = false;
  selectcheckboxSenderbool: boolean = false;
  selectcheckboxShootLocationbool: boolean = false;
  selectcheckboxSizebool: boolean = false;
  selectsamblestatusbool: boolean = false;
  selectcheckboxSizeRangebool: boolean = false;
  selectcheckboxStoryThemebool: boolean = false;
  selectcheckboxStyleNumberbool: boolean = false;
  selectcheckboxTypebool: boolean = false;

  selectcheckboxCollection = new FormControl(this.selectcheckboxCollectionbool);
  selectcheckboxDepartment = new FormControl(this.selectcheckboxDepartmentbool);
  selectcheckboxDesigner = new FormControl(this.selectcheckboxDesignerbool);
  selectcheckboxSeason = new FormControl(this.selectcheckboxSeasonbool);
  selectcheckboxShipDate = new FormControl(this.selectcheckboxShipDatebool);
  selectcheckboxColor = new FormControl(this.selectcheckboxColorbool);
  selectcheckboxCountryOfOrigin = new FormControl(this.selectcheckboxCountryOfOriginbool);
  selectcheckboxEditorStylist = new FormControl(this.selectcheckboxEditorStylistbool);
  selectcheckboxEvent = new FormControl(this.selectcheckboxEventbool);
  selectcheckboxFabric = new FormControl(this.selectcheckboxFabricbool);
  selectcheckboxGender = new FormControl(this.selectcheckboxGenderbool);
  selectcheckboxIncludeStaffLoans = new FormControl(this.selectcheckboxIncludeStaffLoansbool);
  selectcheckboxManufacturer = new FormControl(this.selectcheckboxManufacturerbool);
  selectcheckboxMethodOfDispatch = new FormControl(this.selectcheckboxMethodOfDispatchbool);
  selectcheckboxMisc = new FormControl(this.selectcheckboxMiscbool);
  selectcheckboxModelCeleb = new FormControl(this.selectcheckboxModelCelebbool);
  selectcheckboxPattern = new FormControl(this.selectcheckboxPatternbool);
  selectcheckboxPhotographer = new FormControl(this.selectcheckboxPhotographerbool);
  selectcheckboxPublication = new FormControl(this.selectcheckboxPublicationbool);
  selectcheckboxRecipient = new FormControl(this.selectcheckboxRecipientbool);
  selectcheckboxRegion = new FormControl(this.selectcheckboxRegionbool);
  selectcheckboxRequestedBy = new FormControl(this.selectcheckboxRequestedBybool);
  selectcheckboxSender = new FormControl(this.selectcheckboxSenderbool);
  selectcheckboxShootLocation = new FormControl(this.selectcheckboxShootLocationbool);
  selectcheckboxSize = new FormControl(this.selectcheckboxSizebool);
  selectsamplestatus = new FormControl(this.selectsamblestatusbool);
  selectcheckboxSizeRange = new FormControl(this.selectcheckboxSizeRangebool);
  selectcheckboxStoryTheme = new FormControl(this.selectcheckboxStoryThemebool);
  selectcheckboxStyleNumber = new FormControl(this.selectcheckboxStyleNumberbool);
  selectcheckboxType = new FormControl(this.selectcheckboxTypebool);

  filteredDesignerOptions?: Observable<string[]>;
  filteredCollectionOptions?: Observable<string[]>;
  filteredSeasonOptions?: Observable<string[]>;
  filteredTypeOptions?: Observable<string[]>;
  filteredSizeOptions?: Observable<string[]>;
  filteredFabricOptions?: Observable<string[]>;
  options: string[] = [];
  regionoptions: string[] = [];
  manuoptions: string[] = [];
  styleoptions: string[] = [];
  miscoptions: string[] = [];
  patternoptions: string[] = [];
  sizerangeoptions: string[] = [];
  barcodeoptions: string[] = [];
  filteredOptions?: Observable<string[]>;
  filteredRegionOptions?: Observable<string[]>;
  filteredManuOptions?: Observable<string[]>;
  filteredStyleOptions?: Observable<string[]>;
  filteredMisceOptions?: Observable<string[]>;
  filteredPatternOptions?: Observable<string[]>;
  filteredSizeRangeOptions?: Observable<string[]>;
  filteredBarcodeOptions?: Observable<string[]>;
  filteredCompanyNameOptions?: Observable<string[]>
  filteredContactsOptions?: Observable<string[]>;
  iSystemUsersResult: GetSystemUsers[] = [];
  iSystemUsersRequstedResult: GetSystemUsers[] = [];


  designerptions: string[] = [];
  collectionoptions: string[] = [];
  seasonoptions: string[] = [];
  typeoptions: string[] = [];
  sizeoptions: string[] = [];
  fabricoptions: string[] = [];
  filteredCountries: Observable<any[]> = of([]);

  iSystemFields: GetSystemFields[] = [];
  iDesignerResult: GetDesignerList[] = [];
  iCollection: GetCollectionList[] = [];
  iSeason: GetSeasonList[] = [];
  iPEResult: GettodList[] = [];
  iTODResult: GettodList[] = [];
  iType: GetTypeList[] = [];
  iSize: GetTypeList[] = [];
  iFabricResult: GetFabricList[] = [];

  iSWSResult: GetSWSList[] = [];
  iRegionResult: GetRegionList[] = [];
  iManuResult: GetManuList[] = [];
  iStyle: GetStyleList[] = [];
  iMisc: GetMiscList[] = [];
  ilbMainResult: GetLBMain[] = [];
  iPattern: GetTypeList[] = [];
  iImages: GetImageList[] = [];
  iSizeRange: GetSizeRangeList[] = [];
  iBarcode: GetBarcodeList[] = [];
  iCFV: SetCustomFieldsValue[] = [];
  iCompanyResult: GetCompanyList[] = [];
  companyoptions: string[] = [];
  iContactsResult: SetContactsList[] = [];

  contactsoptions: string[] = [];
  returnoptions: string[] = [];

  dateRange = new FormGroup({
    start: new FormControl(new Date(new Date().setMonth(new Date().getMonth() - 1))),
    end: new FormControl(new Date())
  });

  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
      ? SelfData.account[0].data[0].id
      : SelfData.account[0].data[0].parentid;
  }

  constructor(public modalService: ModalserviceService,
              private yourappsService: YourappsserviceService,
              private serviceservice: ServiceService,
              private http: HttpClient) {
    this.group = new FormGroup({
      datetimeCtrl: new FormControl(''),
      datetimeCtrl1: new FormControl(''),
      datetimeCtrl2: new FormControl(''),
      datetimeRange: new FormGroup({
        start: new FormControl(''),
        end: new FormControl('')
      }),
      datetimeCtrl3: new FormControl('')
    });
    this.departmentFormControl.setValue(SelfData?.account[0]?.data[0]?.company);
    this.serviceservice
      .setDesignerList({accountid: this.getAccountID()})
      .subscribe((desingervalue) => {
        this.iDesignerResult[0] = desingervalue;
        SelfData.designer = this.iDesignerResult;
        this.filteredDesignerOptions = this.setDataAndFilter(
          this.desingerFormControl,
          desingervalue,
          this.designerptions,
          2
        );
      });

    this.serviceservice
      .setSeasonList({accountid: this.getAccountID()})
      .subscribe((seasonvalue) => {
        this.iSeason[0] = seasonvalue;
        SelfData.season = this.iSeason;
        this.filteredSeasonOptions = this.setDataAndFilter(
          this.seasonFormControl,
          seasonvalue,
          this.seasonoptions,
          6
        );
      });

    this.serviceservice
      .setCollectionList({accountid: this.getAccountID()})
      .subscribe((collectionvalue) => {
        this.iCollection[0] = collectionvalue;
        SelfData.collection = this.iCollection;
        this.filteredCollectionOptions = this.setDataAndFilter(
          this.collectionFormControl,
          collectionvalue,
          this.collectionoptions,
          5
        );
      });

    this.serviceservice
      .setPEList({accountid: this.getAccountID()})
      .subscribe((value) => {
        this.iPEResult = [];
        // this.resetResults()
        this.iPEResult[0] = value;
        SelfData.pe = this.iPEResult;
        // this.filteredPEOptions = this.setDataAndFilterDescription(
        //   this.peControl,
        //   value,
        //   this.peoptions,
        //   3
        // );
      });

    this.serviceservice
      .setFabricList({accountid: this.getAccountID()})
      .subscribe((fabricvalue) => {
        this.iFabricResult[0] = fabricvalue;
        SelfData.fabric = this.iFabricResult;
        this.filteredFabricOptions = this.setDataAndFilter(
          this.fabricControl,
          fabricvalue,
          this.fabricoptions,
          3
        );
      });

    this.serviceservice
      .setManuList({accountid: this.getAccountID()})
      .subscribe((manuvalue) => {
        this.iManuResult[0] = manuvalue;
        SelfData.manu = this.iManuResult;
        this.filteredManuOptions = this.setDataAndFilter(
          this.manuControl,
          manuvalue,
          this.manuoptions,
          4
        );
      });
    this.serviceservice
      .setTODList({accountid: this.getAccountID()})
      .subscribe((value) => {
        this.iTODResult = [];
        // this.resetResults()
        this.iTODResult[0] = value;
        SelfData.tod = this.iTODResult;
        // this.filteredTODOptions = this.setDataAndFilterDescription(
        //   this.todControl,
        //   value,
        //   this.todoptions,
        //   2
        // );
      });

    this.serviceservice
      .setMiscList({accountid: this.getAccountID()})
      .subscribe((value) => {
        this.iMisc[0] = value;
        SelfData.misc = this.iMisc;
        this.filteredMisceOptions = this.setDataAndFilter(
          this.miscControl,
          value,
          this.miscoptions,
          9
        );
      });

    this.serviceservice
      .selectlbmain({accountid: this.getAccountID()})
      .subscribe((value) => {
        this.ilbMainResult[0] = value;
        SelfData.lbmain = this.ilbMainResult;
        this.filteredPatternOptions = this.setDataAndFilter(
          this.patternControl,
          value,
          this.patternoptions,
          12
        );
      });

    this.serviceservice.setCompanyList({accountid: this.getAccountID()}).subscribe({
      next: (value: GetCompanyList) => {
        if (value && value.data) {
          this.iCompanyResult[0] = value;
          SelfData.company = this.iCompanyResult;
          this.filteredCompanyNameOptions = this.setFullDataFilter(
            this.companynameControl,
            value.data,
            this.companyoptions,
            0
          );
        } else {
          console.log('The response is empty or undefined.');
        }
      },
      error: (error) => {
        console.error('Error occurred during the subscription:', error);
      },
    });

    this.serviceservice
      .setContactsList({accountid: this.getAccountID(), contactsid: 0})
      .subscribe({
        next: (value) => {
          if (value) {
            this.iContactsResult[0] = value;
            SelfData.contactdata = this.iContactsResult;
            this.filteredContactsOptions = this.setDataAndFilter1(
              this.companynameControl,
              value,
              this.contactsoptions,
              0
            );
          } else {
            console.log('The response is empty or undefined.');
          }
        },
        error: (error) => {
          console.error('Error occurred during the subscription:', error);
        },
      });

    this.serviceservice
      .setSystemUsers({accountid: this.getAccountID()})
      .subscribe((value) => {
        this.iSystemUsersResult[0] = value;
        this.iSystemUsersRequstedResult[0] = value;
        SelfData.systemuser = this.iSystemUsersResult;
      });

    this.serviceservice
      .setRegionList({accountid: this.getAccountID()})
      .subscribe((regionvalue) => {
        this.iRegionResult[0] = regionvalue;
        SelfData.region = this.iRegionResult;
        this.filteredRegionOptions = this.setDataAndFilter(
          this.myRegionControl,
          regionvalue,
          this.regionoptions,
          1
        );
      });

    this.serviceservice
      .setSizeList({accountid: this.getAccountID()})
      .subscribe((value) => {
        this.iSize[0] = value;
        SelfData.size = this.iSize;
        this.filteredSizeOptions = this.setDataAndFilter(
          this.sizeControl,
          value,
          this.sizeoptions,
          11
        );
      });

    this.serviceservice
      .setSizeRangeList({accountid: this.getAccountID()})
      .subscribe((value) => {
        this.iSizeRange[0] = value;
        SelfData.sizerange = this.iSizeRange;
        this.filteredSizeRangeOptions = this.setDataAndFilter(
          this.sizerangeControl,
          value,
          this.sizerangeoptions,
          13
        );
      });

    this.serviceservice
      .setStyleList({accountid: this.getAccountID()})
      .subscribe((value) => {
        this.iStyle[0] = value;
        SelfData.style = this.iStyle;
        this.filteredStyleOptions = this.setDataAndFilter(
          this.styleControl,
          value,
          this.styleoptions,
          7
        );
      });

    this.serviceservice
      .setTypeList({accountid: this.getAccountID()})
      .subscribe((value) => {
        this.iType[0] = value;
        SelfData.type = this.iType;
        this.filteredTypeOptions = this.setDataAndFilter(
          this.typeFormControl,
          value,
          this.typeoptions,
          10
        );
      });
  }

  private setFullDataFilter(
    control: FormControl,
    response: any,
    options: string[],
    type: number
  ) {
    for (let data of response) {
      options.push(data.companyname);
    }
    return control.valueChanges.pipe(
      startWith(''),
      map((value) => this._myfilter(value || '', type))
    );
  }

  private _myfilter(value: string, type: number): string[] {
    const filterValue = value.toLowerCase();

    // replace 'this.companyoptions' with the correct array based on 'type'
    return this.companyoptions.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  private setDataAndFilter(
    control: FormControl,
    response: any,
    options: string[],
    type: number
  ) {
    let results = response.data;
    for (let data of results) {
      options.push(data.description);
    }
    return control.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || '', type))
    );
  }

  private setDataAndFilter1(
    control: FormControl,
    response: any,
    options: string[],
    type: number
  ) {
    let results = response.data;
    for (let data of results) {
      options.push(data.firstname + ' ' + data.lastname); // use firstname and lastname
    }
    return control.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter1(value || '', type))
    );
  }

  private _filter(value: string, type: number): string[] {
    const filterValue = value.toLowerCase();

    // Map the type to the corresponding options array
    const optionsMap: { [key: number]: string[] } = {
      1: this.regionoptions,
      2: this.designerptions,
      3: this.fabricoptions,
      4: this.manuoptions,
      5: this.collectionoptions,
      6: this.seasonoptions,
      7: this.styleoptions,
      9: this.miscoptions,
      10: this.typeoptions,
      11: this.sizeoptions,
      12: this.patternoptions,
      13: this.sizerangeoptions,
      14: this.barcodeoptions,
    };

    if (optionsMap[type]) {
      return optionsMap[type].filter((option) =>
        option.toLowerCase().includes(filterValue)
      );
    } else {
      console.warn(`Unexpected filter type: ${type}`);
      return [];
    }
  }

  private _filter1(value: string, type: number): string[] {
    const filterValue = value.toLowerCase();

    const options = [
      this.contactsoptions,
      this.returnoptions,
      // this.todoptions,
      this.companyoptions,
    ];

    if (type >= 0 && type < options.length) {
      return options[type].filter((option) =>
        option.toLowerCase().includes(filterValue)
      );
    } else {
      console.warn(`Unexpected filter type: ${type}`);
      return [];
    }
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    this.http.get<any>('assets/countries.json').subscribe((data) => {
      this.countries = data.countries;
      this.countri2Control.setValue('United States');
      this.http.get<any>('assets/us-states.json').subscribe((data) => {
        this.states = data;
        //
        // this.filteredStates = this.state2Control.valueChanges.pipe(
        //   startWith(''),
        //   map((value) => this._filterStates(value || ''))
        // );
      });

      this.filteredCountries = this.countri2Control.valueChanges.pipe(
        startWith(''),
        map((value) => this._filterCountries(value || ''))
      );
    });
    if (SelfData.userfilter && SelfData.userfilter[0].data) {
      if (SelfData.userfilter[0].data.length > 0) {
        this.selectcheckboxCollectionbool = SelfData.userfilter[0].data[0].SelectCheckboxCollection;
        this.selectcheckboxDepartmentbool = SelfData.userfilter[0].data[0].SelectCheckboxDepartment;
        this.selectcheckboxDesignerbool = SelfData.userfilter[0].data[0].SelectCheckboxDesigner;
        this.selectcheckboxSeasonbool = SelfData.userfilter[0].data[0].SelectCheckboxSeason;
        this.selectcheckboxShipDatebool = SelfData.userfilter[0].data[0].SelectCheckboxShipDate;
        this.selectcheckboxColorbool = SelfData.userfilter[0].data[0].SelectCheckboxColor;
        this.selectcheckboxCountryOfOriginbool = SelfData.userfilter[0].data[0].SelectCheckboxCountryOfOrigin;
        this.selectcheckboxEditorStylistbool = SelfData.userfilter[0].data[0].SelectCheckboxEditorStylist;
        this.selectcheckboxEventbool = SelfData.userfilter[0].data[0].SelectCheckboxEvent;
        this.selectcheckboxFabricbool = SelfData.userfilter[0].data[0].SelectCheckboxFabric;
        this.selectcheckboxGenderbool = SelfData.userfilter[0].data[0].SelectCheckboxGender;
        this.selectcheckboxIncludeStaffLoansbool = SelfData.userfilter[0].data[0].SelectCheckboxIncludeStaffLoans;
        this.selectcheckboxManufacturerbool = SelfData.userfilter[0].data[0].SelectCheckboxManufacturer;
        this.selectcheckboxMethodOfDispatchbool = SelfData.userfilter[0].data[0].SelectCheckboxMethodOfDispatch;
        this.selectcheckboxMiscbool = SelfData.userfilter[0].data[0].SelectCheckboxMisc;
        this.selectcheckboxModelCelebbool = SelfData.userfilter[0].data[0].SelectCheckboxModelCeleb;
        this.selectcheckboxPatternbool = SelfData.userfilter[0].data[0].SelectCheckboxPattern;
        this.selectcheckboxPhotographerbool = SelfData.userfilter[0].data[0].SelectCheckboxPhotographer;
        this.selectcheckboxPublicationbool = SelfData.userfilter[0].data[0].SelectCheckboxPublication;
        this.selectcheckboxRecipientbool = SelfData.userfilter[0].data[0].SelectCheckboxRecipient;
        this.selectcheckboxRegionbool = SelfData.userfilter[0].data[0].SelectCheckboxRegion;
        this.selectcheckboxRequestedBybool = SelfData.userfilter[0].data[0].SelectCheckboxRequestedBy;
        this.selectcheckboxSenderbool = SelfData.userfilter[0].data[0].SelectCheckboxSender;
        this.selectcheckboxShootLocationbool = SelfData.userfilter[0].data[0].SelectCheckboxShootLocation;
        this.selectcheckboxSizebool = SelfData.userfilter[0].data[0].SelectCheckboxSize;
        this.selectcheckboxSizeRangebool = SelfData.userfilter[0].data[0].SelectCheckboxSizeRange;
        this.selectcheckboxStoryThemebool = SelfData.userfilter[0].data[0].SelectCheckboxStoryTheme;
        this.selectcheckboxStyleNumberbool = SelfData.userfilter[0].data[0].SelectCheckboxStyleNumber;
        this.selectcheckboxTypebool = SelfData.userfilter[0].data[0].SelectCheckboxType;
        this.selectsamblestatusbool = SelfData.userfilter[0].data[0].SelectSampleStatus;
      } else {
        this.selectcheckboxCollectionbool = true;
        this.selectcheckboxDepartmentbool = true;
        this.selectcheckboxDesignerbool = true;
        this.selectcheckboxSeasonbool = true;
        this.selectcheckboxShipDatebool = true;
        this.selectcheckboxColorbool = false;
        this.selectcheckboxCountryOfOriginbool = false;
        this.selectcheckboxEditorStylistbool = false;
        this.selectcheckboxEventbool = false;
        this.selectcheckboxFabricbool = false;
        this.selectcheckboxGenderbool = false;
        this.selectcheckboxIncludeStaffLoansbool = false;
        this.selectcheckboxManufacturerbool = false;
        this.selectcheckboxMethodOfDispatchbool = false;
        this.selectcheckboxMiscbool = false;
        this.selectcheckboxModelCelebbool = false;
        this.selectcheckboxPatternbool = false;
        this.selectcheckboxPhotographerbool = false;
        this.selectcheckboxPublicationbool = false;
        this.selectcheckboxRecipientbool = false;
        this.selectcheckboxRegionbool = false;
        this.selectcheckboxRequestedBybool = false;
        this.selectcheckboxSenderbool = false;
        this.selectcheckboxShootLocationbool = false;
        this.selectcheckboxSizebool = false;
        this.selectcheckboxSizeRangebool = false;
        this.selectcheckboxStoryThemebool = false;
        this.selectcheckboxStyleNumberbool = false;
        this.selectcheckboxTypebool = false;
        this.selectsamblestatusbool = false;
      }
    }
    this.selectcheckboxCollection.setValue(this.selectcheckboxCollectionbool);
    this.selectcheckboxDepartment.setValue(this.selectcheckboxDepartmentbool);
    this.selectcheckboxDesigner.setValue(this.selectcheckboxDesignerbool);
    this.selectcheckboxSeason.setValue(this.selectcheckboxSeasonbool);
    this.selectcheckboxShipDate.setValue(this.selectcheckboxShipDatebool);
    this.selectcheckboxColor.setValue(this.selectcheckboxColorbool);
    this.selectcheckboxCountryOfOrigin.setValue(this.selectcheckboxCountryOfOriginbool);
    this.selectcheckboxEditorStylist.setValue(this.selectcheckboxEditorStylistbool);
    this.selectcheckboxEvent.setValue(this.selectcheckboxEventbool);
    this.selectcheckboxFabric.setValue(this.selectcheckboxFabricbool);
    this.selectcheckboxGender.setValue(this.selectcheckboxGenderbool);
    this.selectcheckboxIncludeStaffLoans.setValue(this.selectcheckboxIncludeStaffLoansbool);
    this.selectcheckboxManufacturer.setValue(this.selectcheckboxManufacturerbool);
    this.selectcheckboxMethodOfDispatch.setValue(this.selectcheckboxMethodOfDispatchbool);
    this.selectcheckboxMisc.setValue(this.selectcheckboxMiscbool);
    this.selectcheckboxModelCeleb.setValue(this.selectcheckboxModelCelebbool);
    this.selectcheckboxPattern.setValue(this.selectcheckboxPatternbool);
    this.selectcheckboxPhotographer.setValue(this.selectcheckboxPhotographerbool);
    this.selectcheckboxPublication.setValue(this.selectcheckboxPublicationbool);
    this.selectcheckboxRecipient.setValue(this.selectcheckboxRecipientbool);
    this.selectcheckboxRegion.setValue(this.selectcheckboxRegionbool);
    this.selectcheckboxRequestedBy.setValue(this.selectcheckboxRequestedBybool);
    this.selectcheckboxSender.setValue(this.selectcheckboxSenderbool);
    this.selectcheckboxShootLocation.setValue(this.selectcheckboxShootLocationbool);
    this.selectcheckboxSize.setValue(this.selectcheckboxSizebool);
    this.selectcheckboxSizeRange.setValue(this.selectcheckboxSizeRangebool);
    this.selectcheckboxStoryTheme.setValue(this.selectcheckboxStoryThemebool);
    this.selectcheckboxStyleNumber.setValue(this.selectcheckboxStyleNumberbool);
    this.selectcheckboxType.setValue(this.selectcheckboxTypebool);
    this.selectsamplestatus.setValue(this.selectsamblestatusbool);
  }

  closeOverlayPanel() {
    this.selectcheckboxCollectionbool = this.selectcheckboxCollection.value || false;
    this.selectcheckboxDepartmentbool = this.selectcheckboxDepartment.value || false;
    this.selectcheckboxDesignerbool = this.selectcheckboxDesigner.value || false;
    this.selectcheckboxSeasonbool = this.selectcheckboxSeason.value || false;
    this.selectcheckboxShipDatebool = this.selectcheckboxShipDate.value || false;
    this.selectcheckboxColorbool = this.selectcheckboxColor.value || false;
    this.selectcheckboxCountryOfOriginbool = this.selectcheckboxCountryOfOrigin.value || false;
    this.selectcheckboxEditorStylistbool = this.selectcheckboxEditorStylist.value || false;
    this.selectcheckboxEventbool = this.selectcheckboxEvent.value || false;
    this.selectcheckboxFabricbool = this.selectcheckboxFabric.value || false;
    this.selectcheckboxGenderbool = this.selectcheckboxGender.value || false;
    this.selectsamblestatusbool = this.selectsamplestatus.value || false;
    this.selectcheckboxIncludeStaffLoansbool = this.selectcheckboxIncludeStaffLoans.value || false;
    this.selectcheckboxManufacturerbool = this.selectcheckboxManufacturer.value || false;
    this.selectcheckboxMethodOfDispatchbool = this.selectcheckboxMethodOfDispatch.value || false;
    this.selectcheckboxMiscbool = this.selectcheckboxMisc.value || false;
    this.selectcheckboxModelCelebbool = this.selectcheckboxModelCeleb.value || false;
    this.selectcheckboxPatternbool = this.selectcheckboxPattern.value || false;
    this.selectcheckboxPhotographerbool = this.selectcheckboxPhotographer.value || false;
    this.selectcheckboxPublicationbool = this.selectcheckboxPublication.value || false;
    this.selectcheckboxRecipientbool = this.selectcheckboxRecipient.value || false;
    this.selectcheckboxRegionbool = this.selectcheckboxRegion.value || false;
    this.selectcheckboxRequestedBybool = this.selectcheckboxRequestedBy.value || false;
    this.selectcheckboxSenderbool = this.selectcheckboxSender.value || false;
    this.selectcheckboxShootLocationbool = this.selectcheckboxShootLocation.value || false;
    this.selectcheckboxSizebool = this.selectcheckboxSize.value || false;
    this.selectcheckboxSizeRangebool = this.selectcheckboxSizeRange.value || false;
    this.selectcheckboxStoryThemebool = this.selectcheckboxStoryTheme.value || false;
    this.selectcheckboxStyleNumberbool = this.selectcheckboxStyleNumber.value || false;
    this.selectcheckboxTypebool = this.selectcheckboxType.value || false;
    this.overlayPanel.hide();

    const requestData: UserPreferencesRequest = {
      ctrl: 1, // Set your desired ctrl value
      userID: SelfData.account[0].data[0].id,
      selectCheckboxCollection: this.selectcheckboxCollectionbool,
      selectCheckboxDepartment: this.selectcheckboxDepartmentbool,
      selectCheckboxDesigner: this.selectcheckboxDesignerbool,
      selectCheckboxSeason: this.selectcheckboxSeasonbool,
      selectCheckboxShipDate: this.selectcheckboxShipDatebool,
      selectCheckboxColor: this.selectcheckboxColorbool,
      selectCheckboxCountryOfOrigin: this.selectcheckboxCountryOfOriginbool,
      selectCheckboxEditorStylist: this.selectcheckboxEditorStylistbool,
      selectCheckboxEvent: this.selectcheckboxEventbool,
      selectCheckboxFabric: this.selectcheckboxFabricbool,
      selectCheckboxGender: this.selectcheckboxGenderbool,
      selectCheckboxIncludeStaffLoans: this.selectcheckboxIncludeStaffLoansbool,
      selectCheckboxManufacturer: this.selectcheckboxManufacturerbool,
      selectCheckboxMethodOfDispatch: this.selectcheckboxMethodOfDispatchbool,
      selectCheckboxMisc: this.selectcheckboxMiscbool,
      selectCheckboxModelCeleb: this.selectcheckboxModelCelebbool,
      selectCheckboxPattern: this.selectcheckboxPatternbool,
      selectCheckboxPhotographer: this.selectcheckboxPhotographerbool,
      selectCheckboxPublication: this.selectcheckboxPublicationbool,
      selectCheckboxRecipient: this.selectcheckboxRecipientbool,
      selectCheckboxRegion: this.selectcheckboxRegionbool,
      selectCheckboxRequestedBy: this.selectcheckboxRequestedBybool,
      selectCheckboxSender: this.selectcheckboxSenderbool,
      selectCheckboxShootLocation: this.selectcheckboxShootLocationbool,
      selectCheckboxSize: this.selectcheckboxSizebool,
      selectCheckboxSizeRange: this.selectcheckboxSizeRangebool,
      selectCheckboxStoryTheme: this.selectcheckboxStoryThemebool,
      selectCheckboxStyleNumber: this.selectcheckboxStyleNumberbool,
      selectCheckboxType: this.selectcheckboxTypebool,
      selectSampleStatus: this.selectsamblestatusbool,
    };

    this.serviceservice.prcUserPreferences(requestData).subscribe(response => {
      const requestData: UserPreferencesRequest = {
        ctrl: 0, // Set your desired ctrl value
        userID: SelfData.account[0].data[0].id,
        selectCheckboxCollection: this.selectcheckboxCollectionbool,
        selectCheckboxDepartment: this.selectcheckboxDepartmentbool,
        selectCheckboxDesigner: this.selectcheckboxDesignerbool,
        selectCheckboxSeason: this.selectcheckboxSeasonbool,
        selectCheckboxShipDate: this.selectcheckboxShipDatebool,
        selectCheckboxColor: this.selectcheckboxColorbool,
        selectCheckboxCountryOfOrigin: this.selectcheckboxCountryOfOriginbool,
        selectCheckboxEditorStylist: this.selectcheckboxEditorStylistbool,
        selectCheckboxEvent: this.selectcheckboxEventbool,
        selectCheckboxFabric: this.selectcheckboxFabricbool,
        selectCheckboxGender: this.selectcheckboxGenderbool,
        selectCheckboxIncludeStaffLoans: this.selectcheckboxIncludeStaffLoansbool,
        selectCheckboxManufacturer: this.selectcheckboxManufacturerbool,
        selectCheckboxMethodOfDispatch: this.selectcheckboxMethodOfDispatchbool,
        selectCheckboxMisc: this.selectcheckboxMiscbool,
        selectCheckboxModelCeleb: this.selectcheckboxModelCelebbool,
        selectCheckboxPattern: this.selectcheckboxPatternbool,
        selectCheckboxPhotographer: this.selectcheckboxPhotographerbool,
        selectCheckboxPublication: this.selectcheckboxPublicationbool,
        selectCheckboxRecipient: this.selectcheckboxRecipientbool,
        selectCheckboxRegion: this.selectcheckboxRegionbool,
        selectCheckboxRequestedBy: this.selectcheckboxRequestedBybool,
        selectCheckboxSender: this.selectcheckboxSenderbool,
        selectCheckboxShootLocation: this.selectcheckboxShootLocationbool,
        selectCheckboxSize: this.selectcheckboxSizebool,
        selectCheckboxSizeRange: this.selectcheckboxSizeRangebool,
        selectCheckboxStoryTheme: this.selectcheckboxStoryThemebool,
        selectCheckboxStyleNumber: this.selectcheckboxStyleNumberbool,
        selectCheckboxType: this.selectcheckboxTypebool,
        selectSampleStatus: this.selectsamblestatusbool,
      };

      this.serviceservice.prcUserPreferences(requestData).subscribe(response => {
        this.serviceservice
          .selectUserPreferences(SelfData.account[0].data[0].id)
          .subscribe({
            next: (value: any) => {
              if (value && value.data && Array.isArray(value.data)) {
                SelfData.userfilter[0] = value;
              } else {
                console.log(
                  'The response is empty or not in the expected format.'
                );
              }
            },
            error: (error) => {
              console.error('Error occurred during the subscription:', error);
            },
            complete: ()=> {

            }
          });
      });
    });
  }

  private _filterCountries(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.countries.filter((country) =>
      country.country.toLowerCase().includes(filterValue)
    );
  }

  onApplyClick() {

  }
}
