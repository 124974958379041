import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ModalserviceService } from '../../services/modalservice.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { HashService } from '../../services/hash.service';
import { MessageboxComponent } from '../../modals/messagebox/messagebox.component';
import { ServiceService } from '../../services/service.service';
import { YourappsserviceService } from '../../services/yourappsservice.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { map, Observable, of, startWith } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  GetAddressList,
  PrcAddress,
} from '../../models/struct';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-addressdialog',
  templateUrl: './addressdialog.component.html',
  styleUrls: ['./addressdialog.component.scss'],
})
export class AddressdialogComponent implements OnInit, AfterViewInit {
  myForm: FormGroup = new FormGroup({});
  createbuttoncaption = 'Create';
  filteredCountries: Observable<any[]> = of([]);
  filteredStates: Observable<any[]> = of([]);
  countries: any[] = [];
  states: any[] = [];

  descriptionFormControl = new FormControl({ value: '', disabled: false }, [
    Validators.required,
  ]);

  careoff1Contro2 = new FormControl<string>('');
  countri2Control = new FormControl<string>('');
  address12Control = new FormControl<string>('');
  otherEmail2Control = new FormControl<string>('');
  address22Control = new FormControl<string>('');
  directPhone2Control = new FormControl<string>('');
  city2Control = new FormControl<string>('');
  generalPhoneControl = new FormControl<string>('');
  state2Control = new FormControl<string>('');
  mobile1Control = new FormControl<string>('');
  zipcode2Control = new FormControl<string>('');
  mobile2Control = new FormControl<string>('');
  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private hashService: HashService,
    public messageBox: MessageboxComponent,
    private serviceservice: ServiceService,
    private yourappsService: YourappsserviceService,
    private _snackBar: MatSnackBar,
    private http: HttpClient
  ) {}

  iAddressResult: GetAddressList[] = [];
  submit() {
    if (this.descriptionFormControl.value?.trim() == '') {
    } else {
      this.serviceservice
        .prcaddress({
          ctrl: 0,
          id: 0,
          careof: this.careoff1Contro2.value || '',
          country: this.countri2Control.value || '',
          address1: this.address12Control.value || '',
          email: this.otherEmail2Control.value || '',
          otheremail: '',
          address2: this.address22Control.value || '',
          phone: this.directPhone2Control.value || '',
          generalphone: this.generalPhoneControl.value || '',
          mobile1: this.mobile1Control.value || '',
          mobile2: this.mobile2Control.value || '',
          city: this.city2Control.value || '',
          state: this.state2Control.value || '',
          zipcode: this.zipcode2Control.value || '',
          isactivity: true,
          contactsid: 0,
          accountid: 0,
          description: this.descriptionFormControl.value?.trim() || '',
        })
        .subscribe((value: PrcAddress) => {
          this.serviceservice
            .setNewAddressList({ id: value.data })
            .subscribe((value) => {
              this.iAddressResult[0] = value;
              this.yourappsService.setAddress(this.iAddressResult);
              this.modalService.createaddressclose();
              // SelfData.addres = this.iAddressResult;
            });
        });
    }
  }

  ngAfterViewInit(): void {}

  private _filterStates(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.states.filter((state) =>
      state.state.toLowerCase().includes(filterValue)
    );
  }

  private _filterCountries(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.countries.filter((country) =>
      country.country.toLowerCase().includes(filterValue)
    );
  }
  ngOnInit(): void {
    this.http.get<any>('assets/countries.json').subscribe((data) => {
      this.countries = data.countries;
      this.countri2Control.setValue('United States');
      this.http.get<any>('assets/us-states.json').subscribe((data) => {
        this.states = data;

        this.filteredStates = this.state2Control.valueChanges.pipe(
          startWith(''),
          map((value) => this._filterStates(value || ''))
        );
      });

      this.filteredCountries = this.countri2Control.valueChanges.pipe(
        startWith(''),
        map((value) => this._filterCountries(value || ''))
      );
    });
    this.yourappsService.address$.subscribe((address) => {
      if (address && address[0] && address[0].data && address[0].data[0]) {
        this.iAddressResult = address;
        this.descriptionFormControl.setValue(
          this.iAddressResult[0].data[0].description
        );
      } else {
        // console.error('Received unexpected data format:', address);
      }
    });
  }

  getCountryIdByName(countryName: string): number | null {
    const country = this.countries.find((c) => c.country === countryName);
    return country ? country.id : null;
  }
  onOptionSelected(event: MatAutocompleteSelectedEvent): void {
    const countryId = this.getCountryIdByName(event.option.value);
    this.states = [];
    this.state2Control.reset();
    if (countryId == 187) {
      this.http.get<any>('assets/us-states.json').subscribe((data) => {
        this.states = data;
        this.filteredStates = this.state2Control.valueChanges.pipe(
          startWith(''),
          map((value) => this._filterStates(value || ''))
        );
      });
    }
    // You can perform further actions using the selected country
  }
}
