import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {ModalserviceService} from '../../services/modalservice.service';
import {FormBuilder, FormControl, FormGroup, Validators,} from '@angular/forms';
import {HashService} from '../../services/hash.service';
import {MessageboxComponent} from '../../modals/messagebox/messagebox.component';
import {ServiceService} from '../../services/service.service';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition,} from '@angular/material/snack-bar';
import {
  GetCollectionList,
  GetCollectionListData, GetCollectionValues,
  GetCustomFieldsData,
  GetDesignerList,
  GetDesignerListData,
  GetFabricList,
  GetFabricListData,
  GetImageList,
  GetLBMain,
  GetLBMainData,
  GetManuList,
  GetManuListData,
  GetMiscList,
  GetMiscListData,
  GetPatternListData,
  GetRegionList,
  GetRegionListData, GetScannedList,
  GetSeasonList,
  GetSeasonListData, GetSendOutList,
  GetSizeListData,
  GetSizeRangeList,
  GetSizeRangeListData,
  GetStyleList,
  GetStyleListData,
  GetSWSList,
  GetSWSListData,
  GetTypeList,
  GetTypeListData, PrcAddress, samplehistory,
  SelfData, SendOut1Data, setcomponentfields,
  SetCustomFieldsValue, setgridfields, SetSampleListData,
  SetSelectedFiles,
  SetSelectedFilesV, Speener, transformImageUrl,
} from '../../models/struct';
import {YourappsserviceService} from '../../services/yourappsservice.service';
import {MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger,} from '@angular/material/autocomplete';
import {firstValueFrom, map, Observable, of, startWith, Subscription} from 'rxjs';
import {animate, state, style, transition, trigger,} from '@angular/animations';
import {MessageService} from "primeng/api";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {CircularProgressBarComponent} from "smart-webcomponents-angular/progressbar";
import {ThemePalette} from "@angular/material/core";
import {ProgressSpinnerMode} from "@angular/material/progress-spinner";
import {HttpEventType} from "@angular/common/http";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import heic2any from 'heic2any';
import {DatePipe, formatDate} from "@angular/common";
import {Table} from "primeng/table";
import {OverlayPanel} from "primeng/overlaypanel";

interface InventoryStatus {
  value: string;
  viewValue: string;
}

interface ColorArray {
  colorname: string;
  colorcode: string;
}

interface Gender {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-createsampledialogs-sale',
  templateUrl: './createsampledialogs-sale.component.html',
  styleUrls: ['./createsampledialogs-sale.component.scss'],
  animations: [
    trigger('zoom', [
      state(
        'initial',
        style({
          transform: 'scale(1)',
        })
      ),
      state(
        'zoomed',
        style({
          transform: 'scale(1.5)',
        })
      ),
      transition('initial <=> zoomed', animate('200ms ease-in-out')),
    ]),
  ],
})
export class CreatesampledialogsSaleComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChildren(MatAutocomplete) matAutocompletes!: QueryList<MatAutocomplete>;
  @ViewChild('circularProgressBar', {
    read: CircularProgressBarComponent,
    static: false
  }) circularProgressBar!: CircularProgressBarComponent;

  autoCompletes: { [key: string]: MatAutocomplete } = {};

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  inventorystatus: InventoryStatus[] = [
    {value: '1', viewValue: 'Inventory'},
  ];

  sizes!: any[];
  selectedSize: any = '';

  colorarray: ColorArray[] = [
    {colorname: 'White', colorcode: '#ffffff'},
    {colorname: 'Black ', colorcode: '#000000'},
    {colorname: 'Yellow', colorcode: '#FFFF00'},
    {colorname: 'Purple', colorcode: '#800080'},
    {colorname: 'Orange ', colorcode: '#FFA500'},
    {colorname: 'Blue ', colorcode: '#2196f3'},
    {colorname: 'Light Blue ', colorcode: '#03a9f4'},
    {colorname: 'Red', colorcode: '#ff0000'},
    {colorname: 'Buff', colorcode: '#DAA06D'},
    // {colorname: 'Gray ', colorcode: '#808080'},
    {colorname: 'Grey ', colorcode: '#808080'},
    {colorname: 'Green ', colorcode: '#00FF00'},
    {colorname: 'Pink', colorcode: '#FFC0CB'},
    {colorname: 'Violet', colorcode: '#8F00FF'},
    {colorname: 'Brown', colorcode: '#964B00'},
    {colorname: 'Silver', colorcode: '#C0C0C0'},
    {colorname: 'Cream', colorcode: '#FFFDD0'},
    {colorname: 'Gold', colorcode: '#FFD700'},
    {colorname: 'Beige', colorcode: '#F5F5DC'},
    {colorname: 'Navy', colorcode: '#202A44'},
    {colorname: 'Mint', colorcode: '#A8E8D7'},
  ];

  gender: Gender[] = [
    {value: 'Man', viewValue: 'Man'},
    {value: 'Unisex', viewValue: 'Unisex'},
    {value: 'Women', viewValue: 'Women'},
  ];
  isChecked: boolean = false;
  isVisible: boolean = true;
  tdata: any = null;
  selectdefaultimage: any = []
  private subscription?: Subscription;
  private subscription1?: Subscription;
  private subscription2?: Subscription;
  private subscription3?: Subscription;
  private valueChangesSubscription?: Subscription;
  createbuttoncaption = 'Create';
  private selectedlookbook: GetLBMainData[] = [];
  pricesid: number = 0
  // myControl = new FormControl<string>('');
  // myRegionControl = new FormControl<string>('');
  // notesRegionControl = new FormControl<string>('');
  // fabricControl = new FormControl<string>('');
  // manuControl = new FormControl<string>('');
  // associatedControl = new FormControl<string>('');
  // sizeControl = new FormControl<string>('');
  // sizerangeControl = new FormControl<string>('');
  // descriptionControl = new FormControl<string>('');
  // patternControl = new FormControl<string>('');
  // lookbookControl = new FormControl<string>('');
  // colorControl = new FormControl<string>('');
  // quantityControl = new FormControl('1', [
  //   Validators.required,
  //   Validators.min(1),
  //   Validators.pattern('^[0-9]*$'),
  // ]);
  // priceControl = new FormControl('0', [
  //   Validators.required,
  //   Validators.min(0),
  //   Validators.pattern('^\\d*(\\.\\d+)?$'),
  // ]);
  // price1Control = new FormControl('0', [
  //   Validators.required,
  //   Validators.min(0),
  //   Validators.pattern('^\\d*(\\.\\d+)?$'),
  // ]);
  // price2Control = new FormControl('0', [
  //   Validators.required,
  //   Validators.min(0),
  //   Validators.pattern('^\\d*(\\.\\d+)?$'),
  // ]);
  // price3Control = new FormControl('0', [
  //   Validators.required,
  //   Validators.min(0),
  //   Validators.pattern('^\\d*(\\.\\d+)?$'),
  // ]);
  // price4Control = new FormControl('0', [
  //   Validators.required,
  //   Validators.min(0),
  //   Validators.pattern('^\\d*(\\.\\d+)?$'),
  // ]);
  // price5Control = new FormControl('0', [
  //   Validators.required,
  //   Validators.min(0),
  //   Validators.pattern('^\\d*(\\.\\d+)?$'),
  // ]);
  public captionInventoryStatus: string = '';
  public captionGender: string = '';
  public captionSampleWorkflowStatus: string = '';
  public captionDepartment: string = '';
  public captionRegion: string = '';
  public captionNotes: string = '';
  public captionDesigner: string = '';
  public captionFabric: string = '';
  public captionManufacturer: string = '';
  public captionCollection: string = '';
  public captionStyle: string = '';
  public captionLooksAssociated: string = '';
  public captionSeason: string = '';
  public captionMisc: string = '';
  public captionQuantity: string = '';
  public captionType: string = '';
  public captionSize: string = '';
  public captionPattern: string = '';
  public captionLookBook: string = '';
  public captionColor: string = '';
  public captionSizeRange: string = '';
  public captionRetailPriceEUR: string = '';
  public captionRetailPriceUSD: string = '';
  public captionWholesaleEUR: string = '';
  public captionWholesaleUSD: string = '';
  public captionSalePriceEUR: string = '';
  public captionSalePriceUSD: string = '';
  public captionDescription: string = '';
  @ViewChild('dt1') dt1!: Table;
  filtertext: string = '';
  selecteditem: any
  selectedIndex: number = -1;
  customfieldvisibled: boolean = false;
  fieldscount: GetCustomFieldsData[] = [];
  formcaption: string = "Create Sample"
  // styleControl = new FormControl<string>('');
  // miscControl = new FormControl<string>('');
  filteredOptions?: Observable<string[]>;
  filteredRegionOptions?: Observable<string[]>;
  filteredDesignerOptions?: Observable<string[]>;
  filteredFabricOptions?: Observable<string[]>;
  filteredManuOptions?: Observable<string[]>;
  filteredCollectionOptions?: Observable<string[]>;
  filteredSeasonOptions?: Observable<string[]>;
  filteredStyleOptions?: Observable<string[]>;
  filteredMisceOptions?: Observable<string[]>;
  filteredTypeOptions?: Observable<string[]>;
  filteredSizeOptions?: Observable<string[]>;
  filteredPatternOptions?: Observable<string[]>;
  filteredLookBookOptions?: Observable<string[]>;
  filteredSizeRangeOptions?: Observable<string[]>;
  myForm: FormGroup;
  isItemSelected = false;
  options: string[] = [];
  regionoptions: string[] = [];
  designerptions: string[] = [];
  fabricoptions: string[] = [];
  manuoptions: string[] = [];
  collectionoptions: string[] = [];
  seasonoptions: string[] = [];
  styleoptions: string[] = [];
  miscoptions: string[] = [];
  typeoptions: string[] = [];
  sizeoptions: string[] = [];
  patternoptions: string[] = [];
  lookbookoptions: string[] = [];
  sizerangeoptions: string[] = [];
  cfvoptions: string[] = [];
  neededreload: boolean = false;
  selectCheckDesigner: boolean = true;
  selectCheckInventoryStatus = true;
  selectCheckGender = true;
  selectCheckSampleWorkflowStatus = true;
  selectCheckRegion = true;
  selectCheckNotes = true;
  selectCheckFabric = true;
  selectCheckManufacturer = true;
  selectCheckCollection = true;
  selectCheckStyle = true;
  selectCheckLooksAssociated = true;
  selectCheckSeason = true;
  selectCheckMisc = true;
  selectCheckType = true;
  selectCheckSize = true;
  selectCheckSizeRange = true;
  selectCheckPattern = true;
  selectCheckQuantity = true;
  selectCheckColor = true;
  selectCheckBarcode = true;
  selectCheckDepartment = true;
  selectCheckLookBook = true;
  // departmentFormControl = new FormControl({value: '', disabled: true}, [
  //   Validators.required,
  // ]);
  // desingerFormControl = new FormControl({value: '', disabled: false}, [
  //   Validators.required,
  // ]);
  // collectionFormControl = new FormControl({value: '', disabled: false}, [
  //   Validators.required,
  // ]);
  // seasonFormControl = new FormControl({value: '', disabled: false}, [
  //   Validators.required,
  // ]);
  // typeFormControl = new FormControl({value: '', disabled: false}, [
  //   Validators.required,
  // ]);
  iSWSResult: GetSWSList[] = [];
  iRegionResult: GetRegionList[] = [];
  iDesignerResult: GetDesignerList[] = [];
  iFabricResult: GetFabricList[] = [];
  iManuResult: GetManuList[] = [];
  iCollection: GetCollectionList[] = [];
  iSeason: GetSeasonList[] = [];
  iStyle: GetStyleList[] = [];
  iMisc: GetMiscList[] = [];
  iType: GetTypeList[] = [];
  iSize: GetTypeList[] = [];
  collectionbyid: GetCollectionValues[] = [];
  iPattern: GetTypeList[] = [];
  iImages: GetImageList[] = [];
  iSizeRange: GetSizeRangeList[] = [];
  iCFV: SetCustomFieldsValue[] = [];
  ilbMainResult: GetLBMain[] = [];
  notneededNewID: boolean = true

  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
      ? SelfData.account[0].data[0].id
      : SelfData.account[0].data[0].parentid;
  }

  @ViewChild(MatAutocompleteTrigger)
  autocompleteTrigger!: MatAutocompleteTrigger;
  video: any;
  rawMarkdown: string = ""; // Your raw Markdown text
  convertedHtml: string = ""; // The HTML output
  formattedText: string = "";

  iFieldList: setcomponentfields[] = [];

  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private sanitizer: DomSanitizer,
    private hashService: HashService,
    public messageBox: MessageboxComponent,
    private serviceservice: ServiceService,
    private yourappsService: YourappsserviceService,
    private _snackBar: MatSnackBar,
    private messageService: MessageService,
    private datePipe: DatePipe,
    private cdr: ChangeDetectorRef
  ) {
    SelfData.componentFieldData.forEach((field: any) => {
      const propertyName = field.componentname as keyof typeof this; // Явное указание типа ключа
      if (propertyName in this) {
        (this[propertyName] as boolean) = field.isshow; // Приведение типа
      }
    });
    // const element = document.querySelector('.modal-container') as HTMLElement; // Явно указываем тип
    //
    // if (element) { // Проверяем, что элемент существует
    //   const computedHeight = element.scrollHeight; // Вычисляем высоту auto
    //   element.style.height = `${computedHeight + 200}px`; // Устанавливаем высоту
    // } else {
    //   console.error('Element with class "modal-container" not found.');
    // }
    // this.serviceservice
    //   .getComponentFields({
    //     formaname: "createsamplesdialog",
    //     accountid: this.getAccountID(),
    //     userid: SelfData.account[0].data[0].id,
    //   })
    //   .subscribe((response) => {
    //     if (response.Code === 200) {
    //       const fieldData = response.data;
    //
    //       fieldData.forEach((field: any) => {
    //         const propertyName = field.componentname as keyof typeof this; // Явное указание типа ключа
    //         if (propertyName in this) {
    //           (this[propertyName] as boolean) = field.isshow; // Приведение типа
    //         }
    //       });
    //
    //       console.log("Обновленные свойства:", this);
    //     } else {
    //       console.error("Ошибка: ", response.ErrorMessage);
    //     }
    //   });

    this.sizes = [
      {name: 'Small', class: 'p-datatable-sm'}
    ];
    this.selectedSize = this.sizes[0]
    this.neededreload = false
    this.captionInventoryStatus = SelfData.systemfields[0].data[1].fieldlabel;
    this.captionGender = SelfData.systemfields[0].data[2].fieldlabel;
    this.captionSampleWorkflowStatus = SelfData.systemfields[0].data[3].fieldlabel;
    this.captionDepartment = SelfData.systemfields[0].data[4].fieldlabel;
    this.captionRegion = SelfData.systemfields[0].data[5].fieldlabel;
    this.captionNotes = SelfData.systemfields[0].data[6].fieldlabel;
    this.captionDesigner = SelfData.systemfields[0].data[7].fieldlabel;
    this.captionFabric = SelfData.systemfields[0].data[8].fieldlabel;
    this.captionManufacturer = SelfData.systemfields[0].data[9].fieldlabel;
    this.captionCollection = SelfData.systemfields[0].data[10].fieldlabel;
    this.captionStyle = SelfData.systemfields[0].data[11].fieldlabel;
    this.captionLooksAssociated = SelfData.systemfields[0].data[12].fieldlabel;
    this.captionSeason = SelfData.systemfields[0].data[13].fieldlabel;
    this.captionMisc = SelfData.systemfields[0].data[14].fieldlabel;
    this.captionQuantity = SelfData.systemfields[0].data[15].fieldlabel;
    this.captionType = SelfData.systemfields[0].data[16].fieldlabel;
    this.captionSize = SelfData.systemfields[0].data[17].fieldlabel;
    this.captionPattern = SelfData.systemfields[0].data[18].fieldlabel;
    this.captionLookBook = SelfData.systemfields[0].data[28].fieldlabel;
    this.captionColor = SelfData.systemfields[0].data[19].fieldlabel;
    this.captionSizeRange = SelfData.systemfields[0].data[20].fieldlabel;
    this.captionRetailPriceEUR = SelfData.systemfields[0].data[21].fieldlabel;
    this.captionRetailPriceUSD = SelfData.systemfields[0].data[22].fieldlabel;
    this.captionWholesaleEUR = SelfData.systemfields[0].data[23].fieldlabel;
    this.captionWholesaleUSD = SelfData.systemfields[0].data[24].fieldlabel;
    this.captionSalePriceEUR = SelfData.systemfields[0].data[25].fieldlabel;
    this.captionSalePriceUSD = SelfData.systemfields[0].data[26].fieldlabel;
    this.captionDescription = SelfData.systemfields[0].data[27].fieldlabel;
    // this.departmentFormControl.setValue(SelfData.account[0].data[0].company);
    this.departmentstring = SelfData.account[0].data[0].company;
    console.log("SelfData.systemfields[0].data", SelfData.systemfields[0].data[28].fieldlabel)
    this.myForm = this.fb.group({
      inventoryStatus: ['Inventory'],
      gender: ['',],
      checkboxValue: [false],
    });
    this.inventory = "Inventory"
    var accountid: number = 0;
    if (SelfData.account[0].data[0].parentid == 0) {
      accountid = SelfData.account[0].data[0].id;
    } else {
      accountid = SelfData.account[0].data[0].parentid;
    }

    this.customfieldvisibled = SelfData.customfields[0].data.length > 0;

    // this.fieldscount = SelfData.customfields[0].data
    if (Array.isArray(SelfData.customfields[0].data)) {
      this.fieldscount = SelfData.customfields[0].data.map((field) => ({
        ...field,
        control: new FormControl('', field.requered ? Validators.required : null),
      }));
    } else {
      // console.error('SelfData.customfields[0].data is not an array: ', SelfData.customfields[0].data);
    }

    // pattern
    this.serviceservice
      .setPatternList({accountid: this.getAccountID()})
      .subscribe((value) => {
        this.iPattern[0] = value;
        SelfData.pattern = this.iPattern;
        // this.filteredPatternOptions = this.setDataAndFilter(
        //   this.patternControl,
        //   value,
        //   this.patternoptions,
        //   12
        // );
      });

    // lookbook
    this.serviceservice
      .selectlbmain({accountid: this.getAccountID()})
      .subscribe((value) => {
        this.ilbMainResult[0] = value;
        SelfData.lbmain = this.ilbMainResult;
        // this.filteredLookBookOptions = this.setDataAndFilter(
        //   this.lookbookControl,
        //   value,
        //   this.lookbookoptions,
        //   120
        // );
      });
    // SwsList
    this.serviceservice
      .setSWSList({accountid: accountid})
      .subscribe((swsvalue) => {
        this.iSWSResult[0] = swsvalue;
        SelfData.sws = this.iSWSResult;
        // this.filteredOptions = this.setDataAndFilter(
        //   this.myControl,
        //   swsvalue,
        //   this.options,
        //   0
        // );
      });

    // RegionList
    this.serviceservice
      .setRegionList({accountid: accountid})
      .subscribe((regionvalue) => {
        this.iRegionResult[0] = regionvalue;
        SelfData.region = this.iRegionResult;
        // this.filteredRegionOptions = this.setDataAndFilter(
        //   this.myRegionControl,
        //   regionvalue,
        //   this.regionoptions,
        //   1
        // );
      });
    // DesignerList
    this.serviceservice
      .setDesignerList({accountid: accountid})
      .subscribe((desingervalue) => {
        this.iDesignerResult[0] = desingervalue;
        SelfData.designer = this.iDesignerResult;
        // this.filteredDesignerOptions = this.setDataAndFilter(
        //   this.desingerFormControl,
        //   desingervalue,
        //   this.designerptions,
        //   2
        // );
      });
    // FabricList
    this.serviceservice
      .setFabricList({accountid: accountid})
      .subscribe((fabricvalue) => {
        this.iFabricResult[0] = fabricvalue;
        SelfData.fabric = this.iFabricResult;
        // this.filteredFabricOptions = this.setDataAndFilter(
        //   this.fabricControl,
        //   fabricvalue,
        //   this.fabricoptions,
        //   3
        // );
      });
    // ManuList
    this.serviceservice
      .setManuList({accountid: accountid})
      .subscribe((manuvalue) => {
        this.iManuResult[0] = manuvalue;
        SelfData.manu = this.iManuResult;
        // this.filteredManuOptions = this.setDataAndFilter(
        //   this.manuControl,
        //   manuvalue,
        //   this.manuoptions,
        //   4
        // );
      });
    // CollectionList

    this.serviceservice
      .setCollectionList({accountid: accountid})
      .subscribe((collectionvalue) => {
        this.iCollection[0] = collectionvalue;
        SelfData.collection = this.iCollection;
        // this.filteredCollectionOptions = this.setDataAndFilter(
        //   this.collectionFormControl,
        //   collectionvalue,
        //   this.collectionoptions,
        //   5
        // );
      });
    // SeasonList
    this.serviceservice
      .setSeasonList({accountid: accountid})
      .subscribe((seasonvalue) => {
        this.iSeason[0] = seasonvalue;
        SelfData.season = this.iSeason;
        // this.filteredSeasonOptions = this.setDataAndFilter(
        //   this.seasonFormControl,
        //   seasonvalue,
        //   this.seasonoptions,
        //   6
        // );
      });
    // StyleList
    // this.serviceservice
    //   .setStyleList({accountid: accountid})
    //   .subscribe((value) => {
    //     this.iStyle[0] = value;
    //     SelfData.style = this.iStyle;
    //     this.filteredStyleOptions = this.setDataAndFilter(
    //       this.styleControl,
    //       value,
    //       this.styleoptions,
    //       7
    //     );
    //   });
    // MisceList
    this.serviceservice
      .setMiscList({accountid: accountid})
      .subscribe((value) => {
        this.iMisc[0] = value;
        SelfData.misc = this.iMisc;
        // this.filteredMisceOptions = this.setDataAndFilter(
        //   this.miscControl,
        //   value,
        //   this.miscoptions,
        //   9
        // );
      });
    // TypeList
    this.serviceservice
      .setTypeList({accountid: accountid})
      .subscribe((value) => {
        this.iType[0] = value;
        SelfData.type = this.iType;
        // this.filteredTypeOptions = this.setDataAndFilter(
        //   this.typeFormControl,
        //   value,
        //   this.typeoptions,
        //   10
        // );
      });
    //sizeList
    this.serviceservice
      .setSizeList({accountid: accountid})
      .subscribe((value) => {
        this.iSize[0] = value;
        SelfData.size = this.iSize;
        // this.filteredSizeOptions = this.setDataAndFilter(
        //   this.sizeControl,
        //   value,
        //   this.sizeoptions,
        //   11
        // );
      });

    //sizerangeList
    this.serviceservice
      .setSizeRangeList({accountid: accountid})
      .subscribe((value) => {
        this.iSizeRange[0] = value;
        SelfData.sizerange = this.iSizeRange;
        // this.filteredSizeRangeOptions = this.setDataAndFilter(
        //   this.sizerangeControl,
        //   value,
        //   this.sizerangeoptions,
        //   13
        // );
      });

    this.serviceservice
      .setCFVList({
        accountid: accountid,
        framename: 'createsampledialog',
        sampleid: 0,
      })
      .subscribe((value) => {
        this.iCFV[0] = value;
        SelfData.cfv = this.iCFV;
      });

    // this.serviceservice.setSampleList({accountid: accountid, page: 1, pageSize: 3000}).subscribe({
    //   next: (value) => {
    //     if (value) {
    //     } else {
    //       console.log('The response is empty or undefined.');
    //     }
    //   },
    //   error: (error) => {
    //     console.error('Error occurred during the subscription:', error);
    //   },
    // });
  }

  getFilteredCFVOptions(controllername: string): Observable<string[]> {
    let filteredArray: string[] = [];

    if (
      SelfData.cfv &&
      SelfData.cfv.length > 0 &&
      SelfData.cfv[0].data !== null &&
      SelfData.cfv[0].data !== undefined
    ) {
      filteredArray = SelfData.cfv[0].data
        .filter((item) => item.controllername === controllername)
        .map((item) => item.value);
    }
    return of(filteredArray);
  }

  filterCFVptions(value: string): string[] {
    const filterValue = value.toLowerCase();

    // Perform your filtering logic here based on the provided value
    // and return the filtered fabric options as an array

    // Example filtering logic:
    return this.cfvoptions.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  // getCFV(framename: string, controllername: string, sampleid: number): Observable<any> {
  // let accountid: number = 0;
  // if (SelfData.account[0].data[0].parentid === 0) {
  //   accountid = SelfData.account[0].data[0].id;
  // } else {
  //   accountid = SelfData.account[0].data[0].parentid;
  // }
  // return this.serviceservice.setCFVList({ accountid, framename, controllername, sampleid });
  // }

  private setDataAndFilter(
    control: FormControl,
    response: any,
    options: string[],
    type: number
  ) {
    let results = response.data;
    for (let data of results) {
      options.push(data.description);
    }
    return control.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || '', type))
    );
  }

  iSampleList: samplehistory[] = [];

  ngAfterViewInit() {
    this.matAutocompletes.forEach((matAutocomplete, index) => {
      this.autoCompletes[`auto${index + 1}`] = matAutocomplete;
    });
    var accountid: number = 0;
    if (SelfData.account[0].data[0].parentid == 0) {
      accountid = SelfData.account[0].data[0].id;
    } else {
      accountid = SelfData.account[0].data[0].parentid;
    }
    this.yourappsService.currentSampleData.subscribe((data) => {
      this.tdata = data;
      if (data != null) {
        if (SelfData.isduplicate) {
          this.formcaption = "Create Duplicate"
        } else {
          this.formcaption = "Edit Sample"
        }
        this.inventory = data?.['Inventory Status'] ?? '';
        this.genderstring = data?.['Gender'] ?? '';
        this.swsstring = data?.['Sample Workflow Status'] ?? '';
// this.departmentstring = data?.['Department'] ?? '';
        this.regionstring = data?.['Region'] ?? '';
        this.notesstring = data?.['Notes'] ?? '';
        this.designerstring = data?.['Designer'] ?? '';
        this.fabricstring = data?.['Fabric'] ?? '';
        this.manustring = data?.['Manufacturer'] ?? '';
        this.collectionstring = data?.['Collection'] ?? '';
        this.stylestring = data?.['Style'] ?? '';
        this.associatedstring = data?.['Looks Associated'] ?? '';
        this.seasonstring = data?.['Season'] ?? '';
        this.miscstring = data?.['Misc'] ?? '';
        this.quantitystring = data?.['Quantity'] ?? '1'; // Defaulting to '1' as per your initial value
        this.typestring = data?.['Type'] ?? '';
        this.sizestring = data?.['Size'] ?? '';
        this.sizerangestring = data?.['Size Range'] ?? '';
        this.patternstring = data?.['Pattern'] ?? '';
        if (SelfData.isduplicate) {
          this.barcodestring = "";
        } else {
          this.barcodestring = data?.['barcode'] ?? '';
        }
        // this.colorstring = data?.['Color'] ?? '';
        this.lookbookstring = data?.['LookBook'] ?? '';
        this.retailPriceEURstring = data?.['Retail Price EUR'] ?? '0'; // Defaulting to '0'
        this.retailPriceUSDstring = data?.['Retail Price USD'] ?? '0'; // Defaulting to '0'
        this.wholesaleEURstring = data?.['Wholesale EUR'] ?? '0'; // Defaulting to '0'
        this.wholesaleUSDstring = data?.['Wholesale USD'] ?? '0'; // Defaulting to '0'
        this.salePriceEURstring = data?.['Sale Price EUR'] ?? '0'; // Defaulting to '0'
        this.salePriceUSDstring = data?.['Sale Price USD'] ?? '0'; // Defaulting to '0'
        this.descriptionstring = data?.['Description']?.toUpperCase() ?? ''; // Applying the toUpperCase() logic as in your control setup
        const matchingColor = this.colorarray.find(
          color => color.colorname.toUpperCase().trim() === data?.['Color'].toUpperCase().trim()
        );
        if (matchingColor) {
          this.colorstring = matchingColor.colorcode;
        }
        // this.myControl.setValue(data?.['Sample Workflow Status']);
        // // this.myForm.value.gender.setValue(data?.["Gender"])
        // // this.myForm.value.inventoryStatus.setValue(data?.["Inventory Status"])
        // this.myForm = this.fb.group({
        //   inventoryStatus: [data?.['Inventory Status']],
        //   gender: [data?.['Gender']],
        //   color: data?.['Color'],
        // });
        // this.myControl.setValue(data?.['Sample Workflow Status']);
        // this.myRegionControl.setValue(data?.['Region']);
        // this.notesRegionControl.setValue(data?.['Notes']);
        // this.desingerFormControl.setValue(data?.['Designer']);
        // this.fabricControl.setValue(data?.['Fabric']);
        // this.manuControl.setValue(data?.['Manufacturer']);
        // this.collectionFormControl.setValue(data?.['Collection']);
        // this.styleControl.setValue(data?.['Style']);
        // this.associatedControl.setValue(data?.['Looks Associated']);
        // this.seasonFormControl.setValue(data?.['Season']);
        // this.miscControl.setValue(data?.['Misc']);
        // this.quantityControl.setValue(data?.['Quantity']);
        // this.typeFormControl.setValue(data?.['Type']);
        // this.sizeControl.setValue(data?.['Size']);
        // this.patternControl.setValue(data?.['Pattern']);
        // this.lookbookControl.setValue(data?.['LookBook']);
        // this.colorControl.setValue(data?.['Color']);
        // this.sizerangeControl.setValue(data?.['Size Range']);
        // this.priceControl.setValue(data?.['Retail Price EUR']);
        // this.price1Control.setValue(data?.['Retail Price USD']);
        // this.price2Control.setValue(data?.['Wholesale EUR']);
        // this.price3Control.setValue(data?.['Wholesale USD']);
        // this.price4Control.setValue(data?.['Sale Price EUR']);
        // this.price5Control.setValue(data?.['Sale Price USD']);
        // this.pricesid = data?.['pricesid'];
        // this.descriptionControl.setValue(data?.['Description']?.toUpperCase() ?? '');
        if (SelfData.isduplicate) {
          this.isVisible = true;
          this.createbuttoncaption = 'Create';
        } else {
          this.isVisible = false;
          this.createbuttoncaption = 'Update';
        }

        this.serviceservice
          .getSampleHistoy({sampleid: data?.['ID']})
          .subscribe((value) => {
            this.iSampleList[0] = value;
            console.log("this.iSampleList", this.iSampleList)
            SelfData.samplehistory = this.iSampleList;
          });

        this.serviceservice
          .setImageList({accountid: accountid, sampleid: data?.['ID']})
          .subscribe({
            next: (value: any) => {
              if (value && value.data && Array.isArray(value.data)) {
                this.iImages[0] = value.data;
                console.log(value.data)
                value.data.forEach((item: { imagelink?: string, sortby: number }) => {
                  if (item.imagelink) {
                    // console.log("item", item)
                    // Include both file and url properties
                    const url = item.imagelink;

                    const parts = url.split('/');

                    const fileName = parts[parts.length - 1];

                    this.selectedImageFiles.push({
                      file: null,
                      url: item.imagelink,
                      filename: fileName,
                      sortby: item.sortby
                    });
                    this.AIDisabled = this.selectedImageFiles.length === 0;
                  }
                });
              } else {
                console.log(
                  'The response is empty or not in the expected format.'
                );
              }
            },
            error: (error) => {
              console.error('Error occurred during the subscription:', error);
            },
          });
        let cfdata = SelfData.customfields[0].data;
        for (let i = 0; i < cfdata.length; i++) {
          const input = document.getElementById(
            cfdata[i].fieldlabel
          ) as HTMLInputElement | null;
          if (input != null) {
            input.value = data?.[cfdata[i].fieldlabel];
          }
        }
      } else {
        this.formcaption = "Create Sample"
      }
    });

    this.cdr.detectChanges();
  }

  formatDate(date: string): string {
    return <string>this.datePipe.transform(date, 'dd/MMM/yy HH:mm');
  }

  ngOnInit(): void {
    this.setupValueChanges();
    this.reloadOptionsOnSignal();
    this.AIDisabled = true
    this.subscription2 = this.yourappsService.validSelectedFiles$.subscribe((value: {
      data: SetSelectedFilesV[] | SetSelectedFilesV,
      type: number
    }) => {
      console.log("Received value:", value);
      let data = Array.isArray(value.data) ? value.data : [value.data]; // Ensure data is always an array

      if (value.type === 1 && data.length > 0) {
        this.notneededNewID = false
        this.selectedImageFiles = [];

        if ('description' in data[0]) {
          this.patternstring = data[0].description
          // this.patternControl.setValue(data[0].description);
        }

        this.selectedImageFiles.push({
          file: data[0].file,
          url: data[0].url,
          filename: data[0].filename,
          sortby: 1
          // Add other properties if needed
        });
        this.AIDisabled = this.selectedImageFiles.length === 0;
        this.cdr.detectChanges();
        this.yourappsService.setValudSelectedFiles(null, 0);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.subscription1) {
      this.subscription1.unsubscribe();
    }
    if (this.subscription2) {
      this.subscription2.unsubscribe();
    }
    if (this.subscription3) {
      this.subscription3.unsubscribe();
    }
    if (this.valueChangesSubscription) {
      this.valueChangesSubscription.unsubscribe();
    }
    if (this.neededreload) {
      this.neededreload = false
      this.yourappsService.getReloadValidedList()
      this.yourappsService.getReloadSampleList()
    }
  }

  setupValueChanges(): void {
    this.yourappsService.collectionsbyid$.subscribe((value: { data: any, type: number }) => {
      if (value.type === 1) {
        const id = value.data.id;
        const accountid = value.data.accountid;

        this.serviceservice.setCollections({accountid: accountid, id}).subscribe({
          next: (value: any) => {
            if (value && value.data && Array.isArray(value.data)) {
              this.collectionbyid = [value]; // Storing the entire response in an array

              // Accessing the data array
              const dataArray = this.collectionbyid[0].data;

              // Example: Looping through the data array
              dataArray.forEach(item => {
                // You can access other properties here as well
                // this.desingerFormControl.setValue(item.desinger)
                // this.collectionFormControl.setValue(item.description)
                // this.seasonFormControl.setValue(item.season)

                this.designerstring = item.desinger ?? ''; // Assuming `designer` is the correct property in `item`
                this.collectionstring = item.description ?? '';
                this.seasonstring = item.season ?? '';
              });
              this.yourappsService.getCollectionsByID(null, 0)
            } else {
              console.log('The response is empty or not in the expected format.');
            }
          },
          error: (error) => {
            console.error('Error occurred during the subscription:', error);
          }
        });
      }
    });
    this.yourappsService.selectedValue$.subscribe((description) => {
      // switch (SelfData.modaltype) {
      //   case 0:
      //     this.myControl.setValue(description);
      //     break;
      //   case 1:
      //     this.myRegionControl.setValue(description);
      //     break;
      //   case 2:
      //     this.desingerFormControl.setValue(description);
      //     break;
      //   case 3:
      //     this.fabricControl.setValue(description);
      //     break;
      //   case 4:
      //     this.manuControl.setValue(description);
      //     break;
      //   case 5:
      //     this.collectionFormControl.setValue(description);
      //     break;
      //   case 6:
      //     this.seasonFormControl.setValue(description);
      //     break;
      //   case 7:
      //     this.styleControl.setValue(description);
      //     break;
      //   case 9:
      //     this.miscControl.setValue(description);
      //     break;
      //   case 10:
      //     this.typeFormControl.setValue(description);
      //     break;
      //   case 11:
      //     this.sizeControl.setValue(description);
      //     break;
      //   case 12:
      //     this.patternControl.setValue(description);
      //     break;
      //   case 13:
      //     this.sizerangeControl.setValue(description);
      //     break;
      // }
      switch (SelfData.modaltype) {
        case 0:
          this.swsstring = description ?? '';
          break;
        case 1:
          this.regionstring = description ?? '';
          break;
        case 2:
          this.designerstring = description ?? '';
          break;
        case 3:
          this.fabricstring = description ?? '';
          break;
        case 4:
          this.manustring = description ?? '';
          break;
        case 5:
          this.collectionstring = description ?? '';
          break;
        case 6:
          this.seasonstring = description ?? '';
          break;
        case 7:
          this.stylestring = description ?? '';
          break;
        case 9:
          this.miscstring = description ?? '';
          break;
        case 10:
          this.typestring = description ?? '';
          break;
        case 11:
          this.sizestring = description ?? '';
          break;
        case 12:
          this.patternstring = description ?? '';
          break;
        case 13:
          this.sizerangestring = description ?? '';
          break;
      }
    });

    // this.desingerFormControl.valueChanges.subscribe((selectedValue) => {
    //   if (selectedValue) {
    //     console.log('User selected', selectedValue);
    //   } else {
    //     console.log('No selection or selection cleared');
    //   }
    // });
  }

  reloadOptionsOnSignal(): void {
    console.log("{{{{{SelfData.modaltype}}}}}", SelfData.modaltype)
    this.yourappsService.reloadArrayList.subscribe((description) => {
      let accountid: number = this.determineAccountId();
      // this.resetResults();

      // this.iSWSResult = [];
      // this.iRegionResult = [];
      // this.iDesignerResult = [];
      // this.iFabricResult = [];
      // this.iManuResult = [];
      // this.iCollection = [];
      // this.iSeason = [];
      // this.iStyle = [];
      // this.iMisc = [];
      // this.iType = [];
      // this.iSize = [];
      // this.iPattern = [];
      // this.iSizeRange = [];

      switch (SelfData.modaltype) {
        case 0:
          this.iSWSResult = [];
          this.reloadSWSList(accountid);
          break;
        case 1:
          this.iRegionResult = [];
          this.reloadRegionList(accountid);
          break;
        case 2:
          this.iDesignerResult = [];
          this.reloadDesignerList(accountid);
          break;
        case 3:
          this.iFabricResult = [];
          this.reloadFabricList(accountid);
          break;
        case 4:
          this.iManuResult = [];
          this.reloadManuList(accountid);
          break;
        case 5:
          this.iDesignerResult = [];
          this.iSeason = [];
          this.iCollection = [];
          this.reloadDesignerList(accountid);
          this.reloadSeasonList(accountid);
          this.reloadCollectionList(accountid);
          break;
        case 6:
          this.iSeason = [];
          this.reloadSeasonList(accountid);
          break;
        case 7:
          this.iStyle = [];
          this.reloadStyleList(accountid);
          break;
        case 9:
          this.iMisc = [];
          this.reloadMiscList(accountid);
          break;
        case 10:
          this.iType = [];
          this.reloadTypeList(accountid);
          break;
        case 11:
          this.iSize = [];
          this.reloadSizeList(accountid);
          break;
        case 12:
          this.iPattern = [];
          this.reloadPatternList(accountid);
          break;
        case 13:
          this.iSizeRange = [];
          this.reloadSizeRangeList(accountid);
          break;
      }
    });
  }

  determineAccountId(): number {
    if (SelfData.account[0].data[0].parentid == 0) {
      return SelfData.account[0].data[0].id;
    } else {
      return SelfData.account[0].data[0].parentid;
    }
  }

  resetResults(): void {
    this.iSWSResult = [];
    this.iRegionResult = [];
    this.iDesignerResult = [];
    this.iFabricResult = [];
    this.iManuResult = [];
    this.iCollection = [];
    this.iSeason = [];
    this.iStyle = [];
    this.iMisc = [];
    this.iType = [];
    this.iSize = [];
    this.iPattern = [];
    this.iSizeRange = [];
  }

  reloadSWSList(accountid: number): void {
    this.serviceservice
      .setSWSList({accountid: accountid})
      .subscribe((swsvalue) => {
        this.iSWSResult.push(swsvalue);
        SelfData.sws = this.iSWSResult;

        //   this.options = this.iSWSResult[0].data.map((item) => item.description);
        //   this.filteredOptions = this.myControl.valueChanges.pipe(
        //     startWith(''),
        //     map((value) => this._filter(value || '', 0))
        //   );
      });
  }

  reloadRegionList(accountid: number): void {
    this.serviceservice
      .setRegionList({accountid: accountid})
      .subscribe((regionvalue) => {
        this.iRegionResult.push(regionvalue);
        SelfData.region = this.iRegionResult;

        // this.regionoptions = this.iRegionResult[0].data.map(
        //   (item) => item.description
        // );
        // this.filteredRegionOptions = this.myRegionControl.valueChanges.pipe(
        //   startWith(''),
        //   map((value) => this._filter(value || '', 1))
        // );
      });
  }

  reloadDesignerList(accountid: number): void {
    this.serviceservice
      .setDesignerList({accountid: accountid})
      .subscribe((designervalue) => {
        this.iDesignerResult.push(designervalue);
        SelfData.designer = this.iDesignerResult;

        // this.designerptions = this.iDesignerResult[0].data.map(
        //   (item) => item.description
        // );
        // this.filteredDesignerOptions =
        //   this.desingerFormControl.valueChanges.pipe(
        //     startWith(''),
        //     map((value) => this._filter(value || '', 2))
        //   );
      });
  }

  reloadFabricList(accountid: number): void {
    this.serviceservice
      .setFabricList({accountid: accountid})
      .subscribe((fabricvalue) => {
        this.iFabricResult.push(fabricvalue);
        SelfData.fabric = this.iFabricResult;

        // this.fabricoptions = this.iFabricResult[0].data.map(
        //   (item) => item.description
        // );
        // this.filteredFabricOptions = this.fabricControl.valueChanges.pipe(
        //   startWith(''),
        //   map((value) => this._filter(value || '', 3))
        // );
      });
  }

  reloadManuList(accountid: number): void {
    this.serviceservice
      .setManuList({accountid: accountid})
      .subscribe((Manuvalue) => {
        this.iManuResult.push(Manuvalue);
        SelfData.manu = this.iManuResult;

        // this.manuoptions = this.iManuResult[0].data.map(
        //   (item) => item.description
        // );
        // this.filteredManuOptions = this.manuControl.valueChanges.pipe(
        //   startWith(''),
        //   map((value) => this._filter(value || '', 4))
        // );
      });
  }

  reloadCollectionList(accountid: number): void {
    console.log(3, accountid)
    this.serviceservice
      .setCollectionList({accountid: accountid})
      .subscribe((Collectionvalue) => {
        this.iCollection.push(Collectionvalue);
        SelfData.collection = this.iCollection;

        // this.collectionoptions = this.iCollection[0].data.map(
        //   (item) => item.description
        // );
        // this.filteredCollectionOptions =
        //   this.collectionFormControl.valueChanges.pipe(
        //     startWith(''),
        //     map((value) => this._filter(value || '', 5))
        //   );
      });
  }

  reloadSeasonList(accountid: number): void {
    this.serviceservice
      .setSeasonList({accountid: accountid})
      .subscribe((seasonvalue) => {
        this.iSeason.push(seasonvalue);
        SelfData.season = this.iSeason;

        // this.seasonoptions = this.iSeason[0].data.map(
        //   (item) => item.description
        // );
        // this.filteredSeasonOptions = this.seasonFormControl.valueChanges.pipe(
        //   startWith(''),
        //   map((value) => this._filter(value || '', 6))
        // );
      });
  }

  reloadStyleList(accountid: number): void {
    this.serviceservice
      .setStyleList({accountid: accountid})
      .subscribe((value) => {
        this.iStyle.push(value);
        SelfData.style = this.iStyle;

        // this.styleoptions = this.iStyle[0].data.map((item) => item.description);
        // this.filteredStyleOptions = this.styleControl.valueChanges.pipe(
        //   startWith(''),
        //   map((value) => this._filter(value || '', 7))
        // );
      });
  }

  reloadMiscList(accountid: number): void {
    this.serviceservice
      .setMiscList({accountid: accountid})
      .subscribe((value) => {
        this.iMisc.push(value);
        SelfData.misc = this.iMisc;

        // this.miscoptions = this.iMisc[0].data.map((item) => item.description);
        // this.filteredMisceOptions = this.miscControl.valueChanges.pipe(
        //   startWith(''),
        //   map((value) => this._filter(value || '', 9))
        // );
      });
  }

  reloadTypeList(accountid: number): void {
    this.serviceservice
      .setTypeList({accountid: accountid})
      .subscribe((value) => {
        this.iType.push(value);
        SelfData.type = this.iType;
        // this.typeoptions = this.iType[0].data.map((item) => item.description);
        // this.filteredTypeOptions = this.typeFormControl.valueChanges.pipe(
        //   startWith(''),
        //   map((value) => this._filter(value || '', 10))
        // );
      });
  }

  reloadSizeList(accountid: number): void {
    this.serviceservice
      .setSizeList({accountid: accountid})
      .subscribe((value) => {
        this.iSize.push(value);
        SelfData.size = this.iSize;

        // this.sizeoptions = this.iSize[0].data.map((item) => item.description);
        // this.filteredSizeOptions = this.sizeControl.valueChanges.pipe(
        //   startWith(''),
        //   map((value) => this._filter(value || '', 11))
        // );
      });
  }

  reloadPatternList(accountid: number): void {
    this.serviceservice
      .setPatternList({accountid: accountid})
      .subscribe((value) => {
        this.iPattern.push(value);
        SelfData.pattern = this.iPattern;
        // console.log("this.iPattern", this.iPattern)
        // this.patternoptions = this.iPattern[0].data.map(
        //   (item) => item.description
        // );
        // this.filteredPatternOptions = this.patternControl.valueChanges.pipe(
        //   startWith(''),
        //   map((value) => this._filter(value || '', 12))
        // );
      });
  }

  reloadSizeRangeList(accountid: number): void {
    this.serviceservice
      .setSizeRangeList({accountid: accountid})
      .subscribe((value) => {
        this.iSizeRange.push(value);
        SelfData.sizerange = this.iSizeRange;

        //   this.sizerangeoptions = this.iSizeRange[0].data.map(
        //     (item) => item.description
        //   );
        //   this.filteredSizeRangeOptions = this.sizerangeControl.valueChanges.pipe(
        //     startWith(''),
        //     map((value) => this._filter(value || '', 13))
        //   );
      });
  }

  private _filter(value: string, type: number): string[] {
    const filterValue = value.toLowerCase();

    // Map the type to the corresponding options array
    const optionsMap: { [key: number]: string[] } = {
      0: this.options,
      1: this.regionoptions,
      2: this.designerptions,
      3: this.fabricoptions,
      4: this.manuoptions,
      5: this.collectionoptions,
      6: this.seasonoptions,
      7: this.styleoptions,
      // 8: this.,
      9: this.miscoptions,
      10: this.typeoptions,
      11: this.sizeoptions,
      12: this.patternoptions,
      13: this.sizerangeoptions,
      120: this.lookbookoptions,
      // ... add the rest here
    };

    if (optionsMap[type]) {
      return optionsMap[type].filter((option) =>
        option.toLowerCase().includes(filterValue)
      );
    } else {
      console.warn(`Unexpected filter type: ${type}`);
      return [];
    }
  }

  add(
    event: MouseEvent,
    type: number,
    autoInput: MatAutocompleteTrigger | null
  ): void {
    event.stopPropagation(); // This will stop the event from bubbling up
    SelfData.modaltype = type;
    if (autoInput != null) {
      autoInput.closePanel();
    }
    // Determine the caption based on the provided type
    switch (type) {
      case 0:
        SelfData.modalformcaption = 'Sample Workflow Status';
        SelfData.frame = 0;
        break;
      case 1:
        SelfData.modalformcaption = 'Region';
        SelfData.frame = 0;
        break;
      case 2:
        SelfData.modalformcaption = 'Designer';
        SelfData.frame = 0;
        break;
      case 3:
        SelfData.modalformcaption = 'Fabric';
        SelfData.frame = 0;
        break;
      case 4:
        SelfData.modalformcaption = 'Manufacturer';
        SelfData.frame = 0;
        break;
      case 5:
        SelfData.modalformcaption = 'Collection';
        SelfData.frame = 1;
        break;
      case 6:
        SelfData.modalformcaption = 'Season';
        SelfData.frame = 1;
        break;
      case 7:
        SelfData.modalformcaption = 'Style';
        SelfData.frame = 0;
        break;
      case 9:
        SelfData.modalformcaption = 'Misc';
        SelfData.frame = 0;
        break;
      case 10:
        SelfData.modalformcaption = 'Type';
        SelfData.frame = 0;
        break;
      case 11:
        SelfData.modalformcaption = 'Size';
        SelfData.frame = 0;
        break;
      case 12:
        SelfData.modalformcaption = 'Pattern';
        SelfData.frame = 0;
        break;
      case 13:
        SelfData.modalformcaption = 'Size Range';
        SelfData.frame = 0;
        break;
      case 120:
        SelfData.modalformcaption = 'Look Book';
        SelfData.frame = 0;
        break;
      default:
        console.log(`Unsupported type: ${type}`);
        return;
    }

    // Invoke the modal
    if (type !== 120) {
      this.modalService.createSWS();
    } else {
      SelfData.lbframetype = 1
      this.subscription = this.yourappsService.setLookBook$.subscribe((value: { data: any, type: number }) => {
        if (this.subscription) {
          this.subscription.unsubscribe();
          if (value.data) {
            this.selectedlookbook[0] = value.data
            // this.lookbookControl.setValue(value.data.description)
            this.lookbookstring = value.data.description ?? '';
          }
        }
      })
      this.modalService.openlb()
    }

    // your logic for when the add button is clicked
  }

  findIdByDescriptionInSWS(
    swsList: GetSWSListData[],
    targetDescription: string
  ): number {
    const found = swsList.find(
      (element) => element.description === targetDescription
    );

    return found ? found.id : 0;
  }

  findIdByDescriptionInRegion(
    regionList: GetRegionListData[],
    targetDescription: string
  ): number {
    const found = regionList.find(
      (element) => element.description === targetDescription
    );

    return found ? found.id : 0;
  }

  findIdByDescriptionInDesigner(
    designerList: GetDesignerListData[],
    targetDescription: string
  ): number {
    const found = designerList.find(
      (element) => element.description === targetDescription
    );

    return found ? found.id : 0;
  }

  findIdByDescriptionInFabric(
    fabricList: GetFabricListData[],
    targetDescription: string
  ): number {
    const found = fabricList.find(
      (element) => element.description === targetDescription
    );

    return found ? found.id : 0;
  }

  findIdByDescriptionInManu(
    manuList: GetManuListData[],
    targetDescription: string
  ): number {
    const found = manuList.find(
      (element) => element.description === targetDescription
    );

    return found ? found.id : 0;
  }

  findIdByDescriptionInCollection(
    collectionList: GetCollectionListData[],
    targetDescription: string
  ): number {
    const found = collectionList.find(
      (element) => element.description === targetDescription
    );

    return found ? found.id : 0;
  }

  findIdByDescriptionInSeason(
    seasonList: GetSeasonListData[],
    targetDescription: string
  ): number {
    const found = seasonList.find(
      (element) => element.description === targetDescription
    );

    return found ? found.id : 0;
  }

  findIdByDescriptionInStyle(
    styleList: GetStyleListData[],
    targetDescription: string
  ): number {
    const found = styleList.find(
      (element) => element.description === targetDescription
    );

    return found ? found.id : 0;
  }

  findIdByDescriptionInMisc(
    miscList: GetMiscListData[],
    targetDescription: string
  ): number {
    const found = miscList.find(
      (element) => element.description === targetDescription
    );

    return found ? found.id : 0;
  }

  findIdByDescriptionInType(
    typeList: GetTypeListData[],
    targetDescription: string
  ): number {
    const found = typeList.find(
      (element) => element.description === targetDescription
    );

    return found ? found.id : 0;
  }

  findIdByDescriptionInSize(
    sizeList: GetSizeListData[],
    targetDescription: string
  ): number {
    const found = sizeList.find(
      (element) => element.description === targetDescription
    );

    return found ? found.id : 0;
  }

  findIdByDescriptionInSizeRange(
    sizerangeList: GetSizeRangeListData[],
    targetDescription: string
  ): number {
    const found = sizerangeList.find(
      (element) => element.description === targetDescription
    );

    return found ? found.id : 0;
  }

  findIdByDescriptionInPattern(
    patternList: GetPatternListData[],
    targetDescription: string
  ): number {
    const found = patternList.find(
      (element) => element.description === targetDescription
    );

    return found ? found.id : 0;
  }

  async prcaddprices() {
    if (this.pricesid === 0) {
      try {
        // const data = await firstValueFrom(this.serviceservice.prcprices(
        //   0, this.getAccountID(), 0, this.priceControl.value, this.price1Control.value, this.price2Control.value,
        //   this.price3Control.value, this.price4Control.value, this.price5Control.value
        // ));
        const data = await firstValueFrom(this.serviceservice.prcprices(
          0, this.getAccountID(), 0, this.retailPriceEURstring, this.retailPriceUSDstring,
          this.wholesaleEURstring, this.wholesaleUSDstring, this.salePriceEURstring, this.salePriceUSDstring
        ));
        this.pricesid = data.data;
        console.log(data.data);
      } catch (error) {
        console.error('Error:', error);
      }
    } else {
      try {
        const data = await firstValueFrom(this.serviceservice.prcprices(
          0, this.getAccountID(), 0, this.retailPriceEURstring, this.retailPriceUSDstring,
          this.wholesaleEURstring, this.wholesaleUSDstring, this.salePriceEURstring, this.salePriceUSDstring
        ));
        this.pricesid = data.data;
        console.log(data.data);
      } catch (error) {
        console.error('Error:', error);
      }
    }

  }

  async submit(type: number = 0) {
    let searchedDesigner: GetDesignerListData | undefined;

    if (this.designerstring.trim() === "") {
      this.messageService.add({
        key: 'tc',
        severity: 'warn',
        summary: 'Information',
        detail:
          'Designer not found. \n' +
          'You need to add this designer from the collection.',
        life: 10000,
      });
      return
      // for (let designerList of this.iDesignerResult) {
      //   if (Array.isArray(designerList.data)) {
      //     searchedDesigner = designerList.data.find(designer => designer.description === this.designerstring);
      //     if (searchedDesigner) break;
      //   }
      // }
      //
      //   if (searchedDesigner) {
      //
      //   } else {
      //     this.messageService.add({
      //       key: 'tc',
      //       severity: 'warn',
      //       summary: 'Information',
      //       detail:
      //         'Designer not found. \n' +
      //         'You need to add this designer from the collection.',
      //       life: 10000,
      //     });
      //     return
      //   }
    }

    if (this.seasonstring.trim() === "") {
      this.messageService.add({
        key: 'tc',
        severity: 'warn',
        summary: 'Information',
        detail:
          'Season not found. \n' +
          'You need to add the season from the collection.',
        life: 10000,
      });
      return
    }

    if (this.typestring.trim() === "") {
      this.messageService.add({
        key: 'tc',
        severity: 'warn',
        summary: 'Information',
        detail:
          'Type not found. \n' +
          'You need to add type.',
        life: 10000,
      });
      return
    }

    var ttype: number = 0
    if (this.lookbookstring?.trim().length > 0) {
      ttype = 1;
      console.log(1);
    }

    if (this.designerstring.trim() === '') {
      // Handle empty designer case
    } else if (this.collectionstring.trim() === '') {
      // Handle empty collection case
    } else if (this.seasonstring.trim() === '') {
      // Handle empty season case
    } else if (this.typestring.trim() === '') {
      // Handle empty type case
    } else {
      var accountid: number = 0;
      if (SelfData.account[0].data[0].parentid == 0) {
        accountid = SelfData.account[0].data[0].id;
      } else {
        accountid = SelfData.account[0].data[0].parentid;
      }
      let value: string = '';
      let viewValue: string = '';
      const inventoryStatusValue = this.myForm.get('inventoryStatus')?.value;

      const selectedOption = this.inventorystatus.find(
        (option) => option.viewValue === inventoryStatusValue
      );

      if (selectedOption) {
        value = selectedOption.value;
        viewValue = selectedOption.viewValue;
      }

      let colorname: string = '';
      let colorcode: string = '';

      // const selectedColor = this.colorControl.value;
      const selectedColor = this.colorstring;
      const selectedColorObj = this.colorarray.find(
        (color) => color.colorcode === selectedColor
      );

      if (selectedColorObj) {
        colorname = selectedColorObj.colorname;
        colorcode = selectedColorObj.colorcode;

        // Use the colorname and colorcode as needed
      } else {
        console.log('Selected Color not found');
      }

      // console.log("this.pricesid", this.pricesid)
      // if (this.pricesid === 0) {
      //   this.serviceservice.prcprices(
      //     0, this.getAccountID(), 0, this.priceControl.value, this.price1Control.value, this.price2Control.value,
      //     this.price3Control.value, this.price4Control.value, this.price5Control.value
      //   ).subscribe((data) => {
      //     tuserid = data.data
      //     console.log(data.data)
      //   });
      // } else {
      //   this.serviceservice.prcprices(
      //     1, this.getAccountID(), this.pricesid, this.priceControl.value, this.price1Control.value, this.price2Control.value,
      //     this.price3Control.value, this.price4Control.value, this.price5Control.value
      //   ).subscribe((data) => {
      //     tuserid = data.data
      //     console.log(data.data)
      //   });
      // }

      await this.prcaddprices();

      var ctrl: number = 0;
      var id: number = 0;
      if (this.tdata == null || SelfData.isduplicate) {
        ctrl = 0;
        id = 0;
      } else {
        ctrl = 1;
        id = this.tdata?.['ID'];
      }


      let account = {
        ctrl: ctrl,
        id: id,
        inventoristatus: viewValue,
        inventoristatusid: value,
        gender: this.genderstring, // Replace with corresponding string property
        sampleworkflowstatusid: this.findIdByDescriptionInSWS(
          SelfData.sws.flatMap((value) => value.data),
          this.swsstring || ''
        ),
        accountid: accountid,
        regionid: this.findIdByDescriptionInRegion(
          SelfData.region.flatMap((value) => value.data),
          this.regionstring || ''
        ),
        notes: this.notesstring, // Replace with corresponding string property
        designerid: this.findIdByDescriptionInDesigner(
          SelfData.designer.flatMap((value) => value.data),
          this.designerstring || ''
        ),
        fabricid: this.findIdByDescriptionInFabric(
          SelfData.fabric.flatMap((value) => value.data),
          this.fabricstring || ''
        ),
        manufacturerid: this.findIdByDescriptionInManu(
          SelfData.manu.flatMap((value) => value.data),
          this.manustring || ''
        ),
        collectionid: this.findIdByDescriptionInCollection(
          SelfData.collection.flatMap((value) => value.data),
          this.collectionstring || ''
        ),
        styleid: this.findIdByDescriptionInStyle(
          SelfData.style.flatMap((value) => value.data),
          this.stylestring || ''
        ),
        looksassociated: this.associatedstring, // Replace with corresponding string property
        sessionid: this.findIdByDescriptionInSeason(
          SelfData.season.flatMap((value) => value.data),
          this.seasonstring || ''
        ),
        miscid: this.findIdByDescriptionInMisc(
          SelfData.misc.flatMap((value) => value.data),
          this.miscstring || ''
        ),
        quantity: this.quantitystring, // Replace with corresponding string property
        typeid: this.findIdByDescriptionInType(
          SelfData.type.flatMap((value) => value.data),
          this.typestring || ''
        ),
        sizeid: this.findIdByDescriptionInSize(
          SelfData.size.flatMap((value) => value.data),
          this.sizestring || ''
        ),
        sizerangeid: this.findIdByDescriptionInSizeRange(
          SelfData.sizerange.flatMap((value) => value.data),
          this.sizerangestring || ''
        ),
        patternid: this.findIdByDescriptionInPattern(
          SelfData.pattern.flatMap((value) => value.data),
          this.patternstring || ''
        ),
        colorname: colorname,
        colorcode: colorcode,
        pricesid: this.pricesid,
        description: this.descriptionstring, // Replace with corresponding string property
        storageid: 0,
        curdate: new Date(),
        userid: SelfData.account[0].data[0].id,
        isactiviti: true,
        stylestr: this.stylestring, // Replace with corresponding string property
        barcode: this.barcodestring,
        samplestyle: SelfData.samplestyle
      };

      // this.serviceservice.prcSample(account).subscribe(async (data) => {
      //   this.getValue(data.data);
      //   if (type <= 2) {
      //     this.neededreload = false;
      //     await this.onUpload(0, accountid, SelfData.account[0].data[0].id, data.data, ttype);
      //     this.yourappsService.getReloadValidedList()
      //     this.yourappsService.getReloadSampleList()
      //     this.modalService.createsamplerclose();
      //   } else if (type === 3) {
      //     this.neededreload = true;
      //     await this.onUpload(0, accountid, SelfData.account[0].data[0].id, data.data, ttype);
      //     this.clearAllfieldOnAdd()
      //   }
      //   this.messageService.add({
      //     key: 'tc',
      //     severity: 'success',
      //     summary: 'Information',
      //     detail: 'Item successfully added',
      //     life: 5000,
      //   });
      // });
      let repetitions = parseInt(this.quantitystring, 10);
      for (let i = 0; i < repetitions; i++) {
        this.serviceservice.prcSample(account).subscribe(async (data) => {
          this.getValue(data.data);

          if (type <= 2) {
            this.neededreload = false;
            await this.onUpload(0, accountid, SelfData.account[0].data[0].id, data.data, ttype);
            this.yourappsService.getReloadValidedList();
            this.yourappsService.getReloadSampleList();
            this.modalService.createsamplesaleclose();
          } else if (type === 3) {
            this.neededreload = true;
            await this.onUpload(0, accountid, SelfData.account[0].data[0].id, data.data, ttype);
            this.clearAllfieldOnAdd();
          }

          this.messageService.add({
            key: 'tc',
            severity: 'success',
            summary: 'Information',
            detail: 'Item successfully added',
            life: 5000,
          });
        });
        if (type == 0) {

        } else if (type == 1) {
          this.modalService.createsamplesaleclose();
        }
      }
    }
  }

  selectedFiles: File[] = [];
  selectedImageFiles: { file: File | null; url: string; filename: string, sortby: number }[] = [];
  deletedImageFiles: { file: File | null; url: string; filename: string, sortby: number }[] = [];

  uploadFiles() {
    let fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.multiple = true;
    fileInput.accept = '.txt,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.ods,.odt'; // Accept Microsoft Office file formats
    fileInput.addEventListener('change', (event) => {
      const files: FileList | null = (event.target as HTMLInputElement).files;
      if (files) {
        this.selectedFiles = Array.from(files);
        this.selectedFiles.forEach((file) => {
        });
      }
    });
    fileInput.click();
  }

  uploadImageFiles() {
    if (this.lookbookstring.trim().length > 0) {
      this.selectedIndex = -1
      this.modalService.openvc1frame()
      this.subscription1 = this.yourappsService.retSelectedFiles$.subscribe((value: {
        data: SetSelectedFiles[],
        type: number
      }) => {
        if (this.subscription1) {
          this.subscription1.unsubscribe();
          if (value.data) {
            if (value.type === 1) {
              console.log("value.data", value.data)
              this.selectedImageFiles.push({
                file: value.data[0].file,
                url: value.data[0].url,
                filename: value.data[0].filename,
                sortby: 1
              });
              this.AIDisabled = this.selectedImageFiles.length === 0;
              this.yourappsService.returnSelectedFiles(null, 0)
            }
          }
        }
      })
    } else {
      setTimeout(() => {
        this.progressvalue = 0;
        this.showprogressbar = true;
        this.cdr.detectChanges(); // Принудительное обновление состояния
      }, 2000); // 2000 миллисекунд = 2 секунды
      let fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.multiple = true;
      fileInput.accept = 'image/*,.heic'; // Accept image files and HEIC
      fileInput.addEventListener('change', async (event) => {
        const files: FileList | null = (event.target as HTMLInputElement).files;
        if (files) {
          for (let file of Array.from(files)) {
            this.AIDisabled = true;
            this.progressvalue = 0;
            this.showprogressbar = true;

            let processedFile = file;
            let url: string;

            if (file.name.toLowerCase().endsWith('.heic')) {
              try {
                const jpegBlob = await heic2any({
                  blob: file,
                  toType: 'image/jpeg',
                  quality: 0.8
                });

                // Handle the case where heic2any returns an array of Blobs
                const convertedBlob = Array.isArray(jpegBlob) ? jpegBlob[0] : jpegBlob;

                processedFile = new File([convertedBlob], file.name.replace('.heic', '.jpg'), { type: 'image/jpeg' });
                url = URL.createObjectURL(convertedBlob);
              } catch (error) {
                console.error('Error converting HEIC to JPEG:', error);
                continue; // Skip this file if conversion fails
              }
            } else {
              url = await this.readFileAsDataURL(file);
            }

            console.log("value.data", "!");
            await this.onUploadTempImage(processedFile, processedFile.name);
            this.selectedImageFiles.push({
              file: processedFile,
              url: url,
              filename: processedFile.name,
              sortby: 1
            });
          }
          this.AIDisabled = this.selectedImageFiles.length === 0;
        }
      });
      fileInput.click();
    }
  }

  private readFileAsDataURL(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event: any) => resolve(event.target.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  }

  deleteFile(event: MouseEvent, index: number) {
    event.stopPropagation();
    this.selectedFiles.splice(index, 1);
  }

  deleteImageFile(event: MouseEvent, index: number) {
    this.selectedIndex = -1;
    event.stopPropagation();

    // Найдите элемент в selectedImageFiles по индексу
    const deletedItem = this.selectedImageFiles[index];

    if (deletedItem) {
      if (!deletedItem.file) {
        console.log(deletedItem)
        // Добавьте этот элемент в массив deletedImageFiles
        this.deletedImageFiles.push(deletedItem);
      }
    }
    // Удалите элемент из массива selectedImageFiles
    this.selectedImageFiles.splice(index, 1);

    // Обновите статус AIDisabled
    this.AIDisabled = this.selectedImageFiles.length === 0;
  }

  zoomedIndex: number | null = null;

  zoomImage(index: number, item: any) {
    // this.zoomedIndex = this.zoomedIndex === index ? null : index;
    // SelfData.imageUrl = this.selectedImageFiles[index].url;
    // this.modalService.createimagezoom();
    console.log(this.selectedImageFiles)
    if (item["file"]) {
      item["url"] = "http://108.178.189.70:6330/SampleImageOriginal/" + item["filename"]
      this.selecteditem = item
      this.selectedIndex = index;
      console.log("from comp")
    } else {
      console.log("from server")
      this.selecteditem = item
      this.selectedIndex = index;
    }
  }

  onDefaultImageClick(item: any) {
    console.log('Default image clicked:', item);
    for (let i = 0; i < this.selectedImageFiles.length; i++) {
      if (this.selectedImageFiles[i].filename !== item.filename) {
        this.selectedImageFiles[i].sortby = 1;
      } else {
        this.selectedImageFiles[i].sortby = 0;
      }
    }
    console.log('Updated selectedImageFiles:', this.selectedImageFiles);
    // handle additional logic if needed
  }

  clearAllfieldOnAdd() {
    this.swsstring = '';
    this.regionstring = '';
    this.notesstring = '';
    this.fabricstring = '';
    this.manustring = '';
    this.associatedstring = '';
    this.sizestring = '';
    this.sizerangestring = '';
    this.patternstring = '';
    this.colorstring = '';
    this.descriptionstring = '';
    this.quantitystring = '1'; // Default value as in your original code
    this.retailPriceEURstring = '0'; // Default value as in your original code
    this.retailPriceUSDstring = '0'; // Default value as in your original code
    this.wholesaleEURstring = '0'; // Default value as in your original code
    this.wholesaleUSDstring = '0'; // Default value as in your original code
    this.salePriceEURstring = '0'; // Default value as in your original code
    this.salePriceUSDstring = '0'; // Default value as in your original code
    this.stylestring = '';
    this.miscstring = '';
    this.typestring = ''; // This was initially using a `FormControl` with a required validator
    this.barcodestring = '';

    this.reloadTypeList(this.getAccountID());

    this.selectedFiles = [];
    this.selectedImageFiles = [];
  }

  clearallfield(): void {
    // Initialize the form group with default values for inventoryStatus and gender
    this.inventory = 'Inventory';
    this.genderstring = '';

    // Reset all string properties to their initial values
    this.swsstring = '';
    this.regionstring = '';
    this.notesstring = '';
    this.fabricstring = '';
    this.manustring = '';
    this.associatedstring = '';
    this.sizestring = '';
    this.sizerangestring = '';
    this.patternstring = '';
    this.colorstring = '';
    this.quantitystring = '1'; // Default to '1' as in your original code
    this.retailPriceEURstring = '0'; // Default to '0' as in your original code
    this.retailPriceUSDstring = '0'; // Default to '0' as in your original code
    this.wholesaleEURstring = '0'; // Default to '0' as in your original code
    this.wholesaleUSDstring = '0'; // Default to '0' as in your original code
    this.salePriceEURstring = '0'; // Default to '0' as in your original code
    this.salePriceUSDstring = '0'; // Default to '0' as in your original code
    this.stylestring = '';
    this.miscstring = '';
    this.designerstring = ''; // Reset designer string
    this.collectionstring = ''; // Reset collection string
    this.seasonstring = ''; // Reset season string
    this.typestring = ''; // Reset type string
    this.barcodestring = '';

    // Reset selected files and images
    this.selectedFiles = [];
    this.selectedImageFiles = [];
  }

  getValue(sampleid: number) {
    if (!this.fieldscount) {
      // fieldscount is undefined, do not proceed
      // console.error('fieldscount is undefined!');
      return;
    }

    let data = this.fieldscount;
    var accountid: number = 0;
    if (SelfData.account[0].data[0].parentid == 0) {
      accountid = SelfData.account[0].data[0].id;
    } else {
      accountid = SelfData.account[0].data[0].parentid;
    }
    for (let i = 0; i < data.length; i++) {
      const input = document.getElementById(
        data[i].fieldlabel
      ) as HTMLInputElement | null;
      if (input != null) {
        this.serviceservice
          .prccfv({
            framename: data[i].framename,
            controllername: data[i].fieldlabel,
            accountid: accountid,
            value: input.value,
            sampleid: sampleid,
          })
          .subscribe((manuvalue) => {
          });
      }
    }
  }

  onChange() {
    // Check if the value is empty or not
    // if (this.lookbookControl.value && this.lookbookControl.value.trim().length > 0) {
    //   this.AIDisabled = false
    //   console.log('The input is not empty:', this.patternControl.value);
    // } else {
    //   console.log('The input is empty.');
    //   this.AIDisabled = true
    //   this.selectedlookbook = []
    // }
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent): void {
    if (this.lookbookstring.trim().length > 0) {
      this.AIDisabled = false;
    } else {
      console.log('The input is empty.');
      this.AIDisabled = true;
      this.selectedlookbook = [];
    }
  }

  onUploadTempImage(file: File | null, filename: string) {
    if (file !== null && file !== undefined) {
      const uploadObservable = this.serviceservice.uploadTempImage(file, filename);

      uploadObservable.subscribe({
        next: (event) => {
          if (event.type === HttpEventType.UploadProgress) {
            // Check if the total byte count is available
            if (event.total) {
              const progress = Math.round(100 * event.loaded / event.total);
              this.progressvalue = progress
              console.log(`Upload is ${progress}% done`);
            }
          } else if (event.type === HttpEventType.Response) {
            console.log('Upload successful', event.body);
            this.showprogressbar = false
            this.AIDisabled = this.selectedImageFiles.length === 0;
          }
        },
        error: (error) => {
          console.error('Upload failed', error);
        },
      });
    } else {
      console.error('File is null or undefined');
    }
  }

  async deleteimagefiles(ctrl: number, accountid: number, sampleid: number) {
    for (const imageFile of this.deletedImageFiles) {
      console.log(imageFile.filename)
      this.serviceservice
        .delImageList({ctrl: ctrl, accountid: accountid, sampleid: sampleid, filename: imageFile.filename})
        .subscribe({
          next: (value: any) => {
            if (value && value.data && Array.isArray(value.data)) {

            } else {
              console.log(
                'The response is empty or not in the expected format.'
              );
            }
          },
          error: (error) => {
            console.error('Error occurred during the subscription:', error);
          },
        });
    }
  }

  async onUpload(ctrl: number, accountid: number, userid: number, sampleid: number, type: number) {
    SelfData.updatebuttonisenabled = true
    await this.deleteimagefiles(2, accountid, sampleid)
    if (type === 0) {
      this.selectedImageFiles.forEach((imageFile, index) => {
        if (imageFile.file !== null && imageFile.file !== undefined) {
          try {
            console.log("!!!!!!!!!!!!!!!!!!!!!",imageFile)
            const response = this.serviceservice.uploadSample(
              imageFile.file,
              accountid,
              userid,
              sampleid,
              ctrl,
              imageFile.filename,
              type,
              imageFile.url,
              index
            );
            console.log('Upload successful', response);
            // if (!this.neededreload) {
            //   this.yourappsService.getReloadSampleList();
            // }
            if (this.notneededNewID) {
              this.yourappsService.setNewID(sampleid);
            }
          } catch (error) {
            console.log('Upload failed', error);
          }
        } else {
          // console.log('File is null or undefined');
          let ctrl: number = 0
          let isduplicate: boolean = false
          if (SelfData.isduplicate) {
            ctrl = 0
            isduplicate = true
          } else {
            ctrl = 3
            isduplicate = false
          }
          console.log("imageFile.filename", imageFile, "imageFile.sortby", imageFile.sortby)
          // await this.deleteimagefiles(2, accountid, sampleid)
          this.serviceservice.uploadsortby(
            accountid,
            userid,
            sampleid,
            ctrl, // ctrl
            imageFile.filename,
            type,
            imageFile.url,
            index,
            isduplicate
          ).subscribe({
            next: (response) => {
              console.log('Upload sortby successful', response);
              // Handle the response after successful upload
            },
            error: (error) => {
              console.error('Error uploading data', error);
              // Handle the error if the upload fails
            },
            complete: () => {
              console.log('Upload sortby request completed');
              // Any finalization code after the request completes
            }
          });
          // const response = this.serviceservice.uploadsortby(
          //   accountid,
          //   userid,
          //   sampleid,
          //   2,
          //   imageFile.filename,
          //   type,
          //   "",
          //   imageFile.sortby
          // );
          // console.log('Upload sortby successful');
        }
      })
    } else if (type === 1) {
      for (const imageFile of this.selectedImageFiles) {
        try {
          console.log("@@@@@@@@@@@", imageFile)
          const response = this.serviceservice.uploadSample(
            imageFile.file,
            accountid,
            userid,
            sampleid,
            ctrl,
            imageFile.filename,
            type,
            imageFile.url,
            imageFile.sortby
          );
          console.log('Upload successful', response);
          // if (!this.neededreload) {
          //   this.yourappsService.getReloadSampleList();
          // }
          if (this.notneededNewID) {
            this.yourappsService.setNewID(sampleid);
          }
        } catch (error) {
          console.error('Upload failed', error);
        }
      }
    }
  }

  // onButtonCheckClick() {
  //   let description = this.colorControl.value + " " + this.patternControl.value + " " + this.fabricControl.value + " " + this.typeFormControl.value
  //   this.descriptionControl.setValue(description.trim().toUpperCase())
  // }

  onCheckboxChange(event: MatCheckboxChange) {
    console.log(event.checked); // This will log 'true' if checked and 'false' otherwise
  }

  onFocus(event: FocusEvent) {
    console.log('Input gained focus:', event);
    // Perform your logic here
  }

  // onBlur(event: FocusEvent, type: number) {
  //   let searchedDesigner: GetDesignerListData | undefined;
  //   if (type == 0) {
  //     if (this.desingerFormControl.value?.trim() != "") {
  //       for (let designerList of this.iDesignerResult) {
  //         if (Array.isArray(designerList.data)) {
  //           searchedDesigner = designerList.data.find(designer => designer.description === this.desingerFormControl.value);
  //           if (searchedDesigner) break;
  //         }
  //       }
  //
  //       if (searchedDesigner) {
  //
  //       } else {
  //         this.messageService.add({
  //           key: 'tc',
  //           severity: 'warn',
  //           summary: 'Information',
  //           detail:
  //             'Designer not found. \n' +
  //             'You need to add this designer from the collection.',
  //           life: 10000,
  //         });
  //       }
  //     }
  //   } else if (type == 1) {
  //     if (this.seasonFormControl.value?.trim() != "") {
  //       for (let designerList of this.iSeason) {
  //         if (Array.isArray(designerList.data)) {
  //           searchedDesigner = designerList.data.find(designer => designer.description === this.seasonFormControl.value);
  //           if (searchedDesigner) break;
  //         }
  //       }
  //
  //       if (searchedDesigner) {
  //
  //       } else {
  //         this.messageService.add({
  //           key: 'tc',
  //           severity: 'warn',
  //           summary: 'Information',
  //           detail:
  //             'Season not found. \n' +
  //             'You need to add the season from the collection.',
  //           life: 10000,
  //         });
  //       }
  //     }
  //   } else  if (type === 2) {
  //     if (this.typeFormControl.value?.trim() != "") {
  //       for (let designerList of this.iType) {
  //         if (Array.isArray(designerList.data)) {
  //           searchedDesigner = designerList.data.find(designer => designer.description === this.typeFormControl.value);
  //           if (searchedDesigner) break;
  //         }
  //       }
  //
  //       if (searchedDesigner) {
  //
  //       } else {
  //         this.messageService.add({
  //           key: 'tc',
  //           severity: 'warn',
  //           summary: 'Information',
  //           detail:
  //             'Type not found. \n' +
  //             'You need to add type.',
  //           life: 10000,
  //         });
  //       }
  //     }
  //   }
  // }

  onInput(event: any) {
    this.formattedText = event.target.innerHTML;
    this.descriptionstring = this.stripHtml(this.formattedText).toUpperCase();
  }

  private stripHtml(html: string): string {
    const tempDivElement = document.createElement('div');
    tempDivElement.innerHTML = html;
    return tempDivElement.textContent || tempDivElement.innerText || '';
  }

  onButtonAIClick() {
    if (this.selectedIndex < 0) {
      this.messageService.add({
        key: 'tc',
        severity: 'success',
        summary: 'Information',
        detail: 'You have not selected a picture',
        life: 5000,
      });
      return
    }
    // this.modalService.openAIChoos()
    this.subscription3 = this.yourappsService.setAIDescription$.subscribe((value: {
      data: any[] | any,
      description: any[] | any,
      type: number
    }) => {
      const replaceBold = (msg: string): string => {
        return msg.replace(/\*\*(.*?)\*\*/g, (match, p1) => `<strong>${p1}</strong>`);
      };

      if (Array.isArray(value.description)) {
        const formattedDescriptions = value.description
          .map(descObj => `**${descObj.type}**\n${descObj.description}`)
          .join('\n\n');

        this.formattedText = replaceBold(this.stripHtml(formattedDescriptions));
        // const htmlDescriptions = replaceBold(this.stripHtml(formattedDescriptions));
        this.descriptionstring = this.stripHtml(this.formattedText).toUpperCase();
      }
    });

    this.yourappsService.setSelectedImages(this.selecteditem, 1)
    this.modalService.openAI()
  }

  optionSelected(event: MatAutocompleteSelectedEvent) {
    // event.option.value will contain the selected option
    // here you can handle the event when a new option is selected or typed
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.selectedImageFiles, event.previousIndex, event.currentIndex);
  }

  getColorOptionLabel(option: any): string {
    const sanitizedHtml: SafeHtml = this.sanitizer.bypassSecurityTrustHtml(
      `<span class="color-box" style="background: ${option.colorcode};"></span> ${option.colorname}`
    );
    return sanitizedHtml.toString();
  }

  protected readonly SelfData = SelfData;
  AIDisabled: boolean = false;
  progressvalue: number = 0;
  color: ThemePalette = 'accent';
  mode: ProgressSpinnerMode = 'determinate';
  showprogressbar: boolean = false
  statusinventory: '' | 'error' | 'warning' = '';
  statusdesigner: '' | 'error' | 'warning' = '';
  statuscollection: '' | 'error' | 'warning' = '';
  statusseason: '' | 'error' | 'warning' = '';
  statustype: '' | 'error' | 'warning' = '';


  inventory: string = ""
  genderstring: string = ""
  swsstring: string = ""
  departmentstring: string = ""
  regionstring: string = ""
  notesstring: string = ""
  barcodestring: string = ""
  designerstring: string = ""
  fabricstring: string = ""
  manustring: string = ""
  collectionstring: string = ""
  stylestring: string = ""
  associatedstring: string = ""
  seasonstring: string = ""
  miscstring: string = ""
  quantitystring: string = "1"
  typestring: string = ""
  sizestring: string = ""
  sizerangestring: string = ""
  patternstring: string = ""
  colorstring: string = ""
  lookbookstring: string = ""
  retailPriceEURstring: string = "0"
  retailPriceUSDstring: string = "0"
  wholesaleEURstring: string = "0"
  wholesaleUSDstring: string = "0"
  salePriceEURstring: string = "0"
  salePriceUSDstring: string = "0"
  descriptionstring: string = ""

  onBlurAll() {

  }

  selectedsample: SetSampleListData[] = [];
  iSendOutList: GetScannedList[] = [];

  iSendOutList1: GetSendOutList[] = [];

  onDeliveriClick(element: any) {
    SelfData.selectadpage = 3
    SelfData.sendoutopentype = 1
    SelfData.sendoutstep = 2;
    SelfData.saveonclose = false
    SelfData.sendoutid = element?.["sendoutid"]
    this.serviceservice
      .selectsendoutsamples({
        sendoutid: element.sendoutid,
      })
      .subscribe((value) => {
        this.serviceservice
          .getCurSendOut({sendoutid: element.sendoutid})
          .subscribe((value) => {
            this.iSendOutList1[0] = value;
            SelfData.sendoutlist = this.iSendOutList1;
            console.log(">>>>>>",SelfData.sendoutlist)
            SendOut1Data.senderFormControl = SelfData.sendoutlist[0].data[0].sender
            console.log("SendOut1Data.senderFormControl", SendOut1Data.senderFormControl)
            this.yourappsService.selectedSendOut(SelfData.sendoutlist);
            SelfData.selectadpage1 = 0
            this.modalService.openPrinterFrame()

            if (SelfData.scannedlist[0].data.length > 0) {
              this.selectedsample = []
              for (const item of SelfData.scannedlist[0].data) {
                const newSample: SetSampleListData = {
                  "ID": item["ID"],
                  "Inventory Status": item["Inventory Status"],
                  "Gender": item["Gender"],
                  "Sample Workflow Status": item["Sample Workflow Status"],
                  "Account ID": item["accountid"],
                  "Region": item["Region"],
                  "Notes": item["Notes"],
                  "Designer": item["Designer"],
                  "Fabric": item["Fabric"],
                  "Manufacturer": item["Manufacturer"],
                  "Collection": item["Collection"],
                  "Style": item["Style"],
                  "Looks Associated": item["Looks Associated"],
                  "Season": item["Season"],
                  "Misc": item["Misc"],
                  "Quantity": item["Quantity"],
                  "Type": item["Type"],
                  "Size": item["Size"],
                  "Size Range": item["Size Range"],
                  "Pattern": item["Pattern"],
                  "Color": item["Color"],
                  "Color Code": item["colorcode"],
                  "Retail Price EUR": item["Retail Price EUR"],
                  "Retail Price USD": item["Retail Price USD"],
                  "Wholesale EUR": item["Wholesale EUR"],
                  "Wholesale USD": item["Wholesale USD"],
                  "Sale Price EUR": item["Sale Price EUR"],
                  "Sale Price USD": item["Sale Price USD"],
                  "Prices ID": item["pricesid"],
                  "Description": item["Description"],
                  "Storage ID": item["storageid"],
                  "Registration Date": item["regdate"],
                  "Current Date": item["curdate"],
                  "User ID": item["userid"],
                  "Is Active": item["isactiviti"],
                  "Image Link": item["Image Link"],
                  "barcode": item["barcode"],
                  "User": item["user"],
                  "Barcode Prefix": item["barcodeprefix"],
                  "Selected Sample ID": item["selectedsampleid"],
                  "itemcount": item["itemcount"] || 1
                };
                this.selectedsample.push(newSample);

              }
              // ... any other operations you need to perform on items
              // }
              SelfData.setSelectedSample = this.selectedsample
            }
          });
        this.iSendOutList[0] = value;
        SelfData.scannedlist = this.iSendOutList;
      });
  }

  onInputFilter(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;
    this.dt1.filterGlobal(value, 'contains');
  }

  clear(table: Table) {
    table.clear();
    this.filtertext = '';
  }

  protected readonly transformImageUrl = transformImageUrl;

  onInfoButtonClick() {
    window.open('https://fm.welcomeapps.net/FAQ/CreateSample.html', '_blank');
  }

  showAddEditFrame(number: number, item: any) {

  }
  @ViewChild('op') overlayPanel!: OverlayPanel;
  closeOverlayPanel() {
    const fields = [
      { name: 'selectCheckDesigner', value: this.selectCheckDesigner },
      { name: 'selectCheckInventoryStatus', value: this.selectCheckInventoryStatus },
      { name: 'selectCheckGender', value: this.selectCheckGender },
      { name: 'selectCheckSampleWorkflowStatus', value: this.selectCheckSampleWorkflowStatus },
      { name: 'selectCheckRegion', value: this.selectCheckRegion },
      { name: 'selectCheckNotes', value: this.selectCheckNotes },
      { name: 'selectCheckFabric', value: this.selectCheckFabric },
      { name: 'selectCheckManufacturer', value: this.selectCheckManufacturer },
      { name: 'selectCheckCollection', value: this.selectCheckCollection },
      { name: 'selectCheckStyle', value: this.selectCheckStyle },
      { name: 'selectCheckLooksAssociated', value: this.selectCheckLooksAssociated },
      { name: 'selectCheckSeason', value: this.selectCheckSeason },
      { name: 'selectCheckMisc', value: this.selectCheckMisc },
      { name: 'selectCheckType', value: this.selectCheckType },
      { name: 'selectCheckSize', value: this.selectCheckSize },
      { name: 'selectCheckSizeRange', value: this.selectCheckSizeRange },
      { name: 'selectCheckPattern', value: this.selectCheckPattern },
      { name: 'selectCheckQuantity', value: this.selectCheckQuantity },
      { name: 'selectCheckColor', value: this.selectCheckColor },
      { name: 'selectCheckBarcode', value: this.selectCheckBarcode },
      { name: 'selectCheckDepartment', value: this.selectCheckDepartment },
      { name: 'selectCheckLookBook', value: this.selectCheckLookBook },
    ];

    fields.forEach((field) => {
      const fieldData = {
        isshow: field.value,
        formaname: "createsamplesdialog", // Укажите актуальное имя формы
        componentname: field.name, // Имя компонента
        userid: SelfData.account[0].data[0].id, // Идентификатор пользователя
        accountid: this.getAccountID(), // Идентификатор аккаунта
      };

      this.serviceservice.prcComponentFields(fieldData).subscribe(
        (response) => {
          console.log('Field updated:', fieldData.componentname, response);
        },
        (error) => {
          console.error('Error updating field:', fieldData.componentname, error);
        }
      );
    });
    this.overlayPanel.hide();
  }
}
