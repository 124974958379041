<p-confirmDialog *ngIf="showdialog" #cd [style]="{width: '25vw'}" [closable]="true">
  <!--  <ng-template pTemplate="header">-->
  <!--    <h3 class="red-text">This item is on loan!</h3>-->
  <!--  </ng-template>-->
  <ng-template pTemplate="footer">
    <!--        <button type="button" pButton label="No" (click)="cd.reject()"></button>-->
    <div class="button-container-request">
      <button mat-raised-button class="button-secondary" style="margin-right: 10px; width: 50%"
        (click)="cd.reject()">
        No
      </button>
      <button mat-raised-button class="button-primary" style="margin-right: 0; width: 50%" (click)="cd.accept()">
        Yes
      </button>
    </div>
    <!--        <button type="button" pButton label="Yes" (click)="cd.accept()"></button>-->
  </ng-template>
</p-confirmDialog>
<div class="scrollable-div-step2">

  <div class="black-background-step2">

    <div class="child-40 full-width-caption">
      <p class="caption-text">Add Samples</p>

      <div class="subdiv">

        <div class="example-full-width">
          <label class="line1-edits-new inputlabel">Input Barcode</label>
          <nz-input-group nzSearch [nzAddOnAfter]="associatedbutton">
            <input (keydown.enter)="onButtonScanClick()" class="line1-edits-new inputedit testdiv1" type="text" [(ngModel)]="scannerstring" nz-input/>
          </nz-input-group>
          <ng-template #associatedbutton>
            <button type="button" (click)="onButtonScanClick()" nz-button nzType="default" nzSearch>Scann</button>
          </ng-template>
        </div>

<!--        <mat-form-field class="form-field-spacing-step2" appearance="fill">-->
<!--          <mat-label>Input Barcode</mat-label>-->

<!--          <input type="text" matInput (keydown.enter)="onButtonScanClick()" [formControl]="scannerControl">-->

<!--          <button mat-stroked-button type="button" class="add-button-step2" matSuffix-->
<!--            (click)="onButtonScanClick()">-->
<!--            Scan-->
<!--          </button>-->

<!--        </mat-form-field>-->

        <div class="full-width-div-contact">

          <button mat-raised-button type="button" class="add-button-step21" matSuffix
            (click)="onButtonInventaryClick()">
            Add from Inventory
          </button>

          <button mat-raised-button type="button" class="add-button-step21" matSuffix
            (click)="onButtonLookClick()">
            Add by Look
          </button>

        </div>

        <div class="full-width-button">

          <button mat-raised-button type="button" class="add-button-step21" matSuffix
            (click)="onButtonCreateSampleClick()">
            Create & Add Sample
          </button>

          <button mat-raised-button type="button" class="add-button-step21" matSuffix [matBadgeHidden]="hidden" [matBadge]="SelfData.scannedfulllist[0].data?.length"
            (click)="onScannClick()">
            Add from scanner
          </button>

        </div>

        <div class="full-width-button">

          <button mat-raised-button type="button" class="add-button-step21" matSuffix
            (click)="onButtonSearch1Click()">
            Add Queued Samples
          </button>

          <button mat-raised-button type="button" class="another-button" matSuffix
            (click)="onButtonSearch2Click()">
            <mat-icon class="center-icon-step2">search</mat-icon>
          </button>

        </div>
        <!--                *ngIf="SelfData.sendoutmode === 0"-->
        <div class="full-width-button">

          <button mat-raised-button type="button" class="add-button-step21" matSuffix (click)="onButtonLookBookClick()">
            Add Selected from Look Book
          </button>

        </div>

      </div>
    </div>

    <div class="child-60 full-width-caption">
      <p class="caption-text">Logs</p>

      <div class="subdiv">

        <div class="form-field-spacing-contact form-field-h"
          style="overflow-y: auto; border: 1px solid #ccc; padding: 5px; resize: none;">
          <p *ngFor="let msg of messages" [style.color]="msg.color" [innerHTML]="msg.text"></p>
        </div>

      </div>
    </div>

  </div>

  <div class="black-background-step2-table">
    <button *ngIf="neededreturn" mat-raised-button type="button" class="add-button-step211" matSuffix (click)="onButtonReturnAllCLick()">
      Return All Samples
    </button>
    <div class="full-width-caption">

      <p class="caption-text">
        Added Samples ({{ selectedsample.length }} samples)
      </p>

      <div class="card">
        <p-table
          [value]="selectedsample"
          [paginator]="true"
          [rows]="10"
          [showCurrentPageReport]="true"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          [rowsPerPageOptions]="[10, 20, 50, 100]"
          [styleClass]="'p-datatable-striped mat-elevation-z3 ' + selectedSize.class.toString()"
          selectionMode="single"
          [tableStyle]="{'min-width': '50rem'}">
          <ng-template pTemplate="header">
            <tr>
              <!--                    -->
              <th style="width:4%">Options</th>
              <th style="width:17.6%">Designer<br>Collection<br>Season</th>
              <th style="width:17.6%">Type<br>Barcode<br>Color</th>
              <th style="width:17.6%">Gender<br>Region<br>Size</th>
              <th style="width:17.6%">Description</th>
              <th style="width:8%">Image</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-product>
            <tr [ngStyle]="{'background-color':
                  (product['requested count'] > 1) ? 'rgba(255,217,171,0.2)' :
                  (product['requested count'] < 0 || (product['isok'] === 0 || product['itemstatus'] === 0)) ? '#ffdddd' :
                  ''}"
              [pSelectableRow]="product">
              <td>
<!--                <p-splitButton (click)="currentSample = product"-->
<!--                  label="select" [matMenuTriggerFor]="menu"-->
<!--                  styleClass="p-button-outlined p-button-secondary mr-2 mb-2"></p-splitButton>-->
                <button type="button" nzTrigger="click" nz-button nz-dropdown [nzDropdownMenu]="menu4" (click)="currentSample = product">
                  select
                  <span nz-icon nzType="down"></span>
                </button>
              </td>
              <td>{{ product["Designer"] }}<br>{{ product["Collection"] }}<br>{{ product["Season"] }}</td>
              <td>{{ product["Type"] }}<br>{{ product["barcode"] }}<br>{{ product["Color"] }}</td>
              <td>{{ product["Gender"] }}<br>
                {{ product["Region"] }}<br>
                {{ product["Size"] }}
              </td>
              <td>{{ product["Description"] }}</td>
              <td><img [src]="transformImageUrl(product['Image Link'])" alt="Image" class="image-size-80x80 shadow-4"></td>
<!--              <td><img [src]="product['Image Link']" alt="Image" class="image-size-80x80 shadow-4"></td>-->
            </tr>
          </ng-template>
        </p-table>
        <div class="fullline">

        </div>
        <div *ngIf="SelfData.fullPage" class="nextpage">
          <app-sendoutstep1></app-sendoutstep1>
        </div>
      </div>
    </div>
  </div>
  <!--  <div class="nextpage">-->
  <!--    <app-sendoutstep1></app-sendoutstep1>-->
  <!--  </div>-->
</div>

<nz-dropdown-menu #menu4="nzDropdownMenu">
  <ul nz-menu>
    <li nz-menu-item *ngIf="currentSample?.['isok'] == 0" (click)="onButtonReturnScanClick(currentSample)">Return & Scan</li>
    <li nz-menu-item (click)="onViewButtonClick(currentSample)">View Details</li>
    <li nz-menu-item (click)="onButtonDesignerClick()">Choose more samples from this designer.</li>
    <li nz-menu-item *ngIf="SelfData.sendoutmode === 0" (click)="onPrintBarcodeClick(currentSample?.['barcode'])">Print Barcode</li>
    <li nz-menu-item (click)="removeSelectedSample(currentSample?.['barcode'])">Remove Sample</li>
<!--    <li nz-menu-item *ngIf="currentSample?.['itemstatus'] == 0" (click)="restoreSelectedSample(currentSample?.['barcode'])">Restore Sample</li>-->
    <li nz-menu-item *ngIf="currentSample?.['requested count'] > 1" (click)="onSameRequestClick(currentSample)">Same Request</li>
  </ul>
</nz-dropdown-menu>

