  <button mat-icon-button type="button" class="close-btn"  (click)="modalService.closeEditor()">
    <mat-icon>close</mat-icon>
  </button>
  <p class="sample-manager-editor">Editor</p>
  <div class="card">
    <p-editor [(ngModel)]="text" [style]="{ height: '350px' }">
      <ng-template pTemplate="header">
        <span class="ql-formats">
            <button type="button" class="ql-bold" aria-label="Bold"></button>
            <button type="button" class="ql-italic" aria-label="Italic"></button>
            <button type="button" class="ql-underline" aria-label="Underline"></button>
        </span>
      </ng-template>
    </p-editor>
  </div>
  <div class="button-container-label">
    <button mat-raised-button class="button-settings" type="button" (click)="submit()">
      OK
    </button>
  </div>

