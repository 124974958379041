<div class="black-background1-ll">
  <div class="child-40-ll full-width-caption-ll">
    <p class="caption-text-ll">Look List ({{SelfData.lookname[0].data.length}})</p>
    <div class="subdiv-ll">

      <div class="card">
        <p-table [value]="SelfData.lookname[0].data"
                 [paginator]="true"
                 [rows]="5"
                 [style]="{'border-radius': '10px'}"
                 [showCurrentPageReport]="true"
                 currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                 [rowsPerPageOptions]="[5, 10, 25, 50, 100]"
                 [styleClass]="'p-datatable-striped mat-elevation-z3 borders ' + selectedSize.class.toString()"
                 (onRowSelect)="onItemClick($event)"
                 (onRowUnselect)="onItemClick($event)"
                 selectionMode="single">
          <ng-template pTemplate="header">
            <tr>
              <th style="width:4%">Options</th>
              <th style="width:96%">Look Name</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-product>
            <!-- Here's the corrected line: -->
            <tr [pSelectableRow]="product">
              <td>
                <div class="button-container-table">

                  <p-button *ngIf="SelfData.selectlookmode == 0"
                            label="Select" size="small" [outlined]="true" severity="secondary"
                            [matMenuTriggerFor]="menu" (click)="currentSample = product">
                  </p-button>


                  <p-button *ngIf="SelfData.selectlookmode != 0"
                            label="Select" size="small" [outlined]="true" severity="secondary"
                            (click)="onButtonSelectLookClick(product)">
                  </p-button>

                </div>
              </td>
              <td>{{product["description"]}}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>

<!--      <section class="example-container" tabindex="0">-->
<!--        <table mat-table [dataSource]="setdataSource">-->
<!--          <ng-container matColumnDef="sc">-->
<!--            <th class="field2 mat-header-row" mat-header-cell *matHeaderCellDef></th>-->
<!--            <td mat-cell *matCellDef="let element" class="icon-buttons">-->
<!--              <div class="button-container-table">-->
<!--                <button *ngIf="SelfData.selectlookmode == 0" mat-stroked-button [matMenuTriggerFor]="menu" (click)="currentSample = element">-->
<!--                  Select-->
<!--                </button>-->

<!--                <button *ngIf="SelfData.selectlookmode != 0" mat-stroked-button (click)="onButtonSelectLookClick(element)">-->
<!--                  Select-->
<!--                </button>-->
<!--              </div>-->
<!--            </td>-->
<!--          </ng-container>-->
<!--          <ng-container matColumnDef="lookname">-->
<!--            <th class="field1 mat-header-row" mat-header-cell *matHeaderCellDef>-->
<!--              Look Name-->
<!--            </th>-->
<!--            <td mat-cell *matCellDef="let element" (click)="onItemClick(element)">-->
<!--              {{ element?.["description"] }}-->
<!--            </td>-->
<!--          </ng-container>-->

<!--          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>-->
<!--          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>-->
<!--        </table>-->
<!--        <mat-paginator-->
<!--          class="sticky-paginator"-->
<!--          #paginator-->
<!--          [pageSizeOptions]="[5, 10, 20, 50, 100]"-->
<!--          aria-label="Select page of periodic elements"-->
<!--        >-->
<!--        </mat-paginator>-->
<!--      </section>-->

    </div>
  </div>

  <div class="child-60-ll full-width-caption-ll">
    <p class="caption-text-ll">Samples List ({{SelfData.looks && SelfData.looks[0] ? SelfData.looks[0].data.length : 0}}) - [{{loolname}}]</p>
    <div class="subdiv-ll">

      <div class="card">
        <p-table
                [value]="firstLookData"
                [paginator]="true"
                [rows]="5"
                [style]="{'border-radius': '10px'}"
                [showCurrentPageReport]="true"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [rowsPerPageOptions]="[5, 10, 25, 50, 100]"
                [styleClass]="'p-datatable-striped mat-elevation-z3 borders ' + selectedSize.class.toString()"
                selectionMode="single"
                [tableStyle]="{'min-width': '50rem'}">
          <ng-template pTemplate="header">
            <tr>
              <th style="width:17.6%">Designer<br/>Collection<br/>Season</th>
              <th style="width:17.6%">Type<br/>Color<br/></th>
              <th style="width:12.6%">Barcode</th>
              <th style="width:22.6%">Image</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-product>
            <tr [pSelectableRow]="product">
              <td>{{product["Designer"]}}<br>{{ product?.["Collection"] }}<br>{{product["Season"]}}</td>
              <td>{{product["Type"]}}<br>{{ product?.["Color"] }}<br></td>
              <td><app-barcode [element]="product?.barcode" [background]="'rgba(255,255,255,0)'" class="barcode-component"></app-barcode></td>
              <td><img [src]="transformImageUrl(product['Image Link'])" alt="Image" class="image-size-80x80 shadow-4"></td>
            </tr>
          </ng-template>
        </p-table>
      </div>

<!--      <section class="example-container" tabindex="0">-->
<!--        <table mat-table [dataSource]="dataSourceList">-->

<!--          <ng-container matColumnDef="Designer">-->
<!--            <th class="field1 mat-header-row" mat-header-cell *matHeaderCellDef>-->
<!--              Designer<br/>Collection<br/>Season-->
<!--            </th>-->
<!--            <td mat-cell *matCellDef="let element">-->
<!--              <span>-->
<!--                {{ element?.["Designer"] }}<br/>-->
<!--                {{ element?.["Collection"] }}<br/>-->
<!--                {{ element?.["Season"] }}-->
<!--              </span>-->
<!--            </td>-->
<!--          </ng-container>-->

<!--          <ng-container matColumnDef="Type">-->
<!--            <th class="typefield mat-header-row" mat-header-cell *matHeaderCellDef>-->
<!--              Type <br/>Color <br/>-->
<!--            </th>-->
<!--            <td mat-cell *matCellDef="let element">-->
<!--              <span>-->
<!--                {{ element?.["Type"] }}<br/>-->
<!--&lt;!&ndash;                {{ element?.["barcode"] }}<br/>&ndash;&gt;-->
<!--                {{ element?.["Color"] }}-->
<!--              </span>-->
<!--            </td>-->
<!--          </ng-container>-->

<!--          <ng-container matColumnDef="Description">-->
<!--            <th class="descriptionfield mat-header-row" mat-header-cell *matHeaderCellDef>-->
<!--              Barcode-->
<!--            </th>-->
<!--            <td mat-cell *matCellDef="let element">-->
<!--              <app-barcode [element]="element?.barcode" class="barcode-component"></app-barcode>-->
<!--            </td>-->
<!--          </ng-container>-->

<!--          <ng-container matColumnDef="Image">-->
<!--            <th class="imagefield mat-header-row" mat-header-cell *matHeaderCellDef>-->
<!--              Image-->
<!--            </th>-->
<!--            <td mat-cell *matCellDef="let element" class="icon-buttons">-->
<!--              <img [src]="element?.['Image Link']" alt="Image" class="image-size-80x80"/>-->
<!--            </td>-->
<!--          </ng-container>-->

<!--          <tr mat-header-row *matHeaderRowDef="displayedLookColumns; sticky: true"></tr>-->
<!--          <tr mat-row *matRowDef="let row; columns: displayedLookColumns"></tr>-->
<!--        </table>-->
<!--        <mat-paginator #paginator1 [pageSizeOptions]="[5, 10, 25, 50, 100]" aria-label="Select page of periodic elements"></mat-paginator>-->
<!--      </section>-->
    </div>
  </div>
</div>

<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="onButtonEditClick(currentSample)">
    <mat-icon>edit_note</mat-icon>
    <span>Edit Details</span>
  </button>

  <button mat-menu-item>
    <mat-icon>delete</mat-icon>
    <span>Delete</span>
  </button>
</mat-menu>
