import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import * as moment from 'moment-timezone';
import {tr} from "date-fns/locale";
import {tz} from "moment-timezone";
import {HttpClient} from "@angular/common/http";
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder} from "@angular/forms";
import {HashService} from "../../services/hash.service";
import {MessageboxComponent} from "../../modals/messagebox/messagebox.component";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {PrcSaleGeneralInput, SelfData, settaxefields, settaxefieldsData, TagsListData} from "../../models/struct";
import {forkJoin} from "rxjs";
import {MessageService} from "primeng/api";
import {NzNotificationService} from "ng-zorro-antd/notification";

interface Currency {
  value: string;
  symbol: string;
}

interface TimeZone {
  viewvalue: string;
  value: string;
}

@Component({
  selector: 'app-setupgeneral',
  templateUrl: './setupgeneral.component.html',
  styleUrl: './setupgeneral.component.scss'
})
export class SetupgeneralComponent implements OnInit {
  currency: Currency[] = [
    {value: 'US Dollar', symbol: '$'},
    {value: 'Euro', symbol: '€'},
    {value: 'British Pound', symbol: '£'},
    {value: 'Japanese Yen', symbol: '¥'},
    {value: 'Swiss Franc', symbol: 'CHF'},
    {value: 'Canadian Dollar', symbol: '$'},
    {value: 'Australian Dollar', symbol: '$'},
    {value: 'New Zealand Dollar', symbol: '$'},
    {value: 'Chinese Yuan', symbol: '¥'},
    {value: 'Hong Kong Dollar', symbol: '$'},
    {value: 'Singapore Dollar', symbol: '$'},
    {value: 'South Korean Won', symbol: '₩'},
    {value: 'Indian Rupee', symbol: '₹'},
    {value: 'Brazilian Real', symbol: 'R$'},
    {value: 'Mexican Peso', symbol: '$'},
    {value: 'Russian Ruble', symbol: '₽'},
    {value: 'South African Rand', symbol: 'R'},
    {value: 'Turkish Lira', symbol: '₺'},
    {value: 'UAE Dirham', symbol: ''},
    {value: 'Saudi Riyal', symbol: ''},
    {value: 'Thai Baht', symbol: '฿'},
    {value: 'Malaysian Ringgit', symbol: 'RM'},
    {value: 'Philippine Peso', symbol: '₱'},
    {value: 'Indonesian Rupiah', symbol: 'Rp'},
    {value: 'Vietnamese Dong', symbol: '₫'},
    {value: 'Israeli Shekel', symbol: '₪'},
    {value: 'Egyptian Pound', symbol: 'E£'},
    {value: 'Chilean Peso', symbol: '$'},
    {value: 'Colombian Peso', symbol: '$'},
    {value: 'Argentine Peso', symbol: '$'},
    {value: 'Peruvian Sol', symbol: 'S/'},
    {value: 'Ukrainian Hryvnia', symbol: '₴'},
    {value: 'Kazakhstani Tenge', symbol: '₸'},
    {value: 'Pakistani Rupee', symbol: '₨'},
    {value: 'Bangladeshi Taka', symbol: '৳'},
    {value: 'Nigerian Naira', symbol: '₦'},
    {value: 'Ghanaian Cedi', symbol: '₵'},
    {value: 'Kenyan Shilling', symbol: ''},
    {value: 'Tanzanian Shilling', symbol: ''},
    {value: 'Ugandan Shilling', symbol: ''},
    {value: 'Moroccan Dirham', symbol: ''},
    {value: 'Tunisian Dinar', symbol: ''},
    {value: 'Algerian Dinar', symbol: ''},
    {value: 'Georgian Lari', symbol: '₾'},
    {value: 'Armenian Dram', symbol: '֏'},
    {value: 'Azerbaijani Manat', symbol: '₼'},
    {value: 'Belarusian Ruble', symbol: 'Br'},
    {value: 'Mongolian Tugrik', symbol: '₮'},
    {value: 'Nepalese Rupee', symbol: ''},
    {value: 'Sri Lankan Rupee', symbol: ''},
    {value: 'Paraguayan Guarani', symbol: '₲'},
    {value: 'Bolivian Boliviano', symbol: 'Bs'},
    {value: 'Venezuelan Bolívar', symbol: ''},
    {value: 'Dominican Peso', symbol: 'RD$'},
    {value: 'Costa Rican Colón', symbol: '₡'},
    {value: 'Honduran Lempira', symbol: 'L'},
    {value: 'Nicaraguan Córdoba', symbol: 'C$'},
    {value: 'Guatemalan Quetzal', symbol: 'Q'},
    {value: 'Haitian Gourde', symbol: 'G'},
    {value: 'Jamaican Dollar', symbol: 'J$'},
    {value: 'Cuban Peso', symbol: ''},
    {value: 'Bahamian Dollar', symbol: '$'},
    {value: 'Barbadian Dollar', symbol: '$'},
    {value: 'Trinidad and Tobago Dollar', symbol: 'TT$'},
    {value: 'Fijian Dollar', symbol: '$'},
    {value: 'Samoan Tala', symbol: ''},
    {value: 'Papua New Guinean Kina', symbol: ''},
    {value: 'Solomon Islands Dollar', symbol: ''},
    {value: 'Vanuatu Vatu', symbol: ''},
    {value: 'CFA Franc (West Africa)', symbol: ''},
    {value: 'CFA Franc (Central Africa)', symbol: ''},
    {value: 'CFP Franc', symbol: ''},
    {value: 'Malagasy Ariary', symbol: 'Ar'},
    {value: 'Mauritian Rupee', symbol: ''},
    {value: 'Seychellois Rupee', symbol: ''},
    {value: 'Brunei Dollar', symbol: '$'},
    {value: 'Macanese Pataca', symbol: ''},
    {value: 'Burmese Kyat', symbol: ''},
    {value: 'Lao Kip', symbol: '₭'},
    {value: 'Cambodian Riel', symbol: '៛'},
    {value: 'Bhutanese Ngultrum', symbol: ''},
    {value: 'Sudanese Pound', symbol: ''},
    {value: 'Syrian Pound', symbol: ''},
    {value: 'Lebanese Pound', symbol: ''},
    {value: 'Iranian Rial', symbol: '﷼'},
    {value: 'Iraqi Dinar', symbol: ''},
    {value: 'Afghan Afghani', symbol: '؋'},
    {value: 'Yemeni Rial', symbol: '﷼'},
    {value: 'Omani Rial', symbol: ''},
    {value: 'Qatari Riyal', symbol: ''},
    {value: 'Kuwaiti Dinar', symbol: ''},
    {value: 'Bahraini Dinar', symbol: ''},
    {value: 'Libyan Dinar', symbol: ''},
    {value: 'North Korean Won', symbol: '₩'}
  ];

  timezone: TimeZone[] = [];
  iTaxResult: settaxefieldsData[] = []
  countries: any[] = [];
  states: any[] = [];
  pcountries: any[] = [];
  pstates: any[] = [];
  storagename: string = "";
  defoultcurrency: string = "";
  defaulttimezone: string = "";
  defaultsalestax: string = "";
  taxexclusive: boolean = true;
  imregisteredforvat: boolean = true;
  generateskusbynumber: boolean = true;
  vatnumber: string = "0";
  currentsequencenumber: string = "0";
  userauthvalue: string = "1";
  automaticallyaddcustomers: boolean = true;
  firstname: string = "";
  lastname: string = "";
  email: string = "";
  phone: string = "";
  website: string = "";
  instagram: string = "";
  facebook: string = "";
  tiktok: string = "";
  twitter: string = "";
  youtube: string = "";
  statesstring: string = "";
  countrystring: string = "";
  address: string = "";
  address2: string = "";
  suburb: string = "";
  city: string = "";
  zipcode: string = "";
  usedifferent: boolean = false;
  pstatesstring: string = "";
  pcountrystring: string = "";
  paddress: string = "";
  paddress2: string = "";
  psuburb: string = "";
  pcity: string = "";
  pzipcode: string = "";
  pusedifferent: boolean = false;
  storagedisabled: boolean = false;


  constructor(public modalService: ModalserviceService,
              public fb: FormBuilder,
              private hashService: HashService,
              public messageBox: MessageboxComponent,
              private messageService: MessageService,
              private serviceservice: ServiceService,
              private yourappsService: YourappsserviceService,
              private _snackBar: MatSnackBar,
              private notification: NzNotificationService,
              private http: HttpClient,
              private cdr: ChangeDetectorRef) {
    const timezoneNames = moment.tz.names();

    const accountId = SelfData.account[0].data[0].id;

    forkJoin({

      taxes: this.serviceservice.getTaxes({accountid: accountId}),
    }).subscribe(({ taxes }) => {
      this.iTaxResult = taxes.data;
    });

    this.timezone = timezoneNames.map((tz) => {
      const offset = moment.tz(tz).utcOffset(); // Смещение в минутах
      const hours = Math.floor(Math.abs(offset) / 60).toString().padStart(2, '0');
      const minutes = Math.abs(offset) % 60 === 0 ? '00' : (Math.abs(offset) % 60).toString().padStart(2, '0');
      const formattedOffset = `${offset >= 0 ? '+' : '-'}${hours}:${minutes}`;
      return { viewvalue: `(GMT${formattedOffset}) ${tz}`, value: formattedOffset };
    });

    if (SelfData.generalsetup[0].data.length > 0) {
      const generalData = SelfData.generalsetup[0].data[0];
      this.storagename = generalData["storename"]?.trim() || "";
      this.defoultcurrency = generalData["defaultcurrency"]?.trim() || "";
      this.defaulttimezone = generalData["timezone"]?.trim() || "";
      this.defaultsalestax = generalData["defaultsalestax"]?.trim() || "";
      this.taxexclusive = generalData["taxexclusivedisplayprices"] || false;
      this.imregisteredforvat = generalData["imregisteredforvat"] || false;
      this.vatnumber = generalData["vataccountnumber"]?.trim() || "";
      this.generateskusbynumber = generalData["generateskusbynumber"] || false;
      this.currentsequencenumber = generalData["currentsequencenumber"]?.trim() || "";
      this.automaticallyaddcustomers = generalData["automaticallyaddcustomers"] || false;
      this.firstname = generalData["firstname"]?.trim() || "";
      this.lastname = generalData["lastname"]?.trim() || "";
      this.email = generalData["email"]?.trim() || "";
      this.phone = generalData["phone"]?.trim() || "";
      this.website = generalData["website"]?.trim() || "";
      this.instagram = generalData["instagram"]?.trim() || "";
      this.facebook = generalData["facebook"]?.trim() || "";
      this.tiktok = generalData["tiktok"]?.trim() || "";
      this.twitter = generalData["twitter"]?.trim() || "";
      this.youtube = generalData["youtube"]?.trim() || "";
      this.address = generalData["street"]?.trim() || "";
      this.address2 = generalData["street1"]?.trim() || "";
      this.suburb = generalData["suburb"]?.trim() || "";
      this.city = generalData["city"]?.trim() || "";
      this.zipcode = generalData["zipcode"]?.trim() || "";
      this.statesstring = generalData["state"]?.trim() || "";
      this.countrystring = generalData["country"]?.trim() || "";
      this.usedifferent = generalData["usedifferent"] || false;
      this.paddress = generalData["pstreet"]?.trim() || "";
      this.paddress2 = generalData["pstreet1"]?.trim() || "";
      this.psuburb = generalData["psuburb"]?.trim() || "";
      this.pcity = generalData["pcity"]?.trim() || "";
      this.pzipcode = generalData["pzipcode"]?.trim() || "";
      this.pstatesstring = generalData["pstate"]?.trim() || "";
      this.pcountrystring = generalData["pcountry"]?.trim() || "";
      if (generalData["usersettings1"]) {
        this.userauthvalue = "1";
      } else if (generalData["usersettings2"]) {
        this.userauthvalue = "2";
      } else if (generalData["usersettings3"]) {
        this.userauthvalue = "3";
      } else if (generalData["usersettings4"]) {
        this.userauthvalue = "4";
      } else {
        this.userauthvalue = "1"; // Если ни один параметр не включен
      }

      this.storagedisabled = true;
    }
  }

  ngOnInit(): void {
    this.http.get<any>('assets/countries.json').subscribe((data) => {
      this.countries = data.countries;
      if (SelfData.generalsetup[0].data.length === 0) {
        this.countrystring = "United States"
      }
      this.pcountries = data.countries;
      if (SelfData.generalsetup[0].data.length === 0) {
        this.pcountrystring = "United States"
      }
      // this.countriControl.setValue('United States');
      // this.countri1Control.setValue('United States');
      // this.countri2Control.setValue('United States');
      this.http.get<any>('assets/us-states.json').subscribe((data) => {
        this.states = data;
        this.onOptionSelected(this.countrystring)
        this.pstates = data;
        this.onOptionSelectedp(this.pcountrystring)
      })
    })
  }

  onOptionSelected(event: string): void {
    const countryId = this.getCountryIdByName(event);
    this.states = [];
    if (SelfData.generalsetup[0].data.length === 0) {
      this.statesstring = "";
    }
    if (countryId == 187) {
      this.http.get<any>('assets/us-states.json').subscribe((data) => {
        this.states = data;
      });
    }
  }

  getCountryIdByName(countryName: string): number | null {
    const country = this.countries.find((c) => c.country === countryName);
    return country ? country.id : null;
  }

  onOptionSelectedp(event: string) {
    const countryId = this.getCountryIdByName(event);
    this.pstates = [];
    if (SelfData.generalsetup[0].data.length === 0) {
      this.pstatesstring = "";
    }
    if (countryId == 187) {
      this.http.get<any>('assets/us-states.json').subscribe((data) => {
        this.pstates = data;
      });
    }
  }

  onTaxesClick() {
    this.modalService.taxesOpen()
  }

  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
      ? SelfData.account[0].data[0].id
      : SelfData.account[0].data[0].parentid;
  }

  onSaveButtonClick() {
    const taxValue = getTaxValueByDisplayName(this.iTaxResult, this.defaultsalestax);

    console.log(taxValue); // Выведет: 5
    let value1: boolean = false;
    let value2: boolean = false;
    let value3: boolean = false;
    let value4: boolean = false;

    switch (this.userauthvalue) {
      case "1":
        value1 = true;
        break;
      case "2":
        value2 = true;
        break;
      case "3":
        value3 = true;
        break;
      case "4":
        value4 = true;
        break;
    }
    const inputData: PrcSaleGeneralInput = {
      storename: this.storagename.trim(),
      defaultcurrency: this.defoultcurrency.trim(),
      timezone: this.defaulttimezone.trim(),
      defaultsalestax: this.defaultsalestax.trim(),
      taxrate: taxValue,
      taxexclusivedisplayprices: this.taxexclusive,
      imregisteredforvat: this.imregisteredforvat,
      vataccountnumber: this.vatnumber,
      generateskusbynumber: this.generateskusbynumber,
      currentsequencenumber: this.currentsequencenumber,
      usersettings1: value1,
      usersettings2: value2,
      usersettings3: value3,
      usersettings4: value4,
      automaticallyaddcustomers: this.automaticallyaddcustomers,
      firstname: this.firstname.trim(),
      lastname: this.lastname.trim(),
      email: this.email.trim(),
      phone: this.phone.trim(),
      website: this.website.trim(),
      instagram: this.instagram.trim(),
      facebook: this.facebook.trim(),
      tiktok: this.tiktok.trim(),
      twitter: this.twitter.trim(),
      youtube: this.youtube.trim(),
      street: this.address.trim(),
      street1: this.address2.trim(),
      suburb: this.suburb.trim(),
      city: this.city.trim(),
      zipcode: this.zipcode.trim(),
      state: this.statesstring.trim(),
      country: this.countrystring.trim(),
      usedifferent: this.usedifferent,
      pstreet: this.paddress.trim(),
      pstreet1: this.paddress2.trim(),
      psuburb: this.psuburb.trim(),
      pcity: this.pcity.trim(),
      pzipcode: this.pzipcode.trim(),
      pstate: this.pstatesstring.trim(),
      pcountry: this.pcountrystring.trim(),
      accountid: this.getAccountID(),
      userid: SelfData.account[0].data[0].id
    }
    this.serviceservice.prcSaleGeneral(inputData).subscribe({
      next: (response) => {
        console.log(response);
        this.notification.create(
          "success",
          'System Message',
          'Data saved successfully.',
          {nzDuration: 4000}
        );
        const accountId = SelfData.account[0].data[0].id;
        forkJoin({
          generalsetup: this.serviceservice.selectGeneralSetup(accountId),
        }).subscribe(({ generalsetup }) => {
          if (!SelfData.generalsetup) {
            SelfData.generalsetup = []; // Инициализируем массив, если его нет
          }

          if (!SelfData.generalsetup[0]) {
            SelfData.generalsetup[0] = {Code: 0, ErrorMessage: "", data: [] }; // Используем [] вместо null
          }

          SelfData.generalsetup[0].data = (generalsetup?.data as TagsListData[]) ?? []; // Теперь можно безопасно присвоить
          console.log(SelfData.generalsetup)
        });
        // Do something with the response
      },
      error: (error) => {
        console.error('Error while sending data:', error);
      },
      complete: () => {
        // This block is optional and will be executed once the observable completes
        console.log('Completed');
      }
    });
  }
}

function getTaxValueByDisplayName(iTaxResult: settaxefieldsData[], displayName: string): any {
  const foundItem = iTaxResult.find(item => item["displayvalue"] === displayName);
  return foundItem ? foundItem["taxvalue"] : null; // Вернет taxvalue или null, если не найдено
}
