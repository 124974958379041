<!--<p-toast *ngIf="!isDesktopDevice" position="top-center" key="tc" [style]="{ width: '90vw' }"></p-toast>-->
<!--<p-toast *ngIf="isDesktopDevice" position="top-center" key="tc" [style]="{ width: '30vw' }"></p-toast>-->
<button mat-icon-button type="button" class="close-btn" (click)="onButtonCloseClick()">
  <mat-icon>close</mat-icon>
</button>
<p class="sample-manager-text">.</p>

<span class="messagetext">Would you like to get notifications for the new Look Books from this designer to <b>{{SelfData.lbsents[0].data[0].email}}</b>?</span>
<span class="messagetext">Or add a new email</span>
  <mat-form-field class="selector-full-width" appearance="fill">
    <mat-label>Email</mat-label>
    <input type="email" matInput [formControl]="emaiControl"/>
  </mat-form-field>
<span class="messagetext1">where you want to get notifications.</span>
<div class="button-container">
  <button mat-raised-button class="button-settings" type="button" (click)="onButtonNoClick()">
    No
  </button>
  <button mat-raised-button class="button-settings" type="button" (click)="onButtonAcceptClick()">
    Accept
  </button>
</div>

