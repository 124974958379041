<button mat-icon-button type="button" class="close-btn" (click)="onButtonCloseClick()">
  <mat-icon>close</mat-icon>
</button>

<p class="sample-manager-text">Schedule</p>

<div class="datediv">
  <nz-date-picker [nzAllowClear]="false" class="line1-edits-new inputedit testdiv" [(ngModel)]="date"
    (ngModelChange)="onChange($event)"></nz-date-picker>
  <nz-select nzPlaceHolder="Hour" nzShowSearch [nzShowArrow]="true" class="testdiv1"
    [(ngModel)]="hourString">
    <nz-option *ngFor="let option of hour" [nzLabel]="option.value"
      [nzValue]="option.value"></nz-option>
  </nz-select>
</div>
<div class="button-container-label">
  <button mat-raised-button class="button-settings" type="button" (click)="onButtonCloseClick()">
    Cancel
  </button>

  <button mat-raised-button class="button-settings" type="button" (click)="onButtonOKClick()">
    Send
  </button>
</div>
