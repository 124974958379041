import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {EMPTY, forkJoin, Subscription, switchMap, tap} from "rxjs";
import {
  PrcProductsInSaleRequest,
  PrcProductsInSaleResponse,
  SelfData,
  TagsListData,
  transformImageUrl
} from "../../models/struct";
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder} from "@angular/forms";
import {HashService} from "../../services/hash.service";
import {MessageboxComponent} from "../../modals/messagebox/messagebox.component";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {MatSnackBar} from "@angular/material/snack-bar";
import {HttpClient} from "@angular/common/http";
import {Table} from "primeng/table";


export interface Product {
  id: number;
  name: string;
  code: string;
  sku_code: string; // ✅ Добавлен SKU Number
  quantity: number;
  unitPrice: number;
  discountPercentage: number;
  discountAmount: number;
  totalBeforeDiscount: number;
  totalAfterDiscount: number;
  imagelink: string; // ✅ Добавлен параметр для изображения
  wholesaleprice: number;
  totalwholesale: number;
}

@Component({
  selector: 'app-scframedialog',
  templateUrl: './scframedialog.component.html',
  styleUrl: './scframedialog.component.scss'
})
export class ScframedialogComponent implements OnInit, AfterViewInit {
  private subscription?: Subscription;
  caption: string = "New purchase order"
  selectsupplier: string = "";
  iSuppliersResult: TagsListData[] = []
  iProductinResult: TagsListData[] = []
  iOutletsResult: TagsListData[] = []
  iProductsResult: TagsListData[] = []
  bdate: Date | Date[] | null = null;
  settaxvalue: number = 0;

  shippingCost: number = 0;
  importDuty: number = 0;
  orderTotal: number = 0;

  selectedproduct: Product[] = [];

  sizes!: any[];
  @ViewChild(Table) dataTable?: Table;
  selectedSize: any = ''

  setid: number = 0;
  opermode: number = 0;
  listOfTagOptions: any;
  selectCategories: string = "";
  ordernumber: string = "";
  invoicenumber: string = "";
  descriptionstring: string = "";
  selectproduct: string = "";
  filteredProducts: any[] = [];
  @ViewChild('searchInput', { static: false }) searchInput!: ElementRef;
  inputWidth: number = 401;

// ✅ Форматтер: Преобразует число 1234.56 в строку "$1,234.56"
  formatterDollar = (value: number): string => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
      ? SelfData.account[0].data[0].id
      : SelfData.account[0].data[0].parentid;
  }

  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private hashService: HashService,
    public messageBox: MessageboxComponent,
    private serviceservice: ServiceService,
    private yourappsService: YourappsserviceService,
    private notification: NzNotificationService,
    private _snackBar: MatSnackBar,
    private http: HttpClient,
    private cdr: ChangeDetectorRef
  ) {
    this.sizes = [{ name: "Small", class: "p-datatable-sm" }];
    this.selectedSize = this.sizes[0];
    this.setid = 0;
    this.opermode = 0;

    const accountId = SelfData.account[0].data[0].id;

    this.ordernumber = this.generateRandomCode()
    // ✅ Загружаем `suppliers`, `outlets`, `products` сразу при инициализации
    forkJoin({
      suppliers: this.serviceservice.selectSuppliers(accountId),
      outlets: this.serviceservice.selectOutlets({ accountid: this.getAccountID() }),
      products: this.serviceservice.selectProducts(this.getAccountID()),
    }).subscribe(({ suppliers, outlets, products }) => {
      this.iSuppliersResult = suppliers.data;
      this.iOutletsResult = outlets.data;
      this.iProductsResult = products.data;
      console.log("✅ Загружены suppliers, outlets, products");
      console.log("this.iProductsResult", this.iProductsResult)
      this.filteredProducts = this.iProductsResult;
    });

    // ✅ Ждем `selectedOrderInList$`, потом загружаем `productin`
    this.yourappsService.selectedOrderInList$.pipe(
      tap((value) => {
        if (this.subscription) {
          this.subscription.unsubscribe();
        }

        if (value?.type === 1) {
          this.opermode = 1;
          this.caption = "Edit order";

          if (value.data && typeof value.data === "object") {
            let data: any = value.data;

            if (Array.isArray(data) && data.length > 0) {
              data = data[0];
            }

            console.log("✅ Объект после обработки:", data);

            if (!Array.isArray(this.listOfTagOptions)) {
              this.listOfTagOptions = [];
            }

            // ✅ Заполняем параметры
            this.setid = data.id;
            this.listOfTagOptions = data.ordering_for ? data.ordering_for.split(',') : [];
            this.selectsupplier = data.supplier;
            this.selectCategories = data.deliver_to;
            this.bdate = data.delivery_date ? new Date(data.delivery_date) : null;
            this.ordernumber = data.order_number;
            this.invoicenumber = data.supplier_invoice_number;
            this.descriptionstring = data.note;
            this.shippingCost = data.shipping_cost;
            this.importDuty = data.import_duty;
          }
        }
      }),
      switchMap(() => {
        if (!this.setid) {
          return EMPTY; // ❌ Если `setid = 0`, не делаем запрос
        }
        return this.serviceservice.selectProductin(this.getAccountID(), this.setid);
      })
    ).subscribe((productin) => {
      this.iProductinResult = productin.data;
      console.log("📌 iProductinResult:", this.iProductinResult);

      if (Array.isArray(this.iProductinResult) && this.iProductinResult.length > 0) {
        this.selectedproduct = this.iProductinResult.map((item: any) => ({
          id: item.productid,
          name: item.name,
          code: item.code,
          sku_code: item.sku_code,
          quantity: item.quantity || 1,
          unitPrice: item.unitPrice || 0,
          discountPercentage: item.discountPercentage || 0,
          discountAmount: item.discountAmount || 0,
          totalBeforeDiscount: item.totalBeforeDiscount || item.unitPrice || 0,
          totalAfterDiscount: item.totalAfterDiscount || item.unitPrice || 0,
          imagelink: item.imagelink || "",
          wholesaleprice: item.wholesaleprice || 0,
          totalwholesale: item.totalwholesale || 0
        }));
        console.log("✅ Заполненный selectedproduct:", this.selectedproduct);
        this.calculateOrderTotal();
        this.getTotalDiscount();
      } else {
        console.warn("⚠️ iProductinResult пуст или не является массивом.");
      }
    });

    if (SelfData.generalsetup.length > 0) {
      this.settaxvalue = SelfData.generalsetup[0].data[0]["taxrate"];
    } else {
      this.settaxvalue = 0;
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.adjustAutocompleteWidth();
    });
  }

  ngOnInit(): void {
  }


  onSupplierClick() {
    this.yourappsService.setSelectedSupplierList([], 0)
    this.yourappsService.getReloadSupplierList([], 0)
    this.subscription = this.yourappsService.reloadSupplierList$.subscribe((value) => {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      if (value.type === 1) {
        forkJoin({
          suppliers: this.serviceservice.selectSuppliers(this.getAccountID()),
        }).subscribe(({ suppliers }) => {
          this.iSuppliersResult = suppliers.data;
        });
      }
    })
    this.modalService.suppliersFrameOpen()
  }

  onChange($event: any) {

  }

  generateRandomCode(): string {
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'; // Алфавит
    const numbers = '0123456789'; // Цифры

    // Генерируем 3 случайные буквы
    let randomLetters = '';
    for (let i = 0; i < 3; i++) {
      randomLetters += letters.charAt(Math.floor(Math.random() * letters.length));
    }

    // Генерируем 3 случайные цифры
    let randomNumbers = '';
    for (let i = 0; i < 3; i++) {
      randomNumbers += numbers.charAt(Math.floor(Math.random() * numbers.length));
    }

    return `${randomLetters}-${randomNumbers}`;
  }

  onProductAddClick() {
    this.yourappsService.setSelectedProductsList([], 0)
    this.yourappsService.getReloadProductsList([], 0)
    this.subscription = this.yourappsService.reloadProductsList$.subscribe((value) => {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      if (value.type === 1) {
        forkJoin({
          outlets: this.serviceservice.selectProducts(this.getAccountID()),
        }).subscribe(({ outlets }) => {
          this.iProductsResult = outlets.data;
          console.log("this.iProductsResult", this.iProductsResult)
        });
      }
    })
    this.modalService.productFrameOpen()
  }

  onProductPluseClick() {
    console.log("onProductPluseClick", this.selectproduct);

    if (this.selectproduct.trim() ==="") {
      this.notification.create(
        "error",
        "System Message",
        "You have not selected a product.",
        { nzDuration: 8000 }
      );
      return;
    }

    const checkproduct = this.iProductsResult.find(
      product => product['name'].toLowerCase().trim() === this.selectproduct.toLowerCase().trim()
    );

    if (!checkproduct) {
      this.notification.create(
        "error",
        "System Message",
        "The selected product is not in our database.",
        { nzDuration: 8000 }
      );
      return;
    }

    // Проверяем, есть ли такой продукт в `iProductsResult`
    const foundProduct = this.iProductsResult.find(
      product => product['name'].toLowerCase().trim() === this.selectproduct.toLowerCase().trim()
    );

    console.log("selectedproduct", this.selectedproduct)
    console.log("foundProduct", foundProduct)

    if (foundProduct) {
      // Проверяем, есть ли уже такой продукт в `selectedproduct`
      const existingProduct = this.selectedproduct.find(product => product.id === foundProduct['id']);
      console.log("existingProduct", existingProduct)
      if (existingProduct) {
        // ✅ Если продукт уже в `selectedproduct`, увеличиваем `quantity`
        existingProduct.quantity += 1;
        existingProduct.totalBeforeDiscount = existingProduct.quantity * existingProduct.unitPrice;
        existingProduct.totalAfterDiscount = existingProduct.totalBeforeDiscount - existingProduct.discountAmount;

        console.log("🔄 Количество увеличено:", existingProduct);
      } else {
        // ✅ Если продукта нет в `selectedproduct`, создаем новый объект
        const newSelectedProduct: Product = {
          id: foundProduct['id'],
          name: foundProduct['name'],
          sku_code: foundProduct['sku_code'],
          code: foundProduct['sku_code'], // Можно использовать SKU как код
          quantity: 1, // ✅ Начальное количество = 1
          unitPrice: foundProduct['supplier_price'], // ✅ Цена поставщика
          discountPercentage: 0, // ✅ Скидка (если есть логика)
          discountAmount: 0, // ✅ Вычисляется при необходимости
          totalBeforeDiscount: foundProduct['supplier_price'], // ✅ Изначальная стоимость
          imagelink: foundProduct['imagelink'], // ✅ Изображение
          totalAfterDiscount: foundProduct['supplier_price'], // ✅ Добавлено поле totalAfterDiscount
          wholesaleprice: foundProduct['wholesale_price'],
          totalwholesale: foundProduct['wholesale_price'] * 1,
        };

        // ✅ Рассчитываем totalAfterDiscount
        newSelectedProduct.totalAfterDiscount = newSelectedProduct.totalBeforeDiscount - newSelectedProduct.discountAmount;

        // ✅ Добавляем продукт в массив `selectedproduct`
        this.selectedproduct.push(newSelectedProduct);
        console.log("✅ Продукт добавлен:", newSelectedProduct);
      }

      // ✅ Пересчитываем ORDER TOTAL после добавления продукта
      this.calculateOrderTotal();
    } else {
      console.log("❌ Продукт не найден.");
    }

    // Сбрасываем выбранное значение
    this.selectproduct = "";
  }

  protected readonly transformImageUrl = transformImageUrl;

  deleteProduct(product: Product) {
    // Фильтруем массив, оставляя только те продукты, которые не равны удаляемому
    this.selectedproduct = this.selectedproduct.filter(p => p.id !== product.id);
    this.calculateOrderTotal();
    this.getTotalDiscount()
    console.log("❌ Продукт удален:", product);
  }

  updateTotalCost(product: Product) {
    // 1. Пересчитываем сумму скидки
    product.discountAmount = ((product.unitPrice * product.discountPercentage) / 100) * product.quantity;
    const discountwAmount = ((product.wholesaleprice * product.discountPercentage) / 100) * product.quantity;

    // 2. Пересчитываем общую сумму без скидки
    product.totalBeforeDiscount = product.quantity * product.unitPrice;

    // 3. Итоговая сумма: ОТНИМАЕМ ТОЛЬКО СКИДКУ
    product.totalAfterDiscount = product.totalBeforeDiscount - product.discountAmount;

    // 4. Пересчитываем оптовую стоимость
    product.totalwholesale = (product.quantity * product.wholesaleprice) - discountwAmount;

    // ✅ Пересчитываем ORDER TOTAL сразу после изменений
    this.calculateOrderTotal();
    this.getTotalDiscount();
  }


  getSubtotal(): number {
    return this.selectedproduct.reduce((sum, product) => sum + product.totalBeforeDiscount, 0);
  }

// ✅ ORDER TOTAL (Сумма после учета скидок + доставка + импортный налог)
  calculateOrderTotal() {
    const productsTotal = this.selectedproduct.reduce((sum, product) => sum + product.totalAfterDiscount, 0);
    this.orderTotal = productsTotal + this.shippingCost + this.importDuty;
  }

  // ✅ Метод для вычисления общей суммы скидки (отрицательное значение)
  getTotalDiscount(): number {
    const totalDiscount = this.selectedproduct.reduce((sum, product) => sum + product.discountAmount, 0);
    return totalDiscount > 0 ? totalDiscount : 0; // ✅ Если скидка 0, не делаем отрицательной
  }

  getSupplierIdByName(supplierName: string): number | null {
    const foundSupplier = this.iSuppliersResult.find(supplier => supplier['SupplierName'] === supplierName);
    return foundSupplier ? foundSupplier['id'] : null;
  }

  onAddProductClick() {
    let tid: number = this.setid;
    let tctrl: number = this.opermode;

    let supplierId = this.getSupplierIdByName(this.selectsupplier);

    this.serviceservice.prcOrderSaleIn({
      ctrl: tctrl,  // 0 = Insert, 1 = Update, 2 = Deactivate
      id: tid,
      supplierid: supplierId || 0, // Если есть ID поставщика
      supplier: this.selectsupplier,
      accountid: this.getAccountID(),
      ordering_for: this.listOfTagOptions,
      deliver_to: this.selectCategories,
      delivery_date: this.bdate instanceof Date ? this.bdate : new Date(),
      order_number: this.ordernumber,
      supplier_invoice_number: this.invoicenumber,
      note: this.descriptionstring,
      subtotal: this.getSubtotal(),
      total_discount: this.getTotalDiscount(),
      shipping_cost: this.shippingCost,
      import_duty: this.importDuty,
      order_total: this.orderTotal,
    }).subscribe(async response => {
      let orderid = response.data
      const newProduct: PrcProductsInSaleRequest = {
        tbl_order_sale_inid: response.data,
        accountid: 0,
        name: "",
        code: "",
        sku_code: "",
        quantity: 0,
        unitPrice: 0,
        discountPercentage: 0,
        discountAmount: 0,
        totalBeforeDiscount: 0,
        totalAfterDiscount: 0,
        imagelink: "",
        ctrl: 1,
        productid: 0,
        wholesaleprice: 0,
        totalwholesale: 0
      };
      this.serviceservice.prcProductsInSale(newProduct).subscribe({
        next: (response: PrcProductsInSaleResponse) => {
          console.log("✅ Ответ от сервера:", response);
          this.selectedproduct.forEach(product => {
            const newProduct: PrcProductsInSaleRequest = {
              tbl_order_sale_inid: orderid,
              accountid: this.getAccountID(), // Используем актуальный `accountid`
              name: product.name,
              code: product.code,
              sku_code: product.sku_code,
              quantity: product.quantity,
              unitPrice: product.unitPrice,
              discountPercentage: product.discountPercentage,
              discountAmount: product.discountAmount,
              totalBeforeDiscount: product.totalBeforeDiscount,
              totalAfterDiscount: product.totalAfterDiscount,
              imagelink: product.imagelink,
              ctrl: 0, // ✅ 0 = Вставка нового продукта
              productid: product.id,
              wholesaleprice: product.wholesaleprice,
              totalwholesale: product.totalwholesale
            };

            // ✅ Отправляем продукт в `prcProductsInSale`
            this.serviceservice.prcProductsInSale(newProduct).subscribe({
              next: (response: PrcProductsInSaleResponse) => {
                console.log(`✅ Продукт ${product.name} добавлен в заказ ${orderid}:`, response);
              },
              error: (error: any) => {
                console.error(`❌ Ошибка при добавлении ${product.name}:`, error);
              },
              complete: () => {
                console.log(`✅ Запрос для ${product.name} успешно выполнен!`);
              }
            });
          });
        },
        error: (error: any) => {
          console.error("❌ Ошибка:", error);
        },
        complete: () => {
          console.log("✅ Запрос успешно выполнен!");
        }
      });
      this.yourappsService.getReloadOrderInList([], 1)
      this.modalService.scFrameClose();
      this.notification.create(
        "success",
        "System Message",
        "Order saved successfully.",
        { nzDuration: 4000 }
      );
    });
  }

  onSearch(value: string): void {
    const searchValue = value.toLowerCase();

    this.filteredProducts = this.iProductsResult.filter(product =>
      product['description'].toLowerCase().includes(searchValue) ||
      product['sku_code'].toLowerCase().includes(searchValue) ||
      product['tags'].toLowerCase().includes(searchValue) ||
      product['brand'].toLowerCase().includes(searchValue) ||
      product['color'].toLowerCase().includes(searchValue)
    );

  }

  onFocus() {
    this.filteredProducts = this.iProductsResult; // ყველა პროდუქტის გამოჩენა
    this.onSearch(this.selectproduct)
    this.searchInput.nativeElement.dispatchEvent(new Event('input')); // ან ეს ხაზი, თუ გსურთ autocomplete-ის გახსნა
  }

  adjustAutocompleteWidth() {
    if (this.searchInput) {
      console.log("this.searchInput.nativeElement.offsetWidth", this.searchInput.nativeElement.offsetWidth + 40)
      this.inputWidth = this.searchInput.nativeElement.offsetWidth + 40;
    }
  }
}
