import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder} from "@angular/forms";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ConfirmationService, ConfirmEventType, MessageService} from "primeng/api";
import {GetScannedList, SelfData, SetSampleListData, transformImageUrl} from "../../models/struct";

@Component({
  selector: 'app-scannerformdialog',
  templateUrl: './scannerformdialog.component.html',
  styleUrl: './scannerformdialog.component.scss'
})
export class ScannerformdialogComponent implements OnInit, AfterViewInit, OnDestroy {
  currentSample: SetSampleListData | null = null;

  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private serviceservice: ServiceService,
    private yourappsService: YourappsserviceService,
    private _snackBar: MatSnackBar,
    private cdRef: ChangeDetectorRef,
    private messageService: MessageService,
    private renderer: Renderer2,
    private confirmationService: ConfirmationService
  ) {
    this.sendfunction = false
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
  }

  onViewButtonClick(element: any) {
    SelfData.sampleselecteddata = element;
    SelfData.updatebuttonisenabled = false
    this.yourappsService.selectSampleData(element);
    this.modalService.createsampleropen();
  }

  onSelectedItemCLick(barcode: string) {
    // Find the sample with matching barcode in scannedfulllist
    let foundSample = null;

    if (SelfData.scannedfulllist && SelfData.scannedfulllist.length > 0) {
      // Iterate through each list in scannedfulllist
      for (const list of SelfData.scannedfulllist) {
        // Find the sample with matching barcode in list.data
        const sample = list.data.find(item => item.barcode === barcode);
        if (sample) {
          foundSample = sample;
          break;
        }
      }
    }

    // If sample is found, add it to scannedselectedlist
    if (foundSample) {
      SelfData.scannedselectedlist.push({
        userid: foundSample.userid || 0,
        accountid: foundSample.accountid || 0,
        barcode: barcode
      });
    }

    this.yourappsService.setSelectedSample(barcode);
  }

  onSelectAllClick() {
    this.sendfunction = true
    if (SelfData.scannedfulllist && SelfData.scannedfulllist.length > 0) {
      // Проходим по каждому объекту GetScannedList в scannedfulllist
      SelfData.scannedfulllist.forEach(list => {
        // Проходим по каждому объекту GetScannedListData в list.data
        list.data.forEach(sample => {
          const barcode = sample.barcode; // Получаем значение поля 'barcode'
          if (barcode) {
            // console.log(barcode)
            // შევავსოთ scannedselectedlist
            SelfData.scannedselectedlist.push({
              userid: sample.userid || 0, // თუ არსებობს, თუ არა მაშინ 0
              accountid: sample.accountid || 0, // თუ არსებობს, თუ არა მაშინ 0
              barcode: barcode
            });
            this.yourappsService.setSelectedSample(barcode); // Вызываем функцию для каждого barcode
          }
        });
      });
      this.modalService.closeScann()
    } else {
      console.log('scannedfulllist пуст или не определен');
    }
  }

  showdialog: boolean = false;

  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
      ? SelfData.account[0].data[0].id
      : SelfData.account[0].data[0].parentid;
  }

  iScannedList: GetScannedList[] = [];

  onDeleteItemCLick(element: any) {
    this.showdialog = true
    this.cdRef.detectChanges()
    this.confirmationService.confirm({
      message: `<strong>Do you want to delete this item the list?</strong>`,
      header: 'Delete item',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        if (element) {
          this.serviceservice
            .deletefromscannlist({
              ctrl: 1,
              userid: 0,
              accountid: this.getAccountID(),
              barcode: element['barcode']
            })
            .subscribe((value) => {
              this.serviceservice
                .scannedlist({
                  accountid: this.getAccountID()
                })
                .subscribe((value) => {
                  this.iScannedList[0] = value;
                  SelfData.scannedfulllist = this.iScannedList;
                });
            });
        }
        this.showdialog = false
        this.cdRef.detectChanges()
      },
      reject: (type: any) => {
        if (type == ConfirmEventType.REJECT) {
          this.showdialog = false
          this.cdRef.detectChanges()
        } else if (type == ConfirmEventType.CANCEL) {
          this.showdialog = false
          this.cdRef.detectChanges()
        }
      }
    });

    setTimeout(() => {
      this.applyLocalStyles();
    }, 0);
  }

  private applyLocalStyles() {
    setTimeout(() => {
      const messageElements = document.querySelectorAll('.p-confirm-dialog-message');
      messageElements.forEach((element) => {
        element.setAttribute('style', 'font-size: 16px; color: red;');
      });
    }, 0);

    const iconElements = document.querySelectorAll('.p-confirm-dialog-icon');
    iconElements.forEach((element) => {
      element.setAttribute('style', 'color: red');
    });
  }

  protected readonly SelfData = SelfData;
  sendfunction: boolean = false;
  protected readonly transformImageUrl = transformImageUrl;
}
