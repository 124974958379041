import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {ModalserviceService} from "../../services/modalservice.service";
import {ServiceService} from "../../services/service.service";
import {HashService} from "../../services/hash.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MessageService} from "primeng/api";
import {PullSheetData, PullSheetDetails, RequestListDetails, SelfData, SmsDetails} from "../../models/struct";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-createsrdialog',
  templateUrl: './createsrdialog.component.html',
  styleUrls: ['./createsrdialog.component.scss']
})
export class CreatesrdialogComponent implements OnInit, AfterViewInit, OnDestroy {
  emailFormControl = new FormControl('', [
    Validators.email,
  ]);
  sendoutControl = new FormControl<string>('');
  phoneControl = new FormControl<string>('');
  sendoutid: number = 0
  text: string = "";
  buttondisabled: boolean = false
  private subscription?: Subscription;
  constructor(
      public modalService: ModalserviceService,
      public fb: FormBuilder,
      private serviceservice: ServiceService,
      private hashService: HashService,
      private yourappsService: YourappsserviceService,
      private messageService: MessageService
  ) {

  }

  getRandomNumber(): number {
    return Math.floor(Math.random() * 999999999);
  }

  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
        ? SelfData.account[0].data[0].id
        : SelfData.account[0].data[0].parentid;
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription?.unsubscribe()
      this.yourappsService.setSelectedSendOut(null)
    }
  }

  ngOnInit(): void {
    this.subscription = this.yourappsService.selsendout.subscribe(value => {
      if (value) {
        this.buttondisabled = true
        console.log("VALUE", value)
        this.sendoutid = value?.["id"]
        this.sendoutControl.setValue(value?.["Delivery"])
      } else {
        console.log("NULL")
        this.buttondisabled = false
      }

      // this.sendoutControl.setValue(value?.["Delivery"])
      // this.sendoutid = value?.["id"]
      // this.subscription?.unsubscribe()

    });
  }

  onButtonSendClick() {
    if (this.emailFormControl.value?.trim() === "" && this.phoneControl.value?.trim() === "") {
      this.messageService.add({
        key: 'tc',
        severity: 'error',
        summary: 'Information',
        detail: 'Please provide either an email address or a phone number.',
        life: 10000,
      });
      return
    }
    if (this.emailFormControl.value?.trim() !== "" && this.emailFormControl.invalid) {
      this.messageService.add({
        key: 'tc',
        severity: 'error',
        summary: 'Information',
        detail: 'Incorrect email',
        life: 10000,
      });
      return
    }
    let key = this.hashService.md5(this.getRandomNumber().toString())

    if (this.emailFormControl.value?.trim() !== "") {
      const data: RequestListDetails = {
        email: this.emailFormControl.value?.trim() || "",
        subject: SelfData.account[0].data[0].company + " - Request List",
        message: this.text.trim(),
        key: key,
        accountid: this.getAccountID(),
        userid: SelfData.account[0].data[0].id,
        sendoutid: this.sendoutid,
        status: 0,
        isactivity: true,
        ctrl: 0,
        from: 'tata-pr@tata-la.com'
      };

      this.serviceservice.requestlistemail(data).subscribe({
        next: (response: PullSheetData) => {
          this.messageService.add({
            key: 'tc',
            severity: 'success',
            summary: 'E-mail Sent',
            detail: '\n' +
                'Notice! The text contains a link and this email may end up in the Junk/Spam on the other side. \n' +
                'It would be nice if you tell the other party to check Junk/Spam if they don\'t see the email.',
            life: 10000,
          });
          this.modalService.closeSR()
        },
        error: error => {
          console.error('Error:', error);
        }
      });

    }

    if (this.phoneControl.value?.trim() !== "") {
      const data: SmsDetails = {
        to: this.phoneControl.value?.trim() || "",
        from: "13175970635" ,
        text: SelfData.account[0].data[0].company + "\n" + 'Please see "Request List" attachment' + "\n",
        key: key,
        accountid: this.getAccountID(),
        userid: SelfData.account[0].data[0].id,
        sendoutid: this.sendoutid,
        status: 0,
        isactivity: true,
        ctrl: 0
      };
      this.serviceservice.requestsmssend(data).subscribe({
        next: (response: PullSheetData) => {
          this.messageService.add({
            key: 'tc',
            severity: 'success',
            summary: 'The message has been sent.',
            detail: '\n' +
                'Notice! The message has been sent to the specified number.',
            life: 10000,
          });
          this.modalService.closeSR()
        },
        error: error => {
          console.error('Error:', error);
        }
      });
    }


  }
}
