import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Table} from "primeng/table";
import {
  GetCheckVisibled,
  GetLBAccepts,
  GetlbSentList,
  GetlbSentListData,
  LbSentDetails,
  SelfData
} from "../../models/struct";
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder} from "@angular/forms";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ConfirmationService, ConfirmEventType, MessageService} from "primeng/api";
import {NgxImageCompressService} from "ngx-image-compress";
import { HttpClient } from "@angular/common/http";
import {DatePipe} from "@angular/common";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-createlsb1dialog',
  templateUrl: './createlsb1dialog.component.html',
  styleUrls: ['./createlsb1dialog.component.scss']
})
export class Createlsb1dialogComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('dt1') dt1!: Table;
  filtertext: string = ""
  showdialog: boolean = false
  accepted: boolean = false
  datasource: GetlbSentListData[] = [];
  currancecatalog: GetlbSentListData[] = [];
  ilbSentResult: GetlbSentList[] = [];
  ilbCVResult: GetCheckVisibled[] = []

  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
        ? SelfData.account[0].data[0].id
        : SelfData.account[0].data[0].parentid;
  }

  constructor(
      public modalService: ModalserviceService,
      public fb: FormBuilder,
      private serviceservice: ServiceService,
      private yourappsService: YourappsserviceService,
      private _snackBar: MatSnackBar,
      private cdr: ChangeDetectorRef,
      private messageService: MessageService,
      private imageCompressService: NgxImageCompressService,
      private confirmationService: ConfirmationService,
      private http: HttpClient,
      private datePipe: DatePipe,
      private el: ElementRef
  ) {
    this.serviceservice
        .selectlbsent({accountid: this.getAccountID()})
        .subscribe((value) => {
          this.ilbSentResult[0] = value;
          this.ilbSentResult[0].data = this.ilbSentResult[0].data.filter(item => item.accepted === 1);
          console.log("this.ilbSentResult", this.ilbSentResult)
          SelfData.lbsent = this.ilbSentResult;
          this.datasource = SelfData.lbsent[0].data
        });
  }

  formatDate(dateString: string): string {
    // Convert and format the date
    return <string>this.datePipe.transform(dateString, 'yyyy-MM-dd HH:mm');
  }

  onInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;
    this.dt1.filterGlobal(value, 'contains');
  }

  clear(table: Table) {
    table.clear();
    this.filtertext = ""
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.showdialog = true
  }

  ngOnInit(): void {
    this.showdialog = true
  }

  onButtonCloseClick() {
    this.modalService.closelsb1()
  }

  onButtonAddClick() {
    this.showdialog = false
    this.subscription = this.yourappsService.reloadsendbooklist.subscribe((value) => {
      if (this.subscription) {
        this.subscription.unsubscribe();
        this.serviceservice
            .selectlbsent({accountid: this.getAccountID()})
            .subscribe((value) => {
              this.ilbSentResult[0] = value;
              SelfData.lbsent = this.ilbSentResult;
              this.datasource = SelfData.lbsent[0].data
              this.showdialog = true
            });
      }
    })
    this.modalService.openlsbframe()
  }

  onButtonPendingClick(catalog: any) {
    this.messageService.add({
      key: 'tc',
      severity: 'warn',
      summary: 'Information',
      detail: 'Look Book still pending',
      life: 5000,
    });
  }

  updateVisibility(id: number): void {
    const item = this.datasource.find(item => item.id === id);
    if (item) {
      item.visibled = false;
    }
  }

  removeSentLookBook(id: number): void {
    const index = this.datasource.findIndex(item => item.id === id);
    if (index !== -1) {
      this.datasource.splice(index, 1);
    }
  }

  iLBAcceptsResult: GetLBAccepts[] = [];

  onButtonSelectClick(catalog: any) {
    this.showdialog = true
    this.confirmationService.confirm({
      message: `<strong>Would you like to add items from this Look Book?</strong>`,
      header: 'Select Look Book',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.yourappsService.returnSelectedFiles(catalog, 1)
        this.modalService.closelsb1();
      },
      reject: (type: any) => {
        if (type == ConfirmEventType.REJECT) {

        } else if (type == ConfirmEventType.CANCEL) {
        }
      }

    });
    setTimeout(() => {
      this.applyLocalRestoreStyles();
    }, 0);

  }

  onButtonSelectedClick(catalog: any) {
    this.updateVisibility(catalog.id)
    const fileData = {
      lbmainid: 0,
      accounid: catalog.id,
      userid: 0,
      filename: "",
      filedir: "",
      lbcatalogid: 0,
      colection: "",
      designer: "",
      catalog: "",
      catalogyear: 0,
      ctrl: 2,
      key: catalog.key
    };

    this.serviceservice.selectlbaccepts({lbmainid: catalog.lbmainid, key: catalog.key})
        .subscribe((value) => {
          this.iLBAcceptsResult[0] = value
          SelfData.lbaccepts = this.iLBAcceptsResult;
          SelfData.tcustomimages = value
          this.yourappsService.setImageList(catalog, 1)
          this.modalService.openivframe()
        })

    this.serviceservice.prcLbAccepted(fileData).subscribe({
      next: (value) => {

      },
      error: (error: any) => {
        console.error('Error occurred:', error);
      },
      complete: () => {
        this.serviceservice
            .checkvisibled({accountid: this.getAccountID()})
            .subscribe((value) => {
              console.log("value", value)
              this.ilbCVResult[0] = value;
              SelfData.lbvisibled = this.ilbCVResult;
            });
      }
    });
  }

  private subscription?: Subscription

  onDeleteButtonClick(catalog: any) {
    this.showdialog = true
    this.confirmationService.confirm({
      message: `<strong>Delete sent Look Book?</strong>`,
      header: 'Sent Look Book',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.subscription = this.yourappsService.reloadsendbooklist.subscribe((value) => {
          if (this.subscription) {
            this.subscription.unsubscribe();
            this.serviceservice
                .selectlbsent({accountid: this.getAccountID()})
                .subscribe((value) => {
                  this.ilbSentResult[0] = value;
                  SelfData.lbsent = this.ilbSentResult;
                  this.datasource = SelfData.lbsent[0].data
                });
          }
        })
        this.removeSentLookBook(catalog.id)
        const dataToSend: LbSentDetails = {
          email: '',
          phone: '',
          key: '',
          accountid: 0,
          userid: 0,
          lbmainid: 0,
          isactivity: false,
          ctrl: 1,
          id: catalog.id,
          name: ''
        };
        this.serviceservice.prclbsent(dataToSend).subscribe({
          next: (response) => {
            this.yourappsService.getReloadSLBlist()
            // Handle the response here
          },
          error: (error) => {
            console.error('Error:', error);
            // Handle the error here
          },
          complete: () => {

          }
        });
      },
      reject: (type: any) => {
        if (type == ConfirmEventType.REJECT) {

        } else if (type == ConfirmEventType.CANCEL) {
        }
      }

    });
    setTimeout(() => {
      this.applyLocalStyles();
    }, 0);
  }

  private applyLocalStyles() {
    setTimeout(() => {
      const messageElements = document.querySelectorAll('.p-confirm-dialog-message');
      messageElements.forEach((element) => {
        element.setAttribute('style', 'font-size: 16px; color: red;');
      });
    }, 0);

    const iconElements = document.querySelectorAll('.p-confirm-dialog-icon');
    iconElements.forEach((element) => {
      element.setAttribute('style', 'color: red');
    });
  }

  private applyLocalRestoreStyles() {
    setTimeout(() => {
      const messageElements = document.querySelectorAll('.p-confirm-dialog-message');
      messageElements.forEach((element) => {
        element.setAttribute('style', 'font-size: 16px; color: #343a40;');
      });
    }, 0);

    const iconElements = document.querySelectorAll('.p-confirm-dialog-icon');
    iconElements.forEach((element) => {
      element.setAttribute('style', 'color: #343a40');
    });
  }

}
