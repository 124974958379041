import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder} from "@angular/forms";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {ConfirmationService, MessageService} from "primeng/api";
import {NgxImageCompressService} from "ngx-image-compress";
import {HttpClient} from "@angular/common/http";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-comingsoondialog',
  templateUrl: './comingsoondialog.component.html',
  styleUrl: './comingsoondialog.component.scss'
})
export class ComingsoondialogComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private serviceservice: ServiceService,
    private yourappsService: YourappsserviceService,
    private cdr: ChangeDetectorRef,
    private messageService: MessageService,
    private imageCompressService: NgxImageCompressService,
    private confirmationService: ConfirmationService,
    private http: HttpClient,
    private datePipe: DatePipe,
    private el: ElementRef
  ) {

  }

  onButtonCloseClick() {
    this.modalService.closeCS()
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
  }
}
