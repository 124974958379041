<p-toast *ngIf="!isDesktopDevice" position="bottom-center" key="bc" [style]="{ width: '90vw' }"></p-toast>
<p-toast *ngIf="isDesktopDevice" position="bottom-center" key="bc" [style]="{ width: '30vw' }"></p-toast>

<div class="bodydiv">
    <mat-card [ngClass]="deviceClass">
        <mat-card-content class="cards-container">
            <div *ngFor="let card of SelfData.lbdesigners[0].data" class="inner-card">
                <mat-card [matBadge]="card.catalogcount" class="subcard" (click)="onDesignerClick(card)">
                    <mat-icon>perm_media</mat-icon>
                    <span>{{ card.designer }}</span>
                </mat-card>
            </div>
            <div class="feshendiv">
                <div class="left-text-qr">
                    <a href="http://www.fashion-m.com" target="_blank">fashion-m.com</a>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>
