import {AfterViewInit, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder, FormControl} from "@angular/forms";
import {HashService} from "../../services/hash.service";
import {MessageboxComponent} from "../../modals/messagebox/messagebox.component";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {NgxImageCompressService} from "ngx-image-compress";
import { HttpClient } from "@angular/common/http";
import {PrcPstemplateInput, SelfData} from "../../models/struct";
import {MessageService} from "primeng/api";

@Component({
    selector: 'app-pullsheettemplatedialog',
    templateUrl: './pullsheettemplatedialog.component.html',
    styleUrls: ['./pullsheettemplatedialog.component.scss']
})
export class PullsheettemplatedialogComponent implements OnInit, AfterViewInit {
    imageUrl: string = '';
    selectedFile: File | null = null;
    prmanagerFormControl = new FormControl('');
    phoneFormControl = new FormControl('');
    emailFormControl = new FormControl('');

    prassistentFormControl = new FormControl('');
    assistentphoneFormControl = new FormControl('');
    assistentemailFormControl = new FormControl('');
    value1FormControl = new FormControl('');
    value2FormControl = new FormControl('');
    value3FormControl = new FormControl('');
    value4FormControl = new FormControl('');
    value5FormControl = new FormControl('');
    value6FormControl = new FormControl('');
    value7FormControl = new FormControl('');
    value8FormControl = new FormControl('');
    value9FormControl = new FormControl('');
    value10FormControl = new FormControl('');
    value11FormControl = new FormControl('');

    constructor(
        public modalService: ModalserviceService,
        public fb: FormBuilder,
        private hashService: HashService,
        private serviceservice: ServiceService,
        private yourappsService: YourappsserviceService,
        private cdr: ChangeDetectorRef,
        private messageService: MessageService,
        private imageCompressService: NgxImageCompressService,
        private http: HttpClient
    ) {

    }

    onFileSelected(event: Event) {
        const inputElement = event.target as HTMLInputElement;
        if (inputElement.files && inputElement.files.length > 0) {
            const file = inputElement.files[0];
            this.compressImage(file);
            this.imageUrl = file.name
        } else {
            this.selectedFile = null;
            this.imageUrl = ""
        }
    }

    compressImage(file: File) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
            const image = e.target.result;
            this.imageCompressService.compressFile(image, -1, 80, 100).then(
                (result: string) => {
                    // Set the compressed image as the src for the image element
                    const imageElement = document.querySelector(
                        '.account-image1'
                    ) as HTMLImageElement;
                    imageElement.src = result;

                    // Convert the compressed image (base64) back to a File object and set it to selectedFile
                    const compressedFile = this.base64ToFile(
                        result,
                        file.name,
                        file.type
                    );
                    this.selectedFile = compressedFile;
                },
                (error: any) => {
                    console.error('Error compressing image:', error);
                }
            );
        };
        reader.readAsDataURL(file);
    }

    base64ToFile(dataURI: string, fileName: string, mimeType: string): File {
        const byteString = atob(dataURI.split(',')[1]);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
        }
        return new File([int8Array], fileName, {type: mimeType});
    }

    ngAfterViewInit(): void {
    }

    ngOnInit(): void {
    }

    private getAccountID(): number {
        return SelfData.account[0].data[0].parentid === 0
            ? SelfData.account[0].data[0].id
            : SelfData.account[0].data[0].parentid;
    }

    uploadLogo(
        file: File,
        accountId: number,
        callback: (success: boolean) => void
    ) {
        const formData = new FormData();

        const updatedFile = new File(
            [file],
            this.hashService.md5(accountId.toString()) + '.png',
            {type: file.type}
        );

        formData.append('image', updatedFile, updatedFile.name);
        formData.append('accountId', accountId.toString());

        this.http.post('http://108.178.189.70:6330/updatelogo', formData).subscribe(
            (response) => {
                console.log('Image uploaded successfully', response);
                callback(true);
            },
            (error) => {
                console.error('Image upload failed', error);
                callback(false);
            }
        );
    }

    private getStringValue(formControlValue: any): string {
        return (typeof formControlValue === 'string') ? formControlValue.trim() : '';
    }

    onSubmit() {
        this.modalService.closePullSheetTemplate()
        var filename: string = ""
        if (this.imageUrl === "") {
            filename = ""
        } else {
            filename = this.hashService.md5(this.getAccountID().toString()) + '.png'
        }
        const accountData: PrcPstemplateInput = {
            ctrl: 0,  // Example value
            prmanager: this.getStringValue(this.prmanagerFormControl.value),
            prphone: this.getStringValue(this.phoneFormControl.value),
            premail: this.getStringValue(this.emailFormControl.value),
            asistant: this.getStringValue(this.prassistentFormControl.value),
            asiphone: this.getStringValue(this.assistentphoneFormControl.value),
            asiemail: this.getStringValue(this.assistentemailFormControl.value),
            latefees: this.getStringValue(this.value1FormControl.value),
            cleaning: this.getStringValue(this.value2FormControl.value),
            minor: this.getStringValue(this.value3FormControl.value),
            lost: this.getStringValue(this.value4FormControl.value),
            missing: this.getStringValue(this.value5FormControl.value),
            instagram: this.getStringValue(this.value6FormControl.value),
            id: 0,  // Example value
            accountid: this.getAccountID(),
            logoname: filename,
            deposite: "$250.00",
            facebook: this.getStringValue(this.value7FormControl.value),
            tiktok: this.getStringValue(this.value8FormControl.value),
            twitter: this.getStringValue(this.value9FormControl.value),
            website: this.getStringValue(this.value10FormControl.value),
            onlinestore: this.getStringValue(this.value11FormControl.value)
        };

        this.serviceservice.prcpstemplate(accountData).subscribe(response => {
            console.log(response);
            // Handle the response as needed
            if (response.Code === 200) {
                if (this.imageUrl.trim() !== '') {
                    this.uploadLogo(this.selectedFile!, this.getAccountID(),
                        (success: boolean) => {
                            if (success) {
                                this.messageService.add({
                                    key: 'tc',
                                    severity: 'success',
                                    summary: 'Success',
                                    detail: "Data saved",
                                    life: 5000,
                                });
                                // Image upload succeeded, do something
                            } else {
                                // Image upload failed, handle the error
                            }
                        })
                }
                // Success logic
            } else {
                // Error handling
            }
        }, error => {
            console.error("Error occurred:", error);
            // Handle the HTTP error
        });
    }

  onInfoButtonClick() {
    window.open('https://fm.welcomeapps.net/FAQ/Template-Settings.html', '_blank');
  }
}
