import { Component } from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";

@Component({
  selector: 'app-sendoutsamplelist1',
  templateUrl: './sendoutsamplelist1.component.html',
  styleUrls: ['./sendoutsamplelist1.component.scss']
})
export class Sendoutsamplelist1Component {
  constructor(public modalService: ModalserviceService) {

  }
}
