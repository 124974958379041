import { Component } from '@angular/core';
import { ModalserviceService } from '../../services/modalservice.service';

@Component({
  selector: 'app-editprofile',
  templateUrl: './editprofile.component.html',
  styleUrls: ['./editprofile.component.scss'],
})
export class EditprofileComponent {
  constructor(public modalService: ModalserviceService) {}
}
