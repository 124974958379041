import {
  AfterViewInit,
  ChangeDetectorRef,
  Component, ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import {ProgressBarComponent} from "smart-webcomponents-angular/progressbar";
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {
  GetLBAccepts,
  GetLBCImageList,
  Getlbdesignerlist,
  GetlbSentList,
  SelfData,
  transformImageUrl
} from "../../models/struct";
import {map, Observable, startWith, Subscription} from "rxjs";
import {ModalserviceService} from "../../services/modalservice.service";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ConfirmationService, ConfirmEventType, MessageService} from "primeng/api";
import {NgxImageCompressService} from "ngx-image-compress";
import { HttpClient } from "@angular/common/http";
import {DOCUMENT} from "@angular/common";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";

@Component({
  selector: 'app-createvcimages1dialog',
  templateUrl: './createvcimages1dialog.component.html',
  styleUrls: ['./createvcimages1dialog.component.scss']
})
export class Createvcimages1dialogComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('progressbar', {read: ProgressBarComponent, static: false}) progressbar!: ProgressBarComponent;
  formcaption: string = "Select Image From Look Books"
  desingerFormControl = new FormControl({value: '', disabled: false}, []);
  acceptFormControl = new FormControl({value: '', disabled: false}, []);
  progressbarshow: boolean = false;
  previewVisible: boolean = false;
  translationY: number = 0;
  zoomLevel: number = 1;
  previewUrl: string = '';
  dragging: boolean = false;
  startY: number = 0;
  operationType: number = 0
  catalogid: number = 0
  iImages: GetLBCImageList[] = [];
  deletedFiles: string[] = [];
  private subscription?: Subscription;
  private subscription1?: Subscription;
  showdialog: boolean = true;
  uploadbuttonenable: boolean = true
  filteredDesignerOptions?: Observable<string[]>;
  filteredAcceptedOptions?: Observable<string[]>;
  ilbSentResult: GetlbSentList[] = [];
  filteredResult: GetlbSentList[] = [];
  designerptions: string[] = [];
  acceptoptions: string[] = [];
  iLBAcceptsResult: GetLBAccepts[] = [];
  selectedImageFiles: {
    file: File | null;
    url: string;
    filename: string;
    lbcatalogid: number;
    colection: string;
    designer: string;
    catalog: string;
    catalogyear: number;
    seasoncode: string;
  }[] = [];
  originalSelectedImageFiles: {
    file: File | null;
    url: string;
    filename: string;
    lbcatalogid: number;
    colection: string;
    designer: string;
    catalog: string;
    catalogyear: number;
    seasoncode: string;
  }[] = [];
  iDesignerResult: Getlbdesignerlist[] = [{Code: 0, ErrorMessage: '', data: []}];
  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
        ? SelfData.account[0].data[0].id
        : SelfData.account[0].data[0].parentid;
  }

  constructor(
      public modalService: ModalserviceService,
      public fb: FormBuilder,
      private serviceservice: ServiceService,
      private yourappsService: YourappsserviceService,
      private _snackBar: MatSnackBar,
      private cdr: ChangeDetectorRef,
      private messageService: MessageService,
      private imageCompressService: NgxImageCompressService,
      private http: HttpClient,
      private confirmationService: ConfirmationService,
      private renderer: Renderer2, @Inject(DOCUMENT) private document: Document,
      private el: ElementRef
  ) {
    this.serviceservice
        .selectlbdesignerlist({accountid: this.getAccountID()})
        .subscribe((value) => {
          this.iDesignerResult[0] = value;
          SelfData.lbdesignerlist = this.iDesignerResult;
          this.filteredDesignerOptions = this.setDataAndFilter(
              this.desingerFormControl,
              value,
              this.designerptions,
              2
          );
        });

    this.serviceservice
        .selectlbsent({accountid: this.getAccountID()})
        .subscribe((value) => {
          this.ilbSentResult[0] = value;
          this.ilbSentResult[0].data = this.ilbSentResult[0].data.filter(item => item.accepted === 1);
          console.log("this.ilbSentResult", this.ilbSentResult)
          SelfData.lbsent = this.ilbSentResult;
          this.filteredAcceptedOptions = this.setAcceptDataAndFilter(
              this.acceptFormControl,
              value,
              this.acceptoptions,
              3
          );
        });
  }

  private setDataAndFilter(
      control: FormControl,
      response: any,
      options: string[],
      type: number
  ) {
    let results = response.data;
    for (let data of results) {
      options.push(data.designer);
    }
    return control.valueChanges.pipe(
        startWith(''),
        map((value) => this._filter(value || '', type))
    );
  }

  private setAcceptDataAndFilter(
      control: FormControl,
      response: any,
      options: string[],
      type: number
  ) {
    let results = response.data;
    for (let data of results) {
      options.push(data.name);
    }
    return control.valueChanges.pipe(
        startWith(''),
        map((value) => this._filter(value || '', type))
    );
  }

  private _filter(value: string, type: number): string[] {
    const filterValue = value.toLowerCase();
    switch (type) {
      case 2:
        return this.designerptions.filter((option) =>
            option.toLowerCase().includes(filterValue)
        );
      case 3:
        return this.acceptoptions.filter((option) =>
            option.toLowerCase().includes(filterValue)
        );
      default:
        return []; // default return for other cases
    }
  }

  onDesignerSelected(event: MatAutocompleteSelectedEvent): void {
    const selectedDesigner = event.option.value;
    const designerObj = this.iDesignerResult[0].data.find(d => d.designer === selectedDesigner);
    if (designerObj) {
      console.log('Selected Designer ID:', designerObj.desingerid);
      this.loadCustomImageList(designerObj.desingerid)
      // You can now use designerObj.designerid as needed
    } else {
      console.log('Designer not found');
    }
  }

  onAcceptedSelected(event: MatAutocompleteSelectedEvent): void {
    const selectedAccept = event.option.value;
    const acceptObj = this.ilbSentResult[0].data.find(d => d.name === selectedAccept);
    if (acceptObj) {
      const fileData = {
        lbmainid: 0,
        accounid: acceptObj.id,
        userid: 0,
        filename: "",
        filedir: "",
        lbcatalogid: 0,
        colection: "",
        designer: "",
        catalog: "",
        catalogyear: 0,
        ctrl: 2,
        key: acceptObj.key
      };
      this.serviceservice.selectlbaccepts({lbmainid: acceptObj.lbmainid, key: acceptObj.key})
          .subscribe((value) => {
            this.iLBAcceptsResult[0] = value
            SelfData.lbaccepts = this.iLBAcceptsResult;
            SelfData.tcustomimages = value

            this.selectedImageFiles = this.selectedImageFiles.filter(selectedFile =>
                this.iLBAcceptsResult[0].data.some(acceptResult => acceptResult.filename === selectedFile.filename)
            );
          })

      this.serviceservice.prcLbAccepted(fileData).subscribe({
        next: (value) => {

        },
        error: (error: any) => {
          console.error('Error occurred:', error);
        },
        complete: () => {

        }
      });

      // You can now use designerObj.designerid as needed
    } else {

    }
  }

  onInputChange(): void {
    const value = this.acceptFormControl.value;
    if (value === '') {
      this.selectedImageFiles = [...this.originalSelectedImageFiles];
    }
    // Additional logic for handling input changes can be added here
  }

  loadCustomImageList(designerid: number): void {
    this.selectedImageFiles = []
    this.serviceservice.selectcustomimages({accountid: this.getAccountID(), designerid: designerid})
        .subscribe({
          next: (value: any) => {

            if (value.data.length > 0) {
              value.data.forEach((imageinfo: any) => {
                const catalog = imageinfo.catalog
                const catalogyear = imageinfo.catalogyear
                const colection = imageinfo.colection
                const designer = imageinfo.designer
                const filedir = imageinfo.filedir
                const filename = imageinfo.filename
                const lbcatalogid = imageinfo.lbcatalogid
                const seasoncode = imageinfo.seasoncode
                this.selectedImageFiles.push({
                  file: null,
                  url: filedir,
                  filename: filename,
                  catalog: catalog,
                  catalogyear: catalogyear,
                  colection: colection,
                  designer: designer,
                  lbcatalogid: lbcatalogid,
                  seasoncode: seasoncode,
                });
              })
            } else {
              this.selectedImageFiles = []
            }
            this.originalSelectedImageFiles = [...this.selectedImageFiles];
            console.log("this.selectedImageFiles", this.selectedImageFiles)

          },
          error: (error) => {
            console.error('Error occurred during the subscription:', error);
          },
        });
  }

  showFullWindowImage(imageUrl: string): void {
    // Create the elements for the modal, image, and close button
    const modal = this.renderer.createElement('div');
    this.renderer.addClass(modal, 'preview-modal');

    const img = this.renderer.createElement('img');
    this.renderer.addClass(img, 'preview-image');
    this.renderer.setAttribute(img, 'src', imageUrl);
    this.renderer.appendChild(modal, img);

    const closeButton = this.renderer.createElement('button');
    this.renderer.addClass(closeButton, 'close-preview');
    closeButton.innerHTML = '&times;';  // or use an SVG/icon for the close button
    this.renderer.appendChild(modal, closeButton);

    // Append the modal to the body
    this.renderer.appendChild(this.document.body, modal);

    // Add a click listener to the close button to remove the modal
    this.renderer.listen(closeButton, 'click', (event) => {
      event.stopPropagation();  // Prevent the event from closing the modal immediately
      this.renderer.removeChild(this.document.body, modal);
    });


    // Optionally, add a click listener to the modal to close it if the user clicks outside the image
    // this.renderer.listen(modal, 'click', () => {
    //   this.renderer.removeChild(this.document.body, modal);
    // });

    // this.renderer.listen(img, 'click', () => {
    //   this.zoomScale = this.zoomScale === 1 ? 2 : 1; // Toggle between 1x and 2x zoom
    //   this.renderer.setStyle(img, 'transform', `scale(${this.zoomScale})`);
    //   this.renderer.setStyle(img, 'cursor', this.zoomScale === 1 ? 'zoom-in' : 'zoom-out');
    // });
  }

  updateImageTransform(): void {
    const img = document.querySelector('.overlay-content') as HTMLImageElement;
    img.style.transform = `scale(${this.zoomLevel}) translateY(${this.translationY}px)`;
  }

  hideFullWindowImage(): void {
    // ... existing code to hide the image
    // Reset zoom and translation
    this.zoomLevel = 1;
    this.translationY = 0;
    this.updateImageTransform();
  }

  dragImage(event: MouseEvent): void {
    if (this.dragging && this.zoomLevel > 1) {
      const diffY = event.clientY - this.startY;
      this.translationY += diffY;
      this.startY = event.clientY;
      this.updateImageTransform();
    }
  }

  stopDragging(event: MouseEvent): void {
    this.dragging = false;
    window.removeEventListener('mousemove', this.dragImage.bind(this));
    window.removeEventListener('mouseup', this.stopDragging.bind(this));
  }

  startDragging(event: MouseEvent): void {
    event.preventDefault();
    this.dragging = true;
    this.startY = event.clientY;

    // Listen for mouse move and mouse up events
    window.addEventListener('mousemove', this.dragImage.bind(this));
    window.addEventListener('mouseup', this.stopDragging.bind(this));
  }

  zoomImage(event: WheelEvent): void {
    event.preventDefault();
    const delta = Math.sign(event.deltaY);
    this.zoomLevel += delta * -0.1; // Adjust the scale step as needed
    this.zoomLevel = Math.max(1, this.zoomLevel); // Prevent zooming out below original size
    this.updateImageTransform();
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
  }

  onButtonCloseClick() {
    this.modalService.closevc1frame()
  }

  getFileAtIndex(index: number): {
    file: File | null;
    url: string;
    filename: string;
    lbcatalogid: number;
    colection: string;
    designer: string;
    catalog: string;
    catalogyear: number;
    seasoncode: string;
  } | null {
    // Check if the index is within the bounds of the array
    if (index < 0 || index >= this.selectedImageFiles.length) {
      console.error('Index out of bounds when trying to access selectedImageFiles');
      return null;
    }

    // Check if there is a file object
    const imageFileEntry = this.selectedImageFiles[index];
    if (!imageFileEntry) {
      console.error('No file found at the given index');
      return null;
    }

    // Return the entire file object
    return imageFileEntry;
  }

  SelectImageFile($event: MouseEvent, i: number) {
    this.confirmationService.confirm({
      message: `<strong>Select this photo?</strong>`,
      header: 'Select Image',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        let fileObject = this.getFileAtIndex(i);
        if (fileObject) {
          console.log("fileObject", fileObject);
          this.yourappsService.returnSelectedFiles([fileObject], 1);
          this.modalService.closevc1frame();
        }
      },
      reject: (type: any) => {
        if (type == ConfirmEventType.REJECT) {

        } else if (type == ConfirmEventType.CANCEL) {
        }
      }

    });
    setTimeout(() => {
      this.applyLocalRestoreStyles();
    }, 0);
  }

  private applyLocalRestoreStyles() {
    setTimeout(() => {
      const messageElements = document.querySelectorAll('.p-confirm-dialog-message');
      messageElements.forEach((element) => {
        element.setAttribute('style', 'font-size: 16px; color: #343a40;');
      });
    }, 0);

    const iconElements = document.querySelectorAll('.p-confirm-dialog-icon');
    iconElements.forEach((element) => {
      element.setAttribute('style', 'color: #343a40');
    });
  }

  protected readonly transformImageUrl = transformImageUrl;
}
