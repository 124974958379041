import {AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit, Renderer2} from '@angular/core';
import {SelfData, SetSampleListData} from "../../models/struct";
import {DOCUMENT} from "@angular/common";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MessageService} from "primeng/api";
import {ServiceService} from "../../services/service.service";
import {HashService} from "../../services/hash.service";
import { HttpClient } from "@angular/common/http";
import {Router} from "@angular/router";
import {DeviceDetectorService} from "ngx-device-detector";
import {ModalserviceService} from "../../services/modalservice.service";

@Component({
  selector: 'app-latelist',
  templateUrl: './latelist.component.html',
  styleUrls: ['./latelist.component.scss']
})
export class LatelistComponent implements OnInit, AfterViewInit {
  deviceClass: string = "";
  deviceClassAddress: string = "";
  imageUrl: string = '';
  printerpage: boolean = false
  isLandscape: boolean = false;
  textvalue: string = ""
  signatureImg: string = "";
  selectedsample: SetSampleListData[] = [];
  isDesktopDevice: boolean = this.deviceService.isDesktop();
  constructor(private renderer: Renderer2, @Inject(DOCUMENT) private document: Document,
              private yourappsService: YourappsserviceService,
              private messageService: MessageService,
              private serviceservice: ServiceService,
              private hashService: HashService,
              private http: HttpClient,
              private router: Router,
              private changeDetector: ChangeDetectorRef,
              private deviceService: DeviceDetectorService, public modalService: ModalserviceService) {
    this.setDeviceClass();

  }
  setDeviceClass(): void {
    this.deviceClass = this.deviceService.isMobile() ? 'mobilemaincard' : 'pcmaincard';
    this.deviceClassAddress = this.deviceService.isMobile() ? 'mobileaddressdiv' : 'pcaddressdiv';
    // this.isLandscape = window.innerWidth > window.innerHeight ? 'islandscape' : 'isnlandscape';
  }

  setOrientation() {
    this.isLandscape = window.innerWidth > window.innerHeight;
  }
  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    this.renderer.setStyle(this.document.body, 'background-color', 'rgba(150,150,150,0.5)');
    this.setOrientation();
    window.addEventListener('resize', this.setOrientation.bind(this))

    this.yourappsService.reloadRequestList.subscribe((value) => {
      if (SelfData && SelfData.latelist && SelfData.latelist.length > 0 && SelfData.latelist[0].data && SelfData.latelist[0].data.length > 0) {
        for (const item of SelfData.latelist[0].data) {
          console.log("item[\"id\"]", item["id"])
          const newSample: SetSampleListData = {
            "ID": item["id"],
            "Inventory Status": item["Inventory Status"],
            "Gender": item["Gender"],
            "Sample Workflow Status": item["Sample Workflow Status"],
            "Account ID": item["accountid"],
            "Region": item["Region"],
            "Notes": item["Notes"],
            "Designer": item["Designer"],
            "Fabric": item["Fabric"],
            "Manufacturer": item["Manufacturer"],
            "Collection": item["Collection"],
            "Style": item["Style"],
            "Looks Associated": item["Looks Associated"],
            "Season": item["Season"],
            "Misc": item["Misc"],
            "Quantity": item["Quantity"],
            "Type": item["Type"],
            "Size": item["Size"],
            "Size Range": item["Size Range"],
            "Pattern": item["Pattern"],
            "Color": item["Color"],
            "Color Code": item["colorcode"],
            "Retail Price EUR": item["Retail Price EUR"],
            "Retail Price USD": item["Retail Price USD"],
            "Wholesale EUR": item["Wholesale EUR"],
            "Wholesale USD": item["Wholesale USD"],
            "Sale Price EUR": item["Sale Price EUR"],
            "Sale Price USD": item["Sale Price USD"],
            "Prices ID": item["pricesid"],
            "Description": item["Description"],
            "Storage ID": item["storageid"],
            "Registration Date": item["regdate"],
            "Current Date": item["curdate"],
            "User ID": item["userid"],
            "Is Active": item["isactiviti"],
            "Image Link": item["Image Link"],
            "barcode": item["barcode"],
            "User": item["user"],
            "Barcode Prefix": item["barcodeprefix"],
            "celebriti": item["celebriti"],
            "contactid": item["contactid"],
            "Selected Sample ID": item["selectedsampleid"],
            "itemcount": item["ItemCount"] || 1,
            "sendoutid": item["sendoutid"] || 0,
          };
          this.selectedsample.push(newSample);
        }
        console.log("this.selectedsample", this.selectedsample)
      }
    })
  }

  booleanToInt(value: boolean | undefined | null): number {
    return value ? 1 : 0;
  }

}
