import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder} from "@angular/forms";
import {HashService} from "../../services/hash.service";
import {MessageboxComponent} from "../../modals/messagebox/messagebox.component";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {ConfirmationService, MessageService} from "primeng/api";
import {MatSnackBar} from "@angular/material/snack-bar";
import {NzNotificationService} from "ng-zorro-antd/notification";

@Component({
  selector: 'app-emailsigndialog',
  templateUrl: './emailsigndialog.component.html',
  styleUrl: './emailsigndialog.component.scss'
})
export class EmailsigndialogComponent implements OnInit, AfterViewInit {
  tags = ["Selected Emails"];
  inputVisible = false;
  inputValue = '';
  @ViewChild('inputElement', {static: false}) inputElement?: ElementRef;

  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private hashService: HashService,
    public messageBox: MessageboxComponent,
    private serviceservice: ServiceService,
    private yourappsService: YourappsserviceService,
    private confirmationService: ConfirmationService,
    private _snackBar: MatSnackBar,
    private notification: NzNotificationService,
    private messageService: MessageService
  ) {

  }

  handleClose(removedTag: {}): void {
    this.tags = this.tags.filter(tag => tag !== removedTag);
  }

  sliceTagName(tag: string): string {
    const isLongTag = tag.length > 20;
    return isLongTag ? `${tag.slice(0, 20)}...` : tag;
  }

  showInput(): void {
    this.inputVisible = true;
    setTimeout(() => {
      this.inputElement?.nativeElement.focus();
    }, 10);
  }

  handleInputConfirm(): void {
    if (this.inputValue && this.tags.indexOf(this.inputValue) === -1) {
      this.tags = [...this.tags, this.inputValue];
    }
    this.inputValue = '';
    this.inputVisible = false;
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
  }

  onOKClick() {
    // Создаем массив объектов с пустыми значениями по заданной структуре, начиная с индекса 1
    const emptyArray = [];

    for (let i = 1; i < this.tags.length; i++) {
      emptyArray.push({
        "Contact Name": "",
        "Phone Number": "",
        "email": this.tags[i], // Заполняем email из массива tags, начиная с индекса 1
        "Has Email": 1,
        "Job Title": "",
        "Category": "",
        "Job Department": "",
        "accountid": 0,
        "id": 0
      });
    }

    // Теперь emptyArray содержит только нужные записи
    console.log(emptyArray);
    emptyArray.forEach((product: any) => {
      console.log(product)
      this.yourappsService.setSelectedEmail(product, 1);
    });
    this.modalService.closeEmailSign()
  }
}
