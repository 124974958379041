<p-toast *ngIf="!isDesktopDevice" position="bottom-center" key="bc" [style]="{ width: '90vw' }"></p-toast>
<p-toast *ngIf="isDesktopDevice" position="bottom-center" key="bc" [style]="{ width: '30vw' }"></p-toast>
<div class="bodydiv">
    <mat-card [ngClass]="deviceClass">
        <mat-card-content>
            <div class="card">
                <p-table
                        [value]="selectedsample"
                        [scrollHeight]="'calc(100vh - 65px)'"
                        [styleClass]="'p-datatable-striped p-datatable-sm'"
                        [tableStyle]="{'min-width': '50rem'}">
                    <ng-template pTemplate="header">
                        <tr>
                            <!--                    -->
                            <th style="width:17.6%">Designer<br>Collection<br>Season</th>
                            <th style="width:17.6%">Type<br>Barcode<br>Color</th>
                            <th style="width:17.6%">Gender<br>Item Count<br>Region</th>
                            <th style="width:17.6%">Description</th>
                            <th style="width:7%">Image</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-product>
                        <tr [ngStyle]="{'background-color': (product['isok'] === 0 || product['itemstatus'] === 0) ? '#ffdddd' : ''}"
                                [pSelectableRow]="product">
                            <td>{{product["Designer"]}}<br>{{product["Collection"]}}<br>{{product["Season"]}}</td>
                            <td>{{product["Type"]}}<br>{{product["barcode"]}}<br>{{product["Color"]}}</td>
                            <td>{{product["Gender"]}}<br>
                                {{product['itemcount']}}
                                <br>{{product["Region"]}}</td>
                            <td>{{product["Description"]}}</td>
                            <td><img [src]="product['Image Link']" alt="Image" class="image-size-80x80 shadow-4"></td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </mat-card-content>
        <div class="feshendiv">
            <a href="http://www.fashion-m.com" target="_blank" class="full-width-link">
                <div class="left-text-qr">
                    fashion-m.com
                </div>
            </a>
        </div>
    </mat-card>
</div>
