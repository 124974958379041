import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder} from "@angular/forms";
import {HashService} from "../../services/hash.service";
import {MessageboxComponent} from "../../modals/messagebox/messagebox.component";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ConfirmationService, ConfirmEventType, MessageService} from "primeng/api";
import {EmailEditorComponent} from "angular-email-editor";
import sample from "../../top/main-menu/sample.json";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {emailaccount, ProcessEmailFields, SelfData} from "../../models/struct";
import {Subscription} from "rxjs";
import {Table} from "primeng/table";

@Component({
  selector: 'app-emaileditordialog',
  templateUrl: './emaileditordialog.component.html',
  styleUrl: './emaileditordialog.component.scss'
})
export class EmaileditordialogComponent implements OnInit, AfterViewInit {
  selectedIndex = 0;
  subscription?: Subscription;
  showdialog: boolean = false;
  selectedemails: emailaccount[] = [];
  previewHtml = '';
  themeValue: 'modern_light' | 'classic_light' | 'classic_dark' | 'modern_dark' | 'light' | 'dark' = 'modern_light';

  options = {
    appearance: {
      theme: this.themeValue,
      branding: false,
    },
    version: 'latest',
  };

  scriptUrl = 'https://editor.unlayer.com/embed.js?2';

  @ViewChild('editor')
  private emailEditor!: EmailEditorComponent;
  @ViewChild('dt1') dt1!: Table;
  @ViewChild('previewDiv') previewDiv!: ElementRef<HTMLIFrameElement>;
  filtertext: string = ""
  subjectstring: string = "";
  fromnamestring: string = "";
  fromemailstring: string = "";
  replytoemail: string = "";
  internalnotes: string = "";
  tabsdisabled: boolean = true

  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private hashService: HashService,
    public messageBox: MessageboxComponent,
    private serviceservice: ServiceService,
    private yourappsService: YourappsserviceService,
    private _snackBar: MatSnackBar,
    private notification: NzNotificationService,
    private cdr: ChangeDetectorRef,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
  ) {
  }

  onInput(event: any) {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;
    this.dt1.filterGlobal(value, 'contains');
  }

  clear(table: Table) {
    table.clear();
    this.filtertext = ""
    // this.emailcount = SelfData.emailcontacttlist[0].data.length
  }

  editorLoaded($event: any) {
    console.log('editorLoaded');
    this.emailEditor.editor.loadDesign(sample);
  }

  editorReady($event: any) {
    console.log('Editor ready');
  }

  saveDesign() {
    this.emailEditor.editor.saveDesign((data: any) =>
      console.log('saveDesign', data)
    );
  }

  checkFormFields(): void {
    this.tabsdisabled = !(this.subjectstring.trim() !== '' &&
      this.fromnamestring.trim() !== '' &&
      this.fromemailstring.trim() !== '' &&
      this.replytoemail.trim() !== '');
  }

  exportHtml() {
    this.emailEditor.editor.exportHtml((data) => {
      console.log('exportHtml', data);
      // data содержит две части: "html" и "design", мы используем "html"
      const htmlContent = data.html;

      // Создаем Blob, представляющий HTML-файл
      const blob = new Blob([htmlContent], {type: 'text/html'});

      // Создаем ссылку для скачивания
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = 'email_design.html';  // Имя файла для скачивания
      document.body.appendChild(a);  // Добавляем ссылку на страницу, чтобы она была кликабельной
      a.click();  // Симулируем клик по ссылке, чтобы инициировать скачивание
      document.body.removeChild(a);  // Удаляем ссылку после скачивания
    });
  }

  goToNextTab(): void {
    if (this.selectedIndex < 4) { // Убедитесь, что индекс не превышает количество вкладок
      this.selectedIndex++;
    }
  }

  goToBackTab(): void {
    if (this.selectedIndex != 0) { // Убедитесь, что индекс не превышает количество вкладок
      this.selectedIndex--;
    }
  }

  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
      ? SelfData.account[0].data[0].id
      : SelfData.account[0].data[0].parentid;
  }

  goToSend(): void {
    // Закрытие формы перед выполнением API вызова
    this.modalService.closeEmailEditor();

    const emailFields: ProcessEmailFields = {
      ctrl: 0,
      id: 0,
      FromName: this.fromnamestring,
      FromEmail: this.fromemailstring,
      ReplyTo: this.replytoemail,
      selectedemails: this.selectedemails?.[0]?.data,
      sendertype: 'Admin',
      email: this.emailbody,
      subject: this.subjectstring,
      accountid: this.getAccountID(),
      notes: this.internalnotes,
      isactivity: true,
      issent: false
    };

    // Выполнение API вызова
    this.serviceservice.processEmail(emailFields).subscribe(
      (response) => {
        if (response.success) {
          console.log('Email processing completed successfully. Return Value:', response.returnValue);
        } else {
          console.error('Error in email processing:', response.error);
        }
      },
      (error) => {
        console.error('API call failed:', error);
      }
    );
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const iframe = document.querySelector('iframe[src*="unlayer"]') as HTMLIFrameElement;
      if (iframe) {
        iframe.onload = () => {
          const observer = new MutationObserver((mutations) => {
            try {
              const brandingElement = iframe.contentWindow?.document.querySelector('.blockbuilder-branding') as HTMLElement;
              if (brandingElement) {
                brandingElement.style.display = 'none';
                console.log('Элемент брендинга успешно скрыт');
              }
            } catch (e) {
              console.error('Не удалось получить доступ к iframe из-за политики безопасности (CORS)', e);
            }
          });

          observer.observe(iframe, {childList: true, subtree: true});
        };
      } else {
        console.log('Не удалось найти iframe с редактором Unlayer');
      }
    }, 2000);

  }

  selectedFiles: File[] = [];
  currentSize: string = '0 b';

  uploadFiles() {
    let fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.multiple = true;
    fileInput.accept = '*.*'; // Accept Microsoft Office file formats
    fileInput.addEventListener('change', (event) => {
      const files: FileList | null = (event.target as HTMLInputElement).files;
      if (files) {
        let newFiles = Array.from(files);
        let newSize = this.selectedFiles.reduce((sum, file) => sum + file.size, 0) + newFiles.reduce((sum, file) => sum + file.size, 0);
        if (newSize > 5 * 1024 * 1024) {
          // alert('Total file size exceeds the 5 Mb limit. Please remove some files.');
          this.notification.error(
            '<strong class="notification-error">File Size</strong>',
            '<strong class="notification-error">Total file size exceeds the 5 Mb limit. Please remove some files.</strong>'
          );
        } else {
          this.selectedFiles = [...this.selectedFiles, ...newFiles];
          this.currentSize = this.formatSize(newSize);
        }
      }
    });
    fileInput.click();
  }

  deleteFile(event: MouseEvent, index: number) {
    event.stopPropagation();
    this.selectedFiles.splice(index, 1);
    this.calculateCurrentSize();
  }

  // Метод для вычисления общего размера файлов
  calculateCurrentSize() {
    let totalSize = 0;
    this.selectedFiles.forEach((file) => {
      totalSize += file.size;
    });
    this.currentSize = this.formatSize(totalSize);
  }

// Метод для форматирования размера файла в более удобный вид
  formatSize(size: number): string {
    if (size < 1024) {
      return `${size} b`;
    } else if (size < 1024 * 1024) {
      return `${(size / 1024).toFixed(2)} Kb`;
    } else {
      return `${(size / (1024 * 1024)).toFixed(2)} Mb`;
    }
  }

  ngOnInit(): void {
  }

  protected readonly SelfData = SelfData;

  onEmailContactsClick() {
    this.yourappsService.setSelectedEmail(null, 0)
    this.subscription = this.yourappsService.selectedemails$.subscribe((value: { data: any, type: number }) => {
      if (value.type >= 1) {
        console.log(value)
        // Проверяем, существует ли уже первый элемент в selectedemails
        if (!this.selectedemails[0]) {
          // Если его нет, создаем его
          this.selectedemails[0] = {
            Code: 0, // или любое значение по умолчанию, которое вам подходит
            ErrorMessage: '',
            data: []
          };
        }

        // Проверяем, существует ли уже запись с такими данными в selectedemails[0].data
        const existingItem = this.selectedemails[0].data.find((item: any) => item.id === value.data.id);

        if (!existingItem) {
          // Если запись не найдена, добавляем ее
          if (value.type === 1) {
            this.messageService.add({
              key: 'tc',
              severity: 'success',
              summary: 'Information',
              // detail: `Sample ${barcodeValue} already scanned by user ${foundData['user']} for a new delivery or reservation.`,
              detail: `Email ${value.data.email} added to the list`,
              life: 1000,
            });
          }
          this.selectedemails[0].data.push(value.data);
        } else {
          // Если запись уже существует, выводим сообщение
          // if (value.type === 1) {
          //   this.messageService.add({
          //     key: 'tc',
          //     severity: 'error',
          //     summary: 'Information',
          //     // detail: `Sample ${barcodeValue} already scanned by user ${foundData['user']} for a new delivery or reservation.`,
          //     //Email GioQveladze@yahoo.com is already added to the list
          //     detail: `Email ${value.data.email} is already added to the list`,
          //     life: 1000,
          //   });
          // }
          // console.log("Запись с таким id уже существует:", value.data.id);
        }
      }
    });
    this.modalService.openEmailContacts()
  }

  onEmailSignClick() {
    this.yourappsService.setSelectedEmail(null, 0);
    this.subscription = this.yourappsService.selectedemails$.subscribe((value: { data: any, type: number }) => {
      if (value.type >= 1) {
        // Проверяем, существует ли уже первый элемент в selectedemails
        if (!this.selectedemails[0]) {
          this.selectedemails[0] = {
            Code: 0,
            ErrorMessage: '',
            data: []
          };
        }

        // Проверяем, существует ли уже запись с таким же email в selectedemails[0].data
        if (value.data) {
          const existingItem = this.selectedemails[0].data.find((item: any) => item.email === value.data.email);

          if (!existingItem) {
            this.messageService.add({
              key: 'tc',
              severity: 'success',
              summary: 'Information',
              // detail: `Sample ${barcodeValue} already scanned by user ${foundData['user']} for a new delivery or reservation.`,
              detail: `Email ${value.data.email} added to the list`,
              life: 1000,
            });
            this.selectedemails[0].data.push(value.data);
          } else {
            // this.messageService.add({
            //   key: 'tc',
            //   severity: 'error',
            //   summary: 'Information',
            //   // detail: `Sample ${barcodeValue} already scanned by user ${foundData['user']} for a new delivery or reservation.`,
            //   //Email GioQveladze@yahoo.com is already added to the list
            //   detail: `Email ${value.data.email} is already added to the list`,
            //   life: 1000,
            // });
          }
        }
      }
    });
    this.modalService.openEmailSign();
  }

  removeProduct(product: any): void {
    this.showdialog = true
    this.cdr.detectChanges()
    this.confirmationService.confirm({
      message: `<strong>Do you want to remove ${product["email"]} from the list?</strong>`,
      header: 'Information',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.showdialog = true
        this.cdr.detectChanges()
        if (this.selectedemails[0] && this.selectedemails[0].data) {
          // Находим индекс элемента, который нужно удалить
          const index = this.selectedemails[0].data.findIndex((item: any) => item.id === product.id);

          // Если элемент найден (index больше или равен 0), удаляем его
          if (index >= 0) {
            this.selectedemails[0].data.splice(index, 1);
          }
        }
        this.showdialog = false
        this.cdr.detectChanges()
      },
      reject: (type: any) => {
        if (type === ConfirmEventType.REJECT) {
          this.showdialog = false
          this.cdr.detectChanges()
          // Действие при отказе
        } else if (type === ConfirmEventType.CANCEL) {
          this.showdialog = false
          this.cdr.detectChanges()
          // Действие при отмене
        }
      }
    });
  }

  loadFile(event: any) {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        const htmlContent = e.target.result;

        const iframe = document.querySelector('#previewIframe') as HTMLIFrameElement;
        if (iframe && iframe.contentDocument) {
          iframe.contentDocument.open();
          iframe.contentDocument.write(htmlContent);
          iframe.contentDocument.close();
        }
      };

      reader.readAsText(file);
    }
  }

  emailbody: string = ""

  onTabChange(index: number): void {
    this.selectedIndex = index;
    if (index !== 1) {
      this.emailEditor.editor.exportHtml((data: any) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(data.html, 'text/html');

        // Извлекаем содержимое <body>
        const bodyContent = doc.body.innerHTML;

        // Извлекаем стили из <head> и добавляем их в div
        const styles = doc.head.querySelectorAll('style, link[rel="stylesheet"]');
        let styleContent = '';
        styles.forEach((style) => {
          styleContent += style.outerHTML;
        });

        // Добавляем стили и содержимое в previewDiv
        if (this.previewDiv?.nativeElement) {
          console.log(`${styleContent} ${bodyContent}`)
          this.emailbody = `${styleContent} ${bodyContent}`
          this.previewDiv.nativeElement.innerHTML = `${styleContent} ${bodyContent}`;
        }
      });
    }
  }

  goToSchedule() {
    this.modalService.openschedule()
  }
}
