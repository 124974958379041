<!--<form [formGroup]="myForm" (ngSubmit)="submit()" autocomplete="off">-->
<p class="sample-manager-text">Edit</p>
<button
  mat-icon-button
  class="close-btn"
  (click)="modalService.closeFieldsEditor()"
>
  <mat-icon>close</mat-icon>
</button>
<div class="black-background1">
  <mat-form-field class="full-width">
    <mat-label>Field Label</mat-label>
    <input matInput type="text" [formControl]="myControl" />
    <button
      *ngIf="myControl.value"
      matSuffix
      mat-icon-button
      aria-label="Clear"
      (click)="clearValue()"
    >
      <mat-icon>close</mat-icon>
    </button>
    <mat-error *ngIf="myControl.hasError('required')">
      Field Label is <strong>required</strong>
    </mat-error>
  </mat-form-field>
</div>

<div class="black-background2">
  <mat-form-field class="full-width" appearance="fill">
    <mat-label>Field Type</mat-label>
    <mat-select
      [formControl]="ftControl"
      (selectionChange)="onSelectionChange($event)"
    >
      <mat-option *ngFor="let ft of fieldTypes" [value]="ft.viewValue">
        {{ ft.viewValue }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="ftControl.hasError('required')">
      Field Type is <strong>required</strong>
    </mat-error>
  </mat-form-field>
</div>

<div class="black-background3">
  <div class="center-container">
    <mat-form-field class="full-width">
      <mat-label>Description</mat-label>
      <textarea
        matInput
        class="no-resize"
        [formControl]="descriptionControl"
      ></textarea>
    </mat-form-field>
  </div>
</div>

<div class="black-background4">
  <section class="example-section">
    <mat-checkbox
      class="example-margin"
      [(ngModel)]="checked"
      [disabled]="requeredisDisabled"
      >Requered</mat-checkbox
    >
    <mat-checkbox
      class="example-margin"
      [(ngModel)]="indeterminate"
      [disabled]="isDisabled"
      (change)="onCheckboxChange($event)"
      >Is Float</mat-checkbox
    >
  </section>
</div>

<div class="black-background5">
  <div class="flex-container">
    <mat-form-field class="selector-full-width" appearance="fill">
      <mat-label>Minvalue</mat-label>
      <input matInput type="number" min="0" [formControl]="minControl" />
      <mat-error *ngIf="minControl.hasError('required')">
        Quantity cannot be less than <strong>0</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="selector-full-width" appearance="fill">
      <mat-label>Max value</mat-label>
      <input matInput type="number" min="0" [formControl]="maxControl" />
      <mat-error *ngIf="maxControl.hasError('required')">
        Quantity cannot be less than <strong>0</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="selector-full-width" appearance="fill">
      <mat-label>Step</mat-label>
      <input
        matInput
        type="number"
        step="0.01"
        min="0"
        [formControl]="stepControl"
      />
      <mat-error *ngIf="stepControl.hasError('required')">
        <strong>Incorrect value</strong>
      </mat-error>
    </mat-form-field>
  </div>
</div>

<div class="button-containerA">
  <button
    mat-raised-button
    class="button-settings"
    type="button"
    (click)="modalService.closeFieldsEditor()"
  >
    Cancel
  </button>
  <button
    mat-raised-button
    class="button-settings"
    type="submit"
    (click)="submit()"
  >
    OK
  </button>
</div>
<!--</form>-->
