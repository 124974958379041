import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ModalserviceService } from '../../services/modalservice.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { HashService } from '../../services/hash.service';
import { MessageboxComponent } from '../../modals/messagebox/messagebox.component';
import { ServiceService } from '../../services/service.service';
import { YourappsserviceService } from '../../services/yourappsservice.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-editlabeldialog',
  templateUrl: './editlabeldialog.component.html',
  styleUrls: ['./editlabeldialog.component.scss'],
})
export class EditlabeldialogComponent implements OnInit, AfterViewInit {
  myForm: FormGroup = new FormGroup({});

  attnContro = new FormControl<string>('');
  careofContro = new FormControl<string>('');
  companyname = new FormControl<string>('');
  addressaddress1 = new FormControl<string>('');
  addressaddress2 = new FormControl<string>('');
  mobile = new FormControl<string>('');
  email = new FormControl<string>('');
  taxid = new FormControl<string>('');
  dataArray: any[] = [];
  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private hashService: HashService,
    public messageBox: MessageboxComponent,
    private serviceservice: ServiceService,
    private yourappsService: YourappsserviceService,
    private _snackBar: MatSnackBar
  ) {}
  ngAfterViewInit(): void {}

  ngOnInit(): void {
    this.yourappsService.setaddreslabel$.subscribe((value: { data: any, type: number }) => {
      if (value.data) {
        if (value.type === 1) {
          console.log(value.data)
          const firstname = value?.data?.firstname || '';
          this.attnContro.setValue(value.data[0]['firstname']+" "+value.data[0]['lastname']);
          this.careofContro.setValue(value.data[0]['companycareof']);
          this.companyname.setValue(value.data[0]['companyname']);
          this.addressaddress1.setValue(value.data[0]['addressaddress1']);
          this.addressaddress2.setValue(value.data[0]['addresscity']+" "+value.data[0]['addressstate']+" "+value.data[0]['addresszipcode']+" "+value.data[0]['addresscountry']);
          this.mobile.setValue(value.data[0]['mobile']);
          this.email.setValue(value.data[0]['email']);
          this.taxid.setValue(value.data[0]['taxid']);
          // this.attnContro.setValue(value.data['firstname'])
        }
      }
    })
  }

  submit() {
    this.dataArray = [
      {
        fullname: this.attnContro.value, // Имя + фамилия
        careof: this.careofContro.value, // Компания
        companyname: this.companyname.value, // Название компании
        address1: this.addressaddress1.value, // Адрес 1
        address2: this.addressaddress2.value, // Адрес 2
        mobile: this.mobile.value, // Мобильный телефон
        email: this.email.value, // Email
        taxid: this.taxid.value, // Налоговый ID
      },
    ];
    this.yourappsService.returnAddressLabel(this.dataArray, 1)
    this.modalService.closeEditLabel()
  }
}
