import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {SelfData, TagsListData, transformImageUrl} from "../../models/struct";
import {ModalserviceService} from "../../services/modalservice.service";
import {HashService} from "../../services/hash.service";
import {MessageboxComponent} from "../../modals/messagebox/messagebox.component";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {HttpClient} from "@angular/common/http";
import {Table} from "primeng/table";
import {forkJoin, Subscription} from "rxjs";

@Component({
  selector: 'app-sc',
  templateUrl: './sc.component.html',
  styleUrl: './sc.component.scss'
})
export class ScComponent implements OnInit {
  @ViewChild('dt1') dt1!: Table;
  filtertext: string = ""
  sizes!: any[];
  @ViewChild(Table) dataTable?: Table;
  selectedSize: any = ''
  iOrderInResult: TagsListData[] = []
  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
      ? SelfData.account[0].data[0].id
      : SelfData.account[0].data[0].parentid;
  }

  constructor(public modalService: ModalserviceService,
              private hashService: HashService,
              public messageBox: MessageboxComponent,
              private serviceservice: ServiceService,
              private yourappsService: YourappsserviceService,
              private _snackBar: MatSnackBar,
              private http: HttpClient,
              private cdr: ChangeDetectorRef) {
    this.sizes = [
      {name: 'Small', class: 'p-datatable-sm'}
    ];
    this.selectedSize = this.sizes[0]
    setTimeout(() => {
      SelfData.spinnerpossition = true
    });
    forkJoin({
      oerderin: this.serviceservice.selectOrderin({accountid: this.getAccountID()}),
    }).subscribe(({ oerderin }) => {
      this.iOrderInResult = oerderin.data;
      console.log("this.iOutletsResult", this.iOrderInResult)
      setTimeout(() => {
        SelfData.spinnerpossition = false
      });
    });
  }

  ngOnInit(): void {
  }

  protected readonly transformImageUrl = transformImageUrl;

  private subscription?: Subscription;

  editProduct(product: any) {
    this.yourappsService.setSelectedOrderInList(product, 1)
    this.yourappsService.getReloadOrderInList([], 0)
    this.subscription = this.yourappsService.reloadOrderInList$.subscribe((value) => {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      if (value.type === 1) {
        forkJoin({
          outlets: this.serviceservice.selectOrderin({accountid: this.getAccountID()}),
        }).subscribe(({ outlets }) => {
          this.iOrderInResult = outlets.data;
        });
      }
    })
    this.modalService.scFrameOpen()
  }

  deleteProduct(product: any) {

  }

  onAddProductClick() {
    this.yourappsService.setSelectedOrderInList([], 0)
    this.yourappsService.getReloadOrderInList([], 0)
    this.subscription = this.yourappsService.reloadOrderInList$.subscribe((value) => {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      if (value.type === 1) {
        forkJoin({
          outlets: this.serviceservice.selectOrderin({accountid: this.getAccountID()}),
        }).subscribe(({ outlets }) => {
          this.iOrderInResult = outlets.data;
        });
      }
    })
    this.modalService.scFrameOpen()
  }

  onInput(event: any) {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;
    this.dt1.filterGlobal(value, 'contains');
  }

  clear(table: Table) {
    table.clear();
    this.filtertext = ""
  }

  protected readonly SelfData = SelfData;
}
