import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import {CarouselComponent} from "smart-webcomponents-angular/carousel";
import {DOCUMENT} from "@angular/common";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {ConfirmationService, MessageService} from "primeng/api";
import {ServiceService} from "../../services/service.service";
import {HashService} from "../../services/hash.service";
import { HttpClient } from "@angular/common/http";
import {Router} from "@angular/router";
import {DeviceDetectorService} from "ngx-device-detector";
import {ModalserviceService} from "../../services/modalservice.service";
import {SelfData} from "../../models/struct";

@Component({
  selector: 'app-acceptedimages',
  templateUrl: './acceptedimages.component.html',
  styleUrls: ['./acceptedimages.component.scss']
})
export class AcceptedimagesComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('carousel', {read: CarouselComponent, static: false}) carousel!: CarouselComponent;
  isDesktopDevice: boolean = this.deviceService.isDesktop();
  isVisibled: boolean = false;
  isChecked: boolean = false;
  isDisabled = false;
  deviceClass: string = "";
  footerClass: string = "";
  deviceClassAddress: string = "";
  isLandscape: boolean = false;
  selectedImageFiles: {
    file: File | null;
    url: string;
    filename: string;
    lbcatalogid: number;
    colection: string;
    designer: string;
    catalog: string;
    catalogyear: number;
    seasoncode: string;
  }[] = [];

  constructor(private renderer: Renderer2, @Inject(DOCUMENT) private document: Document,
              private yourappsService: YourappsserviceService,
              private messageService: MessageService,
              private serviceservice: ServiceService,
              private hashService: HashService,
              private http: HttpClient,
              private router: Router,
              private cdr: ChangeDetectorRef,
              private confirmationService: ConfirmationService,
              private deviceService: DeviceDetectorService, public modalService: ModalserviceService) {
    this.setDeviceClass();
    this.loadImageList(SelfData.tlbcatalogid)

  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
  }

  setDeviceClass(): void {
    this.deviceClass = this.deviceService.isMobile() ? 'mobilemaincard' : 'pcmaincard';
    this.footerClass = this.deviceService.isMobile() ? 'mobilebutton-container-dm-ps' : 'pcbutton-container-dm-ps';
    this.deviceClassAddress = this.deviceService.isMobile() ? 'mobileaddressdiv' : 'pcaddressdiv';
    // this.isLandscape = window.innerWidth > window.innerHeight ? 'islandscape' : 'isnlandscape';
  }

  private loadImageList(tlbcatalogid: number) {
    this.selectedImageFiles = []
    if (SelfData.tcustomimages.data && SelfData.tcustomimages.data.length > 0) {
      SelfData.tcustomimages.data.forEach((imageinfo: any) => {
        const catalog = imageinfo.catalog
        const catalogyear = imageinfo.catalogyear
        const colection = imageinfo.colection
        const designer = imageinfo.designer
        const filedir = imageinfo.filedir
        const filename = imageinfo.filename
        const lbcatalogid = imageinfo.lbcatalogid
        const seasoncode = imageinfo.seasoncode
        this.selectedImageFiles.push({
          file: null,
          url: filedir,
          filename: filename,
          catalog: catalog,
          catalogyear: catalogyear,
          colection: colection,
          designer: designer,
          lbcatalogid: lbcatalogid,
          seasoncode: seasoncode
        });
      })
      console.log("this.selectedImageFiles", this.selectedImageFiles)
      setTimeout(() => {
        this.init(this.selectedImageFiles.length);
      });
    }
  }


  init(imagecount: number): void {
    // init code.
    const that = this,
        basePath = './../../../src/images/';
    if (this.carousel) {
      this.carousel.dataSource = generateDataSource(imagecount, this.selectedImageFiles);
    } else {
      console.error("Carousel is undefined or null");
    }

    function generateDataSource(items: number, selectedImageFiles: {
      file: File | null;
      url: string;
      filename: string;
      lbcatalogid: number;
      colection: string;
      designer: string;
      catalog: string;
      catalogyear: number;
      seasoncode: string
    }[]) {
      return Array(items).fill({}).map((element, index) => {
        // Use the image from selectedImageFiles if available; otherwise, use a placeholder
        var headlines: string = ""
        const imageUrl = index < selectedImageFiles.length && selectedImageFiles[index].file ? selectedImageFiles[index].url : selectedImageFiles[index].url;
        // if (SelfData.lbmainlist[0].data[0].lbtype === 0) {
        //   headlines = ""
        // } else {
        //   headlines = index < selectedImageFiles.length && selectedImageFiles[index].file ? selectedImageFiles[index].catalog : selectedImageFiles[index].catalog;
        // }
        headlines = index < selectedImageFiles.length && selectedImageFiles[index].file ? selectedImageFiles[index].catalog : selectedImageFiles[index].catalog;
        return {image: imageUrl, label: headlines};
      });
    }
  }
}
