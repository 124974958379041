<div class="container">
  <div class="image-container">
    <img src="assets/loginbg.png" alt="My Image"/>
  </div>
  <div class="form-container">
    <button mat-icon-button class="close-btn" (click)="modalService.close()">
      <mat-icon>close</mat-icon>
    </button>

    <div class="example-full-width">
      <label class="line1-edits-new inputlabel">E-Mail</label>
      <nz-input-group nzPrefixIcon="user" [nzStatus]="statusemail" nzSize="default" type="" class="p-float-label line1-edits-new inputedit" [nzSuffix]="suffixIconSearch1">
        <input placeholder="Enter your email" type="email" [(ngModel)]="email" nz-input/>
      </nz-input-group>
      <ng-template #suffixIconSearch1>
      </ng-template>
    </div>
<!--    <mat-form-field class="example-full-width" appearance="fill">-->
<!--      <mat-label>Email</mat-label>-->
<!--      <input-->
<!--        type="email"-->
<!--        id="loginEmail"-->
<!--        matInput-->
<!--        [formControl]="emailFormControl"-->
<!--        placeholder="Enter your email"-->
<!--      />-->
<!--      <mat-error-->
<!--        *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">-->
<!--        Please enter a valid email address-->
<!--      </mat-error>-->
<!--      <mat-error *ngIf="emailFormControl.hasError('required')">-->
<!--        Email is <strong>required</strong>-->
<!--      </mat-error>-->
<!--    </mat-form-field>-->

    <div class="example-full-width1">
      <label class="line1-edits-new inputlabel">Password</label>
      <nz-input-group [nzStatus]="statuspassword" [nzSuffix]="suffixTemplate" nzPrefixIcon="lock">
        <input [type]="passwordVisible ? 'text' : 'password'" [(ngModel)]="password" nz-input placeholder="Enter your password" />
      </nz-input-group>
      <ng-template #suffixTemplate>
        <span
          nz-icon
          [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
          (click)="passwordVisible = !passwordVisible"
        ></span>
      </ng-template>
    </div>

<!--    <mat-form-field class="example-full-width" appearance="fill">-->
<!--      <mat-label>Password</mat-label>-->
<!--      <input [type]="hide ? 'password' : 'text'" matInput [formControl]="passwordFormControl" placeholder="Enter your password"/>-->
<!--      <button mat-icon-button matSuffix (click)="togglePasswordVisibility()" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="!hide">-->
<!--        <mat-icon>{{!hide ? 'visibility_off' : 'visibility'}}</mat-icon>-->
<!--      </button>-->
<!--      <mat-error *ngIf="passwordFormControl.invalid && passwordFormControl.errors?.['minlength']">-->
<!--        Must be at least 6 characters-->
<!--      </mat-error>-->
<!--      <mat-error *ngIf="passwordFormControl.hasError('required')">-->
<!--        Password is <strong>required</strong>-->
<!--      </mat-error>-->
<!--    </mat-form-field>-->
<!--    <p-button class="view-more-btn" label="Login" severity="contrast" (click)="createMainPage()"/>-->
    <button class="view-more-btn" nz-button nzSize="default" nzType="default" (click)="createMainPage()">Login</button>
<!--    <button mat-raised-button class="view-more-btn" (click)="createMainPage()">-->
<!--      Login-->
<!--    </button>-->
    <button class="view-more-btn" nz-button nzSize="default" nzType="default" (click)="modalService.registrationopen()">Request a Demo</button>
<!--    <button-->
<!--      mat-raised-button-->
<!--      class="view-more-btn"-->
<!--      (click)="modalService.registrationopen()"-->
<!--    >-->
<!--      Request a Demo-->
<!--    </button>-->
  </div>
</div>
