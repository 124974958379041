import {ChangeDetectorRef, Component, Inject, OnInit, Renderer2, ViewChild} from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";
import {
  GetCollectionList,
  GetCompanyList,
  GetDesignerList,
  GetFabricList,
  GetManuList, GetMiscList,
  GetRegionList, GetScannedList,
  GetSeasonList, GetSendOutList, GetSizeRangeList,
  GetStyleList,
  GetSWSList,
  GettodList,
  GetTypeList,
  SelfData, SendOut1Data,
  setgridfields, SetSampleListData,
  SetsorList,
  transformImageUrl
} from "../../models/struct";
import {ServiceService} from "../../services/service.service";
import {MatPaginator} from "@angular/material/paginator";
import {Table} from "primeng/table";
import {Paginator} from "primeng/paginator";
import {DatePipe, DOCUMENT} from "@angular/common";
import {OverlayPanel} from "primeng/overlaypanel";
import {YourappsserviceService} from "../../services/yourappsservice.service";


interface ColorArray {
  colorname: string;
  colorcode: string;
}

interface Gender {
  value: string;
}

@Component({
  selector: 'app-sopanel',
  templateUrl: './sopanel.component.html',
  styleUrl: './sopanel.component.scss'
})
export class SopanelComponent implements OnInit {
  iSendOutReportResult: SetsorList[] = [];
  @ViewChild('op') overlayPanel!: OverlayPanel;
  @ViewChild(MatPaginator) paginator: MatPaginator | null = null;
  @ViewChild('dt1') dt1!: Table;
  @ViewChild('paginator') paginator1!: Paginator;
  filtertext: string = ""
  sizes!: any[];

  colorarray: ColorArray[] = [
    {colorname: 'White', colorcode: '#ffffff'},
    {colorname: 'Black ', colorcode: '#000000'},
    {colorname: 'Yellow', colorcode: '#FFFF00'},
    {colorname: 'Purple', colorcode: '#800080'},
    {colorname: 'Orange ', colorcode: '#FFA500'},
    {colorname: 'Blue ', colorcode: '#2196f3'},
    {colorname: 'Light Blue ', colorcode: '#03a9f4'},
    {colorname: 'Red', colorcode: '#ff0000'},
    {colorname: 'Buff', colorcode: '#DAA06D'},
    // {colorname: 'Gray ', colorcode: '#808080'},
    {colorname: 'Grey ', colorcode: '#808080'},
    {colorname: 'Green ', colorcode: '#00FF00'},
    {colorname: 'Pink', colorcode: '#FFC0CB'},
    {colorname: 'Violet', colorcode: '#8F00FF'},
    {colorname: 'Brown', colorcode: '#964B00'},
    {colorname: 'Silver', colorcode: '#C0C0C0'},
    {colorname: 'Cream', colorcode: '#FFFDD0'},
    {colorname: 'Gold', colorcode: '#FFD700'},
    {colorname: 'Beige', colorcode: '#F5F5DC'},
    {colorname: 'Navy', colorcode: '#202A44'},
    {colorname: 'Mint', colorcode: '#A8E8D7'},
  ];

  gender: Gender[] = [
    {value: 'Man'},
    {value: 'Unisex'},
    {value: 'Women'},
  ];

  selectedSize: any = '';

  iFieldList: setgridfields[] = [];

  constructor(public modalService: ModalserviceService, private serviceservice: ServiceService,
              private datePipe: DatePipe,
              private cdr: ChangeDetectorRef,
              private yourappsService: YourappsserviceService,
              private renderer: Renderer2, @Inject(DOCUMENT) private document: Document) {
    this.serviceservice
      .getGridFields({
        formname: "SendOutReport",
        accountid: this.getAccountID(),
        userid: SelfData.account[0].data[0].id
      })
      .subscribe((value) => {
        this.iFieldList[0] = value;
        console.log("this.iFieldList", this.iFieldList)
        SelfData.gridgields = this.iFieldList;
        this.processFieldList();
      });
    this.sizes = [
      {name: 'Small', class: 'p-datatable-sm'}
    ];
    this.selectedSize = this.sizes[0]
    this.serviceservice.setSendOutReportList({accountid: this.getAccountID(), forceRefresh: false}).subscribe({
      next: (value) => {
        if (value) {
          this.iSendOutReportResult[0] = value;
          SelfData.originalsoReportData[0] = value;
        } else {
          console.log('The response is empty or undefined.');
        }
      },
      error: (error) => {
        console.error('Error occurred during the subscription:', error);
      },
    });

    this.serviceservice
      .setTODList({accountid: this.getAccountID()})
      .subscribe((desingervalue) => {
        this.iTodResult[0] = desingervalue;
        SelfData.tod = this.iTodResult;
      });

    this.serviceservice
      .setDesignerList({accountid: this.getAccountID()})
      .subscribe((desingervalue) => {
        this.iDesignerResult[0] = desingervalue;
        SelfData.designer = this.iDesignerResult;
      });

    this.serviceservice
      .setCollectionList({accountid: this.getAccountID()})
      .subscribe((collectionvalue) => {
        this.iCollection[0] = collectionvalue;
        SelfData.collection = this.iCollection;
      });

    this.serviceservice
      .setSeasonList({accountid: this.getAccountID()})
      .subscribe((seasonvalue) => {
        this.iSeason[0] = seasonvalue;
        SelfData.season = this.iSeason;
      });

    this.serviceservice
      .setStyleList({accountid: this.getAccountID()})
      .subscribe((value) => {
        this.iStyle[0] = value;
        SelfData.style = this.iStyle;
      });

    this.serviceservice
      .setContactList({accountid: this.getAccountID()})
      .subscribe((value) => {
        this.iContact[0] = value;
        SelfData.contact = this.iContact;
      });

    this.serviceservice
      .setClientList({accountid: this.getAccountID()})
      .subscribe((value) => {
        this.iClient[0] = value;
        SelfData.contact = this.iContact;
      });

    this.serviceservice
      .setPublicationList({accountid: this.getAccountID()})
      .subscribe((value) => {
        this.ipublication[0] = value;
        SelfData.contact = this.iContact;
      });

    this.serviceservice
      .setestyLis({accountid: this.getAccountID()})
      .subscribe((value) => {
        this.iEStyllist[0] = value;
        SelfData.estylist = this.iEStyllist;
      });

    this.serviceservice
      .setsendouttype({accountid: this.getAccountID()})
      .subscribe((value) => {
        this.iSendOutType[0] = value;
        SelfData.sendouttype = this.iSendOutType;
      });

    this.serviceservice
      .setTypeList({accountid: this.getAccountID()})
      .subscribe((value) => {
        this.iType.push(value);
        SelfData.type = this.iType;
      });

    this.serviceservice
      .setcim({accountid: this.getAccountID()})
      .subscribe((value) => {
        this.iCimResult[0] = value;
        SelfData.cim = this.iCimResult;
      });

    this.serviceservice
      .setSender({accountid: this.getAccountID()})
      .subscribe((value) => {
        this.iSenderResult[0] = value;
        SelfData.sender = this.iSenderResult;
      });

    this.serviceservice
      .setPEList({accountid: this.getAccountID()})
      .subscribe((value) => {
        this.iPEResult = [];
        // this.resetResults()
        this.iPEResult[0] = value;
        SelfData.pe = this.iPEResult;
      });

    this.serviceservice
      .setSWSList({accountid: this.getAccountID()})
      .subscribe((swsvalue) => {
        this.iSWSResult[0] = swsvalue;
        SelfData.sws = this.iSWSResult;

      });

    this.serviceservice
      .setRegionList({accountid: this.getAccountID()})
      .subscribe((regionvalue) => {
        this.iRegionResult[0] = regionvalue;
        SelfData.region = this.iRegionResult;
      });

    this.serviceservice
      .setFabricList({accountid: this.getAccountID()})
      .subscribe((fabricvalue) => {
        this.iFabricResult[0] = fabricvalue;
        SelfData.fabric = this.iFabricResult;
      });

    this.serviceservice
      .setManuList({accountid: this.getAccountID()})
      .subscribe((manuvalue) => {
        this.iManuResult[0] = manuvalue;
        SelfData.manu = this.iManuResult;
      });

    this.serviceservice
      .setMiscList({accountid: this.getAccountID()})
      .subscribe((value) => {
        this.iMisc[0] = value;
        SelfData.misc = this.iMisc;
      });

    this.serviceservice
      .setIssueList({accountid: this.getAccountID()})
      .subscribe((value) => {
        this.iTODResult[0] = value;
        SelfData.issue = this.iTODResult;
      });

    this.serviceservice
      .setSizeList({accountid: this.getAccountID()})
      .subscribe((value) => {
        this.iSize[0] = value;
        SelfData.size = this.iSize;
      });

    this.serviceservice
      .setSizeRangeList({accountid: this.getAccountID()})
      .subscribe((value) => {
        this.iSizeRange[0] = value;
        SelfData.sizerange = this.iSizeRange;
      });

    this.serviceservice
      .setPatternList({accountid: this.getAccountID()})
      .subscribe((value) => {
        this.iPattern[0] = value;
        SelfData.pattern = this.iPattern;
      });

    this.serviceservice
      .setPhotographer({accountid: this.getAccountID()})
      .subscribe((value) => {
        this.iPhotograoher[0] = value;
        SelfData.photographer = this.iPhotograoher;
      });

    this.serviceservice.setCompanyList({accountid: this.getAccountID()}).subscribe({
      next: (value: GetCompanyList) => {
        if (value && value.data) {
          this.iCompanyResult[0] = value;
          SelfData.company = this.iCompanyResult;
        } else {
          console.log('The response is empty or undefined.');
        }
      },
      error: (error) => {
        console.error('Error occurred during the subscription:', error);
      },
    });
  }

  iPattern: GetTypeList[] = [];
  iSizeRange: GetSizeRangeList[] = [];
  iSize: GetTypeList[] = [];
  iTODResult: GettodList[] = [];
  iTodResult: GettodList[] = [];
  iDesignerResult: GetDesignerList[] = [];
  iCollection: GetCollectionList[] = [];
  iSeason: GetSeasonList[] = [];
  iStyle: GetStyleList[] = [];
  iContact: GetStyleList[] = [];
  iClient: GetStyleList[] = [];
  ipublication: GetStyleList[] = [];
  iEStyllist: GetStyleList[] = [];
  iPhotograoher: GetStyleList[] = [];
  iType: GetTypeList[] = [];
  iSendOutType: GetTypeList[] = [];
  iCompanyResult: GetCompanyList[] = [];
  iCimResult: GetTypeList[] = [];
  iPEResult: GettodList[] = [];
  iSenderResult: GettodList[] = [];
  iSWSResult: GetSWSList[] = [];
  iRegionResult: GetRegionList[] = []
  iFabricResult: GetFabricList[] = [];
  iManuResult: GetManuList[] = [];
  iMisc: GetMiscList[] = [];

  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
      ? SelfData.account[0].data[0].id
      : SelfData.account[0].data[0].parentid;
  }

  onInfoButtonClick() {
    window.open('https://fm.welcomeapps.net/FAQ/SendOutReport.html', '_blank');
  }

  onInput(event: any) {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;

    this.dt1.filterGlobal(value, 'contains');
  }

  onInputType(value: string) {
    // this.dt1.filterGlobal(value, 'contains');
    // this.filterTypetext = value
    // this.dataSource.data = SelfData.sampledata[0].data;
    // this.filterData()
  }

  clear(table: Table) {
    table.clear();
    this.filtertext = ""
  }

  formatDate(date: string): string {
    return <string>this.datePipe.transform(date, 'dd/MMM/yy hh:mm a', 'UTC');
  }

  processFieldList() {
    const fieldListData = this.iFieldList[0]?.data || [];
    console.log("fieldListData", fieldListData)
    fieldListData.forEach((item: any) => {
      console.log("item.fieldname", item.fieldname)
      switch (item.fieldname) {
        case 'Designer':
          this.selectCheckDesigner = item.isshow;
          break;
        case 'Inventory Status':
          this.selectCheckInventoryStatus = item.isshow;
          break;
        case 'Gender':
          this.selectCheckGender = item.isshow;
          break;
        case 'Sample Workflow Status':
          this.selectCheckSampleWorkflowStatus = item.isshow;
          break;
        case 'Region':
          this.selectCheckRegion = item.isshow;
          break;
        case 'Notes':
          this.selectCheckNotes = item.isshow;
          break;
        case 'Fabric':
          this.selectCheckFabric = item.isshow;
          break;
        case 'Manufacturer':
          this.selectCheckManufacturer = item.isshow;
          break;
        case 'Collection':
          this.selectCheckCollection = item.isshow;
          break;
        case 'Style':
          this.selectCheckStyle = item.isshow;
          break;
        case 'Looks Associated':
          this.selectCheckLooksAssociated = item.isshow;
          break;
        case 'Season':
          this.selectCheckSeason = item.isshow;
          break;
        case 'Misc':
          this.selectCheckMisc = item.isshow;
          break;
        case 'Type':
          this.selectCheckType = item.isshow;
          break;
        case 'Size':
          this.selectCheckSize = item.isshow;
          break;
        case 'Size Range':
          this.selectCheckSizeRange = item.isshow;
          break;
        case 'Pattern':
          this.selectCheckPattern = item.isshow;
          break;
        case 'Color':
          this.selectCheckColor = item.isshow;
          break;
        // case 'Retail Price EUR':
        //   this.selectCheckRetailPriceEUR = item.isshow;
        //   break;
        // case 'Retail Price USD':
        //   this.selectCheckRetailPriceUSD = item.isshow;
        //   break;
        // case 'Wholesale EUR':
        //   this.selectCheckWholesaleEUR = item.isshow;
        //   break;
        // case 'Wholesale USD':
        //   this.selectCheckWholesaleUSD = item.isshow;
        //   break;
        // case 'Sale Price EUR':
        //   this.selectCheckSalePriceEUR = item.isshow;
        //   break;
        // case 'Sale Price USD':
        //   this.selectCheckSalePriceUSD = item.isshow;
        //   break;
        case 'Description':
          this.selectCheckDescription = item.isshow;
          break;
        case 'Barcode':
          this.selectCheckBarcode = item.isshow;
          break;
        case 'User':
          this.selectCheckUser = item.isshow;
          break;
        case 'ReservedUser':
          this.selectCheckReservedUser = item.isshow;
          break;
        case 'Department':
          this.selectCheckDepartment = item.isshow;
          break;
        case 'Ship':
          this.selectCheckShip = item.isshow;
          break;
        case 'Ship To':
          this.selectCheckShipTo = item.isshow;
          break;
        case 'Type of Dispatch':
          this.selectCheckTypeOfDispatch = item.isshow;
          break;
        case 'Project/Event':
          this.selectCheckProjectEvent = item.isshow;
          break;
        case 'CompanyName':
          this.selectCheckCompanyName = item.isshow;
          break;
        case 'Celeb/Influenc/Model':
          this.selectCheckCelebInfluencModel = item.isshow;
          break;
        case 'Publication':
          this.selectCheckPublication = item.isshow;
          break;
        case 'Editor/Stylist':
          this.selectCheckEditorStylist = item.isshow;
          break;
        case 'Photographer':
          this.selectCheckPhotographer = item.isshow;
          break;
        case 'Project/Event Location':
          this.selectCheckProjectEventLocation = item.isshow;
          break;
        case 'Project/Event Theme':
          this.selectCheckProjectEventTheme = item.isshow;
          break;
        case 'Issue':
          this.selectCheckIssue = item.isshow;
          break;
        case 'Tracking Number':
          this.selectCheckTrackingNumber = item.isshow;
          break;
        case 'Return':
          this.selectCheckReturn = item.isshow;
          break;
        case 'SendOut Node':
          this.selectCheckSendOutNode = item.isshow;
          break;
        case 'Requested By':
          this.selectCheckRequestedBy = item.isshow;
          break;
        case 'SendOut Type':
          this.selectCheckSendOutType = item.isshow;
          break;
        case 'Currency':
          this.selectCheckCurrency = item.isshow;
          break;
        case 'Reservation Date':
          this.selectCheckReservationDate = item.isshow;
          break;
        case 'Return Date':
          this.selectCheckReturnDate = item.isshow;
          break;
        case 'Create Date':
          this.selectCheckCreateDate = item.isshow;
          break;
        case 'Sender':
          this.selectCheckSender = item.isshow;
          break;
        case 'Celebriti':
          this.selectCheckCelebriti = item.isshow;
          break;
        case 'Image':
          this.selectCheckImage = item.isshow;
          break;
        case 'Filename':
          this.selectCheckFilename = item.isshow;
          break;
        case 'Delivery #':
          this.selectCheckDeliveryNumber = item.isshow;
          break;
        case 'Contact':
          this.selectCheckContact = item.isshow;
          break;
        case 'Sample Status':
          this.selectCheckSampleStatus = item.isshow;
          break;
        case 'Ship Date':
          this.selectCheckShipDate = item.isshow;
          break;
        default:
          break;
      }
    });
  }

  private originalData: any[] = [];

// Функция для фильтрации данных
  filterData(): void {
    console.log("Original Data перед фильтрацией:", this.originalData);

    // Если оригинальные данные еще не сохранены, сохраняем их
    if (this.originalData.length === 0 && Array.isArray(SelfData.originalsoReportData[0]?.data)) {
      this.originalData = [...SelfData.originalsoReportData[0].data];
    }

    // Проверяем, что массив содержит данные
    if (!Array.isArray(this.originalData) || this.originalData.length === 0) {
      console.error("Нет данных для фильтрации");
      return;
    }

    // Создаем объект фильтров
    const filters = {
      DeliveryNumber: this.selectCheckDeliveryNumberString?.toLowerCase().trim() || "",
      TypeOfDispatch: this.selectCheckTypeOfDispatchString?.toLowerCase().trim() || "",
      Barcode: this.selectCheckBarcodeString?.toLowerCase().trim() || "",
      Designer: this.selectCheckDesignerString?.toLowerCase().trim() || "",
      Collection: this.selectCheckCollectionString?.toLowerCase().trim() || "",
      Season: this.selectCheckSeasonString?.toLowerCase().trim() || "",
      Style: this.selectCheckStyleString?.toLowerCase().trim() || "",
      Type: this.selectCheckTypeString?.toLowerCase().trim() || "",
      Color: this.selectCheckColorString?.toLowerCase().trim() || "",
      CompanyName: this.selectCheckCompanyNameString?.toLowerCase().trim() || "",
      Contact: this.selectCheckContactString?.toLowerCase().trim() || "",
      Celebriti: this.selectCheckCelebritiString?.toLowerCase().trim() || "",
      Publication: this.selectCheckPublicationString?.toLowerCase().trim() || "",
      EditorStylist: this.selectCheckEditorStylistString?.toLowerCase().trim() || "",
      SendOutType: this.selectCheckSendOutTypeString?.toLowerCase().trim() || "",
      CelebInfluencModel: this.selectCheckCelebInfluencModelString?.toLowerCase().trim() || "",
      ProjectEvent: this.selectCheckProjectEventString?.toLowerCase().trim() || "",
      Sender: this.selectCheckSenderString?.toLowerCase().trim() || "",
      Description: this.selectCheckDescriptionString?.toLowerCase().trim() || "",
      ShipTo: this.selectCheckShipToString?.toLowerCase().trim() || "",
      SampleWorkflowStatus: this.selectCheckSampleWorkflowStatusString?.toLowerCase().trim() || "",
      Region: this.selectCheckRegionString?.toLowerCase().trim() || "",
      Fabric: this.selectCheckFabricString?.toLowerCase().trim() || "",
      Manufacturer: this.selectCheckManufacturerString?.toLowerCase().trim() || "",
      Misc: this.selectCheckMiscString?.toLowerCase().trim() || "",
      Issue: this.selectCheckIssueString?.toLowerCase().trim() || "",
      TrackingNumber: this.selectCheckTrackingNumberString?.toLowerCase().trim() || "",
      Size: this.selectCheckSizeString?.toLowerCase().trim() || "",
      SizeRange: this.selectCheckSizeRangeString?.toLowerCase().trim() || "",
      Pattern: this.selectCheckPatternstring?.toLowerCase().trim() || "",
      Gender: this.selectCheckgenderString?.toLowerCase().trim() || "",
      Notes: this.selectCheckNotesString?.toLowerCase().trim() || "",
      Photographer: this.selectCheckPhotographerString?.toLowerCase().trim() || "",
      createDateStart: this.createDate?.[0] || null,
      createDateEnd: this.createDate?.[1] || null,
      createShipDateStart: this.shipDate?.[0] || null,
      createShipDateEnd: this.shipDate?.[1] || null,
      createReturnDateStart: this.returnDate?.[0] || null,
      createReturnDateEnd: this.returnDate?.[1] || null,
    };

    // Проверяем, если все фильтры пустые
    const isEmptyFilters = Object.values(filters).every(filter => filter === "" || filter === null);

    // Если фильтры пустые, возвращаем оригинальные данные
    if (isEmptyFilters) {
      console.log("Фильтры пустые. Возвращаем исходные данные.");
      this.iSendOutReportResult[0].data = [...this.originalData];
      return;
    }

    // Фильтруем массив
    const filteredData = this.originalData.filter((item: any) => {
      const createDate = item["Create Date"] ? new Date(item["Create Date"]) : null;
      const shipDate = item["Ship Date"] ? new Date(item["Ship Date"]) : null;
      const returnDate = item["Return Date"] ? new Date(item["Return Date"]) : null;
      return (
        (!filters.DeliveryNumber || item["Delivery #"]?.toString().toLowerCase() === filters.DeliveryNumber.toLowerCase()) &&
        (!filters.TypeOfDispatch || item["Type of Dispatch"]?.toString().toLowerCase() === filters.TypeOfDispatch.toLowerCase()) &&
        (!filters.Barcode || item["Barcode"]?.toString().toLowerCase() === filters.Barcode.toLowerCase()) &&
        (!filters.Designer || item["Designer"]?.toString().toLowerCase() === filters.Designer.toLowerCase()) &&
        (!filters.Collection || item["Collection"]?.toString().toLowerCase() === filters.Collection.toLowerCase()) &&
        (!filters.Season || item["Season"]?.toString().toLowerCase() === filters.Season.toLowerCase()) &&
        (!filters.Style || item["Style"]?.toString().toLowerCase() === filters.Style.toLowerCase()) &&
        (!filters.Type || item["Type"]?.toString().toLowerCase() === filters.Type.toLowerCase()) &&
        (!filters.Color || item["Color"]?.toString().toLowerCase() === filters.Color.toLowerCase()) &&
        (!filters.CompanyName || item["Companyname"]?.toString().toLowerCase() === filters.CompanyName.toLowerCase()) &&
        (!filters.Contact || item["Contact"]?.toString().toLowerCase() === filters.Contact.toLowerCase()) &&
        (!filters.Celebriti || item["Celebriti"]?.toString().toLowerCase() === filters.Celebriti.toLowerCase()) &&
        (!filters.Publication || item["Publication"]?.toString().toLowerCase() === filters.Publication.toLowerCase()) &&
        (!filters.EditorStylist || item["Editor/Stylist"]?.toString().toLowerCase() === filters.EditorStylist.toLowerCase()) &&
        (!filters.SendOutType || item["RendOut Type"]?.toString().toLowerCase() === filters.SendOutType.toLowerCase()) &&
        (!filters.CelebInfluencModel || item["Celeb/Influenc/Model"]?.toString().toLowerCase() === filters.CelebInfluencModel.toLowerCase()) &&
        (!filters.ProjectEvent || item["Project/Event"]?.toString().toLowerCase() === filters.ProjectEvent.toLowerCase()) &&
        (!filters.Sender || item["Sender"]?.toString().toLowerCase() === filters.Sender.toLowerCase()) &&
        (!filters.Description || item["Description"]?.toString().toLowerCase() === filters.Description.toLowerCase()) &&
        (!filters.ShipTo || item["Ship To"]?.toString().toLowerCase() === filters.ShipTo.toLowerCase()) &&
        (!filters.SampleWorkflowStatus || item["Sample Workflow Status"]?.toString().toLowerCase() === filters.SampleWorkflowStatus.toLowerCase()) &&
        (!filters.Region || item["Region"]?.toString().toLowerCase() === filters.Region.toLowerCase()) &&
        (!filters.Fabric || item["Fabric"]?.toString().toLowerCase() === filters.Fabric.toLowerCase()) &&
        (!filters.Manufacturer || item["Manufacturer"]?.toString().toLowerCase() === filters.Manufacturer.toLowerCase()) &&
        (!filters.Misc || item["Misc"]?.toString().toLowerCase() === filters.Misc.toLowerCase()) &&
        (!filters.Issue || item["Issue"]?.toString().toLowerCase() === filters.Issue.toLowerCase()) &&
        (!filters.TrackingNumber || item["Tracking Number"]?.toString().toLowerCase() === filters.TrackingNumber.toLowerCase()) &&
        (!filters.Size || item["Size"]?.toString().toLowerCase() === filters.Size.toLowerCase()) &&
        (!filters.SizeRange || item["Size Range"]?.toString().toLowerCase() === filters.SizeRange.toLowerCase()) &&
        (!filters.Pattern || item["Pattern"]?.toString().toLowerCase() === filters.Pattern.toLowerCase()) &&
        (!filters.Gender || item["Gender"]?.toString().toLowerCase() === filters.Gender.toLowerCase()) &&
        (!filters.Notes || item["Notes"]?.toString().toLowerCase() === filters.Notes.toLowerCase()) &&
        (!filters.Photographer || item["Photographer"]?.toString().toLowerCase() === filters.Photographer.toLowerCase()) &&
        (!filters.createDateStart || (createDate && createDate >= filters.createDateStart)) &&
        (!filters.createDateEnd || (createDate && createDate <= filters.createDateEnd)) &&
        (!filters.createShipDateStart || (shipDate && shipDate >= filters.createShipDateStart)) &&
        (!filters.createShipDateEnd || (shipDate && shipDate <= filters.createShipDateEnd)) &&
        (!filters.createReturnDateStart || (returnDate && returnDate >= filters.createReturnDateStart)) &&
        (!filters.createReturnDateEnd || (returnDate && returnDate <= filters.createReturnDateEnd))
      );
    });
    // const filteredData = this.originalData.filter((item: any) => {
    //   const createDate = item["Create Date"] ? new Date(item["Create Date"]) : null;
    //   const shipDate = item["Ship Date"] ? new Date(item["Ship Date"]) : null;
    //   const returnDate = item["Return Date"] ? new Date(item["Return Date"]) : null;
    //   return (
    //     (!filters.DeliveryNumber || item["Delivery #"]?.toString().toLowerCase().includes(filters.DeliveryNumber)) &&
    //     (!filters.TypeOfDispatch || item["Type of Dispatch"]?.toString().toLowerCase().includes(filters.TypeOfDispatch)) &&
    //     (!filters.Barcode || item["Barcode"]?.toString().toLowerCase().includes(filters.Barcode)) &&
    //     (!filters.Designer || item["Designer"]?.toString().toLowerCase() === filters.Designer.toLowerCase()) &&
    //     (!filters.Collection || item["Collection"]?.toString().toLowerCase().includes(filters.Collection)) &&
    //     (!filters.Season || item["Season"]?.toString().toLowerCase().includes(filters.Season)) &&
    //     (!filters.Style || item["Style"]?.toString().toLowerCase().includes(filters.Style)) &&
    //     (!filters.Type || item["Type"]?.toString().toLowerCase().includes(filters.Type)) &&
    //     (!filters.Color || item["Color"]?.toString().toLowerCase().includes(filters.Color)) &&
    //     (!filters.CompanyName || item["Companyname"]?.toString().toLowerCase().includes(filters.CompanyName)) &&
    //     (!filters.Contact || item["Contact"]?.toString().toLowerCase().includes(filters.Contact)) &&
    //     (!filters.Celebriti || item["Celebriti"]?.toString().toLowerCase().includes(filters.Celebriti)) &&
    //     (!filters.Publication || item["Publication"]?.toString().toLowerCase().includes(filters.Publication)) &&
    //     (!filters.EditorStylist || item["Editor/Stylist"]?.toString().toLowerCase().includes(filters.EditorStylist)) &&
    //     (!filters.SendOutType || item["RendOut Type"]?.toString().toLowerCase().includes(filters.SendOutType)) &&
    //     (!filters.CelebInfluencModel || item["Celeb/Influenc/Model"]?.toString().toLowerCase().includes(filters.CelebInfluencModel)) &&
    //     (!filters.ProjectEvent || item["Project/Event"]?.toString().toLowerCase().includes(filters.ProjectEvent)) &&
    //     (!filters.Sender || item["Sender"]?.toString().toLowerCase().includes(filters.Sender)) &&
    //     (!filters.Description || item["Description"]?.toString().toLowerCase().includes(filters.Description)) &&
    //     (!filters.ShipTo || item["Ship To"]?.toString().toLowerCase().includes(filters.ShipTo)) &&
    //     (!filters.SampleWorkflowStatus || item["Sample Workflow Status"]?.toString().toLowerCase().includes(filters.SampleWorkflowStatus)) &&
    //     (!filters.Region || item["Region"]?.toString().toLowerCase().includes(filters.Region)) &&
    //     (!filters.Fabric || item["Fabric"]?.toString().toLowerCase().includes(filters.Fabric)) &&
    //     (!filters.Manufacturer || item["Manufacturer"]?.toString().toLowerCase().includes(filters.Manufacturer)) &&
    //     (!filters.Misc || item["Misc"]?.toString().toLowerCase().includes(filters.Misc)) &&
    //     (!filters.Issue || item["Issue"]?.toString().toLowerCase().includes(filters.Issue)) &&
    //     (!filters.TrackingNumber || item["Tracking Number"]?.toString().toLowerCase().includes(filters.TrackingNumber)) &&
    //     (!filters.Size || item["Size"]?.toString().toLowerCase().includes(filters.Size)) &&
    //     (!filters.SizeRange || item["Size Range"]?.toString().toLowerCase().includes(filters.SizeRange)) &&
    //     (!filters.Pattern || item["Pattern"]?.toString().toLowerCase().includes(filters.Pattern)) &&
    //     (!filters.Gender || item["Gender"]?.toString().toLowerCase().includes(filters.Gender)) &&
    //     (!filters.Notes || item["Notes"]?.toString().toLowerCase().includes(filters.Notes)) &&
    //     (!filters.Photographer || item["Photographer"]?.toString().toLowerCase().includes(filters.Photographer)) &&
    //     (!filters.createDateStart || (createDate && createDate >= filters.createDateStart)) &&
    //     (!filters.createDateEnd || (createDate && createDate <= filters.createDateEnd)) &&
    //     (!filters.createShipDateStart || (shipDate && shipDate >= filters.createShipDateStart)) &&
    //     (!filters.createShipDateEnd || (shipDate && shipDate <= filters.createShipDateEnd)) &&
    //     (!filters.createReturnDateStart || (returnDate && returnDate >= filters.createReturnDateStart)) &&
    //     (!filters.createReturnDateEnd || (returnDate && returnDate <= filters.createReturnDateEnd))
    //   );
    // });

    console.log("Filtered Data:", filteredData);
    this.iSendOutReportResult[0].data = filteredData;

    if (filteredData.length === 0) {
      console.warn("Фильтрация не нашла совпадений. Проверьте параметры фильтров.");
    }
  }

  protected readonly transformImageUrl = transformImageUrl;
  selectCheckDesigner: boolean = false;
  selectCheckInventoryStatus = false;
  selectCheckGender = false;
  selectCheckSampleWorkflowStatus = false;
  selectCheckRegion = false;
  selectCheckNotes = false;
  selectCheckFabric = false;
  selectCheckManufacturer = false;
  selectCheckCollection = false;
  selectCheckStyle = false;
  selectCheckLooksAssociated = false;
  selectCheckSeason = false;
  selectCheckMisc = false;
  selectCheckType = false;
  selectCheckSize = false;
  selectCheckSizeRange = false;
  selectCheckPattern = false;
  selectCheckColor = false;
  // selectCheckRetailPriceEUR = false;
  // selectCheckRetailPriceUSD = false;
  // selectCheckWholesaleEUR = false;
  // selectCheckWholesaleUSD = false;
  // selectCheckSalePriceEUR = false;
  // selectCheckSalePriceUSD = false;
  selectCheckDescription = false;
  selectCheckBarcode = false;
  selectCheckUser = false;
  selectCheckReservedUser = false;
  // selectCheckBarcodePrefix = false;
  selectCheckDepartment = false;
  selectCheckShip = false;
  selectCheckShipTo = false;
  selectCheckTypeOfDispatch = false;
  selectCheckProjectEvent = false;
  selectCheckCompanyName = false;
  selectCheckCelebInfluencModel = false;
  selectCheckPublication = false;
  selectCheckEditorStylist = false;
  selectCheckPhotographer = false;
  selectCheckProjectEventLocation = false;
  selectCheckProjectEventTheme = false;
  selectCheckIssue = false;
  selectCheckTrackingNumber = false;
  selectCheckReturn = false;
  selectCheckSendOutNode = false;
  selectCheckRequestedBy = false;
  selectCheckSendOutType = false;
  selectCheckCurrency = false;
  selectCheckReservationDate = false;
  selectCheckReturnDate = false;
  selectCheckCreateDate = false;
  selectCheckSender = false;
  selectCheckCelebriti = false;
  selectCheckImage = false;
  selectCheckFilename = false;
  selectCheckDeliveryNumber = false;
  selectCheckContact = false;
  selectCheckSampleStatus = false;
  selectCheckShipDate = false;
  selectCheckDeliveryNumberString: string = "";
  selectCheckTypeOfDispatchString: string = "";
  selectCheckBarcodeString: string = "";
  selectCheckDesignerString: string = "";
  selectCheckCollectionString: string = "";
  selectCheckSeasonString: string = "";
  selectCheckStyleString: string = "";
  selectCheckTypeString: string = "";
  selectCheckColorString: string = "";
  createDate: Date[] | null = null;
  shipDate: Date[] | null = null;
  returnDate: Date[] | null = null;
  selectCheckCompanyNameString: string = "";
  selectCheckContactString: string = "";
  selectCheckCelebritiString: string = "";
  selectCheckPublicationString: string = "";
  selectCheckEditorStylistString: string = "";
  selectCheckSendOutTypeString: string = "";
  selectCheckCelebInfluencModelString: string = "";
  selectCheckProjectEventString: string = "";
  selectCheckSenderString: string = "";
  selectCheckDescriptionString: string = "";
  selectCheckShipToString: string = "";
  selectCheckSampleWorkflowStatusString: string = "";
  selectCheckRegionString: string = "";
  selectCheckFabricString: string = "";
  selectCheckManufacturerString: string = "";
  selectCheckMiscString: string = "";
  selectCheckIssueString: string = "";
  selectCheckTrackingNumberString: string = "";
  selectCheckSizeString: string = "";
  selectCheckSizeRangeString: string = "";
  selectCheckPatternstring: string = "";
  selectCheckgenderString: string = "";
  selectCheckNotesString: string = "";
  selectCheckPhotographerString: string = "";

  onInputDesigner(value: string) {
    // this.dt1.filterGlobal(value, 'contains');
    // this.selectCheckDeliveryNumberString = value
    // this.dataSource.data = SelfData.sampledata[0].data;
    console.log('Selected date range:', value);
    this.filterData()
  }

  onInputDate(value: Date[]): void {
    if (value && value.length === 2) {
      this.createDate = value; // Update the date range
    } else {
      this.createDate = null; // Clear date range if no selection
    }
    console.log('Selected date range:', this.createDate);
    this.filterData(); // Trigger filtering
  }

  onInputShipDate(value: Date[]): void {
    if (value && value.length === 2) {
      this.shipDate = value; // Update the date range
    } else {
      this.shipDate = null; // Clear date range if no selection
    }
    console.log('Selected date range:', this.shipDate);
    this.filterData(); // Trigger filtering
  }

  onInputReturnDate(value: Date[]): void {
    if (value && value.length === 2) {
      this.returnDate = value; // Update the date range
    } else {
      this.returnDate = null; // Clear date range if no selection
    }
    console.log('Selected date range:', this.returnDate);
    this.filterData(); // Trigger filtering
  }

  onInputColor(selectedOption: any): void {
    if (selectedOption) {
      this.selectCheckColorString = selectedOption;
    } else {
      console.log('Цвет не выбран или поле было очищено');
      this.selectCheckColorString = ''; // Или другой обработчик для очистки
    }
    this.filterData();
  }

  onInputGender(selectedOption: any): void {
    if (selectedOption) {
      this.selectCheckgenderString = selectedOption;
    } else {
      console.log('Цвет не выбран или поле было очищено');
      this.selectCheckgenderString = ''; // Или другой обработчик для очистки
    }
    this.filterData();
  }

  onInputSeason(value: string) {
    // this.dt1.filterGlobal(value, 'contains');
    // this.filtertext = value
    // this.dataSource.data = SelfData.sampledata[0].data;
    // this.filterData()
  }


  onImageClick(product: any) {

  }

  closeOverlayPanel() {
//     const fieldListData = this.iFieldList[0]?.data || [];
//     const dataToSend = fieldListData.map((item: any) => {
//       return {
//         isshow: item.isshow,
//         formname: "SendOutReport", // Предположим, что `formName` определен в вашем компоненте
//         fieldname: item.fieldname,
//         userid: SelfData.account[0].data[0].id, // Предположим, что `userId` определен в вашем компоненте
//         accountid: this.getAccountID(), // Предположим, что `accountId` определен в вашем компоненте
//       };
//     });
//
// // Отправка каждого поля на сервер через prcGridFields
//     dataToSend.forEach((fieldData) => {
//       this.serviceservice.prcGridFields(fieldData).subscribe(
//         (response) => {
//           console.log('Field updated:', fieldData.fieldname, response);
//         },
//         (error) => {
//           console.error('Error updating field:', fieldData.fieldname, error);
//         }
//       );
//     });
    const fieldListData = this.iFieldList[0]?.data || [];

// Преобразование `fieldListData` с использованием значений свойств компонента
    const dataToSend = fieldListData.map((item: any) => {
      const isshowPropertyName = `selectCheck${item.fieldname.replace(/[^a-zA-Z0-9]/g, '')}`; // Убираем пробелы и символы
      const isshowValue = (this as any)[isshowPropertyName] || false; // Динамически получаем значение свойства
      return {
        isshow: isshowValue,
        formname: "SendOutReport", // Предположим, что `formName` определен в вашем компоненте
        fieldname: item.fieldname,
        userid: SelfData.account[0].data[0].id, // Предположим, что `userId` определен в вашем компоненте
        accountid: this.getAccountID(), // Предположим, что `accountId` определен в вашем компоненте
      };
    });

// Отправка данных
    dataToSend.forEach((fieldData) => {
      this.serviceservice.prcGridFields(fieldData).subscribe(
        (response) => {
          console.log('Field updated:', fieldData.fieldname, response);
        },
        (error) => {
          console.error('Error updating field:', fieldData.fieldname, error);
        }
      );
    });
    const fields = {
      isshow: this.selectCheckDeliveryNumber,
      formname: "SendOutReport", // Предположим, что `formName` определен в вашем компоненте
      fieldname: "Delivery #",
      userid: SelfData.account[0].data[0].id, // Предположим, что `userId` определен в вашем компоненте
      accountid: this.getAccountID(),
    }

    this.serviceservice.prcGridFields(fields).subscribe(
      (response) => {
        // console.log('Field updated:', fieldData.fieldname, response);
      },
      (error) => {
        // console.error('Error updating field:', fieldData.fieldname, error);
      }
    );

    const fields1 = {
      isshow: this.selectCheckTypeOfDispatch,
      formname: "SendOutReport", // Предположим, что `formName` определен в вашем компоненте
      fieldname: "Type of Dispatch",
      userid: SelfData.account[0].data[0].id, // Предположим, что `userId` определен в вашем компоненте
      accountid: this.getAccountID(),
    }

    this.serviceservice.prcGridFields(fields1).subscribe(
      (response) => {
        // console.log('Field updated:', fieldData.fieldname, response);
      },
      (error) => {
        // console.error('Error updating field:', fieldData.fieldname, error);
      }
    );

    this.overlayPanel.hide();
  }

  updateImageTransform(): void {
    const img = document.querySelector('.overlay-content') as HTMLImageElement;
    img.style.transform = `scale(${this.zoomLevel}) translateY(${this.translationY}px)`;
  }

  showFullWindowImage(imageUrl: string): void {
    // Create the elements for the modal, image, and close button
    const modal = this.renderer.createElement('div');
    this.renderer.addClass(modal, 'preview-modal');

    const img = this.renderer.createElement('img');
    this.renderer.addClass(img, 'preview-image');
    this.renderer.setAttribute(img, 'src', imageUrl);
    this.renderer.appendChild(modal, img);

    const closeButton = this.renderer.createElement('button');
    this.renderer.addClass(closeButton, 'close-preview');
    closeButton.innerHTML = '&times;'; // or use an SVG/icon for the close button
    this.renderer.appendChild(modal, closeButton);

// Append the modal to the body
    this.renderer.appendChild(this.document.body, modal);

    // Add a click listener to the close button to remove the modal
    this.renderer.listen(closeButton, 'click', (event) => {
      event.stopPropagation(); // Prevent the event from bubbling up
      this.renderer.removeChild(this.document.body, modal);
    });

// Add a click listener to the modal to close it if the user clicks outside the image
    this.renderer.listen(modal, 'click', () => {
      this.renderer.removeChild(this.document.body, modal);
    });

// Prevent the modal from closing when clicking on the image
    this.renderer.listen(img, 'click', (event) => {
      event.stopPropagation(); // Stop propagation to prevent the modal click listener from triggering
    });

    // Optionally, add a click listener to the modal to close it if the user clicks outside the image
    // this.renderer.listen(modal, 'click', () => {
    //   this.renderer.removeChild(this.document.body, modal);
    // });

    // this.renderer.listen(img, 'click', () => {
    //   this.zoomScale = this.zoomScale === 1 ? 2 : 1; // Toggle between 1x and 2x zoom
    //   this.renderer.setStyle(img, 'transform', `scale(${this.zoomScale})`);
    //   this.renderer.setStyle(img, 'cursor', this.zoomScale === 1 ? 'zoom-in' : 'zoom-out');
    // });
  }

  hideFullWindowImage(item: any): void {
    // ... existing code to hide the image
    // Reset zoom and translation
    this.zoomLevel = 1;
    this.translationY = 0;
    this.updateImageTransform();
  }

  dragging: boolean = false;
  startY: number = 0;
  translationY: number = 0;
  zoomLevel: number = 1

    ngOnInit(): void {

  }

  onButtonEmailClick() {
    SelfData.iSendOutReportResult[0] = this.iSendOutReportResult[0] ?? {};
    this.modalService.openEmail()
  }

  onButtonPrintClick() {
    const el = document.getElementById('scrollable-div-dm');
    if (!el) {
      console.error('Element not found!');
      return;
    }

    const clonedEl = el.cloneNode(true) as HTMLElement;

    // Create and append the iframe
    const iframe = document.createElement('iframe');
    document.body.appendChild(iframe);

    // Dynamically load the printer CSS
    const printerStyleLink = document.createElement('link');
    printerStyleLink.rel = 'stylesheet';
    printerStyleLink.href = '/assets/sopanel.component.css'; // Убедитесь, что путь верный

    iframe.contentWindow!.document.head.appendChild(printerStyleLink);

    // Append the element you want to print to the iframe
    iframe.contentWindow!.document.body.appendChild(clonedEl);

    // Print after a brief timeout to allow styles to load
    setTimeout(() => {
      iframe.contentWindow!.focus();
      iframe.contentWindow!.print();
      document.body.removeChild(iframe);
    }, 2000);
  }

  onClearAllFilters() {
    this.selectCheckDeliveryNumberString = ""
    this.selectCheckTypeOfDispatchString = ""
    this.selectCheckBarcodeString = ""
    this.selectCheckDesignerString = ""
    this.selectCheckCollectionString = ""
    this.selectCheckSeasonString = ""
    this.selectCheckStyleString = ""
    this.selectCheckTypeString = ""
    this.selectCheckColorString = ""
    this.createDate = null
    this.shipDate = null
    this.returnDate = null
    this.selectCheckCompanyNameString = ""
    this.selectCheckContactString = ""
    this.selectCheckCelebritiString = ""
    this.selectCheckPublicationString = ""
    this.selectCheckEditorStylistString = ""
    this.selectCheckSendOutTypeString = ""
    this.selectCheckCelebInfluencModelString = ""
    this.selectCheckProjectEventString = ""
    this.selectCheckSenderString = ""
    this.selectCheckDescriptionString = ""
    this.selectCheckShipToString = ""
    this.selectCheckSampleWorkflowStatusString = ""
    this.selectCheckRegionString = ""
    this.selectCheckFabricString = ""
    this.selectCheckManufacturerString = ""
    this.selectCheckMiscString = ""
    this.selectCheckIssueString = ""
    this.selectCheckTrackingNumberString = ""
    this.selectCheckSizeString = ""
    this.selectCheckSizeRangeString = ""
    this.selectCheckPatternstring = ""
    this.selectCheckgenderString = ""
    this.selectCheckNotesString = ""
    this.selectCheckPhotographerString = ""
    this.filterData()
    this.cdr.detectChanges();
  }

  selectedsample: SetSampleListData[] = [];
  iSendOutList: GetScannedList[] = [];

  iSendOutList1: GetSendOutList[] = [];

  createSendOut(product: any) {
    console.log("product", product)
    SelfData.selectadpage = 3
    SelfData.sendoutopentype = 1
    SelfData.sendoutstep = 2;
    SelfData.saveonclose = false
    SelfData.sendoutid = product?.["Sendoutid"]
    this.serviceservice
      .selectsendoutsamples({
        sendoutid: product.Sendoutid,
      })
      .subscribe((value) => {
        this.serviceservice
          .getCurSendOut({sendoutid: product.Sendoutid})
          .subscribe((value) => {
            this.iSendOutList1[0] = value;
            SelfData.sendoutlist = this.iSendOutList1;
            console.log(">>>>>>",SelfData.sendoutlist)
            SendOut1Data.senderFormControl = SelfData.sendoutlist[0].data[0].sender
            console.log("SendOut1Data.senderFormControl", SendOut1Data.senderFormControl)
            this.yourappsService.selectedSendOut(SelfData.sendoutlist);
            SelfData.selectadpage1 = 0
            this.modalService.openPrinterFrame()

            if (SelfData.scannedlist[0].data.length > 0) {
              this.selectedsample = []
              for (const item of SelfData.scannedlist[0].data) {
                const newSample: SetSampleListData = {
                  "ID": item["ID"],
                  "Inventory Status": item["Inventory Status"],
                  "Gender": item["Gender"],
                  "Sample Workflow Status": item["Sample Workflow Status"],
                  "Account ID": item["accountid"],
                  "Region": item["Region"],
                  "Notes": item["Notes"],
                  "Designer": item["Designer"],
                  "Fabric": item["Fabric"],
                  "Manufacturer": item["Manufacturer"],
                  "Collection": item["Collection"],
                  "Style": item["Style"],
                  "Looks Associated": item["Looks Associated"],
                  "Season": item["Season"],
                  "Misc": item["Misc"],
                  "Quantity": item["Quantity"],
                  "Type": item["Type"],
                  "Size": item["Size"],
                  "Size Range": item["Size Range"],
                  "Pattern": item["Pattern"],
                  "Color": item["Color"],
                  "Color Code": item["colorcode"],
                  "Retail Price EUR": item["Retail Price EUR"],
                  "Retail Price USD": item["Retail Price USD"],
                  "Wholesale EUR": item["Wholesale EUR"],
                  "Wholesale USD": item["Wholesale USD"],
                  "Sale Price EUR": item["Sale Price EUR"],
                  "Sale Price USD": item["Sale Price USD"],
                  "Prices ID": item["pricesid"],
                  "Description": item["Description"],
                  "Storage ID": item["storageid"],
                  "Registration Date": item["regdate"],
                  "Current Date": item["curdate"],
                  "User ID": item["userid"],
                  "Is Active": item["isactiviti"],
                  "Image Link": item["Image Link"],
                  "barcode": item["barcode"],
                  "User": item["user"],
                  "Barcode Prefix": item["barcodeprefix"],
                  "Selected Sample ID": item["selectedsampleid"],
                  "itemcount": item["itemcount"] || 1
                };
                this.selectedsample.push(newSample);

              }
              // ... any other operations you need to perform on items
              // }
              SelfData.setSelectedSample = this.selectedsample
            }
          });
        this.iSendOutList[0] = value;
        SelfData.scannedlist = this.iSendOutList;
      });
  }
}
