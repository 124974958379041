import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder} from "@angular/forms";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {ConfirmationService, MessageService} from "primeng/api";
import {NgxImageCompressService} from "ngx-image-compress";
import {HttpClient} from "@angular/common/http";
import {DatePipe} from "@angular/common";
import {QzTrayService} from "../../services/qz-tray.service";
import jsPDF from "jspdf";
import JsBarcode from "jsbarcode";

@Component({
  selector: 'app-testprinterdialog',
  templateUrl: './testprinterdialog.component.html',
  styleUrl: './testprinterdialog.component.scss'
})
export class TestprinterdialogComponent implements OnInit, AfterViewInit, OnDestroy {
  connected: boolean = false;
  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private serviceservice: ServiceService,
    private yourappsService: YourappsserviceService,
    private cdr: ChangeDetectorRef,
    private messageService: MessageService,
    private imageCompressService: NgxImageCompressService,
    private confirmationService: ConfirmationService,
    private http: HttpClient,
    private datePipe: DatePipe,
    private el: ElementRef,
    private qzTrayService: QzTrayService
  ) {

  }

  async connectToPrinter() {
    try {
      await this.qzTrayService.connect();
      this.connected = true; // Устанавливаем connected = true при успешном подключении
      console.log('Connected to QZ Tray');
    } catch (error) {
      this.connected = false; // Если ошибка, устанавливаем connected = false
      console.error('Failed to connect to QZ Tray:', error);
      this.connected = false;
    }
  }

  async disconnectFromPrinter() {
    try {
      await this.qzTrayService.disconnect();
      this.connected = false; // Устанавливаем connected = false после отключения
      console.log('Disconnected from QZ Tray');
    } catch (error) {
      console.error('Failed to disconnect from QZ Tray:', error);
    }
  }

//   async printDocument() {
//     if (!this.connected) {
//       console.warn('Not connected to QZ Tray. Please connect first.');
//       return;
//     }
//
//     const zpl = `^XA
// ^FO100,50^A0N,50,50^FDHello, World!^FS
// ^FO100,150^BY2,3,50
// ^BCN,100,Y,N,N
// ^FD123456789^FS
// ^XZ`;
//
//     console.log('Sending ZPL:', zpl);
//
//     const printData = [
//       { type: 'raw', format: 'plain', data: zpl }
//     ];
//
//     try {
//       await this.qzTrayService.print(printData);
//       console.log('Barcode printed successfully!');
//     } catch (error) {
//       console.error('Print job failed:', error);
//     }
//   }

  // async generateCustomPDF(): Promise<Blob | null> {
  //   try {
  //     const doc = new jsPDF({
  //       orientation: 'portrait',
  //       unit: 'mm',
  //       format: 'a4' // Формат A4
  //     });
  //
  //     const pageWidth = doc.internal.pageSize.getWidth(); // Ширина страницы A4
  //     const contentWidth = 120; // Узкая ширина контента
  //     const offsetX = (pageWidth - contentWidth) / 2; // Центрирование по горизонтали
  //     const offsetY = 5; // Размещение в верхней части страницы
  //
  //     const canvas = document.createElement('canvas');
  //     JsBarcode(canvas, 'TTA0011143', {
  //       format: 'CODE128',
  //       width: 1.5, // Более узкие линии штрихкода
  //       height: 30, // Высота штрихкода
  //       displayValue: false,
  //       margin: 2,
  //       background: '#FFFFFF',
  //       lineColor: '#000000',
  //       fontSize: 10
  //     });
  //
  //     const barcodeImg = canvas.toDataURL('image/png');
  //
  //     // Чёрный прямоугольник слева
  //     doc.setFillColor(0, 0, 0);
  //     doc.rect(offsetX, offsetY, 10, 50, 'F'); // Узкая ширина
  //
  //     // LAUNCHMETRICS вертикально (белым по черному)
  //     doc.setTextColor(255, 255, 255);
  //     doc.setFontSize(7); // Оптимальный размер текста
  //     doc.setFont('helvetica', 'bold');
  //     doc.text('LAUNCHMETRICS', offsetX + 5, offsetY + 45, { angle: 90 });
  //
  //     // Основной текст (черным)
  //     doc.setTextColor(0, 0, 0);
  //
  //     // TATA PR LA
  //     doc.setFontSize(14);
  //     doc.setFont('helvetica', 'bold');
  //     doc.text('TATA PR LA', offsetX + 15, offsetY + 10);
  //
  //     // Штрихкод
  //     doc.addImage(barcodeImg, 'PNG', offsetX + 15, offsetY + 15, 90, 20); // Узкая ширина штрихкода
  //
  //     // Текст под штрихкодом
  //     doc.setFontSize(10);
  //     doc.text('TTA0011143', offsetX + 15, offsetY + 40);
  //
  //     // Дополнительная информация
  //     doc.setFontSize(9);
  //     doc.setFont('helvetica', 'normal');
  //     doc.text('STUDIO 83 Womenswear FW24/25', offsetX + 15, offsetY + 50);
  //     doc.text('Bordeaux Shoes 40', offsetX + 15, offsetY + 55);
  //     doc.text('tata-la.com', offsetX + 15, offsetY + 60);
  //
  //     return doc.output('blob');
  //   } catch (error) {
  //     console.error('Ошибка при создании PDF:', error);
  //     return null;
  //   }
  // }
  // async savePDF() {
  //   const pdfBlob = await this.generateCustomPDF();
  //
  //   if (pdfBlob) {
  //     console.log('PDF успешно создан!');
  //
  //     // Сохранение PDF в локальной системе
  //     const link = document.createElement('a');
  //     link.href = URL.createObjectURL(pdfBlob);
  //     link.download = 'barcode.pdf';
  //     link.click();
  //   } else {
  //     console.error('Не удалось создать PDF.');
  //   }
  // }
  //
  // async printDocument() {
  //
  //   this.savePDF()
  // }

  async printDocument() {
    if (!this.connected) {
      console.warn('Not connected to QZ Tray. Please connect first.');
      return;
    }

    const zpl = `
    ^XA
^CF0,30
^FO200,90^FDTATA PR LA^FS

^FO200,120^BY2,2,30
^BCN,60,Y,N,N
^FDTTA0000011088^FS

^CF0,20
^FO200,210^FDWomenswear SS25 DAL Ruched^FS
^FO200,230^FDasymmetrical midi dress OS^FS
^FO200,270^FDtata-la.com^FS

^FO582,100^GB20,200,20^FS
^FO580,115^A0R,20,20^FR^FDfashion-m.com^FS

^XZ
  `;

    console.log("Sending ZPL: ", zpl);

    const printData = [
      { type: 'raw', format: 'plain', data: zpl }
    ];

    try {
      await this.qzTrayService.print(printData);
      console.log('Barcode printed successfully!');
    } catch (error) {
      console.error('Print job failed:', error);
    }
  }

  // async printDocument() {
  //   if (!this.connected) {
  //     console.warn('Not connected to QZ Tray. Please connect first.');
  //     return;
  //   }
  //
  //   const pdfPath = 'assets/barcode.pdf'; // Путь к вашему PDF-файлу
  //
  //   const printData = [
  //     {
  //       type: 'pdf', // Указываем, что это PDF
  //       format: 'file', // Указываем, что это файл
  //       data: pdfPath // Путь к файлу PDF
  //     }
  //   ];
  //
  //   try {
  //     await this.qzTrayService.print(printData);
  //     console.log('PDF printed successfully!');
  //   } catch (error) {
  //     console.error('Print job failed:', error);
  //   }
  // }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
  }

  onButtonCloseClick() {
   this.modalService.closetestprinter()
  }
}
