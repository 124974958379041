<div class="black-background2">

  <div class="card example-container-cn mat-elevation-z3">
    <p-table
      #dt1
      [value]="firstContactsData"
      [paginator]="true"
      [rows]="10"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [rowsPerPageOptions]="[10, 20, 50, 100]"
      [styleClass]="'p-datatable-striped mat-elevation-z3 ' + selectedSize.class.toString()"
      selectionMode="single"
      [globalFilterFields]="['Department', 'Email Type', 'Subject', 'Notes', 'Created By', 'Date Created', 'Date Sent', 'Recipients', 'From Name', 'From Email', 'Reply-To Email']"
      [tableStyle]="{'min-width': '50rem'}">
      <ng-template pTemplate="caption">
        <div class="flex">
          <!--                    <span class="p-inputgroup-addon custom-hover" matRipple (click)="onButtonAddClick()">-->
          <!--                        <i class="pi pi-plus" style="color: #000000"></i></span>-->
          <p-button class="info-btn" icon="pi pi-info" [rounded]="true" [outlined]="true" (click)="onInfoButtonClick()"/>
          <span class="p-input-icon-left ml-auto">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <i class="pi pi-search" style="color: #000000"></i>
              </span>
              <input pInputText type="text" (input)="onInput($event)" placeholder="Filter" [(ngModel)]="filtertext"/>
              <span class="p-inputgroup-addon custom-hover-red" matRipple (click)="clear(dt1)">
                <i class="pi pi-times" style="color: #000000"></i></span>
            </div>
          </span>

        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <!--                    -->
          <th style="width:5.3%">Options</th>
          <th style="width:6%">Department</th>
          <th style="width:5%">Email Type</th>
          <th style="width:10%">Subject</th>
          <th style="width:7%">Successfull Deliveries</th>
          <th style="width:7%">Unique Opens</th>
          <th style="width:10%">Notes</th>
          <th style="width:8%">Created By</th>
          <th style="width:6%">Date Created</th>
          <th style="width:6%">Date Sent</th>
          <th style="width:4%">Recipients</th>
          <th style="width:8%">From Name</th>
          <th style="width:8%">From Email</th>
          <th style="width:30%">Reply To Email</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-product>
        <tr [pSelectableRow]="product">
          <td>
            <button type="button" nzTrigger="click" nz-button nz-dropdown [nzDropdownMenu]="menu4" (click)="currentSample = product">
              select
              <span nz-icon nzType="down"></span>
            </button>
          </td>
          <td>{{ product?.["Department"] }}</td>
          <td>{{ product?.["Email Type"] }}</td>
          <td>{{ product?.["Subject"] }}</td>
          <td>
            <nz-progress
              [nzPercent]="product?.['Sended'] ? Math.round((product?.['SendedON'] / product?.['Sended']) * 100) : 0">
            </nz-progress>
            <div style="margin-top: -7px; font-size: 10px;">
              {{ product?.['SendedON'] }} of {{ product?.['Sended'] }}
              ({{ product?.['Sended'] ? Math.round((product?.['SendedON'] / product?.['Sended']) * 100) : 0 }}%)
            </div>
          </td>
          <td>
            <nz-progress
              [nzPercent]="product?.['Sended'] ? Math.round((product?.['OpenON'] / product?.['Sended']) * 100) : 0">
            </nz-progress>
            <div style="margin-top: -7px; font-size: 10px;">
              {{ product?.['OpenON'] }} of {{ product?.['Sended'] }}
              ({{ product?.['Sended'] ? Math.round((product?.['OpenON'] / product?.['Sended']) * 100) : 0 }}%)
            </div>
          </td>
          <td>{{product["Notes"]}}</td>
          <td>{{product["Created By"]}}</td>
          <td>{{ formatCustomDate(product?.["Date Created"]) }}</td>
          <td>{{ formatCustomDate(product?.["Date Sent"]) }}</td>
          <td>{{product["Recipients"]}}</td>
          <td>{{product["From Name"]}}</td>
          <td>{{product["From Email"]}}</td>
          <td>{{product["Reply-To Email"]}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<nz-dropdown-menu #menu4="nzDropdownMenu">
  <ul nz-menu>
    <li nz-menu-item (click)="onDetailViewClick(currentSample)">View Details</li>
  </ul>
</nz-dropdown-menu>

<mat-menu #menu="matMenu">
  <button mat-menu-item>
    <mat-icon>visibility</mat-icon>
    <span>View Details</span>
  </button>
  <button mat-menu-item>
    <mat-icon>edit_note</mat-icon>
    <span>Edit Details</span>
  </button>
  <button mat-menu-item>
    <mat-icon>delete</mat-icon>
    <span>Delete</span>
  </button>
</mat-menu>

