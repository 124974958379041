<button mat-icon-button type="button" class="close-btn" (click)="modalService.closeSSL()">
    <mat-icon>close</mat-icon>
</button>
<p class="sample-manager-text-rrc">Sample List</p>

<div class="card tablesettings">
    <p-table
            [value]="SelfData.sendoutsamples[0].data"
            [showCurrentPageReport]="true"
            [scrollable]="true"
            [scrollHeight]="'calc(80vh - 70px)'"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [rowsPerPageOptions]="[10, 20, 50, 100]"
            [styleClass]="'p-datatable-striped mat-elevation-z3 p-datatable-sm'"
            selectionMode="single"
            [tableStyle]="{'min-width': '10rem'}">
        <ng-template pTemplate="header">
            <tr>
                <!--                    -->
                <th style="width:25.6%">Designer<br>Collection<br>Season</th>
                <th style="width:17.6%">Type<br>Color</th>
                <th style="width:15.6%">Image</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
            <tr [ngStyle]="{'background-color': product['isok'] == 0 ? '#ffdddd' : ''}" [pSelectableRow]="product">
                <td>{{product["Designer"]}}<br>{{product["Collection"]}}<br>{{product["Season"]}}</td>
                <td>{{product["Type"]}}<br>{{product["Color"]}}</td>
                <td><img [src]="product['Image Link']" alt="Image" class="image-size-80x80-ssl shadow-4"></td>
            </tr>
        </ng-template>
    </p-table>
</div>

