import {
  AfterViewInit, ChangeDetectorRef,
  Component, NgZone,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {ModalserviceService} from '../../services/modalservice.service';
import {FormBuilder, FormControl} from '@angular/forms';
import {HashService} from '../../services/hash.service';
import {MessageboxComponent} from '../../modals/messagebox/messagebox.component';
import {ServiceService} from '../../services/service.service';
import {YourappsserviceService} from '../../services/yourappsservice.service';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {
  formatDate,
  GetCCList,
  GetCCListData,
  GetCelebritiList,
  GetLBAccepts,
  GetlbSentListData,
  GetLinkedImages, GetScannedList,
  GetSendOutSamples,
  prefixZeros,
  RequestSampleRequest,
  SelfData,
  SendOut1Data,
  SendOutSampleRequest,
  SetContactsListData,
  SetSampleList,
  SetSampleListData,
  SetSelectedFiles,
  SetSelectedFilesV, transformImageUrl,
} from '../../models/struct';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {ConfirmationService, ConfirmEventType, MessageService} from 'primeng/api';
import {concatMap, from, Subscription} from "rxjs";
import {Table} from "primeng/table";
import {log} from "@angular-devkit/build-angular/src/builders/ssr-dev-server";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

interface OperationTypes {
  id: number;
  value: string;
}

@Component({
  selector: 'app-sendoutstep2',
  templateUrl: './sendoutstep2.component.html',
  styleUrls: ['./sendoutstep2.component.scss'],
})
export class Sendoutstep2Component implements OnInit, AfterViewInit, OnDestroy {
  sizes!: any[];
  @ViewChild(Table) dataTable?: Table;
  selectedSize: any = '';
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  @ViewChild(MatPaginator) paginator: MatPaginator | null = null;
  isButtonDisabled = true;
  // scannerControl = new FormControl<string>('');
  messages: { text: SafeHtml; color: string }[] = [];
  selectedsample: SetSampleListData[] = [];
  iLBAcceptsResult: GetLBAccepts[] = [];
  iSendOutSample: GetSendOutSamples[] = [];
  currentSample: SetSampleListData | null = null;
  selectedemail: string = ""
  selectedname: string = ""
  selectedkey: string = ""
  iScannedList: GetScannedList[] = [];
  selectedImageFiles: {
    file: File | null;
    url: string;
    filename: string;
    lbcatalogid: number;
    colection: string;
    designer: string;
    catalog: string;
    catalogyear: number;
    seasoncode: string;
    islinked: boolean;
    simpleid: number;
    lbmainid: number;
    description: string;
    barcode: string;
  }[] = [];
  color: string = '';
  selectedElement: any;
  displayedColumns: string[] = [
    'actions',
    'Designer',
    'Type',
    'NewField',
    'Description',
    'Image',
  ];
  operationtypes: OperationTypes[] = [
    {id: 0, value: 'Clear Queue'},
    {id: 1, value: 'Mark as Returned'},
    {id: 2, value: 'Mark as Written-off'},
    {id: 3, value: 'Mark as Gifted'},
    {id: 4, value: 'Mark as Returned to vendor'},
    {id: 5, value: 'Mark as Send to archive'},
    {id: 6, value: 'Return/EOL Comments'},

  ];
  // dataSource = new MatTableDataSource<SetSampleListData>(this.selectedsample);
  private subscription?: Subscription;
  private subscription1?: Subscription;
  private subscription2?: Subscription;
  private subscription3?: Subscription;
  private commentsubscription?: Subscription;
  private remsubscription?: Subscription;
  neededreturn = false;
  private tsendoutid: number = 0
  iLinkedImages: GetLinkedImages[] = []
  private keepAliveSubscription: number | undefined;

  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private hashService: HashService,
    public messageBox: MessageboxComponent,
    private serviceservice: ServiceService,
    private yourappsService: YourappsserviceService,
    private _snackBar: MatSnackBar,
    private cdRef: ChangeDetectorRef,
    private messageService: MessageService,
    private renderer: Renderer2,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef, private ngZone: NgZone,
    private confirmationService: ConfirmationService
  ) {
    this.tsendoutid = 0
    if (SelfData.scannedlist[0].data.length === 0) {
      SelfData.scannedlist = [{code: 0, errorMessage: '', data: []}];
    }

    if (SelfData.setSelectedSample.length > 0) {
      console.log("selected sample.1");
      this.selectedsample = SelfData.setSelectedSample
      this.neededreturn = this.selectedsample.some((sample: any) => sample["isok"] === 0);
    } else {
      this.selectedsample = [];
      console.log("selected sample.2");
    }
    this.sizes = [
      {name: 'Small', class: 'p-datatable-sm'}
    ];
    this.selectedSize = this.sizes[0]
    SelfData.scannlistshower = true
    // console.log("SelfData.scannedlist[0].data)", SelfData.scannedlist[0].data)
    // if (SelfData && SelfData.scannedlist && SelfData.scannedlist.length > 0 && SelfData.scannedlist[0].data && SelfData.scannedlist[0].data.length > 0) {
    //   for (const item of SelfData.scannedlist[0].data) {
    //     console.log("SelfData.scannedlist[0].data", item)
    //     // if (item.operid == 0) {
    //     const newSample: SetSampleListData = {
    //       "ID": item["ID"],
    //       "Inventory Status": item["Inventory Status"],
    //       "Gender": item["Gender"],
    //       "Sample Workflow Status": item["Sample Workflow Status"],
    //       "Account ID": item["accountid"],
    //       "Region": item["Region"],
    //       "Notes": item["Notes"],
    //       "Designer": item["Designer"],
    //       "Fabric": item["Fabric"],
    //       "Manufacturer": item["Manufacturer"],
    //       "Collection": item["Collection"],
    //       "Style": item["Style"],
    //       "Looks Associated": item["Looks Associated"],
    //       "Season": item["Season"],
    //       "Misc": item["Misc"],
    //       "Quantity": item["Quantity"],
    //       "Type": item["Type"],
    //       "Size": item["Size"],
    //       "Size Range": item["Size Range"],
    //       "Pattern": item["Pattern"],
    //       "Color": item["Color"],
    //       "Color Code": item["colorcode"],
    //       "Retail Price EUR": item["Retail Price EUR"],
    //       "Retail Price USD": item["Retail Price USD"],
    //       "Wholesale EUR": item["Wholesale EUR"],
    //       "Wholesale USD": item["Wholesale USD"],
    //       "Sale Price EUR": item["Sale Price EUR"],
    //       "Sale Price USD": item["Sale Price USD"],
    //       "Prices ID": item["pricesid"],
    //       "Description": item["Description"],
    //       "Storage ID": item["storageid"],
    //       "Registration Date": item["regdate"],
    //       "Current Date": item["curdate"],
    //       "User ID": item["userid"],
    //       "Is Active": item["isactiviti"],
    //       "Image Link": item["Image Link"],
    //       "barcode": item["barcode"],
    //       "User": item["user"],
    //       "Barcode Prefix": item["barcodeprefix"],
    //       "Selected Sample ID": item["selectedsampleid"],
    //       "itemcount": item["itemcount"] || 1,
    //       "itemstatus": item["itemstatus"] || 1,
    //     };
    //     // this.selectedsample.push(newSample);
    //     // console.log(this.selectedsample)
    //
    //   }
    //   // ... any other operations you need to perform on items
    //   // }
    //   // SelfData.setSelectedSample = this.selectedsample
    // } else if (SelfData && SelfData.requestlist && SelfData.requestlist.length > 0 && SelfData.requestlist[0].data && SelfData.requestlist[0].data.length > 0) {
    //   for (const item of SelfData.requestlist[0].data) {
    //     console.log("item[\"id\"]", item["id"])
    //     const newSample: SetSampleListData = {
    //       "ID": item["id"],
    //       "Inventory Status": item["Inventory Status"],
    //       "Gender": item["Gender"],
    //       "Sample Workflow Status": item["Sample Workflow Status"],
    //       "Account ID": item["accountid"],
    //       "Region": item["Region"],
    //       "Notes": item["Notes"],
    //       "Designer": item["Designer"],
    //       "Fabric": item["Fabric"],
    //       "Manufacturer": item["Manufacturer"],
    //       "Collection": item["Collection"],
    //       "Style": item["Style"],
    //       "Looks Associated": item["Looks Associated"],
    //       "Season": item["Season"],
    //       "Misc": item["Misc"],
    //       "Quantity": item["Quantity"],
    //       "Type": item["Type"],
    //       "Size": item["Size"],
    //       "Size Range": item["Size Range"],
    //       "Pattern": item["Pattern"],
    //       "Color": item["Color"],
    //       "Color Code": item["colorcode"],
    //       "Retail Price EUR": item["Retail Price EUR"],
    //       "Retail Price USD": item["Retail Price USD"],
    //       "Wholesale EUR": item["Wholesale EUR"],
    //       "Wholesale USD": item["Wholesale USD"],
    //       "Sale Price EUR": item["Sale Price EUR"],
    //       "Sale Price USD": item["Sale Price USD"],
    //       "Prices ID": item["pricesid"],
    //       "Description": item["Description"],
    //       "Storage ID": item["storageid"],
    //       "Registration Date": item["regdate"],
    //       "Current Date": item["curdate"],
    //       "User ID": item["userid"],
    //       "Is Active": item["isactiviti"],
    //       "Image Link": item["Image Link"],
    //       "barcode": item["barcode"],
    //       "User": item["user"],
    //       "Barcode Prefix": item["barcodeprefix"],
    //       "celebriti": item["celebriti"],
    //       "contactid": item["contactid"],
    //       "Selected Sample ID": item["selectedsampleid"],
    //       "itemcount": item["ItemCount"] || 1,
    //       "sendoutid": item["sendoutid"] || 0,
    //       "isaccepted": item["isaccepted"],
    //       "itemstatus": this.booleanToInt(item["isaccepted"])
    //     };
    //     // this.selectedsample.push(newSample);
    //   }
    // }
  }

  booleanToInt(value: boolean | undefined | null): number {
    return value ? 1 : 0;
  }

  selectSample(sample: SetSampleListData) {
    this.currentSample = sample;
  }

  checkMessageColor(colorcode: number): string {
    let color: string;
    if (colorcode === 0) {
      color = 'red';
    } else if (colorcode === 1) {
      color = 'green';
    } else {
      color = 'black'; // Default color
    }
    return color;
  }

  async sendMessage(message: string, code: number) {
    let color = this.checkMessageColor(code);
    let link = '';

    // Проверяем, содержит ли сообщение строку "not found in data.."
    if (message.includes('not found in data..')) {
      // Извлекаем ID образца из сообщения
      const sampleId = message.split(' ')[1].replace(/<\/?strong>/g, ''); // Удаляем теги <strong>

      // Создаем ссылку "Add Sample", которая вызывает функцию onButtonCreateSampleClick
      link = `<a href="#" class="add-sample-link" style="color: blue; font-weight: bold; text-decoration: underline;" onclick="window.angularComponentReference.onButtonCreateSampleClick('${sampleId}'); return false;">Add Sample</a>`;
    }

    // Проверяем, содержит ли сообщение строку "cannot be added to the list"
    if (message.includes('cannot be added to the list')) {
      // Извлекаем ID образца из сообщения и удаляем теги <strong>
      const sampleId = message.split(' ')[1].replace(/<\/?strong>/g, ''); // Удаляем теги <strong>

      // Ищем образец в selectedsample по полю barcode
      const foundSample = this.selectedsample.find(sample => sample['barcode'] === sampleId);

      if (foundSample) {
        console.log('Sample found:', foundSample);

        // Создаем ссылку для возврата и сканирования "Return & Scan"
        link = `<a href="#" class="add-sample-link" style="color: green; font-weight: bold; text-decoration: underline;" onclick="window.angularComponentReference.onButtonReturnScanClick(window.angularComponentReference.component.selectedsample.find(sample => sample.barcode === '${sampleId}')); return false;">Return & Scan</a>`;

        // Удаляем обработанный образец из списка, чтобы не обработать его повторно
        // this.selectedsample = this.selectedsample.filter(sample => sample['barcode'] !== sampleId);

      } else {
        console.log('Sample not found');
      }
    }

    // Создаем безопасное сообщение
    let safeMessage: SafeHtml = this.sanitizer.bypassSecurityTrustHtml(`${message} ${link}`);
    // Добавляем сообщение в начало массива сообщений
    this.messages.unshift({text: safeMessage, color: color});
    this.neededreturn = this.selectedsample.some((sample: any) => sample["isok"] === 0);
    await this.handleSendOutSamples();
  }


  onButtonScanClick() {
    if (this.scannerstring.trim() == '') {
      this.sendMessage('No valid barcode has been provided.', 0);
      return;
    }

    let barcodeValue = this.scannerstring;

    let foundSample = SelfData.sampledata.find((sample) => {
      return sample.data.some((sampleData: SetSampleListData) => {
        return sampleData['barcode'] === barcodeValue;
      });
    });

    if (foundSample) {
      // Find the data object with the matching barcode
      let foundData = foundSample.data.find(
        (sampleData) => sampleData['barcode'] === barcodeValue
      );

      if (foundData) {
        // Check if the barcode is already in the selectedsample
        let isBarcodeInSelectedSample = this.selectedsample.some(
          (sampleData) => sampleData['barcode'] === barcodeValue
        );

        // If it's not in the selectedsample, add it
        if (!isBarcodeInSelectedSample) {
          this.sendMessage(
            `Sample <strong>${barcodeValue}</strong> added to your temporary samples list.`,
            1
          );
          // this.selectedsample.push(foundData);
          this.cdRef.markForCheck();
          this.selectedsample = this.selectedsample.concat(foundData);
          // this.dataTable?.reset();
          // this.dataSource = new MatTableDataSource<SetSampleListData>(
          //   this.selectedsample
          // );
          console.log(this.selectedsample)
          SelfData.setSelectedSample = this.selectedsample
          // this.dataSource.paginator = this.paginator;
        } else {
          //Sample TTA0008215 already scanned by user Tata Kartvelishvili for a new delivery or reservation.
          this.sendMessage(
            // `Sample <strong>${barcodeValue}</strong> already scanned by user <strong>${foundData['user']}</strong> for a new delivery or reservation.`,
            `Sample <strong>${barcodeValue}</strong> already in the list.`,
            0
          );
        }
      }
    } else {
      this.sendMessage(
        `Sample <strong>${barcodeValue}</strong> not found in data..`,
        0
      );
    }

    this.scannerstring = "";

    // console.log(SelfData.sampledata)
  }

  show() {
    // this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Message Content' });
    this.messageService.add({
      key: 'tc',
      severity: 'error',
      summary: 'Information',
      detail: 'Message Content',
      life: 1000,
    });
  }

  onSearch1() {
    // Loop over each item in checkedsample
    SelfData.checkedsample.forEach((checkedSample) => {
      let barcodeValue = checkedSample['barcode'];

      let foundSample = SelfData.sampledata.find((sample) => {
        return sample.data.some((sampleData: SetSampleListData) => {
          return sampleData['barcode'] === barcodeValue;
        });
      });

      if (foundSample) {
        // Find the data object with the matching barcode
        let foundData = foundSample.data.find(
          (sampleData) => sampleData['barcode'] === barcodeValue
        );

        if (foundData) {
          // Check if the barcode is already in the selectedsample
          let isBarcodeInSelectedSample = this.selectedsample.some(
            (sampleData) => sampleData['barcode'] === barcodeValue
          );

          // If it's not in the selectedsample, add it
          if (!isBarcodeInSelectedSample) {
            this.sendMessage(
              `Sample <strong>${barcodeValue}</strong> added to your temporary samples list.`,
              1
            );
            // this.selectedsample.push(foundData);
            this.cdRef.markForCheck();
            this.selectedsample = this.selectedsample.concat(foundData);
            // this.dataTable?.reset();
            SelfData.setSelectedSample = this.selectedsample
            this.neededreturn = this.selectedsample.some((sample: any) => sample["isok"] === 0);
            // this.dataSource = new MatTableDataSource<SetSampleListData>(
            //   this.selectedsample
            // );
            console.log(this.selectedsample)
            // this.dataSource.paginator = this.paginator;
          } else {
            // Sample TTA0008215 already scanned by user Tata Kartvelishvili for a new delivery or reservation.
            this.sendMessage(
              // `Sample <strong>${barcodeValue}</strong> already scanned by user <strong>${foundData['user']}</strong> for a new delivery or reservation.`,
              `Sample <strong>${barcodeValue}</strong> already in the list.`,
              0
            );
          }
        }
      } else {
        this.sendMessage(
          `Sample <strong>${barcodeValue}</strong> not found in data..`,
          0
        );
      }
    });
  }

  async onSearch2(barcode: string) {
    // Loop over each item in checkedsample
    let barcodeValue = barcode;
    let foundData: SetSampleListData | undefined = []

    console.log("SelfData.sampledata", SelfData.sampledata)

    let foundSample = SelfData.sampledata.find((sample) => {
      return sample.data.some((sampleData: SetSampleListData) => {
        return sampleData['barcode'] === barcodeValue;
      });
    });

    if (foundSample) {
      // let id = (foundSample as any)[0].data.selectedsampleid;
      // this.serviceservice
      //   .getCheckSample({ sampleid: id })
      //   .subscribe((value) => {
      //     console.log("value", value)
      //   });
      // Find the data object with the matching barcode
      foundData = foundSample.data.find(
        (sampleData) => sampleData['barcode'] === barcodeValue
      );

      if (foundData) {
        let selectedSampleId = foundData["Selected Sample ID"];

        // Check if the barcode is already in the selectedsample
        let isBarcodeInSelectedSample = this.selectedsample.some(
          (sampleData) => sampleData['barcode'] === barcodeValue
        );

        // If it's not in the selectedsample, add it
        console.log("isBarcodeInSelectedSample", isBarcodeInSelectedSample, "selectedSampleId", selectedSampleId)
        if (!isBarcodeInSelectedSample) {
          this.serviceservice
            .getCheckSample({sampleid: selectedSampleId})
            .subscribe((value) => {
              if (value.Code !== 1 && SelfData.sendoutmode === 0) {
                this.messageService.add({
                  key: 'tc',
                  severity: 'error',
                  summary: 'Information',
                  // detail: `Sample ${barcodeValue} already scanned by user ${foundData['user']} for a new delivery or reservation.`,
                  detail: `Sample ${barcodeValue} cannot be added to the list, reason: ` + value.ErrorMessage,
                  life: 1000,
                });
                this.sendMessage(
                  `Sample <strong>${barcodeValue}</strong> cannot be added to the list, reason: <strong>` + value.ErrorMessage + `</strong>`,
                  0
                );
                if (foundData) {
                  foundData["isok"] = 0
                  foundData["sendoutid"] = value.data[0]?.["sendoutid"]
                }
                this.neededreturn = this.selectedsample.some((sample: any) => sample["isok"] === 0);
                // return
              } else {
                this.sendMessage(
                  `Sample <strong>${barcodeValue}</strong> added to your temporary samples list.`,
                  1
                );
                this.messageService.add({
                  key: 'tc',
                  severity: 'success',
                  summary: 'Success',
                  detail: `Sample ${barcodeValue} added to your temporary samples list.`,
                  life: 1000,
                });
                this.serviceservice
                  .deletefromscannlist({
                    ctrl: 1,
                    userid: 0,
                    accountid: this.getAccountID(),
                    barcode: barcode
                  })
                  .subscribe((value) => {
                    this.serviceservice
                      .scannedlist({
                        accountid: this.getAccountID()
                      })
                      .subscribe((value) => {
                        this.iScannedList[0] = value;
                        SelfData.scannedfulllist = this.iScannedList;
                      });
                  });
              }
            });
          console.log("foundData", foundData)
          if (barcode != "") {
            this.serviceservice
              .deletefromscannlist({
                ctrl: 1,
                userid: 0,
                accountid: this.getAccountID(),
                barcode: barcode
              })
              .subscribe((value) => {
                this.serviceservice
                  .scannedlist({
                    accountid: this.getAccountID()
                  })
                  .subscribe((value) => {
                    this.iScannedList[0] = value;
                    SelfData.scannedfulllist = this.iScannedList;
                  });
              });
          }
          this.cdRef.markForCheck();
          this.selectedsample = this.selectedsample.concat(foundData);
          SelfData.setSelectedSample = this.selectedsample

          // this.dataSource = new MatTableDataSource<SetSampleListData>(
          //   this.selectedsample
          // );
          // this.dataSource.paginator = this.paginator;
        } else {
          // Sample TTA0008215 already scanned by user Tata Kartvelishvili for a new delivery or reservation.
          this.sendMessage(
            // `Sample <strong>${barcodeValue}</strong> already scanned by user <strong>${foundData['user']}</strong> for a new delivery or reservation.`,
            `Sample <strong>${barcodeValue}</strong> already in the list.`,
            0
          );
          this.messageService.add({
            key: 'tc',
            severity: 'error',
            summary: 'Information',
            // detail: `Sample ${barcodeValue} already scanned by user ${foundData['user']} for a new delivery or reservation.`,
            detail: `Sample ${barcodeValue} already in the list.`,
            life: 1000,
          });
        }
      }
    } else {
      this.sendMessage(
        `Sample <strong>${barcodeValue}</strong> not found in data..`,
        0
      );
    }

    if (SelfData.setSelectedSample.length > 0) {
      this.selectedsample = SelfData.setSelectedSample
      this.cdRef.detectChanges();
      console.log(this.selectedsample)
    }
  }

  innerButtonClicked() {
    this.messageService.add({
      key: 'tc',
      severity: 'error',
      summary: 'Information',
      detail: 'Message Content',
    });
  }

  getDeepProperty(obj: any, path: string[]): any {
    return path.reduce((o, p) => (o && o[p] ? o[p] : null), obj);
  }


  removeSelectedSample(barcodeValue: string) {
    if (SelfData.sendoutmode === 0) {
      if (!Array.isArray(this.selectedsample)) {
        console.error('selectedsample is not an array:', this.selectedsample);
        return;
      }

      let index = this.selectedsample.findIndex(
        (sampleData) => sampleData['barcode'] === barcodeValue
      );

      // Safely retrieve the data list
      const dataList = this.getDeepProperty(SelfData, ['scannedlist', '0', 'data']);

      let index1 = -1; // Initialize with a default value
      if (Array.isArray(dataList)) {
        index1 = dataList.findIndex((sampleData) => sampleData['barcode'] === barcodeValue);
      } else {
        console.log('Data list is not an array:', dataList);
        // return;
      }

      SelfData.selselectedIndex = index;
      SelfData.selselectedIndex1 = index1;

      console.log("SelfData", SelfData.selselectedIndex);

      if (index !== -1) {
        const record = this.selectedsample[index];
        SelfData.selDeleteSimpleID = record?.["ID"];
      }

      SelfData.selectedbarcode = barcodeValue;

      this.modalService.openDeleteFrame();
    } else if (SelfData.sendoutmode === 1) {
      this.showdialog = true
      this.cdRef.detectChanges()
      let selectedItem = this.selectedsample.find(sample => sample?.['barcode'] === barcodeValue);
      this.confirmationService.confirm({
        message: `<strong>Do you want to remove this item from the list?</strong>`,
        header: 'Remove item',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          if (selectedItem) {
            selectedItem["itemstatus"] = 0;
            selectedItem["isaccepted"] = false;
            // Here you might want to update the state of your application or trigger some update logic
          }

          this.showdialog = false
          this.cdRef.detectChanges()
        },
        reject: (type: any) => {
          if (type == ConfirmEventType.REJECT) {
            this.showdialog = false
            this.cdRef.detectChanges()

          } else if (type == ConfirmEventType.CANCEL) {
            this.showdialog = false
            this.cdRef.detectChanges()
          }
        }

      });

      setTimeout(() => {
        this.applyLocalStyles();
      }, 0);
    }
  }

  restoreSelectedSample(barcodeValue: string) {
    let selectedItem = this.selectedsample.find(sample => sample?.['barcode'] === barcodeValue);
    this.showdialog = true
    this.cdRef.detectChanges()
    this.confirmationService.confirm({
      message: `<strong>Do you want to restore this item the list?</strong>`,
      header: 'Restore item',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        if (selectedItem) {
          selectedItem["itemstatus"] = 1;
          selectedItem["isaccepted"] = true;
          // Here you might want to update the state of your application or trigger some update logic
        }
        this.showdialog = false
        this.cdRef.detectChanges()
      },
      reject: (type: any) => {
        if (type == ConfirmEventType.REJECT) {
          this.showdialog = false
          this.cdRef.detectChanges()
        } else if (type == ConfirmEventType.CANCEL) {
          this.showdialog = false
          this.cdRef.detectChanges()
        }
      }
    });

    setTimeout(() => {
      this.applyLocalRestoreStyles();
    }, 0);
  }

  private applyLocalStyles() {
    setTimeout(() => {
      const messageElements = document.querySelectorAll('.p-confirm-dialog-message');
      messageElements.forEach((element) => {
        element.setAttribute('style', 'font-size: 16px; color: red;');
      });
    }, 0);

    const iconElements = document.querySelectorAll('.p-confirm-dialog-icon');
    iconElements.forEach((element) => {
      element.setAttribute('style', 'color: red');
    });
  }

  private applyLocalRestoreStyles() {
    setTimeout(() => {
      const messageElements = document.querySelectorAll('.p-confirm-dialog-message');
      messageElements.forEach((element) => {
        element.setAttribute('style', 'font-size: 16px; color: #343a40;');
      });
    }, 0);

    const iconElements = document.querySelectorAll('.p-confirm-dialog-icon');
    iconElements.forEach((element) => {
      element.setAttribute('style', 'color: #343a40');
    });
  }

  removefromtable(barcode: string) {
    let index = this.selectedsample.findIndex(sampleData => sampleData['barcode'] === barcode);
    if (index !== -1) {
      this.selectedsample.splice(index, 1);
      SelfData.setSelectedSample = this.selectedsample;
      this.selectedsample = [...this.selectedsample];
      // this.dataSource = new MatTableDataSource<SetSampleListData>(this.selectedsample);
      // this.dataSource.paginator = this.paginator;
      if (!this.currentSample) {
        this.currentSample = this.selectedsample.find(sample => sample['barcode'] === barcode) || null;
      }

      // console.log("this.currentSample >>>>>>>>>>>>>>>>>>", this.currentSample)

      this.serviceservice.prcsendoutreturnupdate({
        ctrl: 3,
        sampleid: this.currentSample?.["ID"],
        sendoutid: SelfData.sendoutid,
        sendouttype: SelfData.operid,
        samplestatus: this.returnsamplestatus(SelfData.operid),
        comment: "",
        amount: this.currentSample?.["amount"],
        discount: this.currentSample?.["discount"],
        totalprice: this.currentSample?.["totalprice"]
      }).subscribe(() => {

      });
      console.log('Sample removed: ' + barcode);
      this.sendMessage(
        `Sample <strong>${barcode}</strong> Removed from list.`,
        0
      );
      console.log(this.selectedsample)
    } else {
      console.log('Sample not found: ' + barcode);
    }

    if (Array.isArray(SelfData.scannedlist[0]?.data)) {
      let index1 = SelfData.scannedlist[0].data.findIndex(sampleData => sampleData.barcode === barcode);
      if (index1 !== -1) {
        SelfData.scannedlist[0].data.splice(index1, 1);
      }
    } else {
      console.error('SelfData.scannedlist[0].data is not an array:', SelfData.scannedlist[0]?.data);
      // Initialize as an array or handle this case appropriately
    }
  }

  onButtonSearch1Click() {
    if (SelfData.checkedsample.length == 0) {
      // this.openSnackBar()
      this.messageService.add({
        key: 'tc',
        severity: 'error',
        summary: 'Information',
        detail:
          'The “Queued Samples” will be empty until you check something as a “Sample” and click the “Search” button',
        life: 1000,
      });
    } else {
      this.onSearch1();
    }
  }

  onButtonSearch2Click() {
    if (SelfData.checkedsample.length == 0) {
      // this.openSnackBar()
      this.messageService.add({
        key: 'tc',
        severity: 'error',
        summary: 'Information',
        detail:
          'The “Queued Samples” will be empty until you check something as a “Sample” and click the “Search” button',
        life: 1000,
      });
    } else {
      this.modalService.createssopen();
    }
  }

  public get setdataSource() {
    return null;
  }

  ngAfterViewInit(): void {
    // this.dataSource = new MatTableDataSource<SetSampleListData>(
    //   this.selectedsample
    // );
    console.log(this.selectedsample)
    // this.dataSource.paginator = this.paginator;
  }

  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
      ? SelfData.account[0].data[0].id
      : SelfData.account[0].data[0].parentid;
  }

  async ngOnDestroy(): Promise<void> {
    this.unsubscribeAll();

    console.log('SelfData.saveonclose', this.SelfData.saveonclose);
    console.log('SelfData.sendoutid', this.SelfData.sendoutid);
    console.log('this.selectedsample', this.selectedsample);
    this.tsendoutid = SelfData.sendoutid;
    if (this.SelfData.saveonclose) {
      try {
        console.log("this.SelfData.sendoutmode", this.SelfData.sendoutmode)
        if (this.SelfData.sendoutmode === 0) {
          await this.handleSendOutSamples();
          this.selectedsample = [];
          // SelfData.setSelectedSample = [];
          // SendOut1Data.id = 0
          // SelfData.sendoutid = 0
          // SendOut1Data.reset()
        } else if (this.SelfData.sendoutmode === 1) {
          await this.handleRequestSamples();
          this.selectedsample = [];
          // SelfData.setSelectedSample = [];
          // SendOut1Data.id = 0
          // SelfData.sendoutid = 0
          // SendOut1Data.reset()
        }
      } catch (err) {
        console.error('Error in handleSendOutSamples or handleRequestSamples:', err);
      }
    }


    // Use NgZone to ensure changes are detected correctly
    this.ngZone.run(() => {
      this.cdr.detectChanges();
    });
  }

  private unsubscribeAll() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.subscription1) {
      this.subscription1.unsubscribe();
    }
    if (this.subscription2) {
      this.subscription2.unsubscribe();
    }
    if (this.subscription3) {
      this.subscription3.unsubscribe();
    }
    if (this.remsubscription) {
      this.remsubscription.unsubscribe();
    }

    if (this.commentsubscription) {
      this.commentsubscription.unsubscribe();
    }
  }

  handleSendOutSamples(): Promise<void> {
    if (this.keepAliveSubscription) {
      clearInterval(this.keepAliveSubscription);
    }
    console.log("handleSendOutSamples Begin", this.selectedsample)
    return new Promise((resolve, reject) => {
      const requestData: SendOutSampleRequest = {
        ctrl: 2,
        sampleid: 0,
        accountid: this.getAccountID(),
        userid: this.SelfData.account[0].data[0].id,
        sendoutid: this.tsendoutid,
        itemcount: 0
      };

      this.serviceservice.prcSendOutSamples(requestData).subscribe({
        next: (response) => {
          console.log('Initial send out sample request response:', response);
          console.log("handleSendOutSamples Next", this.selectedsample)

          from(this.selectedsample).pipe(
            concatMap(sample => {
              const requestData: SendOutSampleRequest = {
                ctrl: 0,
                sampleid: sample?.["ID"],
                accountid: this.getAccountID(),
                userid: this.SelfData.account[0].data[0].id,
                sendoutid: this.tsendoutid,
                itemcount: sample?.["itemcount"]
              };

              console.log('Requesting send out sample:', requestData);

              return this.serviceservice.prcSendOutSamples(requestData);
            })
          ).subscribe({
            next: (response) => {
              const targetItem = SelfData.sendoutlist[0].data.find(item => item.id === this.tsendoutid);

              if (targetItem) {
                // Update the `itemcount` to 10 and `completed` to true
                targetItem.itemscount = 0;
                targetItem.completed = false;
              } else {
                // console.error('Item with the given ID was not found');
              }
            },
            error: (err) => {
              console.error('Error in send out sample request:', err);
              reject(err);
            },
            complete: () => {
              console.log('All send out sample requests completed.'+this.tsendoutid);
              const targetItem = SelfData.sendoutlist[0].data.find(item => item.id === this.tsendoutid);

              console.log("SelfData.sendoutlist[0].data", SelfData.sendoutlist[0].data)
              if (targetItem) {
                // Update the `itemcount` to 10 and `completed` to true
                targetItem.itemscount = this.selectedsample.length;
                targetItem.completed = true;
              } else {
                 // console.error('Item with the given ID was not found');
              }
              this.ngZone.run(() => {
                this.cdr.detectChanges();
                resolve();
              });
            }
          });
        },
        error: (err) => {
          console.error('Error in initial send out sample request:', err);
          reject(err);
        }
      });
    });
  }

  handleRequestSamples(): Promise<void> {
    return new Promise((resolve, reject) => {
      const requestData: RequestSampleRequest = {
        ctrl: 2,
        sampleid: 0,
        accountid: this.getAccountID(),
        userid: this.SelfData.account[0].data[0].id,
        sendoutid: this.tsendoutid,
        itemcount: 0,
        isaccepted: false
      };

      this.serviceservice.prcRequestSamples(requestData).subscribe(response => {
        from(this.selectedsample).pipe(
          concatMap(sample => {
            const requestData: RequestSampleRequest = {
              ctrl: 0,
              sampleid: sample?.["ID"],
              accountid: this.getAccountID(),
              userid: this.SelfData.account[0].data[0].id,
              sendoutid: this.tsendoutid,
              itemcount: sample?.["itemcount"],
              isaccepted: sample?.["isaccepted"]
            };

            console.log('sample', sample);

            return this.serviceservice.prcRequestSamples(requestData);
          })
        ).subscribe({
          next: (response) => {
            console.log('Request sample response:', response);
          },
          error: (err) => {
            console.error('Error in request sample:', err);
            reject(err);
          },
          complete: () => {
            console.log('All request sample operations completed.');
            const fields = {
              ctrl: 2,
              id: this.tsendoutid,
              check1: false,
              check2: false,
              check3: false,
              department: '',
              ship: '',
              shipto: '',
              tod: '',
              pe: '',
              companyname: '',
              addresslabel: '',
              costofshipping: '',
              cim: '',
              publication: '',
              estylist: '',
              photographer: '',
              pel: '',
              pet: '',
              issue: '',
              trackingNumber: '',
              return: '',
              notes: '',
              sender: '',
              requestedby: '',
              sendOutType: '',
              currancy: '',
              reservationdate: SendOut1Data.reservationdateControl,
              returndate: SendOut1Data.returndateControl,
              datetimeCtrl: SendOut1Data.datetimeCtrl,
              datetimeCtrl1: SendOut1Data.datetimeCtrl1,
              datetimeCtrl2: SendOut1Data.datetimeCtrl2,
              datetimeCtrl3: SendOut1Data.datetimeCtrl3,
              datetimeCtrl4: SendOut1Data.datetimeCtrl4,
              accountid: this.getAccountID(),
              userid: this.SelfData.account[0].data[0].id,
              sendouttypeid: this.SelfData.selectedSendouttype,
              isactivity: true,
              celebriti: '',
              sendoutid: 0,
              isaccepted: false
            };

            this.serviceservice.prcRequest(fields).subscribe(value => {
              this.SelfData.sendoutid = value.data;
              this.SelfData.saveonclose = true;
              this.SelfData.isacceptedstatus = false;
              this.yourappsService.getReloadRequestList();
              this.ngZone.run(() => {
                this.cdr.detectChanges();
                resolve();
              });
            });
          }
        });
      });
    });
  }

  onViewButtonClick(element: any) {
    SelfData.sampleselecteddata = element;
    SelfData.updatebuttonisenabled = false
    this.yourappsService.selectSampleData(element);
    this.modalService.createsampleropen();
  }

  onButtonCreateSampleClick(barcode: string = "") {
    SelfData.sampledata = [];
    SelfData.updatebuttonisenabled = true
    console.log(barcode)
    this.yourappsService.selectSampleData(null);
    this.modalService.createsampleropen();
  }

  onButtonInventaryClick() {
    this.yourappsService.setSelectDesigner(null, this.selectedsample)
    this.modalService.inventaryopen();
  }

  onButtonDesignerClick() {
    // console.log(this.currentSample)
    this.yourappsService.setSelectDesigner(this.currentSample, this.selectedsample)
    this.modalService.inventaryopen();
  }

  onButtonReturnScanClick(currentSample: SetSampleListData | null) {
    this.showdialog = true
    this.cdRef.detectChanges()
    this.confirmationService.confirm({
      message: `<strong>Do you want to return and scan for a new send out?</strong>`,
      header: 'This item is on loan!',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.showdialog = true
        this.cdRef.detectChanges()
        console.log("currentSample", currentSample)
        if (currentSample) {
          // if (!this.currentSample) {
            this.currentSample = this.selectedsample.find(sample => sample['ID'] === currentSample["ID"]) || null;
          // }
          console.log("this.currentSample", this.currentSample)
          if (this.currentSample && SelfData.sendoutmode === 0) {
            this.currentSample["isok"] = 1
          }
          this.serviceservice.prcsendoutreturnupdate({
            ctrl: 1,
            sampleid: this.currentSample?.["ID"],
            sendoutid: this.currentSample?.["sendoutid"],
            sendouttype: SelfData.operid,
            samplestatus: this.returnsamplestatus(SelfData.operid),
            comment: "",
            amount: this.currentSample?.["amount"],
            discount: this.currentSample?.["discount"],
            totalprice: this.currentSample?.["totalprice"]
          }).subscribe(() => {
            this.sendMessage(
              `Sample <strong>${this.currentSample?.["barcode"]}</strong> added to your temporary samples list.`,
              1
            );
            this.messageService.add({
              key: 'tc',
              severity: 'success',
              summary: this.operationtypes[1].value,
              detail: "Sample " + this.currentSample?.["barcode"] + " has been " + this.operationtypes[1].value + " on delivery " + this.currentSample?.["Barcode Prefix"] + "-" + this.currentSample?.["sendoutid"],
              life: 1000,
            });
            this.yourappsService.getFooterText("")
          });
          // SelfData.opertext = "You are about to mark sample as Returned"
          // SelfData.operid = 1
          // this.modalService.openRRC()
        }
        this.showdialog = false
        this.cdRef.detectChanges()
      },
      reject: (type: any) => {
        if (type == ConfirmEventType.REJECT) {
          this.showdialog = false
          this.cdRef.detectChanges()
        } else if (type == ConfirmEventType.CANCEL) {
          this.showdialog = false
          this.cdRef.detectChanges()
        }
      }
    });
  }

  onPrintBarcodeClick(barcodeValue: string) {
    SelfData.selectadpage = 4
    SelfData.selectedbarcode = barcodeValue
    SelfData.setSample = []
    if (this.currentSample) {
      SelfData.setSample.push(this.currentSample);
    }
    this.modalService.openPrinterFrame()
  }

  returnsamplestatus(value: number): string {
    // if (this.selectedsample.length > 0) {
    if (value === 1) {
      const currentDateTime = new Date();
      return "Returned on " + formatDate(currentDateTime) + " by " + SelfData.account[0].data[0].firstname + " " + SelfData.account[0].data[0].lastname;
    } else if (value === 2) {
      return "Written-off";
    } else if (value === 3) {
      return "Gifted";
    } else if (value === 4) {
      return "Returned to vendor";
    } else if (value === 5) {
      return "Send to Archive";
    }
    // }
    return "On Loan"
  }

  iSampleDataResult: SetSampleList[] = [];

  sendKeepAliveRequest(): void {
    this.serviceservice.setSampleList({ accountid: this.getAccountID(), page: 1, pageSize: 1, forceRefresh: false, samplestyle: SelfData.samplestyle }).subscribe({
      next: (value) => {
        if (value) {
          console.log("value",value)
        } else {
          console.log('The response is empty or undefined.');
        }
      },
      error: (error) => {
        console.error('Error occurred during the subscription:', error);
      },
    });
  }

  ngOnInit(): void {
    // this.keepAliveSubscription = setInterval(() => {
    //   this.sendKeepAliveRequest();
    // }, 300000); // 5 минут
    (window as any).angularComponentReference = {
      component: this,
      onButtonCreateSampleClick: (sampleId: string) => this.onButtonCreateSampleClick(sampleId),
      onButtonReturnScanClick: (foundSample: SetSampleListData) => this.onButtonReturnScanClick(foundSample),
    };
    // (window as any).angularComponentReference = {
    //   component: this,
    //   onButtonReturnScanClick: (sampleId: SetSampleListData | null) => this.onButtonReturnScanClick(sampleId),
    // };
    // this.SelfData.saveonclose = false;
    this.hidden = SelfData.scannedfulllist[0].data?.length === 0
    this.commentsubscription = this.yourappsService.reloadSendOutReturnList.subscribe((comment) => {
      if (this.commentsubscription) {
        if (this.currentSample && SelfData.sendoutmode === 0) {
          this.currentSample["isok"] = 1
        }
        this.serviceservice.prcsendoutreturnupdate({
          ctrl: 1,
          sampleid: this.currentSample?.["ID"],
          sendoutid: this.currentSample?.["sendoutid"],
          sendouttype: SelfData.operid,
          samplestatus: this.returnsamplestatus(SelfData.operid),
          comment: comment.trim(),
          amount: this.currentSample?.["amount"],
          discount: this.currentSample?.["discount"],
          totalprice: this.currentSample?.["totalprice"]
        }).subscribe(() => {
          this.messageService.add({
            key: 'tc',
            severity: 'success',
            summary: this.operationtypes[SelfData.operid].value,
            detail: "Sample " + this.currentSample?.["barcode"] + " has been " + this.operationtypes[SelfData.operid].value + " on delivery " + this.currentSample?.["barcodeprefix"] + "-" + this.currentSample?.["sendoutid"],
            life: 1000,
          });
          this.yourappsService.getFooterText("")
        });
      }
    });

    this.subscription = this.yourappsService.selectedSample$.subscribe((barcode: string) => {
      if (this.subscription) {
        if (barcode != '') {
          console.log("Log1")
          this.onSearch2(barcode);
          barcode = '';
        }
      }
    });

    this.remsubscription = this.yourappsService.removedSample$.subscribe((barcode: string) => {
      console.log("barcode", barcode);
      if (barcode != '') {
        this.removefromtable(barcode);
      }
    });

    // this.yourappsService.addSampleID$.subscribe((id) => {
    //   if (id > 0) {
    //     var accountid: number = 0;
    //     if (SelfData.account[0].data[0].parentid == 0) {
    //       accountid = SelfData.account[0].data[0].id;
    //     } else {
    //       accountid = SelfData.account[0].data[0].parentid;
    //     }
    //     this.serviceservice.setSampleList({accountid: accountid, page: 1, pageSize: 1000000, forceRefresh: true}).subscribe({
    //       next: (value) => {
    //         if (value) {
    //           this.iSampleDataResult[0] = value;
    //           SelfData.sampledata = this.iSampleDataResult;
    //           var barcodeprefix: string =
    //             SelfData.account[0].data[0].barcodeprefix;
    //           let barcode = barcodeprefix + prefixZeros(id);
    //           console.log("Log2")
    //           this.onSearch2(barcode);
    //         } else {
    //           console.log('The response is empty or undefined.');
    //         }
    //       },
    //       error: (error) => {
    //         console.error('Error occurred during the subscription:', error);
    //       },
    //     });
    //   }
    // });
    // if (SelfData.sendoutopentype === 0) {
    //   SelfData.setSelectedSample = []
    // }
    console.log(">>>>2", SelfData.setSelectedSample, SelfData.setSelectedSample.length, SendOut1Data.length)
    this.selectedsample = [];
    if (SelfData.setSelectedSample.length > 0) {
      this.selectedsample = SelfData.setSelectedSample
      console.log(this.selectedsample)
    }

    this.serviceservice
      .scannedlist({
        accountid: this.getAccountID()
      })
      .subscribe((value) => {
        this.iScannedList[0] = value;
        SelfData.scannedfulllist = this.iScannedList;
        this.hidden = SelfData.scannedfulllist[0].data?.length === 0
      });
    // this.cdRef.detectChanges();
    SelfData.closesendout = false
  }

  onButtonLookClick() {
    this.modalService.lookopen()
  }

  openSnackBar() {
    // Get the email address from the account data.
    const email = SelfData.account[0].data[0].email;

    // Open the snack bar.
    this._snackBar.open(
      'The “Queued Samples” will be empty until you check something as a “Sample” and click the “Search” button.',
      '',
      {
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        duration: 5000,
        panelClass: ['red-snackbar'],
      }
    );
  }

  protected readonly customElements = customElements;
  protected readonly SelfData = SelfData;
  showdialog: boolean = false;

  onButtonReturnAllCLick() {
    this.showdialog = true
    this.cdRef.detectChanges()
    this.confirmationService.confirm({
      message: `<strong>Are you sure you want to return all the Samples?</strong>`,
      header: 'Return All Samples',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.showdialog = true
        this.cdRef.detectChanges()
        this.selectedsample.forEach((sample: any) => {
          if (sample["isok"] === 0) {
            this.currentSample = sample;  // Устанавливаем текущий образец

            sample["isok"] = 1;

            this.serviceservice.prcsendoutreturnupdate({
              ctrl: 1,
              sampleid: sample?.["ID"],
              sendoutid: sample?.["sendoutid"],
              sendouttype: SelfData.operid,
              samplestatus: this.returnsamplestatus(SelfData.operid),
              comment: "",
              amount: sample?.["amount"],
              discount: sample?.["discount"],
              totalprice: sample?.["totalprice"]
            }).subscribe(() => {
              this.sendMessage(
                `Sample <strong>${sample?.["barcode"]}</strong> added to your temporary samples list.`,
                1
              );
              this.messageService.add({
                key: 'tc',
                severity: 'success',
                summary: this.operationtypes[1].value,
                detail: "Sample " + sample?.["barcode"] + " has been " + this.operationtypes[1].value + " on delivery " + sample?.["Barcode Prefix"] + "-" + sample?.["sendoutid"],
                life: 1000,
              });
              this.yourappsService.getFooterText("");
            });
          }
        });
        this.showdialog = false
        this.cdRef.detectChanges()
      },
      reject: (type: any) => {
        if (type == ConfirmEventType.REJECT) {
          this.showdialog = false
          this.cdRef.detectChanges()
        } else if (type == ConfirmEventType.CANCEL) {
          this.showdialog = false
          this.cdRef.detectChanges()
        }
      }
    });
  }
  onButtonLookBookClick() {
    this.showdialog = false
    this.cdRef.detectChanges()
    this.subscription1 = this.yourappsService.retSelectedFiles$.subscribe((value: {
      data: GetlbSentListData | GetlbSentListData[],
      type: number
    }) => {
      if (this.subscription1) {
        this.subscription1.unsubscribe();
      }

      // Check if value.data is an object and not an array
      if (value.data && !Array.isArray(value.data) && value.type === 1) {
        this.selectedImageFiles = []

        SelfData.tlbmainid = value.data.lbmainid
        SelfData.lbkey = value.data.key
        this.selectedemail = value.data.email
        this.selectedname = value.data.name
        this.selectedkey = value.data.key
        console.log("value.data", value.data)

        this.serviceservice.selectlbaccepts({lbmainid: value.data.lbmainid, key: value.data.key})
          .subscribe((value) => {
            this.iLBAcceptsResult[0] = value
            SelfData.lbaccepts = this.iLBAcceptsResult;

            this.serviceservice
              .selectlinkedimages({accountid: this.getAccountID()})
              .subscribe((value) => {
                this.iLinkedImages[0] = value;
                this.SelfData.linkedimages = this.iLinkedImages;
                console.log("this.SelfData.linkedimages", this.iLinkedImages)
                this.iLBAcceptsResult[0].data.forEach(acceptResult => {
                  // Create a new object with properties from acceptResult
                  let newFile = {
                    file: null,
                    url: acceptResult.filedir,
                    filename: acceptResult.filename,
                    lbcatalogid: acceptResult.lbcatalogid,
                    colection: acceptResult.colection,
                    designer: acceptResult.designer,
                    catalog: acceptResult.catalog,
                    catalogyear: acceptResult.catalogyear,
                    seasoncode: "",
                    islinked: false, // Default to false
                    simpleid: -1,
                    lbmainid: acceptResult.lbmainid,
                    description: acceptResult.description,
                    barcode: ""
                  };

                  // Check if there's a corresponding item in this.iLinkedImages
                  const linkedImage = this.iLinkedImages[0].data.find(image => image.filename === acceptResult.filename);

                  // Update islinked and simpleid if linkedImage is found
                  if (linkedImage && this.iLinkedImages[0].data.length > 0) {
                    newFile.islinked = true;
                    newFile.simpleid = linkedImage.sampleid;
                    newFile.barcode = linkedImage.barcode
                  }

                  if (linkedImage?.sampleid) {
                    console.log("linkedImage.sampleid", linkedImage?.sampleid)
                    const existingFileIndex = this.selectedImageFiles.findIndex(file => file.simpleid === newFile.simpleid);
                    if (existingFileIndex === -1) {
                      this.selectedImageFiles.push(newFile);
                    } else {
                      this.selectedImageFiles[existingFileIndex].islinked = true;
                      this.selectedImageFiles[existingFileIndex].simpleid = linkedImage?.sampleid || 0;
                    }
                  } else {
                    this.selectedImageFiles.push(newFile);
                  }
                });
                console.log("this.selectedImageFiles", this.selectedImageFiles)
                const countIsLinkedFalse = this.selectedImageFiles.filter(file => !file.islinked).length;
                const item = countIsLinkedFalse === 1 ? "item" : "items";
                if (countIsLinkedFalse > 0) {
                  this.showdialog = true
                  this.cdRef.detectChanges()
                  this.confirmationService.confirm({
                    message: `<strong>In this Look Book, <span style="color: red;"><b>${countIsLinkedFalse} ${item}</b></span> don't have a “create sample” form. Would you like to see this items?</strong>`,
                    header: 'Information',
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => {
                      // Your accept logic here
                      this.yourappsService.returnSelectedFiles(this.selectedImageFiles, 1)
                      this.modalService.openvi()
                      this.showdialog = false
                      this.cdRef.detectChanges()
                    },
                    reject: (type: any) => {
                      this.showdialog = false
                      this.cdRef.detectChanges()
                      // Your reject logic here, handling different types of rejection
                      if (type === ConfirmEventType.REJECT) {
                        // Logic for REJECT
                      } else if (type === ConfirmEventType.CANCEL) {
                        // Logic for CANCEL
                      }
                    }
                  });
                } else {
                  this.selectedImageFiles.forEach(acceptResult => {
                    this.onSearch2(acceptResult.barcode)
                  })
                }

                console.log('Number of items with islinked = false:', countIsLinkedFalse);
              });

          })
        this.subscription2 = this.yourappsService.reloadSendoutList$.subscribe((value: {
          data: SetSelectedFilesV[] | SetSelectedFilesV,
          type: number
        }) => {
          console.log("Received value:", value);

          if (this.subscription2) {
            this.subscription2.unsubscribe();
          }
          let data = Array.isArray(value.data) ? value.data : [value.data]; // Ensure data is always an array

          if (value.type === 1 && data.length > 0) {
            this.selectedImageFiles = []
            data.forEach(acceptResult => {
              this.onSearch2(acceptResult.barcode);

              this.selectedImageFiles.push({
                file: acceptResult.file,
                url: acceptResult.url,
                filename: acceptResult.filename,
                lbcatalogid: acceptResult.lbcatalogid,
                colection: acceptResult.colection,
                designer: acceptResult.designer,
                catalog: acceptResult.catalog,
                catalogyear: acceptResult.catalogyear,
                seasoncode: acceptResult.seasoncode,
                islinked: acceptResult.islinked,
                simpleid: acceptResult.simpleid,
                lbmainid: acceptResult.lbmainid,
                description: acceptResult.description,
                barcode: acceptResult.barcode
              });
            });
            // this.selectedImageFiles.push({
            //     file: data[0].file,
            //     url: data[0].url,
            //     filename: data[0].filename,
            //     // Add other properties if needed
            // });
            this.cdRef.detectChanges();
            this.yourappsService.setValudSelectedFiles(null, 0);
          }
        });
        this.yourappsService.returnSelectedFiles(null, 0);
      }
    });

    this.modalService.openlsb1();
  }

  iCCResult: GetCCList[] = [];
  hidden: boolean = true;
  scannerstring: string = "";

  onSameRequestClick(item: any) {
    this.serviceservice
      .selectcclist({filedir: (item?.["Image Link"])})
      .subscribe((value) => {
        this.iCCResult = [];
        this.iCCResult[0] = value;
        SelfData.cc = this.iCCResult;
        this.subscription3 = this.yourappsService.setAcceptedItem$.subscribe((value: {
          data: GetCCListData[] | GetCCListData,
          type: number
        }) => {
          console.log("Received value:", value);

          let data: GetCCListData[] = Array.isArray(value.data) ? value.data : [value.data];

          // Find the selectedsample item with 'requested count' > 1
          let selectedsample = this.selectedsample.find(item => item['requested count'] > 1);
          if (selectedsample && data[0]) {
            if (data[0]['email'] === this.selectedemail) {
              selectedsample["isok"] = 1
              selectedsample["requested count"] = 1
              selectedsample["itemstatus"] = 1;
              // selectedsample["isaccepted"] = true;
            } else {
              selectedsample["isok"] = 1
              selectedsample["requested count"] = 0
              selectedsample["itemstatus"] = 0;
            }
          }

          // Rest of your logic...
          if (this.subscription3) {
            this.subscription3.unsubscribe();
          }
        });
        this.modalService.openCC();
      });
  }

  onScannClick() {
    this.serviceservice
      .scannedlist({
        accountid: this.getAccountID()
      })
      .subscribe((value) => {
        this.iScannedList[0] = value;
        SelfData.scannedfulllist = this.iScannedList;
        this.modalService.openScann()
      });

  }

  protected readonly transformImageUrl = transformImageUrl;
}
