<div class="top-div">
  <div class="center-div">
    <button mat-button class="mat-menu-panel" (click)="onButtonHome()">
      <span style="color: #ffffff">Home</span>
    </button>
    <button mat-button [matMenuTriggerFor]="menusamples" class="mat-menu-panel">
      <span style="color: #ffffff">Samples</span>
    </button>

    <mat-menu #menusamples="matMenu">
      <button mat-menu-item (click)="onButtonCreateSampleClick()">Create Sample</button>
      <button mat-menu-item (click)="showSampleManager()">
        Sample Manager
      </button>
      <!--      <button mat-menu-item (click)="showchooseitem()">Choose Items</button>-->
      <button mat-menu-item (click)="showlookmanager()">Look Manager</button>
      <!--            <button mat-menu-item>Archived Collection</button>-->
      <button mat-menu-item (click)="showCDLList()">Editor for Designer, Season, Collection</button>
    </mat-menu>

    <button
      class="mat-menu-panel"
      mat-button
      [matMenuTriggerFor]="nmenuContacts"
    >
      <mat-menu #nmenuContacts="matMenu">
        <button mat-menu-item (click)="onButtonCreateContactClick()">Create Contact</button>
        <button mat-menu-item (click)="showcontactManager()">
          Contact Manager
        </button>
        <button mat-menu-item (click)="showcontactMailing()">Contact Mailing</button>
      </mat-menu>
      <span style="color: #ffffff">Contacts</span>
    </button>

    <button class="mat-menu-panel" mat-button [matMenuTriggerFor]="nmenuRequests">
      <span style="color: #ffffff">Requests</span>
    </button>
    <mat-menu #nmenuRequests="matMenu">
      <button mat-menu-item (click)="createRequest()">Create Request</button>
      <button mat-menu-item (click)="showRequestManager()">List of requests</button>
    </mat-menu>

    <button class="mat-menu-panel" mat-button [matMenuTriggerFor]="nmenuLookBook">
      <span style="color: #ffffff">Look Book</span>
      <span *ngIf="SelfData.lbvisibled && SelfData.lbvisibled.length > 0 && SelfData.lbvisibled[0].data && SelfData.lbvisibled[0].data.length > 0 && SelfData.lbvisibled[0].data[0].visibled === 1"
        class="badge-box-sml1">

        <p class="badge-text1">!</p>
      </span>
    </button>
    <mat-menu #nmenuLookBook="matMenu">
      <button mat-menu-item (click)="onButtonLBClick()">Look Book List</button>
      <button mat-menu-item (click)="onButtonVasiaClick()">Catalogs of items</button>
      <button mat-menu-item (click)="onButtonLSBClick()">Sent Look Books
        <span *ngIf="SelfData.lbvisibled && SelfData.lbvisibled.length > 0 && SelfData.lbvisibled[0].data && SelfData.lbvisibled[0].data.length > 0 && SelfData.lbvisibled[0].data[0].visibled === 1"
          class="badge-box-sml2">

          <p class="badge-text2">{{SelfData.lbvisibled[0].data[0].visibledcount}}</p>
        </span>
      </button>
    </mat-menu>

    <button class="mat-menu-panel" mat-button [matMenuTriggerFor]="nmenuSendOuts">
      <span style="color: #ffffff">Sale</span>
    </button>
    <mat-menu #nmenuSendOuts="matMenu">
      <button mat-menu-item (click)="createSendOut()">Create Sale</button>
      <button mat-menu-item (click)="showSendOutManager()">
        Sale Manager
      </button>
      <!--            <button mat-menu-item (click)="showSendOutPendingManager()">Pending Send Outs <span *ngIf="itemcount != 0" class="badge-box-sml"><p class="badge-text">{{itemcount}}</p></span></button>-->

    </mat-menu>

    <button class="mat-menu-panel" mat-button [matMenuTriggerFor]="nmenuPullSheets">
      <span style="color: #ffffff">Pull Sheets</span>
      <!--            <span class="badge-box-sml"><p class="badge-text">99</p></span>-->
    </button>
    <mat-menu #nmenuPullSheets="matMenu">
      <button mat-menu-item (click)="createPullSheet()">Create Pull Sheet</button>
      <button mat-menu-item (click)="showPullSheetManager()">Pull Sheet Manager</button>
      <button mat-menu-item (click)="onButtonPullSheetSettingsClick()">Template Settings</button>
      <button mat-menu-item (click)="onButtonPrintEmptyClick()">Print Pull Sheet</button>
    </mat-menu>

    <button class="mat-menu-panel" mat-button [matMenuTriggerFor]="nmenuReturns">
      <span style="color: #ffffff">Returns</span>
    </button>
    <mat-menu #nmenuReturns="matMenu">
      <button mat-menu-item (click)="onNotificationsClick()">Return Samples</button>
      <button mat-menu-item (click)="onNotificationsClick()">Recently Returned</button>
      <button mat-menu-item (click)="onNotificationsClick()">Overdue Returns</button>
    </mat-menu>

    <button class="mat-menu-panel" mat-button (click)="onNotificationsClick()">
      <span style="color: #ffffff">Audit</span>
    </button>
    <button class="mat-menu-panel" mat-button (click)="onNotificationsClick()">
      <span style="color: #ffffff">Credit Request</span>
    </button>

    <button class="mat-menu-panel" mat-button [matMenuTriggerFor]="nmenuImport">
      <span style="color: #ffffff">Import</span>
    </button>
    <mat-menu #nmenuImport="matMenu">
      <button mat-menu-item (click)="onNotificationsClick()">New Import</button>
      <button mat-menu-item (click)="onNotificationsClick()">Verify Import</button>
      <button mat-menu-item (click)="onNotificationsClick()">Upload Sample Images</button>
    </mat-menu>
    <button
      class="mat-menu-panel"
      mat-button
      [matMenuTriggerFor]="nmenuReporting"
    >
      <span style="color: #ffffff">Reporting</span>
    </button>
    <mat-menu #nmenuReporting="matMenu">
      <button mat-menu-item (click)="onButtonsprclick()">Efficiency perfomance</button>
      <button mat-menu-item (click)="onNotificationsClick()">Look Performance Report</button>
      <button mat-menu-item (click)="onButtonsoclick()">Send-Out Report</button>
      <button mat-menu-item (click)="onNotificationsClick()">Delivery History</button>
      <button mat-menu-item (click)="onNotificationsClick()">Invoice Reports</button>
      <button mat-menu-item (click)="onNotificationsClick()">Request Reports</button>
      <button mat-menu-item (click)="onNotificationsClick()">Credit Reports</button>
    </mat-menu>
  </div>
</div>

<!--<email-editor-->
<!--  [options]="options"-->
<!--  [scriptUrl]="scriptUrl"-->
<!--  (loaded)="editorLoaded($event)"-->
<!--  (ready)="editorReady($event)"-->
<!--  #editor-->
<!--&gt;</email-editor>-->
