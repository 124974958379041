<div class="card">
  <p class="sample-manager-customers">Users List</p>
  <div class="black-background-blt">
    <div class="example-container-blt">
      <div class="headerdiv1">
        <div class="headerdiv">
          <!--          <p-button label="Add outlet" icon="pi pi-plus" [rounded]="false" [outlined]="true" />-->
          <button nz-button nzType="default" (click)="onAddUserClick()">Add user</button>
          <nz-input-group class="filterclass" [nzPrefix]="suffixTemplateInfo" [nzAddOnAfter]="suffixIconButton">
            <input type="text" (input)="onInput($event)" [(ngModel)]="filtertext" nz-input placeholder="Filter"/>
          </nz-input-group>
          <ng-template #suffixTemplateInfo>
            <span nz-icon nzType="search"></span>
          </ng-template>
          <ng-template #suffixIconButton>
            <button nz-button nzSearch><span nz-icon nzType="close" (click)="clear(dt1)"></span></button>
          </ng-template>
        </div>
      </div>

      <div class="griddiv">
        <p-table
          #dt1
          [value]=iSellAccountResult
          [paginator]="true"
          [rows]="10"
          [showCurrentPageReport]="true"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          [rowsPerPageOptions]="[10, 20, 50, 100]"
          [styleClass]="'p-datatable-striped mat-elevation-z1 ' + (selectedSize?.class || '')"
          selectionMode="single"
          [globalFilterFields]="['firstname', 'lastname', 'email']"
          [tableStyle]="{'min-width': '50rem'}">

          <ng-template pTemplate="header">
            <tr>
              <th style="width: 7%">First Name</th>
              <th style="width: 7%">Last Name</th>
              <th style="width: 7%">Email</th>

            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-product>
            <tr>
              <td>{{ product["firstname"] }}</td>
              <td>{{ product["lastname"] }}</td>
              <td>{{ product["email"] }}</td>
<!--              <td>{{ product.totaldiscount | currency:'USD':'symbol':'1.2-2' }}</td>-->
<!--              <td>{{ product.operdate | date:'yyyy-MM-dd HH:mm' }}</td>-->
<!--              <td>-->
<!--                &lt;!&ndash; Кнопка Edit &ndash;&gt;-->
<!--                &lt;!&ndash;                <button pButton type="button" icon="pi pi-pencil" class="p-button-rounded p-button-text p-button-primary" (click)="editOutlet(product)"></button>&ndash;&gt;-->
<!--                <button nz-button nzType="text" nzSize="small" nzShape="circle" (click)="editUser(product)">-->
<!--                  <span nz-icon [nzType]="'edit'" [nzTheme]="'fill'"></span>-->
<!--                </button>-->
<!--                <button nz-button nzType="text" nzSize="small" nzShape="circle" (click)="deleteUser(product)">-->
<!--                  <span nz-icon [nzType]="'delete'" [nzTheme]="'fill'" style="color: red;"></span>-->
<!--                </button>-->
<!--              </td>-->
            </tr>
          </ng-template>
        </p-table>

        <div class="fullline">

        </div>
      </div>

    </div>
  </div>
</div>

<nz-dropdown-menu #menu4="nzDropdownMenu">
  <ul nz-menu>
    <!--    <li nz-menu-item *ngIf="currentSample?.['isok'] == 0" (click)="onButtonReturnScanClick(currentSample)">Return & Scan</li>-->
    <!--    <li nz-menu-item (click)="onViewButtonClick(currentSample)">View Details</li>-->
    <!--    <li nz-menu-item (click)="onButtonDesignerClick()">Choose more samples from this designer.</li>-->
    <!--    <li nz-menu-item *ngIf="SelfData.sendoutmode === 0" (click)="onPrintBarcodeClick(currentSample?.['barcode'])">Print Barcode</li>-->
    <!--    <li nz-menu-item (click)="removeSelectedSample(currentSample?.['barcode'])">Remove Sample</li>-->
    <!--    &lt;!&ndash;    <li nz-menu-item *ngIf="currentSample?.['itemstatus'] == 0" (click)="restoreSelectedSample(currentSample?.['barcode'])">Restore Sample</li>&ndash;&gt;-->
    <!--    <li nz-menu-item *ngIf="currentSample?.['requested count'] > 1" (click)="onSameRequestClick(currentSample)">Same Request</li>-->
  </ul>
</nz-dropdown-menu>

