import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class HashService {
  md5(input: string): string {
    const hash = CryptoJS.MD5(
      'Alan Mathison Turing ' + input + ' Alan Mathison Turing'
    );
    return hash.toString(CryptoJS.enc.Hex);
  }
}
