<button mat-icon-button type="button" class="close-btn" (click)="onButtonCloseClick()">
  <mat-icon>close</mat-icon>
</button>

<div class="test1">
  <button nz-button nzType="default" (click)="connectToPrinter()">Connect</button>
  <div *ngIf="!connected" class="test2"></div>
  <div *ngIf="connected" class="test3"></div>
</div>

<!--<div>-->
<!--  <button (click)="connectToPrinter()" [disabled]="connected">Подключиться к принтеру</button>-->
<!--  <button (click)="disconnectFromPrinter()" [disabled]="!connected">Отключиться от принтера</button>-->
<!--  <button (click)="printDocument()" [disabled]="!connected">Печать документа</button>-->

<!--  <p *ngIf="connected">Статус: Подключен</p>-->
<!--  <p *ngIf="!connected">Статус: Отключен</p>-->
<!--</div>-->

<div class="test1-1">
  <button (click)="printDocument()" class="buttonsettings" nz-button nzType="default">Print</button>
</div>
