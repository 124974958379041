import { ChangeDetectorRef, Component } from '@angular/core';
import { ModalserviceService } from '../../services/modalservice.service';
import { YourappsserviceService } from '../../services/yourappsservice.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { MatCheckboxChange } from '@angular/material/checkbox';
import {
  GetCustomFields,
  GetSystemFields,
  SelfData,
} from '../../models/struct';
import { ServiceService } from '../../services/service.service';

interface FieldType {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-fieldseditordialog',
  templateUrl: './fieldseditordialog.component.html',
  styleUrls: ['./fieldseditordialog.component.scss'],
})
/*
Free Text
DropDown List
Date Picker
DropDown Multi Select
Country
Interactive
Number Field
 */
export class FieldseditordialogComponent {
  myForm: FormGroup;
  checked = false;
  indeterminate = false;
  isDisabled = true;
  requeredisDisabled = true;
  iSystemFields: GetSystemFields[] = [];
  iContactsSystemFields: GetSystemFields[] = [];
  iCustomFields: GetCustomFields[] = [];
  iContactsCustomFields: GetCustomFields[] = [];
  tdata: any;
  fieldTypes: FieldType[] = [
    { value: '1', viewValue: 'Free Text' },
    { value: '2', viewValue: 'DropDown List' },
    { value: '3', viewValue: 'Date Picker' },
    // { value: '4', viewValue: 'DropDown Multi Select' },
    { value: '5', viewValue: 'Country' },
    { value: '6', viewValue: 'Interactive' },
    { value: '7', viewValue: 'Number Field' },
  ];
  selected = 'DropDown List';
  myControl = new FormControl({ value: '', disabled: false }, [
    Validators.required,
  ]);
  ftControl = new FormControl({ value: '2', disabled: false }, [
    Validators.required,
  ]);
  descriptionControl = new FormControl<string>('');

  stepControl = new FormControl({ value: '0.01', disabled: true }, [
    Validators.required,
    Validators.min(0),
    Validators.pattern('^\\d*(\\.\\d+)?$'),
  ]);

  minControl = new FormControl({ value: '0', disabled: true }, [
    Validators.required,
    Validators.min(1),
    Validators.pattern('^[0-9]*$'),
  ]);

  maxControl = new FormControl({ value: '0', disabled: true }, [
    Validators.required,
    Validators.min(1),
    Validators.pattern('^[0-9]*$'),
  ]);

  constructor(
    public modalService: ModalserviceService,
    private yourappsService: YourappsserviceService,
    private fb: FormBuilder,
    private serviceservice: ServiceService,
    private cdr: ChangeDetectorRef
  ) {
    this.myForm = this.fb.group({});
    this.yourappsService.reloadAvatarImage.subscribe(() => {});
    this.ftControl.enable();
    this.requeredisDisabled = false;
    this.descriptionControl.enable();
    this.yourappsService.currentData.subscribe((data) => {
      if (data !== null) {
        this.tdata = data;
        // do something with the data
        let foundFieldType = this.fieldTypes.find(
          (fieldType) => fieldType.value === String(data.fieldtype)
        );
        this.ftControl.setValue(foundFieldType?.viewValue ?? null);
        this.myControl.setValue(data.fieldlabel);
        this.checked = data.requered;
        this.indeterminate = data.isfloat;
        if (data.controllertype == 0) {
          this.ftControl.disable();
          this.requeredisDisabled = true;
          this.descriptionControl.disable();
        } else if (data.controllertype == 1) {
          this.ftControl.enable();
          this.requeredisDisabled = false;
          this.descriptionControl.enable();
        }
      }
    });
  }

  onSelectionChange(event: MatSelectChange) {
    const selectedValue = event.value;
    console.dir(selectedValue);
    let fieldType = this.fieldTypes.find(
      (ft) => ft.viewValue === selectedValue
    );
    switch (fieldType?.value) {
      case '7':
        // Enable the controls and set isDisabled to false
        this.minControl.enable();
        this.maxControl.enable();
        this.isDisabled = false;
        break;
      default:
        // Disable the controls and set isDisabled to true
        this.minControl.disable();
        this.maxControl.disable();
        this.isDisabled = true;
    }

    this.cdr.detectChanges();
  }

  onCheckboxChange(event: MatCheckboxChange) {
    if (event.checked) {
      this.stepControl.enable();
    } else {
      this.stepControl.disable();
    }
  }

  clearValue() {
    this.myControl.setValue(''); // Clear the form control value
  }

  submit() {
    var accountid: number = 0;
    if (SelfData.account[0].data[0].parentid == 0) {
      accountid = SelfData.account[0].data[0].id;
    } else {
      accountid = SelfData.account[0].data[0].parentid;
    }

    let fieldType = this.fieldTypes.find(
      (ft) => ft.viewValue === this.ftControl.value
    );
    var controllertype: number = 0;
    var ctrl: number = 0;
    var id: number = 0;

    if (SelfData.fieldtype == 0) {
      id = 0;
      ctrl = 0;
      controllertype = 1;
    } else if (SelfData.fieldtype == 1) {
      id = this.tdata.id;
      ctrl = 1;
      controllertype = 0;
    } else if (SelfData.fieldtype == 2) {
      id = this.tdata.id;
      ctrl = 1;
      controllertype = 1;
    }
    var framename: string;
    if (SelfData.formcontrol == 0) {
      framename = 'createsampledialog';
    } else {
      framename = 'createcontactsdialog';
    }
    let account = {
      ctrl: ctrl,
      id: id,
      framename: framename,
      fieldlabel: this.myControl.value?.trim() || '',
      accountid: accountid,
      required: this.checked,
      fieldtype: Number(fieldType?.value) || 0,
      controllertype: controllertype,
      minnumber: Number(this.minControl.value) || 0,
      maxnumber: Number(this.maxControl.value) || 0,
      isfloat: this.indeterminate,
      step: Number(this.stepControl.value) || 0,
      userid: accountid,
      curdate: new Date(),
      description: this.descriptionControl.value || '',
    };
    this.modalService.closeFieldsEditor();
    if (SelfData.formcontrol == 0) {
      this.serviceservice.prcSystemFields(account).subscribe((data) => {
        if (SelfData.fieldtype == 0 || SelfData.fieldtype == 2) {
          this.serviceservice
            .getCustomFieldsList({
              accountid: accountid,
              framename: 'createsampledialog',
            })
            .subscribe((value) => {
              this.iCustomFields[0] = value;
              SelfData.customfields = this.iCustomFields;
              this.yourappsService.getReloadFieldList();
            });
        } else if (SelfData.fieldtype == 1) {
          this.serviceservice
            .getSystemFieldsList({
              accountid: accountid,
              framename: 'createsampledialog',
            })
            .subscribe((value) => {
              this.iSystemFields[0] = value;
              SelfData.systemfields = this.iSystemFields;
              this.yourappsService.getReloadFieldList();
            });
        }
      });
    } else {
      this.serviceservice.prcContactsSystemFields(account).subscribe((data) => {
        if (SelfData.fieldtype == 0 || SelfData.fieldtype == 2) {
          this.serviceservice
            .getContactCustomFieldsList({
              accountid: accountid,
              framename: 'createcontactsdialog',
            })
            .subscribe((value) => {
              this.iContactsCustomFields[0] = value;
              SelfData.contactcustomfields = this.iContactsCustomFields;
              this.yourappsService.getReloadFieldList();
            });
        } else if (SelfData.fieldtype == 1) {
          this.serviceservice
            .getSystemContactsFieldsList({
              accountid: accountid,
              framename: 'createcontactsdialog',
            })
            .subscribe((value) => {
              this.iContactsSystemFields[0] = value;
              SelfData.systemContactsfields = this.iContactsSystemFields;
              this.yourappsService.getReloadFieldList();
            });
        }
      });
    }
  }

  deleterecord(id: number) {
    // this.yourappsService.setOKOption(1);
    // this.yourappsService.setFrameid(id);
    // this.messageBox.modalService.openMessageBox(
    //   'Information',
    //   'Are you sure you want to delete this entry?',
    //   'No',
    //   'Yes'
    // );
  }
}
