import { Component } from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";

@Component({
  selector: 'app-scannerform',
  templateUrl: './scannerform.component.html',
  styleUrl: './scannerform.component.scss'
})
export class ScannerformComponent {
  constructor(public modalService: ModalserviceService) {}
}
