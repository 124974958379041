import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ModalserviceService} from "../../services/modalservice.service";
import {HashService} from "../../services/hash.service";
import {MessageboxComponent} from "../../modals/messagebox/messagebox.component";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import { HttpClient } from "@angular/common/http";
import {map, Observable, startWith, Subscription} from "rxjs";
import {
  GetCollectionList,
  GetDesignerList, GetFabricList,
  GetSeasonList, GetTypeList,
  SelfData,
  SetSampleList,
  SetSampleListData, transformImageUrl
} from "../../models/struct";
import {CdkDragDrop, moveItemInArray, transferArrayItem} from "@angular/cdk/drag-drop";
import {MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {NzNotificationService} from "ng-zorro-antd/notification";

interface ColorArray {
  colorname: string;
  colorcode: string;
}

interface Gender {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-createlookdialog',
  templateUrl: './createlookdialog.component.html',
  styleUrls: ['./createlookdialog.component.scss']
})
export class CreatelookdialogComponent implements OnInit, AfterViewInit, OnDestroy {

  myForm: FormGroup = new FormGroup({});
  createbuttoncaption = 'Create';
  isupdate: boolean = false
  iSampleDataResult: SetSampleList[] = [];
  iSampleSelectedResult: SetSampleList[] = [];
  selectedElement: any;
  curranceid: number = 0

  designerdelbutton = false;

  filteredDesignerOptions?: Observable<string[]>;
  filteredCollectionOptions?: Observable<string[]>;
  filteredSeasonOptions?: Observable<string[]>;
  filteredTypeOptions?: Observable<string[]>;
  filteredSizeOptions?: Observable<string[]>;
  filteredFabricOptions?: Observable<string[]>;

  designerptions: string[] = [];
  collectionoptions: string[] = [];
  seasonoptions: string[] = [];
  typeoptions: string[] = [];
  sizeoptions: string[] = [];
  fabricoptions: string[] = [];

  colorarray: ColorArray[] = [
    {colorname: 'White', colorcode: '#ffffff'},
    {colorname: 'Black ', colorcode: '#000000'},
    {colorname: 'Yellow', colorcode: '#FFFF00'},
    {colorname: 'Purple', colorcode: '#800080'},
    {colorname: 'Orange ', colorcode: '#FFA500'},
    {colorname: 'Blue ', colorcode: '#2196f3'},
    {colorname: 'Light Blue ', colorcode: '#03a9f4'},
    {colorname: 'Red', colorcode: '#ff0000'},
    {colorname: 'Buff', colorcode: '#DAA06D'},
    {colorname: 'Gray ', colorcode: '#808080'},
    {colorname: 'Green ', colorcode: '#00FF00'},
    {colorname: 'Pink', colorcode: '#FFC0CB'},
    {colorname: 'Violet', colorcode: '#8F00FF'},
    {colorname: 'Brown', colorcode: '#964B00'},
    {colorname: 'Silver', colorcode: '#C0C0C0'},
    {colorname: 'Cream', colorcode: '#FFFDD0'},
    {colorname: 'Gold', colorcode: '#FFD700'},
    {colorname: 'Beige', colorcode: '#F5F5DC'},
    {colorname: 'Navy', colorcode: '#202A44'},
  ];

  gender: Gender[] = [
    {value: 'Man', viewValue: 'Man'},
    {value: 'Unisex', viewValue: 'Unisex'},
    {value: 'Women', viewValue: 'Women'},
  ];

  private subscription?: Subscription;

  // descriptionFormControl = new FormControl({value: '', disabled: false}, [
  //   Validators.required,
  // ]);

  showButton: { [key: string]: boolean } = {
    desingerFormControl: false,
    collectionFormControl: false,
    seasonFormControl: false,
    typeFormControl: false,
    colorControl: false,
    sizeControl: false,
    fabricControl: false,
    genderControl: false
  };

  // desingerFormControl = new FormControl({value: '', disabled: false}, []);
  // collectionFormControl = new FormControl({value: '', disabled: false}, []);
  // seasonFormControl = new FormControl({value: '', disabled: false}, []);
  // typeFormControl = new FormControl({value: '', disabled: false}, []);
  // colorControl = new FormControl({value: '', disabled: false}, []);
  // sizeControl = new FormControl({value: '', disabled: false}, []);
  // fabricControl = new FormControl({value: '', disabled: false}, []);
  // genderControl = new FormControl({value: '', disabled: false}, []);

  descriptionstring: string = "";
  designerstring: string = ""
  collectionstring: string = ""
  seasonstring: string = "";
  typestring: string = "";
  colorstring: string = ""
  sizestring: string = "";
  fabricstring: string = "";
  genderstring: string = "";

  stringProperties: { [key: string]: string } = {
    descriptionstring: this.descriptionstring,
    designerstring: this.designerstring,
    collectionstring: this.collectionstring,
    seasonstring: this.seasonstring,
    typestring: this.typestring,
    colorstring: this.colorstring,
    sizestring: this.sizestring,
    fabricstring: this.fabricstring,
    genderstring: this.genderstring,
  };

  iDesignerResult: GetDesignerList[] = [];
  iCollection: GetCollectionList[] = [];
  iSeason: GetSeasonList[] = [];
  iType: GetTypeList[] = [];
  iSize: GetTypeList[] = [];
  iFabricResult: GetFabricList[] = [];
  statusdescription: '' | 'error' | 'warning' = '';
  statusdesigner: '' | 'error' | 'warning' = '';
  statuscollection: '' | 'error' | 'warning' = '';

  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private hashService: HashService,
    public messageBox: MessageboxComponent,
    private serviceservice: ServiceService,
    private yourappsService: YourappsserviceService,
    private _snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef,
    private notification: NzNotificationService,
    private http: HttpClient
  ) {
    // this.iSampleDataResult = SelfData.samplelookdata
    this.iSampleDataResult.push({Code: 200, ErrorMessage: '', data: []});
    this.iSampleSelectedResult.push({Code: 200, ErrorMessage: '', data: []});
    // this.addUniqueItems()

    var accountid: number = 0;
    if (SelfData.account[0].data[0].parentid == 0) {
      accountid = SelfData.account[0].data[0].id;
    } else {
      accountid = SelfData.account[0].data[0].parentid;
    }

    this.serviceservice
      .setDesignerList({accountid: accountid})
      .subscribe((desingervalue) => {
        this.iDesignerResult[0] = desingervalue;
        SelfData.designer = this.iDesignerResult;
        // this.filteredDesignerOptions = this.setDataAndFilter(
        //   this.desingerFormControl,
        //   desingervalue,
        //   this.designerptions,
        //   2
        // );
      });

    this.serviceservice
      .setCollectionList({accountid: accountid})
      .subscribe((collectionvalue) => {
        this.iCollection[0] = collectionvalue;
        SelfData.collection = this.iCollection;
        // this.filteredCollectionOptions = this.setDataAndFilter(
        //   this.collectionFormControl,
        //   collectionvalue,
        //   this.collectionoptions,
        //   5
        // );
      });

    this.serviceservice
      .setSeasonList({accountid: accountid})
      .subscribe((seasonvalue) => {
        this.iSeason[0] = seasonvalue;
        SelfData.season = this.iSeason;
        // this.filteredSeasonOptions = this.setDataAndFilter(
        //   this.seasonFormControl,
        //   seasonvalue,
        //   this.seasonoptions,
        //   6
        // );
      });

    this.serviceservice
      .setTypeList({accountid: accountid})
      .subscribe((value) => {
        this.iType[0] = value;
        SelfData.type = this.iType;
        // this.filteredTypeOptions = this.setDataAndFilter(
        //   this.typeFormControl,
        //   value,
        //   this.typeoptions,
        //   10
        // );
      });

    this.serviceservice
      .setSizeList({accountid: accountid})
      .subscribe((value) => {
        if (value) {
          this.iSize[0] = value;
          SelfData.size = this.iSize;
          // this.filteredSizeOptions = this.setDataAndFilter(
          //   this.sizeControl,
          //   value,
          //   this.sizeoptions,
          //   11
          // );
        } else {
          // Handle the situation when value is undefined or null
        }
      });

    this.serviceservice
      .setFabricList({accountid: accountid})
      .subscribe((fabricvalue) => {
        this.iFabricResult[0] = fabricvalue;
        SelfData.fabric = this.iFabricResult;
        // this.filteredFabricOptions = this.setDataAndFilter(
        //   this.fabricControl,
        //   fabricvalue,
        //   this.fabricoptions,
        //   3
        // );
      });

  }

  private setDataAndFilter(
    control: FormControl,
    response: any,
    options: string[],
    type: number
  ) {
    let results = response.data;
    for (let data of results) {
      options.push(data.description);
    }
    return control.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || '', type))
    );
  }

  private _filter(value: string, type: number): string[] {
    const filterValue = value.toLowerCase();

    // Map the type to the corresponding options array
    const optionsMap: { [key: number]: string[] } = {
      2: this.designerptions,
      3: this.fabricoptions,
      5: this.collectionoptions,
      6: this.seasonoptions,
      10: this.typeoptions,
      11: this.sizeoptions,
    };

    if (optionsMap[type]) {
      return optionsMap[type].filter((option) =>
        option.toLowerCase().includes(filterValue)
      );
    } else {
      console.warn(`Unexpected filter type: ${type}`);
      return [];
    }
  }

  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
      ? SelfData.account[0].data[0].id
      : SelfData.account[0].data[0].parentid;
  }

  submit() {
    if (this.descriptionstring.trim() === "") {
      this.notification.error(
        '<strong class="notification-error">Information</strong>',
        '<strong class="notification-error">Description is required</strong>'
      );
      return
    }
    var lookid: number = 0
    let ctrl = this.isupdate ? 1 : 0
    let id = this.isupdate ? this.curranceid : 0
    this.serviceservice
      .prclook({
        ctrl: ctrl,
        description: this.descriptionstring?.trim(),
        accountid: this.getAccountID(),
        id: id
      })
      .subscribe((newid) => {
        lookid = newid.data
        if (this.isupdate) {
          this.serviceservice
            .prclooklist({
              userid: SelfData.account[0].data[0].id,
              ctrl: 1,
              sampleid: 0,
              lookid: lookid
            })
            .subscribe((value) => {
              this.iSampleSelectedResult[0].data.forEach((sample) => {
                let id = sample["selectedsampleid"];
                if (id !== undefined) {
                  this.serviceservice
                    .prclooklist({
                      userid: SelfData.account[0].data[0].id,
                      ctrl: 0,
                      sampleid: id,
                      lookid: lookid
                    })
                    .subscribe((value) => {
                      // You can use lookid here
                      this.yourappsService.getReloadLooks();
                    });
                }
              });
            });
        } else {
          this.iSampleSelectedResult[0].data.forEach((sample) => {
            let id = sample["selectedsampleid"];
            if (id !== undefined) {
              this.serviceservice
                .prclooklist({
                  userid: SelfData.account[0].data[0].id,
                  ctrl: 0,
                  sampleid: id,
                  lookid: lookid
                })
                .subscribe((value) => {
                  this.yourappsService.getReloadLooks();
                });
            }
          });
        }
        this.modalService.createlooklose()
      });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.isupdate = false
    }
  }

  ngAfterViewInit(): void {
    this.subscription = this.yourappsService.getlookdata$.subscribe(([looknames, looks]) => {
      console.log("Received looknames object:", looknames);

      try {
        if (!looknames || !looknames.data) {
          throw new Error("looknames or looknames.data is undefined.");
        }

        const { description, id } = looknames.data;
        if (!description || id === undefined) {
          throw new Error("description or id is missing in looknames.data.");
        }

        this.descriptionstring = description.trim();
        this.curranceid = id;

        this.createbuttoncaption = "Update & Save";
        this.isupdate = true;

        if (!looks) {
          throw new Error("looks data is undefined.");
        }

        this.iSampleSelectedResult[0].data = looks;
        this.cdr.detectChanges();

      } catch (error) {
        console.error("An error occurred:");
      }
    });
  }

  ngOnInit(): void {
    this.createbuttoncaption = "Create"
    this.isupdate = false
    this.curranceid = 0
  }


  addUniqueItems(): void {
    this.iSampleDataResult = []

    if (this.designerstring.trim() != "" || this.collectionstring.trim() != "" ||
      this.seasonstring.trim() != "" || this.typestring.trim() != "" ||
      this.colorstring.trim() != "" || this.sizestring.trim() != "" ||
      this.fabricstring.trim() != "" || this.genderstring.trim() != "") {

      if (SelfData.samplelookdata.length > 0) {
        const selectedIds = this.iSampleSelectedResult[0].data.map((item: SetSampleListData) => item?.["selectedsampleid"]);

        const uniqueItems: SetSampleListData[] = [];

        SelfData.samplelookdata[0].data.forEach((item: SetSampleListData) => {
          if (!selectedIds.includes(item?.["ID"])) {
            uniqueItems.push(item);
          }
        });

        if (uniqueItems.length > 0) {
          this.iSampleDataResult.push({
            Code: 200,
            ErrorMessage: "Accepted",
            data: uniqueItems
          });
        }
      }
    } else {
      this.iSampleDataResult.push({Code: 200, ErrorMessage: '', data: []});
    }
  }

  onInputChange(event: Event, controlName: string) {
    this.showButton[controlName] = (event.target as HTMLInputElement).value != '';
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent, controlName: string) {
    this.showButton[controlName] = event.option.value != '';
  }

  clearValue(event: MouseEvent, controlName: string, autoInput: MatAutocompleteTrigger | null): void {
    event.stopPropagation();
    if (autoInput) autoInput.closePanel();
    // this.formControls[controlName].setValue("");
    this.showButton[controlName] = false;
  }

  ondbleclick(element: any) {
    SelfData.sampleselecteddata = element;
    SelfData.updatebuttonisenabled = true
    this.yourappsService.selectSampleData(element);
    this.modalService.createsampleropen();
  }


  drop(event: CdkDragDrop<SetSampleListData[], any>): void {
    if (!event.container.data || !event.previousContainer.data) {
      return; // exit function if data is undefined
    }

    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  onFilterButtonClick() {
    this.filterData()
  }

  filterData() {
    this.addUniqueItems()
    let controls = [
      { control: this.designerstring, key: "Designer" },
      { control: this.collectionstring, key: "Collection" },
      { control: this.seasonstring, key: "Season" },
      { control: this.typestring, key: "Type" },
      { control: this.colorstring, key: "Color" },
      { control: this.sizestring, key: "Size" },
      { control: this.fabricstring, key: "Fabric" },
      { control: this.genderstring, key: "Gender" }
    ];

    controls.forEach(controlItem => {
      if (controlItem.control.trim() !== "") {  // Directly use the string property
        this.iSampleDataResult = this.iSampleDataResult.map(sampleList => ({
          ...sampleList,
          data: sampleList.data.filter(sample => sample?.[controlItem.key] === controlItem.control)
        }));
      }
    });
  }

  protected readonly transformImageUrl = transformImageUrl;
}
