<div class="grid">
  <div class="col-12 lg:col-6 xl:col-3">
    <div class="card1 mb-0">
      <div class="flex justify-content-between mb-3">
        <div>
          <span class="block text-500 font-medium mb-3">Value 1</span>
          <div class="text-900 font-medium text-xl">15</div>
        </div>
        <div class="flex align-items-center justify-content-center bg-blue-100 border-round" [ngStyle]="{width: '2.5rem', height: '2.5rem'}">
          <i class="pi pi-shopping-cart text-blue-500 text-xl"></i>
        </div>
      </div>
      <!--      <span class="text-green-500 font-medium">24 new </span>-->
      <!--      <span class="text-500">since last visit</span>-->
    </div>
  </div>
  <div class="col-12 lg:col-6 xl:col-3">
    <div class="card1 mb-0">
      <div class="flex justify-content-between mb-3">
        <div>
          <span class="block text-green-500 font-medium mb-3">Value 2</span>
          <div class="text-green-500 font-medium text-xl">30</div>
        </div>
        <div class="flex align-items-center justify-content-center bg-orange-100 border-round" [ngStyle]="{width: '2.5rem', height: '2.5rem'}">
          <i class="pi pi-map-marker text-orange-500 text-xl"></i>
        </div>
      </div>
      <!--      <span class="text-green-500 font-medium">%52+ </span>-->
      <!--      <span class="text-500">since last week</span>-->
    </div>
  </div>
  <div class="col-12 lg:col-6 xl:col-3">
    <div class="card1 mb-0">
      <div class="flex justify-content-between mb-3">
        <div>
          <span class="block text-amber-500 font-medium mb-3">Value 3</span>
          <div class="text-amber-500 font-medium text-xl">15</div>
        </div>
        <div class="flex align-items-center justify-content-center bg-cyan-100 border-round" [ngStyle]="{width: '2.5rem', height: '2.5rem'}">
          <i class="pi pi-inbox text-cyan-500 text-xl"></i>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 lg:col-6 xl:col-3">
    <div class="card1 mb-0">
      <div class="flex justify-content-between mb-3">
        <div>
          <span class="block text-red-500 font-medium mb-3">Value 4</span>
          <div class="text-red-500 font-medium text-xl">5</div>
        </div>
        <div class="flex align-items-center justify-content-center bg-purple-100 border-round" [ngStyle]="{width: '2.5rem', height: '2.5rem'}">
          <i class="pi pi-comment text-purple-500 text-xl"></i>
        </div>
      </div>
      <!--      <span class="text-green-500 font-medium">85 </span>-->
      <!--      <span class="text-500">responded</span>-->
    </div>
  </div>

  <div class="col-12 xl:col-6">
    <div class="card1">
      <h5>Recent Sales</h5>
      <p-table [value]="SelfData.account" [paginator]="true" [rows]="5" responsiveLayout="scroll">
        <ng-template pTemplate="header">
          <tr>
            <th>Image</th>
            <th pSortableColumn="name">Name <p-sortIcon field="name"></p-sortIcon></th>
            <th pSortableColumn="price">Price <p-sortIcon field="price"></p-sortIcon></th>
            <th>View</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
          <tr>
            <td style="width: 15%; min-width: 5rem;">
              <img src="assets/demo/images/product/{{product.image}}" class="shadow-4" alt="{{product.name}}" width="50">
            </td>
            <td style="width: 35%; min-width: 7rem;">{{product.name}}</td>
            <td style="width: 35%; min-width: 8rem;">{{product.price | currency:'USD'}}</td>
            <td style="width: 15%;">
              <button pButton pRipple type="button" icon="pi pi-search" class="p-button p-component p-button-text p-button-icon-only"></button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

</div>

