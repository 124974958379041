<form [formGroup]="myForm" (ngSubmit)="submit()" autocomplete="off">
    <button mat-icon-button type="button" class="close-btn-ld" (click)="modalService.lookclose()">
        <mat-icon>close</mat-icon>
    </button>
    <p class="sample-manager-text-ld">Look</p>
</form>
<p-progressSpinner *ngIf="Speener.lookbookvisiblemode" class="spinner" styleClass="w-3rem h-3rem" strokeWidth="5"
  fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
<div class="centered-container3-dl">
    <app-looklist  *ngIf="modalService.isLookList$ | async"></app-looklist>
</div>
