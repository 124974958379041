<div class="top-div">
  <div class="center-div">
    <mat-tab-group (selectedTabChange)="onTabChanged($event)">
      <mat-tab>
        <ng-template mat-tab-label>
          <span style="color: #ffffff">Apps</span>
        </ng-template>
        <!-- Your tab content here -->
        <div class="your-apps-content">
          <!-- Your "Your apps" tab content goes here -->
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <span style="color: #ffffff">Messenger</span>
        </ng-template>
        <!-- Your tab content here -->
        <div class="your-apps-content">
          <div class="coming-soon-container">
            <div class="coming-soon-text">Coming Soon</div>
          </div>
          <!-- Your "Your apps" tab content goes here -->
        </div>

      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <span style="color: #ffffff">What's new</span>
        </ng-template>
        <!-- Your tab content here -->
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <span style="color: #ffffff">Support</span>
        </ng-template>
        <!-- Your tab content here -->
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <span style="color: #ffffff">Public Services</span>
        </ng-template>
        <div class="coming-soon-container">
          <div class="coming-soon-text">Coming Soon</div>
        </div>
        <!-- Your tab content here -->
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <span style="color: #ffffff">Assistant</span>
        </ng-template>
        <div class="coming-soon-container">
          <div class="coming-soon-text">Coming Soon</div>
        </div>
        <!-- Your tab content here -->
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
