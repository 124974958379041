import { Component } from '@angular/core';
import { ModalserviceService } from '../../services/modalservice.service';

@Component({
  selector: 'app-imagezoom',
  templateUrl: './imagezoom.component.html',
  styleUrls: ['./imagezoom.component.scss'],
})
export class ImagezoomComponent {
  constructor(public modalService: ModalserviceService) {}
}
