import { Component } from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";

@Component({
  selector: 'app-createlsb1',
  templateUrl: './createlsb1.component.html',
  styleUrls: ['./createlsb1.component.scss']
})
export class Createlsb1Component {
  constructor(public modalService: ModalserviceService) {}
}
