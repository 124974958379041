import { Component } from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";

@Component({
  selector: 'app-profilelist',
  templateUrl: './profilelist.component.html',
  styleUrl: './profilelist.component.scss'
})
export class ProfilelistComponent {
  constructor(public modalService: ModalserviceService) {}
}
