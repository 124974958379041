import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {
  GetlbCatalogs,
  GetLBMainData, Getlbseasonlist, GetlbseasonlistData, LbCatalogData, LbSelectedDesigner, LbSelectedSeasonRequest,
  lbsentData,
  lbsentDetails, LbTempCatalogDetails,
  PullSheetData,
  PullSheetDetails,
  SelfData,
  SmsDetails
} from "../../models/struct";
import {ModalserviceService} from "../../services/modalservice.service";
import {ServiceService} from "../../services/service.service";
import {HashService} from "../../services/hash.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {ConfirmationService, ConfirmEventType, MessageService} from "primeng/api";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-createlsbframedialog',
  templateUrl: './createlsbframedialog.component.html',
  styleUrls: ['./createlsbframedialog.component.scss']
})
export class CreatelsbframedialogComponent implements OnInit, AfterViewInit, OnDestroy {
  emailFormControl = new FormControl('', [
    Validators.email,
  ]);
  sendoutControl = new FormControl({value: '', disabled: false}, [
    Validators.required,
  ]);

  nameControl = new FormControl({value: '', disabled: false}, [
    Validators.required,
  ]);
  phoneControl = new FormControl<string>('');
  sendoutid: number = 0
  email = 'tata-pr@tata-la.com';
  text: string = "";
  buttondisabled: boolean = false
  messagemode: number = 0
  ilbResult: GetlbCatalogs[] = [];
  private subscription?: Subscription;
  private subscription1?: Subscription;
  private selectedlookbook: GetLBMainData[] = [];
  iSeasonToResult: Getlbseasonlist[] = [{Code: 0, ErrorMessage: '', data: []}];
  showdialog: boolean = false

  getRandomNumber(): number {
    return Math.floor(Math.random() * 999999999);
  }

  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
      ? SelfData.account[0].data[0].id
      : SelfData.account[0].data[0].parentid;
  }

  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private serviceservice: ServiceService,
    private hashService: HashService,
    private yourappsService: YourappsserviceService,
    private confirmationService: ConfirmationService,
    private cdr: ChangeDetectorRef,
    private messageService: MessageService
  ) {
    this.serviceservice
      .selecttempcatalogs({accountid: this.getAccountID()})
      .subscribe((value) => {
        this.ilbResult[0] = value;
        this.SelfData.tlb = this.ilbResult;
        console.log("this.SelfData.tlb", this.SelfData.tlb)
      });
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.subscription1) {
      this.subscription1.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.showdialog = true
  }

  onButtonAddClick() {
    SelfData.lbframetype = 1
    this.subscription = this.yourappsService.setLookBook$.subscribe((value: { data: any, type: number }) => {
      if (this.subscription) {
        this.subscription.unsubscribe();
        if (value.data) {
          if (value.data.lbtype === 0) {
            this.serviceservice
              .selectlbselectedseasonlist({accountid: this.getAccountID(), lbmainid: value.data.id})
              .subscribe((value) => {
                this.iSeasonToResult[0] = value;
              });
          }
          this.selectedlookbook[0] = value.data
          this.sendoutControl.setValue(value.data.description)
        }
      }
    })
    this.modalService.openlb()
  }

  hasSeasonBeforeCurrentYear(seasonList: Getlbseasonlist[]) {
    let currentYear = new Date().getFullYear();

    return seasonList[0].data.some(item => item.seasonyear < currentYear);
  }

  filterSeasonsByCurrentYear(seasonList: Getlbseasonlist[]): GetlbseasonlistData[] {
    let currentYear = new Date().getFullYear();

    return seasonList[0].data.filter(item => item.seasonyear < currentYear);
  }

  btnNoClick() {
  }

  btnShowClick() {
    this.showdialog = false
    if (this.messagemode === 0) {
      var filteredSeasons: Getlbseasonlist[] = [{Code: 0, ErrorMessage: '', data: []}];
      // var iDeletedToResult: Getlbseasonlist[] = [{Code: 0, ErrorMessage: '', data: []}];
      filteredSeasons[0].data = this.filterSeasonsByCurrentYear(this.iSeasonToResult);
      this.yourappsService.selectCollectionList(filteredSeasons, 1)
      this.subscription1 = this.yourappsService.retColectionList$.subscribe((value: { data: any, type: number }) => {
        if (this.subscription1) {
          this.subscription1.unsubscribe();
          if (value.data) {
            if (value.type === 2) {
              this.modalService.closelsbframe()
            } else if (value.type === 1) {
              var isshow: boolean = false
              let key = this.hashService.md5(this.getRandomNumber().toString())
              value.data[0].data.forEach((item: any) => {
                const selCatalogDetails: LbSelectedSeasonRequest = {
                  description: "",
                  seasoncode: "",
                  seasonyear: 0,
                  accountid: this.getAccountID(),
                  desingerid: 0,
                  catalogid: 0,
                  lbmainid: 0,
                  ctrl: 2,
                  id: item.id
                };
                this.serviceservice.prcLbSelectedSeason(selCatalogDetails).subscribe({
                  next: (value: LbSelectedDesigner) => {

                  },
                  error: (error: any) => {
                    console.error('Error occurred:', error);
                  },
                  complete: () => {
                  }
                });
              })
              if (this.emailFormControl.value?.trim() !== "") {
                const data: lbsentDetails = {
                  email: this.emailFormControl.value?.trim() || "",
                  phone: this.phoneControl.value?.trim() || "",
                  subject: SelfData.account[0].data[0].company + " - Look Book",
                  message: this.text.trim(),
                  key: key,
                  accountid: this.getAccountID(),
                  userid: SelfData.account[0].data[0].id,
                  lbmainid: this.selectedlookbook[0].id,
                  from: 'tata-pr@tata-la.com',
                  isactivity: true,
                  ctrl: 0,
                  id: 0,
                  name: this.nameControl.value?.trim() || ""
                };
                this.serviceservice.lbsentemail(data).subscribe({
                  next: (response: lbsentData) => {
                    isshow = true
                    this.messageService.add({
                      key: 'tc',
                      severity: 'success',
                      summary: 'E-mail Sent',
                      detail: '\n' +
                        'Notice! The text contains a link and this email may end up in the Junk/Spam on the other side. \n' +
                        'It would be nice if you tell the other party to check Junk/Spam if they don\'t see the email.',
                      life: 10000,
                    });
                    this.yourappsService.getReloadSLBlist()
                    this.modalService.closelsbframe()
                  },
                  error: error => {
                    console.error('Error:', error);
                  }
                });
              }

              if (this.phoneControl.value?.trim() !== "") {
                const data: lbsentDetails = {
                  email: this.emailFormControl.value?.trim() || "",
                  phone: this.phoneControl.value?.trim() || "",
                  subject: SelfData.account[0].data[0].company + " - Look Book",
                  message: SelfData.account[0].data[0].company + "\n" + 'Please see "Look Book" attachment' + "\n",
                  key: key,
                  accountid: this.getAccountID(),
                  userid: SelfData.account[0].data[0].id,
                  lbmainid: this.selectedlookbook[0].id,
                  from: "13175970635",
                  isactivity: true,
                  ctrl: 0,
                  id: 0,
                  name: this.nameControl.value?.trim() || ""
                };
                this.serviceservice.smssendlbsent(data).subscribe({
                  next: (response: PullSheetData) => {
                    if (!isshow) {
                      this.messageService.add({
                        key: 'tc',
                        severity: 'success',
                        summary: 'The message has been sent.',
                        detail: '\n' +
                          'Notice! The message has been sent to the specified number.',
                        life: 10000,
                      });
                      this.yourappsService.getReloadSLBlist()
                      this.modalService.closelsbframe()
                    }
                  },
                  error: error => {
                    console.error('Error:', error);
                  }
                });
              }
            }
          }
        }
      })
      this.modalService.openCatalogsList()
    } else if (this.messagemode === 1) {
      var filteredSeasons1: GetlbCatalogs[] = [{Code: 0, ErrorMessage: '', data: []}];
      // var iDeletedToResult: Getlbseasonlist[] = [{Code: 0, ErrorMessage: '', data: []}];
      filteredSeasons1[0].data = this.ilbResult[0].data
      this.yourappsService.selectCollectionList(filteredSeasons1, 1)
      this.modalService.openCatalogsList1()
      this.subscription1 = this.yourappsService.retColectionList$.subscribe((value: { data: any, type: number }) => {
        if (this.subscription1) {
          this.subscription1.unsubscribe();
          if (value.data) {
            if (value.type === 2) {
              this.modalService.closelsbframe()
            } else if (value.type === 1) {
              var isshow: boolean = false
              let key = this.hashService.md5(this.getRandomNumber().toString())
              value.data[0].data.forEach((item: any) => {
                console.log("item.id", item.id)
                const tempcatalogDetails: LbTempCatalogDetails = {
                  id: item.id, // Replace with actual id
                  description: "",
                  accountid: this.getAccountID(),
                  designerid:  0,
                  seasonid: 0,
                  isactivity: false,
                  ctrl: 2,
                  lbmainid: 0
                };
                // Handle the response here
                this.serviceservice.lbtempcatalogs(tempcatalogDetails).subscribe({
                  next: (value: LbCatalogData) => {
                    console.log(value)
                  },
                  error: (error: any) => {
                    console.error('Error occurred:', error);
                  },
                  complete: () => {
                    console.log('Subscription completed');
                  }
                })
              })
              if (this.emailFormControl.value?.trim() !== "") {
                const data: lbsentDetails = {
                  email: this.emailFormControl.value?.trim() || "",
                  phone: this.phoneControl.value?.trim() || "",
                  subject: SelfData.account[0].data[0].company + " - Look Book",
                  message: this.text.trim(),
                  key: key,
                  accountid: this.getAccountID(),
                  userid: SelfData.account[0].data[0].id,
                  lbmainid: this.selectedlookbook[0].id,
                  from: 'tata-pr@tata-la.com',
                  isactivity: true,
                  ctrl: 0,
                  id: 0,
                  name: this.nameControl.value?.trim() || ""
                };
                this.serviceservice.lbsentemail(data).subscribe({
                  next: (response: lbsentData) => {
                    isshow = true
                    this.messageService.add({
                      key: 'tc',
                      severity: 'success',
                      summary: 'E-mail Sent',
                      detail: '\n' +
                        'Notice! The text contains a link and this email may end up in the Junk/Spam on the other side. \n' +
                        'It would be nice if you tell the other party to check Junk/Spam if they don\'t see the email.',
                      life: 10000,
                    });
                    this.yourappsService.getReloadSLBlist()
                    this.modalService.closelsbframe()
                  },
                  error: error => {
                    console.error('Error:', error);
                  }
                });
              }

              if (this.phoneControl.value?.trim() !== "") {
                const data: lbsentDetails = {
                  email: this.emailFormControl.value?.trim() || "",
                  phone: this.phoneControl.value?.trim() || "",
                  subject: SelfData.account[0].data[0].company + " - Look Book",
                  message: SelfData.account[0].data[0].company + "\n" + 'Please see "Look Book" attachment' + "\n",
                  key: key,
                  accountid: this.getAccountID(),
                  userid: SelfData.account[0].data[0].id,
                  lbmainid: this.selectedlookbook[0].id,
                  from: "13175970635",
                  isactivity: true,
                  ctrl: 0,
                  id: 0,
                  name: this.nameControl.value?.trim() || ""
                };
                this.serviceservice.smssendlbsent(data).subscribe({
                  next: (response: PullSheetData) => {
                    if (!isshow) {
                      this.messageService.add({
                        key: 'tc',
                        severity: 'success',
                        summary: 'The message has been sent.',
                        detail: '\n' +
                          'Notice! The message has been sent to the specified number.',
                        life: 10000,
                      });
                      this.yourappsService.getReloadSLBlist()
                      this.modalService.closelsbframe()
                    }
                  },
                  error: error => {
                    console.error('Error:', error);
                  }
                });
              }
            }
          }
        }
      })
    }
  }

  onButtonSendClick() {
    this.showdialog = true
    this.cdr.detectChanges()
    var isshow: boolean = false
    if (this.emailFormControl.value?.trim() === "" && this.phoneControl.value?.trim() === "") {
      this.messageService.add({
        key: 'tc',
        severity: 'error',
        summary: 'Information',
        detail: 'Please provide either an email address or a phone number.',
        life: 10000,
      });
      return
    }

    if (this.nameControl.value?.trim() === "") {
      this.messageService.add({
        key: 'tc',
        severity: 'error',
        summary: 'Information',
        detail: 'You need to add name',
        life: 10000,
      });
      return
    }

    if (this.emailFormControl.value?.trim() !== "" && this.emailFormControl.invalid) {
      this.messageService.add({
        key: 'tc',
        severity: 'error',
        summary: 'Information',
        detail: 'Incorrect email',
        life: 10000,
      });
      return
    }

    if (this.sendoutControl.value?.trim() === "") {
      this.messageService.add({
        key: 'tc',
        severity: 'error',
        summary: 'Information',
        detail: 'You didn\'t specify a Look Book',
        life: 10000,
      });
      return
    }

    let key = this.hashService.md5(this.getRandomNumber().toString())
    let result = false
    if (this.iSeasonToResult[0].data.length > 0) {
      result = this.hasSeasonBeforeCurrentYear(this.iSeasonToResult)
    }
    if (result) {
      this.messagemode = 0
      this.confirmationService.confirm({
        message: `<strong>This Look Book contains old season collections. Would you like to send?</strong>`,
        header: 'Information',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          if (this.emailFormControl.value?.trim() !== "") {
            const data: lbsentDetails = {
              email: this.emailFormControl.value?.trim() || "",
              phone: this.phoneControl.value?.trim() || "",
              subject: SelfData.account[0].data[0].company + " - Look Book",
              message: this.text.trim(),
              key: key,
              accountid: this.getAccountID(),
              userid: SelfData.account[0].data[0].id,
              lbmainid: this.selectedlookbook[0].id,
              from: 'tata-pr@tata-la.com',
              isactivity: true,
              ctrl: 0,
              id: 0,
              name: this.nameControl.value?.trim() || ""
            };
            this.serviceservice.lbsentemail(data).subscribe({
              next: (response: lbsentData) => {
                isshow = true
                this.messageService.add({
                  key: 'tc',
                  severity: 'success',
                  summary: 'E-mail Sent',
                  detail: '\n' +
                    'Notice! The text contains a link and this email may end up in the Junk/Spam on the other side. \n' +
                    'It would be nice if you tell the other party to check Junk/Spam if they don\'t see the email.',
                  life: 10000,
                });
                this.yourappsService.getReloadSLBlist()
                this.modalService.closelsbframe()
              },
              error: error => {
                console.error('Error:', error);
              }
            });
          }

          if (this.phoneControl.value?.trim() !== "") {
            const data: lbsentDetails = {
              email: this.emailFormControl.value?.trim() || "",
              phone: this.phoneControl.value?.trim() || "",
              subject: SelfData.account[0].data[0].company + " - Look Book",
              message: SelfData.account[0].data[0].company + "\n" + 'Please see "Look Book" attachment' + "\n",
              key: key,
              accountid: this.getAccountID(),
              userid: SelfData.account[0].data[0].id,
              lbmainid: this.selectedlookbook[0].id,
              from: "13175970635",
              isactivity: true,
              ctrl: 0,
              id: 0,
              name: this.nameControl.value?.trim() || ""
            };
            this.serviceservice.smssendlbsent(data).subscribe({
              next: (response: PullSheetData) => {
                if (!isshow) {
                  this.messageService.add({
                    key: 'tc',
                    severity: 'success',
                    summary: 'The message has been sent.',
                    detail: '\n' +
                      'Notice! The message has been sent to the specified number.',
                    life: 10000,
                  });
                  this.yourappsService.getReloadSLBlist()
                  this.modalService.closelsbframe()
                }
              },
              error: error => {
                console.error('Error:', error);
              }
            });
          }
        },
        reject: (type: any) => {
          if (type == ConfirmEventType.REJECT) {

          } else if (type == ConfirmEventType.CANCEL) {

          }
        }

      });
      setTimeout(() => {
        this.applyLocalStyles();
      }, 0);
    } else if (this.ilbResult[0].data.length > 0) {
      this.messagemode = 1
      this.confirmationService.confirm({
        message: `<strong>You have not entered new catalogs in this Look Book. <br>Would you like to send?</strong>`,
        header: 'Information',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          if (this.emailFormControl.value?.trim() !== "") {
            const data: lbsentDetails = {
              email: this.emailFormControl.value?.trim() || "",
              phone: this.phoneControl.value?.trim() || "",
              subject: SelfData.account[0].data[0].company + " - Look Book",
              message: this.text.trim(),
              key: key,
              accountid: this.getAccountID(),
              userid: SelfData.account[0].data[0].id,
              lbmainid: this.selectedlookbook[0].id,
              from: 'tata-pr@tata-la.com',
              isactivity: true,
              ctrl: 0,
              id: 0,
              name: this.nameControl.value?.trim() || ""
            };
            this.serviceservice.lbsentemail(data).subscribe({
              next: (response: lbsentData) => {
                isshow = true
                this.messageService.add({
                  key: 'tc',
                  severity: 'success',
                  summary: 'E-mail Sent',
                  detail: '\n' +
                    'Notice! The text contains a link and this email may end up in the Junk/Spam on the other side. \n' +
                    'It would be nice if you tell the other party to check Junk/Spam if they don\'t see the email.',
                  life: 10000,
                });
                this.yourappsService.getReloadSLBlist()
                this.modalService.closelsbframe()
              },
              error: error => {
                console.error('Error:', error);
              }
            });
          }

          if (this.phoneControl.value?.trim() !== "") {
            const data: lbsentDetails = {
              email: this.emailFormControl.value?.trim() || "",
              phone: this.phoneControl.value?.trim() || "",
              subject: SelfData.account[0].data[0].company + " - Look Book",
              message: SelfData.account[0].data[0].company + "\n" + 'Please see "Look Book" attachment' + "\n",
              key: key,
              accountid: this.getAccountID(),
              userid: SelfData.account[0].data[0].id,
              lbmainid: this.selectedlookbook[0].id,
              from: "13175970635",
              isactivity: true,
              ctrl: 0,
              id: 0,
              name: this.nameControl.value?.trim() || ""
            };
            this.serviceservice.smssendlbsent(data).subscribe({
              next: (response: PullSheetData) => {
                if (!isshow) {
                  this.messageService.add({
                    key: 'tc',
                    severity: 'success',
                    summary: 'The message has been sent.',
                    detail: '\n' +
                      'Notice! The message has been sent to the specified number.',
                    life: 10000,
                  });
                  this.yourappsService.getReloadSLBlist()
                  this.modalService.closelsbframe()
                }
              },
              error: error => {
                console.error('Error:', error);
              }
            });
          }
        },
        reject: (type: any) => {
          if (type == ConfirmEventType.REJECT) {

          } else if (type == ConfirmEventType.CANCEL) {

          }
        }

      });
      setTimeout(() => {
        this.applyLocalRestoreStyles();
      }, 0);
    }
    else {
      if (this.emailFormControl.value?.trim() !== "") {
        const data: lbsentDetails = {
          email: this.emailFormControl.value?.trim() || "",
          phone: this.phoneControl.value?.trim() || "",
          subject: SelfData.account[0].data[0].company + " - Look Book",
          message: this.text.trim(),
          key: key,
          accountid: this.getAccountID(),
          userid: SelfData.account[0].data[0].id,
          lbmainid: this.selectedlookbook[0].id,
          from: 'tata-pr@tata-la.com',
          isactivity: true,
          ctrl: 0,
          id: 0,
          name: this.nameControl.value?.trim() || ""
        };
        this.serviceservice.lbsentemail(data).subscribe({
          next: (response: lbsentData) => {
            isshow = true
            this.messageService.add({
              key: 'tc',
              severity: 'success',
              summary: 'E-mail Sent',
              detail: '\n' +
                'Notice! The text contains a link and this email may end up in the Junk/Spam on the other side. \n' +
                'It would be nice if you tell the other party to check Junk/Spam if they don\'t see the email.',
              life: 10000,
            });
            this.yourappsService.getReloadSLBlist()
            this.modalService.closelsbframe()
          },
          error: error => {
            console.error('Error:', error);
          }
        });
      }

      if (this.phoneControl.value?.trim() !== "") {
        const data: lbsentDetails = {
          email: this.emailFormControl.value?.trim() || "",
          phone: this.phoneControl.value?.trim() || "",
          subject: SelfData.account[0].data[0].company + " - Look Book",
          message: SelfData.account[0].data[0].company + "\n" + 'Please see "Look Book" attachment' + "\n",
          key: key,
          accountid: this.getAccountID(),
          userid: SelfData.account[0].data[0].id,
          lbmainid: this.selectedlookbook[0].id,
          from: "13175970635",
          isactivity: true,
          ctrl: 0,
          id: 0,
          name: this.nameControl.value?.trim() || ""
        };
        this.serviceservice.smssendlbsent(data).subscribe({
          next: (response: PullSheetData) => {
            if (!isshow) {
              this.messageService.add({
                key: 'tc',
                severity: 'success',
                summary: 'The message has been sent.',
                detail: '\n' +
                  'Notice! The message has been sent to the specified number.',
                life: 10000,
              });
              this.yourappsService.getReloadSLBlist()
              this.modalService.closelsbframe()
            }
          },
          error: error => {
            console.error('Error:', error);
          }
        });
      }
    }
  }

  private applyLocalStyles() {
    setTimeout(() => {
      const messageElements = document.querySelectorAll('.p-confirm-dialog-message');
      messageElements.forEach((element) => {
        element.setAttribute('style', 'font-size: 16px; color: red;');
      });
    }, 0);

    const iconElements = document.querySelectorAll('.p-confirm-dialog-icon');
    iconElements.forEach((element) => {
      element.setAttribute('style', 'color: red');
    });
  }

  private applyLocalRestoreStyles() {
    setTimeout(() => {
      const messageElements = document.querySelectorAll('.p-confirm-dialog-message');
      messageElements.forEach((element) => {
        element.setAttribute('style', 'font-size: 16px; color: #343a40;');
      });
    }, 0);

    const iconElements = document.querySelectorAll('.p-confirm-dialog-icon');
    iconElements.forEach((element) => {
      element.setAttribute('style', 'color: #343a40');
    });
  }

  protected readonly SelfData = SelfData;
}
