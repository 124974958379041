<button mat-icon-button type="button" class="close-btn" (click)="onButtonCloseClick()">
  <mat-icon>close</mat-icon>
</button>
<p class="sample-manager-text">Catalogs</p>

<div class="updatebutton">
  <button class="btnupdatte" mat-raised-button type="button" (click)="onButtonSelectAllClick()">
    Remove All
    <mat-icon>delete_sweep</mat-icon>
  </button>
</div>

<div cdkDropList [cdkDropListData]="iSeasonToResult[0].data" class="example-list"
  (cdkDropListDropped)="dropseason($event)">
  <div class="full-width-caption-look">
    <p class="caption-text-look">Selected Catalogs ({{iSeasonToResult[0].data.length}})</p>
  </div>
  <div class="example-box1" *ngFor="let item of iSeasonToResult[0].data || []; let i = index" cdkDrag
    (click)="onItemClick(item, i)">
    <div class="items">
      <span class="designer-name">{{ item.description }}</span>
      <span class="designer-name">{{ item.seasoncode }}</span>
      <span class="designer-year">{{ item.seasonyear }}</span>
    </div>
    <span class="p-inputgroup-addon lb-hover" matRipple (click)="onCatalogRemoveClick(item, i)">
      Remove</span>
  </div>
</div>

<div class="button-container">
  <button mat-raised-button class="button-settings" type="button" (click)="onDeleteButtonClick()">
    Confirm
  </button>
</div>
