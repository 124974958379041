import { Component } from '@angular/core';
import { ModalserviceService } from '../../services/modalservice.service';
import { MessageboxComponent } from '../../modals/messagebox/messagebox.component';
import { ServiceService } from '../../services/service.service';
import { YourappsserviceService } from '../../services/yourappsservice.service';
import { IpService } from '../../services/ip.service';
import { LocalStorageService } from '../../services/local-storage.service';
import { SelfData } from '../../models/struct';

@Component({
  selector: 'app-imagezoomdialog',
  templateUrl: './imagezoomdialog.component.html',
  styleUrls: ['./imagezoomdialog.component.scss'],
})
export class ImagezoomdialogComponent {
  imageUrl!: string;
  constructor(
    public modalService: ModalserviceService,
    public messageBox: MessageboxComponent,
    private serviceservice: ServiceService,
    private yourappsService: YourappsserviceService,
    private ipService: IpService,
    private localStorageService: LocalStorageService
  ) {
    this.imageUrl = SelfData.imageUrl;
  }
}
