import { Component } from '@angular/core';
import { ModalserviceService } from '../../services/modalservice.service';
import { YourappsserviceService } from '../../services/yourappsservice.service';

@Component({
  selector: 'app-settingsdialog',
  templateUrl: './settingsdialog.component.html',
  styleUrls: ['./settingsdialog.component.scss'],
})
export class SettingsdialogComponent {
  isHidden = false;
  iscontactsHiden = true;
  constructor(
    public modalService: ModalserviceService,
    private yourappsService: YourappsserviceService
  ) {
    this.yourappsService.reloadAvatarImage.subscribe(() => {});
  }

  showsamplefields() {
    this.isHidden = false;
    this.iscontactsHiden = true;
  }
  showcontactfields() {
    this.isHidden = true;
    this.iscontactsHiden = false;
  }
}
