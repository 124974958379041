<button mat-icon-button type="button" class="close-btn" (click)="modalService.aesClose()">
  <mat-icon>close</mat-icon>
</button>
<p class="sample-manager-customer">{{caption}}</p>

<div class="componentsdiv">
  <div class="componentsdiv2">
    <label class="txtdiv">Description</label>
    <nz-input-group>
      <input class="cmdiv" type="text" [(ngModel)]="description" nz-input/>
    </nz-input-group>

  </div>
</div>

<div class="footerdiv">
  <button nz-button nzType="default" (click)="onAddButtonClick()">{{caption}}</button>
</div>
