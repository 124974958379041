<form [formGroup]="myForm" (ngSubmit)="submit()" autocomplete="off">
    <button mat-icon-button type="button" class="close-btn" (click)="modalService.createssclose()">
        <mat-icon>close</mat-icon>
    </button>
    <p class="sample-manager-text">View Queue</p>
    <div class="scrollable-div-ss">
        <div class="card example-container-ss">
            <p-table
                    [value]="SelfData.checkedsample"
                    [paginator]="true"
                    [rows]="5"
                    [style]="{'border-radius': '10px'}"
                    [showCurrentPageReport]="true"
                    [scrollable]="true"
                    [scrollHeight]="'calc(70vh - 130px)'"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    [rowsPerPageOptions]="[5, 10, 25, 50, 100]"
                    [styleClass]="'p-datatable-striped mat-elevation-z3 borders ' + selectedSize.class.toString()"
                    selectionMode="single"
                    [tableStyle]="{'min-width': '50rem'}">
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width:4%"></th>
                        <th style="width:4%">Options</th>
                        <th style="width:17.6%">Designer<br/>Collection<br/>Season</th>
                        <th style="width:17.6%">Type<br/>Color<br/></th>
                        <th style="width:12.6%">Barcode</th>
                        <th style="width:22.6%">Image</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-product>
                    <tr [pSelectableRow]="product">
                        <td>
                            <mat-checkbox
                                    [(ngModel)]="product['checked']"
                                    [ngModelOptions]="{ standalone: true }"
                                    (ngModelChange)="updateCheckedSamples(product)"
                            ></mat-checkbox>
                        </td>
                        <td>
                            <p-button label="Select" size="small" [outlined]="true" severity="secondary"
                                      (click)="onSelectedItemCLick(product['barcode'])">

                            </p-button>
<!--                            <div class="button-container-table">-->
<!--                                <button mat-stroked-button-->
<!--                                        (click)="onSelectedItemCLick(product['barcode'])">-->
<!--                                    Select-->
<!--                                </button>-->
<!--                            </div>-->

                        </td>
                        <td>{{product["Designer"]}}<br>{{ product?.["Collection"] }}<br>{{product["Season"]}}</td>
                        <td>{{product["Type"]}}<br>{{ product?.["Color"] }}<br></td>
                        <td>
                            <app-barcode [element]="product?.barcode" [background]="'rgba(255,255,255,0)'"
                                         class="barcode-component"></app-barcode>
                        </td>
                        <td><img [src]="product['Image Link']" alt="Image" class="image-size-80x80 shadow-4"></td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>

<!--    <section class="example-container-ss" tabindex="0">-->
<!--        <table mat-table [dataSource]="setdataSource">-->
<!--            <ng-container matColumnDef="checkbox">-->
<!--                <th-->
<!--                        class="checkboxfield mat-header-row"-->
<!--                        mat-header-cell-->
<!--                        *matHeaderCellDef-->
<!--                ></th>-->
<!--                <td mat-cell *matCellDef="let element" class="icon-buttons">-->
<!--                    <div class="checkbox-container">-->
<!--                        <mat-checkbox-->
<!--                                [(ngModel)]="element['checked']"-->
<!--                                [ngModelOptions]="{ standalone: true }"-->
<!--                                (ngModelChange)="updateCheckedSamples(element)"-->
<!--                        ></mat-checkbox>-->
<!--                    </div>-->
<!--                </td>-->
<!--            </ng-container>-->

<!--            <ng-container matColumnDef="actions">-->
<!--                <th-->
<!--                        class="field2 mat-header-row"-->
<!--                        mat-header-cell-->
<!--                        *matHeaderCellDef-->
<!--                ></th>-->
<!--                <td mat-cell *matCellDef="let element" class="icon-buttons">-->
<!--                    <div class="button-container-table">-->
<!--                        <button-->
<!--                                mat-stroked-button-->
<!--                                (click)="onSelectedItemCLick(element['barcode'])"-->
<!--                        >-->
<!--                            Select-->
<!--                        </button>-->
<!--                    </div>-->
<!--                </td>-->
<!--            </ng-container>-->

<!--            <ng-container matColumnDef="Designer">-->
<!--                <th class="field1 mat-header-row" mat-header-cell *matHeaderCellDef>-->
<!--                    Designer-->
<!--                    <br/>-->
<!--                    Collection-->
<!--                    <br/>-->
<!--                    Season-->
<!--                </th>-->
<!--                <td mat-cell *matCellDef="let element">-->
<!--          <span>-->
<!--            {{ element?.["Designer"] }}-->
<!--              <br/>-->
<!--              {{ element?.["Collection"] }}-->
<!--              <br/>-->
<!--              {{ element?.["Season"] }}-->
<!--          </span>-->
<!--                </td>-->
<!--            </ng-container>-->

<!--            <ng-container matColumnDef="Type">-->
<!--                <th class="typefield mat-header-row" mat-header-cell *matHeaderCellDef>-->
<!--                    Type <br/>-->
<!--                    Barcode <br/>-->
<!--                    Color <br/>-->
<!--                </th>-->
<!--                <td mat-cell *matCellDef="let element">-->
<!--          <span>-->
<!--            {{ element?.["Type"] }}-->
<!--              <br/>-->
<!--              {{ element?.["barcode"] }}-->
<!--              <br/>-->
<!--              {{ element?.["Color"] }}-->
<!--          </span>-->
<!--                </td>-->
<!--            </ng-container>-->

<!--            <ng-container matColumnDef="Description">-->
<!--                <th-->
<!--                        class="descriptionfield mat-header-row"-->
<!--                        mat-header-cell-->
<!--                        *matHeaderCellDef-->
<!--                >-->
<!--                    Description-->
<!--                </th>-->
<!--                <td mat-cell *matCellDef="let element">-->
<!--                    {{ element?.["Description"] }}-->
<!--                </td>-->
<!--            </ng-container>-->

<!--            <ng-container matColumnDef="Image">-->
<!--                <th class="imagefield mat-header-row" mat-header-cell *matHeaderCellDef>-->
<!--                    Image-->
<!--                </th>-->
<!--                <td mat-cell *matCellDef="let element" class="icon-buttons">-->
<!--                    <img-->
<!--                            [src]="element?.['Image Link']"-->
<!--                            alt="Image"-->
<!--                            class="image-size-80x80"-->
<!--                    />-->
<!--                </td>-->
<!--            </ng-container>-->

<!--            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>-->
<!--            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>-->
<!--        </table>-->
<!--        <mat-paginator-->
<!--                class="sticky-paginator"-->
<!--                [pageSizeOptions]="[5, 10, 20, 50, 100]"-->
<!--                aria-label="Select page of periodic elements"-->
<!--        >-->
<!--        </mat-paginator>-->
<!--    </section>-->
</form>
