<form [formGroup]="myForm" (ngSubmit)="submit()" autocomplete="off">
  <button
    mat-icon-button
    type="button"
    class="close-btn"
    (click)="modalService.closeCollection()"
  >
    <mat-icon>close</mat-icon>
  </button>
  <p class="sample-manager-text">{{ frameCaption }}</p>

  <div class="black-background1">
    <mat-form-field class="selector-full-width" appearance="fill">
      <mat-label>Designer</mat-label>
      <input type="text" matInput placeholder="Select or type..." [matAutocomplete]="auto2"
        [formControl]="desingerFormControl" #autoInput2="matAutocompleteTrigger"/>
      <button mat-stroked-button type="button" class="add-button" matSuffix (click)="add($event, 2, autoInput2)">
        <mat-icon class="center-icon">add</mat-icon>
      </button>
      <mat-autocomplete #auto2="matAutocomplete">
        <mat-option *ngFor="let item of filteredDesignerOptions | async" [value]="item">
          {{ item }}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="desingerFormControl.hasError('required')">
        Designer is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </div>

  <div class="black-background">
    <mat-form-field class="selector-full-width" appearance="fill">
      <mat-label>Season</mat-label>
      <input type="text" matInput placeholder="Select or type..." [matAutocomplete]="auto3"
        [formControl]="seasonFormControl"
        #autoInput3="matAutocompleteTrigger"/>

      <button mat-stroked-button type="button" class="add-button" matSuffix (click)="add($event, 6, autoInput3)">
        <mat-icon class="center-icon">add</mat-icon>
      </button>
      <mat-autocomplete #auto3="matAutocomplete">
        <mat-option *ngFor="let item of filteredSeasonOptions | async" [value]="item">
          {{ item }}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="seasonFormControl.hasError('required')">
        Season is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </div>

  <div class="black-background">
    <mat-form-field class="selector-full-width" appearance="fill">
      <mat-label>Collection</mat-label>
      <input
        type="text"
        matInput
        placeholder="Select or type..."
        [matAutocomplete]="auto5"
        [formControl]="collectionFormControl"
        #autoInput5="matAutocompleteTrigger"
      />
      <mat-autocomplete #auto5="matAutocomplete">
        <mat-option
          *ngFor="let item of filteredCollectionOptions | async" [value]="item">
          {{ item }}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="collectionFormControl.hasError('required')">
        Collection is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </div>

  <div class="button-container">
    <button
      mat-flat-button
      type="button"
      (click)="modalService.closeCollection()">
      Cancel
    </button>
    <button mat-flat-button type="submit">OK</button>
  </div>
</form>
