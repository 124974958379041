<p-toast *ngIf="!isDesktopDevice" position="bottom-center" key="bc" [style]="{ width: '90vw' }"></p-toast>
<p-toast *ngIf="isDesktopDevice" position="bottom-center" key="bc" [style]="{ width: '30vw' }"></p-toast>
<div [ngClass]="deviceClass">
    <mat-card id="pcmaincard" class="printerpage">
        <mat-card-content>
            <div class="mainrow-pull">
                <div class="child-1-pull">
                    <div class="maindiv">
                        <div class="companyname">
                            <ng-container
                                    *ngIf="SelfData?.pullsheetlist?.length && SelfData.pullsheetlist[0]?.data?.length">
                                <span>
                                    {{SelfData.pullsheetlist[0].data[0].company}}
                                </span>
                            </ng-container>
                        </div>
                        <div [ngClass]="[deviceClassAddress, isLandscape ? 'islandscape' : 'isnotlandscape']">
                            <ng-container
                                    *ngIf="SelfData?.pullsheetlist?.length && SelfData.pullsheetlist[0]?.data?.length">
                                <span>
                                    {{SelfData.pullsheetlist[0].data[0].address1}},
                                    {{SelfData.pullsheetlist[0].data[0].address2}},
                                    {{SelfData.pullsheetlist[0].data[0].city}},
                                    {{SelfData.pullsheetlist[0].data[0].state}},
                                    {{SelfData.pullsheetlist[0].data[0].zipcode}}
                                </span>
                            </ng-container>
                            <!--                            <p>291 S La Cienega Blvd Suite 401 Beverly Hills, CA 90211</p>-->
                            <div class="divcontacts">
                                <!--                                <p>TATA: (818) 458 0108 tata-pr&#64;tata-la.com</p>-->
                                <ng-container
                                        *ngIf="SelfData?.pullsheetlist?.length && SelfData.pullsheetlist[0]?.data?.length">
                                    <span>
                                        {{SelfData.pullsheetlist[0].data[0].prmanager}}
                                        {{SelfData.pullsheetlist[0].data[0].prphone}}
                                        {{SelfData.pullsheetlist[0].data[0].premail}}
                                    </span>
                                </ng-container> <br>
                                <!--                                <p>OLIVIA: (562) 315 6051 pr-assistant&#64;tata-la.com</p>-->
                                <ng-container *ngIf="SelfData?.pullsheetlist?.length && SelfData.pullsheetlist[0]?.data?.length">
                                    <span>
                                        {{SelfData.pullsheetlist[0].data[0].asistant}}
                                        {{SelfData.pullsheetlist[0].data[0].asiphone}}
                                        {{SelfData.pullsheetlist[0].data[0].asiemail}}
                                    </span>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="child-2-pull">
                    <!--                    <img *ngIf="imageUrl === ''" class="account-image-pull" src="assets/tatalogo.png" alt="noimage"/>-->
                    <img *ngIf="imageUrl !== ''" class="account-image1" [src]="imageUrl" alt="Avatar"/>
                </div>
            </div>
            <div class="blackline"></div>
            <div class="line3">
                <div class="option1">
                    <span [ngClass]="[deviceClassAddress, 'option1_1', isLandscape ? 'islandscapedate' : 'isnotlandscapedate']">Date of Pull:</span>
                    <div class="datediv">
                        <ng-container
                                *ngIf="SelfData?.pullsheetlist?.length && SelfData.pullsheetlist[0]?.data?.length">
                            {{ extractDateFromDateObject(SelfData.pullsheetlist[0].data[0].dateofpull) }}
                        </ng-container>
                    </div>
                </div>
                <div class="option1">
                    <span [ngClass]="[deviceClassAddress, 'option1_1', isLandscape ? 'islandscapedate' : 'isnotlandscapedate']">Date of Fitting:</span>
                    <div class="datediv">
                        <ng-container
                                *ngIf="SelfData?.pullsheetlist?.length && SelfData.pullsheetlist[0]?.data?.length">
                            {{ extractDateFromDateObject(SelfData.pullsheetlist[0].data[0].dateodfitting) }}
                        </ng-container>
                    </div>
                </div>
                <div class="option1">
                    <span [ngClass]="[deviceClassAddress, 'option1_1', isLandscape ? 'islandscapedate' : 'isnotlandscapedate']">Date of Shoot/Event:</span>
                    <div class="datediv">
                        <ng-container
                                *ngIf="SelfData?.pullsheetlist?.length && SelfData.pullsheetlist[0]?.data?.length">
                            {{ extractDateFromDateObject(SelfData.pullsheetlist[0].data[0].dateofse) }}
                        </ng-container>
                    </div>
                </div>
                <div class="option1">
                    <span [ngClass]="[deviceClassAddress, 'option1_1', isLandscape ? 'islandscapedate' : 'isnotlandscapedate']">Date of Edits Return:</span>
                    <div class="datediv">
                        <ng-container
                                *ngIf="SelfData?.pullsheetlist?.length && SelfData.pullsheetlist[0]?.data?.length">
                            {{ extractDateFromDateObject(SelfData.pullsheetlist[0].data[0].dateoder) }}
                        </ng-container>
                    </div>
                </div>
                <div class="option1">
                    <span [ngClass]="[deviceClassAddress, 'option1_1', isLandscape ? 'islandscapedate' : 'isnotlandscapedate']">Date of Final Return:</span>
                    <div class="datediv">
                        <ng-container
                                *ngIf="SelfData?.pullsheetlist?.length && SelfData.pullsheetlist[0]?.data?.length">
                            {{ extractDateFromDateObject(SelfData.pullsheetlist[0].data[0].dateodfitting) }}
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="line4">
                <div class="option1">
                    <span [ngClass]="[deviceClassAddress, 'option1_1', isLandscape ? 'islandscapedate' : 'isnotlandscapedate']"># of Garment Bags:</span>
                    <div class="datediv">
                        <ng-container   *ngIf="SelfData?.pullsheetlist?.length && SelfData.pullsheetlist[0]?.data?.length">
                            {{ SelfData.pullsheetlist[0].data[0].gb }}
                        </ng-container>
                    </div>
                </div>
                <div class="option1">
                    <span [ngClass]="[deviceClassAddress, 'option1_1', isLandscape ? 'islandscapedate' : 'isnotlandscapedate']"># of Tote Bags:</span>
                    <div class="datediv">
                        <ng-container
                                *ngIf="SelfData?.pullsheetlist?.length && SelfData.pullsheetlist[0]?.data?.length">
                            {{ SelfData.pullsheetlist[0].data[0].tb }}
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="line5">
                <div class="line5_1">
                    <span>PRESS REQUIREMENTS</span>
                </div>
            </div>
            <div [ngClass]="[deviceClassAddress, 'line5', isLandscape ? 'islandscapedate' : 'isnotlandscapedate']">
                <ng-container *ngIf="SelfData?.pullsheetlist?.length && SelfData.pullsheetlist[0]?.data?.length">
                    <span class="no-wrap">
                        This letter is to verify that (Stylist’s name)
                        <span class="underline">{{ SelfData.pullsheetlist[0].data[0].stylistname }}</span>
                        is styling a VIP or Editorial feature or shoot for (Publication/Celebrity) <span
                            class="underline">{{ SelfData.pullsheetlist[0].data[0].publication }}</span> This letter confirms that I, <span
                            class="underline">{{ SelfData.pullsheetlist[0].data[0].confirms }}</span>
                    </span>
                </ng-container>

                <ng-container *ngIf="SelfData?.pullsheetlist?.length && SelfData.pullsheetlist[0]?.data?.length">

                    <span>will take full responsibility for all samples while out on loan, and will return all items within
                        the
                        scheduled appointment date/time to the {{SelfData.pullsheetlist[0].data[0].company}}
                        office: {{SelfData.pullsheetlist[0].data[0].address1}},
                        {{SelfData.pullsheetlist[0].data[0].address2}},
                        {{SelfData.pullsheetlist[0].data[0].city}},
                        {{SelfData.pullsheetlist[0].data[0].state}},
                        {{SelfData.pullsheetlist[0].data[0].zipcode}}</span>
                </ng-container>

            </div>
            <div class="line5">
                <ng-container *ngIf="SelfData?.pullsheetlist?.length && SelfData.pullsheetlist[0]?.data?.length">
                    <span>{{SelfData.pullsheetlist[0].data[0].company}} & Designers reserves the right to share credited
                        content online and across media
                        platforms.</span>
                </ng-container>
            </div>
            <div class="line5">
                <div class="line5_1">
                    <span style="text-decoration: underline;">Red Carpet or VIP Placement:</span>
                </div>
                <ng-container *ngIf="SelfData?.pullsheetlist?.length && SelfData.pullsheetlist[0]?.data?.length">
                    <span>● Must provide images of talent in pulled items; this includes red carpet images</span>
                    <span>● Inform {{SelfData.pullsheetlist[0].data[0].prmanager}}
                        & {{SelfData.pullsheetlist[0].data[0].asistant}} via text as soon as talent is out the door w/ what they are wearing</span>
                    <span>● Talent must provide credit via tagging on Instagram: {{SelfData.pullsheetlist[0].data[0].instagram}}
                        + designers handles must be included</span>
                    <span>● Stylists may not use wardrobe pulled/approved for one talent to dress another talent without prior written approval by {{SelfData.pullsheetlist[0].data[0].prmanager}}
                        or {{SelfData.pullsheetlist[0].data[0].asistant}}</span>
                </ng-container>
            </div>
            <div class="line5">
                <div class="line5_1">
                    <span style="text-decoration: underline;">Editorial:</span>
                </div>
                <ng-container *ngIf="SelfData?.pullsheetlist?.length && SelfData.pullsheetlist[0]?.data?.length">
                <span>● Email {{SelfData.pullsheetlist[0].data[0].prmanager}}
                    & {{SelfData.pullsheetlist[0].data[0].asistant}} preview images from the shoot within 48 hours of the shoot, we will not release images until given approval. You must let us know at the time you return, or within 24 hours of the shoot,
what pieces are confirmed. Email this info to <strong>{{SelfData.pullsheetlist[0].data[0].premail}}</strong> and <strong>{{SelfData.pullsheetlist[0].data[0].asiemail}}</strong></span>
                    <span>● The designer in print will be credited, along with agency, contact {{SelfData.pullsheetlist[0].data[0].prmanager}}
                        or {{SelfData.pullsheetlist[0].data[0].asistant}} for credit information</span>
                    <span>● Provide 2 copies of publication or contact information for the person who can provide copies, as well as month, issue, number and talent in spread</span>
                    <span>● Prior to pull, you must provide a copy of the pull letter (LOR) to verify the shoot and placement</span>
                    <span>● Stylists may not change talent wearing garments without prior written approval by {{SelfData.pullsheetlist[0].data[0].prmanager}}
                        or {{SelfData.pullsheetlist[0].data[0].asistant}}</span>
                </ng-container>
            </div>
            <div class="line5">
                <div class="line5_1">
                    <span>PRE PULL:</span>
                </div>
            </div>
            <div class="line5">
                <ng-container *ngIf="SelfData?.pullsheetlist?.length && SelfData.pullsheetlist[0]?.data?.length">
                <span [ngClass]="[deviceClassAddress, 'option1_1', isLandscape ? 'islandscapedate' : 'isnotlandscapedate']">DEPOSIT OF {{SelfData.pullsheetlist[0].data[0].deposite}}
                    MUST BE SUBMITTED PRIOR TO PULL. YOU MUST PROVIDE A VALID DRIVER’S LICENSE AND CREDIT CARD, WE WILL MAKE
 PHOTOCOPIES FOR OUR RECORDS.</span>
                </ng-container>
            </div>
            <div class="line5">
                <span>Deposit Fee will be refunded on the final return date in full if no expenses are incurred. </span>
            </div>
            <div class="line5">
                <div class="line5_1">
                    <span>RETURNS / CHARGES:</span>
                </div>
            </div>
            <div class="line5">
                <ng-container *ngIf="SelfData?.pullsheetlist?.length && SelfData.pullsheetlist[0]?.data?.length">
                    <span><u>The list provided below outlines fees that will be automatically deducted from the Deposit
                        amount
                        accordingly.</u> All additional fees incurred will be owed by the Stylist
                        to {{SelfData.pullsheetlist[0].data[0].company}} upon the final
                        return date, or after sufficient effort has been made to collect the garments, and will be
                        charged
                        to the credit card provided.</span>
                </ng-container>
            </div>
            <div class="line5">
                <span>Samples are sent under our current terms and conditions. You are fully responsible for their return
                    in the
                    supplied condition. This includes returning items in the original garment bags, tote bags, and
                    hangers
                    they were received in; failure to do so will result in a fee. All lost and/or damaged items will be
                    charged
                    the full retail price.</span>
            </div>
            <div class="line5">
                <ng-container *ngIf="SelfData?.pullsheetlist?.length && SelfData.pullsheetlist[0]?.data?.length">
                    <span>ALL ALTERATIONS need to be previously approved and agreed on
                        by {{SelfData.pullsheetlist[0].data[0].company}} ({{SelfData.pullsheetlist[0].data[0].company}}
                        cannot and will
                        not cover the cost of the alterations)</span>
                </ng-container>
            </div>
            <div class="line5">
                <ng-container *ngIf="SelfData?.pullsheetlist?.length && SelfData.pullsheetlist[0]?.data?.length">
                    <span>Late fee charges will begin accumulating after 5:00pm on the day of the agreed upon date of final
                        return.
                        Any pull or item from a pull that is returned late will be charged additional fees if we are not
                        notified of an
                        updated return date prior to 5:00pm on the day of the agreed upon date of final return
                        ({{SelfData.pullsheetlist[0].data[0].latefees}} / day).
                        You
                        may only update the final return date once. NO EXCEPTIONS.</span>
                </ng-container>
            </div>

            <div class="line5">
                <ng-container *ngIf="SelfData?.pullsheetlist?.length && SelfData.pullsheetlist[0]?.data?.length">
                    <span>All worn items must be dry cleaned before return. Dry-cleaning expenses will be administered for
                        worn
                        or
                        dirtied garments ({{SelfData.pullsheetlist[0].data[0].cleaning}} / item) if not dry-cleaned upon
                        return to {{SelfData.pullsheetlist[0].data[0].company}}. Dry cleaned items must be
                        returned in the dry cleaning packaging.</span>
                </ng-container>
            </div>
            <div class="line5">
                <span>Waiver of any single part of this agreement in no way, shape or form negates any other section of
                    this
                    agreement</span>
            </div>
            <div class="line5">
                <ng-container *ngIf="SelfData?.pullsheetlist?.length && SelfData.pullsheetlist[0]?.data?.length">
                    <span>No other agreement, verbal, supposed, implied or otherwise shall in ANY way shape or form and
                        shall
                        for
                        no reason override ANY section of the {{SelfData.pullsheetlist[0].data[0].company}} contract and
                        paperwork.</span>
                </ng-container>
            </div>
            <div class="line5 line5">
                <ng-container *ngIf="SelfData?.pullsheetlist?.length && SelfData.pullsheetlist[0]?.data?.length">
                    <span><strong>• Late Fees:</strong> {{SelfData.pullsheetlist[0].data[0].latefees}} per day</span>
                    <span><strong>• Dry-Cleaning Fee:</strong> {{SelfData.pullsheetlist[0].data[0].cleaning}} per item</span>
                    <span><strong>• Minor / Total Damage Fee:</strong> {{SelfData.pullsheetlist[0].data[0].minor}}</span>
                    <span><strong>• Lost Item Fee:</strong> {{SelfData.pullsheetlist[0].data[0].lost}}</span>
                    <span><strong>• Missing Garment Bags & Tote
                        Bags:</strong> {{SelfData.pullsheetlist[0].data[0].missing}} per bag</span>
                </ng-container>
            </div>
            <div class="blacklineshort"></div>
            <div class="line5" style="text-align: center;">
                <ng-container *ngIf="SelfData?.pullsheetlist?.length && SelfData.pullsheetlist[0]?.data?.length">
                    <span><strong>By accepting these items for loan and providing us with your credit card and
                        personal data information, you are authorizing {{SelfData.pullsheetlist[0].data[0].company}} to
                        charge your credit
                        card for the deposit fee and all remaining balances due. All balances owed to
                        {{SelfData.pullsheetlist[0].data[0].company}} will be charged immediately.</strong></span>
                </ng-container>
            </div>
            <div class="line5">
                <div class="line5_1">
                    <span>PERSONAL INFO</span>
                </div>
            </div>
            <div class="line6">
                <span class="textposition">Phone Number:</span>
                <div class="textdiv">
                    <ng-container *ngIf="SelfData?.pullsheetlist?.length && SelfData.pullsheetlist[0]?.data?.length">
                        {{ SelfData.pullsheetlist[0].data[0].phonenumber }}
                    </ng-container>
                </div>
            </div>
            <div class="line7">
                <span class="textposition">Email:</span>
                <div class="textdiv">
                    <ng-container *ngIf="SelfData?.pullsheetlist?.length && SelfData.pullsheetlist[0]?.data?.length">
                        {{ SelfData.pullsheetlist[0].data[0].acceptedemail }}
                    </ng-container>
                </div>
            </div>
            <div class="line5">
                <div class="line5_1">
                    <span>BILLING INFO</span>
                </div>
            </div>
            <div class="line6">
                <span class="textposition">CC #:</span>
                <div class="textdiv">
                    <ng-container *ngIf="SelfData?.pullsheetlist?.length && SelfData.pullsheetlist[0]?.data?.length">
                        {{ SelfData.pullsheetlist[0].data[0].cc }}
                    </ng-container>
                </div>
            </div>
            <div class="line7">
                <span class="textposition">Exp. Date:</span>
                <div class="textdiv">
                    <!--                    <input class="textsettings1 p-inputtext-sm" type="email" pInputText [(ngModel)]="textvalue"/>-->
                    <ng-container *ngIf="SelfData?.pullsheetlist?.length && SelfData.pullsheetlist[0]?.data?.length">
<!--                        {{ extractDateFromDateObject(SelfData.pullsheetlist[0].data[0].expdate) }}-->
                    </ng-container>
                </div>
            </div>
            <div class="line7">
                <span class="textposition">Security Code:</span>
                <div class="textdiv">
                    <ng-container *ngIf="SelfData?.pullsheetlist?.length && SelfData.pullsheetlist[0]?.data?.length">
                        {{ SelfData.pullsheetlist[0].data[0].securitycode }}
                    </ng-container>
                </div>
            </div>
            <div class="line7">
                <span class="textposition">Billing Zip Code:</span>
                <div class="textdiv">
                    <ng-container *ngIf="SelfData?.pullsheetlist?.length && SelfData.pullsheetlist[0]?.data?.length">
                        {{ SelfData.pullsheetlist[0].data[0].bilingzipcode }}
                    </ng-container>
                </div>
            </div>
            <div class="line5">
                <div class="line5_1">
                    <span>SIGNATURES</span>
                </div>
            </div>
            <div class="line6">
                <span class="textposition">(Print Name)</span>
                <div class="textdiv">
                    <ng-container *ngIf="SelfData?.pullsheetlist?.length && SelfData.pullsheetlist[0]?.data?.length">
                        {{ SelfData.pullsheetlist[0].data[0].printname }}
                    </ng-container>
                </div>
            </div>
            <div class="line6">
                <span class="textposition">(Signature)</span>
                <div class="signaturediv">
<!--                    <img *ngIf="signatureImg === ''" class="account-image-pull" src="assets/signature.png"-->
<!--                         alt="noimage"/>-->
                    <img *ngIf="signatureImg !== ''" class="account-image-pull" [src]="signatureImg" alt="noimage"
                    />
                    <!--                    <input class="textsettings1 p-inputtext-sm" type="text" pInputText [(ngModel)]="textvalue"/>-->
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>
<div [ngClass]="footerClass">
    <div class="button-container-dm">
        <button *ngIf="isDesktopDevice" icon="pi pi-print" type="button" label="Print" class="bgcolor button-settings-dm" pButton matRipple (click)="onButtonPrintClick()">
        </button>
        <button *ngIf="!isDesktopDevice" icon="pi pi-print" type="button" label="Print" class="bgcolor button-settings-dm" pButton matRipple (click)="printContent()">
        </button>
        <!--        <p-button icon="pi pi-check" label="Accept" styleClass="button-settings-dm" (click)="onButtonAcceptClick()"></p-button>-->
        <!--        <button mat-raised-button class="button-settings-dm" type="button" (click)="onButtonAcceptClick()">-->
        <!--            Accept-->
        <!--            <mat-icon>check</mat-icon>-->
        <!--        </button>-->
    </div>

    <div class="button-container-dm-l">
<!--        <button (click)="printContent()">Print!!!!!</button>-->
        <ng-container *ngIf="SelfData?.pullsheetlist?.length && SelfData.pullsheetlist[0]?.data?.length">
            <button *ngIf="SelfData.pullsheetlist[0].data[0].sendoutid > 0" type="button" label="View Items"
                    class="bgcolor button-settings-dm" pButton matRipple (click)="onButtonViewItems()">

            </button>
        </ng-container>
        <!--        <p-button class="button-settings-dm" label="View Items" styleClass="button-settings-dm"></p-button>-->
        <!--        <button mat-raised-button class="button-settings-dm" type="button">-->
        <!--            View Items-->
        <!--&lt;!&ndash;            <mat-icon>visibility</mat-icon>&ndash;&gt;-->
        <!--        </button>-->
    </div>
</div>

<app-sendoutsamplelist class="centered-messagebox" *ngIf="modalService.isSSLVisibled$ | async" @fadeInOut>
    <app-sendoutsamplelistdialog></app-sendoutsamplelistdialog>
</app-sendoutsamplelist>
