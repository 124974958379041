<div  class="full-width-div"  *ngIf="iSampleDataResult && iSampleDataResult.length > 0">
  <mat-card    class="custom-mat-card"    *ngFor="let item of iSampleDataResult[0].data"  >
    <img
      [src]="item?.['Image Link']"
      alt="Image"
      class="custom-mat-card-image"
    />
    <div class="checkbox-container">
      <mat-checkbox></mat-checkbox>
    </div>
    <mat-card-content class="truncate-text">
      <div class="full-width-container">
        <p><strong>Designer</strong></p>
        {{ item?.['Designer'] }}
      </div>

      <div class="flex-container">
        <div class="flex-item">
          <p><strong>Collection</strong></p>
          {{ item?.['Collection'] }}
        </div>

        <div class="flex-item">
          <p><strong>Season</strong></p>
          {{ item?.['Season'] }}
        </div>
      </div>

      <div class="flex-container">
        <div class="flex-item1">
          <p><strong>Type</strong></p>
          {{ item?.['Type'] }}
        </div>

        <div class="flex-item1">
          <p><strong>Color</strong></p>
          <!--          <div [style.background-color]="item?.['colorcode']"></div>-->
          {{ item?.['Color'] }}
        </div>
      </div>

      <div class="full-width-container1">
        <p><strong>Description</strong></p>
        {{ item?.['Description'] }}
      </div>

      <div class="menu-container">
        <button
          mat-stroked-button
          [matMenuTriggerFor]="menu"
          (click)="selectedElement = item"
        >
          Select
          <!-- <mat-icon>more_vert</mat-icon> -->
        </button>
      </div>
    </mat-card-content>

    <!-- Adding a new div at the bottom -->
    <div class="full-width-bottom-container">
      <!-- Content goes here -->
    </div>
  </mat-card>
</div>

<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="onEditButtonClick(selectedElement)">
    <mat-icon>edit_note</mat-icon>
    <span>Edit Details</span>
  </button>
  <button mat-menu-item (click)="setqrcodedata(selectedElement?.['ID'])">
    <mat-icon>qr_code_2</mat-icon>
    <span>Print QR Labels</span>
  </button>
  <button mat-menu-item (click)="onButtonDeleteClick(selectedElement)">
    <mat-icon>delete</mat-icon>
    <span>Delete</span>
  </button>
</mat-menu>
<div
  #qrCode
  class="qrcodeImage"
  [style.display]="showQRCode ? 'block' : 'none'"
>
  <qrcode
    [qrdata]="qrcodedata"
    [allowEmptyString]="true"
    [cssClass]="'center'"
    [colorDark]="'#000000'"
    [colorLight]="'#ffffffff'"
    [elementType]="'img'"
    [errorCorrectionLevel]="'H'"
    [width]="400"
  ></qrcode>
</div>
