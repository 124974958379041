import { Component, OnInit } from '@angular/core';
import { ModalserviceService } from '../../services/modalservice.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { HashService } from '../../services/hash.service';
import { MessageboxComponent } from '../../modals/messagebox/messagebox.component';
import { ServiceService } from '../../services/service.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { YourappsserviceService } from '../../services/yourappsservice.service';
import { SelfData } from '../../models/struct';
import { map, Observable, startWith } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-seasondialog',
  templateUrl: './seasondialog.component.html',
  styleUrls: ['./seasondialog.component.scss'],
})
export class SeasondialogComponent implements OnInit {
  myForm: FormGroup;
  frameCaption: String = 'Add New';
  myControl = new FormControl({ value: '', disabled: false }, [
    Validators.required,
  ]);
  myNameControl = new FormControl({ value: '', disabled: false }, [
    Validators.required,
  ]);
  options1: string[] = [
    'AUTUMN WINTER',
    'FALL WINTER',
    'FALL',
    'HOLIDAY',
    'LATE SUMMER',
    'LAUNCH',
    'PRE FALL',
    'RESORT',
    'SPRING SUMMER',
    'SUMMER',
    'SPRING',
    'PRE SPRING',
  ];
  optionsYear: string[] = [
    '2002',
    '2003',
    '2004',
    '2005',
    '2006',
    '2007',
    '2008',
    '2009',
    '2010',
    '2011',
    '2012',
    '2013',
    '2014',
    '2015',
    '2016',
    '2017',
    '2018',
    '2019',
    '2020',
    '2021',
    '2022',
    '2023',
    '2024',
    '2025',
    '2026',
    '2027',
    '2028',
    '2029',
    '2030',
  ];
  filteredOptions?: Observable<string[]>;
  filteredYearOptions?: Observable<string[]>;
  myYear = new FormControl(new Date().getFullYear(), [Validators.required]);
  value: string | undefined = '';

  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private hashService: HashService,
    public messageBox: MessageboxComponent,
    private serviceservice: ServiceService,
    private _snackBar: MatSnackBar,
    private yourappsService: YourappsserviceService
  ) {
    this.myForm = new FormGroup({
      // value: new FormControl(''),  // Initialize your form control
      // other form controls...
    });
  }

  ngOnInit(): void {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || ''))
    );

    this.filteredYearOptions = this.myYear.valueChanges.pipe(
      startWith(new Date().getFullYear()),
      map((value) =>
        this._filterYear(
          value?.toString() || new Date().getFullYear().toString()
        )
      )
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options1.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  getInitials(name: string): string {
    let initials = name.split(' ');

    if (initials.length > 1) {
      // If the name has more than one word, take the first letter of the first two words
      return (initials[0][0] + initials[1][0]).toUpperCase();
    } else {
      // If the name has only one word, take the first two letters of that word
      return initials[0].substring(0, 2).toUpperCase();
    }
  }

  getLastTwoSymbols(text: string): string {
    return text.slice(-2);
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent) {
    console.log(event?.option?.value); // Logs the value of the selected option
    this.myNameControl?.setValue(
      this.getInitials(this.myControl?.value ?? '') +
        this.getLastTwoSymbols(this.myYear?.value?.toString() ?? '')
    );
  }

  private _filterYear(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.optionsYear.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  submit() {
    /*
    const ctrl = parseInt(req.body.ctrl)
    const id =  parseInt(req.body.id)
    const seasoncode = req.body.seasoncode
    const seasonyear = req.body.seasonyear
    const description = req.body.description
    const accountid = parseInt(req.body.accountid)
    */
    if (!this.myControl.value || this.myControl.value.trim() === '') {
      this.messageBox.modalService.openMessageBox(
        'Information',
        'You must specify the Season Code',
        'Cancel',
        'OK'
      );
    } else if (
      !this.myYear.value ||
      this.myYear.value.toString().trim() === ''
    ) {
      this.messageBox.modalService.openMessageBox(
        'Information',
        'You must specify the Season Year',
        'Cancel',
        'OK'
      );
    } else if (
      !this.myNameControl.value ||
      this.myNameControl.value.trim() === ''
    ) {
      this.messageBox.modalService.openMessageBox(
        'Information',
        'You must specify the Season Name',
        'Cancel',
        'OK'
      );
    } else {
      this.modalService.closeSeason();
      let accountid: number = 0;
      if (SelfData.account[0].data[0].parentid == 0) {
        accountid = SelfData.account[0].data[0].id;
      } else {
        accountid = SelfData.account[0].data[0].parentid;
      }
      this.serviceservice
        .prcseason({
          ctrl: 0,
          id: 0,
          seasoncode: this.myControl?.value ?? undefined,
          seasonyear: this.myYear?.value?.toString() ?? undefined,
          description: this.myNameControl?.value ?? undefined,
          accountid: accountid,
        })
        .subscribe((value) => {
          this.yourappsService.setAddFrameSeason(
            this.myNameControl?.value || ''
          );
        });
    }
  }
}
