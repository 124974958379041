import {AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ModalserviceService} from "../../services/modalservice.service";
import {HashService} from "../../services/hash.service";
import {MessageboxComponent} from "../../modals/messagebox/messagebox.component";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {
  GetSystemUsers,
  GetSystemUsersData,
  SelfData,
  SendOut1Data,
  SetContactsList,
  SetContactsListData,
  SetSampleListData, transformImageUrl
} from "../../models/struct";
import {MatTableDataSource} from "@angular/material/table";
import {DomSanitizer} from "@angular/platform-browser";
import {Subscription} from "rxjs";
import * as ExcelJS from 'exceljs';
import html2canvas from 'html2canvas';
import moment from "moment";
import {MessageService} from "primeng/api";
import printJS from "print-js";

@Component({
    selector: 'app-deliverymemo',
    templateUrl: './deliverymemo.component.html',
    styleUrls: ['./deliverymemo.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DeliverymemoComponent implements OnInit, AfterViewInit {
  public showScroll: boolean = true;
  myForm: FormGroup = new FormGroup({});
  footertext: string = "";
  iSystemUsersResult: GetSystemUsers[] = [];
  iContactsResult: SetContactsList[] = [];
  messages: { text: string; color: string }[] = [];
  messages_1: { text: string; color: string }[] = [];
  messages1: { text: string; color: string }[] = [];
  messages2: { text: string; color: string }[] = [];

  dataSource = new MatTableDataSource<SetSampleListData>(SelfData.setSelectedSample);

  displayedColumns: string[] = [
    'Designer',
    'Type',
    'Description',
    'Image',
  ];

  shipFormControl = new FormControl({ value: '', disabled: false }, []);

  shiptoControl = new FormControl({ value: '', disabled: true }, [
    Validators.required,
  ]);

  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
        ? SelfData.account[0].data[0].id
        : SelfData.account[0].data[0].parentid;
  }

  constructor(
      public modalService: ModalserviceService,
      public fb: FormBuilder,
      private hashService: HashService,
      public messageBox: MessageboxComponent,
      private serviceservice: ServiceService,
      private yourappsService: YourappsserviceService,
      private _snackBar: MatSnackBar,
      private sanitizer: DomSanitizer,
      private cd: ChangeDetectorRef,
      private messageService: MessageService
  ) {
    // console.log("SendOut1Data.senderFormControl", SendOut1Data.senderFormControl)
    this.showScroll = true
    this.serviceservice
        .setSystemUsers({ accountid: this.getAccountID() })
        .subscribe((value) => {
          this.iSystemUsersResult[0] = value;
          var recaddress: string | undefined;
          var recaddress_1: string | undefined;
          console.log("this.iSystemUsersResult[0]", this.iSystemUsersResult[0])
          console.log("SendOut1Data.senderFormControl", SendOut1Data.senderFormControl)
          let result = this.searchUser(SendOut1Data.senderFormControl, this.iSystemUsersResult);
          recaddress = '<strong><h5>' + 'Ship From' + '</strong></h5>' + result?.company + '<br>' +
              result?.address1 + '<br>' + result?.address2 + '<br>' +
              result?.city + ' ' + result?.state + ' ' +result?.zipcode + '<br>' +
              result?.phone
          recaddress_1 = result?.company+ ' ' + result?.address1 + ' ' + result?.address2 + ' ' +
            result?.city + ' ' + result?.state + ' ' +result?.zipcode + ' ' + result?.phone
          this.messages_1.unshift({ text: recaddress_1, color: "black" })
          this.messages.unshift({ text: recaddress, color: "black" });
          // this.footertext = '<p class="ql-align-center">Please retainthis include it when returning samples to the following address: '+(this.messages_1)[0].text+'</p>'
          this.footertext = '<p class="ql-align-center">Please retain this include it when returning samples to the following address: ' + (this.messages_1)[0].text + '</p>';
          // this.shipFormControl.setValue(recaddress!!)
        });

    this.serviceservice
        .setContactsList({ accountid: this.getAccountID(), contactsid: 0 })
        .subscribe((value) => {
          this.iContactsResult[0] = value;
          var recaddress: string | undefined;
          let result = this.searchItem(SendOut1Data.companynameControl, this.iContactsResult);
          recaddress = result?.['addressaddress1'] + '<br>' +
              result?.['addresscity']  + ' ' + result?.['addressstate'] + ' ' +
              result?.['addresszipcode'] + ' ' + result?.['addresscountry'] + ' <br>' +
              'Attn: ' + SendOut1Data.companynameControl + '<br>' +
              'Tel: ' + result?.['mobile'] + '<br>' +
              'Email: ' + result?.['email']
          this.messages1.unshift({ text: recaddress, color: "black" });
          // this.shipFormControl.setValue(recaddress!!)
        });

    function getDateFormat(dateString: string) {
      const isoFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
      const amPmFormat = /^\d{2}\/\d{2}\/\d{4} \d{2}:\d{2} (AM|PM)$/;

      if (isoFormat.test(dateString)) {
        return "ISO Format";
      } else if (amPmFormat.test(dateString)) {
        return "AM/PM Format";
      } else {
        return "Unknown Format";
      }
    }

    // console.log("SendOut1Data.reservationdateControl.value: ", SendOut1Data.datetimeCtrl.format('DD/MMM/YY HH:mm') )

    function getTempMessage() {
      const dateFormat = getDateFormat(SendOut1Data.datetimeCtrl);

      if (dateFormat === "ISO Format") {
        tempmessage = 'Type of Dispatch: ' + SendOut1Data.todControl?.trim() + '<br>' +
            'Reservation Date: ' + moment(SendOut1Data.datetimeCtrl).format('DD/MMM/YY HH:mm') + '<br>' +
            'Exp. Return Date: ' + moment(SendOut1Data.datetimeCtrl1).format('DD/MMM/YY HH:mm') + '<br>' +
            'Sender: ' + SendOut1Data.senderFormControl;
      } else if (dateFormat === "AM/PM Format") {
        tempmessage = 'Type of Dispatch: ' + SendOut1Data.todControl?.trim() + '<br>' +
            'Reservation Date: ' + SendOut1Data.datetimeCtrl.format('DD/MMM/YY HH:mm') + '<br>' +
            'Exp. Return Date: ' + SendOut1Data.datetimeCtrl1.format('DD/MMM/YY HH:mm') + '<br>' +
            'Sender: ' + SendOut1Data.senderFormControl;
      } else {
        // Handle the unknown format if needed
        tempmessage = "Date format not recognized!";
      }

      return tempmessage;
    }
    var tempmessage: string = getTempMessage()
    this.messages2.unshift({ text: tempmessage!!, color: "black" });
  }

  formatDate(date: Date): string {
    const days = String(date.getDate()).padStart(2, '0');
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const monthName = months[date.getMonth()];
    const year = String(date.getFullYear()).slice(-2);
    const hour = String(date.getHours()).padStart(2, '0');
    const minute = String(date.getMinutes()).padStart(2, '0');


    return `${days}/${monthName}/${year} ${hour}:${minute}`;
  }

  searchUser(
      name: string | null,
      users: GetSystemUsers[]
  ): GetSystemUsersData | null {
    for (let user of users) {
      let found = user.data.find(
          (d) => d?.['firstname'] + ' ' + d?.['lastname'] === name
      );

      if (found) {
        return found;
      }
    }

    return null;
  }

  searchItem(
      name: string | null,
      contacts: SetContactsList[]
  ): SetContactsListData | null {
    for (let contact of contacts) {
      let found = contact.data.find(
          (d) => d?.['firstname'] + ' ' + d?.['lastname'] === name
      );

      if (found) {
        return found;
      }
    }

    return null;
  }

  public get setdataSource() {
    return this.dataSource;
  }

  ngAfterViewInit(): void {
  }

    private subscription?: Subscription;
    ngOnInit(): void {
        this.subscription = this.yourappsService.setFooterText$.subscribe((value: string) => {
            // if (this.subscription) {
            console.log(value)
                if (value != '') {
                  this.footertext = value
                    // this.text = value
                }
            // }
            // this.subscription?.unsubscribe()
        });
  }

  submit() {

  }

  // onButtonPrintClick() {
  //   printJS({
  //     printable: 'scrollable-div-dm',
  //     type: 'html',
  //     css: '/assets/deliverymemo.component.css'
  //   });
  // }

  onButtonPrintClick() {
    const el = document.getElementById('scrollable-div-dm');
    if (!el) {
      console.error('Element not found!');
      return;
    }

    const clonedEl = el.cloneNode(true) as HTMLElement;

    // Create and append the iframe
    const iframe = document.createElement('iframe');
    document.body.appendChild(iframe);

    // Dynamically load the printer CSS
    const printerStyleLink = document.createElement('link');
    printerStyleLink.rel = 'stylesheet';
    printerStyleLink.href = '/assets/deliverymemo.component.css';

    iframe.contentWindow!.document.head.appendChild(printerStyleLink);

    // Append the element you want to print to the iframe
    iframe.contentWindow!.document.body.appendChild(clonedEl);

    // Print after a brief timeout to allow styles to load
    setTimeout(() => {
      iframe.contentWindow!.focus();
      iframe.contentWindow!.print();
      document.body.removeChild(iframe);
    }, 2000);
  }

  onBarcodeClick() {
    console.log("SelfData.selectadpage = 3")
    this.yourappsService.getPrintBarcode(null, 1)
    // SelfData.selectadpage = 3
    // this.modalService.openPrinterFrame()
  }

  onAppButtonClick() {
    this.messageService.add({
      key: 'tc',
      severity: 'success',
      summary: 'Success',
      detail: 'Coming soon',
      life: 7000,
    });
  }

  dataToExport = this.dataSource.data.map(item => ({
    Designer: item["Designer"],
    Collection: item["Collection"],
    Season: item["Season"],
    Type: item["Type"],
    barcode: item["barcode"],
    Color: item["Color"],
    Description: item["Description"]
  }));

  onButtonExportToExcelClick() {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('My Sheet');

    // Add data and create columns headers
    worksheet.columns = [
      { header: 'Designer', key: 'Designer', width: 30 },
      { header: 'Collection', key: 'Collection', width: 30 },
      { header: 'Type', key: 'Type', width: 25 },
      { header: 'barcode', key: 'barcode', width: 30 },
      { header: 'Color', key: 'Color', width: 25 },
      { header: 'Description', key: 'Description', width: 80 },
      // ... Add more columns as needed
    ];

    worksheet.addRows(this.dataToExport);

    // // Style a specific cell
    // let rowCount = worksheet.rowCount;
    // for (let i = 1; i <= rowCount; i++) {
    //   const targetCell = worksheet.getCell(`A${i}`);
    //   targetCell.fill = {
    //     type: 'pattern',
    //     pattern: 'solid',
    //     fgColor: { argb: 'BBBBBB' }  // Blue color
    //   };
    // }

    const endColumn = worksheet.columns.length;
    for (let i = 1; i <= endColumn; i++) {
      const columnLetter = String.fromCharCode(64 + i); // 65 = 'A', 66 = 'B', ...
      const targetCell = worksheet.getCell(`${columnLetter}1`);
      targetCell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFBBBBBB' }  // Grey color (You mentioned blue earlier, but the color code you provided is for grey. Adjust as necessary.)
      };

      targetCell.font = {
        bold: true
      };
    }

    workbook.xlsx.writeBuffer().then((data: any) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      const formattedDate = this.getCurrentDateTime();
      link.download = `FM_${formattedDate}.xlsx`;
      link.click();
    });
  }

  getCurrentDateTime(): string {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}${month}${day}_${hours}${minutes}${seconds}`;
  }

  async captureDivAsImage() {
    const div = document.getElementById('scrollable-div-dm');
    if (!div) {
      throw new Error('Element not found!');
    }
    const canvas = await html2canvas(div);
    return canvas.toDataURL('image/png');
  }

  onButtonEditClick() {
    this.yourappsService.getFooterText(this.footertext)
    this.modalService.openEditor()
  }

  async onButtonEmailClick() {
    this.modalService.openEmail()
    // this.serviceservice.sendEmail()
    // const data = document.getElementById('scrollable-div-dm') as HTMLElement;
    //
    // if (data) {
    //   // Backup original styles
    //   const originalDisplay = data.style.display;
    //   const originalOverflow = data.style.overflowY;
    //
    //   // Modify styles to expand content
    //   data.style.display = 'block';
    //   data.style.overflowY = 'visible';
    //
    //   // Allow the changes to take effect
    //   setTimeout(() => {
    //     html2canvas(data).then(canvas => {
    //       // Restore original styles
    //       data.style.display = originalDisplay;
    //       data.style.overflowY = originalOverflow;
    //
    //       const imgWidth = 208;
    //       const imgHeight = canvas.height * imgWidth / canvas.width;
    //       const contentDataURL = canvas.toDataURL('image/png');
    //
    //       let pdf = new jsPDF('p', 'mm', 'a4');
    //       pdf.addImage(contentDataURL, 'PNG', 0, 0, imgWidth, imgHeight);
    //       pdf.save('MYPdf.pdf');
    //     });
    //   });
    // } else {
    //   console.error('Element not found!');
    // }
  }

  protected readonly SelfData = SelfData;
  protected readonly SendOut1Data = SendOut1Data;
  protected readonly transformImageUrl = transformImageUrl;
}
