import { Component } from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";

@Component({
  selector: 'app-fullsendout',
  templateUrl: './fullsendout.component.html',
  styleUrl: './fullsendout.component.scss'
})
export class FullsendoutComponent {
  constructor(public modalService: ModalserviceService) {}
}
