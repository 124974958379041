import {Component, OnDestroy, OnInit} from '@angular/core';
import {ModalserviceService} from '../../services/modalservice.service';
import {LocalStorageService} from '../../services/local-storage.service';
import {YourappsserviceService} from '../../services/yourappsservice.service';
import {
  GetCheckVisibled,
  GetCustomFields,
  GetScannedList, GetSendOutList,
  GetSystemFields,
  SelfData,
  SetSelectedFilesV,
} from '../../models/struct';
import {animate, state, style, transition, trigger,} from '@angular/animations';
import {ServiceService} from '../../services/service.service';
import {Observable} from "rxjs";
import {MessageService} from "primeng/api";
import {Router} from "@angular/router";
import {NzNotificationService} from "ng-zorro-antd/notification";


@Component({
  selector: 'app-samples',
  templateUrl: './samples.component.html',
  styleUrls: ['./samples.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0 }), animate(300)]),
      transition(':leave', [animate(300, style({ opacity: 0 }))]),
    ]),
  ],
})
export class SamplesComponent implements OnInit, OnDestroy {
  private intervalId: any;
  // private socket;
  iSystemFields: GetSystemFields[] = [];
  iSystemContactsFields: GetSystemFields[] = [];
  iCustomFields: GetCustomFields[] = [];
  iContactCustomFields: GetCustomFields[] = [];
  iScannedList: GetScannedList[] = [];
  ilbCVResult: GetCheckVisibled[] = []
  imageUrl: string = '';
  constructor(
    public modalService: ModalserviceService,
    private localStorageService: LocalStorageService,
    private serviceservice: ServiceService,
    private router: Router,
    private notification: NzNotificationService,
    private messageService: MessageService,
    private yourappsService: YourappsserviceService
  ) {
    SelfData.samplestyle = 0
    const savedData = this.localStorageService.getItem('myAccountArray');
    if (savedData) {
      SelfData.account = savedData;
      SelfData.scannlistshower = false
      this.yourappsService.reloadAvatarImage.next();
      let programmscount = 0;
      if (
        SelfData.account[0].data[0].rpmonitoring ||
        SelfData.account[0].data[0].events ||
        SelfData.account[0].data[0].samples ||
        SelfData.account[0].data[0].contacts
      ) {
        if (SelfData.account[0].data[0].rpmonitoring) {
          this.modalService.rpmonitoringshow();
          programmscount += 1;
        } else {
          this.modalService.rpmonitorinclose();
        }
        if (SelfData.account[0].data[0].events) {
          this.modalService.eventsshow();
          programmscount += 1;
        } else {
          this.modalService.eventsclose();
        }
        if (SelfData.account[0].data[0].samples) {
          this.modalService.samplesshow();
          programmscount += 1;
        } else {
          this.modalService.samplesclose();
        }
        if (SelfData.account[0].data[0].contacts) {
          this.modalService.contactsshow();
          programmscount += 1;
        } else {
          this.modalService.contactsclose();
        }
        this.yourappsService.updateProgrammsCount(programmscount);
        this.yourappsService.setAccountName(
          SelfData.account[0].data[0].firstname +
            ' ' +
            SelfData.account[0].data[0].lastname
        );
        var accountid: number = 0;
        if (SelfData.account[0].data[0].parentid == 0) {
          accountid = SelfData.account[0].data[0].id;
        } else {
          accountid = SelfData.account[0].data[0].parentid;
        }
        this.serviceservice
          .getSystemFieldsList({
            accountid: accountid,
            framename: 'createsampledialog',
          })
          .subscribe((value) => {
            this.iSystemFields[0] = value;
            SelfData.systemfields = this.iSystemFields;
          });
        this.serviceservice
          .getSystemContactsFieldsList({
            accountid: accountid,
            framename: 'createcontactsdialog',
          })
          .subscribe((value) => {
            this.iSystemContactsFields[0] = value;
            SelfData.systemContactsfields = this.iSystemContactsFields;
          });
        this.serviceservice
          .getCustomFieldsList({
            accountid: accountid,
            framename: 'createsampledialog',
          })
          .subscribe((value) => {
            this.iCustomFields[0] = value;
            SelfData.customfields = this.iCustomFields;
          });
        this.serviceservice
          .getContactCustomFieldsList({
            accountid: accountid,
            framename: 'createcontactsdialog',
          })
          .subscribe((value) => {
            this.iContactCustomFields[0] = value;
            SelfData.contactcustomfields = this.iContactCustomFields;
          });
        this.yourappsService.reloadAvatarImage.next();
        this.modalService.programmsshow();
        this.yourappsService.setAuth(true);
      } else {
        this.modalService.programmsclose();
        this.modalService.rpmonitorinclose();
        this.modalService.eventsclose();
        this.modalService.samplesclose();
        this.modalService.contactsclose();
        this.yourappsService.setAuth(false);
        this.yourappsService.setAccountName('');
      }
    }

    // this.socket = io('http://108.178.189.70:6335');
  }

  public getMessages = () => {
    return new Observable((observer) => {
      // this.socket.on('chat message', (message) => {
      //   observer.next(message);
      // });
    });
  }

  // public getBarCode = () => {
  //   return new Observable((observer) => {
  //     this.socket.on('readbarcode', (barcode) => {
  //       observer.next(barcode);
  //     });
  //   });
  // }

  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
        ? SelfData.account[0].data[0].id
        : SelfData.account[0].data[0].parentid;
  }

  logout() {
    console.log("USED Logout Function")
    window.location.reload();
    // this.socket.disconnect()
  }

  ngOnInit() {

    this.yourappsService.logoutservice$.subscribe((value: {
      data: any,
      type: number
    }) => {
      console.log("Received value:", value);
      if (value.type === 1) {
        this.logout()
      }
    });

    let storedSamples = localStorage.getItem('checkedSamples');
    if (storedSamples) {
      SelfData.checkedsample = JSON.parse(storedSamples);
    }
    this.serviceservice
      .checkvisibled({accountid: this.getAccountID()})
      .subscribe((value) => {
        console.log("value", value)
        this.ilbCVResult[0] = value;
        SelfData.lbvisibled = this.ilbCVResult;
      });
    this.getMessages().subscribe((message) => {
      console.log(message)
    });
    // this.socket.on('readbarcode', (data) => {
    //   console.log(data)
    //   console.log('Received barcode: ', data.barcode);
    //   this.yourappsService.setSelectedSample(data.barcode);
    // });
    //
    // this.socket.on('deleteitem', (data) => {
    //   console.log(data)
    //   console.log('Received barcode: ', data.barcode);
    //   this.yourappsService.removedSample(data.barcode);
    // });

    // this.socket.on('neededdisconnect', (data) => {
    //   this.socket.disconnect()
    //   this.modalService.closespr()
    //   this.router.navigate(['']);
    //   this.localStorageService.clear()
    //   this.yourappsService.updateProgrammsCount(0);
    //   this.modalService.programmsclose();
    //   this.modalService.rpmonitorinclose();
    //   this.modalService.eventsclose();
    //   this.modalService.samplesclose();
    //   this.modalService.contactsclose();
    //   this.yourappsService.setAuth(false);
    //   SelfData.account = [];
    //   this.yourappsService.setAccountName('');
    //   this.yourappsService.reloadDataRequest;
    //   this.yourappsService.reloadAvatarImage;
    //   this.LoadAvatarImage();
    //   this.router.navigate(['']);
    //   console.log("neededdisconnect", data);
    //   this.notification.error(
    //     '<strong class="notification-error">Information</strong>',
    //     '<strong class="notification-error">Another user has logged into this account.</strong>', {
    //       nzDuration: 10000 // Правильное указание продолжительности
    //     }
    //   );
    // });

    // this.socket.on('connectedusersocket', (data) => {
    //   this.serviceservice
    //       .runSocketInitDataRequest({
    //         ctrl: 0,
    //         userid: SelfData.account[0].data[0].id,
    //         accountid: this.getAccountID(),
    //         socketid: data,
    //         type: 2,
    //       })
    //       .subscribe((value) => {
    //
    //       });
    // });
    this.serviceservice
        .scannedlist({
          accountid: this.getAccountID()
        })
        .subscribe((value) => {
          this.iScannedList[0] = value;
          SelfData.scannedfulllist = this.iScannedList;
          SelfData.scannedlist = this.iScannedList;
        });
    this.startTimer()
    // this.serviceservice
    //     .scannedcount({
    //       userid: SelfData.account[0].data[0].id,
    //       accountid: this.getAccountID()
    //     })
    //     .subscribe((value) => {
    //       console.log(value.data[0].scannedcount)
    //     });

    console.log("Sample Manager Loaded")
    this.serviceservice
      .getSendOutPendinglist({accountid: this.getAccountID()})
      .subscribe((value) => {
        this.iSendOutList1[0] = value;

        SelfData.sendoutpendinglist = this.iSendOutList1;
        this.yourappsService.getReloadSendOutList(null, 0)
      });
  }

  iSendOutList1: GetSendOutList[] = [];

  startTimer() {
    this.intervalId = setInterval(() => {
      this.serviceservice
          .scannedcount({
            userid: SelfData.account[0].data[0].id,
            accountid: this.getAccountID()
          })
          .subscribe((value) => {
            if (value.data[0].scannedcount > 0) {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: `${value.data[0].scannedcount} items have been scanned`,
                life: 5000,
              });
              if (SelfData.scannlistshower) {
                if (this.intervalId) {
                  clearInterval(this.intervalId);
                }
              }
            }
          });
    }, 180000); // 180000 milliseconds = 3 minutes
  }

  LoadAvatarImage() {
    const timestamp = new Date().getTime(); // Get the current timestamp
    if (SelfData.account[0]?.data[0]?.imageLink) {
      this.imageUrl = `${SelfData.account[0].data[0].imageLink}?${timestamp}`; // Append timestamp to the image URL
    } else {
      this.imageUrl = ''; // Set imageUrl to an empty string if imageLink is undefined
    }
  }

  ngOnDestroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
}
