<form [formGroup]="myForm" (ngSubmit)="submit()" autocomplete="off">
    <button mat-icon-button type="button" class="close-btn" (click)="modalService.closePrinterFrame()">
        <mat-icon>close</mat-icon>
    </button>
    <p class="sample-manager-text-dm">Barcodes</p>
    <div id="scrollable-div-dm" class="scrollable-div-dm">
        <div class="full-width-div-dm">
        </div>
        <div class="print-item full-width-div-dm2-br example-container-print"
             *ngFor="let item of SelfData.setSelectedSample; let i = index">

            <div class="black-background1-br">
                <div class="child-60-br">
                    <div class="center-container-barcode">
                        <div class="caption">{{ SelfData.account[0].data[0].company }}</div>
                        <app-barcode [element]="item?.['barcode']" [height]="30" [width]="1"
                                     [fontSize]="10" [background]="'rgba(255,255,255,0)'"
                                     [marginTop]="-10" class="barcode-component"></app-barcode>

                        <div class="description">
                            {{ item?.["Collection"] + " " + item?.["Season"] + " " + item?.["Designer"] + " " + item?.["Description"] + " " + item?.["Size"]}}
                        </div>
                        <div class="qrgroup">
                            <div class="qrcodeImage">
                                <qrcode [qrdata]="getQRData(item)"
                                        [allowEmptyString]="true"
                                        cssClass="center"
                                        colorDark="#000000"
                                        colorLight="#ffffffff"
                                        elementType="img"
                                        [imageHeight]="70"
                                        [imageWidth]="70"
                                        errorCorrectionLevel="H">
                                </qrcode>
                            </div>

                            <!-- Here's the additional div for the website without any spaces -->
                            <div class="website">
                                {{ SelfData.account[0].data[0].website }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="child-40-br">
                    <div class="left-text">fashion-m.com</div>
                </div>
            </div>

            <!--      </div>-->
        </div>
    </div>

    <div class="button-container-dm">

        <button mat-raised-button class="button-settings-dm" type="button" (click)="onButtonPrintClick()">
            Print
            <mat-icon>print</mat-icon>
        </button>

    </div>
</form>

<!--<div-->
<!--    #qrCode-->
<!--    class="qrcodeImage"-->
<!--    [style.display]="showQRCode ? 'block' : 'none'"-->
<!--&gt;-->
<!--    <qrcode-->
<!--            [qrdata]="qrcodedata"-->
<!--            [allowEmptyString]="true"-->
<!--            [cssClass]="'center'"-->
<!--            [colorDark]="'#000000'"-->
<!--            [colorLight]="'#ffffffff'"-->
<!--            [elementType]="'img'"-->
<!--            [errorCorrectionLevel]="'H'"-->
<!--    >-->
<!--    </qrcode>-->
<!--</div>-->
