<p-toast *ngIf="!isDesktopDevice" position="bottom-center" key="bc" [style]="{ width: '90vw' }"></p-toast>
<p-toast *ngIf="isDesktopDevice" position="bottom-center" key="bc" [style]="{ width: '30vw' }"></p-toast>
<div class="bodydiv">
    <mat-card [ngClass]="deviceClass">
        <mat-card-content>
            <div class="imgposition">
                <!--                    <img *ngIf="imageUrl === ''" class="account-image-pull" src="assets/tatalogo.png" alt="noimage"/>-->
                <img *ngIf="imageUrl !== ''" class="account-image-qr shadow-4" [src]="imageUrl" alt="Avatar"/>
            </div>
            <div class="buttonspanel">
                <button class="buttonstyle"
                        *ngIf="SelfData?.qrresult?.length && SelfData.qrresult[0]?.data?.length && SelfData.qrresult[0].data[0].website"
                        mat-raised-button
                        (click)="openInstagramLink(SelfData.qrresult[0].data[0].website)">
                    Web Site
                </button>
                <button class="buttonstyle"
                        *ngIf="SelfData?.qrresult?.length && SelfData.qrresult[0]?.data?.length && SelfData.qrresult[0].data[0].onlintestore"
                        mat-raised-button
                        (click)="openInstagramLink(SelfData.qrresult[0].data[0].onlintestore)">
                    E-Commerce
                </button>
                <button class="buttonstyle"
                        *ngIf="SelfData?.qrresult?.length && SelfData.qrresult[0]?.data?.length && SelfData.qrresult[0].data[0].instagram"
                        mat-raised-button
                        (click)="openInstagramLink(SelfData.qrresult[0].data[0].instagram)">
                    Instagram
                </button>
                <button class="buttonstyle"
                        *ngIf="SelfData?.qrresult?.length && SelfData.qrresult[0]?.data?.length && SelfData.qrresult[0].data[0].tiktok"
                        mat-raised-button
                        (click)="openInstagramLink(SelfData.qrresult[0].data[0].tiktok)">
                    TikTok
                </button>
                <button class="buttonstyle"
                        *ngIf="SelfData?.qrresult?.length && SelfData.qrresult[0]?.data?.length && SelfData.qrresult[0].data[0].facebook"
                        mat-raised-button
                        (click)="openInstagramLink(SelfData.qrresult[0].data[0].facebook)">
                    FaceBook
                </button>
                <button class="buttonstyle"
                        *ngIf="SelfData?.qrresult?.length && SelfData.qrresult[0]?.data?.length && SelfData.qrresult[0].data[0].twitter"
                        mat-raised-button
                        (click)="openInstagramLink(SelfData.qrresult[0].data[0].twitter)">
                    X (Twitter)
                </button>
            </div>
            <div class="feshendiv">
                <div class="left-text-qr">
                    <a href="http://www.fashion-m.com" target="_blank">fashion-m.com</a>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>
