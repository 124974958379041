<p-toast position="top-center" key="tc" [style]="{ width: '40vw' }"></p-toast>
<div class="fixed-elements">
    <app-top-panel></app-top-panel>
    <app-main-tabs></app-main-tabs>
</div>

<div class="scrollable-content">
    <app-yourapps *ngIf="selectedTabIndex === 0"></app-yourapps>
    <app-whatsnew *ngIf="selectedTabIndex === 2"></app-whatsnew>
    <app-support *ngIf="selectedTabIndex === 3"></app-support>
    <!-- Add other components for different tabs here -->
</div>

<app-login class="login-overlay" *ngIf="modalService.isLoginDialogVisibled$ | async" @fadeInOut>
    <div [ngSwitch]="showLoginframe">
        <app-logindialog *ngSwitchCase="1"></app-logindialog>
    </div>
</app-login>

<app-registration class="login-overlay" *ngIf="modalService.isRegistrationDialogVisibled$ | async" @fadeInOut>
    <app-registrationdialog></app-registrationdialog>
    <!--    <app-registration *ngSwitchCase="2"></app-registration>-->
    <!--    <app-registration1 *ngSwitchCase="3"></app-registration1>-->
</app-registration>

<app-messagebox
        class="centered-messagebox"
        *ngIf="modalService.isMessageBoxVisible$ | async"
        @fadeInOut
>
    <app-messageboxdialog></app-messageboxdialog>
    <!--    <app-registration *ngSwitchCase="2"></app-registration>-->
    <!--    <app-registration1 *ngSwitchCase="3"></app-registration1>-->
</app-messagebox>

<app-profile
        class="centered-messagebox"
        *ngIf="modalService.isProfileVisibled$ | async"
        @fadeInOut
>
    <app-profiledialog></app-profiledialog>
    <!--  <app-messageboxdialog></app-messageboxdialog>-->
</app-profile>

<app-code
        class="centered-messagebox"
        *ngIf="modalService.isCodeVisibled$ | async"
        @fadeInOut
>
    <app-codedialog></app-codedialog>
    <!--  <app-messageboxdialog></app-messageboxdialog>-->
</app-code>

<app-editprofile
        class="centered-messagebox"
        *ngIf="modalService.isProfileEditVisibled$ | async"
        @fadeInOut
>
    <app-editprofiledialog></app-editprofiledialog>
    <!--  <app-messageboxdialog></app-messageboxdialog>-->
</app-editprofile>

<app-settings class="centered-messagebox" *ngIf="modalService.isSettings$ | async" @fadeInOut>
    <app-settingsdialog></app-settingsdialog>
</app-settings>

<app-address class="centered-messagebox" *ngIf="modalService.isAddressVisibled$ | async" @fadeInOut>
    <app-addressdialog></app-addressdialog>
</app-address>

<app-signatureframe class="centered-messagebox" *ngIf="modalService.isSignatureVisibled$ | async" @fadeInOut>
    <app-signatureframedialog></app-signatureframedialog>
</app-signatureframe>

<app-sendoutsamplelist class="centered-messagebox" *ngIf="modalService.isSSLVisibled$ | async" @fadeInOut>
    <app-sendoutsamplelistdialog></app-sendoutsamplelistdialog>
</app-sendoutsamplelist>

<app-favorite class="centered-messagebox" *ngIf="modalService.isfavoriteVisibled$ | async" @fadeInOut>
  <app-favoritedialog></app-favoritedialog>
</app-favorite>

<app-createimageviews class="centered-messagebox" *ngIf="modalService.isimageviewVisibled$ | async" @fadeInOut>
  <app-createimageviewsdialog></app-createimageviewsdialog>
</app-createimageviews>

<app-comingsoon class="centered-messagebox" *ngIf="modalService.icsVisibled$ | async" @fadeInOut>
  <app-comingsoondialog></app-comingsoondialog>
</app-comingsoon>
