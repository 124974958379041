import {AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {forkJoin, Subscription} from "rxjs";
import {Table} from "primeng/table";
import {SelfData} from "../../models/struct";
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder} from "@angular/forms";
import {HashService} from "../../services/hash.service";
import {MessageboxComponent} from "../../modals/messagebox/messagebox.component";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {MatSnackBar} from "@angular/material/snack-bar";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-srframedialog',
  templateUrl: './srframedialog.component.html',
  styleUrl: './srframedialog.component.scss'
})
export class SrframedialogComponent implements OnInit, AfterViewInit {
  private subscription?: Subscription;
  caption: string = "Sale"
  sizes!: any[];
  @ViewChild(Table) dataTable?: Table;

  formatterDollar = (value: number): string => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  paymentvalue: number = 0;
  totalpayment: number = 150;

  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
      ? SelfData.account[0].data[0].id
      : SelfData.account[0].data[0].parentid;
  }

  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private hashService: HashService,
    public messageBox: MessageboxComponent,
    private serviceservice: ServiceService,
    private yourappsService: YourappsserviceService,
    private notification: NzNotificationService,
    private _snackBar: MatSnackBar,
    private http: HttpClient,
    private cdr: ChangeDetectorRef
  ) {
    this.totalpayment = SelfData.totalorder
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
  }

  processPaymentCash() {
    this.yourappsService.setPayment(1, this.paymentvalue, this.paymentvalue - this.totalpayment, 1)
    this.modalService.srFrameClose()
  }

  processPaymentOther() {
    this.yourappsService.setPayment(2, this.paymentvalue, this.paymentvalue - this.totalpayment, 1)
    this.modalService.srFrameClose()
  }
}
