import { Component } from '@angular/core';
import { ModalserviceService } from '../../services/modalservice.service';

@Component({
  selector: 'app-sws',
  templateUrl: './sws.component.html',
  styleUrls: ['./sws.component.scss'],
})
export class SwsComponent {
  constructor(public modalService: ModalserviceService) {}
}
