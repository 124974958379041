import { Component, Input, OnInit } from '@angular/core';
import { ModalserviceService } from '../../services/modalservice.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  @Input() title: string | undefined;

  constructor(public modalService: ModalserviceService) {}

  ngOnInit(): void {}
}
