import { Component } from '@angular/core';
import {animate, state, style, transition, trigger} from "@angular/animations";
import {ModalserviceService} from "../../services/modalservice.service";
import {LocalStorageService} from "../../services/local-storage.service";
import {ServiceService} from "../../services/service.service";
import {Router} from "@angular/router";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {MessageService} from "primeng/api";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {
  GetCheckVisibled,
  GetCustomFields,
  GetScannedList,
  GetSystemFields,
  SelfData,
  TagsListData
} from "../../models/struct";
import {forkJoin} from "rxjs";

@Component({
  selector: 'app-sale',
  templateUrl: './sale.component.html',
  styleUrls: ['./sale.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0 }), animate(300)]),
      transition(':leave', [animate(300, style({ opacity: 0 }))]),
    ]),
  ],
})
export class SaleComponent {
  private intervalId: any;
  // private socket;
  iSystemFields: GetSystemFields[] = [];
  iSystemContactsFields: GetSystemFields[] = [];
  iCustomFields: GetCustomFields[] = [];
  iContactCustomFields: GetCustomFields[] = [];
  iScannedList: GetScannedList[] = [];
  ilbCVResult: GetCheckVisibled[] = []
  imageUrl: string = '';
  constructor(
    public modalService: ModalserviceService,
    private localStorageService: LocalStorageService,
    private serviceservice: ServiceService,
    private router: Router,
    private notification: NzNotificationService,
    private messageService: MessageService,
    private yourappsService: YourappsserviceService
  ) {
    SelfData.samplestyle = 1
    const savedData = this.localStorageService.getItem('myAccountArray');
    if (savedData) {
      SelfData.account = savedData;
      SelfData.scannlistshower = false
      this.yourappsService.reloadAvatarImage.next();
      let programmscount = 0;
      if (
        SelfData.account[0].data[0].rpmonitoring ||
        SelfData.account[0].data[0].events ||
        SelfData.account[0].data[0].samples ||
        SelfData.account[0].data[0].contacts
      ) {
        if (SelfData.account[0].data[0].rpmonitoring) {
          this.modalService.rpmonitoringshow();
          programmscount += 1;
        } else {
          this.modalService.rpmonitorinclose();
        }
        if (SelfData.account[0].data[0].events) {
          this.modalService.eventsshow();
          programmscount += 1;
        } else {
          this.modalService.eventsclose();
        }
        if (SelfData.account[0].data[0].samples) {
          this.modalService.samplesshow();
          programmscount += 1;
        } else {
          this.modalService.samplesclose();
        }
        if (SelfData.account[0].data[0].contacts) {
          this.modalService.contactsshow();
          programmscount += 1;
        } else {
          this.modalService.contactsclose();
        }
        this.yourappsService.updateProgrammsCount(programmscount);
        this.yourappsService.setAccountName(
          SelfData.account[0].data[0].firstname +
          ' ' +
          SelfData.account[0].data[0].lastname
        );
        var accountid: number = 0;
        if (SelfData.account[0].data[0].parentid == 0) {
          accountid = SelfData.account[0].data[0].id;
        } else {
          accountid = SelfData.account[0].data[0].parentid;
        }
        this.serviceservice
          .getSystemFieldsList({
            accountid: accountid,
            framename: 'createsampledialog',
          })
          .subscribe((value) => {
            this.iSystemFields[0] = value;
            SelfData.systemfields = this.iSystemFields;
          });
        this.serviceservice
          .getSystemContactsFieldsList({
            accountid: accountid,
            framename: 'createcontactsdialog',
          })
          .subscribe((value) => {
            this.iSystemContactsFields[0] = value;
            SelfData.systemContactsfields = this.iSystemContactsFields;
          });
        this.serviceservice
          .getCustomFieldsList({
            accountid: accountid,
            framename: 'createsampledialog',
          })
          .subscribe((value) => {
            this.iCustomFields[0] = value;
            SelfData.customfields = this.iCustomFields;
          });
        this.serviceservice
          .getContactCustomFieldsList({
            accountid: accountid,
            framename: 'createcontactsdialog',
          })
          .subscribe((value) => {
            this.iContactCustomFields[0] = value;
            SelfData.contactcustomfields = this.iContactCustomFields;
          });
        this.yourappsService.reloadAvatarImage.next();
        this.modalService.programmsshow();
        this.yourappsService.setAuth(true);
      } else {
        this.modalService.programmsclose();
        this.modalService.rpmonitorinclose();
        this.modalService.eventsclose();
        this.modalService.samplesclose();
        this.modalService.contactsclose();
        this.yourappsService.setAuth(false);
        this.yourappsService.setAccountName('');
      }

      const accountId = SelfData.account[0].data[0].id;

      forkJoin({
        generalsetup: this.serviceservice.selectGeneralSetup(accountId),
      }).subscribe(({ generalsetup }) => {
        if (!SelfData.generalsetup) {
          SelfData.generalsetup = []; // Инициализируем массив, если его нет
        }

        if (!SelfData.generalsetup[0]) {
          SelfData.generalsetup[0] = {Code: 0, ErrorMessage: "", data: [] }; // Используем [] вместо null
        }

        SelfData.generalsetup[0].data = (generalsetup?.data as TagsListData[]) ?? []; // Теперь можно безопасно присвоить
        console.log(SelfData.generalsetup)
      });
    }

    // this.socket = io('http://108.178.189.70:6335');
  }

  protected readonly SelfData = SelfData;
}
