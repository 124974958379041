<!--<button mat-icon-button type="button" class="info-btn" (click)="modalService.createsamplerclose()">-->
<!--  <mat-icon class="material-symbols-outlined">info</mat-icon>-->
<!--</button>-->
<p-button class="info-btn" icon="pi pi-info" [rounded]="true" [outlined]="true" (click)="onInfoButtonClick()"/>
<button mat-icon-button type="button" class="close-btn" (click)="modalService.createsamplerclose()">
  <mat-icon>close</mat-icon>
</button>
<p class="sample-manager-text">{{ formcaption }}</p>
<div class="scrollable-div">
  <div class="black-background">
    <div class="full-width-div">
      <!--      1Line-->
      <div class="example-full-width form-field-spacing">
        <label class="line1-edits-new inputlabel">{{ captionInventoryStatus }}</label>
        <nz-input-group [nzStatus]="statusinventory" nzSearch [nzAddOnAfter]="suffixIconSearch1">
          <nz-select (focus)="onBlurAll()" nzShowSearch [nzShowArrow]="true" class="line1-edits-new inputedit testdiv"
            [(ngModel)]="inventory">
            <nz-option *ngFor="let option of inventorystatus" [nzLabel]="option.viewValue"
              [nzValue]="option.viewValue"></nz-option>
          </nz-select>
        </nz-input-group>
        <ng-template #suffixIconSearch1>
        </ng-template>

        <!--        <mat-form-field class="selector-full-width" appearance="fill">-->
        <!--          <mat-label>{{ captionInventoryStatus }}</mat-label>-->
        <!--          <mat-select-->
        <!--            formControlName="inventoryStatus"-->
        <!--            name="inventorystatus"-->
        <!--            required-->
        <!--          >-->
        <!--            <mat-option-->
        <!--              *ngFor="let invent of inventorystatus"-->
        <!--              [value]="invent.viewValue"-->
        <!--            >-->
        <!--              &lt;!&ndash;              <mat-option *ngFor="let invent of inventorystatus; let isFirst = first" [value]="invent.value" [ngClass]="{'bold': isFirst}">&ndash;&gt;-->
        <!--              {{ invent.viewValue }}-->
        <!--            </mat-option>-->
        <!--          </mat-select>-->
        <!--        </mat-form-field>-->
      </div>

      <div class="example-full-width form-field-spacing">
        <label class="line1-edits-new inputlabel">{{ captionGender }}</label>
        <nz-input-group nzSearch [nzAddOnAfter]="suffixIconSearch1">
          <nz-select nzShowSearch nzAllowClear [nzShowArrow]="true" class="line1-edits-new inputedit testdiv"
            [(ngModel)]="genderstring">
            <nz-option *ngFor="let option of gender" [nzLabel]="option.viewValue"
              [nzValue]="option.viewValue"></nz-option>
          </nz-select>
        </nz-input-group>
        <ng-template #suffixIconSearch1>
        </ng-template>
        <!--        <mat-form-field class="selector-full-width" appearance="fill">-->
        <!--          <mat-label>{{ captionGender }}</mat-label>-->
        <!--          <mat-select formControlName="gender" name="gender">-->
        <!--            <mat-option-->
        <!--              *ngFor="let gender of gender"-->
        <!--              [value]="gender.viewValue"-->
        <!--            >-->
        <!--              {{ gender.viewValue }}-->
        <!--            </mat-option>-->
        <!--          </mat-select>-->
        <!--        </mat-form-field>-->
      </div>

      <div class="example-full-width form-field-spacing">
        <label class="line1-edits-new inputlabel">{{ captionSampleWorkflowStatus }}</label>
        <nz-input-group nzSearch [nzAddOnAfter]="swsbutton">
          <nz-select nzShowSearch nzAllowClear [nzShowArrow]="true" class="line1-edits-new inputedit testdiv1"
            [(ngModel)]="swsstring">
            <nz-option *ngFor="let option of iSWSResult[0]?.data" [nzLabel]="option.description"
              [nzValue]="option.description"></nz-option>
          </nz-select>
        </nz-input-group>
        <ng-template #swsbutton>
          <button (click)="add($event, 0, null)" nz-button nzType="default" nzSearch><span nz-icon
            nzType="plus"></span></button>
        </ng-template>
        <!--        <mat-form-field class="selector-full-width" appearance="fill">-->
        <!--          <mat-label>{{ captionSampleWorkflowStatus }}</mat-label>-->
        <!--          <input-->
        <!--            type="text"-->
        <!--            matInput-->
        <!--            placeholder="Select or type..."-->
        <!--            [matAutocomplete]="auto"-->
        <!--            [formControl]="myControl"-->
        <!--            #autoInput="matAutocompleteTrigger"-->
        <!--          />-->
        <!--          <button-->
        <!--            mat-stroked-button-->
        <!--            type="button"-->
        <!--            class="add-button"-->
        <!--            matSuffix-->
        <!--            (click)="add($event, 0, autoInput)"-->
        <!--          >-->
        <!--            <mat-icon class="center-icon">add</mat-icon>-->
        <!--          </button>-->
        <!--          <mat-autocomplete #auto="matAutocomplete">-->
        <!--            <mat-option-->
        <!--              *ngFor="let item of filteredOptions | async"-->
        <!--              [value]="item"-->
        <!--            >-->
        <!--              {{ item }}-->
        <!--            </mat-option>-->
        <!--          </mat-autocomplete>-->
        <!--        </mat-form-field>-->

        <!--        <button type="button" mat-stroked-button class="add-button" (click)="modalService.createSWS()">-->
        <!--          <mat-icon class="center-icon">add</mat-icon>-->
        <!--        </button>-->
      </div>

      <div class="example-full-width form-field-spacing">
        <label class="line1-edits-new inputlabel">{{ captionDepartment }}</label>
        <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton1">
          <input disabled="true" class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="departmentstring"
            nz-input/>
        </nz-input-group>
        <ng-template #suffixIconButton1>
        </ng-template>
      </div>

      <!--      <mat-form-field-->
      <!--        class="example-full-width form-field-spacing"-->
      <!--        appearance="fill"-->
      <!--      >-->
      <!--        <mat-label>{{ captionDepartment }}</mat-label>-->
      <!--        <input matInput [formControl]="departmentFormControl" required/>-->
      <!--      </mat-form-field>-->


      <div class="example-full-width form-field-spacing">
        <label class="line1-edits-new inputlabel">{{ captionRegion }}</label>
        <nz-input-group nzSearch [nzAddOnAfter]="regionbutton">
          <nz-select nzShowSearch nzAllowClear [nzShowArrow]="true" class="line1-edits-new inputedit testdiv1"
            [(ngModel)]="regionstring">
            <nz-option *ngFor="let option of iRegionResult[0]?.data" [nzLabel]="option.description"
              [nzValue]="option.description"></nz-option>
          </nz-select>
        </nz-input-group>
        <ng-template #regionbutton>
          <button (click)="add($event, 1, null)" nz-button nzType="default" nzSearch><span nz-icon
            nzType="plus"></span></button>
        </ng-template>
        <!--        <mat-form-field class="selector-full-width" appearance="fill">-->
        <!--          <mat-label>{{ captionRegion }}</mat-label>-->
        <!--          <input type="text" matInput placeholder="Select or type..." [matAutocomplete]="auto1"-->
        <!--            [formControl]="myRegionControl" #autoInput1="matAutocompleteTrigger"/>-->
        <!--          <button mat-stroked-button type="button" class="add-button" matSuffix-->
        <!--            (click)="add($event, 1, autoInput1)">-->
        <!--            <mat-icon class="center-icon">add</mat-icon>-->
        <!--          </button>-->
        <!--          <mat-autocomplete #auto1="matAutocomplete">-->
        <!--            <mat-option-->
        <!--              *ngFor="let item of filteredRegionOptions | async"-->
        <!--              [value]="item"-->
        <!--            >-->
        <!--              {{ item }}-->
        <!--            </mat-option>-->
        <!--          </mat-autocomplete>-->
        <!--        </mat-form-field>-->
      </div>
      <div class="example-full-width form-field-spacing">
        <label class="line1-edits-new inputlabel">{{ captionNotes }}</label>
        <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton1">
          <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="notesstring" nz-input/>
        </nz-input-group>
        <ng-template #suffixIconButton1>
        </ng-template>
        <!--        <mat-form-field class="selector-full-width" appearance="fill">-->
        <!--          <mat-label>{{ captionNotes }}</mat-label>-->
        <!--          <input matInput [formControl]="notesRegionControl"/>-->
        <!--        </mat-form-field>-->
      </div>

      <div class="example-full-width form-field-spacing">
        <label class="line1-edits-new inputlabel">{{ captionDesigner }}</label>
        <nz-input-group [nzStatus]="statusdesigner" nzSearch [nzAddOnAfter]="suffixIconSearch1">
          <nz-select (focus)="onBlurAll()" nzShowSearch [nzShowArrow]="true" class="line1-edits-new inputedit testdiv"
            [(ngModel)]="designerstring">
            <nz-option *ngFor="let option of iDesignerResult[0]?.data" [nzLabel]="option.description"
              [nzValue]="option.description"></nz-option>
          </nz-select>
        </nz-input-group>
        <ng-template #suffixIconSearch1>
        </ng-template>
        <!--        <mat-form-field class="selector-full-width" appearance="fill">-->
        <!--          <mat-label>{{ captionDesigner }}</mat-label>-->
        <!--          <input type="text" matInput placeholder="Select or type..." [matAutocomplete]="auto2"-->
        <!--            [formControl]="desingerFormControl"-->
        <!--            #autoInput2="matAutocompleteTrigger"-->
        <!--            (focus)="onFocus($event)"/>-->

        <!--          <mat-autocomplete #auto2="matAutocomplete">-->
        <!--            <mat-option-->
        <!--              *ngFor="let item of filteredDesignerOptions | async"-->
        <!--              [value]="item">-->
        <!--              {{ item }}-->
        <!--            </mat-option>-->
        <!--          </mat-autocomplete>-->
        <!--          <mat-error *ngIf="desingerFormControl.hasError('required')">-->
        <!--            Designer is <strong>required</strong>-->
        <!--          </mat-error>-->
        <!--        </mat-form-field>-->
      </div>

      <div class="example-full-width form-field-spacing">
        <label class="line1-edits-new inputlabel">{{ captionFabric }}</label>
        <nz-input-group nzSearch [nzAddOnAfter]="fabricbutton">
          <nz-select nzShowSearch nzAllowClear [nzShowArrow]="true" class="testdiv1"
            [(ngModel)]="fabricstring">
            <nz-option *ngFor="let option of iFabricResult[0]?.data" [nzLabel]="option.description"
              [nzValue]="option.description"></nz-option>
          </nz-select>
        </nz-input-group>
        <ng-template #fabricbutton>
          <button (click)="add($event, 3, null)" nz-button nzType="default" nzSearch><span nz-icon
            nzType="plus"></span></button>
        </ng-template>
        <!--        <mat-form-field class="selector-full-width" appearance="fill">-->
        <!--          <mat-label>{{ captionFabric }}</mat-label>-->
        <!--          <input-->
        <!--            type="text"-->
        <!--            matInput-->
        <!--            placeholder="Select or type..."-->
        <!--            [matAutocomplete]="auto3"-->
        <!--            [formControl]="fabricControl"-->
        <!--            #autoInput3="matAutocompleteTrigger"-->
        <!--          />-->

        <!--          <button-->
        <!--            mat-stroked-button-->
        <!--            type="button"-->
        <!--            class="add-button"-->
        <!--            matSuffix-->
        <!--            (click)="add($event, 3, autoInput3)"-->
        <!--          >-->
        <!--            <mat-icon class="center-icon">add</mat-icon>-->
        <!--          </button>-->
        <!--          <mat-autocomplete #auto3="matAutocomplete">-->
        <!--            <mat-option-->
        <!--              *ngFor="let item of filteredFabricOptions | async"-->
        <!--              [value]="item"-->
        <!--            >-->
        <!--              {{ item }}-->
        <!--            </mat-option>-->
        <!--          </mat-autocomplete>-->
        <!--        </mat-form-field>-->
      </div>

      <div class="example-full-width form-field-spacing">
        <label class="line1-edits-new inputlabel">{{ captionManufacturer }}</label>
        <nz-input-group nzSearch [nzAddOnAfter]="manubutton">
          <nz-select nzShowSearch nzAllowClear [nzShowArrow]="true" class="testdiv1"
            [(ngModel)]="manustring">
            <nz-option *ngFor="let option of iManuResult[0]?.data" [nzLabel]="option.description"
              [nzValue]="option.description"></nz-option>
          </nz-select>
        </nz-input-group>
        <ng-template #manubutton>
          <button (click)="add($event, 4, null)" nz-button nzType="default" nzSearch><span nz-icon
            nzType="plus"></span></button>
        </ng-template>
        <!--        <mat-form-field class="selector-full-width" appearance="fill">-->
        <!--          <mat-label>{{ captionManufacturer }}</mat-label>-->
        <!--          <input-->
        <!--            type="text"-->
        <!--            matInput-->
        <!--            placeholder="Select or type..."-->
        <!--            [matAutocomplete]="auto4"-->
        <!--            [formControl]="manuControl"-->
        <!--            #autoInput4="matAutocompleteTrigger"-->
        <!--          />-->

        <!--          <button-->
        <!--            mat-stroked-button-->
        <!--            type="button"-->
        <!--            class="add-button"-->
        <!--            matSuffix-->
        <!--            (click)="add($event, 4, autoInput4)"-->
        <!--          >-->
        <!--            <mat-icon class="center-icon">add</mat-icon>-->
        <!--          </button>-->
        <!--          <mat-autocomplete #auto4="matAutocomplete">-->
        <!--            <mat-option-->
        <!--              *ngFor="let item of filteredManuOptions | async"-->
        <!--              [value]="item"-->
        <!--            >-->
        <!--              {{ item }}-->
        <!--            </mat-option>-->
        <!--          </mat-autocomplete>-->
        <!--        </mat-form-field>-->
      </div>

      <div class="example-full-width form-field-spacing">
        <label class="line1-edits-new inputlabel">{{ captionCollection }}</label>
        <nz-input-group [nzStatus]="statuscollection" nzSearch [nzAddOnAfter]="collectionbutton">
          <nz-select (focus)="onBlurAll()" nzShowSearch [nzShowArrow]="true" class="line1-edits-new inputedit testdiv1"
            [(ngModel)]="collectionstring">
            <nz-option *ngFor="let option of iCollection[0]?.data" [nzLabel]="option.description"
              [nzValue]="option.description"></nz-option>
          </nz-select>
        </nz-input-group>
        <ng-template #collectionbutton>
          <button (click)="add($event, 5, null)" nz-button nzType="default" nzSearch><span nz-icon
            nzType="plus"></span></button>
        </ng-template>
        <!--        <mat-form-field class="selector-full-width" appearance="fill">-->
        <!--          <mat-label>{{ captionCollection }}</mat-label>-->
        <!--          <input-->
        <!--            type="text"-->
        <!--            matInput-->
        <!--            placeholder="Select or type..."-->
        <!--            [matAutocomplete]="auto5"-->
        <!--            [formControl]="collectionFormControl"-->
        <!--            #autoInput5="matAutocompleteTrigger"-->
        <!--          />-->

        <!--          <button-->
        <!--            mat-stroked-button-->
        <!--            type="button"-->
        <!--            class="add-button"-->
        <!--            matSuffix-->
        <!--            (click)="add($event, 5, autoInput5)"-->
        <!--          >-->
        <!--            <mat-icon class="center-icon">add</mat-icon>-->
        <!--          </button>-->
        <!--          <mat-autocomplete #auto5="matAutocomplete">-->
        <!--            <mat-option-->
        <!--              *ngFor="let item of filteredCollectionOptions | async"-->
        <!--              [value]="item"-->
        <!--            >-->
        <!--              {{ item }}-->
        <!--            </mat-option>-->
        <!--          </mat-autocomplete>-->
        <!--          <mat-error *ngIf="desingerFormControl.hasError('required')">-->
        <!--            Collection is <strong>required</strong>-->
        <!--          </mat-error>-->
        <!--        </mat-form-field>-->
      </div>

      <div class="example-full-width form-field-spacing">
        <label class="line1-edits-new inputlabel">{{ captionStyle }}</label>
        <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton1">
          <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="stylestring" nz-input/>
        </nz-input-group>
        <ng-template #suffixIconButton1>
        </ng-template>
        <!--        <mat-form-field class="selector-full-width" appearance="fill">-->
        <!--          <mat-label>{{ captionStyle }}</mat-label>-->
        <!--          <input type="text" matInput placeholder="Type..." [matAutocomplete]="auto6"-->
        <!--            [formControl]="styleControl" #autoInput6="matAutocompleteTrigger"/>-->
        <!--          &lt;!&ndash;            <button mat-stroked-button type="button" class="add-button" matSuffix&ndash;&gt;-->
        <!--          &lt;!&ndash;              (click)="add($event, 7, autoInput6)">&ndash;&gt;-->
        <!--          &lt;!&ndash;              <mat-icon class="center-icon">add</mat-icon>&ndash;&gt;-->
        <!--          &lt;!&ndash;            </button>&ndash;&gt;-->
        <!--          <mat-autocomplete #auto6="matAutocomplete">-->
        <!--            <mat-option *ngFor="let item of filteredStyleOptions | async" [value]="item">-->
        <!--              {{ item }}-->
        <!--            </mat-option>-->
        <!--          </mat-autocomplete>-->
        <!--        </mat-form-field>-->
      </div>

      <div class="example-full-width form-field-spacing">
        <label class="line1-edits-new inputlabel">{{ captionLooksAssociated }}</label>
        <nz-input-group nzSearch [nzAddOnAfter]="associatedbutton">
          <input class="line1-edits-new inputedit testdiv1" type="text" [(ngModel)]="associatedstring" nz-input/>
        </nz-input-group>
        <ng-template #associatedbutton>
          <button (click)="add($event, 8, null)" nz-button nzType="default" nzSearch><span nz-icon
            nzType="search"></span></button>
        </ng-template>
        <!--        <mat-form-field class="selector-full-width" appearance="fill">-->
        <!--          <mat-label>{{ captionLooksAssociated }}</mat-label>-->
        <!--          <input matInput [formControl]="associatedControl"/>-->
        <!--          <button mat-stroked-button type="button" class="add-button" matSuffix-->
        <!--            (click)="add($event, 8, null)">-->
        <!--            <mat-icon class="center-icon">search</mat-icon>-->
        <!--          </button>-->
        <!--        </mat-form-field>-->
      </div>

      <div class="example-full-width form-field-spacing">
        <label class="line1-edits-new inputlabel">{{ captionSeason }}</label>
        <nz-input-group [nzStatus]="statusseason" nzSearch [nzAddOnAfter]="suffixIconSearch1">
          <nz-select (focus)="onBlurAll()" nzShowSearch [nzShowArrow]="true" class="line1-edits-new inputedit testdiv"
            [(ngModel)]="seasonstring">
            <nz-option *ngFor="let option of iSeason[0]?.data" [nzLabel]="option.description"
              [nzValue]="option.description"></nz-option>
          </nz-select>
        </nz-input-group>
        <ng-template #suffixIconSearch1>
        </ng-template>
        <!--        <mat-form-field class="selector-full-width" appearance="fill">-->
        <!--          <mat-label>{{ captionSeason }}</mat-label>-->
        <!--          <input type="text" matInput placeholder="Select or type..." [matAutocomplete]="auto7"-->
        <!--            [formControl]="seasonFormControl"-->
        <!--            #autoInput7="matAutocompleteTrigger"-->
        <!--            (focus)="onFocus($event)"/>-->

        <!--          <mat-autocomplete #auto7="matAutocomplete">-->
        <!--            <mat-option-->
        <!--              *ngFor="let item of filteredSeasonOptions | async"-->
        <!--              [value]="item"-->
        <!--            >-->
        <!--              {{ item }}-->
        <!--            </mat-option>-->
        <!--          </mat-autocomplete>-->
        <!--          <mat-error *ngIf="seasonFormControl.hasError('required')">-->
        <!--            Season is <strong>required</strong>-->
        <!--          </mat-error>-->
        <!--        </mat-form-field>-->
      </div>

      <div class="example-full-width form-field-spacing">
        <label class="line1-edits-new inputlabel">{{ captionMisc }}</label>
        <nz-input-group nzSearch [nzAddOnAfter]="miscbutton">
          <nz-select nzShowSearch nzAllowClear [nzShowArrow]="true" class="testdiv1"
            [(ngModel)]="miscstring">
            <nz-option *ngFor="let option of iMisc[0]?.data" [nzLabel]="option.description"
              [nzValue]="option.description"></nz-option>
          </nz-select>
        </nz-input-group>
        <ng-template #miscbutton>
          <button (click)="add($event, 9, null)" nz-button nzType="default" nzSearch><span nz-icon
            nzType="plus"></span></button>
        </ng-template>
        <!--        <mat-form-field class="selector-full-width" appearance="fill">-->
        <!--          <mat-label>{{ captionMisc }}</mat-label>-->
        <!--          <input-->
        <!--            type="text"-->
        <!--            matInput-->
        <!--            placeholder="Select or type..."-->
        <!--            [matAutocomplete]="auto8"-->
        <!--            [formControl]="miscControl"-->
        <!--            #autoInput8="matAutocompleteTrigger"-->
        <!--          />-->
        <!--          <button-->
        <!--            mat-stroked-button-->
        <!--            type="button"-->
        <!--            class="add-button"-->
        <!--            matSuffix-->
        <!--            (click)="add($event, 9, autoInput8)"-->
        <!--          >-->
        <!--            <mat-icon class="center-icon">add</mat-icon>-->
        <!--          </button>-->
        <!--          <mat-autocomplete #auto8="matAutocomplete">-->
        <!--            <mat-option-->
        <!--              *ngFor="let item of filteredMisceOptions | async"-->
        <!--              [value]="item"-->
        <!--            >-->
        <!--              {{ item }}-->
        <!--            </mat-option>-->
        <!--          </mat-autocomplete>-->
        <!--          &lt;!&ndash;          <mat-error *ngIf="desingerFormControl.hasError('required')">&ndash;&gt;-->
        <!--          &lt;!&ndash;            Season is <strong>required</strong>&ndash;&gt;-->
        <!--          &lt;!&ndash;          </mat-error>&ndash;&gt;-->
        <!--        </mat-form-field>-->
      </div>
      <div class="example-full-width form-field-spacing">
        <label class="line1-edits-new inputlabel">{{ captionQuantity }}</label>
        <nz-input-group nzSearch [nzAddOnAfter]="associatedquantity">
          <nz-input-number class="line1-edits-new inputedit testdiv" [(ngModel)]="quantitystring" [nzMin]="1"
            [nzMax]="100" [nzStep]="1"></nz-input-number>
        </nz-input-group>
        <ng-template #associatedquantity>

        </ng-template>
        <!--        <mat-form-field class="selector-full-width" appearance="fill">-->
        <!--          <mat-label>{{ captionQuantity }}</mat-label>-->
        <!--          <input-->
        <!--            matInput-->
        <!--            type="number"-->
        <!--            min="1"-->
        <!--            [formControl]="quantityControl"-->
        <!--          />-->
        <!--          <mat-error *ngIf="desingerFormControl.hasError('required')">-->
        <!--            Quantity cannot be less than <strong>1</strong>-->
        <!--          </mat-error>-->
        <!--        </mat-form-field>-->
      </div>

      <div class="example-full-width form-field-spacing">
        <label class="line1-edits-new inputlabel">{{ captionType }}</label>
        <nz-input-group [nzStatus]="statustype" nzSearch [nzAddOnAfter]="typebutton">
          <nz-select (focus)="onBlurAll()" nzShowSearch [nzShowArrow]="true" class="line1-edits-new inputedit testdiv1"
            [(ngModel)]="typestring">
            <nz-option *ngFor="let option of iType[0]?.data" [nzLabel]="option.description"
              [nzValue]="option.description"></nz-option>
          </nz-select>
        </nz-input-group>
        <ng-template #typebutton>
          <button (click)="add($event, 10, null)" nz-button nzType="default" nzSearch><span nz-icon
            nzType="plus"></span></button>
        </ng-template>
        <!--        <mat-form-field class="selector-full-width" appearance="fill">-->
        <!--          <mat-label>{{ captionType }}</mat-label>-->
        <!--          <input-->
        <!--            type="text"-->
        <!--            matInput-->
        <!--            placeholder="Select or type..."-->
        <!--            [matAutocomplete]="auto9"-->
        <!--            [formControl]="typeFormControl"-->
        <!--            #autoInput9="matAutocompleteTrigger"-->
        <!--            (focus)="onFocus($event)"-->
        <!--          />-->
        <!--          <button-->
        <!--            mat-stroked-button-->
        <!--            type="button"-->
        <!--            class="add-button"-->
        <!--            matSuffix-->
        <!--            (click)="add($event, 10, autoInput9)"-->
        <!--          >-->
        <!--            <mat-icon class="center-icon">add</mat-icon>-->
        <!--          </button>-->
        <!--          <mat-autocomplete #auto9="matAutocomplete">-->
        <!--            <mat-option-->
        <!--              *ngFor="let item of filteredTypeOptions | async"-->
        <!--              [value]="item"-->
        <!--            >-->
        <!--              {{ item }}-->
        <!--            </mat-option>-->
        <!--          </mat-autocomplete>-->
        <!--          <mat-error *ngIf="desingerFormControl.hasError('required')">-->
        <!--            Type is <strong>required</strong>-->
        <!--          </mat-error>-->
        <!--        </mat-form-field>-->
      </div>

      <div class="example-full-width form-field-spacing">
        <label class="line1-edits-new inputlabel">{{ captionSize }}</label>
        <nz-input-group nzSearch [nzAddOnAfter]="sizebutton">
          <nz-select nzShowSearch nzAllowClear [nzShowArrow]="true" class="testdiv1"
            [(ngModel)]="sizestring">
            <nz-option *ngFor="let option of iSize[0]?.data" [nzLabel]="option.description"
              [nzValue]="option.description"></nz-option>
          </nz-select>
        </nz-input-group>
        <ng-template #sizebutton>
          <button (click)="add($event, 11, null)" nz-button nzType="default" nzSearch><span nz-icon
            nzType="plus"></span></button>
        </ng-template>
        <!--        <mat-form-field class="selector-full-width" appearance="fill">-->
        <!--          <mat-label>{{ captionSize }}</mat-label>-->
        <!--          <input type="text" matInput placeholder="Select or type..." [matAutocomplete]="auto10"-->
        <!--            [formControl]="sizeControl" #autoInput10="matAutocompleteTrigger"/>-->
        <!--          <button mat-stroked-button type="button" class="add-button" matSuffix-->
        <!--            (click)="add($event, 11, autoInput10)">-->
        <!--            <mat-icon class="center-icon">add</mat-icon>-->
        <!--          </button>-->
        <!--          <mat-autocomplete #auto10="matAutocomplete">-->
        <!--            <mat-option *ngFor="let item of filteredSizeOptions | async" [value]="item">-->
        <!--              {{ item }}-->
        <!--            </mat-option>-->
        <!--          </mat-autocomplete>-->
        <!--          &lt;!&ndash;          <mat-error *ngIf="desingerFormControl.hasError('required')">&ndash;&gt;-->
        <!--          &lt;!&ndash;            Type is <strong>required</strong>&ndash;&gt;-->
        <!--          &lt;!&ndash;          </mat-error>&ndash;&gt;-->
        <!--        </mat-form-field>-->
      </div>

      <div class="example-full-width form-field-spacing">
        <label class="line1-edits-new inputlabel">{{ captionPattern }}</label>
        <nz-input-group nzSearch [nzAddOnAfter]="patternbutton">
          <nz-select nzShowSearch nzAllowClear [nzShowArrow]="true" class="testdiv1"
            [(ngModel)]="patternstring">
            <nz-option *ngFor="let option of iPattern[0]?.data" [nzLabel]="option.description"
              [nzValue]="option.description"></nz-option>
          </nz-select>
        </nz-input-group>
        <ng-template #patternbutton>
          <button (click)="add($event, 12, null)" nz-button nzType="default" nzSearch><span nz-icon
            nzType="plus"></span></button>
        </ng-template>
        <!--        <mat-form-field class="selector-full-width" appearance="fill">-->
        <!--          <mat-label>{{ captionPattern }}</mat-label>-->
        <!--          <input type="text" matInput placeholder="Select or type..." [matAutocomplete]="auto11"-->
        <!--            [formControl]="patternControl" #autoInput11="matAutocompleteTrigger" (change)="onChange()">-->
        <!--          <button mat-stroked-button type="button" class="add-button" matSuffix-->
        <!--            (click)="add($event, 12, autoInput11)">-->
        <!--            <mat-icon class="center-icon">add</mat-icon>-->
        <!--          </button>-->
        <!--          <mat-autocomplete #auto11="matAutocomplete">-->
        <!--            <mat-option *ngFor="let item of filteredPatternOptions | async" [value]="item">-->
        <!--              {{ item }}-->
        <!--            </mat-option>-->
        <!--          </mat-autocomplete>-->
        <!--        </mat-form-field>-->
      </div>

      <div class="example-full-width form-field-spacing">
        <label class="line1-edits-new inputlabel">{{ captionColor }}</label>
        <nz-input-group nzSearch [nzAddOnAfter]="suffixIconSearch1">
          <nz-select nzShowSearch nzAllowClear [nzShowArrow]="true" class="line1-edits-new inputedit testdiv"
            [(ngModel)]="colorstring">
            <nz-option nzCustomContent *ngFor="let option of colorarray" [nzLabel]="option.colorname"
              [nzValue]="option.colorcode">
              <span class="color-box" [style.background]="option.colorcode"></span>
              {{ option.colorname }}
            </nz-option>
          </nz-select>
        </nz-input-group>
        <ng-template #suffixIconSearch1>
        </ng-template>
        <!--        <mat-form-field class="selector-full-width" appearance="fill">-->
        <!--          <mat-label>{{ captionColor }}</mat-label>-->
        <!--          <mat-select [formControl]="colorControl">-->
        <!--            <mat-option-->
        <!--              *ngFor="let color of colorarray"-->
        <!--              [value]="color.colorname"-->
        <!--            >-->
        <!--              <span-->
        <!--                class="color-box"-->
        <!--                [style.background]="color.colorcode"-->
        <!--              ></span>-->
        <!--              {{ color.colorname }}-->
        <!--            </mat-option>-->
        <!--          </mat-select>-->
        <!--        </mat-form-field>-->
      </div>

      <div class="example-full-width form-field-spacing">
        <label class="line1-edits-new inputlabel">{{ captionSizeRange }}</label>
        <nz-input-group nzSearch [nzAddOnAfter]="sizerangebutton">
          <nz-select nzShowSearch nzAllowClear [nzShowArrow]="true" class="testdiv1"
            [(ngModel)]="sizerangestring">
            <nz-option *ngFor="let option of iSizeRange[0]?.data" [nzLabel]="option.description"
              [nzValue]="option.description"></nz-option>
          </nz-select>
        </nz-input-group>
        <ng-template #sizerangebutton>
          <button (click)="add($event, 13, null)" nz-button nzType="default" nzSearch><span nz-icon
            nzType="plus"></span></button>
        </ng-template>
        <!--        <mat-form-field class="selector-full-width" appearance="fill">-->
        <!--          <mat-label>{{ captionSizeRange }}</mat-label>-->
        <!--          <input type="text" matInput placeholder="Select or type..." [matAutocomplete]="auto12"-->
        <!--            [formControl]="sizerangeControl" #autoInput12="matAutocompleteTrigger"/>-->
        <!--          <button mat-stroked-button type="button" class="add-button" matSuffix-->
        <!--            (click)="add($event, 13, autoInput12)">-->
        <!--            <mat-icon class="center-icon">add</mat-icon>-->
        <!--          </button>-->
        <!--          <mat-autocomplete #auto12="matAutocomplete">-->
        <!--            <mat-option *ngFor="let item of filteredSizeRangeOptions | async" [value]="item">-->
        <!--              {{ item }}-->
        <!--            </mat-option>-->
        <!--          </mat-autocomplete>-->
        <!--          &lt;!&ndash;          <mat-error *ngIf="desingerFormControl.hasError('required')">&ndash;&gt;-->
        <!--          &lt;!&ndash;            Type is <strong>required</strong>&ndash;&gt;-->
        <!--          &lt;!&ndash;          </mat-error>&ndash;&gt;-->
        <!--        </mat-form-field>-->
      </div>

      <div class="example-full-width form-field-spacing">
        <label class="line1-edits-new inputlabel">{{ captionLookBook }}</label>
        <nz-input-group nzSearch [nzAddOnAfter]="lookbookbutton">
          <nz-select nzShowSearch nzAllowClear [nzShowArrow]="true" class="testdiv1"
            [(ngModel)]="lookbookstring">
            <nz-option *ngFor="let option of ilbMainResult[0]?.data" [nzLabel]="option.description"
              [nzValue]="option.description"></nz-option>
          </nz-select>
        </nz-input-group>
        <ng-template #lookbookbutton>
          <button (click)="add($event, 120, null)" nz-button nzType="default" nzSearch><span nz-icon
            nzType="plus"></span></button>
        </ng-template>
        <!--        <mat-form-field class="selector-full-width" appearance="fill">-->
        <!--          <mat-label>{{ captionLookBook }}</mat-label>-->
        <!--          <input type="text" matInput placeholder="Select or type..." [matAutocomplete]="auto120"-->
        <!--            [formControl]="lookbookControl" #autoInput120="matAutocompleteTrigger" (change)="onChange()">-->
        <!--          <button mat-stroked-button type="button" class="add-button" matSuffix-->
        <!--            (click)="add($event, 120, autoInput120)">-->
        <!--            <mat-icon class="center-icon">add</mat-icon>-->
        <!--          </button>-->
        <!--          <mat-autocomplete #auto120="matAutocomplete" (optionSelected)="onOptionSelected($event)">-->
        <!--            <mat-option *ngFor="let item of filteredLookBookOptions | async" [value]="item">-->
        <!--              {{ item }}-->
        <!--            </mat-option>-->
        <!--          </mat-autocomplete>-->
        <!--        </mat-form-field>-->
      </div>

      <div class="example-full-width form-field-spacing">
        <label class="line1-edits-new inputlabel">Barcode</label>
        <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton1">
          <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="barcodestring" nz-input/>
        </nz-input-group>
        <ng-template #suffixIconButton1>
        </ng-template>
      </div>
    </div>

    <div class="black-background3-2">
      <div class="full-width-div1">
        <div class="example-full-width form-field-spacing1">
          <label class="line1-edits-new inputlabel">{{ captionRetailPriceEUR }}</label>
          <nz-input-group nzSearch [nzAddOnAfter]="associatedquantity">
            <nz-input-number class="line1-edits-new inputedit testdiv" [(ngModel)]="retailPriceEURstring" [nzMin]="0"
              [nzStep]="0.01"></nz-input-number>
          </nz-input-group>
          <ng-template #associatedquantity>

          </ng-template>
        </div>
        <!--        <mat-form-field class="selector-full-width" appearance="fill">-->
        <!--          <mat-label>{{ captionRetailPriceEUR }}</mat-label>-->
        <!--          <input-->
        <!--            matInput-->
        <!--            type="number"-->
        <!--            step="0.01"-->
        <!--            min="0"-->
        <!--            [formControl]="priceControl"-->
        <!--          />-->
        <!--          <mat-error *ngIf="desingerFormControl.hasError('required')">-->
        <!--            <strong>Incorrect value</strong>-->
        <!--          </mat-error>-->
        <!--        </mat-form-field>-->

        <div class="example-full-width form-field-spacing1">
          <label class="line1-edits-new inputlabel">{{ captionRetailPriceUSD }}</label>
          <nz-input-group nzSearch [nzAddOnAfter]="associatedquantity">
            <nz-input-number class="line1-edits-new inputedit testdiv" [(ngModel)]="retailPriceUSDstring" [nzMin]="0"
              [nzStep]="0.01"></nz-input-number>
          </nz-input-group>
          <ng-template #associatedquantity>

          </ng-template>
        </div>

        <!--        <mat-form-field class="selector-full-width" appearance="fill">-->
        <!--          <mat-label>{{ captionRetailPriceUSD }}</mat-label>-->
        <!--          <input-->
        <!--            matInput-->
        <!--            type="number"-->
        <!--            step="0.01"-->
        <!--            min="0"-->
        <!--            [formControl]="price1Control"-->
        <!--          />-->
        <!--          <mat-error *ngIf="desingerFormControl.hasError('required')">-->
        <!--            <strong>Incorrect value</strong>-->
        <!--          </mat-error>-->
        <!--        </mat-form-field>-->

        <div class="example-full-width form-field-spacing1">
          <label class="line1-edits-new inputlabel">{{ captionWholesaleEUR }}</label>
          <nz-input-group nzSearch [nzAddOnAfter]="associatedquantity">
            <nz-input-number class="line1-edits-new inputedit testdiv" [(ngModel)]="wholesaleEURstring" [nzMin]="0"
              [nzStep]="0.01"></nz-input-number>
          </nz-input-group>
          <ng-template #associatedquantity>

          </ng-template>
        </div>

        <!--        <mat-form-field class="selector-full-width" appearance="fill">-->
        <!--          <mat-label>{{ captionWholesaleEUR }}</mat-label>-->
        <!--          <input-->
        <!--            matInput-->
        <!--            type="number"-->
        <!--            step="0.01"-->
        <!--            min="0"-->
        <!--            [formControl]="price2Control"-->
        <!--          />-->
        <!--          <mat-error *ngIf="desingerFormControl.hasError('required')">-->
        <!--            <strong>Incorrect value</strong>-->
        <!--          </mat-error>-->
        <!--        </mat-form-field>-->

        <div class="example-full-width form-field-spacing1">
          <label class="line1-edits-new inputlabel">{{ captionWholesaleUSD }}</label>
          <nz-input-group nzSearch [nzAddOnAfter]="associatedquantity">
            <nz-input-number class="line1-edits-new inputedit testdiv" [(ngModel)]="wholesaleUSDstring" [nzMin]="0"
              [nzStep]="0.01"></nz-input-number>
          </nz-input-group>
          <ng-template #associatedquantity>

          </ng-template>
        </div>

        <!--        <mat-form-field class="selector-full-width" appearance="fill">-->
        <!--          <mat-label>{{ captionWholesaleUSD }}</mat-label>-->
        <!--          <input-->
        <!--            matInput-->
        <!--            type="number"-->
        <!--            step="0.01"-->
        <!--            min="0"-->
        <!--            [formControl]="price3Control"-->
        <!--          />-->
        <!--          <mat-error *ngIf="desingerFormControl.hasError('required')">-->
        <!--            <strong>Incorrect value</strong>-->
        <!--          </mat-error>-->
        <!--        </mat-form-field>-->

        <div class="example-full-width form-field-spacing1">
          <label class="line1-edits-new inputlabel">{{ captionSalePriceEUR }}</label>
          <nz-input-group nzSearch [nzAddOnAfter]="associatedquantity">
            <nz-input-number class="line1-edits-new inputedit testdiv" [(ngModel)]="salePriceEURstring" [nzMin]="0"
              [nzStep]="0.01"></nz-input-number>
          </nz-input-group>
          <ng-template #associatedquantity>

          </ng-template>
        </div>

        <!--        <mat-form-field class="selector-full-width" appearance="fill">-->
        <!--          <mat-label>{{ captionSalePriceEUR }}</mat-label>-->
        <!--          <input-->
        <!--            matInput-->
        <!--            type="number"-->
        <!--            step="0.01"-->
        <!--            min="0"-->
        <!--            [formControl]="price4Control"-->
        <!--          />-->
        <!--          <mat-error *ngIf="desingerFormControl.hasError('required')">-->
        <!--            <strong>Incorrect value</strong>-->
        <!--          </mat-error>-->
        <!--        </mat-form-field>-->

        <div class="example-full-width form-field-spacing1">
          <label class="line1-edits-new inputlabel">{{ captionSalePriceUSD }}</label>
          <nz-input-group nzSearch [nzAddOnAfter]="associatedquantity">
            <nz-input-number class="line1-edits-new inputedit testdiv" [(ngModel)]="salePriceUSDstring" [nzMin]="0"
              [nzStep]="0.01"></nz-input-number>
          </nz-input-group>
          <ng-template #associatedquantity>

          </ng-template>
        </div>

        <!--        <mat-form-field class="selector-full-width" appearance="fill">-->
        <!--          <mat-label>{{ captionSalePriceUSD }}</mat-label>-->
        <!--          <input-->
        <!--            matInput-->
        <!--            type="number"-->
        <!--            step="0.01"-->
        <!--            min="0"-->
        <!--            [formControl]="price5Control"-->
        <!--          />-->
        <!--          <mat-error *ngIf="desingerFormControl.hasError('required')">-->
        <!--            <strong>Incorrect value</strong>-->
        <!--          </mat-error>-->
        <!--        </mat-form-field>-->
      </div>
      <!--        2 Line-->
    </div>
  </div>

  <div class="black-background3-1">
    <!--            <mat-form-field class="full-width">-->
    <!--              <mat-label>{{ captionDescription }}</mat-label>-->
    <!--              <textarea matInput class="no-resize" [formControl]="descriptionControl" style="height: 130px"></textarea>-->
    <!--            </mat-form-field>-->
    <div class="example-full-width-description full-width">
      <label class="line1-edits-new inputlabel">Description</label><br>
      <textarea [(ngModel)]="descriptionstring" rows="3" cols="30" class="line1-edits-new inputedit testdiv2 dopinfo"
        nz-input
      ></textarea>
    </div>
    <!--      <mat-form-field class="full-width">-->
    <!--        <mat-label>{{ captionDescription }}</mat-label>-->
    <!--        <textarea matInput class="no-resize" [formControl]="descriptionControl" style="height: 100px;"></textarea>-->
    <!--      </mat-form-field>-->

    <!-- Preview Div for Formatted Text -->

    <!-- Preview Div for Formatted Text -->
  </div>

  <!--    AI da atvirtva-->

  <div class="black-background3">
    <div class="full-width-div2">
      <div class="center-container1">
        <button mat-raised-button type="button" (click)="uploadImageFiles()">
          Upload
          <mat-icon>upload</mat-icon>
        </button>
        <button mat-raised-button type="button" (click)="onButtonAIClick()" [disabled]="AIDisabled">
          Describe items with AI
          <!--                        <mat-icon>neurology</mat-icon>-->
        </button>

        <div class="file-container">
          <mat-progress-spinner *ngIf="showprogressbar"
            class="example-margin"
            [color]="color"
            [mode]="mode"
            [value]="progressvalue"
            style="width: 50px; height: 50px; --mdc-circular-progress-size: 50px; --mdc-circular-progress-active-indicator-width: 100px;">
          </mat-progress-spinner>
          <div cdkDropList cdkDropListOrientation="horizontal" class="example-list" (cdkDropListDropped)="drop($event)">
            <div *ngFor="let item of selectedImageFiles; index as i" class="example-box" cdkDrag>
              <mat-card
                class="card-item"
                [class.selected]="i === selectedIndex"
                [cdkContextMenuTriggerFor]="inner"
                (contextmenu)="selectdefaultimage = item"
                (click)="zoomImage(i, item)">
                <img [src]="transformImageUrl(item.url)" alt="Image" />
<!--                <img [src]="item.url" alt="Image" />-->
                <button mat-mini-fab type="button" class="close-button" [disabled]="showprogressbar"
                  (click)="deleteImageFile($event, i)">
                  <mat-icon class="custom-icon">close</mat-icon>
                </button>
                <img *ngIf="item.sortby === -1" class="checkboxdefault" src="assets/checkbox.png" alt="isdefault" />
              </mat-card>
            </div>
          </div>
          <!--                <mat-card *ngFor="let item of selectedImageFiles; index as i" class="card-item"-->
          <!--                  [class.selected]="i === selectedIndex"-->
          <!--                  [cdkContextMenuTriggerFor]="inner"-->
          <!--                  (contextmenu)="selectdefaultimage = item"-->
          <!--                  (click)="zoomImage(i, item)">-->
          <!--                  <img [src]="item.url" alt="Image"/>-->
          <!--                  <button mat-mini-fab type="button" class="close-button" [disabled]="showprogressbar"-->
          <!--                    (click)="deleteImageFile($event, i)">-->
          <!--                    <mat-icon class="custom-icon">close</mat-icon>-->
          <!--                  </button>-->
          <!--                  <img *ngIf="item.sortby === 0" class="checkboxdefault" src="assets/checkbox.png" alt="isdefault">-->
          <!--                </mat-card>-->
        </div>
      </div>
      <div class="center-container1">
        <button mat-raised-button type="button" (click)="uploadFiles()">
          Browse
          <mat-icon>upload</mat-icon>
        </button>
        <div class="file-container">
          <div
            *ngFor="let file of selectedFiles; let i = index"
            class="file-item"
          >
            {{ file.name }}
            <img
              src="assets/ic-delete-file.svg"
              alt="Delete file"
              class="delete-icon"
              (click)="deleteFile($event, i)"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="samplehistorycaption">
      <span class="samplehistorytext">Sample History</span>
    </div>
    <div class="card example-container-so  mat-elevation-z3">
      <!--  [(selection)]="selectedElement"-->
      <!--  (onRowSelect)="createSendOut(selectedElement)"-->
      <p-table
        #dt1
        *ngIf="SelfData?.samplehistory?.[0]?.data"
        [value]="SelfData.samplehistory[0].data"
        [paginator]="false"
        [rows]="10"
        [showCurrentPageReport]="false"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [rowsPerPageOptions]="[10, 20, 50, 100]"
        [styleClass]="'p-datatable-striped'"
        selectionMode="single"
        [tableStyle]="{'min-width': '50rem'}">
        <ng-template pTemplate="header">
          <tr>
            <!--                    -->
            <th style="width:17.6%">Delivery #</th>
            <th style="width:17.6%">Company</th>
            <th style="width:17.6%">Contact</th>
            <th style="width:17.6%">Ship Date</th>
            <!--                <th>Name</th>-->
            <!--                <th>Category</th>-->
            <!--                <th>Quantity</th>-->
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
          <tr [pSelectableRow]="SelfData.samplehistory[0].data">
            <td>
              <span style="text-decoration: underline; color: #007bff;" (click)="onDeliveriClick(product)">
                {{ product?.["Delivery"] }}
              </span>
            </td>
            <td>
              {{ product?.["Company"] }}
            </td>
            <td>
              {{ product?.["Contact"] }}
            </td>
            <td>
              {{ formatDate(product?.["Ship Date"]) }}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<div class="button-container">
  <button
    mat-raised-button
    class="button-settings"
    type="button"
    *ngIf="isVisible"
    (click)="clearallfield()"
  >
    Clear All
  </button>
  <button
    mat-raised-button
    class="button-settings"
    type="button"
    *ngIf="isVisible"
    (click)="submit(3)"
  >
    Create & Add
  </button>
  <button mat-raised-button class="button-settings" type="button" [disabled]="!SelfData.updatebuttonisenabled"
    (click)="submit(2)">
    {{ createbuttoncaption }}
  </button>
</div>

<div class="button-container1">
  <!--    <mat-checkbox [(ngModel)]="isChecked" [ngModelOptions]="{standalone: true}" (change)="onCheckboxChange($event)">Global Sample</mat-checkbox>-->
</div>

<ng-template #inner>
  <div class="example-menu" cdkMenu>
    <button class="example-menu-item" cdkMenuItem (click)="onDefaultImageClick(selectdefaultimage)">Use as Default
    </button>
    <!--    <button class="example-menu-item" cdkMenuItem>Copy</button>-->
    <!--    <button class="example-menu-item" cdkMenuItem>Paste</button>-->
  </div>
</ng-template>
