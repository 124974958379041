import {Injectable} from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest, HttpHeaders } from '@angular/common/http';
import {catchError, Observable, throwError} from 'rxjs';
import {
  Aboutuslist,
  celebritiaaccount,
  ChangePassDetails,
  ChangePasswordData,
  checksample,
  checksampleaccount,
  Countrylist,
  DemoAccaunt,
  EditAccountDetails,
  GetAddressAccont,
  GetAddressList,
  GetBarcodeAccount,
  GetBarcodeList,
  GetCelebritiList,
  GetCollectionAccont,
  GetCollectionList,
  GetCompanyAccont,
  GetCompanyList,
  GetCompTypeAccont,
  GetCompTypeList,
  GetContacAccont,
  GetContacList,
  GetContactCustomFields,
  GetContactCustomFieldsParams,
  GetContCategoryAccont,
  GetContCategoryList,
  GetContcgAccont,
  GetContcgList,
  GetContjdAccont,
  GetContjdList,
  GetContRbAccont,
  GetContRbList,
  GetContRegionAccont,
  GetContRegionList,
  GetContTypeAccont,
  GetContTypeList,
  GetCurrancyAccont,
  GetCurrancyList,
  GetCustomFields,
  GetCustomFieldsParams,
  GetDesignerAccont,
  GetDesignerList,
  GetFabricAccont,
  GetFabricList,
  GetImageList,
  GetissueAccont,
  GetissueList,
  GetlbCatalogs,
  Getlbdesignerlist,
  GetLBImageList,
  GetLBMain,
  Getlbseasonlist,
  GetLookAccount,
  GetLookList,
  GetLookListAccount,
  GetLookNameList,
  GetManuAccont,
  GetManuList,
  GetMiscAccont,
  GetMiscList,
  GetNewAddressAccont,
  GetNotSettings,
  GetNotSettingsAccont,
  GetPatternAccont,
  GetPatternList,
  GetpeAccont,
  GetpeList,
  GetPronounAccont,
  GetPronounList,
  getPullSheet,
  GetQRResult,
  GetRegionAccont,
  GetRegionList,
  GetScannedList,
  GetScannerCount,
  GetSeasonAccont,
  GetSeasonList,
  GetSendOutAccont,
  GetSendOutList,
  GetSendOutReturnList,
  GetSendOutSamples,
  GetSizeAccont,
  GetSizeList,
  GetSizeRangeList,
  GetSMList,
  GetSocialMediaList,
  GetStyleAccont,
  GetStyleList,
  GetSWSAccont,
  GetSWSList,
  GetSystemContactsFields,
  GetSystemContactsFieldsParams,
  GetSystemFields,
  GetSystemFieldsParams,
  GetSystemUsers,
  GetSystemUsersAccont,
  GettodAccont,
  GettodList,
  GetTypeAccont,
  GetTypeList,
  Industrylist,
  Jobslist,
  LbCatalogData,
  LbMainDetails,
  LbCatalogDetails,
  lbCatalogsAccount,
  lbSeasonAccount,
  lbSelectedSeasonAccount,
  LoginResult,
  Noelist,
  PrcAddress,
  Prccelebritilist,
  Prccfvlist,
  Prccollectionlist,
  PrcCompany,
  Prccomptypelist,
  Prccontaclist,
  PrcContacts,
  PrcContactsSystemFieldsList,
  Prccontcategorylist,
  Prccontcglist,
  Prccontjdlist,
  Prccontrblist,
  Prccontregionlist,
  Prcconttypelist,
  PrcDeleteSamplesFields,
  PrcDeleteSamplesResponse,
  Prcdesignerlist,
  Prcfabriclist,
  Prcissuelist,
  PrclookAccount,
  PrclooklistAccount,
  Prcmanulist,
  Prcmisclist,
  PrcNotificationSettings,
  Prcpatternlist,
  Prcpelist,
  Prcpriceslist,
  Prcpronounlist,
  PrcPstemplateInput,
  PrcPstemplateOutput,
  PrcPullsheetAcceptedInput,
  PrcPullsheetAcceptedOutput,
  Prcregionlist,
  PrcSampleList,
  Prcseasonlist,
  PrcSendOutFields,
  prcSendOutList,
  PrcSendOutReturnlist,
  Prcsizelist,
  Prcsizerangelist,
  Prcsmlist,
  prcsocketlist,
  Prcstylelist,
  prcswsaccount,
  Prcswslist,
  PrcSystemFieldsList,
  Prctodlist,
  Prctypelist,
  PullSheetData,
  PullSheetDetails,
  qraccount,
  RegisterResult,
  reloaddate,
  scannedcountaccount,
  scannedlistaccount,
  sendoutsampleid,
  sendoutsamplelist,
  SendOutSampleRequest,
  SetCFVParams,
  SetContactsList,
  SetContactsParams,
  SetCustomFieldsValue,
  setImageParams,
  setLBImageParams,
  setPullSheet,
  SetPullSheetList,
  SetSampleList,
  SetSampleParams,
  smaccount,
  smediaaccount,
  SmsDetails,
  socialmediaaccount,
  UserAuth,
  LbMainData,
  lbCatalogsAccount1,
  LbSelectedDesignerResponse,
  LbSelectedSeasonRequest,
  LbSelectedSeasonResponse,
  DesignerAccountDetails,
  LbSelectedDesigner,
  GetlbSentList,
  ciAccount,
  GetCustomImagesData,
  GetLBCImageListData,
  setLBCImageParams,
  lbsentDetails,
  lbsentData,
  lbSentAccount,
  GetLBSents,
  lbMainListAccount,
  GetLBMainList,
  lbDesignerstAccount,
  GetLBDesigners,
  lbCollectionsAccount,
  GetLBCollections,
  GetLBImages,
  PrcLbAcceptedResponse,
  PrcLbAcceptedRequest,
  lbAcceptsAccount,
  GetLBAccepts,
  LbSendAccept,
  LbSendAcceptData,
  GetCheckVisibled,
  LbSentData,
  LbSentDetails,
  FavoritesData,
  FavoritesValue,
  LbTempCatalogDetails,
  GetPDFImages,
  linkedImagesAccounts,
  GetLinkedImages,
  GetRequestAccount,
  GetRequestList,
  PrcRequestFields,
  prcRequestList,
  RequestSampleRequest,
  Prcrequestlist,
  requestsamplelist,
  RequestListDetails,
  PrcCreateRequestToSendOutResponse,
  PrcCreateSendOutToRequestResponse,
  latesendoutAccount,
  GetLateSendOut,
  latelist,
  GetLateList,
  AIAccount,
  SetAIAnswer,
  CdlData,
  CdlResponse,
  ccaaccount,
  GetCCList,
  GetUserFilterList,
  UserPreferencesRequest,
  UserPreferencesResponse,
  originalimagesaccount,
  GetOriginalImages,
  PrcDeleteSendOutFields,
  deletescanlistaccount,
  delImageParams,
  GetCollectionValues,
  GetCollectionValuesData,
  collectionsParams,
  PrcHistoryList,
  SetHistoryList,
  GetSampleId,
  samplehistory,
  GetCurSendOutAccont,
  GetContactAccount,
  emailaccount,
  PrcContactemail,
  PrcContactemailOutput,
  PrcSetup,
  PrcSetupOutput,
  PrcContEmails,
  PrcContEmailsOutput,
  GetCMAccountId,
  clientmails,
  maildetailsview,
  GetCMDetailsAccountId,
  SetsorParams,
  SetsorList,
  PrcSendOutShipFields,
  GetGridFields,
  setgridfields,
  ProcessEmailFields,
  ProcessEmailResponse,
  setcomponentfields,
  PrcSaleGeneralInput,
  PrcSaleGeneralOutput,
  GetCustomerGrpupes,
  setcustomergroups,
  settaxefields,
  PrcSuppliersSaleRequest,
  PrcSuppliersSaleResponse,
  TagsList,
  PrcProductsSaleRequest,
  PrcProductsSaleResponse,
  OutletSettingsDetails,
  OutletSettingsResponse,
  CustomerResponse,
  CustomerDetails,
  PrcOrderSaleRequest,
  PrcOrderSaleResponse,
  PrcProductsInSaleRequest,
  PrcProductsInSaleResponse,
  OrderDetails,
  OrderResponse,
  PrcProductsOutSaleRequest,
  PrcBrandSaleRequest,
  PrcBrandSaleResponse,
  ProfileSaleDetails,
  ProfileSale, PermissionsSaleDetails, PermissionsSale, SaleAccaunt, setgridfieldsData, SetsorListData,
} from '../models/struct';
import {HashService} from "./hash.service";
import * as pako from 'pako';

@Injectable({
  providedIn: 'root',
})
export class ServiceService {
  private baseURL: string = '/api/local';


  constructor(private http: HttpClient, private hashService: HashService) {
  }

  getAll(): Observable<Jobslist[]> {
    return this.http.get<Jobslist[]>(
      '/api/local/?command=GetJobList'
    );
    // return this.http.get<Jobslist[]>('http://localhost:6330/?command=GetJobList')
  }

  getAllIndustry(): Observable<Industrylist[]> {
    return this.http.get<Industrylist[]>(
      '/api/local/?command=GetIndustryList'
    );
    // return this.http.get<Industrylist[]>('http://localhost:6330/?command=GetIndustryList')
  }

  getAllCountry(): Observable<Countrylist[]> {
    return this.http.get<Countrylist[]>(
      '/api/local/?command=GetCountryList'
    );
    // return this.http.get<Countrylist[]>('http://localhost:6330/?command=GetCountryList')
  }

  getAllNoe(): Observable<Noelist[]> {
    return this.http.get<Noelist[]>(
      '/api/local/?command=GetNoeList'
    );
    // return this.http.get<Noelist[]>('http://localhost:6330/?command=GetNoeList')
  }

  getAllAboutus(): Observable<Aboutuslist[]> {
    return this.http.get<Aboutuslist[]>(
      '/api/local/?command=GetAboutusList'
    );
    // return this.http.get<Aboutuslist[]>('http://localhost:6330/?command=GetAboutusList')
  }

  create(account: DemoAccaunt): Observable<RegisterResult> {
    return this.http
      .post<RegisterResult>('/api/local/login', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  saleaccount(account: SaleAccaunt): Observable<RegisterResult> {
    return this.http
      .post<RegisterResult>('/api/local/saleaccount', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  checkuser(account: UserAuth): Observable<LoginResult> {
    return this.http
      .post<LoginResult>('/api/local/auth', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  reloaduserdate(account: reloaddate): Observable<LoginResult> {
    return this.http
      .post<LoginResult>('/api/local/selectmydates', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  selectsendoutsamples(account: sendoutsamplelist): Observable<GetScannedList> {
    return this.http
      .post<GetScannedList>('/api/local/getsendoutsamplelist', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  selectrequestsamples(account: requestsamplelist): Observable<GetRequestList> {
    return this.http
      .post<GetRequestList>('/api/local/getrequestsamplelist', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  selectlatelist(account: latelist): Observable<GetLateList> {
    return this.http
      .post<GetLateList>('/api/local/selectlatelist', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  getAIAnswer(account: AIAccount): Observable<SetAIAnswer> {
    return this.http
      .post<SetAIAnswer>('/api/local/message', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  getNewAIAnswer(
    account: AIAccount,
    callback: (chunk: string, done: boolean, error?: any) => void
  ) {
    const url = '/api/local/message';

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(account),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Ошибка сервера: ${response.status} ${response.statusText}`);
        }

        const reader = response.body!.getReader();
        const decoder = new TextDecoder();

        const readChunk = () => {
          reader.read().then(({ done, value }) => {
            if (done) {
              console.log('Получение текста завершено');
              callback('', true); // Уведомляем о завершении передачи
              return;
            }

            const chunk = decoder.decode(value, { stream: true });
            callback(chunk, false); // Передаем chunk с флагом done = false
            console.log(chunk)
            readChunk(); // Продолжаем читать следующие части
          }).catch((error) => {
            console.error('Ошибка при чтении данных:', error);
            callback('', true, error); // Передаем ошибку через callback
          });
        };

        readChunk(); // Начинаем чтение
      })
      .catch((error) => {
        console.error('Произошла ошибка при отправке запроса:', error);
        callback('', true, error); // Передаем ошибку через callback
      });
  }

  selectcclist(account: ccaaccount): Observable<GetCCList> {
    return this.http
      .post<GetCCList>('/api/local/selectcclist', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  selectcelebriti(account: celebritiaaccount): Observable<GetCelebritiList> {
    return this.http
      .post<GetCelebritiList>('/api/local/selectcelebriti', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  selectsm(account: smaccount): Observable<GetSMList> {
    return this.http
      .post<GetSMList>('/api/local/selectsm', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  selectsendoutreturnsamples(account: sendoutsamplelist): Observable<GetSendOutReturnList> {
    return this.http
      .post<GetSendOutReturnList>('/api/local/getsendoutreturnsamplelist', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  selectsendout4samples(account: sendoutsamplelist): Observable<SetSampleList> {
    return this.http
      .post<SetSampleList>('/api/local/getsendoutsamplelist', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  getQRResult(account: qraccount): Observable<GetQRResult> {
    return this.http
      .post<GetQRResult>('/api/local/getqrresult', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  selectlbsents(account: lbSentAccount): Observable<GetLBSents> {
    return this.http
      .post<GetLBSents>('/api/local/selectlbsents', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  selectoriginalimages(account: originalimagesaccount): Observable<GetOriginalImages> {
    return this.http
      .post<GetOriginalImages>('/api/local/selectorigimages', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  selectrequestlist(account: lbSentAccount): Observable<GetLBSents> {
    return this.http
      .post<GetLBSents>('/api/local/selectprintrequestlist', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  selectlatesendout(account: latesendoutAccount): Observable<GetLateSendOut> {
    return this.http
      .post<GetLateSendOut>('/api/local/selectlatesendout', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  selectlbmainlist(account: lbMainListAccount): Observable<GetLBMainList> {
    return this.http
      .post<GetLBMainList>('/api/local/selectlbmainlist', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  selectlbdesigners(account: lbDesignerstAccount): Observable<GetLBDesigners> {
    return this.http
      .post<GetLBDesigners>('/api/local/selectlbdesigners', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  selectlbaccepts(account: lbAcceptsAccount): Observable<GetLBAccepts> {
    return this.http
      .post<GetLBAccepts>('/api/local/selectlbaccepts', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  selectlbcollections(account: lbCollectionsAccount): Observable<GetLBCollections> {
    return this.http
      .post<GetLBCollections>('/api/local/selectlbcollection', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  scannedcount(account: scannedcountaccount): Observable<GetScannerCount> {
    return this.http
      .post<GetScannerCount>('/api/local/selectscannedcount', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  scannedlist(account: scannedlistaccount): Observable<GetScannedList> {
    return this.http
      .post<GetScannedList>('/api/local/getscannlist', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  deletefromscannlist(account: deletescanlistaccount): Observable<GetScannedList> {
    return this.http
      .post<GetScannedList>('/api/local/prcscanList', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  editaccount(account: EditAccountDetails): Observable<ChangePasswordData> {
    return this.http
      .post<ChangePasswordData>(
        '/api/local/prceditaccount',
        account
      )
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  pullSheet(data: PullSheetDetails): Observable<PullSheetData> {
    return this.http
      .post<PullSheetData>(
        '/api/local/pullsheet',
        data
      );
  }

  requestlistemail(data: RequestListDetails): Observable<PullSheetData> {
    return this.http
      .post<PullSheetData>(
        '/api/local/requestlistemail',
        data
      );
  }

  lbsentemail(data: lbsentDetails): Observable<lbsentData> {

    return this.http
      .post<lbsentData>(
        '/api/local/lbsentemail',
        data
      );
  }

  smssend(data: SmsDetails): Observable<PullSheetData> {
    return this.http
      .post<PullSheetData>(
        '/api/local/sendsms',
        data
      );
  }

  requestsmssend(data: SmsDetails): Observable<PullSheetData> {
    return this.http
      .post<PullSheetData>(
        '/api/local/sendrequestsms',
        data
      );
  }

  smssendlbsent(data: lbsentDetails): Observable<PullSheetData> {
    return this.http
      .post<lbsentData>(
        '/api/local/sendsmslbsent',
        data
      );
  }

  lbcatalogs(data: LbCatalogDetails): Observable<LbCatalogData> {
    return this.http.post<LbCatalogData>(
      '/api/local/lbcatalogs',
      data
    );
  }

  lbtempcatalogs(data: LbTempCatalogDetails): Observable<LbCatalogData> {
    return this.http.post<LbCatalogData>(
      '/api/local/lbtempcatalogs',
      data
    );
  }

  OutletSettingsSale(data: OutletSettingsDetails): Observable<OutletSettingsResponse> {
    return this.http.post<OutletSettingsResponse>(
      '/api/local/OutletSettingsSale',
      data
    );
  }

  customersSale(data: CustomerDetails): Observable<CustomerResponse> {
    return this.http.post<CustomerResponse>(
      '/api/local/customersSale',
      data
    );
  }

  orderSaleOut(data: OrderDetails): Observable<OrderResponse> {
    return this.http.post<OrderResponse>(
      '/api/local/orderSaleOut',
      data
    );
  }

  getFavorites(favoritesData: FavoritesData): Observable<FavoritesValue> {
    return this.http
      .post<FavoritesValue>('/api/local/favorites', favoritesData)
      .pipe();
  }

  updateCdl(cdlData: CdlData): Observable<CdlResponse> {
    const url = `${this.apiUrl}/cdl`;
    return this.http.post<CdlResponse>(url, cdlData);
  }

  prclbmain(data: LbMainDetails): Observable<LbMainData> {
    return this.http.post<LbMainData>(
      '/api/local/prclbmain',
      data
    );
  }

  prclbsent(data: LbSentDetails): Observable<LbSentData> {
    return this.http.post<LbSentData>(
      '/api/local/prclbsent',
      data
    );
  }

  sendtocontactsaccept(data: LbSendAccept): Observable<LbSendAcceptData> {
    return this.http.post<LbMainData>(
      '/api/local/sendtocontactsaccept',
      data
    );
  }

  selectcatalogs(account: lbCatalogsAccount): Observable<GetlbCatalogs> {
    return this.http
      .post<GetlbCatalogs>('/api/local/selectlbcatalogs', account)
      .pipe
      ();
  }

  selectlinkedimages(account: linkedImagesAccounts): Observable<GetLinkedImages> {
    return this.http
      .post<GetLinkedImages>('/api/local/selectlinkedimages', account)
      .pipe
      ();
  }

  selecttempcatalogs(account: lbCatalogsAccount): Observable<GetlbCatalogs> {
    return this.http
      .post<GetlbCatalogs>('/api/local/selecttemplbcatalogs', account)
      .pipe
      ();
  }

  selectcustomimages(account: ciAccount): Observable<GetCustomImagesData> {
    return this.http
      .post<GetCustomImagesData>('/api/local/selectcusomimages', account)
      .pipe
      ();
  }

  selectlbmain(account: lbCatalogsAccount): Observable<GetLBMain> {
    return this.http
      .post<GetLBMain>('/api/local/selectlbmain', account)
      .pipe
      ();
  }

  selectlbsent(account: lbCatalogsAccount): Observable<GetlbSentList> {
    return this.http
      .post<GetlbSentList>('/api/local/selectlbsent', account)
      .pipe
      ();
  }

  checkvisibled(account: lbCatalogsAccount): Observable<GetCheckVisibled> {
    return this.http
      .post<GetCheckVisibled>('/api/local/checkvisibled', account)
      .pipe
      ();
  }

  selectlbdesignerlist(account: lbCatalogsAccount): Observable<Getlbdesignerlist> {
    return this.http
      .post<Getlbdesignerlist>('/api/local/selectlbdesignerlist', account)
      .pipe
      ();
  }

  selectlbselecteddesignerlist(account: lbCatalogsAccount1): Observable<Getlbdesignerlist> {
    return this.http
      .post<Getlbdesignerlist>('/api/local/selectlbselecteddesignerlist', account)
      .pipe
      ();
  }

  selectlbseasonlist(account: lbSeasonAccount): Observable<Getlbseasonlist> {
    return this.http
      .post<Getlbseasonlist>('/api/local/selectlbseasonlist', account)
      .pipe
      ();
  }

  selectlbselectedseasonlist(account: lbSelectedSeasonAccount): Observable<Getlbseasonlist> {
    return this.http
      .post<Getlbseasonlist>('/api/local/selectlbselectedseasonlist', account)
      .pipe
      ();
  }

  changepassword(account: ChangePassDetails): Observable<ChangePasswordData> {
    return this.http
      .post<ChangePasswordData>(
        '/api/local/changepassword',
        account
      )
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  setSWSList(account: GetSWSAccont): Observable<GetSWSList> {
    return this.http
      .post<GetSWSList>('/api/local/getswslist', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  setPronounList(account: GetPronounAccont): Observable<GetPronounList> {
    return this.http
      .post<GetPronounList>('/api/local/getpronounlist', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  setRegionList(account: GetRegionAccont): Observable<GetRegionList> {
    return this.http
      .post<GetRegionList>('/api/local/getregionlist', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  setDesignerList(account: GetDesignerAccont): Observable<GetDesignerList> {
    return this.http
      .post<GetDesignerList>(
        '/api/local/getdesignerlist',
        account
      )
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  setBarcodeList(account: GetBarcodeAccount): Observable<GetBarcodeList> {
    return this.http
      .post<GetBarcodeList>(
        '/api/local/selectbarcode',
        account
      )
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  setFabricList(account: GetFabricAccont): Observable<GetFabricList> {
    return this.http
      .post<GetFabricList>('/api/local/getfabriclist', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  setManuList(account: GetManuAccont): Observable<GetManuList> {
    return this.http
      .post<GetManuList>('/api/local/getmanulist', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  setCollectionList(
    account: GetCollectionAccont
  ): Observable<GetCollectionList> {
    return this.http
      .post<GetCollectionList>(
        '/api/local/getcollectionlist',
        account
      )
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  setTodList(account: GetCollectionAccont): Observable<GetCollectionList> {
    return this.http.post<GetCollectionList>('/api/local/gettodlist',account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  setContactList(account: GetCollectionAccont): Observable<GetCollectionList> {
    return this.http.post<GetCollectionList>('/api/local/getcontactlist',account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  setClientList(account: GetCollectionAccont): Observable<GetCollectionList> {
    return this.http.post<GetCollectionList>('/api/local/getselectclientlist',account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  setestyLis(account: GetCollectionAccont): Observable<GetCollectionList> {
    return this.http.post<GetCollectionList>('/api/local/getselectestylist',account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  setsendouttype(account: GetCollectionAccont): Observable<GetCollectionList> {
    return this.http.post<GetCollectionList>('/api/local/getselectsendouttype',account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  setcim(account: GetCollectionAccont): Observable<GetCollectionList> {
    return this.http.post<GetCollectionList>('/api/local/getselectcim',account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  setPublicationList(account: GetCollectionAccont): Observable<GetCollectionList> {
    return this.http.post<GetCollectionList>('/api/local/getselectpublication',account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  getSendOutlist(account: GetSendOutAccont): Observable<GetSendOutList> {
    return this.http
      .post<GetSendOutList>('/api/local/selectsendoutlist', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  getCurSendOut(account: GetCurSendOutAccont): Observable<GetSendOutList> {
    return this.http
      .post<GetSendOutList>('/api/local/selectcursendout', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  getEmailContacts(account: GetContactAccount): Observable<emailaccount> {
    return this.http
      .post<emailaccount>('/api/local/getemailcontacts', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  getSampleHistoy(account: GetSampleId): Observable<samplehistory> {
    return this.http
      .post<samplehistory>('/api/local/selectsamplehistory', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  getGridFields(fieldparams: GetGridFields): Observable<setgridfields> {
    return this.http
      .post<setgridfields>('/api/local/selectgridfields', fieldparams)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  getCustomerGroupes(fieldparams: GetCustomerGrpupes): Observable<setcustomergroups> {
    return this.http
      .post<setcustomergroups>('/api/local/selectcustomergroupesale', fieldparams)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  getComponentFields(fieldParams: { formaname: string; userid: number; accountid: number }): Observable<setcomponentfields> {
    return this.http
      .post<setcomponentfields>('/api/local/selectcomponentfields', fieldParams)
      .pipe(
        // Add any necessary RxJS operators here, such as tap or catchError
      );
  }

  getTaxes(fieldParams: { accountid: number }): Observable<settaxefields> {
    return this.http
      .post<settaxefields>('/api/local/selecttaxes', fieldParams)
      .pipe(
        // Add any necessary RxJS operators here, such as tap or catchError
      );
  }

  getContactMailList(account: GetCMAccountId): Observable<clientmails> {
    return this.http
      .post<clientmails>('/api/local/selectcontactemaillist', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  getMailDetailsView(account: GetCMDetailsAccountId): Observable<maildetailsview> {
    return this.http
      .post<maildetailsview>('/api/local/selectemaildetailsview', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  getSendOutPendinglist(account: GetSendOutAccont): Observable<GetSendOutList> {
    return this.http
      .post<GetSendOutList>('/api/local/selectsendoutpendinglist', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  getRequestlist(account: GetRequestAccount): Observable<GetRequestList> {
    return this.http
      .post<GetRequestList>('/api/local/selectrequestlist', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  setSeasonList(account: GetSeasonAccont): Observable<GetSeasonList> {
    return this.http
      .post<GetSeasonList>('/api/local/getseasonlist', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  setStyleList(account: GetStyleAccont): Observable<GetStyleList> {
    return this.http
      .post<GetStyleList>('/api/local/getstylelist', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  setMiscList(account: GetMiscAccont): Observable<GetMiscList> {
    return this.http
      .post<GetMiscList>('/api/local/getmisclist', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  setTypeList(account: GetTypeAccont): Observable<GetTypeList> {
    return this.http
      .post<GetTypeList>('/api/local/gettypelist', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  setSizeList(account: GetSizeAccont): Observable<GetSizeList> {
    return this.http
      .post<GetSizeList>('/api/local/getsizelist', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  setPatternList(account: GetPatternAccont): Observable<GetPatternList> {
    return this.http
      .post<GetPatternList>('/api/local/getpatternlist', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  getSystemFieldsList(
    account: GetSystemFieldsParams
  ): Observable<GetSystemFields> {
    return this.http
      .post<GetSystemFields>(
        '/api/local/getsystemfields',
        account
      )
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  getSystemContactsFieldsList(
    account: GetSystemContactsFieldsParams
  ): Observable<GetSystemContactsFields> {
    return this.http
      .post<GetSystemContactsFields>(
        '/api/local/getsystemcontactsfields',
        account
      )
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  getCustomFieldsList(
    account: GetCustomFieldsParams
  ): Observable<GetCustomFields> {
    return this.http
      .post<GetCustomFields>(
        '/api/local/getcustomfields',
        account
      )
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  getContactCustomFieldsList(
    account: GetContactCustomFieldsParams
  ): Observable<GetContactCustomFields> {
    return this.http
      .post<GetContactCustomFields>(
        '/api/local/getcontactscustomfields',
        account
      )
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  setSizeRangeList(account: GetPatternAccont): Observable<GetSizeRangeList> {
    return this.http
      .post<GetSizeRangeList>(
        '/api/local/getsizerangelist',
        account
      )
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  setCFVList(account: SetCFVParams): Observable<SetCustomFieldsValue> {
    return this.http
      .post<SetCustomFieldsValue>(
        '/api/local/selectcustomfieldsvalue',
        account
      )
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  setSampleList(account: SetSampleParams): Observable<SetSampleList> {
    return this.http
      .post<SetSampleList>(
        '/api/local/selectsamplelist',
        account
      )
      .pipe(
        catchError((error) => {
          console.error('Error occurred during the request:', error);
          return throwError(error);
        })
      );
  }

  setSendOutReportList(account: SetsorParams): Observable<SetsorList> {
    return this.http
      .post<SetsorList>(
        '/api/local/getfilteredsendouts',
        account
      )
      .pipe(
        catchError((error) => {
          console.error('Error occurred during the request:', error);
          return throwError(error);
        })
      );
  }

  setContactsList(account: SetContactsParams): Observable<SetContactsList> {
    return this.http
      .post<SetContactsList>(
        '/api/local/selectcontactslist',
        account
      )
      .pipe(
        catchError((error) => {
          console.error('Error occurred during the request:', error);
          return throwError(error);
        })
      );
  }

  setSocialMediaList(account: socialmediaaccount): Observable<GetSocialMediaList> {
    return this.http
      .post<GetSocialMediaList>(
        '/api/local/getsocialdatas',
        account
      )
      .pipe(
        catchError((error) => {
          console.error('Error occurred during the request:', error);
          return throwError(error);
        })
      );
  }

  setSMList(account: smediaaccount): Observable<GetSocialMediaList> {
    return this.http
      .post<GetSocialMediaList>(
        '/api/local/getsmdatas',
        account
      )
      .pipe(
        catchError((error) => {
          console.error('Error occurred during the request:', error);
          return throwError(error);
        })
      );
  }

  setCompanyList(account: GetCompanyAccont): Observable<GetCompanyList> {
    return this.http
      .post<GetCompanyList>('/api/local/getcompanylist', account)
      .pipe(
        catchError((error) => {
          console.error('Error occurred during the request:', error);
          return throwError(error);
        })
      );
  }

  setImageList(account: setImageParams): Observable<GetImageList> {
    return this.http
      .post<GetImageList>('/api/local/selectimagelist', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  setSaleImageList(account: setImageParams): Observable<GetImageList> {
    return this.http
      .post<GetImageList>('/api/local/selectsaleimagelist', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  setCollections(account: collectionsParams): Observable<GetCollectionValues> {
    return this.http
      .post<GetCollectionValues>('/api/local/selectcollectionbyid', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  delImageList(account: delImageParams): Observable<GetImageList> {
    return this.http
      .post<GetImageList>('/api/local/prcdeleteimagefiles', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }


  selectUserPreferences(UserID: number): Observable<GetUserFilterList> {
    const params = { UserID: UserID }; // Replace ReturnTypeHere with the actual return type

    return this.http.post<GetUserFilterList>('/api/local/selectuserpreferences', params);
  }

  setLBImageList(account: setLBImageParams): Observable<GetLBImageList> {
    return this.http
      .post<GetLBImageList>('/api/local/selectlbimagelist', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  setLBImages(account: setLBImageParams): Observable<GetLBImages> {
    return this.http
      .post<GetLBImages>('/api/local/selectlbImages', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  setLBCImageList(account: setLBCImageParams): Observable<GetLBCImageListData> {
    return this.http
      .post<GetLBCImageListData>('/api/local/selectlbcimagelist', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  setContCategoryList(
    account: GetContCategoryAccont
  ): Observable<GetContCategoryList> {
    return this.http
      .post<GetContCategoryList>(
        '/api/local/getcontcategorylist',
        account
      )
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  setContJDList(account: GetContjdAccont): Observable<GetContjdList> {
    return this.http
      .post<GetContjdList>('/api/local/getcontjdlist', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  setCurrancyList(account: GetCurrancyAccont): Observable<GetCurrancyList> {
    return this.http
      .post<GetCurrancyList>(
        '/api/local/getcontcurrencylist',
        account
      )
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  setContTypeList(account: GetContTypeAccont): Observable<GetContTypeList> {
    return this.http
      .post<GetContTypeList>(
        '/api/local/getconttypelist',
        account
      )
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  setCompTypeList(account: GetCompTypeAccont): Observable<GetCompTypeList> {
    return this.http
      .post<GetCompTypeList>(
        '/api/local/getcomptypelist',
        account
      )
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  setContRegionList(
    account: GetContRegionAccont
  ): Observable<GetContRegionList> {
    return this.http
      .post<GetContRegionList>(
        '/api/local/getcontregionlist',
        account
      )
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  setContRBList(account: GetContRbAccont): Observable<GetContRbList> {
    return this.http
      .post<GetContRbList>('/api/local/getcontrblist', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  getCheckSample(account: checksampleaccount): Observable<checksample> {
    return this.http
      .post<checksample>('/api/local/checksample', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  setContACList(account: GetContacAccont): Observable<GetContacList> {
    return this.http
      .post<GetContacList>('/api/local/getcontaclist', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  setContCGList(account: GetContcgAccont): Observable<GetContcgList> {
    return this.http
      .post<GetContcgList>('/api/local/getcontcglist', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  setAddressList(account: GetAddressAccont): Observable<GetAddressList> {
    return this.http
      .post<GetAddressList>('/api/local/selectaddress', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  selectpullsheet(account: setPullSheet): Observable<SetPullSheetList> {
    return this.http
      .post<SetPullSheetList>('/api/local/selectpullsheet', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  selectpullsheetlist(account: {
                        date: null | string;
                        accountid: number;
                        period: string | null;
                        email: string;
                        status: string;
                        sendout: string;
                      }
  ): Observable<SetPullSheetList> {
    return this.http
      .post<SetPullSheetList>('/api/local/selectpullsheetlist', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  selectHistory(userid: number): Observable<SetHistoryList> {
    return this.http
      .post<SetHistoryList>('/api/local/selecthistory', { userid })
      .pipe();
  }

  selectCategories(userid: number): Observable<TagsList> {
    return this.http
      .post<TagsList>('/api/local/selectcategories', { userid })
      .pipe();
  }

  selectTags(userid: number): Observable<TagsList> {
    return this.http
      .post<TagsList>('/api/local/selecttags', { userid })
      .pipe(
        // You can uncomment the tap operator to perform actions with the response if needed
        // tap(hist => this.history.push(hist))
      );
  }

  selectSendOutReport(guid:string): Observable<TagsList> {
    return this.http
      .post<TagsList>('/api/local/selectsendoutreport', { guid })
      .pipe(
        // You can uncomment the tap operator to perform actions with the response if needed
        // tap(hist => this.history.push(hist))
      );
  }

  selectEmailFields(guid:string): Observable<TagsList> {
    return this.http
      .post<TagsList>('/api/local/selectemailfields', { guid })
      .pipe(
        // You can uncomment the tap operator to perform actions with the response if needed
        // tap(hist => this.history.push(hist))
      );
  }

  selectSaleAccounts(accountid: number): Observable<TagsList> {
    return this.http
      .post<TagsList>('/api/local/selectsaleaccounts', { accountid })
      .pipe(
        // You can uncomment the tap operator to perform actions with the response if needed
        // tap(hist => this.history.push(hist))
      );
  }

  selectPermissions(profileid: number): Observable<TagsList> {
    return this.http
      .post<TagsList>('/api/local/selectpermissions', { profileid })
      .pipe(
        // You can uncomment the tap operator to perform actions with the response if needed
        // tap(hist => this.history.push(hist))
      );
  }

  selectProfiles(accountid: number): Observable<TagsList> {
    return this.http
      .post<TagsList>('/api/local/selectprofiles', { accountid })
      .pipe(
        // You can uncomment the tap operator to perform actions with the response if needed
        // tap(hist => this.history.push(hist))
      );
  }

  selectCustomerHistory(customerid: number): Observable<TagsList> {
    return this.http
      .post<TagsList>('/api/local/selectcustomerhistory', { customerid })
      .pipe(
        // You can uncomment the tap operator to perform actions with the response if needed
        // tap(hist => this.history.push(hist))
      );
  }

  selectSupplierHistory(supplierid: number): Observable<TagsList> {
    return this.http
      .post<TagsList>('/api/local/selectsupplierhistory', { supplierid })
      .pipe(
        // You can uncomment the tap operator to perform actions with the response if needed
        // tap(hist => this.history.push(hist))
      );
  }

  selectProductHistory(productid: number): Observable<TagsList> {
    return this.http
      .post<TagsList>('/api/local/selectproducthistory', { productid })
      .pipe(
        // You can uncomment the tap operator to perform actions with the response if needed
        // tap(hist => this.history.push(hist))
      );
  }

  selectSalesReport(startDate: Date, endDate: Date, accountId: number): Observable<TagsList> {
    return this.http
      .post<TagsList>('/api/local/selectSalesReport', {
        startDate,
        endDate,
        accountId
      })
      .pipe(
        // You can uncomment the tap operator to perform actions with the response if needed
        // tap(report => this.salesReports.push(report))
      );
  }

  getRemainingProductQuantity(productid: number): Observable<TagsList> {
    return this.http
      .post<TagsList>('/api/local/remainingproductquantity', { productid })
      .pipe(
        // You can uncomment the tap operator to perform actions with the response if needed
        // tap(response => console.log(response))
      );
  }

  selectProducts(userid: number): Observable<TagsList> {
    return this.http
      .post<TagsList>('/api/local/selectproducts', { userid })
      .pipe(
        // You can uncomment the tap operator to perform actions with the response if needed
        // tap(hist => this.history.push(hist))
      );
  }

  selectOutlets(accountid: { accountid: number }): Observable<TagsList> {
    return this.http
      .post<TagsList>('/api/local/selectoutlets', { accountid })
      .pipe(
        // You can uncomment the tap operator to perform actions with the response if needed
        // tap(hist => this.history.push(hist))
      );
  }

  selectOrderin(accountid: { accountid: number }): Observable<TagsList> {
    return this.http
      .post<TagsList>('/api/local/selectorderin', { accountid })
      .pipe(
        // You can uncomment the tap operator to perform actions with the response if needed
        // tap(hist => this.history.push(hist))
      );
  }

  selectOrderout(accountid: { accountid: number }): Observable<TagsList> {
    return this.http
      .post<TagsList>('/api/local/selectorderout', { accountid })
      .pipe(
        // You can uncomment the tap operator to perform actions with the response if needed
        // tap(hist => this.history.push(hist))
      );
  }

  selectProductin(accountid: number, orderid: number): Observable<TagsList> {
    return this.http
      .post<TagsList>('/api/local/selectproductin', { accountid, orderid })
      .pipe(
        // You can uncomment the tap operator to perform actions with the response if needed
        // tap(hist => this.history.push(hist))
      );
  }

  selectProductout(accountid: number, orderid: number): Observable<TagsList> {
    return this.http
      .post<TagsList>('/api/local/selectproductout', { accountid, orderid })
      .pipe(
        // You can uncomment the tap operator to perform actions with the response if needed
        // tap(hist => this.history.push(hist))
      );
  }

  selectCustomers(accountid: { accountid: number }): Observable<TagsList> {
    return this.http
      .post<TagsList>('/api/local/selectCustomers', { accountid })
      .pipe(
        // You can uncomment the tap operator to perform actions with the response if needed
        // tap(hist => this.history.push(hist))
      );
  }

  selectBrands(userid: number): Observable<TagsList> {
    return this.http
      .post<TagsList>('/api/local/selectbrands', { userid })
      .pipe(
        // You can uncomment the tap operator to perform actions with the response if needed
        // tap(hist => this.history.push(hist))
      );
  }

  selectMaterials(userid: number): Observable<TagsList> {
    return this.http
      .post<TagsList>('/api/local/selectmaterials', { userid })
      .pipe(
        // You can uncomment the tap operator to perform actions with the response if needed
        // tap(hist => this.history.push(hist))
      );
  }

  selectSuppliers(accountid: number): Observable<TagsList> {
    return this.http
      .post<TagsList>('/api/local/selectsuppliers', { accountid })
      .pipe(
        // You can uncomment the tap operator to perform actions with the response if needed
        // tap(hist => this.history.push(hist))
      );
  }

  selectGeneralSetup(accountid: number): Observable<TagsList> {
    return this.http
      .post<TagsList>('/api/local/selectgeneralsetup', { accountid })
      .pipe(
        // You can uncomment the tap operator to perform actions with the response if needed
        // tap(hist => this.history.push(hist))
      );
  }

  getsendoutsaples(account: sendoutsampleid): Observable<GetSendOutSamples> {
    return this.http
      .post<GetSendOutSamples>('/api/local/getsendoutsamples', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  setNewAddressList(account: GetNewAddressAccont): Observable<GetAddressList> {
    return this.http
      .post<GetAddressList>(
        '/api/local/selectnewaddress',
        account
      )
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  setNotSettings(account: GetNotSettingsAccont): Observable<GetNotSettings> {
    return this.http
      .post<GetNotSettings>(
        '/api/local/selectnotificationsettings',
        account
      )
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  setTODList(account: GettodAccont): Observable<GettodList> {
    return this.http
      .post<GettodList>('/api/local/gettodlist', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  setIssueList(account: GetissueAccont): Observable<GetissueList> {
    return this.http
      .post<GetissueList>('/api/local/getissuelist', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  setPEList(account: GetpeAccont): Observable<GetpeList> {
    return this.http
      .post<GetpeList>('/api/local/getpelist', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  setSender(account: GetpeAccont): Observable<GetpeList> {
    return this.http
      .post<GetpeList>('/api/local/getselectsender', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  setPhotographer(account: GetpeAccont): Observable<GetpeList> {
    return this.http
      .post<GetpeList>('/api/local/getselectphotographer', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  setLookName(account: GetLookAccount): Observable<GetLookNameList> {
    return this.http
      .post<GetLookNameList>('/api/local/getLookNameList', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  setLookList(account: GetLookListAccount): Observable<GetLookList> {
    return this.http
      .post<GetLookList>('/api/local/getLookList', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }


  setSystemUsers(account: GetSystemUsersAccont): Observable<GetSystemUsers> {
    return this.http
      .post<GetSystemUsers>('/api/local/getsystemusers', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  prcsws(account: {
    accountid: number;
    ctrl: number;
    description: string | undefined;
    id: number;
  }): Observable<Prcswslist> {
    return this.http
      .post<Prcswslist>('/api/local/prcsws', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  prcGridFields(data: {
    isshow: boolean;
    formname: string;
    fieldname: string;
    userid: number;
    accountid: number;
  }): Observable<any> {
    return this.http
      .post<any>('/api/local/prcgridfields', data)
      .pipe(
        // Дополнительная обработка данных, если потребуется
        // tap(response => console.log('Response:', response))
      );
  }

  prcComponentFields(data: {
    isshow: boolean;
    formaname: string;
    componentname: string;
    userid: number;
    accountid: number;
  }): Observable<any> {
    return this.http
      .post<any>('/api/local/prccomponentfields', data)
      .pipe(
        // Дополнительная обработка данных, если потребуется
        // tap(response => console.log('Response:', response))
      );
  }

  prcHistory(history: {
    userid: number;
    formtype: number;
    sender: string;
    recipient: string;
    returnpoint: string;
    sendouttype: string;
    currency: string;
    reservationdate: Date;
    expreturndate: Date;
    requestedby: string;
  }): Observable<PrcHistoryList> {
    return this.http
      .post<PrcHistoryList>('/api/local/prc_history', history)
      .pipe(
        // You can uncomment the tap operator to perform actions with the response if needed
        // tap(hist => this.history.push(hist))
      );
  }

  prcCreateRequestToSendOut(requestid: number): Observable<PrcCreateRequestToSendOutResponse> {
    return this.http
      .post<PrcCreateRequestToSendOutResponse>('/api/local/prcCreateRequestToSendOut', {requestid})
      .pipe();
    // You can add operators like tap() or catchError() inside the pipe() if needed
  }

  prcCreateSendOutToRequest(requestid: number): Observable<PrcCreateSendOutToRequestResponse> {
    return this.http
      .post<PrcCreateSendOutToRequestResponse>('/api/local/prcCreateSendOutToRequest', {requestid})
      .pipe();
    // You can add operators like tap() or catchError() inside the pipe() if needed
  }


  prcLbSelectedDesigner(accountDetails: DesignerAccountDetails): Observable<LbSelectedDesigner> {
    return this.http
      .post<LbSelectedDesigner>('/api/local/prcLbSelectedDesigner', accountDetails)
      .pipe(
        // tap(response => console.log(response)) // Use tap to perform side effects with the response data
      );
  }

  prcProfileSale(profileSaleDetails: ProfileSaleDetails): Observable<ProfileSale> {
    return this.http
      .post<ProfileSale>('/api/local/prcProfileSale', profileSaleDetails)
      .pipe(
        // tap(response => console.log(response)) // Use tap to perform side effects with the response data
      );
  }

  prcPermissionsSale(permissionsSaleDetails: PermissionsSaleDetails): Observable<PermissionsSale> {
    return this.http
      .post<PermissionsSale>('/api/local/prcPermissionsSale', permissionsSaleDetails)
      .pipe(
        // tap(response => console.log(response)) // Use tap to perform side effects with the response data
      );
  }

  prcLbSelectedSeason(account: LbSelectedSeasonRequest): Observable<LbSelectedSeasonResponse> {
    return this.http.post<LbSelectedSeasonResponse>('/api/local/prcLbSelectedSeason', account);
  }

  prcSuppliersSale(account: PrcSuppliersSaleRequest): Observable<PrcSuppliersSaleResponse> {
    return this.http.post<PrcSuppliersSaleResponse>('/api/local/prcSuppliersSale', account);
  }

  prcBrandSale(account: PrcBrandSaleRequest): Observable<PrcBrandSaleResponse> {
    return this.http.post<PrcBrandSaleResponse>('/api/local/prc_brand_sale', account);
  }

  prcCategorySale(account: PrcBrandSaleRequest): Observable<PrcBrandSaleResponse> {
    return this.http.post<PrcBrandSaleResponse>('/api/local/prc_category_sale', account);
  }

  prcMaterialSale(account: PrcBrandSaleRequest): Observable<PrcBrandSaleResponse> {
    return this.http.post<PrcBrandSaleResponse>('/api/local/prc_material_sale', account);
  }

  prcProductsSale(product: PrcProductsSaleRequest): Observable<PrcProductsSaleResponse> {
    return this.http.post<PrcProductsSaleResponse>('/api/local/prcProductsSale', product);
  }

  prcOrderSaleIn(order: PrcOrderSaleRequest): Observable<PrcOrderSaleResponse> {
    return this.http.post<PrcOrderSaleResponse>('/api/local/prcOrderSaleIn', order);
  }

  prcProductsInSale(product: PrcProductsInSaleRequest): Observable<PrcProductsInSaleResponse> {
    return this.http.post<PrcProductsInSaleResponse>('/api/local/prcProductsInSale', product);
  }

  prcProductsOutSale(product: PrcProductsOutSaleRequest): Observable<PrcProductsInSaleResponse> {
    return this.http.post<PrcProductsInSaleResponse>('/api/local/prcProductsOutSale', product);
  }

  prccelebriti(account: {
    accountid: number;
    ctrl: number;
    contactid: number;
    description?: string;
    id: number;
    isactivity: boolean;
    smid: number;
  }): Observable<Prccelebritilist> {
    return this.http
      .post<Prccelebritilist>('/api/local/prccelebriti', account);
  }

  prcsm(account: {
    id?: number;
    sm_instagram?: string;
    sm_tiktok?: string;
    sm_twitter?: string;
    sm_facebook?: string;
    sm_youtube?: string;
    celebritiid: number;
    ctrl: number;
  }): Observable<Prcsmlist> {
    return this.http
      .post<Prcsmlist>('/api/local/prcsm', account);
  }

  prcdeletelbimage(account: {
    userid?: number;
    accountid?: number;
    filename?: string;
  }): Observable<Prcsmlist> {
    return this.http
      .post<Prcsmlist>('/api/local/prcdeletelbimage', account);
  }

  prcdeletelbcimage(account: {
    userid?: number;
    accountid?: number;
    filename?: string;
  }): Observable<Prcsmlist> {
    return this.http
      .post<Prcsmlist>('/api/local/prcdeletelbcimage', account);
  }

  prclbcimage(account: {
    userid?: number;
    accountid?: number;
    filename?: string;
    filedir?: string;
    ctrl?: number;
    lbcatalogid?: number;
    lbmainid?: number;
    description?: string;
  }): Observable<Prcsmlist> {
    return this.http
      .post<Prcsmlist>('/api/local/prclbcimage', account);
  }

  insertMaimmailList(account: { accountid: number }): Observable<Prcsmlist> {
    return this.http.post<Prcsmlist>('/api/local/insertmaimmaillist',
      JSON.stringify(account),  // Ensure valid JSON format
      { headers: { 'Content-Type': 'application/json' } }
    );
  }

  sendSetMail(from: string, to: string, subject: string, message: string, setData?: SetsorListData[], setFields?: setgridfieldsData[], setGuid?: string): Observable<any> {
    // Default values if parameters are undefined
    const safeSetData: SetsorListData[] = Array.isArray(setData) ? setData : [];
    const safeSetFields: setgridfieldsData[] = Array.isArray(setFields) ? setFields : [];
    const safeSetGuid: string = setGuid || "";

    const requestBody = {
      SetData: {
        Code: 200,
        ErrorMessage: "Success",
        data: safeSetData  // ✅ Always ensure `data` array is present
      },
      SetFields: {
        Code: 200,
        ErrorMessage: "Success",
        data: safeSetFields
      },
      setGuid: safeSetGuid,
      from: from,       // ✅ დამატებულია
      to: to,           // ✅ დამატებულია
      subject: subject, // ✅ დამატებულია
      message: message  // ✅ დამატებულია
    };

    console.log("Sending Data:", JSON.stringify(requestBody)); // Debugging

    return this.http.post<any>('/api/local/sendsetmail', requestBody, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    });
  }



  prcLbAccepted(request: PrcLbAcceptedRequest): Observable<PrcLbAcceptedResponse> {
    return this.http.post<PrcLbAcceptedResponse>('/api/local/prclbaccepted', request);
  }

  prcsendoutreturnupdate(account: {
    ctrl: number;
    sampleid: number;
    sendoutid: number;
    sendouttype: number;
    samplestatus: string | undefined;
    comment: string | undefined;
    amount: number;
    discount: number;
    totalprice: number;
  }): Observable<PrcSendOutReturnlist> {
    return this.http
      .post<Prcswslist>('/api/local/prcsendoutreturnupdate', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  prcSendOutSamples(data: SendOutSampleRequest): Observable<Prcswslist> {
    return this.http
      .post<Prcswslist>('/api/local/prcsendoutsamples', data);
  }

  prcRequestSamples(data: RequestSampleRequest): Observable<Prcrequestlist> {
    return this.http
      .post<Prcrequestlist>('/api/local/prcrequestsamples', data);
  }

  prcUserPreferences(data: UserPreferencesRequest): Observable<UserPreferencesResponse> {
    return this.http
      .post<UserPreferencesResponse>('/api/local/prc_UserPreferences', data);
  }

  prcregion(account: {
    accountid: number;
    ctrl: number;
    description: string | undefined;
    id: number;
  }): Observable<Prcregionlist> {
    return this.http
      .post<Prcregionlist>('/api/local/prcregion', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  prctod(account: {
    accountid: number;
    ctrl: number;
    description: string | undefined;
    id: number;
  }): Observable<Prctodlist> {
    return this.http
      .post<Prctodlist>('/api/local/prctod', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  prcpstemplate(account: PrcPstemplateInput): Observable<PrcPstemplateOutput> {
    return this.http.post<PrcPstemplateOutput>('/api/local/prc_pstemplate', account);
  }

  prccontactemail(account: PrcContactemail): Observable<PrcContactemailOutput> {
    return this.http.post<PrcContactemailOutput>('/api/local/prc_contacteditemail', account);
  }

  prcsetup(account: PrcSetup): Observable<PrcSetupOutput> {
    return this.http.post<PrcSetupOutput>('/api/local/prc_setup', account);
  }

  prccontemails(account: PrcContEmails): Observable<PrcContEmailsOutput> {
    return this.http.post<PrcContEmailsOutput>('/api/local/prc_cont_emails', account);
  }


  prcPullsheetAccepted(account: PrcPullsheetAcceptedInput): Observable<PrcPullsheetAcceptedOutput> {
    return this.http.post<PrcPullsheetAcceptedOutput>('/api/local/prc_pullsheetaccepted', account);
  }

  prcSaleGeneral(account: PrcSaleGeneralInput): Observable<PrcSaleGeneralOutput> {
    return this.http.post<PrcSaleGeneralOutput>('/api/local/prc_sale_general', account);
  }

  prcissue(account: {
    accountid: number;
    ctrl: number;
    description: string | undefined;
    id: number;
  }): Observable<Prcissuelist> {
    return this.http
      .post<Prcissuelist>('/api/local/prcissue', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  prcpe(account: {
    accountid: number;
    ctrl: number;
    description: string | undefined;
    id: number;
  }): Observable<Prcpelist> {
    return this.http
      .post<Prcpelist>('/api/local/prcpe', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  prcdesigner(account: {
    accountid: number;
    ctrl: number;
    description: string | undefined;
    id: number;
  }): Observable<Prcdesignerlist> {
    return this.http
      .post<Prcdesignerlist>('/api/local/prcdesigner', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  prcfabric(account: {
    accountid: number;
    ctrl: number;
    description: string | undefined;
    id: number;
  }): Observable<Prcfabriclist> {
    return this.http
      .post<Prcfabriclist>('/api/local/prcfabric', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  prcstyle(account: {
    accountid: number;
    ctrl: number;
    description: string | undefined;
    id: number;
  }): Observable<Prcstylelist> {
    return this.http
      .post<Prcstylelist>('/api/local/prcstyle', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  prcmanu(account: {
    accountid: number;
    ctrl: number;
    description: string | undefined;
    id: number;
  }): Observable<Prcmanulist> {
    return this.http
      .post<Prcmanulist>('/api/local/prcmanu', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  prcseason(account: {
    /*
  const ctrl = parseInt(req.body.ctrl)
    const id =  parseInt(req.body.id)
    const seasoncode = req.body.seasoncode
    const seasonyear = req.body.seasonyear
    const description = req.body.description
    const accountid = parseInt(req.body.accountid)
   */
    ctrl: number;
    id: number;
    seasoncode: string | undefined;
    seasonyear: string | undefined;
    description: string | undefined;
    accountid: number;
  }): Observable<Prcseasonlist> {
    return this.http
      .post<Prcseasonlist>('/api/local/prcseason', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  prccollection(account: {
    /*
    @ctrl		 INT		   = 0,
    @id			 INT		   = 0,
    @description NVARCHAR(150) = '',
    @accountid	 INT		   = 0,
    @desinger	 NVARCHAR(150) = '',
    @season		 NVARCHAR(150) = ''
     */
    ctrl: number;
    id: number;
    description: string | undefined;
    accountid: number;
    desinger: string | undefined;
    season: string | undefined;
  }): Observable<Prccollectionlist> {
    return this.http
      .post<Prccollectionlist>(
        '/api/local/prccollection',
        account
      )
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  prcmisc(account: {
    accountid: number;
    ctrl: number;
    description: string | undefined;
    id: number;
  }): Observable<Prcmisclist> {
    return this.http
      .post<Prcmisclist>('/api/local/prcmisc', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  prclook(account: {
    accountid: number;
    ctrl: number;
    description: string | undefined;
    id: number;
  }): Observable<PrclookAccount> {
    return this.http
      .post<PrclookAccount>('/api/local/prclook', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  prclooklist(account: {
    userid: number;
    ctrl: number;
    sampleid: number;
    lookid: number;
  }): Observable<PrclooklistAccount> {
    return this.http
      .post<PrclooklistAccount>('/api/local/prclooklist', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  prctype(account: {
    accountid: number;
    ctrl: number;
    description: string | undefined;
    id: number;
  }): Observable<Prctypelist> {
    return this.http
      .post<Prctypelist>('/api/local/prctype', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  prcsize(account: {
    accountid: number;
    ctrl: number;
    description: string | undefined;
    id: number;
  }): Observable<Prcsizelist> {
    return this.http
      .post<Prcsizelist>('/api/local/prcsize', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  prcpattern(account: {
    accountid: number;
    ctrl: number;
    description: string | undefined;
    id: number;
  }): Observable<Prcpatternlist> {
    return this.http
      .post<Prcpatternlist>('/api/local/prcpattern', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  prcsizerange(account: {
    accountid: number;
    ctrl: number;
    description: string | undefined;
    id: number;
  }): Observable<Prcsizerangelist> {
    return this.http
      .post<Prcsizerangelist>('/api/local/prcsizerange', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  prcpronoun(account: {
    accountid: number;
    ctrl: number;
    description: string | undefined;
    id: number;
  }): Observable<Prcpronounlist> {
    return this.http
      .post<Prcpronounlist>('/api/local/prcpronoun', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  prccontcategory(account: {
    accountid: number;
    ctrl: number;
    description: string | undefined;
    id: number;
  }): Observable<Prccontcategorylist> {
    return this.http
      .post<Prccontcategorylist>(
        '/api/local/prccontcategory',
        account
      )
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  prccontjd(account: {
    accountid: number;
    ctrl: number;
    description: string | undefined;
    id: number;
  }): Observable<Prccontjdlist> {
    return this.http
      .post<Prccontjdlist>('/api/local/prccontjd', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }


  runSocketInitDataRequest(account: {
    ctrl: number;
    userid: number;
    accountid: number;
    socketid: string | undefined;
    type: number;
  }): Observable<prcsocketlist> {
    return this.http
      .post<prcsocketlist>('/api/local/prcsecketlist', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  prcconttype(account: {
    accountid: number;
    ctrl: number;
    description: string | undefined;
    id: number;
  }): Observable<Prcconttypelist> {
    return this.http
      .post<Prcconttypelist>('/api/local/prcconttype', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  prccomptype(account: {
    accountid: number;
    ctrl: number;
    description: string | undefined;
    id: number;
  }): Observable<Prccomptypelist> {
    return this.http
      .post<Prccomptypelist>('/api/local/prccomptype', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  prccontregion(account: {
    accountid: number;
    ctrl: number;
    description: string | undefined;
    id: number;
  }): Observable<Prccontregionlist> {
    return this.http
      .post<Prccontregionlist>(
        '/api/local/prccontregion',
        account
      )
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  prccontrb(account: {
    accountid: number;
    ctrl: number;
    description: string | undefined;
    id: number;
  }): Observable<Prccontrblist> {
    return this.http
      .post<Prccontrblist>('/api/local/prccontrb', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  prccontac(account: {
    accountid: number;
    ctrl: number;
    description: string | undefined;
    id: number;
  }): Observable<Prccontaclist> {
    return this.http
      .post<Prccontaclist>('/api/local/prccontac', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  prccontcg(account: {
    accountid: number;
    ctrl: number;
    description: string | undefined;
    id: number;
  }): Observable<Prccontcglist> {
    return this.http
      .post<Prccontcglist>('/api/local/prccontcg', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  prcSample(account: {
    designerid: number;
    isactiviti: boolean;
    notes: string | null;
    looksassociated: string | null;
    gender: any;
    ctrl: number;
    inventoristatusid: any;
    manufacturerid: number;
    colorcode: string | null;
    description: string | null;
    sessionid: number;
    curdate: Date;
    userid: number;
    accountid: number;
    regionid: number;
    typeid: number;
    id: number;
    sizeid: number;
    quantity: string | null;
    fabricid: number;
    patternid: number;
    sizerangeid: number;
    colorname: string | null;
    styleid: number;
    miscid: number;
    pricesid: number;
    collectionid: number;
    inventoristatus: any;
    sampleworkflowstatusid: number;
    storageid: number;
    stylestr: string | null;
    barcode: string | null;
    samplestyle: number | 0
  }): Observable<PrcSampleList> {
    return this.http
      .post<PrcSampleList>('/api/local/prcsamples', account)
      .pipe();
  }

  prcSystemFields(account: {
    ctrl: number;
    id: number;
    framename: string;
    fieldlabel: string;
    accountid: number;
    required: boolean;
    fieldtype: number;
    controllertype: number;
    minnumber: number;
    maxnumber: number;
    isfloat: boolean;
    step: number;
    userid: number;
    curdate: Date;
    description: string;
  }): Observable<PrcSystemFieldsList> {
    return this.http
      .post<PrcSystemFieldsList>('/api/local/prcfields', account)
      .pipe();
  }

  prcContactsSystemFields(account: {
    ctrl: number;
    id: number;
    framename: string;
    fieldlabel: string;
    accountid: number;
    required: boolean;
    fieldtype: number;
    controllertype: number;
    minnumber: number;
    maxnumber: number;
    isfloat: boolean;
    step: number;
    userid: number;
    curdate: Date;
    description: string;
  }): Observable<PrcContactsSystemFieldsList> {
    return this.http
      .post<PrcContactsSystemFieldsList>(
        '/api/local/prccontactsfields',
        account
      )
      .pipe();
  }

  prccfv(account: {
    framename: string;
    controllername: string;
    accountid: number;
    value: string;
    sampleid: number;
  }): Observable<Prccfvlist> {
    return this.http
      .post<Prccfvlist>('/api/local/prccfv', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  prcccfv(account: {
    framename: string;
    controllername: string;
    accountid: number;
    value: string;
    sampleid: number;
  }): Observable<Prccfvlist> {
    return this.http
      .post<Prccfvlist>('/api/local/prccfv', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  prccompany(account: {
    ctrl: number;
    id: number;
    companyname: string;
    phone: string;
    publicationcompany: string;
    website: string;
    email: string;
    typeid: number;
    einvoicingcode: string;
    marker: string;
    taxid: string;
    manufacturecode: string;
    notes: string;
    instagram: string;
    facebook: string;
    tiktok: string;
    youtube: string;
    twitter: string;
    linkedin: string;
    accountid: number;
    userid: number;
    isactivity: boolean;
    addressid: number;
  }): Observable<PrcCompany> {
    return this.http
      .post<PrcCompany>('/api/local/prccompany', account)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  prcContacts(account: {
    ctrl: number;
    id: number;
    department: string;
    pronoumid: number | null;
    salutation: string;
    spouse: string;
    firstname: string;
    assistantname: string;
    lastname: string;
    assistanttitle: string;
    mobile: string;
    assistantmobile1: string;
    email: string;
    assistantmobile2: string;
    alternativeemail: string;
    assistentemail: string;
    einvoicingcode: string;
    vipcelebrityemail: string;
    comments: string;
    sidenotes: string;
    jobtitle: string;
    categoryid: number | null;
    jobdepartmentid: number | null;
    typeid: number | null;
    regionid: number | null;
    refferedbyid: number | null;
    associatedcontactsid: number | null;
    contactgroupsid: number | null;
    instagram: string;
    facebook: string;
    tiktok: string;
    youtube: string;
    twitter: string;
    linkedin: string;
    primaryaddresstype: number;
    companyinfoid: number;
    homeinfoid: number;
    otherinfo: number;
    activity: boolean;
    accountid: number;
    taxid: string;
    companyname: string;
    companycareof: string;
    companyaddress1: string;
    companyaddress2: string;
    companycity: string;
    companystate: string;
    companyzipcode: string;
    companycountry: string;
    companydirectphone: string;
    companywebsite: string;
    companyemail: string;
    companyotheremail: string;
    // New parameters added here
    cardname: string;
    cardnumber: string;
    expdate: string;
    cvccode: string;
    billingaddress1: string;
    billingaddress2: string;
    billingstate: string;
    billingcity: string;
    billingzipcode: string;
    billingcountry: string;
  }): Observable<PrcContacts> {
    return this.http
      .post<PrcContacts>('/api/local/prccontacts', account)
      .pipe(
        // tap(acc => this.account.push(acc))
      );
  }

  prcaddress(address: {
    ctrl: number;
    id: number;
    careof: string;
    country: string;
    address1: string;
    email: string;
    otheremail: string;
    address2: string;
    phone: string;
    generalphone: string;
    mobile1: string;
    mobile2: string;
    city: string;
    state: string;
    zipcode: string;
    isactivity: boolean;
    contactsid: number;
    accountid: number;
    description: string;
  }): Observable<PrcAddress> {
    return this.http
      .post<PrcAddress>('/api/local/prcaddress', address)
      .pipe
      // tap(acc => this.account.push(acc))
      ();
  }

  prcNotificationSettings(settings: {
    rsMonday: boolean;
    rsTuesday: boolean;
    rsWednesday: boolean;
    rsThursday: boolean;
    rsFriday: boolean;
    rsSaturday: boolean;
    rsSunday: boolean;
    osMonday: boolean;
    osTuesday: boolean;
    osWednesday: boolean;
    osThursday: boolean;
    osFriday: boolean;
    osSaturday: boolean;
    osSunday: boolean;
    email: boolean;
    push: boolean;
    check1: boolean;
    check2: boolean;
    check3: boolean;
    check4: boolean;
    check5: boolean;
    accountid: number;
  }): Observable<PrcNotificationSettings> {
    return this.http
      .post<PrcNotificationSettings>(
        '/api/local/prcNotificationSettings',
        settings
      )
      .pipe
      // tap(settings => this.settings.push(settings))
      ();
  }

  prcSendOut(fields: PrcSendOutFields): Observable<prcSendOutList> {
    return this.http.post<prcSendOutList>(`/api/local/prcsendout`, fields);
  }

  prcSendOutShip(fields: PrcSendOutShipFields): Observable<prcSendOutList> {
    return this.http.post<prcSendOutList>(`/api/local/prcsendoutship`, fields);
  }

  prcSendOutPending(fields: PrcSendOutFields): Observable<prcSendOutList> {
    return this.http.post<prcSendOutList>(`/api/local/prcsendoutpending`, fields);
  }

  prcDeleteSendOut(fields: PrcDeleteSendOutFields): Observable<prcSendOutList> {
    return this.http.post<prcSendOutList>(`/api/local/prcdeletesendout`, fields);
  }

  prcRequest(fields: PrcRequestFields): Observable<prcRequestList> {
    return this.http.post<prcRequestList>(`/api/local/prcrequest`, fields);
  }

  prcDeleteSamples(fields: PrcDeleteSamplesFields): Observable<PrcDeleteSamplesResponse> {
    return this.http.post<PrcDeleteSamplesResponse>(`/api/local/prcdeletedsamples`, fields);
  }

  processEmail(fields: ProcessEmailFields): Observable<ProcessEmailResponse> {
    return this.http.post<ProcessEmailResponse>('/api/local/process-email', fields);
  }

  prcprices(
    ctrl: number,
    accountid: number,
    id: number,
    retailpriceeur: string | null,
    retailpriceusd: string | null,
    wholesaleeur: string | null,
    wholesaleusd: string | null,
    salepriceeur: string | null,
    saleusd: string | null
  ): Observable<Prcpriceslist> {
    const payload = {
      ctrl,
      accountid,
      id,
      retailpriceeur,
      retailpriceusd,
      wholesaleeur,
      wholesaleusd,
      salepriceeur,
      saleusd,
    };

    return this.http.post<Prcpriceslist>(
      '/api/local/prcprices',
      payload
    );
  }

  apiUrl = '/api/local';

  uploadSample(
    file: File | null,
    accountid: number,
    userid: number,
    sampleid: number,
    ctrl: number,
    filename: string,
    type: number,
    url: string,
    sortby: number
  ): Promise<any> {
    const formData = new FormData();

    // Append the file only if it is not null
    if (file instanceof File) {
      formData.append('image', file, file.name);
    } else {
      formData.append('filename', filename);
    }

    // Append other data to formData
    // formData.append('filename', filename);
    formData.append('accountid', accountid.toString());
    formData.append('userid', userid.toString());
    formData.append('sampleid', sampleid.toString());
    formData.append('ctrl', ctrl.toString());
    formData.append('type', type.toString());
    formData.append('url', url);
    formData.append('sortby', sortby.toString());

    console.log(formData);
    console.log("sortby", sortby);

    return new Promise((resolve, reject) => {
      this.http.post(`${this.apiUrl}/uploadSample`, formData).subscribe({
        next: (response) => resolve(response),
        error: (error) => reject(error),
      });

    });
  }

  uploadSampleProducts(
    file: File | null,
    accountid: number,
    userid: number,
    sampleid: number,
    ctrl: number,
    filename: string,
    type: number,
    url: string,
    sortby: number
  ): Promise<any> {
    const formData = new FormData();

    // Append the file only if it is not null
    if (file instanceof File) {
      formData.append('image', file, file.name);
    } else {
      formData.append('filename', filename);
    }

    // Append other data to formData
    // formData.append('filename', filename);
    formData.append('accountid', accountid.toString());
    formData.append('userid', userid.toString());
    formData.append('sampleid', sampleid.toString());
    formData.append('ctrl', ctrl.toString());
    formData.append('type', type.toString());
    formData.append('url', url);
    formData.append('sortby', sortby.toString());

    console.log(formData);
    console.log("sortby", sortby);

    return new Promise((resolve, reject) => {
      this.http.post(`${this.apiUrl}/uploadSampleProducts`, formData).subscribe({
        next: (response) => resolve(response),
        error: (error) => reject(error),
      });

    });
  }

  uploadTempImage(
    file: File | null,
    filename: string,
  ): Observable<HttpEvent<any>> {
    const formData = new FormData();

    if (file instanceof File) {
      formData.append('image', file, file.name);
    } else {
      formData.append('filename', filename);
    }

    return this.http.post(`${this.apiUrl}/uploadTempImage`, formData, {
      reportProgress: true,
      observe: 'events',
    });
  }

  uploadsortby (
    accountid: number,
    userid: number,
    sampleid: number,
    ctrl: number,
    filename: string,
    type: number,
    url: string,
    sortby: number,
    isduplicate: boolean
  ) {
    const payload = {
      accountid,
      userid,
      sampleid,
      ctrl,
      filename,
      type,
      url,
      sortby,
      isduplicate
    };

    return this.http.post(`${this.apiUrl}/prcimagefiles`, payload);
  }

  uploadlbImage(
    file: File,
    accountid: number,
    userid: number,
    ctrl: number,
    lbcatalogid: number
  ) {
    const formData = new FormData();
    const newFilename = `${this.hashService.md5(lbcatalogid.toString())}_${file.name}`;
    if (file instanceof File) {
      // Check if file is an instance of File
      formData.append('image', file, newFilename);
      formData.append('accountid', accountid.toString());
      formData.append('userid', userid.toString());
      formData.append('ctrl', ctrl.toString());
      formData.append('lbcatalogid', lbcatalogid.toString());

      return this.http.post(`${this.apiUrl}/uploadLBImages`, formData);
    } else {
      // Return null to indicate that nothing should happen
      return null;
    }
  }

  uploadPDFFile(file: File, lbcatalogid: number): Observable<HttpEvent<any>> {
    const formData = new FormData();
    formData.append('pdfFile', file, file.name);
    formData.append('designerid', `${this.hashService.md5(lbcatalogid.toString())}`);

    const req = new HttpRequest('POST', `${this.apiUrl}/extractImagesFromPdf`, formData, {
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req);
  }

  deleteImageFiles(imageFiles: any[]) {
    const url = `${this.apiUrl}/deleteimagefiles`;
    return this.http.post(url, imageFiles, {responseType: 'text'});
  }

  saveImageFiles(imageFiles: any[]) {
    const url = `${this.apiUrl}/saveimagefiles`;
    return this.http.post(url, imageFiles, {responseType: 'text'});
  }

  copydeleteImageFiles(imageFiles: any[]) {
    const url = `${this.apiUrl}/copyanddelete`;
    return this.http.post(url, imageFiles, {responseType: 'text'});
  }

  getDivHTML(): string {
    const element = document.getElementById('scrollable-div-dm');
    return element ? element.outerHTML : '';
  }

  sendEmail(from: string, to: string, subject: string, message: string) {
    const htmlContent = this.getDivHTML();

    // Сжимаем данные и конвертируем в Base64
    const compressedHtml = btoa(
      String.fromCharCode(...pako.gzip(htmlContent))
    );

    this.http.post('/api/local/SendSendOutMail', {
      html: compressedHtml, // Используем сжатый контент
      from: from,
      to: to,
      subject: subject,
      message: message,
      compressed: true
    })
      .subscribe(
        response => {
          // Handle successful response, like getting back the generated PDF
          console.log('Email sent successfully:', response);
        },
        error => {
          // Handle error
          console.error('Error sending email:', error);
        }
      );
  }
}
