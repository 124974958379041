<form [formGroup]="myForm" (ngSubmit)="submit()" autocomplete="off">
    <button
            mat-icon-button
            type="button"
            class="close-btn"
            (click)="modalService.closeDragDrop()"
    >
        <mat-icon>close</mat-icon>
    </button>
    <p class="sample-manager-text">{{ formCaption }}</p>
    <div class="black-background">
        <button mat-icon-button (click)="showAddEditFrame(0, null, -1)">
            <mat-icon>add</mat-icon>
        </button>
    </div>

    <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
        <ng-container *ngIf="SelfData.modaltype === -4">
            <div class="example-box" *ngFor="let item of iCelebritiResult[0].data" cdkDrag>
                {{ item.description }}
                <div class="button-container-dd">
                    <button mat-button class="edit-button"
                            (click)="showAddEditFrame(1, item, item.id, item.description)">
                        Edit
                    </button>
                    <button mat-button class="delete-button" (click)="deleterecord(item.id)">
                        Delete
                    </button>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="SelfData.modaltype !== -4">
            <div class="example-box" *ngFor="let item of iTODResult[0].data" cdkDrag>
                {{ item.description }}
                <div class="button-container-dd">
                    <button mat-button class="edit-button"
                            (click)="showAddEditFrame(1, item, item.id, item.description)">
                        Edit
                    </button>
                    <button mat-button class="delete-button" (click)="deleterecord(item.id)">
                        Delete
                    </button>
                </div>
            </div>
        </ng-container>
    </div>

    <div class="button-container-dragdrop">
        <button mat-raised-button class="button-settings" type="button" (click)="onButtonSaveClick()">
            <!--      <mat-icon>arrow_back_ios</mat-icon>-->
            Save
        </button>
    </div>
</form>

<p-confirmDialog #cd [style]="{width: '25vw'}" [closable]="true">
  <ng-template pTemplate="footer">
    <div class="button-container-request">
      <button mat-raised-button class="button-secondary" style="margin-right: 10px; width: 50%" (click)="cd.reject()">
        No
      </button>
      <button mat-raised-button class="button-primary" style="margin-right: 0; width: 50%" (click)="cd.accept()">
        Yes
      </button>
    </div>
  </ng-template>
</p-confirmDialog>
