<div *ngIf="isDesktopDevice; else elseBlock">
  <div *ngIf="isParams; else elseBlock2">
    <div *ngIf="isaccepted===2" class="spinner">
      <p-progressSpinner></p-progressSpinner>
    </div>
    <div *ngIf="showqrcodescan===2" class="spinner">
      <p-progressSpinner></p-progressSpinner>
    </div>
    <div *ngIf="showlookbok===2" class="spinner">
      <p-progressSpinner></p-progressSpinner>
    </div>
    <div *ngIf="showcatalog===2" class="spinner">
      <p-progressSpinner></p-progressSpinner>
    </div>
    <div *ngIf="showimages===2" class="spinner">
      <p-progressSpinner></p-progressSpinner>
    </div>
    <div *ngIf="requestlist===2" class="spinner">
      <p-progressSpinner></p-progressSpinner>
    </div>
    <div *ngIf="latesendout===2" class="spinner">
      <p-progressSpinner></p-progressSpinner>
    </div>
    <div *ngIf="sendout===2" class="spinner">
      <p-progressSpinner></p-progressSpinner>
    </div>
    <app-pullsheet *ngIf="isaccepted===0"></app-pullsheet>
    <app-acceptedpullsheet *ngIf="isaccepted===1"></app-acceptedpullsheet>
    <app-qrcodescan *ngIf="showqrcodescan===1"></app-qrcodescan>
    <app-lookbook *ngIf="showlookbok===1"></app-lookbook>
    <app-catalogs *ngIf="showcatalog===1"></app-catalogs>
    <app-imageviews *ngIf="showimages===1"></app-imageviews>
    <app-acceptedimages *ngIf="showreqimages===1"></app-acceptedimages>
    <app-printrequestlist *ngIf="requestlist===1"></app-printrequestlist>
    <app-latelist *ngIf="latesendout===1"></app-latelist>
    <app-sendoutreport *ngIf="sendout===1"></app-sendoutreport>
  </div>
  <ng-template #elseBlock2>
    <router-outlet></router-outlet>
  </ng-template>

</div>
<ng-template #elseBlock>
  <div *ngIf="isParams; else elseBlock1">
    <div *ngIf="isaccepted===2" class="spinner">
      <p-progressSpinner></p-progressSpinner>
    </div>
    <div *ngIf="showqrcodescan===2" class="spinner">
      <p-progressSpinner></p-progressSpinner>
    </div>
    <div *ngIf="showlookbok===2" class="spinner">
      <p-progressSpinner></p-progressSpinner>
    </div>
    <div *ngIf="showcatalog===2" class="spinner">
      <p-progressSpinner></p-progressSpinner>
    </div>
    <div *ngIf="showimages===2" class="spinner">
      <p-progressSpinner></p-progressSpinner>
    </div>
    <div *ngIf="showreqimages===2" class="spinner">
      <p-progressSpinner></p-progressSpinner>
    </div>
    <div *ngIf="requestlist===2" class="spinner">
      <p-progressSpinner></p-progressSpinner>
    </div>
    <div *ngIf="latesendout===2" class="spinner">
      <p-progressSpinner></p-progressSpinner>
    </div>
    <div *ngIf="sendout===2" class="spinner">
      <p-progressSpinner></p-progressSpinner>
    </div>
    <app-pullsheet *ngIf="isaccepted===0"></app-pullsheet>
    <app-acceptedpullsheet *ngIf="isaccepted===1"></app-acceptedpullsheet>
    <app-qrcodescan *ngIf="showqrcodescan===1"></app-qrcodescan>
    <app-lookbook *ngIf="showlookbok===1"></app-lookbook>
    <app-catalogs *ngIf="showcatalog===1"></app-catalogs>
    <app-imageviews *ngIf="showimages===1"></app-imageviews>
    <app-acceptedimages *ngIf="showreqimages===1"></app-acceptedimages>
    <app-printrequestlist *ngIf="requestlist===1"></app-printrequestlist>
    <app-latelist *ngIf="latesendout===1"></app-latelist>
    <app-sendoutreport *ngIf="sendout===1"></app-sendoutreport>
  </div>
  <ng-template #elseBlock1>
    <h1 class="text-style text-center pt-3">PC or Mac only {{tempText}}</h1>
  </ng-template>
</ng-template>
