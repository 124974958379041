import { Component } from '@angular/core';
import { ModalserviceService } from '../../services/modalservice.service';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-messagebox',
  templateUrl: './messagebox.component.html',
  styleUrls: ['./messagebox.component.scss'],
})
export class MessageboxComponent {
  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder
  ) {}
}
