<p-confirmDialog #cd [style]="{width: '25vw'}" [closable]="true">
    <!--  <ng-template pTemplate="header">-->
    <!--    <h3 class="red-text">This item is on loan!</h3>-->
    <!--  </ng-template>-->
    <ng-template pTemplate="footer">
        <!--        <button type="button" pButton label="No" (click)="cd.reject()"></button>-->
        <div class="button-container-rr">
            <button mat-raised-button class="button-secondary" style="margin-right: 10px; width: 10%" (click)="cd.reject()">
                No
            </button>
            <button mat-raised-button class="button-primary" style="width: 10%" (click)="cd.accept()">
                Yes
            </button>
        </div>
        <!--        <button type="button" pButton label="Yes" (click)="cd.accept()"></button>-->
    </ng-template>
</p-confirmDialog>
<div class="black-background2">
    <div class="card example-container-sm mat-elevation-z3">
        <p-table
                [value]="SelfData.selectpullsheetlist[0].data"
                [paginator]="true"
                [rows]="10"
                [showCurrentPageReport]="true"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [rowsPerPageOptions]="[10, 20, 50, 100]"
                [styleClass]="'p-datatable-striped p-datatable-sm'"
                selectionMode="single"
                [tableStyle]="{'min-width': '50rem '}">
            <ng-template pTemplate="header">
                <tr>
                    <!--                    -->
                    <th style="width:0%">Options</th>
                    <th style="width:10%">Email</th>
                    <th style="width:8%">Date</th>
                    <th style="width:10%">Status</th>
                    <th style="width:10%">SendOut</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-product>
                <tr [pSelectableRow]="product">
                    <td>
                        <div class="button-container-table">
                            <p-button *ngIf="SelfData.selectlookmode != 0"
                                      label="Select" size="small" [outlined]="true" severity="secondary"
                                      [matMenuTriggerFor]="menu"
                                      (click)="selectedElement = product">
                            </p-button>
                        </div>

                    </td>
                    <td>{{ product?.["email"] }}</td>
                    <td>{{ extractDateFromDateObject(product?.["operdate"]) }}</td>
                    <ng-container *ngIf="product?.['status'] !== undefined">
                        <td *ngIf="product['status'] === 0; else acceptedTemplate">Pending</td>

                        <ng-template #acceptedTemplate>
                            <td *ngIf="product['status'] === 1" [style.color]="'green'">Accepted</td>
                        </ng-template>
                    </ng-container>
                    <ng-container *ngIf="product?.['sendoutid'] !== undefined">
                        <td *ngIf="product['sendoutid'] === 0; else acceptedTemplate">Without SendOut</td>

                        <ng-template #acceptedTemplate>
                            <td *ngIf="product['sendoutid'] > 0" [style.color]="'green'">With SendOut</td>
                        </ng-template>
                    </ng-container>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<mat-menu #menu="matMenu">
    <ng-container *ngIf="selectedElement?.status !== undefined">
        <button *ngIf="selectedElement?.status === 1" mat-menu-item (click)="onButtonViewPullSheetClick(selectedElement)">
            <span>View Pull Sheet</span>
        </button>
    </ng-container>

    <ng-container *ngIf="selectedElement?.sendoutid !== undefined">
        <button *ngIf="selectedElement?.sendoutid > 0" mat-menu-item (click)="onButtonViewItems(selectedElement)">
            <span>View Samples</span>
        </button>
    </ng-container>
    <ng-container *ngIf="selectedElement?.status !== undefined">
    <button *ngIf="selectedElement?.status === 0"  mat-menu-item (click)="onButtonDeleteClick(selectedElement)">
        <mat-icon>delete</mat-icon>
        <span>Delete</span>
    </button>
    </ng-container>
</mat-menu>
