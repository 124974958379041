import { Component } from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";

@Component({
  selector: 'app-createvasiaframe',
  templateUrl: './createvasiaframe.component.html',
  styleUrls: ['./createvasiaframe.component.scss']
})
export class CreatevasiaframeComponent {
  constructor(public modalService: ModalserviceService) {}
}
