<button mat-icon-button type="button" class="close-btn" (click)="modalService.closeRRC()">
    <mat-icon>close</mat-icon>
</button>
<p class="sample-manager-text-rrc">Marked as:</p>

<div class="black-background-mail">
    <span>
        <p class="operationtext">
            {{SelfData.opertext}}
        </p>
    </span>
</div>

<div class="card-email-rrc">
    <p-editor [(ngModel)]="commenttext" [style]="{ height: '150px' }">
        <!--        <ng-template pTemplate="header">-->
        <!--        <span class="ql-formats">-->
        <!--            <button type="button" class="ql-bold" aria-label="Bold"></button>-->
        <!--            <button type="button" class="ql-italic" aria-label="Italic"></button>-->
        <!--            <button type="button" class="ql-underline" aria-label="Underline"></button>-->
        <!--        </span>-->
        <!--        </ng-template>-->
    </p-editor>
</div>

<div class="button-container-rrc">
    <button mat-raised-button class="button-settings" type="button" (click)="submit()">
        Submit
    </button>
</div>
