import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {EMPTY, forkJoin, Subscription, switchMap, tap} from "rxjs";
import {Table} from "primeng/table";
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder} from "@angular/forms";
import {HashService} from "../../services/hash.service";
import {MessageboxComponent} from "../../modals/messagebox/messagebox.component";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {MatSnackBar} from "@angular/material/snack-bar";
import {HttpClient} from "@angular/common/http";
import {
  CustomerDetails,
  CustomerResponse, OrderDetails, PrcProductsInSaleRequest, PrcProductsInSaleResponse, PrcProductsOutSaleRequest,
  SelfData,
  settaxefieldsData,
  TagsListData,
  transformImageUrl
} from "../../models/struct";

export interface Product {
  id: number;
  name: string;
  code: string;
  sku_code: string; // ✅ Добавлен SKU Number
  quantity: number;
  unitPrice: number;
  discountPercentage: number;
  discountAmount: number;
  totalBeforeDiscount: number;
  totalAfterDiscount: number;
  imagelink: string; // ✅ Добавлен параметр для изображения
  count: number;
  status: boolean;
  wholesaleprice: number;
}

@Component({
  selector: 'app-ssframedialog',
  templateUrl: './ssframedialog.component.html',
  styleUrl: './ssframedialog.component.scss'
})
export class SsframedialogComponent implements OnInit, AfterViewInit {
  private subscription?: Subscription;
  caption: string = "New sale"
  sizes!: any[];
  @ViewChild(Table) dataTable?: Table;
  iProductinResult: TagsListData[] = []
  iCustomersResult: TagsListData[] = []
  iProductCountResult: TagsListData[] = []
  iTaxResult: settaxefieldsData[] = []
  selectedproduct: Product[] = [];
  filteredProducts: any[] = [];
  selectedSize: any = ''
  setid: number = 0;
  opermode: number = 0;
  openclose: number = 1;
  selectproduct: string = "";
  selectcustomer: string = "";
  shippingCost: number = 0;
  importDuty: number = 0;
  orderTotal: number = 0;
  taxvalue: number = 0;
  ttaxvalue: number = 0;
  paymenttype: number = 0;
  paymentvalue: number = 0;
  changevalue: number = 0;
  twholesale: boolean = false

  inputWidth: number = 401; // default width
  @ViewChild('searchInput', { static: false }) searchInput!: ElementRef;

  formatterDollar = (value: number): string => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  formatterPercent = (value: number): string => `${value}%`;
  parserPercent = (value: string): number => parseFloat(value?.replace('%', ''));
  private taxAmount: number = 0;

  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
      ? SelfData.account[0].data[0].id
      : SelfData.account[0].data[0].parentid;
  }

  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private hashService: HashService,
    public messageBox: MessageboxComponent,
    private serviceservice: ServiceService,
    private yourappsService: YourappsserviceService,
    private notification: NzNotificationService,
    private _snackBar: MatSnackBar,
    private http: HttpClient,
    private cdr: ChangeDetectorRef
  ) {
    this.sizes = [{name: "Small", class: "p-datatable-sm"}];
    this.selectedSize = this.sizes[0];
    this.setid = 0;
    this.opermode = 0;

    if (SelfData.returnorder) {
      this.completesalestring = "Return"
    } else {
      this.completesalestring = "Complete sale"
    }

    const accountId = SelfData.account[0].data[0].id;

    forkJoin({
      productin: this.serviceservice.selectProducts(accountId),
      customer: this.serviceservice.selectCustomers({accountid: this.getAccountID()}),
      taxes: this.serviceservice.getTaxes({accountid: this.getAccountID()}),
    }).subscribe(({productin, customer, taxes}) => {
      this.iProductinResult = productin.data;
      this.iCustomersResult = customer.data;
      this.iTaxResult = taxes.data;
      this.filteredProducts = this.iProductinResult;
      console.log("this.iProductinResult", this.iProductinResult)

      // ✅ Ждем `selectedOrderInList$`, потом загружаем `productin`
      this.yourappsService.selectedOrderOutList$.pipe(
        tap((value) => {
          if (this.subscription) {
            this.subscription.unsubscribe();
          }

          if (value?.type === 1) {
            this.opermode = 1;
            if (this.SelfData.returnorder) {
              this.opermode = 2;
            }
            this.caption = "Edit order";

            if (value.data && typeof value.data === "object") {
              let data: any = value.data;

              if (Array.isArray(data) && data.length > 0) {
                data = data[0];
              }

              console.log("✅ Объект после обработки:", data);

              // ✅ Заполняем параметры
              this.setid = data.id;
              this.selectcustomer = data.customer;
              this.shippingCost = data.shipingcost;
              this.selecttaxe = data.saletaxdisplay
              this.tselecttaxe = data.saletaxdisplay
              this.orderTotal = data.ordertotal
              this.notestring = data.note;
              this.paymenttype = data.paymenttype;
              this.changevalue = data.change;
              this.completesale = true
              this.paymentvalue = data.ordertotal + data.change
              this.ttaxvalue = data.salestax
              // this.listOfTagOptions = data.ordering_for ? data.ordering_for.split(',') : [];
              // this.selectsupplier = data.supplier;
              // this.selectCategories = data.deliver_to;
              // this.bdate = data.delivery_date ? new Date(data.delivery_date) : null;
              // this.ordernumber = data.order_number;
              // this.invoicenumber = data.supplier_invoice_number;
              // this.descriptionstring = data.note;
            }
          }
        }),
        switchMap(() => {
          if (!this.setid) {
            return EMPTY; // ❌ Если `setid = 0`, не делаем запрос
          }
          return this.serviceservice.selectProductout(this.getAccountID(), this.setid);
        })
      ).subscribe((productin) => {
        this.iProductinResult = productin.data;
        console.log("!!!!📌 iProductinResult:", this.iProductinResult);

        if (Array.isArray(this.iProductinResult) && this.iProductinResult.length > 0) {
          this.selectedproduct = this.iProductinResult.map((item: any) => ({
            id: item.productid,
            name: item.name,
            code: item.code,
            sku_code: item.sku_code,
            quantity: item.quantity || 1,
            unitPrice: item.unitPrice || 0,
            discountPercentage: item.discountPercentage || 0,
            discountAmount: item.discountAmount || 0,
            totalBeforeDiscount: item.totalBeforeDiscount || item.unitPrice || 0,
            totalAfterDiscount: item.totalAfterDiscount || item.unitPrice || 0,
            imagelink: item.imagelink || "",
            count: 0,
            status: true,
            wholesaleprice: 0,
          }));
          console.log("✅ Заполненный selectedproduct:", this.selectedproduct);
          this.calculateOrderTotal();
          this.getTotalDiscount();
        } else {
          console.warn("⚠️ iProductinResult пуст или не является массивом.");
        }
      });

      if (SelfData.generalsetup.length > 0) {
        this.selecttaxe = SelfData.generalsetup[0].data[0]["defaultsalestax"]
      } else {
        this.selecttaxe = "No Tax (0%)"
      }
      if (this.selecttaxe.trim().toLowerCase() !== this.tselecttaxe.trim().toLowerCase()) {
        this.selecttaxe = this.tselecttaxe
      }
      console.log("selecttaxe", this.selecttaxe, SelfData.generalsetup[0])
    });

  }

  adjustAutocompleteWidth() {
    if (this.searchInput) {
      console.log("this.searchInput.nativeElement.offsetWidth", this.searchInput.nativeElement.offsetWidth + 40)
      this.inputWidth = this.searchInput.nativeElement.offsetWidth + 40;
    }
  }

  onFocus() {
    this.filteredProducts = this.iProductinResult; // ყველა პროდუქტის გამოჩენა
    this.onSearch(this.selectproduct)
    this.searchInput.nativeElement.dispatchEvent(new Event('input')); // ან ეს ხაზი, თუ გსურთ autocomplete-ის გახსნა
  }

  onSearch(value: string): void {
    const searchValue = value.toLowerCase();

    this.filteredProducts = this.iProductinResult.filter(product =>
      product['description'].toLowerCase().includes(searchValue) ||
      product['sku_code'].toLowerCase().includes(searchValue) ||
      product['tags'].toLowerCase().includes(searchValue) ||
      product['brand'].toLowerCase().includes(searchValue) ||
      product['color'].toLowerCase().includes(searchValue)
    );
  }


  getTaxValue(displayValue: string): number {
    const tax = this.iTaxResult.find(t => t['displayvalue'] === displayValue);
    return tax ? tax['taxvalue'] : null; // Возвращает taxvalue или null, если не найдено
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.adjustAutocompleteWidth();
    });
  }

  ngOnInit(): void {
  }

  hasUnavailableProduct(): boolean {
    return this.selectedproduct.some(product => product.status === false);
  }

  onProductPluseClick() {
    if (this.selectproduct.trim() ==="") {
      this.notification.create(
        "error",
        "System Message",
        "You have not selected a product.",
        { nzDuration: 8000 }
      );
      return;
    }

    const checkproduct = this.iProductinResult.find(
      product => product['name'].toLowerCase().trim() === this.selectproduct.toLowerCase().trim()
    );

    if (!checkproduct) {
      this.notification.create(
        "error",
        "System Message",
        "The selected product is not in our database.",
        { nzDuration: 8000 }
      );
      return;
    }

    var customerDiscount: number = 0;
    var wholesale: boolean = false; // Меняем тип на boolean

    if (this.selectcustomer !== "") {
      const selectedCustomer = this.iProductinResult.find(customer => customer['FullName'] === this.selectcustomer);
      if (selectedCustomer) {
        customerDiscount = selectedCustomer['Discount'];
        wholesale = selectedCustomer['Wholesale'] === true; // Убеждаемся, что это boolean
        console.log("Customer discount:", customerDiscount, "Wholesale:", wholesale);
      } else {
        console.log("Customer not found in the list.");
      }
    }

    this.twholesale = wholesale
    if (wholesale) {
      this.selecttaxe = "No Tax (0%)"
    } else {
      if (SelfData.generalsetup.length > 0) {
        this.selecttaxe = SelfData.generalsetup[0].data[0]["defaultsalestax"]
      }
    }

    console.log("iProductinResult", this.iProductinResult)

    // Проверяем, есть ли такой продукт в `iProductsResult`
    const foundProduct = this.iProductinResult.find(
      product => product['name'].toLowerCase().trim() === this.selectproduct.toLowerCase().trim()
    );

    if (!foundProduct) {
      console.log("❌ Продукт не найден.");
      return;
    }

    const tproductid: number = foundProduct["id"];

    // Запрашиваем количество оставшегося товара перед добавлением в корзину
    forkJoin({
      productcount: this.serviceservice.getRemainingProductQuantity(tproductid),
    }).subscribe(({ productcount }) => {
      this.iProductCountResult = productcount.data;
      console.log("📊 Получено количество:", this.iProductCountResult);
      console.log("📊 Получено selectedproduct:", this.selectedproduct);

      // Проверяем, есть ли уже такой продукт в `selectedproduct`
      const existingProduct = this.selectedproduct.find(product => product.id === foundProduct['id']);

      // Определяем, какую цену использовать (оптовую или розничную)
      const unitPriceValue = wholesale ? foundProduct['wholesale_price'] : foundProduct['retail_price'];

      if (existingProduct) {
        // ✅ Если продукт уже в `selectedproduct`, увеличиваем `quantity`
        existingProduct.quantity += 1;
        existingProduct.unitPrice = unitPriceValue; // Обновляем цену, если изменился тип продажи
        existingProduct.totalBeforeDiscount = existingProduct.quantity * existingProduct.unitPrice;
        existingProduct.discountAmount = (existingProduct.totalBeforeDiscount * customerDiscount) / 100; // Вычисляем скидку
        existingProduct.totalAfterDiscount = existingProduct.totalBeforeDiscount - existingProduct.discountAmount;

        console.log("🔄 Количество увеличено:", existingProduct);
      } else {
        // ✅ Если продукта нет в `selectedproduct`, создаем новый объект
        const remainingQuantity = (this.iProductCountResult?.length > 0 && this.iProductCountResult[0]?.['remaining_quantity'] !== undefined)
          ? this.iProductCountResult[0]['remaining_quantity']
          : 0; // Если нет данных, ставим 0

        const quantityValue = remainingQuantity === 0 ? 0 : 1; // Если нет товара, ставим 0, иначе 1
        const statusValue = remainingQuantity !== 0;

        // ✅ Вычисляем скидку
        const totalBeforeDiscount = unitPriceValue * quantityValue;
        const discountAmount = (totalBeforeDiscount * customerDiscount) / 100;
        const totalAfterDiscount = totalBeforeDiscount - discountAmount;

        const newSelectedProduct: Product = {
          id: foundProduct['id'],
          name: foundProduct['name'],
          sku_code: foundProduct['sku_code'],
          code: foundProduct['sku_code'], // Можно использовать SKU как код
          quantity: quantityValue, // ✅ Устанавливаем 0, если товара нет
          unitPrice: unitPriceValue, // ✅ Используем retail_price или wholesaleprice
          wholesaleprice: foundProduct['wholesaleprice'], // ✅ Добавляем wholesalePrice
          discountPercentage: customerDiscount, // ✅ Скидка в процентах
          discountAmount: discountAmount, // ✅ Вычисленная сумма скидки
          totalBeforeDiscount: totalBeforeDiscount, // ✅ Сумма до скидки
          imagelink: foundProduct['imagelink'], // ✅ Изображение
          totalAfterDiscount: totalAfterDiscount, // ✅ Итоговая стоимость после скидки
          count: remainingQuantity, // ✅ Безопасно используем значение
          status: statusValue
        };

        // ✅ Добавляем продукт в массив `selectedproduct`
        this.selectedproduct.push(newSelectedProduct);
        console.log("✅ Продукт добавлен:", newSelectedProduct);
      }

      // ✅ Пересчитываем ORDER TOTAL после добавления продукта
      this.calculateOrderTotal();

      // ✅ Сбрасываем выбранное значение
      this.selectproduct = "";
    }, error => {
      console.error("❌ Ошибка при получении количества товара:", error);
    });
  }

  onCustomerAddClick() {
    this.yourappsService.setSelectedCustomList([], 0)
    this.yourappsService.getReloadCustomList([], 0)
    this.subscription = this.yourappsService.reloadCustomList$.subscribe((value) => {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      if (value.type === 1) {
        forkJoin({
          outlets: this.serviceservice.selectCustomers({accountid: this.getAccountID()}),
        }).subscribe(({ outlets }) => {
          this.iCustomersResult = outlets.data;
          console.log("this.iCustomersResult", this.iCustomersResult)
        });
      }
    })
    this.modalService.customersFrameOpen()
  }

  updateTotalCost(product: Product) {
    // 1. Пересчитываем сумму скидки
    product.discountAmount = ((product.unitPrice * product.discountPercentage) / 100) * product.quantity;

    // 2. Пересчитываем общую сумму без скидки
    product.totalBeforeDiscount = product.quantity * product.unitPrice;

    // 3. Итоговая сумма: ОТНИМАЕМ ТОЛЬКО СКИДКУ
    product.totalAfterDiscount = product.totalBeforeDiscount - product.discountAmount;

    // ✅ Пересчитываем ORDER TOTAL сразу после изменений
    this.calculateOrderTotal();
    this.getTotalDiscount()
  }

  getSubtotal(): number {
    return this.selectedproduct.reduce((sum, product) => sum + product.totalBeforeDiscount, 0);
  }

// ✅ ORDER TOTAL (Сумма после учета скидок + доставка + импортный налог)
  calculateOrderTotal() {
    const productsTotal = this.selectedproduct.reduce((sum, product) => sum + product.totalAfterDiscount, 0);

    // Вычисляем промежуточную сумму без налога
    const subtotal = productsTotal + this.shippingCost + this.importDuty;

    // Получаем налог в процентах
    this.taxvalue = this.getTaxValue(this.selecttaxe);

    // Вычисляем сумму налога от `subtotal`
    this.taxAmount = (this.taxvalue / 100) * subtotal;

    // Финальный итог заказа с учетом налога
    this.orderTotal = subtotal + this.taxAmount;

    console.log(`Subtotal: ${subtotal}, Tax Rate: ${this.taxvalue}%, Tax Amount: ${this.taxAmount}, Order Total: ${this.orderTotal}`);
  }
  // ✅ Метод для вычисления общей суммы скидки (отрицательное значение)
  getTotalDiscount(): number {
    const totalDiscount = this.selectedproduct.reduce((sum, product) => sum + product.discountAmount, 0);
    return totalDiscount > 0 ? totalDiscount : 0; // ✅ Если скидка 0, не делаем отрицательной
  }

  getTaxAmount(): number {
    return this.taxAmount; // Используем уже вычисленный налог
  }

  protected readonly transformImageUrl = transformImageUrl;
  selecttaxe: string = "";
  tselecttaxe: string = "";
  notestring: string = "";
  completesale: boolean = false;

  deleteProduct(product: Product) {
    // Фильтруем массив, оставляя только те продукты, которые не равны удаляемому
    this.selectedproduct = this.selectedproduct.filter(p => p.id !== product.id);
    this.calculateOrderTotal();
    this.getTotalDiscount()
    console.log("❌ Продукт удален:", product);
  }

  processPayment() {
    const hasUnavailable = this.selectedproduct.some(product => product.status === false);

    if (hasUnavailable) {
      console.log("One or more products have a quantity of 0");
      this.notification.create(
        "error",
        "System Message",
        "One or more products have a quantity of 0",
        { nzDuration: 8000 }
      );
      return; // Останавливаем выполнение метода, если есть недоступные товары
    }
    SelfData.totalorder = this.orderTotal
    this.yourappsService.setPayment(0, 0, 0, 0)
    this.subscription = this.yourappsService.payment$.subscribe((value) => {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      if (value.type === 1) {
        this.completesale = true;
        this.paymenttype = value.paymenttype
        this.paymentvalue = value.paymentvalue
        this.changevalue = value.changevalue
        console.log(value)
      }
    })
    this.modalService.srFrameOpen()

    // Дальнейшая логика оплаты...
    console.log("Processing payment...");
  }

  updateCustomerDiscount() {
    // if (!this.selectcustomer) return;

    // Найти выбранного клиента
    const selectedCustomer = this.iCustomersResult.find(customer => customer['FullName'] === this.selectcustomer);

    if (selectedCustomer) {
      const customerDiscount = selectedCustomer['Discount'];
      console.log("selectedCustomer:", selectedCustomer);
      var tprice: number = 0
      // Пройтись по всем товарам и обновить скидку
      const wholesale = selectedCustomer['Wholesale'] === true
      console.log("Customer discount:", customerDiscount, "Wholesale:", wholesale);

      this.twholesale = wholesale
      if (wholesale) {
        this.selecttaxe = "No Tax (0%)"
      } else {
        if (SelfData.generalsetup.length > 0) {
          this.selecttaxe = SelfData.generalsetup[0].data[0]["defaultsalestax"]
        }
      }

      console.log("this.selectedproduct", this.selectedproduct)
      this.selectedproduct.forEach(product => {

        const foundProduct = this.iProductinResult.find(
          tproduct => tproduct['name'].toLowerCase().trim() === product.name.toLowerCase().trim()
        );

        if (!foundProduct) {
          console.log("❌ Продукт не найден.");
          return;
        } else {
          console.log("foundProduct", foundProduct)
        }
        // const foundProduct = this.iProductinResult.find(
        //   setproduct => setproduct['name'].toLowerCase().trim() === product.name.trim()
        // );
        //
        // if (!foundProduct) {
        //   console.log("❌ Продукт не найден.");
        //   return;
        // } else {
        //   console.log("foundProduct", foundProduct)
        // }
        //
        const unitPriceValue = wholesale ? foundProduct['wholesale_price'] : foundProduct['retail_price'];
        //
        console.log("customerDiscount", customerDiscount)
        product.totalBeforeDiscount = unitPriceValue
        product.discountPercentage = customerDiscount;
        product.discountAmount = (unitPriceValue * customerDiscount) / 100;
        product.totalAfterDiscount = product.totalBeforeDiscount - product.discountAmount;

        product.unitPrice = unitPriceValue;
      });

      // Пересчитать итоговую сумму заказа
      this.calculateOrderTotal();
    } else {
      const customerDiscount = 0;
      console.log("Customer discount:", customerDiscount);

      // Пройтись по всем товарам и обновить скидку
      this.selectedproduct.forEach(product => {
        product.discountPercentage = customerDiscount;
        product.discountAmount = ((product.totalBeforeDiscount * customerDiscount) / 100) * product.quantity;
        product.totalAfterDiscount = product.totalBeforeDiscount - product.discountAmount;
      });

      // Пересчитать итоговую сумму заказа
      this.calculateOrderTotal();
      console.log("Customer not found in the list.");
    }
  }

  processCompletePayment() {
    let tid: number = this.setid;
    let tctrl: number = this.opermode;
    let topenclose: number = this.openclose;
    let customerid: number = 0
    if (this.selectcustomer !== "") {
      const selectedCustomer = this.iCustomersResult.find(customer => customer['FullName'] === this.selectcustomer);
      if (selectedCustomer) {
        customerid = selectedCustomer['ID'];
        console.log("Customer discount:", customerid);
      } else {
        console.log("Customer not found in the list.");
      }
    }

    if (SelfData.returnorder) {
      this.selectedproduct.forEach(product => {
        const newProduct: PrcProductsOutSaleRequest = {
          tbl_order_sale_id: 0,
          accountid: 0,
          name: "",
          code: "",
          sku_code: "",
          quantity: 0,
          unitPrice: 0,
          discountPercentage: 0,
          discountAmount: 0,
          totalBeforeDiscount: 0,
          totalAfterDiscount: 0,
          imagelink: "",
          ctrl: 2,
          productid: 0,
          salaroid: 0,
          customerid: 0,
          orderstatus: 0,
          id: product.id
        };
        this.serviceservice.prcProductsOutSale(newProduct).subscribe({
          next: (response: PrcProductsInSaleResponse) => {
            console.log(`✅ Продукт ${product.name} добавлен в заказ ${product.id}:`, response);
          },
          error: (error: any) => {
            console.error(`❌ Ошибка при добавлении ${product.name}:`, error);
          },
          complete: () => {
            console.log(`✅ Запрос для ${product.name} успешно выполнен!`);
          }
        });
        this.modalService.ssFrameClose()
        this.notification.create(
          "success",
          'System Message',
          'Data saved successfully.',
          {nzDuration: 4000}
        );
        this.yourappsService.getReloadOrderOutList([], 1)

      })
      return
    }


    const customerDetails: OrderDetails = {
      id: tid, // ID клиента
      outlet: 1,
      openclose: topenclose,
      customer: this.selectcustomer,
      customerid: customerid,
      subtotalofproducts: this.getSubtotal(),
      totaldiscount: this.getTotalDiscount(),
      shipingcost: this.shippingCost,
      salestax: this.getTaxValue(this.selecttaxe),
      saletaxdisplay: this.selecttaxe,
      ordertotal: this.orderTotal,
      paymenttype: this.paymenttype,
      paymentdisplay: this.paymenttype === 1 ? 'Cash' : 'Other Method',
      change: this.changevalue,
      note: this.notestring,
      accountid: this.getAccountID(),
      isactivity: true,
      paymentstatus: 1,
      ctrl: tctrl
    };
    this.serviceservice.orderSaleOut(customerDetails).subscribe({
      next: (value: CustomerResponse) => {
        let orderid = value.data
        const newProduct: PrcProductsOutSaleRequest = {
          tbl_order_sale_id: value.data,
          accountid: 0,
          name: "",
          code: "",
          sku_code: "",
          quantity: 0,
          unitPrice: 0,
          discountPercentage: 0,
          discountAmount: 0,
          totalBeforeDiscount: 0,
          totalAfterDiscount: 0,
          imagelink: "",
          ctrl: 1,
          productid: 0,
          salaroid: 0,
          customerid: 0,
          orderstatus: 0
        };
        this.serviceservice.prcProductsOutSale(newProduct).subscribe({
          next: (response: PrcProductsInSaleResponse) => {
            console.log("✅ Ответ от сервера:", response);
            this.selectedproduct.forEach(product => {
              const newProduct: PrcProductsOutSaleRequest = {
                tbl_order_sale_id: orderid,
                accountid: this.getAccountID(), // Используем актуальный `accountid`
                name: product.name,
                code: product.code,
                sku_code: product.sku_code,
                quantity: product.quantity,
                unitPrice: product.unitPrice,
                discountPercentage: product.discountPercentage,
                discountAmount: product.discountAmount,
                totalBeforeDiscount: product.totalBeforeDiscount,
                totalAfterDiscount: product.totalAfterDiscount,
                imagelink: product.imagelink,
                ctrl: 0, // ✅ 0 = Вставка нового продукта
                productid: product.id,
                salaroid: topenclose,
                customerid: customerid,
                orderstatus: 1
              };

              // ✅ Отправляем продукт в `prcProductsInSale`
              this.serviceservice.prcProductsOutSale(newProduct).subscribe({
                next: (response: PrcProductsInSaleResponse) => {
                  console.log(`✅ Продукт ${product.name} добавлен в заказ ${orderid}:`, response);
                },
                error: (error: any) => {
                  console.error(`❌ Ошибка при добавлении ${product.name}:`, error);
                },
                complete: () => {
                  console.log(`✅ Запрос для ${product.name} успешно выполнен!`);
                }
              });
            });
          },
          error: (error: any) => {
            console.error("❌ Ошибка:", error);
          },
          complete: () => {
            console.log("✅ Запрос успешно выполнен!");
          }
        });
        this.modalService.ssFrameClose()
        this.notification.create(
          "success",
          'System Message',
          'Data saved successfully.',
          {nzDuration: 4000}
        );
        this.yourappsService.getReloadOrderOutList([], 1)
        console.log(value)
      },
      error: (error: any) => {
        console.error('Error occurred:', error);
      },
      complete: () => {
        console.log('Subscription completed');
      }
    })
  }

  protected readonly SelfData = SelfData;
  completesalestring: string = "";
  // isDropdownOpen: boolean = false;
}
