<div class="top-div">
  <div class="left-div"></div>
  <div class="right-div">
<!--    <div class="profile-container">-->
<!--      <span class="profile-text">{{ accountname }}</span>-->
<!--      <button mat-icon-button class="profile-btn" [matMenuTriggerFor]="menu">-->
<!--        <mat-icon *ngIf="!imageUrl">account_circle</mat-icon>-->
<!--        <img-->
<!--          *ngIf="imageUrl"-->
<!--          class="account-image"-->
<!--          [src]="imageUrl"-->
<!--          alt="Avatar"-->
<!--        />-->
<!--      </button>-->
<!--    </div>-->
<!--    <mat-menu #menu="matMenu">-->
<!--      <button *ngIf="!auth" mat-menu-item (click)="modalService.open(1)">-->
<!--        <mat-icon>login</mat-icon>-->
<!--        <span>Log in</span>-->
<!--      </button>-->
<!--      <button-->
<!--        *ngIf="auth"-->
<!--        mat-menu-item-->
<!--        (click)="modalService.profileopen()"-->
<!--        class="profile-button"-->
<!--      >-->
<!--        <div class="icon-container">-->
<!--          <mat-icon *ngIf="!imageUrl">account_circle</mat-icon>-->
<!--          <img-->
<!--            *ngIf="imageUrl"-->
<!--            class="account-image1"-->
<!--            [src]="imageUrl"-->
<!--            alt="Avatar"-->
<!--          />-->
<!--        </div>-->
<!--        <span class="text-container">Profile</span>-->
<!--      </button>-->
<!--      <mat-divider *ngIf="auth"></mat-divider>-->
<!--      <button *ngIf="(modalService.iRPMonitoring$ | async) && auth" mat-menu-item>-->
<!--        <mat-icon class="icon-spacing icon-scale">monitoring</mat-icon>-->
<!--        {{ rpmonitoring }}-->
<!--      </button>-->
<!--      <button *ngIf="(modalService.isSamples$ | async) && auth" mat-menu-item>-->
<!--        <mat-icon class="icon-spacing icon-scale">checkroom-->
<!--        </mat-icon>-->
<!--        {{ samples }}-->
<!--      </button>-->
<!--      &lt;!&ndash;      <button *ngIf="(modalService.iContacts$ | async) && auth" mat-menu-item>&ndash;&gt;-->
<!--      &lt;!&ndash;        <mat-icon class="icon-spacing icon-scale">image</mat-icon>{{ contacts }}&ndash;&gt;-->
<!--      &lt;!&ndash;      </button>&ndash;&gt;-->
<!--      <button *ngIf="(modalService.iEvents$ | async) && auth" mat-menu-item (click)="onNotificationsClick()">-->
<!--        <mat-icon class="icon-spacing icon-scale">event</mat-icon>-->
<!--        {{ events }}-->
<!--      </button>-->
<!--      &lt;!&ndash;      <button&ndash;&gt;-->
<!--      &lt;!&ndash;        *ngIf="auth"&ndash;&gt;-->
<!--      &lt;!&ndash;        mat-menu-item&ndash;&gt;-->
<!--      &lt;!&ndash;        (click)="modalService.createSettings()"&ndash;&gt;-->
<!--      &lt;!&ndash;      >&ndash;&gt;-->
<!--      &lt;!&ndash;        <mat-icon>person</mat-icon>&ndash;&gt;-->
<!--      &lt;!&ndash;        <span>Contacts</span>&ndash;&gt;-->
<!--      &lt;!&ndash;      </button>&ndash;&gt;-->
<!--      <mat-divider *ngIf="auth"></mat-divider>-->
<!--      <button *ngIf="auth" mat-menu-item (click)="onNotificationsClick()">-->
<!--        <mat-icon>notifications</mat-icon>-->
<!--        <span>Notifications</span>-->
<!--      </button>-->
<!--      <mat-divider></mat-divider>-->
<!--      <button mat-menu-item (click)="onHelpClick()">-->
<!--        <mat-icon>help</mat-icon>-->
<!--        <span>Help</span>-->
<!--      </button>-->
<!--      <mat-divider *ngIf="auth"></mat-divider>-->
<!--      &lt;!&ndash;      <button&ndash;&gt;-->
<!--      &lt;!&ndash;        *ngIf="auth"&ndash;&gt;-->
<!--      &lt;!&ndash;        mat-menu-item&ndash;&gt;-->
<!--      &lt;!&ndash;        (click)="modalService.createSettings()"&ndash;&gt;-->
<!--      &lt;!&ndash;      >&ndash;&gt;-->
<!--      &lt;!&ndash;        <mat-icon>settings</mat-icon>&ndash;&gt;-->
<!--      &lt;!&ndash;        <span>Settings</span>&ndash;&gt;-->
<!--      &lt;!&ndash;      </button>&ndash;&gt;-->
<!--      <mat-divider *ngIf="auth"></mat-divider>-->
<!--      <button *ngIf="auth" mat-menu-item (click)="logout()">-->
<!--        <mat-icon>logout</mat-icon>-->
<!--        <span>Log out</span>-->
<!--      </button>-->
<!--    </mat-menu>-->
  </div>
  <div class="center-logo">
    <img class="logoimage" src="assets/256 FM.png" alt="Logo"/>
    <span class="logo-text">{{ title | uppercase }}</span>
  </div>
</div>
