<button mat-icon-button type="button" class="close-btn" (click)="onButtonCloseClick()">
    <mat-icon>close</mat-icon>
</button>
<p class="sample-manager-text">{{formcaption}}</p>

<div class="row">
    <div class="forma-group">
        <mat-form-field class="selector-full-width" appearance="fill">
            <mat-label>Description</mat-label>
            <input type="text" matInput [formControl]="collectionFormControl">
            <mat-error *ngIf="collectionFormControl.hasError('required')">
                Description is <strong>required</strong>
            </mat-error>
        </mat-form-field>
    </div>

    <div class="forma-group">
    </div>
</div>
<div class="updatebutton">
    <button class="btnupdatte" mat-raised-button type="button" [disabled]="!uploadbuttonenable" (click)="onButtonSelectAllClick()">
        Select All
        <mat-icon>checklist</mat-icon>
    </button>
    <div class="progressbar">
        <smart-progress-bar theme="fm" *ngIf="progressbarshow" #progressbar id="progressbar1" class="progressbar"
                [showProgressValue]="true"></smart-progress-bar>
    </div>
</div>
<!--<div *ngFor="let imageUrl of imageUrls">-->
<!--  <img [src]="imageUrl" alt="Extracted Image">-->
<!--  <a href="{{ imageUrl }}" download>Download Image</a>-->
<!--</div>-->
<div class="row1">
    <div class="forma-group1">
        <!--    <div cdkDropListGroup class="row1">-->
        <!--      <div cdkDropList class="example-list"-->
        <!--        #todoList="cdkDropList"-->
        <!--        [cdkDropListData]=" ilDesignerListResult[0].data || []"-->
        <!--        [cdkDropListConnectedTo]="[doneList]"-->
        <!--        (cdkDropListDropped)="drop($event)">-->
        <!--        <div class="example-box" *ngFor="let item of ilDesignerListResult[0]?.data || []" cdkDrag>-->
        <!--          {{ item.designer }}-->
        <!--        </div>-->
        <!--      </div>-->
        <!--      <div cdkDropList class="example-list"-->
        <!--        #doneList="cdkDropList"-->
        <!--        [cdkDropListData]="iTOResult[0].data || []"-->
        <!--        [cdkDropListConnectedTo]="[todoList]"-->
        <!--        (cdkDropListDropped)="drop($event)">-->
        <!--        <div class="example-box" *ngFor="let item of iTOResult[0]?.data || []" cdkDrag>-->
        <!--          {{ item.designer }}-->
        <!--        </div>-->
        <!--      </div>-->
        <!--    </div>-->

        <div class="row1">
            <div cdkDropList [cdkDropListData]="ilDesignerListResult[0].data" class="example-list"
                    (cdkDropListDropped)="drop($event)">
                <div class="full-width-caption-look">
                    <p class="caption-text-look">Designers List ({{ilDesignerListResult[0].data.length}})</p>
                </div>
                <div class="example-box" *ngFor="let item of ilDesignerListResult[0].data || []" cdkDrag>
                    {{ item.designer }}
                    <span class="p-inputgroup-addon lb-hover" matRipple
                            [class.inactive-span]="isItemFoundInITOrResult(item)"
                            (click)="!isItemFoundInITOrResult(item) && onButtonSelectClick(item)">
                        Select
                    </span>
                </div>
            </div>

            <div cdkDropList [cdkDropListData]="iTOResult[0].data" class="example-list"
                    (cdkDropListDropped)="drop($event)">
                <div class="full-width-caption-look">
                    <p class="caption-text-look">Selected Designers ({{iTOResult[0].data.length}})</p>
                </div>
                <div class="example-box" *ngFor="let item of iTOResult[0].data || [] let i = index" cdkDrag
                        [class.selected-item]="selectedItem === item"
                        (click)="onitemclick(item)">
                    {{ item.designer }}
                    <span class="p-inputgroup-addon lb-hover" matRipple
                            (click)="onRemoveClick(item, i)">
                        Remove</span>
                </div>
            </div>
        </div>
    </div>

    <div class="forma-group1">
        <div class="row1">
            <div cdkDropList [cdkDropListData]="iSeasonFromResult[0].data" class="example-list"
                    (cdkDropListDropped)="dropseason($event)">
                <div class="full-width-caption-look">
                    <p class="caption-text-look">Catalogs List ({{iSeasonFromResult[0].data.length}})</p>
                </div>
                <div class="example-box1" *ngFor="let item of iSeasonFromResult[0].data || []" cdkDrag>
                    <div class="items">
                        <span class="designer-name">{{ item.description }}</span>
                        <span class="designer-name">{{ item.seasoncode }}</span>
                        <span class="designer-name">{{ item.seasonyear }}</span>
                    </div>
                    <span class="p-inputgroup-addon lb-hover" matRipple
                            [class.inactive-span]="isItemFoundIniSeasonToResult(item)"
                            (click)="!isItemFoundIniSeasonToResult(item) && onButtonSelectCatalogClick(item)">
                        Select
                    </span>
                </div>
            </div>

            <div cdkDropList [cdkDropListData]="iSeasonToResult[0].data" class="example-list"
                    (cdkDropListDropped)="dropseason($event)">
                <div class="full-width-caption-look">
                    <p class="caption-text-look">Selected Catalogs ({{iSeasonToResult[0].data.length}})</p>
                </div>
                <div class="example-box1" *ngFor="let item of iSeasonToResult[0].data || []; let i = index" cdkDrag
                        (click)="onclick(item, i)">
                    <div class="items">
                        <span class="designer-name">{{ item.description }}</span>
                        <span class="designer-name">{{ item.seasoncode }}</span>
                        <span class="designer-name">{{ item.seasonyear }}</span>
                    </div>
                    <span class="p-inputgroup-addon lb-hover" matRipple (click)="onCatalogRemoveClick(item, i)">
                        Remove</span>
                </div>
            </div>
        </div>
    </div>

</div>

<div class="button-container">
    <button mat-raised-button class="button-settings" type="button" (click)="onButtonUploadClick()">
        Confirm
    </button>
</div>


<p-confirmDialog #cd [style]="{width: '25vw'}" [closable]="true">
    <ng-template pTemplate="footer">
        <div class="button-container-rr">
            <button mat-raised-button class="button-secondary" style="margin-right: 10px; width: 10%"
                    (click)="cd.reject()">
                No
            </button>
            <button mat-raised-button class="button-primary" style="width: 10%" (click)="cd.accept()">
                Yes
            </button>
        </div>
    </ng-template>
</p-confirmDialog>
