import {AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {SelfData, TagsListData, transformImageUrl} from "../../models/struct";
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder} from "@angular/forms";
import {HashService} from "../../services/hash.service";
import {MessageboxComponent} from "../../modals/messagebox/messagebox.component";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {MatSnackBar} from "@angular/material/snack-bar";
import {HttpClient} from "@angular/common/http";
import {forkJoin, Subscription} from "rxjs";
import {Table} from "primeng/table";

@Component({
  selector: 'app-profilelistdialog',
  templateUrl: './profilelistdialog.component.html',
  styleUrl: './profilelistdialog.component.scss'
})
export class ProfilelistdialogComponent implements OnInit, AfterViewInit {
  caption: string = "Profiles List"
  setid: number = 0;
  opermode: number = 0;
  profilesResult: TagsListData[] = []
  selectProfile: string = "";
  sizes!: any[];
  @ViewChild(Table) dataTable?: Table;
  selectedSize: any = ''

  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
      ? SelfData.account[0].data[0].id
      : SelfData.account[0].data[0].parentid;
  }

  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private hashService: HashService,
    public messageBox: MessageboxComponent,
    private serviceservice: ServiceService,
    private yourappsService: YourappsserviceService,
    private notification: NzNotificationService,
    private _snackBar: MatSnackBar,
    private http: HttpClient,
    private cdr: ChangeDetectorRef
  ) {
    this.sizes = [{name: "Small", class: "p-datatable-sm"}];
    this.selectedSize = this.sizes[0];
    setTimeout(() => {
      SelfData.spinnerpossition = true
    });
    forkJoin({
      profiles: this.serviceservice.selectProfiles(this.getAccountID()),
    }).subscribe(({profiles}) => {
      this.profilesResult = profiles.data;
      setTimeout(() => {
        SelfData.spinnerpossition = false
      });
      console.log("this.profilesResult", this.profilesResult)
    });
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
  }

  protected readonly transformImageUrl = transformImageUrl;

  deleteProfile(product: any) {

  }

  editProfile(product: any) {
    this.yourappsService.setSelectedProfileList(product, 1)
    this.yourappsService.getReloadProfileList([], 0)
    this.subscription = this.yourappsService.reloadProfileList$.subscribe((value) => {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      if (value.type === 1) {
        forkJoin({
          profiles: this.serviceservice.selectProfiles(this.getAccountID()),
        }).subscribe(({profiles}) => {
          this.profilesResult = profiles.data;
          setTimeout(() => {
            SelfData.spinnerpossition = false
          });
          console.log("this.profilesResult", this.profilesResult)
        });
      }
    })
    this.modalService.profileframeOpen()
  }
  private subscription?: Subscription
  onAddProfileClick() {
    this.yourappsService.setSelectedProfileList([], 0)
    this.yourappsService.getReloadProfileList([], 0)
    this.subscription = this.yourappsService.reloadProfileList$.subscribe((value) => {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      if (value.type === 1) {
        forkJoin({
          profiles: this.serviceservice.selectProfiles(this.getAccountID()),
        }).subscribe(({profiles}) => {
          this.profilesResult = profiles.data;
          setTimeout(() => {
            SelfData.spinnerpossition = false
          });
          console.log("this.profilesResult", this.profilesResult)
        });
      }
    })
    this.modalService.profileframeOpen()
  }

  onCloseButtonClick() {
    this.yourappsService.getReloadProfileList([], 1)
    this.modalService.profilelistClose()
  }
}
