import { Component } from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";

@Component({
  selector: 'app-testprinter',
  templateUrl: './testprinter.component.html',
  styleUrl: './testprinter.component.scss'
})
export class TestprinterComponent {
  constructor(public modalService: ModalserviceService) {}
}
