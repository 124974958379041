<div class="card">
  <p class="sample-manager-customers">Suppliers</p>
  <div class="black-background-blt">
    <div class="example-container-blt">
      <div class="headerdiv1">
        <div class="headerdiv">
          <!--          <p-button label="Add outlet" icon="pi pi-plus" [rounded]="false" [outlined]="true" />-->
          <button nz-button nzType="default" (click)="onSupplierClick()">Add supplier</button>

          <nz-input-group class="filterclass" [nzPrefix]="suffixTemplateInfo" [nzAddOnAfter]="suffixIconButton" >
            <input type="text" (input)="onInput($event)" [(ngModel)]="filtertext" nz-input placeholder="Filter" />
          </nz-input-group>
          <ng-template #suffixTemplateInfo>
            <span nz-icon nzType="search"></span>
          </ng-template>
          <ng-template #suffixIconButton>
            <button nz-button nzSearch><span nz-icon nzType="close" (click)="clear(dt1)"></span></button>
          </ng-template>
        </div>
      </div>

      <div class="griddiv">
        <p-table
          #dt1
          [value]=iSupplierResult
          [paginator]="true"
          [rows]="10"
          [showCurrentPageReport]="true"
          selectionMode="single"
          rowGroupMode="subheader"
          groupRowsBy="designer"
          (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          [rowsPerPageOptions]="[10, 20, 50, 100]"
          [styleClass]="'p-datatable-striped mat-elevation-z1 ' + selectedSize.class.toString()"
          [globalFilterFields]="['CompanyName', 'Description', 'Email', 'Facebook', 'FirstName', 'Instagram', 'LastName',
          'MailingCity', 'MailingCountry', 'MailingState', 'MailingStreetAddress', 'MailingStreetAddress2', 'MailingSuburb',
          'MailingZipCode', 'Mobile', 'Phone', 'StoreCity', 'StoreCountry', 'StoreState', 'StoreStreetAddress', 'StoreStreetAddress2',
          'StoreSuburb', 'StoreZipCode', 'SupplierName', 'TikTok', 'Twitter', 'Website', 'YouTube']"
          [tableStyle]="{'min-width': '50rem'}">
          <ng-template pTemplate="header">
            <tr>
              <th style="width:20%">Supplier Name</th>
              <th style="width:20%">Email</th>
              <th style="width:50%">Description</th>
              <th style="width:8.4%">Actions</th>  <!-- Добавлен заголовок для кнопки -->
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-product>
            <tr [pSelectableRow]="product">
              <td>{{ product["SupplierName"] }}</td>
              <td>{{ product["Email"] }}</td>
              <td>{{ product["Description"] }}</td>
              <td>
                <!-- Кнопка Edit -->
                <!--                <button pButton type="button" icon="pi pi-pencil" class="p-button-rounded p-button-text p-button-primary" (click)="editOutlet(product)"></button>-->
                <button nz-button nzType="text" nzSize = "small" nzShape="circle" (click)="viewCustomer(product)">
                  <span nz-icon [nzType]="'eye'" [nzTheme]="'fill'"></span>
                </button>
                <button nz-button nzType="text" nzSize = "small" nzShape="circle" (click)="editSupplier(product)">
                  <span nz-icon [nzType]="'edit'" [nzTheme]="'fill'"></span>
                </button>
                <button nz-button nzType="text" nzSize="small" nzShape="circle" (click)="deleteSupplier(product)">
                  <span nz-icon [nzType]="'delete'" [nzTheme]="'fill'" style="color: red;"></span>
                </button>
              </td>
            </tr>
          </ng-template>
        </p-table>
        <div class="fullline">

        </div>
        <div *ngIf="SelfData.fullPage" class="nextpage">
          <app-sendoutstep1></app-sendoutstep1>
        </div>
      </div>

    </div>
  </div>
</div>

<nz-dropdown-menu #menu4="nzDropdownMenu">
  <ul nz-menu>
    <!--    <li nz-menu-item *ngIf="currentSample?.['isok'] == 0" (click)="onButtonReturnScanClick(currentSample)">Return & Scan</li>-->
    <!--    <li nz-menu-item (click)="onViewButtonClick(currentSample)">View Details</li>-->
    <!--    <li nz-menu-item (click)="onButtonDesignerClick()">Choose more samples from this designer.</li>-->
    <!--    <li nz-menu-item *ngIf="SelfData.sendoutmode === 0" (click)="onPrintBarcodeClick(currentSample?.['barcode'])">Print Barcode</li>-->
    <!--    <li nz-menu-item (click)="removeSelectedSample(currentSample?.['barcode'])">Remove Sample</li>-->
    <!--    &lt;!&ndash;    <li nz-menu-item *ngIf="currentSample?.['itemstatus'] == 0" (click)="restoreSelectedSample(currentSample?.['barcode'])">Restore Sample</li>&ndash;&gt;-->
    <!--    <li nz-menu-item *ngIf="currentSample?.['requested count'] > 1" (click)="onSameRequestClick(currentSample)">Same Request</li>-->
  </ul>
</nz-dropdown-menu>
