<button mat-icon-button type="button" class="close-btn" (click)="onButtonCloseClick()">
  <mat-icon>close</mat-icon>
</button>
<p class="sample-manager-text">Same Requests</p>


<img class="selectimage shadow-4" [src]="imageurl ? imageurl : 'default-image-url'" alt="Selected Image">
<div class="tablediv">
  <p-table
    [value]="datasource"
    [paginator]="true"
    [rows]="10"
    [showCurrentPageReport]="true"
    [tableStyle]="{ 'width': '100%'}"
    [scrollable]="true"
    [scrollHeight]="'350px'"
    [styleClass]="'p-datatable-gridlines p-datatable-sm'"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    selectionMode="single"
    [rowsPerPageOptions]="[10, 25, 50, 100]">

    <ng-template pTemplate="header">
      <tr>
        <th style="width:15%">Email</th>
        <th style="width:15%">Name</th>
        <th style="width:25%">Send Date</th>
        <!--          <th style="width:10%">Designer</th>-->
        <th style="width:1%"></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-catalog>
      <tr [pSelectableRow]="catalog">
        <td>{{ catalog.email }}</td>
        <td>{{ catalog.name }}</td>
        <td>{{ formatDate(catalog.operdate.toString()) }}</td>

        <td>
          <div class="optionbuttons">

            <p-button label="Accept" size="small" [text]="true" severity="success"
              (click)="onButtonAcceptClick(catalog)">
            </p-button>

          </div>

        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<p-confirmDialog #cd [style]="{width: '25vw'}" [closable]="true">
  <ng-template pTemplate="footer">
    <div class="button-container-request">
      <button mat-raised-button class="button-secondary" style="margin-right: 10px; width: 50%" (click)="cd.reject()">
        No
      </button>
      <button mat-raised-button class="button-primary" style="margin-right: 0; width: 50%" (click)="cd.accept()">
        Yes
      </button>
    </div>
  </ng-template>
</p-confirmDialog>
