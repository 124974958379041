import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {Table} from "primeng/table";
import {SelfData, TagsListData} from "../../models/struct";
import {ModalserviceService} from "../../services/modalservice.service";
import {HashService} from "../../services/hash.service";
import {MessageboxComponent} from "../../modals/messagebox/messagebox.component";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {HttpClient} from "@angular/common/http";
import {forkJoin} from "rxjs";

@Component({
  selector: 'app-userslist',
  templateUrl: './userslist.component.html',
  styleUrl: './userslist.component.scss'
})
export class UserslistComponent implements OnInit {
  @ViewChild('dt1') dt1!: Table;
  filtertext: string = ""
  sizes!: any[];
  @ViewChild(Table) dataTable?: Table;
  selectedSize: any = ''
  iSellAccountResult: TagsListData[] = []

  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
      ? SelfData.account[0].data[0].id
      : SelfData.account[0].data[0].parentid;
  }

  constructor(public modalService: ModalserviceService,
              private hashService: HashService,
              public messageBox: MessageboxComponent,
              private serviceservice: ServiceService,
              private yourappsService: YourappsserviceService,
              private _snackBar: MatSnackBar,
              private http: HttpClient,
              private cdr: ChangeDetectorRef) {
    this.sizes = [
      {name: 'Small', class: 'p-datatable-sm'}
    ];
    this.selectedSize = this.sizes[0]
    setTimeout(() => {
      SelfData.spinnerpossition = true
    });
    forkJoin({
      sellaccount: this.serviceservice.selectSaleAccounts(this.getAccountID()),
    }).subscribe(({sellaccount}) => {
      this.iSellAccountResult = sellaccount.data;
      console.log("this.iOrderOutResult", this.iSellAccountResult)
      setTimeout(() => {
        SelfData.spinnerpossition = false
      });
    });
  }

  ngOnInit(): void {
  }

  editUser(product: any) {

  }

  deleteUser(product: any) {

  }

  onAddUserClick() {
    this.modalService.userOpen()
  }

  onInput(event: any) {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;
    this.dt1.filterGlobal(value, 'contains');
  }

  clear(table: Table) {
    table.clear();
    this.filtertext = ""
  }

  protected readonly SelfData = SelfData;
}
