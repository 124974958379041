import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import {HashService} from "../../services/hash.service";
import {MessageboxComponent} from "../../modals/messagebox/messagebox.component";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Subscription} from "rxjs";


@Component({
  selector: 'app-editordialog',
  templateUrl: './editordialog.component.html',
  styleUrls: ['./editordialog.component.scss']
})
export class EditordialogComponent implements OnInit, AfterViewInit {
  myForm: FormGroup = new FormGroup({});
  text: string = "";
  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private hashService: HashService,
    public messageBox: MessageboxComponent,
    private serviceservice: ServiceService,
    private yourappsService: YourappsserviceService,
    private _snackBar: MatSnackBar
  ) {}
  ngAfterViewInit(): void {}
  private subscription?: Subscription;
  ngOnInit(): void {
    this.subscription = this.yourappsService.getFooterText$.subscribe((value: string) => {
      // if (this.subscription) {
        if (value != '') {
          this.text = value
        }
      // }
      this.subscription?.unsubscribe()
    });
  }

  submit() {
    this.modalService.closeEditor()
      this.yourappsService.setFooterText(this.text)
    // console.log(this.text)
  }

}
