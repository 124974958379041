<button
  mat-icon-button
  class="close-btn"
  (click)="modalService.registrationclose()"
>
  <mat-icon>close</mat-icon>
</button>

<div class="form-container">
  <table class="name-fields">
    <tr>
      <td>
        <div class="example-full-width">
          <label class="line1-edits-new inputlabel">First Name</label>
          <nz-input-group [nzStatus]="statusfirstname" nzSize="default" class="" [nzSuffix]="suffixIconSearch1">
            <input (focus)="onBlurAll()" type="text" [(ngModel)]="firstname" nz-input/>
          </nz-input-group>
          <ng-template #suffixIconSearch1>
          </ng-template>
          <!--          <mat-form-field class="example-full-width" appearance="fill">-->
          <!--            <mat-label>First name</mat-label>-->
          <!--            <input matInput [formControl]="firstNameFormControl" required/>-->
          <!--            <mat-error *ngIf="firstNameFormControl.hasError('required')">-->
          <!--              First name is <strong>required</strong>-->
          <!--            </mat-error>-->
          <!--          </mat-form-field>-->
        </div>
      </td>
      <td>
        <div class="example-full-width">
          <label class="line1-edits-new inputlabel">Last Name</label>
          <nz-input-group [nzStatus]="statuslastname" nzSize="default" class="" [nzSuffix]="suffixIconSearch1">
            <input (focus)="onBlurAll()" type="text" [(ngModel)]="lastname" nz-input/>
          </nz-input-group>
          <ng-template #suffixIconSearch1>
          </ng-template>
        </div>
        <!--          <mat-form-field class="example-full-width" appearance="fill">-->
        <!--            <mat-label>Last Name</mat-label>-->
        <!--            <input matInput [formControl]="lastNameFormControl"/>-->
        <!--            <mat-error *ngIf="lastNameFormControl.hasError('required')">-->
        <!--              Last name is <strong>required</strong>-->
        <!--            </mat-error>-->
        <!--          </mat-form-field>-->
      </td>
    </tr>
    <tr>
      <td colspan="2">
        <div class="example-full-width">
          <label class="line1-edits-new inputlabel">E-Mail</label>
          <nz-input-group [nzStatus]="statusemail" nzSize="default" type=""
            class="p-float-label line1-edits-new inputedit" [nzSuffix]="suffixIconSearch1">
            <input (focus)="onBlurAll()" type="email" [(ngModel)]="email" nz-input/>
          </nz-input-group>
          <ng-template #suffixIconSearch1>
          </ng-template>
        </div>
        <!--          <mat-form-field class="example-full-width" appearance="fill">-->
        <!--            <mat-label>Working Email</mat-label>-->
        <!--            <input type="email" matInput [formControl]="emailFormControl" placeholder="Enter your Email"/>-->
        <!--            <mat-error-->
        <!--              *ngIf=" emailFormControl.hasError('email') && !emailFormControl.hasError('required')">-->
        <!--              Please enter a valid email address-->
        <!--            </mat-error>-->
        <!--            <mat-error *ngIf="emailFormControl.hasError('required')">-->
        <!--              Email is <strong>required</strong>-->
        <!--            </mat-error>-->
        <!--          </mat-form-field>-->
      </td>
    </tr>
    <tr>
      <td>
        <div class="example-full-width">
          <label class="line1-edits-new inputlabel">Job Title</label>
          <nz-input-group [nzStatus]="statusjobtitle" nzSearch [nzAddOnAfter]="suffixIconSearch1">
            <nz-select (focus)="onBlurAll()" nzShowSearch [nzShowArrow]="true" class="line1-edits-new inputedit testdiv"
              [(ngModel)]="jobtitle">
              <nz-option *ngFor="let option of iJobList" [nzLabel]="option.Description"
                [nzValue]="option.Description"></nz-option>
            </nz-select>
          </nz-input-group>
          <ng-template #suffixIconSearch1>
          </ng-template>
        </div>
        <!--        <mat-form-field class="example-full-width" appearance="fill">-->
        <!--          <mat-label>Job Title</mat-label>-->
        <!--          <mat-select [formControl]="jobControl" name="Jobs" required>-->
        <!--            <mat-option>None</mat-option>-->
        <!--            <mat-option-->
        <!--              *ngFor="let job of iJobList"-->
        <!--              [value]="job.Description"-->
        <!--            >-->
        <!--              {{ job.Description }}-->
        <!--            </mat-option>-->
        <!--          </mat-select>-->
        <!--          <mat-error *ngIf="jobControl.hasError('required')">-->
        <!--            Job title is <strong>required</strong>-->
        <!--          </mat-error>-->
        <!--        </mat-form-field>-->
      </td>
      <td>

        <div class="example-full-width">
          <label class="line1-edits-new inputlabel">Industry</label>
          <nz-input-group [nzStatus]="statusindustry" nzSearch [nzAddOnAfter]="suffixIconSearch1">
            <nz-select (focus)="onBlurAll()" nzShowSearch [nzShowArrow]="true" class="line1-edits-new inputedit testdiv"
              [(ngModel)]="industry">
              <nz-option *ngFor="let option of iIndustry" [nzLabel]="option.Description"
                [nzValue]="option.Description"></nz-option>
            </nz-select>
          </nz-input-group>
          <ng-template #suffixIconSearch1>
          </ng-template>
        </div>
        <!--        <mat-form-field class="example-full-width" appearance="fill">-->
        <!--          <mat-label>Industry</mat-label>-->
        <!--          <mat-select-->
        <!--            [formControl]="industryControl"-->
        <!--            name="Industry"-->
        <!--            required-->
        <!--          >-->
        <!--            <mat-option>None</mat-option>-->
        <!--            <mat-option-->
        <!--              *ngFor="let industry of iIndustry"-->
        <!--              [value]="industry.Description"-->
        <!--            >-->
        <!--              {{ industry.Description }}-->
        <!--            </mat-option>-->
        <!--          </mat-select>-->
        <!--          <mat-error *ngIf="industryControl.hasError('required')">-->
        <!--            Industry is <strong>required</strong>-->
        <!--          </mat-error>-->
        <!--        </mat-form-field>-->
      </td>
    </tr>
    <tr>
      <td>
        <div class="example-full-width">
          <label class="line1-edits-new inputlabel">Address</label>
          <nz-input-group [nzStatus]="statusaddress" nzSearch [nzAddOnAfter]="suffixIconButton">
            <input (focus)="onBlurAll()" readonly="readonly" type="text" [(ngModel)]="address" nz-input/>
          </nz-input-group>
          <ng-template #suffixIconButton>
            <button (click)="showAddEditFrame(0, null)" nz-button nzType="default" nzSearch><span nz-icon
              nzType="plus"></span></button>
          </ng-template>
        </div>

        <!--        <mat-form-field class="example-full-width" appearance="fill">-->
        <!--          <mat-label>Address</mat-label>-->
        <!--          <input-->
        <!--            type="text"-->
        <!--            matInput-->
        <!--            readonly-->
        <!--            [formControl]="countryFormControl"-->
        <!--          />-->
        <!--          <button-->
        <!--            mat-stroked-button-->
        <!--            type="button"-->
        <!--            class="add-button-address"-->
        <!--            matSuffix-->
        <!--            (click)="showAddEditFrame(0, null)"-->
        <!--          >-->
        <!--            <mat-icon-->
        <!--              class="center-icon-address"-->
        <!--              *ngIf="!(this.iAddressResult?.[0]?.data?.[0]); else elseBlock"-->
        <!--            >add-->
        <!--            </mat-icon>-->
        <!--            <ng-template #elseBlock>-->
        <!--              <mat-icon class="center-icon-address">edit</mat-icon>-->
        <!--            </ng-template>-->
        <!--          </button>-->
        <!--          <mat-error *ngIf="countryFormControl.hasError('required')">-->
        <!--            Address is <strong>required</strong>-->
        <!--          </mat-error>-->
        <!--        </mat-form-field>-->
      </td>
      <td>
        <div class="example-full-width">
          <label class="line1-edits-new inputlabel">Company</label>
          <nz-input-group [nzStatus]="statuscompany" nzSearch [nzAddOnAfter]="suffixIconButton1">
            <input (focus)="onBlurAll()" class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="company" nz-input/>
          </nz-input-group>
          <ng-template #suffixIconButton1>
          </ng-template>
        </div>
<!--        <mat-form-field class="example-full-width" appearance="fill">-->
<!--          <mat-label>Company</mat-label>-->
<!--          <input-->
<!--            matInput-->
<!--            [formControl]="companyFormControl"-->
<!--            placeholder="Enter your company"-->
<!--          />-->
<!--          <mat-error *ngIf="companyFormControl.hasError('required')">-->
<!--            Company is <strong>required</strong>-->
<!--          </mat-error>-->
<!--        </mat-form-field>-->
      </td>
    </tr>
    <tr>
      <td>
        <div class="example-full-width">
          <label class="line1-edits-new inputlabel">Company Website</label>
          <nz-input-group [nzStatus]="statuscompanywebsite" nzSearch [nzAddOnAfter]="suffixIconButton1">
            <input (focus)="onBlurAll()" class="line1-edits-new inputedit testdiv" type="url" [(ngModel)]="companywebsite" nz-input/>
          </nz-input-group>
          <ng-template #suffixIconButton1>

          </ng-template>
        </div>
<!--        <mat-form-field class="example-full-width" appearance="fill">-->
<!--          <mat-label>Company Website</mat-label>-->
<!--          <input-->
<!--            matInput-->
<!--            [formControl]="companyWebsiteFormControl"-->
<!--            placeholder="Enter Company Website"-->
<!--          />-->
<!--          <mat-error *ngIf="companyWebsiteFormControl.hasError('required')">-->
<!--            Company Website is <strong>required</strong>-->
<!--          </mat-error>-->
<!--        </mat-form-field>-->
      </td>
      <td>
        <div class="example-full-width">
          <label class="line1-edits-new inputlabel">Number of Employees</label>
          <nz-input-group [nzStatus]="statusnoe" nzSearch [nzAddOnAfter]="suffixIconSearch1">
            <nz-select (focus)="onBlurAll()" nzShowSearch [nzShowArrow]="true" class="line1-edits-new inputedit testdiv"
              [(ngModel)]="noe">
              <nz-option *ngFor="let option of iNoe" [nzLabel]="option.Description"
                [nzValue]="option.Description"></nz-option>
            </nz-select>
          </nz-input-group>
          <ng-template #suffixIconSearch1>
          </ng-template>
        </div>
<!--        <mat-form-field class="example-full-width" appearance="fill">-->
<!--          <mat-label>Number of Employees</mat-label>-->
<!--          <mat-select [formControl]="noeFormControl" name="Noe" required>-->
<!--            <mat-option>None</mat-option>-->
<!--            <mat-option *ngFor="let noe of iNoe" [value]="noe.Description">-->
<!--              {{ noe.Description }}-->
<!--            </mat-option>-->
<!--          </mat-select>-->
<!--          <mat-error *ngIf="noeFormControl.hasError('required')">-->
<!--            Number of Employees is <strong>required</strong>-->
<!--          </mat-error>-->
<!--        </mat-form-field>-->
      </td>
    </tr>
    <tr>
      <td>
        <div class="example-full-width">
          <label class="line1-edits-new inputlabel">Phone</label>
          <nz-input-group [nzStatus]="statusphone" nzSearch [nzAddOnAfter]="suffixIconButton1">
            <input (focus)="onBlurAll()" class="line1-edits-new inputedit testdiv" type="tel" [(ngModel)]="phone" nz-input/>
          </nz-input-group>
          <ng-template #suffixIconButton1>

          </ng-template>
        </div>
<!--        <mat-form-field class="example-full-width" appearance="fill">-->
<!--          <mat-label>Phone</mat-label>-->
<!--          <input-->
<!--            matInput-->
<!--            [formControl]="companyPhone"-->
<!--            placeholder="Enter Phone number"-->
<!--          />-->
<!--          <mat-error *ngIf="companyPhone.hasError('pattern')">-->
<!--            Must contain only numbers-->
<!--          </mat-error>-->
<!--        </mat-form-field>-->
      </td>
      <td>
        <div class="example-full-width">
          <label class="line1-edits-new inputlabel">How did you hear about us?</label>
          <nz-input-group [nzStatus]="statusaboutus" nzSearch [nzAddOnAfter]="suffixIconSearch1">
            <nz-select (focus)="onBlurAll()" nzShowSearch [nzShowArrow]="true" class="line1-edits-new inputedit testdiv"
              [(ngModel)]="aboutus">
              <nz-option *ngFor="let option of iAboutUS" [nzLabel]="option.Description"
                [nzValue]="option.Description"></nz-option>
            </nz-select>
          </nz-input-group>
          <ng-template #suffixIconSearch1>
          </ng-template>
        </div>
<!--        <mat-form-field class="example-full-width" appearance="fill">-->
<!--          <mat-label>How did you hear about us?</mat-label>-->
<!--          <mat-select-->
<!--            [formControl]="howdidFormControl"-->
<!--            name="HowDid"-->
<!--            required-->
<!--          >-->
<!--            <mat-option>None</mat-option>-->
<!--            <mat-option-->
<!--              *ngFor="let howdid of iAboutUS"-->
<!--              [value]="howdid.Description"-->
<!--            >-->
<!--              {{ howdid.Description }}-->
<!--            </mat-option>-->
<!--          </mat-select>-->
<!--          <mat-error *ngIf="howdidFormControl.hasError('required')">-->
<!--            Value is <strong>required</strong>-->
<!--          </mat-error>-->
<!--        </mat-form-field>-->
      </td>
    </tr>
    <tr>
      <td colspan="1">
        <div class="example-full-width1">
          <label class="line1-edits-new inputlabel">Referal Promo Code</label>
          <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton1">
            <input (focus)="onBlurAll()" class="line1-edits-new inputedit testdiv" type="tel" [(ngModel)]="referal" nz-input/>
          </nz-input-group>
          <ng-template #suffixIconButton1>

          </ng-template>
        </div>
<!--        <mat-form-field class="example-full-width" appearance="fill">-->
<!--          <mat-label>Referal Promo Code</mat-label>-->
<!--          <input type="email" matInput [formControl]="referalControl" placeholder="Enter Referal Promo Code"/>-->
<!--        </mat-form-field>-->
      </td>
    </tr>
    <tr>
      <td colspan="1">
        <h5 class="text-stype">Which solutions interest you?</h5>
      </td>
    </tr>
    <tr>
      <td class="set-select-td4">
        <section [formGroup]="toppings" class="checkbox-group">
          <mat-checkbox
            id="check1_1"
            class="checkbox1"
            formControlName="check1_1"
          >Managment
          </mat-checkbox>
          <br/>
          <mat-checkbox
            id="check1_2"
            class="checkbox1"
            formControlName="check1_2"
          >Look Book
          </mat-checkbox>
          <br/>
          <mat-checkbox
            id="check2"
            class="custom-checkbox"
            formControlName="check2"
          >Rental ShowRoom
          </mat-checkbox>
        </section>
      </td>
    </tr>

    <tr>
      <td colspan="2">
        <section class="radioboxGroup" [formGroup]="clicking">
          <mat-checkbox
            id="check2_1"
            class="checkbox2"
            formControlName="check2_1"
            (change)="showOptions($event)"
          >
            I want to stay in the loop and receive the latest marketing communication from “Fashion Management”
          </mat-checkbox>
        </section>
      </td>
    </tr>
    <tr>
      <td colspan="2">
        <section class="radioboxGroup1" [formGroup]="clicking">
          <mat-checkbox id="check2_2" class="checkbox2" formControlName="check2_2" (change)="showOptions($event)">I
            accept the <a href="https://www.google.com" target="_blank" rel="noopener noreferrer"><u>privacy
              policy</u></a></mat-checkbox>
        </section>
      </td>
    </tr>
  </table>

  <button class="view-more-btn" nz-button nzSize="default" nzType="default" (click)="submit()">Request a Demo</button>

<!--  <button mat-raised-button class="view-more-btn" type="submit">-->
<!--    Request a Demo-->
<!--  </button>-->
  <!--  <button mat-raised-button class="view-more-btn" (click)="modalService.registrationopen()">Request a Demo</button>-->
</div>
