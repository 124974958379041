<form [formGroup]="myForm" (ngSubmit)="submit()" autocomplete="off">
  <button mat-icon-button type="button" class="close-btn" (click)="modalService.createaddressclose()">
    <mat-icon>close</mat-icon>
  </button>
  <p class="sample-manager-text">Address</p>
  <div class="scrollable-div-address">
    <div class="example-full-width form-field-spacing-contact">
      <mat-form-field class="selector-full-width" appearance="fill">
        <mat-label>Description</mat-label>
        <input matInput [formControl]="descriptionFormControl" required/>
        <mat-error *ngIf="descriptionFormControl.hasError('required')">
          Description is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="full-width-div1-contact">
      <div class="example-full-width form-field-spacing-contact1">
        <mat-form-field class="selector-full-width" appearance="fill">
          <mat-label>Care Of</mat-label>
          <input matInput [formControl]="careoff1Contro2"/>
        </mat-form-field>
      </div>

      <div class="form-field-spacing-contact1">
        <mat-form-field class="selector-full-width" appearance="fill">
          <mat-label>Country</mat-label>
          <input type="text" matInput placeholder="Select or type..." [matAutocomplete]="auto27"
            [formControl]="countri2Control"/>
          <mat-autocomplete #auto27="matAutocomplete" (optionSelected)="onOptionSelected($event)">
            <mat-option *ngFor="let item of filteredCountries | async" [value]="item.country">
              {{ item.country }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div class="example-full-width form-field-spacing-contact1">
        <mat-form-field class="selector-full-width" appearance="fill">
          <mat-label>Address1</mat-label>
          <input matInput [formControl]="address12Control"/>
        </mat-form-field>
      </div>

      <div class="example-full-width form-field-spacing-contact1">
        <mat-form-field class="selector-full-width" appearance="fill">
          <mat-label>Email</mat-label>
          <input type="email" matInput [formControl]="otherEmail2Control"/>
        </mat-form-field>
      </div>

      <div class="example-full-width form-field-spacing-contact1">
        <mat-form-field class="selector-full-width" appearance="fill">
          <mat-label>Address2</mat-label>
          <input matInput [formControl]="address22Control"/>
        </mat-form-field>
      </div>

      <div class="example-full-width form-field-spacing-contact1">
        <mat-form-field class="selector-full-width" appearance="fill">
          <mat-label>Phone</mat-label>
          <input matInput [formControl]="directPhone2Control"/>
        </mat-form-field>
      </div>

      <div class="example-full-width form-field-spacing-contact1">
        <mat-form-field class="selector-full-width" appearance="fill">
          <mat-label>City</mat-label>
          <input matInput [formControl]="city2Control"/>
        </mat-form-field>
      </div>

      <div class="example-full-width form-field-spacing-contact1">
        <mat-form-field class="selector-full-width" appearance="fill">
          <mat-label>General Phone</mat-label>
          <input matInput [formControl]="generalPhoneControl"/>
        </mat-form-field>
      </div>

      <div class="form-field-spacing-contact1">
        <mat-form-field class="selector-full-width" appearance="fill">
          <mat-label>State</mat-label>
          <input
            type="text"
            matInput
            placeholder="Select or type..."
            [matAutocomplete]="auto28"
            [formControl]="state2Control"
          />
          <mat-autocomplete #auto28="matAutocomplete">
            <mat-option
              *ngFor="let item of filteredStates | async"
              [value]="item.state"
            >
              {{ item.state }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div class="example-full-width form-field-spacing-contact1">
        <mat-form-field class="selector-full-width" appearance="fill">
          <mat-label>Mobile1</mat-label>
          <input matInput [formControl]="mobile1Control"/>
        </mat-form-field>
      </div>

      <div class="example-full-width form-field-spacing-contact1">
        <mat-form-field class="selector-full-width" appearance="fill">
          <mat-label>Zip Code</mat-label>
          <input matInput [formControl]="zipcode2Control"/>
        </mat-form-field>
      </div>

      <div class="example-full-width form-field-spacing-contact1">
        <mat-form-field class="selector-full-width" appearance="fill">
          <mat-label>Mobile2</mat-label>
          <input matInput [formControl]="mobile2Control"/>
        </mat-form-field>
      </div>
    </div>

    <div class="button-container-address">
      <!--    <button mat-raised-button class="button-settings" type="button">-->
      <!--      Cancel-->
      <!--    </button>-->
      <!--        <button mat-raised-button class="button-settings" type="submit" *ngIf="isVisible">Create & Add</button>-->
      <button mat-raised-button class="button-settings-address" type="submit">
        {{ createbuttoncaption }}
      </button>
    </div>
  </div>
</form>
