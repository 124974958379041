<button mat-icon-button type="button" class="close-btn" (click)="modalService.ssFrameClose()">
  <mat-icon>close</mat-icon>
</button>
<p class="sample-manager-customer">{{ caption }}</p>
<div class="maindiv">
  <div class="componentsdiv">
    <div class="componentsdiv2">
      <label class="txtdiv2"><span class="onlybold">Product</span></label>
      <!--      <nz-input-group [nzAddOnAfter]="suffixProductIconButton">-->
      <!--        <nz-select nzShowSearch [nzShowArrow]="true" class="cmdiv"-->
      <!--          [(ngModel)]="selectproduct">-->
      <!--          <nz-option *ngFor="let option of iProductinResult"-->
      <!--            [nzLabel]="option['name']"-->
      <!--            [nzValue]="option['name']">-->
      <!--          </nz-option>-->
      <!--        </nz-select>-->
      <!--      </nz-input-group>-->

      <ng-template #suffixProductIconButton>
        <button class="buttonclass" nz-button nzType="default" (click)="onProductPluseClick()">
          <i nz-icon nzType="plus" nzTheme="outline"></i>
        </button>
      </ng-template>

      <nz-input-group [nzSuffix]="suffixIcon" [nzAddOnAfter]="suffixProductIconButton">
        <input
          #searchInput
          type="text"
          nz-input
          [(ngModel)]="selectproduct"
          (ngModelChange)="onSearch($event)"
          (focus)="onFocus()"
          [nzAutocomplete]="auto"
          placeholder="Search for a product..."
        />
      </nz-input-group>

      <nz-autocomplete [class.richfilter]="true" #auto [nzWidth]="inputWidth" class="custom-autocomplete-panel">
        <nz-auto-option *ngFor="let product of filteredProducts" [nzValue]="product.name">
          <div class="search-item">
            <img [src]="product.imagelink" class="product-image shadow-4" alt="Product">
            <div class="product-info">
              <strong class="product-name">{{ product.name }}</strong><br>
              <span class="product-description" [innerHTML]="product.description"></span><br>
              <span class="product-price">{{ product.retail_price | currency }}</span>
            </div>
          </div>
        </nz-auto-option>
      </nz-autocomplete>

      <ng-template #suffixIcon>
        <i nz-icon nzType="search"></i>
      </ng-template>
    </div>
    <div class="componentsdiv2">
      <label class="txtdiv2"><span class="onlybold">Customer</span></label>
      <nz-input-group [nzAddOnAfter]="suffixCustomerIconButton">
        <nz-select
          nzShowSearch
          nzAllowClear
          [nzShowArrow]="true"
          class="cmdiv"
          [(ngModel)]="selectcustomer"
          (ngModelChange)="updateCustomerDiscount()">
          <nz-option *ngFor="let option of iCustomersResult"
            [nzLabel]="option['FullName']"
            [nzValue]="option['FullName']">
          </nz-option>
        </nz-select>

      </nz-input-group>

      <ng-template #suffixCustomerIconButton>
        <button class="buttonclass" nz-button nzType="default" (click)="onCustomerAddClick()">
          <i nz-icon nzType="ellipsis" nzTheme="outline"></i>
        </button>
      </ng-template>
    </div>
  </div>

  <div class="componentsdiv1-2">
    <div class="componentsdiv2-1">
      <p-table
        [value]="selectedproduct"
        [paginator]="false"
        [rows]="10000"
        [showCurrentPageReport]="false"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [styleClass]="'p-datatable-striped mat-elevation-z1 ' + (selectedSize?.class || '')"
        selectionMode="single"
        [tableStyle]="{'min-width': '50rem'}">

        <ng-template pTemplate="header">
          <tr>
            <th style="width: 3%">Image</th>
            <th style="width: 17%">Name</th>
            <th style="width: 10%">Quantity</th>
            <th style="width: 10%">Price</th>
            <th style="width: 10%">Discount (%)</th>
            <th style="width: 10%">Total Price</th>
            <th style="width: 1%"></th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-product>
          <tr>
            <td>
              <img
                *ngIf="product?.imagelink"
                [src]="transformImageUrl(product.imagelink)"
                alt="Product Image"
                class="image-size-80x80 shadow-4"
                onerror="this.src='assets/images/placeholder.jpg';">
            </td>

            <td>
              {{ product.name }}<br>
              <small>{{ product.sku_code }}</small>
            </td>

            <td>
              <nz-input-number
                nzSize="small"
                [(ngModel)]="product.quantity"
                [nzMin]="product.count === 0 ? 0 : 1"
                [nzMax]="product.count"
                (ngModelChange)="updateTotalCost(product)">
              </nz-input-number>
              <br>
              <small>{{ product.count }} Items remaining</small>
            </td>

            <td>
              <nz-input-number
                nzSize="small"
                [(ngModel)]="product.unitPrice"
                [nzFormatter]="formatterDollar"
                nzMin="1"
                (ngModelChange)="updateTotalCost(product)">
              </nz-input-number>
            </td>

            <td>
              <nz-input-number
                nzSize="small"
                [(ngModel)]="product.discountPercentage"
                [nzFormatter]="formatterPercent"
                nzMin="0" nzMax="100"
                (ngModelChange)="updateTotalCost(product)">
              </nz-input-number>
              <br>
              <small>Discount Amount: ${{ product.discountAmount.toFixed(2) }}</small>
            </td>

            <td>
              <h2><strong>${{ product.totalAfterDiscount.toFixed(2) }}</strong></h2>
            </td>

            <td>
              <button nz-button nzType="text" nzSize="small" nzShape="circle" (click)="deleteProduct(product)">
                <span nz-icon [nzType]="'delete'" [nzTheme]="'fill'" style="color: red;"></span>
              </button>
            </td>
          </tr>
        </ng-template>
      </p-table>

      <div class="summary-container">
        <div class="summary-row">
          <span class="summary-label">Subtotal of products</span>
          <strong class="summary-value">${{ getSubtotal().toFixed(2) }}</strong>
        </div>

        <!-- ✅ Показывать сумму скидки только если она больше 0 -->
        <div class="summary-row" *ngIf="getTotalDiscount() > 0">
          <span class="summary-label">Total Discount</span>
          <strong class="summary-discount">-${{ getTotalDiscount().toFixed(2) }}</strong>
        </div>

        <div class="summary-row">
          <span class="summary-label">Shipping Costs</span>
          <nz-input-number
            [(ngModel)]="shippingCost"
            [nzFormatter]="formatterDollar"
            nzSize="small"
            nzMin="0"
            class="summary-input"
            (ngModelChange)="calculateOrderTotal()">
          </nz-input-number>
        </div>

        <!-- ✅ Default Sales Tax (новый блок) -->
        <div class="summary-row">
          <label class="txtdiv2"><span>Sales Tax</span></label>
          <div style="flex: 1;">
            <nz-input-group>
              <nz-select [nzDisabled]="twholesale" nzShowSearch [nzShowArrow]="true" class="cmdiv1"
                [(ngModel)]="selecttaxe" (ngModelChange)="calculateOrderTotal()">
                <nz-option *ngFor="let option of iTaxResult" [nzLabel]="option['displayvalue']"
                  [nzValue]="option['displayvalue']"></nz-option>
              </nz-select>
            </nz-input-group>
          </div>
          <!-- Показываем налог в долларах -->
          <strong class="summary-tax">+${{ getTaxAmount().toFixed(2) }}</strong>
        </div>

        <hr class="summary-divider">

        <div class="summary-row total">
          <span class="summary-label">ORDER TOTAL</span>
          <strong class="summary-total">${{ orderTotal.toFixed(2) }}</strong>
        </div>

        <hr class="summary-divider" *ngIf="completesale">

        <div class="summary-row" *ngIf="completesale">
          <span class="summary-label">{{ paymenttype === 1 ? 'Cash' : 'Other Method' }}</span>
          <strong class="summary-value">${{ paymentvalue.toFixed(2) }}</strong>
        </div>

        <!-- Показывать "Change" только если changevalue > 0 -->
        <div class="summary-row" *ngIf="completesale && changevalue > 0">
          <span class="summary-label">Change</span>
          <strong class="summary-value">${{ changevalue.toFixed(2) }}</strong>
        </div>

        <hr class="summary-divider">

        <div class="componentsdivnote-2">
          <div class="componentsdivnote2-1">
            <label class="txtdiv">Note</label><br>
            <textarea [(ngModel)]="notestring" rows="3" cols="30" class="cmdiv dopinfo"
              nz-input
            ></textarea>
          </div>
        </div>

        <!-- ✅ КНОПКА ОПЛАТЫ -->
        <div *ngIf="!completesale" class="pay-button-container">
          <button
            class="pay-button"
            (click)="processPayment()"
            [disabled]="selectedproduct.length === 0"
            [ngClass]="{'error-button': hasUnavailableProduct()}"
          >
            <div class="pay-left">
              <span class="pay-text">Pay</span>
              <span
                class="item-count">{{ selectedproduct.length }} {{ selectedproduct.length === 1 ? 'item' : 'items' }}</span>
            </div>
            <span class="pay-amount">${{ orderTotal.toFixed(2) }}</span>
          </button>
        </div>

        <div *ngIf="completesale" class="pay-button-container">
          <button
            class="pay-button-complete"
            (click)="processCompletePayment()"
            [disabled]="selectedproduct.length === 0"
            [ngClass]="{
      'error-button': hasUnavailableProduct(),
      'return-order': SelfData?.returnorder
    }"
          >
            <div class="pay-left">
              <span class="pay-text">{{ completesalestring }}</span>
              <span class="item-count">
                {{ selectedproduct.length }} {{ selectedproduct.length === 1 ? 'item' : 'items' }}
              </span>
            </div>
            <span class="pay-amount">${{ orderTotal.toFixed(2) }}</span>
          </button>
        </div>

      </div>

    </div>
  </div>
</div>
