<form [formGroup]="myForm" (ngSubmit)="submit()" autocomplete="off">
    <button mat-icon-button type="button" class="close-btn" (click)="modalService.closeDeleteFrame()">
        <mat-icon>close</mat-icon>
    </button>
    <p class="sample-manager-delete">Choose a reason</p>
    <div class="scrollable-div-delete horizontal-container-delete">
        <div class="black-background-delete">
            <mat-radio-group aria-label="Select an option" (change)="onRadioChange($event)">
                <mat-radio-button class="radiobutton" value="1">Sample is not available</mat-radio-button>
                <br>
                <mat-radio-button class="radiobutton" value="2">Sample is in another delivery</mat-radio-button>
                <br>
                <mat-radio-button class="radiobutton" value="3">Sample is on another request</mat-radio-button>
                <br>
                <mat-radio-button class="radiobutton" value="4">I made a mistake</mat-radio-button>
                <br>
                <mat-radio-button class="radiobutton" value="5">Sample is lost</mat-radio-button>
                <br>
                <mat-radio-button class="radiobutton" value="6">Sample is damaged</mat-radio-button>
                <br>
                <mat-radio-button class="radiobutton" value="7">Sample will be substituted</mat-radio-button>
                <br>
                <mat-radio-button class="radiobutton" value="8">Other</mat-radio-button>
                <br>
            </mat-radio-group>
            <mat-form-field class="form-field-spacing-delete form-field-delete">
              <textarea class="no-resize" matInput style="height: 160px" [disabled]="isTextareaDisabled">
              </textarea>
            </mat-form-field>
        </div>
    </div>
    <div class="button-container-delete">
        <button mat-raised-button class="button-settings-delete" type="submit" [disabled]="buttonenable">
            OK
        </button>
    </div>
</form>
