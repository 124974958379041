import {
  AfterViewInit,
  Component,
  ElementRef,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ModalserviceService } from '../../services/modalservice.service';
import { YourappsserviceService } from '../../services/yourappsservice.service';
import { Router } from '@angular/router';
import {SelfData, transformImageUrl} from "../../models/struct";

@Component({
  selector: 'app-yourapps',
  templateUrl: './yourapps.component.html',
  styleUrls: ['./yourapps.component.scss'],
})
export class YourappsComponent implements OnInit, AfterViewInit, OnChanges {
  newsItems: any[];

  programms = 0;
  programmscount = 0;
  rpmonitoring = 'Managment';
  events = 'Rental ShowRoom';
  samples = 'Managment';
  contacts = 'Look Book';

  @ViewChild('centeredDiv', { static: false }) centeredDiv:
    | ElementRef
    | undefined;

  constructor(
    public modalService: ModalserviceService,
    private yourappsService: YourappsserviceService,
    private router: Router
  ) {
    this.newsItems = [
      {
        newsid: 1,
        content:
          '“Fashion Management” is a software and data analytics company offers a wide range and variety of services designed to support Fashion PR Agencces, Showrooms, Brands, Creators, lifestyle, and beauty industries.\n' +
            '“Fashion Management” provides brands with custom reports and analytics that help them track their performance and identify areas for improvement.',
        url: transformImageUrl('http://108.178.189.70:6330/IMNews/1.jpg'),
      },
      {
        newsid: 1,
        content: '“Fashion Management” is equips fashion PR agencies and brands companies with tools and intelligence to develop their brand assets efficiently, launch their collections with impact, and measure brand performance to optimize their strategy globally. This is achieved by combining best-in-class data, software, highly quantified platform and better services​ than other.',
        url: transformImageUrl('http://108.178.189.70:6330/IMNews/2.jpg'),
      },
      {
        newsid: 1,
        content: '“Fashion Management” also offers digital tools to streamline marketing and communication activities and saving time and money​.\n' +
            'Overall, “Fashion Management” is a comprehensive platform that can help brands in the fashion, lifestyle, and beauty industries improve their performance and reach their goals.',
        url: transformImageUrl('http://108.178.189.70:6330/IMNews/4.jpg'),
      },
      {
        newsid: 1,
        content: '“Fashion Management” is a company that believes in the evolution of an industry where technology has changed the speed, expectations, and inspirations of the market. With over a decade of experience, we provide intelligence that empowers companies in the fashion, lifestyle and beauty sectors to grow their businesses and streamline their processes.\n' +
            'Data and technology bring a sharp focus to profitability, accountability, and efficiency while enabling the type of quick decision making required for agility. With tools for PR monitoring and data analysis, the “Fashion Management” enables brand to build a successful marketing strategy, all in one place.\n' +
            'Transforming big data into industry insights through our proprietary AI, machine learning algorithms.',
        url: transformImageUrl('http://108.178.189.70:6330/IMNews/5.webp'),
      },
      // {
      //   newsid: 1,
      //   content: 'Content for single cell',
      //   url: 'https://btrnews.co.uk/wp-content/uploads/2021/05/Terrace-at-The-Lexington-BTR-scheme-Liverpool-Moda-Living-BTR-News-696x483.png',
      // },
      // {
      //   newsid: 1,
      //   content: 'Content for second cell in two-cell layout',
      //   url: 'https://apa.az/storage/news/2023/january/31/big/63d8d7662ed3363d8d7662ed34167515530263d8d7662ed3163d8d7662ed32.png',
      // },
      // Add more items as needed
    ];
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('programms' in changes) {
      const newValue = changes['programms'].currentValue;
      // Call a function to handle the change
      this.onProgrammsChange(newValue);
    }
  }

  onProgrammsChange(newValue: any) {
    // Do something with the new value
  }

  private repaintView(): void {
    // Implement the logic needed to repaint or re-render your view
  }
  showprogramms() {
    this.programms = 1;
    console.dir(this.programms);
  }
  ngOnInit(): void {
    this.yourappsService.programmsCount$.subscribe((count) => {
      this.programmscount = count;
      console.dir(count);
    });
    // setTimeout(() => {
    //   let th = this.newsItems.length * 301;
    //   this.setCenteredDivHeight(th);
    // }, 0);
  }

  ngAfterViewInit() {}

  setCenteredDivHeight(height: number | string) {
    // if (this.centeredDiv) {
    //   console.dir(this.centeredDiv.nativeElement.style.height)
    //   this.centeredDiv.nativeElement.style.height = typeof height === 'number' ? `${height}px` : height;
    // }
  }

  protected readonly Event = Event;

  openSamplesPage() {
    SelfData.samplestyle = 0
    this.router.navigate(['/Samples']);
  }

  openSalePage() {
    SelfData.samplestyle = 1
    this.router.navigate(['/Sale']);
  }

  onNotificationsClick() {
    this.modalService.openCS()
  }
}
