<button mat-icon-button type="button" class="close-btn" (click)="onButtonCloseClick()">
    <mat-icon>close</mat-icon>
</button>
<p class="sample-manager-text">{{formcaption}}</p>


<div class="imagelist">
    <div class="file-container-images">
        <mat-card *ngFor="let item of selectedImageFiles; let i = index"
                [ngClass]="{'red-background': !item.islinked, 'green-background': item.islinked}"
                class="card-item shadow-4">
            <button *ngIf="!item.islinked" mat-mini-fab type="button" class="close-button" (click)="SelectImageFile($event,item ,i)">
                <span class="spantext">Create Sample</span>
            </button>
            <img [src]="item.url" (click)="showFullWindowImage(item.url)" class="thumbnail" alt="Image thumbnail">
        </mat-card>

        <!-- Single preview modal for full window image preview -->
        <div *ngIf="previewVisible" class="overlay" (click)="hideFullWindowImage()">
            <img [src]="previewUrl" class="overlay-content" alt="Full image preview">
        </div>
    </div>
</div>
