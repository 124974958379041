import { Component } from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";

@Component({
  selector: 'app-createsample-sale',
  templateUrl: './createsample-sale.component.html',
  styleUrl: './createsample-sale.component.scss'
})
export class CreatesampleSaleComponent {
  constructor(public modalService: ModalserviceService) {

  }
}
