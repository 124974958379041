<button mat-icon-button type="button" class="close-btn" (click)="modalService.closeEmailSign()">
  <mat-icon>close</mat-icon>
</button>
<span class="sample-manager-editor">Add Email

</span>

<div class="tagsdiv">
  @for (tag of tags; track tag) {
    <nz-tag [nzMode]="$index === 0 ? 'default' : 'closeable'" (nzOnClose)="handleClose(tag)">
      {{ sliceTagName(tag) }}
    </nz-tag>
  }

  @if (!inputVisible) {
    <nz-tag class="editable-tag" nzNoAnimation (click)="showInput()">
      <span nz-icon nzType="plus"></span>
      Add Email
    </nz-tag>
  } @else {
    <input
      #inputElement
      nz-input
      nzSize="small"
      type="text"
      [(ngModel)]="inputValue"
      style="width: 150px;"
      (blur)="handleInputConfirm()"
      (keydown.enter)="handleInputConfirm()"
    />
  }
</div>

<div class="button-container">
  <button
    mat-raised-button
    class="button-settings"
    type="button" (click)="modalService.closeEmailSign()">
    Cancel
  </button>
  <button mat-raised-button class="button-settings" type="button" (click)="onOKClick()">
    OK
  </button>
</div>
