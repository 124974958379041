<div class="modal-container">
  <div class="headerdiv">
    <span class="headertext">
      TATA PR
    </span>
  </div>
  <ng-content></ng-content>
  <div class="black-background2">
    <!--  [(selection)]="selectedElement"-->
    <!--  (onRowSelect)="onEditButtonClick(selectedElement)"-->
    <div id="scrollable-div-dm" class="card example-container-sm mat-elevation-z3">
      <p-table
        [value]="iSendOutReportResult || []"
        #dt1
        [paginator]="true"
        [rows]="100"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [rowsPerPageOptions]="[100, 300, 500, 1000]"
        [styleClass]="'p-datatable-striped ' + selectedSize.class.toString()"
        selectionMode="single"
        [globalFilterFields]="['Designer', 'barcode', 'Collection', 'Season', 'Type', 'Color', 'Description']"
        [tableStyle]="{'min-width': '50rem '}">

        <!--        <ng-template pTemplate="caption">-->
        <!--          <div class="flex">-->
        <!--            <p-button class="info-btn" icon="pi pi-info" [rounded]="true" [outlined]="true"-->
        <!--              (click)="onInfoButtonClick()"/>-->
        <!--            -->
        <!--          </div>-->
        <!--        </ng-template>-->

        <ng-template pTemplate="header">
          <tr>
            <th style="width:5%" *ngIf="selectCheckDeliveryNumber || selectCheckTypeOfDispatch || selectCheckBarcode">
              <span *ngIf="selectCheckDeliveryNumber">Delivery #<br></span>
              <span *ngIf="selectCheckTypeOfDispatch">Type of Dispatch<br></span>
              <span *ngIf="selectCheckBarcode">Barcode</span>
            </th>
            <th style="width:5%" *ngIf="selectCheckDesigner || selectCheckCollection || selectCheckSeason">
              <span *ngIf="selectCheckDesigner">Designer<br></span>
              <span *ngIf="selectCheckCollection">Collection<br></span>
              <span *ngIf="selectCheckSeason">Season</span>
            </th>
            <th style="width:5%" *ngIf="selectCheckStyle || selectCheckType || selectCheckColor">
              <span *ngIf="selectCheckStyle">Style<br></span>
              <span *ngIf="selectCheckType">Type<br></span>
              <span *ngIf="selectCheckColor">Color</span>
            </th>
            <th style="width:5%" *ngIf="selectCheckCreateDate || selectCheckShipDate || selectCheckReturnDate">
              <span *ngIf="selectCheckCreateDate">Created Date<br></span>
              <span *ngIf="selectCheckShipDate">Ship Date<br></span>
              <span *ngIf="selectCheckReturnDate">Return Date</span>
            </th>
            <th style="width:5%" *ngIf="selectCheckCompanyName || selectCheckContact || selectCheckCelebriti">
              <span *ngIf="selectCheckCompanyName">Company<br></span>
              <span *ngIf="selectCheckContact">Contact<br></span>
              <span *ngIf="selectCheckCelebriti">Client</span>
            </th>
            <th style="width:5%" *ngIf="selectCheckPublication || selectCheckEditorStylist || selectCheckSendOutType">
              <span *ngIf="selectCheckPublication">Publication<br></span>
              <span *ngIf="selectCheckEditorStylist">Editor/Stylist<br></span>
              <span *ngIf="selectCheckSendOutType">SendOut Type</span>
            </th>
            <th style="width:1%" *ngIf="selectCheckCelebInfluencModel || selectCheckProjectEvent || selectCheckSender">
              <span *ngIf="selectCheckCelebInfluencModel">Celeb/Influenc/Model<br></span>
              <span *ngIf="selectCheckProjectEvent">Project/Event<br></span>
              <span *ngIf="selectCheckSender">Sender</span>
            </th>
            <th style="width:5%" *ngIf="selectCheckDescription || selectCheckShipTo || selectCheckSampleWorkflowStatus">
              <span *ngIf="selectCheckDescription">Description<br></span>
              <span *ngIf="selectCheckShipTo">Ship To<br></span>
              <span *ngIf="selectCheckSampleWorkflowStatus">Sample Workflow Status</span>
            </th>
            <th style="width:5%" *ngIf="selectCheckRegion || selectCheckFabric || selectCheckManufacturer">
              <span *ngIf="selectCheckRegion">Region<br></span>
              <span *ngIf="selectCheckFabric">Fabric<br></span>
              <span *ngIf="selectCheckManufacturer">Manufacturer</span>
            </th>
            <th style="width:5%" *ngIf="selectCheckMisc || selectCheckIssue || selectCheckTrackingNumber">
              <span *ngIf="selectCheckMisc">Misc<br></span>
              <span *ngIf="selectCheckIssue">Issue<br></span>
              <span *ngIf="selectCheckTrackingNumber">Tracking Number</span>
            </th>
            <th style="width:5%" *ngIf="selectCheckSize || selectCheckSizeRange || selectCheckPattern">
              <span *ngIf="selectCheckSize">Size<br></span>
              <span *ngIf="selectCheckSizeRange">Size Range<br></span>
              <span *ngIf="selectCheckPattern">Pattern</span>
            </th>
            <th style="width:5%" *ngIf="selectCheckGender || selectCheckNotes || selectCheckPhotographer">
              <span *ngIf="selectCheckGender">Gender<br></span>
              <span *ngIf="selectCheckNotes">Notes<br></span>
              <span *ngIf="selectCheckPhotographer">Photographer</span>
            </th>
            <th *ngIf="selectCheckImage" style="width:1%">Image</th>

          </tr>
        </ng-template>

<!--        -->

        <ng-template pTemplate="body" let-product>
          <tr [pSelectableRow]="product">
            <td *ngIf="selectCheckDeliveryNumber || selectCheckTypeOfDispatch || selectCheckBarcode">
              <span *ngIf="selectCheckDeliveryNumber">
                <span style="text-decoration: underline; color: #007bff;">
                  {{ product["DeliveryNumber"] }}
                </span>
                <br></span>
              <span *ngIf="selectCheckTypeOfDispatch">{{ product["TypeOfDispatch"] }}<br></span>
              <span *ngIf="selectCheckBarcode">{{ product["Barcode"] }}</span>
            </td>
            <td *ngIf="selectCheckDesigner || selectCheckCollection || selectCheckSeason">
              <span *ngIf="selectCheckDesigner">{{ product["Designer"] }}<br></span>
              <span *ngIf="selectCheckCollection">{{ product["Collection"] }}<br></span>
              <span *ngIf="selectCheckSeason">{{ product["Season"] }}</span>
            </td>
            <td *ngIf="selectCheckStyle || selectCheckType || selectCheckColor">
              <span *ngIf="selectCheckStyle">{{ product["Style"] }}<br></span>
              <span *ngIf="selectCheckType">{{ product["Type"] }}<br></span>
              <span *ngIf="selectCheckColor">{{ product["Color"] }}</span>
            </td>
            <td *ngIf="selectCheckCreateDate || selectCheckShipDate || selectCheckReturnDate">
              <span *ngIf="selectCheckCreateDate">{{ formatDate(product["CreateDate"]) }}<br></span>
              <span *ngIf="selectCheckShipDate">{{ formatDate(product["ReservationDate"]) }}<br></span>
              <span *ngIf="selectCheckReturnDate">{{ formatDate(product["ReturnDate"]) }}</span>
            </td>
            <td *ngIf="selectCheckCompanyName || selectCheckContact || selectCheckCelebriti">
              <span *ngIf="selectCheckCompanyName">{{ product["Companyname"] }}<br></span>
              <span *ngIf="selectCheckContact">{{ product["Contact"] }}<br></span>
              <span *ngIf="selectCheckCelebriti">{{ product["Celebriti"] }}</span>
            </td>
            <td *ngIf="selectCheckPublication || selectCheckEditorStylist || selectCheckSendOutType">
              <span *ngIf="selectCheckPublication">{{ product["Publication"] }}<br></span>
              <span *ngIf="selectCheckEditorStylist">{{ product["EditorStylist"] }}<br></span>
              <span *ngIf="selectCheckSendOutType">{{ product["SendOutType"] }}</span>
            </td>
            <td *ngIf="selectCheckCelebInfluencModel || selectCheckProjectEvent || selectCheckSender">
              <span *ngIf="selectCheckCelebInfluencModel">{{ product["CelebInfluencModel"] }}<br></span>
              <span *ngIf="selectCheckProjectEvent">{{ product["ProjectEvent"] }}<br></span>
              <span *ngIf="selectCheckSender">{{ product["Sender"] }}</span>
            </td>
            <td *ngIf="selectCheckDescription || selectCheckShipTo || selectCheckSampleWorkflowStatus">
              <span *ngIf="selectCheckDescription">{{ product["Description"] }}<br></span>
              <span *ngIf="selectCheckShipTo">{{ product["ShipTo"] }}<br></span>
              <span *ngIf="selectCheckSampleWorkflowStatus">{{ product["SampleWorkflowStatus"] }}</span>
            </td>
            <td *ngIf="selectCheckRegion || selectCheckFabric || selectCheckManufacturer">
              <span *ngIf="selectCheckRegion">{{ product["Region"] }}<br></span>
              <span *ngIf="selectCheckFabric">{{ product["Fabric"] }}<br></span>
              <span *ngIf="selectCheckManufacturer">{{ product["Manufacturer"] }}</span>
            </td>
            <td *ngIf="selectCheckMisc || selectCheckIssue || selectCheckTrackingNumber">
              <span *ngIf="selectCheckMisc">{{ product["Misc"] }}<br></span>
              <span *ngIf="selectCheckIssue">{{ product["Issue"] }}<br></span>
              <span *ngIf="selectCheckTrackingNumber">{{ product["TrackingNumber"] }}</span>
            </td>
            <td *ngIf="selectCheckSize || selectCheckSizeRange || selectCheckPattern">
              <span *ngIf="selectCheckSize">{{ product["Size"] }}<br></span>
              <span *ngIf="selectCheckSizeRange">{{ product["SizeRange"] }}<br></span>
              <span *ngIf="selectCheckPattern">{{ product["Pattern"] }}</span>
            </td>
            <td *ngIf="selectCheckGender || selectCheckNotes || selectCheckPhotographer">
              <span *ngIf="selectCheckGender">{{ product["Gender"] }}<br></span>
              <span *ngIf="selectCheckNotes">{{ product["Notes"] }}<br></span>
              <span *ngIf="selectCheckPhotographer">{{ product["Photographer"] }}</span>
            </td>
            <td *ngIf="selectCheckImage"><img [src]="transformImageUrl(product['ImageLink'])" alt="Image"
              class="overlay-content image-size-80x80 shadow-4"
              (click)="showFullWindowImage(transformImageUrl(product['ImageLink']))"></td>
          </tr>
        </ng-template>

        <p-paginator #paginator></p-paginator>
      </p-table>
    </div>
  </div>
  <div class="footerdiv">
    <span class="footertext">
      <a href="https://fashion-m.com/" target="_blank">
        Click and learn more about Fashion Management Software
      </a>
    </span>
  </div>
</div>

