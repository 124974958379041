<div
  class="bg-black/40 fixed top-0 right-0 left-0 bottom-0"

></div>

<div class="modal-container">
  <ng-content></ng-content>
</div>

<!--<app-invoice class="centered-messagebox" *ngIf="modalService.isInvoiceVisibled$ | async" @fadeInOut>-->
<!--  <app-invoicedialog></app-invoicedialog>-->
<!--  &lt;!&ndash;  <app-messageboxdialog></app-messageboxdialog>&ndash;&gt;-->
<!--</app-invoice>-->
