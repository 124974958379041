<div class="modal-container">
  <ng-content></ng-content>
  <div class="black-background2">
    <!--  [(selection)]="selectedElement"-->
    <!--  (onRowSelect)="onEditButtonClick(selectedElement)"-->
    <div class="topheader">
      <button type="button" nz-button nzType="default" (click)="op.toggle($event)">Grid Fields</button>
      <p-button class="info-btn" icon="pi pi-info" [rounded]="true" [outlined]="true" (click)="onInfoButtonClick()"/>
    </div>
    <div class="headerdiv">
      <div class="components-container">
        <input (input)="onInputDesigner(selectCheckDeliveryNumberString)" class="componentdiv" *ngIf="selectCheckDeliveryNumber" nz-input placeholder="Delivery #" [(ngModel)]="selectCheckDeliveryNumberString" />
          <nz-select *ngIf="selectCheckTypeOfDispatch"
            nzPlaceHolder="Type of Dispatch"
            nzShowSearch
            nzAllowClear
            [nzShowArrow]="true"
            class="componentdiv"
            [(ngModel)]="selectCheckTypeOfDispatchString"
            (ngModelChange)="onInputDesigner($event)">
            <nz-option
              *ngFor="let option of iTodResult[0]?.data"
              [nzLabel]="option.description"
              [nzValue]="option.description"
            ></nz-option>
          </nz-select>

        <input (input)="onInputDesigner(selectCheckBarcodeString)" class="componentdiv" *ngIf="selectCheckBarcode" nz-input placeholder="Barcode" [(ngModel)]="selectCheckBarcodeString" />
        <nz-select *ngIf="selectCheckDesigner"
          nzPlaceHolder="Designer"
          nzShowSearch
          nzAllowClear
          [nzShowArrow]="true"
          class="componentdiv"
          [(ngModel)]="selectCheckDesignerString"
          (ngModelChange)="onInputDesigner($event)">
          <nz-option
            *ngFor="let option of iDesignerResult[0]?.data"
            [nzLabel]="option.description"
            [nzValue]="option.description"
          ></nz-option>
        </nz-select>

        <nz-select *ngIf="selectCheckCollection"
          nzPlaceHolder="Collection"
          nzShowSearch
          nzAllowClear
          [nzShowArrow]="true"
          class="componentdiv"
          [(ngModel)]="selectCheckCollectionString"
          (ngModelChange)="onInputDesigner($event)">
          <nz-option
            *ngFor="let option of iCollection[0]?.data"
            [nzLabel]="option.description"
            [nzValue]="option.description"
          ></nz-option>
        </nz-select>

        <nz-select *ngIf="selectCheckSeason" nzPlaceHolder="Season" nzShowSearch nzAllowClear [nzShowArrow]="true" class="componentdiv"
          [(ngModel)]="selectCheckSeasonString" (ngModelChange)="onInputDesigner($event)">
          <nz-option *ngFor="let option of iSeason[0]?.data" [nzLabel]="option.description"
            [nzValue]="option.description"></nz-option>
        </nz-select>

        <nz-select *ngIf="selectCheckStyle" nzPlaceHolder="Style" nzShowSearch nzAllowClear [nzShowArrow]="true" class="componentdiv"
          [(ngModel)]="selectCheckStyleString" (ngModelChange)="onInputDesigner($event)">
          <nz-option *ngFor="let option of iStyle[0]?.data" [nzLabel]="option.description"
            [nzValue]="option.description"></nz-option>
        </nz-select>

        <nz-select *ngIf="selectCheckType" nzPlaceHolder="Type" nzShowSearch nzAllowClear [nzShowArrow]="true" class="componentdiv"
          [(ngModel)]="selectCheckTypeString" (ngModelChange)="onInputDesigner($event)">
          <nz-option *ngFor="let option of iType[0]?.data" [nzLabel]="option.description"
            [nzValue]="option.description"></nz-option>
        </nz-select>

        <nz-select (ngModelChange)="onInputColor($event)" *ngIf="selectCheckColor" nzPlaceHolder="Color" nzShowSearch nzAllowClear [nzShowArrow]="true" class="componentdiv"
          [(ngModel)]="selectCheckColorString">
          <nz-option nzCustomContent *ngFor="let option of colorarray" [nzLabel]="option.colorname"
            [nzValue]="option.colorname">
            <span class="color-box" [style.background]="option.colorcode"></span>
            {{ option.colorname }}
          </nz-option>
        </nz-select>

        <nz-range-picker (ngModelChange)="onInputDate($event)" *ngIf="selectCheckCreateDate" [nzAllowClear]="true" class="componentdiv" [(ngModel)]="createDate"></nz-range-picker>
        <nz-range-picker (ngModelChange)="onInputShipDate($event)" *ngIf="selectCheckShipDate" [nzAllowClear]="true" class="componentdiv" [(ngModel)]="shipDate"></nz-range-picker>
        <nz-range-picker (ngModelChange)="onInputReturnDate($event)" *ngIf="selectCheckReturnDate" [nzAllowClear]="true" class="componentdiv" [(ngModel)]="returnDate"></nz-range-picker>

        <nz-select *ngIf="selectCheckCompanyName" nzPlaceHolder="Company" nzShowSearch nzAllowClear [nzShowArrow]="true" class="componentdiv"
          [(ngModel)]="selectCheckCompanyNameString" (ngModelChange)="onInputDesigner($event)">
          <nz-option *ngFor="let option of iCompanyResult[0]?.data" [nzLabel]="option.companyname"
            [nzValue]="option.companyname"></nz-option>
        </nz-select>

        <nz-select *ngIf="selectCheckContact" nzPlaceHolder="Contact" nzShowSearch nzAllowClear [nzShowArrow]="true" class="componentdiv"
          [(ngModel)]="selectCheckContactString" (ngModelChange)="onInputDesigner($event)">
          <nz-option *ngFor="let option of iContact[0]?.data" [nzLabel]="option.description"
            [nzValue]="option.description"></nz-option>
        </nz-select>

        <nz-select *ngIf="selectCheckCelebriti" nzPlaceHolder="Client" nzShowSearch nzAllowClear [nzShowArrow]="true" class="componentdiv"
          [(ngModel)]="selectCheckCelebritiString" (ngModelChange)="onInputDesigner($event)">
          <nz-option *ngFor="let option of iClient[0]?.data" [nzLabel]="option.description"
            [nzValue]="option.description"></nz-option>
        </nz-select>

        <nz-select *ngIf="selectCheckPublication" nzPlaceHolder="Publication" nzShowSearch nzAllowClear [nzShowArrow]="true" class="componentdiv"
          [(ngModel)]="selectCheckPublicationString" (ngModelChange)="onInputDesigner($event)">
          <nz-option *ngFor="let option of ipublication[0]?.data" [nzLabel]="option.description"
            [nzValue]="option.description"></nz-option>
        </nz-select>

        <nz-select *ngIf="selectCheckEditorStylist" nzPlaceHolder="Editor/Stylist" nzShowSearch nzAllowClear [nzShowArrow]="true" class="componentdiv"
          [(ngModel)]="selectCheckEditorStylistString" (ngModelChange)="onInputDesigner($event)">
          <nz-option *ngFor="let option of iEStyllist[0]?.data" [nzLabel]="option.description"
            [nzValue]="option.description"></nz-option>
        </nz-select>

        <nz-select *ngIf="selectCheckSendOutType" nzPlaceHolder="SendOut Type" nzShowSearch nzAllowClear [nzShowArrow]="true" class="componentdiv"
          [(ngModel)]="selectCheckSendOutTypeString" (ngModelChange)="onInputDesigner($event)">
          <nz-option *ngFor="let option of iSendOutType[0]?.data" [nzLabel]="option.description"
            [nzValue]="option.description"></nz-option>
        </nz-select>

        <nz-select *ngIf="selectCheckCelebInfluencModel" nzPlaceHolder="Celeb/Influenc/Model" nzShowSearch nzAllowClear [nzShowArrow]="true" class="componentdiv"
          [(ngModel)]="selectCheckCelebInfluencModelString" (ngModelChange)="onInputDesigner($event)">
          <nz-option *ngFor="let option of iCimResult[0]?.data" [nzLabel]="option.description"
            [nzValue]="option.description"></nz-option>
        </nz-select>

        <nz-select *ngIf="selectCheckProjectEvent" nzPlaceHolder="Project/Event" nzShowSearch nzAllowClear [nzShowArrow]="true" class="componentdiv"
          [(ngModel)]="selectCheckProjectEventString" (ngModelChange)="onInputDesigner($event)">
          <nz-option *ngFor="let option of iPEResult[0]?.data" [nzLabel]="option.description"
            [nzValue]="option.description"></nz-option>
        </nz-select>

        <nz-select *ngIf="selectCheckSender" nzPlaceHolder="Sender" nzShowSearch nzAllowClear [nzShowArrow]="true" class="componentdiv"
          [(ngModel)]="selectCheckSenderString" (ngModelChange)="onInputDesigner($event)">
          <nz-option *ngFor="let option of iSenderResult[0]?.data" [nzLabel]="option.description"
            [nzValue]="option.description"></nz-option>
        </nz-select>

        <input (input)="onInputDesigner(selectCheckDescriptionString)" class="componentdiv" *ngIf="selectCheckDescription" nz-input placeholder="Description" [(ngModel)]="selectCheckDescriptionString" />
        <input (input)="onInputDesigner(selectCheckShipToString)" class="componentdiv" *ngIf="selectCheckShipTo" nz-input placeholder="Ship To" [(ngModel)]="selectCheckShipToString" />

        <nz-select *ngIf="selectCheckSampleWorkflowStatus" nzPlaceHolder="Sample Workflow Status" nzShowSearch nzAllowClear [nzShowArrow]="true" class="componentdiv"
          [(ngModel)]="selectCheckSampleWorkflowStatusString" (ngModelChange)="onInputDesigner($event)">
          <nz-option *ngFor="let option of iSWSResult[0]?.data" [nzLabel]="option.description"
            [nzValue]="option.description"></nz-option>
        </nz-select>

        <nz-select *ngIf="selectCheckRegion" nzPlaceHolder="Region" nzShowSearch nzAllowClear [nzShowArrow]="true" class="componentdiv"
          [(ngModel)]="selectCheckRegionString" (ngModelChange)="onInputDesigner($event)">
          <nz-option *ngFor="let option of iRegionResult[0]?.data" [nzLabel]="option.description"
            [nzValue]="option.description"></nz-option>
        </nz-select>

        <nz-select *ngIf="selectCheckFabric" nzPlaceHolder="Fabric" nzShowSearch nzAllowClear [nzShowArrow]="true" class="componentdiv"
          [(ngModel)]="selectCheckFabricString" (ngModelChange)="onInputDesigner($event)">
          <nz-option *ngFor="let option of iFabricResult[0]?.data" [nzLabel]="option.description"
            [nzValue]="option.description"></nz-option>
        </nz-select>

        <nz-select *ngIf="selectCheckManufacturer" nzPlaceHolder="Manufacturer" nzShowSearch nzAllowClear [nzShowArrow]="true" class="componentdiv"
          [(ngModel)]="selectCheckManufacturerString" (ngModelChange)="onInputDesigner($event)">
          <nz-option *ngFor="let option of iManuResult[0]?.data" [nzLabel]="option.description"
            [nzValue]="option.description"></nz-option>
        </nz-select>

        <nz-select *ngIf="selectCheckMisc" nzPlaceHolder="Misc" nzShowSearch nzAllowClear [nzShowArrow]="true" class="componentdiv"
          [(ngModel)]="selectCheckMiscString" (ngModelChange)="onInputDesigner($event)">
          <nz-option *ngFor="let option of iMisc[0]?.data" [nzLabel]="option.description"
            [nzValue]="option.description"></nz-option>
        </nz-select>

        <nz-select *ngIf="selectCheckIssue" nzPlaceHolder="Issue" nzShowSearch nzAllowClear [nzShowArrow]="true" class="componentdiv"
          [(ngModel)]="selectCheckIssueString" (ngModelChange)="onInputDesigner($event)">
          <nz-option *ngFor="let option of iTODResult[0]?.data" [nzLabel]="option.description"
            [nzValue]="option.description"></nz-option>
        </nz-select>

        <input (input)="onInputDesigner(selectCheckTrackingNumberString)" class="componentdiv" *ngIf="selectCheckTrackingNumber" nz-input placeholder="Tracking Number" [(ngModel)]="selectCheckTrackingNumberString" />

        <nz-select *ngIf="selectCheckSize" nzPlaceHolder="Size" nzShowSearch nzAllowClear [nzShowArrow]="true" class="componentdiv"
          [(ngModel)]="selectCheckSizeString" (ngModelChange)="onInputDesigner($event)">
          <nz-option *ngFor="let option of iSize[0]?.data" [nzLabel]="option.description"
            [nzValue]="option.description"></nz-option>
        </nz-select>

        <nz-select *ngIf="selectCheckSizeRange" nzPlaceHolder="Size Range" nzShowSearch nzAllowClear [nzShowArrow]="true" class="componentdiv"
          [(ngModel)]="selectCheckSizeRangeString" (ngModelChange)="onInputDesigner($event)">
          <nz-option *ngFor="let option of iSizeRange[0]?.data" [nzLabel]="option.description"
            [nzValue]="option.description"></nz-option>
        </nz-select>

        <nz-select *ngIf="selectCheckPattern" nzPlaceHolder="Pattern" nzShowSearch nzAllowClear [nzShowArrow]="true" class="componentdiv"
          [(ngModel)]="selectCheckPatternstring" (ngModelChange)="onInputDesigner($event)">
          <nz-option *ngFor="let option of iPattern[0]?.data" [nzLabel]="option.description"
            [nzValue]="option.description"></nz-option>
        </nz-select>

        <nz-select (ngModelChange)="onInputGender($event)" *ngIf="selectCheckGender" nzPlaceHolder="Gender" nzShowSearch nzAllowClear [nzShowArrow]="true" class="componentdiv"
          [(ngModel)]="selectCheckgenderString">
          <nz-option *ngFor="let option of gender" [nzLabel]="option.value"
            [nzValue]="option.value"></nz-option>
        </nz-select>

        <input (input)="onInputDesigner(selectCheckNotesString)" class="componentdiv" *ngIf="selectCheckNotes" nz-input placeholder="Notes" [(ngModel)]="selectCheckNotesString" />

        <nz-select (ngModelChange)="onInputDesigner($event)" *ngIf="selectCheckPhotographer" nzPlaceHolder="Photographer" nzShowSearch nzAllowClear [nzShowArrow]="true" class="componentdiv"
          [(ngModel)]="selectCheckPhotographerString">
          <nz-option *ngFor="let option of iPhotograoher[0]?.data" [nzLabel]="option.description"
            [nzValue]="option.description"></nz-option>
        </nz-select>

        <button class="componentdiv" type="button" nz-button nzType="default" (click)="onClearAllFilters()">Clear All Filters</button>
      </div>
      <!--      <span class="p-input-icon-left ml-auto">-->
      <!--        <div class="p-inputgroup">-->
      <!--          <span class="p-inputgroup-addon">-->
      <!--            <i class="pi pi-search" style="color: #000000"></i>-->
      <!--          </span>-->
      <!--          <input pInputText type="text" (input)="onInput($event)" placeholder="Filter" [(ngModel)]="filtertext"/>-->
      <!--          <span class="p-inputgroup-addon custom-hover-red" matRipple (click)="clear(dt1)">-->
      <!--            <i class="pi pi-times" style="color: #000000"></i></span>-->
      <!--        </div>-->
      <!--      </span>-->
    </div>
    <div id="scrollable-div-dm" class="card example-container-sm mat-elevation-z3">
      <p-table
        [value]="iSendOutReportResult[0]?.data || []"
        #dt1
        [paginator]="true"
        [rows]="100"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [rowsPerPageOptions]="[100, 300, 500, 1000]"
        [styleClass]="'p-datatable-striped ' + selectedSize.class.toString()"
        selectionMode="single"
        [globalFilterFields]="['Designer', 'barcode', 'Collection', 'Season', 'Type', 'Color', 'Description']"
        [tableStyle]="{'min-width': '50rem '}">

        <!--        <ng-template pTemplate="caption">-->
        <!--          <div class="flex">-->
        <!--            <p-button class="info-btn" icon="pi pi-info" [rounded]="true" [outlined]="true"-->
        <!--              (click)="onInfoButtonClick()"/>-->
        <!--            -->
        <!--          </div>-->
        <!--        </ng-template>-->

        <ng-template pTemplate="header">
          <tr>
            <th style="width:5%" *ngIf="selectCheckDeliveryNumber || selectCheckTypeOfDispatch || selectCheckBarcode">
              <span *ngIf="selectCheckDeliveryNumber">Delivery #<br></span>
              <span *ngIf="selectCheckTypeOfDispatch">Type of Dispatch<br></span>
              <span *ngIf="selectCheckBarcode">Barcode</span>
            </th>
            <th style="width:5%" *ngIf="selectCheckDesigner || selectCheckCollection || selectCheckSeason">
              <span *ngIf="selectCheckDesigner">Designer<br></span>
              <span *ngIf="selectCheckCollection">Collection<br></span>
              <span *ngIf="selectCheckSeason">Season</span>
            </th>
            <th style="width:5%" *ngIf="selectCheckStyle || selectCheckType || selectCheckColor">
              <span *ngIf="selectCheckStyle">Style<br></span>
              <span *ngIf="selectCheckType">Type<br></span>
              <span *ngIf="selectCheckColor">Color</span>
            </th>
            <th style="width:5%" *ngIf="selectCheckCreateDate || selectCheckShipDate || selectCheckReturnDate">
              <span *ngIf="selectCheckCreateDate">Created Date<br></span>
              <span *ngIf="selectCheckShipDate">Ship Date<br></span>
              <span *ngIf="selectCheckReturnDate">Return Date</span>
            </th>
            <th style="width:5%" *ngIf="selectCheckCompanyName || selectCheckContact || selectCheckCelebriti">
              <span *ngIf="selectCheckCompanyName">Company<br></span>
              <span *ngIf="selectCheckContact">Contact<br></span>
              <span *ngIf="selectCheckCelebriti">Client</span>
            </th>
            <th style="width:5%" *ngIf="selectCheckPublication || selectCheckEditorStylist || selectCheckSendOutType">
              <span *ngIf="selectCheckPublication">Publication<br></span>
              <span *ngIf="selectCheckEditorStylist">Editor/Stylist<br></span>
              <span *ngIf="selectCheckSendOutType">SendOut Type</span>
            </th>
            <th style="width:1%" *ngIf="selectCheckCelebInfluencModel || selectCheckProjectEvent || selectCheckSender">
              <span *ngIf="selectCheckCelebInfluencModel">Celeb/Influenc/Model<br></span>
              <span *ngIf="selectCheckProjectEvent">Project/Event<br></span>
              <span *ngIf="selectCheckSender">Sender</span>
            </th>
            <th style="width:5%" *ngIf="selectCheckDescription || selectCheckShipTo || selectCheckSampleWorkflowStatus">
              <span *ngIf="selectCheckDescription">Description<br></span>
              <span *ngIf="selectCheckShipTo">Ship To<br></span>
              <span *ngIf="selectCheckSampleWorkflowStatus">Sample Workflow Status</span>
            </th>
            <th style="width:5%" *ngIf="selectCheckRegion || selectCheckFabric || selectCheckManufacturer">
              <span *ngIf="selectCheckRegion">Region<br></span>
              <span *ngIf="selectCheckFabric">Fabric<br></span>
              <span *ngIf="selectCheckManufacturer">Manufacturer</span>
            </th>
            <th style="width:5%" *ngIf="selectCheckMisc || selectCheckIssue || selectCheckTrackingNumber">
              <span *ngIf="selectCheckMisc">Misc<br></span>
              <span *ngIf="selectCheckIssue">Issue<br></span>
              <span *ngIf="selectCheckTrackingNumber">Tracking Number</span>
            </th>
            <th style="width:5%" *ngIf="selectCheckSize || selectCheckSizeRange || selectCheckPattern">
              <span *ngIf="selectCheckSize">Size<br></span>
              <span *ngIf="selectCheckSizeRange">Size Range<br></span>
              <span *ngIf="selectCheckPattern">Pattern</span>
            </th>
            <th style="width:5%" *ngIf="selectCheckGender || selectCheckNotes || selectCheckPhotographer">
              <span *ngIf="selectCheckGender">Gender<br></span>
              <span *ngIf="selectCheckNotes">Notes<br></span>
              <span *ngIf="selectCheckPhotographer">Photographer</span>
            </th>
            <th *ngIf="selectCheckImage" style="width:1%">Image</th>

          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-product>
          <tr [pSelectableRow]="product">
            <td *ngIf="selectCheckDeliveryNumber || selectCheckTypeOfDispatch || selectCheckBarcode">
              <span *ngIf="selectCheckDeliveryNumber">
                <span style="text-decoration: underline; color: #007bff;" (click)="createSendOut(product)">
                {{ product["Delivery #"] }}
              </span>
                <br></span>
              <span *ngIf="selectCheckTypeOfDispatch">{{ product["Type of Dispatch"] }}<br></span>
              <span *ngIf="selectCheckBarcode">{{ product["Barcode"] }}</span>
            </td>
            <td *ngIf="selectCheckDesigner || selectCheckCollection || selectCheckSeason">
              <span *ngIf="selectCheckDesigner">{{ product["Designer"] }}<br></span>
              <span *ngIf="selectCheckCollection">{{ product["Collection"] }}<br></span>
              <span *ngIf="selectCheckSeason">{{ product["Season"] }}</span>
            </td>
            <td *ngIf="selectCheckStyle || selectCheckType || selectCheckColor">
              <span *ngIf="selectCheckStyle">{{ product["Style"] }}<br></span>
              <span *ngIf="selectCheckType">{{ product["Type"] }}<br></span>
              <span *ngIf="selectCheckColor">{{ product["Color"] }}</span>
            </td>
            <td *ngIf="selectCheckCreateDate || selectCheckShipDate || selectCheckReturnDate">
              <span *ngIf="selectCheckCreateDate">{{ formatDate(product["Create Date"]) }}<br></span>
              <span *ngIf="selectCheckShipDate">{{ formatDate(product["Ship Date"]) }}<br></span>
              <span *ngIf="selectCheckReturnDate">{{ formatDate(product["Return Date"]) }}</span>
            </td>
            <td *ngIf="selectCheckCompanyName || selectCheckContact || selectCheckCelebriti">
              <span *ngIf="selectCheckCompanyName">{{ product["Companyname"] }}<br></span>
              <span *ngIf="selectCheckContact">{{ product["Contact"] }}<br></span>
              <span *ngIf="selectCheckCelebriti">{{ product["Celebriti"] }}</span>
            </td>
            <td *ngIf="selectCheckPublication || selectCheckEditorStylist || selectCheckSendOutType">
              <span *ngIf="selectCheckPublication">{{ product["Publication"] }}<br></span>
              <span *ngIf="selectCheckEditorStylist">{{ product["Editor/Stylist"] }}<br></span>
              <span *ngIf="selectCheckSendOutType">{{ product["RendOut Type"] }}</span>
            </td>
            <td *ngIf="selectCheckCelebInfluencModel || selectCheckProjectEvent || selectCheckSender">
              <span *ngIf="selectCheckCelebInfluencModel">{{ product["Celeb/Influenc/Model"] }}<br></span>
              <span *ngIf="selectCheckProjectEvent">{{ product["Project/Event"] }}<br></span>
              <span *ngIf="selectCheckSender">{{ product["Sender"] }}</span>
            </td>
            <td *ngIf="selectCheckDescription || selectCheckShipTo || selectCheckSampleWorkflowStatus">
              <span *ngIf="selectCheckDescription">{{ product["Description"] }}<br></span>
              <span *ngIf="selectCheckShipTo">{{ product["Ship To"] }}<br></span>
              <span *ngIf="selectCheckSampleWorkflowStatus">{{ product["Sample Workflow Status"] }}</span>
            </td>
            <td *ngIf="selectCheckRegion || selectCheckFabric || selectCheckManufacturer">
              <span *ngIf="selectCheckRegion">{{ product["Region"] }}<br></span>
              <span *ngIf="selectCheckFabric">{{ product["Fabric"] }}<br></span>
              <span *ngIf="selectCheckManufacturer">{{ product["Manufacturer"] }}</span>
            </td>
            <td *ngIf="selectCheckMisc || selectCheckIssue || selectCheckTrackingNumber">
              <span *ngIf="selectCheckMisc">{{ product["Misc"] }}<br></span>
              <span *ngIf="selectCheckIssue">{{ product["Issue"] }}<br></span>
              <span *ngIf="selectCheckTrackingNumber">{{ product["Tracking Number"] }}</span>
            </td>
            <td *ngIf="selectCheckSize || selectCheckSizeRange || selectCheckPattern">
              <span *ngIf="selectCheckSize">{{ product["Size"] }}<br></span>
              <span *ngIf="selectCheckSizeRange">{{ product["Size Range"] }}<br></span>
              <span *ngIf="selectCheckPattern">{{ product["Pattern"] }}</span>
            </td>
            <td *ngIf="selectCheckGender || selectCheckNotes || selectCheckPhotographer">
              <span *ngIf="selectCheckGender">{{ product["Gender"] }}<br></span>
              <span *ngIf="selectCheckNotes">{{ product["Notes"] }}<br></span>
              <span *ngIf="selectCheckPhotographer">{{ product["Photographer"] }}</span>
            </td>
            <td *ngIf="selectCheckImage"><img [src]="transformImageUrl(product['Image Link'])" alt="Image"
              class="overlay-content image-size-80x80 shadow-4"
              (click)="showFullWindowImage(transformImageUrl(product['Image Link']))"></td>
          </tr>
        </ng-template>

        <p-paginator #paginator></p-paginator>
      </p-table>
    </div>
  </div>
</div>

<div class="bootompanel">
  <button mat-raised-button class="button-settings-dm" type="button" (click)="onButtonEmailClick()">
    E-Mail
    <mat-icon>mail</mat-icon>
  </button>

  <button mat-raised-button class="button-settings-dm" type="button" (click)="onButtonPrintClick()">
    Print
    <mat-icon>print</mat-icon>
  </button>
</div>

<p-overlayPanel #op>
  <div class="mainop">
<!--    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckInventoryStatus">Inventory Status</label></div>-->
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckGender">Gender</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckSampleWorkflowStatus">Sample Workflow Status</label>
    </div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckRegion">Region</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckNotes">Notes</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckDesigner">Designer</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckFabric">Fabric</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckManufacturer">Manufacturer</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckCollection">Collection</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckStyle">Style</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckLooksAssociated">Looks Associated</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckSeason">Season</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckMisc">Misc</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckType">Type</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckSize">Size</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckSizeRange">Size Range</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckPattern">Pattern</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckColor">Color</label></div>
<!--    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckRetailPriceEUR">Retail Price EUR</label></div>-->
<!--    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckRetailPriceUSD">Retail Price USD</label></div>-->
<!--    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckWholesaleEUR">Wholesale EUR</label></div>-->
<!--    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckWholesaleUSD">Wholesale USD</label></div>-->
<!--    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckSalePriceEUR">Sale Price EUR</label></div>-->
<!--    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckSalePriceUSD">Sale Price USD</label></div>-->
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckDescription">Description</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckBarcode">Barcode</label></div>
<!--    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckUser">User</label></div>-->
<!--    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckReservedUser">ReservedUser</label></div>-->
<!--    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckBarcodePrefix">BarcodePrefix</label></div>-->
<!--    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckDepartment">Department</label></div>-->
<!--    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckShip">Ship</label></div>-->
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckShipTo">Ship To</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckTypeOfDispatch">Type of Dispatch</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckProjectEvent">Project/Event</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckCompanyName">CompanyName</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckCelebInfluencModel">Celeb/Influenc/Model</label>
    </div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckPublication">Publication</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckEditorStylist">Editor/Stylist</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckPhotographer">Photographer</label></div>
<!--    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckProjectEventLocation">Project/Event Location</label>-->
<!--    </div>-->
<!--    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckProjectEventTheme">Project/Event Theme</label></div>-->
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckIssue">Issue</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckTrackingNumber">Tracking Number</label></div>
<!--    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckReturn">Return</label></div>-->
<!--    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckSendOutNode">SendOut Node</label></div>-->
<!--    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckRequestedBy">Requested By</label></div>-->
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckSendOutType">SendOut Type</label></div>
<!--    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckCurrency">Currency</label></div>-->
<!--    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckReservationDate">Reservation Date</label></div>-->
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckReturnDate">Return Date</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckCreateDate">Create Date</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckSender">Sender</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckCelebriti">Client</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckImage">Image</label></div>
<!--    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckFilename">Filename</label></div>-->
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckDeliveryNumber">Delivery #</label></div>
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckContact">Contact</label></div>
<!--    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckSampleStatus">Sample Status</label></div>-->
    <div class="option"><label nz-checkbox [(ngModel)]="selectCheckShipDate">Ship Date</label></div>
  </div>

  <div class="applypanel">
    <p-button label="Apply" [rounded]="true" severity="success" (onClick)="closeOverlayPanel()"></p-button>
  </div>
</p-overlayPanel>

<p-confirmDialog #cd [style]="{width: '25vw'}" [closable]="true">
  <ng-template pTemplate="footer">
    <div class="button-container-request">
      <button mat-raised-button class="button-secondary" style="margin-right: 10px; width: 50%" (click)="cd.reject()">
        No
      </button>
      <button mat-raised-button class="button-primary" style="margin-right: 0; width: 50%" (click)="cd.accept()">
        Yes
      </button>
    </div>
  </ng-template>
</p-confirmDialog>

