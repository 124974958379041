import {Injectable, EventEmitter} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {AlertDialogData} from '../dialogs/messageboxdialog/messageboxdialog.component';
import {YourappsserviceService} from "./yourappsservice.service";
import {ServiceService} from "./service.service";
import {GetCheckVisibled, SelfData} from "../models/struct";

@Injectable({
  providedIn: 'root',
})
export class ModalserviceService {
  isLoginDialogVisibled$ = new BehaviorSubject<boolean>(false);
  isShowProgramms$ = new BehaviorSubject<boolean>(false);
  iRPMonitoring$ = new BehaviorSubject<boolean>(false);
  iEvents$ = new BehaviorSubject<boolean>(false);
  isSamples$ = new BehaviorSubject<boolean>(true);
  iContacts$ = new BehaviorSubject<boolean>(true);
  isRegistrationDialogVisibled$ = new BehaviorSubject<boolean>(false);
  isProfileVisibled$ = new BehaviorSubject<boolean>(false);
  isProfileEditVisibled$ = new BehaviorSubject<boolean>(false);
  isCodeVisibled$ = new BehaviorSubject<boolean>(false);
  isSampleManagerVisibled$ = new BehaviorSubject<boolean>(false);
  isSendOutPendingVisibled$ = new BehaviorSubject<boolean>(false);
  isSampleVisibled$ = new BehaviorSubject<boolean>(false);
  isSampleSaleVisibled$ = new BehaviorSubject<boolean>(false);
  isCompanyVisibled$ = new BehaviorSubject<boolean>(false);
  isSendOutVisibled$ = new BehaviorSubject<boolean>(false);
  isFullSendOutVisibled$ = new BehaviorSubject<boolean>(false);
  isFullRequestVisibled$ = new BehaviorSubject<boolean>(false);
  isNotificationSettingsVisibled$ = new BehaviorSubject<boolean>(false);
  isAddressVisibled$ = new BehaviorSubject<boolean>(false);
  isLookVisibled$ = new BehaviorSubject<boolean>(false);
  isInvoiceVisibled$ = new BehaviorSubject<boolean>(false);
  isLookDialogVisibled$ = new BehaviorSubject<boolean>(false);
  isSSVisibled$ = new BehaviorSubject<boolean>(false);
  isInventaryVisibled$ = new BehaviorSubject<boolean>(false);
  isEditLabelVisibled$ = new BehaviorSubject<boolean>(false);
  isRRVisibled$ = new BehaviorSubject<boolean>(false);
  isRRCVisibled$ = new BehaviorSubject<boolean>(false);
  isScannVisibled$ = new BehaviorSubject<boolean>(false);
  isPSTVisibled$ = new BehaviorSubject<boolean>(false);
  isCPSVisibled$ = new BehaviorSubject<boolean>(false);
  isSRVisibled$ = new BehaviorSubject<boolean>(false);
  isRequestVisibled$ = new BehaviorSubject<boolean>(false);
  isCDLListVisibled$ = new BehaviorSubject<boolean>(false);
  isVasiaVisibled$ = new BehaviorSubject<boolean>(false);
  isVasiaFrameVisibled$ = new BehaviorSubject<boolean>(false);
  islbVisibled$ = new BehaviorSubject<boolean>(false);
  islsbVisibled$ = new BehaviorSubject<boolean>(false);
  islsb1Visibled$ = new BehaviorSubject<boolean>(false);
  isfavoriteVisibled$ = new BehaviorSubject<boolean>(false);
  iscatalogslistVisibled$ = new BehaviorSubject<boolean>(false);
  iscatalogslist1Visibled$ = new BehaviorSubject<boolean>(false);
  islsbframeVisibled$ = new BehaviorSubject<boolean>(false);
  islbframeVisibled$ = new BehaviorSubject<boolean>(false);
  isimageviewVisibled$ = new BehaviorSubject<boolean>(false);
  isimageview1Visibled$ = new BehaviorSubject<boolean>(false);
  isvcVisibled$ = new BehaviorSubject<boolean>(false);
  iAIVisibled$ = new BehaviorSubject<boolean>(false);
  iTestPrinterVisibled$ = new BehaviorSubject<boolean>(false);
  icsVisibled$ = new BehaviorSubject<boolean>(false);
  ischeduleVisibled$ = new BehaviorSubject<boolean>(false);
  ishipdateVisibled$ = new BehaviorSubject<boolean>(false);
  iCCVisibled$ = new BehaviorSubject<boolean>(false);
  isprVisibled$ = new BehaviorSubject<boolean>(false);
  isoVisibled$ = new BehaviorSubject<boolean>(false);
  iAIChoosVisibled$ = new BehaviorSubject<boolean>(false);
  isviVisibled$ = new BehaviorSubject<boolean>(false);
  isvc1Visibled$ = new BehaviorSubject<boolean>(false);
  isCIVisibled$ = new BehaviorSubject<boolean>(false);
  isSSOVisibled$ = new BehaviorSubject<boolean>(false);
  isSSLVisibled$ = new BehaviorSubject<boolean>(false);
  isCFVisibled$ = new BehaviorSubject<boolean>(false);
  isSignatureVisibled$ = new BehaviorSubject<boolean>(false);
  isEditorVisibled$ = new BehaviorSubject<boolean>(false);
  isEmailEditorVisibled$ = new BehaviorSubject<boolean>(false);
  isorVisibled$ = new BehaviorSubject<boolean>(false);
  taxesVisibled$ = new BehaviorSubject<boolean>(false);
  customersframeVisibled$ = new BehaviorSubject<boolean>(false);
  aesframeVisibled$ = new BehaviorSubject<boolean>(false);
  userVisibled$ = new BehaviorSubject<boolean>(false);
  profilelistVisibled$ = new BehaviorSubject<boolean>(false);
  profileframeVisibled$ = new BehaviorSubject<boolean>(false);
  productframeVisibled$ = new BehaviorSubject<boolean>(false);
  scframeVisibled$ = new BehaviorSubject<boolean>(false);
  ssframeVisibled$ = new BehaviorSubject<boolean>(false);
  srframeVisibled$ = new BehaviorSubject<boolean>(false);
  suppliersframeVisibled$ = new BehaviorSubject<boolean>(false);
  isEmailDetailsViewVisibled$ = new BehaviorSubject<boolean>(false);
  isEmailContactsVisibled$ = new BehaviorSubject<boolean>(false);
  isEmailAddVisibled$ = new BehaviorSubject<boolean>(false);
  isEmailVisibled$ = new BehaviorSubject<boolean>(false);
  isEmailSignVisibled$ = new BehaviorSubject<boolean>(false);
  isDeleteVisibled$ = new BehaviorSubject<boolean>(false);
  isPrinterVisibled$ = new BehaviorSubject<boolean>(false);
  isContactVisibled$ = new BehaviorSubject<boolean>(false);
  isSWS$ = new BehaviorSubject<boolean>(false);
  isCollection$ = new BehaviorSubject<boolean>(false);
  isHomePanelVisibled$ = new BehaviorSubject<boolean>(true);
  isSetupGeneralVisibled$ = new BehaviorSubject<boolean>(false);
  isOutletsVisibled$ = new BehaviorSubject<boolean>(false);
  isCustomerVisibled$ = new BehaviorSubject<boolean>(false);
  isSupplierVisibled$ = new BehaviorSubject<boolean>(false);
  isProductVisibled$ = new BehaviorSubject<boolean>(false);
  isSCVisibled$ = new BehaviorSubject<boolean>(false);
  iSSVisibled$ = new BehaviorSubject<boolean>(false);
  isalesreportVisibled$ = new BehaviorSubject<boolean>(false);
  userslistVisibled$ = new BehaviorSubject<boolean>(false);
  isImageZoom$ = new BehaviorSubject<boolean>(false);
  isSeason$ = new BehaviorSubject<boolean>(false);
  isSettings$ = new BehaviorSubject<boolean>(false);
  isSampleHeader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  isLookList$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isSampleLabel$ = new BehaviorSubject<boolean>(false);
  isRequestLabel$ = new BehaviorSubject<boolean>(false);
  isSelectPullSheet$ = new BehaviorSubject<boolean>(false);
  iCMVisible$ = new BehaviorSubject<boolean>(false);
  isSendoutList$ = new BehaviorSubject<boolean>(false);
  isSendoutPendingList$ = new BehaviorSubject<boolean>(true);
  isContactLabel$ = new BehaviorSubject<boolean>(false);
  isInventoryLabel$ = new BehaviorSubject<boolean>(false);
  isSampleGrid$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isSendOutStep1$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  isSendOutStep2$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  isSendOutStep3$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  isFieldEditor$ = new BehaviorSubject<boolean>(false);
  isAddEdit$ = new BehaviorSubject<boolean>(false);
  isDragDrop$ = new BehaviorSubject<boolean>(false);
  private isMessageBoxVisibleSource = new BehaviorSubject<boolean>(false);
  isMessageBoxVisible$ = this.isMessageBoxVisibleSource.asObservable();
  private alertDialogDataSource = new BehaviorSubject<AlertDialogData>({
    title: '',
    message: '',
    buttonneg: '',
    buttonpos: '',
  });
  alertDialogData$ = this.alertDialogDataSource.asObservable();
  frameTypeChanged = new EventEmitter<number>();


  open(frameType: number) {
    this.frameTypeChanged.emit(frameType);
    this.isLoginDialogVisibled$.next(true);
  }

  close() {
    this.isLoginDialogVisibled$.next(false);
  }

  registrationopen() {
    this.isRegistrationDialogVisibled$.next(true);
  }

  registrationclose() {
    this.isRegistrationDialogVisibled$.next(false);
  }

  homepanelshow() {
    this.isHomePanelVisibled$.next(true);
  }

  homepanelclose() {
    this.isHomePanelVisibled$.next(false);
  }

  setupgeneralopen() {
    this.isSetupGeneralVisibled$.next(true);
  }

  setupgeneralclose() {
    this.isSetupGeneralVisibled$.next(false);
  }

  outletsopen() {
    this.isOutletsVisibled$.next(true);
  }

  outletsclose() {
    this.isOutletsVisibled$.next(false);
  }

  customeropen() {
    this.isCustomerVisibled$.next(true);
  }

  customerclose() {
    this.isCustomerVisibled$.next(false);
  }

  supplieropen() {
    this.isSupplierVisibled$.next(true);
  }

  supplierclose() {
    this.isSupplierVisibled$.next(false);
  }

  productopen() {
    this.isProductVisibled$.next(true);
  }

  productclose() {
    this.isProductVisibled$.next(false);
  }

  scopen() {
    this.isSCVisibled$.next(true);
  }

  scclose() {
    this.isSCVisibled$.next(false);
  }

  ssopen() {
    this.iSSVisibled$.next(true);
  }

  ssclose() {
    this.iSSVisibled$.next(false);
  }

  salesreportopen() {
    this.isalesreportVisibled$.next(true);
  }

  salesreportclose() {
    this.isalesreportVisibled$.next(false);
  }

  userslistopen() {
    this.userslistVisibled$.next(true);
  }

  userslistclose() {
    this.userslistVisibled$.next(false);
  }

  openMessageBox(
    title: string,
    message: string,
    buttonneg: string,
    buttonpos: string
  ) {
    this.alertDialogDataSource.next({title, message, buttonneg, buttonpos});
    this.isMessageBoxVisibleSource.next(true);
  }

  closeMessageBox() {
    this.isMessageBoxVisibleSource.next(false);
  }

  programmsshow() {
    this.isShowProgramms$.next(true);
  }

  rpmonitoringshow() {
    this.iRPMonitoring$.next(true);
  }

  rpmonitorinclose() {
    this.iRPMonitoring$.next(false);
  }

  eventsshow() {
    this.iEvents$.next(true);
  }

  eventsclose() {
    this.iEvents$.next(false);
  }

  samplesshow() {
    this.isSamples$.next(true);
  }

  samplesclose() {
    this.isSamples$.next(false);
  }

  contactsshow() {
    this.iContacts$.next(true);
  }

  contactsclose() {
    this.iContacts$.next(false);
  }

  programmsclose() {
    this.isShowProgramms$.next(false);
  }

  profileopen() {
    this.isProfileVisibled$.next(true);
  }

  profileclose() {
    this.isProfileVisibled$.next(false);
  }

  profileeditopen() {
    this.isProfileEditVisibled$.next(true);
  }

  profileeditclose() {
    this.isProfileEditVisibled$.next(false);
  }

  codeopen() {
    this.isCodeVisibled$.next(true);
  }

  codeclose() {
    this.isCodeVisibled$.next(false);
  }

  samplemanageropen() {
    this.isSampleManagerVisibled$.next(true);
  }

  samplemanagerclose() {
    this.isSampleManagerVisibled$.next(false);
  }

  sendoutpendingopen() {
    this.isSendOutPendingVisibled$.next(true);
  }

  sendoutpendingclose() {
    this.isSendOutPendingVisibled$.next(false);
  }

  createsampleropen() {
    this.isSampleVisibled$.next(true);
  }

  createsamplerclose() {
    this.isSampleVisibled$.next(false);
  }

  createsamplesaleopen() {
    this.isSampleSaleVisibled$.next(true);
  }

  createsamplesaleclose() {
    this.isSampleSaleVisibled$.next(false);
  }

  createcompanyopen() {
    this.isCompanyVisibled$.next(true);
  }

  createcompanyclose() {
    this.isCompanyVisibled$.next(false);
  }

  createsendoutopen() {
    this.isSendOutVisibled$.next(true);
  }

  createsendoutclose() {
    this.isSendOutVisibled$.next(false);
  }

  createfullsendoutopen() {
    this.isFullSendOutVisibled$.next(true);
  }

  createfullsendoutclose() {
    this.isFullSendOutVisibled$.next(false);
  }

  createfullrequestopen() {
    this.isFullRequestVisibled$.next(true);
  }

  createfullrequestclose() {
    this.isFullRequestVisibled$.next(false);
  }

  createaddressopen() {
    this.isAddressVisibled$.next(true);
  }

  createaddressclose() {
    this.isAddressVisibled$.next(false);
  }

  openCC() {
    this.iCCVisibled$.next(true);
  }

  closeCC() {
    this.iCCVisibled$.next(false);
  }

  openspr() {
    SelfData.reportmode = 0
    this.isprVisibled$.next(true);
  }

  closespr() {
    this.isprVisibled$.next(false);
  }

  openso() {
    SelfData.reportmode = 1
    this.isoVisibled$.next(true);
  }

  closeso() {
    this.isoVisibled$.next(false);
  }

  openAI() {
    this.iAIVisibled$.next(true);
  }

  closeAI() {
    this.iAIVisibled$.next(false);
  }

  openCS() {
    this.icsVisibled$.next(true);
  }

  closeCS() {
    this.icsVisibled$.next(false);
  }

  openschedule() {
    this.ischeduleVisibled$.next(true);
  }

  closeschedule() {
    this.ischeduleVisibled$.next(false);
  }

  openshipdate() {
    this.ishipdateVisibled$.next(true);
  }

  closeshipdate() {
    this.ishipdateVisibled$.next(false);
  }

  openAIChoos() {
    this.iAIChoosVisibled$.next(true);
  }

  closeAIChoos() {
    this.iAIChoosVisibled$.next(false);
  }

  createlookopen() {
    this.isLookVisibled$.next(true);
  }

  createlooklose() {
    this.isLookVisibled$.next(false);
  }

  invoiceOpen() {
    this.isInvoiceVisibled$.next(true)
  }

  invoiceClose() {
    this.isInvoiceVisibled$.next(false)
  }

  lookopen() {
    this.isLookDialogVisibled$.next(true);
  }

  lookclose() {
    this.isLookDialogVisibled$.next(false);
  }

  createssopen() {
    this.isSSVisibled$.next(true);
  }

  createssclose() {
    this.isSSVisibled$.next(false);
  }

  inventaryopen() {
    this.isInventaryVisibled$.next(true);
  }

  inventaryclose() {
    this.isInventaryVisibled$.next(false);
  }

  openEditlabel() {
    this.isEditLabelVisibled$.next(true);
  }

  closeEditLabel() {
    this.isEditLabelVisibled$.next(false);
  }

  openRR() {
    this.isRRVisibled$.next(true);
  }

  closeRR() {
    this.isRRVisibled$.next(false);
  }

  openRRC() {
    this.isRRCVisibled$.next(true);
  }

  closeRRC() {
    this.isRRCVisibled$.next(false);
  }

  openScann() {
    this.isScannVisibled$.next(true);
  }

  closeScann() {
    this.isScannVisibled$.next(false);
  }

  openPullSheetTemplate() {
    this.isPSTVisibled$.next(true);
  }

  closePullSheetTemplate() {
    this.isPSTVisibled$.next(false);
  }

  openCPS() {
    this.isCPSVisibled$.next(true);
  }

  closeCPS() {
    this.isCPSVisibled$.next(false);
  }

  openSR() {
    this.isSRVisibled$.next(true);
  }

  closeSR() {
    this.isSRVisibled$.next(false);
  }

  openRequest() {
    this.isRequestVisibled$.next(true);
  }

  closeReques() {
    this.isRequestVisibled$.next(false);
  }

  openCDL() {
    this.isCDLListVisibled$.next(true);
  }

  closeCDL() {
    this.isCDLListVisibled$.next(false);
  }

  openVasia() {
    this.isVasiaVisibled$.next(true);
  }

  closeVasia() {
    this.isVasiaVisibled$.next(false);
  }

  openVasiaFrame() {
    this.isVasiaFrameVisibled$.next(true);
  }

  closeVasiaFrame() {
    this.isVasiaFrameVisibled$.next(false);
  }

  openlb() {
    this.islbVisibled$.next(true);
  }

  closelb() {
    this.islbVisibled$.next(false);
  }

  openlsb() {
    this.islsbVisibled$.next(true);
  }

  closelsb() {

    this.islsbVisibled$.next(false);
  }

  openlsb1() {
    this.islsb1Visibled$.next(true);
  }

  closelsb1() {
    this.islsb1Visibled$.next(false);
  }

  openfavorite() {
    this.isfavoriteVisibled$.next(true);
  }

  closefavorite() {
    this.isfavoriteVisibled$.next(false);
  }

  openCatalogsList() {
    this.iscatalogslistVisibled$.next(true);
  }

  closeCatalogsList() {
    this.iscatalogslistVisibled$.next(false);
  }

  openCatalogsList1() {
    this.iscatalogslist1Visibled$.next(true);
  }

  closeCatalogsList1() {
    this.iscatalogslist1Visibled$.next(false);
  }

  openlsbframe() {
    this.islsbframeVisibled$.next(true);
  }

  closelsbframe() {
    this.islsbframeVisibled$.next(false);
  }

  openlbframe() {
    this.islbframeVisibled$.next(true);
  }

  closelbframe() {
    this.islbframeVisibled$.next(false);
  }

  openivframe() {
    this.isimageviewVisibled$.next(true);
  }

  closeivframe() {
    this.isimageviewVisibled$.next(false);
  }

  openiv1frame() {
    this.isimageview1Visibled$.next(true);
  }

  closeiv1frame() {
    this.isimageview1Visibled$.next(false);
  }

  openvcframe() {
    this.isvcVisibled$.next(true);
  }

  closevcframe() {
    this.isvcVisibled$.next(false);
  }

  opentestprinter() {
    this.iTestPrinterVisibled$.next(true);
  }

  closetestprinter() {
    this.iTestPrinterVisibled$.next(false);
  }

  openvi() {
    this.isviVisibled$.next(true);
  }

  closevi() {
    this.isviVisibled$.next(false);
  }

  openvc1frame() {
    this.isvc1Visibled$.next(true);
  }

  closevc1frame() {
    this.isvc1Visibled$.next(false);
  }

  openciframe() {
    this.isCIVisibled$.next(true);
  }

  closeciframe() {
    this.isCIVisibled$.next(false);
  }

  openSSO() {
    this.isSSOVisibled$.next(true);
  }

  closeSSO() {
    this.isSSOVisibled$.next(false);
  }

  openSSL() {
    this.isSSLVisibled$.next(true);
  }

  closeSSL() {
    this.isSSLVisibled$.next(false);
  }

  openCF() {
    this.isCFVisibled$.next(true);
  }

  closeCF() {
    this.isCFVisibled$.next(false);
  }

  openSignature() {
    this.isSignatureVisibled$.next(true);
    // document.body.style.overflow = 'hidden';
  }

  closeSignature() {
    this.isSignatureVisibled$.next(false);
    // document.body.style.overflow = 'auto';
  }

  openEditor() {
    this.isEditorVisibled$.next(true);
  }

  closeEditor() {
    this.isEditorVisibled$.next(false);
  }

  openEmailEditor() {
    console.log("Test")
    this.isEmailEditorVisibled$.next(true);
  }

  closeEmailEditor() {
    this.isEmailEditorVisibled$.next(false);
  }

  orOpen() {
    this.isorVisibled$.next(true);
  }

  orClose() {
    this.isorVisibled$.next(false);
  }

  taxesOpen() {
    this.taxesVisibled$.next(true);
  }

  taxesClose() {
    this.taxesVisibled$.next(false);
  }

  customersFrameOpen() {
    this.customersframeVisibled$.next(true);
  }

  customersFrameClose() {
    this.customersframeVisibled$.next(false);
  }

  aesOpen() {
    this.aesframeVisibled$.next(true);
  }

  aesClose() {
    this.aesframeVisibled$.next(false);
  }

  userOpen() {
    this.userVisibled$.next(true);
  }

  userClose() {
    this.userVisibled$.next(false);
  }

  profilelistOpen() {
    this.profilelistVisibled$.next(true);
  }

  profilelistClose() {
    this.profilelistVisibled$.next(false);
  }

  profileframeOpen() {
    this.profileframeVisibled$.next(true);
  }

  profileframeClose() {
    this.profileframeVisibled$.next(false);
  }

  productFrameOpen() {
    this.productframeVisibled$.next(true);
  }

  productFrameClose() {
    this.productframeVisibled$.next(false);
  }

  scFrameOpen() {
    this.scframeVisibled$.next(true);
  }

  scFrameClose() {
    this.scframeVisibled$.next(false);
  }

  ssFrameOpen() {
    this.ssframeVisibled$.next(true);
  }

  ssFrameClose() {
    this.ssframeVisibled$.next(false);
  }

  srFrameOpen() {
    this.srframeVisibled$.next(true);
  }

  srFrameClose() {
    this.srframeVisibled$.next(false);
  }

  suppliersFrameOpen() {
    this.suppliersframeVisibled$.next(true);
  }

  suppliersFrameClose() {
    this.suppliersframeVisibled$.next(false);
  }

  openEmailDetailsView() {
    this.isEmailDetailsViewVisibled$.next(true);
  }

  closeEmailDetailsView() {
    this.isEmailDetailsViewVisibled$.next(false);
  }

  openEmailContacts() {
    this.isEmailContactsVisibled$.next(true);
  }

  closeEmailContacts() {
    this.isEmailContactsVisibled$.next(false);
  }

  openEmailAdd() {
    this.isEmailAddVisibled$.next(true);
  }

  closeEmailAdd() {
    this.isEmailAddVisibled$.next(false);
  }

  openEmailSign() {
    this.isEmailSignVisibled$.next(true);
  }

  closeEmailSign() {
    this.isEmailSignVisibled$.next(false);
  }

  openEmail() {
    this.isEmailVisibled$.next(true);
  }

  closeEmail() {
    this.isEmailVisibled$.next(false);
  }

  openDeleteFrame() {
    this.isDeleteVisibled$.next(true);
  }

  closeDeleteFrame() {
    this.isDeleteVisibled$.next(false);
  }

  openPrinterFrame() {
    this.isPrinterVisibled$.next(true);
  }

  closePrinterFrame() {
    this.isPrinterVisibled$.next(false);
  }

  createnotsettingsopen() {
    this.isNotificationSettingsVisibled$.next(true);
  }

  createnotsettingsclose() {
    this.isNotificationSettingsVisibled$.next(false);
  }

  createcontactopen() {
    this.isContactVisibled$.next(true);
  }

  createcontactclose() {
    this.isContactVisibled$.next(false);
  }

  createSWS() {
    this.isSWS$.next(true);
  }

  closeSWS() {
    this.isSWS$.next(false);
  }

  createCollection() {
    this.isCollection$.next(true);
  }

  closeCollection() {
    this.isCollection$.next(false);
  }

  createimagezoom() {
    this.isImageZoom$.next(true);
  }

  closeimagezoom() {
    this.isImageZoom$.next(false);
  }

  createSeason() {
    this.isSeason$.next(true);
  }

  closeSeason() {
    this.isSeason$.next(false);
  }

  createSettings() {
    this.isSettings$.next(true);
  }

  closeSettings() {
    this.isSettings$.next(false);
  }

  createFieldsEditor() {
    this.isFieldEditor$.next(true);
  }

  closeFieldsEditor() {
    this.isFieldEditor$.next(false);
  }

  createAddEdit() {
    this.isAddEdit$.next(true);
  }

  closeAddEdit() {
    this.isAddEdit$.next(false);
  }

  createDragDrop() {
    this.isDragDrop$.next(true);
  }

  closeDragDrop() {
    this.isDragDrop$.next(false);
  }

  createSampleHeader() {
    this.isSampleHeader$.next(true);
  }

  closeSampleHeader() {
    this.isSampleHeader$.next(false);
  }

  createLookList() {
    this.isLookList$.next(true);
  }

  closeLookList() {
    this.isLookList$.next(false);
  }

  createReuestList() {
    this.isRequestLabel$.next(true);
  }

  closeReuestList() {
    this.isRequestLabel$.next(false);
  }

  createSampleLabel() {
    this.isSampleLabel$.next(true);
  }

  selectPullsheetopen() {
    this.isSelectPullSheet$.next(true)
  }

  selectPullsheetcolse() {
    this.isSelectPullSheet$.next(false)
  }

  cmopen() {
    this.iCMVisible$.next(true)
  }

  cmclose() {
    this.iCMVisible$.next(false)
  }

  sendoutlistopen() {
    this.isSendoutList$.next(true);
  }

  sendoutlistclose() {
    this.isSendoutList$.next(false);
  }

  sendoutpendinglistopen() {
    this.isSendoutPendingList$.next(true);
  }

  sendoutpendinglistclose() {
    this.isSendoutPendingList$.next(false);
  }

  createContactLabel() {
    this.isContactLabel$.next(true);
  }

  closeContactLabel() {
    this.isContactLabel$.next(false);
  }

  createInventoryLabel() {
    this.isInventoryLabel$.next(true);
  }

  closeInventoryLabel() {
    this.isInventoryLabel$.next(false);
  }

  closeSampleGrid() {
    this.isSampleGrid$.next(false);
  }

  createSampleGrid() {
    this.isSampleGrid$.next(true);
  }

  closeSendOutStep1() {
    this.isSendOutStep1$.next(false);
  }

  createSendOutStep1() {
    this.isSendOutStep1$.next(true);
  }

  closeSendOutStep2() {
    this.isSendOutStep2$.next(false);
  }

  createSendOutStep2() {
    this.isSendOutStep2$.next(true);
  }

  closeSendOutStep3() {
    this.isSendOutStep3$.next(false);
  }

  createSendOutStep3() {
    this.isSendOutStep3$.next(true);
  }

  closeSampleLabel() {
    this.isSampleLabel$.next(false);
  }

  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
      ? SelfData.account[0].data[0].id
      : SelfData.account[0].data[0].parentid;
  }

  constructor(
    private serviceservice: ServiceService,
    private yourappsService: YourappsserviceService
  ) {

  }
}
