import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder} from "@angular/forms";
import {HashService} from "../../services/hash.service";
import {MessageboxComponent} from "../../modals/messagebox/messagebox.component";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {HttpClient} from "@angular/common/http";
import * as moment from "moment-timezone";
import {OutletSettingsDetails, OutletSettingsResponse, SelfData, TagsListData} from "../../models/struct";
import {forkJoin, Subscription} from "rxjs";
import {NzNotificationService} from "ng-zorro-antd/notification";

interface TimeZone {
  viewvalue: string;
  value: string;
}

@Component({
  selector: 'app-ordialog',
  templateUrl: './ordialog.component.html',
  styleUrl: './ordialog.component.scss'
})
export class OrdialogComponent implements OnInit, AfterViewInit, OnDestroy {
  current = 0;
  address: string = "";
  address2: string = "";
  suburb: string = "";
  city: string = "";
  countries: any[] = [];
  states: any[] = [];
  pcountries: any[] = [];
  pstates: any[] = [];
  countrystring: string = "";
  pstatesstring: string = "";
  pcountrystring: string = "";
  statesstring: string = "";
  zipcode: string = "";
  defaulttimezone: string = "";
  timezone: TimeZone[] = [];
  outletname: string = "";
  defaultsalestax: string = "";
  register: string = "";
  paymenttypecash: string = "Cash";
  paymenttypeother: string = "Other payment method";
  radioValue: string = "0";
  limit: number = 0;
  loyalty: number = 0;
  setid: number = 0;
  opermode: number = 0;

  formatterPercent = (value: number): string => `${value} %`;
  parserPercent = (value: string): string => value.replace(' %', '');

  private subscription?: Subscription;

  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private hashService: HashService,
    public messageBox: MessageboxComponent,
    private serviceservice: ServiceService,
    private yourappsService: YourappsserviceService,
    private notification: NzNotificationService,
    private _snackBar: MatSnackBar,
    private http: HttpClient,
    private cdr: ChangeDetectorRef
  ) {
    const timezoneNames = moment.tz.names();

    // id: tid, // Replace with actual id
    //   street1: this.address.trim(),
    //   street2: this.address2.trim(),
    //   suburb: this.suburb.trim(),
    //   city: this.city.trim(),
    //   zipCode: this.zipcode.trim(),
    //   state: this.statesstring.trim(),
    //   country: this.countrystring.trim(),
    //   timeZone: this.defaulttimezone.trim(),
    //   outletName: this.outletname.trim(),
    //   registers: this.register.trim(),
    //   paymentTypeCash: this.paymenttypecash.trim(),
    //   paymentTypeOther: this.paymenttypeother.trim(),
    //   paymentTypeCashIndex: 1, // Значение можно менять при необходимости
    //   paymentTypeOtherIndex: 1, // Значение можно менять при необходимости
    //   onAccountBalance: this.radioValue.trim(),
    //   balanceLimit: this.limit,
    //   loyaltyEarnedPerSale: this.loyalty,
    //   isActivity: true, // Можно поменять при необходимости
    //   ctrl: tctrl, // Значение может изменяться в зависимости от контекста
    //   accountid: this.getAccountID()
    this.setid = 0
    this.opermode = 0
    this.subscription = this.yourappsService.selectedOutLetList$.subscribe((value) => {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      if (value?.type === 1) {
        this.opermode = 1
        if (value.data && typeof value.data === 'object') {
          this.setid = (value.data as any).ID
          this.address = (value.data as any).Street1
          this.address2 = (value.data as any).Street2
          this.suburb = (value.data as any).Suburb
          this.city = (value.data as any).City
          this.zipcode = (value.data as any).ZipCode
          this.statesstring = (value.data as any).State
          this.countrystring = (value.data as any).Country
          this.defaulttimezone = (value.data as any).TimeZone
          this.outletname = (value.data as any).OutletName
          this.register = (value.data as any).Registers
          this.paymenttypecash = (value.data as any).PaymentTypeCash
          this.paymenttypeother = (value.data as any).PaymentTypeOther
          this.radioValue = (value.data as any).OnAccountBalance
          this.limit = (value.data as any).BalanceLimit
          this.loyalty = (value.data as any).LoyaltyEarnedPerSale
          console.log("✅ Объект:", value.data);
        }
      }
    })

    // this.timezone = timezoneNames.map((tz) => {
    //   const offset = moment.tz(tz).utcOffset(); // Смещение в минутах
    //   const hours = Math.floor(Math.abs(offset) / 60).toString().padStart(2, '0');
    //   const minutes = Math.abs(offset) % 60 === 0 ? '00' : (Math.abs(offset) % 60).toString().padStart(2, '0');
    //   const formattedOffset = `${offset >= 0 ? '+' : '-'}${hours}:${minutes}`;
    //   return { viewvalue: `(GMT${formattedOffset}) ${tz}`, value: formattedOffset };
    // });
    // console.log(this.timezone)
    // setTimeout(() => {
    //   this.cdr.detectChanges();  // Обновление представления после полной отрисовки
    // });
  }
  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    setTimeout(() => {  // Даем Angular время на инициализацию
      const timezoneNames = moment.tz.names();

      this.timezone = timezoneNames.map((tz) => {
        const offset = moment.tz(tz).utcOffset();
        const hours = Math.floor(Math.abs(offset) / 60).toString().padStart(2, '0');
        const minutes = Math.abs(offset) % 60 === 0 ? '00' : (Math.abs(offset) % 60).toString().padStart(2, '0');
        const formattedOffset = `${offset >= 0 ? '+' : '-'}${hours}:${minutes}`;
        return { viewvalue: `(GMT${formattedOffset}) ${tz}`, value: formattedOffset };
      });

      console.log(this.timezone); // Проверяем, загружаются ли данные
      this.cdr.detectChanges();  // Принудительное обновление UI
    }, 100);

    this.http.get<any>('assets/countries.json').subscribe((data) => {
      this.countries = data.countries;
      if (this.opermode === 0) {
        this.countrystring = "United States"
      }
      this.pcountries = data.countries;
      if (this.opermode === 0) {
        this.pcountrystring = "United States"
      }
      // this.countriControl.setValue('United States');
      // this.countri1Control.setValue('United States');
      // this.countri2Control.setValue('United States');
      this.http.get<any>('assets/us-states.json').subscribe((data) => {
        this.states = data;
        if (this.opermode === 0) {
          this.onOptionSelected(this.countrystring)
        }
        this.pstates = data;
        if (this.opermode === 0) {
          this.onOptionSelectedp(this.pcountrystring)
        }
      })
    })
  }

  onOptionSelected(event: string): void {
    const countryId = this.getCountryIdByName(event);
    this.states = [];
    this.statesstring = "";
    if (countryId == 187) {
      this.http.get<any>('assets/us-states.json').subscribe((data) => {
        this.states = data;
      });
    }
  }

  getCountryIdByName(countryName: string): number | null {
    const country = this.countries.find((c) => c.country === countryName);
    return country ? country.id : null;
  }

  onOptionSelectedp(event: string) {
    const countryId = this.getCountryIdByName(event);
    this.pstates = [];
    this.pstatesstring = "";
    if (countryId == 187) {
      this.http.get<any>('assets/us-states.json').subscribe((data) => {
        this.pstates = data;
      });
    }
  }

  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
      ? SelfData.account[0].data[0].id
      : SelfData.account[0].data[0].parentid;
  }

  next() {
    this.current += 1;
  }

  pre() {
    this.current -= 1;
  }

  done() {
    let tid: number = this.setid
    let tctrl: number = this.opermode
    console.log("radioValue", this.radioValue)
    const tempcatalogDetails: OutletSettingsDetails = {
      id: tid, // Replace with actual id
      street1: this.address,
      street2: this.address2,
      suburb: this.suburb,
      city: this.city,
      zipCode: this.zipcode,
      state: this.statesstring,
      country: this.countrystring,
      timeZone: this.defaulttimezone,
      outletName: this.outletname,
      registers: this.register,
      paymentTypeCash: this.paymenttypecash,
      paymentTypeOther: this.paymenttypeother,
      paymentTypeCashIndex: 1, // Значение можно менять при необходимости
      paymentTypeOtherIndex: 1, // Значение можно менять при необходимости
      onAccountBalance: this.radioValue,
      balanceLimit: this.limit,
      loyaltyEarnedPerSale: this.loyalty,
      isActivity: true, // Можно поменять при необходимости
      ctrl: tctrl, // Значение может изменяться в зависимости от контекста
      accountid: this.getAccountID()
    };
    // Handle the response here
    this.serviceservice.OutletSettingsSale(tempcatalogDetails).subscribe({
      next: (value: OutletSettingsResponse) => {
        this.modalService.orClose()
        this.notification.create(
          "success",
          'System Message',
          'Data saved successfully.',
          {nzDuration: 4000}
        );
        this.yourappsService.getReloadOutLetList([], 1)
        console.log(value)
      },
      error: (error: any) => {
        console.error('Error occurred:', error);
      },
      complete: () => {
        console.log('Subscription completed');
      }
    })
  }

  onTaxesClick() {
    this.modalService.taxesOpen()
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
