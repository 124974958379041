import {AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {
  GetImageList,
  SelfData,
  SetSelectedFiles,
  settaxefieldsData,
  TagsList,
  TagsListData,
  transformImageUrl
} from "../../models/struct";
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder} from "@angular/forms";
import {HashService} from "../../services/hash.service";
import {MessageboxComponent} from "../../modals/messagebox/messagebox.component";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {HttpClient, HttpEventType} from "@angular/common/http";
import {forkJoin, Subscription} from "rxjs";
import heic2any from "heic2any";
import {ThemePalette} from "@angular/material/core";
import {ProgressSpinnerMode} from "@angular/material/progress-spinner";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {Table} from "primeng/table";

interface ColorArray {
  colorname: string;
  colorcode: string;
}

@Component({
  selector: 'app-productframedialog',
  templateUrl: './productframedialog.component.html',
  styleUrl: './productframedialog.component.scss'
})
export class ProductframedialogComponent implements OnInit, AfterViewInit {
  productname: string = "";
  brand: string = "";
  description: string = "";

  colorarray: ColorArray[] = [
    {colorname: 'White', colorcode: '#ffffff'},
    {colorname: 'Black ', colorcode: '#000000'},
    {colorname: 'Yellow', colorcode: '#FFFF00'},
    {colorname: 'Purple', colorcode: '#800080'},
    {colorname: 'Orange ', colorcode: '#FFA500'},
    {colorname: 'Blue ', colorcode: '#2196f3'},
    {colorname: 'Light Blue ', colorcode: '#03a9f4'},
    {colorname: 'Red', colorcode: '#ff0000'},
    {colorname: 'Buff', colorcode: '#DAA06D'},
    // {colorname: 'Gray ', colorcode: '#808080'},
    {colorname: 'Grey ', colorcode: '#808080'},
    {colorname: 'Green ', colorcode: '#00FF00'},
    {colorname: 'Pink', colorcode: '#FFC0CB'},
    {colorname: 'Violet', colorcode: '#8F00FF'},
    {colorname: 'Brown', colorcode: '#964B00'},
    {colorname: 'Silver', colorcode: '#C0C0C0'},
    {colorname: 'Cream', colorcode: '#FFFDD0'},
    {colorname: 'Gold', colorcode: '#FFD700'},
    {colorname: 'Beige', colorcode: '#F5F5DC'},
    {colorname: 'Navy', colorcode: '#202A44'},
    {colorname: 'Mint', colorcode: '#A8E8D7'},
  ];
  viewmode: boolean = false;

  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
      ? SelfData.account[0].data[0].id
      : SelfData.account[0].data[0].parentid;
  }

  iTagsResult: TagsListData[] = []
  iCategoriesResult: TagsListData[] = []
  iBrandsResult: TagsListData[] = []
  iMaterialsResult: TagsListData[] = []
  iSuppliersResult: TagsListData[] = []
  iTaxResult: settaxefieldsData[] = []
  listOfTagOptions: any;
  selectCategories: string = "";
  skucode: number = 0;
  colorstring: any = [];
  selectMaterials: string = "";
  supplierprice: number = 0;
  taxvalue: number = 0;
  wtaxvalue: number = 0;
  suppliercode: string = "";
  selectsupplier: string = "";
  selecttaxe: string = "No Tax (0%)";
  markup: number = 0;
  wmarkup: number = 0;
  settaxvalue: number = 0;
  showprogressbar: boolean = false
  notneededNewID: boolean = true
  selectedIndex: number = -1;
  iImages: GetImageList[] = [];
  caption: string = "Add Product"

  setid: number = 0;
  opermode: number = 0;

  formatterPercent = (value: number): string => `${value} %`;
  parserPercent = (value: string): string => value.replace(' %', '');
  margin: number = 0;
  wmargin: number = 0;
  retailprice: number = 0;
  wholesaleprice: number = 0;
  progressvalue: number = 0;
  color: ThemePalette = 'accent';
  mode: ProgressSpinnerMode = 'determinate';
  iProductsResult: TagsListData[] = []
  sizes!: any[];
  @ViewChild(Table) dataTable?: Table;
  selectedSize: any = ''

  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private hashService: HashService,
    public messageBox: MessageboxComponent,
    private serviceservice: ServiceService,
    private yourappsService: YourappsserviceService,
    private notification: NzNotificationService,
    private _snackBar: MatSnackBar,
    private http: HttpClient,
    private cdr: ChangeDetectorRef
  ) {
    const accountId = SelfData.account[0].data[0].id;

    this.sizes = [
      {name: 'Small', class: 'p-datatable-sm'}
    ];
    this.selectedSize = this.sizes[0]

    forkJoin({
      tags: this.serviceservice.selectTags(this.getAccountID()),
      categories: this.serviceservice.selectCategories(this.getAccountID()),
      brands: this.serviceservice.selectBrands(this.getAccountID()),
      materials: this.serviceservice.selectMaterials(this.getAccountID()),
      suppliers: this.serviceservice.selectSuppliers(this.getAccountID()),
      taxes: this.serviceservice.getTaxes({accountid: this.getAccountID()}),
    }).subscribe(({tags, categories, brands, materials, suppliers, taxes}) => {
      if (this.opermode === 0) {
        this.iTagsResult = tags.data;
      }
      this.iCategoriesResult = categories.data;
      this.iBrandsResult = brands.data;
      this.iMaterialsResult = materials.data;
      this.iSuppliersResult = suppliers.data;
      this.iTaxResult = taxes.data;

      if (SelfData.generalsetup.length > 0) {
        this.settaxvalue = SelfData.generalsetup[0].data[0]["taxrate"]
      } else {
        this.settaxvalue = 0
      }
    });

    this.skucode = this.generateSKU();

    this.setid = 0
    this.opermode = 0
    this.subscription = this.yourappsService.selectedProductsList$.subscribe((value) => {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }

      if (value?.type === 1) {
        if (SelfData.formstyle === 1) {
          this.caption = "Edit Product"
          this.viewmode = false
        }

        if (SelfData.formstyle === 2) {
          this.caption = "View Product"
          this.viewmode = true
        }

        this.opermode = 1;

        let data: any = value.data;

        // ✅ Проверяем, является ли data массивом, и берем первый элемент
        if (Array.isArray(data) && data.length > 0) {
          data = data[0];
        }

        this.iTagsResult = []
        const selectedColor = this.colorarray.find(c => c.colorname.toLowerCase().trim() === (data.color ?? "").toLowerCase().trim());
        // ✅ Проверяем, является ли data объектом перед заполнением переменных
        if (data && typeof data === 'object') {
          this.setid = data.id ?? 0;
          this.productname = data.name ?? "";
          this.brand = data.brand ?? "";
          this.description = data.description ?? "";

          this.listOfTagOptions = data.tags ? data.tags.split(',') : [];
          this.selectCategories = data.product_categories ?? "";
          this.skucode = data.sku_code ?? "";
          this.colorstring = data.color ? data.color.split(',') : [];
          this.selectMaterials = data.materials ?? "";
          this.supplierprice = data.supplier_price ?? 0;
          this.taxvalue = data.tax ?? 0;
          this.wtaxvalue = data.taxw ?? 0;
          this.suppliercode = data.supplier_code ?? "";
          this.selectsupplier = data.supplier ?? "";
          this.selecttaxe = data.default_sales_tax ?? "No Tax (0%)";
          this.markup = data.markup ?? 0;
          this.wmarkup = data.markupw ?? 0;
          this.margin = data.margin ?? 0;
          this.wmargin = data.marginw ?? 0;
          this.retailprice = data.retail_price ?? 0;
          this.wholesaleprice = data.wholesale_price ?? 0;
          this.settaxvalue = data.tax ?? 10; // Если нет, оставляем 10
          this.showprogressbar = false;
          this.notneededNewID = true;
          this.selectedIndex = -1;
          this.minimumquantity = data.minimumquantity ?? 0;
          this.stoneweight = data.stoneweight ?? 0;
          this.netweight = data.netweight ?? 0;
          this.size = data.size ?? 0;

          setTimeout(() => {
            SelfData.spinnerpossition = true
          });
          forkJoin({
            products: this.serviceservice.selectProductHistory(this.setid),
          }).subscribe(({products}) => {
            this.iProductsResult = products.data;
            console.log("this.iProductsResult", this.iProductsResult)
            setTimeout(() => {
              SelfData.spinnerpossition = false
            });
          });

          console.log("✅ Объект после обработки:", data);
        } else {
          console.error("❌ Ошибка: data не является объектом", data);
        }
        this.serviceservice
          .setSaleImageList({accountid: this.getAccountID(), sampleid: this.setid})
          .subscribe({
            next: (value: any) => {
              if (value && value.data && Array.isArray(value.data)) {
                this.iImages[0] = value.data;
                console.log(value.data)
                this.selectedImageFiles = []
                value.data.forEach((item: { imagelink?: string, sortby: number }) => {
                  if (item.imagelink) {
                    // console.log("item", item)
                    // Include both file and url properties
                    const url = item.imagelink;

                    const parts = url.split('/');

                    const fileName = parts[parts.length - 1];

                    this.selectedImageFiles.push({
                      file: null,
                      url: item.imagelink,
                      filename: fileName,
                      sortby: item.sortby
                    });
                  }
                });
              } else {
                console.log(
                  'The response is empty or not in the expected format.'
                );
              }
            },
            error: (error) => {
              console.error('Error occurred during the subscription:', error);
            },
          });
      }
    });
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
  }

  generateSKU(): number {
    return Math.floor(Math.random() * (100000000 - 100000 + 1)) + 100000;
  }

  recalculateFromMarkup(price: number, type: number): void {
    // Пересчитываем все из Markup
    const markupAmount = this.supplierprice * (price / 100);
    const basePrice = this.supplierprice + markupAmount;

    if (type === 1) {
      // Вычисляем налог как процент от цены без налога
      this.taxvalue = parseFloat((basePrice * (this.settaxvalue / 100)).toFixed(2));
      // Розничная цена с учетом налога
      this.retailprice = parseFloat((basePrice).toFixed(2));
      // Маржа = (Наценка / Цена без налога) * 100
      this.margin = parseFloat(((markupAmount / basePrice) * 100).toFixed(2));
      console.log(`Supplier Price: ${this.supplierprice}, Markup: ${price}%, Base Price: ${basePrice}, Tax: ${this.taxvalue}, Retail Price: ${this.retailprice}, Margin: ${this.margin}%`);
    } else if (type === 2) {
      // Для type = 2 налог не рассчитываем (равен 0)
      this.wtaxvalue = 0;
      // Розничная цена без учета налога
      this.wholesaleprice = parseFloat((basePrice).toFixed(2));
      // Маржа = (Наценка / Цена без налога) * 100
      this.wmargin = parseFloat(((markupAmount / basePrice) * 100).toFixed(2));
      console.log(`Supplier Price: ${this.supplierprice}, Markup: ${price}%, Base Price: ${basePrice}, Tax: ${this.wtaxvalue}, Wholesale Price: ${this.wholesaleprice}, Margin: ${this.wmargin}%`);
    }
  }

  recalculateFromMargin(price: number, type: number): void {
    // Проверка на граничные значения маржи
    if (price >= 100) {
      console.warn("Маржа не может быть 100% или больше");
      price = 99.99; // Устанавливаем максимально допустимое значение
    }

    // Вычисляем базовую цену (без налога) на основе Margin и закупочной цены
    const basePrice = parseFloat((this.supplierprice / (1 - price / 100)).toFixed(2));

    // Вычисляем сумму наценки
    const markupAmount = basePrice - this.supplierprice;

    if (type === 1) {
      // Пересчитываем Markup из Margin
      this.markup = parseFloat(((markupAmount / this.supplierprice) * 100).toFixed(2));

      // Вычисляем налог как процент от базовой цены
      this.taxvalue = parseFloat((basePrice * (this.settaxvalue / 100)).toFixed(2));

      // Розничная цена = базовая цена
      this.retailprice = parseFloat((basePrice).toFixed(2));

      console.log(`Margin: ${price}%, Supplier Price: ${this.supplierprice}, Base Price: ${basePrice}, Markup: ${this.markup}%, Tax: ${this.taxvalue}, Retail Price: ${this.retailprice}`);
    } else if (type === 2) {
      // Пересчитываем Markup из Margin
      this.wmarkup = parseFloat(((markupAmount / this.supplierprice) * 100).toFixed(2));

      // Для type = 2 налог не рассчитываем (равен 0)
      this.wtaxvalue = 0;

      // Оптовая цена = базовая цена
      this.wholesaleprice = parseFloat((basePrice).toFixed(2));

      console.log(`Margin: ${price}%, Supplier Price: ${this.supplierprice}, Base Price: ${basePrice}, Markup: ${this.wmarkup}%, Tax: ${this.wtaxvalue}, Wholesale Price: ${this.wholesaleprice}`);
    }
  }

  recalculateFromTax(): void {
    // Пересчитываем Retail Price из Tax
    const preRetailPrice = this.supplierprice + (this.supplierprice * (this.markup / 100));
    this.taxvalue = parseFloat((preRetailPrice * (this.settaxvalue / 100)).toFixed(2));
    this.retailprice = preRetailPrice + this.taxvalue;

    // Обновляем Margin
    this.margin = parseFloat(((this.markup / 100) * preRetailPrice / preRetailPrice * 100).toFixed(2));
  }

  recalculateFromRetailPrice(price: number, type: number): void {
    if (price <= 0 || this.supplierprice <= 0) {
      console.warn("Некорректные значения Retail Price или Supplier Price");
      return;
    }

    if (type === 1) {
      // 1. Рассчитываем налог (Tax) - налог рассчитывается сверху цены
      const taxAmount = price * (this.settaxvalue / 100);
      // 2. Вычисляем полную цену с налогом
      const priceWithTax = price + taxAmount;

      this.taxvalue = parseFloat(taxAmount.toFixed(2));
      // 3. Вычисляем Markup (наценку) - от цены без налога
      this.markup = parseFloat(((price - this.supplierprice) / this.supplierprice * 100).toFixed(2));
      // 4. Вычисляем Margin (маржу) - от цены без налога
      this.margin = parseFloat(((price - this.supplierprice) / price * 100).toFixed(2));
      console.log(`Base Price: ${price}, Tax: ${this.taxvalue}, Total Price: ${priceWithTax}, Markup: ${this.markup}, Margin: ${this.margin}`);
    } else if (type === 2) {
      // Для type = 2 налог не рассчитываем (равен 0)
      this.wtaxvalue = 0;
      // Полная цена равна базовой цене (без налога)
      const priceWithTax = price;

      // 3. Вычисляем Markup (наценку) - от цены без налога
      this.wmarkup = parseFloat(((price - this.supplierprice) / this.supplierprice * 100).toFixed(2));
      // 4. Вычисляем Margin (маржу) - от цены без налога
      this.wmargin = parseFloat(((price - this.supplierprice) / price * 100).toFixed(2));
      console.log(`Base Price: ${price}, Tax: ${this.wtaxvalue}, Total Price: ${priceWithTax}, Markup: ${this.wmarkup}, Margin: ${this.wmargin}`);
    }
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.selectedImageFiles, event.previousIndex, event.currentIndex);
  }

  calculatePrices(supplyPrice: number, markupPercent: number, taxPercent: number) {
    console.log("🔹 Исходные данные:");
    console.log("Supply Price:", supplyPrice);
    console.log("Markup %:", markupPercent);
    console.log("Tax %:", taxPercent);

    // 1. Рассчитываем наценку (Markup)
    const markup = supplyPrice * (markupPercent / 100);
    console.log("✅ Markup (Наценка):", markup);

    // 2. Рассчитываем предварительную розничную цену (без налога)
    const preRetailPrice = supplyPrice + markup;
    console.log("✅ Pre-Retail Price (без налога):", preRetailPrice);

    // 3. Рассчитываем налог (если налог > 0)
    const tax = taxPercent > 0 ? parseFloat((preRetailPrice * (taxPercent / 100)).toFixed(2)) : 0;
    console.log("✅ Tax (Налог):", tax);

    // 4. Retail Price (Окончательная цена)
    const retailPrice = preRetailPrice + tax;
    console.log("✅ Retail Price (Окончательная цена):", retailPrice);

    // 5. **Исправленная формула для Margin**
    const margin = (markup / preRetailPrice) * 100;
    console.log("✅ Margin (Маржа):", margin);

    return {
      markup: parseFloat(markup.toFixed(2)),
      margin: parseFloat(margin.toFixed(2)),  // Теперь должно быть 9.09%
      tax: tax,
      retailPrice: parseFloat(retailPrice.toFixed(2))
    };
  }

  uploadImageFiles() {
    setTimeout(() => {
      this.progressvalue = 0;
      this.showprogressbar = true;
      this.cdr.detectChanges(); // Принудительное обновление состояния
    }, 2000); // 2000 миллисекунд = 2 секунды
    let fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.multiple = true;
    fileInput.accept = 'image/*,.heic'; // Accept image files and HEIC
    fileInput.addEventListener('change', async (event) => {
      const files: FileList | null = (event.target as HTMLInputElement).files;
      if (files) {
        for (let file of Array.from(files)) {
          this.progressvalue = 0;
          this.showprogressbar = true;

          let processedFile = file;
          let url: string;

          if (file.name.toLowerCase().endsWith('.heic')) {
            try {
              const jpegBlob = await heic2any({
                blob: file,
                toType: 'image/jpeg',
                quality: 0.8
              });

              // Handle the case where heic2any returns an array of Blobs
              const convertedBlob = Array.isArray(jpegBlob) ? jpegBlob[0] : jpegBlob;

              processedFile = new File([convertedBlob], file.name.replace('.heic', '.jpg'), {type: 'image/jpeg'});
              url = URL.createObjectURL(convertedBlob);
            } catch (error) {
              console.error('Error converting HEIC to JPEG:', error);
              continue; // Skip this file if conversion fails
            }
          } else {
            url = await this.readFileAsDataURL(file);
          }

          console.log("value.data", "!");
          await this.onUploadTempImage(processedFile, processedFile.name);
          this.selectedImageFiles.push({
            file: processedFile,
            url: url,
            filename: processedFile.name,
            sortby: 1
          });
        }
      }
    });
    fileInput.click();
  }

  selectedFiles: File[] = [];
  selectedImageFiles: { file: File | null; url: string; filename: string, sortby: number }[] = [];
  deletedImageFiles: { file: File | null; url: string; filename: string, sortby: number }[] = [];

  private readFileAsDataURL(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event: any) => resolve(event.target.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  }

  onUploadTempImage(file: File | null, filename: string) {
    if (file !== null && file !== undefined) {
      const uploadObservable = this.serviceservice.uploadTempImage(file, filename);

      uploadObservable.subscribe({
        next: (event) => {
          if (event.type === HttpEventType.UploadProgress) {
            // Check if the total byte count is available
            if (event.total) {
              const progress = Math.round(100 * event.loaded / event.total);
              this.progressvalue = progress
              console.log(`Upload is ${progress}% done`);
            }
          } else if (event.type === HttpEventType.Response) {
            console.log('Upload successful', event.body);
            this.showprogressbar = false
          }
        },
        error: (error) => {
          console.error('Upload failed', error);
        },
      });
    } else {
      console.error('File is null or undefined');
    }
  }

  async deleteimagefiles(ctrl: number, accountid: number, sampleid: number) {
    for (const imageFile of this.deletedImageFiles) {
      console.log(imageFile.filename)
      this.serviceservice
        .delImageList({ctrl: ctrl, accountid: accountid, sampleid: sampleid, filename: imageFile.filename})
        .subscribe({
          next: (value: any) => {
            if (value && value.data && Array.isArray(value.data)) {

            } else {
              console.log(
                'The response is empty or not in the expected format.'
              );
            }
          },
          error: (error) => {
            console.error('Error occurred during the subscription:', error);
          },
        });
    }
  }

  async onUpload(ctrl: number, accountid: number, userid: number, sampleid: number, type: number) {
    SelfData.updatebuttonisenabled = true
    await this.deleteimagefiles(2, accountid, sampleid)
    if (type === 0) {
      this.selectedImageFiles.forEach((imageFile, index) => {
        if (imageFile.file !== null && imageFile.file !== undefined) {
          try {
            console.log("!!!!!!!!!!!!!!!!!!!!!", imageFile)
            const response = this.serviceservice.uploadSampleProducts(
              imageFile.file,
              accountid,
              userid,
              sampleid,
              ctrl,
              imageFile.filename,
              type,
              imageFile.url,
              index
            );
            console.log('Upload successful', response);
            // if (!this.neededreload) {
            //   this.yourappsService.getReloadSampleList();
            // }
            if (this.notneededNewID) {
              this.yourappsService.setNewID(sampleid);
            }
          } catch (error) {
            console.log('Upload failed', error);
          }
        } else {
          // console.log('File is null or undefined');
          let ctrl: number = 0
          let isduplicate: boolean = false
          if (SelfData.isduplicate) {
            ctrl = 0
            isduplicate = true
          } else {
            ctrl = 3
            isduplicate = false
          }
          console.log("imageFile.filename", imageFile, "imageFile.sortby", imageFile.sortby)
          // await this.deleteimagefiles(2, accountid, sampleid)
          this.serviceservice.uploadsortby(
            accountid,
            userid,
            sampleid,
            ctrl, // ctrl
            imageFile.filename,
            type,
            imageFile.url,
            index,
            isduplicate
          ).subscribe({
            next: (response) => {
              console.log('Upload sortby successful', response);
              // Handle the response after successful upload
            },
            error: (error) => {
              console.error('Error uploading data', error);
              // Handle the error if the upload fails
            },
            complete: () => {
              console.log('Upload sortby request completed');
              // Any finalization code after the request completes
            }
          });
          // const response = this.serviceservice.uploadsortby(
          //   accountid,
          //   userid,
          //   sampleid,
          //   2,
          //   imageFile.filename,
          //   type,
          //   "",
          //   imageFile.sortby
          // );
          // console.log('Upload sortby successful');
        }
      })
    } else if (type === 1) {
      for (const imageFile of this.selectedImageFiles) {
        try {
          console.log("@@@@@@@@@@@", imageFile)
          const response = this.serviceservice.uploadSampleProducts(
            imageFile.file,
            accountid,
            userid,
            sampleid,
            ctrl,
            imageFile.filename,
            type,
            imageFile.url,
            imageFile.sortby
          );
          console.log('Upload successful', response);
          // if (!this.neededreload) {
          //   this.yourappsService.getReloadSampleList();
          // }
          if (this.notneededNewID) {
            this.yourappsService.setNewID(sampleid);
          }
        } catch (error) {
          console.error('Upload failed', error);
        }
      }
    }
  }

  zoomImage(index: number, item: any) {
    // this.zoomedIndex = this.zoomedIndex === index ? null : index;
    // SelfData.imageUrl = this.selectedImageFiles[index].url;
    // this.modalService.createimagezoom();
    console.log(this.selectedImageFiles)
    if (item["file"]) {
      item["url"] = "http://108.178.189.70:6330/SampleImageOriginal/" + item["filename"]
      this.selecteditem = item
      this.selectedIndex = index;
      console.log("from comp")
    } else {
      console.log("from server")
      this.selecteditem = item
      this.selectedIndex = index;
    }
  }

  onDefaultImageClick(item: any) {
    console.log('Default image clicked:', item);
    for (let i = 0; i < this.selectedImageFiles.length; i++) {
      if (this.selectedImageFiles[i].filename !== item.filename) {
        this.selectedImageFiles[i].sortby = 1;
      } else {
        this.selectedImageFiles[i].sortby = 0;
      }
    }
    console.log('Updated selectedImageFiles:', this.selectedImageFiles);
    // handle additional logic if needed
  }

  deleteImageFile(event: MouseEvent, index: number) {
    this.selectedIndex = -1;
    event.stopPropagation();

    // Найдите элемент в selectedImageFiles по индексу
    const deletedItem = this.selectedImageFiles[index];

    if (deletedItem) {
      if (!deletedItem.file) {
        console.log(deletedItem)
        // Добавьте этот элемент в массив deletedImageFiles
        this.deletedImageFiles.push(deletedItem);
      }
    }
    // Удалите элемент из массива selectedImageFiles
    this.selectedImageFiles.splice(index, 1);

  }

  selectdefaultimage: any = []
  selecteditem: any

  protected readonly transformImageUrl = transformImageUrl;

  onProductClick() {
    var ttype: number = 0
    var imagelink: string = ""
    if (this.selectedImageFiles.length > 0) {
      imagelink = "http://108.178.189.70:6330/SampleImageAvatar/" + this.selectedImageFiles[0].filename
    }
    const selectedColor = this.colorstring;
    const selectedColorObj = this.colorarray.find(
      (color) => color.colorcode === selectedColor
    );

    let colorname: string = '';
    let colorcode: string = '';

    if (selectedColorObj) {
      colorname = selectedColorObj.colorname;
      colorcode = selectedColorObj.colorcode;

      // Use the colorname and colorcode as needed
    } else {
      console.log('Selected Color not found');
    }
    let tid: number = this.setid
    let tctrl: number = this.opermode
    this.serviceservice.prcProductsSale({
      ctrl: tctrl,  // 0 = Insert, 1 = Update, 2 = Deactivate
      id: tid,
      name: this.productname,
      brand: this.brand,
      description: this.description,
      tags: this.listOfTagOptions,
      product_categories: this.selectCategories,
      color: this.colorstring,
      materials: this.selectMaterials,
      sku_code: this.skucode.toString(),
      supplier: this.selectsupplier,
      supplier_code: this.suppliercode,
      supplier_price: this.supplierprice,
      default_sales_tax: this.selecttaxe,
      supply_price: this.supplierprice,
      markup: this.markup,
      margin: this.margin,
      tax: this.taxvalue,
      retail_price: this.retailprice,
      imagelink: imagelink,
      userid: this.getAccountID(),
      markupw: this.wmarkup,
      marginw: this.wmargin,
      taxw: this.wtaxvalue,
      wholesale_price: this.wholesaleprice,
      minimumquantity: this.minimumquantity,
      stoneweight: this.stoneweight,
      netweight: this.netweight,
      size: this.size,
    }).subscribe(async response => {
      await this.onUpload(0, this.getAccountID(), SelfData.account[0].data[0].id, response.data, ttype);
      this.modalService.productFrameClose()
      this.notification.create(
        "success",
        'System Message',
        'Data saved successfully.',
        {nzDuration: 4000}
      );
      this.yourappsService.getReloadProductsList([], 1)
      // console.log("this.selectedImageFiles", this.selectedImageFiles)
      // console.log('API Response:', response.data);
    });
  }

  private subscription?: Subscription;
  minimumquantity: number = 0;

  onSupplierClick() {
    this.yourappsService.setSelectedSupplierList([], 0)
    this.yourappsService.getReloadSupplierList([], 0)
    this.subscription = this.yourappsService.reloadSupplierList$.subscribe((value) => {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      if (value.type === 1) {
        forkJoin({
          suppliers: this.serviceservice.selectSuppliers(this.getAccountID()),
        }).subscribe(({suppliers}) => {
          this.iSuppliersResult = suppliers.data;
        });
      }
    })
    this.modalService.suppliersFrameOpen()
  }

  onBrandClick() {
    SelfData.caption = "Add Brand"
    this.yourappsService.getReloadSaleList([], 0)
    this.subscription = this.yourappsService.reloadSaleList$.subscribe((value) => {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      if (value.type === 1) {
        forkJoin({
          categories: this.serviceservice.selectCategories(this.getAccountID()),
          brands: this.serviceservice.selectBrands(this.getAccountID()),
          materials: this.serviceservice.selectMaterials(this.getAccountID()),
        }).subscribe(({categories, brands, materials}) => {
          this.iCategoriesResult = categories.data;
          this.iBrandsResult = brands.data;
          this.iMaterialsResult = materials.data;
        });
      }
    })
    this.modalService.aesOpen()
  }

  onCategoryClick() {
    SelfData.caption = "Add Category"
    this.yourappsService.getReloadSaleList([], 0)
    this.subscription = this.yourappsService.reloadSaleList$.subscribe((value) => {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      if (value.type === 1) {
        forkJoin({
          categories: this.serviceservice.selectCategories(this.getAccountID()),
          brands: this.serviceservice.selectBrands(this.getAccountID()),
          materials: this.serviceservice.selectMaterials(this.getAccountID()),
        }).subscribe(({categories, brands, materials}) => {
          this.iCategoriesResult = categories.data;
          this.iBrandsResult = brands.data;
          this.iMaterialsResult = materials.data;
        });
      }
    })
    this.modalService.aesOpen()
  }

  onMaterialClick() {
    SelfData.caption = "Add Material"
    this.yourappsService.getReloadSaleList([], 0)
    this.subscription = this.yourappsService.reloadSaleList$.subscribe((value) => {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      if (value.type === 1) {
        forkJoin({
          categories: this.serviceservice.selectCategories(this.getAccountID()),
          brands: this.serviceservice.selectBrands(this.getAccountID()),
          materials: this.serviceservice.selectMaterials(this.getAccountID()),
        }).subscribe(({categories, brands, materials}) => {
          this.iCategoriesResult = categories.data;
          this.iBrandsResult = brands.data;
          this.iMaterialsResult = materials.data;
        });
      }
    })
    this.modalService.aesOpen()
  }

  protected readonly SelfData = SelfData;
  selectedSalesReport: any;
  stoneweight: number = 0;
  netweight: number = 0;
  size: number = 0;
}
