import {AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, Renderer2, Self} from '@angular/core';
import {GetSendOutSamples, SelfData, SetPullSheetList} from "../../models/struct";
import {DOCUMENT} from "@angular/common";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MessageService} from "primeng/api";
import {ServiceService} from "../../services/service.service";
import {HashService} from "../../services/hash.service";
import { HttpClient } from "@angular/common/http";
import {DeviceDetectorService} from "ngx-device-detector";
import {ModalserviceService} from "../../services/modalservice.service";
import {Subscription} from "rxjs";
import {animate, state, style, transition, trigger} from "@angular/animations";

@Component({
    selector: 'app-acceptedpullsheet',
    templateUrl: './acceptedpullsheet.component.html',
    styleUrls: ['./acceptedpullsheet.component.scss'],
    animations: [
        trigger('fadeInOut', [
            state('in', style({ opacity: 1 })),
            transition(':enter', [style({ opacity: 0 }), animate(300)]),
            transition(':leave', [animate(300, style({ opacity: 0 }))]),
        ]),
    ],
})
export class AcceptedpullsheetComponent implements OnInit, OnDestroy, AfterViewInit {
    deviceClass: string = "";
    footerClass: string = "";
    deviceClassAddress: string = "";
    imageUrl: string = '';
    signatureimage: string = '';
    isLandscape: boolean = false;
    dateofpull: Date | null = null;
    dateoffitting: Date | null = null;
    dateofse: Date | null = null;
    dateofer: Date | null = null;
    dateoffr: Date | null = null;
    extdate: Date | null = null;
    numbervalue: string = ""
    numbervalue1: string = ""
    textvalue: string = ""
    textvalue1: string = ""
    textvalue2: string = ""
    textvalue3: string = ""
    textvalue4: string = ""
    textvalue5: string = ""
    textvalue6: string = ""
    textvalue7: string = ""
    textvalue8: string = ""
    signatureImg: string = "";
    selectedFile: File | null = null;
    isDesktopDevice: boolean = this.deviceService.isDesktop();
    printerpage: boolean = false

    constructor(private renderer: Renderer2, @Inject(DOCUMENT) private document: Document,
                private yourappsService: YourappsserviceService,
                private messageService: MessageService,
                private serviceservice: ServiceService,
                private hashService: HashService,
                private http: HttpClient,
                private cdr: ChangeDetectorRef,
                private deviceService: DeviceDetectorService, public modalService: ModalserviceService) {
        this.setDeviceClass();
    }

    setDeviceClass(): void {
        this.deviceClass = this.deviceService.isMobile() ? 'mobilemaincard' : 'pcmaincard';
        this.footerClass = this.deviceService.isMobile() ? 'mobilebutton-container-dm-ps' : 'pcbutton-container-dm-ps';
        this.deviceClassAddress = this.deviceService.isMobile() ? 'mobileaddressdiv' : 'pcaddressdiv';
        // this.isLandscape = window.innerWidth > window.innerHeight ? 'islandscape' : 'isnlandscape';
    }

    ngAfterViewInit(): void {
    }

    ngOnDestroy(): void {
    }

    private subscription?: Subscription;

    private signaturesubscription?: Subscription;

    ngOnInit() {
        this.subscription = this.yourappsService.logoimage$.subscribe((image) => {
            this.imageUrl = image
            console.log("this.imageUrl", this.imageUrl)
        });
        this.renderer.setStyle(this.document.body, 'background-color', 'rgba(150,150,150,0.5)');
        this.setOrientation();
        window.addEventListener('resize', this.setOrientation.bind(this))
        this.signaturesubscription = this.yourappsService.signatureImage$.subscribe((image: string) => {
            console.log(image)
            if (image != '') {
                this.signatureImg = image
            }
        });

        // window.addEventListener('resize', () => {
        //   // this.isLandscape = window.innerWidth > window.innerHeight ? 'islandscape' : 'isnlandscape';
        // });
    }

    printContent() {
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

        if (isMobile) {
            // Handle mobile printing
            const printWindow = window.open('', '_blank');
            printWindow!.document.write('<html><head>');
            printWindow!.document.write('<link rel="stylesheet" href="/assets/acceptedpullsheet.component.css">');
            printWindow!.document.write('</head><body>');
            printWindow!.document.write(document.getElementById('pcmaincard')!.outerHTML);
            printWindow!.document.write('</body></html>');
            printWindow!.document.close();
            printWindow!.print();
        } else {
            // Your existing approach for desktop
            // ... your iframe and cloning logic ...
        }
    }

    onButtonPrintClick() {
        this.modalService.closePrinterFrame()
        this.printerpage = true;
        this.cdr.detectChanges();

        const el = document.getElementById('pcmaincard');
        if (!el) {
            console.error('Element not found!');
            return;
        }

        const clonedEl = el.cloneNode(true) as HTMLElement;

        const iframe = document.createElement('iframe');
        document.body.appendChild(iframe);

        const printerStyleLink = document.createElement('link');
        printerStyleLink.rel = 'stylesheet';
        printerStyleLink.href = '/assets/acceptedpullsheet.component.css';

        iframe.contentWindow!.document.head.appendChild(printerStyleLink);

        iframe.contentWindow!.document.body.appendChild(clonedEl);

        let lastTime = Date.now();

        const timer = setInterval(() => {
            const currentTime = Date.now();
            const elapsedTime = currentTime - lastTime;

            // If elapsed time is greater than a certain amount (like 1000ms for 1 second),
            // assume that print dialog was open during this period.
            if (elapsedTime > 1000) {
                console.log('Print dialog was likely closed');
                this.printerpage = false;
                this.cdr.detectChanges();
                clearInterval(timer);
                // Do what you need to do after the dialog has been closed
            }

            lastTime = currentTime;
        }, 100);

        setTimeout(() => {
            iframe.contentWindow!.focus();
            iframe.contentWindow!.print();
            document.body.removeChild(iframe);
        }, 1000);
    }

    iSendOutSample: GetSendOutSamples[] = [];
    onButtonViewItems() {
        console.log("SelfData.pullsheetlist[0].data[0].sendoutid", SelfData.pullsheetlist[0].data[0].sendoutid)
        this.serviceservice
            .getsendoutsaples({ sendoutid:  SelfData.pullsheetlist[0].data[0].sendoutid})
            .subscribe((value) => {
                this.iSendOutSample[0] = value;
                SelfData.sendoutsamples = this.iSendOutSample
                this.modalService.openSSL()
                console.log("SelfData.sendoutsamples", SelfData.sendoutsamples)
                // SelfData.addres = this.iAddressResult;
            });
    }

    setOrientation() {
        this.isLandscape = window.innerWidth > window.innerHeight;
    }

    extractDateFromDateObject(date: string | Date): string {
        let d: Date;

        if (typeof date === 'string') {
            d = new Date(date);
        } else if (date instanceof Date) {
            d = date;
        } else {
            throw new Error('Invalid date format');
        }

        const year = d.getUTCFullYear();
        const month = (d.getUTCMonth() + 1).toString().padStart(2, '0');
        const day = d.getUTCDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }


    protected readonly SelfData = SelfData;
}
