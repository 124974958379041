import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder} from "@angular/forms";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ConfirmEventType, ConfirmationService, MessageService} from "primeng/api";
import {
  formatDate,
  SelfData,
  SendOut1Data,
  SetContactsList,
  SetContactsListData,
  SetSampleListData, transformImageUrl
} from "../../models/struct";
import moment from "moment";
import {Table} from "primeng/table";
import {Subscription} from "rxjs";
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

interface OperationTypes {
    id: number;
    value: string;
}

@Component({
    selector: 'app-returnreciverdialog',
    templateUrl: './returnreciverdialog.component.html',
    styleUrls: ['./returnreciverdialog.component.scss']
})
export class ReturnreciverdialogComponent implements OnInit, AfterViewInit, OnDestroy {
    public value1?: String;
    selectedsample: SetSampleListData[] = [];
    messages2: { text: string; color: string }[] = [];
    messages1: { text: string; color: string }[] = [];
    iContactsResult: SetContactsList[] = [];
    sizes!: any[];
    samplestatys: string = "On Loan"
    selectedElement: any;
    public bgcolor: string = '';
    selectedopertype: number = 0
    iscomment: boolean = false

    selectedProducts!: SetSampleListData[];
    selectedSize: any = '';
    operationtypes: OperationTypes[] = [
        {id: 0, value: 'Clear Queue'},
        {id: 1, value: 'Mark as Returned'},
        {id: 2, value: 'Mark as Written-off'},
        {id: 3, value: 'Mark as Gifted'},
        {id: 4, value: 'Mark as Returned to vendor'},
        {id: 5, value: 'Mark as Send to archive'},
        {id: 6, value: 'Mark as Purchased'},

    ];

    constructor(
        public modalService: ModalserviceService,
        public fb: FormBuilder,
        private serviceservice: ServiceService,
        private yourappsService: YourappsserviceService,
        private _snackBar: MatSnackBar,
        private messageService: MessageService,
        private sanitizer: DomSanitizer,
        private cdRef: ChangeDetectorRef,
        private confirmationService: ConfirmationService
    ) {
        this.selectedsample = [];
        this.sizes = [
            {name: 'Small', class: 'p-datatable-sm'}
        ];
        this.selectedSize = this.sizes[0]
    }

    ngAfterViewInit(): void {
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.subscription1) {
            this.subscription1.unsubscribe();
        }
    }

    private subscription?: Subscription;
    private subscription1?: Subscription;

    getSafeHTML(value: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(value);
    }

    editSendOutTypeById(id: any, newSendOutType: any, newSampleStatus: any, newcomment: any) {
        // Find the item with the specific ID
        const item = this.selectedsample.find(sample => sample["ID"] === id);

        // If the item exists, update its sendOutType
        if (item) {
            item["sendOutType"] = newSendOutType;
            item["samplestatus"] = newSampleStatus;
            item["comment"] = newcomment;
        }
    }

    ngOnInit(): void {
        this.subscription1 = this.yourappsService.invoiceCreateSend.subscribe((value) => {
            if (this.subscription1) {
                this.selectedProducts = SelfData.setSelectedSample
                this.modalService.openRRC()
            }
        });
        this.subscription = this.yourappsService.reloadSendOutReturnList.subscribe((comment) => {
            if (this.subscription) {
                if (this.selectedopertype == 0) {
                    this.selectedProducts.forEach(sample => {
                        this.serviceservice.prcsendoutreturnupdate({
                            ctrl: 1,
                            sampleid: sample?.["ID"],
                            sendoutid: sample?.["sendoutid"],
                            sendouttype: SelfData.operid,
                            samplestatus: this.returnsamplestatus(SelfData.operid),
                            comment: comment.trim(),
                            amount: sample?.["amount"],
                            discount: sample?.["discount"],
                            totalprice: sample?.["totalprice"]
                        }).subscribe(() => {
                            this.editSendOutTypeById(sample?.["ID"], SelfData.operid, this.returnsamplestatus(SelfData.operid), comment)
                            if (SelfData.operid === 1) {
                                this.messageService.add({
                                    key: 'tc',
                                    severity: 'success',
                                    summary: this.operationtypes[SelfData.operid].value,
                                    detail: "Sample " + sample?.["barcode"] + " has been " + this.operationtypes[SelfData.operid].value + " on delivery " + sample?.["Barcode Prefix"] + "-" + sample?.["sendoutid"],
                                    life: 5000,
                                });
                            } else {
                                this.messageService.add({
                                    key: 'tc',
                                    severity: 'warn',
                                    summary: this.operationtypes[SelfData.operid].value,
                                    detail: "Sample " + sample?.["barcode"] + " has been " + this.operationtypes[SelfData.operid].value + " on delivery " + sample?.["Barcode Prefix"] + "-" + sample?.["sendoutid"] + " and deleted from database",
                                    life: 5000,
                                });
                            }
                            this.selectedProducts = []
                        });
                    });
                    this.selectedProducts = []
                } else {
                    if (this.iscomment) {
                        this.serviceservice.prcsendoutreturnupdate({
                            ctrl: 1,
                            sampleid: this.selectedElement?.["ID"],
                            sendoutid: this.selectedElement?.["sendoutid"],
                            sendouttype: SelfData.operid,
                            samplestatus: this.returnsamplestatus(SelfData.operid),
                            comment: comment.trim(),
                            amount: this.selectedElement?.["amount"],
                            discount: this.selectedElement?.["discount"],
                            totalprice: this.selectedElement?.["totalprice"]
                        }).subscribe(() => {
                            this.editSendOutTypeById(this.selectedElement?.["ID"], SelfData.operid, this.returnsamplestatus(SelfData.operid), comment)
                            this.messageService.add({
                                key: 'tc',
                                severity: 'success',
                                summary: this.operationtypes[SelfData.operid].value,
                                detail: "Sample " + this.selectedElement?.["barcode"] + " comment has been changed on delivery " + this.selectedElement?.["Barcode Prefix"] + "-" + this.selectedElement?.["sendoutid"],
                                life: 5000,
                            });
                            this.yourappsService.getFooterText("")
                            this.selectedProducts = []
                        });
                    } else {
                        this.serviceservice.prcsendoutreturnupdate({
                            ctrl: 1,
                            sampleid: this.selectedElement?.["ID"],
                            sendoutid: this.selectedElement?.["sendoutid"],
                            sendouttype: SelfData.operid,
                            samplestatus: this.returnsamplestatus(SelfData.operid),
                            comment: comment.trim(),
                            amount: this.selectedElement?.["amount"],
                            discount: this.selectedElement?.["discount"],
                            totalprice: this.selectedElement?.["totalprice"]
                        }).subscribe(() => {
                            this.editSendOutTypeById(this.selectedElement?.["ID"], SelfData.operid, this.returnsamplestatus(SelfData.operid), comment)
                            if (SelfData.operid === 1) {
                                this.messageService.add({
                                    key: 'tc',
                                    severity: 'success',
                                    summary: this.operationtypes[SelfData.operid].value,
                                    detail: "Sample " + this.selectedElement?.["barcode"] + " has been " + this.operationtypes[SelfData.operid].value + " on delivery " + this.selectedElement?.["Barcode Prefix"] + "-" + this.selectedElement?.["sendoutid"],
                                    life: 5000,
                                });
                            } else {
                                this.messageService.add({
                                    key: 'tc',
                                    severity: 'warn',
                                    summary: this.operationtypes[SelfData.operid].value,
                                    detail: "Sample " + this.selectedElement?.["barcode"] + " has been " + this.operationtypes[SelfData.operid].value + " on delivery " + this.selectedElement?.["Barcode Prefix"] + "-" + this.selectedElement?.["sendoutid"] + " and deleted from database",
                                    life: 5000,
                                });
                            }
                            this.selectedProducts = []
                        });
                    }
                }
            }
        });

        this.value1 = SelfData.account[0].data[0].barcodeprefix + "-" + SelfData.sendoutid

        this.yourappsService.currentSendOutData.subscribe((data) => {
          console.log("data", data)
            if (data != null) {
                SendOut1Data.check1 = data.check1
                SendOut1Data.check2 = data.check2
                SendOut1Data.check3 = data.check3
                SendOut1Data.shipFormControl = data?.["ship"]
                SendOut1Data.shiptoControl = data?.["shipto"]
                SendOut1Data.todControl = data?.["tod"]
                SendOut1Data.peControl = data?.["pe"]
                SendOut1Data.companynameControl = data?.["companyname"]
                SendOut1Data.addresslabelControl = data?.["addresslabel"]
                SendOut1Data.costofshippingControl = data?.["costofshipping"]
                SendOut1Data.cimControl = data?.["cim"]
                SendOut1Data.publicationControl = data?.["publication"]
                SendOut1Data.estylistControl = data?.["estylist"]
                SendOut1Data.photographerControl = data?.["photographer"]
                SendOut1Data.pelControl = data?.["pel"]
                SendOut1Data.petControl = data?.["pet"]
                SendOut1Data.issueControl = data?.["issue"]
                SendOut1Data.trackingNumberControl = data?.["trackingNumber"]
                SendOut1Data.returnControl = data?.["return"]
                SendOut1Data.notesControl = data?.["notes"]
                SendOut1Data.senderFormControl = data?.["sender"]
                SendOut1Data.requestedbyFormControl = data?.["requestedby"]
                SendOut1Data.sendOutTypeControl = data?.["sendOutType"]
                SendOut1Data.currancyControl = data?.["currancy"]
                SendOut1Data.reservationdateControl = data?.["reservationdate"]
                SendOut1Data.returndateControl = data?.["returndate"]
                SendOut1Data.datetimeCtrl = data?.["datetimeCtrl"]
                SendOut1Data.datetimeCtrl1 = data?.["datetimeCtrl1"]
                SendOut1Data.datetimeCtrl2 = data?.["datetimeCtrl2"]
                SendOut1Data.datetimeCtrl3 = data?.["datetimeCtrl3"]
            } else {
                SendOut1Data.check1 = false;
                SendOut1Data.check2 = false;
                SendOut1Data.check3 = false;
                SendOut1Data.departmentFormControl = '';
                SendOut1Data.shipFormControl = '';
                SendOut1Data.shiptoControl = '';
                SendOut1Data.todControl = '';
                SendOut1Data.peControl = '';
                SendOut1Data.companynameControl = '';
                SendOut1Data.addresslabelControl = '';
                SendOut1Data.costofshippingControl = '';
                SendOut1Data.cimControl = '';
                SendOut1Data.publicationControl = '';
                SendOut1Data.estylistControl = '';
                SendOut1Data.photographerControl = '';
                SendOut1Data.pelControl = '';
                SendOut1Data.petControl = '';
                SendOut1Data.issueControl = '';
                SendOut1Data.trackingNumberControl = '';
                SendOut1Data.returnControl = '';
                SendOut1Data.notesControl = '';
                SendOut1Data.senderFormControl = '';
                SendOut1Data.requestedbyFormControl = '';
                SendOut1Data.sendOutTypeControl = '';
                SendOut1Data.currancyControl = '';
                SendOut1Data.reservationdateControl = null;
                SendOut1Data.returndateControl = null;
                SendOut1Data.datetimeCtrl = null;
                SendOut1Data.datetimeCtrl1 = null;
                SendOut1Data.datetimeCtrl2 = null;
                SendOut1Data.datetimeCtrl3 = null;
                SendOut1Data.datetimeCtrl4 = null;
            }
            console.log("AQ", SelfData.setSelectedSample)
            this.selectedsample = SelfData.setSelectedSample
            var tempmessage: string;
            // console.log("SendOut1Data.reservationdateControl.value: ", SendOut1Data.datetimeCtrl.format('DD/MMM/YY HH:mm') )
            tempmessage = 'Ship Type: ' + SendOut1Data.todControl?.trim() + '<br>' +
                'Ship Date: ' + moment(SendOut1Data.datetimeCtrl).format('DD/MMM/YY HH:mm') + '<br>' +
                'Exp. Return Date: ' + moment(SendOut1Data.datetimeCtrl1).format('DD/MMM/YY HH:mm')
            this.messages2.unshift({text: tempmessage!!, color: "black"});

            this.serviceservice
                .setContactsList({accountid: this.getAccountID(), contactsid: 0})
                .subscribe((value) => {
                    this.iContactsResult[0] = value;
                    var recaddress: string | undefined;
                    let result = this.searchItem(SendOut1Data.companynameControl, this.iContactsResult);
                    recaddress = result?.['addressaddress1'] + '<br>' +
                        result?.['addresscity'] + ' ' + result?.['addressstate'] + ' ' +
                        result?.['addresszipcode'] + ' ' + result?.['addresscountry'] + ' <br>' +
                        'Attn: ' + SendOut1Data.companynameControl + '<br>' +
                        'Tel: ' + result?.['mobile'] + '<br>' +
                        'Email: ' + result?.['email']
                    this.messages1.unshift({text: recaddress, color: "black"});
                    // this.shipFormControl.setValue(recaddress!!)
                });

        })
    }

    searchItem(
        name: string | null,
        contacts: SetContactsList[]
    ): SetContactsListData | null {
        for (let contact of contacts) {
            let found = contact.data.find(
                (d) => d?.['firstname'] + ' ' + d?.['lastname'] === name
            );

            if (found) {
                return found;
            }
        }

        return null;
    }

    returncolor(value: number): string {
        if (this.selectedsample.length > 0) {
            if (value === 1) {
                return '#00642e';  // Red
            } else if (value === 2) {
                return '#ff0000';  // Green
            } else if (value === 3) {
                return '#78008b';  // Green
            } else if (value === 4) {
                return '#002593';  // Green
            } else if (value === 5) {
                return 'rgb(173,171,171)';  // Green
            } else if (value === 6) {
                return 'rgb(246, 183, 26)';  // Green
            }
        }
        return ""
    }


    returnsamplestatus(value: number): string {
        console.log("value", value)
        // if (this.selectedsample.length > 0) {
        if (value === 1) {
            const currentDateTime = new Date();
            return "Returned on " + formatDate(currentDateTime) + " by " + SelfData.account[0].data[0].firstname + " " + SelfData.account[0].data[0].lastname;
        } else if (value === 2) {
            return "Written-off";
        } else if (value === 3) {
            return "Gifted";
        } else if (value === 4) {
            return "Returned to vendor";
        } else if (value === 5) {
            return "Send to Archive";
        } else if (value === 5) {
            return "Send to Archive";
        } else if (value === 6) {
            return "Purchased";
        }

        // }
        return "On Loan"
    }

    onViewButtonClick(element: any) {
        SelfData.sampleselecteddata = element;
        SelfData.updatebuttonisenabled = false
        this.yourappsService.selectSampleData(element);
        this.modalService.createsampleropen();
    }

    onButtnPrintClick(element: any) {
        console.log(element)
    }

    // sample?.["barcode"] + " has been marked as returned on delivery " + sample?.["Barcode Prefix"] + "-" + sample?.["sendoutid"]
    onButtnRecoverClick(element: any) {
        setTimeout(() => {
            this.confirmationService.confirm({
                message: "You are about to recover sample " + element?.["barcode"] + " from delivery " + element?.["Barcode Prefix"] + "-" + element?.["sendoutid"] + " currently Mark as Written-Off. Are you certain that you want do this?",
                header: 'Recover Sample',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.serviceservice.prcsendoutreturnupdate({
                        ctrl: 1,
                        sampleid: element?.["ID"],
                        sendoutid: element?.["sendoutid"],
                        sendouttype: 1,
                        samplestatus: this.returnsamplestatus(SelfData.operid),
                        comment: "",
                        amount: element?.["amount"],
                        discount: element?.["discount"],
                        totalprice: element?.["totalprice"]
                    }).subscribe(() => {
                        this.editSendOutTypeById(element?.["ID"], 1, this.returnsamplestatus(SelfData.operid), "")
                        this.messageService.add({
                            key: 'tc',
                            severity: 'success',
                            summary: 'Success',
                            detail: "Sample " + element?.["barcode"] + " has been restored and " + this.operationtypes[1].value + " from delivery " + element?.["Barcode Prefix"] + "-" + element?.["sendoutid"],
                            life: 5000,
                        });
                        console.log("this.selectedsample", this.selectedsample)
                    });
                },
                reject: (type: any) => {
                    if (type == ConfirmEventType.REJECT) {

                    } else if (type == ConfirmEventType.CANCEL) {

                    }
                }
            });
        }, 0)
        this.cdRef.detectChanges();
    }


    private getAccountID(): number {
        return SelfData.account[0].data[0].parentid === 0
            ? SelfData.account[0].data[0].id
            : SelfData.account[0].data[0].parentid;
    }

// {id: 0, value: 'Clear Queue'},
// {id: 1, value: 'Mark as Returned'},
// {id: 2, value: 'Mark as Written-off'},
// {id: 3, value: 'Mark as Gifted'},
// {id: 4, value: 'Mark as Returned to vendor'},
// {id: 5, value: 'Mark as Send to archive'},
// {id: 6, value: 'Return/EOL Comments'},

    onButtonReturnClick(element: any, opertype: number) {
        this.selectedopertype = opertype
        this.iscomment = false
        if (opertype == 0) {
            if (!this.selectedProducts || this.selectedProducts.length === 0) {
                this.messageService.add({
                    key: 'tc',
                    severity: 'error',
                    summary: 'Information',
                    detail:
                        'You don\'t have more than one sample selected',
                    life: 5000,
                });
                return
            }
            SelfData.opertext = "You are about to mark " + this.selectedProducts.length + " sample(s) as Returned"
            SelfData.operid = 1
            this.modalService.openRRC()
        } else {
            SelfData.opertext = "You are about to mark sample as Returned"
            SelfData.operid = 1
            this.modalService.openRRC()
        }

    }

    onButtonWrittenOffClick(element: any, opertype: number) {
        this.iscomment = false
        this.selectedopertype = opertype
        if (opertype == 0) {
            if (!this.selectedProducts || this.selectedProducts.length === 0) {
                this.messageService.add({
                    key: 'tc',
                    severity: 'error',
                    summary: 'Information',
                    detail:
                        'You don\'t have more than one sample selected',
                    life: 5000,
                });
                return
            }
            SelfData.opertext = "You are about to mark " + this.selectedProducts.length + " sample(s) as Written-Off"
            SelfData.operid = 2
            this.modalService.openRRC()
        } else {
            SelfData.opertext = "You are about to mark sample as Written-Off"
            SelfData.operid = 2
            this.modalService.openRRC()
        }

    }

    onButtonGiftedClick(element: any, opertype: number) {
        this.iscomment = false
        this.selectedopertype = opertype
        if (opertype == 0) {
            if (!this.selectedProducts || this.selectedProducts.length === 0) {
                this.messageService.add({
                    key: 'tc',
                    severity: 'error',
                    summary: 'Information',
                    detail:
                        'You don\'t have more than one sample selected',
                    life: 5000,
                });
                return
            }
            SelfData.opertext = "You are about to mark " + this.selectedProducts.length + " sample(s) as Gifted"
            SelfData.operid = 3
            this.modalService.openRRC()
        } else {
            SelfData.opertext = "You are about to mark sample as Gifted"
            SelfData.operid = 3
            this.modalService.openRRC()
        }

    }

    onButtonSendtoarchiveClick(element: any, opertype: number) {
        this.iscomment = false
        this.selectedopertype = opertype
        if (opertype == 0) {
            if (!this.selectedProducts || this.selectedProducts.length === 0) {
                this.messageService.add({
                    key: 'tc',
                    severity: 'error',
                    summary: 'Information',
                    detail:
                        'You don\'t have more than one sample selected',
                    life: 5000,
                });
                return
            }
            SelfData.opertext = "You are about to mark " + this.selectedProducts.length + " sample(s) as Returned To Vendor"
            SelfData.operid = 4
            this.modalService.openRRC()
        } else {
            SelfData.opertext = "You are about to mark sample as Returned To Vendor"
            SelfData.operid = 4
            this.modalService.openRRC()
        }

    }

    onButtonReturnedtovendorClick(element: any, opertype: number) {
        this.iscomment = false
        this.selectedopertype = opertype
        if (opertype == 0) {
            if (!this.selectedProducts || this.selectedProducts.length === 0) {
                this.messageService.add({
                    key: 'tc',
                    severity: 'error',
                    summary: 'Information',
                    detail:
                        'You don\'t have more than one sample selected',
                    life: 5000,
                });
                return
            }
            SelfData.opertext = "You are about to mark " + this.selectedProducts.length + " sample(s) as Send To Archive"
            SelfData.operid = 5
            this.modalService.openRRC()
        } else {
            SelfData.opertext = "You are about to mark sample as Send To Archive"
            SelfData.operid = 5
            this.modalService.openRRC()
        }

    }

    onButtonPurchaseClick(element: any, opertype: number) {
        this.iscomment = false
        this.selectedopertype = opertype
        if (opertype == 0) {
            if (!this.selectedProducts || this.selectedProducts.length === 0) {
                this.messageService.add({
                    key: 'tc',
                    severity: 'error',
                    summary: 'Information',
                    detail:
                        'You don\'t have more than one sample selected',
                    life: 5000,
                });
                return
            }
            SelfData.opertext = "You are about to mark " + this.selectedProducts.length + " sample(s) as Purchased"
            SelfData.operid = 6
            SelfData.setSelectedSample = this.selectedProducts
            this.yourappsService.setSelectedSamples(this.selectedProducts)
            this.modalService.invoiceOpen()
        } else {
            SelfData.opertext = "You are about to mark sample as Purchased"
            SelfData.operid = 6
            const filteredSample = this.selectedsample.filter(item => item?.["ID"] === element?.["ID"]);
            SelfData.setSelectedSample = filteredSample
            console.log("this.selectedProducts", filteredSample)
            this.yourappsService.setSelectedSamples(filteredSample)
            this.modalService.invoiceOpen()
        }

    }

    onButtonCommentEditClick(element: any, opertype: number) {
        this.iscomment = true
        this.selectedopertype = opertype
        SelfData.opertext = "Are you going to edit the comment"
        SelfData.operid = element?.["sendOutType"]
        this.yourappsService.getFooterText(element?.["comment"])
        this.modalService.openRRC()
    }

    onButtonClearAllCheckClick(element: any) {
        if (!this.selectedProducts || this.selectedProducts.length === 0) {
            this.messageService.add({
                key: 'tc',
                severity: 'error',
                summary: 'Information',
                detail:
                    'You don\'t have more than one sample selected',
                life: 5000,
            });
            return
        }
        this.selectedProducts = []
    }


    protected readonly SelfData = SelfData;
    protected readonly SendOut1Data = SendOut1Data;
  protected readonly transformImageUrl = transformImageUrl;
}
