import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder, FormControl} from "@angular/forms";
import {HashService} from "../../services/hash.service";
import {MessageboxComponent} from "../../modals/messagebox/messagebox.component";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MessageService} from "primeng/api";
import {Subscription} from "rxjs";
import {emailaccount, Prcsmlist, SelfData} from "../../models/struct";

@Component({
  selector: 'app-emailframedialog',
  templateUrl: './emailframedialog.component.html',
  styleUrls: ['./emailframedialog.component.scss']
})
export class EmailframedialogComponent implements OnInit, AfterViewInit {
  toControl = new FormControl('');
  subjectControl = new FormControl('');
  text: string = "";

  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private hashService: HashService,
    public messageBox: MessageboxComponent,
    private serviceservice: ServiceService,
    private yourappsService: YourappsserviceService,
    private _snackBar: MatSnackBar,
    private messageService: MessageService
  ) {
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
  }

  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
      ? SelfData.account[0].data[0].id
      : SelfData.account[0].data[0].parentid;
  }

  submit() {
    this.serviceservice.insertMaimmailList({ accountid: this.getAccountID() }).subscribe({
      next: (response: any) => { // Fix: Explicitly mark response as any
        if (response?.data && typeof response.data === 'object' && 'duid' in response.data) {
          const duid = response.data.duid;
          const temail: string = SelfData.account[0].data[0].email
          const tto: string = this.toControl.value?.trim() ?? ""
          const tsubject: string = this.subjectControl.value?.trim() ?? ""
          const ttext: string = this.text ?? ""
          this.serviceservice.sendSetMail(temail, tto, tsubject, ttext, SelfData.iSendOutReportResult[0]?.data, SelfData.gridgields[0]?.data, duid).subscribe({
            next: (response: any) => { // Fix: Explicitly mark response as any
              console.log("emaildata", response)
            },
            error: (error: any) => {
              console.error("Error:", error);
            }
          });
          console.log("DUID:", duid);
        } else {
          console.error("DUID not found or response format is incorrect:", response);
        }
        // this.modalService.closeEmail();
        //
        // const temail: string = SelfData.account[0].data[0].email;
        // this.serviceservice.sendEmail(
        //   temail,
        //   this.toControl.value?.trim() ?? "",
        //   this.subjectControl.value?.trim() ?? "",
        //   this.text
        // );
      },
      error: (error: any) => {
        console.error("Error:", error);
      }
    });

    this.modalService.closeEmail()
    // const temail: string = SelfData.account[0].data[0].email
    // this.serviceservice.sendEmail(
    //   temail,
    //   this.toControl.value?.trim() ?? "",
    //   this.subjectControl.value?.trim() ?? "",
    //   this.text
    // );

    this.messageService.add({
      key: 'tc',
      severity: 'success',
      summary: 'Success',
      detail:
        'Email has been sent!',
      life: 10000,
    });
    // this.yourappsService.setFooterText(this.text)
    // console.log(this.text)
  }

  subscription?: Subscription;
  selectedemails: emailaccount[] = [];
  emailList: string[] = [];

  onEmailContactsClick() {
    this.yourappsService.setSelectedEmail(null, 0)

    this.subscription = this.yourappsService.selectedemails$.subscribe((value: { data: any, type: number }) => {
      if (value.type >= 1) {
        console.log(value)
        // Проверяем, существует ли уже первый элемент в selectedemails
        if (!this.selectedemails[0]) {
          // Если его нет, создаем его
          this.selectedemails[0] = {
            Code: 0, // или любое значение по умолчанию, которое вам подходит
            ErrorMessage: '',
            data: []
          };
        }

        // Проверяем, существует ли уже запись с такими данными в selectedemails[0].data
        const existingItem = this.selectedemails[0].data.find((item: any) => item.id === value.data.id);

        if (!existingItem) {
          // Если запись не найдена, добавляем ее
          if (value.type === 1) {
            this.messageService.add({
              key: 'tc',
              severity: 'success',
              summary: 'Information',
              // detail: `Sample ${barcodeValue} already scanned by user ${foundData['user']} for a new delivery or reservation.`,
              detail: `Email ${value.data.email} added to the list`,
              life: 1000,
            });
          }

          this.emailList.push(value.data.email);

          const emailString = this.emailList.join(", "); // Объединяем массив в строку
          console.log("emailString", emailString)
          this.toControl.setValue(emailString); // Присваиваем значение FormControl
        } else {
          // Если запись уже существует, выводим сообщение
          // if (value.type === 1) {
          //   this.messageService.add({
          //     key: 'tc',
          //     severity: 'error',
          //     summary: 'Information',
          //     // detail: `Sample ${barcodeValue} already scanned by user ${foundData['user']} for a new delivery or reservation.`,
          //     //Email GioQveladze@yahoo.com is already added to the list
          //     detail: `Email ${value.data.email} is already added to the list`,
          //     life: 1000,
          //   });
          // }
          // console.log("Запись с таким id уже существует:", value.data.id);
        }
      }
    });
    this.modalService.openEmailContacts()
  }
}
