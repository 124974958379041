<div class="form-container">
    <h4 class="top-left-text">
        {{ (modalService.alertDialogData$ | async)?.title }}
    </h4>
    <p class="centered-text">
        {{ (modalService.alertDialogData$ | async)?.message }}
    </p>
    <div class="button-container">
        <button mat-raised-button class="button-secondary" style="margin-right: 10px" (click)="onButtonOKCancel()">
            {{ (modalService.alertDialogData$ | async)?.buttonneg }}
        </button>
        <button mat-raised-button class="button-primary" style="width: 100%" (click)="onButtonOKClick()">
            {{ (modalService.alertDialogData$ | async)?.buttonpos }}
        </button>
    </div>
    <button
            mat-icon-button
            class="close-btn"
            (click)="onButtonCloseClick()"
    >
        <mat-icon>close</mat-icon>
    </button>
</div>
