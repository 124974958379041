<p-confirmDialog #cd [style]="{width: '25vw'}">
    <ng-template pTemplate="footer">
<!--        <button type="button" pButton label="No" (click)="cd.reject()"></button>-->
        <div class="button-container-rr">
            <button mat-raised-button class="button-secondary" style="margin-right: 10px; width: 10%" (click)="cd.reject()">
                No
            </button>
            <button mat-raised-button class="button-primary" style="width: 10%" (click)="cd.accept()">
                Yes
            </button>
        </div>
<!--        <button type="button" pButton label="Yes" (click)="cd.accept()"></button>-->
    </ng-template>
</p-confirmDialog>
<button mat-icon-button type="button" class="rainbow-btn" (click)="op.toggle($event)">🌈
</button>
<button mat-icon-button type="button" class="close-btn" (click)="modalService.closeRR()">
    <mat-icon>close</mat-icon>
</button>
<p class="sample-manager-text">Return/Recover Samples from Delivery: {{value1}}</p>
<div class="barcode-container">
    <div class="barcode-div-rr">
        <div class="red-overlay-rr">
            <span class="centered-text-rr">Delivery: {{SelfData.account[0].data[0].barcodeprefix + '-' + SelfData.sendoutid}}</span>
        </div>
        <app-barcode [element]="SelfData.account[0].data[0].barcodeprefix+SelfData.sendoutid" [text]="'fashion-m.com'"
                     [height]="60" [width]="1.48" [marginBottom]="0"
                     [marginRight]="-0.00001" [fontSize]="14" [background]="'rgba(255,255,255,0)'"
                     class="barcode-component"></app-barcode>
    </div>
    <div class="barcode-div-rrc">
        <div class="div-dm1">
            <p class="sample-manager-text-dm2">Delivery: (Open)</p>
        </div>
        <div class="form-field-spacing-contact form-field-h-dm"
             style="overflow-y: auto;border: 0px solid #ccc;padding: 5px;resize: none;">
            <p *ngFor="let msg of messages2" [style.color]="msg.color" [innerHTML]="msg.text">
            </p>
        </div>
        <div class="red-overlay2"></div>
    </div>
    <div class="barcode-div-rre">
        <div class="form-field-spacing-contact form-field-h-dm"
             style="overflow-y: auto;border: 1px solid #ccc;padding: 5px;resize: none; background-color: #e5e5e5;">
            <p *ngFor="let msg of messages1" [style.color]="msg.color" [innerHTML]="msg.text">
            </p>
        </div>
    </div>
</div>
<div class="captionpanel">
    <div class="button-container-table">
        <button class="selectbutton" mat-stroked-button [matMenuTriggerFor]="menu">
            Selection options
        </button>
    </div>
</div>

<div class="card tablepanel">
    <p-table
            [value]="selectedsample"
            [paginator]="true"
            [rows]="10"
            [showCurrentPageReport]="true"
            [resizableColumns]="false"
            [scrollable]="true"
            [(selection)]="selectedProducts"
            [scrollHeight]="'calc(90vh - 320px)'"
            dataKey="barcode"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [rowsPerPageOptions]="[10, 20, 50, 100]"
            [styleClass]="'p-datatable-striped ' + selectedSize.class.toString()"
            [tableStyle]="{'min-width': '50rem'}">
        <ng-template pTemplate="header">
            <tr>
                <!--                    -->
                <th style="width: 1%">
                </th>
                <th style="width: 1%; text-align: center ">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th style="width:1%">Options</th>
                <th style="width:12%">Sample Status</th>
                <th style="width:8%">Barcode</th>
                <th style="width:5%">Type</th>
                <th style="width:8%">Designer</th>
                <th style="width:8%">Collection</th>
                <th style="width:8%">Season</th>
                <th style="width:8%">Style #</th>
<!--                <th style="width:5%">Pattern</th>-->
<!--                <th style="width:10%">Looks Associated</th>-->
                <th style="width:5%">Fabric</th>
                <th style="width:5%">Color</th>
                <th style="width:10%">Description</th>
<!--                <th style="width:5%">Region</th>-->
                <th style="width:15%">Return/EOL Comments</th>
                <th style="width:20%">Image</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
            <tr [pSelectableRow]="product">
                <td>
<!--                    <div class="scdivrr" [ngStyle]="{'background-color': bgcolor}"></div>-->
                     <div class="scdivrr" [style.background-color]="returncolor(product['sendOutType'])"></div>
                </td>
                <td style="text-align: center">
                    <p-tableCheckbox *ngIf="product?.['sendOutType'] === 0" [value]="product"></p-tableCheckbox>
                </td>
                <td style="text-align: center">
                    <p-button *ngIf="SelfData.selectlookmode != 0"
                              label="Select" size="small" [outlined]="true" severity="secondary"
                              [matMenuTriggerFor]="menu1"
                              (click)="selectedElement = product">
                    </p-button>
<!--                    <p-button icon="pi pi-print" styleClass="p-button-rounded p-button-text"></p-button>-->
                </td>
<!--                <td>-->
<!--                    <p-button label="Select" styleClass="p-button-sm p-button-outlined p-button-secondary"-->
<!--                              [matMenuTriggerFor]="menu" (click)="currentSample = product">-->

<!--                    </p-button>-->

<!--                </td>-->
                <td>{{product['samplestatus']}}</td>
                <td>{{product["barcode"]}}</td>
                <td>{{product["Type"]}}</td>
                <td>{{product["Designer"]}}</td>
                <td>{{product["Collection"]}}</td>
                <td>{{product["Season"]}}</td>
                <td>{{product["Style"]}}</td>
                <td>{{product["Fabric"]}}</td>
                <td>{{product["Color"]}}</td>
                <td>{{product["Description"]}}</td>
                <td [innerHTML]="getSafeHTML(product['comment'])"></td>

                <td><img [src]="transformImageUrl(product['Image Link'])" alt="Image" class="image-size-80x80-rr shadow-4"></td>
<!--                <td><img [src]="product['Image Link']" alt="Image" class="image-size-80x80-rr shadow-4"></td>-->
<!--                <td>{{product["Type"]}}<br>{{product["barcode"]}}<br>{{product["Color"]}}</td>-->
<!--                <td>{{product["Gender"]}}<br>-->
<!--                    <mat-form-field class="price-input" appearance="fill">-->
<!--                        <mat-label>Item Count</mat-label>-->
<!--                        <input matInput type="number" step="1" [value]="product['itemcount']" min="1">-->
<!--                    </mat-form-field>-->
<!--                    <br>{{product["Region"]}}</td>-->
<!--                <td>{{product["Description"]}}</td>-->
<!--                <td><img [src]="product['Image Link']" alt="Image" class="image-size-80x80 shadow-4"></td>-->
<!--            </tr>-->
        </ng-template>
    </p-table>
</div>

<mat-menu #menu1="matMenu">
    <!-- If sendOutType of the first element is 0, show the Print button -->
<!--    <button *ngIf="selectedElement && selectedElement?.['sendOutType'] < 2" mat-menu-item (click)="onButtnPrintClick(selectedElement)">-->
<!--        &lt;!&ndash; <mat-icon>edit_note</mat-icon> &ndash;&gt;-->
<!--        <span>Print</span>-->
<!--    </button>-->

    <!-- If sendOutType of the first element is not 0, show the Recover button -->
    <button *ngIf="selectedElement && selectedElement?.['sendOutType'] > 1" mat-menu-item (click)="onButtnRecoverClick(selectedElement)">
        <!-- <mat-icon>edit_note</mat-icon> -->

        <span>Recover</span>
    </button>

    <mat-divider></mat-divider>
    <button mat-menu-item (click)="onViewButtonClick(selectedElement)">
        <!--        <mat-icon>edit_note</mat-icon>-->
        <span>View Sample</span>
    </button>

    <mat-divider></mat-divider>

    <button mat-menu-item (click)="onButtonReturnClick(selectedElement, 1)">
        <!--        <mat-icon>qr_code_2</mat-icon>-->
        <span>Mark as Returned</span>
    </button>

    <button mat-menu-item (click)="onButtonWrittenOffClick(selectedElement, 1)">
        <!--        <mat-icon>delete</mat-icon>-->
        <span>Mark as Written-off</span>
    </button>

    <button mat-menu-item (click)="onButtonGiftedClick(selectedElement, 1)">
        <!--        <mat-icon>delete</mat-icon>-->
        <span>Mark as Gifted</span>
    </button>

    <button mat-menu-item (click)="onButtonSendtoarchiveClick(selectedElement, 1)">
        <!--        <mat-icon>delete</mat-icon>-->
        <span>Mark as Returned to vendor</span>
    </button>

    <button mat-menu-item (click)="onButtonReturnedtovendorClick(selectedElement, 1)">
        <!--        <mat-icon>delete</mat-icon>-->
        <span>Mark as Send to archive</span>
    </button>

  <button mat-menu-item (click)="onButtonPurchaseClick(selectedElement, 1)">
    <!--        <mat-icon>delete</mat-icon>-->
    <span>Mark as Purchased</span>
  </button>

    <button mat-menu-item (click)="onButtonCommentEditClick(selectedElement, 1)">
        <!--        <mat-icon>delete</mat-icon>-->
        <span>Edit Coment</span>
    </button>
</mat-menu>


<mat-menu #menu="matMenu">
    <button mat-menu-item (click)="onButtonClearAllCheckClick(null)">
        <!--        <mat-icon>edit_note</mat-icon>-->
        <span>Clear Queue</span>
    </button>

    <button mat-menu-item (click)="onButtonReturnClick(selectedElement, 0)">
        <!--        <mat-icon>qr_code_2</mat-icon>-->
        <span>Mark as Returned</span>
    </button>

    <button mat-menu-item (click)="onButtonWrittenOffClick(selectedElement, 0)">
        <!--        <mat-icon>delete</mat-icon>-->
        <span>Mark as Written-off</span>
    </button>

    <button mat-menu-item (click)="onButtonGiftedClick(selectedElement, 0)">
        <!--        <mat-icon>delete</mat-icon>-->
        <span>Mark as Gifted</span>
    </button>

    <button mat-menu-item (click)="onButtonSendtoarchiveClick(selectedElement, 0)">
        <!--        <mat-icon>delete</mat-icon>-->
        <span>Mark as Returned to vendor</span>
    </button>

    <button mat-menu-item (click)="onButtonReturnedtovendorClick(selectedElement, 0)">
        <!--        <mat-icon>delete</mat-icon>-->
        <span>Mark as Send to archive</span>
    </button>

  <button mat-menu-item (click)="onButtonPurchaseClick(selectedElement, 0)">
    <!--        <mat-icon>delete</mat-icon>-->
    <span>Mark as Purchased</span>
  </button>

<!--    <button mat-menu-item>-->
<!--        &lt;!&ndash;        <mat-icon>delete</mat-icon>&ndash;&gt;-->
<!--        <span>Return/EOL Comments</span>-->
<!--    </button>-->

</mat-menu>

<p-overlayPanel #op>
  <div class="mainop">
    <div class="option">
      <div class="mark-returned"></div>
      <span>Mark as Returned</span>
    </div>
    <div class="option">
      <div class="mark-written-off"></div>
      <span>Mark as Written-off</span>
    </div>
    <div class="option">
      <div class="mark-gifted"></div>
      <span>Mark as Gifted</span>
    </div>
    <div class="option">
      <div class="mark-returned-vendor"></div>
      <span>Mark as Returned to vendor</span>
    </div>
    <div class="option">
      <div class="mark-archive"></div>
      <span>Mark as Send to archive</span>
    </div>
    <div class="option">
      <div class="mark-purchase"></div>
      <span>Mark as Purchased</span>
    </div>
  </div>
</p-overlayPanel>
