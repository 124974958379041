import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from "@angular/animations";
import {ModalserviceService} from "../../services/modalservice.service";
import {SelfData} from "../../models/struct";

@Component({
  selector: 'app-mainmenu',
  templateUrl: './mainmenu.component.html',
  styleUrl: './mainmenu.component.scss',
  animations: [
    trigger('children', [
      state('collapsed', style({
        height: '0'
      })),
      state('expanded', style({
        height: '*'
      })),
      transition('collapsed <=> expanded', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})
export class MainmenuComponent implements OnInit {
  isCollapsed = false;

  constructor(
    public modalService: ModalserviceService,
    // private webservice: WebService,
    // private messageService: MessageService,
    // private mainservice: MainService,
    // private cdr: ChangeDetectorRef
  ) {

  }

  toggleCollapsed(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  ngOnInit(): void {

  }

  onMainClick() {
    this.modalService.setupgeneralclose()
    this.modalService.outletsclose()
    this.modalService.homepanelshow()
  }

  onServicebiClick() {

  }

  onServicebiGroupeClick() {

  }

  onVisitebiClick() {

  }

  onMachveneblebiClick() {

  }

  onMedDocslebiClick() {

  }

  onDrugsClick() {

  }

  onSetupGeneralClick() {
    this.modalService.homepanelclose()
    this.modalService.outletsclose()
    this.modalService.customerclose()
    this.modalService.supplierclose()
    this.modalService.productclose()
    this.modalService.scclose()
    this.modalService.ssclose()
    this.modalService.salesreportclose()
    this.modalService.userslistclose()
    this.modalService.setupgeneralopen()
  }

  onOutletsClick() {
    this.modalService.homepanelclose()
    this.modalService.setupgeneralclose()
    this.modalService.customerclose()
    this.modalService.supplierclose()
    this.modalService.productclose()
    this.modalService.scclose()
    this.modalService.ssclose()
    this.modalService.salesreportclose()
    this.modalService.userslistclose()
    this.modalService.outletsopen()
  }

  onCustomerClick() {
    this.modalService.homepanelclose()
    this.modalService.outletsclose()
    this.modalService.setupgeneralclose()
    this.modalService.supplierclose()
    this.modalService.productclose()
    this.modalService.scclose()
    this.modalService.ssclose()
    this.modalService.salesreportclose()
    this.modalService.userslistclose()
    this.modalService.customeropen()
  }

  onSuppliersClick() {
    this.modalService.homepanelclose()
    this.modalService.outletsclose()
    this.modalService.setupgeneralclose()
    this.modalService.customerclose()
    this.modalService.productclose()
    this.modalService.scclose()
    this.modalService.ssclose()
    this.modalService.salesreportclose()
    this.modalService.userslistclose()
    this.modalService.supplieropen()
  }

  onProductClick() {
    this.modalService.homepanelclose()
    this.modalService.outletsclose()
    this.modalService.setupgeneralclose()
    this.modalService.customerclose()
    this.modalService.supplierclose()
    this.modalService.scclose()
    this.modalService.ssclose()
    this.modalService.salesreportclose()
    this.modalService.userslistclose()
    this.modalService.productopen()
  }

  onSCClick() {
    this.modalService.homepanelclose()
    this.modalService.outletsclose()
    this.modalService.setupgeneralclose()
    this.modalService.customerclose()
    this.modalService.supplierclose()
    this.modalService.productclose()
    this.modalService.ssclose()
    this.modalService.salesreportclose()
    this.modalService.userslistclose()
    this.modalService.scopen()
  }

  onSellClick() {
    this.modalService.homepanelclose()
    this.modalService.outletsclose()
    this.modalService.setupgeneralclose()
    this.modalService.customerclose()
    this.modalService.supplierclose()
    this.modalService.productclose()
    this.modalService.scclose()
    this.modalService.salesreportclose()
    this.modalService.userslistclose()
    this.modalService.ssopen()
  }

  onSalesReportClick() {
    this.modalService.homepanelclose()
    this.modalService.outletsclose()
    this.modalService.setupgeneralclose()
    this.modalService.customerclose()
    this.modalService.supplierclose()
    this.modalService.productclose()
    this.modalService.scclose()
    this.modalService.ssclose()
    this.modalService.userslistclose()
    this.modalService.salesreportopen()
  }

  onUsersListClick() {
    this.modalService.homepanelclose()
    this.modalService.outletsclose()
    this.modalService.setupgeneralclose()
    this.modalService.customerclose()
    this.modalService.supplierclose()
    this.modalService.productclose()
    this.modalService.scclose()
    this.modalService.ssclose()
    this.modalService.salesreportclose()
    this.modalService.userslistopen()
  }

  protected readonly SelfData = SelfData;
}
