import { Component } from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";

@Component({
  selector: 'app-cclist',
  templateUrl: './cclist.component.html',
  styleUrl: './cclist.component.scss'
})
export class CclistComponent {
  constructor(public modalService: ModalserviceService) {}
}
