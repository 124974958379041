<!--<div class="black-background2">-->

<div class="card example-container-ii mat-elevation-z3">
  <p-table
    [value]="SelfData.sampledata[0].data"
    [paginator]="true"
    [rows]="100"
    [first]="first"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [rowsPerPageOptions]="[10, 20, 50, 100]"
    [styleClass]="'p-datatable-striped ' + selectedSize.class.toString()"
    selectionMode="single"
    [sortField]="'Designer'"
    (onPage)="pageChange($event)"
    (onFilter)="onfilter()"
    [globalFilterFields]="['Designer', 'barcode', 'Collection', 'Season', 'Type', 'Color', 'Description']"
    [tableStyle]="{'min-width': '50rem'}">
    <ng-template pTemplate="caption">
      <div class="flex">
        <!--                    <span class="p-inputgroup-addon custom-hover" matRipple (click)="onButtonAddClick()">-->
        <!--                        <i class="pi pi-plus" style="color: #000000"></i></span>-->
<!--        დიზაინერი-->
<!--        <span class="p-input-icon-left ml-auto">-->
<!--          <div class="p-inputgroup">-->
<!--            <span class="p-inputgroup-addon">-->
<!--              <i class="pi pi-search" style="color: #000000"></i>-->
<!--            </span>-->
<!--            <input pInputText type="text" (input)="onInput($event)" placeholder="Designer" [(ngModel)]="filterDesignertext"/>-->
<!--            <span class="p-inputgroup-addon custom-hover-red" matRipple (click)="clearDesigner()">-->
<!--              <i class="pi pi-times" style="color: #000000"></i></span>-->
        <nz-input-group nzSearch>
          <nz-select
            nzPlaceHolder="Designer"
            nzShowSearch
            nzAllowClear
            [nzDisabled]="SelfData.waitpanel"
            [nzShowArrow]="true"
            class="testdiv"
            [(ngModel)]="designerstring"
            (ngModelChange)="onInputDesigner($event)">
          <nz-option
            *ngFor="let option of iDesignerResult[0]?.data"
            [nzLabel]="option.description"
            [nzValue]="option.description"
          ></nz-option>
          </nz-select>
        </nz-input-group>
<!--          </div>-->
<!--        </span>-->

        <!--        ფერი-->

        <nz-input-group nzSearch>
          <nz-select
            nzPlaceHolder="Color"
            nzShowSearch
            nzAllowClear
            [nzDisabled]="SelfData.waitpanel"
            [nzShowArrow]="true"
            class="testdiv"
            [(ngModel)]="colorstring"
            (ngModelChange)="onInputColor($event)">
            <nz-option
              nzCustomContent
              *ngFor="let option of colorarray"
              [nzLabel]="option.colorname"
              [nzValue]="option">
              <span class="color-box" [style.background]="option.colorcode"></span>
              {{ option.colorname }}
            </nz-option>
          </nz-select>
        </nz-input-group>

<!--        <span class="p-input-icon-left ml-auto">-->
<!--          <div class="p-inputgroup">-->
<!--&lt;!&ndash;            <span class="p-inputgroup-addon">&ndash;&gt;-->
<!--&lt;!&ndash;              <i class="pi pi-search" style="color: #000000"></i>&ndash;&gt;-->
<!--&lt;!&ndash;            </span>&ndash;&gt;-->
<!--            <input pInputText type="text" (input)="onInput($event)" placeholder="Color" [(ngModel)]="filterColortext"/>-->
<!--            <span class="p-inputgroup-addon custom-hover-red" matRipple (click)="clearColor()">-->
<!--              <i class="pi pi-times" style="color: #000000"></i></span>-->
<!--          </div>-->
<!--        </span>-->

        <!--        ტიპი-->
        <nz-input-group nzSearch >
          <nz-select nzPlaceHolder="Type" nzShowSearch nzAllowClear [nzDisabled]="SelfData.waitpanel" [nzShowArrow]="true" class="testdiv"
            [(ngModel)]="typestring" (ngModelChange)="onInputType($event)">
            <nz-option *ngFor="let option of iType[0]?.data" [nzLabel]="option.description"
              [nzValue]="option.description"></nz-option>
          </nz-select>
        </nz-input-group>
<!--        <span class="p-input-icon-left ml-auto">-->
<!--          <div class="p-inputgroup">-->
<!--&lt;!&ndash;            <span class="p-inputgroup-addon">&ndash;&gt;-->
<!--&lt;!&ndash;              <i class="pi pi-search" style="color: #000000"></i>&ndash;&gt;-->
<!--&lt;!&ndash;            </span>&ndash;&gt;-->
<!--            <input pInputText type="text" (input)="onInput($event)" placeholder="Type" [(ngModel)]="filterTypetext"/>-->
<!--            <span class="p-inputgroup-addon custom-hover-red" matRipple (click)="clearType()">-->
<!--              <i class="pi pi-times" style="color: #000000"></i></span>-->
<!--          </div>-->
<!--        </span>-->

        <!--        ფაბრიკა-->
        <nz-input-group nzSearch>
          <nz-select nzPlaceHolder="Fabric" nzShowSearch nzAllowClear [nzDisabled]="SelfData.waitpanel" [nzShowArrow]="true" class="testdiv"
            [(ngModel)]="fabricstring" (ngModelChange)="onInputFabric($event)">
            <nz-option *ngFor="let option of iFabricResult[0]?.data" [nzLabel]="option.description"
              [nzValue]="option.description"></nz-option>
          </nz-select>
        </nz-input-group>
<!--        <span class="p-input-icon-left ml-auto">-->
<!--          <div class="p-inputgroup">-->
<!--&lt;!&ndash;            <span class="p-inputgroup-addon">&ndash;&gt;-->
<!--&lt;!&ndash;              <i class="pi pi-search" style="color: #000000"></i>&ndash;&gt;-->
<!--&lt;!&ndash;            </span>&ndash;&gt;-->
<!--            <input pInputText type="text" (input)="onInput($event)" placeholder="Fabric" [(ngModel)]="filterFabrictext"/>-->
<!--            <span class="p-inputgroup-addon custom-hover-red" matRipple (click)="clearFabric()">-->
<!--              <i class="pi pi-times" style="color: #000000"></i></span>-->
<!--          </div>-->
<!--        </span>-->

        <!--სეზონი-->
        <nz-input-group nzSearch>
          <nz-select nzPlaceHolder="Season" nzShowSearch nzAllowClear [nzDisabled]="SelfData.waitpanel" [nzShowArrow]="true" class="line1-edits-new inputedit testdiv"
            [(ngModel)]="seasonstring" (ngModelChange)="onInputSeason($event)">
            <nz-option *ngFor="let option of iSeason[0]?.data" [nzLabel]="option.description"
              [nzValue]="option.description"></nz-option>
          </nz-select>
        </nz-input-group>
<!--        <span class="p-input-icon-left ml-auto">-->
<!--          <div class="p-inputgroup">-->
<!--&lt;!&ndash;            <span class="p-inputgroup-addon">&ndash;&gt;-->
<!--&lt;!&ndash;              <i class="pi pi-search" style="color: #000000"></i>&ndash;&gt;-->
<!--&lt;!&ndash;            </span>&ndash;&gt;-->
<!--            <input pInputText type="text" (input)="onInput($event)" placeholder="Season" [(ngModel)]="filtertext"/>-->
<!--            <span class="p-inputgroup-addon custom-hover-red" matRipple (click)="clearSeason()">-->
<!--              <i class="pi pi-times" style="color: #000000"></i></span>-->
<!--          </div>-->
<!--        </span>-->

      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <!--                    -->
        <th style="width:4%">Options</th>
        <th style="width:17.6%">Collection<br>Designer<br>Season</th>
        <th style="width:12.6%">Barcode</th>
        <th style="width:12.6%">Type<br>Color</th>
        <th style="width:12.6%">Size<br>Size Range</th>
        <th style="width:22.6%">Description</th>
        <th style="width:7%">Image</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-product>
      <tr [pSelectableRow]="SelfData.sampledata[0].data"
        [ngStyle]="{'background-color': product['itemstatus'] == 0 ? '#ffffff' : '#ffdddd'}">
        <td>
          <div class="button-container-table">
            <button *ngIf="product['itemstatus'] == 0 && SelfData.selectlookmode != 0" type="button" nz-button (click)="onSelectedItemCLick(product['barcode'], product)">
              select
            </button>
<!--            <p-button *ngIf="product['itemstatus'] == 0 && SelfData.selectlookmode != 0"-->
<!--              label="Select" size="small" [outlined]="true" severity="secondary"-->
<!--              (click)="onSelectedItemCLick(product['barcode'], product)">-->
<!--            </p-button>-->
            <button *ngIf="product['itemstatus'] != 0 && SelfData.selectlookmode != 0" type="button" nz-button (click)="onSelectedItemCLick(product['barcode'], product)">
              Selected
            </button>
<!--            <p-button *ngIf="product['itemstatus'] != 0 && SelfData.selectlookmode != 0"-->
<!--              label="Selected" size="small" [outlined]="true" severity="secondary"-->
<!--              (click)="onSelectedItemCLick(product['barcode'], product)">-->
<!--            </p-button>-->
          </div>
        </td>
        <td>{{ product["Collection"] }}<br>{{ product?.["Designer"] }}<br>{{ product["Season"] }}</td>
        <td>
          <app-barcode [element]="product?.barcode" [background]="'rgba(255,255,255,0)'"
            class="barcode-component"></app-barcode>
        </td>
        <td>{{ product["Type"] }}<br>{{ product["Color"] }}</td>
        <td>{{ product["Size"] }}<br>{{ product["Size Range"] }}</td>
        <td>{{ product["Description"] }}</td>
        <td><img [src]="transformImageUrl(product['Image Link'])" alt="Image" class="image-size-80x80 shadow-4" (click)="onImageClick(product)"></td>
<!--        <td><img [src]="product['Image Link']" alt="Image" class="image-size-80x80 shadow-4" (click)="onImageClick(product)"></td>-->
      </tr>
    </ng-template>
  </p-table>
</div>
