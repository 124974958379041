<div class="left-image-container1">
<!--  <app-samplefieldsdialog *ngIf="!isHidden"></app-samplefieldsdialog>-->
<!--  <app-contactfieldsdialog *ngIf="!iscontactsHiden"></app-contactfieldsdialog>-->
</div>
<p class="sample-manager-text">Settings</p>
<div class="left-image-container">
<!--  <div class="button-container">-->
<!--    <button mat-stroked-button class="edit-profile-btn" (click)="showsamplefields()">-->
<!--      Sample Fields Settings-->
<!--    </button>-->
<!--  </div>-->
<!--  <div class="button-container">-->
<!--    <button mat-stroked-button class="edit-profile-btn" (click)="showcontactfields()">-->
<!--      Contact Fields Settings-->
<!--    </button>-->
<!--  </div>-->
  <div class="button-container">
    <button mat-stroked-button class="edit-profile-btn">
      Printer Settings
    </button>
  </div>
</div>
<button mat-icon-button class="close-btn" (click)="modalService.closeSettings()">
  <mat-icon>close</mat-icon>
</button>
