import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ModalserviceService} from '../../services/modalservice.service';
import {FormControl, Validators} from '@angular/forms';
import {
  GetBarcodeList,
  GetCollectionList,
  GetDesignerList,
  GetFabricList,
  GetImageList,
  GetLookNameList,
  GetManuList,
  GetMiscList,
  GetNotSettings,
  GetRegionList,
  GetSeasonList,
  GetSendOutList,
  GetSendOutSamples,
  GetSizeRangeList,
  GetStyleList,
  GetSWSList,
  GetSystemFields,
  GetTypeList,
  SelfData, SendOut1Data,
  SetContactsList,
  SetCustomFieldsValue,
  SetPullSheetList,
  SetSampleList, SetSampleListData, Speener,
} from '../../models/struct';
import {ServiceService} from '../../services/service.service';
import {YourappsserviceService} from '../../services/yourappsservice.service';
import {MatTableDataSource} from '@angular/material/table';
import {map, Observable, startWith, Subscription} from "rxjs";
import {MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from "@angular/material/autocomplete";
import moment from "moment";
import {MatDatepickerInputEvent} from "@angular/material/datepicker";

// import * as SocialBlade from 'socialblade';

interface InventoryStatus {
  value: string;
  viewValue: string;
}

interface Period {
  value: string;
  viewValue: string;
}

interface Style {
  value: string;
  viewValue: string;
}

interface Desinger {
  value: string;
  viewValue: string;
}

interface Barcode {
  value: string;
  viewValue: string;
}

interface Fabric {
  value: string;
  viewValue: string;
}

interface AvailabilityStatus {
  value: string;
  viewValue: string;
}

interface ColorArray {
  colorname: string;
  colorcode: string;
}

interface Gender {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-samplemanager',
  templateUrl: './samplemanager.component.html',
  styleUrls: ['./samplemanager.component.scss'],
})
export class SamplemanagerComponent implements OnInit, AfterViewInit, OnDestroy {
  departmentFormControl = new FormControl({value: '', disabled: true}, [
    Validators.required,
  ]);
  emailFormControl = new FormControl('');

  firstnameFormControl = new FormControl('');

  formcaption: string = ""

  private subscription?: Subscription;
  private subscription1?: Subscription;

  filteredDesignerOptions?: Observable<string[]>;
  filteredCollectionOptions?: Observable<string[]>;
  filteredSeasonOptions?: Observable<string[]>;
  filteredTypeOptions?: Observable<string[]>;
  filteredSizeOptions?: Observable<string[]>;
  filteredFabricOptions?: Observable<string[]>;

  designerptions: string[] = [];
  collectionoptions: string[] = [];
  seasonoptions: string[] = [];
  typeoptions: string[] = [];
  sizeoptions: string[] = [];
  fabricoptions: string[] = [];

  colorarray: ColorArray[] = [
    {colorname: 'White', colorcode: '#ffffff'},
    {colorname: 'Black ', colorcode: '#000000'},
    {colorname: 'Yellow', colorcode: '#FFFF00'},
    {colorname: 'Purple', colorcode: '#800080'},
    {colorname: 'Orange ', colorcode: '#FFA500'},
    {colorname: 'Blue ', colorcode: '#2196f3'},
    {colorname: 'Light Blue ', colorcode: '#03a9f4'},
    {colorname: 'Red', colorcode: '#ff0000'},
    {colorname: 'Buff', colorcode: '#DAA06D'},
    {colorname: 'Gray ', colorcode: '#808080'},
    {colorname: 'Green ', colorcode: '#00FF00'},
    {colorname: 'Pink', colorcode: '#FFC0CB'},
    {colorname: 'Violet', colorcode: '#8F00FF'},
    {colorname: 'Brown', colorcode: '#964B00'},
    {colorname: 'Silver', colorcode: '#C0C0C0'},
    {colorname: 'Cream', colorcode: '#FFFDD0'},
    {colorname: 'Gold', colorcode: '#FFD700'},
    {colorname: 'Beige', colorcode: '#F5F5DC'},
    {colorname: 'Navy', colorcode: '#202A44'},
  ];

  gender: Gender[] = [
    {value: 'Man', viewValue: 'Man'},
    {value: 'Unisex', viewValue: 'Unisex'},
    {value: 'Women', viewValue: 'Women'},
  ];


  desingerFormControl = new FormControl({value: '', disabled: false}, []);
  collectionFormControl = new FormControl({value: '', disabled: false}, []);
  seasonFormControl = new FormControl({value: '', disabled: false}, []);
  typeFormControl = new FormControl({value: '', disabled: false}, []);
  colorControl = new FormControl({value: '', disabled: false}, []);
  sizeControl = new FormControl({value: '', disabled: false}, []);
  fabricControl = new FormControl({value: '', disabled: false}, []);
  genderControl = new FormControl({value: '', disabled: false}, []);
  inventarystatysFormControl = new FormControl({value: '', disabled: false}, []);

  selectedValueInventaryStatys: string | null = null;
  selectedValueStyle: string | undefined;
  selectedValueGender: string | null = null;
  selectedValueDesinger: string | undefined;
  selectedValueBarcode: string | undefined;
  selectedValueFabric: string | undefined;
  AvailabilitySselectedValuetatus: string | null = null;
  styleControl = new FormControl<string>('');
  miscControl = new FormControl<string>('');
  filteredOptions?: Observable<string[]>;
  filteredRegionOptions?: Observable<string[]>;
  filteredManuOptions?: Observable<string[]>;
  filteredStyleOptions?: Observable<string[]>;
  filteredMisceOptions?: Observable<string[]>;
  filteredPatternOptions?: Observable<string[]>;
  filteredSizeRangeOptions?: Observable<string[]>;
  filteredBarcodeOptions?: Observable<string[]>;
  psemailControl = new FormControl<string>('');
  selectedValuePeriod: string | undefined;
  samplemanager = false;
  contactmanager = false;
  cdllistmanager = false;
  sendoutmanager = false;
  requestmanager = false;
  contactmailmanager = false;
  sendoutpendingmanager = false;
  lookmanager = false
  pullsheetmanager = false
  neededrefresh: boolean = true
  iNotSettingsResult: GetNotSettings[] = [];
  inventorystatus: InventoryStatus[] = [
    // {value: 'Add New Record', viewValue: 'Add New Record'},
    {value: 'Inventory', viewValue: 'Inventory'},
    {value: 'Imported', viewValue: 'Imported'},
  ];

  style: Style[] = [
    {value: '(Empty/None)', viewValue: '(Empty/None)'},
    {value: 'Value1', viewValue: 'Value1'},
    {value: 'Value2', viewValue: 'Value2'},
  ];

  desinger: Desinger[] = [
    {value: '(Empty/None)', viewValue: '(Empty/None)'},
    {value: 'Value1', viewValue: 'Value1'},
    {value: 'Value2', viewValue: 'Value2'},
  ];

  period: Period[] = [
    // { value: '(Empty/None)', viewValue: '(Empty/None)' },
    {value: '', viewValue: ''},
    {value: 'The last week', viewValue: 'The last week'},
    {value: 'Last month', viewValue: 'Last month'},
    {value: 'Last 3 months', viewValue: 'Last 3 months'},
    {value: 'Last 6 months', viewValue: 'Last 6 months'},
  ];

  barcode: Barcode[] = [
    {value: '(Empty/None)', viewValue: '(Empty/None)'},
    {value: 'Value1', viewValue: 'Value1'},
    {value: 'Value2', viewValue: 'Value2'},
  ];

  fabric: Fabric[] = [
    {value: '(Empty/None)', viewValue: '(Empty/None)'},
    {value: 'Value1', viewValue: 'Value1'},
    {value: 'Value2', viewValue: 'Value2'},
  ];

  availabilitystatus: AvailabilityStatus[] = [
    {value: '(Empty/None)', viewValue: '(Empty/None)'},
    {value: 'Not Marked On Loan', viewValue: 'Not Marked On Loan'},
    {value: 'Marked On Loan', viewValue: 'Marked On Loan'},
  ];

  iSystemFields: GetSystemFields[] = [];
  iDesignerResult: GetDesignerList[] = [];
  iCollection: GetCollectionList[] = [];
  iSeason: GetSeasonList[] = [];
  iType: GetTypeList[] = [];
  iSize: GetTypeList[] = [];
  iFabricResult: GetFabricList[] = [];

  iSWSResult: GetSWSList[] = [];
  iRegionResult: GetRegionList[] = [];
  iManuResult: GetManuList[] = [];
  iStyle: GetStyleList[] = [];
  iMisc: GetMiscList[] = [];
  iPattern: GetTypeList[] = [];
  iImages: GetImageList[] = [];
  iSizeRange: GetSizeRangeList[] = [];
  iBarcode: GetBarcodeList[] = [];
  iCFV: SetCustomFieldsValue[] = [];
  ingredient!: string;
  ingredient1!: string;
  formControls: { [key: string]: FormControl } = {
    desingerFormControl: this.desingerFormControl,
    collectionFormControl: this.collectionFormControl,
    seasonFormControl: this.seasonFormControl,
    typeFormControl: this.typeFormControl,
    colorControl: this.colorControl,
    sizeControl: this.sizeControl,
    fabricControl: this.fabricControl,
    genderControl: this.genderControl,
  };
  showButton: { [key: string]: boolean } = {
    desingerFormControl: false,
    collectionFormControl: false,
    seasonFormControl: false,
    typeFormControl: false,
    colorControl: false,
    sizeControl: false,
    fabricControl: false,
    genderControl: false
  };

  selectedDate: Date | moment.Moment | null = null;
  selectedPeriod: string | null = null;

  myControl = new FormControl<string>('');
  myRegionControl = new FormControl<string>('');
  notesRegionControl = new FormControl<string>('');
  manuControl = new FormControl<string>('');
  associatedControl = new FormControl<string>('');
  sizerangeControl = new FormControl<string>('');
  barcodeControl = new FormControl<string>('');
  descriptionControl = new FormControl<string>('');
  patternControl = new FormControl<string>('');
  quantityControl = new FormControl('1', [
    Validators.required,
    Validators.min(1),
    Validators.pattern('^[0-9]*$'),
  ]);
  priceControl = new FormControl('0', [
    Validators.required,
    Validators.min(0),
    Validators.pattern('^\\d*(\\.\\d+)?$'),
  ]);
  price1Control = new FormControl('0', [
    Validators.required,
    Validators.min(0),
    Validators.pattern('^\\d*(\\.\\d+)?$'),
  ]);
  price2Control = new FormControl('0', [
    Validators.required,
    Validators.min(0),
    Validators.pattern('^\\d*(\\.\\d+)?$'),
  ]);
  price3Control = new FormControl('0', [
    Validators.required,
    Validators.min(0),
    Validators.pattern('^\\d*(\\.\\d+)?$'),
  ]);
  price4Control = new FormControl('0', [
    Validators.required,
    Validators.min(0),
    Validators.pattern('^\\d*(\\.\\d+)?$'),
  ]);
  price5Control = new FormControl('0', [
    Validators.required,
    Validators.min(0),
    Validators.pattern('^\\d*(\\.\\d+)?$'),
  ]);

  options: string[] = [];
  regionoptions: string[] = [];
  manuoptions: string[] = [];
  styleoptions: string[] = [];
  miscoptions: string[] = [];
  patternoptions: string[] = [];
  sizerangeoptions: string[] = [];
  barcodeoptions: string[] = [];
  cfvoptions: string[] = [];

  onInputChange(event: Event, controlName: string) {
    this.showButton[controlName] = (event.target as HTMLInputElement).value != '';
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent, controlName: string) {
    this.showButton[controlName] = event.option.value != '';
  }

  clearValue(event: MouseEvent, controlName: string, autoInput: MatAutocompleteTrigger | null): void {
    event.stopPropagation();
    if (autoInput) autoInput.closePanel();
    this.formControls[controlName].setValue("");
    this.showButton[controlName] = false;
  }

  constructor(
    public modalService: ModalserviceService,
    private yourappsService: YourappsserviceService,
    private serviceservice: ServiceService
  ) {
    if (SelfData.createsamplebutton) {
      this.formcaption = "Sample Manager"
    } else {
      this.formcaption = "Choose Item"
    }
    this.departmentFormControl.setValue(SelfData.account[0].data[0].company);
    var accountid: number = 0;
    if (SelfData.account[0].data[0].parentid == 0) {
      accountid = SelfData.account[0].data[0].id;
    } else {
      accountid = SelfData.account[0].data[0].parentid;
    }

    var accountid: number = 0;
    if (SelfData.account[0].data[0].parentid == 0) {
      accountid = SelfData.account[0].data[0].id;
    } else {
      accountid = SelfData.account[0].data[0].parentid;
    }

    // // SwsList
    // this.serviceservice
    //   .setSWSList({accountid: accountid})
    //   .subscribe((swsvalue) => {
    //     this.iSWSResult[0] = swsvalue;
    //     SelfData.sws = this.iSWSResult;
    //     this.filteredOptions = this.setDataAndFilter(
    //       this.myControl,
    //       swsvalue,
    //       this.options,
    //       0
    //     );
    //   });
    //
    // // RegionList
    // this.serviceservice
    //   .setRegionList({accountid: accountid})
    //   .subscribe((regionvalue) => {
    //     this.iRegionResult[0] = regionvalue;
    //     SelfData.region = this.iRegionResult;
    //     this.filteredRegionOptions = this.setDataAndFilter(
    //       this.myRegionControl,
    //       regionvalue,
    //       this.regionoptions,
    //       1
    //     );
    //   });
    // // DesignerList
    // this.serviceservice
    //   .setDesignerList({accountid: accountid})
    //   .subscribe((desingervalue) => {
    //     this.iDesignerResult[0] = desingervalue;
    //     SelfData.designer = this.iDesignerResult;
    //     this.filteredDesignerOptions = this.setDataAndFilter(
    //       this.desingerFormControl,
    //       desingervalue,
    //       this.designerptions,
    //       2
    //     );
    //   });
    // // FabricList
    // this.serviceservice
    //   .setFabricList({accountid: accountid})
    //   .subscribe((fabricvalue) => {
    //     this.iFabricResult[0] = fabricvalue;
    //     SelfData.fabric = this.iFabricResult;
    //     this.filteredFabricOptions = this.setDataAndFilter(
    //       this.fabricControl,
    //       fabricvalue,
    //       this.fabricoptions,
    //       3
    //     );
    //   });
    // // ManuList
    // this.serviceservice
    //   .setManuList({accountid: accountid})
    //   .subscribe((manuvalue) => {
    //     this.iManuResult[0] = manuvalue;
    //     SelfData.manu = this.iManuResult;
    //     this.filteredManuOptions = this.setDataAndFilter(
    //       this.manuControl,
    //       manuvalue,
    //       this.manuoptions,
    //       4
    //     );
    //   });
    // // CollectionList
    //
    // this.serviceservice
    //   .setCollectionList({accountid: accountid})
    //   .subscribe((collectionvalue) => {
    //     this.iCollection[0] = collectionvalue;
    //     SelfData.collection = this.iCollection;
    //     this.filteredCollectionOptions = this.setDataAndFilter(
    //       this.collectionFormControl,
    //       collectionvalue,
    //       this.collectionoptions,
    //       5
    //     );
    //   });
    // // SeasonList
    // this.serviceservice
    //   .setSeasonList({accountid: accountid})
    //   .subscribe((seasonvalue) => {
    //     this.iSeason[0] = seasonvalue;
    //     SelfData.season = this.iSeason;
    //     this.filteredSeasonOptions = this.setDataAndFilter(
    //       this.seasonFormControl,
    //       seasonvalue,
    //       this.seasonoptions,
    //       6
    //     );
    //   });
    // // StyleList
    // this.serviceservice
    //   .setStyleList({accountid: accountid})
    //   .subscribe((value) => {
    //     this.iStyle[0] = value;
    //     SelfData.style = this.iStyle;
    //     this.filteredStyleOptions = this.setDataAndFilter(
    //       this.styleControl,
    //       value,
    //       this.styleoptions,
    //       7
    //     );
    //   });
    // // MisceList
    // this.serviceservice
    //   .setMiscList({accountid: accountid})
    //   .subscribe((value) => {
    //     this.iMisc[0] = value;
    //     SelfData.misc = this.iMisc;
    //     this.filteredMisceOptions = this.setDataAndFilter(
    //       this.miscControl,
    //       value,
    //       this.miscoptions,
    //       9
    //     );
    //   });
    // // TypeList
    // this.serviceservice
    //   .setTypeList({accountid: accountid})
    //   .subscribe((value) => {
    //     this.iType[0] = value;
    //     SelfData.type = this.iType;
    //     this.filteredTypeOptions = this.setDataAndFilter(
    //       this.typeFormControl,
    //       value,
    //       this.typeoptions,
    //       10
    //     );
    //   });
    // //sizeList
    // this.serviceservice
    //   .setSizeList({accountid: accountid})
    //   .subscribe((value) => {
    //     this.iSize[0] = value;
    //     SelfData.size = this.iSize;
    //     this.filteredSizeOptions = this.setDataAndFilter(
    //       this.sizeControl,
    //       value,
    //       this.sizeoptions,
    //       11
    //     );
    //   });
    // //patternList
    // this.serviceservice
    //   .setPatternList({accountid: accountid})
    //   .subscribe((value) => {
    //     this.iPattern[0] = value;
    //     SelfData.pattern = this.iPattern;
    //     this.filteredPatternOptions = this.setDataAndFilter(
    //       this.patternControl,
    //       value,
    //       this.patternoptions,
    //       12
    //     );
    //   });
    // //sizerangeList
    // this.serviceservice
    //   .setSizeRangeList({accountid: accountid})
    //   .subscribe((value) => {
    //     this.iSizeRange[0] = value;
    //     SelfData.sizerange = this.iSizeRange;
    //     this.filteredSizeRangeOptions = this.setDataAndFilter(
    //       this.sizerangeControl,
    //       value,
    //       this.sizerangeoptions,
    //       13
    //     );
    //   });
    //
    // //barcodelist
    // this.serviceservice
    //   .setBarcodeList({accountid: accountid})
    //   .subscribe((value) => {
    //     this.iBarcode[0] = value;
    //     SelfData.barcode = this.iBarcode;
    //     this.filteredBarcodeOptions = this.setDataAndFilter(
    //       this.barcodeControl,
    //       value,
    //       this.barcodeoptions,
    //       14
    //     );
    //   });

    this.serviceservice
      .setCFVList({
        accountid: accountid,
        framename: 'createsampledialog',
        sampleid: 0,
      })
      .subscribe((value) => {
        this.iCFV[0] = value;
        SelfData.cfv = this.iCFV;
      });

    // this.serviceservice.setSampleList({accountid: accountid, page: 1, pageSize: 50}).subscribe({
    //   next: (value) => {
    //     if (value) {
    //     } else {
    //       console.log('The response is empty or undefined.');
    //     }
    //   },
    //   error: (error) => {
    //     console.error('Error occurred during the subscription:', error);
    //   },
    // });

    this.serviceservice
      .setNotSettings({accountid: accountid})
      .subscribe((value) => {
        this.iNotSettingsResult[0] = value;
        SelfData.notificationsettings = this.iNotSettingsResult;
      });

    // this.serviceservice.setSampleList({accountid: accountid, page: 1, pageSize: 50}).subscribe({
    //   next: (value) => {
    //     if (value) {
    //       this.iSampleLookDataResult[0] = value;
    //       SelfData.samplelookdata = this.iSampleLookDataResult;
    //       // console.log(SelfData.samplelookdata)
    //     } else {
    //       console.log('The response is empty or undefined.');
    //     }
    //   },
    //   error: (error) => {
    //     console.error('Error occurred during the subscription:', error);
    //   },
    // });

    // this.serviceservice
    //   .getSystemFieldsList({ accountid: accountid, framename: "createsampledialog" })
    //   .subscribe((value) => {
    //     this.iSystemFields[0] = value;
    //     SelfData.systemfields = this.iSystemFields;
    //     console.dir(SelfData.systemfields)
    //   });
    this.yourappsService.reloadSampleList.subscribe((description) => {
      console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>")
      yourappsService.getPaginator(0)
      Speener.samplevisiblemode = true;
      this.modalService.closespr();
      this.modalService.closeso();
      SelfData.createsamplebutton = true;
      // this.modalService.samplemanageropen();
      // this.yourappsService.setSampleAndContavcts(true, false, false, false, false, false, false);

      // Инициализируем процесс загрузки данных

      this.iSampleDataResult = []
      setTimeout(() => {
        if (this.neededrefresh) {
          this.neededrefresh = false
          this.loadSampleDataRecursively(1, 100000);
        }
      }, 3000);

      // this.serviceservice.setSampleList({accountid: accountid, page: 1, pageSize: 1000}).subscribe({
      //   next: (value) => {
      //     if (value) {
      //       this.iSampleDataResult[0] = value;
      //       SelfData.sampledata = this.iSampleDataResult;
      //       this.yourappsService.getReloadSampleTableList();
      //     } else {
      //       console.log('The response is empty or undefined.');
      //     }
      //   },
      //   error: (error) => {
      //     console.error('Error occurred during the subscription:', error);
      //   },
      // });
    });
  }

  iSampleDataResult: SetSampleListData[] = [];

  loadSampleDataRecursively(page: number, pageSize: number) {
    this.serviceservice.setSampleList({ accountid: this.getAccountID(), page, pageSize, forceRefresh: false, samplestyle: SelfData.samplestyle }).subscribe({
      next: (value) => {
        Speener.samplevisiblemode = false;

        SelfData.sampledata = [{Code: 0, ErrorMessage: '', data: []}];
        if (value && value.data && value.data.length > 0) {
          // Предполагаем, что iSampleDataResult должен хранить массив объектов SetSampleListData
          this.iSampleDataResult = [...this.iSampleDataResult, ...value.data];

          SelfData.sampledata[0].data = this.iSampleDataResult
          this.yourappsService.getReloadCurSampleList()
          // console.log("this.iSampleDataResult", SelfData.sampledata[0].data)
          // this.updateUI();

          if (value.data.length < pageSize) {
            this.neededrefresh = true
            this.yourappsService.getReloadCurSampleList()
            console.log('All data has been loaded');
          } else {
            this.loadSampleDataRecursively(page + 1, pageSize);
          }
        } else {
          console.log('The response is empty or undefined.');
        }
      },
      error: (error) => {
        Speener.samplevisiblemode = false;
        console.error('Error occurred:', error);
      }
    });
  }

  private setDataAndFilter(
    control: FormControl,
    response: any,
    options: string[],
    type: number
  ) {
    let results = response.data;
    for (let data of results) {
      options.push(data.description);
    }
    return control.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || '', type))
    );
  }

  private _filter(value: string, type: number): string[] {
    const filterValue = value.toLowerCase();

    // Map the type to the corresponding options array
    const optionsMap: { [key: number]: string[] } = {
      1: this.regionoptions,
      2: this.designerptions,
      3: this.fabricoptions,
      4: this.manuoptions,
      5: this.collectionoptions,
      6: this.seasonoptions,
      7: this.styleoptions,
      9: this.miscoptions,
      10: this.typeoptions,
      11: this.sizeoptions,
      12: this.patternoptions,
      13: this.sizerangeoptions,
      14: this.barcodeoptions,
    };

    if (optionsMap[type]) {
      return optionsMap[type].filter((option) =>
        option.toLowerCase().includes(filterValue)
      );
    } else {
      console.warn(`Unexpected filter type: ${type}`);
      return [];
    }
  }

  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
      ? SelfData.account[0].data[0].id
      : SelfData.account[0].data[0].parentid;
  }

  ngAfterViewInit(): void {

  }

  ngOnInit(): void {
    console.log("ngOnInit")
    this.subscription = this.modalService.isLookVisibled$.subscribe(isVisible => {
      if (!isVisible) {

        this.subscription1 = this.yourappsService.reloadLooks.subscribe((description) => {
          if (this.subscription1) {
            this.onButtonLookSearchClick()
          }
        });

        // Do something when app-createlook is hidden...

      }
    });

    this.yourappsService.sampleandcontacts$.subscribe((value) => {
      console.log(value)
      this.samplemanager = value[0];
      this.contactmanager = value[1];
      this.sendoutmanager = value[2];
      this.lookmanager = value[3];
      this.pullsheetmanager = value[4];
      this.requestmanager = value[5];
      this.cdllistmanager = value[6];
      this.sendoutpendingmanager = value[7];
      this.contactmailmanager = value[8];
      this.modalService.closeSampleHeader();
      this.modalService.closeSampleLabel();
      this.modalService.closeSampleGrid();
      this.modalService.closeLookList()
      this.modalService.closeReques()
      this.modalService.selectPullsheetcolse()
      console.log("this.sendoutpendingmanager", this.sendoutpendingmanager)
    });
  }

  // iSampleDataResult: SetSampleList[] = [];
  iLookNameResult: GetLookNameList[] = [];
  iSampleLookDataResult: SetSampleList[] = [];

  onButtonCreateSampleClick() {
    // SelfData.sampledata = [];
    SelfData.isduplicate = false
    SelfData.updatebuttonisenabled = true
    this.yourappsService.selectSampleData(null);
    this.modalService.createsampleropen();
  }

  onButtonCreateContactClick() {
    // SelfData.contactdata = [];
    // this.yourappsService.selectSampleData(null);
    this.yourappsService.contact$.subscribe((value) => {
      setTimeout(() => {
        this.Speener.sendoutvisiblemode = true; // Or whatever condition is causing the issue
      });
      this.modalService.samplemanageropen();
      this.yourappsService.setSampleAndContavcts(false, true, false, false, false, false, false, false, false);
      this.serviceservice.setContactsList({accountid: this.getAccountID(), contactsid: 0})
        .subscribe({
          next: (value) => {
            if (value) {
              this.iContactsResult[0] = value;
              setTimeout(() => {
                this.Speener.sendoutvisiblemode = false; // Or whatever condition is causing the issue
              });
              SelfData.contactdata = this.iContactsResult;
              this.modalService.createContactLabel()

            } else {
              console.log('The response is empty or undefined.');
            }
          },
          error: (error) => {
            console.error('Error occurred during the subscription:', error);
          },
        });
    })
    this.yourappsService.selectContact(null, 0)
    this.modalService.createcontactopen();
  }

  onButtonCreateNotSettings() {
    SelfData.scannedlist = [{code: 0, errorMessage: '', data: []}];
    this.yourappsService.selectSampleData(null);
    this.modalService.createnotsettingsopen();
  }

  onButtonCreateLookclick() {
    this.modalService.createlookopen();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onButtonCreateSendOut() {
    this.yourappsService.selectSampleData(null);
    SendOut1Data.reset()
    SelfData.sendoutid = 0
    SelfData.sendoutopentype = 0
    SelfData.saveonclose = true
    SelfData.fullPage = false
    SelfData.sendoutgroupecaption = "Send Out Details";
    this.yourappsService.selectedSendOut(null);
    this.modalService.createsendoutopen();
  }

  onButtonPullSheetTemplateClick() {
    this.modalService.openPullSheetTemplate()
  }

  filters: { [key: string]: string | null } = {
    designerProperty: '',
    collectionProperty: '',
    seasonProperty: '',
    typeProperty: '',
    colorProperty: '',
    sizeProperty: '',
    fabricProperty: '',
    genderProperty: '',
    myProperty: '',
    myRegionProperty: '',
    manuProperty: '',
    sizerangeProperty: '',
    barcodeProperty: '',
    patternProperty: ''
  };

  getMapping(key: string): string {
    const map: { [key: string]: string } = {
      designerProperty: "Designer",
      collectionProperty: "Collection",
      seasonProperty: "Season",
      typeProperty: "Type",
      colorProperty: "Color",
      sizeProperty: "Size",
      fabricProperty: "Fabric",
      genderProperty: "Gender",
      myProperty: "MyProperty", // Adjust this as per your actual data key if needed
      myRegionProperty: "Region",
      manuProperty: "Manufacturer",
      sizerangeProperty: "Size Range",
      barcodeProperty: "barcode",
      patternProperty: "Pattern",
      miscProperty: "Misc"
    };
    return map[key];
  }

  filterData(data: any[], filters: { [key: string]: string | null }): any[] {
    // Check if data is an array
    if (!Array.isArray(data)) {
      // console.error('filterData called with non-array data:', data);
      // Handle the error appropriately, maybe return an empty array or throw an error
      return [];
    }

    return data.filter(item => {
      for (const key in filters) {
        const actualKey = this.getMapping(key);
        if (filters[key] && item[actualKey] !== filters[key]) {
          return false;
        }
      }
      return true;
    });
  }

  onMailEditorClick() {
    console.log("Clicked")
    this.modalService.openEmailEditor()
  }

  onMailEditorCreateClick() {
    console.log("Clicked")
    this.modalService.openEmailEditor()
  }

  onButtonSearchClick() {
    this.modalService.openCS()
    // Update filter values
    // this.filters['designerProperty'] = this.desingerFormControl.value;
    // this.filters['collectionProperty'] = this.collectionFormControl.value;
    // this.filters['seasonProperty'] = this.seasonFormControl.value;
    // this.filters['typeProperty'] = this.typeFormControl.value;
    // this.filters['colorProperty'] = this.colorControl.value;
    // this.filters['sizeProperty'] = this.sizeControl.value;
    // this.filters['fabricProperty'] = this.fabricControl.value;
    // this.filters['genderProperty'] = this.genderControl.value;
    // this.filters['myProperty'] = this.myControl.value;
    // this.filters['myRegionProperty'] = this.myRegionControl.value;
    // this.filters['manuProperty'] = this.manuControl.value;
    // this.filters['sizerangeProperty'] = this.sizerangeControl.value;
    // this.filters['barcodeProperty'] = this.barcodeControl.value;
    // this.filters['patternProperty'] = this.patternControl.value;
    // this.filters['miscProperty'] = this.miscControl.value;

    // this.serviceservice.setSampleList({accountid: this.getAccountID()}).subscribe({
    //   next: (value) => {
    //     if (value) {
    //       this.iSampleDataResult[0] = value;
    //       const filteredData = this.filterData(this.iSampleDataResult[0].data, this.filters);
    //       console.log("filteredData", filteredData);
    //       SelfData.sampledata = []
    //       SelfData.sampledata = this.iSampleDataResult;
    //       SelfData.sampledata[0].data = filteredData;
    //       SelfData.samplelookdata = this.iSampleDataResult;
    //       this.yourappsService.getReloadSampleTableList();
    //       this.modalService.createSampleHeader();
    //       this.modalService.createSampleLabel();
    //       this.modalService.closeSampleGrid();
    //       this.modalService.closeLookList();
    //       this.modalService.sendoutlistclose();
    //       this.modalService.closeContactLabel();
    //       this.modalService.selectPullsheetcolse()
    //     } else {
    //       console.log('The response is empty or undefined.');
    //     }
    //   },
    //   error: (error) => {
    //     console.error('Error occurred during the subscription:', error);
    //   }
    // });
  }

  onButtonClearAllClick() {
    this.selectedValueInventaryStatys = null;
    this.selectedValueGender = null
    this.AvailabilitySselectedValuetatus = null
    this.desingerFormControl.setValue('');
    this.collectionFormControl.setValue('');
    this.seasonFormControl.setValue('');
    this.typeFormControl.setValue('');
    this.colorControl.setValue('');
    this.sizeControl.setValue('');
    this.fabricControl.setValue('');
    this.genderControl.setValue('');
    this.myControl.setValue('');
    this.myRegionControl.setValue('');
    this.manuControl.setValue('');
    this.sizerangeControl.setValue('');
    this.barcodeControl.setValue('');
    this.patternControl.setValue('');
    this.styleControl.setValue("")
    this.descriptionControl.setValue("")
    this.miscControl.setValue("")
  }

  onButtonPullSheetClearAllClick() {
    this.psemailControl.setValue("")
    this.selectedDate = null
    this.selectedPeriod = null
    this.ingredient = ""
    this.ingredient1 = ""
  }

  iContactsResult: SetContactsList[] = [];

  onButtonContactsSearchClick() {
    this.serviceservice.setContactsList({accountid: this.getAccountID(), contactsid: 0})
      .subscribe({
        next: (value) => {
          if (value) {
            this.iContactsResult[0] = value;
            SelfData.contactdata = this.iContactsResult;

            this.modalService.closeSampleHeader();
            this.modalService.closeSampleLabel();
            this.modalService.closeSampleGrid();
            this.modalService.closeLookList()
            this.modalService.closeReques()
            this.modalService.sendoutlistclose();
            this.modalService.createContactLabel()
            this.modalService.selectPullsheetcolse()
          } else {
            console.log('The response is empty or undefined.');
          }
        },
        error: (error) => {
          console.error('Error occurred during the subscription:', error);
        },
      });
  }

  onButtonLookSearchClick() {
    this.modalService.openCS()
  }

  iSendOutList: GetSendOutList[] = [];

  onButtonSendOutSearchClick() {
    SelfData.selectsendoutmode = 0
    this.modalService.sendoutlistclose();
    this.serviceservice
      .getSendOutlist({accountid: this.getAccountID()})
      .subscribe((value) => {
        this.modalService.closeSampleHeader();
        this.modalService.closeSampleLabel();
        this.modalService.closeSampleGrid();
        this.modalService.closeLookList();
        this.modalService.closeReques()
        this.modalService.sendoutlistopen();
        this.modalService.selectPullsheetcolse()
        this.iSendOutList[0] = value;
        SelfData.sendoutlist = this.iSendOutList;
        console.log(SelfData.sendoutlist)
      });
  }

  createRequest() {
    this.yourappsService.selectSampleData(null);
    SelfData.sendoutopentype = 0
    SelfData.sendoutmode = 1
    SelfData.sendoutgroupecaption = "Request Details"
    this.modalService.openRequest();
  }

  onDateChange(event: MatDatepickerInputEvent<Date>) {
    this.selectedPeriod = null
    console.log(event.value); // this will log the selected date
    // You can now handle or execute whatever logic you need here when the date changes
  }

  onPeriodChange(event: any) {
    this.selectedDate = null;
  }

  iPullSheetList: SetPullSheetList[] = [];

  onButtonPullSheetSearchClick() {
    let dateOnlyString: string | null = null;

    if (moment.isMoment(this.selectedDate)) {
      dateOnlyString = this.selectedDate.format('YYYY-MM-DD');
    } else if (this.selectedDate instanceof Date) {
      // Convert the native JavaScript Date object to a string format
      dateOnlyString = this.selectedDate.toISOString().split('T')[0];
    }
    this.serviceservice
      .selectpullsheetlist({
        accountid: this.getAccountID(),
        email: this.psemailControl.value?.trim() || "",
        date: dateOnlyString,
        period: this.selectedPeriod,
        status: this.ingredient,
        sendout: this.ingredient1
      })
      .subscribe((value) => {
        this.modalService.closeSampleHeader();
        this.modalService.closeSampleLabel();
        this.modalService.closeSampleGrid();
        this.modalService.closeLookList();
        this.modalService.closeReques()
        this.modalService.sendoutlistclose();
        this.modalService.selectPullsheetopen()
        this.iPullSheetList[0] = value;
        SelfData.selectpullsheetlist = this.iPullSheetList;
      });
  }

  createPullSheet() {
    this.modalService.openCPS()
  }


  protected readonly SelfData = SelfData;
  protected readonly Speener = Speener;
}
