<button mat-icon-button type="button" class="close-btn" (click)="onButtonCloseClick()">
  <mat-icon>close</mat-icon>
</button>
<p class="sample-manager-text">{{formcaption}}</p>

<div class="row">
  <div class="forma-group">
    <mat-form-field class="selector-full-width" appearance="fill">
      <mat-label>Designer</mat-label>
      <input type="text" matInput placeholder="Select or type..." [matAutocomplete]="auto2"
        [formControl]="desingerFormControl" #autoInput2="matAutocompleteTrigger"/>
      <button mat-stroked-button type="button" class="add-button" [disabled]="editdesignerdisabled" matSuffix (click)="add($event, 2, autoInput2)">
        <mat-icon class="center-icon">add</mat-icon>
      </button>
      <mat-autocomplete #auto2="matAutocomplete">
        <mat-option *ngFor="let item of filteredDesignerOptions | async" [value]="item">
          {{ item }}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="desingerFormControl.hasError('required')">
        Designer is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </div>
  <div class="forma-group">
    <mat-form-field class="selector-full-width" appearance="fill">
      <mat-label>Season</mat-label>
      <input type="text" matInput placeholder="Select or type..." [matAutocomplete]="auto3"
        [formControl]="seasonFormControl"
        #autoInput3="matAutocompleteTrigger"/>

      <button mat-stroked-button type="button" class="add-button" [disabled]="editsesiondisabled" matSuffix (click)="add($event, 6, autoInput3)">
        <mat-icon class="center-icon">add</mat-icon>
      </button>
      <mat-autocomplete #auto3="matAutocomplete">
        <mat-option *ngFor="let item of filteredSeasonOptions | async" [value]="item">
          {{ item }}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="seasonFormControl.hasError('required')">
        Season is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </div>
  <div class="forma-group">
    <mat-form-field class="selector-full-width" appearance="fill">
      <mat-label>Collection</mat-label>
      <input type="text" matInput [formControl]="collectionFormControl">
      <mat-error *ngIf="collectionFormControl.hasError('required')">
        Collection is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </div>
</div>

<div class="imagelist">
  <div class="updatebutton">
    <button class="btnupdatte" mat-raised-button type="button" [disabled]="!uploadbuttonenable" (click)="onButtonPdfClick()">
      Upload PDF File
      <mat-icon>upload</mat-icon>
    </button>
    <div class="progressbar">
      <smart-progress-bar theme="fm" *ngIf="progressbarshow" [indeterminate]="indeterminate" #progressbar id="progressbar1" class="progressbar"
        [showProgressValue]="true"></smart-progress-bar>
    </div>
  </div>
  <div class="file-container-images">
    <mat-card *ngFor="let item of selectedImageFiles; let i = index" class="card-item shadow-4">
      <button mat-mini-fab type="button" class="close-button" (click)="deleteImageFile($event, i)">
        <mat-icon class="custom-icon">close</mat-icon>
      </button>
      <img [src]="transformImageUrl(item.url)" (click)="showFullWindowImage(transformImageUrl(item.url))" class="thumbnail" alt="Image thumbnail">
<!--      <img [src]="item.url" (click)="showFullWindowImage(item.url)" class="thumbnail" alt="Image thumbnail">-->
    </mat-card>

    <!-- Single preview modal for full window image preview -->
    <div *ngIf="previewVisible" class="overlay" (click)="hideFullWindowImage()">
      <img [src]="previewUrl" class="overlay-content" alt="Full image preview"
        (mousedown)="startDragging($event)" (wheel)="zoomImage($event)">
    </div>
  </div>
</div>

<div class="button-container">
  <button mat-raised-button class="button-settings" type="button" [disabled]="!uploadbuttonenable" (click)="onButtonUploadClick()">
    Confirm
  </button>
</div>

<mat-menu #menu="matMenu">
  <input type="file" #fileInput (change)="onFileSelected($event)" accept=".pdf" style="display: none;" />
  <button mat-menu-item (click)="uploadImageFiles()">
    <span>Upload Photos</span>
  </button>
  <button mat-menu-item (click)="onButtonPdfClick()">
    <span>Upload PDF File</span>
  </button>
</mat-menu>

<p-confirmDialog #cd [style]="{width: '25vw'}" [closable]="true">
  <!--  <ng-template pTemplate="header">-->
  <!--    <h3 class="red-text">This item is on loan!</h3>-->
  <!--  </ng-template>-->
  <ng-template pTemplate="footer">
    <!--        <button type="button" pButton label="No" (click)="cd.reject()"></button>-->
    <div class="button-container-rr">
      <button mat-raised-button class="button-secondary" style="margin-right: 10px; width: 10%"
        (click)="cd.reject()">
        No
      </button>
      <button mat-raised-button class="button-primary" style="width: 10%" (click)="cd.accept()">
        Yes
      </button>
    </div>
    <!--        <button type="button" pButton label="Yes" (click)="cd.accept()"></button>-->
  </ng-template>
</p-confirmDialog>
