import { Component } from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";

@Component({
  selector: 'app-createsr',
  templateUrl: './createsr.component.html',
  styleUrls: ['./createsr.component.scss']
})
export class CreatesrComponent {
  constructor(public modalService: ModalserviceService) {}
}
