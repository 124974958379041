import { Component } from '@angular/core';
import { ModalserviceService } from '../../services/modalservice.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { HashService } from '../../services/hash.service';
import { MessageboxComponent } from '../../modals/messagebox/messagebox.component';
import { ServiceService } from '../../services/service.service';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { LoginResult, SelfData } from '../../models/struct';
import { YourappsserviceService } from '../../services/yourappsservice.service';

@Component({
  selector: 'app-codedialog',
  templateUrl: './codedialog.component.html',
  styleUrls: ['./codedialog.component.scss'],
})
export class CodedialogComponent {
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  codeFormControl = new FormControl('Test Department', [Validators.required]);
  myForm: FormGroup = new FormGroup({});
  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private hashService: HashService,
    public messageBox: MessageboxComponent,
    private serviceservice: ServiceService,
    private yourappsService: YourappsserviceService,
    private _snackBar: MatSnackBar
  ) {}

  iLoginResult: LoginResult[] = [];
  submit() {
    if (
      SelfData.account[0]?.data?.[0]?.code == this.codeFormControl.value?.trim()
    ) {
      this.serviceservice
        .changepassword({
          ctrl: 1,
          accountid: SelfData.account[0].data[0].id,
          password: '',
          email: SelfData.account[0].data[0].email,
          code: parseInt(this.codeFormControl.value?.trim() || '0', 10),
        })
        .subscribe((loginAuth) => {
          this.modalService.codeclose();
          this.openSnackBar();
          this.serviceservice
            .reloaduserdate({
              id: SelfData.account[0].data[0].id,
            })
            .subscribe((loginAuth) => {
              this.iLoginResult[0] = loginAuth;
              SelfData.account = this.iLoginResult;
            });
        });
    } else {
      this.yourappsService.setOKOption(0);
      this.messageBox.modalService.openMessageBox(
        'Information',
        'Authorization code entered incorrectly',
        'Cancel',
        'OK'
      );
    }
  }

  openSnackBar() {
    // Get the email address from the account data.
    const email = SelfData.account[0].data[0].email;

    // Open the snack bar.
    this._snackBar.open(
      'Your account password has been successfully changed',
      'Done',
      {
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        duration: 10000,
      }
    );
  }
}
