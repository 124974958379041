<p-button class="info-btn" icon="pi pi-info" [rounded]="true" [outlined]="true" (click)="onInfoButtonClick()"/>
<button mat-icon-button type="button" class="close-btn" (click)="onButtonCloseClick()">
    <mat-icon>close</mat-icon>
</button>
<p class="sample-manager-text">Sent Look Books</p>

<!--
{
	"Code": 200,
	"ErrorMessage": "Accepted",
	"data": [
		{
			"id": 1,
			"email": "GioQveladze@yahoo.com",
			"phone": "551081090",
			"accountid": 1,
			"userid": 1,
			"lbmainid": 1,
			"isactivity": true,
			"operdate": "2023-11-10T13:59:00.000Z",
			"description": "Look Book 1",
			"user": "გივი ქველაძე"
		}
	]
}
-->

<div class="black-background-blt">
    <div class="card example-container-blt mat-elevation-z1">
        <p-table
            #dt1
                [value]="datasource"
                [paginator]="true"
                [rows]="10"
                [showCurrentPageReport]="true"
                [tableStyle]="{ 'width': '100%'}"
                [scrollable]="true"
                rowGroupMode="subheader"
                groupRowsBy="designer"
                [scrollHeight]="'calc(90vh - 200px)'"
                [styleClass]="'p-datatable-gridlines p-datatable-sm'"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [globalFilterFields]="['name', 'email', 'phone', 'description', 'user', 'operdate']"
                selectionMode="single"
                [rowsPerPageOptions]="[10, 25, 50, 100]">
            <ng-template pTemplate="caption">
                <div class="flex">
                    <span class="p-inputgroup-addon custom-hover" matRipple (click)="onButtonAddClick()">
                        <i class="pi pi-plus" style="color: #000000"></i></span>
                    <span class="p-input-icon-left ml-auto">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-search" style="color: #000000"></i>
                            </span>
                            <input pInputText type="text" (input)="onInput($event)" placeholder="Filter" [(ngModel)]="filtertext"/>
                            <span class="p-inputgroup-addon custom-hover-red" matRipple (click)="clear(dt1)">
                                <i class="pi pi-times" style="color: #000000"></i></span>
                        </div>
                    </span>

                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th style="width:1%"></th>
                    <th style="width:15%">name</th>
                    <th style="width:15%">Email</th>
                    <th style="width:15%">Phone</th>
                    <th style="width:20%">Description</th>
                    <th style="width:20%">Sender</th>
                    <th style="width:25%">Send Date</th>
                    <!--          <th style="width:10%">Designer</th>-->
                    <th style="width:1%"></th>
                </tr>
            </ng-template>
            <!--      <ng-template pTemplate="groupheader" let-catalog>-->
            <!--        <tr pRowGroupHeader>-->
            <!--          <td colspan="6">-->
            <!--            <span class="font-bold ml-2">{{catalog.designer}}</span>-->
            <!--          </td>-->
            <!--        </tr>-->
            <!--      </ng-template>-->
            <!--      <ng-template pTemplate="groupfooter" let-catalog>-->
            <!--        <tr>-->
            <!--          <td colspan="6" class="text-right font-bold pr-3">Total Catalogs: {{calculateCustomerTotal(catalog.designer)}}</td>-->
            <!--        </tr>-->
            <!--      </ng-template>-->
            <ng-template pTemplate="body" let-catalog >
                <tr [pSelectableRow]="catalog">
                    <div class="optionbuttons">
                        <td>
                            <p-button label="Select" size="small" [outlined]="true" severity="secondary"
                                    [matMenuTriggerFor]="menu" (click)="currancecatalog = catalog">
                            </p-button>
                        </td>
                    </div>
                    <td>{{catalog.name}}</td>
                    <td>{{catalog.email}}</td>
                    <td>{{catalog.phone}}</td>
                    <td>{{catalog.description}}</td>
                    <td>{{catalog.user}}</td>
                    <td>{{ formatDate(catalog.operdate.toString()) }}</td>
                    <!--          <td>{{catalog.seasoncode}}</td>-->
                    <!--          <td>{{catalog.seasonyear}}</td>-->
                    <!--          <td>{{catalog.designer}}</td>-->
                    <td>
                        <div class="optionbuttons">

                          <p-button *ngIf="catalog.accepted === 1" label="Selected" size="small" [text]="true" severity="success" (click)="onButtonSelectedClick(catalog)">
                            <span *ngIf="catalog.visibled" class="smart-badge smart-badge-success">New</span>
                          </p-button>

                          <p-button *ngIf="catalog.accepted === 0" label="Pending" size="small" [text]="true" severity="warning" (click)="onButtonPendingClick(catalog)">

                          </p-button>

                            <p-button label="Delete" size="small" [text]="true" severity="danger" (click)="onDeleteButtonClick(catalog)">

                            </p-button>
                        </div>

                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<mat-menu #menu="matMenu">
<!--    <button mat-menu-item>-->
<!--        <span>Send Look Book</span>-->
<!--    </button>-->
<!--    <button mat-menu-item>-->
<!--        <span>2</span>-->
<!--    </button>-->
<!--    <button mat-menu-item>-->
<!--        <span>3</span>-->
<!--    </button>-->
</mat-menu>

<mat-menu #addmenu="matMenu">
    <button mat-menu-item>
        <span>Main look Books</span>
    </button>
    <button mat-menu-item>
        <span>Custom Collections</span>
    </button>
</mat-menu>

<p-confirmDialog *ngIf="showdialog" #cd [style]="{width: '25vw'}" [closable]="true">
    <ng-template pTemplate="footer">
        <div class="button-container-rr">
            <button mat-raised-button class="button-secondary" style="margin-right: 10px; width: 10%"
                    (click)="cd.reject()">
                No
            </button>
            <button mat-raised-button class="button-primary" style="width: 10%" (click)="cd.accept()">
                Yes
            </button>
        </div>
    </ng-template>
</p-confirmDialog>

