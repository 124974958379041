import {AfterViewInit, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ModalserviceService} from "../../services/modalservice.service";
import {HashService} from "../../services/hash.service";
import {MessageboxComponent} from "../../modals/messagebox/messagebox.component";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {
  GetSystemUsers,
  GetSystemUsersData,
  SelfData,
  SendOut1Data,
  SetContactsList,
  SetContactsListData, SetSampleListData
} from "../../models/struct";
import {MatTableDataSource} from "@angular/material/table";
import moment from "moment/moment";

@Component({
  selector: 'app-addresslabel',
  templateUrl: './addresslabel.component.html',
  styleUrls: ['./addresslabel.component.scss']
})
export class AddresslabelComponent implements OnInit, AfterViewInit {

  myForm: FormGroup = new FormGroup({});
  iSystemUsersResult: GetSystemUsers[] = [];
  iContactsResult: SetContactsList[] = [];
  messages: { text: string; color: string }[] = [];
  messages_1: { text: string; color: string }[] = [];
  messages1: { text: string; color: string }[] = [];
  messages2: { text: string; color: string }[] = [];

  dataSource = new MatTableDataSource<SetSampleListData>(SelfData.setSelectedSample);

  displayedColumns: string[] = [
    'Designer',
    'Type',
    'Description',
    'Image',
  ];

  shipFormControl = new FormControl({ value: '', disabled: false }, []);

  shiptoControl = new FormControl({ value: '', disabled: true }, [
    Validators.required,
  ]);

  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
      ? SelfData.account[0].data[0].id
      : SelfData.account[0].data[0].parentid;
  }

  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private hashService: HashService,
    public messageBox: MessageboxComponent,
    private serviceservice: ServiceService,
    private yourappsService: YourappsserviceService,
    private _snackBar: MatSnackBar,
    private cd: ChangeDetectorRef
  ) {
    // console.log("SendOut1Data.senderFormControl", SendOut1Data.senderFormControl)
    this.serviceservice
      .setSystemUsers({ accountid: this.getAccountID() })
      .subscribe((value) => {
        this.iSystemUsersResult[0] = value;
        var recaddress: string | undefined;
        var recaddress_1: string | undefined;
        let result = this.searchUser(SendOut1Data.senderFormControl, this.iSystemUsersResult);
        recaddress = '<strong><h5>' + 'Ship From' + '</strong></h5>' + result?.company + '<br>' +
          result?.address1 + '<br>' + result?.address2 + '<br>' +
          result?.city + ' ' + result?.state + ' ' +result?.zipcode + '<br>' +
          result?.phone
        recaddress_1 = result?.company+ ' ' + result?.address1 + ' ' + result?.address2 + ' ' +
          result?.city + ' ' + result?.state + ' ' +result?.zipcode + ' ' + result?.phone
        this.messages_1.unshift({ text: recaddress_1, color: "black" })
        this.messages.unshift({ text: recaddress, color: "black" });
        // this.shipFormControl.setValue(recaddress!!)
      });

    this.serviceservice
      .setContactsList({ accountid: this.getAccountID(), contactsid: 0 })
      .subscribe((value) => {
        this.iContactsResult[0] = value;
        var recaddress: string | undefined;
        let result = this.searchItem(SendOut1Data.companynameControl, this.iContactsResult);
        recaddress = result?.['addressaddress1'] + '<br>' +
          result?.['addresscity']  + ' ' + result?.['addressstate'] + ' ' +
          result?.['addresszipcode'] + ' ' + result?.['addresscountry'] + ' <br>' +
          'Attn: ' + SendOut1Data.companynameControl + '<br>' +
          'Tel: ' + result?.['mobile'] + '<br>' +
          'Email: ' + result?.['email']
        this.messages1.unshift({ text: recaddress, color: "black" });
        // this.shipFormControl.setValue(recaddress!!)
      });

    var tempmessage: string;
    // console.log("SendOut1Data.reservationdateControl.value: ", SendOut1Data.datetimeCtrl.format('DD/MMM/YY HH:mm') )
    tempmessage = 'Type of Dispatch: ' + SendOut1Data.todControl?.trim() + '<br>' +
      'Sender: ' + SendOut1Data.senderFormControl + '<br>' + 'Samples: ' + SelfData.setSelectedSample.length + '<br>' +
      'Powered by Fashion Managment'
    this.messages2.unshift({ text: tempmessage!!, color: "black" });
  }

  formatDate(date: Date): string {
    const days = String(date.getDate()).padStart(2, '0');
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const monthName = months[date.getMonth()];
    const year = String(date.getFullYear()).slice(-2);
    const hour = String(date.getHours()).padStart(2, '0');
    const minute = String(date.getMinutes()).padStart(2, '0');


    return `${days}/${monthName}/${year} ${hour}:${minute}`;
  }

  searchUser(
    name: string | null,
    users: GetSystemUsers[]
  ): GetSystemUsersData | null {
    for (let user of users) {
      let found = user.data.find(
        (d) => d?.['firstname'] + ' ' + d?.['lastname'] === name
      );

      if (found) {
        return found;
      }
    }

    return null;
  }

  searchItem(
    name: string | null,
    contacts: SetContactsList[]
  ): SetContactsListData | null {
    for (let contact of contacts) {
      let found = contact.data.find(
        (d) => d?.['firstname'] + ' ' + d?.['lastname'] === name
      );

      if (found) {
        return found;
      }
    }

    return null;
  }

  public get setdataSource() {
    return this.dataSource;
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {

  }

  submit() {

  }



  onButtonPrintClick() {
    const el = document.getElementById('scrollable-div-dm');
    if (!el) {
      console.error('Element not found!');
      return;
    }

    const clonedEl = el.cloneNode(true) as HTMLElement;

    // Create and append the iframe
    const iframe = document.createElement('iframe');
    document.body.appendChild(iframe);

    // Dynamically load the printer CSS
    const printerStyleLink = document.createElement('link');
    printerStyleLink.rel = 'stylesheet';
    printerStyleLink.href = '/assets/addresslabel.component.css';
    iframe.contentWindow!.document.head.appendChild(printerStyleLink);

    // Append the element you want to print to the iframe
    iframe.contentWindow!.document.body.appendChild(clonedEl);

    // Print after a brief timeout to allow styles to load
    setTimeout(() => {
      iframe.contentWindow!.focus();
      iframe.contentWindow!.print();
      document.body.removeChild(iframe);
    }, 1000);
  }



  protected readonly SelfData = SelfData;
  protected readonly SendOut1Data = SendOut1Data;
}
