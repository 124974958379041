import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {Table} from "primeng/table";
import {SelfData, TagsListData} from "../../models/struct";
import {ModalserviceService} from "../../services/modalservice.service";
import {HashService} from "../../services/hash.service";
import {MessageboxComponent} from "../../modals/messagebox/messagebox.component";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {HttpClient} from "@angular/common/http";
import {forkJoin, Subscription} from "rxjs";

@Component({
  selector: 'app-salesreport',
  templateUrl: './salesreport.component.html',
  styleUrl: './salesreport.component.scss'
})
export class SalesreportComponent implements OnInit {
  metaKey: boolean = true;
  iSalesReportResult: TagsListData[] = []
  sizes!: any[];
  @ViewChild(Table) dataTable?: Table;
  selectedSize: any = ''
  date: Date | Date[] | null = null;
  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
      ? SelfData.account[0].data[0].id
      : SelfData.account[0].data[0].parentid;
  }

  constructor(public modalService: ModalserviceService,
              private hashService: HashService,
              public messageBox: MessageboxComponent,
              private serviceservice: ServiceService,
              private yourappsService: YourappsserviceService,
              private _snackBar: MatSnackBar,
              private http: HttpClient,
              private cdr: ChangeDetectorRef) {
    this.sizes = [
      {name: 'Small', class: 'p-datatable-sm'}
    ];
    this.selectedSize = this.sizes[0]
    setTimeout(() => {
      SelfData.spinnerpossition = false
    });
  }

  ngOnInit(): void {
  }

  onChange($event: any) {
    setTimeout(() => {
      SelfData.spinnerpossition = true
    });
    const startDate = new Date($event[0]);
    const endDate = new Date($event[1]);

    // Предположим, что ID аккаунта хранится где-то в вашем компоненте
    const accountId = this.getAccountID();
    forkJoin({
      salesreport: this.serviceservice.selectSalesReport(startDate, endDate, accountId),
    }).subscribe(({salesreport,}) => {
      this.iSalesReportResult = salesreport.data;
      console.log("this.iSalesReportResult", this.iSalesReportResult)
      setTimeout(() => {
        SelfData.spinnerpossition = false
      });
    });
  }

  calculateTotal() {
    let total = 0;

    if (this.iSalesReportResult) {
      for (let customer of this.iSalesReportResult) {
        total += customer['ordertotal'];
        // if (customer['ordertotal'] === name) {
        //   total++;
        // }
      }
    }

    return total;
  }

  calculateTotal1() {
    let total = 0;

    if (this.iSalesReportResult) {
      for (let customer of this.iSalesReportResult) {
        total += customer['totaldiscount'];
        // if (customer['ordertotal'] === name) {
        //   total++;
        // }
      }
    }

    return total;
  }

  calculateTotal2() {
    let total = 0;

    if (this.iSalesReportResult) {
      for (let customer of this.iSalesReportResult) {
        total += customer['salestaxamount'];
        // if (customer['ordertotal'] === name) {
        //   total++;
        // }
      }
    }

    return total;
  }

  calculateTotal3() {
    let total = 0;

    if (this.iSalesReportResult) {
      for (let customer of this.iSalesReportResult) {
        total += customer['profit'];
        // if (customer['ordertotal'] === name) {
        //   total++;
        // }
      }
    }

    return total;
  }

  private subscription?: Subscription

  onRowSelect(event: any) {
    console.log('Row selected:', event.data);
    this.SelfData.returnorder = false
    this.yourappsService.setSelectedOrderOutList(event, 2)
    this.yourappsService.getReloadOrderOutList([], 0)
    this.subscription = this.yourappsService.reloadOrderOutList$.subscribe((value) => {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      if (value.type === 1) {

      }
    })
    this.modalService.ssFrameOpen()
    // Ваша логика обработки выбора
  }

  onRowUnselect(event: any) {
    console.log('Row unselected:', event.data);
    // Ваша логика обработки отмены выбора
  }


  protected readonly SelfData = SelfData;
  selectedSalesReport: any = [];
}
