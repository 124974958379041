import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ModalserviceService } from '../../services/modalservice.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import {
  Countrylist,
  GetAddressList,
  Industrylist,
  Jobslist,
  Noelist,
  PrcAddress,
  RegisterResult,
  SelfData,
} from '../../models/struct';
import { ServiceService } from '../../services/service.service';
import { MessageboxComponent } from '../../modals/messagebox/messagebox.component';
import { LogindialogComponent } from '../logindialog/logindialog.component';
import { YourappsserviceService } from '../../services/yourappsservice.service';
import {MessageService} from "primeng/api";
import {NzNotificationService} from "ng-zorro-antd/notification";

@Component({
  selector: 'app-registrationdialog',
  templateUrl: './registrationdialog.component.html',
  styleUrls: ['./registrationdialog.component.scss'],
  providers: [MessageboxComponent, LogindialogComponent],
})
export class RegistrationdialogComponent implements OnInit {
  iAddressResult: GetAddressList[] = [];
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  // firstNameFormControl = new FormControl('', [Validators.required]);
  // jobControl = new FormControl('', Validators.required);
  // referalControl = new FormControl('', []);
  // industryControl = new FormControl('', Validators.required);
  // lastNameFormControl = new FormControl('', Validators.required);
  // companyFormControl = new FormControl('', Validators.required);
  // countryFormControl = new FormControl('', Validators.required);
  // noeFormControl = new FormControl('', Validators.required);
  // companyWebsiteFormControl = new FormControl('', Validators.required);
  // companyPhone = new FormControl('', Validators.pattern(/^\d+$/));
  // howdidFormControl = new FormControl('', Validators.required);
  statusfirstname: '' | 'error' | 'warning' = '';
  statuslastname: '' | 'error' | 'warning' = '';
  statusemail: '' | 'error' | 'warning' = '';
  statusjobtitle: '' | 'error' | 'warning' = '';
  statusindustry: '' | 'error' | 'warning' = '';
  statusaddress: '' | 'error' | 'warning' = '';
  statuscompany: '' | 'error' | 'warning' = '';
  statuscompanywebsite: '' | 'error' | 'warning' = '';
  statusnoe: '' | 'error' | 'warning' = '';
  statusphone: '' | 'error' | 'warning' = '';
  statusaboutus: '' | 'error' | 'warning' = '';

  firstname: string = "";
  lastname: string = "";
  email: string = "";
  jobtitle: string = "";
  industry: string = "";
  address: string = "";
  company: string = "";
  companywebsite: string = "";
  noe: string = "";
  phone: string = "";
  aboutus: string = "";
  referal: string = "";
  toppings = this.fb.group({
    check1: true,
    check2: false,
    check3: false,
    check4: false,
    check5: false,
    check1_1: { value: true, disabled: true },
    check1_2: { value: true, disabled: true },
    check1_3: false,
    check1_4: false,
  });

  clicking = this.fb.group({
    check2_1: false,
    check2_2: false,
  });

  iJobList: Jobslist[] = [];
  iIndustry: Industrylist[] = [];
  iCountry: Countrylist[] = [];
  iNoe: Noelist[] = [];
  iAboutUS: Noelist[] = [];

  iRegisterResult: RegisterResult[] = [];

  myForm: FormGroup = new FormGroup({});
  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    public serviceservice: ServiceService,
    public messageBox: MessageboxComponent,
    private loginform: LogindialogComponent,
    private yourappsService: YourappsserviceService,
    private notification: NzNotificationService,
    private messageService: MessageService
  ) {}
  ngOnInit(): void {
    this.yourappsService.address$.subscribe((address) => {
      if (address && address[0] && address[0].data && address[0].data[0]) {
        this.iAddressResult = address;
        this.address = this.iAddressResult[0].data[0].description;
      } else {
        // console.error('Received unexpected data format:', address);
      }
    });
    this.serviceservice.getAll().subscribe((iJobList) => {
      this.iJobList = iJobList;
    });
    this.serviceservice.getAllIndustry().subscribe((iIndustryList) => {
      this.iIndustry = iIndustryList;
    });
    this.serviceservice.getAllCountry().subscribe((iCountryList) => {
      this.iCountry = iCountryList;
    });
    this.serviceservice.getAllNoe().subscribe((iNoeList) => {
      this.iNoe = iNoeList;
    });
    this.serviceservice.getAllAboutus().subscribe((iAboutUSList) => {
      this.iAboutUS = iAboutUSList;
    });
    this.myForm = this.fb.group({
      // Your form controls go here
      toppings: this.fb.group({
        check1: [true],
        check2: [false],
        // ...
      }),
      clicking: this.fb.group({
        check2_1: [true],
        check2_2: [false],
        // ...
      }),
    });
  }

  submit() {
    if (this.firstname.trim() === "") {
      this.statusfirstname = 'error';
      this.notification.error(
        '<strong class="notification-error">Information</strong>',
        '<strong class="notification-error">You must specify the First Name</strong>'
      );
      return;
    }

    if (this.lastname.trim() === "") {
      this.statuslastname = 'error';
      this.notification.error(
        '<strong class="notification-error">Information</strong>',
        '<strong class="notification-error">You must specify the Last Name</strong>'
      );
      return;
    }

    if (this.email.trim() === "") {
      this.statusemail = 'error';
      this.notification.error(
        '<strong class="notification-error">Information</strong>',
        '<strong class="notification-error">You must specify the Email</strong>'
      );
      return;
    }

    if (this.jobtitle.trim() === "") {
      this.statusjobtitle = 'error';
      this.notification.error(
        '<strong class="notification-error">Information</strong>',
        '<strong class="notification-error">You must specify the Job Title</strong>'
      );
      return;
    }

    if (this.industry.trim() === "") {
      this.statusindustry = 'error';
      this.notification.error(
        '<strong class="notification-error">Information</strong>',
        '<strong class="notification-error">You must specify the Industry</strong>'
      );
      return;
    }

    if (this.address.trim() === "") {
      this.statusaddress = 'error';
      this.notification.error(
        '<strong class="notification-error">Information</strong>',
        '<strong class="notification-error">You must specify the Address</strong>'
      );
      return;
    }

    if (this.company.trim() === "") {
      this.statuscompany = 'error';
      this.notification.error(
        '<strong class="notification-error">Information</strong>',
        '<strong class="notification-error">You must specify the Company</strong>'
      );
      return;
    }

    if (this.companywebsite.trim() === "") {
      this.statuscompanywebsite = 'error';
      this.notification.error(
        '<strong class="notification-error">Information</strong>',
        '<strong class="notification-error">You must specify the Company Website</strong>'
      );
      return;
    }

    if (this.noe.trim() === "") {
      this.statusnoe = 'error';
      this.notification.error(
        '<strong class="notification-error">Information</strong>',
        '<strong class="notification-error">You must specify the Number of Employees</strong>'
      );
      return;
    }

    if (this.phone.trim() === "") {
      this.statusphone = 'error';
      this.notification.error(
        '<strong class="notification-error">Information</strong>',
        '<strong class="notification-error">You must specify the Phone Number</strong>'
      );
      return;
    }

    if (this.aboutus.trim() === "") {
      this.statusaboutus = 'error';
      this.notification.error(
        '<strong class="notification-error">Information</strong>',
        '<strong class="notification-error">You must specify the About Us section</strong>'
      );
      return;
    }

    this.yourappsService.setOKOption(0);
    if (
      this.firstname.trim() === ''
    ) {
      this.messageBox.modalService.openMessageBox(
        'Information',
        'You must specify the First Name',
        'Cancel',
        'OK'
      );
    } else if (
      this.lastname.trim() === ''
    ) {
      this.messageBox.modalService.openMessageBox(
        'Information',
        'You must specify the Last Name',
        'Cancel',
        'OK'
      );
    } else if (this.email.trim() === '') {
      this.messageBox.modalService.openMessageBox(
        'Information',
        'Please enter a valid email address',
        'Cancel',
        'OK'
      );
    } else if (!this.jobtitle || this.jobtitle.trim() === '') {
      this.messageBox.modalService.openMessageBox(
        'Information',
        'You must definitely choose a Job Title',
        'Cancel',
        'OK'
      );
    } else if (
      this.industry.trim() === ''
    ) {
      this.messageBox.modalService.openMessageBox(
        'Information',
        'You must definitely choose a Industry',
        'Cancel',
        'OK'
      );
    } else if (
      this.address.trim() === ''
    ) {
      this.messageBox.modalService.openMessageBox(
        'Information',
        'You must definitely choose a Address',
        'Cancel',
        'OK'
      );
    } else if (
      this.company .trim() === ''
    ) {
      this.messageBox.modalService.openMessageBox(
        'Information',
        'You must specify the Company',
        'Cancel',
        'OK'
      );
    } else if (
      this.companywebsite.trim() === ''
    ) {
      this.messageBox.modalService.openMessageBox(
        'Information',
        'You must specify the Company WebSite',
        'Cancel',
        'OK'
      );
    } else if (
      this.noe.trim() === ''
    ) {
      this.messageBox.modalService.openMessageBox(
        'Information',
        'You must definitely choose a Number of Employees',
        'Cancel',
        'OK'
      );
    } else if (
      this.phone.trim() === ''
    ) {
      this.messageBox.modalService.openMessageBox(
        'Information',
        'Must contain only numbers',
        'Cancel',
        'OK'
      );
    } else if (
      this.aboutus.trim() === ''
    ) {
      this.messageBox.modalService.openMessageBox(
        'Information',
        'You must indicate how you heard about us',
        'Cancel',
        'OK'
      );
    } else if (
      this.toppings.get('check1')?.value == false &&
      this.toppings.get('check2')?.value == false &&
      this.toppings.get('check1_1')?.value == false &&
      this.toppings.get('check1_2')?.value == false
    ) {
      this.messageBox.modalService.openMessageBox(
        'Information',
        'You must select at least one program',
        'Cancel',
        'OK'
      );
    } else if (!this.clicking.value.check2_2) {
      this.messageBox.modalService.openMessageBox(
        'Information',
        'You must agree to the privacy policy',
        'Cancel',
        'OK'
      );
    } else {
      this.serviceservice
        .create({
          Ctrl: 0,
          FirstName: this.firstname?.trim(),
          LastName: this.lastname.trim(),
          SetPasswprd: '',
          SetEmail: this.email.trim(),
          JobTitle: this.jobtitle.trim(),
          Industry: this.industry.trim(),
          Country: this.address.trim(),
          Company: this.company.trim(),
          Website: this.companywebsite.trim(),
          Noe: this.noe.trim(),
          Phone: this.phone?.trim(),
          AboutUS: this.aboutus.trim(),
          RPMonitoring: this.toppings.value.check1 as boolean,
          InternalTracking: false as boolean,
          ChinaKOLAnalytics: false as boolean,
          Events: this.toppings.value.check2 as boolean,
          Samples: this.toppings.value.check1_1 as boolean,
          DigitalShowroom: false as boolean,
          BrandBenchmark: false as boolean,
          ProductionServices: false as boolean,
          Contacts: this.toppings.value.check1_2 as boolean,
          IWant: this.clicking.value.check2_1 as boolean,
          Type: 1,
          Activity: true,
          parentid: 0,
          addressid: this.iAddressResult[0].data[0].id || 0,
          referalcode: this.referal?.trim() || ""
        })
        .subscribe((RegisterResult) => {
          this.iRegisterResult[0] = RegisterResult;
          console.log(
            this.iRegisterResult[0].Code,
            this.iAddressResult[0].data[0].id
          );
          if (this.iRegisterResult[0].Code > 0) {
            this.serviceservice
              .prcaddress({
                ctrl: 2,
                id: this.iAddressResult[0].data[0].id,
                careof: '',
                country: '',
                address1: '',
                email: '',
                otheremail: '',
                address2: '',
                phone: '',
                generalphone: '',
                mobile1: '',
                mobile2: '',
                city: '',
                state: '',
                zipcode: '',
                isactivity: true,
                contactsid: 0,
                accountid: this.iRegisterResult[0].Code,
                description: '',
              })
              .subscribe((value: PrcAddress) => {
                this.serviceservice
                  .setNewAddressList({ id: value.data })
                  .subscribe((value) => {
                    this.iAddressResult[0] = value;
                    this.yourappsService.setAddress(this.iAddressResult);
                    // SelfData.addres = this.iAddressResult;
                    console.log(value);
                  });
              });
          }
          this.yourappsService.setOKOption(0);
          if (this.iRegisterResult[0].Code == 0) {
            this.messageService.add({
              key: 'tc',
              severity: 'warn',
              summary: 'Information',
              detail: this.iRegisterResult[0].data,
              life: 86400000,
            });
            // this.messageBox.modalService.openMessageBox(
            //   'Information',
            //   this.iRegisterResult[0].data,
            //   'Cancel',
            //   'OK'
            // );
          } else {
            this.messageService.add({
              key: 'tc',
              severity: 'success',
              summary: 'Success',
              detail: this.iRegisterResult[0].data,
              life: 86400000,
            });
            // this.messageBox.modalService.openMessageBox(
            //   'Information',
            //   this.iRegisterResult[0].data,
            //   'Cancel',
            //   'OK'
            // );
            SelfData.email = this.email.trim();
            this.loginform.changeemail(SelfData.email);
            this.modalService.registrationclose();
          }
          // console.log(this.iRegisterResult[1])

          // this.modalService.close()
        });
      // this.modalService.close()
    }
  }

  showAddEditFrame(
    showType: number,
    element: any,
    id: number = 0,
    description: string = ''
  ) {
    if (
      this.iAddressResult &&
      this.iAddressResult[0] &&
      this.iAddressResult[0].data &&
      this.iAddressResult[0].data[0]
    ) {
      this.yourappsService.setAddress(this.iAddressResult);
    }
    this.modalService.createaddressopen();
  }
  onBlurAll() {
    this.statusfirstname = '';
    this.statuslastname = '';
    this.statusemail = '';
    this.statusjobtitle = '';
    this.statusindustry = '';
    this.statusaddress = '';
    this.statuscompany = '';
    this.statuscompanywebsite = '';
    this.statusnoe = '';
    this.statusphone = '';
    this.statusaboutus = '';
  }


  onButtonAddressClick() {}

  showOptions(event: MatCheckboxChange): void {
    console.log(this.toppings.get('check1')?.value);
    if (event.source.id == 'check1') {
      console.log('პირველი ჩეკბოქსის სტატუსის შეცვლა');
    } else {
      console.log('სხვა ჩეკბოქსის სტატუსის შეცვლა');
    }
  }
}
