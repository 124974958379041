import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { ModalserviceService } from '../../services/modalservice.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { HashService } from '../../services/hash.service';
import { MessageboxComponent } from '../../modals/messagebox/messagebox.component';
import { ServiceService } from '../../services/service.service';
import { YourappsserviceService } from '../../services/yourappsservice.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxImageCompressService } from 'ngx-image-compress';
import { HttpClient } from '@angular/common/http';
import {
  GetAddressList, GetCompanyList,
  GetCompTypeList,
  GetCompTypeListData,
  GetPronounList,
  GetSWSListData,
  PrcAddress,
  PrcCompany,
  SelfData,
} from '../../models/struct';
import { map, Observable, of, startWith } from 'rxjs';
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import {NzNotificationService} from "ng-zorro-antd/notification";

@Component({
  selector: 'app-createcompanydialog',
  templateUrl: './createcompanydialog.component.html',
  styleUrls: ['./createcompanydialog.component.scss'],
})
export class CreatecompanydialogComponent implements OnInit, AfterViewInit {
  myForm: FormGroup;
  isVisible: boolean = true;
  createbuttoncaption = 'Create';
  filteredCountries: Observable<any[]> = of([]);
  filteredStates: Observable<any[]> = of([]);
  states: any[] = [];
  countries: any[] = [];
  departmentFormControl = new FormControl({ value: '', disabled: true }, [
    Validators.required,
  ]);
  companynameFormControl = new FormControl({ value: '', disabled: false }, [
    Validators.required,
  ]);
  phoneControl = new FormControl<string>('');
  websiteControl = new FormControl<string>('');
  publicationCompanyControl = new FormControl<string>('');
  emailControl = new FormControl<string>('');
  typeControl = new FormControl<string>('');
  eInvoicingCodeControl = new FormControl<string>('');
  markerControl = new FormControl<string>('');
  taxidContorl = new FormControl<string>('');
  manufactureCodeContorl = new FormControl<string>('');
  notesContorl = new FormControl<string>('');
  address1Control = new FormControl<string>('');
  address2Control = new FormControl<string>('');
  stateControl = new FormControl<string>('');
  zipcodeControl = new FormControl<string>('');
  cityControl = new FormControl<string>('');
  countriControl = new FormControl<string>('');
  InstagraControl = new FormControl<string>('');
  FacebookControl = new FormControl<string>('');
  TiktokControl = new FormControl<string>('');
  YoutubeControl = new FormControl<string>('');
  TwitterControl = new FormControl<string>('');
  LinkedlnControl = new FormControl<string>('');
  OtherControl = new FormControl<string>('');

  careoff1Contro2 = new FormControl<string>('');
  countri2Control = new FormControl<string>('');
  address12Control = new FormControl<string>('');
  otherEmail2Control = new FormControl<string>('');
  address22Control = new FormControl<string>('');
  directPhone2Control = new FormControl<string>('');
  city2Control = new FormControl<string>('');
  generalPhoneControl = new FormControl<string>('');
  state2Control = new FormControl<string>('');
  mobile1Control = new FormControl<string>('');
  zipcode2Control = new FormControl<string>('');
  mobile2Control = new FormControl<string>('');

  filteredCompTypeOptions?: Observable<string[]>;

  comptypeoptions: string[] = [];

  iCompTypeResult: GetCompTypeList[] = [];
  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private hashService: HashService,
    public messageBox: MessageboxComponent,
    private serviceservice: ServiceService,
    private yourappsService: YourappsserviceService,
    private _snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef,
    private imageCompressService: NgxImageCompressService,
    private notification: NzNotificationService,
    private http: HttpClient
  ) {
    this.myForm = this.fb.group({});
    this.departmentFormControl.setValue(SelfData.account[0].data[0].company);
    this.departmentstring = SelfData.account[0].data[0].company
    var accountid: number = 0;
    if (SelfData.account[0].data[0].parentid == 0) {
      accountid = SelfData.account[0].data[0].id;
    } else {
      accountid = SelfData.account[0].data[0].parentid;
    }

    this.serviceservice
      .setCompTypeList({ accountid: accountid })
      .subscribe((value) => {
        this.iCompTypeResult[0] = value;
        SelfData.comptype = this.iCompTypeResult;
        this.filteredCompTypeOptions = this.setDataAndFilter(
          this.typeControl,
          value,
          this.comptypeoptions,
          0
        );
      });
  }

  private setDataAndFilter(
    control: FormControl,
    response: any,
    options: string[],
    type: number
  ) {
    let results = response.data;
    for (let data of results) {
      options.push(data.description);
    }
    return control.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || '', type))
    );
  }

  private _filter(value: string, type: number): string[] {
    const filterValue = value.toLowerCase();

    const options = [this.comptypeoptions];

    if (type >= 0 && type < options.length) {
      return options[type].filter((option) =>
        option.toLowerCase().includes(filterValue)
      );
    } else {
      console.warn(`Unexpected filter type: ${type}`);
      return [];
    }
  }

  add(
    event: MouseEvent,
    type: number,
    autoInput: MatAutocompleteTrigger | null
  ): void {
    event.stopPropagation(); // This will stop the event from bubbling up
    SelfData.modaltype = type;
    if (autoInput != null) {
      autoInput.closePanel();
    }
    // Determine the caption based on the provided type
    switch (type) {
      case 0:
        SelfData.modalformcaption = 'Type';
        SelfData.frame = 0;
        SelfData.modaltype = 22;
        break;
      default:
        console.log(`Unsupported type: ${type}`);
        return;
    }

    // Invoke the modal
    this.modalService.createSWS();

    // your logic for when the add button is clicked
  }
  ngAfterViewInit(): void {}

  ngOnInit(): void {
    this.setupValueChanges();
    this.reloadOptionsOnSignal();

    this.http.get<any>('assets/countries.json').subscribe((data) => {
      this.countries = data.countries;
      this.countriControl.setValue('United States');
      this.countrystring = 'United States';

      this.http.get<any>('assets/us-states.json').subscribe((data) => {
        this.states = data;
        this.onOptionSelected(this.countrystring)
        this.filteredStates = this.stateControl.valueChanges.pipe(
          startWith(''),
          map((value) => this._filterStates(value || ''))
        );
      });

      this.filteredCountries = this.countriControl.valueChanges.pipe(
        startWith(''),
        map((value) => this._filterCountries(value || ''))
      );
    });

  }

  private _filterCountries(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.countries.filter((country) =>
      country.country.toLowerCase().includes(filterValue)
    );
  }

  setupValueChanges(): void {
    this.yourappsService.selectedValue$.subscribe((description) => {
      switch (SelfData.modaltype) {
        case 22:
          this.typeControl.setValue(description);
          break;
      }
    });
  }

  determineAccountId(): number {
    if (SelfData.account[0].data[0].parentid == 0) {
      return SelfData.account[0].data[0].id;
    } else {
      return SelfData.account[0].data[0].parentid;
    }
  }

  resetResults(): void {
    this.iCompTypeResult = [];
  }
  reloadOptionsOnSignal(): void {
    this.yourappsService.reloadArrayList.subscribe((description) => {
      let accountid: number = this.determineAccountId();
      this.resetResults();

      switch (SelfData.modaltype) {
        case 22:
          this.reloadCompTypeList(accountid);
          break;
      }
    });
  }

  reloadCompTypeList(accountid: number): void {
    this.serviceservice
      .setCompTypeList({ accountid: accountid })
      .subscribe((value) => {
        this.iCompTypeResult.push(value);
        SelfData.comptype = this.iCompTypeResult;
        console.log(this.iCompTypeResult);
        this.comptypeoptions = this.iCompTypeResult[0].data.map(
          (item) => item.description
        );
        this.filteredCompTypeOptions = this.typeControl.valueChanges.pipe(
          startWith(''),
          map((value) => this._filter(value || '', 0))
        );
      });
  }

  getCountryIdByName(countryName: string): number | null {
    const country = this.countries.find((c) => c.country === countryName);
    return country ? country.id : null;
  }

  private _filterStates(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.states.filter((state) =>
      state.state.toLowerCase().includes(filterValue)
    );
  }
  onOptionSelected(event: string): void {
    const countryId = this.getCountryIdByName(event);
    this.states = [];
    this.stateControl.reset();
    if (countryId == 187) {
      this.http.get<any>('assets/us-states.json').subscribe((data) => {
        this.states = data;
        this.filteredStates = this.stateControl.valueChanges.pipe(
          startWith(''),
          map((value) => this._filterStates(value || ''))
        );
      });
    }
    console.log(this.states);
    // You can perform further actions using the selected country
  }

  findIdByDescriptionInType(
    swsList: GetCompTypeListData[],
    targetDescription: string
  ): number {
    const found = swsList.find(
      (element) => element.description === targetDescription
    );

    return found ? found.id : 0;
  }

  iCompanyResult: GetCompanyList[] = [];
  statuscompanyname: '' | 'error' | 'warning' = '';

  departmentstring: string = "";
  phonestring: string = "";
  companyNamestring: string = "";
  websitestring: string = "";
  publicationcompanystring: string = "";
  emailrstring: string = "";
  typestring: string = "";
  eiCodestring: string = "";
  markerstring: string = "";
  taxidstring: string = "";
  manufacturecodestring: string = "";
  notesstring: string = "";
  careofstring: string = "";
  countrystring: string = "";
  address1string: string = "";
  otherEmail2string: string = "";
  address2string: string = "";
  phone2string: string = "";
  citystring: string = "";
  generalphonestring: string = "";
  statesstring: string = "";
  mobile1string: string = "";
  mobile2string: string = "";
  zipcodestring: string = "";
  instagramstring: string = "";
  facebookstring: string = "";
  youtubestring: string = "";
  tiktokstring: string = "";
  twitterstring: string = "";
  linkedinstring: string = "";
  othernstring: string = "";

  submit(type: number = 0) {
    if (this.companyNamestring?.trim() == '') {
      this.notification.error(
        '<strong class="notification-error">Information</strong>',
        '<strong class="notification-error">Company Name is required<</strong>'
      );
      return
    } else {
      var accountid: number = 0;
      if (SelfData.account[0].data[0].parentid == 0) {
        accountid = SelfData.account[0].data[0].id;
      } else {
        accountid = SelfData.account[0].data[0].parentid;
      }
      this.serviceservice
        .prcaddress({
          ctrl: 0,
          id: 0,
          careof: this.careofstring || '',
          country: this.countrystring || '',
          address1: this.address1string || '',
          email: this.otherEmail2string || '',
          otheremail: '',
          address2: this.address2string || '',
          phone: this.phone2string || '',
          generalphone: this.generalphonestring || '',
          mobile1: this.mobile1string || '',
          mobile2: this.mobile2string || '',
          city: this.citystring || '',
          state: this.statesstring || '',
          zipcode: this.zipcodestring || '',
          isactivity: true,
          contactsid: 0,
          accountid: accountid,
          description: 'Company Address',
        })
        .subscribe((value: PrcAddress) => {
          this.serviceservice
            .prccompany({
              ctrl: 0,
              id: 0,
              companyname: this.companyNamestring || '',
              phone: this.phonestring || '',
              publicationcompany: this.publicationcompanystring || '',
              website: this.websitestring || '',
              email: this.emailrstring || '',
              typeid: this.findIdByDescriptionInType(
                SelfData.comptype.flatMap((value) => value.data),
                this.typestring || ''
              ),
              einvoicingcode: this.eiCodestring || '',
              marker: this.markerstring || '',
              taxid: this.taxidstring || '',
              manufacturecode: this.manufacturecodestring || '',
              notes: this.notesstring || '',
              instagram: this.instagramstring || '',
              facebook: this.facebookstring || '',
              tiktok: this.tiktokstring || '',
              youtube: this.youtubestring || '',
              twitter: this.twitterstring || '',
              linkedin: this.linkedinstring || '',
              accountid: accountid,
              userid: SelfData.account[0].data[0].id,
              isactivity: true,
              addressid: value.data,
            })
            .subscribe((value: PrcCompany) => {
              console.log("value", value)
              this.yourappsService.setCompany(value.data)
            });
        });
      this.modalService.createcompanyclose();
    }
  }
}
