<button mat-icon-button type="button" class="close-btn" (click)="onButtonCloseClick()">
    <mat-icon>close</mat-icon>
</button>
<p class="sample-manager-text">{{formcaption}}</p>

<div class="row">
    <div class="forma-group">
        <mat-form-field class="selector-full-width" appearance="fill">
            <mat-label>Designer</mat-label>
            <input type="text" matInput placeholder="Select or type..." [matAutocomplete]="auto2"
                    [formControl]="desingerFormControl" #autoInput2="matAutocompleteTrigger"/>
            <mat-autocomplete #auto2="matAutocomplete" (optionSelected)="onDesignerSelected($event)">
                <mat-option *ngFor="let item of filteredDesignerOptions | async" [value]="item">
                    {{ item }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>

    <div class="forma-group">
        <mat-form-field class="selector-full-width" appearance="fill">
            <mat-label>Select from accepted Look Book</mat-label>
            <input type="text" matInput placeholder="Select or type..." [matAutocomplete]="auto3"
                    [formControl]="acceptFormControl" #autoInput3="matAutocompleteTrigger"
                    (input)="onInputChange()"/>
            <mat-autocomplete #auto3="matAutocomplete" (optionSelected)="onAcceptedSelected($event)">
                <mat-option *ngFor="let item of filteredAcceptedOptions | async" [value]="item">
                    {{ item }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>
</div>

<div class="imagelist">
    <div class="file-container-images">
        <mat-card *ngFor="let item of selectedImageFiles; let i = index" class="card-item shadow-4">
            <button mat-mini-fab type="button" class="close-button" (click)="SelectImageFile($event, i)">
                Select
            </button>
            <img [src]="transformImageUrl(item.url)" (click)="showFullWindowImage(transformImageUrl(item.url))" class="thumbnail" alt="Image thumbnail">
        </mat-card>

        <!-- Single preview modal for full window image preview -->
        <div *ngIf="previewVisible" class="overlay" (click)="hideFullWindowImage()">
            <img [src]="previewUrl" class="overlay-content" alt="Full image preview"
                    (mousedown)="startDragging($event)" (wheel)="zoomImage($event)">
        </div>
    </div>
</div>

<p-confirmDialog *ngIf="showdialog" #cd [style]="{width: '25vw'}" [closable]="true">
    <!--  <ng-template pTemplate="header">-->
    <!--    <h3 class="red-text">This item is on loan!</h3>-->
    <!--  </ng-template>-->
    <ng-template pTemplate="footer">
        <!--        <button type="button" pButton label="No" (click)="cd.reject()"></button>-->
        <div class="button-container-request">
            <button mat-raised-button class="button-secondary" style="margin-right: 10px; width: 50%"
                    (click)="cd.reject()">
                No
            </button>
            <button mat-raised-button class="button-primary" style="margin-right: 0; width: 50%" (click)="cd.accept()">
                Yes
            </button>
        </div>
        <!--        <button type="button" pButton label="Yes" (click)="cd.accept()"></button>-->
    </ng-template>
</p-confirmDialog>
