import { Component } from '@angular/core';

@Component({
  selector: 'app-taxesdialog',
  templateUrl: './taxesdialog.component.html',
  styleUrl: './taxesdialog.component.scss'
})
export class TaxesdialogComponent {

}
