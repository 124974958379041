import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  NgZone,
  ViewChild,
  Input,
  OnInit,
} from '@angular/core';
import {
  GetCollectionList, GetCompanyList,
  GetDesignerList, GetFabricList,
  GetLBMainList, GetManuList, GetMiscList,
  GetOriginalImages, GetRegionList, GetSeasonList, GetSizeRangeList, GetStyleList, GetSWSList, GettodList, GetTypeList,
  PrcContacts,
  SelfData, setgridfields, SetSampleList,
  SetSampleListData, SetsorList,
  Speener,
  transformImageUrl
} from '../../models/struct';
import { ModalserviceService } from '../../services/modalservice.service';
import { FormBuilder } from '@angular/forms';
import { HashService } from '../../services/hash.service';
import { MessageboxComponent } from '../../modals/messagebox/messagebox.component';
import { ServiceService } from '../../services/service.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { YourappsserviceService } from '../../services/yourappsservice.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import {Table} from "primeng/table";
import {Paginator} from "primeng/paginator";
import {ConfirmationService, ConfirmEventType} from "primeng/api";
import {OverlayPanel} from "primeng/overlaypanel";
import {QzTrayService} from "../../services/qz-tray.service";

interface ColorArray {
  colorname: string;
  colorcode: string;
}

interface Gender {
  value: string;
}

@Component({
  selector: 'app-samplelisttable',
  templateUrl: './samplelisttable.component.html',
  styleUrls: ['./samplelisttable.component.scss'],
})
export class SamplelisttableComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator | null = null;
  @ViewChild('dt1') dt1!: Table;
  @ViewChild('paginator') paginator1!: Paginator;
  filtertext: string = ""
  first: number = 0;

  showQRCode: boolean = false;
  selectedElement: any;
  ELEMENT_DATA: SetSampleListData[] = [];
  qrcodedata: string = '';
  margin = 4;
  scale = 1;
  title = 'A custom title attribute';
  width = 300;

  sizes!: any[];

  selectedSize: any = '';

  options: string[] = [];
  colorarray: ColorArray[] = [
    {colorname: 'White', colorcode: '#ffffff'},
    {colorname: 'Black ', colorcode: '#000000'},
    {colorname: 'Yellow', colorcode: '#FFFF00'},
    {colorname: 'Purple', colorcode: '#800080'},
    {colorname: 'Orange ', colorcode: '#FFA500'},
    {colorname: 'Blue ', colorcode: '#2196f3'},
    {colorname: 'Light Blue ', colorcode: '#03a9f4'},
    {colorname: 'Red', colorcode: '#ff0000'},
    {colorname: 'Buff', colorcode: '#DAA06D'},
    // {colorname: 'Gray ', colorcode: '#808080'},
    {colorname: 'Grey ', colorcode: '#808080'},
    {colorname: 'Green ', colorcode: '#00FF00'},
    {colorname: 'Pink', colorcode: '#FFC0CB'},
    {colorname: 'Violet', colorcode: '#8F00FF'},
    {colorname: 'Brown', colorcode: '#964B00'},
    {colorname: 'Silver', colorcode: '#C0C0C0'},
    {colorname: 'Cream', colorcode: '#FFFDD0'},
    {colorname: 'Gold', colorcode: '#FFD700'},
    {colorname: 'Beige', colorcode: '#F5F5DC'},
    {colorname: 'Navy', colorcode: '#202A44'},
    {colorname: 'Mint', colorcode: '#A8E8D7'},
  ];
  displayedColumns: string[] = [
    'sc',
    'checkbox',
    'actions',
    'Designer',
    'BarCode',
    'Collection',
    'Season',
    'Type',
    'Color',
    'Description',
    'Image',
  ];
  dataSource = new MatTableDataSource<SetSampleListData>(this.ELEMENT_DATA);
  @ViewChild('qrCode') qrCode!: ElementRef;
  iFieldList: setgridfields[] = [];
  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    public messageBox: MessageboxComponent,
    private cdr: ChangeDetectorRef,
    private serviceservice: ServiceService,
    private yourappsService: YourappsserviceService,
    private confirmationService: ConfirmationService,
    private cdRef: ChangeDetectorRef,
    private qzTrayService: QzTrayService
  ) {
    this.serviceservice
      .getGridFields({
        formname: "createsamplesdialog",
        accountid: this.getAccountID(),
        userid: SelfData.account[0].data[0].id
      })
      .subscribe((value) => {
        this.iFieldList[0] = value;
        SelfData.gridgields = this.iFieldList;
        this.processFieldList();
      });
    this.iSendOutReportResult = SelfData.sampledata
    this.sizes = [
      { name: 'Small', class: 'p-datatable-sm' }
    ];
    this.selectedSize = this.sizes[0]

    this.ELEMENT_DATA = SelfData.sampledata[0].data;
    this.dataSource = new MatTableDataSource<SetSampleListData>(
      this.ELEMENT_DATA
    );
    console.log("SelfData.sampledata[0]?.data", SelfData.sampledata[0]?.data)

    this.serviceservice
      .setTODList({accountid: this.getAccountID()})
      .subscribe((desingervalue) => {
        this.iTodResult[0] = desingervalue;
        SelfData.tod = this.iTodResult;
      });

    this.serviceservice
      .setDesignerList({accountid: this.getAccountID()})
      .subscribe((desingervalue) => {
        this.iDesignerResult[0] = desingervalue;
        SelfData.designer = this.iDesignerResult;
      });

    this.serviceservice
      .setCollectionList({accountid: this.getAccountID()})
      .subscribe((collectionvalue) => {
        this.iCollection[0] = collectionvalue;
        SelfData.collection = this.iCollection;
      });

    this.serviceservice
      .setSeasonList({accountid: this.getAccountID()})
      .subscribe((seasonvalue) => {
        this.iSeason[0] = seasonvalue;
        SelfData.season = this.iSeason;
      });

    this.serviceservice
      .setStyleList({accountid: this.getAccountID()})
      .subscribe((value) => {
        this.iStyle[0] = value;
        SelfData.style = this.iStyle;
      });

    this.serviceservice
      .setContactList({accountid: this.getAccountID()})
      .subscribe((value) => {
        this.iContact[0] = value;
        SelfData.contact = this.iContact;
      });

    this.serviceservice
      .setClientList({accountid: this.getAccountID()})
      .subscribe((value) => {
        this.iClient[0] = value;
        SelfData.contact = this.iContact;
      });

    this.serviceservice
      .setPublicationList({accountid: this.getAccountID()})
      .subscribe((value) => {
        this.ipublication[0] = value;
        SelfData.contact = this.iContact;
      });

    this.serviceservice
      .setestyLis({accountid: this.getAccountID()})
      .subscribe((value) => {
        this.iEStyllist[0] = value;
        SelfData.estylist = this.iEStyllist;
      });

    this.serviceservice
      .setsendouttype({accountid: this.getAccountID()})
      .subscribe((value) => {
        this.iSendOutType[0] = value;
        SelfData.sendouttype = this.iSendOutType;
      });

    this.serviceservice
      .setTypeList({accountid: this.getAccountID()})
      .subscribe((value) => {
        this.iType.push(value);
        SelfData.type = this.iType;
      });

    this.serviceservice
      .setcim({accountid: this.getAccountID()})
      .subscribe((value) => {
        this.iCimResult[0] = value;
        SelfData.cim = this.iCimResult;
      });

    this.serviceservice
      .setSender({accountid: this.getAccountID()})
      .subscribe((value) => {
        this.iSenderResult[0] = value;
        SelfData.sender = this.iSenderResult;
      });

    this.serviceservice
      .setPEList({accountid: this.getAccountID()})
      .subscribe((value) => {
        this.iPEResult = [];
        // this.resetResults()
        this.iPEResult[0] = value;
        SelfData.pe = this.iPEResult;
      });

    this.serviceservice
      .setSWSList({accountid: this.getAccountID()})
      .subscribe((swsvalue) => {
        this.iSWSResult[0] = swsvalue;
        SelfData.sws = this.iSWSResult;

      });

    this.serviceservice
      .setRegionList({accountid: this.getAccountID()})
      .subscribe((regionvalue) => {
        this.iRegionResult[0] = regionvalue;
        SelfData.region = this.iRegionResult;
      });

    this.serviceservice
      .setFabricList({accountid: this.getAccountID()})
      .subscribe((fabricvalue) => {
        this.iFabricResult[0] = fabricvalue;
        SelfData.fabric = this.iFabricResult;
      });

    this.serviceservice
      .setManuList({accountid: this.getAccountID()})
      .subscribe((manuvalue) => {
        this.iManuResult[0] = manuvalue;
        SelfData.manu = this.iManuResult;
      });

    this.serviceservice
      .setMiscList({accountid: this.getAccountID()})
      .subscribe((value) => {
        this.iMisc[0] = value;
        SelfData.misc = this.iMisc;
      });

    this.serviceservice
      .setIssueList({accountid: this.getAccountID()})
      .subscribe((value) => {
        this.iTODResult[0] = value;
        SelfData.issue = this.iTODResult;
      });

    this.serviceservice
      .setSizeList({accountid: this.getAccountID()})
      .subscribe((value) => {
        this.iSize[0] = value;
        SelfData.size = this.iSize;
      });

    this.serviceservice
      .setSizeRangeList({accountid: this.getAccountID()})
      .subscribe((value) => {
        this.iSizeRange[0] = value;
        SelfData.sizerange = this.iSizeRange;
      });

    this.serviceservice
      .setPatternList({accountid: this.getAccountID()})
      .subscribe((value) => {
        this.iPattern[0] = value;
        SelfData.pattern = this.iPattern;
      });

    this.serviceservice
      .setPhotographer({accountid: this.getAccountID()})
      .subscribe((value) => {
        this.iPhotograoher[0] = value;
        SelfData.photographer = this.iPhotograoher;
      });

  }

  processFieldList() {
    const fieldListData = this.iFieldList[0]?.data || [];
    console.log("fieldListData", fieldListData)
    fieldListData.forEach((item: any) => {
      console.log("item.fieldname", item.fieldname)
      switch (item.fieldname) {
        case 'Designer':
          this.selectCheckDesigner = item.isshow;
          break;
        case 'Inventory Status':
          this.selectCheckInventoryStatus = item.isshow;
          break;
        case 'Gender':
          this.selectCheckGender = item.isshow;
          break;
        case 'Sample Workflow Status':
          this.selectCheckSampleWorkflowStatus = item.isshow;
          break;
        case 'Region':
          this.selectCheckRegion = item.isshow;
          break;
        case 'Notes':
          this.selectCheckNotes = item.isshow;
          break;
        case 'Fabric':
          this.selectCheckFabric = item.isshow;
          break;
        case 'Manufacturer':
          this.selectCheckManufacturer = item.isshow;
          break;
        case 'Collection':
          this.selectCheckCollection = item.isshow;
          break;
        case 'Style':
          this.selectCheckStyle = item.isshow;
          break;
        case 'Looks Associated':
          this.selectCheckLooksAssociated = item.isshow;
          break;
        case 'Season':
          this.selectCheckSeason = item.isshow;
          break;
        case 'Misc':
          this.selectCheckMisc = item.isshow;
          break;
        case 'Type':
          this.selectCheckType = item.isshow;
          break;
        case 'Size':
          this.selectCheckSize = item.isshow;
          break;
        case 'Size Range':
          this.selectCheckSizeRange = item.isshow;
          break;
        case 'Pattern':
          this.selectCheckPattern = item.isshow;
          break;
        case 'Color':
          this.selectCheckColor = item.isshow;
          break;
        case 'Description':
          this.selectCheckDescription = item.isshow;
          break;
        case 'Barcode':
          this.selectCheckBarcode = item.isshow;
          break;
        case 'Look Book':
          this.selectCheckLookBook = item.isshow;
          break;
        case 'Quantity':
          this.selectCheckQuantity = item.isshow;
          break;
        default:
          break;
      }
    });
  }

  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
      ? SelfData.account[0].data[0].id
      : SelfData.account[0].data[0].parentid;
  }

  ngAfterViewInit(): void {
    this.ELEMENT_DATA = [...SelfData.sampledata[0].data];
    this.dataSource = new MatTableDataSource<SetSampleListData>(
      this.ELEMENT_DATA
    );
    this.dataSource.paginator = this.paginator;
    this.cdRef.detectChanges();
    this.yourappsService.reloadSampleTableList.subscribe((description) => {
      this.ELEMENT_DATA = [...SelfData.sampledata[0].data];
      this.dataSource = new MatTableDataSource<SetSampleListData>(
        this.ELEMENT_DATA
      );
      this.dataSource.paginator = this.paginator;
    });
    console.log("ngAfterViewInit>>>>")
  }

  ngOnInit() {
    for (let element of this.dataSource.data) {
      element['checked'] = !!SelfData.checkedsample.find(
        (sample) => sample['barcode'] === element['barcode']
      );
    }
    this.yourappsService.reloadCurSampleList.subscribe((description) => {
      this.filterData()
    })
    this.yourappsService.setpaginator$.subscribe((page) => {
      this.first = page;
      console.log("page",page)
      this.first = SelfData.samplespage
    });
    this.first = SelfData.samplespage
  }

  public get setdataSource() {
    return this.dataSource;
  }

  calculateAccountId(): number {
    return SelfData.account[0].data[0].parentid === 0
      ? SelfData.account[0].data[0].id
      : SelfData.account[0].data[0].parentid;
  }

  onButtonDeleteClick(element: any) {

    this.confirmationService.confirm({
      message: `<strong>Are you sure you want to delete this entry?</strong>`,
      header: 'Information',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        SelfData.sampleselecteddata = element;
        const accountid = this.calculateAccountId();
        const account = {
          designerid: 0,
          isactiviti: false,
          notes: null,
          looksassociated: null,
          gender: null,
          ctrl: 2,
          inventoristatusid: null,
          manufacturerid: 0,
          colorcode: null,
          description: null,
          sessionid: 0,
          curdate: new Date(),
          userid: 0,
          accountid: accountid,
          regionid: 0,
          typeid: 0,
          id: SelfData.sampleselecteddata?.['ID'] || 0,
          sizeid: 0,
          quantity: null,
          fabricid: 0,
          patternid: 0,
          sizerangeid: 0,
          colorname: null,
          styleid: 0,
          miscid: 0,
          pricesid: 0,
          collectionid: 0,
          inventoristatus: null,
          sampleworkflowstatusid: 0,
          storageid: 0,
          stylestr: "",
          barcode: "",
          samplestyle: SelfData.samplestyle
        };

        this.serviceservice.prcSample(account).subscribe(() => {
          this.yourappsService.getReloadSampleList();
        });
      },
      reject: (type: any) => {
        if (type == ConfirmEventType.REJECT) {

        } else if (type == ConfirmEventType.CANCEL) {

        }
      }

    });
    setTimeout(() => {
      this.applyLocalStyles();
    }, 0);

  }

  goToFirstPage() {
    console.log(this.first)
    this.paginator1.changePage(0);
    this.first = 0;
    this.cdRef.detectChanges();
  }

  onCellDblClick(element: any) {
    console.log('Double clicked cell with data: ', element);
    // this.yourappsService.setSelectedValue(element.description);
    // this.modalService.closeSWS();
  }

  onEditButtonClick(element: any) {
    SelfData.sampleselecteddata = element;
    SelfData.isduplicate = false
    SelfData.updatebuttonisenabled = true
    this.yourappsService.selectSampleData(element);
    this.modalService.createsampleropen();
  }

  onDuplicateButtonClick(element: any) {
    SelfData.sampleselecteddata = element;
    SelfData.isduplicate = true
    SelfData.updatebuttonisenabled = true
    this.yourappsService.selectSampleData(element);
    this.modalService.createsampleropen();
  }

  printQRCode() {
    const printWindow = window.open('', '', 'width=800,height=600');

    if (printWindow) {
      printWindow.document.open();
      printWindow.document.write(`
      <html>
        <head>
          <title>Print QR Code</title>
          <style>
            @page {
              size: 400mm 50mm; /* Custom size: width x height */
              margin: 1mm; /* Minimum margin */
            }
            body {
              margin: 0;
              width: 100%;
              height: 100%;
              display: flex; /* Enable Flexbox */
              justify-content: center; /* Center horizontally */
              align-items: center; /* Center vertically */
            }
            .barcode {
              transform: scale(1.1); /* Scale to 120% */
            }
          </style>
        </head>
        <body>
          <div class="barcode">
            ${this.qrCode.nativeElement.innerHTML}
          </div>
        </body>
      </html>
    `);
      printWindow.document.close();
      printWindow.focus();
      printWindow.print();
      printWindow.close();
    } else {
      console.error('Failed to open print window');
    }
  }

  updateCheckedSamples(element: SetSampleListData) {
    if (element['checked']) {
      SelfData.checkedsample.push(element);
    } else {
      const index = SelfData.checkedsample.findIndex(
        (sample) => sample['barcode'] === element['barcode']
      );
      if (index !== -1) {
        SelfData.checkedsample.splice(index, 1);
      }
    }

    // update the localstorage
    localStorage.setItem(
      'checkedSamples',
      JSON.stringify(SelfData.checkedsample)
    );
  }

  iOriginalImagesResult: GetOriginalImages[] = [];
  onImageClick(item: any) {
    console.log("item", item["ID"], item["accountid"])
    this.yourappsService.setImageList(item, 3)
    this.modalService.openivframe()
  }

  connected: boolean = false;

  async setqrcodedata(value: string,) {
    this.qrcodedata = value;
    // console.log(value);
    // this.printQRCode();
    SelfData.selectadpage1 = 4
    SelfData.selectedbarcode = value
    SelfData.setSample = []
    if (this.selectedElement) {
      SelfData.setSample.push(this.selectedElement);
      console.log("this.selectedElement", this.selectedElement)
//       try {
//          await this.qzTrayService.connect();
//         this.connected = true; // Устанавливаем connected = true при успешном подключении
//
//         //barcodeprint
//         if (!this.connected) {
//           console.warn('Not connected to QZ Tray. Please connect first.');
//           return;
//         }
//
//         // Предположим, что SelfData.account[0].data[0].company существует
//         const companyName = SelfData.account[0].data[0].company;
//         const barcode = this.selectedElement['barcode'];
//         const line1 = this.selectedElement['Collection'];
//         const line2 = this.selectedElement['Season'];
//         const line3 = this.selectedElement['Designer'];
//         const line4 = this.selectedElement['Description'];
//         const line5 = this.selectedElement['Size'];
//         const site = SelfData.account[0].data[0].website.toUpperCase();
//
//         const line1groupe = line1 + ' ' + line2 + ' ' + line3
//         const line2groupe = line4 + ' ' + line5
//         // console.log("this.selectedElement['Season']", this.selectedElement['Season'])
//         // console.log("this.selectedElement['season']", this.selectedElement['season'])
//         // console.log(barcode)
//         const zpl = `
//     ^XA
//     ^CF0,30
//     ^FO200,40^FD${companyName}^FS
//
//     ^FO200,70^BY2,2,30
//     ^BCN,60,Y,N,N
//     ^FD${barcode}^FS
//
//     ^CF0,20
//     ^FO200,160^FD${line1groupe}^FS
//     ^FO200,190^FD${line2groupe}^FS
//     ^FO200,220^FD${site}^FS
//
//     ^FO582,50^GB20,200,20^FS
//     ^FO580,65^A0R,20,20^FR^FDfashion-m.com^FS
//
//     ^XZ
//   `;
//
//
// //         const zpl = `^XA
// // ^LH0,0
// // ^FO50,50^A0N,50,50^FDTest Success!^FS
// // ^FO50,100^BQN,2,5^FDMM,A123-456^FS
// // ^XZ`;
//
//         const printData = [
//           {
//             type: 'raw',
//             data: zpl,
//             options: { encoding: 'UTF-8' } // Добавить кодировку
//           }
//         ];
//
//         try {
//           await this.qzTrayService.print(printData);
//           console.log('Print job successful!');
//         } catch (error) {
//           console.error('Print job failed:', error);
//         }
//         //barcodeprint
//         console.log('Connected to QZ Tray');
//       } catch (error) {
//         this.connected = false; // Если ошибка, устанавливаем connected = false
//         console.error('Failed to connect to QZ Tray:', error);
//         this.connected = false;
//       }
    }
     this.modalService.openPrinterFrame()
  }

  onInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;

    this.dt1.filterGlobal(value, 'contains');
  }

  private applyLocalStyles() {
    setTimeout(() => {
      const messageElements = document.querySelectorAll('.p-confirm-dialog-message');
      messageElements.forEach((element) => {
        element.setAttribute('style', 'font-size: 16px; color: red;');
      });
    }, 0);

    const iconElements = document.querySelectorAll('.p-confirm-dialog-icon');
    iconElements.forEach((element) => {
      element.setAttribute('style', 'color: red');
    });
  }

  clear(table: Table) {
    table.clear();
    this.filtertext = ""
  }

  protected readonly SelfData = SelfData;

  pageChange(event: { first: number }) {
    this.first = event.first;
    SelfData.samplespage = event.first
    console.log("SelfData.samplespage", SelfData.samplespage)
  }

  protected readonly transformImageUrl = transformImageUrl;
  selectCheckDesigner: boolean = false;
  selectCheckInventoryStatus = false;
  selectCheckGender = false;
  selectCheckSampleWorkflowStatus = false;
  selectCheckRegion = false;
  selectCheckNotes = false;
  selectCheckFabric = false;
  selectCheckManufacturer = false;
  selectCheckCollection = false;
  selectCheckStyle = false;
  selectCheckLooksAssociated = false;
  selectCheckSeason = false;
  selectCheckMisc = false;
  selectCheckType = false;
  selectCheckSize = false;
  selectCheckSizeRange = false;
  selectCheckPattern = false;
  selectCheckQuantity = false;
  selectCheckColor = false;
  selectCheckBarcode = false;
  selectCheckDepartment = false;
  selectCheckLookBook = false;
  selectCheckDescription = false;
  selectCheckDeliveryNumberString: string = "";
  selectCheckTypeOfDispatchString: string = "";
  selectCheckBarcodeString: string = "";
  selectCheckDesignerString: string = "";
  selectCheckCollectionString: string = "";
  selectCheckSeasonString: string = "";
  selectCheckStyleString: string = "";
  selectCheckTypeString: string = "";
  selectCheckColorString: string = "";
  createDate: Date[] | null = null;
  shipDate: Date[] | null = null;
  returnDate: Date[] | null = null;
  selectCheckCompanyNameString: string = "";
  selectCheckContactString: string = "";
  selectCheckCelebritiString: string = "";
  selectCheckPublicationString: string = "";
  selectCheckEditorStylistString: string = "";
  selectCheckSendOutTypeString: string = "";
  selectCheckCelebInfluencModelString: string = "";
  selectCheckProjectEventString: string = "";
  selectCheckSenderString: string = "";
  selectCheckDescriptionString: string = "";
  selectCheckShipToString: string = "";
  selectCheckSampleWorkflowStatusString: string = "";
  selectCheckRegionString: string = "";
  selectCheckFabricString: string = "";
  selectCheckManufacturerString: string = "";
  selectCheckMiscString: string = "";
  selectCheckIssueString: string = "";
  selectCheckTrackingNumberString: string = "";
  selectCheckSizeString: string = "";
  selectCheckSizeRangeString: string = "";
  selectCheckPatternstring: string = "";
  selectCheckgenderString: string = "";
  selectCheckNotesString: string = "";
  selectCheckPhotographerString: string = "";

  iPattern: GetTypeList[] = [];
  iSizeRange: GetSizeRangeList[] = [];
  iSize: GetTypeList[] = [];
  iTODResult: GettodList[] = [];
  iTodResult: GettodList[] = [];
  iDesignerResult: GetDesignerList[] = [];
  iCollection: GetCollectionList[] = [];
  iSeason: GetSeasonList[] = [];
  iStyle: GetStyleList[] = [];
  iContact: GetStyleList[] = [];
  iClient: GetStyleList[] = [];
  ipublication: GetStyleList[] = [];
  iEStyllist: GetStyleList[] = [];
  iPhotograoher: GetStyleList[] = [];
  iType: GetTypeList[] = [];
  iSendOutType: GetTypeList[] = [];
  iCompanyResult: GetCompanyList[] = [];
  iCimResult: GetTypeList[] = [];
  iPEResult: GettodList[] = [];
  iSenderResult: GettodList[] = [];
  iSWSResult: GetSWSList[] = [];
  iRegionResult: GetRegionList[] = []
  iFabricResult: GetFabricList[] = [];
  iManuResult: GetManuList[] = [];
  iMisc: GetMiscList[] = [];
  gender: Gender[] = [
    {value: 'Man'},
    {value: 'Unisex'},
    {value: 'Women'},
  ];

  onInfoButtonClick() {
    window.open('https://fm.welcomeapps.net/FAQ/SampleManager.html', '_blank');
  }

  @ViewChild('op') overlayPanel!: OverlayPanel;

  closeOverlayPanel() {
    const fieldListData = this.iFieldList[0]?.data || [];

// Преобразование `fieldListData` с использованием значений свойств компонента
    const dataToSend = fieldListData.map((item: any) => {
      const isshowPropertyName = `selectCheck${item.fieldname.replace(/[^a-zA-Z0-9]/g, '')}`; // Убираем пробелы и символы
      const isshowValue = (this as any)[isshowPropertyName] || false; // Динамически получаем значение свойства
      return {
        isshow: isshowValue,
        formname: "createsamplesdialog", // Предположим, что `formName` определен в вашем компоненте
        fieldname: item.fieldname,
        userid: SelfData.account[0].data[0].id, // Предположим, что `userId` определен в вашем компоненте
        accountid: this.getAccountID(), // Предположим, что `accountId` определен в вашем компоненте
      };
    });

// Отправка данных
    dataToSend.forEach((fieldData) => {
      this.serviceservice.prcGridFields(fieldData).subscribe(
        (response) => {
          console.log('Field updated:', fieldData.fieldname, response);
        },
        (error) => {
          console.error('Error updating field:', fieldData.fieldname, error);
        }
      );
    });

    this.overlayPanel.hide();
  }

  onInputDesigner(value: string) {
    // this.dt1.filterGlobal(value, 'contains');
    // this.selectCheckDeliveryNumberString = value
    // this.dataSource.data = SelfData.sampledata[0].data;
    console.log('Selected date range:', value);
    this.filterData()
  }

  onInputColor(selectedOption: any): void {
    if (selectedOption) {
      this.selectCheckColorString = selectedOption;
    } else {
      console.log('Цвет не выбран или поле было очищено');
      this.selectCheckColorString = ''; // Или другой обработчик для очистки
    }
    this.filterData();
  }

  onInputGender(selectedOption: any): void {
    if (selectedOption) {
      this.selectCheckgenderString = selectedOption;
    } else {
      console.log('Цвет не выбран или поле было очищено');
      this.selectCheckgenderString = ''; // Или другой обработчик для очистки
    }
    this.filterData();
  }

  iSendOutReportResult: SetSampleList[] = [];
  private originalData: any[] = [];

  filterData(): void {
    console.log("Original Data перед фильтрацией:", this.originalData);

    // Если оригинальные данные еще не сохранены, сохраняем их
    if (this.originalData.length === 0 && Array.isArray(SelfData.sampledata[0]?.data)) {
      this.originalData = [...SelfData.sampledata[0].data];
    }

    // Проверяем, что массив содержит данные
    if (!Array.isArray(this.originalData) || this.originalData.length === 0) {
      console.error("Нет данных для фильтрации");
      return;
    }

    // Создаем объект фильтров
    const filters = {
      DeliveryNumber: this.selectCheckDeliveryNumberString?.toLowerCase().trim() || "",
      TypeOfDispatch: this.selectCheckTypeOfDispatchString?.toLowerCase().trim() || "",
      Barcode: this.selectCheckBarcodeString?.toLowerCase().trim() || "",
      Designer: this.selectCheckDesignerString?.toLowerCase().trim() || "",
      Collection: this.selectCheckCollectionString?.toLowerCase().trim() || "",
      Season: this.selectCheckSeasonString?.toLowerCase().trim() || "",
      Style: this.selectCheckStyleString?.toLowerCase().trim() || "",
      Type: this.selectCheckTypeString?.toLowerCase().trim() || "",
      Color: this.selectCheckColorString?.toLowerCase().trim() || "",
      CompanyName: this.selectCheckCompanyNameString?.toLowerCase().trim() || "",
      Contact: this.selectCheckContactString?.toLowerCase().trim() || "",
      Celebriti: this.selectCheckCelebritiString?.toLowerCase().trim() || "",
      Publication: this.selectCheckPublicationString?.toLowerCase().trim() || "",
      EditorStylist: this.selectCheckEditorStylistString?.toLowerCase().trim() || "",
      SendOutType: this.selectCheckSendOutTypeString?.toLowerCase().trim() || "",
      CelebInfluencModel: this.selectCheckCelebInfluencModelString?.toLowerCase().trim() || "",
      ProjectEvent: this.selectCheckProjectEventString?.toLowerCase().trim() || "",
      Sender: this.selectCheckSenderString?.toLowerCase().trim() || "",
      Description: this.selectCheckDescriptionString?.toLowerCase().trim() || "",
      ShipTo: this.selectCheckShipToString?.toLowerCase().trim() || "",
      SampleWorkflowStatus: this.selectCheckSampleWorkflowStatusString?.toLowerCase().trim() || "",
      Region: this.selectCheckRegionString?.toLowerCase().trim() || "",
      Fabric: this.selectCheckFabricString?.toLowerCase().trim() || "",
      Manufacturer: this.selectCheckManufacturerString?.toLowerCase().trim() || "",
      Misc: this.selectCheckMiscString?.toLowerCase().trim() || "",
      Issue: this.selectCheckIssueString?.toLowerCase().trim() || "",
      TrackingNumber: this.selectCheckTrackingNumberString?.toLowerCase().trim() || "",
      Size: this.selectCheckSizeString?.toLowerCase().trim() || "",
      SizeRange: this.selectCheckSizeRangeString?.toLowerCase().trim() || "",
      Pattern: this.selectCheckPatternstring?.toLowerCase().trim() || "",
      Gender: this.selectCheckgenderString?.toLowerCase().trim() || "",
      Notes: this.selectCheckNotesString?.toLowerCase().trim() || "",
      Photographer: this.selectCheckPhotographerString?.toLowerCase().trim() || "",
      createDateStart: this.createDate?.[0] || null,
      createDateEnd: this.createDate?.[1] || null,
      createShipDateStart: this.shipDate?.[0] || null,
      createShipDateEnd: this.shipDate?.[1] || null,
      createReturnDateStart: this.returnDate?.[0] || null,
      createReturnDateEnd: this.returnDate?.[1] || null,
    };

    // Проверяем, если все фильтры пустые
    const isEmptyFilters = Object.values(filters).every(filter => filter === "" || filter === null);

    // Если фильтры пустые, возвращаем оригинальные данные
    if (isEmptyFilters) {
      console.log("Фильтры пустые. Возвращаем исходные данные.");
      this.iSendOutReportResult[0].data = [...this.originalData];
      return;
    }

    // Фильтруем массив
    const filteredData = this.originalData.filter((item: any) => {
      const createDate = item["Create Date"] ? new Date(item["Create Date"]) : null;
      const shipDate = item["Ship Date"] ? new Date(item["Ship Date"]) : null;
      const returnDate = item["Return Date"] ? new Date(item["Return Date"]) : null;

      return (
        (!filters.DeliveryNumber || item["Delivery #"]?.toString().toLowerCase() === filters.DeliveryNumber.toLowerCase()) &&
        (!filters.TypeOfDispatch || item["Type of Dispatch"]?.toString().toLowerCase() === filters.TypeOfDispatch.toLowerCase()) &&
        (!filters.Barcode || item["barcode"]?.toString().toLowerCase() === filters.Barcode.toLowerCase()) &&
        (!filters.Designer || item["Designer"]?.toString().toLowerCase() === filters.Designer.toLowerCase()) &&
        (!filters.Collection || item["Collection"]?.toString().toLowerCase() === filters.Collection.toLowerCase()) &&
        (!filters.Season || item["Season"]?.toString().toLowerCase() === filters.Season.toLowerCase()) &&
        (!filters.Style || item["Style"]?.toString().toLowerCase() === filters.Style.toLowerCase()) &&
        (!filters.Type || item["Type"]?.toString().toLowerCase() === filters.Type.toLowerCase()) &&
        (!filters.Color || item["Color"]?.toString().toLowerCase() === filters.Color.toLowerCase()) &&
        (!filters.CompanyName || item["Companyname"]?.toString().toLowerCase() === filters.CompanyName.toLowerCase()) &&
        (!filters.Contact || item["Contact"]?.toString().toLowerCase() === filters.Contact.toLowerCase()) &&
        (!filters.Celebriti || item["Celebriti"]?.toString().toLowerCase() === filters.Celebriti.toLowerCase()) &&
        (!filters.Publication || item["Publication"]?.toString().toLowerCase() === filters.Publication.toLowerCase()) &&
        (!filters.EditorStylist || item["Editor/Stylist"]?.toString().toLowerCase() === filters.EditorStylist.toLowerCase()) &&
        (!filters.SendOutType || item["RendOut Type"]?.toString().toLowerCase() === filters.SendOutType.toLowerCase()) &&
        (!filters.CelebInfluencModel || item["Celeb/Influenc/Model"]?.toString().toLowerCase() === filters.CelebInfluencModel.toLowerCase()) &&
        (!filters.ProjectEvent || item["Project/Event"]?.toString().toLowerCase() === filters.ProjectEvent.toLowerCase()) &&
        (!filters.Sender || item["Sender"]?.toString().toLowerCase() === filters.Sender.toLowerCase()) &&
        (!filters.Description || item["Description"]?.toString().toLowerCase() === filters.Description.toLowerCase()) &&
        (!filters.ShipTo || item["Ship To"]?.toString().toLowerCase() === filters.ShipTo.toLowerCase()) &&
        (!filters.SampleWorkflowStatus || item["Sample Workflow Status"]?.toString().toLowerCase() === filters.SampleWorkflowStatus.toLowerCase()) &&
        (!filters.Region || item["Region"]?.toString().toLowerCase() === filters.Region.toLowerCase()) &&
        (!filters.Fabric || item["Fabric"]?.toString().toLowerCase() === filters.Fabric.toLowerCase()) &&
        (!filters.Manufacturer || item["Manufacturer"]?.toString().toLowerCase() === filters.Manufacturer.toLowerCase()) &&
        (!filters.Misc || item["Misc"]?.toString().toLowerCase() === filters.Misc.toLowerCase()) &&
        (!filters.Issue || item["Issue"]?.toString().toLowerCase() === filters.Issue.toLowerCase()) &&
        (!filters.TrackingNumber || item["Tracking Number"]?.toString().toLowerCase() === filters.TrackingNumber.toLowerCase()) &&
        (!filters.Size || item["Size"]?.toString().toLowerCase() === filters.Size.toLowerCase()) &&
        (!filters.SizeRange || item["Size Range"]?.toString().toLowerCase() === filters.SizeRange.toLowerCase()) &&
        (!filters.Pattern || item["Pattern"]?.toString().toLowerCase() === filters.Pattern.toLowerCase()) &&
        (!filters.Gender || item["Gender"]?.toString().toLowerCase() === filters.Gender.toLowerCase()) &&
        (!filters.Notes || item["Notes"]?.toString().toLowerCase() === filters.Notes.toLowerCase()) &&
        (!filters.Photographer || item["Photographer"]?.toString().toLowerCase() === filters.Photographer.toLowerCase()) &&
        (!filters.createDateStart || (createDate && createDate >= filters.createDateStart)) &&
        (!filters.createDateEnd || (createDate && createDate <= filters.createDateEnd)) &&
        (!filters.createShipDateStart || (shipDate && shipDate >= filters.createShipDateStart)) &&
        (!filters.createShipDateEnd || (shipDate && shipDate <= filters.createShipDateEnd)) &&
        (!filters.createReturnDateStart || (returnDate && returnDate >= filters.createReturnDateStart)) &&
        (!filters.createReturnDateEnd || (returnDate && returnDate <= filters.createReturnDateEnd))
      );
    });
    // const filteredData = this.originalData.filter((item: any) => {
    //   const createDate = item["Create Date"] ? new Date(item["Create Date"]) : null;
    //   const shipDate = item["Ship Date"] ? new Date(item["Ship Date"]) : null;
    //   const returnDate = item["Return Date"] ? new Date(item["Return Date"]) : null;
    //   return (
    //     (!filters.DeliveryNumber || item["Delivery #"]?.toString().toLowerCase().includes(filters.DeliveryNumber)) &&
    //     (!filters.TypeOfDispatch || item["Type of Dispatch"]?.toString().toLowerCase().includes(filters.TypeOfDispatch)) &&
    //     (!filters.Barcode || item["barcode"]?.toString().toLowerCase().includes(filters.Barcode)) &&
    //     (!filters.Designer || item["Designer"]?.toString().toLowerCase().includes(filters.Designer)) &&
    //     (!filters.Collection || item["Collection"]?.toString().toLowerCase().includes(filters.Collection)) &&
    //     (!filters.Season || item["Season"]?.toString().toLowerCase().includes(filters.Season)) &&
    //     (!filters.Style || item["Style"]?.toString().toLowerCase().includes(filters.Style)) &&
    //     (!filters.Type || item["Type"]?.toString().toLowerCase().includes(filters.Type)) &&
    //     (!filters.Color || item["Color"]?.toString().toLowerCase().includes(filters.Color)) &&
    //     (!filters.CompanyName || item["Companyname"]?.toString().toLowerCase().includes(filters.CompanyName)) &&
    //     (!filters.Contact || item["Contact"]?.toString().toLowerCase().includes(filters.Contact)) &&
    //     (!filters.Celebriti || item["Celebriti"]?.toString().toLowerCase().includes(filters.Celebriti)) &&
    //     (!filters.Publication || item["Publication"]?.toString().toLowerCase().includes(filters.Publication)) &&
    //     (!filters.EditorStylist || item["Editor/Stylist"]?.toString().toLowerCase().includes(filters.EditorStylist)) &&
    //     (!filters.SendOutType || item["RendOut Type"]?.toString().toLowerCase().includes(filters.SendOutType)) &&
    //     (!filters.CelebInfluencModel || item["Celeb/Influenc/Model"]?.toString().toLowerCase().includes(filters.CelebInfluencModel)) &&
    //     (!filters.ProjectEvent || item["Project/Event"]?.toString().toLowerCase().includes(filters.ProjectEvent)) &&
    //     (!filters.Sender || item["Sender"]?.toString().toLowerCase().includes(filters.Sender)) &&
    //     (!filters.Description || item["Description"]?.toString().toLowerCase().includes(filters.Description)) &&
    //     (!filters.ShipTo || item["Ship To"]?.toString().toLowerCase().includes(filters.ShipTo)) &&
    //     (!filters.SampleWorkflowStatus || item["Sample Workflow Status"]?.toString().toLowerCase().includes(filters.SampleWorkflowStatus)) &&
    //     (!filters.Region || item["Region"]?.toString().toLowerCase().includes(filters.Region)) &&
    //     (!filters.Fabric || item["Fabric"]?.toString().toLowerCase().includes(filters.Fabric)) &&
    //     (!filters.Manufacturer || item["Manufacturer"]?.toString().toLowerCase().includes(filters.Manufacturer)) &&
    //     (!filters.Misc || item["Misc"]?.toString().toLowerCase().includes(filters.Misc)) &&
    //     (!filters.Issue || item["Issue"]?.toString().toLowerCase().includes(filters.Issue)) &&
    //     (!filters.TrackingNumber || item["Tracking Number"]?.toString().toLowerCase().includes(filters.TrackingNumber)) &&
    //     (!filters.Size || item["Size"]?.toString().toLowerCase().includes(filters.Size)) &&
    //     (!filters.SizeRange || item["Size Range"]?.toString().toLowerCase().includes(filters.SizeRange)) &&
    //     (!filters.Pattern || item["Pattern"]?.toString().toLowerCase().includes(filters.Pattern)) &&
    //     (!filters.Gender || item["Gender"]?.toString().toLowerCase().includes(filters.Gender)) &&
    //     (!filters.Notes || item["Notes"]?.toString().toLowerCase().includes(filters.Notes)) &&
    //     (!filters.Photographer || item["Photographer"]?.toString().toLowerCase().includes(filters.Photographer)) &&
    //     (!filters.createDateStart || (createDate && createDate >= filters.createDateStart)) &&
    //     (!filters.createDateEnd || (createDate && createDate <= filters.createDateEnd)) &&
    //     (!filters.createShipDateStart || (shipDate && shipDate >= filters.createShipDateStart)) &&
    //     (!filters.createShipDateEnd || (shipDate && shipDate <= filters.createShipDateEnd)) &&
    //     (!filters.createReturnDateStart || (returnDate && returnDate >= filters.createReturnDateStart)) &&
    //     (!filters.createReturnDateEnd || (returnDate && returnDate <= filters.createReturnDateEnd))
    //   );
    // });

    console.log("Filtered Data:", filteredData);
    this.iSendOutReportResult[0].data = filteredData;

    if (filteredData.length === 0) {
      console.warn("Фильтрация не нашла совпадений. Проверьте параметры фильтров.");
    }
  }

  onClearAllFilters() {
    this.selectCheckDeliveryNumberString = ""
    this.selectCheckTypeOfDispatchString = ""
    this.selectCheckBarcodeString = ""
    this.selectCheckDesignerString = ""
    this.selectCheckCollectionString = ""
    this.selectCheckSeasonString = ""
    this.selectCheckStyleString = ""
    this.selectCheckTypeString = ""
    this.selectCheckColorString = ""
    this.createDate = null
    this.shipDate = null
    this.returnDate = null
    this.selectCheckCompanyNameString = ""
    this.selectCheckContactString = ""
    this.selectCheckCelebritiString = ""
    this.selectCheckPublicationString = ""
    this.selectCheckEditorStylistString = ""
    this.selectCheckSendOutTypeString = ""
    this.selectCheckCelebInfluencModelString = ""
    this.selectCheckProjectEventString = ""
    this.selectCheckSenderString = ""
    this.selectCheckDescriptionString = ""
    this.selectCheckShipToString = ""
    this.selectCheckSampleWorkflowStatusString = ""
    this.selectCheckRegionString = ""
    this.selectCheckFabricString = ""
    this.selectCheckManufacturerString = ""
    this.selectCheckMiscString = ""
    this.selectCheckIssueString = ""
    this.selectCheckTrackingNumberString = ""
    this.selectCheckSizeString = ""
    this.selectCheckSizeRangeString = ""
    this.selectCheckPatternstring = ""
    this.selectCheckgenderString = ""
    this.selectCheckNotesString = ""
    this.selectCheckPhotographerString = ""
    this.filterData()
    this.cdr.detectChanges();
  }
}
