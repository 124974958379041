<p-button class="info-btn" icon="pi pi-info" [rounded]="true" [outlined]="true" (click)="onInfoButtonClick()"/>
  <button
    mat-icon-button
    type="button"
    class="close-btn"
    (click)="modalService.createcontactclose()"
  >
    <mat-icon>close</mat-icon>
  </button>
  <p class="sample-manager-text">{{ contactcaption }}</p>
  <div class="full-width-yellow-div">
    <p class="important-text">
      Important points to remember when storing personal information:
    </p>
    <p class="body-text">
      Your contacts have a right to access the information contained in this
      form under the GDPR. Therefore the information you provide here must be
      RELEVANT and RESTRICTED to the context of the intended usage. Additionally
      under the GDPR, you must not include a subjective assessment or reveal,
      directly or indirectly, health data, racial origins, political,
      philosophical or religious opinions, trade union membership or the morals
      of the contact concerned.
    </p>
  </div>
  <mat-divider class="black-divider-contact"></mat-divider>
  <div class="scrollable-div-contact horizontal-container-contact">
    <div class="black-background-contact">
      <p class="caption-text">Personal Information</p>
      <div class="full-width-div-contact">
        <div class="form-field-spacing-contact form-field-spacing">
          <label class="line1-edits-new inputlabel">{{ captionDepartment }}</label>
          <nz-input-group nzSearch>
            <input disabled="true" class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="departmentstring"
              nz-input/>
          </nz-input-group>
        </div>
<!--        <mat-form-field class="form-field-spacing-contact" appearance="fill">-->
<!--          <mat-label>{{ captionDepartment }}</mat-label>-->
<!--          <input matInput [formControl]="departmentFormControl" required/>-->
<!--        </mat-form-field>-->


        <div class="example-full-width form-field-spacing-contact">
          <label class="line1-edits-new inputlabel">{{ captionPronoun }}</label>
          <nz-input-group nzSearch [nzAddOnAfter]="pronounbutton">
            <nz-select nzShowSearch [nzShowArrow]="true" class="line1-edits-new inputedit testdiv"
              [(ngModel)]="myPronounstring">
              <nz-option *ngFor="let option of (iPronounResult[0]?.data || [])" [nzLabel]="option.description"
                [nzValue]="option.description"></nz-option>
            </nz-select>
          </nz-input-group>
          <ng-template #pronounbutton>
            <button type="button" (click)="add($event, 0, null)" nz-button nzType="default" nzSearch><span nz-icon
              nzType="plus"></span></button>
          </ng-template>
<!--          <mat-form-field class="selector-full-width" appearance="fill">-->
<!--            <mat-label>{{ captionPronoun }}</mat-label>-->
<!--            <input type="text" matInput placeholder="Select or type..." [matAutocomplete]="auto1"-->
<!--              [formControl]="myPronounControl"-->
<!--              #autoInput1="matAutocompleteTrigger"-->
<!--            />-->
<!--            <button mat-stroked-button type="button" class="add-button-contact" matSuffix-->
<!--              (click)="add($event, 0, autoInput1)">-->
<!--              <mat-icon class="center-icon">add</mat-icon>-->
<!--            </button>-->
<!--            <mat-autocomplete #auto1="matAutocomplete">-->
<!--              <mat-option-->
<!--                *ngFor="let item of filteredPronounOptions | async"-->
<!--                [value]="item"-->
<!--              >-->
<!--                {{ item }}-->
<!--              </mat-option>-->
<!--            </mat-autocomplete>-->
<!--          </mat-form-field>-->
        </div>

        <div class="form-field-spacing-contact">
          <label class="line1-edits-new inputlabel">{{ captionSalutation }}</label>
          <nz-input-group nzSearch>
            <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="salutationstring" nz-input/>
          </nz-input-group>
        </div>

<!--        <div class="example-full-width form-field-spacing-contact">-->
<!--          <mat-form-field class="selector-full-width" appearance="fill">-->
<!--            <mat-label>{{ captionSalutation }}</mat-label>-->
<!--            <input matInput [formControl]="salutationControl"/>-->
<!--          </mat-form-field>-->
<!--        </div>-->

        <div class="form-field-spacing-contact">
          <label class="line1-edits-new inputlabel">{{ captionSpouse }}</label>
          <nz-input-group nzSearch>
            <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="pousestring" nz-input/>
          </nz-input-group>
        </div>

<!--        <div class="example-full-width form-field-spacing-contact">-->
<!--          <mat-form-field class="selector-full-width" appearance="fill">-->
<!--            <mat-label>{{ captionSpouse }}</mat-label>-->
<!--            <input matInput [formControl]="spouseControl"/>-->
<!--          </mat-form-field>-->
<!--        </div>-->

        <div class="form-field-spacing-contact">
          <label class="line1-edits-new inputlabel">{{ captionFirstname }}</label>
          <nz-input-group [nzStatus]="statusfirstname" nzSearch>
            <input [nzStatus]="statusfirstname" class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="firstNamestring" nz-input/>
          </nz-input-group>
        </div>

<!--        <div class="example-full-width form-field-spacing-contact">-->
<!--          <mat-form-field class="selector-full-width" appearance="fill">-->
<!--            <mat-label>{{ captionFirstname }}</mat-label>-->
<!--            <input-->
<!--              matInput-->
<!--              [formControl]="firstNameFormControl"-->
<!--              required-->
<!--              [type]="inputType"-->
<!--            />-->
<!--            <mat-error *ngIf="firstNameFormControl.hasError('required')">-->
<!--              First Name is <strong>required</strong>-->
<!--            </mat-error>-->
<!--          </mat-form-field>-->
<!--        </div>-->

        <div class="form-field-spacing-contact">
          <label class="line1-edits-new inputlabel">{{ captionAssistantName }}</label>
          <nz-input-group nzSearch>
            <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="assistantnamestring" nz-input/>
          </nz-input-group>
        </div>

<!--        <div class="example-full-width form-field-spacing-contact">-->
<!--          <mat-form-field class="selector-full-width" appearance="fill">-->
<!--            <mat-label>{{ captionAssistantName }}</mat-label>-->
<!--            <input matInput [formControl]="assistantnameControl"/>-->
<!--          </mat-form-field>-->
<!--        </div>-->

        <div class="form-field-spacing-contact">
          <label class="line1-edits-new inputlabel">{{ captionLastName }}</label>
          <nz-input-group nzSearch>
            <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="lastnamestring" nz-input/>
          </nz-input-group>
        </div>

<!--        <div class="example-full-width form-field-spacing-contact">-->
<!--          <mat-form-field class="selector-full-width" appearance="fill">-->
<!--            <mat-label>{{ captionLastName }}</mat-label>-->
<!--            <input matInput [formControl]="lastnameControl"/>-->
<!--          </mat-form-field>-->
<!--        </div>-->

        <div class="form-field-spacing-contact">
          <label class="line1-edits-new inputlabel">{{ captionAssistantTitle }}</label>
          <nz-input-group nzSearch>
            <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="assistanttitlestring" nz-input/>
          </nz-input-group>
        </div>

<!--        <div class="example-full-width form-field-spacing-contact">-->
<!--          <mat-form-field class="selector-full-width" appearance="fill">-->
<!--            <mat-label>{{ captionAssistantTitle }}</mat-label>-->
<!--            <input matInput [formControl]="assistanttitleControl"/>-->
<!--          </mat-form-field>-->
<!--        </div>-->

        <div class="form-field-spacing-contact">
          <label class="line1-edits-new inputlabel">{{ captionMobile }}</label>
          <nz-input-group nzSearch>
            <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="mobilestring" nz-input/>
          </nz-input-group>
        </div>

<!--        <div class="example-full-width form-field-spacing-contact">-->
<!--          <mat-form-field class="selector-full-width" appearance="fill">-->
<!--            <mat-label>{{ captionMobile }}</mat-label>-->
<!--            <input matInput [formControl]="mobileControl"/>-->
<!--          </mat-form-field>-->
<!--        </div>-->

        <div class="form-field-spacing-contact">
          <label class="line1-edits-new inputlabel">{{ captionAssistentMobile1 }}</label>
          <nz-input-group nzSearch>
            <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="assistentmobile1string" nz-input/>
          </nz-input-group>
        </div>

<!--        <div class="example-full-width form-field-spacing-contact">-->
<!--          <mat-form-field class="selector-full-width" appearance="fill">-->
<!--            <mat-label>{{ captionAssistentMobile1 }}</mat-label>-->
<!--            <input matInput [formControl]="assistentmobile1Control"/>-->
<!--          </mat-form-field>-->
<!--        </div>-->

        <div class="form-field-spacing-contact">
          <label class="line1-edits-new inputlabel">{{ captionEmail }}</label>
          <nz-input-group nzSearch>
            <input class="line1-edits-new inputedit testdiv" type="email" [(ngModel)]="emailstring" nz-input/>
          </nz-input-group>
        </div>

<!--        <div class="example-full-width form-field-spacing-contact">-->
<!--          <mat-form-field class="selector-full-width" appearance="fill">-->
<!--            <mat-label>{{ captionEmail }}</mat-label>-->
<!--            <input type="email" matInput [formControl]="emailControl"/>-->
<!--          </mat-form-field>-->
<!--        </div>-->

        <div class="form-field-spacing-contact">
          <label class="line1-edits-new inputlabel">{{ captionAssistentMobile2 }}</label>
          <nz-input-group nzSearch>
            <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="assistentmobile2string" nz-input/>
          </nz-input-group>
        </div>

<!--        <div class="example-full-width form-field-spacing-contact">-->
<!--          <mat-form-field class="selector-full-width" appearance="fill">-->
<!--            <mat-label>{{ captionAssistentMobile2 }}</mat-label>-->
<!--            <input matInput [formControl]="assistentmobile2Control"/>-->
<!--          </mat-form-field>-->
<!--        </div>-->

        <div class="form-field-spacing-contact">
          <label class="line1-edits-new inputlabel">{{ captionAltEmail }}</label>
          <nz-input-group nzSearch>
            <input class="line1-edits-new inputedit testdiv" type="email" [(ngModel)]="emailAltstring" nz-input/>
          </nz-input-group>
        </div>

<!--        <div class="example-full-width form-field-spacing-contact">-->
<!--          <mat-form-field class="selector-full-width" appearance="fill">-->
<!--            <mat-label>{{ captionAltEmail }}</mat-label>-->
<!--            <input type="email" matInput [formControl]="emailAltContolr"/>-->
<!--          </mat-form-field>-->
<!--        </div>-->

        <div class="form-field-spacing-contact">
          <label class="line1-edits-new inputlabel">{{ captionAssistentEmail }}</label>
          <nz-input-group nzSearch>
            <input class="line1-edits-new inputedit testdiv" type="email" [(ngModel)]="emailAssistentstring" nz-input/>
          </nz-input-group>
        </div>

<!--        <div class="example-full-width form-field-spacing-contact">-->
<!--          <mat-form-field class="selector-full-width" appearance="fill">-->
<!--            <mat-label>{{ captionAssistentEmail }}</mat-label>-->
<!--            <input-->
<!--              type="email"-->
<!--              matInput-->
<!--              [formControl]="emailAssistentContolr"-->
<!--            />-->
<!--          </mat-form-field>-->
<!--        </div>-->

        <div class="form-field-spacing-contact">
          <label class="line1-edits-new inputlabel">{{ captionEICode }}</label>
          <nz-input-group nzSearch>
            <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="eiCodestring" nz-input/>
          </nz-input-group>
        </div>

<!--        <div class="example-full-width form-field-spacing-contact">-->
<!--          <mat-form-field class="selector-full-width" appearance="fill">-->
<!--            <mat-label>{{ captionEICode }}</mat-label>-->
<!--            <input matInput [formControl]="eiCodeContolr"/>-->
<!--          </mat-form-field>-->
<!--        </div>-->

        <div class="form-field-spacing-contact">
          <label class="line1-edits-new inputlabel">{{ captionvcemail }}</label>
          <nz-input-group nzSearch>
            <input class="line1-edits-new inputedit testdiv" type="email" [(ngModel)]="vcemailstring" nz-input/>
          </nz-input-group>
        </div>

<!--        <div class="example-full-width form-field-spacing-contact">-->
<!--          <mat-form-field class="selector-full-width" appearance="fill">-->
<!--            <mat-label>{{ captionvcemail }}</mat-label>-->
<!--            <input type="email" matInput [formControl]="vcemailContolr"/>-->
<!--          </mat-form-field>-->
<!--        </div>-->

        <div class="form-field-spacing-contact">
          <label class="line1-edits-new inputlabel">{{ captiontaxid }}</label>
          <nz-input-group nzSearch>
            <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="taxidstring" nz-input/>
          </nz-input-group>
        </div>

<!--        <div class="example-full-width form-field-spacing-contact">-->
<!--          <mat-form-field class="selector-full-width" appearance="fill">-->
<!--            <mat-label>{{ captiontaxid }}</mat-label>-->
<!--            <input type="email" matInput [formControl]="taxidContorl"/>-->
<!--          </mat-form-field>-->
<!--        </div>-->
      </div>
      <mat-divider class="black-divider-contact"></mat-divider>
      <!--      <p class="caption-text">Internal</p>-->
      <mat-expansion-panel class="custom-expansion-panel custom-color">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <p class="caption-text">Internal</p>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="full-width-div1-contact">
          <div class="form-field-spacing1">
            <div class="form-field-spacing-contact2">
              <label class="line1-edits-new inputlabel">{{ captionComments }}</label>
              <nz-input-group nzSearch>
                <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="commentsdstring" nz-input/>
              </nz-input-group>
            </div>
<!--            <mat-form-field class="selector-full-width">-->
<!--              <mat-label>{{ captionComments }}</mat-label>-->
<!--              <textarea-->
<!--                matInput-->
<!--                class="no-resize"-->
<!--                [formControl]="commentsControl"-->
<!--              ></textarea>-->
<!--            </mat-form-field>-->
          </div>

          <div class="form-field-spacing1">
            <div class="form-field-spacing-contact2">
              <label class="line1-edits-new inputlabel">{{ captionSideNotes }}</label>
              <nz-input-group nzSearch>
                <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="sidenotesstring" nz-input/>
              </nz-input-group>
            </div>
<!--            <mat-form-field class="selector-full-width">-->
<!--              <mat-label>{{ captionSideNotes }}</mat-label>-->
<!--              <textarea-->
<!--                matInput-->
<!--                class="no-resize"-->
<!--                [formControl]="sidenotesControl"-->
<!--              ></textarea>-->
<!--            </mat-form-field>-->
          </div>
        </div>
      </mat-expansion-panel>
      <mat-divider class="black-divider-contact"></mat-divider>

      <mat-expansion-panel class="custom-expansion-panel custom-color">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <p class="caption-text">Professional Information</p>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="full-width-div1-contact">
          <div class="example-full-width form-field-spacing-contact1">
            <div class="form-field-spacing-contact2">
              <label class="line1-edits-new inputlabel">{{ captionJobTitle }}</label>
              <nz-input-group nzSearch>
                <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="jobtitlestring" nz-input/>
              </nz-input-group>
            </div>
<!--            <mat-form-field class="selector-full-width" appearance="fill">-->
<!--              <mat-label>{{ captionJobTitle }}</mat-label>-->
<!--              <input matInput [formControl]="jobtitleControl"/>-->
<!--            </mat-form-field>-->
          </div>

          <div class="example-full-width form-field-spacing-contact1">
            <label class="line1-edits-new inputlabel">{{ captionCategory }}</label>
            <nz-input-group [nzStatus]="statuscategory" nzSearch [nzAddOnAfter]="categorybutton">
              <nz-select nzShowSearch [nzShowArrow]="true" class="line1-edits-new inputedit testdiv"
                [(ngModel)]="categorystring">
                <nz-option *ngFor="let option of (iContCategoryResult[0]?.data ?? [])" [nzLabel]="option.description"
                  [nzValue]="option.description"></nz-option>
              </nz-select>
            </nz-input-group>
            <ng-template #categorybutton>
              <button type="button" (click)="add($event, 1, null)" nz-button nzType="default" nzSearch><span nz-icon
                nzType="plus"></span></button>
            </ng-template>
<!--            <mat-form-field class="selector-full-width" appearance="fill">-->
<!--              <mat-label>{{ captionCategory }}</mat-label>-->
<!--              <input-->
<!--                type="text"-->
<!--                matInput-->
<!--                placeholder="Select or type..."-->
<!--                [matAutocomplete]="auto15"-->
<!--                [formControl]="contCategoryFormControl"-->
<!--                #autoInput15="matAutocompleteTrigger"-->
<!--              />-->
<!--              <button-->
<!--                mat-stroked-button-->
<!--                type="button"-->
<!--                class="add-button-contact"-->
<!--                matSuffix-->
<!--                (click)="add($event, 1, autoInput15)"-->
<!--              >-->
<!--                <mat-icon class="center-icon">add</mat-icon>-->
<!--              </button>-->
<!--              <mat-autocomplete #auto15="matAutocomplete">-->
<!--                <mat-option-->
<!--                  *ngFor="let item of filteredContCategoryOptions | async"-->
<!--                  [value]="item"-->
<!--                >-->
<!--                  {{ item }}-->
<!--                </mat-option>-->
<!--              </mat-autocomplete>-->
<!--              <mat-error *ngIf="contCategoryFormControl.hasError('required')">-->
<!--                Category is <strong>required</strong>-->
<!--              </mat-error>-->
<!--            </mat-form-field>-->
          </div>

          <div class="form-field-spacing-contact1">
            <label class="line1-edits-new inputlabel">{{ captionJD }}</label>
            <nz-input-group nzSearch [nzAddOnAfter]="contJDbutton">
              <nz-select nzShowSearch [nzAllowClear]="true" [nzShowArrow]="true" class="line1-edits-new inputedit testdiv"
                [(ngModel)]="contJDstring">
                <nz-option *ngFor="let option of (iContJDResult[0]?.data ?? [])" [nzLabel]="option.description"
                  [nzValue]="option.description"></nz-option>
              </nz-select>
            </nz-input-group>
            <ng-template #contJDbutton>
              <button type="button" (click)="add($event, 2, null)" nz-button nzType="default" nzSearch><span nz-icon
                nzType="plus"></span></button>
            </ng-template>
<!--            <mat-form-field class="selector-full-width" appearance="fill">-->
<!--              <mat-label>{{ captionJD }}</mat-label>-->
<!--              <input-->
<!--                type="text"-->
<!--                matInput-->
<!--                placeholder="Select or type..."-->
<!--                [matAutocomplete]="auto16"-->
<!--                [formControl]="contJDControl"-->
<!--                #autoInput16="matAutocompleteTrigger"-->
<!--              />-->
<!--              <button-->
<!--                mat-stroked-button-->
<!--                type="button"-->
<!--                class="add-button-contact"-->
<!--                matSuffix-->
<!--                (click)="add($event, 2, autoInput16)"-->
<!--              >-->
<!--                <mat-icon class="center-icon">add</mat-icon>-->
<!--              </button>-->
<!--              <mat-autocomplete #auto16="matAutocomplete">-->
<!--                <mat-option-->
<!--                  *ngFor="let item of filteredContJDOptions | async"-->
<!--                  [value]="item"-->
<!--                >-->
<!--                  {{ item }}-->
<!--                </mat-option>-->
<!--              </mat-autocomplete>-->
<!--            </mat-form-field>-->
          </div>

          <div class="form-field-spacing-contact1">
            <label class="line1-edits-new inputlabel">{{ captionType }}</label>
            <nz-input-group nzSearch [nzAddOnAfter]="contTypebutton">
              <nz-select nzShowSearch [nzAllowClear]="true" [nzShowArrow]="true" class="line1-edits-new inputedit testdiv"
                [(ngModel)]="contTypestring">
                <nz-option *ngFor="let option of (iContTypeResult[0]?.data ?? [])" [nzLabel]="option.description"
                  [nzValue]="option.description"></nz-option>
              </nz-select>
            </nz-input-group>
            <ng-template #contTypebutton>
              <button type="button" (click)="add($event, 3, null)" nz-button nzType="default" nzSearch><span nz-icon
                nzType="plus"></span></button>
            </ng-template>
<!--            <mat-form-field class="selector-full-width" appearance="fill">-->
<!--              <mat-label>{{ captionType }}</mat-label>-->
<!--              <input-->
<!--                type="text"-->
<!--                matInput-->
<!--                placeholder="Select or type..."-->
<!--                [matAutocomplete]="auto17"-->
<!--                [formControl]="contTypeControl"-->
<!--                #autoInput17="matAutocompleteTrigger"-->
<!--              />-->
<!--              <button-->
<!--                mat-stroked-button-->
<!--                type="button"-->
<!--                class="add-button-contact"-->
<!--                matSuffix-->
<!--                (click)="add($event, 3, autoInput17)"-->
<!--              >-->
<!--                <mat-icon class="center-icon">add</mat-icon>-->
<!--              </button>-->
<!--              <mat-autocomplete #auto17="matAutocomplete">-->
<!--                <mat-option-->
<!--                  *ngFor="let item of filteredContTypeOptions | async"-->
<!--                  [value]="item"-->
<!--                >-->
<!--                  {{ item }}-->
<!--                </mat-option>-->
<!--              </mat-autocomplete>-->
<!--            </mat-form-field>-->
          </div>

          <div class="form-field-spacing-contact1">
            <label class="line1-edits-new inputlabel">{{ captionRegion }}</label>
            <nz-input-group nzSearch [nzAddOnAfter]="regionbutton">
              <nz-select nzShowSearch [nzAllowClear]="true" [nzShowArrow]="true" class="line1-edits-new inputedit testdiv"
                [(ngModel)]="regionstring">
                <nz-option *ngFor="let option of (iContRegionResult[0]?.data ?? [])" [nzLabel]="option.description"
                  [nzValue]="option.description"></nz-option>
              </nz-select>
            </nz-input-group>
            <ng-template #regionbutton>
              <button type="button" (click)="add($event, 4, null)" nz-button nzType="default" nzSearch><span nz-icon
                nzType="plus"></span></button>
            </ng-template>
<!--            <mat-form-field class="selector-full-width" appearance="fill">-->
<!--              <mat-label>{{ captionRegion }}</mat-label>-->
<!--              <input-->
<!--                type="text"-->
<!--                matInput-->
<!--                placeholder="Select or type..."-->
<!--                [matAutocomplete]="auto18"-->
<!--                [formControl]="contRegionControl"-->
<!--                #autoInput18="matAutocompleteTrigger"-->
<!--              />-->
<!--              <button-->
<!--                mat-stroked-button-->
<!--                type="button"-->
<!--                class="add-button-contact"-->
<!--                matSuffix-->
<!--                (click)="add($event, 4, autoInput18)"-->
<!--              >-->
<!--                <mat-icon class="center-icon">add</mat-icon>-->
<!--              </button>-->
<!--              <mat-autocomplete #auto18="matAutocomplete">-->
<!--                <mat-option-->
<!--                  *ngFor="let item of filteredContRegionOptions | async"-->
<!--                  [value]="item"-->
<!--                >-->
<!--                  {{ item }}-->
<!--                </mat-option>-->
<!--              </mat-autocomplete>-->
<!--            </mat-form-field>-->
          </div>

          <div class="form-field-spacing-contact1">
            <label class="line1-edits-new inputlabel">{{ captionRB }}</label>
            <nz-input-group nzSearch [nzAddOnAfter]="rbbutton">
              <nz-select nzShowSearch [nzAllowClear]="true" [nzShowArrow]="true" class="line1-edits-new inputedit testdiv"
                [(ngModel)]="rbstring">
                <nz-option *ngFor="let option of (iContRBResult[0]?.data ?? [])" [nzLabel]="option.description"
                  [nzValue]="option.description"></nz-option>
              </nz-select>
            </nz-input-group>
            <ng-template #rbbutton>
              <button type="button" (click)="add($event, 5, null)" nz-button nzType="default" nzSearch><span nz-icon
                nzType="plus"></span></button>
            </ng-template>
<!--            <mat-form-field class="selector-full-width" appearance="fill">-->
<!--              <mat-label>{{ captionRB }}</mat-label>-->
<!--              <input-->
<!--                type="text"-->
<!--                matInput-->
<!--                placeholder="Select or type..."-->
<!--                [matAutocomplete]="auto19"-->
<!--                [formControl]="contRBControl"-->
<!--                #autoInput19="matAutocompleteTrigger"-->
<!--              />-->
<!--              <button-->
<!--                mat-stroked-button-->
<!--                type="button"-->
<!--                class="add-button-contact"-->
<!--                matSuffix-->
<!--                (click)="add($event, 5, autoInput19)"-->
<!--              >-->
<!--                <mat-icon class="center-icon">add</mat-icon>-->
<!--              </button>-->
<!--              <mat-autocomplete #auto19="matAutocomplete">-->
<!--                <mat-option-->
<!--                  *ngFor="let item of filteredContRBOptions | async"-->
<!--                  [value]="item"-->
<!--                >-->
<!--                  {{ item }}-->
<!--                </mat-option>-->
<!--              </mat-autocomplete>-->
<!--            </mat-form-field>-->
          </div>
        </div>
      </mat-expansion-panel>

      <mat-divider class="black-divider-contact"></mat-divider>

      <mat-expansion-panel class="custom-expansion-panel custom-color">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <p class="caption-text">Associated And Groups</p>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="full-width-div1-contact">
          <div class="form-field-spacing-contact1">
            <label class="line1-edits-new inputlabel">{{ captionAssociated }}</label>
            <nz-input-group nzSearch [nzAddOnAfter]="associatedbutton">
              <nz-select nzShowSearch [nzAllowClear]="true" [nzShowArrow]="true" class="line1-edits-new inputedit testdiv"
                [(ngModel)]="associatedstring">
                <nz-option *ngFor="let option of (iContACResult[0]?.data ?? [])" [nzLabel]="option.description"
                  [nzValue]="option.description"></nz-option>
              </nz-select>
            </nz-input-group>
            <ng-template #associatedbutton>
              <button type="button" (click)="add($event, 6, null)" nz-button nzType="default" nzSearch><span nz-icon
                nzType="plus"></span></button>
            </ng-template>
<!--            <mat-form-field class="selector-full-width" appearance="fill">-->
<!--              <mat-label>{{ captionAssociated }}</mat-label>-->
<!--              <input-->
<!--                type="text"-->
<!--                matInput-->
<!--                placeholder="Select or type..."-->
<!--                [matAutocomplete]="auto20"-->
<!--                [formControl]="contACControl"-->
<!--                #autoInput20="matAutocompleteTrigger"-->
<!--              />-->
<!--              <button-->
<!--                mat-stroked-button-->
<!--                type="button"-->
<!--                class="add-button-contact"-->
<!--                matSuffix-->
<!--                (click)="add($event, 6, autoInput20)"-->
<!--              >-->
<!--                <mat-icon class="center-icon">add</mat-icon>-->
<!--              </button>-->
<!--              <mat-autocomplete #auto20="matAutocomplete">-->
<!--                <mat-option-->
<!--                  *ngFor="let item of filteredContACOptions | async"-->
<!--                  [value]="item"-->
<!--                >-->
<!--                  {{ item }}-->
<!--                </mat-option>-->
<!--              </mat-autocomplete>-->
<!--            </mat-form-field>-->
          </div>

          <div class="form-field-spacing-contact1">
            <label class="line1-edits-new inputlabel">{{ captionGroups }}</label>
            <nz-input-group nzSearch [nzAddOnAfter]="groupsbutton">
              <nz-select nzShowSearch [nzAllowClear]="true" [nzShowArrow]="true" class="line1-edits-new inputedit testdiv"
                [(ngModel)]="groupsstring">
                <nz-option *ngFor="let option of (iContCGResult[0]?.data ?? [])" [nzLabel]="option.description"
                  [nzValue]="option.description"></nz-option>
              </nz-select>
            </nz-input-group>
            <ng-template #groupsbutton>
              <button type="button" (click)="add($event, 7, null)" nz-button nzType="default" nzSearch><span nz-icon
                nzType="plus"></span></button>
            </ng-template>
<!--            <mat-form-field class="selector-full-width" appearance="fill">-->
<!--              <mat-label>{{ captionGroups }}</mat-label>-->
<!--              <input-->
<!--                type="text"-->
<!--                matInput-->
<!--                placeholder="Select or type..."-->
<!--                [matAutocomplete]="auto21"-->
<!--                [formControl]="contCGControl"-->
<!--                #autoInput21="matAutocompleteTrigger"-->
<!--              />-->
<!--              <button-->
<!--                mat-stroked-button-->
<!--                type="button"-->
<!--                class="add-button-contact"-->
<!--                matSuffix-->
<!--                (click)="add($event, 7, autoInput21)"-->
<!--              >-->
<!--                <mat-icon class="center-icon">add</mat-icon>-->
<!--              </button>-->
<!--              <mat-autocomplete #auto21="matAutocomplete">-->
<!--                <mat-option-->
<!--                  *ngFor="let item of filteredContCGOptions | async"-->
<!--                  [value]="item"-->
<!--                >-->
<!--                  {{ item }}-->
<!--                </mat-option>-->
<!--              </mat-autocomplete>-->
<!--            </mat-form-field>-->
          </div>
        </div>
      </mat-expansion-panel>

      <mat-divider class="black-divider-contact"></mat-divider>

      <mat-expansion-panel
        *ngFor="let field of fieldscount; let i = index"
        class="custom-expansion-panel custom-color"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            <p class="caption-text">Custom Field</p>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="full-width-div1-contact">
          <mat-form-field class="selector-full-width" appearance="fill">
            <mat-label>{{ field.fieldlabel }}</mat-label>

            <input
              *ngIf="field.fieldtype === 1"
              matInput
              [required]="field.requered"
              [id]="field.fieldlabel"
            />

            <input
              *ngIf="field.fieldtype === 7"
              matInput
              type="number"
              [min]="field.minnumber"
              [id]="field.fieldlabel"
              [max]="field.maxnumber > 0 ? field.maxnumber : null"
              [value]="field.minnumber.toString()"
              [required]="field.requered"
              [step]="field.isfloat ? field.step : null"
            />

            <input
              *ngIf="field.fieldtype === 3"
              matInput
              [matDatepicker]="picker"
              [required]="field.requered"
              [id]="field.fieldlabel"
            />
            <mat-datepicker-toggle
              matSuffix
              *ngIf="field.fieldtype === 3"
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>

            <input
              *ngIf="field.fieldtype === 2"
              type="text"
              matInput
              placeholder="Select or type..."
              [matAutocomplete]="getAutoComplete(14 + i)"
              [required]="field.requered"
              [id]="field.fieldlabel"
              #autoInput12="matAutocompleteTrigger"
            />

            <button
              *ngIf="field.fieldtype === 2"
              mat-stroked-button
              type="button"
              class="add-button"
              matSuffix
              (click)="customadd($event)"
            >
              <mat-icon class="center-icon">add</mat-icon>
            </button>

            <mat-autocomplete
              *ngIf="field.fieldtype === 2"
              #auto{{field.id}}="matAutocomplete"
            >
              <mat-option
                *ngFor="
                  let item of getFilteredCCFVOptions(field.fieldlabel) | async
                "
                [value]="item"
              >
                {{ item }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </mat-expansion-panel>

      <mat-divider
        *ngFor="let field of fieldscount; let i = index"
        class="black-divider-contact"
      ></mat-divider>

      <mat-expansion-panel class="custom-expansion-panel custom-color">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <p class="caption-text">Social Media</p>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="full-width-div1-contact">
          <div class="example-full-width form-field-spacing-contact1">
            <label class="line1-edits-new inputlabel">{{ captionInstagram }}</label>
            <nz-input-group nzSearch>
              <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="instagramstring" nz-input/>
            </nz-input-group>
<!--            <mat-form-field class="selector-full-width" appearance="fill">-->
<!--              <mat-label>{{ captionInstagram }}</mat-label>-->
<!--              <input matInput [formControl]="InstagraControl"/>-->
<!--            </mat-form-field>-->
          </div>

          <div class="example-full-width form-field-spacing-contact1">
            <label class="line1-edits-new inputlabel">{{ captionFacebook }}</label>
            <nz-input-group nzSearch>
              <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="facebookstring" nz-input/>
            </nz-input-group>
<!--            <mat-form-field class="selector-full-width" appearance="fill">-->
<!--              <mat-label>{{ captionFacebook }}</mat-label>-->
<!--              <input matInput [formControl]="FacebookControl"/>-->
<!--            </mat-form-field>-->
          </div>

          <div class="example-full-width form-field-spacing-contact1">
            <label class="line1-edits-new inputlabel">{{ captionTiktok }}</label>
            <nz-input-group nzSearch>
              <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="tiktokstring" nz-input/>
            </nz-input-group>
<!--            <mat-form-field class="selector-full-width" appearance="fill">-->
<!--              <mat-label>{{ captionTiktok }}</mat-label>-->
<!--              <input matInput [formControl]="TiktokControl"/>-->
<!--            </mat-form-field>-->
          </div>

          <div class="example-full-width form-field-spacing-contact1">
            <label class="line1-edits-new inputlabel">{{ captionYoutube }}</label>
            <nz-input-group nzSearch>
              <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="youtubestring" nz-input/>
            </nz-input-group>
<!--            <mat-form-field class="selector-full-width" appearance="fill">-->
<!--              <mat-label>{{ captionYoutube }}</mat-label>-->
<!--              <input matInput [formControl]="YoutubeControl"/>-->
<!--            </mat-form-field>-->
          </div>

          <div class="example-full-width form-field-spacing-contact1">
            <label class="line1-edits-new inputlabel">{{ captionTwitter }}</label>
            <nz-input-group nzSearch>
              <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="twitterstring" nz-input/>
            </nz-input-group>
<!--            <mat-form-field class="selector-full-width" appearance="fill">-->
<!--              <mat-label>{{ captionTwitter }}</mat-label>-->
<!--              <input matInput [formControl]="TwitterControl"/>-->
<!--            </mat-form-field>-->
          </div>

          <div class="example-full-width form-field-spacing-contact1">
            <label class="line1-edits-new inputlabel">{{ captionLinkedln }}</label>
            <nz-input-group nzSearch>
              <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="linkedinstring" nz-input/>
            </nz-input-group>
<!--            <mat-form-field class="selector-full-width" appearance="fill">-->
<!--              <mat-label>{{ captionLinkedln }}</mat-label>-->
<!--              <input matInput [formControl]="LinkedlnControl"/>-->
<!--            </mat-form-field>-->
          </div>
        </div>
      </mat-expansion-panel>

      <!--      <mat-divider class="black-divider-contact"></mat-divider>-->
      <!--      <p class="caption-text">Primary Address</p>-->
      <!--      <mat-radio-group aria-label="Select an option">-->
      <!--        <mat-radio-button value="1" checked="true">Company Information</mat-radio-button>-->
      <!--        <mat-radio-button value="2">Home Information</mat-radio-button>-->
      <!--        <mat-radio-button value="3">Other Address Information</mat-radio-button>-->
      <!--      </mat-radio-group>-->
      <mat-divider class="black-divider-contact"></mat-divider>
      <mat-expansion-panel class="custom-expansion-panel custom-color">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <p class="caption-text">Company Information</p>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="full-width-div1-contact">
          <div class="form-field-spacing-contact1">
            <label class="line1-edits-new inputlabel">{{ captionCompanyName }}</label>
            <nz-input-group nzSearch [nzAddOnAfter]="companybutton">
              <nz-select nzShowSearch [nzAllowClear]="true" [nzShowArrow]="true" class="line1-edits-new inputedit testdiv"
                (ngModelChange)="onCompanySelect($event)"
                [(ngModel)]="companystring">
                <nz-option *ngFor="let option of (iCompanyResult[0]?.data ?? [])" [nzLabel]="option.companyname"
                  [nzValue]="option.companyname"></nz-option>
              </nz-select>
            </nz-input-group>
            <ng-template #companybutton>
              <button type="button" (click)="addCompany($event, 0, null)" nz-button nzType="default" nzSearch><span nz-icon
                nzType="plus"></span></button>
            </ng-template>
<!--            <mat-form-field class="selector-full-width" appearance="fill">-->
<!--              <mat-label>{{ captionCompanyName }}</mat-label>-->
<!--              <input type="text" matInput placeholder="Select or type..." [matAutocomplete]="auto22"-->
<!--                [formControl]="companynameControl" #autoInput22="matAutocompleteTrigger"-->
<!--              />-->
<!--              <button mat-stroked-button type="button" class="add-button-contact" matSuffix-->
<!--                (click)="addCompany($event, 0, autoInput22)">-->
<!--                <mat-icon class="center-icon">add</mat-icon>-->
<!--              </button>-->
<!--              <mat-autocomplete #auto22="matAutocomplete"-->
<!--                (optionSelected)="onCompanySelect($event)">-->
<!--                <mat-option-->
<!--                  *ngFor="let item of filteredCompanyNameOptions | async"-->
<!--                  [value]="item">-->
<!--                  {{ item }}-->
<!--                </mat-option>-->
<!--              </mat-autocomplete>-->
<!--            </mat-form-field>-->
          </div>

          <div class="example-full-width form-field-spacing-contact1">
            <label class="line1-edits-new inputlabel">{{ captionZipCode }}</label>
            <nz-input-group nzSearch>
              <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="zipcodestring" nz-input/>
            </nz-input-group>
<!--            <mat-form-field class="selector-full-width" appearance="fill">-->
<!--              <mat-label>{{ captionZipCode }}</mat-label>-->
<!--              <input matInput [formControl]="zipcodeControl"/>-->
<!--            </mat-form-field>-->
          </div>

          <div class="example-full-width form-field-spacing-contact1">
            <label class="line1-edits-new inputlabel">{{ captionCareOff }}</label>
            <nz-input-group nzSearch>
              <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="careoffstring" nz-input/>
            </nz-input-group>
<!--            <mat-form-field class="selector-full-width" appearance="fill">-->
<!--              <mat-label>{{ captionCareOff }}</mat-label>-->
<!--              <input matInput [formControl]="careoffControl"/>-->
<!--            </mat-form-field>-->
          </div>

          <div class="form-field-spacing-contact1">
            <label class="line1-edits-new inputlabel">{{ captionCountri }}</label>
            <nz-input-group nzSearch >
              <nz-select nzShowSearch [nzShowArrow]="true" class="line1-edits-new inputedit testdiv"
                (ngModelChange)="onOptionSelected($event)"
                [(ngModel)]="countrystring">
                <nz-option *ngFor="let option of (countries ?? [])" [nzLabel]="option['country']"
                  [nzValue]="option['country']"></nz-option>
              </nz-select>
            </nz-input-group>
<!--            <mat-form-field class="selector-full-width" appearance="fill">-->
<!--              <mat-label>{{ captionCountri }}</mat-label>-->
<!--              <input-->
<!--                type="text"-->
<!--                matInput-->
<!--                placeholder="Select or type..."-->
<!--                [matAutocomplete]="auto23"-->
<!--                [formControl]="countriControl"-->
<!--              />-->
<!--              <mat-autocomplete-->
<!--                #auto23="matAutocomplete"-->
<!--                (optionSelected)="onOptionSelected($event)"-->
<!--              >-->
<!--                <mat-option-->
<!--                  *ngFor="let item of filteredCountries | async"-->
<!--                  [value]="item.country"-->
<!--                >-->
<!--                  {{ item.country }}-->
<!--                </mat-option>-->
<!--              </mat-autocomplete>-->
<!--            </mat-form-field>-->
          </div>

          <div class="example-full-width form-field-spacing-contact1">
            <label class="line1-edits-new inputlabel">{{ captionAddress1 }}</label>
            <nz-input-group nzSearch>
              <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="address1string" nz-input/>
            </nz-input-group>
<!--            <mat-form-field class="selector-full-width" appearance="fill">-->
<!--              <mat-label>{{ captionAddress1 }}</mat-label>-->
<!--              <input matInput [formControl]="address1Control"/>-->
<!--            </mat-form-field>-->
          </div>

          <div class="example-full-width form-field-spacing-contact1">
            <label class="line1-edits-new inputlabel">{{ captionDirectPhone }}</label>
            <nz-input-group nzSearch>
              <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="directphonestring" nz-input/>
            </nz-input-group>
<!--            <mat-form-field class="selector-full-width" appearance="fill">-->
<!--              <mat-label>{{ captionDirectPhone }}</mat-label>-->
<!--              <input matInput [formControl]="directPhoneControl"/>-->
<!--            </mat-form-field>-->
          </div>

          <div class="example-full-width form-field-spacing-contact1">
            <label class="line1-edits-new inputlabel">{{ captionAddress2 }}</label>
            <nz-input-group nzSearch>
              <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="address2string" nz-input/>
            </nz-input-group>
<!--            <mat-form-field class="selector-full-width" appearance="fill">-->
<!--              <mat-label>{{ captionAddress2 }}</mat-label>-->
<!--              <input matInput [formControl]="address2Control"/>-->
<!--            </mat-form-field>-->
          </div>

          <div class="example-full-width form-field-spacing-contact1">
            <label class="line1-edits-new inputlabel">{{ captionWebsite }}</label>
            <nz-input-group nzSearch>
              <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="websitestring" nz-input/>
            </nz-input-group>
<!--            <mat-form-field class="selector-full-width" appearance="fill">-->
<!--              <mat-label>{{ captionWebsite }}</mat-label>-->
<!--              <input matInput [formControl]="websiteControl"/>-->
<!--            </mat-form-field>-->
          </div>

          <div class="example-full-width form-field-spacing-contact1">
            <label class="line1-edits-new inputlabel">{{ captionCity }}</label>
            <nz-input-group nzSearch>
              <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="citystring" nz-input/>
            </nz-input-group>
<!--            <mat-form-field class="selector-full-width" appearance="fill">-->
<!--              <mat-label>{{ captionCity }}</mat-label>-->
<!--              <input matInput [formControl]="cityControl"/>-->
<!--            </mat-form-field>-->
          </div>

          <div class="example-full-width form-field-spacing-contact1">
            <label class="line1-edits-new inputlabel">{{ captioncontemail }}</label>
            <nz-input-group nzSearch>
              <input class="line1-edits-new inputedit testdiv" type="email" [(ngModel)]="emailrstring" nz-input/>
            </nz-input-group>
<!--            <mat-form-field class="selector-full-width" appearance="fill">-->
<!--              <mat-label>{{ captioncontemail }}</mat-label>-->
<!--              <input type="email" matInput [formControl]="emailContolr"/>-->
<!--            </mat-form-field>-->
          </div>

          <div class="form-field-spacing-contact1">
            <label class="line1-edits-new inputlabel">{{ captionState }}</label>
            <nz-input-group nzSearch >
              <nz-select nzShowSearch [nzShowArrow]="true" class="line1-edits-new inputedit testdiv"
                [(ngModel)]="statesstring">
                <nz-option *ngFor="let option of (states ?? [])" [nzLabel]="option['state']"
                  [nzValue]="option['state']"></nz-option>
              </nz-select>
            </nz-input-group>
<!--            <mat-form-field class="selector-full-width" appearance="fill">-->
<!--              <mat-label>{{ captionState }}</mat-label>-->
<!--              <input-->
<!--                type="text"-->
<!--                matInput-->
<!--                placeholder="Select or type..."-->
<!--                [matAutocomplete]="auto24"-->
<!--                [formControl]="stateControl"-->
<!--              />-->
<!--              <mat-autocomplete #auto24="matAutocomplete">-->
<!--                <mat-option-->
<!--                  *ngFor="let item of filteredStates | async"-->
<!--                  [value]="item.state"-->
<!--                >-->
<!--                  {{ item.state }}-->
<!--                </mat-option>-->
<!--              </mat-autocomplete>-->
<!--            </mat-form-field>-->
          </div>

          <div class="example-full-width form-field-spacing-contact1">
            <label class="line1-edits-new inputlabel">{{ captioncontotheremail }}</label>
            <nz-input-group nzSearch>
              <input class="line1-edits-new inputedit testdiv" type="email" [(ngModel)]="otheremailstring" nz-input/>
            </nz-input-group>
<!--            <mat-form-field class="selector-full-width" appearance="fill">-->
<!--              <mat-label>{{ captioncontotheremail }}</mat-label>-->
<!--              <input type="email" matInput [formControl]="otherEmailControl"/>-->
<!--            </mat-form-field>-->
          </div>
        </div>
      </mat-expansion-panel>

      <mat-divider class="black-divider-contact"></mat-divider>

      <mat-expansion-panel class="custom-expansion-panel custom-color">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <p class="caption-text">Other Address Information</p>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="black-background">
          <button type="button" mat-icon-button (click)="showAddEditFrame(0, null)">
            <mat-icon>add</mat-icon>
          </button>
        </div>

        <div class="black-background2">

          <div class="card">
            <p-table
              [value]="firstAddressData"
              [paginator]="true"
              [rows]="5"
              [showCurrentPageReport]="true"

              [rowsPerPageOptions]="[5, 10, 20, 50, 100]"
              [styleClass]="'p-datatable-striped mat-elevation-z3 ' + selectedSize.class.toString()"
              selectionMode="single"
              [tableStyle]="{'min-width': '10rem'}">
              <ng-template pTemplate="header">
                <tr>
                  <!--                  <th style="width:1%">No.</th>-->
                  <th style="width:20%">Description</th>
                  <th style="width:10%">Options</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-product>
                <tr [pSelectableRow]="product">
                  <!--                  <td>{{product["id"]}}</td>-->
                  <td>{{ product["description"] }}</td>
                  <td>
                    <p-button label="Edit"
                      size="small" [text]="true" severity="success"
                      (click)="showAddEditFrame(1,product,product.id,product.description)">
                    </p-button>

                    <p-button label="Delete"
                      size="small" [text]="true" severity="danger"
                      (click)="deleterecord(product.id)">

                    </p-button>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </mat-expansion-panel>

      <mat-divider class="black-divider-contact"></mat-divider>

      <mat-expansion-panel class="custom-expansion-panel custom-color">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <p class="caption-text">Credit card information</p>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="full-width-div2-contact">
          <div class="example-full-width form-field-spacing-contact1">
            <label class="line1-edits-new inputlabel">Cardholder name</label>
            <nz-input-group nzSearch>
              <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="cardnamestring" nz-input/>
            </nz-input-group>
<!--            <mat-form-field class="selector-full-width" appearance="fill">-->
<!--              <mat-label>Cardholder name</mat-label>-->
<!--              <input matInput [formControl]="cardNameControl"/>-->
<!--            </mat-form-field>-->
          </div>

          <div class="example-full-width form-field-spacing-contact1">
            <label class="line1-edits-new inputlabel">Card number</label>
            <nz-input-group nzSearch>
              <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="cardnumberstring" nz-input/>
            </nz-input-group>
<!--            <mat-form-field class="selector-full-width" appearance="fill">-->
<!--              <mat-label>Card number</mat-label>-->
<!--              <input matInput [formControl]="cardNumberControl"/>-->
<!--            </mat-form-field>-->
          </div>
        </div>

        <div class="full-width-div1-contact">
          <div class="example-full-width form-field-spacing-contact1">
            <label class="line1-edits-new inputlabel">Expiration date</label>
            <nz-input-group nzSearch>
              <nz-date-picker
                class="line1-edits-new inputedit testdiv"
                nzMode="month"
                [(ngModel)]="expirationdate"
                nzPlaceHolder="MM/YYYY"
                nzFormat="MM/YYYY"
                (ngModelChange)="onDateChange($event)">
              </nz-date-picker>
<!--              <input class="line1-edits-new inputedit testdiv" type="" [(ngModel)]="cardnumberstring" nz-input/>-->
            </nz-input-group>
<!--            <mat-form-field class="selector-full-width" appearance="fill">-->
<!--              <mat-label>Expiration date</mat-label>-->
<!--              <input matInput [formControl]="expirationDateControl" appCustomMask placeholder="00/0000"/>-->
<!--            </mat-form-field>-->
          </div>

          <div class="example-full-width form-field-spacing-contact1">
            <label class="line1-edits-new inputlabel">CVC Code</label>
            <nz-input-group nzSearch>
              <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="cvcodestring" nz-input/>
            </nz-input-group>
<!--            <mat-form-field class="selector-full-width" appearance="fill">-->
<!--              <mat-label>CVC Code</mat-label>-->
<!--              <input matInput [formControl]="cvcCodeControl"/>-->
<!--            </mat-form-field>-->
          </div>
        </div>
        <div class="full-width-div2-contact divbilling">
          <span class="divbillingtext">Billing Address</span>
        </div>

        <div class="full-width-div2-contact">
          <div class="full-width-div1-contact">

            <div class="example-full-width form-field-spacing-contact1">
              <label class="line1-edits-new inputlabel">{{ captionAddress1 }}</label>
              <nz-input-group nzSearch>
                <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="address12string" nz-input/>
              </nz-input-group>
<!--              <mat-form-field class="selector-full-width" appearance="fill">-->
<!--                <mat-label>Address1</mat-label>-->
<!--                <input matInput [formControl]="address12Control"/>-->
<!--              </mat-form-field>-->
            </div>

            <div class="form-field-spacing-contact1">
<!--              <mat-form-field class="selector-full-width" appearance="fill">-->
<!--                <mat-label>State</mat-label>-->
<!--                <input-->
<!--                  type="text"-->
<!--                  matInput-->
<!--                  placeholder="Select or type..."-->
<!--                  [matAutocomplete]="auto28"-->
<!--                  [formControl]="state2Control_1"-->
<!--                />-->
<!--                <mat-autocomplete #auto28="matAutocomplete">-->
<!--                  <mat-option-->
<!--                    *ngFor="let item of filteredStates | async"-->
<!--                    [value]="item.state"-->
<!--                  >-->
<!--                    {{ item.state }}-->
<!--                  </mat-option>-->
<!--                </mat-autocomplete>-->
<!--              </mat-form-field>-->
              <label class="line1-edits-new inputlabel">{{ captionState }}</label>
              <nz-input-group nzSearch >
                <nz-select nzShowSearch [nzShowArrow]="true" class="line1-edits-new inputedit testdiv"
                  [(ngModel)]="states2string">
                  <nz-option *ngFor="let option of (statesh ?? [])" [nzLabel]="option['state']"
                    [nzValue]="option['state']"></nz-option>
                </nz-select>
              </nz-input-group>
            </div>

            <div class="example-full-width form-field-spacing-contact1">
              <label class="line1-edits-new inputlabel">{{ captionAddress2 }}</label>
              <nz-input-group nzSearch>
                <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="address22string" nz-input/>
              </nz-input-group>
<!--              <mat-form-field class="selector-full-width" appearance="fill">-->
<!--                <mat-label>Address2</mat-label>-->
<!--                <input matInput [formControl]="address22Control"/>-->
<!--              </mat-form-field>-->
            </div>

            <div class="example-full-width form-field-spacing-contact1">
              <label class="line1-edits-new inputlabel">{{ captionZipCode }}</label>
              <nz-input-group nzSearch>
                <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="zipcode2string" nz-input/>
              </nz-input-group>
<!--              <mat-form-field class="selector-full-width" appearance="fill">-->
<!--                <mat-label>Zip Code</mat-label>-->
<!--                <input matInput [formControl]="zipcode2Control"/>-->
<!--              </mat-form-field>-->
            </div>

            <div class="example-full-width form-field-spacing-contact1">
              <label class="line1-edits-new inputlabel">{{ captionCity }}</label>
              <nz-input-group nzSearch>
                <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="city2string" nz-input/>
              </nz-input-group>
<!--              <mat-form-field class="selector-full-width" appearance="fill">-->
<!--                <mat-label>City</mat-label>-->
<!--                <input matInput [formControl]="city2Control"/>-->
<!--              </mat-form-field>-->
            </div>

            <div class="form-field-spacing-contact1">
              <nz-input-group nzSearch >
                <label class="line1-edits-new inputlabel">{{ captionCountri }}</label>
                <nz-select nzShowSearch [nzShowArrow]="true" class="line1-edits-new inputedit testdiv"
                  (ngModelChange)="onOptionSelectedh($event)"
                  [(ngModel)]="country2string">
                  <nz-option *ngFor="let option of (countriesh ?? [])" [nzLabel]="option['country']"
                    [nzValue]="option['country']"></nz-option>
                </nz-select>
              </nz-input-group>
<!--              <mat-form-field class="selector-full-width" appearance="fill">-->
<!--                <mat-label>Country</mat-label>-->
<!--                <input type="text" matInput placeholder="Select or type..." [matAutocomplete]="auto27"-->
<!--                  [formControl]="countri2Control"/>-->
<!--                <mat-autocomplete #auto27="matAutocomplete" (optionSelected)="onOptionSelected('')">-->
<!--                  <mat-option *ngFor="let item of filteredCountries | async" [value]="item.country">-->
<!--                    {{ item.country }}-->
<!--                  </mat-option>-->
<!--                </mat-autocomplete>-->
<!--              </mat-form-field>-->
            </div>
          </div>
        </div>
      </mat-expansion-panel>

      <mat-divider class="black-divider-contact"></mat-divider>

      <div class="button-container">
        <!--        <button mat-raised-button class="button-settings" type="button" [disabled]="clearAllisDisabled">-->
        <!--          Clear All-->
        <!--        </button>-->
        <!--        <button mat-raised-button class="button-settings" type="submit" *ngIf="isVisible">Create & Add</button>-->
        <button mat-raised-button class="button-settings" type="button" [disabled]="submitIsDisabled" (click)="submit()">
          {{ createbuttoncaption }}
        </button>
      </div>
    </div>
  </div>
