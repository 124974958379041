import { Component } from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";

@Component({
  selector: 'app-deleteframe',
  templateUrl: './deleteframe.component.html',
  styleUrls: ['./deleteframe.component.scss']
})
export class DeleteframeComponent {
  constructor(public modalService: ModalserviceService) {}
}
