import {AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {
  DesignerAccountDetails,
  LbSelectedDesigner, PermissionsSaleDetails, ProfileSale,
  ProfileSaleDetails,
  SelfData,
  TagsListData
} from "../../models/struct";
import {Table} from "primeng/table";
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder} from "@angular/forms";
import {HashService} from "../../services/hash.service";
import {MessageboxComponent} from "../../modals/messagebox/messagebox.component";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {MatSnackBar} from "@angular/material/snack-bar";
import {HttpClient} from "@angular/common/http";
import {forkJoin, Subscription} from "rxjs";
import {NzFormatEmitEvent} from "ng-zorro-antd/tree";

@Component({
  selector: 'app-profileframedialog',
  templateUrl: './profileframedialog.component.html',
  styleUrl: './profileframedialog.component.scss'
})
export class ProfileframedialogComponent implements OnInit, AfterViewInit {
  caption: string = "Add Profile"
  setid: number = 0;
  opermode: number = 0;
  profilesResult: TagsListData[] = []
  selectProfile: string = "";
  sizes!: any[];
  @ViewChild(Table) dataTable?: Table;
  selectedSize: any = ''
  profilename: string = "";
  defaultCheckedKeys: string[] = ['Home'];
  defaultSelectedKeys = [''];
  defaultExpandedKeys = [''];
  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
      ? SelfData.account[0].data[0].id
      : SelfData.account[0].data[0].parentid;
  }
  permissions: Record<string, boolean> = {};

  readonly nodes = [
    {
      title: 'Home',
      key: 'Home',
      expanded: true,
      isLeaf: true
    },
    {
      title: 'Sales',
      key: 'Sales',
      children: [
        {
          title: 'Sell',
          key: 'Sell',
        },
        {
          title: 'Credit',
          key: 'Credit',
        },
        {
          title: 'Rent',
          key: 'Rent',
        },
        {
          title: 'Gift',
          key: 'Gift',
        }
      ]
    },
    {
      title: 'Catalog',
      key: 'Catalog',
      children: [
        { title: 'Products', key: 'Products' },
        { title: 'Suppliers', key: 'Suppliers' }
      ]
    },
    {
      title: 'Inventory',
      key: 'Inventory',
      children: [
        { title: 'Stock Control', key: 'Stock Control' }
      ]
    },
    {
      title: 'Customers',
      key: 'Customers',
      children: [
        { title: 'Customers', key: 'Customers'}
      ]
    },
    {
      title: 'Reporting',
      key: 'Reporting',
      children: [
        { title: 'Sales Report', key: 'Sales Report' }
      ]
    },
    {
      title: 'Settings',
      key: 'Settings',
      children: [
        { title: 'Users', key: 'Users' },
        { title: 'General', key: 'General'},
        { title: 'Outlets And Registers', key: 'Outlets And Registers'}
      ]
    },
    {
      title: 'Precious Metals Prices',
      key: 'Precious Metals Prices',
      isLeaf: true
    }
  ];

  private subscription?: Subscription

  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private hashService: HashService,
    public messageBox: MessageboxComponent,
    private serviceservice: ServiceService,
    private yourappsService: YourappsserviceService,
    private notification: NzNotificationService,
    private _snackBar: MatSnackBar,
    private http: HttpClient,
    private cdr: ChangeDetectorRef
  ) {
    this.sizes = [{ name: "Small", class: "p-datatable-sm" }];
    this.selectedSize = this.sizes[0];
    this.subscription = this.yourappsService.selectedProfileList$.subscribe((value) => {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      if (value?.type === 1) {
        this.caption = "Edit Profile"
        this.opermode = 1
        if (value.data && typeof value.data === 'object') {
          this.setid = (value.data as any).id
          this.profilename = (value.data as any).description
          forkJoin({
            profiles: this.serviceservice.selectPermissions(this.setid),
          }).subscribe(({ profiles }) => {
            this.profilesResult = profiles.data;
            console.log("Полученные данные (profilesResult):", JSON.stringify(this.profilesResult, null, 2));

            // Даем Angular обновить DOM перед изменением checked keys
            setTimeout(() => {
              this.setDefaultCheckedKeys();
              this.cdr.detectChanges(); // Принудительно обновляем Angular
            }, 0);
          });
        }
      }
    })
    // forkJoin({
    //   profiles: this.serviceservice.selectProfiles(this.getAccountID()),
    // }).subscribe(({profiles}) => {
    //   this.profilesResult = profiles.data;
    //   console.log("this.profilesResult", this.profilesResult)
    // });
  }

  setDefaultCheckedKeys(): void {
    if (!this.profilesResult || this.profilesResult.length === 0) {
      console.log("profilesResult пуст, пропускаем установку defaultCheckedKeys");
      return;
    }

    const profile = this.profilesResult[0];

    // Получаем список ключей из дерева в нормализованном виде
    const allTreeKeys = this.getAllTreeKeys(this.nodes).map(k => k.toLowerCase());

    this.defaultCheckedKeys = Object.keys(profile)
      .filter(key => profile[key] === true) // Оставляем только true
      .map(key => this.normalizeKey(key)) // Приводим к формату, как в дереве
      .filter(key => allTreeKeys.includes(key.toLowerCase())); // Проверяем наличие

    console.log("Начально выбранные ключи (defaultCheckedKeys):", this.defaultCheckedKeys);
  }

// Приведение `snake_case` ключей к `PascalCase`, если требуется
  normalizeKey(key: string): string {
    const keyMap: { [key: string]: string } = {
      "home": "Home",
      "sales": "Sales",
      "sell": "Sell",
      "credit": "Credit",
      "rent": "Rent",
      "gift": "Gift",
      "catalog": "Catalog",
      "products": "Products",
      "suppliers": "Suppliers",
      "inventory": "Inventory",
      "stock_control": "Stock Control",
      "customers": "Customers",
      "reporting": "Reporting",
      "sales_report": "Sales Report",
      "settings": "Settings",
      "users": "Users",
      "general": "General",
      "outlets_and_registers": "Outlets And Registers",
      "precious_metals_prices": "Precious Metals Prices"
    };

    return keyMap[key] || key; // Если ключ найден в словаре, заменяем его
  }

// Получаем все ключи из дерева (включая детей)
  getAllTreeKeys(nodes: any[]): string[] {
    let keys: string[] = [];
    nodes.forEach(node => {
      keys.push(node.key);
      if (node.children) {
        keys = keys.concat(this.getAllTreeKeys(node.children));
      }
    });
    console.log("Все ключи из дерева:", keys);
    return keys;
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
  }

  nzEvent(event: NzFormatEmitEvent): void {
    this.defaultCheckedKeys = event.checkedKeys ? event.checkedKeys.map(node => node.key) as string[] : this.defaultCheckedKeys;

    console.log('Выбранные элементы:', this.defaultCheckedKeys);

    // === Обновляем глобальный объект permissions ===
    this.permissions = {
      home: this.defaultCheckedKeys.includes('Home'),
      sales: this.defaultCheckedKeys.includes('Sales') ||
        this.defaultCheckedKeys.includes('Sell') ||
        this.defaultCheckedKeys.includes('Credit') ||
        this.defaultCheckedKeys.includes('Rent') ||
        this.defaultCheckedKeys.includes('Gift'),
      sell: this.defaultCheckedKeys.includes('Sell'),
      credit: this.defaultCheckedKeys.includes('Credit'),
      rent: this.defaultCheckedKeys.includes('Rent'),
      gift: this.defaultCheckedKeys.includes('Gift'),
      catalog: this.defaultCheckedKeys.includes('Catalog') ||
        this.defaultCheckedKeys.includes('Products') ||
        this.defaultCheckedKeys.includes('Suppliers'),
      products: this.defaultCheckedKeys.includes('Products'),
      suppliers: this.defaultCheckedKeys.includes('Suppliers'),
      inventory: this.defaultCheckedKeys.includes('Inventory') ||
        this.defaultCheckedKeys.includes('Stock Control'),
      stock_control: this.defaultCheckedKeys.includes('Stock Control'),
      customers: this.defaultCheckedKeys.includes('Customers'),
      reporting: this.defaultCheckedKeys.includes('Reporting') ||
        this.defaultCheckedKeys.includes('Sales Report'),
      sales_report: this.defaultCheckedKeys.includes('Sales Report'),
      settings: this.defaultCheckedKeys.includes('Settings') ||
        this.defaultCheckedKeys.includes('Users') ||
        this.defaultCheckedKeys.includes('General') ||
        this.defaultCheckedKeys.includes('Outlets And Registers'),
      users: this.defaultCheckedKeys.includes('Users'),
      general: this.defaultCheckedKeys.includes('General'),
      outlets_and_registers: this.defaultCheckedKeys.includes('Outlets And Registers'),
      precious_metals_prices: this.defaultCheckedKeys.includes('Precious Metals Prices')
    };
  }

  onAddProfileClick() {
    let tid: number = this.setid;
    let tctrl: number = this.opermode;
    const profileDetails: ProfileSaleDetails = {
      description: this.profilename,
      accountid: this.getAccountID(),
      ctrl: tctrl,
      isactivity: true,
      id: tid
    };

    this.serviceservice.prcProfileSale(profileDetails).subscribe({
      next: (value: ProfileSale) => {
        console.log(value.data)
        const permissionDetails: PermissionsSaleDetails = {
          ctrl: tctrl,
          id: tid,
          tbl_profile_saleid: value.data, // Укажите значение

          // Явное перечисление значений из this.permissions
          home: this.permissions['home'],
          sales: this.permissions['sales'],
          sell: this.permissions['sell'],
          credit: this.permissions['credit'],
          rent: this.permissions['rent'],
          gift: this.permissions['gift'],
          catalog: this.permissions['catalog'],
          products: this.permissions['products'],
          suppliers: this.permissions['suppliers'],
          inventory: this.permissions['inventory'],
          stock_control: this.permissions['stock_control'],
          customers: this.permissions['customers'],
          reporting: this.permissions['reporting'],
          sales_report: this.permissions['sales_report'],
          settings: this.permissions['settings'],
          users: this.permissions['users'],
          general: this.permissions['general'],
          outlets_and_registers: this.permissions['outlets_and_registers'],
          precious_metals_prices: this.permissions['precious_metals_prices']
        };
        this.serviceservice.prcPermissionsSale(permissionDetails).subscribe({
          next: (value: ProfileSale) => {
            this.modalService.profileframeClose()
            this.yourappsService.getReloadProfileList([], 1)
          },
          error: (error: any) => {
            console.error('Error occurred:', error);
          },
          complete: () => {
            // console.log('Subscription completed');
          }
        });
      },
      error: (error: any) => {
        console.error('Error occurred:', error);
      },
      complete: () => {
        // console.log('Subscription completed');
      }
    });
    console.log("defaultCheckedKeys", this.defaultCheckedKeys)
  }

  nzCheckBoxChange(event: NzFormatEmitEvent): void {
    // Проверяем, существует ли event.checkedKeys, если нет - присваиваем пустой массив
    this.defaultCheckedKeys = event.checkedKeys ? event.checkedKeys.map(node => node.key) as string[] : [];

    console.log('Выбранные элементы:', this.defaultCheckedKeys);

    // === Обновляем глобальный объект permissions ===
    this.permissions = {
      home: this.defaultCheckedKeys.includes('Home'),
      sales: this.defaultCheckedKeys.includes('Sales') ||
        this.defaultCheckedKeys.includes('Sell') ||
        this.defaultCheckedKeys.includes('Credit') ||
        this.defaultCheckedKeys.includes('Rent') ||
        this.defaultCheckedKeys.includes('Gift'),
      sell: this.defaultCheckedKeys.includes('Sell'),
      credit: this.defaultCheckedKeys.includes('Credit'),
      rent: this.defaultCheckedKeys.includes('Rent'),
      gift: this.defaultCheckedKeys.includes('Gift'),
      catalog: this.defaultCheckedKeys.includes('Catalog') ||
        this.defaultCheckedKeys.includes('Products') ||
        this.defaultCheckedKeys.includes('Suppliers'),
      products: this.defaultCheckedKeys.includes('Products'),
      suppliers: this.defaultCheckedKeys.includes('Suppliers'),
      inventory: this.defaultCheckedKeys.includes('Inventory') ||
        this.defaultCheckedKeys.includes('Stock Control'),
      stock_control: this.defaultCheckedKeys.includes('Stock Control'),
      customers: this.defaultCheckedKeys.includes('Customers'),
      reporting: this.defaultCheckedKeys.includes('Reporting') ||
        this.defaultCheckedKeys.includes('Sales Report'),
      sales_report: this.defaultCheckedKeys.includes('Sales Report'),
      settings: this.defaultCheckedKeys.includes('Settings') ||
        this.defaultCheckedKeys.includes('Users') ||
        this.defaultCheckedKeys.includes('General') ||
        this.defaultCheckedKeys.includes('Outlets And Registers'),
      users: this.defaultCheckedKeys.includes('Users'),
      general: this.defaultCheckedKeys.includes('General'),
      outlets_and_registers: this.defaultCheckedKeys.includes('Outlets And Registers'),
      precious_metals_prices: this.defaultCheckedKeys.includes('Precious Metals Prices')
    };

    console.log('Объект разрешений:', this.permissions);
  }

}
