<form [formGroup]="myForm" (ngSubmit)="submit()" autocomplete="off">
  <div class="fullscreen-div">
    <div class="form-container">
      <div class="account-image-container">
        <img
          *ngIf="imageUrl === ''"
          class="account-image1"
          src="assets/account_circle.svg"
          alt="Voz"
        />
        <img
          *ngIf="imageUrl !== ''"
          class="account-image1"
          [src]="imageUrl"
          alt="Avatar"
        />
        <input
          type="file"
          (change)="onFileSelected($event)"
          style="display: none"
          #fileInput
        />
        <button
          mat-icon-button
          type="button"
          class="icon-btn"
          (click)="fileInput.click()"
        >
          <mat-icon>edit</mat-icon>
        </button>
      </div>
      <button
        mat-icon-button
        type="button"
        class="close-btn"
        (click)="modalService.profileeditclose()"
      >
        <mat-icon>close</mat-icon>
      </button>
      <div class="button-container">
        <button
          mat-flat-button
          type="button"
          (click)="modalService.profileeditclose()"
        >
          Cancel
        </button>
        <button mat-flat-button type="submit">OK</button>
      </div>
    </div>
  </div>

  <div class="fullscreen-div1">
    <table class="name-fields">
      <tr>
        <td>
          <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>First name</mat-label>
            <input matInput [formControl]="firstNameFormControl" required />
            <mat-error *ngIf="firstNameFormControl.hasError('required')">
              First name is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </td>
        <td>
          <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Last Name</mat-label>
            <input matInput [formControl]="lastNameFormControl" />
            <mat-error *ngIf="lastNameFormControl.hasError('required')">
              Last name is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </td>
      </tr>
    </table>
  </div>

  <div class="fullscreen-div2">
    <section [formGroup]="toppings" class="checkbox-group">
      <mat-checkbox
        id="check1"
        class="checkbox1"
        formControlName="check1"
        (change)="showOptions($event)"
        >Change Password</mat-checkbox
      >
    </section>
  </div>

  <div class="fullscreen-div3">
    <table class="name-fields">
      <tr>
        <td>
          <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Current Password</mat-label>
            <input
              matInput
              type="password"
              [formControl]="passwordFormControl"
              required
            />
            <mat-error *ngIf="passwordFormControl.hasError('required')">
              Password is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="passwordFormControl.hasError('minlength')">
              Password must be at least <strong>6 characters long</strong>
            </mat-error>
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td>
          <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>New Password</mat-label>
            <input
              matInput
              type="password"
              [formControl]="newpasswordFormControl"
              required
            />
            <mat-error *ngIf="newpasswordFormControl.hasError('required')">
              Password is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="newpasswordFormControl.hasError('minlength')">
              Password must be at least <strong>6 characters long</strong>
            </mat-error>
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td>
          <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Confirm Password</mat-label>
            <input
              matInput
              type="password"
              [formControl]="conffirmpasswordFormControl"
              required
            />
            <mat-error *ngIf="conffirmpasswordFormControl.hasError('required')">
              Password is <strong>required</strong>
            </mat-error>
            <mat-error
              *ngIf="conffirmpasswordFormControl.hasError('minlength')"
            >
              Password must be at least <strong>6 characters long</strong>
            </mat-error>
          </mat-form-field>
        </td>
      </tr>
    </table>
  </div>
</form>
