import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainFrameComponent } from './main/main-frame/main-frame.component';
import { YourappsComponent } from './pages/yourapps/yourapps.component';
import { SamplesComponent } from './pages/samples/samples.component';
import {SaleComponent} from "./pages/sale/sale.component";

const routes: Routes = [
  { path: '', component: MainFrameComponent },
  { path: 'pullsheet', component: MainFrameComponent },
  { path: 'qrcodescan', component: MainFrameComponent },
  { path: 'lookbook', component: MainFrameComponent },
  { path: 'catalog', component: MainFrameComponent },
  { path: 'imageviews', component: MainFrameComponent },
  { path: 'your-apps', component: YourappsComponent },
  { path: 'Samples', component: SamplesComponent },
  { path: 'Sale', component: SaleComponent },
  { path: '**', component: SamplesComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
