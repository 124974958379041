<button mat-icon-button type="button" class="close-btn" (click)="onButtonCloseClick()">
  <mat-icon>close</mat-icon>
</button>
<p class="sample-manager-text">{{formcaption}}</p>
<!--<smart-carousel theme="fm" class="carousel bottom-top" [class.imageview]="true" #carousel id="carouselSquare" [autoPlay]="true" [slideShow]="false" [loop]="true" [hideIndicators]="false"-->
<!--  [keyboard]="false" [displayMode]="'3d'" [interval]="10000"></smart-carousel>-->

<smart-carousel *ngIf="!isDesktopDevice"  theme="fm" class="carousel bottom-top" #carousel [class.mobile]="true" [autoPlay]="true" [slideShow]="false" [loop]="true" [hideIndicators]="false"
        [keyboard]="false" [displayMode]="'3d'" [interval]="10000"></smart-carousel>
<smart-carousel *ngIf="isDesktopDevice"  theme="fm" class="carousel bottom-top" #carousel [class.imageselect]="true" [autoPlay]="true" [slideShow]="false" [loop]="true" [hideIndicators]="false"
        [keyboard]="false" [displayMode]="'3d'" [interval]="10000"></smart-carousel>
<!--<div class="card">-->
<!--  <p-galleria [(ngModel)]="selectedImageFiles" [responsiveOptions]="responsiveOptions" [containerStyle]="{ 'max-width': '640px' }" [numVisible]="5">-->
<!--    <ng-template pTemplate="item" let-item>-->
<!--      <img [src]="item.url" style="width: 100%;" />-->
<!--    </ng-template>-->
<!--    <ng-template pTemplate="thumbnail" let-item>-->
<!--      <div class="grid grid-nogutter justify-content-center">-->
<!--        <img [src]="item.url" />-->
<!--      </div>-->
<!--    </ng-template>-->
<!--  </p-galleria>-->
<!--</div>-->
