import { Component } from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";

@Component({
  selector: 'app-emaileditor',
  templateUrl: './emaileditor.component.html',
  styleUrl: './emaileditor.component.scss'
})
export class EmaileditorComponent {
  constructor(public modalService: ModalserviceService) {}
}
