<div class="scrollable-div-contact horizontal-container-contact">
    <div class="black-background-step3">
        <div class="full-width-caption">
            <p class="caption-text">Tracking Number</p>

            <!-- Adding Mat-input -->
            <mat-form-field appearance="fill" class="full-width-input">
                <mat-label>Tracking Number</mat-label>
                <input matInput placeholder="Enter Tracking Number" [formControl]="trackingNumberControl">
            </mat-form-field>
        </div>
    </div>

    <div class="black-background-step31">

        <div class="full-width-caption">
            <p class="caption-text">Complite Send Out</p>

            <div class="full-width-div">
                <div class="button-container1-step3">
                    <span class="bold-text">Print Delivery Memo #{{value1}}</span>
                </div>
                <div class="button-container-step3">
                    <button mat-raised-button class="button-settings" type="button" (click)="onButtonPrinterClick(0)">
                        <mat-icon>print</mat-icon>Print
                    </button>
                </div>
            </div>

            <div class="full-width-div">
                <div class="button-container1-step3">
                    <span class="bold-text">Print Proforma Invoice #{{value2}}</span>
                </div>
                <div class="button-container-step3">
                    <button mat-raised-button class="button-settings" type="button" (click)="onButtonPrinterClick(1)">
                        <mat-icon>print</mat-icon>Print
                    </button>
                </div>
            </div>

            <div class="full-width-div">
                <div class="button-container1-step3">
                    <span class="bold-text">Print Recipient Address Label #{{value3}}</span>
                </div>
                <div class="button-container-step3">
                    <button mat-raised-button class="button-settings" type="button" (click)="onButtonPrinterClick(2)">
                        <mat-icon>print</mat-icon>Print
                    </button>
                </div>
            </div>

            <div class="full-width-div">
                <div class="button-container1-step3">
                    <span class="bold-text">Print All Sample Barcodes From Delivery #{{value4}}</span>
                </div>
                <div class="button-container-step3">
                    <button mat-raised-button class="button-settings" type="button" (click)="onButtonPrinterClick(3)">
                        <mat-icon>print</mat-icon>Print
                    </button>
                </div>
            </div>

            <div class="full-width-div">
                <div class="button-container1-step3">
                    <span class="bold-text">Pull Sheet for #{{value4}}</span>
                </div>
                <div class="button-container-step3">
                    <button mat-raised-button class="button-settings" type="button" [disabled] = "buttonenabled" (click)="onButtonPullSheetClick()">
                        <mat-icon></mat-icon>Create & Send
                    </button>
                </div>
            </div>

        </div>
    </div>
</div>
