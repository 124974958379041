<div class="top-div">
  <div class="centered-div" #centeredDiv>
    <table class="full-width-table" *ngFor="let newsItem of newsItems">
      <tr *ngIf="newsItem.newsid === 1">
        <td>
          <mat-card class="custom-mat-card">
            <div class="top-left-text">{{ newsItem.thema }}</div>
            <div class="top-right-text">{{ newsItem.date }}</div>
            <div class="image-text-content-container">
              <!-- Add this line -->
              <div
                class="thema-image shadow-4"
                [style.background-image]="'url(' + newsItem.url + ')'">
              </div>
              <div class="text-container">
                <!-- Add this line -->
                <div class="image-text">{{ newsItem.caption }}</div>
                <mat-card-content class="truncate-text">
                  <div class="content-text">{{ newsItem.content }}</div>
                </mat-card-content>
              </div>
            </div>
            <button mat-raised-button class="view-more-btn">View More</button>
          </mat-card>
        </td>
      </tr>
    </table>
  </div>
</div>
