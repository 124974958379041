import { Component } from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";

@Component({
  selector: 'app-createpullsheet',
  templateUrl: './createpullsheet.component.html',
  styleUrls: ['./createpullsheet.component.scss']
})
export class CreatepullsheetComponent {

  constructor(public modalService: ModalserviceService) {}

}
