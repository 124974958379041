import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2, ViewChild
} from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ConfirmationService, ConfirmEventType, MessageService} from "primeng/api";
import {NgxImageCompressService} from "ngx-image-compress";
import { HttpClient, HttpEventType } from "@angular/common/http";
import {map, Observable, startWith, Subscription} from "rxjs";
import {MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {
  FavoritesValue,
  GetDesignerList, GetImageList, GetLBImageList, GetPDFImages, GetPDFImagesData,
  GetSeasonList,
  GetSeasonListData,
  LbCatalogData,
  LbCatalogDetails, LbTempCatalogDetails,
  SelfData, transformImageUrl
} from "../../models/struct";
import {DOCUMENT} from "@angular/common";
import {ProgressBarComponent} from "smart-webcomponents-angular/progressbar";

@Component({
  selector: 'app-createvasiaframedialog',
  templateUrl: './createvasiaframedialog.component.html',
  styleUrls: ['./createvasiaframedialog.component.scss']
})
export class CreatevasiaframedialogComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('progressbar', {read: ProgressBarComponent, static: false}) progressbar!: ProgressBarComponent;
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;

  progressbarpossition: number = 0
  deletedFiles: string[] = [];
  selectedImageFiles: { file: File | null; url: string; filename: string }[] = [];
  previewVisible: boolean = false;
  progressbarshow: boolean = false;
  indeterminate: boolean = false;
  pdfload: boolean = false
  photoload: boolean = false
  previewUrl: string = '';
  uploadbuttonenable: boolean = true
  iImages: GetLBImageList[] = [];
  operationType: number = 0
  catalogid: number = 0
  activeIndex: number = 0;
  selectedFile: File | null = null;
  iDesignerResult: GetDesignerList[] = [];
  iSeasonResult: GetSeasonList[] = [];
  editdesignerdisabled: boolean = false
  editsesiondisabled: boolean = false
  editcollectiondisabled: boolean = false
  formcaption: string = ""

  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
      ? SelfData.account[0].data[0].id
      : SelfData.account[0].data[0].parentid;
  }

  desingerFormControl = new FormControl({value: '', disabled: false}, [
    Validators.required,
  ]);

  seasonFormControl = new FormControl({value: '', disabled: false}, [
    Validators.required,
  ]);

  collectionFormControl = new FormControl({value: '', disabled: false}, [
    Validators.required,
  ]);

  filteredDesignerOptions?: Observable<string[]>;
  filteredSeasonOptions?: Observable<string[]>;

  designerptions: string[] = [];
  seasonoptions: string[] = [];
  collectionoptions: string[] = [];

  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private serviceservice: ServiceService,
    private yourappsService: YourappsserviceService,
    private _snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef,
    private messageService: MessageService,
    private imageCompressService: NgxImageCompressService,
    private http: HttpClient,
    private confirmationService: ConfirmationService,
    private renderer: Renderer2, @Inject(DOCUMENT) private document: Document,
    private el: ElementRef
  ) {
    this.selectedImageFiles = []
    this.serviceservice
      .setDesignerList({accountid: this.getAccountID()})
      .subscribe((desingervalue) => {
        this.iDesignerResult[0] = desingervalue;
        SelfData.designer = this.iDesignerResult;
        this.filteredDesignerOptions = this.setDataAndFilter(
          this.desingerFormControl,
          desingervalue,
          this.designerptions,
          2
        );
      });

    this.serviceservice
      .setSeasonList({accountid: this.getAccountID()})
      .subscribe((seasonvalue) => {
        this.iSeasonResult[0] = seasonvalue;
        SelfData.season = this.iSeasonResult;
        this.filteredSeasonOptions = this.setDataAndFilter(
          this.seasonFormControl,
          seasonvalue,
          this.seasonoptions,
          6
        );
      });
  }

  getDesignerIdByDescription(description: string): number | undefined {
    const specificItem = SelfData.designer
      .flatMap(regionList => regionList.data)
      .find(item => item.description === description);

    return specificItem ? specificItem.id : 0;
  }

  getCurrentSeasonItemByDescription(description: string): GetSeasonListData | undefined {
    for (const season of SelfData.season) {
      const foundItem = season.data.find(item => item.description === description);
      if (foundItem) {
        return foundItem;
      }
    }
    return undefined; // Return undefined if no matching description is found
  }

  onButtonCloseClick() {
    this.modalService.closeVasiaFrame()
    // this.modalService.createsendoutclose()
  }

  onButtonPdfClick() {
    if (this.desingerFormControl.value?.trim() === "") {
      this.messageService.add({
        key: 'tc',
        severity: 'error',
        summary: 'Information',
        detail: 'Please choose the designer.',
        life: 10000,
      });
      return
    }
    this.fileInput.nativeElement.click();
  }

  getCurrentTime(): number {
    return new Date().getTime();
  }

  processImageUrls(imagesData: GetPDFImagesData[]): void {
    this.selectedImageFiles = imagesData.map(image => ({
      file: null,
      url: this.addCacheBuster(image.fileurl),
      filename: image.filename
    }));
  }

// Method to add cache-busting query parameter
  addCacheBuster(url: string): string {
    return url + '?cacheBust=' + new Date().getTime();
  }

  onFileSelected(event: Event) {
    const element = event.currentTarget as HTMLInputElement;
    const file = element.files?.[0] || null;

    if (file) {
      this.photoload = false
      this.pdfload = true
      if (this.selectedImageFiles.length > 0) {
        this.serviceservice.deleteImageFiles(this.selectedImageFiles)
          .subscribe({
              next: (value) => {
                console.log('Upload successful', value)
              },
              error: (error: any) => {
                  this.progressbarshow = true
                this.indeterminate = false
                this.uploadbuttonenable = false
                this.cdr.detectChanges();
                this.progressbar.max = 100
                  this.selectedImageFiles.forEach((filename) => {
                      this.deletedFiles.push(filename.filename)
                  })
                this.selectedImageFiles = []
                this.uploadFile(file)
                // this.serviceservice.uploadPDFFile(
                //   file,
                //   this.catalogid || 0
                // )?.subscribe({
                //   next: (value: GetPDFImages) => {
                //     this.processImageUrls(value.data);
                //   },
                //   error: (error: any) => {
                //     console.error('Error occurred:', error);
                //     this.progressbarshow = false
                //     this.indeterminate = false
                //     this.uploadbuttonenable = true
                //   },
                //   complete: () => {
                //     this.progressbarshow = false
                //     this.indeterminate = false
                //     this.uploadbuttonenable = true
                //   }
                // })
              },
              complete: () => {
                console.log('Selected file:', file.size);
                this.progressbarshow = true
                this.indeterminate = false
                this.uploadbuttonenable = false
                this.cdr.detectChanges();
                this.progressbar.max = 100
                this.selectedImageFiles = []
                this.uploadFile(file)
                // this.serviceservice.uploadPDFFile(
                //   file,
                //   this.catalogid
                // )?.subscribe({
                //   next: (value: GetPDFImages) => {
                //     this.processImageUrls(value.data);
                //   },
                //   error: (error: any) => {
                //     console.error('Error occurred:', error);
                //     this.progressbarshow = false
                //     this.indeterminate = false
                //     this.uploadbuttonenable = true
                //   },
                //   complete: () => {
                //     this.progressbarshow = false
                //     this.indeterminate = false
                //     this.uploadbuttonenable = true
                //   }
                // })
              }
            }
          );
      } else {
        console.log('Selected file:', file.size);
        this.progressbarshow = true
        this.indeterminate = false
        this.uploadbuttonenable = false
        this.cdr.detectChanges();
        this.progressbar.max = 100
        this.selectedImageFiles = []
        this.uploadFile(file)
        // this.serviceservice.uploadPDFFile(
        //   file,
        //   this.catalogid
        // )?.subscribe({
        //   next: (value: GetPDFImages) => {
        //     this.processImageUrls(value.data);
        //   },
        //   error: (error: any) => {
        //     console.error('Error occurred:', error);
        //     this.progressbarshow = false
        //     this.indeterminate = false
        //     this.uploadbuttonenable = true
        //   },
        //   complete: () => {
        //     this.progressbarshow = false
        //     this.indeterminate = false
        //     this.uploadbuttonenable = true
        //   }
        // })
      }
    }
  }

  uploadFile(file: File) {
    this.serviceservice.uploadPDFFile(file, this.catalogid).subscribe({
      next: (event) => {
        if (event.type === HttpEventType.UploadProgress) {
          // Check if the total byte count is available
          if (event.total) {
            const percentDone = Math.round(100 * event.loaded / event.total);
            this.progressbar.value = percentDone
            console.log(`File is ${percentDone}% uploaded.`);
          } else {
            console.log('Total size unknown, uploaded:', event.loaded);
          }
        } else if (event.type === HttpEventType.Response) {
          // Handle the response
          this.processImageUrls(event.body.data);
        }
      },
      error: (error: any) => {
        console.error('Error occurred:', error);
        this.progressbarshow = false;
        this.indeterminate = false;
        this.uploadbuttonenable = true;
      },
      complete: () => {
        this.progressbarshow = false;
        this.indeterminate = false;
        this.uploadbuttonenable = true;
      }
    });
  }

  prepareImageFilesForSaving(accountid: number, userid: number, catalogid: number): any[] {
    return this.selectedImageFiles.map(file => ({
      ...file, // Spread the existing properties
      accountid: accountid,
      userid: userid,
      catalogid: this.catalogid
    }));
  }

  onButtonUploadClick() {
    if (this.desingerFormControl.value?.trim() === "") {
      this.messageService.add({
        key: 'tc',
        severity: 'error',
        summary: 'Information',
        detail: 'Please choose the designer.',
        life: 10000,
      });
      return
    }

    if (this.seasonFormControl.value?.trim() === "") {
      this.messageService.add({
        key: 'tc',
        severity: 'error',
        summary: 'Information',
        detail: 'Please choose the season.',
        life: 10000,
      });
      return
    }

    if (this.collectionFormControl.value?.trim() === "") {
      this.messageService.add({
        key: 'tc',
        severity: 'error',
        summary: 'Information',
        detail: 'Please choose the name of the collection.',
        life: 10000,
      });
      return
    }
    this.confirmationService.confirm({
      message: `<strong>Are you sure you want to upload these photos?</strong>`,
      header: 'Upload Images',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.uploadbuttonenable = false
        if (this.deletedFiles.length > 0) {
          this.deletedFiles.forEach((filename) => {
            const fileData = {
              userid: SelfData.account[0].data[0].id, // Replace this with a method or property that gets the actual user ID
              accountid: this.getAccountID(), // Assuming this is a method that returns the account ID
              filename: filename // No need for a default value here; the filename should exist as it's coming from the array
            };
            this.serviceservice.prcdeletelbimage(fileData).subscribe({
              next: (value) => {
              },
              error: (error: any) => {
                console.error('Error occurred:', error);
              },
              complete: () => {
                // console.log('Subscription completed');
              }
            });
          })
        }
        console.log("this.operationType", this.operationType)
        if (this.operationType === 0) {
          const catalogDetails: LbCatalogDetails = {
            id: 0, // Replace with actual id
            description: this.collectionFormControl.value || "",
            accountid: this.getAccountID(),
            designerid: this.getDesignerIdByDescription(this.desingerFormControl.value || "") || 0,
            seasonid: this.getCurrentSeasonItemByDescription(this.seasonFormControl.value || "")?.id || 0,
            isactivity: true,
            ctrl: 0
          };
          this.serviceservice.lbcatalogs(catalogDetails).subscribe({
            next: (value: LbCatalogData) => {
              this.catalogid = value.data
              const tempcatalogDetails: LbTempCatalogDetails = {
                id: 0, // Replace with actual id
                description: this.collectionFormControl.value || "",
                accountid: this.getAccountID(),
                designerid: this.getDesignerIdByDescription(this.desingerFormControl.value || "") || 0,
                seasonid: this.getCurrentSeasonItemByDescription(this.seasonFormControl.value || "")?.id || 0,
                isactivity: true,
                ctrl: 0,
                lbmainid: SelfData.tlbmainid
              };
              // Handle the response here
              this.serviceservice.lbtempcatalogs(tempcatalogDetails).subscribe({
                next: (value: LbCatalogData) => {

                },
                error: (error: any) => {
                  console.error('Error occurred:', error);
                },
                complete: () => {
                  // console.log('Subscription completed');
                }
              })

              if (this.pdfload) {
                const imageFilesToSave = this.prepareImageFilesForSaving(this.getAccountID(), SelfData.account[0].data[0].id, this.catalogid);
                this.serviceservice.saveImageFiles(imageFilesToSave)
                  .subscribe({
                    next: (value) => {
                      console.log('Response from server:', value);

                    },
                    error: (error: any) => {
                      console.error('Error occurred:', error);
                    },
                    complete: () => {
                      this.serviceservice.copydeleteImageFiles(this.selectedImageFiles)
                        .subscribe({
                          next: (value) => {
                            console.log('Upload successful', value)
                            this.completeOperation()
                            this.progressbarpossition = 0
                            this.modalService.closeVasiaFrame()
                            this.yourappsService.getReloadLBlist()
                          },
                          error: (error: any) => {
                            console.error('Error occurred:', error);
                          },
                          complete: () => {

                          }
                        })
                    }
                  });
              } else {
                //
                // if (this.selectedImageFiles && this.selectedImageFiles.length > 0) {
                //   this.progressbarpossition = 0;
                //   this.progressbarshow = true;
                //   this.cdr.detectChanges();
                //   this.progressbar.max = this.selectedImageFiles.length;
                // }
                // this.uploadlbImage(0, this.getAccountID(), SelfData.account[0].data[0].id, this.catalogid)
              }
            },
            error: (error: any) => {
              console.error('Error occurred:', error);
            },
            complete: () => {
              // console.log('Subscription completed');
            }
          });
        } else if (this.operationType === 1) {
          const catalogDetails: LbCatalogDetails = {
            id: this.catalogid, // Replace with actual id
            description: this.collectionFormControl.value || "",
            accountid: this.getAccountID(),
            designerid: this.getDesignerIdByDescription(this.desingerFormControl.value || "") || 0,
            seasonid: this.getCurrentSeasonItemByDescription(this.seasonFormControl.value || "")?.id || 0,
            isactivity: true,
            ctrl: 1
          };
          this.serviceservice.lbcatalogs(catalogDetails).subscribe({
            next: (value: LbCatalogData) => {
              // Handle the response here
              if (this.selectedImageFiles && this.selectedImageFiles.length > 0) {
                this.progressbarpossition = 0;
                this.progressbarshow = true;
                this.cdr.detectChanges();
                this.progressbar.max = this.selectedImageFiles.length;
              }

              this.catalogid = value.data

              if (this.pdfload) {
                const imageFilesToSave = this.prepareImageFilesForSaving(this.getAccountID(), SelfData.account[0].data[0].id, this.catalogid);
                this.serviceservice.saveImageFiles(imageFilesToSave)
                  .subscribe({
                    next: (value) => {
                      console.log('Response from server:', value);

                    },
                    error: (error: any) => {
                      console.error('Error occurred:', error);
                    },
                    complete: () => {
                      this.serviceservice.copydeleteImageFiles(this.selectedImageFiles)
                        .subscribe({
                          next: (value) => {
                            console.log('Upload successful', value)
                            this.completeOperation()
                            this.progressbarpossition = 0
                            this.modalService.closeVasiaFrame()
                            this.yourappsService.getReloadLBlist()
                          },
                          error: (error: any) => {
                            console.error('Error occurred:', error);
                          },
                          complete: () => {

                          }
                        })
                    }
                  });
              } else {
                this.uploadlbImage(0, this.getAccountID(), SelfData.account[0].data[0].id, value.data)
              }
            },
            error: (error: any) => {
              console.error('Error occurred:', error);
            },
            complete: () => {
              // console.log('Subscription completed');
            }
          });
        } else if (this.operationType === 2) {
          const catalogDetails: LbCatalogDetails = {
            id: 0, // Replace with actual id
            description: this.collectionFormControl.value || "",
            accountid: this.getAccountID(),
            designerid: this.getDesignerIdByDescription(this.desingerFormControl.value || "") || 0,
            seasonid: this.getCurrentSeasonItemByDescription(this.seasonFormControl.value || "")?.id || 0,
            isactivity: true,
            ctrl: 0
          };
          this.serviceservice.lbcatalogs(catalogDetails).subscribe({
            next: (value: LbCatalogData) => {
              // Handle the response here
              const tempcatalogDetails: LbTempCatalogDetails = {
                id: 0, // Replace with actual id
                description: this.collectionFormControl.value || "",
                accountid: this.getAccountID(),
                designerid: this.getDesignerIdByDescription(this.desingerFormControl.value || "") || 0,
                seasonid: this.getCurrentSeasonItemByDescription(this.seasonFormControl.value || "")?.id || 0,
                isactivity: true,
                ctrl: 0,
                lbmainid: SelfData.tlbmainid
              };
              // Handle the response here
              this.serviceservice.lbtempcatalogs(tempcatalogDetails).subscribe({
                next: (value: LbCatalogData) => {

                },
                error: (error: any) => {
                  console.error('Error occurred:', error);
                },
                complete: () => {
                  // console.log('Subscription completed');
                }
              })
              this.catalogid = value.data
              if (this.selectedImageFiles && this.selectedImageFiles.length > 0) {
                this.progressbarpossition = 0;
                this.progressbarshow = true;
                this.cdr.detectChanges();
                this.progressbar.max = this.selectedImageFiles.length;
              }
              const imageFilesToSave = this.prepareImageFilesForSaving(this.getAccountID(), SelfData.account[0].data[0].id, this.catalogid);
              this.serviceservice.saveImageFiles(imageFilesToSave)
                .subscribe({
                  next: (value) => {
                    console.log('Response from server:', value);

                  },
                  error: (error: any) => {
                    console.error('Error occurred:', error);
                  },
                  complete: () => {
                    this.serviceservice.copydeleteImageFiles(this.selectedImageFiles)
                      .subscribe({
                        next: (value) => {
                          console.log('Upload successful', value)
                          this.completeOperation()
                          this.progressbarpossition = 0
                          this.modalService.closeVasiaFrame()
                          this.yourappsService.getReloadLBlist()
                        },
                        error: (error: any) => {
                          console.error('Error occurred:', error);
                        },
                        complete: () => {

                        }
                      })
                  }
                });
              // this.uploadlbImage(0, this.getAccountID(), SelfData.account[0].data[0].id, value.data)
            },
            error: (error: any) => {
              console.error('Error occurred:', error);
            },
            complete: () => {
              // console.log('Subscription completed');
            }
          });
        } else if (this.operationType === 3 || this.operationType === 4) {
          const catalogDetails: LbCatalogDetails = {
            id: this.catalogid,
            description: this.collectionFormControl.value || "",
            accountid: this.getAccountID(),
            designerid: this.getDesignerIdByDescription(this.desingerFormControl.value || "") || 0,
            seasonid: this.getCurrentSeasonItemByDescription(this.seasonFormControl.value || "")?.id || 0,
            isactivity: true,
            ctrl: 1
          };
          this.serviceservice.lbcatalogs(catalogDetails).subscribe({
            next: (value: LbCatalogData) => {
              if (this.selectedImageFiles && this.selectedImageFiles.length > 0) {
                this.progressbarpossition = 0;
                this.progressbarshow = true;
                this.cdr.detectChanges();
                this.progressbar.max = this.selectedImageFiles.length;
              }

              console.log("catalogDetails", catalogDetails)

              const imageFilesToSave = this.prepareImageFilesForSaving(this.getAccountID(), SelfData.account[0].data[0].id, value.data);
              this.serviceservice.saveImageFiles(imageFilesToSave)
                .subscribe({
                  next: (value) => {
                    console.log('Response from server:', value);

                  },
                  error: (error: any) => {
                    console.error('Error occurred:', error);
                  },
                  complete: () => {
                    this.serviceservice.copydeleteImageFiles(this.selectedImageFiles)
                      .subscribe({
                        next: (value) => {
                          console.log('Upload successful', value)
                          this.completeOperation()
                          this.progressbarpossition = 0
                          this.modalService.closeVasiaFrame()
                          this.yourappsService.getReloadLBlist()
                        },
                        error: (error: any) => {
                          console.error('Error occurred:', error);
                        },
                        complete: () => {

                        }
                      })
                  }
                });

              // this.uploadlbImage(0, this.getAccountID(), SelfData.account[0].data[0].id, value.data)
            },
            error: (error: any) => {
              console.error('Error occurred:', error);
            },
            complete: () => {
              // console.log('Subscription completed');
            }
          });
        }
      },
      reject: (type: any) => {
        if (type == ConfirmEventType.REJECT) {

        } else if (type == ConfirmEventType.CANCEL) {

        }
      }
    });
    setTimeout(() => {
      this.applyLocalRestoreStyles();
    }, 0);
  }

  private applyLocalRestoreStyles() {
    setTimeout(() => {
      const messageElements = document.querySelectorAll('.p-confirm-dialog-message');
      messageElements.forEach((element) => {
        element.setAttribute('style', 'font-size: 16px; color: #343a40;');
      });
    }, 0);

    const iconElements = document.querySelectorAll('.p-confirm-dialog-icon');
    iconElements.forEach((element) => {
      element.setAttribute('style', 'color: #343a40');
    });
  }

  uploadlbImage(ctrl: number, accountid: number, userid: number, lbcatalogid: number) {
    this.selectedImageFiles.forEach((imageFile) => {
      if (imageFile.file !== null && imageFile.file !== undefined) {
        const result = this.serviceservice.uploadlbImage(
          imageFile.file,
          accountid,
          userid,
          ctrl,
          lbcatalogid
        );
        if (result !== null) {
          result.subscribe({
            next: (response) => {
              this.progressbarpossition++
              this.progressbar.value = this.progressbarpossition
              if (this.progressbarpossition === this.progressbar.max) {
                this.completeOperation()
                this.progressbarpossition = 0
                this.modalService.closeVasiaFrame()
                this.yourappsService.getReloadLBlist()
              }
              console.log('Upload successful', response);
            },
            error: (error) => {
              console.error('Upload failed', error);
            },
          });
        } else {
          console.error('uploadSample returned null');
        }
      } else {
        this.progressbarpossition++
        this.progressbar.value = this.progressbarpossition
        if (this.progressbarpossition === this.progressbar.max) {
          this.completeOperation()
          this.progressbarpossition = 0
          this.modalService.closeVasiaFrame()
          this.yourappsService.getReloadLBlist()
        }
        // console.error('File is null or undefined');
      }
    });
  }

  startOperation() {
    this.progressbarshow = true; // Show the progress bar
    // Start the operation, such as file upload, data loading, etc.
  }

  completeOperation() {
    this.progressbarshow = false; // Hide the progress bar after operation completion
    // Other completion logic
  }

  private setDataAndFilter(
    control: FormControl,
    response: any,
    options: string[],
    type: number
  ) {
    let results = response.data;
    for (let data of results) {
      options.push(data.description);
    }
    return control.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || '', type))
    );
  }

  private _filter(value: string, type: number): string[] {
    const filterValue = value.toLowerCase();
    switch (type) {
      case 2:
        return this.designerptions.filter((option) =>
          option.toLowerCase().includes(filterValue)
        );
      case 5:
        return this.collectionoptions.filter((option) =>
          option.toLowerCase().includes(filterValue)
        );
      case 6:
        return this.seasonoptions.filter((option) =>
          option.toLowerCase().includes(filterValue)
        );
      default:
        console.warn(`Unexpected filter type: ${type}`);
        return [];
    }
  }

  add(
    event: MouseEvent,
    type: number,
    autoInput: MatAutocompleteTrigger
  ): void {
    event.stopPropagation(); // This will stop the event from bubbling up
    SelfData.modaltype = type;
    autoInput.closePanel();
    // Determine the caption based on the provided type
    switch (type) {
      case 2:
        this.showAddEditFrame(0);
        break;
      case 6:
        this.showSeasonAddEditFrame(0);
    }
  }

  uploadImageFiles() {
    let fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.multiple = true;
    fileInput.accept = 'image/*';
    fileInput.addEventListener('change', (event) => {
      const files: FileList | null = (event.target as HTMLInputElement).files;
      if (files && files.length > 0) {
        if (this.pdfload) {
          this.selectedImageFiles = []
        }
        this.pdfload = false
        this.photoload = true
        this.progressbarpossition = 0;
        this.progressbarshow = true;
        // Manually trigger change detection
        this.cdr.detectChanges();
        // Access progressbar after view has been updated
        this.progressbar.max = files.length;
        Array.from(files).forEach(file => {
          console.log("file.size", file.size)
          if (file.size >= 3500000) {
            this.compressImage(file);
          } else {
            let reader = new FileReader();
            reader.onload = (event: any) => {
              this.selectedImageFiles.push({
                file,
                url: event.target.result,
                filename: file.name,
              });
            };
            reader.readAsDataURL(file);
          }
        });
      }
      // Clean up the file input
      fileInput.remove();
    });
    fileInput.click();
  }

  base64ToFile(dataURI: string, fileName: string, mimeType: string): File {
    const byteString = atob(dataURI.split(',')[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    return new File([int8Array], fileName, {type: mimeType});
  }

  compressImage(file: File) {
    // First, check if the file is already in the selectedImageFiles array.
    // Here we're using optional chaining with ?. to safely access file properties
    const existingFile = this.selectedImageFiles.find(f => f.filename === file.name && f.file?.size === file.size && f.file?.lastModified === file.lastModified);

    // console.log("file.name", file.name, "file.size", file.size, "file.url", file.size)
    // If the file exists, return and do not add it again.
    if (existingFile) {
      console.log('File already added:', file.name);
      return;
    }

    const reader = new FileReader();
    reader.onload = (e: any) => {
      const image = e.target.result;
      this.imageCompressService.compressFile(image, -1, 25, 100).then(
        (result: string) => {
          // Assuming base64ToFile is a method that converts base64 string back to File object
          const compressedFile = this.base64ToFile(result, file.name, file.type);
          // Now push the compressed image object to selectedImageFiles
          this.selectedImageFiles.push({
            file: compressedFile,
            url: result, // The compressed image base64 string
            filename: file.name
          });
          this.progressbarpossition++
          this.progressbar.value = this.progressbarpossition
          if (this.progressbarpossition === this.progressbar.max) {
            this.completeOperation()
            this.progressbarpossition = 0
          }
        },
        (error: any) => {
          console.error('Error compressing image:', error);
        }
      );
    };
    reader.readAsDataURL(file);
  }

// ... rest of your code ...


  showAddEditFrame(showType: number, id: number = 0, description: string = '') {
    if (showType === 0) {
      this.yourappsService.setFrameCaption('Add New');
      this.yourappsService.setFrameDescription('');
      this.yourappsService.setFrameid(id);
    } else if (showType === 1) {
      this.yourappsService.setFrameCaption('Edit');
      this.yourappsService.setFrameDescription(description);
      this.yourappsService.setFrameid(id);
    }
    this.modalService.createAddEdit();
  }

  showSeasonAddEditFrame(
    showType: number,
    id: number = 0,
    description: string = ''
  ) {
    console.dir(SelfData.frame);
    if (showType === 0) {
      this.yourappsService.setFrameCaption('Add New');
      this.yourappsService.setFrameDescription('');
      this.yourappsService.setFrameid(id);
    } else if (showType === 1) {
      this.yourappsService.setFrameCaption('Edit');
      this.yourappsService.setFrameDescription(description);
      this.yourappsService.setFrameid(id);
    }
    this.modalService.createSeason();
  }

  modalOpen: boolean = false;
  modalImageUrl: string = '';

  openModal(imageUrl: string) {
    this.modalImageUrl = imageUrl;
    this.modalOpen = true;
  }

  closeModal() {
    this.modalOpen = false;
  }

  zoomScale = 1;
  dragging: boolean = false;
  startY: number = 0;
  translationY: number = 0;
  zoomLevel: number = 1;

  showFullWindowImage(imageUrl: string): void {
    // Create the elements for the modal, image, and close button
    const modal = this.renderer.createElement('div');
    this.renderer.addClass(modal, 'preview-modal');

    const img = this.renderer.createElement('img');
    this.renderer.addClass(img, 'preview-image');
    this.renderer.setAttribute(img, 'src', imageUrl);
    this.renderer.appendChild(modal, img);

    const closeButton = this.renderer.createElement('button');
    this.renderer.addClass(closeButton, 'close-preview');
    closeButton.innerHTML = '&times;'; // or use an SVG/icon for the close button
    this.renderer.appendChild(modal, closeButton);

// Append the modal to the body
    this.renderer.appendChild(this.document.body, modal);

    // Add a click listener to the close button to remove the modal
    this.renderer.listen(closeButton, 'click', (event) => {
      event.stopPropagation(); // Prevent the event from bubbling up
      this.renderer.removeChild(this.document.body, modal);
    });

// Add a click listener to the modal to close it if the user clicks outside the image
    this.renderer.listen(modal, 'click', () => {
      this.renderer.removeChild(this.document.body, modal);
    });

// Prevent the modal from closing when clicking on the image
    this.renderer.listen(img, 'click', (event) => {
      event.stopPropagation(); // Stop propagation to prevent the modal click listener from triggering
    });

    // Optionally, add a click listener to the modal to close it if the user clicks outside the image
    // this.renderer.listen(modal, 'click', () => {
    //   this.renderer.removeChild(this.document.body, modal);
    // });

    // this.renderer.listen(img, 'click', () => {
    //   this.zoomScale = this.zoomScale === 1 ? 2 : 1; // Toggle between 1x and 2x zoom
    //   this.renderer.setStyle(img, 'transform', `scale(${this.zoomScale})`);
    //   this.renderer.setStyle(img, 'cursor', this.zoomScale === 1 ? 'zoom-in' : 'zoom-out');
    // });
  }

  getFilenameAtIndex(index: number): string | null {
    // Check if the index is within the bounds of the array
    if (index < 0 || index >= this.selectedImageFiles.length) {
      console.error('Index out of bounds when trying to access selectedImageFiles');
      return null;
    }

    // Check if there is a file object
    const imageFileEntry = this.selectedImageFiles[index];
    if (!imageFileEntry) {
      console.error('No file found at the given index');
      return null;
    }

    // Return the filename
    return imageFileEntry.filename;
  }

  deleteImageFile(event: MouseEvent, index: number) {
    event.stopPropagation();
    this.confirmationService.confirm({
      message: `<strong>Are you sure you want to delete this Image?</strong>`,
      header: 'Delete Image',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        let filename = this.getFilenameAtIndex(index);
        if (filename) {
          this.deletedFiles.push(filename)
          this.selectedImageFiles.splice(index, 1);
        }
      },
      reject: (type: any) => {
        if (type == ConfirmEventType.REJECT) {

        } else if (type == ConfirmEventType.CANCEL) {
        }
      }

    });
    setTimeout(() => {
      this.applyLocalStyles();
    }, 0);
  }

  private applyLocalStyles() {
    setTimeout(() => {
      const messageElements = document.querySelectorAll('.p-confirm-dialog-message');
      messageElements.forEach((element) => {
        element.setAttribute('style', 'font-size: 16px; color: red;');
      });
    }, 0);

    const iconElements = document.querySelectorAll('.p-confirm-dialog-icon');
    iconElements.forEach((element) => {
      element.setAttribute('style', 'color: red');
    });
  }

  startDragging(event: MouseEvent): void {
    event.preventDefault();
    this.dragging = true;
    this.startY = event.clientY;

    // Listen for mouse move and mouse up events
    window.addEventListener('mousemove', this.dragImage.bind(this));
    window.addEventListener('mouseup', this.stopDragging.bind(this));
  }

  dragImage(event: MouseEvent): void {
    if (this.dragging && this.zoomLevel > 1) {
      const diffY = event.clientY - this.startY;
      this.translationY += diffY;
      this.startY = event.clientY;
      this.updateImageTransform();
    }
  }

  stopDragging(event: MouseEvent): void {
    this.dragging = false;
    window.removeEventListener('mousemove', this.dragImage.bind(this));
    window.removeEventListener('mouseup', this.stopDragging.bind(this));
  }

  zoomImage(event: WheelEvent): void {
    event.preventDefault();
    const delta = Math.sign(event.deltaY);
    this.zoomLevel += delta * -0.1; // Adjust the scale step as needed
    this.zoomLevel = Math.max(1, this.zoomLevel); // Prevent zooming out below original size
    this.updateImageTransform();
  }

  updateImageTransform(): void {
    const img = document.querySelector('.overlay-content') as HTMLImageElement;
    img.style.transform = `scale(${this.zoomLevel}) translateY(${this.translationY}px)`;
  }

  hideFullWindowImage(): void {
    // ... existing code to hide the image
    // Reset zoom and translation
    this.zoomLevel = 1;
    this.translationY = 0;
    this.updateImageTransform();
  }

  ngAfterViewInit(): void {
    // this.uploadbuttonenable = true
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private subscription?: Subscription;

  ngOnInit(): void {
    this.deletedFiles = []
    this.subscription = this.yourappsService.lcitem$.subscribe((value: { data: any, type: number }) => {
      if (!Array.isArray(value.data) && value.type === 1) {
        this.formcaption = "Edit"
        this.operationType = value.type
        this.desingerFormControl.setValue(value.data.designer);
        this.seasonFormControl.setValue(value.data.season);
        this.collectionFormControl.setValue(value.data.collection);
        this.catalogid = value.data.id
        this.serviceservice
          .setLBImageList({lbcatalogid: value.data.id})
          .subscribe({
            next: (value: any) => {
              if (value && value.data && Array.isArray(value.data)) {
                this.iImages[0] = value.data;

                value.data.forEach((item: { filedir?: string }) => {
                  if (item.filedir) {
                    // Include both file and url properties
                    const url = item.filedir

                    const parts = url.split('/');

                    const fileName = parts[parts.length - 1];

                    this.selectedImageFiles.push({
                      file: null,
                      url: this.addCacheBuster(item.filedir),
                      filename: fileName,
                    });
                  }
                });
              } else {
                console.log(
                  'The response is empty or not in the expected format.'
                );
              }
            },
            error: (error) => {
              console.error('Error occurred during the subscription:', error);
            },
          });
      } else if (!Array.isArray(value.data) && value.type === 2) {
        this.formcaption = "Create New Catalog"
        this.operationType = value.type
        this.desingerFormControl.disable()
        this.seasonFormControl.disable()
        this.editdesignerdisabled = true
        this.editsesiondisabled = true
        this.desingerFormControl.setValue(value.data.designer);
        this.seasonFormControl.setValue(value.data.season);
        this.catalogid = value.data.id
      } else if (!Array.isArray(value.data) && value.type === 3) {
        this.formcaption = "Add photos to the current catalog"
        this.operationType = value.type
        this.desingerFormControl.disable()
        this.seasonFormControl.disable()
        this.collectionFormControl.disable()
        this.editdesignerdisabled = true
        this.editsesiondisabled = true
        this.desingerFormControl.setValue(value.data.designer);
        this.seasonFormControl.setValue(value.data.season);
        this.collectionFormControl.setValue(value.data.collection)
        this.catalogid = value.data.id
        this.serviceservice
          .setLBImageList({lbcatalogid: value.data.id})
          .subscribe({
            next: (value: any) => {
              if (value && value.data && Array.isArray(value.data)) {
                this.iImages[0] = value.data;

                value.data.forEach((item: { filedir?: string }) => {
                  if (item.filedir) {
                    // Include both file and url properties
                    const url = item.filedir

                    const parts = url.split('/');

                    const fileName = parts[parts.length - 1];

                    this.selectedImageFiles.push({
                      file: null,
                      url: this.addCacheBuster(item.filedir),
                      filename: fileName,
                    });
                  }
                });
              } else {
                console.log(
                  'The response is empty or not in the expected format.'
                );
              }
            },
            error: (error) => {
              console.error('Error occurred during the subscription:', error);
            },
          });
      } else if (!Array.isArray(value.data) && value.type === 4) {
        this.formcaption = "View photos from the catalog"
        this.operationType = value.type
        this.desingerFormControl.disable()
        this.seasonFormControl.disable()
        this.collectionFormControl.disable()
        this.editdesignerdisabled = true
        this.editsesiondisabled = true
        this.uploadbuttonenable = false
        this.desingerFormControl.setValue(value.data.designer);
        this.seasonFormControl.setValue(value.data.season);
        this.collectionFormControl.setValue(value.data.collection)
        this.catalogid = value.data.id
        this.serviceservice
          .setLBImageList({lbcatalogid: value.data.id})
          .subscribe({
            next: (value: any) => {
              if (value && value.data && Array.isArray(value.data)) {
                this.iImages[0] = value.data;

                value.data.forEach((item: { filedir?: string }) => {
                  if (item.filedir) {
                    // Include both file and url properties
                    const url = item.filedir

                    const parts = url.split('/');

                    const fileName = parts[parts.length - 1];

                    this.selectedImageFiles.push({
                      file: null,
                      url: this.addCacheBuster(item.filedir),
                      filename: fileName,
                    });
                  }
                });
              } else {
                console.log(
                  'The response is empty or not in the expected format.'
                );
              }
            },
            error: (error) => {
              console.error('Error occurred during the subscription:', error);
            },
          });
      } else if (value.type === 0) {
        this.formcaption = "Add New"
        this.operationType = 0
      }
    });
  }

  protected readonly transformImageUrl = transformImageUrl;
}
