<div class="card">
  <div class="savebuttondiv">
    <button class="savebuttonsetting" nz-button nzType="default" (click)="onSaveButtonClick()">Save</button>
  </div>
  <div class="black-background-blt">
    <div class="example-container-blt">
      <div class="headerdiv">
        <samp class="titlediv">General setup</samp>
      </div>
      <div class="headerdiv1">
        <div class="headerdiv">
          <samp class="titlediv1">Configure your store settings and contact information.</samp>
        </div>
      </div>
      <div class="headerdivconteiner">
        <div class="conteinerdiv">
          <div class="child-1">
            <samp class="titlediv2">Store settings</samp><br><br>
            <samp class="titlediv1">Update your store name, currency and timezone.</samp>
          </div>
          <div class="child-2">
            <div class="componentsdiv1">

            </div>
            <div class="componentsdiv">
              <div class="componentsdiv2">
                <label class="txtdiv">Store name</label>
                <nz-input-group>
                  <input class="cmdiv" type="text" [(ngModel)]="storagename" nz-input/>
                </nz-input-group>

              </div>
              <div class="componentsdiv2">

              </div>
            </div>
            <!--            2line-->
            <div class="componentsdiv">
              <div class="componentsdiv2">
                <label class="txtdiv">Default currency</label>
                <nz-input-group nzSearch>
                  <nz-select nzShowSearch [nzShowArrow]="true" class="cmdiv"
                    [(ngModel)]="defoultcurrency">
                    <nz-option *ngFor="let option of currency" [nzLabel]="option.value"
                      [nzValue]="option.value"></nz-option>
                  </nz-select>
                </nz-input-group>

              </div>
              <div class="componentsdiv2">
                <label class="txtdiv">Time zone</label>
                <nz-input-group nzSearch>
                  <nz-select nzShowSearch [nzShowArrow]="true" class="cmdiv"
                    [(ngModel)]="defaulttimezone">
                    <nz-option *ngFor="let option of timezone" [nzLabel]="option.viewvalue"
                      [nzValue]="option.viewvalue"></nz-option>
                  </nz-select>
                </nz-input-group>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="dividerdiv">
        <nz-divider nzPlain></nz-divider>
      </div>

      <div class="headerdivconteiner">
        <div class="conteinerdiv">
          <div class="child-1">
            <samp class="titlediv2">Tax settings</samp><br><br>

          </div>
          <div class="child-2">

            <div class="componentsdiv2">
              <label class="txtdiv">Default sales tax</label>
              <nz-input-group [nzAddOnAfter]="suffixIconButton">
                <nz-select nzShowSearch [nzShowArrow]="true" class="cmdiv"
                  [(ngModel)]="defaultsalestax">
                  <nz-option *ngFor="let option of iTaxResult" [nzLabel]="option['displayvalue']" [nzValue]="option['displayvalue']">
                  </nz-option>
                </nz-select>
              </nz-input-group>
              <ng-template #suffixIconButton>
<!--                <button class="buttonclass" nz-button nzType="default" nzSearch (click)="onTaxesClick()">-->
<!--                  &lt;!&ndash;              <span nz-icon nzType="unordered-list" nzTheme="outline"></span>&ndash;&gt;-->
<!--                  <i nz-icon nzType="ellipsis" nzTheme="outline"></i>-->
<!--                </button>-->
              </ng-template>
            </div>

            <div class="componentsdiv_1">
              <div class="componentsdiv2">
                <label nz-checkbox [(ngModel)]="taxexclusive">Tax exclusive display prices</label>
                <samp class="titlediv3">Enable to display prices without tax.</samp>
              </div>
              <div class="componentsdiv2">

              </div>
            </div>
            <!--            2line-->
            <div class="componentsdiv3">
              <div class="componentsdiv2">
                <label nz-checkbox [(ngModel)]="imregisteredforvat">I’m registered for VAT</label>
              </div>
              <div class="componentsdiv2">

              </div>
            </div>
            <!--            3line-->
            <div *ngIf="imregisteredforvat" class="componentsdiv">
              <div class="componentsdiv2">
                <label class="txtdiv">VAT account number (VAT number)</label>
                <nz-input-group>
                  <input placeholder="Enter your VAT number" class="cmdiv" type="text" [(ngModel)]="vatnumber"
                    nz-input/>
                </nz-input-group>
              </div>
              <div class="componentsdiv2">

              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="dividerdiv">
        <nz-divider nzPlain></nz-divider>
      </div>

      <div class="headerdivconteiner">
        <div class="conteinerdiv">
          <div class="child-1">
            <samp class="titlediv2">Barcode and SKU settings</samp><br><br>
            <samp class="titlediv1">Control how SKUs are generated and how labels and barcodes will be formatted.</samp>
          </div>
          <div class="child-2">
            <div class="componentsdiv1">

            </div>
            <div class="componentsdiv3">
              <div class="componentsdiv2">
                <samp class="titlediv2">SKU generation</samp>
              </div>
              <div class="componentsdiv2">

              </div>
            </div>
            <!--            2line-->
            <div class="componentsdiv5">
              <div class="componentsdiv2">
                <label nz-checkbox [(ngModel)]="generateskusbynumber">Generate SKUs by number</label>
                <samp class="titlediv3">SKUs will be generated by sequence number instead of product name.</samp>
              </div>
              <div class="componentsdiv2">

              </div>
            </div>
            <!--            3line-->
            <div *ngIf="generateskusbynumber" class="componentsdiv4">
              <div class="componentsdiv2">
                <label class="txtdiv">Current sequence number</label>
                <nz-input-group>
                  <input class="cmdiv" type="text" [(ngModel)]="currentsequencenumber" nz-input/>
                </nz-input-group>

              </div>
              <div class="componentsdiv2">

              </div>
            </div>

            <div class="dividerdiv1">
              <nz-divider nzPlain></nz-divider>
            </div>

          </div>
        </div>

      </div>

      <div class="dividerdiv">
        <nz-divider nzPlain></nz-divider>
      </div>

      <div class="headerdivconteiner">
        <div class="conteinerdiv">
          <div class="child-1">
            <samp class="titlediv2">User settings</samp><br><br>
            <samp class="titlediv1">Control general user account security settings.</samp>
          </div>
          <div class="child-2">
            <div class="componentsdiv1">

            </div>
            <div class="componentsdiv5">
              <nz-radio-group [(ngModel)]="userauthvalue" nzName="radiogroup">
                <label nz-radio nzValue="1">Never require a password when switching between users</label>
                <label nz-radio nzValue="2">Don’t require a password when switching with a barcode</label>
                <label nz-radio nzValue="3">Require a password when switching to a user with more privileges</label>
                <label nz-radio nzValue="4">Always require a password when switching between users</label>
              </nz-radio-group>
              <div class="componentsdiv2">

              </div>
            </div>
            <!--            2line-->

            <!--            3line-->

          </div>
        </div>

      </div>

      <div class="dividerdiv">
        <nz-divider nzPlain></nz-divider>
      </div>

      <div class="headerdivconteiner">
        <div class="conteinerdiv">
          <div class="child-1">
            <samp class="titlediv2">Customer settings</samp>
          </div>
          <div class="child-2">
            <div class="componentsdiv1-1">

            </div>
            <div class="componentsdiv5">
              <label nz-checkbox [(ngModel)]="automaticallyaddcustomers">Automatically add customers from emailed
                receipts to the sale</label>
              <div class="componentsdiv2">

              </div>
            </div>
            <!--            2line-->

            <!--            3line-->

          </div>
        </div>

      </div>

      <div class="dividerdiv">
        <nz-divider nzPlain></nz-divider>
      </div>

      <div class="headerdivconteiner">
        <div class="conteinerdiv">
          <div class="child-1">
            <samp class="titlediv2">Contact information</samp><br><br>
            <samp class="titlediv1">Manage your contact information including name, phone number and address
              details.</samp>
          </div>
          <div class="child-2">
            <div class="componentsdiv1-1">

            </div>
            <div class="componentsdiv3">
              <div class="componentsdiv2">
                <samp class="titlediv2">BASIC DETAILS</samp>
              </div>
              <div class="componentsdiv2">

              </div>
            </div>
            <!--            2line-->
            <div class="componentsdiv">
              <div class="componentsdiv2">
                <label class="txtdiv">First name</label>
                <nz-input-group>
                  <input class="cmdiv" type="text" [(ngModel)]="firstname" nz-input/>
                </nz-input-group>

              </div>
              <div class="componentsdiv2">
                <label class="txtdiv">Last name</label>
                <nz-input-group>
                  <input class="cmdiv" type="text" [(ngModel)]="lastname" nz-input/>
                </nz-input-group>
              </div>
            </div>
            <!--            3line-->

            <div class="componentsdiv">
              <div class="componentsdiv2">
                <label class="txtdiv">Email</label>
                <nz-input-group>
                  <input class="cmdiv" type="email" [(ngModel)]="email" nz-input/>
                </nz-input-group>

              </div>
              <div class="componentsdiv2">
                <label class="txtdiv">Phone</label>
                <nz-input-group>
                  <input class="cmdiv" type="tel" [(ngModel)]="phone" nz-input/>
                </nz-input-group>
              </div>
            </div>

            <!--            4line-->

            <div class="componentsdiv">
              <div class="componentsdiv2">
                <label class="txtdiv">Website</label>
                <nz-input-group>
                  <input class="cmdiv" type="text" [(ngModel)]="website" nz-input/>
                </nz-input-group>

              </div>
              <div class="componentsdiv2">
                <label class="txtdiv">Instagram</label>
                <nz-input-group>
                  <input class="cmdiv" type="tel" [(ngModel)]="instagram" nz-input/>
                </nz-input-group>
              </div>
            </div>

            <!--            5line-->

            <div class="componentsdiv">
              <div class="componentsdiv2">
                <label class="txtdiv">Facebook</label>
                <nz-input-group>
                  <input class="cmdiv" type="text" [(ngModel)]="facebook" nz-input/>
                </nz-input-group>

              </div>
              <div class="componentsdiv2">
                <label class="txtdiv">TikTok</label>
                <nz-input-group>
                  <input class="cmdiv" type="tel" [(ngModel)]="tiktok" nz-input/>
                </nz-input-group>
              </div>
            </div>

            <!--            6line-->

            <div class="componentsdiv">
              <div class="componentsdiv2">
                <label class="txtdiv">X (Twitter)</label>
                <nz-input-group>
                  <input class="cmdiv" type="text" [(ngModel)]="twitter" nz-input/>
                </nz-input-group>

              </div>
              <div class="componentsdiv2">
                <label class="txtdiv">YouTube</label>
                <nz-input-group>
                  <input class="cmdiv" type="tel" [(ngModel)]="youtube" nz-input/>
                </nz-input-group>
              </div>
            </div>

            <div class="dividerdiv1">
              <nz-divider nzPlain></nz-divider>
            </div>

            <div class="componentsdiv3">
              <div class="componentsdiv2">
                <samp class="titlediv2">PHYSICAL ADDRESS</samp>
              </div>
              <div class="componentsdiv2">

              </div>
            </div>

            <!--            2line-->
            <div class="componentsdiv">
              <div class="componentsdiv2">
                <label class="txtdiv">Street address</label>
                <nz-input-group>
                  <input class="cmdiv" type="text" [(ngModel)]="address" nz-input/>
                </nz-input-group>

              </div>
              <div class="componentsdiv2">
                <label class="txtdiv">Street address2</label>
                <nz-input-group>
                  <input class="cmdiv" type="text" [(ngModel)]="address2" nz-input/>
                </nz-input-group>
              </div>
            </div>
            <!--            3line-->

            <div class="componentsdiv">
              <div class="componentsdiv2">
                <label class="txtdiv">Suburb</label>
                <nz-input-group>
                  <input class="cmdiv" type="text" [(ngModel)]="suburb" nz-input/>
                </nz-input-group>

              </div>
              <div class="componentsdiv2">
                <label class="txtdiv">City</label>
                <nz-input-group>
                  <input class="cmdiv" type="text" [(ngModel)]="city" nz-input/>
                </nz-input-group>
              </div>
            </div>

            <!--            4line-->
            <div class="componentsdiv">
              <div class="componentsdiv2">
                <label class="txtdiv">ZIP code</label>
                <nz-input-group>
                  <input class="cmdiv" type="text" [(ngModel)]="zipcode" nz-input/>
                </nz-input-group>

              </div>
              <div class="componentsdiv2">
                <label class="txtdiv">State</label>
                <nz-input-group nzSearch>
                  <nz-select nzShowSearch [nzShowArrow]="true" class="cmdiv"
                    [(ngModel)]="statesstring">
                    <nz-option *ngFor="let option of (states ?? [])" [nzLabel]="option['state']"
                      [nzValue]="option['state']"></nz-option>
                  </nz-select>
                </nz-input-group>
              </div>
            </div>

            <!--            5line-->
            <div class="componentsdiv">
              <div class="componentsdiv2">
                <label class="txtdiv">Country</label>
                <nz-select nzShowSearch [nzShowArrow]="true" class="cmdiv"
                  (ngModelChange)="onOptionSelected($event)"
                  [(ngModel)]="countrystring">
                  <nz-option *ngFor="let option of (countries ?? [])" [nzLabel]="option['country']"
                    [nzValue]="option['country']"></nz-option>
                </nz-select>

              </div>
              <div class="componentsdiv2">

              </div>
            </div>

            <!--            6line-->
            <div class="componentsdiv6">
              <label nz-checkbox [(ngModel)]="usedifferent">Use different address for postal address</label>
              <div class="componentsdiv2">

              </div>
            </div>

            <div *ngIf="usedifferent" class="dividerdiv1">
              <nz-divider nzPlain></nz-divider>
            </div>

            <div *ngIf="usedifferent" class="componentsdiv3">
              <div class="componentsdiv2">
                <samp class="titlediv2">POSTAL ADDRESS</samp>
              </div>
              <div class="componentsdiv2">

              </div>
            </div>

            <!--            2line-->
            <div *ngIf="usedifferent" class="componentsdiv">
              <div class="componentsdiv2">
                <label class="txtdiv">Street address</label>
                <nz-input-group>
                  <input class="cmdiv" type="text" [(ngModel)]="paddress" nz-input/>
                </nz-input-group>

              </div>
              <div class="componentsdiv2">
                <label class="txtdiv">Street address2</label>
                <nz-input-group>
                  <input class="cmdiv" type="text" [(ngModel)]="paddress2" nz-input/>
                </nz-input-group>
              </div>
            </div>
            <!--            3line-->

            <div *ngIf="usedifferent" class="componentsdiv">
              <div class="componentsdiv2">
                <label class="txtdiv">Suburb</label>
                <nz-input-group>
                  <input class="cmdiv" type="text" [(ngModel)]="psuburb" nz-input/>
                </nz-input-group>

              </div>
              <div class="componentsdiv2">
                <label class="txtdiv">City</label>
                <nz-input-group>
                  <input class="cmdiv" type="text" [(ngModel)]="pcity" nz-input/>
                </nz-input-group>
              </div>
            </div>

            <!--            4line-->
            <div *ngIf="usedifferent" class="componentsdiv">
              <div class="componentsdiv2">
                <label class="txtdiv">ZIP code</label>
                <nz-input-group>
                  <input class="cmdiv" type="text" [(ngModel)]="pzipcode" nz-input/>
                </nz-input-group>

              </div>
              <div class="componentsdiv2">
                <label class="txtdiv">State</label>
                <nz-input-group nzSearch>
                  <nz-select nzShowSearch [nzShowArrow]="true" class="cmdiv"
                    [(ngModel)]="pstatesstring">
                    <nz-option *ngFor="let option of (pstates ?? [])" [nzLabel]="option['state']"
                      [nzValue]="option['state']"></nz-option>
                  </nz-select>
                </nz-input-group>
              </div>
            </div>

            <!--            5line-->
            <div *ngIf="usedifferent" class="componentsdiv">
              <div class="componentsdiv2">
                <label class="txtdiv">Country</label>
                <nz-select nzShowSearch [nzShowArrow]="true" class="cmdiv"
                  (ngModelChange)="onOptionSelectedp($event)"
                  [(ngModel)]="pcountrystring">
                  <nz-option *ngFor="let option of (pcountries ?? [])" [nzLabel]="option['country']"
                    [nzValue]="option['country']"></nz-option>
                </nz-select>

              </div>
              <div class="componentsdiv2">

              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</div>
