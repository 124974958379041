<div class="scrollable-div-contact horizontal-container-contact">
  <div class="black-background-contact">
    <div class="full-width-caption">
      <p class="caption-text">{{ SelfData.sendoutgroupecaption }}</p>

      <div>
        <mat-checkbox [formControl]="check1" (change)="onCheckboxChange($event)">Staff loan (won’t be shown in
          reports)
        </mat-checkbox>
      </div>

      <div class="full-width-div-contact">
        <div class="black-background-company">
          <div class="form-field-spacing-contact form-field-spacing">
            <label class="line1-edits-new inputlabel">Department<span class="red-text">*</span></label>
            <nz-input-group nzSearch>
              <input disabled="true" class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="departmentstring"
                nz-input/>
            </nz-input-group>

          </div>
<!--          <mat-form-field class="form-field-spacing-contact" appearance="fill">-->
<!--            <mat-label>Department</mat-label>-->
<!--            <input matInput [formControl]="departmentFormControl" required/>-->
<!--          </mat-form-field>-->

          <div class="form-field-spacing-contact">
            <label class="line1-edits-new inputlabel">Sender<span class="red-text">*</span></label>
            <nz-input-group [nzStatus]="statussender" nzSearch>
              <nz-select (focus)="onBlurAll()" nzShowSearch [nzShowArrow]="true" class="line1-edits-new inputedit testdiv"
                (ngModelChange)="onSenderSelect($event)"
                [(ngModel)]="senderstring">
                <nz-option *ngFor="let option of iSystemUsersResult[0]?.data" [nzLabel]="option.firstname + ' ' + option.lastname"
                  [nzValue]="option.firstname + ' ' + option.lastname"></nz-option>
              </nz-select>
            </nz-input-group>

          </div>

<!--          <mat-form-field class="form-field-spacing-contact" appearance="fill">-->
<!--            <mat-label>Sender</mat-label>-->
<!--            <mat-select [formControl]="senderFormControl" required>-->
<!--              <mat-option *ngFor="let systemuser of iSystemUsersResult"-->
<!--                [value]="systemuser.data[0].firstname + ' ' + systemuser.data[0].lastname"-->
<!--                (onSelectionChange)="onSenderSelect($event)">-->
<!--                {{ systemuser.data[0].firstname + ' ' + systemuser.data[0].lastname }}-->
<!--              </mat-option>-->
<!--            </mat-select>-->
<!--            <mat-error *ngIf="senderFormControl.hasError('required')">-->
<!--              Sender is <strong>required</strong>-->
<!--            </mat-error>-->
<!--          </mat-form-field>-->

          <div class="form-field-spacing-contact form-field-spacing">
            <label class="line1-edits-new inputlabel">Recipient<span class="red-text">*</span></label>
            <nz-input-group nzSearch [nzAddOnAfter]="recipientbutton">
              <nz-select
                nzShowSearch
                [nzStatus]="statusrecipiend"
                [nzShowArrow]="true"
                class="line1-edits-new inputedit testdiv1"
                (ngModelChange)="onChange($event, 0)"
                [(ngModel)]="recipiendstring">
                <nz-option *ngFor="let option of (iContactsResult[0]?.data ?? [])"
                  [nzLabel]="(option['firstname'] ? option['firstname'] : '') + (option['firstname'] && option['lastname'] ? ' ' : '') + (option['lastname'] ? option['lastname'] : '')"
                  [nzValue]="(option['firstname'] ? option['firstname'] : '') + (option['firstname'] && option['lastname'] ? ' ' : '') + (option['lastname'] ? option['lastname'] : '')">
                </nz-option>
              </nz-select>
            </nz-input-group>
            <ng-template #recipientbutton>
              <button *ngIf="showeditbutton" type="button" (click)="addCompany($event, 1, null)" nz-button nzType="default" nzSearch><span nz-icon
                nzType="edit"></span></button>
              <button type="button" (click)="addCompany($event, 0, null)" nz-button nzType="default" nzSearch><span nz-icon
                nzType="plus"></span></button>
            </ng-template>
          </div>

<!--          <mat-form-field class="form-field-spacing-contact" appearance="fill">-->
<!--            <mat-label>Recipient</mat-label>-->
<!--            <input type="text" matInput placeholder="Select or type..." [matAutocomplete]="auto1"-->
<!--              [formControl]="companynameControl"-->
<!--              (change)="onChange($event, 0)"-->
<!--              #autoInput1="matAutocompleteTrigger"/>-->
<!--            <button *ngIf="showeditbutton" mat-stroked-button type="button" class="add-button-contact"-->
<!--              matSuffix-->
<!--              (click)="addCompany($event, 1, autoInput1)">-->
<!--              <mat-icon class="center-icon">edit</mat-icon>-->
<!--            </button>-->
<!--            <button mat-stroked-button type="button" class="add-button-contact" matSuffix-->
<!--              (click)="addCompany($event, 0, autoInput1)">-->
<!--              <mat-icon class="center-icon">add</mat-icon>-->
<!--            </button>-->
<!--            <mat-autocomplete #auto1="matAutocomplete">-->
<!--              <mat-option-->
<!--                *ngFor="let item of filteredContactsOptions | async"-->
<!--                [value]="item"-->
<!--                (onSelectionChange)="onOptionSelected($event, 0)">-->
<!--                {{ item }}-->
<!--              </mat-option>-->
<!--            </mat-autocomplete>-->
<!--            <mat-error *ngIf="senderFormControl.hasError('required')">-->
<!--              Recipient is <strong>required</strong>-->
<!--            </mat-error>-->
<!--          </mat-form-field>-->

          <div class="form-field-spacing-contact form-field-spacing">
            <label class="line1-edits-new inputlabel">Return Point of Contact</label>
            <nz-input-group [nzStatus]="statusreturned" nzSearch [nzAddOnAfter]="returnbutton">
              <nz-select
                [disabled] = "!isButtonDisabled || !returnedenabled"
                nzShowSearch
                nzAllowClear
                [nzShowArrow]="true"
                class="line1-edits-new inputedit testdiv1"
                (ngModelChange)="onChange($event, 1)"
                [(ngModel)]="returnedstring">
                <nz-option *ngFor="let option of (iContactsResult[0]?.data ?? [])"
                  [nzLabel]="option['firstname']+' '+option['lastname']"
                  [nzValue]="option['firstname']+' '+option['lastname']">
                </nz-option>
              </nz-select>
            </nz-input-group>
            <ng-template #returnbutton>
              <button [disabled]="!isButtonDisabled || !returnedenabled" *ngIf="showreturneditbutton" type="button" (click)="addCompany($event, 1, null)" nz-button nzType="default" nzSearch><span nz-icon
                nzType="edit"></span></button>
              <button [disabled]="!isButtonDisabled || !returnedenabled" type="button" (click)="addCompany($event, 0, null)" nz-button nzType="default" nzSearch><span nz-icon
                nzType="plus"></span></button>
            </ng-template>
          </div>

<!--          <mat-form-field class="form-field-spacing-contact" appearance="fill">-->
<!--            <mat-label>Return Point of Contact</mat-label>-->
<!--            <input type="text" matInput placeholder="Select or type..." [matAutocomplete]="auto2"-->
<!--              [formControl]="returnControl" #autoInput2="matAutocompleteTrigger"/>-->
<!--            <button *ngIf="showreturneditbutton" mat-stroked-button type="button" class="add-button-contact" matSuffix-->
<!--              (click)="addCompany($event, 1, autoInput2)"-->
<!--              [disabled]="!isButtonDisabled">-->
<!--              <mat-icon class="center-icon">edit</mat-icon>-->
<!--            </button>-->
<!--            <button mat-stroked-button type="button" class="add-button-contact" matSuffix-->
<!--              (click)="addCompany($event, 0, autoInput2)"-->
<!--              [disabled]="!isButtonDisabled">-->
<!--              <mat-icon class="center-icon">add</mat-icon>-->
<!--            </button>-->
<!--            <mat-autocomplete #auto2="matAutocomplete">-->
<!--              <mat-option *ngFor="let item of (filteredReturnOptions | async) ?? []" [value]="item"-->
<!--                (onSelectionChange)="onOptionSelected($event, 1)">-->
<!--                {{ item }}-->
<!--              </mat-option>-->
<!--            </mat-autocomplete>-->
<!--          </mat-form-field>-->
          <div class="form-field-spacing-contact">
            <label class="line1-edits-new inputlabel">Send Out Type<span class="red-text">*</span></label>
            <nz-input-group [nzStatus]="statussendouttype" nzSearch>
              <nz-select (focus)="onBlurAll()" nzShowSearch [nzShowArrow]="true" class="line1-edits-new inputedit testdiv"
                (ngModelChange)="onSelectionChange($event)"
                [(ngModel)]="sendouttypestring">
                <nz-option *ngFor="let option of (sendouttype ?? [])" [nzLabel]="option.value"
                  [nzValue]="option.value"></nz-option>
              </nz-select>
            </nz-input-group>

          </div>

<!--          <mat-form-field class="form-field-spacing-contact" appearance="fill">-->
<!--            <mat-label>Send Out Type</mat-label>-->
<!--            <mat-select [formControl]="sendOutTypeControl" (selectionChange)="onSelectionChange($event)">-->
<!--              <mat-option *ngFor="let st of sendouttype" [value]="st.value">-->
<!--                &lt;!&ndash;                <span class="color-box" [style.background]="color.colorcode"></span>&ndash;&gt;-->
<!--                {{ st.value }}-->
<!--              </mat-option>-->
<!--            </mat-select>-->
<!--            <mat-error *ngIf="sendOutTypeControl.hasError('required')">-->
<!--              Send Out Type is <strong>required</strong>-->
<!--            </mat-error>-->
<!--          </mat-form-field>-->
        </div>

        <div class="black-background-company">
          <div class="form-field-spacing-contact">
            <label class="line1-edits-new inputlabel">Currency<span class="red-text">*</span></label>
            <nz-input-group [nzStatus]="statussecurrency" nzSearch>
              <nz-select (focus)="onBlurAll()" nzShowSearch [nzShowArrow]="true" class="line1-edits-new inputedit testdiv"
                [(ngModel)]="currencystring">
                <nz-option *ngFor="let option of (iCurrancyResult[0]?.data ?? [])" [nzLabel]="option.description"
                  [nzValue]="option.description"></nz-option>
              </nz-select>
            </nz-input-group>

          </div>

<!--          <mat-form-field class="form-field-spacing-contact" appearance="fill">-->
<!--            <mat-label>Currency</mat-label>-->
<!--            <mat-select [formControl]="currancyControl" required>-->
<!--              <mat-option-->
<!--                *ngFor="let currancy of iCurrancyResult[0]?.data"-->
<!--                [value]="currancy.description"-->
<!--              >-->
<!--                {{ currancy.description }}-->
<!--              </mat-option>-->
<!--            </mat-select>-->
<!--            <mat-error *ngIf="senderFormControl.hasError('required')">-->
<!--              Currency is <strong>required</strong>-->
<!--            </mat-error>-->
<!--          </mat-form-field>-->

          <div class="form-field-spacing-contact">
            <label class="line1-edits-new inputlabel">Reservation Date</label>
            <nz-input-group nzSearch>
              <nz-date-picker class="line1-edits-new inputedit testdiv"
                [nzStatus]="statusreservation"
                [nzAllowClear]="true"
                nzFormat="yyyy-MM-dd HH:mm"
                [ngModel]="reservationdate"
                (ngModelChange)="onDateChange($event)"
                (nzOnOk)="onOk($event)"
                [nzShowTime]="{ nzDefaultOpenValue: timeDefaultValue }"
              ></nz-date-picker>
            </nz-input-group>
          </div>

<!--            <mat-form-field-->
<!--              class="form-field-spacing-contact"-->
<!--              appearance="fill"-->
<!--            >-->
<!--              <mat-label>Reservation Date</mat-label>-->
<!--              <mat-datetimepicker-toggle-->
<!--                [for]="datetimePicker"-->
<!--                matSuffix-->
<!--              ></mat-datetimepicker-toggle>-->
<!--              <mat-datetimepicker-->
<!--                #datetimePicker-->
<!--                type="datetime"-->
<!--                mode="landscape"-->
<!--                [openOnFocus]="true"-->
<!--                [timeInterval]="30"-->
<!--              >-->
<!--              </mat-datetimepicker>-->
<!--              <input-->
<!--                matInput-->
<!--                formControlName="datetimeCtrl"-->
<!--                [matDatetimepicker]="datetimePicker"-->
<!--                required-->
<!--                autocomplete="false"-->
<!--              />-->
<!--              <mat-error-->
<!--                *ngIf="-->
<!--                  group.get('datetimeCtrl')?.invalid &&-->
<!--                  group.get('datetimeCtrl')?.hasError('required')-->
<!--                "-->
<!--              >-->
<!--                Reservation Date is <strong>required</strong>-->
<!--              </mat-error>-->
<!--            </mat-form-field>-->

            <div class="form-field-spacing-contact">
              <label class="line1-edits-new inputlabel">Exp. Return Date<span class="red-text">*</span></label>
              <nz-input-group nzSearch>
                <nz-date-picker class="line1-edits-new inputedit testdiv"
                  [nzStatus]="statusreturneddate"
                  [nzDisabled] = "!returneddateenabled"
                  [nzAllowClear]="false"
                  nzFormat="yyyy-MM-dd HH:mm"
                  [ngModel]="expreturndate"
                  (ngModelChange)="onDateChange1($event)"
                  (nzOnOk)="onOk1($event)"
                  [nzShowTime]="{ nzDefaultOpenValue: timeDefaultValueReturned }"
                ></nz-date-picker>
              </nz-input-group>
            </div>

<!--            <mat-form-field class="form-field-spacing-contact" appearance="fill">-->
<!--              <mat-label>Exp. Return Date</mat-label>-->
<!--              <mat-datetimepicker-toggle [for]="datetimePicker1" matSuffix></mat-datetimepicker-toggle>-->
<!--              <mat-datetimepicker #datetimePicker1 type="datetime" mode="landscape" [openOnFocus]="true"-->
<!--                [timeInterval]="30">-->
<!--              </mat-datetimepicker>-->
<!--              <input matInput formControlName="datetimeCtrl1" [matDatetimepicker]="datetimePicker1" required-->
<!--                autocomplete="false"/>-->
<!--              <mat-error-->
<!--                *ngIf="-->
<!--                  group.get('datetimeCtrl1')?.invalid &&-->
<!--                  group.get('datetimeCtrl1')?.hasError('required')-->
<!--                "-->
<!--              >-->
<!--                Exp. Return Date is <strong>required</strong>-->
<!--              </mat-error>-->
<!--            </mat-form-field>-->

          <div class="form-field-spacing-contact">
            <label class="line1-edits-new inputlabel">Requested By</label>
            <nz-input-group [nzStatus]="statusrequestby" nzSearch>
              <nz-select (focus)="onBlurAll()" nzShowSearch [nzShowArrow]="true" class="line1-edits-new inputedit testdiv"
                [nzAllowClear]="true"
                [(ngModel)]="requestbystring">
                <nz-option *ngFor="let option of (iSystemUsersRequstedResult[0]?.data ?? [])" [nzLabel]="option.firstname + ' ' + option.lastname"
                  [nzValue]="option.firstname + ' ' + option.lastname"></nz-option>
              </nz-select>
            </nz-input-group>
          </div>

          <div class="form-field-spacing-contact form-field-spacing">
            <label class="line1-edits-new inputlabel">Type of Dispatch<span class="red-text">*</span></label>
            <nz-input-group nzSearch [nzAddOnAfter]="dispatchbutton">
              <nz-select
                nzShowSearch
                [nzStatus]="statusdispatch"
                [nzShowArrow]="true"
                class="line1-edits-new inputedit testdiv1"
                (ngModelChange)="onChange($event, 0)"
                [(ngModel)]="dispatchstring">
                <nz-option *ngFor="let option of (iTODResult[0]?.data ?? [])"
                  [nzLabel]="option.description"
                  [nzValue]="option.description">
                </nz-option>
              </nz-select>
            </nz-input-group>
            <ng-template #dispatchbutton>
              <button type="button" (click)="opendragdropframe($event, -1, null)" nz-button nzType="default" nzSearch><span nz-icon
                nzType="plus"></span></button>
            </ng-template>
          </div>

<!--          <mat-form-field class="form-field-spacing-contact" appearance="fill">-->
<!--            <mat-label>Requested By</mat-label>-->
<!--            <mat-select [formControl]="requestedbyFormControl" required>-->
<!--              <mat-option *ngFor="let systemuser of iSystemUsersRequstedResult"-->
<!--                [value]="systemuser.data[0].firstname + ' ' + systemuser.data[0].lastname">-->
<!--                {{ systemuser.data[0].firstname + ' ' + systemuser.data[0].lastname }}-->
<!--              </mat-option>-->
<!--            </mat-select>-->
<!--            <mat-error *ngIf="requestedbyFormControl.hasError('required')">-->
<!--              Requested By is <strong>required</strong>-->
<!--            </mat-error>-->
<!--          </mat-form-field>-->
        </div>
      </div>
    </div>
  </div>

  <div class="black-background-3Line">
    <div class="full-width-caption">
      <p class="caption-text">Project Details</p>
      <div class="full-width-div-3line">
        <div class="black-background-company">
          <div class="example-full-width form-field-spacing">
            <label class="line1-edits-new inputlabel">Celeb/Influenc/Model</label>
            <nz-input-group nzSearch>
              <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="cimsstring" nz-input/>
            </nz-input-group>
          </div>
<!--          <mat-form-field class="form-field-spacing-contact" appearance="fill">-->
<!--            <mat-label>Celeb/Influenc/Model</mat-label>-->
<!--            <input matInput [formControl]="cimControl"/>-->
<!--          </mat-form-field>-->

<!--          <div class="form-field-spacing-contact">-->
<!--            <label class="line1-edits-new inputlabel">Project/Event</label>-->
<!--            <nz-input-group nzSearch [nzAddOnAfter]="pebutton">-->
<!--              <nz-select (focus)="onBlurAll()" nzShowSearch [nzShowArrow]="true" class="line1-edits-new inputedit testdiv"-->
<!--                [nzAllowClear]="true"-->
<!--                [(ngModel)]="pestring">-->
<!--                <nz-option *ngFor="let option of (iPEResult[0]?.data ?? [])" [nzLabel]="option.description"-->
<!--                  [nzValue]="option.description"></nz-option>-->
<!--              </nz-select>-->
<!--            </nz-input-group>-->
<!--            <ng-template #pebutton>-->
<!--              <button type="button" (click)="opendragdropframe($event, -2, null)" nz-button nzType="default" nzSearch><span nz-icon-->
<!--                nzType="plus"></span></button>-->
<!--            </ng-template>-->

<!--          </div>-->

          <div class="example-full-width form-field-spacing">
            <label class="line1-edits-new inputlabel">Project/Event</label>
            <nz-input-group nzSearch>
              <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="pestring" nz-input/>
            </nz-input-group>
          </div>

<!--          <mat-form-field class="form-field-spacing-contact" appearance="fill">-->
<!--            <mat-label>Project/Event</mat-label>-->
<!--            <input type="text" matInput placeholder="Select or type..." [matAutocomplete]="auto4"-->
<!--              [formControl]="peControl" #autoInput4="matAutocompleteTrigger"/>-->

<!--            <button mat-stroked-button type="button" class="add-button-contact" matSuffix-->
<!--              (click)="opendragdropframe($event, -2, autoInput4)">-->
<!--              <mat-icon class="center-icon">add</mat-icon>-->
<!--            </button>-->
<!--            <mat-autocomplete #auto4="matAutocomplete">-->
<!--              <mat-option *ngFor="let item of iPEResult[0]?.data" [value]="item.description">-->
<!--                {{ item.description }}-->
<!--              </mat-option>-->
<!--            </mat-autocomplete>-->
<!--          </mat-form-field>-->

          <div class="form-field-spacing-contact">
            <label class="line1-edits-new inputlabel">Publication</label>
            <nz-input-group nzSearch [nzAddOnAfter]="publicationbutton">
              <nz-select (focus)="onBlurAll()" nzShowSearch [nzShowArrow]="true" class="line1-edits-new inputedit testdiv"
                [nzAllowClear]="true"
                [(ngModel)]="pestring">
                <nz-option *ngFor="let option of (iCompanyResult[0]?.data ?? [])" [nzLabel]="option.companyname"
                  [nzValue]="option.companyname"></nz-option>
              </nz-select>
            </nz-input-group>
            <ng-template #publicationbutton>
              <button type="button" (click)="addPublication($event, -4, null)" nz-button nzType="default" nzSearch><span nz-icon
                nzType="plus"></span></button>
            </ng-template>

          </div>

<!--          <mat-form-field class="form-field-spacing-contact" appearance="fill">-->
<!--            <mat-label>Publication</mat-label>-->
<!--            <input-->
<!--              type="text"-->
<!--              matInput-->
<!--              placeholder="Select or type..."-->
<!--              [matAutocomplete]="auto6"-->
<!--              [formControl]="publicationControl"-->
<!--              #autoInput6="matAutocompleteTrigger"-->
<!--            />-->

<!--            <button-->
<!--              mat-stroked-button-->
<!--              type="button"-->
<!--              class="add-button-contact"-->
<!--              matSuffix-->
<!--              (click)="addPublication($event, -4, autoInput6)"-->
<!--            >-->
<!--              <mat-icon class="center-icon">add</mat-icon>-->
<!--            </button>-->
<!--            <mat-autocomplete #auto6="matAutocomplete">-->
<!--              <mat-option-->
<!--                *ngFor="let item of filteredCompanyNameOptions | async"-->
<!--                [value]="item"-->
<!--              >-->
<!--                {{ item }}-->
<!--              </mat-option>-->
<!--            </mat-autocomplete>-->
<!--          </mat-form-field>-->

          <div class="form-field-spacing-contact">
            <label class="line1-edits-new inputlabel">Issue</label>
            <nz-input-group nzSearch [nzAddOnAfter]="issuebutton">
              <nz-select (focus)="onBlurAll()" nzShowSearch [nzShowArrow]="true" class="line1-edits-new inputedit testdiv"
                [nzAllowClear]="true"
                [(ngModel)]="issuestring">
                <nz-option *ngFor="let option of (iIssueResult[0]?.data ?? [])" [nzLabel]="option.description"
                  [nzValue]="option.description"></nz-option>
              </nz-select>
            </nz-input-group>
            <ng-template #issuebutton>
              <button type="button" (click)="opendragdropframe($event, -3, null)" nz-button nzType="default" nzSearch><span nz-icon
                nzType="plus"></span></button>
            </ng-template>

          </div>

<!--          <mat-form-field class="form-field-spacing-contact" appearance="fill">-->
<!--            <mat-label>Issue</mat-label>-->
<!--            <input-->
<!--              type="text"-->
<!--              matInput-->
<!--              placeholder="Select or type..."-->
<!--              [matAutocomplete]="auto5"-->
<!--              [formControl]="issueControl"-->
<!--              #autoInput5="matAutocompleteTrigger"-->
<!--            />-->

<!--            <button-->
<!--              mat-stroked-button-->
<!--              type="button"-->
<!--              class="add-button-contact"-->
<!--              matSuffix-->
<!--              (click)="opendragdropframe($event, -3, autoInput5)"-->
<!--            >-->
<!--              <mat-icon class="center-icon">add</mat-icon>-->
<!--            </button>-->
<!--            <mat-autocomplete #auto5="matAutocomplete">-->
<!--              <mat-option-->
<!--                *ngFor="let item of iIssueResult[0]?.data"-->
<!--                [value]="item.description"-->
<!--              >-->
<!--                {{ item.description }}-->
<!--              </mat-option>-->
<!--            </mat-autocomplete>-->
<!--          </mat-form-field>-->
        </div>

        <div class="black-background-company">
          <div class="example-full-width form-field-spacing">
            <label class="line1-edits-new inputlabel">Editor/Stylist</label>
            <nz-input-group nzSearch>
              <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="esstring" nz-input/>
            </nz-input-group>
          </div>
<!--          <mat-form-field class="form-field-spacing-contact" appearance="fill">-->
<!--            <mat-label>Editor/Stylist</mat-label>-->
<!--            <input matInput [formControl]="estylistControl"/>-->
<!--          </mat-form-field>-->

          <div class="example-full-width form-field-spacing">
            <label class="line1-edits-new inputlabel">Photographer</label>
            <nz-input-group nzSearch>
              <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="photograferstring" nz-input/>
            </nz-input-group>
          </div>

<!--          <mat-form-field class="form-field-spacing-contact" appearance="fill">-->
<!--            <mat-label>Photographer</mat-label>-->
<!--            <input matInput [formControl]="photographerControl"/>-->
<!--          </mat-form-field>-->

          <div class="example-full-width form-field-spacing">
            <label class="line1-edits-new inputlabel">Project/Event Location</label>
            <nz-input-group nzSearch>
              <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="pelstring" nz-input/>
            </nz-input-group>
          </div>

<!--          <mat-form-field class="form-field-spacing-contact" appearance="fill">-->
<!--            <mat-label>Project/Event Location</mat-label>-->
<!--            <input matInput [formControl]="pelControl"/>-->
<!--          </mat-form-field>-->

          <div class="example-full-width form-field-spacing">
            <label class="line1-edits-new inputlabel">Project/Event Theme</label>
            <nz-input-group nzSearch>
              <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="petstring" nz-input/>
            </nz-input-group>
          </div>

<!--          <mat-form-field class="form-field-spacing-contact" appearance="fill">-->
<!--            <mat-label>Project/Event Theme</mat-label>-->
<!--            <input matInput [formControl]="petControl"/>-->
<!--          </mat-form-field>-->
        </div>

        <div class="black-background-company">
          <div class="example-full-width form-field-spacing">
            <label class="line1-edits-new inputlabel">Run-through Date</label>
            <nz-date-picker [nzAllowClear]="true" class="line1-edits-new inputedit testdiv" [(ngModel)]="runthroughdate"></nz-date-picker>
          </div>
<!--          <form [formGroup]="group">-->
<!--            <mat-form-field class="form-field-spacing-contact" appearance="fill">-->
<!--              <mat-label>Run-through Date</mat-label>-->
<!--              <input matInput [matDatepicker]="dp" formControlName="datetimeCtrl2"/>-->
<!--              <mat-datepicker-toggle matIconSuffix [for]="dp">-->
<!--              </mat-datepicker-toggle>-->
<!--              <mat-datepicker #dp></mat-datepicker>-->
<!--            </mat-form-field>-->

            <div class="example-full-width form-field-spacing">
              <label class="line1-edits-new inputlabel">Project/Event Dates</label>
<!--              <nz-date-picker [nzAllowClear]="true" class="line1-edits-new inputedit testdiv" [(ngModel)]="runthroughdate"></nz-date-picker>-->
              <nz-range-picker [nzAllowClear]="true" class="line1-edits-new inputedit testdiv" [(ngModel)]="pedsDate"></nz-range-picker>
            </div>

<!--            <mat-form-field class="form-field-spacing-contact" appearance="fill">-->
<!--              <mat-label>Project/Event Dates</mat-label>-->
<!--              <mat-date-range-input [rangePicker]="picker" formGroupName="datetimeRange">-->
<!--                <input matStartDate formControlName="start" placeholder="Start date"/>-->
<!--                <input matEndDate formControlName="end" placeholder="End date"/>-->
<!--              </mat-date-range-input>-->
<!--              <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>-->
<!--              <mat-date-range-picker #picker></mat-date-range-picker>-->
<!--            </mat-form-field>-->

            <!--                        <mat-form-field class="form-field-spacing-contact" appearance="fill">-->
            <!--                            <input matInput formControlName="datetimeCtrl3" placeholder="Another Date"/>-->
            <!--                        </mat-form-field>-->


            <!--                        <button mat-button (click)="disableControls()">Disable</button>-->
            <!--                        <button mat-button (click)="enableControls()">Enable</button>-->
<!--          </form>-->

          <div class="example-full-width form-field-spacing form-field-spacing-contact">
            <label class="line1-edits-new inputlabel">Notes</label><br>
            <textarea [(ngModel)]="descriptionstring" style="height: 86px" rows="3" cols="30" class="line1-edits-new inputedit testdiv2 dopinfo"
              nz-input
            ></textarea>
          </div>

<!--          <mat-form-field class="form-field-spacing-contact form-field-hh">-->
<!--            <mat-label>Notes</mat-label>-->
<!--            <textarea-->
<!--              class="no-resize"-->
<!--              matInput-->
<!--              [formControl]="notesControl"-->
<!--              style="height: 160px"-->
<!--            ></textarea>-->
<!--          </mat-form-field>-->
        </div>
        <div class="full-width-div-5line">

          <div class="form-field-spacing-contact">
            <label class="line1-edits-new inputlabel">Clients</label>
            <nz-input-group nzSearch [nzAddOnAfter]="clientsbutton">
              <nz-select (focus)="onBlurAll()" nzShowSearch [nzShowArrow]="true" class="line1-edits-new inputedit testdiv"
                [nzAllowClear]="true"
                [(ngModel)]="clientsstring">
                <nz-option *ngFor="let option of optionsCelebriti" [nzLabel]="option.description"
                  [nzValue]="option.description"></nz-option>
              </nz-select>
            </nz-input-group>
            <ng-template #clientsbutton>
              <button *ngIf="showsmbutton" type="button" (click)="showCelebriti($event, null); op.toggle($event)" nz-button nzType="default" nzSearch>
                <span nz-icon nzType="user"></span>
              </button>
              <button type="button" (click)="opendragdropframe($event, -4, null)" nz-button nzType="default" nzSearch>
                <span nz-icon nzType="plus"></span>
              </button>
            </ng-template>

          </div>

<!--          <mat-form-field class="form-field-spacing-contact" appearance="fill">-->
<!--            <mat-label>Clients</mat-label>-->
<!--            <input type="text" matInput placeholder="Select or type..." [matAutocomplete]="autocelebriti"-->
<!--              [formControl]="celebritiControl" #autoInputcelebriti="matAutocompleteTrigger"/>-->
<!--            <button *ngIf="showsmbutton" mat-stroked-button type="button" class="add-button-contact"-->
<!--              (click)="showCelebriti($event, autoInputcelebriti); op.toggle($event)"-->
<!--              matSuffix>-->
<!--              <mat-icon class="center-icon1">account_circle</mat-icon>-->
<!--            </button>-->

<!--            <button mat-stroked-button type="button" class="add-button-contact" matSuffix-->
<!--              (click)="opendragdropframe($event, -4, autoInputcelebriti)">-->
<!--              <mat-icon class="center-icon">add</mat-icon>-->
<!--            </button>-->

<!--            <mat-autocomplete #autocelebriti="matAutocomplete">-->
<!--              <mat-option *ngFor="let item of iCelebritiResult[0]?.data" [value]="item.description"-->
<!--                (onSelectionChange)="onCelebritiSelected($event)">-->
<!--                {{ item.description }}-->

<!--              </mat-option>-->
<!--            </mat-autocomplete>-->
<!--          </mat-form-field>-->
        </div>
      </div>
    </div>
  </div>

  <div class="black-background-1">
    <div class="full-width-caption1">
      <p class="caption-text1">Shipping Details</p>
      <div class="full-width-div-contact">
        <div class="black-background-company">
          <mat-form-field class="form-field-spacing-contact form-field-h">
            <mat-label>Ship from Address</mat-label>
            <textarea matInput class="no-resize" [formControl]="shipFormControl" style="height: 200px"
              [class.disabled]="shiptoControl.disabled">
                        </textarea>
          </mat-form-field>

          <mat-form-field class="form-field-spacing-contact form-field-h">
            <mat-label>Ship to Address</mat-label>
            <textarea
              class="no-resize"
              matInput
              [formControl]="shiptoControl"
              style="height: 200px"
              [class.disabled]="shiptoControl.disabled"
            ></textarea>
          </mat-form-field>

<!--          <mat-form-field class="form-field-spacing-contact" appearance="fill">-->
<!--            <mat-label>Type of Dispatch</mat-label>-->
<!--            <input type="text" matInput placeholder="Select or type..." [matAutocomplete]="auto3"-->
<!--              [formControl]="todControl" #autoInput3="matAutocompleteTrigger"/>-->

<!--            <button-->
<!--              mat-stroked-button-->
<!--              type="button"-->
<!--              class="add-button-contact"-->
<!--              matSuffix-->
<!--              (click)="opendragdropframe($event, -1, autoInput3)"-->
<!--            >-->
<!--              <mat-icon class="center-icon">add</mat-icon>-->
<!--            </button>-->
<!--            <mat-autocomplete #auto3="matAutocomplete">-->
<!--              <mat-option-->
<!--                *ngFor="let item of iTODResult[0]?.data"-->
<!--                [value]="item.description"-->
<!--              >-->
<!--                {{ item.description }}-->
<!--              </mat-option>-->
<!--            </mat-autocomplete>-->
<!--            <mat-error *ngIf="senderFormControl.hasError('required')">-->
<!--              Type of Dispatch is <strong>required</strong>-->
<!--            </mat-error>-->
<!--          </mat-form-field>-->

          <div class="example-full-width form-field-spacing">
            <label class="line1-edits-new inputlabel">Cost of Shipping</label>
            <nz-input-group nzSearch>
              <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="costofshipping" nz-input/>
            </nz-input-group>
          </div>

<!--          <mat-form-field class="form-field-spacing-contact" appearance="fill">-->
<!--            <mat-label>Cost of Shipping</mat-label>-->
<!--            <input matInput [formControl]="costofshippingControl"/>-->
<!--          </mat-form-field>-->

          <div class="example-full-width form-field-spacing">
            <label class="line1-edits-new inputlabel">Tracking Number</label>
            <nz-input-group nzSearch>
              <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="trackingNumber" nz-input/>
            </nz-input-group>
          </div>

<!--          <mat-form-field class="form-field-spacing-contact" appearance="fill">-->
<!--            <mat-label>Tracking Number</mat-label>-->
<!--            <input matInput [formControl]="trackingNumberControl"/>-->
<!--          </mat-form-field>-->
        </div>

        <div class="black-background-company">
          <div class="top-div-dd">
            <p class="caption-text-dd">Address Label Preview</p>
            <button class="add-button-contact" type="button" (click)="onButtonEditLabelClick()" nz-button nzType="default" nzSearch><span nz-icon
              nzType="edit"></span></button>
<!--            <button-->
<!--              mat-stroked-button-->
<!--              type="button"-->
<!--              class="add-button-contact"-->
<!--              matSuffix-->
<!--              (click)="onButtonEditLabelClick()"-->
<!--            >-->
<!--              <mat-icon class="center-icon">edit</mat-icon>-->
<!--            </button>-->
          </div>
          <div class="botton-div-dd">
            <mat-form-field
              class="form-field-spacing-contact form-field-spacing-dd"
            >
              <!--              <mat-label>Ship to Address</mat-label>-->
              <textarea matInput placeholder="Please select a recipient first" class="no-resize"
                style="height: 300px"
                [formControl]="addresslabelControl"
                [class.disabled]="shiptoControl.disabled">

                            </textarea>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="black-background-4Line">
    <div class="full-width-caption">
      <p class="caption-text">Receiving Details</p>

      <div class="full-width-div-4line">
        <div class="black-background-company">
          <mat-checkbox [formControl]="check2"
          >Handoff Samples to another department
          </mat-checkbox
          >
          <mat-checkbox [formControl]="check3"
          >Pull original Air Waybill Number from the Receiving
            Department
          </mat-checkbox
          >
        </div>
      </div>
    </div>
  </div>

</div>

<p-overlayPanel #op>
  <mat-card class="overlay-card">
    <div class="black-background-social">
      <div class="child-40">
        <div class="content-wrapper">
          <div class="thema-image shadow-4" [style.background-image]="'url(' + defaultimagelink + ')'"></div>
        </div>
      </div>

      <div class="child-60">
        <div class="top-center-full-width">
          <span class="centered-text">{{ defaultname }}</span>
        </div>
        <mat-divider class="black-divider"></mat-divider>
        <div class="black-background-caption">
          <div class="caption-40">
            <span class="left-text-caption">CHANNEL</span>
          </div>
          <div class="caption-60">
            <span class="right-text-caption">FOLLOWERS</span>
          </div>
        </div>
        <mat-divider class="black-divider"></mat-divider>
        <div class="centered-div-social" *ngFor="let item of sortedSMedia(); let i = index"
          [ngStyle]="{ 'background-color': getColor(i) }">
          <span class="left-text" (click)="openLink(item.link)">{{ item.platform }}</span>
          <span class="right-text">{{ item.subscribers | formatCount }}</span>
        </div>

      </div>
    </div>

  </mat-card>
</p-overlayPanel>
