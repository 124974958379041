import { Component } from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";

@Component({
  selector: 'app-celebritiframe',
  templateUrl: './celebritiframe.component.html',
  styleUrls: ['./celebritiframe.component.scss']
})
export class CelebritiframeComponent {
  constructor(public modalService: ModalserviceService) {

  }
}
