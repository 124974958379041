<p-button class="info-btn" icon="pi pi-info" [rounded]="true" [outlined]="true" (click)="onInfoButtonClick()"/>
<button mat-icon-button type="button" class="close-btn" (click)="onButtonCloseClick()">
  <mat-icon>close</mat-icon>
</button>
<p class="sample-manager-text">Catalogs of items</p>

<div class="black-background-blt">
  <div class="card example-container-blt mat-elevation-z1">
    <p-table
      #dt1
      [value]="datasource"
      [paginator]="true"
      [rows]="10"
      [showCurrentPageReport]="true"
      [tableStyle]="{ 'width': '100%'}"
      [scrollable]="true"
      rowGroupMode="subheader"
      groupRowsBy="designer"
      [scrollHeight]="'calc(90vh - 200px)'"
      [styleClass]="'p-datatable-gridlines p-datatable-sm'"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [globalFilterFields]="['collection', 'season', 'seasoncode', 'seasonyear', 'designer']"
      selectionMode="single"
      [rowsPerPageOptions]="[10, 25, 50, 100]">
      <ng-template pTemplate="caption">
        <div class="flex">
          <span class="p-inputgroup-addon custom-hover" matRipple (click)="onButtonAddClick()">
            <i class="pi pi-plus" style="color: #000000"></i></span>
          <span class="p-input-icon-left ml-auto">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <i class="pi pi-search" style="color: #000000"></i>
              </span>
              <input pInputText type="text" (input)="onInput($event)" placeholder="Filter" [(ngModel)]="filtertext"/>
              <span class="p-inputgroup-addon custom-hover-red" matRipple (click)="clear(dt1)">
                <i class="pi pi-times" style="color: #000000"></i></span>
            </div>
          </span>

        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width:1%"></th>
          <th style="width:10%">Collection</th>
          <th style="width:10%">Season</th>
          <th style="width:10%">Season Code</th>
          <th style="width:10%">Season Year</th>
<!--          <th style="width:10%">Designer</th>-->
          <th style="width:1%"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="groupheader" let-catalog>
        <tr pRowGroupHeader>
          <td colspan="6">
            <span class="font-bold ml-2">{{catalog.designer}}</span>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="groupfooter" let-catalog>
        <tr>
          <td colspan="6" class="text-right font-bold pr-3">Total Catalogs: {{calculateCustomerTotal(catalog.designer)}}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-catalog>
        <tr [pSelectableRow]="catalog">
          <div class="optionbuttons">
            <td>
              <p-button label="Select" size="small" [outlined]="true" severity="secondary"
                [matMenuTriggerFor]="menu" (click)="currancecatalog = catalog">
              </p-button>
            </td>
          </div>
          <td>{{catalog.collection}}</td>
          <td>{{catalog.season}}</td>
          <td>{{catalog.seasoncode}}</td>
          <td>{{catalog.seasonyear}}</td>
<!--          <td>{{catalog.designer}}</td>-->
          <td>
            <div class="optionbuttons">
              <p-button label="Edit" size="small" [text]="true" severity="success" (click)="onButtonEditClick(catalog)">
              </p-button>

              <p-button label="Delete" size="small" [text]="true" severity="danger" (click)="onDeleteButtonClick(catalog)">

              </p-button>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="onButtonMenu1Click(currancecatalog)">
    <span>Create New Catalog</span>
  </button>
<!--  <button mat-menu-item (click)="onButtonMenu2Click(currancecatalog)">-->
<!--    <span>Add photos to the current catalog</span>-->
<!--  </button>-->
  <button mat-menu-item (click)="onButtonMenu3Click(currancecatalog)">
    <span>View photos from the catalog</span>
  </button>
</mat-menu>

<p-confirmDialog *ngIf="showdialog" #cd [style]="{width: '25vw'}" [closable]="true">
  <ng-template pTemplate="footer">
    <div class="button-container-rr">
      <button mat-raised-button class="button-secondary" style="margin-right: 10px; width: 10%"
        (click)="cd.reject()">
        No
      </button>
      <button mat-raised-button class="button-primary" style="width: 10%" (click)="cd.accept()">
        Yes
      </button>
    </div>
  </ng-template>
</p-confirmDialog>
