import { Component, OnInit, Self } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ModalserviceService } from '../../services/modalservice.service';
import { HashService } from '../../services/hash.service';
import { MessageboxComponent } from '../../modals/messagebox/messagebox.component';
import { ServiceService } from '../../services/service.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { YourappsserviceService } from '../../services/yourappsservice.service';
import { SelfData } from '../../models/struct';
import { SwsdialogComponent } from '../swsdialog/swsdialog.component';
import {combineLatest} from "rxjs";
import {MessageService} from "primeng/api";

@Component({
  selector: 'app-addeditframedialog',
  templateUrl: './addeditframedialog.component.html',
  styleUrls: ['./addeditframedialog.component.scss'],
  providers: [MessageboxComponent, SwsdialogComponent],
})
export class AddeditframedialogComponent implements OnInit {
  myForm: FormGroup;
  frameCaption: String = '';
  frameid: number = 0;
  operationtype: number = 0;
  myControl = new FormControl('');
  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private hashService: HashService,
    public messageBox: MessageboxComponent,
    private serviceservice: ServiceService,
    private _snackBar: MatSnackBar,
    private messageService: MessageService,
    private yourappsService: YourappsserviceService,
    private swsdialog: SwsdialogComponent
  ) {
    this.myForm = new FormGroup({
      // value: new FormControl(''),  // Initialize your form control
      // other form controls...
    });
  }

  ngOnInit(): void {
    combineLatest([
      this.yourappsService.caption$,
      this.yourappsService.description$,
      this.yourappsService.id$,
      this.yourappsService.opertype$
    ]).subscribe(([caption, description, id, opertype]) => {
      this.frameCaption = caption;
      this.myControl.setValue(description);
      this.frameid = id;
      this.operationtype = opertype;
      console.log("this.operationtype", this.operationtype)
      // Your logic here for handling the combined values
    });
  }

  sendaddeditrequst(ctrl: number, description: string | undefined, id: number) {
    const accountid = this.getAccountID();
    console.log("SelfData.modaltype", SelfData.modaltype)
    switch (SelfData.modaltype) {
      case 0:
        this.serviceservice
          .prcsws({ ctrl, description, accountid, id })
          .subscribe(() => {
            this.setAddFrameDescription(description);
          });
        break;
      case 1:
        this.serviceservice
          .prcregion({ ctrl, description, accountid, id })
          .subscribe(() => {
            this.setAddFrameDescription(description);
          });
        break;
      case 2:
        this.serviceservice
          .prcdesigner({ ctrl, description, accountid, id })
          .subscribe(() => {
            this.setAddFrameDescription(description);
          });
        break;
      case 3:
        this.serviceservice
          .prcfabric({ ctrl, description, accountid, id })
          .subscribe(() => {
            this.setAddFrameDescription(description);
          });
        break;
      case 4:
        this.serviceservice
          .prcmanu({ ctrl, description, accountid, id })
          .subscribe(() => {
            this.setAddFrameDescription(description);
          });
        break;
      case 7:
        this.serviceservice
          .prcstyle({ ctrl, description, accountid, id })
          .subscribe(() => {
            this.setAddFrameDescription(description);
          });
        break;
      case 9:
        this.serviceservice
          .prcmisc({ ctrl, description, accountid, id })
          .subscribe(() => {
            this.setAddFrameDescription(description);
          });
        break;
      case 10:
        this.serviceservice
          .prctype({ ctrl, description, accountid, id })
          .subscribe(() => {
            this.setAddFrameDescription(description);
          });
        break;
      case 11:
        this.serviceservice
          .prcsize({ ctrl, description, accountid, id })
          .subscribe(() => {
            this.setAddFrameDescription(description);
          });
        break;
      case 12:
        this.serviceservice
          .prcpattern({ ctrl, description, accountid, id })
          .subscribe(() => {
            this.setAddFrameDescription(description);
          });
        break;
      case 13:
        this.serviceservice
          .prcsizerange({ ctrl, description, accountid, id })
          .subscribe(() => {
            this.setAddFrameDescription(description);
          });
        break;
      case 14:
        this.serviceservice
          .prcpronoun({ ctrl, description, accountid, id })
          .subscribe(() => {
            this.setAddFrameDescription(description);
          });
        break;
      case 15:
        this.serviceservice
          .prccontcategory({ ctrl, description, accountid, id })
          .subscribe(() => {
            this.setAddFrameDescription(description);
          });
        break;
      case 16:
        this.serviceservice
          .prccontjd({ ctrl, description, accountid, id })
          .subscribe(() => {
            this.setAddFrameDescription(description);
          });
        break;
      case 17:
        this.serviceservice
          .prcconttype({ ctrl, description, accountid, id })
          .subscribe(() => {
            this.setAddFrameDescription(description);
          });
        break;
      case 18:
        this.serviceservice
          .prccontregion({ ctrl, description, accountid, id })
          .subscribe(() => {
            this.setAddFrameDescription(description);
          });
        break;
      case 19:
        this.serviceservice
          .prccontrb({ ctrl, description, accountid, id })
          .subscribe(() => {
            this.setAddFrameDescription(description);
          });
        break;
      case 20:
        this.serviceservice
          .prccontac({ ctrl, description, accountid, id })
          .subscribe(() => {
            this.setAddFrameDescription(description);
          });
        break;
      case 21:
        this.serviceservice
          .prccontcg({ ctrl, description, accountid, id })
          .subscribe(() => {
            this.setAddFrameDescription(description);
          });
        break;
      case 22:
        this.serviceservice
          .prccomptype({ ctrl, description, accountid, id })
          .subscribe(() => {
            this.setAddFrameDescription(description);
          });
        break;
      case -1:
        id = SelfData.selectedddid;
        this.serviceservice
          .prctod({ ctrl, description, accountid, id })
          .subscribe(() => {
            this.setAddFrameDescription(description);
            SelfData.selectedddid = -1;
          });
        break;
      case -2:
        id = SelfData.selectedddid;
        this.serviceservice
          .prcpe({ ctrl, description, accountid, id })
          .subscribe(() => {
            this.setAddFrameDescription(description);
            SelfData.selectedddid = -2;
          });
        break;
      case -3:
        id = SelfData.selectedddid;
        this.serviceservice
          .prcissue({ ctrl, description, accountid, id })
          .subscribe(() => {
            this.setAddFrameDescription(description);
            SelfData.selectedddid = -3;
          });
        break;
      default:
        return;
    }
  }

  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
      ? SelfData.account[0].data[0].id
      : SelfData.account[0].data[0].parentid;
  }

  private setAddFrameDescription(description: string | undefined): void {
    this.yourappsService.getReloadSWSList()
    this.yourappsService.setAddFrameDescription(description || '');
  }
  clearValue() {
    this.myControl.setValue(''); // Clear the form control value
  }
  submit() {
    this.modalService.closeAddEdit();
    this.messageService.add({
      key: 'tc',
      severity: 'success',
      summary: 'Information',
      detail: 'Added successfully',
      life: 5000,
    });
    console.log("!!this.operationtype!!", this.operationtype, this.frameid, SelfData.selectedddid)
    if (this.operationtype === 0) {
      if (this.frameid > 0) {
        if (SelfData.selectedddid > 0) {
          this.sendaddeditrequst(
            1,
            this.myControl.value?.trim(),
            SelfData.modaltype || 0
          );
        } else {
          this.sendaddeditrequst(1, this.myControl.value?.trim(), this.frameid);
        }
      } else {
        this.sendaddeditrequst(0, this.myControl.value?.trim(), 0);
      }
    } else {
      this.yourappsService.returnUpdateValue(this.myControl.value?.trim() || "", this.operationtype)
    }
  }
}
