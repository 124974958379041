import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder, FormControl} from "@angular/forms";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ConfirmationService, MessageService} from "primeng/api";
import {NgxImageCompressService} from "ngx-image-compress";
import { HttpClient } from "@angular/common/http";
import {DatePipe} from "@angular/common";
import {SelfData, transformImageUrl} from "../../models/struct";
import {Observable, Subscription} from "rxjs";
import {MatAutocompleteTrigger} from "@angular/material/autocomplete";

@Component({
  selector: 'app-aiframedialog',
  templateUrl: './aiframedialog.component.html',
  styleUrls: ['./aiframedialog.component.scss']
})
export class AiframedialogComponent implements OnInit, AfterViewInit, OnDestroy {
  audio = new Audio();
  private subscription?: Subscription;
  imageurl: string = ""
  messages: { text: string; color: string }[] = [];
  itemslist: string[] = [];
  showspinner: boolean = true;
  neededadded: boolean = true;
  mainresponse: any = []
  maindescription: any = []
  myItemListControl = new FormControl<string>('');
  chooevisible: boolean = false;

  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private serviceservice: ServiceService,
    private yourappsService: YourappsserviceService,
    private cdr: ChangeDetectorRef,
    private messageService: MessageService,
    private imageCompressService: NgxImageCompressService,
    private confirmationService: ConfirmationService,
    private http: HttpClient,
    private datePipe: DatePipe,
    private el: ElementRef
  ) {
    // Set the source of the audio file
    this.audio.src = 'assets/AI_F.mp3';
    // Optionally, set the audio to load automatically and control other attributes
    this.audio.load();
    this.playAudioWithDelay()
  }

  playAudioWithDelay() {
    setTimeout(() => {
      this.audio.play();
    }, 1500);
  }

  onButtonCloseClick() {
    this.modalService.closeAI()
  }

  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {
// Stop the audio playback
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.audio.pause();
    // Reset the audio to the beginning
    this.audio.currentTime = 0;
  }

  sendMessage(message: string, code: number) {
    if (typeof message !== 'string') {
      console.error('Invalid message type:', message);
      return; // Exit the function if message is not a string
    }

    let color = this.checkMessageColor(code);

    // Function to replace '**text**' with '<strong>text</strong>'
    const replaceBold = (msg: string): string => {
      return msg.replace(/\*\*(.*?)\*\*/g, (match, p1) => `<strong>${p1}</strong>`);
    };

    // Apply the bold replacement and then replace newline characters with HTML line breaks
    let formattedMessage = replaceBold(message).replace(/\n/g, '<br>');

    this.messages.unshift({ text: formattedMessage, color: color });
  }

  checkMessageColor(colorcode: number): string {
    let color: string;
    if (colorcode === 0) {
      color = 'red';
    } else if (colorcode === 1) {
      color = 'green';
    } else {
      color = 'black'; // Default color
    }
    return color;
  }

  ngOnInit(): void {
    this.subscription = this.yourappsService.setSelImages$.subscribe((value: { data: any, type: number }) => {
      console.log("Received value", value); // Check if any value is received
      if (value.data) {
        if (value.type === 1) {
          this.imageurl = value.data.url;
          this.serviceservice
            .getAIAnswer({
              setmessage: "Describes objects in detail without paying attention to the background, person, text or mannequin, but only the objects. Specify an additional JSON format, for example: these parameters are required {\"items\": [{\"type\": \"jacket\", \"color\": \"black\",\"material\": \"...\",\"shoulder_design\": \" .. . \" , \" front_detailing\": {\"style\": \"...\",\"color\": \"...\",\"eyelets\": \"...\"} describe all elements in this format. Be sure to use the same structure for each element! Be sure to add a full description in the \"all_items_full_description\":{\"type\": \"\", \"description\":\"\"}: section, in JSON format for each element separately! dont add ```json ```",
              seturl: this.imageurl,
              setdetail: "high"
            })
            .subscribe((response) => {
              console.log(response)
              this.processAIResponse(response.data);

            });
        }
      }
    });
  }

  add(
    event: MouseEvent,
    type: number,
    autoInput: MatAutocompleteTrigger | null
  ): void {
    event.stopPropagation(); // This will stop the event from bubbling up

    if (autoInput != null) {
      autoInput.closePanel();
    }

    const currentValue = this.myItemListControl.value;
    if (!this.itemslist.includes(<string>currentValue)) {
      this.messageService.add({
        key: 'tc',
        severity: 'error',
        summary: 'Information',
        detail: 'You need to select one item for detailing description',
        life: 5000,
      });
      return
    } else {
      // Clear any existing errors if the value is found
      // console.log("2")
      this.messages = [];
      this.showspinner = true
      this.serviceservice
        .getAIAnswer({
          setmessage: "Be sure to describe only the " + this.myItemListControl.value + " short and direct description, maximum ten words!. Without paying attention to the background, person, text or mannequin, exclusively to the " + this.myItemListControl.value + "! Nothing more. Please specify optional JSON format, for example: All these parameters should be require {\"items\": [{\"type\": \"jacket\", \"color\": \"black\",\"material\": \"...\",\"shoulder_design\": \" .. . \" , \" front_detailing\": {\"style\": \"...\",\"color\": \"...\",\"eyelets\": \"...\"} describe all elements in this format. Be sure to use the same structure for each element! Be sure to add a full description in the \"all_items_full_description\":{\"type\": \"\", \"description\":\"\"}: section, in JSON format for each element separately! dont add ```json ```",
          seturl: this.imageurl,
          setdetail: "High"
        })
        .subscribe((response) => {
          console.log(response)
          this.processAIResponse(response.data);

        });
      this.myItemListControl.setErrors(null);
    }
  }

  addAccept(
    event: MouseEvent,
    type: number,
    autoInput: MatAutocompleteTrigger | null
  ): void {
    event.stopPropagation(); // This will stop the event from bubbling up

    if (autoInput != null) {
      autoInput.closePanel();
    }
    this.yourappsService.returnAIDescription(this.mainresponse, this.maindescription, 1)
    this.modalService.closeAI()
  }

  processAIResponse(responseContent: any): void {
    console.log("responseContent", responseContent);
    try {
      let parsedContent;

      if (typeof responseContent === 'string') {
        // Проверяем, является ли строка валидным JSON
        responseContent = responseContent.trim();
        try {
          parsedContent = JSON.parse(responseContent);
        } catch (e) {
          throw new Error('Не найден валидный JSON в строке');
        }
      } else {
        parsedContent = responseContent;
      }

      console.log("Parsed content", parsedContent);

      if (!parsedContent || typeof parsedContent !== 'object') {
        console.log('Нет элементов в parsed content');
        this.showspinner = false;
        return;
      }

      // Объединение дубликатов ключей "items"
      if (Array.isArray(parsedContent.items)) {
        parsedContent.items = parsedContent.items.flat();
      } else {
        parsedContent.items = [parsedContent.items];
      }

      this.mainresponse = parsedContent;
      this.maindescription = this.extractTypeAndDescription(parsedContent);

      // Очищаем список
      this.itemslist = [];

      // Создаем набор уникальных описаний, чтобы избежать дубликатов
      const uniqueDescriptions = new Set();
      const uniqueTypes = new Set();

      if (parsedContent.items) {
        parsedContent.items.forEach((item: any) => {
          if (item.type && !uniqueTypes.has(item.type)) {
            console.log(`Тип: ${item.type}`);
            this.itemslist.push(item.type);
            uniqueTypes.add(item.type);
          }
        });
      }

      if (parsedContent.all_items_full_description) {
        const fullDescription = parsedContent.all_items_full_description;
        if (typeof fullDescription === 'object' && !Array.isArray(fullDescription)) {
          Object.values(fullDescription).forEach((desc: any) => {
            if (desc && desc.description && !uniqueDescriptions.has(desc.description)) {
              console.log(`Описание: ${desc.description}`);
              this.sendMessage(desc.description, 3);
              uniqueDescriptions.add(desc.description);
            }
          });
        } else if (Array.isArray(fullDescription)) {
          fullDescription.forEach((desc: any) => {
            if (desc && desc.description && !uniqueDescriptions.has(desc.description)) {
              console.log(`Описание: ${desc.description}`);
              this.sendMessage(desc.description, 3);
              uniqueDescriptions.add(desc.description);
            }
          });
        }
      }

      console.log("this.itemslist", this.itemslist);
      this.chooevisible = true;
      this.neededadded = false;
      this.showspinner = false;
    } catch (error) {
      console.error('Ошибка обработки контента:', error);
      this.showspinner = false;
    }
  }

  extractTypeAndDescription(data: any) {
    const itemsInfo: any[] = [];

    data.items.forEach((item: { type: any; all_items_full_description: { description: any; }; }) => {
      let description;
      const itemType = item.type;

      // Check for different structures and extract description
      if (item.all_items_full_description && typeof item.all_items_full_description === 'object') {
        console.log("1")
        description = item.all_items_full_description.description;

      } else if (data.all_items_full_description && typeof data.all_items_full_description === 'object') {
        if (Array.isArray(data.all_items_full_description)) {
          // Find description in an array
          console.log("2")
          const descObj = data.all_items_full_description.find((desc: { type: any; }) => desc.type === itemType);
          description = descObj ? descObj.description : null;
        } else {
          // Find description in an object
          console.log("3", data.all_items_full_description.description)
          description = data.all_items_full_description.description
          // description = data.all_items_full_description[itemType]?.description;
        }
      }

      if (description) {
        itemsInfo.push({ type: itemType, description });
        this.sendMessage(description, 3);
        this.sendMessage("**" + itemType + "**", 3);
        if (this.neededadded) {
          this.itemslist.push(itemType)
        }
      }
    });

    return itemsInfo;
  }

  protected readonly transformImageUrl = transformImageUrl;
}

interface ItemDescription {
  type: string;
  description: string;
}
