<button mat-icon-button type="button" class="close-btn" (click)="modalService.suppliersFrameClose()">
  <mat-icon>close</mat-icon>
</button>
<p class="sample-manager-customer">{{ caption }}</p>
<div class="maindiv">
  <nz-tabset>
    <nz-tab nzTitle="Contact">
      <div class="componentsdiv">
        <div class="componentsdiv2">
          <label class="txtdiv">Supplier name</label>
          <nz-input-group>
            <input class="cmdiv" type="text" [(ngModel)]="suppliername" nz-input/>
          </nz-input-group>

        </div>
        <div class="componentsdiv2">
          <label class="txtdiv">Default markup</label>
          <nz-input-group>
            <input class="cmdiv" type="number" [(ngModel)]="defaultmarkup" nz-input/>
          </nz-input-group>
        </div>
      </div>

      <div class="componentsdiv1-2">
        <div class="componentsdiv2-1">
          <label class="txtdiv">Description</label><br>
          <textarea [(ngModel)]="descriptionstring" rows="3" cols="30" class="cmdiv dopinfo"
            nz-input
          ></textarea>
        </div>
      </div>

      <div class="dividerdiv">
        <nz-divider nzPlain></nz-divider>
      </div>

      <div class="componentsdiv">
        <div class="componentsdiv2">
          <label class="txtdiv">First name</label>
          <nz-input-group>
            <input class="cmdiv" type="text" [(ngModel)]="firstname" nz-input/>
          </nz-input-group>

        </div>
        <div class="componentsdiv2">
          <label class="txtdiv">Last name</label>
          <nz-input-group>
            <input class="cmdiv" type="text" [(ngModel)]="lastname" nz-input/>
          </nz-input-group>
        </div>
      </div>

      <div class="componentsdiv">
        <div class="componentsdiv2">
          <label class="txtdiv">Company name</label>
          <nz-input-group>
            <input class="cmdiv" type="text" [(ngModel)]="companyname" nz-input/>
          </nz-input-group>
        </div>
        <div class="componentsdiv2">
          <label class="txtdiv">Email</label>
          <nz-input-group>
            <input class="cmdiv" type="email" [(ngModel)]="email" nz-input/>
          </nz-input-group>
        </div>
      </div>

      <div class="componentsdiv">
        <div class="componentsdiv2">
          <label class="txtdiv">Phone</label>
          <nz-input-group>
            <input class="cmdiv" type="tel" [(ngModel)]="phone" nz-input/>
          </nz-input-group>
        </div>

        <div class="componentsdiv2">
          <label class="txtdiv">Mobile</label>
          <nz-input-group>
            <input class="cmdiv" type="tel" [(ngModel)]="mobile" nz-input/>
          </nz-input-group>
        </div>
      </div>

      <div class="componentsdiv">
        <div class="componentsdiv2">
          <label class="txtdiv">Website</label>
          <nz-input-group>
            <input class="cmdiv" type="text" [(ngModel)]="website" nz-input/>
          </nz-input-group>

        </div>
        <div class="componentsdiv2">
          <label class="txtdiv">Instagram</label>
          <nz-input-group>
            <input class="cmdiv" type="tel" [(ngModel)]="instagram" nz-input/>
          </nz-input-group>
        </div>
      </div>

      <!--            5line-->

      <div class="componentsdiv">
        <div class="componentsdiv2">
          <label class="txtdiv">Facebook</label>
          <nz-input-group>
            <input class="cmdiv" type="text" [(ngModel)]="facebook" nz-input/>
          </nz-input-group>

        </div>
        <div class="componentsdiv2">
          <label class="txtdiv">TikTok</label>
          <nz-input-group>
            <input class="cmdiv" type="tel" [(ngModel)]="tiktok" nz-input/>
          </nz-input-group>
        </div>
      </div>

      <!--            6line-->

      <div class="componentsdiv">
        <div class="componentsdiv2">
          <label class="txtdiv">X (Twitter)</label>
          <nz-input-group>
            <input class="cmdiv" type="text" [(ngModel)]="twitter" nz-input/>
          </nz-input-group>

        </div>
        <div class="componentsdiv2">
          <label class="txtdiv">YouTube</label>
          <nz-input-group>
            <input class="cmdiv" type="tel" [(ngModel)]="youtube" nz-input/>
          </nz-input-group>
        </div>
      </div>

      <div class="dividerdiv">
        <nz-divider nzPlain></nz-divider>
      </div>

      <div class="componentsdiv">
        <div class="componentsdiv2">
          <label class="txtdiv">Street address</label>
          <nz-input-group>
            <input class="cmdiv" type="text" [(ngModel)]="address" nz-input/>
          </nz-input-group>

        </div>
        <div class="componentsdiv2">
          <label class="txtdiv">Street address2</label>
          <nz-input-group>
            <input class="cmdiv" type="text" [(ngModel)]="address2" nz-input/>
          </nz-input-group>
        </div>
      </div>
      <!--            3line-->

      <div class="componentsdiv">
        <div class="componentsdiv2">
          <label class="txtdiv">Suburb</label>
          <nz-input-group>
            <input class="cmdiv" type="text" [(ngModel)]="suburb" nz-input/>
          </nz-input-group>

        </div>
        <div class="componentsdiv2">
          <label class="txtdiv">City</label>
          <nz-input-group>
            <input class="cmdiv" type="text" [(ngModel)]="city" nz-input/>
          </nz-input-group>
        </div>
      </div>

      <!--            4line-->
      <div class="componentsdiv">
        <div class="componentsdiv2">
          <label class="txtdiv">ZIP code</label>
          <nz-input-group>
            <input class="cmdiv" type="text" [(ngModel)]="zipcode" nz-input/>
          </nz-input-group>

        </div>
        <div class="componentsdiv2">
          <label class="txtdiv">State</label>
          <nz-input-group nzSearch>
            <nz-select nzShowSearch [nzShowArrow]="true" class="cmdiv"
              [(ngModel)]="statesstring">
              <nz-option *ngFor="let option of (states ?? [])" [nzLabel]="option['state']"
                [nzValue]="option['state']"></nz-option>
            </nz-select>
          </nz-input-group>
        </div>
      </div>

      <!--            5line-->
      <div class="componentsdiv">
        <div class="componentsdiv2">
          <label class="txtdiv">Country</label>
          <nz-select nzShowSearch [nzShowArrow]="true" class="cmdiv"
            (ngModelChange)="onOptionSelected($event)"
            [(ngModel)]="countrystring">
            <nz-option *ngFor="let option of (countries ?? [])" [nzLabel]="option['country']"
              [nzValue]="option['country']"></nz-option>
          </nz-select>

        </div>
        <div class="componentsdiv2">

        </div>
      </div>

      <div class="componentsdiv6">
        <label nz-checkbox [(ngModel)]="usedifferent">Mailing address same as store address</label>
        <div class="componentsdiv2">

        </div>
      </div>

      <div *ngIf="!usedifferent" class="dividerdiv">
        <nz-divider nzPlain></nz-divider>
      </div>

      <!--            2line-->
      <div *ngIf="!usedifferent" class="componentsdiv">
        <div class="componentsdiv2">
          <label class="txtdiv">Street address</label>
          <nz-input-group>
            <input class="cmdiv" type="text" [(ngModel)]="paddress" nz-input/>
          </nz-input-group>

        </div>
        <div class="componentsdiv2">
          <label class="txtdiv">Street address2</label>
          <nz-input-group>
            <input class="cmdiv" type="text" [(ngModel)]="paddress2" nz-input/>
          </nz-input-group>
        </div>
      </div>
      <!--            3line-->

      <div *ngIf="!usedifferent" class="componentsdiv">
        <div class="componentsdiv2">
          <label class="txtdiv">Suburb</label>
          <nz-input-group>
            <input class="cmdiv" type="text" [(ngModel)]="psuburb" nz-input/>
          </nz-input-group>

        </div>
        <div class="componentsdiv2">
          <label class="txtdiv">City</label>
          <nz-input-group>
            <input class="cmdiv" type="text" [(ngModel)]="pcity" nz-input/>
          </nz-input-group>
        </div>
      </div>

      <!--            4line-->
      <div *ngIf="!usedifferent" class="componentsdiv">
        <div class="componentsdiv2">
          <label class="txtdiv">ZIP code</label>
          <nz-input-group>
            <input class="cmdiv" type="text" [(ngModel)]="pzipcode" nz-input/>
          </nz-input-group>

        </div>
        <div class="componentsdiv2">
          <label class="txtdiv">State</label>
          <nz-input-group nzSearch>
            <nz-select nzShowSearch [nzShowArrow]="true" class="cmdiv"
              [(ngModel)]="pstatesstring">
              <nz-option *ngFor="let option of (pstates ?? [])" [nzLabel]="option['state']"
                [nzValue]="option['state']"></nz-option>
            </nz-select>
          </nz-input-group>
        </div>
      </div>

      <!--            5line-->
      <div *ngIf="!usedifferent" class="componentsdiv">
        <div class="componentsdiv2">
          <label class="txtdiv">Country</label>
          <nz-select nzShowSearch [nzShowArrow]="true" class="cmdiv"
            (ngModelChange)="onOptionSelectedp($event)"
            [(ngModel)]="pcountrystring">
            <nz-option *ngFor="let option of (pcountries ?? [])" [nzLabel]="option['country']"
              [nzValue]="option['country']"></nz-option>
          </nz-select>

        </div>
        <div class="componentsdiv2">

        </div>
      </div>
    </nz-tab>
    <nz-tab *ngIf="SelfData.formstyle > 0" nzTitle="History">
      <div class="griddiv">
        <p-table
          #dt1
          [value]=iProductsResult
          [paginator]="true"
          [rows]="10"
          rowGroupMode="subheader"
          groupRowsBy="designer"
          selectionMode="single"
          [(selection)]="selectedSalesReport"
          [showCurrentPageReport]="true"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          [rowsPerPageOptions]="[10, 20, 50, 100]"
          [styleClass]="'p-datatable-selectable p-datatable-striped mat-elevation-z1 ' + (selectedSize?.class || '')"
          [tableStyle]="{'min-width': '50rem'}">

          <ng-template pTemplate="header">
            <tr>
              <th style="width: 9%">Product Name</th>
              <th style="width: 7%">User</th>
              <th style="width: 6%">Date</th>
              <th style="width: 1%">Quentity</th>
              <th style="width: 2%">Price</th>
              <th style="width: 1%">Discount</th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-product>
            <tr>
              <td>{{ product["name"] }}</td>
              <td>{{ product["user"] }}</td>
              <td>{{ product.Regdate | date:'yyyy-MM-dd HH:mm' }}</td>
              <td>{{ product["Quantity"] }}</td>
              <td>{{ product.UnitPrice | currency:'USD':'symbol':'1.2-2' }}</td>
              <td>{{ product.DiscountAmount | currency:'USD':'symbol':'1.2-2' }}</td>
            </tr>
          </ng-template>
          <ng-template pTemplate="groupfooter" let-product>
            <tr>

              <td colspan="4" class="text-right font-bold pr-3">{{calculateTotal() }}</td>
              <td colspan="1" class="text-right font-bold pr-3">{{calculateTotal1() | currency:'USD':'symbol':'1.2-2'}}</td>
              <td colspan="1" class="text-right font-bold pr-3">{{calculateTotal2() | currency:'USD':'symbol':'1.2-2'}}</td>
              <!--          <td colspan="8" class="text-right font-bold pr-3">Total Catalogs: </td>-->
            </tr>
          </ng-template>
        </p-table>
        <div class="fullline">

        </div>
      </div>
    </nz-tab>
  </nz-tabset>
</div>

<div class="footerdiv">
  <button [disabled]="viewmode" nz-button nzType="default" (click)="onSupplierClick()">{{ caption }}</button>
</div>
