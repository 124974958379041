<button mat-icon-button type="button" class="close-btn" (click)="modalService.customersFrameClose()">
  <mat-icon>close</mat-icon>
</button>
<p class="sample-manager-customer">{{caption}}</p>
<div class="maindiv">
  <nz-tabset>
    <nz-tab nzTitle="Contact">
      <div class="componentsdiv">
        <div class="componentsdiv2-1">
          <label class="txtdiv">Company name</label>
          <nz-input-group>
            <input class="cmdiv" type="text" [(ngModel)]="companyname" nz-input/>
          </nz-input-group>
        </div>
      </div>
      <div class="componentsdiv">
        <div class="componentsdiv2">
          <label class="txtdiv">First name</label>
          <nz-input-group>
            <input class="cmdiv" type="text" [(ngModel)]="firstname" nz-input/>
          </nz-input-group>

        </div>
        <div class="componentsdiv2">
          <label class="txtdiv">Last name</label>
          <nz-input-group>
            <input class="cmdiv" type="text" [(ngModel)]="lastname" nz-input/>
          </nz-input-group>
        </div>
      </div>

      <div class="componentsdiv">
        <div class="componentsdiv2">
          <label class="txtdiv">Email</label>
          <nz-input-group>
            <input class="cmdiv" type="email" [(ngModel)]="email" nz-input/>
          </nz-input-group>

        </div>
        <div class="componentsdiv2">
          <label class="txtdiv">Phone</label>
          <nz-input-group>
            <input class="cmdiv" type="tel" [(ngModel)]="phone" nz-input/>
          </nz-input-group>
        </div>
      </div>

      <div class="componentsdiv">
        <div class="componentsdiv2">
          <label class="txtdiv">Customer group</label>
          <nz-input-group [nzAddOnAfter]="suffixIconButton">
            <nz-select nzShowSearch [nzShowArrow]="true" class="cmdiv"
              [(ngModel)]="groupe">
              <nz-option *ngFor="let option of tgroupe" [nzLabel]="option.value" [nzValue]="option.value">
              </nz-option>
            </nz-select>
          </nz-input-group>
          <ng-template #suffixIconButton>
            <button class="buttonclass" nz-button nzType="default" nzSearch>
              <!--              <span nz-icon nzType="unordered-list" nzTheme="outline"></span>-->
              <i nz-icon nzType="ellipsis" nzTheme="outline"></i>
            </button>
          </ng-template>
        </div>
        <div class="componentsdiv2">
          <div class="checkboxt">
            <nz-switch
              [(ngModel)]="checkemail"
              [nzCheckedChildren]="checkedTemplate"
              [nzUnCheckedChildren]="unCheckedTemplate"
            ></nz-switch>
            <label class="txtdiv1">Opt-in to marketing and promotional emails and sms</label>
            <ng-template #checkedTemplate><span nz-icon nzType="check"></span></ng-template>
            <ng-template #unCheckedTemplate><span nz-icon nzType="close"></span></ng-template>
          </div>
        </div>
      </div>

    </nz-tab>
    <nz-tab nzTitle="Details">
      <div class="headerdivconteiner">
        <div class="conteinerdiv">
          <div class="child-1">
            <samp class="titlediv2">Mailing address</samp>
          </div>
          <div class="child-2">
            <div class="componentsdiv1-1">

            </div>
            <div class="componentsdiv5">

              <div class="componentsdiv2">

              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="dividerdiv">
        <nz-divider nzPlain></nz-divider>
      </div>

      <div class="componentsdiv">
        <div class="componentsdiv2">
          <label class="txtdiv">Street address</label>
          <nz-input-group>
            <input class="cmdiv" type="text" [(ngModel)]="address" nz-input/>
          </nz-input-group>

        </div>
        <div class="componentsdiv2">
          <label class="txtdiv">Street address2</label>
          <nz-input-group>
            <input class="cmdiv" type="text" [(ngModel)]="address2" nz-input/>
          </nz-input-group>
        </div>
      </div>
      <!--            3line-->

      <div class="componentsdiv">
        <div class="componentsdiv2">
          <label class="txtdiv">Suburb</label>
          <nz-input-group>
            <input class="cmdiv" type="text" [(ngModel)]="suburb" nz-input/>
          </nz-input-group>

        </div>
        <div class="componentsdiv2">
          <label class="txtdiv">City</label>
          <nz-input-group>
            <input class="cmdiv" type="text" [(ngModel)]="city" nz-input/>
          </nz-input-group>
        </div>
      </div>

      <!--            4line-->
      <div class="componentsdiv">
        <div class="componentsdiv2">
          <label class="txtdiv">ZIP code</label>
          <nz-input-group>
            <input class="cmdiv" type="text" [(ngModel)]="zipcode" nz-input/>
          </nz-input-group>

        </div>
        <div class="componentsdiv2">
          <label class="txtdiv">State</label>
          <nz-input-group nzSearch>
            <nz-select nzShowSearch [nzShowArrow]="true" class="cmdiv"
              [(ngModel)]="statesstring">
              <nz-option *ngFor="let option of (states ?? [])" [nzLabel]="option['state']"
                [nzValue]="option['state']"></nz-option>
            </nz-select>
          </nz-input-group>
        </div>
      </div>

      <!--            5line-->
      <div class="componentsdiv">
        <div class="componentsdiv2">
          <label class="txtdiv">Country</label>
          <nz-select nzShowSearch [nzShowArrow]="true" class="cmdiv"
            (ngModelChange)="onOptionSelected($event)"
            [(ngModel)]="countrystring">
            <nz-option *ngFor="let option of (countries ?? [])" [nzLabel]="option['country']"
              [nzValue]="option['country']"></nz-option>
          </nz-select>

        </div>
        <div class="componentsdiv2">

        </div>
      </div>

      <!--            6line-->
      <div class="componentsdiv6">
        <label nz-checkbox [(ngModel)]="usedifferent">Use different address for postal address</label>
        <div class="componentsdiv2">

        </div>
      </div>

      <div *ngIf="usedifferent" class="dividerdiv1">
        <nz-divider nzPlain></nz-divider>
      </div>

      <div *ngIf="usedifferent" class="componentsdiv3">
        <div class="componentsdiv2">
          <samp class="titlediv2">POSTAL ADDRESS</samp>
        </div>
      </div>

      <div *ngIf="usedifferent" class="dividerdiv">
        <nz-divider nzPlain></nz-divider>
      </div>

      <!--            2line-->
      <div *ngIf="usedifferent" class="componentsdiv">
        <div class="componentsdiv2">
          <label class="txtdiv">Street address</label>
          <nz-input-group>
            <input class="cmdiv" type="text" [(ngModel)]="paddress" nz-input/>
          </nz-input-group>

        </div>
        <div class="componentsdiv2">
          <label class="txtdiv">Street address2</label>
          <nz-input-group>
            <input class="cmdiv" type="text" [(ngModel)]="paddress2" nz-input/>
          </nz-input-group>
        </div>
      </div>
      <!--            3line-->

      <div *ngIf="usedifferent" class="componentsdiv">
        <div class="componentsdiv2">
          <label class="txtdiv">Suburb</label>
          <nz-input-group>
            <input class="cmdiv" type="text" [(ngModel)]="psuburb" nz-input/>
          </nz-input-group>

        </div>
        <div class="componentsdiv2">
          <label class="txtdiv">City</label>
          <nz-input-group>
            <input class="cmdiv" type="text" [(ngModel)]="pcity" nz-input/>
          </nz-input-group>
        </div>
      </div>

      <!--            4line-->
      <div *ngIf="usedifferent" class="componentsdiv">
        <div class="componentsdiv2">
          <label class="txtdiv">ZIP code</label>
          <nz-input-group>
            <input class="cmdiv" type="text" [(ngModel)]="pzipcode" nz-input/>
          </nz-input-group>

        </div>
        <div class="componentsdiv2">
          <label class="txtdiv">State</label>
          <nz-input-group nzSearch>
            <nz-select nzShowSearch [nzShowArrow]="true" class="cmdiv"
              [(ngModel)]="pstatesstring">
              <nz-option *ngFor="let option of (pstates ?? [])" [nzLabel]="option['state']"
                [nzValue]="option['state']"></nz-option>
            </nz-select>
          </nz-input-group>
        </div>
      </div>

      <!--            5line-->
      <div *ngIf="usedifferent" class="componentsdiv">
        <div class="componentsdiv2">
          <label class="txtdiv">Country</label>
          <nz-select nzShowSearch [nzShowArrow]="true" class="cmdiv"
            (ngModelChange)="onOptionSelectedp($event)"
            [(ngModel)]="pcountrystring">
            <nz-option *ngFor="let option of (pcountries ?? [])" [nzLabel]="option['country']"
              [nzValue]="option['country']"></nz-option>
          </nz-select>

        </div>
        <div class="componentsdiv2">

        </div>
      </div>

      <div class="dividerdiv1">
        <nz-divider nzPlain></nz-divider>
      </div>

      <div class="componentsdiv3">
        <div class="componentsdiv2">
          <samp class="titlediv2">Additional information</samp>
        </div>
      </div>

      <div class="dividerdiv1">
        <nz-divider nzPlain></nz-divider>
      </div>

      <div class="componentsdiv">
        <div class="componentsdiv2">
          <label class="txtdiv">Customer code</label>
          <nz-input-group>
            <input class="cmdiv" type="text" [(ngModel)]="customercode" nz-input/>
          </nz-input-group>

        </div>
        <div class="componentsdiv2">
          <label class="txtdiv">Gender</label>
          <nz-input-group nzSearch>
            <nz-select nzShowSearch [nzShowArrow]="true" class="cmdiv"
              [(ngModel)]="genderstring">
              <nz-option *ngFor="let option of gender" [nzLabel]="option.viewValue"
                [nzValue]="option.viewValue"></nz-option>
            </nz-select>
          </nz-input-group>
        </div>
      </div>

      <div class="componentsdiv">
        <div class="componentsdiv2">
          <label class="txtdiv">Date of birth</label>
          <nz-input-group>
            <nz-date-picker class="cmdiv" [(ngModel)]="bdate" (ngModelChange)="onChange($event)"></nz-date-picker>
          </nz-input-group>

        </div>
        <div class="componentsdiv2">
          <label class="txtdiv">YouTube</label>
          <nz-input-group>
            <input class="cmdiv" type="tel" [(ngModel)]="youtube" nz-input/>
          </nz-input-group>
        </div>
      </div>

      <div class="componentsdiv">
        <div class="componentsdiv2">
          <label class="txtdiv">Website</label>
          <nz-input-group>
            <input class="cmdiv" type="text" [(ngModel)]="website" nz-input/>
          </nz-input-group>

        </div>
        <div class="componentsdiv2">
          <label class="txtdiv">Instagram</label>
          <nz-input-group>
            <input class="cmdiv" type="tel" [(ngModel)]="instagram" nz-input/>
          </nz-input-group>
        </div>
      </div>

      <!--            5line-->

      <div class="componentsdiv">
        <div class="componentsdiv2">
          <label class="txtdiv">Facebook</label>
          <nz-input-group>
            <input class="cmdiv" type="text" [(ngModel)]="facebook" nz-input/>
          </nz-input-group>

        </div>
        <div class="componentsdiv2">
          <label class="txtdiv">TikTok</label>
          <nz-input-group>
            <input class="cmdiv" type="tel" [(ngModel)]="tiktok" nz-input/>
          </nz-input-group>
        </div>
      </div>

      <!--            6line-->

      <div class="componentsdiv">
        <div class="componentsdiv2">
          <label class="txtdiv">X (Twitter)</label>
          <nz-input-group>
            <input class="cmdiv" type="text" [(ngModel)]="twitter" nz-input/>
          </nz-input-group>

        </div>
        <div class="componentsdiv2">

        </div>
      </div>

      <div class="componentsdiv1-2">
        <div class="componentsdiv2-1">
          <label class="txtdiv">Notes</label><br>
          <textarea [(ngModel)]="descriptionstring" rows="3" cols="30" class="cmdiv dopinfo"
            nz-input
          ></textarea>
        </div>
      </div>

      <div class="dividerdiv1">
        <nz-divider nzPlain></nz-divider>
      </div>

      <div class="componentsdiv3">
        <div class="componentsdiv2">
          <samp class="titlediv2">Settings</samp>
        </div>
      </div>

      <div class="dividerdiv1">
        <nz-divider nzPlain></nz-divider>
      </div>

      <div class="componentsdiv1-3">
        <div class="componentsdiv2-1">
          <label class="txtdiv">Allow on-account balance</label>
          <nz-radio-group class="radiogroup" [(ngModel)]="radioValue">
            <label nz-radio nzValue="0">No</label>
            <label nz-radio nzValue="1">Yes, with no balance limit</label>
            <label nz-radio nzValue="2">Yes, but with a balance limit</label>
          </nz-radio-group>
          <nz-input-group *ngIf='radioValue === "2"'>
            <nz-input-number class="cmdiv" [(ngModel)]="limit" nzMin="1" />
          </nz-input-group>
        </div>
        <div class="componentsdiv2-1">
          <label class="txtdiv">Loyalty</label>
          <div class="checkboxt">
            <nz-switch
              [(ngModel)]="loyaltyEnabled"
              [nzCheckedChildren]="checkedTemplate"
              [nzUnCheckedChildren]="unCheckedTemplate"
            ></nz-switch>
            <label class="txtdiv1">Enable Loyalty for this customer</label>
            <ng-template #checkedTemplate><span nz-icon nzType="check"></span></ng-template>
            <ng-template #unCheckedTemplate><span nz-icon nzType="close"></span></ng-template>
          </div>
          <br>
          <label class="txtdiv">Wholesale</label>
          <div class="checkboxt">
            <nz-switch
              [(ngModel)]="wholesaleEnabled"
              [nzCheckedChildren]="checkedTemplate"
              [nzUnCheckedChildren]="unCheckedTemplate"
            ></nz-switch>
            <label class="txtdiv1">Enable Wholesale for this customer</label>
            <ng-template #checkedTemplate><span nz-icon nzType="check"></span></ng-template>
            <ng-template #unCheckedTemplate><span nz-icon nzType="close"></span></ng-template>
          </div>
        </div>
      </div>

      <div class="componentsdiv">
        <div class="componentsdiv2">
          <label class="txtdiv">Tax rate</label>
          <nz-input-group [nzAddOnAfter]="suffixIconButton">
            <nz-select nzShowSearch [nzShowArrow]="true" class="cmdiv"
              [(ngModel)]="taxrate">
              <nz-option *ngFor="let option of SelfData.taxesData" [nzLabel]='option["displayvalue"]' [nzValue]='option["displayvalue"]'>
              </nz-option>
            </nz-select>
          </nz-input-group>
          <ng-template #suffixIconButton>
            <button class="buttonclass" nz-button nzType="default" nzSearch (click)="onTaxesClick()">
              <!--              <span nz-icon nzType="unordered-list" nzTheme="outline"></span>-->
              <i nz-icon nzType="ellipsis" nzTheme="outline"></i>
            </button>
          </ng-template>

        </div>
        <div class="componentsdiv2">
          <label class="txtdiv">Discount (%)</label>
          <nz-input-group>
            <nz-input-number class="cmdiv" [(ngModel)]="discount" nzMin="0" />
          </nz-input-group>
        </div>
      </div>

      <div class="dividerdiv1">
        <nz-divider nzPlain></nz-divider>
      </div>

      <div class="componentsdiv3">
        <div class="componentsdiv2">
          <samp class="titlediv2">Custom fields</samp>
        </div>
      </div>

      <div class="dividerdiv1">
        <nz-divider nzPlain></nz-divider>
      </div>

      <div class="componentsdiv">
        <div class="componentsdiv2">
          <label class="txtdiv">Custom field 1</label>
          <nz-input-group>
            <input class="cmdiv" type="text" [(ngModel)]="customfield1" nz-input/>
          </nz-input-group>

        </div>
        <div class="componentsdiv2">
          <label class="txtdiv">Custom field 2</label>
          <nz-input-group>
            <input class="cmdiv" type="text" [(ngModel)]="customfield2" nz-input/>
          </nz-input-group>
        </div>
      </div>
      <!--            3line-->

      <div class="componentsdiv">
        <div class="componentsdiv2">
          <label class="txtdiv">Custom field 3</label>
          <nz-input-group>
            <input class="cmdiv" type="text" [(ngModel)]="customfield3" nz-input/>
          </nz-input-group>

        </div>
        <div class="componentsdiv2">
          <label class="txtdiv">Custom field 4</label>
          <nz-input-group>
            <input class="cmdiv" type="text" [(ngModel)]="customfield4" nz-input/>
          </nz-input-group>
        </div>
      </div>

    </nz-tab>
    <nz-tab *ngIf="SelfData.formstyle > 0" nzTitle="History">
      <div class="griddiv">
        <p-table
          #dt1
          [value]=iProductsResult
          [paginator]="true"
          [rows]="10"
          rowGroupMode="subheader"
          groupRowsBy="designer"
          selectionMode="single"
          [(selection)]="selectedSalesReport"
          [showCurrentPageReport]="true"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          [rowsPerPageOptions]="[10, 20, 50, 100]"
          [styleClass]="'p-datatable-selectable p-datatable-striped mat-elevation-z1 ' + (selectedSize?.class || '')"
          [tableStyle]="{'min-width': '50rem'}">

          <ng-template pTemplate="header">
            <tr>
              <th style="width: 4%">Customer Type</th>
              <th style="width: 9%">Product Name</th>
              <th style="width: 7%">Seller</th>
              <th style="width: 6%">Date</th>
              <th style="width: 1%">Quentity</th>
              <th style="width: 2%">Price</th>
              <th style="width: 1%">Discount</th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-product>
            <tr>
              <td>{{ product["CustomerType"] }}</td>
              <td>{{ product["Name"] }}</td>
              <td>{{ product["User"] }}</td>
              <td>{{ product.Regdate | date:'yyyy-MM-dd HH:mm' }}</td>
              <td>{{ product["Quentity"] }}</td>
              <td>{{ product.TotalAfterDiscount | currency:'USD':'symbol':'1.2-2' }}</td>
              <td>{{ product.DiscountAmount | currency:'USD':'symbol':'1.2-2' }}</td>
            </tr>
          </ng-template>
          <ng-template pTemplate="groupfooter" let-product>
            <tr>

              <td colspan="5" class="text-right font-bold pr-3">{{calculateTotal() }}</td>
              <td colspan="1" class="text-right font-bold pr-3">{{calculateTotal1() | currency:'USD':'symbol':'1.2-2'}}</td>
              <td colspan="1" class="text-right font-bold pr-3">{{calculateTotal2() | currency:'USD':'symbol':'1.2-2'}}</td>
              <!--          <td colspan="8" class="text-right font-bold pr-3">Total Catalogs: </td>-->
            </tr>
          </ng-template>
        </p-table>
        <div class="fullline">

        </div>
        <div *ngIf="SelfData.fullPage" class="nextpage">
          <app-sendoutstep1></app-sendoutstep1>
        </div>
      </div>
    </nz-tab>
  </nz-tabset>
</div>
<div class="footerdiv">
  <button [disabled]="viewmode" nz-button nzType="default" (click)="onCustomerClick()">{{buttoncaption}}</button>
</div>
