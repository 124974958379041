import { Component } from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";

@Component({
  selector: 'app-profileframe',
  templateUrl: './profileframe.component.html',
  styleUrl: './profileframe.component.scss'
})
export class ProfileframeComponent {
  constructor(public modalService: ModalserviceService) {}
}
