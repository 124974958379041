import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder} from "@angular/forms";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {ConfirmationService, MessageService} from "primeng/api";
import {NgxImageCompressService} from "ngx-image-compress";
import { HttpClient } from "@angular/common/http";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-aichoosdialog',
  templateUrl: './aichoosdialog.component.html',
  styleUrls: ['./aichoosdialog.component.scss']
})
export class AichoosdialogComponent implements OnInit, AfterViewInit, OnDestroy {
  private subscription?: Subscription;
  imageurl: string = ""
  messages: { text: string; color: string }[] = [];
  showspinner: boolean = true;
  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private serviceservice: ServiceService,
    private yourappsService: YourappsserviceService,
    private cdr: ChangeDetectorRef,
    private messageService: MessageService,
    private imageCompressService: NgxImageCompressService,
    private confirmationService: ConfirmationService,
    private http: HttpClient,
    private datePipe: DatePipe,
    private el: ElementRef
  ) {

  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnInit(): void {
  }

  onButtonCloseClick() {
    this.modalService.closeAIChoos()
  }
}
