<button mat-icon-button type="button" class="close-btn" (click)="modalService.closeCF()">
    <mat-icon>close</mat-icon>
</button>
<p class="sample-manager-text-rrc">{{ frameCaption }}</p>

<div class="black-background1">
    <mat-form-field class="full-width">
        <mat-label>Client</mat-label>
        <input matInput type="text" [formControl]="myControl"/>
        <button *ngIf="myControl.value" matSuffix mat-icon-button aria-label="Clear"
                (click)="clearValue()">
            <mat-icon>close</mat-icon>
        </button>
        <mat-error *ngIf="myControl.hasError('required')">
            Client is <strong>required</strong>
        </mat-error>
    </mat-form-field>
    <mat-form-field class="full-width">
        <mat-label>Instagram</mat-label>
        <input matInput type="text" [formControl]="instagramControl"/>
    </mat-form-field>

    <mat-form-field class="full-width">
        <mat-label>TikTok</mat-label>
        <input matInput type="text" [formControl]="tiktokControl"/>
    </mat-form-field>

    <mat-form-field class="full-width">
        <mat-label>X (Twitter)</mat-label>
        <input matInput type="text" [formControl]="twitterControl"/>
    </mat-form-field>

    <mat-form-field class="full-width">
        <mat-label>Facebook</mat-label>
        <input matInput type="text" [formControl]="facebookControl"/>
    </mat-form-field>

    <mat-form-field class="full-width">
        <mat-label>YouTube</mat-label>
        <input matInput type="text" [formControl]="youtubeControl"/>
    </mat-form-field>

    <div class="button-container">
        <button mat-flat-button type="button" (click)="modalService.closeCF()">
            Cancel
        </button>
        <button mat-flat-button type="button" (click)="onSubmit()">OK</button>
    </div>
</div>
