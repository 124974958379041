<div class="card">
  <p class="sample-manager-customers">Products</p>
  <div class="black-background-blt">
    <div class="example-container-blt">
      <div class="headerdiv1">
        <div class="headerdiv">
          <!--          <p-button label="Add outlet" icon="pi pi-plus" [rounded]="false" [outlined]="true" />-->
          <button nz-button nzType="default" (click)="onAddProductClick()">Add product</button>

          <nz-input-group class="filterclass" [nzPrefix]="suffixTemplateInfo" [nzAddOnAfter]="suffixIconButton" >
            <input type="text" (input)="onInput($event)" [(ngModel)]="filtertext" nz-input placeholder="Filter" />
          </nz-input-group>
          <ng-template #suffixTemplateInfo>
            <span nz-icon nzType="search"></span>
          </ng-template>
          <ng-template #suffixIconButton>
            <button nz-button nzSearch><span nz-icon nzType="close" (click)="clear(dt1)"></span></button>
          </ng-template>
        </div>
      </div>

      <div class="griddiv">
        <p-table
          #dt1
          [value]="iProductsResult"
          [paginator]="true"
          [rows]="10"
          [showCurrentPageReport]="true"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          [rowsPerPageOptions]="[10, 20, 50, 100]"
          [styleClass]="'p-datatable-striped mat-elevation-z1 ' + (selectedSize?.class || '')"
          selectionMode="single"
          rowGroupMode="subheader"
          groupRowsBy="designer"
          [(selection)]="selectedSalesReport"
          (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
          [globalFilterFields]="['name', 'brand', 'description', 'tags', 'product_categories', 'color',
          'materials', 'sku_code', 'supplier', 'supplier_code', 'retail_price', 'wholesale_price']"
          [tableStyle]="{'min-width': '50rem'}">

          <ng-template pTemplate="header">
            <tr>
              <th style="width: 1.5%">Image</th>
              <th style="width: 7%">Name</th>
              <th style="width: 7%">Brand</th>
              <th style="width: 7%">Supplier</th>
              <th style="width: 7%">Categories</th>
              <th style="width: 7%">Description</th>
              <th style="width: 4.5%">Actions</th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-product>
            <tr [pSelectableRow]="product" [ngClass]="{'low-stock-row': product['remaining_quantity'] < product['minimumquantity']}">
              <td>
                <img
                  *ngIf="product?.imagelink"
                  [src]="transformImageUrl(product.imagelink)"
                  alt="Product Image"
                  class="image-size-80x80 shadow-4"
                  onerror="this.src='assets/images/placeholder.jpg';">
              </td>
              <td>
                {{ product["name"] }}<br>
                <strong
                  [ngClass]="{'text-red': product['remaining_quantity'] === 0, 'text-green': product['remaining_quantity'] > 0}">
                  Quantity: {{ product["remaining_quantity"] }}
                </strong>
              </td>
              <td>{{ product["brand"] }}</td>
              <td>{{ product["supplier"] }}</td>
              <td>{{ product["product_categories"] }}</td>
              <td [innerHTML]="product?.description"></td>
              <td>
                <button nz-button nzType="text" nzSize = "small" nzShape="circle" (click)="viewCustomer(product)">
                  <span nz-icon [nzType]="'eye'" [nzTheme]="'fill'"></span>
                </button>
                <button nz-button nzType="text" nzSize = "small" nzShape="circle" (click)="editProduct(product)">
                  <span nz-icon [nzType]="'edit'" [nzTheme]="'fill'"></span>
                </button>
                <button nz-button nzType="text" nzSize="small" nzShape="circle" (click)="deleteProduct(product)">
                  <span nz-icon [nzType]="'delete'" [nzTheme]="'fill'" style="color: red;"></span>
                </button>
              </td>
            </tr>
          </ng-template>
        </p-table>

        <div class="fullline">

        </div>
        <div *ngIf="SelfData.fullPage" class="nextpage">

        </div>
      </div>

    </div>
  </div>
</div>

<nz-dropdown-menu #menu4="nzDropdownMenu">
  <ul nz-menu>
    <!--    <li nz-menu-item *ngIf="currentSample?.['isok'] == 0" (click)="onButtonReturnScanClick(currentSample)">Return & Scan</li>-->
    <!--    <li nz-menu-item (click)="onViewButtonClick(currentSample)">View Details</li>-->
    <!--    <li nz-menu-item (click)="onButtonDesignerClick()">Choose more samples from this designer.</li>-->
    <!--    <li nz-menu-item *ngIf="SelfData.sendoutmode === 0" (click)="onPrintBarcodeClick(currentSample?.['barcode'])">Print Barcode</li>-->
    <!--    <li nz-menu-item (click)="removeSelectedSample(currentSample?.['barcode'])">Remove Sample</li>-->
    <!--    &lt;!&ndash;    <li nz-menu-item *ngIf="currentSample?.['itemstatus'] == 0" (click)="restoreSelectedSample(currentSample?.['barcode'])">Restore Sample</li>&ndash;&gt;-->
    <!--    <li nz-menu-item *ngIf="currentSample?.['requested count'] > 1" (click)="onSameRequestClick(currentSample)">Same Request</li>-->
  </ul>
</nz-dropdown-menu>
