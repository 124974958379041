<div class="top-div">
  <div class="centered-div" #centeredDiv>
    <div *ngIf="modalService.isShowProgramms$ | async" class="buttons-container">
      <ng-container *ngIf="programmscount <= 2; else twoRows">
        <div class="buttons-row" style="display: flex; justify-content: space-between">
          <!--          <button *ngIf="modalService.iRPMonitoring$ | async" mat-raised-button class="button full-width-button" style="width: 100%;">-->
          <!--            <mat-icon class="icon-spacing icon-scale">monitoring</mat-icon> {{rpmonitoring}}</button>-->
          <button (click)="openSamplesPage()" mat-raised-button class="button full-width-button" style="width: 100%">
            <mat-icon class="icon-spacing icon-scale">checkroom
            </mat-icon
            >
            {{ samples }}
          </button>
          <!--                    <button-->
          <!--                            *ngIf="modalService.iContacts$ | async"-->
          <!--                            mat-raised-button-->
          <!--                            class="button full-width-button"-->
          <!--                            style="width: 100%"-->
          <!--                    >-->
          <!--                        <mat-icon class="icon-spacing icon-scale">local_mall-->
          <!--                        </mat-icon-->
          <!--                        >-->
          <!--                        {{ contacts }}-->
          <!--                    </button>-->
          <button *ngIf="modalService.iEvents$ | async" mat-raised-button class="button full-width-button" (click)="onNotificationsClick()"
            style="width: 100%">
            <mat-icon class="icon-spacing icon-scale">perm_media
            </mat-icon>
            {{ events }}
          </button>
        </div>
      </ng-container>
      <ng-template #twoRows>
        <div
          class="buttons-row"
          style="display: flex; justify-content: space-between"
        >
          <!--          <button *ngIf="modalService.iRPMonitoring$ | async" mat-raised-button class="button full-width-button" style="width: 100%;">-->
          <!--            <mat-icon class="icon-spacing icon-scale">monitoring</mat-icon> {{rpmonitoring}}</button>-->
          <button (click)="openSamplesPage()" mat-raised-button class="button full-width-button"
            style="width: 100%">
            <mat-icon class="icon-spacing icon-scale">checkroom</mat-icon>
            Fashion Management
          </button>

          <button (click)="openSalePage()" mat-raised-button class="button full-width-button"
            style="width: 100%">
            <mat-icon class="icon-spacing icon-scale">sell</mat-icon>
            Jewelry Management
          </button>

          <button *ngIf="modalService.iEvents$ | async" mat-raised-button class="button1 full-width-button"
            style="width: 100%">
            <mat-icon class="icon-spacing icon-scale">perm_media</mat-icon>
            {{ events }}
          </button>
          <!--          <button-->
          <!--            *ngIf="modalService.iContacts$ | async"-->
          <!--            mat-raised-button-->
          <!--            class="button full-width-button"-->
          <!--            style="width: 100%"-->
          <!--          >-->
          <!--            <mat-icon class="icon-spacing icon-scale">local_mall</mat-icon-->
          <!--            >{{ contacts }}-->
          <!--          </button>-->
        </div>
        <!--        <div-->
        <!--          class="buttons-row"-->
        <!--          style="display: flex; justify-content: space-between"-->
        <!--        >-->
        <!--          <button-->
        <!--            *ngIf="modalService.iEvents$ | async"-->
        <!--            mat-raised-button-->
        <!--            class="button full-width-button"-->
        <!--            style="width: 100%"-->
        <!--          >-->
        <!--            <mat-icon class="icon-spacing icon-scale">perm_media</mat-icon-->
        <!--            >{{ events }}-->
        <!--          </button>-->
        <!--        </div>-->
      </ng-template>
    </div>
    <div class="row" *ngFor="let newsItem of newsItems">
      <div class="forma-group" *ngIf="newsItem.newsid === 1">
        <mat-card class="custom-mat-card">
          <div class="custom-mat-card-image"
            [style.background-image]="'url(' + newsItem.url + ')'">
          </div>
          <mat-card-content class="truncate-text">
            {{ newsItem.content }}
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>
