import { Component } from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";

@Component({
  selector: 'app-emaildetailsview',
  templateUrl: './emaildetailsview.component.html',
  styleUrl: './emaildetailsview.component.scss'
})
export class EmaildetailsviewComponent {
  constructor(public modalService: ModalserviceService) {}
}
