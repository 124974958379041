<button
  mat-icon-button
  type="button"
  class="close-btn"
  (click)="modalService.closeSWS()"
>
  <mat-icon>close</mat-icon>
</button>
<p class="sample-manager-text">{{ formCaption }}</p>
<div class="black-background">
  <button mat-icon-button (click)="showAddEditFrame(0, null)">
    <mat-icon>add</mat-icon>
  </button>
</div>

<div class="black-background2">
  <div class="card example-container-sw mat-elevation-z3">
    <p-table
      #dt1
      [value]="setdataSource"
      [paginator]="true"
      [rows]="5"
      [showCurrentPageReport]="false"
      [scrollable]="true"
      [scrollHeight]="'350px'"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [globalFilterFields]="['description']"
      [styleClass]="'p-datatable-striped ' + selectedSize.class.toString()"
      selectionMode="single"
      [tableStyle]="{'min-width': '10rem'}">
      <ng-template pTemplate="caption">
        <div class="flex">
          <span class="p-input-icon-left ml-auto">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <i class="pi pi-search" style="color: #000000"></i>
              </span>
              <input pInputText type="text" (input)="onInput($event)" placeholder="Filter" [(ngModel)]="filtertext"/>
              <span class="p-inputgroup-addon custom-hover-red" matRipple (click)="clear(dt1)">
                <i class="pi pi-times" style="color: #000000"></i></span>
            </div>
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width:20%">Description</th>
          <th style="width:5%">Options</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-product>
        <tr [pSelectableRow]="product">
          <td (dblclick)="onRowDoubleClick(product)">{{ product.description }}</td>
          <td>
            <p-button label="Edit" size="small" [text]="true" severity="success"
              (click)="showAddEditFrame(1, product, product.id, product.description)" (dblclick)="onRowDoubleClick(product)">
            </p-button>

            <p-button label="Delete" size="small" [text]="true" severity="danger"
              (click)="deleterecord(product.id)" (dblclick)="onRowDoubleClick(product)">
            </p-button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
