<form [formGroup]="myForm" (ngSubmit)="submit(1)" autocomplete="off">
  <button mat-icon-button type="button" class="close-btn" (click)="onButtonCloseClick()">
    <mat-icon>close</mat-icon>
  </button>
  <p class="sample-manager-text">{{formcaption}}</p>
</form>

<div class="centered-sendout-container1">
  <div class="page1">
    <app-sendoutstep2></app-sendoutstep2>
  </div>
<!--  <div class="page2">-->
<!--    -->
<!--  </div>-->
<!--  <app-sendoutstep1></app-sendoutstep1>-->
<!--  <app-sendoutstep2></app-sendoutstep2>-->
</div>

<div class="button-container-sendout">
  <button mat-raised-button class="button-settings" type="button" (click)="onButtonCloseClick()">
    Update
  </button>
</div>

<p-confirmDialog *ngIf="showdialog" #cd [style]="{width: '25vw'}" [closable]="true">
  <ng-template pTemplate="footer">
    <div class="button-container-request">
      <button mat-raised-button class="button-secondary" style="margin-right: 10px; width: 50%" (click)="cd.reject()">
        No
      </button>
      <button mat-raised-button class="button-primary" style="margin-right: 0; width: 50%" (click)="cd.accept()">
        Yes
      </button>
    </div>
  </ng-template>
</p-confirmDialog>
