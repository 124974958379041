import { Component } from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";

@Component({
  selector: 'app-printerframe',
  templateUrl: './printerframe.component.html',
  styleUrls: ['./printerframe.component.scss']
})
export class PrinterframeComponent {
  constructor(public modalService: ModalserviceService) {}
}
