import {Component, ViewChild} from '@angular/core';
import {EmailEditorComponent} from "angular-email-editor";
import {
  clientmails, GetCollectionList, GetDesignerList,
  GetLookNameList, GetRequestList, GetSeasonList,
  GetSendOutList, SendOut1Data,
  SelfData,
  SetContactsList,
  SetSampleListData,
  Speener
} from "../../models/struct";
import {ModalserviceService} from "../../services/modalservice.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {Router} from "@angular/router";
import {MessageService} from "primeng/api";
import {ServiceService} from "../../services/service.service";
import sample from "../main-menu/sample.json";
import {marked} from "marked";

@Component({
  selector: 'app-sale-main-menu',
  templateUrl: './sale-main-menu.component.html',
  styleUrl: './sale-main-menu.component.scss'
})
export class SaleMainMenuComponent {
  themeValue: 'modern_light' | 'classic_light' | 'classic_dark' | 'modern_dark' | 'light' | 'dark' = 'modern_light';

  options = {
    appearance: {
      theme: this.themeValue,
    },
    version: 'latest',
  };

  scriptUrl = 'https://editor.unlayer.com/embed.js?2';

  @ViewChild('editor')
  private emailEditor!: EmailEditorComponent;

  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
      ? SelfData.account[0].data[0].id
      : SelfData.account[0].data[0].parentid;
  }

  iSendOutList1: GetSendOutList[] = [];

  constructor(
    public modalService: ModalserviceService,
    private yourappsService: YourappsserviceService,
    private router: Router,
    private messageService: MessageService,

    private serviceservice: ServiceService
  ) {
    this.serviceservice
      .getSendOutPendinglist({accountid: this.getAccountID()})
      .subscribe((value) => {
        this.iSendOutList1[0] = value;

        SelfData.sendoutpendinglist = this.iSendOutList1;
        console.log("SelfData.sendoutpendinglist", SelfData.sendoutpendinglist)
        if (SelfData.sendoutpendinglist && SelfData.sendoutpendinglist[0] && SelfData.sendoutpendinglist[0].data) {
          this.itemcount = SelfData.sendoutpendinglist[0].data.length;
        } else {
          this.itemcount = 0; // Или другое значение по умолчанию
        }
        this.yourappsService.getReloadSendOutList(null, 0)
      });

  }
  doSomething() {}

  doSomethingElse() {}

  iSampleDataResult: SetSampleListData[] = [];

  showSampleManager() {
    SelfData.samplespage = 0
    Speener.samplevisiblemode = true;
    this.modalService.closespr();
    this.modalService.closeso();
    SelfData.createsamplebutton = true;
    this.modalService.samplemanageropen();
    this.yourappsService.setSampleAndContavcts(true, false, false, false, false, false, false, false, false);

    // Инициализируем процесс загрузки данных
    SelfData.sampledata = [{Code: 0, ErrorMessage: '', data: []}];
    this.iSampleDataResult = []
    this.loadSampleDataRecursively(1, 3000);
  }

  loadSampleDataRecursively(page: number, pageSize: number) {
    this.serviceservice.setSampleList({ accountid: this.getAccountID(), page, pageSize, forceRefresh: false, samplestyle: SelfData.samplestyle }).subscribe({
      next: (value) => {
        Speener.samplevisiblemode = false;
        if (value && value.data && value.data.length > 0) {
          // Предполагаем, что iSampleDataResult должен хранить массив объектов SetSampleListData
          this.iSampleDataResult = [...this.iSampleDataResult, ...value.data];

          SelfData.sampledata[0].data = this.iSampleDataResult
          // console.log("this.iSampleDataResult", SelfData.sampledata[0].data)
          this.updateUI();

          if (value.data.length < pageSize) {
            console.log('All data has been loaded');
          } else {
            this.loadSampleDataRecursively(page + 1, pageSize);
          }
        } else {
          console.log('The response is empty or undefined.');
          this.updateUI();
        }
      },
      error: (error) => {
        Speener.samplevisiblemode = false;
        console.error('Error occurred:', error);
      }
    });
  }

  updateUI() {
    this.yourappsService.getReloadSampleTableList();
    this.modalService.createSampleHeader();
    this.modalService.createSampleLabel();
    this.modalService.closeSampleGrid();
    this.modalService.closeLookList();
    this.modalService.sendoutlistclose();
    this.modalService.closeContactLabel();
    this.modalService.selectPullsheetcolse();
  }

  showchooseitem() {
    this.modalService.closespr()
    this.modalService.closeso();
    SelfData.createsamplebutton = false
    this.modalService.samplemanageropen();
    this.yourappsService.setSampleAndContavcts(true, false, false, false, false, false, false, false, false);
  }

  onButtonHome() {
    this.modalService.closespr()
    this.modalService.closeso();
    this.router.navigate(['']);
  }

  // ngAfterViewInit() {
  //   // Проверяем, существует ли элемент с id 'editor'
  //   const editorElement = document.getElementById('editor');
  //   if (editorElement) {
  //     // Если элемент найден, инициализируем редактор
  //     (window as any).unlayer.init({
  //       id: 'editor',
  //       displayMode: 'email',
  //       projectId: 12345,
  //       onLoad: () => {
  //         this.editorReady = true;
  //         console.log('Editor is ready!');
  //       }
  //     });
  //   } else {
  //     console.error('Element with id "editor" was not found.');
  //   }
  // }

  editorLoaded($event: any) {
    console.log('editorLoaded');
    this.emailEditor.editor.loadDesign(sample);
  }

  editorReady($event: any) {
    console.log('editorReady');
  }

  saveDesign() {
    this.emailEditor.editor.saveDesign((data: any) =>
      console.log('saveDesign', data)
    );
  }

  exportHtml() {
    this.emailEditor.editor.exportHtml((data) =>
      console.log('exportHtml', data)
    );
  }

  onButtonCreateSampleClick() {
    SelfData.sampledata = [];
    SelfData.componentFieldData = []

    SelfData.updatebuttonisenabled = true
    this.yourappsService.selectSampleData(null);
    this.serviceservice
      .getComponentFields({
        formaname: "createsamplesdialog",
        accountid: this.getAccountID(),
        userid: SelfData.account[0].data[0].id,
      })
      .subscribe((response) => {
        if (response.Code === 200) {
          this.SelfData.componentFieldData = response.data
          // const fieldData = response.data;
          //
          // fieldData.forEach((field: any) => {
          //   const propertyName = field.componentname as keyof typeof this; // Явное указание типа ключа
          //   if (propertyName in this) {
          //     (this[propertyName] as boolean) = field.isshow; // Приведение типа
          //   }
          // });
          this.modalService.createsamplesaleopen();
          console.log("Обновленные свойства:", this);
        } else {
          console.error("Ошибка: ", response.ErrorMessage);
        }
      });
    // this.modalService.createsampleropen();
    this.modalService.closespr()
    this.modalService.closeso();
  }

  onButtonCreateContactClick() {
    SelfData.contactdata = [];
    this.yourappsService.selectSampleData(null);
    this.yourappsService.selectContact(null, 0)
    this.modalService.createcontactopen();
    this.modalService.closespr()
    this.modalService.closeso();
  }

  iLookNameResult: GetLookNameList[] = [];
  showlookmanager() {
    // SelfData.createsamplebutton = true
    this.modalService.samplemanageropen();
    this.yourappsService.setSampleAndContavcts(false, false, false, true, false, false, false, false, false);
    SelfData.selectlookmode = 0
    this.iLookNameResult = []
    this.serviceservice.setLookName({
      accountid: this.getAccountID(),
      designer: "",
      collection: "",
      season: "",
      gender: "",
      type: "",
      color: "",
      size: "",
      fabric: ""
    }).subscribe({
      next: (value) => {
        if (value) {
          Speener.lookbookvisiblemode = false
          this.iLookNameResult[0] = value;
          SelfData.lookname = this.iLookNameResult;
          this.yourappsService.getReloadLookNames();
          this.modalService.closeSampleHeader();
          this.modalService.closeSampleLabel();
          this.modalService.closeSampleGrid();
          this.modalService.closeReques()
          this.modalService.sendoutlistclose();
          this.modalService.createLookList()
          this.modalService.selectPullsheetcolse()
        }
      }
    })
    this.modalService.closespr()
    this.modalService.closeso();
  }

  iContactsResult: SetContactsList[] = [];
  showcontactManager() {
    this.modalService.closespr()
    this.modalService.closeso();
    this.modalService.samplemanageropen();
    this.yourappsService.setSampleAndContavcts(false, true, false, false, false, false, false, false, false);
    this.serviceservice.setContactsList({accountid: this.getAccountID(), contactsid: 0})
      .subscribe({
        next: (value) => {
          if (value) {
            this.iContactsResult[0] = value;
            Speener.sendoutvisiblemode = false
            SelfData.contactdata = this.iContactsResult;
            this.modalService.createContactLabel()

          } else {
            console.log('The response is empty or undefined.');
          }
        },
        error: (error) => {
          console.error('Error occurred during the subscription:', error);
        },
      });
  }

  iCMList: clientmails[] = [];
  showcontactMailing() {
    this.modalService.closespr()
    this.modalService.cmclose();
    Speener.cmvisiblemode = true
    this.serviceservice
      .getContactMailList({accountid: this.getAccountID()})
      .subscribe((value) => {
        this.iCMList[0] = value;
        Speener.cmvisiblemode = false
        this.modalService.cmopen();
        SelfData.cmlist = this.iCMList;
      });
    this.modalService.samplemanageropen();
    this.yourappsService.setSampleAndContavcts(false, false, false, false, false, false, false, false, true);
  }

  iSendOutList: GetSendOutList[] = [];
  showSendOutManager() {
    this.modalService.closespr()
    this.modalService.closeso();
    SelfData.selectsendoutmode = 0
    this.modalService.sendoutlistclose();
    Speener.sendoutvisiblemode = true
    this.serviceservice
      .getSendOutlist({accountid: this.getAccountID()})
      .subscribe((value) => {
        this.iSendOutList[0] = value;
        Speener.sendoutvisiblemode = false
        this.modalService.sendoutlistopen();
        SelfData.sendoutlist = this.iSendOutList;
      });
    this.modalService.samplemanageropen();
    this.yourappsService.setSampleAndContavcts(false, false, true, false, false, false, false, false, false);
  }

  showSendOutPendingManager() {
    Speener.sendoutvisiblemode = true
    this.modalService.closespr()
    this.modalService.closeso();
    this.modalService.sendoutlistclose();

    this.serviceservice
      .getSendOutPendinglist({accountid: this.getAccountID()})
      .subscribe((value) => {
        this.iSendOutList1[0] = value;
        this.modalService.sendoutpendingopen();
        SelfData.sendoutpendinglist = this.iSendOutList1;
        Speener.sendoutvisiblemode = false
        this.yourappsService.getReloadSendOutList(null, 0)
      });
    this.modalService.samplemanageropen();
    this.yourappsService.setSampleAndContavcts(false, false, false, false, false, false, false, true, false);

  }

  iRequestList: GetRequestList[] = [];
  showRequestManager() {
    this.modalService.closespr()
    this.modalService.closeso();
    this.modalService.closeReuestList()
    Speener.requestvisiblemode = true
    this.modalService.sendoutlistclose();
    this.serviceservice
      .getRequestlist({accountid: this.getAccountID()})
      .subscribe((value) => {
        this.iRequestList[0] = value;
        Speener.requestvisiblemode = false
        SelfData.requesttlist = this.iRequestList;
        this.modalService.createReuestList();
      });

    this.modalService.samplemanageropen();
    this.yourappsService.setSampleAndContavcts(false, false, false, false, false, true, false, false, false);
  }

  showPullSheetManager() {
    this.modalService.closespr()
    this.modalService.closeso();
    this.modalService.samplemanageropen();
    this.yourappsService.setSampleAndContavcts(false, false, false, false, true, false, false, false, false);
  }

  iCollection: GetCollectionList[] = [];
  iDesignerResult: GetDesignerList[] = [];
  iSeasonResult: GetSeasonList[] = [];
  showCDLList() {
    this.modalService.closespr()
    this.modalService.closeso();
    this.modalService.samplemanageropen();
    this.yourappsService.setSampleAndContavcts(false, false, false, false, false, false, true, false, false);
    this.serviceservice
      .setCollectionList({ accountid: this.getAccountID() })
      .subscribe((collectionvalue) => {
        this.iCollection[0] = collectionvalue;
        SelfData.collection = this.iCollection;

      });

    this.serviceservice
      .setDesignerList({ accountid: this.getAccountID() })
      .subscribe((desingervalue) => {
        this.iDesignerResult[0] = desingervalue;
        SelfData.designer = this.iDesignerResult;
      });

    this.serviceservice
      .setSeasonList({ accountid: this.getAccountID() })
      .subscribe((seasonvalue) => {
        this.iSeasonResult[0] = seasonvalue;
        SelfData.season = this.iSeasonResult;
        this.modalService.openCDL()
      });
  }

  onButtonPullSheetSettingsClick() {
    this.modalService.closespr()
    this.modalService.closeso();
    this.modalService.openPullSheetTemplate()
  }

  createPullSheet() {
    this.modalService.openCPS()
  }

  onButtonPrintEmptyClick() {
    this.modalService.closespr()
    this.modalService.closeso();
    SelfData.selectadpage = 5
    console.log(SelfData.setSample)
    this.modalService.openPrinterFrame()
  }

  createSendOut() {
    this.modalService.closespr()
    this.modalService.closeso();
    this.yourappsService.selectSampleData(null);
    SendOut1Data.reset()
    SelfData.sendoutid = 0
    SelfData.sendoutopentype = 0
    SelfData.sendoutmode = 0
    SelfData.saveonclose = true
    SelfData.fullPage = false
    SelfData.sendoutgroupecaption = "Send Out Details"
    this.yourappsService.selectedSendOut(null);
    this.modalService.createsendoutopen();

    // this.yourappsService.setSampleAndContavcts(false, false, true)
  }

  createRequest() {
    this.modalService.closespr()
    this.modalService.closeso();
    this.yourappsService.selectSampleData(null);
    SelfData.sendoutopentype = 0
    SelfData.sendoutmode = 1
    SelfData.sendoutgroupecaption = "Request Details"
    this.modalService.openRequest();
  }

  onButtonVasiaClick() {
    this.modalService.closespr()
    this.modalService.closeso();
    this.modalService.openVasia()
  }

  onButtonLSBClick() {
    this.modalService.closespr()
    this.modalService.closeso();
    this.modalService.openlsb()
  }

  onButtonLBClick() {
    this.modalService.closespr()
    this.modalService.closeso();
    SelfData.lbframetype = 0
    this.modalService.openlb()
  }

  onButtonsprclick() {
    this.serviceservice
      .selectUserPreferences(SelfData.account[0].data[0].id)
      .subscribe({
        next: (value: any) => {
          if (value && value.data && Array.isArray(value.data)) {
            SelfData.userfilter[0] = value;
            console.log("SelfData.userfilter", SelfData.userfilter)
          } else {
            console.log(
              'The response is empty or not in the expected format.'
            );
          }
        },
        error: (error) => {
          console.error('Error occurred during the subscription:', error);
        },
        complete: ()=> {
          this.yourappsService.setSampleAndContavcts(false, false, false, false, false, false, false, false, false);
          this.modalService.openspr()
        }
      });
  }

  protected readonly SelfData = SelfData;
  itemcount: number = 0;
  protected readonly marked = marked;

  onNotificationsClick() {
    this.modalService.openCS()
  }

  onButtonsoclick() {
    this.modalService.closespr()
    this.modalService.closeso();
    this.serviceservice
      .selectUserPreferences(SelfData.account[0].data[0].id)
      .subscribe({
        next: (value: any) => {
          if (value && value.data && Array.isArray(value.data)) {
            SelfData.userfilter[0] = value;
            console.log("SelfData.userfilter", SelfData.userfilter)
          } else {
            console.log(
              'The response is empty or not in the expected format.'
            );
          }
        },
        error: (error) => {
          console.error('Error occurred during the subscription:', error);
        },
        complete: ()=> {
          this.yourappsService.setSampleAndContavcts(false, false, false, false, false, false, false, false, false);
          this.modalService.openso()
        }
      });
  }
}
