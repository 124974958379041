<form [formGroup]="myForm" (ngSubmit)="submit()" autocomplete="off">
  <button mat-icon-button type="button" class="close-btn" (click)="modalService.closePrinterFrame()">
    <mat-icon>close</mat-icon>
  </button>
  <p class="sample-manager-text-dm">Address Label</p>
  <div id="scrollable-div-dm" class="scrollable-div-dm">
    <div class="full-width-div-dm">
      <div class="form-field-spacing-contact form-field-h-dm"
           style="overflow-y: auto;border: 0 solid #ccc;padding: 5px;resize: none;">
        <p *ngFor="let msg of messages1" [style.color]="msg.color" [innerHTML]="msg.text">

        </p>
      </div>
      <div class="barcode-div-dm">

      </div>
    </div>
    <!--2 line-->
    <div class="black-background1-dm">
      <div class="child-60-al">
        <div class="full-width-div-dm1">
          <div class="red-overlay1"></div>
          <div class="form-field-spacing-contact form-field-h-dm"
               style="overflow-y: auto;border: 0px solid #ccc;padding: 5px;resize: none;">
            <p *ngFor="let msg of messages2" [style.color]="msg.color" [innerHTML]="msg.text">
            </p>
          </div>

          <div class="red-overlay2"></div>
        </div>
      </div>
      <div class="child-40-al">
<!--        <div class="form-field-spacing-contact form-field-h-dm"-->
<!--             style="overflow-y: auto;border: 1px solid #ccc;padding: 5px;resize: none; background-color: #e5e5e5;">-->
          <div class="barcode-div-dm">
            <div class="red-overlay">
              <span
                class="centered-text">SCAN HERE</span>
            </div>
            <app-barcode [element]="SelfData.account[0].data[0].barcodeprefix+SelfData.sendoutid" [text]="'fashion-m.com'" [height]="60" [width]="1.48" [marginBottom]="25"
                         [marginRight]="-0.00001" [fontSize]="14" [background]="'rgba(255,255,255,0)'" class="barcode-component"></app-barcode>
          </div>
          <!--          <p *ngFor="let msg of messages1" [style.color]="msg.color" [innerHTML]="msg.text"></p>-->
<!--        </div>-->
      </div>
    </div>

    <!--        3 line-->
    <div class="full-width-div-dm2">
      <section class="example-container-print1" tabindex="0">
        <div class="full-width-div-dm4">
          <div class="center-text">
            <span *ngIf="messages_1.length > 0">{{messages_1[0].text}}</span>
          </div>
        </div>
      </section>
    </div>

  </div>
  <!--  <div class="button-container-dm-left">-->
  <!--    <button mat-raised-button class="button-settings-dm" type="button">-->
  <!--      Edit-->
  <!--      <mat-icon>edit</mat-icon>-->
  <!--    </button>-->
  <!--  </div>-->
  <div class="button-container-dm">

    <button mat-raised-button class="button-settings-dm" type="button" (click)="onButtonPrintClick()">
      Print
      <mat-icon>print</mat-icon>
    </button>
    <!--    <button mat-raised-button class="button-settings-dm" type="button">-->
    <!--      E-Mail-->
    <!--      <mat-icon>mail</mat-icon>-->
    <!--    </button>-->
    <!--    <button mat-raised-button class="button-settings-dm" type="button">-->
    <!--      Barcodes-->
    <!--      <mat-icon>barcode_scanner</mat-icon>-->
    <!--    </button>-->
    <!--    <button mat-raised-button class="button-settings-dm" type="button">-->
    <!--      Excel-->
    <!--      <mat-icon>heap_snapshot_large</mat-icon>-->
    <!--    </button>-->
  </div>
</form>
