import { Component } from '@angular/core';
import { ModalserviceService } from '../../services/modalservice.service';

@Component({
  selector: 'app-notificationsettings',
  templateUrl: './notificationsettings.component.html',
  styleUrls: ['./notificationsettings.component.scss'],
})
export class NotificationsettingsComponent {
  constructor(public modalService: ModalserviceService) {}
}
