<button mat-icon-button type="button" class="close-btn" (click)="modalService.closeScann()">
  <mat-icon>close</mat-icon>
</button>

<p class="sample-manager-text-rrc">Scann Items List</p>
<button [disabled]="SelfData.scannedfulllist[0].data.length <= 0 || sendfunction" mat-raised-button type="button" class="add-button-step21" matSuffix
  (click)="onSelectAllClick()">
  Add All
</button>
<div class="tablediv">
  <p-table
    [value]="SelfData.scannedfulllist[0].data"
    [paginator]="true"
    [rows]="10"
    [showCurrentPageReport]="true"
    [tableStyle]="{ 'width': '100%'}"
    [scrollable]="true"
    [scrollHeight]="'calc(80% - 200px)'"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [rowsPerPageOptions]="[10, 20, 50, 100]"
    [styleClass]="'p-datatable-striped mat-elevation-z1 p-datatable-sm'"
    selectionMode="single">
    <ng-template pTemplate="header">
      <tr>
        <!--                    -->
        <th style="width:4%">Options</th>
        <th style="width:17.6%">Designer<br>Collection<br>Season</th>
        <th style="width:17.6%">Type<br>Barcode<br>Color</th>
        <th style="width:17.6%">Gender<br>Region<br>Size</th>
        <th style="width:17.6%">Description</th>
        <th style="width:7%">Image</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-product>
      <tr [ngStyle]="{'background-color':
                  (product['requested count'] > 1) ? 'rgba(255,217,171,0.2)' :
                  (product['requested count'] < 0 || (product['isok'] === 0 || product['itemstatus'] === 0)) ? '#ffdddd' :
                  ''}"
        [pSelectableRow]="product">
        <div class="button-container-table">
          <td>
<!--          <p-splitButton (click)="currentSample = product"-->
<!--            label="select" [matMenuTriggerFor]="menu"-->
<!--            styleClass="p-button-outlined p-button-secondary mr-2 mb-2"></p-splitButton>-->
            <button type="button" nzTrigger="click" nz-button nz-dropdown [nzDropdownMenu]="menu4" (click)="currentSample = product">
              select
              <span nz-icon nzType="down"></span>
            </button>
        </td>
        </div>
        <!--        <td>-->
        <!--          <p-splitButton (click)="currentSample = product"-->
        <!--            label="select" [matMenuTriggerFor]="menu"-->
        <!--            styleClass="p-button-outlined p-button-secondary mr-2 mb-2"></p-splitButton>-->
        <!--        </td>-->
        <td>{{ product["Designer"] }}<br>{{ product["Collection"] }}<br>{{ product["Season"] }}</td>
        <td>{{ product["Type"] }}<br>{{ product["barcode"] }}<br>{{ product["Color"] }}</td>
        <td>{{ product["Gender"] }}<br>
          {{ product["Region"] }}<br>
          {{ product["Size"] }}
        </td>
        <td>{{ product["Description"] }}</td>
        <td><img [src]="transformImageUrl(product['Image Link'])" alt="Image" class="image-size-80x80 shadow-4"></td>
<!--        <td><img [src]="product['Image Link']" alt="Image" class="image-size-80x80 shadow-4"></td>-->
      </tr>
    </ng-template>
  </p-table>
  <div class="fullline">

  </div>

</div>


<mat-menu #menu="matMenu">

  <button mat-menu-item (click)="onSelectedItemCLick(currentSample?.['barcode'] ?? '')">
    <!-- <mat-icon>document_scanner</mat-icon> -->
    <span>Select this Item</span>
  </button>

  <button mat-menu-item (click)="onViewButtonClick(currentSample)">
    <!--        <mat-icon>visibility</mat-icon>-->
    <span>View Details</span>
  </button>

  <button mat-menu-item (click)="onDeleteItemCLick(currentSample)">
    <!--        <mat-icon>visibility</mat-icon>-->
    <span>Delete Item</span>
  </button>

</mat-menu>

<nz-dropdown-menu #menu4="nzDropdownMenu">
  <ul nz-menu>
    <li nz-menu-item (click)="onSelectedItemCLick(currentSample?.['barcode'] ?? '')">Select this Item</li>
    <li nz-menu-item (click)="onViewButtonClick(currentSample)">View Details</li>
    <li nz-menu-item (click)="onDeleteItemCLick(currentSample)">Delete Item</li>
  </ul>
</nz-dropdown-menu>

<p-confirmDialog *ngIf="showdialog" #cd [style]="{width: '25vw'}" [closable]="true">
  <!--  <ng-template pTemplate="header">-->
  <!--    <h3 class="red-text">This item is on loan!</h3>-->
  <!--  </ng-template>-->
  <ng-template pTemplate="footer">
    <!--        <button type="button" pButton label="No" (click)="cd.reject()"></button>-->
    <div class="button-container-request">
      <button mat-raised-button class="button-secondary" style="margin-right: 10px; width: 50%"
        (click)="cd.reject()">
        No
      </button>
      <button mat-raised-button class="button-primary" style="margin-right: 0; width: 50%" (click)="cd.accept()">
        Yes
      </button>
    </div>
    <!--        <button type="button" pButton label="Yes" (click)="cd.accept()"></button>-->
  </ng-template>
</p-confirmDialog>
