import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {Table} from "primeng/table";
import {Subscription} from "rxjs";
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder} from "@angular/forms";
import {HashService} from "../../services/hash.service";
import {MessageboxComponent} from "../../modals/messagebox/messagebox.component";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {ConfirmationService, MessageService} from "primeng/api";
import {MatSnackBar} from "@angular/material/snack-bar";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {
  maildetailsview,
  maildetailsviewData,
  SelfData,
  SetContactsListData,
  SetSampleListData,
  Speener
} from "../../models/struct";

@Component({
  selector: 'app-emaildetailsviewdialog',
  templateUrl: './emaildetailsviewdialog.component.html',
  styleUrl: './emaildetailsviewdialog.component.scss'
})
export class EmaildetailsviewdialogComponent implements OnInit, AfterViewInit {
  @ViewChild('dt1') dt1!: Table;
  filtertext: string = ""
  filteredValue: any[] = [];
  samplewait = true
  subscription?: Subscription;
  sendedcount: number = 0;
  openedcount: number = 0;
  fullsendedcount: string = "";
  fullsendedprocentcount: string = "";
  fullopenedcount: string = "";
  fullopenedprocentcount: string = "";
  iContactDetailsResult: maildetailsview[] = [];
  sizes!: any[];
  selectedSize: any = '';
  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private hashService: HashService,
    public messageBox: MessageboxComponent,
    private serviceservice: ServiceService,
    private yourappsService: YourappsserviceService,
    private confirmationService: ConfirmationService,
    private _snackBar: MatSnackBar,
    private notification: NzNotificationService,
    private messageService: MessageService
  ) {
    this.sizes = [
      { name: 'Small', class: 'p-datatable-sm' }
    ];
    this.selectedSize = this.sizes[0]
    this.subscription = this.yourappsService.mailDetailsView$.subscribe((value: { data: any, type: number }) => {
      if (value.type === 1) {
        console.log("value.data", value.data);
        this.sendedcount = value.data?.['Sended'] ? Math.round((value.data?.['SendedON'] / value.data?.['Sended']) * 100) : 0
        this.openedcount = value.data?.['Sended'] ? Math.round((value.data?.['OpenON'] / value.data?.['Sended']) * 100) : 0
        this.fullsendedcount = `${value.data?.['SendedON']} of ${value.data?.['Sended']}`
        this.fullopenedcount = `${value.data?.['OpenON']} of ${value.data?.['Sended']}`
        this.fullsendedprocentcount = String(value.data?.['Sended'] ? Math.round((value.data?.['SendedON'] / value.data?.['Sended']) * 100) : 0)
        this.fullopenedprocentcount = String(value.data?.['Sended'] ? Math.round((value.data?.['OpenON'] / value.data?.['Sended']) * 100) : 0)

        this.serviceservice
          .getMailDetailsView({tblsetupid: value.data?.['Setupid']})
          .subscribe((value) => {
            this.iContactDetailsResult[0] = value;
            console.log(this.iContactDetailsResult[0])
            this.samplewait = false
          });
        // yourappsService.setMailDetailsView(null, 0)
      }
    })
  }

  formatCustomDate(date: any): string {
    if (!date) {
      return '';
    }

    // Преобразуем строку в объект Date, если это необходимо
    const parsedDate = date instanceof Date ? date : new Date(date);

    if (isNaN(parsedDate.getTime())) {
      // Если parsedDate - невалидная дата, возвращаем пустую строку
      return '';
    }

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const day = String(parsedDate.getDate()).padStart(2, '0');
    const month = months[parsedDate.getMonth()];
    const year = String(parsedDate.getFullYear()).slice(-2);
    const hours = String(parsedDate.getHours()).padStart(2, '0');
    const minutes = String(parsedDate.getMinutes()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}`;
  }

  get firstContactsData(): maildetailsviewData[] {
    if (this.iContactDetailsResult && this.iContactDetailsResult[0] && this.iContactDetailsResult[0].data) {
      return this.iContactDetailsResult[0].data;
    }
    return [];
  }

  onInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;
    this.dt1.filterGlobal(value, 'contains');
  }

  clear(table: Table) {
    table.clear();
    this.filtertext = ""
  }

  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
      ? SelfData.account[0].data[0].id
      : SelfData.account[0].data[0].parentid;
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
  }

  protected readonly Math = Math;
}
