<div class="full-width-div">
  <div class="icon-button-container">
    <button mat-icon-button class="icon-button" (click)="showTable()">
      <mat-icon>table_rows</mat-icon>
    </button>
    <button mat-icon-button class="icon-button" (click)="showGrid()">
      <mat-icon>grid_view</mat-icon>
    </button>
  </div>
</div>
