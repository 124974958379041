import { Component } from '@angular/core';
import { ModalserviceService } from '../../services/modalservice.service';

@Component({
  selector: 'app-createsample',
  templateUrl: './createsample.component.html',
  styleUrls: ['./createsample.component.scss'],
})
export class CreatesampleComponent {
  constructor(public modalService: ModalserviceService) {}
}
