<!--<form [formGroup]="myForm" (ngSubmit)="submit(1)" autocomplete="off">-->
  <button
    mat-icon-button
    type="button"
    class="close-btn"
    (click)="modalService.createcompanyclose()"
  >
    <mat-icon>close</mat-icon>
  </button>
  <p class="sample-manager-text">Create Company</p>
  <mat-divider class="black-divider-contact"></mat-divider>
  <div class="scrollable-div-contact horizontal-container-contact">
    <div class="black-background-company">
      <p class="caption-text">Company Information</p>
      <div class="full-width-div-company">
        <div class="form-field-spacing-contact form-field-spacing">
          <label class="line1-edits-new inputlabel">Department</label>
          <nz-input-group nzSearch>
            <input disabled="true" class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="departmentstring"
              nz-input/>
          </nz-input-group>
        </div>
<!--        <mat-form-field class="form-field-spacing-contact" appearance="fill">-->
<!--          <mat-label>Department</mat-label>-->
<!--          <input matInput [formControl]="departmentFormControl" required />-->
<!--        </mat-form-field>-->

        <div class="example-full-width form-field-spacing-contact">
          <label class="line1-edits-new inputlabel">Phone</label>
          <nz-input-group nzSearch>
            <input class="line1-edits-new inputedit testdiv" type="tel" [(ngModel)]="phonestring" nz-input/>
          </nz-input-group>
        </div>

<!--        <div class="example-full-width form-field-spacing-contact">-->
<!--          <mat-form-field class="selector-full-width" appearance="fill">-->
<!--            <mat-label>Phone</mat-label>-->
<!--            <input matInput [formControl]="phoneControl" />-->
<!--          </mat-form-field>-->
<!--        </div>-->


        <div class="form-field-spacing-contact">
          <label class="line1-edits-new inputlabel">Company Name</label>
          <nz-input-group [nzStatus]="statuscompanyname" nzSearch>
            <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="companyNamestring" nz-input/>
          </nz-input-group>
        </div>
<!--        <div class="example-full-width form-field-spacing-contact">-->
<!--          <mat-form-field class="selector-full-width" appearance="fill">-->
<!--            <mat-label>Company Name</mat-label>-->
<!--            <input matInput [formControl]="companynameFormControl" required />-->
<!--            <mat-error *ngIf="companynameFormControl.hasError('required')">-->
<!--              Company Name is <strong>required</strong>-->
<!--            </mat-error>-->
<!--          </mat-form-field>-->
<!--        </div>-->

        <div class="example-full-width form-field-spacing-contact">
          <label class="line1-edits-new inputlabel">WebSite</label>
          <nz-input-group nzSearch>
            <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="websitestring" nz-input/>
          </nz-input-group>
<!--          <mat-form-field class="selector-full-width" appearance="fill">-->
<!--            <mat-label>WebSite</mat-label>-->
<!--            <input matInput [formControl]="websiteControl" />-->
<!--          </mat-form-field>-->
        </div>

        <div class="example-full-width form-field-spacing-contact">
          <label class="line1-edits-new inputlabel">Publication Company</label>
          <nz-input-group nzSearch>
            <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="publicationcompanystring" nz-input/>
          </nz-input-group>
<!--          <mat-form-field class="selector-full-width" appearance="fill">-->
<!--            <mat-label>Publication Company</mat-label>-->
<!--            <input matInput [formControl]="publicationCompanyControl" />-->
<!--          </mat-form-field>-->
        </div>

        <div class="example-full-width form-field-spacing-contact">
          <label class="line1-edits-new inputlabel">Email</label>
          <nz-input-group nzSearch>
            <input class="line1-edits-new inputedit testdiv" type="email" [(ngModel)]="emailrstring" nz-input/>
          </nz-input-group>
<!--          <mat-form-field class="selector-full-width" appearance="fill">-->
<!--            <mat-label>Email</mat-label>-->
<!--            <input type="email" matInput [formControl]="emailControl" />-->
<!--          </mat-form-field>-->
        </div>

        <div class="example-full-width form-field-spacing-contact">
          <label class="line1-edits-new inputlabel">Type</label>
          <nz-input-group nzSearch [nzAddOnAfter]="typebutton">
            <nz-select [nzAllowClear]="true" nzShowSearch [nzShowArrow]="true" class="line1-edits-new inputedit testdiv"
              [(ngModel)]="typestring">
              <nz-option *ngFor="let option of (iCompTypeResult[0]?.data ?? [])" [nzLabel]="option.description"
                [nzValue]="option.description"></nz-option>
            </nz-select>
          </nz-input-group>
          <ng-template #typebutton>
            <button type="button" (click)="add($event, 0, null)" nz-button nzType="default" nzSearch><span nz-icon
              nzType="plus"></span></button>
          </ng-template>
<!--          <mat-form-field class="selector-full-width" appearance="fill">-->
<!--            <mat-label>Type</mat-label>-->
<!--            <input-->
<!--              type="text"-->
<!--              matInput-->
<!--              placeholder="Select or type..."-->
<!--              [matAutocomplete]="auto1"-->
<!--              [formControl]="typeControl"-->
<!--              #autoInput1="matAutocompleteTrigger"-->
<!--            />-->
<!--            <button-->
<!--              mat-stroked-button-->
<!--              type="button"-->
<!--              class="add-button-company"-->
<!--              matSuffix-->
<!--              (click)="add($event, 0, autoInput1)"-->
<!--            >-->
<!--              <mat-icon class="center-icon">add</mat-icon>-->
<!--            </button>-->
<!--            <mat-autocomplete #auto1="matAutocomplete">-->
<!--              <mat-option-->
<!--                *ngFor="let item of filteredCompTypeOptions | async"-->
<!--                [value]="item"-->
<!--              >-->
<!--                {{ item }}-->
<!--              </mat-option>-->
<!--            </mat-autocomplete>-->
<!--          </mat-form-field>-->
        </div>

        <div class="example-full-width form-field-spacing-contact">
          <label class="line1-edits-new inputlabel">E-Invoicing Code</label>
          <nz-input-group nzSearch>
            <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="eiCodestring" nz-input/>
          </nz-input-group>
<!--          <mat-form-field class="selector-full-width" appearance="fill">-->
<!--            <mat-label>E-Invoicing Code</mat-label>-->
<!--            <input matInput [formControl]="eInvoicingCodeControl" />-->
<!--          </mat-form-field>-->
        </div>

        <div class="example-full-width form-field-spacing-contact">
          <label class="line1-edits-new inputlabel">Marker</label>
          <nz-input-group nzSearch>
            <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="markerstring" nz-input/>
          </nz-input-group>
<!--          <mat-form-field class="selector-full-width" appearance="fill">-->
<!--            <mat-label>Marker</mat-label>-->
<!--            <input matInput [formControl]="markerControl" />-->
<!--          </mat-form-field>-->
        </div>

        <div class="example-full-width form-field-spacing-contact">
          <label class="line1-edits-new inputlabel">Tax ID</label>
          <nz-input-group nzSearch>
            <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="taxidstring" nz-input/>
          </nz-input-group>
<!--          <mat-form-field class="selector-full-width" appearance="fill">-->
<!--            <mat-label>Tax ID</mat-label>-->
<!--            <input type="email" matInput [formControl]="taxidContorl" />-->
<!--          </mat-form-field>-->
        </div>

        <div class="example-full-width form-field-spacing-contact">
          <label class="line1-edits-new inputlabel">Manufacture Code</label>
          <nz-input-group nzSearch>
            <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="manufacturecodestring" nz-input/>
          </nz-input-group>
<!--          <mat-form-field class="selector-full-width" appearance="fill">-->
<!--            <mat-label>Manufacture Code</mat-label>-->
<!--            <input-->
<!--              type="email"-->
<!--              matInput-->
<!--              [formControl]="manufactureCodeContorl"-->
<!--            />-->
<!--          </mat-form-field>-->
        </div>

        <div class="example-full-width form-field-spacing-contact">
          <label class="line1-edits-new inputlabel">Notes</label>
          <nz-input-group nzSearch>
            <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="notesstring" nz-input/>
          </nz-input-group>
<!--          <mat-form-field class="selector-full-width" appearance="fill">-->
<!--            <mat-label>Notes</mat-label>-->
<!--            <input type="email" matInput [formControl]="notesContorl" />-->
<!--          </mat-form-field>-->
        </div>
      </div>

      <mat-divider class="black-divider-contact"></mat-divider>
      <p class="caption-text">Address / Location</p>
      <div class="full-width-div-company">
        <div class="example-full-width form-field-spacing-contact">
          <label class="line1-edits-new inputlabel">Care Of</label>
          <nz-input-group nzSearch>
            <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="careofstring" nz-input/>
          </nz-input-group>
<!--          <mat-form-field class="selector-full-width" appearance="fill">-->
<!--            <mat-label>Care Of</mat-label>-->
<!--            <input matInput [formControl]="careoff1Contro2" />-->
<!--          </mat-form-field>-->
        </div>

        <div class="form-field-spacing-contact">
          <label class="line1-edits-new inputlabel">Country</label>
          <nz-input-group nzSearch >
            <nz-select nzShowSearch [nzShowArrow]="true" class="line1-edits-new inputedit testdiv"
              (ngModelChange)="onOptionSelected($event)"
              [(ngModel)]="countrystring">
              <nz-option *ngFor="let option of (countries ?? [])" [nzLabel]="option['country']"
                [nzValue]="option['country']"></nz-option>
            </nz-select>
          </nz-input-group>
<!--          <mat-form-field class="selector-full-width" appearance="fill">-->
<!--            <mat-label>Country</mat-label>-->
<!--            <input-->
<!--              type="text"-->
<!--              matInput-->
<!--              placeholder="Select or type..."-->
<!--              [matAutocomplete]="auto27"-->
<!--              [formControl]="countri2Control"-->
<!--            />-->
<!--            <mat-autocomplete-->
<!--              #auto27="matAutocomplete"-->
<!--              (optionSelected)="onOptionSelected($event)"-->
<!--            >-->
<!--              <mat-option-->
<!--                *ngFor="let item of filteredCountries | async"-->
<!--                [value]="item.country"-->
<!--              >-->
<!--                {{ item.country }}-->
<!--              </mat-option>-->
<!--            </mat-autocomplete>-->
<!--          </mat-form-field>-->
        </div>

        <div class="example-full-width form-field-spacing-contact">
          <label class="line1-edits-new inputlabel">Address1</label>
          <nz-input-group nzSearch>
            <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="address1string" nz-input/>
          </nz-input-group>
<!--          <mat-form-field class="selector-full-width" appearance="fill">-->
<!--            <mat-label>Address1</mat-label>-->
<!--            <input matInput [formControl]="address12Control" />-->
<!--          </mat-form-field>-->
        </div>

        <div class="example-full-width form-field-spacing-contact">
          <label class="line1-edits-new inputlabel">Email</label>
          <nz-input-group nzSearch>
            <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="otherEmail2string" nz-input/>
          </nz-input-group>
<!--          <mat-form-field class="selector-full-width" appearance="fill">-->
<!--            <mat-label>Email</mat-label>-->
<!--            <input type="email" matInput [formControl]="otherEmail2Control" />-->
<!--          </mat-form-field>-->
        </div>

        <div class="example-full-width form-field-spacing-contact">
          <label class="line1-edits-new inputlabel">Address2</label>
          <nz-input-group nzSearch>
            <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="address2string" nz-input/>
          </nz-input-group>
<!--          <mat-form-field class="selector-full-width" appearance="fill">-->
<!--            <mat-label>Address2</mat-label>-->
<!--            <input matInput [formControl]="address22Control" />-->
<!--          </mat-form-field>-->
        </div>

        <div class="example-full-width form-field-spacing-contact">
          <label class="line1-edits-new inputlabel">Phone</label>
          <nz-input-group nzSearch>
            <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="phone2string" nz-input/>
          </nz-input-group>
<!--          <mat-form-field class="selector-full-width" appearance="fill">-->
<!--            <mat-label>Phone</mat-label>-->
<!--            <input matInput [formControl]="directPhone2Control" />-->
<!--          </mat-form-field>-->
        </div>

        <div class="example-full-width form-field-spacing-contact">
          <label class="line1-edits-new inputlabel">City</label>
          <nz-input-group nzSearch>
            <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="citystring" nz-input/>
          </nz-input-group>
<!--          <mat-form-field class="selector-full-width" appearance="fill">-->
<!--            <mat-label>City</mat-label>-->
<!--            <input matInput [formControl]="city2Control" />-->
<!--          </mat-form-field>-->
        </div>

        <div class="example-full-width form-field-spacing-contact">
          <label class="line1-edits-new inputlabel">General Phone</label>
          <nz-input-group nzSearch>
            <input class="line1-edits-new inputedit testdiv" type="tel" [(ngModel)]="generalphonestring" nz-input/>
          </nz-input-group>
<!--          <mat-form-field class="selector-full-width" appearance="fill">-->
<!--            <mat-label>General Phone</mat-label>-->
<!--            <input matInput [formControl]="generalPhoneControl" />-->
<!--          </mat-form-field>-->
        </div>

        <div class="form-field-spacing-contact">
          <label class="line1-edits-new inputlabel">State</label>
          <nz-input-group nzSearch >
            <nz-select nzShowSearch [nzShowArrow]="true" class="line1-edits-new inputedit testdiv"
              [(ngModel)]="statesstring">
              <nz-option *ngFor="let option of (states ?? [])" [nzLabel]="option['state']"
                [nzValue]="option['state']"></nz-option>
            </nz-select>
          </nz-input-group>
<!--          <mat-form-field class="selector-full-width" appearance="fill">-->
<!--            <mat-label>State</mat-label>-->
<!--            <input-->
<!--              type="text"-->
<!--              matInput-->
<!--              placeholder="Select or type..."-->
<!--              [matAutocomplete]="auto28"-->
<!--              [formControl]="state2Control"-->
<!--            />-->
<!--            <mat-autocomplete #auto28="matAutocomplete">-->
<!--              <mat-option-->
<!--                *ngFor="let item of filteredStates | async"-->
<!--                [value]="item.state"-->
<!--              >-->
<!--                {{ item.state }}-->
<!--              </mat-option>-->
<!--            </mat-autocomplete>-->
<!--          </mat-form-field>-->
        </div>

        <div class="example-full-width form-field-spacing-contact">
          <label class="line1-edits-new inputlabel">Mobile1</label>
          <nz-input-group nzSearch>
            <input class="line1-edits-new inputedit testdiv" type="tel" [(ngModel)]="mobile1string" nz-input/>
          </nz-input-group>
<!--          <mat-form-field class="selector-full-width" appearance="fill">-->
<!--            <mat-label>Mobile1</mat-label>-->
<!--            <input matInput [formControl]="mobile1Control" />-->
<!--          </mat-form-field>-->
        </div>

        <div class="example-full-width form-field-spacing-contact">
          <label class="line1-edits-new inputlabel">Zip Code</label>
          <nz-input-group nzSearch>
            <input class="line1-edits-new inputedit testdiv" type="tel" [(ngModel)]="zipcodestring" nz-input/>
          </nz-input-group>
<!--          <mat-form-field class="selector-full-width" appearance="fill">-->
<!--            <mat-label>Zip Code</mat-label>-->
<!--            <input matInput [formControl]="zipcode2Control" />-->
<!--          </mat-form-field>-->
        </div>

        <div class="example-full-width form-field-spacing-contact">
          <label class="line1-edits-new inputlabel">Mobile2</label>
          <nz-input-group nzSearch>
            <input class="line1-edits-new inputedit testdiv" type="tel" [(ngModel)]="mobile2string" nz-input/>
          </nz-input-group>
<!--          <mat-form-field class="selector-full-width" appearance="fill">-->
<!--            <mat-label>Mobile2</mat-label>-->
<!--            <input matInput [formControl]="mobile2Control" />-->
<!--          </mat-form-field>-->
        </div>
      </div>

      <mat-divider class="black-divider-contact"></mat-divider>
      <p class="caption-text">Online & Social</p>
      <div class="full-width-div-company">
        <div class="example-full-width form-field-spacing-contact">
          <label class="line1-edits-new inputlabel">Instagram Username</label>
          <nz-input-group nzSearch>
            <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="instagramstring" nz-input/>
          </nz-input-group>
<!--          <mat-form-field class="selector-full-width" appearance="fill">-->
<!--            <mat-label>Instagram Username</mat-label>-->
<!--            <input matInput [formControl]="InstagraControl" />-->
<!--          </mat-form-field>-->
        </div>

        <div class="example-full-width form-field-spacing-contact">
          <label class="line1-edits-new inputlabel">Facebook Username</label>
          <nz-input-group nzSearch>
            <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="facebookstring" nz-input/>
          </nz-input-group>
<!--          <mat-form-field class="selector-full-width" appearance="fill">-->
<!--            <mat-label>Facebook Username</mat-label>-->
<!--            <input matInput [formControl]="FacebookControl" />-->
<!--          </mat-form-field>-->
        </div>

        <div class="example-full-width form-field-spacing-contact">
          <label class="line1-edits-new inputlabel">TikTok Username</label>
          <nz-input-group nzSearch>
            <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="tiktokstring" nz-input/>
          </nz-input-group>
<!--          <mat-form-field class="selector-full-width" appearance="fill">-->
<!--            <mat-label>TikTok Username</mat-label>-->
<!--            <input matInput [formControl]="TiktokControl" />-->
<!--          </mat-form-field>-->
        </div>

        <div class="example-full-width form-field-spacing-contact">
          <label class="line1-edits-new inputlabel">Youtube Username</label>
          <nz-input-group nzSearch>
            <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="youtubestring" nz-input/>
          </nz-input-group>
<!--          <mat-form-field class="selector-full-width" appearance="fill">-->
<!--            <mat-label>Youtube Username</mat-label>-->
<!--            <input matInput [formControl]="YoutubeControl" />-->
<!--          </mat-form-field>-->
        </div>

        <div class="example-full-width form-field-spacing-contact">
          <label class="line1-edits-new inputlabel">X (Twitter) Username</label>
          <nz-input-group nzSearch>
            <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="twitterstring" nz-input/>
          </nz-input-group>
<!--          <mat-form-field class="selector-full-width" appearance="fill">-->
<!--            <mat-label>X (Twitter) Username</mat-label>-->
<!--            <input matInput [formControl]="TwitterControl" />-->
<!--          </mat-form-field>-->
        </div>

        <div class="example-full-width form-field-spacing-contact">
          <label class="line1-edits-new inputlabel">Linkedin Username</label>
          <nz-input-group nzSearch>
            <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="linkedinstring" nz-input/>
          </nz-input-group>
<!--          <mat-form-field class="selector-full-width" appearance="fill">-->
<!--            <mat-label>Linkedin Username</mat-label>-->
<!--            <input matInput [formControl]="LinkedlnControl" />-->
<!--          </mat-form-field>-->
        </div>

        <div class="example-full-width form-field-spacing-contact">
          <label class="line1-edits-new inputlabel">Other</label>
          <nz-input-group nzSearch>
            <input class="line1-edits-new inputedit testdiv" type="text" [(ngModel)]="othernstring" nz-input/>
          </nz-input-group>
<!--          <mat-form-field class="selector-full-width" appearance="fill">-->
<!--            <mat-label>Other</mat-label>-->
<!--            <input matInput [formControl]="OtherControl" />-->
<!--          </mat-form-field>-->
        </div>
      </div>

      <div class="button-container-company">
        <button
          mat-raised-button
          class="button-settings"
          type="button"
          *ngIf="isVisible"
        >
          Clear All
        </button>
        <!--        <button mat-raised-button class="button-settings" type="submit" *ngIf="isVisible">Create & Add</button>-->
        <button mat-raised-button class="button-settings" type="button" (click)="submit(0)">
          {{ createbuttoncaption }}
        </button>
      </div>
    </div>
  </div>
<!--</form>-->
