import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {NzNotificationService} from "ng-zorro-antd/notification";

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],
})
export class SupportComponent implements OnInit, AfterViewInit {
  caption: String =
    'If you still have questions or prefer to get help directly from an team member, ' +
    'please submit a request. We’ll get back to you as soon as possible.';

  constructor(
    private notification: NzNotificationService,
  ) {

  }
  email: string = "";
  subject: string = "";
  description: string = "";
  statusemail: '' | 'error' | 'warning' = '';
  statussubject: '' | 'error' | 'warning' = '';
  statusdescription: '' | 'error' | 'warning' = '';
  ngAfterViewInit(): void {}

  ngOnInit(): void {}


  onOKClick() {
    this.statusemail = this.email === '' ? 'error' : '';
    this.statussubject = this.subject === '' ? 'error' : '';
    this.statusdescription = this.description === '' ? 'error' : '';
    if (this.email.trim() === "") {
      this.notification.error(
        '<strong class="notification-error">Information</strong>',
        '<strong class="notification-error">Email is required</strong>'
      );
    }
    if (this.subject.trim() === "") {
      this.notification.error(
        '<strong class="notification-error">Information</strong>',
        '<strong class="notification-error">Subject is required</strong>'
      );
    }
    if (this.description.trim() === "") {
      this.notification.error(
        '<strong class="notification-error">Information</strong>',
        '<strong class="notification-error">Description is required</strong>'
      );
    }
  }
}
