import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  NgZone, OnDestroy,
  OnInit,
} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {ModalserviceService} from '../../services/modalservice.service';
import {HashService} from '../../services/hash.service';
import {MessageboxComponent} from '../../modals/messagebox/messagebox.component';
import {ServiceService} from '../../services/service.service';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {YourappsserviceService} from '../../services/yourappsservice.service';
import {GetCelebritiList, GetSMList, GettodList, SelfData} from '../../models/struct';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {ConfirmationService, ConfirmEventType, MessageService} from "primeng/api";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-dragdropdialog',
  templateUrl: './dragdropdialog.component.html',
  styleUrls: ['./dragdropdialog.component.scss'],
})
export class DragdropdialogComponent implements OnInit, AfterViewInit, OnDestroy {
  myForm: FormGroup;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  formCaption: string | undefined = '';
  iTODResult: GettodList[] = [];
  iSM: GetSMList[] = [];

  movies = [
    'Episode I - The Phantom Menace',
    'Episode II - Attack of the Clones',
    'Episode III - Revenge of the Sith',
    'Episode IV - A New Hope',
    'Episode V - The Empire Strikes Back',
    'Episode VI - Return of the Jedi',
    'Episode VII - The Force Awakens',
    'Episode VIII - The Last Jedi',
    'Episode IX – The Rise of Skywalker',
  ];

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.iTODResult[0].data,
      event.previousIndex,
      event.currentIndex
    );
  }

  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private hashService: HashService,
    public messageBox: MessageboxComponent,
    private serviceservice: ServiceService,
    private _snackBar: MatSnackBar,
    private yourappsService: YourappsserviceService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private cdRef: ChangeDetectorRef
  ) {
    this.myForm = new FormGroup({
      value: new FormControl(''), // Initialize your form control
      // other form controls...
    });
    this.formCaption = SelfData.modalformcaption;
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.subscription1) {
      this.subscription1.unsubscribe();
    }
  }

  private subscription?: Subscription;
  private subscription1?: Subscription;

  ngOnInit(): void {
    var accountid: number = 0;
    if (SelfData.account[0].data[0].parentid == 0) {
      accountid = SelfData.account[0].data[0].id;
    } else {
      accountid = SelfData.account[0].data[0].parentid;
    }
    this.subscription1 = this.yourappsService.reloadCelebritiList.subscribe(
      (value) => {
        this.serviceservice
          .selectcelebriti({contactid: SelfData.contactid})
          .subscribe((value) => {
            this.iTODResult = [];
            this.iTODResult[0] = value;
            this.iCelebritiResult[0] = value
            SelfData.celebriti = this.iCelebritiResult;
          });
      })
    this.subscription = this.yourappsService.tod$.subscribe((tod) => {
      this.iTODResult = tod;
      this.iCelebritiResult = tod
    });
    this.yourappsService.sm1$.subscribe((value) => {
      this.iSM = value
    });
    this.yourappsService.adddescription$.subscribe((description) => {
      if (description.trim() != '') {
        if (SelfData.modaltype == -1) {
          this.serviceservice
            .setTODList({accountid: accountid})
            .subscribe((value) => {
              this.iTODResult[0] = value;
              SelfData.tod = this.iTODResult;
              description = '';
              this.yourappsService.getReloadDDArrayList();
            });
        } else if (SelfData.modaltype == -2) {
          this.serviceservice
            .setPEList({accountid: accountid})
            .subscribe((value) => {
              this.iTODResult[0] = value;
              SelfData.pe = this.iTODResult;
              description = '';
              this.yourappsService.getReloadDDArrayList();
            });
        } else if (SelfData.modaltype == -3) {
          this.serviceservice
            .setIssueList({accountid: accountid})
            .subscribe((value) => {
              this.iTODResult[0] = value;
              SelfData.issue = this.iTODResult;
              description = '';
              this.yourappsService.getReloadDDArrayList();
            });
        }
      }
    });
  }

  submit() {
    // Your form submission logic
  }

  onButtonSaveClick() {
    var accountid: number = 0;
    if (SelfData.account[0].data[0].parentid == 0) {
      accountid = SelfData.account[0].data[0].id;
    } else {
      accountid = SelfData.account[0].data[0].parentid;
    }
    if (SelfData.modaltype == -1) {
      this.serviceservice
        .prctod({ctrl: 3, description: '', accountid: accountid, id: 0})
        .subscribe(() => {
          for (let i = 0; i < this.iTODResult[0].data.length; i++) {
            this.serviceservice
              .prctod({
                ctrl: 0,
                description: this.iTODResult[0].data[i].description,
                accountid: accountid,
                id: 0,
              })
              .subscribe(() => {
                // ... handle the response here
                if (this.iTODResult[0].data.length - i == 1) {
                  // this.openSnackBar();
                  this.messageService.add({
                    key: 'tc',
                    severity: 'success',
                    summary: 'Success',
                    detail:
                      'Data was updated successfully',
                    life: 10000,
                  });
                  this.yourappsService.getReloadDDArrayList();
                }
              });
          }
        });
    } else if (SelfData.modaltype == -2) {
      this.serviceservice
        .prcpe({ctrl: 3, description: '', accountid: accountid, id: 0})
        .subscribe(() => {
          for (let i = 0; i < this.iTODResult[0].data.length; i++) {
            this.serviceservice
              .prcpe({
                ctrl: 0,
                description: this.iTODResult[0].data[i].description,
                accountid: accountid,
                id: 0,
              })
              .subscribe(() => {
                // ... handle the response here
                if (this.iTODResult[0].data.length - i == 1) {
                  // this.openSnackBar();
                  this.messageService.add({
                    key: 'tc',
                    severity: 'success',
                    summary: 'Success',
                    detail:
                      'Data was updated successfully',
                    life: 10000,
                  });
                  this.yourappsService.getReloadDDArrayList();
                }
              });
          }
        });
    } else if (SelfData.modaltype == -3) {
      this.serviceservice
        .prcissue({ctrl: 3, description: '', accountid: accountid, id: 0})
        .subscribe(() => {
          for (let i = 0; i < this.iTODResult[0].data.length; i++) {
            this.serviceservice
              .prcissue({
                ctrl: 0,
                description: this.iTODResult[0].data[i].description,
                accountid: accountid,
                id: 0,
              })
              .subscribe(() => {
                // ... handle the response here
                if (this.iTODResult[0].data.length - i == 1) {
                  this.messageService.add({
                    key: 'tc',
                    severity: 'success',
                    summary: 'Success',
                    detail:
                      'Data was updated successfully',
                    life: 10000,
                  });
                  // this.openSnackBar();
                  this.yourappsService.getReloadDDArrayList();
                }
              });
          }
        });
    } else if (SelfData.modaltype == -4) {
      this.serviceservice
        .prccelebriti({
          accountid: accountid,
          ctrl: 3,
          id: SelfData.selectedddid,
          contactid: SelfData.contactid,
          description: "",
          isactivity: false,
          smid: 0
        })
        .subscribe(() => {
          for (let i = 0; i < this.iCelebritiResult[0].data.length; i++) {
            this.serviceservice
              .prccelebriti({
                accountid: accountid, ctrl: 0, id: 0,
                contactid: SelfData.contactid, description: this.iCelebritiResult[0].data[i].description || "",
                isactivity: true, smid: this.iCelebritiResult[0].data[i].smid
              })
              .subscribe(() => {
                // ... handle the response here
                if (this.iCelebritiResult[0].data.length - i == 1) {
                  this.messageService.add({
                    key: 'tc',
                    severity: 'success',
                    summary: 'Success',
                    detail:
                      'Data was updated successfully',
                    life: 10000,
                  });
                  // this.openSnackBar();
                  this.yourappsService.getReloadCelebritiList();
                }
              });
          }
        });
    }
  }

  editItem(item: any): void {
    console.log(item);
    // You can handle the item here
  }

  openSnackBar() {
    // Get the email address from the account data.

    // Open the snack bar.
    this._snackBar.open(`Data was updated successfully`, '', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 3000,
    });
  }

  iSMResult: GetSMList[] = [];
  iCelebritiResult: GetCelebritiList[] = [];

  showAddEditFrame(
    showType: number,
    element: any,
    id: number = 0,
    description: string = ''
  ) {
    SelfData.selectedddid = id;
    id = -1;
    if (showType === 0) {
      this.yourappsService.setFrameCaption('Add New');
      this.yourappsService.setFrameDescription('');
      this.yourappsService.setFrameid(id);
    } else if (showType === 1) {
      this.yourappsService.setFrameCaption('Edit');
      this.yourappsService.setFrameDescription(description);
      this.yourappsService.setFrameid(SelfData.selectedddid);
    }
    if (SelfData.modaltype === -4) {
      if (showType === 0) {
        this.yourappsService.setSM(null)
      } else {
        let celebritiid = this.getIdForDescription(this.iCelebritiResult, description);
        // let celebritiid = this.iCelebritiResult[0].data[0].smid
        this.yourappsService.setSM(this.iSM)
        this.serviceservice
          .selectsm({celebritiid: celebritiid || 0})
          .subscribe((value) => {
            this.iSMResult[0] = value
            SelfData.sm = this.iSMResult;
            this.yourappsService.setSM(this.iSMResult)
            // this.yourappsService.setSM1(this.iSMResult)
          });
      }
      this.modalService.openCF()
    } else {
      this.modalService.createAddEdit();
    }
  }

  getIdForDescription(dataArray: GetCelebritiList[], descriptionToFind: string): number | null {
    for (let dataObj of dataArray) {
      if (dataObj && dataObj.data) {
        let found = dataObj.data.find(item => item.description === descriptionToFind);
        if (found) {
          return found.smid;
        }
      }
    }
    return null;
  }

  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
      ? SelfData.account[0].data[0].id
      : SelfData.account[0].data[0].parentid;
  }

  deleterecord(id: number) {

    SelfData.selectedddid = id;
    id = -1;
    console.log("SelfData.modaltype", SelfData.modaltype)
    this.yourappsService.setOKOption(1);
    this.yourappsService.setFrameid(id);
    if (SelfData.modaltype === -4) {
      this.confirmationService.confirm({
        message: `<strong>Are you sure you want to delete this entry?</strong>`,
        header: 'Information',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.serviceservice
            .prccelebriti({
              accountid: 0,
              ctrl: 2,
              id: SelfData.selectedddid,
              contactid: 0,
              description: "",
              isactivity: false,
              smid: 0
            })
            .subscribe((value) => {
              this.yourappsService.getReloadCelebritiList()
              this.modalService.closeCF()
            });
        },
        reject: (type: any) => {
          if (type == ConfirmEventType.REJECT) {

          } else if (type == ConfirmEventType.CANCEL) {

          }
        }
      });
      setTimeout(() => {
        this.applyLocalStyles();
      }, 0);
    } else
    if (SelfData.modaltype === -3) {
      this.confirmationService.confirm({
        message: `<strong>Are you sure you want to delete this entry?</strong>`,
        header: 'Information',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.serviceservice
            .prcissue({ctrl: 2, description: '', accountid: this.getAccountID(), id: SelfData.selectedddid})
            .subscribe(() => {
              this.serviceservice
                .setIssueList({accountid: this.getAccountID()})
                .subscribe((value) => {
                  this.iTODResult[0] = value;
                  SelfData.issue = this.iTODResult;
                  this.yourappsService.getReloadDDArrayList();
                });
            });
        },
        reject: (type: any) => {
          if (type == ConfirmEventType.REJECT) {

          } else if (type == ConfirmEventType.CANCEL) {

          }
        }
      });
      setTimeout(() => {
        this.applyLocalStyles();
      }, 0);
    } else {
      this.messageBox.modalService.openMessageBox(
        'Information',
        'Are you sure you want to delete this entry?',
        'No',
        'Yes'
      );
    }
  }

  private applyLocalStyles() {
    setTimeout(() => {
      const messageElements = document.querySelectorAll('.p-confirm-dialog-message');
      messageElements.forEach((element) => {
        element.setAttribute('style', 'font-size: 16px; color: red;');
      });
    }, 0);

    const iconElements = document.querySelectorAll('.p-confirm-dialog-icon');
    iconElements.forEach((element) => {
      element.setAttribute('style', 'color: red');
    });
  }

  private applyLocalRestoreStyles() {
    setTimeout(() => {
      const messageElements = document.querySelectorAll('.p-confirm-dialog-message');
      messageElements.forEach((element) => {
        element.setAttribute('style', 'font-size: 16px; color: #343a40;');
      });
    }, 0);

    const iconElements = document.querySelectorAll('.p-confirm-dialog-icon');
    iconElements.forEach((element) => {
      element.setAttribute('style', 'color: #343a40');
    });
  }

  protected readonly SelfData = SelfData;
}
