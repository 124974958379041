<button mat-icon-button type="button" class="close-btn" (click)="onButtonCloseClick()">
  <mat-icon>close</mat-icon>
</button>
<p class="sample-manager-text">Edit ship date</p>

<nz-date-picker class="line1-edits-new inputedit testdiv" [(ngModel)]="date" (ngModelChange)="onChange($event)"></nz-date-picker>


<div class="button-container-label">
  <button mat-raised-button class="button-settings" type="button" (click)="onButtonCloseClick()">
    Cancel
  </button>

  <button mat-raised-button class="button-settings" type="button" (click)="onButtonOKClick()">
    OK
  </button>
</div>
