import {Component, OnDestroy, OnInit} from '@angular/core';
import {DeviceDetectorService} from 'ngx-device-detector';
import {ActivatedRoute, Router} from "@angular/router";
import {ServiceService} from "./services/service.service";
import {
  GetLateList,
  GetLateSendOut,
  GetLBAccepts,
  GetLBDesigners,
  GetLBMainList,
  GetLBSents,
  GetQRResult, GetRequestList,
  SelfData, servermode,
  SetPullSheetList
} from "./models/struct";
import {YourappsserviceService} from "./services/yourappsservice.service";
import {IdleService} from "./services/idle.service";
import {Subscription} from "rxjs";
import {ModalserviceService} from "./services/modalservice.service";
import {LocalStorageService} from "./services/local-storage.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'FASHION MANAGEMENT';
  tempText: string | null = ""
  isParams: boolean = false
  isaccepted: number = 2
  showqrcodescan: number = 2
  showlookbok: number = 2
  showcatalog: number = 2
  showimages: number = 2
  requestlist: number = 2
  showreqimages: number = 2
  latesendout: number = 2
  sendout: number = 2
  private subscription: Subscription | undefined;

  constructor(private deviceService: DeviceDetectorService,
              private serviceservice: ServiceService,
              private yourappsService: YourappsserviceService,
              private router: Router,
              public modalService: ModalserviceService,
              private localStorageService: LocalStorageService,
              private route: ActivatedRoute,
              private idleService: IdleService) {
    // this.epicFunction();
    servermode.server = true // ეს პარამეტრი მოქმედებს თუ საიდან უნდა ჩატვირთოს მონაცემები true ლინუქსი false ლოკალური სერვერი
  }

  isDesktopDevice: boolean = this.deviceService.isDesktop();

  ngOnDestroy(): void {
  }

  checkUrlPath(url: string): string {
    console.log(url)
    const parsedUrl = new URL(url);
    const pathname = parsedUrl.pathname;

    if (pathname.includes('qrcodescan')) {
      return 'qrcodescan';
    } else if (pathname.includes('pullsheet')) {
      return 'pullsheet';
    } else if (pathname.includes('lookbook')) {
      SelfData.tlburl = url
      return 'lookbook';
    } else if (pathname.includes('catalog')) {
      return 'catalog';
    } else if (pathname.includes('imageviews')) {
      return 'imageviews';
    } else if (pathname.includes('requestedimages')) {
      return 'requestedimages';
    } else if (pathname.includes('requestlist')) {
      return 'requestlist';
    } else if (pathname.includes('latesendout')) {
      return 'latesendout';
    } else if (pathname.includes('sendoutreport')) {
      return 'sendoutreport';
    } else {
      return 'unknown';
    }
  }

  iPullSheetList: SetPullSheetList[] = [];
  iQRResult: GetQRResult[] = [];
  iLBSentsResult: GetLBSents[] = [];
  iRequestListResult: GetLBSents[] = [];
  iLateSendoutResult: GetLateSendOut[] = [];
  iLBMainResult: GetLBMainList[] = [];
  iLBDesignersResult: GetLBDesigners[] = [];
  iLBAcceptsResult: GetLBAccepts[] = [];
  iRequestList: GetRequestList[] = [];
  iLateList: GetLateList[] = [];

  ngOnInit(): void {
    // this.subscription = this.idleService.getIdleStatus().subscribe(isIdle => {
    //   if (isIdle) {
    //     // alert('Вы неактивны уже 10 минут!');
    //     window.location.reload();
    //     this.localStorageService.clear()
    //     this.yourappsService.updateProgrammsCount(0);
    //     this.modalService.programmsclose();
    //     this.modalService.rpmonitorinclose();
    //     this.modalService.eventsclose();
    //     this.modalService.samplesclose();
    //     this.modalService.contactsclose();
    //     this.yourappsService.setAuth(false);
    //     SelfData.account = [];
    //     this.yourappsService.setAccountName('');
    //     this.yourappsService.reloadDataRequest;
    //     this.yourappsService.reloadAvatarImage;
    //     // this.LoadAvatarImage();
    //     this.router.navigate(['']);
    //   }
    // });

    this.route.queryParams.subscribe(queryParams => {
        console.log(window.location.href)
        const currentUrl = window.location.href;  // get the current URL
        const urlType = this.checkUrlPath(currentUrl);
        console.log("urlType", urlType)
        if (urlType === 'pullsheet' && queryParams['key']) {
          this.isParams = true;
          this.showqrcodescan = 3
          this.showlookbok = 3
          this.showcatalog = 3
          this.showimages = 3
          this.showreqimages = 3
          this.requestlist = 3
          this.latesendout = 3
          this.sendout = 3
          this.tempText = queryParams['key'];
          const key = queryParams['key'];

          // NOTE: Adjust this service call based on qrcodescan logic if it's different from pullsheet
          this.serviceservice.selectpullsheet({key: key})
            .subscribe((value) => {
              // Your existing logic here
              this.iPullSheetList[0] = value;
              SelfData.pullsheetlist = this.iPullSheetList;
              this.yourappsService.setLogoImage(SelfData.pullsheetlist[0].data[0].imagelink);
              this.yourappsService.setSignatureImage(SelfData.pullsheetlist[0].data[0].signaturelink);
              this.isaccepted = SelfData.pullsheetlist[0].data[0].status;
              // SelfData.addres = this.iAddressResult;
            });
        } else if (urlType === 'qrcodescan') {
          this.isaccepted = 3
          this.showlookbok = 3
          this.showcatalog = 3
          this.showimages = 3
          this.showreqimages = 3
          this.requestlist = 3
          this.latesendout = 3
          this.sendout = 3
          this.isParams = true
          const smid = queryParams['smid'];
          const acid = queryParams['acid'];

          this.serviceservice.getQRResult({accountid: acid, sampleid: smid})
            .subscribe((value) => {
              this.iQRResult[0] = value
              SelfData.qrresult = this.iQRResult;
              if (SelfData.qrresult && SelfData.qrresult[0]?.data && SelfData.qrresult[0].data[0]) {
                this.yourappsService.setLogoImage(SelfData.qrresult[0].data[0].imagelink);
                this.showqrcodescan = 1
              }
            })
        } else if (urlType === 'lookbook' && queryParams['key']) {
          this.isParams = true;
          this.showqrcodescan = 3
          this.isaccepted = 3
          this.showcatalog = 3
          this.showlookbok = 3
          this.showimages = 3
          this.showreqimages = 3
          this.requestlist = 3
          this.latesendout = 3
          this.sendout = 3
          this.tempText = queryParams['key'];
          const key = queryParams['key'];
          SelfData.lbkey = key
          this.serviceservice.selectlbsents({key: key})
            .subscribe((value) => {
              this.iLBSentsResult[0] = value
              SelfData.lbsents = this.iLBSentsResult;
              if (value.data.length > 0) {
                this.serviceservice.selectlbmainlist({id: this.iLBSentsResult[0].data[0].lbmainid})
                  .subscribe((value) => {
                    this.iLBMainResult[0] = value
                    SelfData.lbmainlist = this.iLBMainResult;
                    if (value.data.length > 0) {
                      if (this.iLBMainResult[0].data[0].lbtype === 0) {
                        SelfData.tlbmainid = this.iLBMainResult[0].data[0].id
                        this.serviceservice.selectlbdesigners({lbmainid: this.iLBMainResult[0].data[0].id})
                          .subscribe((value) => {
                            this.iLBDesignersResult[0] = value
                            SelfData.lbdesigners = this.iLBDesignersResult;
                            this.yourappsService.selectLBDesigners(SelfData.lbdesigners, 1)
                            this.serviceservice.selectlbaccepts({lbmainid: this.iLBMainResult[0].data[0].id, key: key})
                              .subscribe((value) => {
                                this.iLBAcceptsResult[0] = value
                                SelfData.lbaccepts = this.iLBAcceptsResult;
                              })
                            this.showlookbok = 1
                          })
                      } else if (this.iLBMainResult[0].data[0].lbtype === 1) {
                        SelfData.tlbmainid = this.iLBMainResult[0].data[0].id
                        this.serviceservice
                          .setLBCImageList({lbmainid: this.iLBMainResult[0].data[0].id})
                          .subscribe({
                            next: (value: any) => {
                              SelfData.tcustomimages = value

                              this.showimages = 1
                            },
                            error: (error) => {
                              console.error('Error occurred during the subscription:', error);
                            },
                          });

                      }
                    }
                  })
              }

            })
        } else if (urlType === 'catalog') {
          this.isParams = true;
          this.showqrcodescan = 3
          this.isaccepted = 3
          this.showlookbok = 3
          this.showimages = 3
          this.showreqimages = 3
          this.requestlist = 3
          this.latesendout = 3
          this.sendout = 3
          this.showcatalog = 1
        } else if (urlType === 'imageviews') {
          this.isParams = true;
          this.showqrcodescan = 3
          this.isaccepted = 3
          this.showlookbok = 3
          this.showcatalog = 3
          this.showreqimages = 3
          this.requestlist = 3
          this.latesendout = 3
          this.sendout = 3
          this.showimages = 1
        } else if (urlType === 'requestedimages') {
          this.isParams = true;
          this.showqrcodescan = 3
          this.isaccepted = 3
          this.showlookbok = 3
          this.showcatalog = 3
          this.showimages = 3
          this.requestlist = 3
          this.latesendout = 3
          this.sendout = 3
          const key = queryParams['key'];
          this.serviceservice.selectlbsents({key: key})
            .subscribe((value) => {
              if (value.data.length > 0) {
                this.iLBSentsResult[0] = value
                SelfData.lbsents = this.iLBSentsResult;
                this.serviceservice.selectlbmainlist({id: this.iLBSentsResult[0].data[0].lbmainid})
                  .subscribe((value) => {
                    SelfData.lbmainlist = this.iLBMainResult;
                    this.iLBMainResult[0] = value
                    this.serviceservice.selectlbaccepts({lbmainid: this.iLBMainResult[0].data[0].id, key: key})
                      .subscribe((value) => {
                        this.iLBAcceptsResult[0] = value
                        SelfData.lbaccepts = this.iLBAcceptsResult;
                        SelfData.tcustomimages = value
                        this.showreqimages = 1
                      })
                  })
              }
            })
        } else if (urlType === 'requestlist') {
          this.isParams = true;
          this.showqrcodescan = 3
          this.isaccepted = 3
          this.showlookbok = 3
          this.showcatalog = 3
          this.showreqimages = 3
          this.showimages = 3
          this.latesendout = 3
          this.sendout = 3
          this.requestlist = 1
          const key = queryParams['key'];
          this.serviceservice.selectrequestlist({key: key})
            .subscribe((value) => {
              this.iRequestListResult[0] = value
              SelfData.printrequestlist = this.iRequestListResult;
              if (value.data.length > 0) {
                console.log("SelfData.printrequestlist", SelfData.printrequestlist[0].data[0].sendoutid)
                SelfData.sendoutopentype = 1
                SelfData.sendoutmode = 1
                SelfData.sendoutstep = 1;
                SelfData.saveonclose = false
                SelfData.sendoutid = SelfData.printrequestlist[0].data[0].sendoutid

                this.serviceservice
                  .selectrequestsamples({
                    requestid: SelfData.sendoutid,
                  })
                  .subscribe((value) => {
                    this.iRequestList[0] = value;
                    SelfData.requestlist = this.iRequestList;
                    console.log("SelfData.requestlist", SelfData.requestlist)
                    this.yourappsService.getReloadRequestList()
                  });
              }
            })
        } else if (urlType === 'latesendout') {
          this.isParams = true;
          this.showqrcodescan = 3
          this.isaccepted = 3
          this.showlookbok = 3
          this.showcatalog = 3
          this.showreqimages = 3
          this.showimages = 3
          this.requestlist = 3
          this.sendout = 3
          this.latesendout = 1
          const key = queryParams['key'];
          this.serviceservice.selectlatesendout({key: key})
            .subscribe((value) => {
              this.iLateSendoutResult[0] = value
              SelfData.printlatesendout = this.iLateSendoutResult;
              if (value.data.length > 0) {
                console.log("SelfData.printrequestlist", SelfData.printlatesendout[0].data[0].sendoutid)
                SelfData.sendoutopentype = 1
                SelfData.sendoutmode = 1
                SelfData.sendoutstep = 1;
                SelfData.saveonclose = true
                SelfData.sendoutid = SelfData.printlatesendout[0].data[0].sendoutid

                this.serviceservice
                  .selectlatelist({
                    sendoutid: SelfData.sendoutid,
                  })
                  .subscribe((value) => {
                    this.iLateList[0] = value;
                    SelfData.latelist = this.iLateList;
                    console.log("SelfData.latelist", SelfData.latelist)
                    this.yourappsService.getReloadRequestList()
                  });
              }
            })
        } else if (urlType === 'sendoutreport') {
          const key = queryParams['key'];

          console.log("Received key:", key); // შეამოწმე key-ს მნიშვნელობა

          if (!key) {
            // console.error("❌ ERROR: key is missing or undefined!");
          } else {
            SelfData.guid = key;
          }

          this.isParams = true;
          this.showqrcodescan = 3;
          this.isaccepted = 3;
          this.showlookbok = 3;
          this.showcatalog = 3;
          this.showreqimages = 3;
          this.showimages = 3;
          this.requestlist = 3;
          this.latesendout = 3;
          this.sendout = 1;
          this.yourappsService.setguid(key, 1)
          console.log("SelfData.guid:", SelfData.guid);
        }
      }
    )
  }
}
