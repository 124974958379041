import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {gu} from "date-fns/locale";

interface CollectionData {
  designer: string;
  collection: string;
  season: string;
}

@Injectable({
  providedIn: 'root',
})
export class YourappsserviceService {
  private programmsCount = new BehaviorSubject<number>(0);
  programmsCount$ = this.programmsCount.asObservable();

  private authSource = new BehaviorSubject<boolean>(false);
  auth$ = this.authSource.asObservable();

  private checkvalidation = new BehaviorSubject<boolean>(false);
  validation$ = this.checkvalidation.asObservable();

  private sampleandcontacts = new BehaviorSubject<[boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean]>([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false
  ]);
  sampleandcontacts$ = this.sampleandcontacts.asObservable();

  private accountname = new BehaviorSubject<string>('');
  accountname$ = this.accountname.asObservable();

  private avatarimage = new BehaviorSubject<string>('');
  avatarimage$ = this.avatarimage.asObservable();

  private setpaginator = new BehaviorSubject<number>(0);
  setpaginator$ = this.setpaginator.asObservable();

  public reloadDataRequest = new Subject<void>();

  public frameCaption = new BehaviorSubject<string>('');
  caption$ = this.frameCaption.asObservable();

  public logoimage = new BehaviorSubject<string>('');
  logoimage$ = this.logoimage.asObservable();

  public signaturemage = new BehaviorSubject<string>('');
  signaturemage$ = this.logoimage.asObservable();

  public frameDescription = new BehaviorSubject<string>('');
  description$ = this.frameDescription.asObservable();

  public frameid = new BehaviorSubject<number>(0);
  id$ = this.frameid.asObservable();

  public opertype = new BehaviorSubject<number>(0);
  opertype$ = this.opertype.asObservable();

  public addframeDescription = new BehaviorSubject<string>('');
  adddescription$ = this.addframeDescription.asObservable();

  public addframeSeasonName = new BehaviorSubject<string>('');
  addSeasonName$ = this.addframeSeasonName.asObservable();

  public setSample = new BehaviorSubject<string>('');
  selectedSample$ = this.setSample.asObservable();

  public footertext = new BehaviorSubject<string>('');
  getFooterText$ = this.footertext.asObservable();

  public setfootertext = new BehaviorSubject<string>('');
  setFooterText$ = this.setfootertext.asObservable();

  public remSample = new BehaviorSubject<string>('');
  removedSample$ = this.remSample.asObservable();

  public signatureImage = new BehaviorSubject<string>('');
  signatureImage$ = this.signatureImage.asObservable();

  public selectedValue = new BehaviorSubject<string>('');
  selectedValue$ = this.selectedValue.asObservable();

  public selectdesigner = new BehaviorSubject<[any, any]>([null, null]);
  seldesigner = this.selectdesigner.asObservable();

  public selectsendout = new BehaviorSubject<any>(null);
  selsendout = this.selectsendout.asObservable();

  public addSampleID = new BehaviorSubject<number>(0);
  addSampleID$ = this.addSampleID.asObservable();

  public getReloadCollections = new BehaviorSubject<CollectionData>({
    designer: '',
    collection: '',
    season: '',
  });
  reloadcollections$ = this.getReloadCollections.asObservable();

  public selectedField = new BehaviorSubject<any>(null);
  currentData = this.selectedField.asObservable();

  public selectedSampledField = new BehaviorSubject<any>(null);
  currentSampleData = this.selectedSampledField.asObservable();

  public selectedSendOutField = new BehaviorSubject<any>(null);
  currentSendOutData = this.selectedSendOutField.asObservable();

  public okOption = new BehaviorSubject<number>(0);
  okOption$ = this.okOption.asObservable();

  private todSource = new BehaviorSubject<any[]>([]);
  private smSource = new BehaviorSubject<any[]>([]);
  private lcitem = new BehaviorSubject<{ data: any[], type: number }>({data: [], type: 0});
  private imagelist = new BehaviorSubject<{ data: any[], type: number }>({data: [], type: 0});
  private sm1Source = new BehaviorSubject<any[]>([]);
  private setSelSamples = new BehaviorSubject<any[]>([]);
  private selectedID = new BehaviorSubject<number>(-1);
  private addressSource = new BehaviorSubject<any[]>([]);
  private companySource = new BehaviorSubject<number>(0);
  private contactSource = new BehaviorSubject<any[]>([]);
  private getlookdata = new BehaviorSubject<any[]>([]);
  public tod$ = this.todSource.asObservable();
  public sm$ = this.smSource.asObservable();
  public sm1$ = this.sm1Source.asObservable();
  public lcitem$ = this.lcitem.asObservable();
  public imagelist$ = this.imagelist.asObservable();
  public setSelSamples$ = this.setSelSamples.asObservable();
  public selectedid$ = this.selectedID.asObservable();
  public address$ = this.addressSource.asObservable();
  public company$ = this.companySource.asObservable();
  public contact$ = this.contactSource.asObservable();
  public getlookdata$ = this.getlookdata.asObservable();
  private setSelImages = new BehaviorSubject<{ data: any[], type: number }>({data: [], type: 0});
  public setSelImages$ = this.setSelImages.asObservable();

  private printBarcode = new BehaviorSubject<{ data: any[], type: number }>({data: [], type: 0});
  public setPrintBarcode$ = this.printBarcode.asObservable();


  private setRetImages = new BehaviorSubject<{ data: any[], type: number }>({data: [], type: 0});
  public setRetImages$ = this.setRetImages.asObservable();

  private setLookBook = new BehaviorSubject<{ data: any[], type: number }>({data: [], type: 0});
  public setLookBook$ = this.setLookBook.asObservable();

  private setColectionList = new BehaviorSubject<{ data: any[], type: number }>({data: [], type: 0});
  public setColectionList$ = this.setColectionList.asObservable();

  private setaddreslabel = new BehaviorSubject<{ data: any[], type: number }>({data: [], type: 0});
  public setaddreslabel$ = this.setaddreslabel.asObservable();

  private retaddreslabel = new BehaviorSubject<{ data: any[], type: number }>({data: [], type: 0});
  public retaddreslabel$ = this.retaddreslabel.asObservable();

  private retColectionList = new BehaviorSubject<{ data: any[], type: number }>({data: [], type: 0});
  public retColectionList$ = this.retColectionList.asObservable();

  private retSelectedFiles = new BehaviorSubject<{ data: any[], type: number }>({data: [], type: 0});
  public retSelectedFiles$ = this.retSelectedFiles.asObservable();

  private validSelectedFiles = new BehaviorSubject<{ data: any[], type: number }>({data: [], type: 0});
  public validSelectedFiles$ = this.validSelectedFiles.asObservable();

  private logoutservice = new BehaviorSubject<{ data: any[], type: number }>({data: [], type: 0});
  public logoutservice$ = this.logoutservice.asObservable();

  private reloadSendoutList = new BehaviorSubject<{ data: any[], type: number }>({data: [], type: 0});
  public reloadSendoutList$ = this.reloadSendoutList.asObservable();

  private reloadOutLetList = new BehaviorSubject<{ data: any[], type: number }>({data: [], type: 0});
  public reloadOutLetList$ = this.reloadOutLetList.asObservable();

  private selectedOutLetList = new BehaviorSubject<{ data: any[], type: number }>({data: [], type: 0});
  public selectedOutLetList$ = this.selectedOutLetList.asObservable();

  private reloadCustomList = new BehaviorSubject<{ data: any[], type: number }>({data: [], type: 0});
  public reloadCustomList$ = this.reloadCustomList.asObservable();

  private selectedCustomList = new BehaviorSubject<{ data: any[], type: number }>({data: [], type: 0});
  public selectedCustomList$ = this.selectedCustomList.asObservable();

  private reloadOrderOutList = new BehaviorSubject<{ data: any[], type: number }>({data: [], type: 0});
  public reloadOrderOutList$ = this.reloadOrderOutList.asObservable();

  private selectedOrderOutList = new BehaviorSubject<{ data: any[], type: number }>({data: [], type: 0});
  public selectedOrderOutList$ = this.selectedOrderOutList.asObservable();

  private reloadOrderInList = new BehaviorSubject<{ data: any[], type: number }>({data: [], type: 0});
  public reloadOrderInList$ = this.reloadOrderInList.asObservable();

  private selectedProfileList = new BehaviorSubject<{ data: any[], type: number }>({data: [], type: 0});
  public selectedProfileList$ = this.selectedProfileList.asObservable();

  private reloadProfileList = new BehaviorSubject<{ data: any[], type: number }>({data: [], type: 0});
  public reloadProfileList$ = this.reloadProfileList.asObservable();

  private payment = new BehaviorSubject<{ paymenttype: number, paymentvalue: number, changevalue: number, type: number }>
  ({paymenttype: 0, paymentvalue: 0, changevalue: 0, type: 0});
  payment$ = this.payment.asObservable();

  private selectedOrderInList = new BehaviorSubject<{ data: any[], type: number }>({data: [], type: 0});
  public selectedOrderInList$ = this.selectedOrderInList.asObservable();

  private reloadSupplierList = new BehaviorSubject<{ data: any[], type: number }>({data: [], type: 0});
  public reloadSupplierList$ = this.reloadSupplierList.asObservable();

  private reloadSaleList = new BehaviorSubject<{ data: any[], type: number }>({data: [], type: 0});
  public reloadSaleList$ = this.reloadSaleList.asObservable();

  private selectedSupplierList = new BehaviorSubject<{ data: any[], type: number }>({data: [], type: 0});
  public selectedSupplierList$ = this.selectedSupplierList.asObservable();

  private reloadProductsList = new BehaviorSubject<{ data: any[], type: number }>({data: [], type: 0});
  public reloadProductsList$ = this.reloadProductsList.asObservable();

  private selectedProductsList = new BehaviorSubject<{ data: any[], type: number }>({data: [], type: 0});
  public selectedProductsList$ = this.selectedProductsList.asObservable();

  private collectionsbyid = new BehaviorSubject<{ data: any[], type: number }>({data: [], type: 0});
  public collectionsbyid$ = this.collectionsbyid.asObservable();

  private editemail = new BehaviorSubject<{ value: string, type: number }>({value: "", type: 0});
  public editemail$ = this.editemail.asObservable();

  private selectedemails = new BehaviorSubject<{ data: any[], type: number }>({data: [], type: 0});
  public selectedemails$ = this.selectedemails.asObservable();

  private setLBDesigners = new BehaviorSubject<{ data: any[], type: number }>({data: [], type: 0});
  public setLBDesigners$ = this.setLBDesigners.asObservable();

  private selectguid = new BehaviorSubject<{ guid: string, type: number }>({guid: "", type: 0});
  public selectguid$ = this.selectguid.asObservable();

  private setContact = new BehaviorSubject<{ data: any[], type: number }>({data: [], type: 0});
  public setContact$ = this.setContact.asObservable();

  private setAcceptedItem = new BehaviorSubject<{ data: any[], type: number }>({data: [], type: 0});
  public setAcceptedItem$ = this.setAcceptedItem.asObservable();

  private setAIDescription = new BehaviorSubject<{ data: any[], description: any[], type: number }>({data: [], description: [], type: 0});
  public setAIDescription$ = this.setAIDescription.asObservable();

  private setUpdateValue = new BehaviorSubject<{ data: string, type: number }>({data: "", type: 0});
  public setUpdateValue$ = this.setUpdateValue.asObservable();

  private mailDetailsView = new BehaviorSubject<{ data: any[], type: number }>({data: [], type: 0});
  public mailDetailsView$ = this.mailDetailsView.asObservable();

  private shipdate = new BehaviorSubject<{ setdate: Date; type: number }>({setdate: new Date(), type: 0});
  public shipdate$ = this.shipdate.asObservable();


  private setInvoice = new BehaviorSubject<{ type: number }>({type: 0});
  public setInvoice$ = this.setInvoice.asObservable();


  public reloadAvatarImage = new Subject<void>();
  public reloadArrayList = new Subject<void>();
  public reloadDDArrayList = new Subject<void>();
  public reloadSWSList = new Subject<void>();
  public reloadCelebritiList = new Subject<void>();
  public reloadFieldList = new Subject<void>();
  public reloadSampleList = new Subject<void>();
  public reloadCurSampleList = new Subject<void>();
  public reloadSampleTableList = new Subject<void>();
  public reloadLookNames = new Subject<void>();
  public reloadLooks = new Subject<void>();
  public reloadSendOutReturnList = new BehaviorSubject<string>('');
  public invoiceCreateSend = new Subject<void>();
  public reloadlblist = new Subject<void>();
  public reloadclblist = new Subject<void>();
  public reloadValidedList = new Subject<void>();
  public reloadsendbooklist = new Subject<void>();
  public reloadRequestList = new Subject<void>();
  public favoriteadded = new Subject<void>();
  public reloadSendOut = new Subject<void>();
  setTod(tod: any[]) {
    this.todSource.next(tod);
  }

  setSM(sm: any[] | null) {
    this.smSource.next(sm || []);
  }

  setLCItem(data: any[] | null, type: number) {
    this.lcitem.next({data: data || [], type: type});
  }

  setImageList(data: any[] | null, type: number) {
    this.imagelist.next({data: data || [], type: type});
  }

  setSM1(sm: any[] | null) {
    this.sm1Source.next(sm || []);
  }

  setSelectedSamples(value: any[]) {
    this.setSelSamples.next(value)
  }

  setSelectedImages(data: any[] | null, type: number) {
    this.setSelImages.next({data: data || [], type: type})
  }

  getPrintBarcode(data: any[] | null, type: number) {
    this.printBarcode.next({data: data || [], type: type})
  }

  setReturnedImages(data: any[] | null, type: number) {
    this.setRetImages.next({data: data || [], type: type})
  }

  selectedLookBook(data: any[] | null, type: number) {
    this.setLookBook.next({data: data || [], type: type})
  }

  selectCollectionList(data: any[] | null, type: number) {
    this.setColectionList.next({data: data || [], type: type})
  }

  selectedAddressLabel(data: any[] | null, type: number) {
    this.setaddreslabel.next({data: data || [], type: type})
  }

  returnAddressLabel(data: any[] | null, type: number) {
    this.retaddreslabel.next({data: data || [], type: type})
  }

  returnCollectionList(data: any[] | null, type: number) {
    this.retColectionList.next({data: data || [], type: type})
  }

  returnSelectedFiles(data: any[] | null, type: number) {
    this.retSelectedFiles.next({data: data || [], type: type})
  }

  setValudSelectedFiles(data: any[] | null, type: number) {
    this.validSelectedFiles.next({data: data || [], type: type})
  }

  setLogOut(data: any[] | null, type: number) {
    this.logoutservice.next({data: data || [], type: type})
  }

  selectLBDesigners(data: any[] | null, type: number) {
    this.setLBDesigners.next({data: data || [], type: type})
  }

  setguid(guid: string | "", type: number) {
    this.selectguid.next({guid: guid || "", type: type})
  }


  selectContact(data: any[] | null, type: number) {
    this.setContact.next({data: data || [], type: type})
  }

  selectInvoice(type: number) {
    this.setInvoice.next({type: type})
  }

  selectAcceptedItems(data: any[] | null, type: number) {
    this.setAcceptedItem.next({data: data || [], type: type})
  }

  returnUpdateValue(data: string | "", type: number) {
    this.setUpdateValue.next({data: data || "", type: type})
  }

  setMailDetailsView(data: any[] | null, type: number) {
    this.mailDetailsView.next({data: data || [], type: type})
  }

  setShipDate(setdate: Date, type: number) {
    this.shipdate.next({setdate: setdate , type: type})
  }

  returnAIDescription(data: any[] | null, description: any[] | null, type: number) {
    this.setAIDescription.next({data: data || [], description: description || [], type: type})
  }

  setSelectedID(value: number) {
    this.selectedID.next(value)
  }

  setLokkData(looknames: any[], looks: any[]) {
    this.getlookdata.next([looknames, looks]);
  }

  setAddress(address: any[]) {
    this.addressSource.next(address);
  }

  setCompany(company: number) {
    this.companySource.next(company);
  }

  setContract(contact: any[]) {
    this.contactSource.next(contact);
  }

  updateProgrammsCount(newCount: number): void {
    this.programmsCount.next(newCount);
  }

  setAuth(auth: boolean): void {
    this.authSource.next(auth);
  }

  setValidation(validation: boolean): void {
    this.checkvalidation.next(validation);
  }

  setSampleAndContavcts(
    value: boolean,
    value1: boolean,
    value2: boolean,
    value3: boolean,
    value4: boolean,
    value5: boolean,
    value6: boolean,
    value7: boolean,
    value8: boolean,
  ): void {
    this.sampleandcontacts.next([value, value1, value2, value3, value4, value5, value6, value7, value8]);
  }

  setAccountName(account: string): void {
    this.accountname.next(account);
  }

  getPaginator(page: number): void {
    this.setpaginator.next(page)
  }

  setFrameCaption(caption: string): void {
    this.frameCaption.next(caption);
  }

  setLogoImage(image: string): void {
    this.logoimage.next(image)
  }

  setFrameDescription(description: string): void {
    this.frameDescription.next(description);
  }

  setFrameid(id: number): void {
    this.frameid.next(id);
  }

  setOperType(id: number = 0): void {
    this.opertype.next(id);
  }

  setAddFrameDescription(description: string): void {
    this.addframeDescription.next(description);
  }

  setAddFrameSeason(description: string): void {
    this.addframeSeasonName.next(description);
  }

  setSelectedSample(Barcode: string) {
    this.setSample.next(Barcode);
  }

  getFooterText(value: string) {
    this.footertext.next(value);
  }

  setFooterText(value: string) {
    this.setfootertext.next(value);
  }

  removedSample(Barcode: string) {
    this.remSample.next(Barcode);
  }

  setSignatureImage(image: string) {
    this.signatureImage.next(image)
  }

  setOKOption(option: number): void {
    this.okOption.next(option);
  }

  setNewID(id: number): void {
    this.addSampleID.next(id);
  }

  setAvatarImage(imagelink: string): void {
    this.avatarimage.next(imagelink);
  }

  setSelectedValue(value: string): void {
    this.selectedValue.next(value);
  }

  setSelectDesigner(value: any, value1: any): void {
    this.selectdesigner.next([value, value1])
  }

  setSelectedSendOut(value: any): void {
    this.selectsendout.next(value)
  }

  setReloadCollection(designer: string, collection: string, season: string) {
    this.getReloadCollections.next({designer, collection, season});
  }

  getReloadArrayList() {
    this.reloadArrayList.next();
  }

  getReloadDDArrayList() {
    this.reloadDDArrayList.next();
  }

  getReloadSWSList() {
    this.reloadSWSList.next();
  }

  getReloadCelebritiList() {
    this.reloadCelebritiList.next();
  }

  getReloadFieldList() {
    this.reloadFieldList.next();
  }

  getReloadSampleList() {
    this.reloadSampleList.next();
  }

  getReloadCurSampleList() {
    this.reloadCurSampleList.next();
  }

  getReloadSampleTableList() {
    this.reloadSampleTableList.next();
  }

  getReloadLookNames() {
    this.reloadLookNames.next();
  }

  getReloadLooks() {
    this.reloadLooks.next();
  }

  getInvoiceCreateSend() {
    this.invoiceCreateSend.next()
  }

  getReloadLBlist() {
    this.reloadlblist.next()
  }

  getReloadCLBlist() {
    this.reloadclblist.next()
  }

  getReloadSLBlist() {
    this.reloadsendbooklist.next()
  }

  getReloadValidedList() {
    this.reloadValidedList.next()
  }

  getReloadSendOutList(data: any[] | null, type: number) {
    this.reloadSendoutList.next({data: data || [], type: type})
  }

  getReloadOutLetList(data: any[] | null, type: number) {
    this.reloadOutLetList.next({data: data || [], type: type})
  }

  setSelectedOutLetList(data: any[] | null, type: number) {
    this.selectedOutLetList.next({data: data || [], type: type})
  }

  getReloadCustomList(data: any[] | null, type: number) {
    this.reloadCustomList.next({data: data || [], type: type})
  }

  setSelectedCustomList(data: any[] | null, type: number) {
    this.selectedCustomList.next({data: data || [], type: type})
  }

  getReloadOrderOutList(data: any[] | null, type: number) {
    this.reloadOrderOutList.next({data: data || [], type: type})
  }

  setSelectedOrderOutList(data: any[] | null, type: number) {
    this.selectedOrderOutList.next({data: data || [], type: type})
  }

  getReloadOrderInList(data: any[] | null, type: number) {
    this.reloadOrderInList.next({data: data || [], type: type})
  }

  setSelectedProfileList(data: any[] | null, type: number) {
    this.selectedProfileList.next({data: data || [], type: type})
  }

  getReloadProfileList(data: any[] | null, type: number) {
    this.reloadProfileList.next({data: data || [], type: type})
  }

  setPayment(paymenttype: number, paymentvalye: number, changevalue: number, type: number) {
    this.payment.next({paymenttype: paymenttype, paymentvalue: paymentvalye, changevalue: changevalue, type: type})
  }

  setSelectedOrderInList(data: any[] | null, type: number) {
    this.selectedOrderInList.next({data: data || [], type: type})
  }

  getReloadSupplierList(data: any[] | null, type: number) {
    this.reloadSupplierList.next({data: data || [], type: type})
  }

  getReloadSaleList(data: any[] | null, type: number) {
    this.reloadSaleList.next({data: data || [], type: type})
  }

  setSelectedSupplierList(data: any[] | null, type: number) {
    this.selectedSupplierList.next({data: data || [], type: type})
  }

  getReloadProductsList(data: any[] | null, type: number) {
    this.reloadProductsList.next({data: data || [], type: type})
  }

  setSelectedProductsList(data: any[] | null, type: number) {
    this.selectedProductsList.next({data: data || [], type: type})
  }

  getCollectionsByID(data: any[] | null, type: number) {
    this.collectionsbyid.next({data: data || [], type: type})
  }

  setSelectedEmail(data: any[] | null, type: number) {
    this.selectedemails.next({data: data || [], type: type})
  }

  setEmail(value: string | "", type: number) {
    this.editemail.next({value: value || "", type: type})
  }

  getFavoritesAdd() {
    this.favoriteadded.next()
  }

  getReloadRequestList() {
    this.reloadRequestList.next()
  }

  getReloadSendOutReturnList(comment: string) {
    this.reloadSendOutReturnList.next(comment)
  }

  changeData(data: any) {
    this.selectedField.next(data);
  }

  selectSampleData(data: any) {
    this.selectedSampledField.next(data);
  }

  selectedSendOut(data: any) {
    this.selectedSendOutField.next(data);
  }
}
