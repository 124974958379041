import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl} from "@angular/forms";
import {ModalserviceService} from "../../services/modalservice.service";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MessageService} from "primeng/api";
import {GetSendOutList, SelfData, SetSampleListData} from "../../models/struct";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-selectsendoutdialog',
  templateUrl: './selectsendoutdialog.component.html',
  styleUrls: ['./selectsendoutdialog.component.scss']
})
export class SelectsendoutdialogComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
      public modalService: ModalserviceService,
      public fb: FormBuilder,
      private serviceservice: ServiceService,
      private yourappsService: YourappsserviceService,
      private messageService: MessageService
  ) {

  }

  onButtonAddClick() {

  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {

  }

  ngOnInit(): void {
  }

  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
        ? SelfData.account[0].data[0].id
        : SelfData.account[0].data[0].parentid;
  }

  iSendOutList: GetSendOutList[] = [];
  onButtonSendOutSearchClick() {
    SelfData.selectsendoutmode = 1
    this.modalService.sendoutlistclose();
    this.serviceservice
        .getSendOutlist({accountid: this.getAccountID()})
        .subscribe((value) => {
          this.modalService.sendoutlistopen();
          this.iSendOutList[0] = value;
          SelfData.sendoutlist = this.iSendOutList;
        });

  }

}
