<div class="form-container">
  <div class="left-image-container1">
    <app-registrationframe></app-registrationframe>
  </div>
  <div class="left-image-container">
    <img
      *ngIf="imageUrl === ''"
      class="account-image"
      src="assets/account_circle.svg"
      alt="Voz"
    />
    <img
      *ngIf="imageUrl !== ''"
      class="account-image"
      [src]="imageUrl"
      alt="Avatar"
    />
    <p class="image-caption">{{ accountname }}</p>
<!--    <div class="mycode">-->
<!--      <p class="mycode-caption">{{ mycode }}-->
<!--        <button mat-icon-button class="copybutton" (click)="onButtonCopyClick()">-->
<!--          <mat-icon>file_copy</mat-icon>-->
<!--        </button>-->
<!--      </p>-->
<!--    </div>-->
    <div class="button-container">
      <button mat-stroked-button class="edit-profile-btn" (click)="onButtonCopyClick()">
        My Referral: <span class="mycode-style">{{mycode}}</span>
      </button>
    </div>
    <div class="button-container">
      <button mat-stroked-button class="edit-profile-btn" (click)="modalService.profileeditopen()">
        Edit Profile
      </button>
    </div>
    <div class="button-container">
      <button mat-stroked-button class="edit-profile-btn">Add Users</button>
    </div>
    <div class="button-container">
      <button mat-stroked-button class="edit-profile-btn">Payment Info</button>
    </div>
    <div class="button-container1">
      <button mat-stroked-button class="edit-profile-btn" (click)="onCancelAccountClick()">
        Cancel Account
      </button>
    </div>
  </div>
  <button mat-icon-button class="close-btn" (click)="modalService.profileclose()">
    <mat-icon>close</mat-icon>
  </button>
</div>
