import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder} from "@angular/forms";
import {HashService} from "../../services/hash.service";
import {MessageboxComponent} from "../../modals/messagebox/messagebox.component";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {HttpClient} from "@angular/common/http";
import * as moment from "moment-timezone";
import {
  CustomerDetails,
  CustomerResponse,
  OutletSettingsResponse,
  SelfData,
  setcustomergroups,
  setgridfields, TagsListData
} from "../../models/struct";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {forkJoin, Subscription} from "rxjs";
import {th} from "date-fns/locale";
import {Table} from "primeng/table";

interface TGroupe {
  value: string;
}

interface Gender {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-customersframedialog',
  templateUrl: './customersframedialog.component.html',
  styleUrl: './customersframedialog.component.scss'
})
export class CustomersframedialogComponent implements OnInit, AfterViewInit, OnDestroy {
  firstname: string = "";
  lastname: string = "";
  email: string = "";
  phone: string = "";
  tgroupe: TGroupe[] = [];
  groupe: string = "All Customers";

  gender: Gender[] = [
    {value: 'Man', viewValue: 'Man'},
    {value: 'Women', viewValue: 'Women'},
    {value: 'Non-binary', viewValue: 'Non-binary'},
  ];

  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
      ? SelfData.account[0].data[0].id
      : SelfData.account[0].data[0].parentid;
  }

  iGroupsList: setcustomergroups[] = [];
  checkemail: boolean = false;
  address: string = "";
  address2: string = "";
  suburb: string = "";
  city: string = "";
  zipcode: string = "";
  statesstring: string = "";
  countries: any[] = [];
  states: any[] = [];
  pcountries: any[] = [];
  pstates: any[] = [];
  countrystring: string = "";
  pcountrystring: string = "";
  usedifferent: boolean = false;
  pzipcode: string = "";
  pstatesstring: string = "";
  pcity: string = "";
  psuburb: string = "";
  paddress2: string = "";
  paddress: string = "";
  customercode: string = "";
  companyname: string = "";
  // bdate: Date | string | null = null;
  bdate: Date | Date[] | null = null;
  genderstring: string = "";
  website: string = "";
  instagram: string = "";
  facebook: string = "";
  tiktok: string = "";
  twitter: string = "";
  youtube: string = "";
  descriptionstring: string = "";
  radioValue: string = "0";
  limit: number = 0;
  discount: number = 0;
  setid: number = 0;
  opermode: number = 0;
  caption: string = "Add Customer"
  buttoncaption: string = "Add Customer"
  private subscription?: Subscription;
  iProductsResult: TagsListData[] = []
  sizes!: any[];
  @ViewChild(Table) dataTable?: Table;
  selectedSize: any = ''

  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private hashService: HashService,
    public messageBox: MessageboxComponent,
    private serviceservice: ServiceService,
    private yourappsService: YourappsserviceService,
    private _snackBar: MatSnackBar,
    private notification: NzNotificationService,
    private http: HttpClient,
    private cdr: ChangeDetectorRef
  ) {
    this.sizes = [
      {name: 'Small', class: 'p-datatable-sm'}
    ];
    this.selectedSize = this.sizes[0]
    this.tgroupe.push({ value: "All Customers" });
    this.serviceservice
      .getCustomerGroupes({ accountid: this.getAccountID() })
      .subscribe((value) => {
        this.iGroupsList[0] = value;
        SelfData.customergroups = this.iGroupsList;

        if (
          SelfData.customergroups &&
          SelfData.customergroups.length > 0 &&
          SelfData.customergroups[0].data &&
          Array.isArray(SelfData.customergroups[0].data)
        ) {
          // Проходим по массиву data и добавляем значение description:
          SelfData.customergroups[0].data.forEach((group: any) => {
            this.tgroupe.push({ value: group.description });
          });
        }

        // Вы можете вывести результат для проверки:
        console.log("this.tgroupe", this.tgroupe);
      });
    const timezoneNames = moment.tz.names();

    this.serviceservice
      .getTaxes({
        accountid: this.getAccountID(),
      })
      .subscribe((response) => {
        if (response.Code === 200) {
          SelfData.taxesData = response.data
          // const fieldData = response.data;
          //
          // fieldData.forEach((field: any) => {
          //   const propertyName = field.componentname as keyof typeof this; // Явное указание типа ключа
          //   if (propertyName in this) {
          //     (this[propertyName] as boolean) = field.isshow; // Приведение типа
          //   }
          // });
          console.log("Обновленные свойства:", this);
        } else {
          console.error("Ошибка: ", response.ErrorMessage);
        }
      });

    this.subscription = this.yourappsService.selectedCustomList$.subscribe((value) => {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      console.log("SelfData.formstyle", SelfData.formstyle)
      if (value?.type === 1) {
        if (SelfData.formstyle === 1) {
          this.buttoncaption = "Edit Customer";
          this.caption = "Edit Customer";
          this.opermode = 1;
          this.viewmode = false
        }
        if (SelfData.formstyle === 2) {
          this.buttoncaption = "View Customer";
          this.caption = "View Customer";
          this.opermode = 1;
          this.viewmode = true
        }

        let data: any = value.data;

        // Проверяем, является ли value.data массивом, и берем первый элемент, если так
        if (Array.isArray(data) && data.length > 0) {
          data = data[0];
        }

        // Проверяем, является ли data объектом перед заполнением переменных
        if (data && typeof data === 'object') {
          this.setid = data.ID ?? 0;
          this.firstname = data.FirstName ?? "";
          this.lastname = data.LastName ?? "";
          this.email = data.Email ?? "";
          this.phone = data.Phone ?? "";
          this.customercode = data.CustomerCode ?? "";
          this.companyname = data.CompanyName ?? "";
          this.bdate = data.DateOfBirth ? new Date(data.DateOfBirth) : null;
          this.genderstring = data.Gender ?? "";
          this.website = data.Website ?? "";
          this.instagram = data.Instagram ?? "";
          this.facebook = data.Facebook ?? "";
          this.tiktok = data.TikTok ?? "";
          this.twitter = data.Twitter ?? "";
          this.youtube = data.YouTube ?? "";
          this.descriptionstring = data.Notes ?? "";

          // Адреса
          this.address = data.MailingStreetAddress ?? "";
          this.address2 = data.MailingStreetAddress2 ?? "";
          this.suburb = data.MailingSuburb ?? "";
          this.city = data.MailingCity ?? "";
          this.zipcode = data.MailingZipCode ?? "";
          this.statesstring = data.MailingState ?? "";
          this.countrystring = data.MailingCountry ?? "";

          // Почтовый адрес
          this.paddress = data.PostalStreetAddress ?? "";
          this.paddress2 = data.PostalStreetAddress2 ?? "";
          this.psuburb = data.PostalSuburb ?? "";
          this.pcity = data.PostalCity ?? "";
          this.pzipcode = data.PostalZipCode ?? "";
          this.pstatesstring = data.PostalState ?? "";
          this.pcountrystring = data.PostalCountry ?? "";

          this.usedifferent = data.Usedifferent ?? false;
          this.limit = data.BalanceLimit ?? 0;
          this.radioValue = data.AllowOnAccountBalance ? data.AllowOnAccountBalance.toString() : "0";
          this.taxrate = data.TaxRate ?? "No Tax (0%)";
          this.loyaltyEnabled = data.LoyaltyEnabled ?? false;
          this.wholesaleEnabled = data.Wholesale ?? false;

          // Кастомные поля
          this.customfield1 = data.CustomField1 ?? "";
          this.customfield2 = data.CustomField2 ?? "";
          this.customfield3 = data.CustomField3 ?? "";
          this.customfield4 = data.CustomField4 ?? "";
          this.discount = data.Discount ?? 0;

          setTimeout(() => {
            SelfData.spinnerpossition = true
          });
          forkJoin({
            products: this.serviceservice.selectCustomerHistory(this.setid),
          }).subscribe(({ products }) => {
            this.iProductsResult = products.data;
            console.log("this.iProductsResult", this.iProductsResult)
            setTimeout(() => {
              SelfData.spinnerpossition = false
            });
          });
        }

        console.log("✅ Объект:", data);
      }
    });

    // this.timezone = timezoneNames.map((tz) => {
    //   const offset = moment.tz(tz).utcOffset(); // Смещение в минутах
    //   const hours = Math.floor(Math.abs(offset) / 60).toString().padStart(2, '0');
    //   const minutes = Math.abs(offset) % 60 === 0 ? '00' : (Math.abs(offset) % 60).toString().padStart(2, '0');
    //   const formattedOffset = `${offset >= 0 ? '+' : '-'}${hours}:${minutes}`;
    //   return { viewvalue: `(GMT${formattedOffset}) ${tz}`, value: formattedOffset };
    // });
    // console.log(this.timezone)
    // setTimeout(() => {
    //   this.cdr.detectChanges();  // Обновление представления после полной отрисовки
    // });
  }

  onOptionSelected(event: string): void {
    const countryId = this.getCountryIdByName(event);
    this.states = [];
    this.statesstring = "";
    if (countryId == 187) {
      this.http.get<any>('assets/us-states.json').subscribe((data) => {
        this.states = data;
      });
    }
  }

  getCountryIdByName(countryName: string): number | null {
    const country = this.countries.find((c) => c.country === countryName);
    return country ? country.id : null;
  }

  onOptionSelectedp(event: string) {
    const countryId = this.getCountryIdByName(event);
    this.pstates = [];
    this.statesstring = "";
    if (countryId == 187) {
      this.http.get<any>('assets/us-states.json').subscribe((data) => {
        this.pstates = data;
      });
    }
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    this.http.get<any>('assets/countries.json').subscribe((data) => {
      this.countries = data.countries;
      if (this.opermode === 0) {
        this.countrystring = "United States"
      }
      this.pcountries = data.countries;
      if (this.opermode === 0) {
        this.pcountrystring = "United States"
      }
      // this.countriControl.setValue('United States');
      // this.countri1Control.setValue('United States');
      // this.countri2Control.setValue('United States');
      this.http.get<any>('assets/us-states.json').subscribe((data) => {
        this.states = data;
        this.onOptionSelected(this.countrystring)
        this.pstates = data;
        this.onOptionSelectedp(this.pcountrystring)
      })
    })
  }

  onCustomerClick() {
    let tid: number = this.setid;
    let tctrl: number = this.opermode;
    console.log("loyaltyEnabled", this.loyaltyEnabled);
    console.log("wholesaleEnabled", this.wholesaleEnabled);

    const customerDetails: CustomerDetails = {
      id: tid, // ID клиента
      firstName: this.firstname,
      lastName: this.lastname,
      email: this.email,
      phone: this.phone,
      customerGroup: this.groupe,
      optInMarketing: this.checkemail, // Преобразуем boolean из `checkemail`

      // Основной адрес (Mailing Address)
      mailingStreetAddress: this.address,
      mailingStreetAddress2: this.address2,
      mailingSuburb: this.suburb,
      mailingCity: this.city,
      mailingZipCode: this.zipcode,
      mailingState: this.statesstring,
      mailingCountry: this.countrystring,

      // Почтовый адрес (Postal Address)
      usedifferent: this.usedifferent, // Флаг "использовать другой адрес"
      postalStreetAddress: this.paddress,
      postalStreetAddress2: this.paddress2,
      postalSuburb: this.psuburb,
      postalCity: this.pcity,
      postalZipCode: this.pzipcode,
      postalState: this.pstatesstring,
      postalCountry: this.pcountrystring,

      // Информация о клиенте
      customerCode: this.customercode,
      companyName: this.companyname,
      dateOfBirth: this.bdate instanceof Date ? this.bdate : new Date(),
      gender: this.genderstring,
      website: this.website,
      instagram: this.instagram,
      facebook: this.facebook,
      tiktok: this.tiktok,
      twitter: this.twitter,
      youtube: this.youtube,
      notes: this.descriptionstring, // `descriptionstring` используется как заметки

      // Настройки
      allowOnAccountBalance: this.radioValue, // Радио-кнопка выбора (0,1,2)
      balanceLimit: this.limit,
      taxRate: this.taxrate,
      loyaltyEnabled: this.loyaltyEnabled, // Флаг включения лояльности

      // Кастомные поля
      customField1: this.customfield1,
      customField2: this.customfield2,
      customField3: this.customfield3,
      customField4: this.customfield4,

      // Системные параметры
      ctrl: tctrl, // Значение может изменяться в зависимости от контекста
      accountid: this.getAccountID(),
      discount: this.discount,
      wholesale: this.wholesaleEnabled
    };
    this.serviceservice.customersSale(customerDetails).subscribe({
      next: (value: CustomerResponse) => {
        this.modalService.customersFrameClose()
        this.notification.create(
          "success",
          'System Message',
          'Data saved successfully.',
          {nzDuration: 4000}
        );
        this.yourappsService.getReloadCustomList([], 1)
        console.log(value)
      },
      error: (error: any) => {
        console.error('Error occurred:', error);
      },
      complete: () => {
        console.log('Subscription completed');
      }
    })
  }

  onChange(result: Date): void {
    console.log('onChange: ', result);
  }

  protected readonly SelfData = SelfData;
  taxrate: string = "No Tax (0%)";
  customfield1: string = "";
  customfield2: string = "";
  customfield3: string = "";
  customfield4: string = "";
  loyaltyEnabled: boolean = false;
  wholesaleEnabled: boolean = false;
  selectedSalesReport: any = [];
  viewmode: boolean = false;

  onTaxesClick() {

  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  calculateTotal() {
    let total = 0;

    if (this.iProductsResult) {
      for (let customer of this.iProductsResult) {
        total += customer['Quentity'];
        // if (customer['ordertotal'] === name) {
        //   total++;
        // }
      }
    }

    return total;
  }

  calculateTotal1() {
    let total = 0;

    if (this.iProductsResult) {
      for (let customer of this.iProductsResult) {
        total += customer['TotalAfterDiscount'];
        // if (customer['ordertotal'] === name) {
        //   total++;
        // }
      }
    }

    return total;
  }

  calculateTotal2() {
    let total = 0;

    if (this.iProductsResult) {
      for (let customer of this.iProductsResult) {
        total += customer['DiscountAmount'];
        // if (customer['ordertotal'] === name) {
        //   total++;
        // }
      }
    }

    return total;
  }
}
