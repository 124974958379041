import { Component } from '@angular/core';
import {DeviceDetectorService} from "ngx-device-detector";
import {ModalserviceService} from "../../services/modalservice.service";

@Component({
  selector: 'app-createimageviews1',
  templateUrl: './createimageviews1.component.html',
  styleUrls: ['./createimageviews1.component.scss']
})
export class Createimageviews1Component {
  constructor(public modalService: ModalserviceService, private deviceService: DeviceDetectorService) {
    this.setDeviceClass();
  }

  deviceClass: string = "";
  footerClass: string = "";
  deviceClassAddress: string = "";
  isLandscape: boolean = false;
  hidden: boolean = true;

  setDeviceClass(): void {
    this.deviceClass = this.deviceService.isMobile() ? 'modal-container-mobile' : 'modal-container';
    this.footerClass = this.deviceService.isMobile() ? 'mobilebutton-container-dm-ps' : 'pcbutton-container-dm-ps';
    this.deviceClassAddress = this.deviceService.isMobile() ? 'mobileaddressdiv' : 'pcaddressdiv';
    // this.isLandscape = window.innerWidth > window.innerHeight ? 'islandscape' : 'isnlandscape';
  }
}
