<form [formGroup]="myForm" (ngSubmit)="submit(1)" autocomplete="off">
  <p-button class="info-btn" icon="pi pi-info" [rounded]="true" [outlined]="true" (click)="onInfoButtonClick()"/>
    <button mat-icon-button type="button" class="close-btn" (click)="onButtonCloseClick()">
        <mat-icon>close</mat-icon>
    </button>
    <p class="sample-manager-text">Create Request</p>

    <div class="step-buttons">
        <button mat-button [class.def-button]="stepIndex > 0" [class.deffull-button]="stepIndex === 0"
                [class.selected-button]="stepIndex === 0 || stepIndex < 2" [disabled]="true">
            <mat-icon iconPositionEnd>arrow_forward</mat-icon>
            <span class="button-content">Step 1: Delivery Information</span>
        </button>

        <button mat-button [class.def1-button]="stepIndex > 1" [class.def2-button]="stepIndex === 1"
                [class.selected-button]="stepIndex === 1" [disabled]="true">
            <mat-icon iconPositionEnd>check</mat-icon>
            <span class="button-content">Step 2: Scan Samples</span>
        </button>
    </div>

    <div *ngIf="modalService.isSendOutStep1$ | async" class="centered-sendout-container1">
        <app-sendoutstep1></app-sendoutstep1>
    </div>

    <div *ngIf="modalService.isSendOutStep2$ | async" class="centered-sendout-container2">
        <app-sendoutstep2></app-sendoutstep2>
    </div>

    <div class="button-container-sendout">
        <button mat-raised-button class="button-settings" type="button" *ngIf="stepIndex > 0 && SelfData.sendoutopentype === 0" (click)="backClick()">
            <mat-icon>arrow_back_ios</mat-icon>
            Back
        </button>

        <button *ngIf="SelfData.sendoutopentype === 0" mat-raised-button class="button-settings" type="button"
                (click)="nextClick()"
                [disabled]="SelfData.sendoutrequestid > 0">
            <mat-icon iconPositionEnd>
                <ng-container *ngIf="stepIndex === 1; else forwardIcon">
                    check
                </ng-container>
                <ng-template #forwardIcon>arrow_forward_ios</ng-template>
            </mat-icon>
            {{ createbuttoncaption }}
        </button>

        <button *ngIf="SelfData.sendoutopentype !== 0" mat-raised-button class="button-settings" type="button"
                (click)="nextClick()"
                [disabled]="SelfData.sendoutrequestid > 0">
            {{ createbuttoncaption }}
        </button>

        <!-- Render this button when SelfData.sendoutopentype does not equal 0 -->
        <button *ngIf="showacceptbutton" mat-raised-button class="button-settings" type="button"
                (click)="onButtonAcceptClick()"
                [disabled]="SelfData.sendoutrequestid > 0">
            {{ acceptbuttoncaption }}
        </button>


    </div>

</form>

<p-confirmDialog *ngIf="showdialog" #cd [style]="{width: '25vw'}" [closable]="true">
  <ng-template pTemplate="footer">
    <div class="button-container-request">
      <button mat-raised-button class="button-secondary" style="margin-right: 10px; width: 50%" (click)="cd.reject()">
        Cancel
      </button>
      <button mat-raised-button class="button-primary" style="margin-right: 0; width: 50%" (click)="cd.accept()">
        Save
      </button>
    </div>
  </ng-template>
</p-confirmDialog>

