import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder} from "@angular/forms";
import {HashService} from "../../services/hash.service";
import {MessageboxComponent} from "../../modals/messagebox/messagebox.component";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {ConfirmationService, ConfirmEventType, MessageService} from "primeng/api";
import {DatePipe} from "@angular/common";
import {
  GetCelebritiList, GetRequestList,
  GetScannedList,
  GetSocialMediaList,
  GetSocialMediaListData, PrcCreateRequestToSendOutResponse,
  SelfData, Speener
} from "../../models/struct";
import {Table} from "primeng/table";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-requestlist',
  templateUrl: './requestlist.component.html',
  styleUrls: ['./requestlist.component.scss']
})
export class RequestlistComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild('dt1') dt1!: Table;
  sizes!: any[];
  filtertext: string = ""
  selectedSize: any = '';
  selectedElement: any;
  progressSpinnerShow: Boolean = true
  defaultimagelink: string = ""
  defaultname: string = ""
  private subscription?: Subscription
  private subscription1?: Subscription
  constructor(
      public modalService: ModalserviceService,
      public fb: FormBuilder,
      private hashService: HashService,
      public messageBox: MessageboxComponent,
      private serviceservice: ServiceService,
      private yourappsService: YourappsserviceService,
      private cd: ChangeDetectorRef,
      private confirmationService: ConfirmationService,
      private messageService: MessageService,
      private datePipe: DatePipe
  ) {
    this.sizes = [
      {name: 'Small', class: 'p-datatable-sm'}
    ];
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.subscription1) {
      this.subscription1.unsubscribe();
    }
  }

  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
      ? SelfData.account[0].data[0].id
      : SelfData.account[0].data[0].parentid;
  }

  ngOnInit(): void {
    this.subscription1 = this.yourappsService.reloadRequestList.subscribe((value) => {
      if (this.subscription1) {
        this.subscription1.unsubscribe();
        Speener.requestvisiblemode = true
        this.modalService.sendoutlistclose();
        this.serviceservice
          .getRequestlist({accountid: this.getAccountID()})
          .subscribe((value) => {
            // SelfData.requesttlist = []
            this.iRequestList = []
            this.iRequestList[0] = value;
            Speener.requestvisiblemode = false
            SelfData.requesttlist = this.iRequestList;
            this.modalService.createReuestList();
          });
      }
    })
  }

    protected readonly SelfData = SelfData;

  formatDate(date: string): string {
    return <string>this.datePipe.transform(date, 'dd/MMM/yy HH:mm');
  }

  onButtonCelebritiClick(element: any){
    this.progressSpinnerShow = true
    this.loadSMData(element?.["contactid"], element?.["celebriti"])
  }

  getIdForDescription(dataArray: GetCelebritiList[], descriptionToFind: string): number | null {
    for (let dataObj of dataArray) {
      if (dataObj && dataObj.data) {
        let found = dataObj.data.find(item => item.description === descriptionToFind);
        if (found) {
          return found.smid;
        }
      }
    }
    return null;
  }
  onInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;
    this.dt1.filterGlobal(value, 'contains');
  }

  clear(table: Table) {
    table.clear();
    this.filtertext = ""
  }

  iCelebritiResult: GetCelebritiList[] = [];
  iSMList: GetSocialMediaList[] = [];
  loadSMData(contactid: number, celebriti: string) {
    this.serviceservice
        .selectcelebriti({contactid: contactid})
        .subscribe((value) => {
          this.iCelebritiResult = [];
          this.iCelebritiResult[0] = value;
          SelfData.celebriti = this.iCelebritiResult;
          SelfData.contactid = contactid
          let smid = this.getIdForDescription(this.iCelebritiResult, celebriti) || 0;
          this.serviceservice.setSMList({id: smid})
              .subscribe({
                next: (value) => {
                  if (value && value.data && Array.isArray(value.data)) {
                    this.iSMList[0] = value;
                    SelfData.smedia = this.iSMList;
                    console.log(SelfData.smedia)
                    const platforms = ["Instagram", "FaceBook", "TikTok", "YouTube", "Twitter"];
                    for (const platform of platforms) {
                      const foundPlatform = SelfData.smedia[0].data.find(item => item.platform === platform);
                      if (foundPlatform) {
                        this.defaultimagelink = foundPlatform.avatarLink;
                        this.defaultname = foundPlatform.name;
                        console.log("this.defaultimagelink", this.defaultimagelink)
                        break;
                      }
                    }
                    this.progressSpinnerShow = false
                  } else {
                    console.log('The response is empty, lacks a data property, or the data property is not an array.');
                  }
                },
                error: (error) => {
                  console.error('Error occurred during the subscription:', error);
                },
              });
        });
  }

  sortedSMedia(): GetSocialMediaListData[] {
    if (this.SelfData && this.SelfData.smedia && this.SelfData.smedia[0] && Array.isArray(this.SelfData.smedia[0].data)) {
      return [...this.SelfData.smedia[0].data].sort((a, b) => b.subscribers - a.subscribers);
    }
    return []; // default to empty array if data isn't available
  }

  createrequest(element: any) {
    SelfData.sendoutrequestid = element.sendoutid
    this.showdialog = false
    this.cd.detectChanges()
    SelfData.sendoutgroupecaption = "Request Details"
    SelfData.sendoutopentype = 1;
    SelfData.sendoutmode = 1
    SelfData.sendoutstep = 0;
    SelfData.saveonclose = false
    this.SelfData.fullPage = true
    SelfData.sendoutid = element?.["id"]
    console.log("SelfData.sendoutid", SelfData.sendoutid)
    this.serviceservice
      .selectrequestsamples({
        requestid: SelfData.sendoutid,
      })
      .subscribe((value) => {
        this.iRequestList[0] = value;
        SelfData.requestlist = this.iRequestList;
        console.log("SelfData.requestlist", SelfData.requestlist)
        this.yourappsService.selectedSendOut(element);

        this.subscription = this.yourappsService.reloadRequestList.subscribe((value) => {
          if (this.subscription) {
            this.subscription.unsubscribe();
            console.log("SelfData.requesttlist[0]", SelfData.requesttlist[0])
            console.log("SelfData.sendoutid", SelfData.sendoutid)
            const isUpdated = this.setAcceptedForRecordById(SelfData.sendoutid, SelfData.requesttlist[0]);
            if (isUpdated) {
              console.log('Record found and updated.');
            } else {
              console.log('No record found with the specified ID.');
            }
          }
        })
        this.modalService.createfullrequestopen()
      });

    // this.modalService.openRequest();
    // // this.yourappsService.setSampleAndContavcts(false, false, true)
  }

  iRequestList: GetRequestList[] = [];
  showdialog: boolean = false;

  createSendRequest(item: any) {
    SelfData.sendoutrequestid = item.sendoutid
    this.yourappsService.setSelectedSendOut(item)
    this.modalService.openSR()
  }

  createRequestSamplse(element: any) {
    SelfData.sendoutrequestid = element.sendoutid
    this.showdialog = false
    this.cd.detectChanges()
    SelfData.sendoutopentype = 1
    SelfData.sendoutmode = 1
    SelfData.sendoutstep = 1;
    SelfData.saveonclose = false
    SelfData.sendoutid = element?.["id"]
    this.serviceservice
        .selectrequestsamples({
          requestid: SelfData.sendoutid,
        })
        .subscribe((value) => {
          this.iRequestList[0] = value;
          SelfData.requestlist = this.iRequestList;
          console.log("SelfData.requestlist", SelfData.requestlist)
          this.yourappsService.selectedSendOut(element);
          this.subscription = this.yourappsService.reloadRequestList.subscribe((value) => {
            if (this.subscription) {
              this.subscription.unsubscribe();
              console.log("SelfData.requesttlist[0]", SelfData.requesttlist[0])
              console.log("SelfData.sendoutid", SelfData.sendoutid)
              const isUpdated = this.setAcceptedForRecordById(SelfData.sendoutid, SelfData.requesttlist[0]);
              if (isUpdated) {
                console.log('Record found and updated.');
              } else {
                console.log('No record found with the specified ID.');
              }
            }
          })
          this.modalService.openRequest();
        });
  }

  setAcceptedForRecordById(id: number, selfData: GetRequestList): boolean {

    const record = selfData.data.find(record => record.id === id);
    if (record) {
      record.isaccepted = SelfData.isacceptedstatus;
      return true; // Successfully found and updated
    }
    return false; // Record not found
  }

  onButtonPullSheetClick(item: any) {
    SelfData.sendoutrequestid = item.sendoutid
    this.showdialog = false
    this.cd.detectChanges()
    if (item.pullsheet === 1) {
      this.messageService.add({
        key: 'tc',
        severity: 'info',
        summary: 'Information',
        detail: 'Pull Sheet is already sent.',
        life: 10000,
      });
    } else {
      this.yourappsService.setSelectedSendOut(item)
      this.modalService.openCPS()
    }
  }

  onButtonCreateSendoutClick(item: any) {
    if (item?.sendoutid > 0) {
      this.messageService.add({
        key: 'tc',
        severity: 'success',
        summary: 'Information',
        // detail: `Sample ${barcodeValue} already scanned by user ${foundData['user']} for a new delivery or reservation.`,
        detail: `Request Has Been Sent`,
        life: 10000,
      });
      return
    }
    if (item?.isaccepted) {
      SelfData.sendoutrequestid = item.sendoutid
      this.showdialog = true
      this.cd.detectChanges()
      this.confirmationService.confirm({
        message: `<strong>Do you want to create a Send Out?</strong>`,
        header: 'Information',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.serviceservice.prcCreateRequestToSendOut(item?.id).subscribe({
            next: (response: PrcCreateRequestToSendOutResponse) => {
              this.messageService.add({
                key: 'tc',
                severity: 'success',
                summary: 'Information',
                // detail: `Sample ${barcodeValue} already scanned by user ${foundData['user']} for a new delivery or reservation.`,
                detail: `Send Out created successfully`,
                life: 10000,
              });
              // Handle the response
            },
            error: (error) => {
              console.error('Error:', error);
              // Handle the error
            }
          });

        },
        reject: (type: any) => {
          if (type == ConfirmEventType.REJECT) {
          } else if (type == ConfirmEventType.CANCEL) {
          }
        }
      })
    } else {
      this.messageService.add({
        key: 'tc',
        severity: 'error',
        summary: 'Information',
        // detail: `Sample ${barcodeValue} already scanned by user ${foundData['user']} for a new delivery or reservation.`,
        detail: `Request is not Accepted`,
        life: 10000,
      });
    }
  }

  openLink(link: string): void {
    window.open(link, "_blank");  // This opens the link in a new window or tab.
    // window.location.href = link;  // Use this instead if you want to navigate to the link in the same window.
  }

  getColor(index: number): string {
    return index % 2 === 0 ? '#efefef' : '#dcdcdc';
  }

  onButtobSelectClick(product: any) {

  }

  onInfoButtonClick() {
    window.open('https://fm.welcomeapps.net/FAQ/ListRequest.html', '_blank');
  }
}
