<div class="card example-container-so  mat-elevation-z3">
    <p-table
        #dt1
            [value]="SelfData.requesttlist[0].data"
            [paginator]="true"
            [rows]="10"
            [showCurrentPageReport]="true"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [rowsPerPageOptions]="[10, 20, 50, 100]"
            [styleClass]="'p-datatable-striped mat-elevation-z3 p-datatable-sm'"
            [globalFilterFields]="['Delivery', 'Type of Dispatch', 'Creation date', 'company', 'Contact', 'celebriti', 'publication', 'Editor/Stylist', 'Celeb/Influenc/Model', 'Project/Event', 'Sendout Status']"
            selectionMode="single"
            [tableStyle]="{'min-width': '50rem'}">
        <ng-template pTemplate="caption">
            <div class="flex">
                <!--                    <span class="p-inputgroup-addon custom-hover" matRipple (click)="onButtonAddClick()">-->
                <!--                        <i class="pi pi-plus" style="color: #000000"></i></span>-->
              <p-button class="info-btn" icon="pi pi-info" [rounded]="true" [outlined]="true" (click)="onInfoButtonClick()"/>
                <span class="p-input-icon-left ml-auto">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">
                            <i class="pi pi-search" style="color: #000000"></i>
                        </span>
                        <input pInputText type="text" (input)="onInput($event)" placeholder="Filter" [(ngModel)]="filtertext"/>
                        <span class="p-inputgroup-addon custom-hover-red" matRipple (click)="clear(dt1)">
                            <i class="pi pi-times" style="color: #000000"></i></span>
                    </div>
                </span>

            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <!--                    -->
                <th style="width: 0.5%">
                </th>
                <th style="width:0%">Options</th>
                <th style="width:17.6%">Delivery #<br>Type of Dispatch</th>
                <th style="width:17.6%">Creation Data</th>
                <th style="width:17.6%">Company<br>Contact<br>Client</th>
                <th style="width:17.6%">Publication<br>Editor/Stylist</th>
                <th style="width:17.6%">Celeb/Influenc/Model<br>Project/Event</th>
                <th style="width:8%">Request Status</th>
                <!--                <th>Name</th>-->
                <!--                <th>Category</th>-->
                <!--                <th>Quantity</th>-->
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
            <tr [pSelectableRow]="SelfData.requesttlist[0].data">
                <td>
                    <div class="scdivsl"></div>
                </td>
                <td>
<!--                  <p-splitButton [matMenuTriggerFor]="menu" (click)="selectedElement = product"-->
<!--                    label="select" styleClass="p-button-outlined p-button-secondary mr-2 mb-2"></p-splitButton>-->
<!--                    <p-button-->
<!--                            label="Select" size="small" [outlined]="true" severity="secondary"-->
<!--                            [matMenuTriggerFor]="menu"-->
<!--                            (click)="selectedElement = product">-->
<!--                    </p-button>-->
                  <button nzTrigger="click"  nz-button nz-dropdown
                    [nzDropdownMenu]="menu4" (click)="selectedElement = product">
                    select
                    <span nz-icon nzType="down"></span>
                  </button>
                </td>


                <td><span style="text-decoration: underline; color: #007bff;" (click)="createrequest(product)">{{ product?.["Delivery"] }}</span><br>
                  {{product?.["Type of Dispatch"]}}</td>
                <td>{{formatDate(product?.["Creation date"])}}</td>
                <td>
                    {{product?.["company"]}}<br>
                    {{product?.["Contact"]}}<br>
                    <div class="celebriti">
                        <strong>{{product?.["celebriti"]}}</strong>
                        <button *ngIf="product?.celebriti" mat-icon-button class="center-icon" (click)="onButtonCelebritiClick(product); op.toggle($event)">
                            <mat-icon class="scaled-icon">account_circle</mat-icon>
                        </button>
                    </div>
                </td>
                <td>{{product?.["publication"]}}<br>{{product?.["Editor/Stylist"]}}</td>
                <td>{{product?.["Celeb/Influenc/Model"]}}<br>{{product?.["Project/Event"]}}</td>
                <td [ngClass]="product?.sendoutid > 0 ? 'text-green' : (product?.isaccepted ? 'text-yellow' : 'text-red')">
                    <span *ngIf="product?.sendoutid > 0; else acceptedOrNot">
                        Has Been Sent
                    </span>
                    <ng-template #acceptedOrNot>
                        {{ product?.isaccepted ? 'Accepted' : 'Not Accepted' }}
                    </ng-template>
                </td>
                <!--                <td>{{product.category}}</td>-->
                <!--                <td>{{product.quantity}}</td>-->
            </tr>
        </ng-template>
    </p-table>
</div>

<mat-menu #menu="matMenu">
    <button mat-menu-item (click)="onButtonCreateSendoutClick(selectedElement)">
        <!--        <mat-icon>edit_note</mat-icon>-->
        <span>Create Send Out</span>
    </button>

    <button mat-menu-item (click)="createrequest(selectedElement)">
        <!--        <mat-icon>edit_note</mat-icon>-->
        <span>Edit Request</span>
    </button>

<!--    <button mat-menu-item (click)="createRequestSamplse(selectedElement)">-->
<!--        &lt;!&ndash;        <mat-icon>qr_code_2</mat-icon>&ndash;&gt;-->
<!--        <span>Edit Selected Samples</span>-->
<!--    </button>-->

    <button mat-menu-item (click)="createSendRequest(selectedElement)">
        <!--        <mat-icon>delete</mat-icon>-->
        <span>Send Request</span>
    </button>

<!--    <button mat-menu-item >-->
<!--        &lt;!&ndash;        <mat-icon>delete</mat-icon>&ndash;&gt;-->
<!--        <span>Print Request</span>-->
<!--    </button>-->

    <!--    <button mat-menu-item>-->
    <!--&lt;!&ndash;        <mat-icon>delete</mat-icon>&ndash;&gt;-->
    <!--        <span>Raise Invoice</span>-->
    <!--    </button>-->
    <button mat-menu-item (click)="onButtonPullSheetClick(selectedElement)">
        <!--        <mat-icon>delete</mat-icon>-->
        <span>Pull Sheet</span>
    </button>
</mat-menu>

<nz-dropdown-menu #menu4="nzDropdownMenu">
  <ul nz-menu>
    <li nz-menu-item (click)="onButtonCreateSendoutClick(selectedElement)">Create Send Out</li>
    <li nz-menu-item (click)="createrequest(selectedElement)">Edit Request</li>
    <li nz-menu-item (click)="createSendRequest(selectedElement)">Send Request</li>
    <li nz-menu-item (click)="onButtonPullSheetClick(selectedElement)">Pull Sheet</li>
  </ul>
</nz-dropdown-menu>

<p-overlayPanel #op>
    <mat-card class="overlay-card">
        <div *ngIf="progressSpinnerShow" class="spinner">
            <p-progressSpinner></p-progressSpinner>
        </div>
        <div *ngIf="!progressSpinnerShow"  class="black-background-social">
            <div class="child-40">
                <div class="content-wrapper">
                    <div class="thema-image shadow-4" [style.background-image]="'url(' + defaultimagelink + ')'"></div>
                </div>
            </div>

            <div class="child-60">
                <div class="top-center-full-width">
                    <span class="centered-text">{{ defaultname }}</span>
                </div>
                <mat-divider class="black-divider"></mat-divider>
                <div class="black-background-caption">
                    <div class="caption-40">
                        <span class="left-text-caption">CHANNEL</span>
                    </div>
                    <div class="caption-60">
                        <span class="right-text-caption">FOLLOWERS</span>
                    </div>
                </div>
                <mat-divider class="black-divider"></mat-divider>
                <div class="centered-div-social" *ngFor="let item of sortedSMedia(); let i = index"
                        [ngStyle]="{ 'background-color': getColor(i) }">
                    <span class="left-text" (click)="openLink(item.link)">{{ item.platform }}</span>
                    <span class="right-text">{{ item.subscribers | formatCount }}</span>
                </div>

            </div>
        </div>

    </mat-card>
</p-overlayPanel>

<p-confirmDialog *ngIf="showdialog" #cd [style]="{width: '25vw'}" [closable]="true">
    <ng-template pTemplate="footer">
        <div class="button-container-request">
            <button mat-raised-button class="button-secondary" style="margin-right: 10px; width: 50%" (click)="cd.reject()">
                No
            </button>
            <button mat-raised-button class="button-primary" style="margin-right: 0; width: 50%" (click)="cd.accept()">
                Yes
            </button>
        </div>
    </ng-template>
</p-confirmDialog>
