import { Component } from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";

@Component({
  selector: 'app-sendoutsamplelist',
  templateUrl: './sendoutsamplelist.component.html',
  styleUrls: ['./sendoutsamplelist.component.scss']
})
export class SendoutsamplelistComponent {

  constructor(public modalService: ModalserviceService) {

  }

}
