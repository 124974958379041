import { Component } from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";

@Component({
  selector: 'app-emailsign',
  templateUrl: './emailsign.component.html',
  styleUrl: './emailsign.component.scss'
})
export class EmailsignComponent {
  constructor(public modalService: ModalserviceService) {}
}
