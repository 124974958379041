<p-toast position="top-center" key="tc" [style]="{ width: '40vw' }"></p-toast>
<p-toast position="bottom-right"></p-toast>

<div class="layout-sidebar">
  <app-sidebar></app-sidebar>
</div>

<div class="fixed-elements">
  <app-top-panel-sale></app-top-panel-sale>
</div>

<div *ngIf="modalService.isHomePanelVisibled$ | async" class="layout-main-container">
  <div class="layout-main">
    <app-home *ngIf="modalService.isHomePanelVisibled$ | async" @fadeInOut></app-home>
  </div>
</div>

<div *ngIf="modalService.isSetupGeneralVisibled$ | async" class="layout-main-container">
  <div class="layout-main">
    <app-setupgeneral *ngIf="modalService.isSetupGeneralVisibled$ | async" @fadeInOut></app-setupgeneral>
  </div>
</div>

<div *ngIf="modalService.isOutletsVisibled$ | async" class="layout-main-container">
  <div class="layout-main">
    <app-outlets *ngIf="modalService.isOutletsVisibled$ | async" @fadeInOut></app-outlets>
  </div>
</div>

<div *ngIf="modalService.isCustomerVisibled$ | async" class="layout-main-container">
  <div class="layout-main">
    <app-customers *ngIf="modalService.isCustomerVisibled$ | async" @fadeInOut></app-customers>
  </div>
</div>

<div *ngIf="modalService.isSupplierVisibled$ | async" class="layout-main-container">
  <div class="layout-main">
    <app-suppliers *ngIf="modalService.isSupplierVisibled$ | async" @fadeInOut></app-suppliers>
  </div>
</div>

<div *ngIf="modalService.isProductVisibled$ | async" class="layout-main-container">
  <div class="layout-main">
    <app-products *ngIf="modalService.isProductVisibled$ | async" @fadeInOut></app-products>
  </div>
</div>

<div *ngIf="modalService.isSCVisibled$ | async" class="layout-main-container">
  <div class="layout-main">
    <app-sc *ngIf="modalService.isSCVisibled$ | async" @fadeInOut></app-sc>
  </div>
</div>

<div *ngIf="modalService.iSSVisibled$ | async" class="layout-main-container">
  <div class="layout-main">
    <app-ss *ngIf="modalService.iSSVisibled$ | async" @fadeInOut></app-ss>
  </div>
</div>

<div *ngIf="modalService.isalesreportVisibled$ | async" class="layout-main-container">
  <div class="layout-main">
    <app-salesreport *ngIf="modalService.isalesreportVisibled$ | async" @fadeInOut></app-salesreport>
  </div>
</div>

<div *ngIf="modalService.userslistVisibled$ | async" class="layout-main-container">
  <div class="layout-main">
    <app-userslist *ngIf="modalService.userslistVisibled$ | async" @fadeInOut></app-userslist>
  </div>
</div>

<app-or class="centered-messagebox" *ngIf="modalService.isorVisibled$ | async" @fadeInOut>
  <app-ordialog></app-ordialog>
</app-or>

<app-taxes class="centered-messagebox" *ngIf="modalService.taxesVisibled$ | async" @fadeInOut>
  <app-taxesdialog></app-taxesdialog>
</app-taxes>

<app-customersframe class="centered-messagebox" *ngIf="modalService.customersframeVisibled$ | async" @fadeInOut>
  <app-customersframedialog></app-customersframedialog>
</app-customersframe>

<app-scframe class="centered-messagebox" *ngIf="modalService.scframeVisibled$ | async" @fadeInOut>
  <app-scframedialog></app-scframedialog>
</app-scframe>

<app-ssframe class="centered-messagebox" *ngIf="modalService.ssframeVisibled$ | async" @fadeInOut>
  <app-ssframedialog></app-ssframedialog>
</app-ssframe>

<app-productframe class="centered-messagebox" *ngIf="modalService.productframeVisibled$ | async" @fadeInOut>
  <app-productframedialog></app-productframedialog>
</app-productframe>

<app-supplierframe class="centered-messagebox" *ngIf="modalService.suppliersframeVisibled$ | async" @fadeInOut>
  <app-supplierframedialog></app-supplierframedialog>
</app-supplierframe>

<app-srframe class="centered-messagebox" *ngIf="modalService.srframeVisibled$ | async" @fadeInOut>
  <app-srframedialog></app-srframedialog>
</app-srframe>

<app-aes class="centered-messagebox" *ngIf="modalService.aesframeVisibled$ | async" @fadeInOut>
  <app-aesdialog></app-aesdialog>
</app-aes>

<app-user class="centered-messagebox" *ngIf="modalService.userVisibled$ | async" @fadeInOut>
  <app-userdialog></app-userdialog>
</app-user>

<app-profilelist class="centered-messagebox" *ngIf="modalService.profilelistVisibled$ | async" @fadeInOut>
  <app-profilelistdialog></app-profilelistdialog>
</app-profilelist>

<app-profileframe class="centered-messagebox" *ngIf="modalService.profileframeVisibled$ | async" @fadeInOut>
  <app-profileframedialog></app-profileframedialog>
</app-profileframe>

<nz-spin class="spinner" [nzSpinning]="SelfData.spinnerpossition" nzSize="large" nzSimple></nz-spin>
