import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2, ViewChild
} from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ConfirmationService, ConfirmEventType, MessageService} from "primeng/api";
import {NgxImageCompressService} from "ngx-image-compress";
import { HttpClient } from "@angular/common/http";
import {DOCUMENT} from "@angular/common";
import {ProgressBarComponent} from "smart-webcomponents-angular/progressbar";
import {GetLBCImageList, GetLBImageList, LbMainData, LbMainDetails, SelfData} from "../../models/struct";
import {Subscription} from "rxjs";
import {data} from "autoprefixer";

@Component({
  selector: 'app-createvcimagesdialog',
  templateUrl: './createvcimagesdialog.component.html',
  styleUrls: ['./createvcimagesdialog.component.scss']
})

export class CreatevcimagesdialogComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('progressbar', {read: ProgressBarComponent, static: false}) progressbar!: ProgressBarComponent;
  formcaption: string = ""
  collectionFormControl = new FormControl({value: '', disabled: false}, [
    Validators.required,
  ]);
  progressbarshow: boolean = false;
  previewVisible: boolean = false;
  translationY: number = 0;
  zoomLevel: number = 1;
  previewUrl: string = '';
  dragging: boolean = false;
  startY: number = 0;
  operationType: number = 0
  catalogid: number = 0
  iImages: GetLBCImageList[] = [];
  deletedFiles: string[] = [];
  private subscription?: Subscription;
  private subscription1?: Subscription;
  showdialog: boolean = true;
  uploadbuttonenable: boolean = true
  selectedImageFiles: {
    file: File | null;
    url: string;
    filename: string;
    lbcatalogid: number;
    colection: string;
    designer: string;
    catalog: string;
    catalogyear: number;
    seasoncode: string;
  }[] = [];
  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
        ? SelfData.account[0].data[0].id
        : SelfData.account[0].data[0].parentid;
  }

  constructor(
      public modalService: ModalserviceService,
      public fb: FormBuilder,
      private serviceservice: ServiceService,
      private yourappsService: YourappsserviceService,
      private _snackBar: MatSnackBar,
      private cdr: ChangeDetectorRef,
      private messageService: MessageService,
      private imageCompressService: NgxImageCompressService,
      private http: HttpClient,
      private confirmationService: ConfirmationService,
      private renderer: Renderer2, @Inject(DOCUMENT) private document: Document,
      private el: ElementRef
  ) {
    // this.formcaption = "Edit"
    // this.collectionFormControl.setValue(SelfData.lbdescription)
    // setInterval(() => {
    //   this.doSomethingAfterDelay();
    // }, 1000);
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.subscription1) {
      this.subscription1.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.showdialog = true
    this.subscription = this.yourappsService.lcitem$.subscribe((value: { data: any, type: number }) => {
      console.log("value.type", value.type)
      if (!Array.isArray(value.data) && value.type === 1) {
        this.uploadbuttonenable = true
        this.operationType = value.type;
        this.formcaption = "Edit";
        this.collectionFormControl.setValue(value.data.description);
        this.catalogid = value.data.id

        this.serviceservice
            .setLBCImageList({ lbmainid: value.data.id })
            .subscribe({
              next: (value: any) => {
                if (value && value.data && Array.isArray(value.data)) {
                  this.iImages[0] = value.data;
                  if (value.data.length > 0) {
                    value.data.forEach((imageinfo: any) => {
                      const catalog = imageinfo.catalog
                      const catalogyear = imageinfo.catalogyear
                      const colection = imageinfo.colection
                      const designer = imageinfo.designer
                      const filedir = imageinfo.filedir
                      const filename = imageinfo.filename
                      const lbcatalogid = imageinfo.lbcatalogid
                      const seasoncode = imageinfo.seasoncode
                      this.selectedImageFiles.push({
                        file: null,
                        url: filedir,
                        filename: filename,
                        catalog: catalog,
                        catalogyear: catalogyear,
                        colection: colection,
                        designer: designer,
                        lbcatalogid: lbcatalogid,
                        seasoncode: seasoncode,
                      });
                    })
                  }
                } else {
                  console.log(
                      'The response is empty or not in the expected format.'
                  );
                }
              },
              error: (error) => {
                console.error('Error occurred during the subscription:', error);
              },
            });
      } else if (value.type === 2) {
        this.uploadbuttonenable = false
        this.operationType = value.type;
        this.formcaption = "Edit";
        this.collectionFormControl.setValue(value.data.description);
        this.catalogid = value.data.id

        this.serviceservice
            .setLBCImageList({ lbmainid: value.data.id })
            .subscribe({
              next: (value: any) => {
                if (value && value.data && Array.isArray(value.data)) {
                  this.iImages[0] = value.data;
                  if (value.data.length > 0) {
                    value.data.forEach((imageinfo: any) => {
                      const catalog = imageinfo.catalog
                      const catalogyear = imageinfo.catalogyear
                      const colection = imageinfo.colection
                      const designer = imageinfo.designer
                      const filedir = imageinfo.filedir
                      const filename = imageinfo.filename
                      const lbcatalogid = imageinfo.lbcatalogid
                      const seasoncode = imageinfo.seasoncode
                      this.selectedImageFiles.push({
                        file: null,
                        url: filedir,
                        filename: filename,
                        catalog: catalog,
                        catalogyear: catalogyear,
                        colection: colection,
                        designer: designer,
                        lbcatalogid: lbcatalogid,
                        seasoncode: seasoncode,
                      });
                    })
                  }
                } else {
                  console.log(
                      'The response is empty or not in the expected format.'
                  );
                }
              },
              error: (error) => {
                console.error('Error occurred during the subscription:', error);
              },
            });
      }
    })
    this.subscription1 = this.yourappsService.setRetImages$.subscribe((value: { data: any, type: number }) => {
      if (value.data) {
        if (value.type === 1) {
          this.uploadbuttonenable = true
          this.selectedImageFiles = []
          this.selectedImageFiles = value.data
          this.showdialog = true
        } else if (value.type === 2) {
          this.uploadbuttonenable = false
        }
      }
    })
  }

  onButtonCloseClick() {
    this.modalService.closevcframe()
  }

  uploadImageFiles() {
    this.showdialog = false
    this.yourappsService.setSelectedImages(this.selectedImageFiles, 1)
    const dialogRef = this.modalService.openciframe()
  }

  getFilenameAtIndex(index: number): string | null {
    // Check if the index is within the bounds of the array
    if (index < 0 || index >= this.selectedImageFiles.length) {
      console.error('Index out of bounds when trying to access selectedImageFiles');
      return null;
    }

    // Check if there is a file object
    const imageFileEntry = this.selectedImageFiles[index];
    if (!imageFileEntry) {
      console.error('No file found at the given index');
      return null;
    }

    // Return the filename
    return imageFileEntry.filename;
  }


  deleteImageFile(event: MouseEvent, index: number) {
    this.showdialog = true
    event.stopPropagation();
    this.confirmationService.confirm({
      message: `<strong>Are you sure you want to delete this Image?</strong>`,
      header: 'Delete Image',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        let filename = this.getFilenameAtIndex(index);
        if (filename) {
          this.deletedFiles.push(filename)
          this.selectedImageFiles.splice(index, 1);
        }
      },
      reject: (type: any) => {
        if (type == ConfirmEventType.REJECT) {

        } else if (type == ConfirmEventType.CANCEL) {
        }
      }

    });
    setTimeout(() => {
      this.applyLocalStyles();
    }, 0);
  }

  private applyLocalStyles() {
    setTimeout(() => {
      const messageElements = document.querySelectorAll('.p-confirm-dialog-message');
      messageElements.forEach((element) => {
        element.setAttribute('style', 'font-size: 16px; color: red;');
      });
    }, 0);

    const iconElements = document.querySelectorAll('.p-confirm-dialog-icon');
    iconElements.forEach((element) => {
      element.setAttribute('style', 'color: red');
    });
  }

  showFullWindowImage(imageUrl: string): void {
    // Create the elements for the modal, image, and close button
    const modal = this.renderer.createElement('div');
    this.renderer.addClass(modal, 'preview-modal');

    const img = this.renderer.createElement('img');
    this.renderer.addClass(img, 'preview-image');
    this.renderer.setAttribute(img, 'src', imageUrl);
    this.renderer.appendChild(modal, img);

    const closeButton = this.renderer.createElement('button');
    this.renderer.addClass(closeButton, 'close-preview');
    closeButton.innerHTML = '&times;';  // or use an SVG/icon for the close button
    this.renderer.appendChild(modal, closeButton);

    // Append the modal to the body
    this.renderer.appendChild(this.document.body, modal);

    // Add a click listener to the close button to remove the modal
    this.renderer.listen(closeButton, 'click', (event) => {
      event.stopPropagation();  // Prevent the event from closing the modal immediately
      this.renderer.removeChild(this.document.body, modal);
    });

    // Optionally, add a click listener to the modal to close it if the user clicks outside the image
    // this.renderer.listen(modal, 'click', () => {
    //   this.renderer.removeChild(this.document.body, modal);
    // });

    // this.renderer.listen(img, 'click', () => {
    //   this.zoomScale = this.zoomScale === 1 ? 2 : 1; // Toggle between 1x and 2x zoom
    //   this.renderer.setStyle(img, 'transform', `scale(${this.zoomScale})`);
    //   this.renderer.setStyle(img, 'cursor', this.zoomScale === 1 ? 'zoom-in' : 'zoom-out');
    // });
  }

  updateImageTransform(): void {
    const img = document.querySelector('.overlay-content') as HTMLImageElement;
    img.style.transform = `scale(${this.zoomLevel}) translateY(${this.translationY}px)`;
  }

  hideFullWindowImage(): void {
    // ... existing code to hide the image
    // Reset zoom and translation
    this.zoomLevel = 1;
    this.translationY = 0;
    this.updateImageTransform();
  }

  dragImage(event: MouseEvent): void {
    if (this.dragging && this.zoomLevel > 1) {
      const diffY = event.clientY - this.startY;
      this.translationY += diffY;
      this.startY = event.clientY;
      this.updateImageTransform();
    }
  }

  stopDragging(event: MouseEvent): void {
    this.dragging = false;
    window.removeEventListener('mousemove', this.dragImage.bind(this));
    window.removeEventListener('mouseup', this.stopDragging.bind(this));
  }

  startDragging(event: MouseEvent): void {
    event.preventDefault();
    this.dragging = true;
    this.startY = event.clientY;

    // Listen for mouse move and mouse up events
    window.addEventListener('mousemove', this.dragImage.bind(this));
    window.addEventListener('mouseup', this.stopDragging.bind(this));
  }

  zoomImage(event: WheelEvent): void {
    event.preventDefault();
    const delta = Math.sign(event.deltaY);
    this.zoomLevel += delta * -0.1; // Adjust the scale step as needed
    this.zoomLevel = Math.max(1, this.zoomLevel); // Prevent zooming out below original size
    this.updateImageTransform();
  }

  onButtonUploadClick() {
    if (this.collectionFormControl.value?.trim() === "") {
      this.messageService.add({
        key: 'tc',
        severity: 'error',
        summary: 'Information',
        detail: 'Please indicate the catalog description.',
        life: 10000,
      });
      return
    }
    if (this.selectedImageFiles.length === 0) {
      this.messageService.add({
        key: 'tc',
        severity: 'error',
        summary: 'Information',
        detail: 'You must select at least one photo.',
        life: 10000,
      });
      return
    }

    this.confirmationService.confirm({
      message: `<strong>Save selected data?</strong>`,
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        var lbmainid: number = 0
          var catalogDetails: LbMainDetails
            catalogDetails = {
              id: SelfData.lbmode,
              description: this.collectionFormControl.value?.trim() || "",
              fields: "Custom Look Book",
              accountid: this.getAccountID(),
              isactivity: true,
              ctrl: 1,
              lbtype: 1
            };
          this.serviceservice.prclbmain(catalogDetails).subscribe({
            next: (value: LbMainData) => {
              // Handle the response here
              lbmainid = value.data
              const fileData = {
                userid: SelfData.account[0].data[0].id,
                accountid: this.getAccountID(),
                filename: "",
                filedir: "",
                ctrl: 1,
                lbcatalogid: 0,
                lbmainid: lbmainid,
                description: this.collectionFormControl.value?.trim()
              };
              this.serviceservice.prclbcimage(fileData).subscribe({
                next: (value) => {
                  this.selectedImageFiles.forEach((filedate) => {
                    const fileData = {
                      userid: SelfData.account[0].data[0].id,
                      accountid: this.getAccountID(),
                      filename: filedate.filename,
                      filedir: filedate.url,
                      ctrl: 0,
                      lbcatalogid: filedate.lbcatalogid,
                      lbmainid: lbmainid,
                      description: this.collectionFormControl.value?.trim()
                    };

                    this.serviceservice.prclbcimage(fileData).subscribe({
                      next: (value) => {

                      },
                      error: (error: any) => {
                        console.error('Error occurred:', error);
                      },
                      complete: () => {
                        // console.log('Subscription completed');
                      }
                    });
                  })
                  this.yourappsService.getReloadCLBlist()
                  this.modalService.closevcframe()
                },
                error: (error: any) => {
                  console.error('Error occurred:', error);
                },
                complete: () => {
                  // console.log('Subscription completed');
                }
              });

            },
            error: (error: any) => {
              console.error('Error occurred:', error);
            },
            complete: () => {
            }
          });
      },
      reject: (type: any) => {
        if (type == ConfirmEventType.REJECT) {

        } else if (type == ConfirmEventType.CANCEL) {
        }
      }

    });
    setTimeout(() => {
      this.applyLocalRestoreStyles();
    }, 0);

  }

  doSomethingAfterDelay() {
    console.log("Timer finished!");
  }

  private applyLocalRestoreStyles() {
    setTimeout(() => {
      const messageElements = document.querySelectorAll('.p-confirm-dialog-message');
      messageElements.forEach((element) => {
        element.setAttribute('style', 'font-size: 16px; color: #343a40;');
      });
    }, 0);

    const iconElements = document.querySelectorAll('.p-confirm-dialog-icon');
    iconElements.forEach((element) => {
      element.setAttribute('style', 'color: #343a40');
    });
  }
}
