import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import {DOCUMENT} from "@angular/common";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MessageService} from "primeng/api";
import {ServiceService} from "../../services/service.service";
import {HashService} from "../../services/hash.service";
import { HttpClient } from "@angular/common/http";
import {NavigationEnd, NavigationError, Router} from "@angular/router";
import {DeviceDetectorService} from "ngx-device-detector";
import {ModalserviceService} from "../../services/modalservice.service";
import {GetLBCollections, GetLBDesigners, SelfData} from "../../models/struct";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {Subscription} from "rxjs";


@Component({
  selector: 'app-catalogs',
  templateUrl: './catalogs.component.html',
  styleUrls: ['./catalogs.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0 }), animate(300)]),
      transition(':leave', [animate(300, style({ opacity: 0 }))]),
    ]),
  ],
})
export class CatalogsComponent implements OnInit, OnDestroy, AfterViewInit {
  isDesktopDevice: boolean = this.deviceService.isDesktop();
  deviceClass: string = "";
  footerClass: string = "";
  deviceClassAddress: string = "";
  isLandscape: boolean = false;
  private subscription?: Subscription
  cardsArray = new Array(6).fill(null);

  constructor(private renderer: Renderer2, @Inject(DOCUMENT) private document: Document,
              private yourappsService: YourappsserviceService,
              private messageService: MessageService,
              private serviceservice: ServiceService,
              private hashService: HashService,
              private http: HttpClient,
              private router: Router,
              private ngZone: NgZone,
              private changeDetector: ChangeDetectorRef,
              private deviceService: DeviceDetectorService, public modalService: ModalserviceService) {
    this.setDeviceClass();
  }

  setDeviceClass(): void {
    this.deviceClass = this.deviceService.isMobile() ? 'mobilemaincard' : 'pcmaincard';
    this.footerClass = this.deviceService.isMobile() ? 'mobilebutton-container-dm-ps' : 'pcbutton-container-dm-ps';
    this.deviceClassAddress = this.deviceService.isMobile() ? 'mobileaddressdiv' : 'pcaddressdiv';
    // this.isLandscape = window.innerWidth > window.innerHeight ? 'islandscape' : 'isnlandscape';
  }

  iLBCollectionResult: GetLBCollections[] = [];

  ngAfterViewInit(): void {
    const lbmainid = SelfData?.tlbmainid;
    const designerid = SelfData?.tdesignerid;
    if (lbmainid !== undefined && designerid !== undefined) {
      this.serviceservice.selectlbcollections({lbmainid, desingerid: designerid})
        .subscribe((value) => {
          if (value) {
            this.iLBCollectionResult.push(value);
            SelfData.lbcollections = this.iLBCollectionResult;
          }
          // Consider handling else case
        });
    } else {
      // Handle case where lbmainid or designerid is undefined
    }
  }

  ngOnDestroy(): void {
    this.iLBCollectionResult = []
    SelfData.lbcollections[0].data = []
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  setOrientation() {
    this.isLandscape = window.innerWidth > window.innerHeight;
  }

  ngOnInit(): void {
    this.renderer.setStyle(this.document.body, 'background-color', 'rgba(150,150,150,0.5)');
    this.setOrientation();
    window.addEventListener('resize', this.setOrientation.bind(this))

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.changeDetector.detectChanges();
      }
    });

    document.addEventListener('DOMContentLoaded', function () {
      const element = document.getElementById('myElement');

      if (element) {
        // When the element gains focus
        element.addEventListener('focus', function () {
          // Apply any specific styles you want on focus
          // For example, if you want to keep the hover styles
          this.style.backgroundColor = '#d9d9d9'; // Same as hover
          this.style.color = '#000000'; // Adjust as needed
        }, true);

        // When the element loses focus
        element.addEventListener('blur', function () {
          // Revert to original styles
          this.style.backgroundColor = '#ffffff';
          this.style.color = '#000000';
        }, true);
      } else {
        console.error('Element with ID "myElement" was not found.');
      }
    });
  }

  onCollectionClick(item: any) {
    SelfData.tlbcatalogid = item.catalogid
    SelfData.tlbmainid = item.lbmainid
    this.router.navigate(['/imageviews'], {
      queryParams: {
        reload: true
      }
    });
  }

  onButtonFavoriteClick() {
    this.subscription = this.yourappsService.favoriteadded.subscribe((value) => {
      if (this.subscription) {
        this.subscription.unsubscribe();
        this.messageService.add({
          key: 'tc',
          severity: 'success',
          summary: 'Success',
          detail: 'You will get notifications for the new Look Books from this designer.',
          life: 5000,
        });
      }
    })
    this.modalService.openfavorite()
  }

  protected readonly SelfData = SelfData;
}
