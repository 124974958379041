import { Component } from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";

@Component({
  selector: 'app-createlbframe',
  templateUrl: './createlbframe.component.html',
  styleUrls: ['./createlbframe.component.scss']
})
export class CreatelbframeComponent {
  constructor(public modalService: ModalserviceService) {}
}
