import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import {CarouselComponent} from "smart-webcomponents-angular/carousel";
import {DOCUMENT} from "@angular/common";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {ConfirmationService, MessageService} from "primeng/api";
import {ServiceService} from "../../services/service.service";
import {HashService} from "../../services/hash.service";
import { HttpClient } from "@angular/common/http";
import {Router} from "@angular/router";
import {DeviceDetectorService} from "ngx-device-detector";
import {ModalserviceService} from "../../services/modalservice.service";
import {GetLBAccepts, SelfData} from "../../models/struct";
import {MatCheckboxChange} from "@angular/material/checkbox";

@Component({
  selector: 'app-imageviewsaccepted',
  templateUrl: './imageviewsaccepted.component.html',
  styleUrls: ['./imageviewsaccepted.component.scss']
})
export class ImageviewsacceptedComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('carousel', {read: CarouselComponent, static: false}) carousel!: CarouselComponent;
  isDesktopDevice: boolean = this.deviceService.isDesktop();
  isVisibled: boolean = false;
  isChecked: boolean = false;
  isDisabled = false;
  checkboxcaption: string = "Select";
  pageindex: number = 0
  selectedImages: {
    index: number;
    file: File | null;
    url: string;
    filename: string;
    lbcatalogid: number;
    colection: string;
    designer: string;
    catalog: string;
    catalogyear: number;
    seasoncode: string;
    oldchecked: boolean;
  }[] = []
  selectedImageFiles: {
    file: File | null;
    url: string;
    filename: string;
    lbcatalogid: number;
    colection: string;
    designer: string;
    catalog: string;
    catalogyear: number;
    seasoncode: string;
  }[] = [];

  filteredImages: {
    index: number;
    file: File | null;
    url: string;
    filename: string;
    lbcatalogid: number;
    colection: string;
    designer: string;
    catalog: string;
    catalogyear: number;
    seasoncode: string;
    oldchecked: boolean;
  }[] = [];

  constructor(private renderer: Renderer2, @Inject(DOCUMENT) private document: Document,
              private yourappsService: YourappsserviceService,
              private messageService: MessageService,
              private serviceservice: ServiceService,
              private hashService: HashService,
              private http: HttpClient,
              private router: Router,
              private cdr: ChangeDetectorRef,
              private confirmationService: ConfirmationService,
              private deviceService: DeviceDetectorService, public modalService: ModalserviceService) {
    this.setDeviceClass();
    // this.loadImageList(SelfData.tlbcatalogid)
  }

  setDeviceClass(): void {
    this.deviceClass = this.deviceService.isMobile() ? 'mobilemaincard' : 'pcmaincard';
    this.footerClass = this.deviceService.isMobile() ? 'mobilebutton-container-dm-ps' : 'pcbutton-container-dm-ps';
    this.deviceClassAddress = this.deviceService.isMobile() ? 'mobileaddressdiv' : 'pcaddressdiv';
    // this.isLandscape = window.innerWidth > window.innerHeight ? 'islandscape' : 'isnlandscape';
  }

  deviceClass: string = "";
  footerClass: string = "";
  deviceClassAddress: string = "";
  isLandscape: boolean = false;
  hidden: boolean = true;
  iLBAcceptsResult: GetLBAccepts[] = [];

  findAlreadyImageByIndex(index: number) {
    index = Number(index);
    let lbcatalogid = Number(this.selectedImageFiles[index].lbcatalogid);
    let filename = this.selectedImageFiles[index].filename;
    const imageIndex = this.selectedImages.findIndex(image => image.filename === filename && image.lbcatalogid === lbcatalogid && image.oldchecked);
    console.log("imageIndex", imageIndex)
    return this.selectedImages.find(image => image.filename === filename && image.lbcatalogid === lbcatalogid && image.oldchecked);
  }

  findImageByIndex(index: number) {
    index = Number(index);
    let lbcatalogid = Number(this.selectedImageFiles[index].lbcatalogid);
    let filename = this.selectedImageFiles[index].filename;
    return this.selectedImages.find(image => image.filename === filename && image.lbcatalogid === lbcatalogid);
  }

  removeImageByIndex(index: number) {
    index = Number(index);
    let lbcatalogid = Number(this.selectedImageFiles[index].lbcatalogid);
    let filename = this.selectedImageFiles[index].filename;
    // Find the index of the image in the selectedImages array
    const imageIndex = this.selectedImages.findIndex(image => image.filename === filename && image.lbcatalogid === lbcatalogid);

    if (imageIndex !== -1) {
      // If the image is found, remove it using splice
      this.selectedImages.splice(imageIndex, 1);
    }
  }

  ngAfterViewInit(): void {
    this.selectedImages = []
    this.pageindex = 0
    this.carousel.addEventListener('changing', (event: Event) => {
      // Use type assertion to assert the event as a CustomEvent
      const customEvent = event as CustomEvent;
      const detail = customEvent.detail,
        index = detail.index,
        previousIndex = detail.previousIndex;
      this.pageindex = index

      console.log(this.pageindex)
      if (this.findImageByIndex(index)) {
        this.isChecked = true
        this.cdr.detectChanges()
        if (this.findAlreadyImageByIndex(index)) {
          this.checkboxcaption = "Already sent"
          this.isDisabled = true
        } else {
          this.checkboxcaption = "Remove"
          this.isDisabled = false
        }
      } else {
        this.isChecked = false
        this.checkboxcaption = "Select"
        this.isDisabled = false
      }
    });
  }

  ngOnDestroy(): void {
  }

  setOrientation() {
    this.isLandscape = window.innerWidth > window.innerHeight;
    window.addEventListener("orientationchange", function () {
      if (screen.orientation) {
        (screen.orientation as any).lock("portrait-primary").catch(function (error: any) {
          console.log("Orientation lock not allowed:", error);
        });
      }
    })
  }

  ngOnInit(): void {
    this.renderer.setStyle(this.document.body, 'background-color', 'rgba(150,150,150,0.5)');
    this.setOrientation();
    window.addEventListener('resize', this.setOrientation.bind(this))
  }

    onCheckboxChange(event: MatCheckboxChange) {
        if (event.checked) {
            this.checkboxcaption = "Remove"
            this.isDisabled = false
            const index = this.pageindex;
            const selectedFile = this.selectedImageFiles[index];

            if (selectedFile) {
                const imageUrl = selectedFile.url;
                const imagefilename = selectedFile.filename;
                const lbcatalogid = selectedFile.lbcatalogid;
                const catalog = selectedFile.catalog;
                const catalogyear = selectedFile.catalogyear;
                const colection = selectedFile.colection;
                const designer = selectedFile.designer;
                const seasoncode = selectedFile.seasoncode;

                this.selectedImages.push({
                    index: index,
                    filename: imagefilename,
                    lbcatalogid: lbcatalogid,
                    url: imageUrl,
                    catalog: catalog,
                    catalogyear: catalogyear,
                    colection: colection,
                    designer: designer,
                    file: null,
                    seasoncode: seasoncode,
                    oldchecked: false
                });

                this.selectedImages = this.selectedImages.map(item => ({
                    ...item,
                    index: Number(item.index) // Convert index to a number
                }));
            } else {
                // Handle the scenario where selectedFile is undefined
                console.error('Selected file not found at index', index);
            }
            this.messageService.add({
                key: 'tc',
                severity: 'success',
                summary: 'Success',
                detail: 'Item added to list',
                life: 2000,
            });
        } else {
            this.checkboxcaption = "Select"
            this.removeImageByIndex(this.pageindex)
            this.messageService.add({
                key: 'tc',
                severity: 'success',
                summary: 'Success',
                detail: 'Item removed from list',
                life: 2000,
            });
        }

        this.hidden = this.selectedImages.length === 0
        // You can now use event.checked, which is a boolean indicating the checkbox's state
    }

    onButtonUploadClick() {

    }

    onButtonViewClick() {

    }
}
