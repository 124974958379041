import { Component, OnInit } from '@angular/core';
import { ModalserviceService } from '../../services/modalservice.service';
import { YourappsserviceService } from '../../services/yourappsservice.service';
import {SelfData, transformImageUrl} from '../../models/struct';
import {MessageService} from "primeng/api";

@Component({
  selector: 'app-profiledialog',
  templateUrl: './profiledialog.component.html',
  styleUrls: ['./profiledialog.component.scss'],
})
export class ProfiledialogComponent implements OnInit {
  accountname = '';
  mycode = '';
  imageUrl: string = '';
  constructor(
    public modalService: ModalserviceService,
    private yourappsService: YourappsserviceService,
    private messageService: MessageService
  ) {
    this.yourappsService.reloadAvatarImage.subscribe(() => {
      this.LoadAvatarImage();
    });
  }

  ngOnInit(): void {
    this.mycode = SelfData.account[0].data[0].mycode
    this.yourappsService.accountname$.subscribe((account) => {
      this.accountname = account;
    });
    this.LoadAvatarImage();
  }

  LoadAvatarImage() {
    const timestamp = new Date().getTime(); // Get the current timestamp
    if (SelfData.account[0]?.data[0]?.imageLink) {
      this.imageUrl = transformImageUrl(`${SelfData.account[0].data[0].imageLink}?${timestamp}`); // serveri
      // this.imageUrl = `${SelfData.account[0].data[0].imageLink}?${timestamp}`; // lokali
    } else {
      this.imageUrl = ''; // Set imageUrl to an empty string if imageLink is undefined
    }
  }

  onButtonCopyClick() {
    if (!navigator.clipboard) {
      // Fallback for browsers where clipboard API is not available
      const textArea = document.createElement('textarea');
      textArea.value = "My Referral Code: " + this.mycode;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        document.execCommand('copy');
        this.showMessage('success', 'Referal Code successfully copied to clipboard');
      } catch (err) {
        this.showMessage('error', 'Could not copy Referal Code: ' + err);
      }

      document.body.removeChild(textArea);
    } else {
      navigator.clipboard.writeText("My Referral Code: " + this.mycode).then(() => {
        this.showMessage('success', 'Referal Code successfully copied to clipboard');
      }).catch(err => {
        this.showMessage('error', 'Could not copy Referal Code to clipboard: ' + err);
      });
    }
  }

  showMessage(severity: string, detail: string) {
    this.messageService.add({
      key: 'tc',
      severity: severity,
      summary: 'Information',
      detail: detail,
      life: 3000,
    });
  }

  onCancelAccountClick() {
    window.open('https://fm.welcomeapps.net/Contact.html', '_blank');
  }
}
