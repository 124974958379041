<p-toast *ngIf="!isDesktopDevice" position="top-center" key="tc" [style]="{ width: '90vw' }"></p-toast>
<p-toast *ngIf="isDesktopDevice" position="top-center" key="tc" [style]="{ width: '30vw' }"></p-toast>
<button mat-icon-button type="button" class="close-btn" (click)="onButtonCloseClick()">
    <mat-icon>close</mat-icon>
</button>
<p class="sample-manager-text">View selected items</p>

<smart-carousel *ngIf="!isDesktopDevice" theme="fm" class="carousel bottom-top" #carousel
        [class.mobile]="true" [autoPlay]="true" [slideShow]="false" [loop]="true" [hideIndicators]="false"
        [keyboard]="false" [displayMode]="'3d'" [interval]="10000"></smart-carousel>
<smart-carousel *ngIf="isDesktopDevice" theme="fm" class="carousel bottom-top" #carousel
        [class.imageselect]="true" [autoPlay]="true" [slideShow]="false" [loop]="true"
        [hideIndicators]="false"
        [keyboard]="false" [displayMode]="'3d'" [interval]="10000"></smart-carousel>

<div *ngIf="!isDesktopDevice" class="button-container-left">
    <mat-checkbox (change)="onCheckboxChange($event)" [class.onlyimageview]="true" [(ngModel)]="isChecked"
            [disabled]="isDisabled">
        {{ checkboxcaption }}
    </mat-checkbox>
</div>

<div *ngIf="isDesktopDevice" class="checkbox">
    <mat-checkbox (change)="onCheckboxChange($event)" [class.onlyimageview]="true" [(ngModel)]="isChecked"
            [disabled]="isDisabled">
        {{ checkboxcaption }}
    </mat-checkbox>
</div>

<div *ngIf="isDesktopDevice" [class.imageselect]="true" class="button-container">
    <button mat-raised-button (click)="onButtonConfirmClick()"
           class="button-settings" type="button">
        Confirm
    </button>
</div>

<div *ngIf="!isDesktopDevice" [class.mobile]="true" class="button-container">
    <button mat-raised-button (click)="onButtonConfirmClick()"
            class="button-settings" type="button">
        Confirm
    </button>
</div>

<p-confirmDialog *ngIf="isDesktopDevice && showdialog" #cd [style]="{width: '25vw'}" [closable]="true">
    <ng-template pTemplate="footer">
        <div class="button-container-rr">
            <button mat-raised-button class="button-secondary" style="margin-right: 10px; width: 10%"
                    (click)="cd.reject()">
                No
            </button>
            <button mat-raised-button class="button-primary" style="width: 10%" (click)="cd.accept()">
                Yes
            </button>
        </div>
    </ng-template>
</p-confirmDialog>

<p-confirmDialog *ngIf="!isDesktopDevice && showdialog" #cd [style]="{width: '90vw'}" [closable]="true">
    <ng-template pTemplate="footer">
        <div class="button-container-rr">
            <button mat-raised-button class="button-secondary" style="margin-right: 10px; width: 10%"
                    (click)="cd.reject()">
                No
            </button>
            <button mat-raised-button class="button-primary" style="width: 10%" (click)="cd.accept()">
                Yes
            </button>
        </div>
    </ng-template>
</p-confirmDialog>
