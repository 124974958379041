<button mat-icon-button type="button" class="close-btn" (click)="modalService.closeSSO()">
    <mat-icon>close</mat-icon>
</button>
<p class="sample-manager-text-rrc">Select SendOut</p>

<div class="centered-div">
    <mat-card class="custom-mat-card1">
        <div class="black-background">
            <!--        1Line-->
        </div>

        <mat-card-content class="truncate-text">
            <!-- Card content for single cell -->
            <!-- {{ newsItem.content }} -->
        </mat-card-content>
        <!--      <div class="button-container">-->

        <!--      </div>-->
        <button mat-raised-button class="view-more-btn1" (click)="onButtonSendOutSearchClick()">
            <mat-icon class="icon-spacing icon-scale">search</mat-icon>
            Search
        </button>
        <mat-divider class="card-bottom-divider-sso"
        ></mat-divider>
    </mat-card>

    <table class="full-width-tableEmpty">
        <tr>
            <td></td>
            <td></td>
        </tr>
    </table>
<!--    <div class="centered-container">-->
<!--        <app-samplelistheader-->
<!--                *ngIf="modalService.isSampleHeader$ | async"-->
<!--        ></app-samplelistheader>-->
<!--    </div>-->

    <div class="centered-container1">
        <app-samplelisttable
                *ngIf="modalService.isSampleLabel$ | async"
        ></app-samplelisttable>
    </div>

    <div class="centered-container2">
        <app-samplelistgrid
                *ngIf="modalService.isSampleGrid$ | async"
        ></app-samplelistgrid>
    </div>

    <div class="centered-container4">
        <app-sendoutlist
                *ngIf="modalService.isSendoutList$ | async"
        ></app-sendoutlist>
    </div>
</div>
