<button mat-icon-button type="button" class="close-btn" (click)="onButtonCloseClick()">
    <mat-icon>close</mat-icon>
</button>
<p class="sample-manager-text">{{formcaption}}</p>
<div class="row">
    <div class="forma-group">
        <mat-form-field class="selector-full-width" appearance="fill">
            <mat-label>Designer</mat-label>
            <input type="text" matInput placeholder="Select or type..." [matAutocomplete]="auto2"
                    [formControl]="desingerFormControl" #autoInput2="matAutocompleteTrigger"/>
            <mat-autocomplete #auto2="matAutocomplete" (optionSelected)="onDesignerSelected($event)">
                <mat-option *ngFor="let item of filteredDesignerOptions | async" [value]="item">
                    {{ item }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>

    <div class="forma-group">
        <mat-form-field class="selector-full-width" appearance="fill">
            <mat-label>Description</mat-label>
            <input type="text" matInput [formControl]="collectionFormControl" [readonly]="editDisabled">
            <mat-error *ngIf="collectionFormControl.hasError('required')">
                Description is <strong>required</strong>
            </mat-error>
        </mat-form-field>
    </div>
</div>
<smart-carousel theme="fm" class="carousel bottom-top" #carousel [class.imageselect]="true" id="carouselSquare" [autoPlay]="true" [slideShow]="false" [loop]="true" [hideIndicators]="false"
        [keyboard]="false" [displayMode]="'3d'" [interval]="10000"></smart-carousel>
<div class="checkbox">
    <p *ngIf="isVisibled"><mat-checkbox (change)="onCheckboxChange($event)" [(ngModel)]="isChecked">{{checkboxcaption}}</mat-checkbox></p>
</div>

<div class="button-container">
    <button mat-raised-button class="button-settings" type="button" (click)="onButtonUploadClick()">
        Confirm
    </button>
</div>

<span class="button-container-left">Total selected ({{ selectedImages.length }})</span>

<p-confirmDialog *ngIf="showdialog" #cd [style]="{width: '25vw'}" [closable]="true">
    <ng-template pTemplate="footer">
        <div class="button-container-rr">
            <button mat-raised-button class="button-secondary" style="margin-right: 10px; width: 10%" (click)="cd.reject()">
                No
            </button>
            <button mat-raised-button class="button-primary" style="width: 10%" (click)="cd.accept()">
                Yes
            </button>
        </div>
    </ng-template>
</p-confirmDialog>
