import { Component } from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";

@Component({
  selector: 'app-createlb',
  templateUrl: './createlb.component.html',
  styleUrls: ['./createlb.component.scss']
})
export class CreatelbComponent {
  constructor(public modalService: ModalserviceService) {}
}
