import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appCustomMask]'
})
export class CustomMaskDirective {

  constructor(public ngControl: NgControl) { }

  @HostListener('input', ['$event'])
  onInputChange(event: any) {
    let input = event.target.value.replace(/\D/g, ''); // Remove all non-digit characters
    const length = input.length;

    if (length <= 2) { // Handle month part
      input = input;
    } else if (length > 2) { // Handle year part with "/"
      input = input.substring(0, 2) + '/' + input.substring(2, 6);
    }

    // Safely update the input field
    if (this.ngControl.control) {
      this.ngControl.control.setValue(input, { emitEvent: false });
    }
  }
}
