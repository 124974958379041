import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import {HashService} from "../../services/hash.service";
import {MessageboxComponent} from "../../modals/messagebox/messagebox.component";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SelfData, SetSampleListData} from "../../models/struct";

@Component({
  selector: 'app-barcodeprintsingle',
  templateUrl: './barcodeprintsingle.component.html',
  styleUrls: ['./barcodeprintsingle.component.scss']
})
export class BarcodeprintsingleComponent implements OnInit, AfterViewInit {
  myForm: FormGroup = new FormGroup({});
  constructor(
      public modalService: ModalserviceService,
      public fb: FormBuilder,
      private hashService: HashService,
      public messageBox: MessageboxComponent,
      private serviceservice: ServiceService,
      private yourappsService: YourappsserviceService,
      private _snackBar: MatSnackBar,
      private cd: ChangeDetectorRef,
      private el: ElementRef, private renderer: Renderer2
  ) {

  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
  }

  submit() {

  }

  onButtonPrintClick() {
    const el = document.getElementById('scrollable-div-dm');
    if (!el) {
      console.error('Element not found!');
      return;
    }

    const clonedEl = el.cloneNode(true) as HTMLElement;

    // Create and append the iframe
    const iframe = document.createElement('iframe');
    document.body.appendChild(iframe);

    // Dynamically load the printer CSS
    const printerStyleLink = document.createElement('link');
    printerStyleLink.rel = 'stylesheet';
    printerStyleLink.href = '/assets/barcodeprint.component.css';

    console.log("printerStyleLink.href", printerStyleLink.href)
    iframe.contentWindow!.document.head.appendChild(printerStyleLink);

    // Append the element you want to print to the iframe
    iframe.contentWindow!.document.body.appendChild(clonedEl);

    // Print after a brief timeout to allow styles to load
    setTimeout(() => {
      iframe.contentWindow!.focus();
      iframe.contentWindow!.print();
      document.body.removeChild(iframe);
    }, 1000);
  }

  protected readonly SelfData = SelfData;
}
