import {AfterViewInit, ChangeDetectorRef, Component, NgZone, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import {HashService} from "../../services/hash.service";
import {MessageboxComponent} from "../../modals/messagebox/messagebox.component";
import {ServiceService} from "../../services/service.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MatTableDataSource} from "@angular/material/table";
import {SelfData, SetSampleListData} from "../../models/struct";

@Component({
  selector: 'app-invoicedialog',
  templateUrl: './invoicedialog.component.html',
  styleUrls: ['./invoicedialog.component.scss']
})
export class InvoicedialogComponent implements OnInit, AfterViewInit {
  myForm: FormGroup;
  @ViewChild(MatPaginator) paginator: MatPaginator | null = null;
  discounts: number[] = Array.from({length: 96}, (_, i) => i);
  selectedDiscount: number = 0;
  enteredPrice: number = 0.00;
  percentDiscount: number = 0.00;
  itemcount: number = 0;
  lastYearTotal!: number;
  formattedValues: string[] = Array(SelfData.setSelectedSample.length).fill("$0.00");
  displayedColumns: string[] = [
    'Designer',
    'Type',
    'NewField',
    'Description',
    'Image',
    'finalprice',
    'discount',
    'final',
  ];

  sizes!: any[];

  selectedSize: any = '';

  dataSource = new MatTableDataSource<SetSampleListData>(
    SelfData.setSelectedSample
  );

  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private hashService: HashService,
    public messageBox: MessageboxComponent,
    private serviceservice: ServiceService,
    private _snackBar: MatSnackBar,
    private yourappsService: YourappsserviceService,
    private ngZone: NgZone,
    private cd: ChangeDetectorRef
  ) {
    this.myForm = new FormGroup({});
    this.sizes = [
      {name: 'Small', class: 'p-datatable-sm'}
    ];
    this.selectedSize = this.sizes[0]

    this.dataSource = new MatTableDataSource<SetSampleListData>(
      SelfData.setSelectedSample
    );
  }

  public get setdataSource() {
    return this.dataSource;
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.calculateThisYearTotal()
  }

  ngOnInit(): void {
  }

  submit() {
    // Your form submission logic
  }

  updateFinalValue(event: any, sample: SetSampleListData): void {
    if (event.target.value  < 0) {
      this.enteredPrice = 0;  // Set the value to the minimum limit
      sample["amount"] = 0
    } else {
      this.enteredPrice = event.target.value;
    }
    let discountAmount: number = 0
    if (sample["discount"]) {
      discountAmount = (sample["discount"] / 100) * this.enteredPrice;
    } else {
      discountAmount = 0;
    }
    const calculatedValue = this.enteredPrice - discountAmount;
    sample["amount"] = this.enteredPrice
    // sample["discount"] = discountAmount
    sample["totalprice"] = calculatedValue;
    this.calculateThisYearTotal()
  }

  defaultDiscountChanged(event: any, sample: SetSampleListData): void {
    // this.enteredPrice = event.target.value;
    if (event.target.value  < 0) {
      this.enteredPrice = 0;  // Set the value to the minimum limit
    } else if (event.target.value  > 100) {
      this.enteredPrice = 100;  // Set the value to the maximum limit
    } else {
      this.enteredPrice = event.target.value
    }
    console.log("this.enteredPrice", this.enteredPrice, event.target.value)
    const discountAmount = (this.enteredPrice / 100) * sample["amount"];
    const calculatedValue = sample["amount"] - discountAmount;
    // this.finalValue = calculatedValue.toFixed(2);
    sample["discount"] = this.enteredPrice
    sample["totalprice"] = calculatedValue;
    // let newValue: number = parseFloat(sample["totalprice"].replace(/[^\d.-]/g, '')); // Convert the formatted value to a number
    // if (!isNaN(newValue)) {
    //   this.formattedValues[index] = <string>this.currencyPipe.transform(newValue, 'USD', 'symbol', '1.2-2');
    //   event.target.value = this.formattedValues[index]; // Update the input's displayed value
    // }
    this.calculateThisYearTotal()
  }

  calculateThisYearTotal() {
    let total = 0;
    for (let sale of SelfData.setSelectedSample) {
      if (sale["totalprice"]) {
        total += sale["totalprice"];
      }
    }
    this.lastYearTotal = total;
    this.cd.detectChanges();
  }

  onButtonInvoiceCreateClick(){
    this.yourappsService.getInvoiceCreateSend()
    this.modalService.invoiceClose()
  }

  protected readonly SelfData = SelfData;
}
