import { Component } from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";

@Component({
  selector: 'app-createvi',
  templateUrl: './createvi.component.html',
  styleUrls: ['./createvi.component.scss']
})
export class CreateviComponent {
  constructor(public modalService: ModalserviceService) {}
}
