import {AfterViewInit, ChangeDetectorRef, Component, NgZone, OnInit} from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder} from "@angular/forms";
import {HashService} from "../../services/hash.service";
import {MessageboxComponent} from "../../modals/messagebox/messagebox.component";
import {ServiceService} from "../../services/service.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MatTableDataSource} from "@angular/material/table";
import {GetSendOutSamples, PullSheetData, PullSheetDetails, SelfData, SetSampleListData} from "../../models/struct";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {ActivatedRoute} from "@angular/router";
import {ConfirmationService, ConfirmEventType, MessageService} from "primeng/api";

@Component({
  selector: 'app-selectpullsheet',
  templateUrl: './selectpullsheet.component.html',
  styleUrls: ['./selectpullsheet.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0 }), animate(300)]),
      transition(':leave', [animate(300, style({ opacity: 0 }))]),
    ]),
  ],
})
export class SelectpullsheetComponent implements AfterViewInit, OnInit {
  selectedElement: any;
  constructor(
      public modalService: ModalserviceService,
      public fb: FormBuilder,
      private hashService: HashService,
      public messageBox: MessageboxComponent,
      private serviceservice: ServiceService,
      private _snackBar: MatSnackBar,
      private yourappsService: YourappsserviceService,
      private ngZone: NgZone,
      private route: ActivatedRoute,
      private messageService: MessageService,
      private confirmationService: ConfirmationService,
      private cdRef: ChangeDetectorRef
  ) {

  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
  }

  iSendOutSample: GetSendOutSamples[] = [];
  onButtonViewItems(item: any) {
    this.serviceservice
        .getsendoutsaples({ sendoutid:  item.sendoutid})
        .subscribe((value) => {
          this.iSendOutSample[0] = value;
          SelfData.sendoutsamples = this.iSendOutSample
          this.modalService.openSSL()
          console.log("SelfData.sendoutsamples", SelfData.sendoutsamples)
          // SelfData.addres = this.iAddressResult;
        });
  }

  onButtonViewPullSheetClick(item: any) {
    const key = item.key;
    const externalUrl = `http://108.178.189.70:6336/pullsheet?key=${key}`;

    window.open(externalUrl, "_blank");
    // const key = item.key;
    // const url = `http://108.178.189.70:6336/pullsheet?key=${key}`;
    // window.location.href = url;  // This will navigate to the external URL
  }

  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
        ? SelfData.account[0].data[0].id
        : SelfData.account[0].data[0].parentid;
  }

  removeItemByKey(targetKey: string): void {
    for (let item of SelfData.selectpullsheetlist) {
      item.data = item.data.filter(dataItem => dataItem.key !== targetKey);
    }
  }

  onButtonDeleteClick(item: any | null) {
    this.confirmationService.confirm({
      message: `<strong>Are you sure you want to remove Pull Sheet?</strong>`,
      header: 'Delete Pull Sheet',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        let key = item.key
        const data: PullSheetDetails = {
          email: "",
          subject: "" ,
          message: "",
          key: key,
          accountid: this.getAccountID(),
          userid: SelfData.account[0].data[0].id,
          sendoutid: 0,
          status: 0,
          isactivity: false,
          ctrl: 3
        };
        this.removeItemByKey(key)
        this.serviceservice.pullSheet(data).subscribe({
          next: (response: PullSheetData) => {
            console.log(response);
            this.messageService.add({
              key: 'tc',
              severity: 'error',
              summary: 'Success',
              detail: 'Pull Sheet successfully removed',
              life: 10000,
            });
          },
          error: error => {
            console.error('Error:', error);
          }
        });
      },
      reject: (type: any) => {
        if (type == ConfirmEventType.REJECT) {

        } else if (type == ConfirmEventType.CANCEL) {

        }
      }
    });
  }

  extractDateFromDateObject(date: string | Date): string {
    let d: Date;

    if (typeof date === 'string') {
      d = new Date(date);
    } else if (date instanceof Date) {
      d = date;
    } else {
      throw new Error('Invalid date format');
    }

    const year = d.getUTCFullYear();
    const month = (d.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = d.getUTCDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  protected readonly SelfData = SelfData;
}
