import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2, ViewChild
} from '@angular/core';
import {
  DesignerAccountDetails,
  Getlbdesignerlist,
  GetlbdesignerlistData,
  GetLBMain,
  GetLBMainData, Getlbseasonlist, GetlbseasonlistData,
  GettodList, LbCatalogData, LbCatalogDetails, LbMainData, LbMainDetails, LbSelectedDesigner, LbSelectedSeasonRequest,
  SelfData, SetSampleListData
} from "../../models/struct";
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ConfirmationService, ConfirmEventType, MessageService} from "primeng/api";
import {NgxImageCompressService} from "ngx-image-compress";
import { HttpClient } from "@angular/common/http";
import {DOCUMENT} from "@angular/common";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {ProgressBarComponent} from "smart-webcomponents-angular/progressbar";
// import { PdfImageExtractoService } from '../../services/pdf-image-extracto.service';

@Component({
  selector: 'app-createlbframedialog',
  templateUrl: './createlbframedialog.component.html',
  styleUrls: ['./createlbframedialog.component.scss']
})
export class CreatelbframedialogComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('progressbar', {read: ProgressBarComponent, static: false}) progressbar!: ProgressBarComponent;
  formcaption: string = "Add New"
  selectedItem: any = null;
  clickedItem: any = null;
  datasource: GetlbdesignerlistData[] = [];
  clickedStates: { [designerid: number]: boolean } = {};
  ilDesignerListResult: Getlbdesignerlist[] = [{Code: 0, ErrorMessage: '', data: []}];
  iTOResult: Getlbdesignerlist[] = [{Code: 0, ErrorMessage: '', data: []}];
  iSelectedItem: Getlbdesignerlist[] = [{Code: 0, ErrorMessage: '', data: []}];
  iSeasonFromResult: Getlbseasonlist[] = [{Code: 0, ErrorMessage: '', data: []}];
  iSeasonToResult: Getlbseasonlist[] = [{Code: 0, ErrorMessage: '', data: []}];
  imageUrls: string[] = [];
  collectionFormControl = new FormControl({value: '', disabled: false}, [
    Validators.required,
  ]);
  progressbarshow: boolean = false;
  previewUrl: string = '';
  uploadbuttonenable: boolean = true
  private progressbarpossition: number = 0;

  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
      ? SelfData.account[0].data[0].id
      : SelfData.account[0].data[0].parentid;
  }

  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private serviceservice: ServiceService,
    private yourappsService: YourappsserviceService,
    private _snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef,
    private messageService: MessageService,
    private imageCompressService: NgxImageCompressService,
    private http: HttpClient,
    private confirmationService: ConfirmationService,
    // private pdfExtractor: PdfImageExtractoService,
    private renderer: Renderer2, @Inject(DOCUMENT) private document: Document,
    private el: ElementRef
  ) {
    this.serviceservice
      .selectlbdesignerlist({accountid: this.getAccountID()})
      .subscribe((value) => {
        this.ilDesignerListResult[0] = value;
        SelfData.lbdesignerlist = this.ilDesignerListResult;
        this.datasource = SelfData.lbdesignerlist[0].data
      });
  }

  // async processPdf(pdfFile: File) {
  //   const pdfData = await pdfFile.arrayBuffer();
  //   this.imageUrls = await this.pdfExtractor.extractImagesFromPdf(pdfData);
  // }

  onButtonSelectAllClick() {
    this.confirmationService.confirm({
      message: `<strong>Select All Look Books from All Designers?</strong>`,
      header: 'Select All',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.iTOResult[0].data = []
        this.iSeasonToResult[0].data = []
        this.progressbarpossition = 0;
        this.progressbarshow = true;
        this.cdr.detectChanges();
        this.ilDesignerListResult[0].data.forEach((designerinfo) => {
          this.iTOResult[0].data.push(designerinfo)
        })
        if (this.iTOResult[0].data.length > 0) {
          this.progressbar.max = this.iTOResult[0].data.length;
          this.iTOResult[0].data.forEach((seldesignerinfo) => {
            this.progressbarpossition++
            this.progressbar.value = this.progressbarpossition
            this.serviceservice
              .selectlbseasonlist({accountid: this.getAccountID(), desingerid: seldesignerinfo.desingerid})
              .subscribe((value) => {
                this.iSeasonFromResult[0] = value;
                this.iSeasonFromResult[0].data.forEach((seldesignerinfo) => {
                  this.iSeasonToResult[0].data.push(seldesignerinfo)
                })
              });
            if (this.progressbarpossition === this.progressbar.max) {
              console.log(this.progressbarpossition, this.progressbar.max)
              setTimeout(() => {
                this.progressbarshow = false;
                this.progressbarpossition = 0;
                this.iSeasonFromResult[0].data = [];

                // Log or additional actions can be done here after properties are updated
                console.log("Properties updated after 1 second");
              }, 1000); // 1000 milliseconds = 1 second
            }
          })
          //
        }
      },
      reject: (type: any) => {
        if (type == ConfirmEventType.REJECT) {

        } else if (type == ConfirmEventType.CANCEL) {
        }
      }

    });
    setTimeout(() => {
      this.applyLocalRestoreStyles();
    }, 0);

  }

  // drop(event: CdkDragDrop<any>) {
  //   if (event.previousContainer === event.container) {
  //     moveItemInArray(
  //       this.ilDesignerListResult[0].data,
  //       event.previousIndex,
  //       event.currentIndex
  //     );
  //   } else {
  //     this.iTOResult[0].data,
  //       event.previousIndex,
  //       event.currentIndex
  //   }
  // }

  // drop(event: CdkDragDrop<GetlbdesignerlistData[], any>): void {
  //   if (!event.container.data || !event.previousContainer.data) {
  //     return; // exit function if data is undefined
  //   }
  //
  //   if (event.previousContainer === event.container) {
  //     moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
  //   } else {
  //     transferArrayItem(
  //       event.previousContainer.data,
  //       event.container.data,
  //       event.previousIndex,
  //       event.currentIndex
  //     );
  //   }
  // }

  drop(event: CdkDragDrop<GetlbdesignerlistData[]>): void {
    // Ensure both the source and destination data are actually arrays
    const previousContainerData: GetlbdesignerlistData[] = event.previousContainer.data instanceof Array ? event.previousContainer.data : [];
    const containerData: GetlbdesignerlistData[] = event.container.data instanceof Array ? event.container.data : [];

    if (event.previousContainer === event.container) {
      // Reordering within the same list
      moveItemInArray(containerData, event.previousIndex, event.currentIndex);
    } else {
      // Transferring between different lists
      this.transferArrayItem(previousContainerData, containerData, event.previousIndex, event.currentIndex);
    }
  }

  transferArrayItem(sourceArray: GetlbdesignerlistData[], targetArray: GetlbdesignerlistData[], fromIndex: number, toIndex: number) {
    if (!Array.isArray(sourceArray) || !Array.isArray(targetArray)) {
      console.log('Error: Attempting to transfer items between non-array types.');
      return;
    }
    const [item] = sourceArray.splice(fromIndex, 1);
    targetArray.splice(toIndex, 0, item);
  }

  dropseason(event: CdkDragDrop<GetlbseasonlistData[]>): void {
    // Ensure both the source and destination data are actually arrays
    const previousContainerData: GetlbseasonlistData[] = event.previousContainer.data instanceof Array ? event.previousContainer.data : [];
    const containerData: GetlbseasonlistData[] = event.container.data instanceof Array ? event.container.data : [];

    if (event.previousContainer === event.container) {
      // Reordering within the same list
      moveItemInArray(containerData, event.previousIndex, event.currentIndex);
    } else {
      // Transferring between different lists
      this.transferArrayItemSeason(previousContainerData, containerData, event.previousIndex, event.currentIndex);
    }
  }

  transferArrayItemSeason(sourceArray: GetlbseasonlistData[], targetArray: GetlbseasonlistData[], fromIndex: number, toIndex: number) {
    if (!Array.isArray(sourceArray) || !Array.isArray(targetArray)) {
      console.log('Error: Attempting to transfer items between non-array types.');
      return;
    }
    const [item] = sourceArray.splice(fromIndex, 1);
    targetArray.splice(toIndex, 0, item);
  }

  onclick(item: any, index: number) {
    this.yourappsService.setImageList(item, 0)
    this.modalService.openivframe()
  }

  onDoubleclick(item: GetlbseasonlistData, index: number) {
    this.confirmationService.confirm({
      message: `<strong>Are you sure you want to delete this catalog?</strong>`,
      header: 'Delete Catalog',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        if (index >= 0 && index < this.iSeasonToResult[0].data.length) {
          // Remove the item at the given index
          this.iSeasonToResult[0].data.splice(index, 1);
          // If using Angular, you may need to update the view manually:
          // this.cdr.detectChanges(); // Uncomment if using ChangeDetectorRef
        } else {
          console.error('Index out of bounds');
        }
      },
      reject: (type: any) => {
        if (type == ConfirmEventType.REJECT) {

        } else if (type == ConfirmEventType.CANCEL) {
        }
      }

    });
    setTimeout(() => {
      this.applyLocalStyles();
    }, 0);
  }

  private applyLocalStyles() {
    setTimeout(() => {
      const messageElements = document.querySelectorAll('.p-confirm-dialog-message');
      messageElements.forEach((element) => {
        element.setAttribute('style', 'font-size: 16px; color: red;');
      });
    }, 0);

    const iconElements = document.querySelectorAll('.p-confirm-dialog-icon');
    iconElements.forEach((element) => {
      element.setAttribute('style', 'color: red');
    });
  }


// The 'source' and 'target' should already be of type GetlbdesignerlistData[]
//   transferArrayItem(source: GetlbdesignerlistData[], target: GetlbdesignerlistData[], fromIndex: number, toIndex: number) {
//     if (!Array.isArray(source) || !Array.isArray(target)) {
//       // One of the arrays is not actually an array, handle this case appropriately
//       console.error('One of the drag containers does not have a valid array.');
//       return;
//     }
//
//     console.log("source", source)
//     console.log("target", target)
//
//     const [item] = source.splice(fromIndex, 1);
//     target.splice(toIndex, 0, item);
//   }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    console.log("SelfData.lbmode", SelfData.lbmode)
    if (SelfData.lbmode === 0) {
      this.collectionFormControl.setValue(SelfData.lbdescription)
      // this.serviceservice
      //     .selectlbselecteddesignerlist({accountid: this.getAccountID(), lbmainid: 0})
      //     .subscribe((value) => {
      //         this.iTOResult[0] = value;
      //     });
      //
      // this.serviceservice
      //     .selectlbselectedseasonlist({accountid: this.getAccountID(), lbmainid: 0})
      //     .subscribe((value) => {
      //         this.iSeasonToResult[0] = value;
      //     });
    } else {
      this.collectionFormControl.setValue(SelfData.lbdescription)
      this.serviceservice
        .selectlbselecteddesignerlist({accountid: this.getAccountID(), lbmainid: SelfData.lbmode})
        .subscribe((value) => {
          this.iTOResult[0] = value;
        });

      this.serviceservice
        .selectlbselectedseasonlist({accountid: this.getAccountID(), lbmainid: SelfData.lbmode})
        .subscribe((value) => {
          this.iSeasonToResult[0] = value;
          console.log(value)
        });
    }
  }

  onitemclick(item: any) {
    this.selectedItem = item;
    this.serviceservice
      .selectlbseasonlist({accountid: this.getAccountID(), desingerid: item.desingerid})
      .subscribe((value) => {
        this.iSeasonFromResult[0] = value;
      });

  }

  onButtonCloseClick() {
    this.modalService.closelbframe()
  }

  protected readonly SelfData = SelfData;

  getAllDesignerNames(): string {
    return this.iTOResult[0].data.map(d => d.designer).join(', ');
  }

  onButtonUploadClick() {
    if (this.collectionFormControl.value?.trim() === "") {
      this.messageService.add({
        key: 'tc',
        severity: 'error',
        summary: 'Information',
        detail: 'Please indicate the catalog description.',
        life: 10000,
      });
      return
    }
    if (this.iTOResult[0].data.length === 0) {
      this.messageService.add({
        key: 'tc',
        severity: 'error',
        summary: 'Information',
        detail: 'You haven\'t selected any designer.',
        life: 10000,
      });
      return
    }
    if (this.iSeasonToResult[0].data.length === 0) {
      this.messageService.add({
        key: 'tc',
        severity: 'error',
        summary: 'Information',
        detail: 'You haven\'t selected any catalog.',
        life: 10000,
      });
      return
    }
    this.confirmationService.confirm({
      message: `<strong>Save selected data?</strong>`,
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        var lbmainid: number = 0
        var catalogDetails: LbMainDetails
        if (SelfData.lbmode === 0) {
          catalogDetails = {
            id: 0,
            description: this.collectionFormControl.value?.trim() || "",
            fields: this.getAllDesignerNames() || "",
            accountid: this.getAccountID(),
            isactivity: true,
            ctrl: 0,
            lbtype: 0
          };
        } else {
          catalogDetails = {
            id: SelfData.lbmode,
            description: this.collectionFormControl.value?.trim() || "",
            fields: this.getAllDesignerNames() || "",
            accountid: this.getAccountID(),
            isactivity: true,
            ctrl: 1,
            lbtype: 0
          };
        }
        this.serviceservice.prclbmain(catalogDetails).subscribe({
          next: (value: LbMainData) => {
            // Handle the response here
            lbmainid = value.data
            const designerDetails: DesignerAccountDetails = {
              designer: "",
              designerid: 0,
              accountid: this.getAccountID(),
              lbmainid: lbmainid,
              ctrl: 1
            };
            this.serviceservice.prcLbSelectedDesigner(designerDetails).subscribe({
              next: (value: LbSelectedDesigner) => {
                this.iTOResult[0].data.forEach((designerinfo) => {
                  const designerSubDetails: DesignerAccountDetails = {
                    designer: designerinfo.designer,
                    designerid: designerinfo.desingerid,
                    accountid: this.getAccountID(),
                    lbmainid: lbmainid,
                    ctrl: 0
                  };
                  this.serviceservice.prcLbSelectedDesigner(designerSubDetails).subscribe({
                    next: (value: LbSelectedDesigner) => {

                    },
                    error: (error: any) => {
                      console.error('Error occurred:', error);
                    },
                    complete: () => {
                      // console.log('Subscription completed');
                    }
                  });
                });

                //addcatalog
                const selCatalogDetails: LbSelectedSeasonRequest = {
                  description: "",
                  seasoncode: "",
                  seasonyear: 0,
                  accountid: this.getAccountID(),
                  desingerid: 0,
                  catalogid: 0,
                  lbmainid: lbmainid,
                  ctrl: 1,
                  id: 0
                };

                this.serviceservice.prcLbSelectedSeason(selCatalogDetails).subscribe({
                  next: (value: LbSelectedDesigner) => {
                    this.iSeasonToResult[0].data.forEach((cataloginfo) => {
                      const selSubCatalogDetails: LbSelectedSeasonRequest = {
                        description: cataloginfo.description,
                        seasoncode: cataloginfo.seasoncode,
                        seasonyear: cataloginfo.seasonyear,
                        accountid: this.getAccountID(),
                        desingerid: cataloginfo.desingerid,
                        catalogid: cataloginfo.catalogid,
                        lbmainid: lbmainid,
                        ctrl: 0,
                        id: 0
                      };
                      this.serviceservice.prcLbSelectedSeason(selSubCatalogDetails).subscribe({
                        next: (value: LbSelectedDesigner) => {

                        },
                        error: (error: any) => {
                          console.error('Error occurred:', error);
                        },
                        complete: () => {
                          // console.log('Subscription completed');
                        }
                      });
                    });
                    //End Process
                    this.yourappsService.getReloadCLBlist()
                    this.modalService.closelbframe()
                  },
                  error: (error: any) => {
                    console.error('Error occurred:', error);
                  },
                  complete: () => {
                  }
                });
              },
              error: (error: any) => {
                console.error('Error occurred:', error);
              },
              complete: () => {
              }
            });
          },
          error: (error: any) => {
            console.error('Error occurred:', error);
          },
          complete: () => {
          }
        });
      },
      reject: (type: any) => {
        if (type == ConfirmEventType.REJECT) {

        } else if (type == ConfirmEventType.CANCEL) {
        }
      }

    });
    setTimeout(() => {
      this.applyLocalRestoreStyles();
    }, 0);
  }

  private applyLocalRestoreStyles() {
    setTimeout(() => {
      const messageElements = document.querySelectorAll('.p-confirm-dialog-message');
      messageElements.forEach((element) => {
        element.setAttribute('style', 'font-size: 16px; color: #343a40;');
      });
    }, 0);

    const iconElements = document.querySelectorAll('.p-confirm-dialog-icon');
    iconElements.forEach((element) => {
      element.setAttribute('style', 'color: #343a40');
    });
  }

  isDisabled(item: any): boolean {
    // Add your condition here to determine if the item should disable the span
    return true; // or false based on your condition
  }

  onButtonSelectClick(item: any) {
    this.iTOResult[0].data.push(item)
  }

  onButtonSelectCatalogClick(item: any) {
    this.iSeasonToResult[0].data.push(item)
  }

  isItemFoundInITOrResult(item: any): boolean {
    return this.iTOResult[0].data.some(it => it.desingerid === item.desingerid);
  }

  isItemSelected(item: any): boolean {
    this.iSelectedItem[0].data = []
    this.iSelectedItem[0].data = [item]
    return this.iSelectedItem[0].data.some(it => it.desingerid === item.desingerid);
  }

  isItemFoundIniSeasonToResult(item: any): boolean {
    return this.iSeasonToResult[0].data.some(it => (it.desingerid === item.desingerid && it.catalogid === item.catalogid));
  }

  isClicked(item: any): boolean {
    return this.clickedStates[item.designerid]; // Check if the item has been clicked
  }

  removeItemsWithDesignerIdOne(desingerid: number): void {
    this.iSeasonToResult.forEach(list => {
      list.data = list.data.filter(item => item.desingerid !== desingerid);
    });
  }

  onRemoveClick(item: any, index: number) {
    console.log("iTOResult[0].data", item)
    console.log("iSeasonToResult[0].data", this.iSeasonToResult[0].data)
    this.removeItemsWithDesignerIdOne(item.desingerid)
    this.iTOResult[0].data.splice(index, 1);
  }

  onCatalogRemoveClick(item: any, index: number) {
    this.iSeasonToResult[0].data.splice(index, 1);
  }
}
