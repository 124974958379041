import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  NgZone, OnDestroy,
  OnInit,
} from '@angular/core';
import { ModalserviceService } from '../../services/modalservice.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { HashService } from '../../services/hash.service';
import { MessageboxComponent } from '../../modals/messagebox/messagebox.component';
import { ServiceService } from '../../services/service.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { YourappsserviceService } from '../../services/yourappsservice.service';
import {
  GetBarcodeList,
  GetCollectionList,
  GetDesignerList,
  GetFabricList,
  GetImageList,
  GetManuList,
  GetMiscList,
  GetNotSettings,
  GetRegionList,
  GetSeasonList,
  GetSizeRangeList,
  GetStyleList,
  GetSWSList,
  GetSystemFields,
  GetTypeList,
  SelfData, SetCustomFieldsValue,
  SetSampleList,
  SetSampleListData, Speener,
} from '../../models/struct';
import { MatTableDataSource } from '@angular/material/table';
import {map, Observable, startWith, Subscription} from "rxjs";
import lookup from "socket.io-client";

interface InventoryStatus {
  value: string;
  viewValue: string;
}
interface Style {
  value: string;
  viewValue: string;
}

interface Gender {
  value: string;
  viewValue: string;
}

interface Desinger {
  value: string;
  viewValue: string;
}
interface Barcode {
  value: string;
  viewValue: string;
}
interface Fabric {
  value: string;
  viewValue: string;
}

interface Period {
  value: string;
  viewValue: string;
}

interface ColorArray {
  colorname: string;
  colorcode: string;
}

interface AvailabilityStatus {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-inventarydialog',
  templateUrl: './inventarydialog.component.html',
  styleUrls: ['./inventarydialog.component.scss'],
})
export class InventarydialogComponent implements AfterViewInit, OnInit, OnDestroy {
  myForm: FormGroup;
  samplewait = false
  departmentFormControl = new FormControl({ value: '', disabled: true }, [
    Validators.required,
  ]);
  emailFormControl = new FormControl('');

  firstnameFormControl = new FormControl('');

  inventarystatysFormControl = new FormControl('', [Validators.required]);
  selectedValueInventaryStatys: string | undefined;
  selectedValueStyle: string | undefined;
  selectedValueGender: string | undefined;
  selectedValueDesinger: string | undefined;
  selectedValueBarcode: string | undefined;
  selectedValueFabric: string | undefined;
  selectedValuePeriod: string | undefined;
  AvailabilitySselectedValuetatus: string | undefined;

  samplemanager = false;
  contactmanager = false;
  sendoutmanager = false;
  iNotSettingsResult: GetNotSettings[] = [];
  inventorystatus: InventoryStatus[] = [
    // {value: 'Add New Record', viewValue: 'Add New Record'},
    { value: 'Inventory', viewValue: 'Inventory' },
    { value: 'Imported', viewValue: 'Imported' },
  ];

  style: Style[] = [
    { value: '(Empty/None)', viewValue: '(Empty/None)' },
    { value: 'Value1', viewValue: 'Value1' },
    { value: 'Value2', viewValue: 'Value2' },
  ];

  gender: Gender[] = [
    { value: '(Empty/None)', viewValue: '(Empty/None)' },
    { value: 'Man', viewValue: 'Man' },
    { value: 'Unisex', viewValue: 'Unisex' },
    { value: 'Women', viewValue: 'Women' },
  ];

  desinger: Desinger[] = [
    { value: '(Empty/None)', viewValue: '(Empty/None)' },
    { value: 'Value1', viewValue: 'Value1' },
    { value: 'Value2', viewValue: 'Value2' },
  ];

  barcode: Barcode[] = [
    { value: '(Empty/None)', viewValue: '(Empty/None)' },
    { value: 'Value1', viewValue: 'Value1' },
    { value: 'Value2', viewValue: 'Value2' },
  ];

  fabric: Fabric[] = [
    { value: '(Empty/None)', viewValue: '(Empty/None)' },
    { value: 'Value1', viewValue: 'Value1' },
    { value: 'Value2', viewValue: 'Value2' },
  ];

  period: Period[] = [
    // { value: '(Empty/None)', viewValue: '(Empty/None)' },
    { value: 'The last week', viewValue: 'The last week' },
    { value: 'Last month', viewValue: 'Last month' },
    { value: 'Last 3 months', viewValue: 'Last 3 months' },
    { value: 'Last 6 months', viewValue: 'Last 6 months' },
  ];

  availabilitystatus: AvailabilityStatus[] = [
    { value: '(Empty/None)', viewValue: '(Empty/None)' },
    { value: 'Not Marked On Loan', viewValue: 'Not Marked On Loan' },
    { value: 'Marked On Loan', viewValue: 'Marked On Loan' },
  ];
  desingerFormControl = new FormControl({value: '', disabled: false}, []);
  collectionFormControl = new FormControl({value: '', disabled: false}, []);
  seasonFormControl = new FormControl({value: '', disabled: false}, []);
  typeFormControl = new FormControl({value: '', disabled: false}, []);
  colorControl = new FormControl({value: '', disabled: false}, []);
  sizeControl = new FormControl({value: '', disabled: false}, []);
  fabricControl = new FormControl({value: '', disabled: false}, []);
  genderControl = new FormControl({value: '', disabled: false}, []);

  iSystemFields: GetSystemFields[] = [];
  iDesignerResult: GetDesignerList[] = [];
  iCollection: GetCollectionList[] = [];
  iSeason: GetSeasonList[] = [];
  iType: GetTypeList[] = [];
  iSize: GetTypeList[] = [];
  iFabricResult: GetFabricList[] = [];

  iSWSResult: GetSWSList[] = [];
  iRegionResult: GetRegionList[] = [];
  iManuResult: GetManuList[] = [];
  iStyle: GetStyleList[] = [];
  iMisc: GetMiscList[] = [];
  iPattern: GetTypeList[] = [];
  iImages: GetImageList[] = [];
  iSizeRange: GetSizeRangeList[] = [];
  iBarcode: GetBarcodeList[] = [];
  iCFV: SetCustomFieldsValue[] = [];

  designerptions: string[] = [];
  collectionoptions: string[] = [];
  seasonoptions: string[] = [];
  typeoptions: string[] = [];
  sizeoptions: string[] = [];
  fabricoptions: string[] = [];

  colorarray: ColorArray[] = [
    {colorname: 'White', colorcode: '#ffffff'},
    {colorname: 'Black ', colorcode: '#000000'},
    {colorname: 'Yellow', colorcode: '#FFFF00'},
    {colorname: 'Purple', colorcode: '#800080'},
    {colorname: 'Orange ', colorcode: '#FFA500'},
    {colorname: 'Blue ', colorcode: '#2196f3'},
    {colorname: 'Light Blue ', colorcode: '#03a9f4'},
    {colorname: 'Red', colorcode: '#ff0000'},
    {colorname: 'Buff', colorcode: '#DAA06D'},
    {colorname: 'Gray ', colorcode: '#808080'},
    {colorname: 'Green ', colorcode: '#00FF00'},
    {colorname: 'Pink', colorcode: '#FFC0CB'},
    {colorname: 'Violet', colorcode: '#8F00FF'},
    {colorname: 'Brown', colorcode: '#964B00'},
    {colorname: 'Silver', colorcode: '#C0C0C0'},
    {colorname: 'Cream', colorcode: '#FFFDD0'},
    {colorname: 'Gold', colorcode: '#FFD700'},
    {colorname: 'Beige', colorcode: '#F5F5DC'},
    {colorname: 'Navy', colorcode: '#202A44'},
  ];

  styleControl = new FormControl<string>('');
  miscControl = new FormControl<string>('');
  filteredOptions?: Observable<string[]>;
  filteredRegionOptions?: Observable<string[]>;
  filteredManuOptions?: Observable<string[]>;
  filteredStyleOptions?: Observable<string[]>;
  filteredMisceOptions?: Observable<string[]>;
  filteredPatternOptions?: Observable<string[]>;
  filteredSizeRangeOptions?: Observable<string[]>;
  filteredBarcodeOptions?: Observable<string[]>;

  filteredDesignerOptions?: Observable<string[]>;
  filteredCollectionOptions?: Observable<string[]>;
  filteredSeasonOptions?: Observable<string[]>;
  filteredTypeOptions?: Observable<string[]>;
  filteredSizeOptions?: Observable<string[]>;
  filteredFabricOptions?: Observable<string[]>;

  formControls: {[key: string]: FormControl} = {
    desingerFormControl: this.desingerFormControl,
    collectionFormControl: this.collectionFormControl,
    seasonFormControl: this.seasonFormControl,
    typeFormControl: this.typeFormControl,
    colorControl: this.colorControl,
    sizeControl: this.sizeControl,
    fabricControl: this.fabricControl,
    genderControl: this.genderControl,
  };
  showButton: { [key: string]: boolean } = {
    desingerFormControl: false,
    collectionFormControl: false,
    seasonFormControl: false,
    typeFormControl: false,
    colorControl: false,
    sizeControl: false,
    fabricControl: false,
    genderControl: false
  };

  myControl = new FormControl<string>('');
  myRegionControl = new FormControl<string>('');
  notesRegionControl = new FormControl<string>('');
  manuControl = new FormControl<string>('');
  associatedControl = new FormControl<string>('');
  sizerangeControl = new FormControl<string>('');
  barcodeControl = new FormControl<string>('');
  descriptionControl = new FormControl<string>('');
  patternControl = new FormControl<string>('');
  quantityControl = new FormControl('1', [
    Validators.required,
    Validators.min(1),
    Validators.pattern('^[0-9]*$'),
  ]);
  priceControl = new FormControl('0', [
    Validators.required,
    Validators.min(0),
    Validators.pattern('^\\d*(\\.\\d+)?$'),
  ]);
  price1Control = new FormControl('0', [
    Validators.required,
    Validators.min(0),
    Validators.pattern('^\\d*(\\.\\d+)?$'),
  ]);
  price2Control = new FormControl('0', [
    Validators.required,
    Validators.min(0),
    Validators.pattern('^\\d*(\\.\\d+)?$'),
  ]);
  price3Control = new FormControl('0', [
    Validators.required,
    Validators.min(0),
    Validators.pattern('^\\d*(\\.\\d+)?$'),
  ]);
  price4Control = new FormControl('0', [
    Validators.required,
    Validators.min(0),
    Validators.pattern('^\\d*(\\.\\d+)?$'),
  ]);
  price5Control = new FormControl('0', [
    Validators.required,
    Validators.min(0),
    Validators.pattern('^\\d*(\\.\\d+)?$'),
  ]);

  options: string[] = [];
  regionoptions: string[] = [];
  manuoptions: string[] = [];
  styleoptions: string[] = [];
  miscoptions: string[] = [];
  patternoptions: string[] = [];
  sizerangeoptions: string[] = [];
  barcodeoptions: string[] = [];
  cfvoptions: string[] = [];
  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private hashService: HashService,
    public messageBox: MessageboxComponent,
    private serviceservice: ServiceService,
    private _snackBar: MatSnackBar,
    private yourappsService: YourappsserviceService,
    private ngZone: NgZone,
    private cdRef: ChangeDetectorRef
  ) {
    this.samplewait = false
    SelfData.waitpanel = true
    this.myForm = new FormGroup({});

    this.departmentFormControl.setValue(SelfData.account[0].data[0].company);
    var accountid: number = 0;
    if (SelfData.account[0].data[0].parentid == 0) {
      accountid = SelfData.account[0].data[0].id;
    } else {
      accountid = SelfData.account[0].data[0].parentid;
    }

    // // SwsList
    // this.serviceservice
    //     .setSWSList({ accountid: accountid })
    //     .subscribe((swsvalue) => {
    //       this.iSWSResult[0] = swsvalue;
    //       SelfData.sws = this.iSWSResult;
    //       this.filteredOptions = this.setDataAndFilter(
    //           this.myControl,
    //           swsvalue,
    //           this.options,
    //           0
    //       );
    //     });
    //
    // // RegionList
    // this.serviceservice
    //     .setRegionList({ accountid: accountid })
    //     .subscribe((regionvalue) => {
    //       this.iRegionResult[0] = regionvalue;
    //       SelfData.region = this.iRegionResult;
    //       this.filteredRegionOptions = this.setDataAndFilter(
    //           this.myRegionControl,
    //           regionvalue,
    //           this.regionoptions,
    //           1
    //       );
    //     });
    // // DesignerList
    // this.serviceservice
    //     .setDesignerList({ accountid: accountid })
    //     .subscribe((desingervalue) => {
    //       this.iDesignerResult[0] = desingervalue;
    //       SelfData.designer = this.iDesignerResult;
    //       this.filteredDesignerOptions = this.setDataAndFilter(
    //           this.desingerFormControl,
    //           desingervalue,
    //           this.designerptions,
    //           2
    //       );
    //     });
    // // FabricList
    // this.serviceservice
    //     .setFabricList({ accountid: accountid })
    //     .subscribe((fabricvalue) => {
    //       this.iFabricResult[0] = fabricvalue;
    //       SelfData.fabric = this.iFabricResult;
    //       this.filteredFabricOptions = this.setDataAndFilter(
    //           this.fabricControl,
    //           fabricvalue,
    //           this.fabricoptions,
    //           3
    //       );
    //     });
    // // ManuList
    // this.serviceservice
    //     .setManuList({ accountid: accountid })
    //     .subscribe((manuvalue) => {
    //       this.iManuResult[0] = manuvalue;
    //       SelfData.manu = this.iManuResult;
    //       this.filteredManuOptions = this.setDataAndFilter(
    //           this.manuControl,
    //           manuvalue,
    //           this.manuoptions,
    //           4
    //       );
    //     });
    // // CollectionList
    //
    // this.serviceservice
    //     .setCollectionList({ accountid: accountid })
    //     .subscribe((collectionvalue) => {
    //       this.iCollection[0] = collectionvalue;
    //       SelfData.collection = this.iCollection;
    //       this.filteredCollectionOptions = this.setDataAndFilter(
    //           this.collectionFormControl,
    //           collectionvalue,
    //           this.collectionoptions,
    //           5
    //       );
    //     });
    // // SeasonList
    // this.serviceservice
    //     .setSeasonList({ accountid: accountid })
    //     .subscribe((seasonvalue) => {
    //       this.iSeason[0] = seasonvalue;
    //       SelfData.season = this.iSeason;
    //       this.filteredSeasonOptions = this.setDataAndFilter(
    //           this.seasonFormControl,
    //           seasonvalue,
    //           this.seasonoptions,
    //           6
    //       );
    //     });
    // // StyleList
    // this.serviceservice
    //     .setStyleList({ accountid: accountid })
    //     .subscribe((value) => {
    //       this.iStyle[0] = value;
    //       SelfData.style = this.iStyle;
    //       this.filteredStyleOptions = this.setDataAndFilter(
    //           this.styleControl,
    //           value,
    //           this.styleoptions,
    //           7
    //       );
    //     });
    // // MisceList
    // this.serviceservice
    //     .setMiscList({ accountid: accountid })
    //     .subscribe((value) => {
    //       this.iMisc[0] = value;
    //       SelfData.misc = this.iMisc;
    //       this.filteredMisceOptions = this.setDataAndFilter(
    //           this.miscControl,
    //           value,
    //           this.miscoptions,
    //           9
    //       );
    //     });
    // // TypeList
    // this.serviceservice
    //     .setTypeList({ accountid: accountid })
    //     .subscribe((value) => {
    //       this.iType[0] = value;
    //       SelfData.type = this.iType;
    //       this.filteredTypeOptions = this.setDataAndFilter(
    //           this.typeFormControl,
    //           value,
    //           this.typeoptions,
    //           10
    //       );
    //     });
    // //sizeList
    // this.serviceservice
    //     .setSizeList({ accountid: accountid })
    //     .subscribe((value) => {
    //       this.iSize[0] = value;
    //       SelfData.size = this.iSize;
    //       this.filteredSizeOptions = this.setDataAndFilter(
    //           this.sizeControl,
    //           value,
    //           this.sizeoptions,
    //           11
    //       );
    //     });
    // //patternList
    // this.serviceservice
    //     .setPatternList({ accountid: accountid })
    //     .subscribe((value) => {
    //       this.iPattern[0] = value;
    //       SelfData.pattern = this.iPattern;
    //       this.filteredPatternOptions = this.setDataAndFilter(
    //           this.patternControl,
    //           value,
    //           this.patternoptions,
    //           12
    //       );
    //     });
    // //sizerangeList
    // this.serviceservice
    //     .setSizeRangeList({ accountid: accountid })
    //     .subscribe((value) => {
    //       this.iSizeRange[0] = value;
    //       SelfData.sizerange = this.iSizeRange;
    //       this.filteredSizeRangeOptions = this.setDataAndFilter(
    //           this.sizerangeControl,
    //           value,
    //           this.sizerangeoptions,
    //           13
    //       );
    //     });
    //
    // //barcodelist
    // this.serviceservice
    //     .setBarcodeList({ accountid: accountid })
    //     .subscribe((value) => {
    //       this.iBarcode[0] = value;
    //       SelfData.barcode = this.iBarcode;
    //       this.filteredBarcodeOptions = this.setDataAndFilter(
    //           this.barcodeControl,
    //           value,
    //           this.barcodeoptions,
    //           14
    //       );
    //     });

    this.serviceservice
        .setCFVList({
          accountid: accountid,
          framename: 'createsampledialog',
          sampleid: 0,
        })
        .subscribe((value) => {
          this.iCFV[0] = value;
          SelfData.cfv = this.iCFV;
        });

    // this.serviceservice.setSampleList({ accountid: accountid, page: 1, pageSize: 100000 }).subscribe({
    //   next: (value) => {
    //     if (value) {
    //     } else {
    //       console.log('The response is empty or undefined.');
    //     }
    //   },
    //   error: (error) => {
    //     console.error('Error occurred during the subscription:', error);
    //   },
    // });

    this.serviceservice
        .setNotSettings({ accountid: accountid })
        .subscribe((value) => {
          this.iNotSettingsResult[0] = value;
          SelfData.notificationsettings = this.iNotSettingsResult;
        });

    // this.serviceservice.setSampleList({ accountid: accountid, page: 1, pageSize: 100000 }).subscribe({
    //   next: (value) => {
    //     if (value) {
    //       this.iSampleDataResult[0] = value;
    //       SelfData.samplelookdata = this.iSampleDataResult;
    //       // console.log(SelfData.samplelookdata)
    //     } else {
    //       console.log('The response is empty or undefined.');
    //     }
    //   },
    //   error: (error) => {
    //     console.error('Error occurred during the subscription:', error);
    //   },
    // });

    // this.serviceservice
    //   .getSystemFieldsList({ accountid: accountid, framename: "createsampledialog" })
    //   .subscribe((value) => {
    //     this.iSystemFields[0] = value;
    //     SelfData.systemfields = this.iSystemFields;
    //     console.dir(SelfData.systemfields)
    //   });
    this.yourappsService.reloadSampleList.subscribe((description) => {
      var accountid: number = 0;
      if (SelfData.account[0].data[0].parentid == 0) {
        accountid = SelfData.account[0].data[0].id;
      } else {
        accountid = SelfData.account[0].data[0].parentid;
      }
      // this.serviceservice.setSampleList({ accountid: accountid, page: 1, pageSize: 100000 }).subscribe({
      //   next: (value) => {
      //     if (value) {
      //       this.iSampleDataResult[0] = value;
      //       SelfData.sampledata = this.iSampleDataResult;
      //       this.yourappsService.getReloadSampleTableList();
      //     } else {
      //       console.log('The response is empty or undefined.');
      //     }
      //   },
      //   error: (error) => {
      //     console.error('Error occurred during the subscription:', error);
      //   },
      // });
    });
  }

  private setDataAndFilter(
      control: FormControl,
      response: any,
      options: string[],
      type: number
  ) {
    let results = response.data;
    for (let data of results) {
      options.push(data.description);
    }
    return control.valueChanges.pipe(
        startWith(''),
        map((value) => this._filter(value || '', type))
    );
  }

  private _filter(value: string, type: number): string[] {
    const filterValue = value.toLowerCase();

    // Map the type to the corresponding options array
    const optionsMap: { [key: number]: string[] } = {
      1: this.regionoptions,
      2: this.designerptions,
      3: this.fabricoptions,
      4: this.manuoptions,
      5: this.collectionoptions,
      6: this.seasonoptions,
      7: this.styleoptions,
      9: this.miscoptions,
      10: this.typeoptions,
      11: this.sizeoptions,
      12: this.patternoptions,
      13: this.sizerangeoptions,
      14: this.barcodeoptions,
    };

    if (optionsMap[type]) {
      return optionsMap[type].filter((option) =>
          option.toLowerCase().includes(filterValue)
      );
    } else {
      console.warn(`Unexpected filter type: ${type}`);
      return [];
    }
  }

  ngOnDestroy(): void {
    if (this.subscription111) {
      this.subscription111?.unsubscribe()
    }

  }

  ngAfterViewInit(): void {
    // Speener.samplevisiblemode = true
    // var accountid: number = 0;
    // if (SelfData.account[0].data[0].parentid == 0) {
    //   accountid = SelfData.account[0].data[0].id;
    // } else {
    //   accountid = SelfData.account[0].data[0].parentid;
    // }
    // this.serviceservice.setSampleList({ accountid: accountid }).subscribe({
    //   next: (value) => {
    //     if (value) {
    //       this.Speener.samplevisiblemode = false
    //       this.iSampleDataResult[0] = value;
    //       SelfData.sampledata = this.iSampleDataResult;
    //       this.modalService.createInventoryLabel();
    //     } else {
    //       console.log('The response is empty or undefined.');
    //     }
    //   },
    //   error: (error) => {
    //     console.error('Error occurred during the subscription:', error);
    //   },
    // });
  }

  private subscription111?: Subscription;
  private selDesigner: string = ""

  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
        ? SelfData.account[0].data[0].id
        : SelfData.account[0].data[0].parentid;
  }

  filterByDesigner(data: SetSampleListData[], designerName: string): SetSampleListData[] {
    return data.filter(item => item?.["Designer"] === designerName);
  }

  // loadSampleDataRecursively(page: number, pageSize: number) {
  //   this.serviceservice.setSampleList({ accountid: this.getAccountID(), page, pageSize, forceRefresh: true }).subscribe({
  //     next: (value) => {
  //       Speener.samplevisiblemode = false;
  //       if (value && value.data && value.data.length > 0) {
  //         // Предполагаем, что iSampleDataResult должен хранить массив объектов SetSampleListData
  //         this.iSampleDataResult[0].data = [...this.iSampleDataResult, ...value.data];
  //
  //         SelfData.sampledata[0].data = this.iSampleDataResult
  //         // console.log("this.iSampleDataResult", SelfData.sampledata[0].data)
  //         this.yourappsService.getReloadSampleTableList();
  //         if (value.data.length < pageSize) {
  //           console.log('All data has been loaded');
  //         } else {
  //           this.loadSampleDataRecursively(page + 1, pageSize);
  //         }
  //       } else {
  //         console.log('The response is empty or undefined.');
  //       }
  //     },
  //     error: (error) => {
  //       Speener.samplevisiblemode = false;
  //       console.error('Error occurred:', error);
  //     }
  //   });
  // }

  ngOnInit(): void {
    Speener.samplevisiblemode = true;
    this.modalService.closeInventoryLabel();

    this.subscription111 = this.yourappsService.seldesigner.subscribe(disvalue => {
      console.log("disvalue", disvalue[1]);

      if (disvalue[0]?.["Designer"]) {
        console.log(disvalue[0]?.["Designer"]);
        this.selDesigner = disvalue[0]?.["Designer"];
        this.loadDataByBatches(this.getAccountID(), disvalue[0]?.["Designer"]);
      } else {
        let accountid: number = SelfData.account[0].data[0].parentid === 0
          ? SelfData.account[0].data[0].id
          : SelfData.account[0].data[0].parentid;
        this.loadDataByBatches(accountid, '', disvalue[1]);
      }
    });
  }

  private loadDataByBatches(accountId: number, designer: string = '', disvalue?: any[]): void {
    const batchSize: number = 3000;
    let currentPage: number = 1;
    let processedIds = new Set<number>();

    // Инициализируем данные
    SelfData.sampledata = [{ Code: 200, ErrorMessage: '', data: [] }];

    SelfData.sampledata[0].data = []
    this.samplewait = true
    SelfData.waitpanel = true
    const loadBatch = () => {
      this.serviceservice.setSampleList({
        accountid: accountId,
        page: currentPage,
        pageSize: batchSize,
        forceRefresh: true,
        samplestyle: SelfData.samplestyle
      }).subscribe({
        next: (response) => {
          if (response && response.data && response.data.length > 0) {
            let filteredData = response.data.filter(item => !processedIds.has(item['ID']));

            // Добавляем уникальные ID в processedIds
            filteredData.forEach(item => processedIds.add(item['ID']));

            // Преобразуем данные
            const newData = filteredData.map(item => {
              const match = disvalue?.find((sample: { ID: any }) => sample.ID === item['ID']);
              const itemstatus = this.booleanToInt(match);
              return {
                "ID": item["ID"],
                "Inventory Status": item["Inventory Status"],
                "Gender": item["Gender"],
                "Sample Workflow Status": item["Sample Workflow Status"],
                "Account ID": item["accountid"],
                "Region": item["Region"],
                "Notes": item["Notes"],
                "Designer": item["Designer"],
                "Fabric": item["Fabric"],
                "Manufacturer": item["Manufacturer"],
                "Collection": item["Collection"],
                "Style": item["Style"],
                "Looks Associated": item["Looks Associated"],
                "Season": item["Season"],
                "Misc": item["Misc"],
                "Quantity": item["Quantity"],
                "Type": item["Type"],
                "Size": item["Size"],
                "Size Range": item["Size Range"],
                "Pattern": item["Pattern"],
                "Color": item["Color"],
                "Color Code": item["colorcode"],
                "Retail Price EUR": item["Retail Price EUR"],
                "Retail Price USD": item["Retail Price USD"],
                "Wholesale EUR": item["Wholesale EUR"],
                "Wholesale USD": item["Wholesale USD"],
                "Sale Price EUR": item["Sale Price EUR"],
                "Sale Price USD": item["Sale Price USD"],
                "Prices ID": item["pricesid"],
                "Description": item["Description"],
                "Storage ID": item["storageid"],
                "Registration Date": item["regdate"],
                "Current Date": item["curdate"],
                "User ID": item["userid"],
                "Is Active": item["isactiviti"],
                "Image Link": item["Image Link"],
                "FullImage Link": item["FullImage Link"],
                "barcode": item["barcode"],
                "User": item["user"],
                "Barcode Prefix": item["barcodeprefix"],
                "celebriti": item["celebriti"],
                "contactid": item["contactid"],
                "Selected Sample ID": item["selectedsampleid"],
                "itemcount": item["ItemCount"] || 1,
                "sendoutid": item["sendoutid"] || 0,
                "isaccepted": item["isaccepted"],
                "itemstatus": itemstatus
              };
            });

            // Добавляем новые данные в SelfData, если они уникальны
            console.log("SelfData.sampledata[0].data.length", SelfData.sampledata[0].data.length)
            SelfData.sampledata[0].data = [...SelfData.sampledata[0].data, ...newData];
            console.log(`Добавлено в этой партии: ${newData.length}. Текущее количество записей: ${SelfData.sampledata[0].data.length}`);

            // Обновляем метку интерфейса
            this.modalService.createInventoryLabel();

            // Скрываем спиннер после первой страницы
            if (currentPage === 1) {
              Speener.samplevisiblemode = false;
            }

            // Проверяем необходимость загрузки следующей партии
            if (response.data.length === batchSize && newData.length > 0) {
              currentPage++;
              loadBatch();
            } else {
              console.log("Загрузка завершена. Всего загружено записей:", SelfData.sampledata[0].data.length);
              SelfData.originalData = SelfData.sampledata[0].data
              Speener.samplevisiblemode = false;
              this.samplewait = false
              SelfData.waitpanel = false
            }
          } else {
            console.log("Загрузка завершена. Всего загружено записей:", SelfData.sampledata[0].data.length);
            SelfData.originalData = SelfData.sampledata[0].data
            Speener.samplevisiblemode = false;
            this.samplewait = false
            SelfData.waitpanel = false
          }
        },
        error: (error) => {
          console.error('Ошибка при загрузке данных:', error);
          Speener.samplevisiblemode = false;
          this.samplewait = false
          SelfData.waitpanel = false
        }
      });
    };

    loadBatch();
  }

// Метод для завершения загрузки данных
  private finalizeDataLoading(): void {
    this.modalService.createInventoryLabel();
    Speener.samplevisiblemode = false;
  }

  // ngOnInit(): void {
  //   Speener.samplevisiblemode = true
  //   this.modalService.closeInventoryLabel();
  //   this.subscription111 = this.yourappsService.seldesigner.subscribe(disvalue => {
  //     console.log("disvalue", disvalue[1])
  //     if (disvalue[0]?.["Designer"]) { // <-- Check if the emitted value is truthy (i.e., not null, undefined, 0, '', etc.)
  //       console.log(disvalue[0]?.["Designer"])
  //       this.selDesigner = disvalue[0]?.["Designer"]
  //       this.serviceservice.setSampleList({ accountid:  this.getAccountID(), page: 1, pageSize: 1000000}).subscribe({
  //         next: (value) => {
  //           if (value) {
  //             this.Speener.samplevisiblemode = false
  //             this.iSampleDataResult[0] = value;
  //             let filteredData: SetSampleListData[] = []
  //             if (disvalue[0]?.["Designer"] !== "") {
  //               console.log("Not Empty")
  //               filteredData = this.filterByDesigner(this.iSampleDataResult[0].data, disvalue[0]?.["Designer"]);
  //             } else {
  //               console.log("Empty")
  //               filteredData = this.iSampleDataResult[0].data
  //             }
  //             SelfData.sampledata[0].data = filteredData
  //             this.modalService.createInventoryLabel();
  //           } else {
  //             console.log('The response is empty or undefined.');
  //           }
  //         },
  //         error: (error) => {
  //           console.error('Error occurred during the subscription:', error);
  //         },
  //       });
  //       // this.yourappsService.setSelectDesigner(null);
  //     } else {
  //       // Speener.samplevisiblemode = true
  //       var accountid: number = 0;
  //       if (SelfData.account[0].data[0].parentid == 0) {
  //         accountid = SelfData.account[0].data[0].id;
  //       } else {
  //         accountid = SelfData.account[0].data[0].parentid;
  //       }
  //       this.serviceservice.setSampleList({ accountid: accountid, page: 1, pageSize: 1000000 }).subscribe({
  //         next: (value) => {
  //           if (value) {
  //             this.iSampleDataResult[0] = value;
  //             SelfData.sampledata = [{Code: 200, ErrorMessage: '', data: []}];
  //             for (const item of this.iSampleDataResult[0].data) {
  //               console.log("item[\"id\"]", item["ID"])
  //               let match = disvalue[1].find((sample: { ID: any; }) => sample.ID === item['ID']);
  //               let itemstatus;
  //               if (match) {
  //                 itemstatus = this.booleanToInt(true);
  //                 console.log("itemstatus", itemstatus)
  //               } else {
  //                 itemstatus = this.booleanToInt(false);
  //               }
  //               const newSample: SetSampleListData = {
  //                 "ID": item["ID"],
  //                 "Inventory Status": item["Inventory Status"],
  //                 "Gender": item["Gender"],
  //                 "Sample Workflow Status": item["Sample Workflow Status"],
  //                 "Account ID": item["accountid"],
  //                 "Region": item["Region"],
  //                 "Notes": item["Notes"],
  //                 "Designer": item["Designer"],
  //                 "Fabric": item["Fabric"],
  //                 "Manufacturer": item["Manufacturer"],
  //                 "Collection": item["Collection"],
  //                 "Style": item["Style"],
  //                 "Looks Associated": item["Looks Associated"],
  //                 "Season": item["Season"],
  //                 "Misc": item["Misc"],
  //                 "Quantity": item["Quantity"],
  //                 "Type": item["Type"],
  //                 "Size": item["Size"],
  //                 "Size Range": item["Size Range"],
  //                 "Pattern": item["Pattern"],
  //                 "Color": item["Color"],
  //                 "Color Code": item["colorcode"],
  //                 "Retail Price EUR": item["Retail Price EUR"],
  //                 "Retail Price USD": item["Retail Price USD"],
  //                 "Wholesale EUR": item["Wholesale EUR"],
  //                 "Wholesale USD": item["Wholesale USD"],
  //                 "Sale Price EUR": item["Sale Price EUR"],
  //                 "Sale Price USD": item["Sale Price USD"],
  //                 "Prices ID": item["pricesid"],
  //                 "Description": item["Description"],
  //                 "Storage ID": item["storageid"],
  //                 "Registration Date": item["regdate"],
  //                 "Current Date": item["curdate"],
  //                 "User ID": item["userid"],
  //                 "Is Active": item["isactiviti"],
  //                 "Image Link": item["Image Link"],
  //                 "FullImage Link": item["FullImage Link"],
  //                 "barcode": item["barcode"],
  //                 "User": item["user"],
  //                 "Barcode Prefix": item["barcodeprefix"],
  //                 "celebriti": item["celebriti"],
  //                 "contactid": item["contactid"],
  //                 "Selected Sample ID": item["selectedsampleid"],
  //                 "itemcount": item["ItemCount"] || 1,
  //                 "sendoutid": item["sendoutid"] || 0,
  //                 "isaccepted": item["isaccepted"],
  //                 "itemstatus": itemstatus
  //               };
  //               // console.log("newSample", newSample)
  //               SelfData.sampledata[0].data.push(newSample)
  //             }
  //
  //              // = this.iSampleDataResult;
  //             this.modalService.createInventoryLabel();
  //             this.Speener.samplevisiblemode = false
  //           } else {
  //             console.log('The response is empty or undefined.');
  //           }
  //         },
  //         error: (error) => {
  //           console.error('Error occurred during the subscription:', error);
  //         },
  //       });
  //       this.selDesigner = ""
  //     }
  //   });
  // }

  booleanToInt(value: boolean | undefined | null): number {
    return value ? 1 : 0;
  }

  submit() {
    // Your form submission logic
  }

  iSampleDataResult: SetSampleList[] = [];
  // onButtonSearchClick() {
  //   var accountid: number = 0;
  //   if (SelfData.account[0].data[0].parentid == 0) {
  //     accountid = SelfData.account[0].data[0].id;
  //   } else {
  //     accountid = SelfData.account[0].data[0].parentid;
  //   }
  //   this.serviceservice.setSampleList({ accountid: accountid, page: 1, pageSize: 100000 }).subscribe({
  //     next: (value) => {
  //       if (value) {
  //         this.iSampleDataResult[0] = value;
  //         SelfData.sampledata = this.iSampleDataResult;
  //         this.modalService.createInventoryLabel();
  //       } else {
  //         console.log('The response is empty or undefined.');
  //       }
  //     },
  //     error: (error) => {
  //       console.error('Error occurred during the subscription:', error);
  //     },
  //   });
  // }

  protected readonly Speener = Speener;
}
