import { Component } from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";

@Component({
  selector: 'app-createvcimages',
  templateUrl: './createvcimages.component.html',
  styleUrls: ['./createvcimages.component.scss']
})
export class CreatevcimagesComponent {
  constructor(public modalService: ModalserviceService) {}
}
