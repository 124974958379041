import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder} from "@angular/forms";
import {HashService} from "../../services/hash.service";
import {MessageboxComponent} from "../../modals/messagebox/messagebox.component";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {HttpClient} from "@angular/common/http";
import {SelfData} from "../../models/struct";

@Component({
  selector: 'app-aesdialog',
  templateUrl: './aesdialog.component.html',
  styleUrl: './aesdialog.component.scss'
})
export class AesdialogComponent implements OnInit, AfterViewInit, OnDestroy {
  private subscription?: Subscription;
  caption: string = "";
  description: string = "";

  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
      ? SelfData.account[0].data[0].id
      : SelfData.account[0].data[0].parentid;
  }

  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private hashService: HashService,
    public messageBox: MessageboxComponent,
    private serviceservice: ServiceService,
    private yourappsService: YourappsserviceService,
    private _snackBar: MatSnackBar,
    private notification: NzNotificationService,
    private http: HttpClient,
    private cdr: ChangeDetectorRef
  ) {
    this.caption = SelfData.caption;
    console.log("SelfData.caption", SelfData.caption)
  }
  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
  }

  onAddButtonClick() {
    if (SelfData.caption === "Add Brand") {
      this.serviceservice.prcBrandSale({
        id: 0,
        description: this.description,
        userid: this.getAccountID(),
        ctrl: 0
      })
        .subscribe(response => {
          console.log('API Response:', response);
          this.modalService.aesClose()
          this.notification.create(
            "success",
            'System Message',
            'Data saved successfully.',
            {nzDuration: 4000}
          );
          this.yourappsService.getReloadSaleList([], 1)
        });
    } else if (SelfData.caption === "Add Category") {
      this.serviceservice.prcCategorySale({
        id: 0,
        description: this.description,
        userid: this.getAccountID(),
        ctrl: 0
      })
        .subscribe(response => {
          console.log('API Response:', response);
          this.modalService.aesClose()
          this.notification.create(
            "success",
            'System Message',
            'Data saved successfully.',
            {nzDuration: 4000}
          );
          this.yourappsService.getReloadSaleList([], 1)
        });
    } else if (SelfData.caption === "Add Material") {
      this.serviceservice.prcMaterialSale({
        id: 0,
        description: this.description,
        userid: this.getAccountID(),
        ctrl: 0
      })
        .subscribe(response => {
          console.log('API Response:', response);
          this.modalService.aesClose()
          this.notification.create(
            "success",
            'System Message',
            'Data saved successfully.',
            {nzDuration: 4000}
          );
          this.yourappsService.getReloadSaleList([], 1)
        });
    }
  }
}
