import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MessageService} from "primeng/api";
import {Subscription} from "rxjs";
import {SelfData} from "../../models/struct";

@Component({
    selector: 'app-celebritiframedialog',
    templateUrl: './celebritiframedialog.component.html',
    styleUrls: ['./celebritiframedialog.component.scss']
})
export class CelebritiframedialogComponent implements OnInit, AfterViewInit, OnDestroy {
    frameCaption: String = '';
    frameid: number = 0;
    smid: number = 0;
    myControl = new FormControl('', [Validators.required]);
    instagramControl = new FormControl('');
    tiktokControl = new FormControl('');
    twitterControl = new FormControl('');
    facebookControl = new FormControl('');
    youtubeControl = new FormControl('');

    constructor(
        public modalService: ModalserviceService,
        public fb: FormBuilder,
        private serviceservice: ServiceService,
        private yourappsService: YourappsserviceService,
        private messageService: MessageService
    ) {

    }

    ngAfterViewInit(): void {
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    private subscription?: Subscription;

    ngOnInit(): void {
        this.subscription = this.yourappsService.sm$.subscribe((value) => {
            this.smid = 0
            if (value[0]?.data) {
                this.instagramControl.setValue(value[0]?.data[0]?.sm_instagram);
                this.tiktokControl.setValue(value[0]?.data[0]?.sm_tiktok);
                this.twitterControl.setValue(value[0]?.data[0]?.sm_twitter);
                this.facebookControl.setValue(value[0]?.data[0]?.sm_facebook);
                this.youtubeControl.setValue(value[0]?.data[0]?.sm_youtube);
                this.smid = value[0]?.data[0]?.id
            }
            // if (value != null) {
            //   console.log(value[0]?.["sm_instagram"])
            //   this.instagramControl.setValue(value[0]?.["sm_instagram"])
            // } else {
            //   console.log("NULL")
            // }
        });
        this.yourappsService.caption$.subscribe((caption) => {
            this.frameCaption = caption;
        });

        this.yourappsService.description$.subscribe((description) => {
            this.myControl.setValue(description);
        });

        this.yourappsService.id$.subscribe((id) => {
            this.frameid = id;
        });
    }

    private getAccountID(): number {
        return SelfData.account[0].data[0].parentid === 0
            ? SelfData.account[0].data[0].id
            : SelfData.account[0].data[0].parentid;
    }

    onSubmit() {
        if (SelfData.selectedddid > 0) {
            this.serviceservice
                .prcsm({
                    ctrl: 1, id: this.smid, sm_facebook: this.facebookControl.value || "", sm_instagram: this.instagramControl.value || "",
                    sm_tiktok: this.tiktokControl.value || "", sm_twitter: this.twitterControl.value || "", sm_youtube: this.youtubeControl.value || "",
                    celebritiid: 0
                })
                .subscribe((value) => {
                    this.serviceservice
                        .prccelebriti({
                            accountid: this.getAccountID(), ctrl: 1,
                            id: SelfData.selectedddid, contactid: SelfData.contactid,
                            description: this.myControl.value || "", isactivity: true, smid: value.data
                        })
                        .subscribe((value) => {
                            this.yourappsService.getReloadCelebritiList()
                            this.modalService.closeCF()
                        })
                });
        } else {
            this.serviceservice
                .prcsm({
                    ctrl: 0, id: 0, sm_facebook: this.facebookControl.value || "", sm_instagram: this.instagramControl.value || "",
                    sm_tiktok: this.tiktokControl.value || "", sm_twitter: this.twitterControl.value || "", sm_youtube: this.youtubeControl.value || "",
                    celebritiid: 0
                })
                .subscribe((value) => {
                    this.serviceservice
                        .prccelebriti({
                            accountid: this.getAccountID(), ctrl: 0, id: 0,
                            contactid: SelfData.contactid, description: this.myControl.value || "",
                            isactivity: true, smid: value.data
                        })
                        .subscribe((value) => {
                            this.modalService.closeCF()
                            this.yourappsService.getReloadCelebritiList()
                        })
                });
        }
    }

    clearValue() {
        this.myControl.setValue(''); // Clear the form control value
    }

}
