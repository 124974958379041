<button mat-icon-button type="button" class="close-btn" (click)="modalService.scFrameClose()">
  <mat-icon>close</mat-icon>
</button>
<p class="sample-manager-customer">{{ caption }}</p>
<div class="maindiv">
  <div class="componentsdiv">
    <div class="componentsdiv2">
      <label class="txtdiv2"><span class="onlybold">Supplier</span></label>
      <nz-input-group [nzAddOnAfter]="suffixSupplierIconButton">
        <nz-select nzShowSearch [nzShowArrow]="true" class="cmdiv"
          [(ngModel)]="selectsupplier">
          <nz-option *ngFor="let option of iSuppliersResult"
            [nzLabel]="option['SupplierName']"
            [nzValue]="option['SupplierName']">
          </nz-option>
        </nz-select>
      </nz-input-group>

      <ng-template #suffixSupplierIconButton>
        <button class="buttonclass" nz-button nzType="default" (click)="onSupplierClick()">
          <i nz-icon nzType="ellipsis" nzTheme="outline"></i>
        </button>
      </ng-template>
    </div>
    <div class="componentsdiv2">
      <label class="txtdiv2"><span class="onlybold">Ordering for</span></label>
      <nz-select
        [(ngModel)]="listOfTagOptions"
        nzMode="tags"
        [nzTokenSeparators]="[',']"
      >
        <nz-option *ngFor="let option of iOutletsResult" [nzLabel]="option['OutletName']"
          [nzValue]="option['OutletName']"></nz-option>
      </nz-select>
    </div>
  </div>

  <div class="componentsdiv">
    <div class="componentsdiv2">
      <label class="txtdiv2"><span class="onlybold">Deliver to</span></label>
      <nz-input-group>
        <nz-select nzShowSearch [nzShowArrow]="true" class="cmdiv"
          [(ngModel)]="selectCategories">
          <nz-option *ngFor="let option of iOutletsResult" [nzLabel]="option['OutletName']"
            [nzValue]="option['OutletName']"></nz-option>
        </nz-select>
      </nz-input-group>

    </div>
    <div class="componentsdiv2">
      <label class="txtdiv2"><span class="onlybold">Delivery date</span> (Optional)</label>
      <nz-input-group>
        <nz-date-picker class="cmdiv" [(ngModel)]="bdate" (ngModelChange)="onChange($event)"></nz-date-picker>
      </nz-input-group>

    </div>
  </div>

  <div class="componentsdiv">
    <div class="componentsdiv2">
      <label class="txtdiv2"><span class="onlybold">Order number</span> Must be a unique number</label>
      <nz-input-group>
        <input class="cmdiv" type="text" [(ngModel)]="ordernumber" nz-input/>
      </nz-input-group>

    </div>
    <div class="componentsdiv2">
      <label class="txtdiv2"><span class="onlybold">Supplier invoice number</span> (Optional)</label>
      <nz-input-group>
        <input class="cmdiv" type="text" [(ngModel)]="invoicenumber" nz-input/>
      </nz-input-group>
    </div>
  </div>

  <div class="componentsdiv1-2">
    <div class="componentsdiv2-1">
      <label class="txtdiv2"><span class="onlybold">Note</span> (Optional)</label>
      <textarea [(ngModel)]="descriptionstring" rows="3" cols="30" class="cmdiv dopinfo"
        nz-input
      ></textarea>
    </div>
  </div>

  <div class="componentsdiv">
    <div class="componentsdiv2">
      <label class="txtdiv2"><span class="onlybold">Product</span></label>
      <!--      <nz-input-group [nzAddOnAfter]="suffixProductIconButton">-->
      <!--        <nz-select nzShowSearch [nzShowArrow]="true" class="cmdiv"-->
      <!--          [(ngModel)]="selectproduct">-->
      <!--          <nz-option *ngFor="let option of iProductsResult"-->
      <!--            [nzLabel]="option['name']"-->
      <!--            [nzValue]="option['name']">-->
      <!--          </nz-option>-->
      <!--        </nz-select>-->
      <!--      </nz-input-group>-->

      <nz-input-group [nzSuffix]="suffixIcon" [nzAddOnAfter]="suffixProductIconButton">
        <input
          #searchInput
          type="text"
          nz-input
          [(ngModel)]="selectproduct"
          (ngModelChange)="onSearch($event)"
          (focus)="onFocus()"
          [nzAutocomplete]="auto"
          placeholder="Search for a product..."
        />
      </nz-input-group>

      <nz-autocomplete #auto [nzWidth]="inputWidth" class="custom-autocomplete-panel">
        <nz-auto-option *ngFor="let product of filteredProducts" [nzValue]="product.name">
          <div class="search-item">
            <img [src]="product.imagelink" class="product-image shadow-4" alt="Product">
            <div class="product-info">
              <strong class="product-name">{{ product.name }}</strong><br>
              <span class="product-description" [innerHTML]="product.description"></span><br>
              <span class="product-price">{{ product.retail_price | currency }}</span>
            </div>
          </div>
        </nz-auto-option>
      </nz-autocomplete>

      <ng-template #suffixIcon>
        <i nz-icon nzType="search"></i>
      </ng-template>

      <ng-template #suffixProductIconButton>
        <button class="buttonclass" nz-button nzType="default" (click)="onProductAddClick()">
          <i nz-icon nzType="ellipsis" nzTheme="outline"></i>
        </button>
        <button class="buttonclass" nz-button nzType="default" (click)="onProductPluseClick()">
          <i nz-icon nzType="plus" nzTheme="outline"></i>
        </button>
      </ng-template>
    </div>
    <div class="componentsdiv2">

    </div>
  </div>

  <div class="componentsdiv1-2">
    <div class="componentsdiv2-1">
      <p-table
        [value]="selectedproduct"
        [paginator]="false"
        [rows]="10000"
        [showCurrentPageReport]="false"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [styleClass]="'p-datatable-striped mat-elevation-z1 ' + (selectedSize?.class || '')"
        selectionMode="single"
        [tableStyle]="{'min-width': '50rem'}">

        <ng-template pTemplate="header">
          <tr>
            <th style="width: 2%">Image</th>
            <th style="width: 12%">Name</th>
            <th style="width: 0%">Quantity</th>
            <!--            <th style="width: 1%">Wholesale Price</th>-->
            <th style="width: 1%">Retail Price</th>
            <th style="width: 5%">Discount (%)</th>
            <th style="width: 10%">Retail Total</th>
            <!--            <th style="width: 10%">Wholesale Total<br>Retail Total</th>-->
            <th style="width: 1%"></th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-product>
          <tr>
            <td>
              <img
                *ngIf="product?.imagelink"
                [src]="transformImageUrl(product.imagelink)"
                alt="Product Image"
                class="image-size-80x80 shadow-4"
                onerror="this.src='assets/images/placeholder.jpg';">
            </td>

            <td>
              {{ product.name }}<br>
              <small>{{ product.sku_code }}</small>
            </td>

            <td>
              <nz-input-number class="inputclass"
                nzSize="small"
                [(ngModel)]="product.quantity"
                nzMin="1"
                (ngModelChange)="updateTotalCost(product)">
              </nz-input-number>
            </td>

            <!--            <td>-->
            <!--              <nz-input-number class="inputclass1"-->
            <!--                nzSize="small"-->
            <!--                [(ngModel)]="product.wholesaleprice"-->
            <!--                [nzFormatter]="formatterDollar"-->
            <!--                (ngModelChange)="updateTotalCost(product)"-->
            <!--                nzMin="1">-->
            <!--              </nz-input-number>-->
            <!--            </td>-->

            <td>
              <nz-input-number class="inputclass1"
                nzSize="small"
                [(ngModel)]="product.unitPrice"
                [nzFormatter]="formatterDollar"
                nzMin="1"
                (ngModelChange)="updateTotalCost(product)">
              </nz-input-number>
            </td>

            <td>
              <nz-input-number class="inputclass"
                nzSize="small"
                [(ngModel)]="product.discountPercentage"
                nzMin="0" nzMax="100"
                (ngModelChange)="updateTotalCost(product)">
              </nz-input-number>
              <!--              <br>-->
              <!--              <small>Discount Amount: ${{ product.discountAmount.toFixed(2) }}</small>-->
            </td>

            <td>
              <!--              <h2><strong>${{ product.totalwholesale.toFixed(2) }}</strong></h2>-->
              <h2><strong>${{ product.totalAfterDiscount.toFixed(2) }}</strong></h2>
            </td>

            <td>
              <button nz-button nzType="text" nzSize="small" nzShape="circle" (click)="deleteProduct(product)">
                <span nz-icon [nzType]="'delete'" [nzTheme]="'fill'" style="color: red;"></span>
              </button>
            </td>
          </tr>
        </ng-template>
      </p-table>

      <div class="summary-container">
        <div class="summary-row">
          <span class="summary-label">Subtotal of products</span>
          <strong class="summary-value">${{ getSubtotal().toFixed(2) }}</strong>
        </div>

        <!-- ✅ Показывать сумму скидки только если она больше 0 -->
        <div class="summary-row" *ngIf="getTotalDiscount() > 0">
          <span class="summary-label">Total Discount</span>
          <strong class="summary-discount">-${{ getTotalDiscount().toFixed(2) }}</strong>
        </div>

        <div class="summary-row">
          <span class="summary-label">Shipping Costs</span>
          <nz-input-number
            [(ngModel)]="shippingCost"
            [nzFormatter]="formatterDollar"
            nzSize="small"
            nzMin="0"
            class="summary-input"
            (ngModelChange)="calculateOrderTotal()">
          </nz-input-number>
        </div>

        <div class="summary-row">
          <span class="summary-label">Import duty</span>
          <nz-input-number
            [(ngModel)]="importDuty"
            [nzFormatter]="formatterDollar"
            nzSize="small"
            nzMin="0"
            class="summary-input"
            (ngModelChange)="calculateOrderTotal()">
          </nz-input-number>
        </div>

        <hr class="summary-divider">

        <div class="summary-row total">
          <span class="summary-label">ORDER TOTAL</span>
          <strong class="summary-total">${{ orderTotal.toFixed(2) }}</strong>
        </div>
      </div>


    </div>
  </div>
</div>

<div class="footerdiv">
  <button nz-button nzType="default" (click)="onAddProductClick()">{{caption}}</button>
</div>
