<button mat-icon-button type="button" class="close-btn" (click)="modalService.profileframeClose()">
  <mat-icon>close</mat-icon>
</button>
<p class="sample-manager-customer">{{ caption }}</p>

  <div class="componentsdiv1-2">
    <div class="componentsdiv2-1">
      <label class="txtdiv">Profile name</label>
      <nz-input-group>
        <input class="cmdiv" type="text" [(ngModel)]="profilename" nz-input/>
      </nz-input-group>
    </div>
  </div>
<div class="maindiv">
  <div class="componentsdiv1-2">
    <div class="componentsdiv2-1">
      <label class="txtdiv">Permissions</label>
      <nz-tree
        [nzData]="nodes"
        nzCheckable
        nzMultiple
        [nzCheckedKeys]="defaultCheckedKeys"
        [nzExpandedKeys]="defaultExpandedKeys"
        [nzSelectedKeys]="defaultSelectedKeys"
        (nzClick)="nzEvent($event)"
        (nzExpandChange)="nzEvent($event)"
        (nzCheckBoxChange)="nzCheckBoxChange($event)"
      ></nz-tree>
    </div>
  </div>

</div>

<div class="footerdiv">
  <button nz-button nzType="default" (click)="onAddProfileClick()">{{caption}}</button>
</div>
