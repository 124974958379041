import {
  AfterViewInit,
  ChangeDetectorRef,
  Component, ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import {ProgressBarComponent} from "smart-webcomponents-angular/progressbar";
import {FormBuilder, FormControl} from "@angular/forms";
import {
  GetLBAccepts,
  GetLBCImageList,
  Getlbdesignerlist,
  GetlbSentList, GetLinkedImages,
  SelfData,
  SetSelectedFiles, SetSelectedFilesV
} from "../../models/struct";
import {Observable, Subscription} from "rxjs";
import {ModalserviceService} from "../../services/modalservice.service";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ConfirmationService, ConfirmEventType, MessageService} from "primeng/api";
import {NgxImageCompressService} from "ngx-image-compress";
import { HttpClient } from "@angular/common/http";
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'app-createvidialog',
  templateUrl: './createvidialog.component.html',
  styleUrls: ['./createvidialog.component.scss']
})
export class CreatevidialogComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('progressbar', {read: ProgressBarComponent, static: false}) progressbar!: ProgressBarComponent;
  formcaption: string = "Images List"
  desingerFormControl = new FormControl({value: '', disabled: false}, []);
  acceptFormControl = new FormControl({value: '', disabled: false}, []);
  progressbarshow: boolean = false;
  previewVisible: boolean = false;
  translationY: number = 0;
  zoomLevel: number = 1;
  previewUrl: string = '';
  dragging: boolean = false;
  startY: number = 0;
  operationType: number = 0
  catalogid: number = 0
  iImages: GetLBCImageList[] = [];
  deletedFiles: string[] = [];
  private subscription?: Subscription;
  private subscription1?: Subscription;
  showdialog: boolean = true;
  uploadbuttonenable: boolean = true
  filteredDesignerOptions?: Observable<string[]>;
  filteredAcceptedOptions?: Observable<string[]>;
  ilbSentResult: GetlbSentList[] = [];
  filteredResult: GetlbSentList[] = [];
  designerptions: string[] = [];
  acceptoptions: string[] = [];
  iLBAcceptsResult: GetLBAccepts[] = [];
  iLinkedImages: GetLinkedImages[] = []
  selectedImageFiles: {
    file: File | null;
    url: string;
    filename: string;
    lbcatalogid: number;
    colection: string;
    designer: string;
    catalog: string;
    catalogyear: number;
    seasoncode: string;
    islinked: boolean;
    simpleid: number;
  }[] = [];
  originalSelectedImageFiles: {
    file: File | null;
    url: string;
    filename: string;
    lbcatalogid: number;
    colection: string;
    designer: string;
    catalog: string;
    catalogyear: number;
    seasoncode: string;
  }[] = [];
  iDesignerResult: Getlbdesignerlist[] = [{Code: 0, ErrorMessage: '', data: []}];
  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
        ? SelfData.account[0].data[0].id
        : SelfData.account[0].data[0].parentid;
  }

  constructor(
      public modalService: ModalserviceService,
      public fb: FormBuilder,
      private serviceservice: ServiceService,
      private yourappsService: YourappsserviceService,
      private _snackBar: MatSnackBar,
      private cdr: ChangeDetectorRef,
      private messageService: MessageService,
      private imageCompressService: NgxImageCompressService,
      private http: HttpClient,
      private confirmationService: ConfirmationService,
      private renderer: Renderer2, @Inject(DOCUMENT) private document: Document,
      private el: ElementRef
  ) {

  }

  SelectImageFile($event: MouseEvent, item: any, i: number) {
    // SelfData.sampledata = [];
    SelfData.isduplicate = false
    SelfData.updatebuttonisenabled = true
    this.subscription1 = this.yourappsService.reloadValidedList.subscribe((value) => {
      if (this.subscription1) {
        this.subscription1.unsubscribe();
        this.selectedImageFiles = []
        this.serviceservice.selectlbaccepts({lbmainid: SelfData.tlbmainid, key: SelfData.lbkey})
            .subscribe((value) => {
              this.iLBAcceptsResult[0] = value
              SelfData.lbaccepts = this.iLBAcceptsResult;
              console.log("this.iLBAcceptsResult[0]", this.iLBAcceptsResult[0])
              this.serviceservice
                  .selectlinkedimages({accountid: this.getAccountID()})
                  .subscribe((value) => {
                    this.iLinkedImages[0] = value;
                    SelfData.linkedimages = this.iLinkedImages;
                    console.log("this.SelfData.linkedimages", this.iLinkedImages)
                    this.iLBAcceptsResult[0].data.forEach(acceptResult => {
                      // Create a new object with properties from acceptResult
                      let newFile = {
                        file: null,
                        url: acceptResult.filedir,
                        filename: acceptResult.filename,
                        lbcatalogid: acceptResult.lbcatalogid,
                        colection: acceptResult.colection,
                        designer: acceptResult.designer,
                        catalog: acceptResult.catalog,
                        catalogyear: acceptResult.catalogyear,
                        seasoncode: "",
                        islinked: false, // Default to false
                        simpleid: -1,
                        lbmainid: acceptResult.lbmainid,
                        description: acceptResult.description,
                        barcode: ""
                      };

                      // Check if there's a corresponding item in this.iLinkedImages
                      const linkedImage = this.iLinkedImages[0].data.find(image => image.filename === acceptResult.filename);

                      // Update islinked and simpleid if linkedImage is found
                      if (linkedImage && this.iLinkedImages[0].data.length > 0) {
                        newFile.islinked = true;
                        newFile.simpleid = linkedImage.sampleid;
                        newFile.barcode = linkedImage.barcode
                      }

                      if (linkedImage?.sampleid) {
                        console.log("linkedImage.sampleid", linkedImage?.sampleid)
                        const existingFileIndex = this.selectedImageFiles.findIndex(file => file.simpleid === newFile.simpleid);
                        if (existingFileIndex === -1) {
                          this.selectedImageFiles.push(newFile);
                        } else {
                          this.selectedImageFiles[existingFileIndex].islinked = true;
                          this.selectedImageFiles[existingFileIndex].simpleid = linkedImage?.sampleid || 0;
                        }
                      } else {
                        this.selectedImageFiles.push(newFile);
                      }
                    });
                    console.log("this.selectedImageFiles", this.selectedImageFiles)
                    const countIsLinkedFalse = this.selectedImageFiles.filter(file => !file.islinked).length;
                    const item = countIsLinkedFalse === 1 ? "item" : "items";
                    if (countIsLinkedFalse > 0) {
                      this.selectedImageFiles.sort((a, b) => {
                        // Compare based on islinked property
                        // Items with islinked = false should come first
                        if (a.islinked === b.islinked) {
                          return 0; // No change in order if both have the same islinked value
                        } else if (a.islinked && !b.islinked) {
                          return 1; // If a is linked and b is not, a should come after b
                        } else {
                          return -1; // If b is linked and a is not, a should come before b
                        }
                      });
                    } else {
                      this.yourappsService.getReloadSendOutList(this.selectedImageFiles, 1)
                      this.modalService.closevi()
                    }

                    console.log('Number of items with islinked = false:', countIsLinkedFalse);
                  });

            })
      }
    })
    this.yourappsService.selectSampleData(null);
    this.yourappsService.setValudSelectedFiles(item, 1)
    this.modalService.createsampleropen();
    // this.confirmationService.confirm({
    //   message: `<strong>Select this photo?</strong>`,
    //   header: 'Select Image',
    //   icon: 'pi pi-exclamation-triangle',
    //   accept: () => {
    //
    //   },
    //   reject: (type: any) => {
    //     if (type == ConfirmEventType.REJECT) {
    //
    //     } else if (type == ConfirmEventType.CANCEL) {
    //     }
    //   }
    //
    // });
    // setTimeout(() => {
    //   this.applyLocalRestoreStyles();
    // }, 0);
  }

  private applyLocalRestoreStyles() {
    setTimeout(() => {
      const messageElements = document.querySelectorAll('.p-confirm-dialog-message');
      messageElements.forEach((element) => {
        element.setAttribute('style', 'font-size: 16px; color: #343a40;');
      });
    }, 0);

    const iconElements = document.querySelectorAll('.p-confirm-dialog-icon');
    iconElements.forEach((element) => {
      element.setAttribute('style', 'color: #343a40');
    });
  }

  updateImageTransform(): void {
    const img = document.querySelector('.overlay-content') as HTMLImageElement;
    img.style.transform = `scale(${this.zoomLevel}) translateY(${this.translationY}px)`;
  }

  hideFullWindowImage(): void {
    // ... existing code to hide the image
    // Reset zoom and translation
    this.zoomLevel = 1;
    this.translationY = 0;
    this.updateImageTransform();
  }

  showFullWindowImage(imageUrl: string): void {
    // Create the elements for the modal, image, and close button
    const modal = this.renderer.createElement('div');
    this.renderer.addClass(modal, 'preview-modal');

    const img = this.renderer.createElement('img');
    this.renderer.addClass(img, 'preview-image');
    this.renderer.setAttribute(img, 'src', imageUrl);
    this.renderer.appendChild(modal, img);

    const closeButton = this.renderer.createElement('button');
    this.renderer.addClass(closeButton, 'close-preview');
    closeButton.innerHTML = '&times;';  // or use an SVG/icon for the close button
    this.renderer.appendChild(modal, closeButton);

    // Append the modal to the body
    this.renderer.appendChild(this.document.body, modal);

    // Add a click listener to the close button to remove the modal
    this.renderer.listen(closeButton, 'click', (event) => {
      event.stopPropagation();  // Prevent the event from closing the modal immediately
      this.renderer.removeChild(this.document.body, modal);
    });



    // Optionally, add a click listener to the modal to close it if the user clicks outside the image
    // this.renderer.listen(modal, 'click', () => {
    //   this.renderer.removeChild(this.document.body, modal);
    // });

    // this.renderer.listen(img, 'click', () => {
    //   this.zoomScale = this.zoomScale === 1 ? 2 : 1; // Toggle between 1x and 2x zoom
    //   this.renderer.setStyle(img, 'transform', `scale(${this.zoomScale})`);
    //   this.renderer.setStyle(img, 'cursor', this.zoomScale === 1 ? 'zoom-in' : 'zoom-out');
    // });
  }

  ngAfterViewInit(): void {
    this.subscription = this.yourappsService.retSelectedFiles$.subscribe((value: { data: SetSelectedFilesV[], type: number }) => {
        if (value.data) {
          if (value.type === 1) {
            this.selectedImageFiles = value.data
            this.selectedImageFiles.sort((a, b) => {
              // Compare based on islinked property
              // Items with islinked = false should come first
              if (a.islinked === b.islinked) {
                return 0; // No change in order if both have the same islinked value
              } else if (a.islinked && !b.islinked) {
                return 1; // If a is linked and b is not, a should come after b
              } else {
                return -1; // If b is linked and a is not, a should come before b
              }
            });
            this.cdr.detectChanges();
            this.yourappsService.returnSelectedFiles(null, 0)
          }
        }
    })
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.subscription1) {
      this.subscription1.unsubscribe();
    }
  }

  ngOnInit(): void {
  }

  onButtonCloseClick() {
    this.modalService.closevi()
  }
}
