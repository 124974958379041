import { Component, OnInit, OnDestroy } from '@angular/core';
import { ModalserviceService } from '../../services/modalservice.service';
import { Subscription } from 'rxjs';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { SelfData } from '../../models/struct';
import { LocalStorageService } from '../../services/local-storage.service';
import { YourappsserviceService } from '../../services/yourappsservice.service';
import {ActivatedRoute} from "@angular/router";
@Component({
  selector: 'app-main-frame',
  templateUrl: './main-frame.component.html',
  styleUrls: ['./main-frame.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0 }), animate(300)]),
      transition(':leave', [animate(300, style({ opacity: 0 }))]),
    ]),
  ],
})
export class MainFrameComponent implements OnInit, OnDestroy {
  selectedTabIndex: number | undefined;
  showLoginframe: number | undefined;
  private frameTypeSubscription?: Subscription;

  constructor(
    public modalService: ModalserviceService,
    private localStorageService: LocalStorageService,
    private yourappsService: YourappsserviceService
  ) {
    this.selectedTabIndex = 0;
    this.showLoginframe = 1;
    const savedData = this.localStorageService.getItem('myAccountArray');
    console.dir(savedData);
    if (savedData) {
      SelfData.account = savedData;
      this.yourappsService.reloadAvatarImage.next();
      let programmscount = 0;
      if (
        SelfData.account[0].data[0].rpmonitoring ||
        SelfData.account[0].data[0].events ||
        SelfData.account[0].data[0].samples ||
        SelfData.account[0].data[0].contacts
      ) {
        if (SelfData.account[0].data[0].rpmonitoring) {
          this.modalService.rpmonitoringshow();
          programmscount += 1;
        } else {
          this.modalService.rpmonitorinclose();
        }
        if (SelfData.account[0].data[0].events) {
          this.modalService.eventsshow();
          programmscount += 1;
        } else {
          this.modalService.eventsclose();
        }
        if (SelfData.account[0].data[0].samples) {
          this.modalService.samplesshow();
          programmscount += 1;
        } else {
          this.modalService.samplesclose();
        }
        if (SelfData.account[0].data[0].contacts) {
          this.modalService.contactsshow();
          programmscount += 1;
        } else {
          this.modalService.contactsclose();
        }
        this.yourappsService.updateProgrammsCount(programmscount);
        this.yourappsService.setAccountName(
          SelfData.account[0].data[0].firstname +
            ' ' +
            SelfData.account[0].data[0].lastname
        );
        this.yourappsService.reloadAvatarImage.next();
        this.modalService.programmsshow();
        this.yourappsService.setAuth(true);
      } else {
        this.modalService.programmsclose();
        this.modalService.rpmonitorinclose();
        this.modalService.eventsclose();
        this.modalService.samplesclose();
        this.modalService.contactsclose();
        this.yourappsService.setAuth(false);
        this.yourappsService.setAccountName('');
      }
    }
  }

  ngOnInit(): void {
    this.frameTypeSubscription = this.modalService.frameTypeChanged.subscribe(
      (frameType: number) => {
        this.showLoginframe = frameType;
      }
    );
  }

  ngOnDestroy(): void {
    // Unsubscribe to prevent memory leaks
    if (this.frameTypeSubscription) {
      this.frameTypeSubscription.unsubscribe();
    }
  }
}
