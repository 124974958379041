import { Component, OnInit } from '@angular/core';
import { ModalserviceService } from '../../services/modalservice.service';
import { YourappsserviceService } from '../../services/yourappsservice.service';
import { SelfData } from '../../models/struct';
import { ServiceService } from '../../services/service.service';
import { Observable } from 'rxjs';

export interface AlertDialogData {
  title: string;
  message: string;
  buttonpos: string;
  buttonneg: string;
}

type ServiceMethodArgs<T> = {
  ctrl: number;
  description: string;
  accountid: number;
  id: number;
} & T;

@Component({
  selector: 'app-messageboxdialog',
  templateUrl: './messageboxdialog.component.html',
  styleUrls: ['./messageboxdialog.component.scss'],
})
export class MessageboxdialogComponent implements OnInit {
  okOption: number = 0;
  itemid: number = 0;

  constructor(
    public modalService: ModalserviceService,
    private yourappsService: YourappsserviceService,
    private serviceservice: ServiceService
  ) {}

  ngOnInit(): void {
    this.yourappsService.okOption$.subscribe((option) => {
      this.okOption = option;
    });
    this.yourappsService.id$.subscribe((id) => {
      if (id < 0) {
        this.itemid = SelfData.selectedddid;
      } else {
        this.itemid = id;
      }
    });
  }

  onButtonOKClick() {
    if (this.okOption === 1) {
      const accountid = this.calculateAccountId();

      const methodToCall = this.getMethodToCall();

      if (methodToCall !== null) {
        const methodParams: ServiceMethodArgs<any> = {
          ctrl: 2,
          description: 'DeleteRecord',
          accountid: accountid,
          id: this.itemid,
        };

        methodToCall.subscribe(() => {
          this.yourappsService.getReloadSWSList();
          this.yourappsService.getReloadArrayList();
          if (SelfData.selectedddid > 0) {
            this.yourappsService.setAddFrameDescription('DeleteRecord' || '');
          }
          SelfData.selectedddid = -1;
          // this.yourappsService.getReloadDDArrayList();
        });
      }
    } else if (this.okOption === 2) {
      const accountid = this.calculateAccountId();
      const account = {
        designerid: 0,
        isactiviti: false,
        notes: null,
        looksassociated: null,
        gender: null,
        ctrl: 2,
        inventoristatusid: null,
        manufacturerid: 0,
        colorcode: null,
        description: null,
        sessionid: 0,
        curdate: new Date(),
        userid: 0,
        accountid: accountid,
        regionid: 0,
        typeid: 0,
        id: SelfData.sampleselecteddata?.['ID'] || 0,
        sizeid: 0,
        quantity: null,
        fabricid: 0,
        patternid: 0,
        sizerangeid: 0,
        colorname: null,
        styleid: 0,
        miscid: 0,
        pricesid: 0,
        collectionid: 0,
        inventoristatus: null,
        sampleworkflowstatusid: 0,
        storageid: 0,
        stylestr: "",
        barcode: "",
        samplestyle: SelfData.samplestyle
      };

      this.serviceservice.prcSample(account).subscribe(() => {
        this.yourappsService.getReloadSampleList();
      });
    } else if (this.okOption == 3) {
      SelfData.saveonclose = false
      this.modalService.createsendoutclose()
    }

    this.modalService.closeMessageBox();
  }

  onButtonOKCancel() {
    if (this.okOption == 3) {
      SelfData.saveonclose = false
      this.modalService.createsendoutclose()
    }
    this.modalService.closeMessageBox();
  }

  onButtonCloseClick() {
    this.modalService.closeMessageBox();
  }

  calculateAccountId(): number {
    return SelfData.account[0].data[0].parentid === 0
      ? SelfData.account[0].data[0].id
      : SelfData.account[0].data[0].parentid;
  }

  getMethodToCall(): Observable<any> | null {
    const methodParams = {
      ctrl: 2,
      description: 'DeleteRecord',
      accountid: this.calculateAccountId(),
      id: this.itemid,
    };
    switch (SelfData.modaltype) {
      case 0:
        return this.serviceservice.prcsws(methodParams);
      case 1:
        return this.serviceservice.prcregion(methodParams);
      case 2:
        return this.serviceservice.prcdesigner(methodParams);
      case 3:
        return this.serviceservice.prcfabric(methodParams);
      case 4:
        return this.serviceservice.prcmanu(methodParams);
      case 7:
        return this.serviceservice.prcstyle(methodParams);
      case 9:
        return this.serviceservice.prcmisc(methodParams);
      case 10:
        return this.serviceservice.prctype(methodParams);
      case 11:
        return this.serviceservice.prcsize(methodParams);
      case 12:
        return this.serviceservice.prcpattern(methodParams);
      case 13:
        return this.serviceservice.prcsizerange(methodParams);
      case 14:
        return this.serviceservice.prcpronoun(methodParams);
      case 15:
        return this.serviceservice.prccontcategory(methodParams);
      case 16:
        return this.serviceservice.prccontjd(methodParams);
      case 17:
        return this.serviceservice.prcconttype(methodParams);
      case 18:
        return this.serviceservice.prccontregion(methodParams);
      case 19:
        return this.serviceservice.prccontrb(methodParams);
      case 20:
        return this.serviceservice.prccontac(methodParams);
      case 21:
        return this.serviceservice.prccontcg(methodParams);
      case 22:
        return this.serviceservice.prccomptype(methodParams);
      case -1:
        return this.serviceservice.prctod(methodParams);
      case -2:
        return this.serviceservice.prcpe(methodParams);
      default:
        return null;
    }
  }
}
