import { Component } from '@angular/core';
import { ModalserviceService } from '../../services/modalservice.service';

@Component({
  selector: 'app-selectedsample',
  templateUrl: './selectedsample.component.html',
  styleUrls: ['./selectedsample.component.scss'],
})
export class SelectedsampleComponent {
  constructor(public modalService: ModalserviceService) {}
}
