import {ChangeDetectorRef, Component, NgZone, ViewChild} from '@angular/core';
import {clientmailsData, SelfData, SetContactsListData, SetSampleListData} from "../../models/struct";
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder} from "@angular/forms";
import {HashService} from "../../services/hash.service";
import {MessageboxComponent} from "../../modals/messagebox/messagebox.component";
import {ServiceService} from "../../services/service.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {ConfirmationService} from "primeng/api";
import {MatTableDataSource} from "@angular/material/table";
import {Table} from "primeng/table";
import {MatPaginator} from "@angular/material/paginator";

@Component({
  selector: 'app-contactemaillist',
  templateUrl: './contactemaillist.component.html',
  styleUrl: './contactemaillist.component.scss'
})
export class ContactemaillistComponent {

  @ViewChild(MatPaginator) paginator: MatPaginator | null = null;
  @ViewChild('dt1') dt1!: Table;
  filtertext: string = ""
  sizes!: any[];
  selectedSize: any = '';

  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private hashService: HashService,
    public messageBox: MessageboxComponent,
    private serviceservice: ServiceService,
    private _snackBar: MatSnackBar,
    private yourappsService: YourappsserviceService,
    private ngZone: NgZone,
    private confirmationService: ConfirmationService,
    private cdRef: ChangeDetectorRef
  ) {
    this.sizes = [
      { name: 'Small', class: 'p-datatable-sm' }
    ];
    this.selectedSize = this.sizes[0]
  }

  formatCustomDate(date: any): string {
    if (!date) {
      return '';
    }

    // Преобразуем строку в объект Date, если это необходимо
    const parsedDate = date instanceof Date ? date : new Date(date);

    if (isNaN(parsedDate.getTime())) {
      // Если parsedDate - невалидная дата, возвращаем пустую строку
      return '';
    }

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const day = String(parsedDate.getDate()).padStart(2, '0');
    const month = months[parsedDate.getMonth()];
    const year = String(parsedDate.getFullYear()).slice(-2);
    const hours = String(parsedDate.getHours()).padStart(2, '0');
    const minutes = String(parsedDate.getMinutes()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}`;
  }

  get firstContactsData(): SetContactsListData[] {
    if (SelfData.cmlist && SelfData.cmlist[0] && SelfData.cmlist[0].data) {
      return SelfData.cmlist[0].data;
    }
    return [];
  }

  onInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;
    this.dt1.filterGlobal(value, 'contains');
  }

  clear(table: Table) {
    table.clear();
    this.filtertext = ""
  }

  protected readonly SelfData = SelfData;
  protected readonly Math = Math;
  currentSample: clientmailsData | null = null;

  onDetailViewClick(currentSample: any | null) {
    this.yourappsService.setMailDetailsView(currentSample, 1)
    this.modalService.openEmailDetailsView()
  }

  onInfoButtonClick() {
    window.open('https://fm.welcomeapps.net/FAQ/ContactMailing.html', '_blank');
  }
}
