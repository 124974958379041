import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder} from "@angular/forms";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ConfirmationService, MessageService} from "primeng/api";
import {NgxImageCompressService} from "ngx-image-compress";
import { HttpClient } from "@angular/common/http";
import {DOCUMENT} from "@angular/common";
import {Subscription} from "rxjs";
import {CarouselComponent} from "smart-webcomponents-angular/carousel";
import {SelfData, transformImageUrl} from "../../models/struct";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
  selector: 'app-createimageviewsdialog',
  templateUrl: './createimageviewsdialog.component.html',
  styleUrls: ['./createimageviewsdialog.component.scss']
})
export class CreateimageviewsdialogComponent implements OnInit, AfterViewInit, OnDestroy {
  formcaption: string = ""
  @ViewChild('carousel', { read: CarouselComponent, static: false }) carousel!: CarouselComponent;
  selectedImageFiles: { file: File | null; url: string; filename: string }[] = [];
  images: any[] | undefined;
  isDesktopDevice: boolean = this.deviceService.isDesktop();
  responsiveOptions: any[] | undefined;
  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private serviceservice: ServiceService,
    private yourappsService: YourappsserviceService,
    private _snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef,
    private messageService: MessageService,
    private imageCompressService: NgxImageCompressService,
    private http: HttpClient,
    private confirmationService: ConfirmationService,
    private renderer: Renderer2, @Inject(DOCUMENT) private document: Document,
    private deviceService: DeviceDetectorService,
    private el: ElementRef
  ) {

  }
  ngAfterViewInit(): void {
    this.carousel.addEventListener('change', (event: Event) => {
      // Use type assertion to assert the event as a CustomEvent
      const customEvent = event as CustomEvent;
      const detail = customEvent.detail,
          index = detail.index,
          previousIndex = detail.previousIndex;
      const imageUrl = this.selectedImageFiles[index].url;
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  init(imagecount: number): void {
    // init code.
    const that = this,
      basePath = './../../../src/images/';

    that.carousel.dataSource = generateDataSource(imagecount, this.selectedImageFiles);
    function generateDataSource(items: number, selectedImageFiles: { file: File | null; url: string; filename: string }[]) {
      return Array(items).fill({}).map((element, index) => {
        // Use the image from selectedImageFiles if available; otherwise, use a placeholder
        const imageUrl = index < selectedImageFiles.length && selectedImageFiles[index].file ? selectedImageFiles[index].url : selectedImageFiles[index].url;

        return {image: transformImageUrl(imageUrl)};
        // return {image: imageUrl};
      });
    }
  }


  private subscription?: Subscription;

  ngOnInit(): void {
    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 5
      },
      {
        breakpoint: '768px',
        numVisible: 3
      },
      {
        breakpoint: '560px',
        numVisible: 1
      }
    ];
    this.subscription = this.yourappsService.imagelist$.subscribe((value: { data: any, type: number }) => {
      if (value.type === 0) {
        this.formcaption = value.data?.["description"]
        this.loadLBImageList(value.data?.["catalogid"])
      } else if (value.type === 1) {
        this.formcaption = "Selected items"
        this.loadLBImageList1()
      } else if (value.type === 2) {
        this.formcaption = value.data?.["collection"]
        this.loadLBImageList(value.data?.["catalogid"])
      } else if (value.type === 3) {
        console.log(value.data["accountid"], value.data["ID"])
        this.formcaption = "Images List"
        const type: number = 1
        this.loadLBImageList(0, 1, value.data["accountid"], value.data["ID"])
      }
    })
  }

  loadLBImageList(catalogId: number, type: number = 0, accountid: number = 0, sampleid: number = 0): void {
    if (type === 0) {
      this.serviceservice.setLBImageList({lbcatalogid: catalogId})
        .subscribe({
          next: (value: any) => {
            if (value && value.data && Array.isArray(value.data)) {
              value.data.forEach((item: { filedir?: string }) => {
                if (item.filedir) {
                  // Construct the full URL if necessary
                  const url = item.filedir; // Adjust if you need to prepend a base path
                  const parts = url.split('/');
                  const fileName = parts[parts.length - 1];
                  this.selectedImageFiles.push({
                    file: null,
                    url: url,
                    filename: fileName,
                  });
                }
              });
            } else {
              console.log('The response is empty or not in the expected format.');
            }
            this.init(this.selectedImageFiles.length)
          },
          error: (error) => {
            console.error('Error occurred during the subscription:', error);
          },
        });
    } else if (type === 1) {
      this.serviceservice.selectoriginalimages({ accountid: accountid, sampleid: sampleid })
        .subscribe((value: any) => {
          console.log("value", value)
          const data = value.data as { imagelink?: string }[];
          if (data && Array.isArray(data)) {
            data.forEach((item) => {
              console.log("imagelink", item.imagelink)
              if (item.imagelink) {
                // Construct the full URL if necessary
                const url = item.imagelink; // Adjust if you need to prepend a base path
                const parts = url.split('/');
                const fileName = parts[parts.length - 1];
                this.selectedImageFiles.push({
                  file: null,
                  url: url,
                  filename: fileName,
                });
              }
            });
          } else {
            console.log('The response is empty or not in the expected format.');
          }
          this.init(this.selectedImageFiles.length);
          console.log("this.selectedImageFiles.length", this.selectedImageFiles.length)
        });
    }
  }

  loadLBImageList1(): void {
    this.selectedImageFiles = []
    if (SelfData.tcustomimages.data && SelfData.tcustomimages.data.length > 0) {
      SelfData.tcustomimages.data.forEach((imageinfo: any) => {
        const filedir = imageinfo.filedir
        const filename = imageinfo.filename
        this.selectedImageFiles.push({
          file: null,
          url: filedir,
          filename: filename,
        });
      })
      console.log("this.selectedImageFiles", this.selectedImageFiles)
      setTimeout(() => {
        this.init(this.selectedImageFiles.length);
      });
    }
  }

  onButtonCloseClick() {
    this.modalService.closeivframe()
  }

  onChange(event: any) {
    console.log('Carousel changed', event);
    // Additional logic here
  }
}
