<button mat-icon-button type="button" class="close-btn" (click)="modalService.closeSignature()">
    <mat-icon>close</mat-icon>
</button>

<button mat-icon-button type="button" class="clear-btn" (click)="clearPad()">
    <mat-icon>edit_off</mat-icon>
</button>

<div class="signaturediv1">
    <canvas class="canvasdiv" #canvas (touchstart)="startDrawing($event)" (touchmove)="moved($event)">

    </canvas>
</div>
<div class="button-container-signature">
    <button mat-flat-button type="button" (click)="savePad()">OK</button>
</div>
<!--<signature-pad [options]="signaturePadOptions" (onBeginEvent)="drawStart()" (onEndEvent)="drawComplete()"></signature-pad>-->
