import {ChangeDetectorRef, Component, Inject, OnInit, Renderer2, ViewChild} from '@angular/core';
import {SelfData, setgridfields, SetsorList, TagsListData, transformImageUrl} from "../../models/struct";
import {Table} from "primeng/table";
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder} from "@angular/forms";
import {HashService} from "../../services/hash.service";
import {MessageboxComponent} from "../../modals/messagebox/messagebox.component";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {HttpClient} from "@angular/common/http";
import * as moment from "moment-timezone";
import {forkJoin} from "rxjs";
import {DatePipe, DOCUMENT, formatDate} from "@angular/common";

@Component({
  selector: 'app-sendoutreport',
  templateUrl: './sendoutreport.component.html',
  styleUrl: './sendoutreport.component.scss'
})
export class SendoutreportComponent implements OnInit {
  iSalesReportResult: TagsListData[] = []
  sizes!: any[];
  @ViewChild(Table) dataTable?: Table;
  iSendOutReportResult: TagsListData[] = [];
  iFieldList: TagsListData[] = [];
  selectedSize: any = ''
  date: Date | Date[] | null = null;
  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
      ? SelfData.account[0].data[0].id
      : SelfData.account[0].data[0].parentid;
  }

  selectCheckDesigner: boolean = false;
  selectCheckInventoryStatus = false;
  selectCheckGender = false;
  selectCheckSampleWorkflowStatus = false;
  selectCheckRegion = false;
  selectCheckNotes = false;
  selectCheckFabric = false;
  selectCheckManufacturer = false;
  selectCheckCollection = false;
  selectCheckStyle = false;
  selectCheckLooksAssociated = false;
  selectCheckSeason = false;
  selectCheckMisc = false;
  selectCheckType = false;
  selectCheckSize = false;
  selectCheckSizeRange = false;
  selectCheckPattern = false;
  selectCheckColor = false;
  // selectCheckRetailPriceEUR = false;
  // selectCheckRetailPriceUSD = false;
  // selectCheckWholesaleEUR = false;
  // selectCheckWholesaleUSD = false;
  // selectCheckSalePriceEUR = false;
  // selectCheckSalePriceUSD = false;
  selectCheckDescription = false;
  selectCheckBarcode = false;
  selectCheckUser = false;
  selectCheckReservedUser = false;
  // selectCheckBarcodePrefix = false;
  selectCheckDepartment = false;
  selectCheckShip = false;
  selectCheckShipTo = false;
  selectCheckTypeOfDispatch = false;
  selectCheckProjectEvent = false;
  selectCheckCompanyName = false;
  selectCheckCelebInfluencModel = false;
  selectCheckPublication = false;
  selectCheckEditorStylist = false;
  selectCheckPhotographer = false;
  selectCheckProjectEventLocation = false;
  selectCheckProjectEventTheme = false;
  selectCheckIssue = false;
  selectCheckTrackingNumber = false;
  selectCheckReturn = false;
  selectCheckSendOutNode = false;
  selectCheckRequestedBy = false;
  selectCheckSendOutType = false;
  selectCheckCurrency = false;
  selectCheckReservationDate = false;
  selectCheckReturnDate = false;
  selectCheckCreateDate = false;
  selectCheckSender = false;
  selectCheckCelebriti = false;
  selectCheckImage = false;
  selectCheckFilename = false;
  selectCheckDeliveryNumber = false;
  selectCheckContact = false;
  selectCheckSampleStatus = false;
  selectCheckShipDate = false;
  selectCheckDeliveryNumberString: string = "";
  selectCheckTypeOfDispatchString: string = "";
  selectCheckBarcodeString: string = "";
  selectCheckDesignerString: string = "";
  selectCheckCollectionString: string = "";
  selectCheckSeasonString: string = "";
  selectCheckStyleString: string = "";
  selectCheckTypeString: string = "";
  selectCheckColorString: string = "";
  createDate: Date[] | null = null;
  shipDate: Date[] | null = null;
  returnDate: Date[] | null = null;
  selectCheckCompanyNameString: string = "";
  selectCheckContactString: string = "";
  selectCheckCelebritiString: string = "";
  selectCheckPublicationString: string = "";
  selectCheckEditorStylistString: string = "";
  selectCheckSendOutTypeString: string = "";
  selectCheckCelebInfluencModelString: string = "";
  selectCheckProjectEventString: string = "";
  selectCheckSenderString: string = "";
  selectCheckDescriptionString: string = "";
  selectCheckShipToString: string = "";
  selectCheckSampleWorkflowStatusString: string = "";
  selectCheckRegionString: string = "";
  selectCheckFabricString: string = "";
  selectCheckManufacturerString: string = "";
  selectCheckMiscString: string = "";
  selectCheckIssueString: string = "";
  selectCheckTrackingNumberString: string = "";
  selectCheckSizeString: string = "";
  selectCheckSizeRangeString: string = "";
  selectCheckPatternstring: string = "";
  selectCheckgenderString: string = "";
  selectCheckNotesString: string = "";
  selectCheckPhotographerString: string = "";

  constructor(public modalService: ModalserviceService,
              public fb: FormBuilder,
              private hashService: HashService,
              public messageBox: MessageboxComponent,
              private serviceservice: ServiceService,
              private yourappsService: YourappsserviceService,
              private _snackBar: MatSnackBar,
              private http: HttpClient,
              private datePipe: DatePipe,
              private cdr: ChangeDetectorRef,
              private renderer: Renderer2, @Inject(DOCUMENT) private document: Document) {
    const timezoneNames = moment.tz.names();
    this.sizes = [
      {name: 'Small', class: 'p-datatable-sm'}
    ];
    this.selectedSize = this.sizes[0]

  }

  ngOnInit(): void {
    this.yourappsService.selectguid$.subscribe((value: { guid: string, type: number }) => {
      console.log("Received GUID:", value); // Debugging

      forkJoin({
        products: this.serviceservice.selectSendOutReport(value.guid),
        fields: this.serviceservice.selectEmailFields(value.guid),
      }).subscribe({
        next: ({ products, fields }) => {
          this.iSendOutReportResult = products?.data || [];
          this.iFieldList = fields?.data || [];
          console.log("this.iSendOutReportResult", this.iSendOutReportResult);
          this.processFieldList()
        },
        error: (error) => {
          console.error("❌ API Error:", error);
        }
      });
    });
  }

  processFieldList() {
    const fieldListData = this.iFieldList|| [];
    console.log("fieldListData", fieldListData)
    fieldListData.forEach((item: any) => {
      console.log("item.fieldname", item.fieldname)
      switch (item.fieldname) {
        case 'Designer':
          this.selectCheckDesigner = item.isshow;
          break;
        case 'Inventory Status':
          this.selectCheckInventoryStatus = item.isshow;
          break;
        case 'Gender':
          this.selectCheckGender = item.isshow;
          break;
        case 'Sample Workflow Status':
          this.selectCheckSampleWorkflowStatus = item.isshow;
          break;
        case 'Region':
          this.selectCheckRegion = item.isshow;
          break;
        case 'Notes':
          this.selectCheckNotes = item.isshow;
          break;
        case 'Fabric':
          this.selectCheckFabric = item.isshow;
          break;
        case 'Manufacturer':
          this.selectCheckManufacturer = item.isshow;
          break;
        case 'Collection':
          this.selectCheckCollection = item.isshow;
          break;
        case 'Style':
          this.selectCheckStyle = item.isshow;
          break;
        case 'Looks Associated':
          this.selectCheckLooksAssociated = item.isshow;
          break;
        case 'Season':
          this.selectCheckSeason = item.isshow;
          break;
        case 'Misc':
          this.selectCheckMisc = item.isshow;
          break;
        case 'Type':
          this.selectCheckType = item.isshow;
          break;
        case 'Size':
          this.selectCheckSize = item.isshow;
          break;
        case 'Size Range':
          this.selectCheckSizeRange = item.isshow;
          break;
        case 'Pattern':
          this.selectCheckPattern = item.isshow;
          break;
        case 'Color':
          this.selectCheckColor = item.isshow;
          break;
        // case 'Retail Price EUR':
        //   this.selectCheckRetailPriceEUR = item.isshow;
        //   break;
        // case 'Retail Price USD':
        //   this.selectCheckRetailPriceUSD = item.isshow;
        //   break;
        // case 'Wholesale EUR':
        //   this.selectCheckWholesaleEUR = item.isshow;
        //   break;
        // case 'Wholesale USD':
        //   this.selectCheckWholesaleUSD = item.isshow;
        //   break;
        // case 'Sale Price EUR':
        //   this.selectCheckSalePriceEUR = item.isshow;
        //   break;
        // case 'Sale Price USD':
        //   this.selectCheckSalePriceUSD = item.isshow;
        //   break;
        case 'Description':
          this.selectCheckDescription = item.isshow;
          break;
        case 'Barcode':
          this.selectCheckBarcode = item.isshow;
          break;
        case 'User':
          this.selectCheckUser = item.isshow;
          break;
        case 'ReservedUser':
          this.selectCheckReservedUser = item.isshow;
          break;
        case 'Department':
          this.selectCheckDepartment = item.isshow;
          break;
        case 'Ship':
          this.selectCheckShip = item.isshow;
          break;
        case 'Ship To':
          this.selectCheckShipTo = item.isshow;
          break;
        case 'Type of Dispatch':
          this.selectCheckTypeOfDispatch = item.isshow;
          break;
        case 'Project/Event':
          this.selectCheckProjectEvent = item.isshow;
          break;
        case 'CompanyName':
          this.selectCheckCompanyName = item.isshow;
          break;
        case 'Celeb/Influenc/Model':
          this.selectCheckCelebInfluencModel = item.isshow;
          break;
        case 'Publication':
          this.selectCheckPublication = item.isshow;
          break;
        case 'Editor/Stylist':
          this.selectCheckEditorStylist = item.isshow;
          break;
        case 'Photographer':
          this.selectCheckPhotographer = item.isshow;
          break;
        case 'Project/Event Location':
          this.selectCheckProjectEventLocation = item.isshow;
          break;
        case 'Project/Event Theme':
          this.selectCheckProjectEventTheme = item.isshow;
          break;
        case 'Issue':
          this.selectCheckIssue = item.isshow;
          break;
        case 'Tracking Number':
          this.selectCheckTrackingNumber = item.isshow;
          break;
        case 'Return':
          this.selectCheckReturn = item.isshow;
          break;
        case 'SendOut Node':
          this.selectCheckSendOutNode = item.isshow;
          break;
        case 'Requested By':
          this.selectCheckRequestedBy = item.isshow;
          break;
        case 'SendOut Type':
          this.selectCheckSendOutType = item.isshow;
          break;
        case 'Currency':
          this.selectCheckCurrency = item.isshow;
          break;
        case 'Reservation Date':
          this.selectCheckReservationDate = item.isshow;
          break;
        case 'Return Date':
          this.selectCheckReturnDate = item.isshow;
          break;
        case 'Create Date':
          this.selectCheckCreateDate = item.isshow;
          break;
        case 'Sender':
          this.selectCheckSender = item.isshow;
          break;
        case 'Celebriti':
          this.selectCheckCelebriti = item.isshow;
          break;
        case 'Image':
          this.selectCheckImage = item.isshow;
          break;
        case 'Filename':
          this.selectCheckFilename = item.isshow;
          break;
        case 'Delivery #':
          this.selectCheckDeliveryNumber = item.isshow;
          break;
        case 'Contact':
          this.selectCheckContact = item.isshow;
          break;
        case 'Sample Status':
          this.selectCheckSampleStatus = item.isshow;
          break;
        case 'Ship Date':
          this.selectCheckShipDate = item.isshow;
          break;
        default:
          break;
      }
    });
  }

  showFullWindowImage(imageUrl: string): void {
    // Create the elements for the modal, image, and close button
    const modal = this.renderer.createElement('div');
    this.renderer.addClass(modal, 'preview-modal');

    const img = this.renderer.createElement('img');
    this.renderer.addClass(img, 'preview-image');
    this.renderer.setAttribute(img, 'src', imageUrl);
    this.renderer.appendChild(modal, img);

    const closeButton = this.renderer.createElement('button');
    this.renderer.addClass(closeButton, 'close-preview');
    closeButton.innerHTML = '&times;'; // or use an SVG/icon for the close button
    this.renderer.appendChild(modal, closeButton);

// Append the modal to the body
    this.renderer.appendChild(this.document.body, modal);

    // Add a click listener to the close button to remove the modal
    this.renderer.listen(closeButton, 'click', (event) => {
      event.stopPropagation(); // Prevent the event from bubbling up
      this.renderer.removeChild(this.document.body, modal);
    });

// Add a click listener to the modal to close it if the user clicks outside the image
    this.renderer.listen(modal, 'click', () => {
      this.renderer.removeChild(this.document.body, modal);
    });

// Prevent the modal from closing when clicking on the image
    this.renderer.listen(img, 'click', (event) => {
      event.stopPropagation(); // Stop propagation to prevent the modal click listener from triggering
    });

    // Optionally, add a click listener to the modal to close it if the user clicks outside the image
    // this.renderer.listen(modal, 'click', () => {
    //   this.renderer.removeChild(this.document.body, modal);
    // });

    // this.renderer.listen(img, 'click', () => {
    //   this.zoomScale = this.zoomScale === 1 ? 2 : 1; // Toggle between 1x and 2x zoom
    //   this.renderer.setStyle(img, 'transform', `scale(${this.zoomScale})`);
    //   this.renderer.setStyle(img, 'cursor', this.zoomScale === 1 ? 'zoom-in' : 'zoom-out');
    // });
  }

  formatDate(date: string): string {
    return <string>this.datePipe.transform(date, 'dd/MMM/yy hh:mm a', 'UTC');
  }

  protected readonly transformImageUrl = transformImageUrl;
}
