<div class="top-div">
  <div class="centered-div" *ngIf="samplemanager">
    <mat-card class="custom-mat-card">
      <p class="sample-manager-text">{{ formcaption }}</p>
      <div class="black-background">
        <!--        1Line-->

      </div>
      <mat-card-content class="truncate-text">
        <!-- Card content for single cell -->
        <!-- {{ newsItem.content }} -->
      </mat-card-content>
      <button *ngIf="SelfData.createsamplebutton" mat-raised-button class="view-more-btn"
        (click)="onButtonCreateSampleClick()">
        <mat-icon class="icon-spacing icon-scale">add</mat-icon>
        Create Sample
      </button>
      <!--      <button mat-raised-button class="view-more-btn-clear" type="button" (click)="onButtonClearAllClick()">-->
      <!--        Clear All-->
      <!--      </button>-->
      <button mat-raised-button class="view-more-btn1" (click)="onButtonSearchClick()">
        <mat-icon class="icon-spacing icon-scale">search</mat-icon>
        Search
      </button>
    </mat-card>

    <table class="full-width-tableEmpty">
      <tr>
        <td></td>
        <td></td>
      </tr>
    </table>

    <p-progressSpinner *ngIf="Speener.samplevisiblemode" class="spinner" styleClass="w-3rem h-3rem" strokeWidth="5"
      fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
<!--    <div class="centered-container">-->
<!--      <app-samplelistheader-->
<!--        *ngIf="modalService.isSampleHeader$ | async"-->
<!--      ></app-samplelistheader>-->
<!--    </div>-->

    <div class="centered-container1">
      <app-samplelisttable
        *ngIf="modalService.isSampleLabel$ | async"
      ></app-samplelisttable>
    </div>

    <div class="centered-container2">
      <app-samplelistgrid
        *ngIf="modalService.isSampleGrid$ | async"
      ></app-samplelistgrid>
    </div>
  </div>

  <div class="centered-div" *ngIf="contactmanager">
    <mat-card class="custom-mat-card-request">
      <p class="sample-manager-text">Contact Manager</p>
      <div class="black-background">
        <!--        1Line-->
        <div class="full-width-div">

        </div>
      </div>
      <mat-card-content class="truncate-text">
        <!-- Card content for single cell -->
        <!-- {{ newsItem.content }} -->
      </mat-card-content>
      <button mat-raised-button class="view-more-btn" (click)="onButtonCreateContactClick()">
        <mat-icon class="icon-spacing icon-scale">add</mat-icon>
        Create Contact
      </button>

      <button mat-raised-button class="view-more-btn2" (click)="onButtonSearchClick()">
        <mat-icon class="icon-spacing icon-scale1">search</mat-icon>
        Search
      </button>
    </mat-card>

    <table class="full-width-tableEmpty">
      <tr>
        <td></td>
        <td></td>
      </tr>
    </table>

    <!--    <p-progressSpinner *ngIf="Speener.sendoutvisiblemode" class="spinner"></p-progressSpinner>-->
    <p-progressSpinner *ngIf="Speener.sendoutvisiblemode" class="spinner" styleClass="w-3rem h-3rem" strokeWidth="5"
      fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
<!--    <div class="centered-container">-->
<!--      <app-samplelistheader-->
<!--        *ngIf="modalService.isSampleHeader$ | async"-->
<!--      ></app-samplelistheader>-->
<!--    </div>-->

    <div class="centered-container4">
      <app-contactslisttable
        *ngIf="modalService.isContactLabel$ | async"
      ></app-contactslisttable>
    </div>
  </div>

  <div class="centered-div" *ngIf="sendoutmanager">
    <mat-card class="custom-mat-card-request">
      <p class="sample-manager-text">Send Out Manager</p>
      <div class="black-background">
        <!--        1Line-->
      </div>

      <mat-card-content class="truncate-text">
        <!-- Card content for single cell -->
        <!-- {{ newsItem.content }} -->
      </mat-card-content>
      <!--      <div class="button-container">-->
      <button mat-raised-button class="view-more-btn" (click)="onButtonCreateSendOut()">
        <mat-icon class="icon-spacing icon-scale">add</mat-icon>
        Create Send Out
      </button>
      <button
        mat-raised-button
        class="view-more-btn2"
        (click)="onButtonCreateNotSettings()"
      >
        <mat-icon class="icon-spacing icon-scale1">manage_accounts</mat-icon>
        Notification Settings
      </button>

      <button mat-raised-button class="view-more-btn3" (click)="onButtonSearchClick()">
        <mat-icon class="icon-spacing icon-scale1">search</mat-icon>
        Search
      </button>
      <!--      </div>-->
      <!--            <button mat-raised-button class="view-more-btn1" (click)="onButtonSendOutSearchClick()">-->
      <!--                <mat-icon class="icon-spacing icon-scale">search</mat-icon>-->
      <!--                Search-->
      <!--            </button>-->

    </mat-card>

    <table class="full-width-tableEmpty">
      <tr>
        <td></td>
        <td></td>
      </tr>
    </table>
    <!--    <p-progressSpinner *ngIf="Speener.sendoutvisiblemode" class="spinner"></p-progressSpinner>-->
    <p-progressSpinner *ngIf="Speener.sendoutvisiblemode" class="spinner" styleClass="w-3rem h-3rem" strokeWidth="5"
      fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
<!--    <div class="centered-container">-->
<!--      <app-samplelistheader-->
<!--        *ngIf="modalService.isSampleHeader$ | async"-->
<!--      ></app-samplelistheader>-->
<!--    </div>-->

    <div class="centered-container1">
      <app-samplelisttable
        *ngIf="modalService.isSampleLabel$ | async"
      ></app-samplelisttable>
    </div>

    <div class="centered-container2">
      <app-samplelistgrid
        *ngIf="modalService.isSampleGrid$ | async"
      ></app-samplelistgrid>
    </div>

    <div class="centered-container4">
      <app-sendoutlist
        *ngIf="modalService.isSendoutList$ | async"
      ></app-sendoutlist>
    </div>

  </div>

  <div class="centered-div" *ngIf="sendoutpendingmanager">
    <mat-card class="custom-mat-card-request">
      <p class="sample-manager-text">Send Out Pending Manager</p>
      <div class="black-background">
        <!--        1Line-->
      </div>

      <mat-card-content class="truncate-text">
        <!-- Card content for single cell -->
        <!-- {{ newsItem.content }} -->
      </mat-card-content>


    </mat-card>

    <table class="full-width-tableEmpty">
      <tr>
        <td></td>
        <td></td>
      </tr>
    </table>
    <!--    <p-progressSpinner *ngIf="Speener.sendoutvisiblemode" class="spinner"></p-progressSpinner>-->
    <p-progressSpinner *ngIf="Speener.sendoutvisiblemode" class="spinner" styleClass="w-3rem h-3rem" strokeWidth="5"
      fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
<!--    <div class="centered-container">-->
<!--      <app-samplelistheader-->
<!--        *ngIf="modalService.isSampleHeader$ | async"-->
<!--      ></app-samplelistheader>-->
<!--    </div>-->

    <div class="centered-container1">
      <app-samplelisttable
        *ngIf="modalService.isSampleLabel$ | async"
      ></app-samplelisttable>
    </div>

    <div class="centered-container2">
      <app-samplelistgrid
        *ngIf="modalService.isSampleGrid$ | async"
      ></app-samplelistgrid>
    </div>

    <div class="centered-container4">
      <app-sendoutlist
        *ngIf="modalService.isSendoutList$ | async"
      ></app-sendoutlist>
    </div>

      <div class="centered-container4">
        <app-sendoutpendinglist
          *ngIf="modalService.isSendoutPendingList$ | async"
        ></app-sendoutpendinglist>
      </div>

  </div>

  <div class="centered-div" *ngIf="requestmanager">
    <mat-card class="custom-mat-card-request">
      <p class="sample-manager-text">List of requests</p>
      <div class="black-background">
        <!--        1Line-->
      </div>

      <mat-card-content class="truncate-text">
        <!-- Card content for single cell -->
        <!-- {{ newsItem.content }} -->
      </mat-card-content>
      <!--      <div class="button-container">-->
      <button mat-raised-button class="view-more-btn" (click)="createRequest()">
        <mat-icon class="icon-spacing icon-scale">add</mat-icon>
        Create Request
      </button>
      <!--      </div>-->
      <button mat-raised-button class="view-more-btn2" (click)="onButtonSearchClick()">
        <mat-icon class="icon-spacing icon-scale1">search</mat-icon>
        Search
      </button>
    </mat-card>

    <table class="full-width-tableEmpty">
      <tr>
        <td></td>
        <td></td>
      </tr>
    </table>
    <!--    <p-progressSpinner *ngIf="Speener.requestvisiblemode" class="spinner"></p-progressSpinner>-->
    <p-progressSpinner *ngIf="Speener.requestvisiblemode" class="spinner" styleClass="w-3rem h-3rem" strokeWidth="5"
      fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
<!--    <div class="centered-container">-->
<!--      <app-samplelistheader-->
<!--        *ngIf="modalService.isSampleHeader$ | async"-->
<!--      ></app-samplelistheader>-->
<!--    </div>-->

    <div class="centered-container1">
      <app-samplelisttable
        *ngIf="modalService.isSampleLabel$ | async"
      ></app-samplelisttable>
    </div>

    <div class="centered-container2">
      <app-samplelistgrid
        *ngIf="modalService.isSampleGrid$ | async"
      ></app-samplelistgrid>
    </div>

    <div class="centered-container4">
      <app-sendoutlist
        *ngIf="modalService.isSendoutList$ | async"
      ></app-sendoutlist>
    </div>

    <div class="centered-container4">
      <app-requestlist
        *ngIf="modalService.isRequestLabel$ | async"
      ></app-requestlist>
    </div>
  </div>

  <div class="centered-div" *ngIf="lookmanager">
    <mat-card class="custom-mat-card2">
      <p class="sample-manager-text">Look Manager</p>
      <div class="black-background">
        <!--        1Line-->

      </div>

      <mat-card-content class="truncate-text">
        <!-- Card content for single cell -->
        <!-- {{ newsItem.content }} -->
      </mat-card-content>
      <!--      <div class="button-container">-->
      <button mat-raised-button class="view-more-btn" (click)="onButtonCreateLookclick()">
        <mat-icon class="icon-spacing icon-scale">add</mat-icon>
        Create Look
      </button>

      <button mat-raised-button class="view-more-btn1" (click)="onButtonLookSearchClick()">
        <mat-icon class="icon-spacing icon-scale">search</mat-icon>
        Search
      </button>

    </mat-card>
    <table class="full-width-tableEmpty">
      <tr>
        <td></td>
        <td></td>
      </tr>
    </table>
    <p-progressSpinner *ngIf="Speener.lookbookvisiblemode" class="spinner" styleClass="w-3rem h-3rem" strokeWidth="5"
      fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
    <div class="centered-container3">
      <app-looklist
        *ngIf="modalService.isLookList$ | async"
      ></app-looklist>
    </div>

  </div>
  <div class="centered-div" *ngIf="pullsheetmanager">
    <mat-card class="custom-mat-card2">
      <p class="sample-manager-text">Pull Sheet</p>
      <div class="black-background">
        <!--        1Line-->
      </div>

      <!--            <mat-card-content class="truncate-text">-->
      <div class="row">
        <div class="forma-group">
          <mat-form-field class="selector-full-width-ps" appearance="fill">
            <mat-label>Email</mat-label>
            <input matInput [formControl]="psemailControl"/>
          </mat-form-field>
        </div>

        <div class="forma-group">
          <mat-form-field class="selector-full-width-ps" appearance="fill">
            <mat-label>Date</mat-label>
            <input matInput
              [matDatepicker]="picker2"
              [(ngModel)]="selectedDate"
              (dateChange)="onDateChange($event)">
            <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="forma-group">
          <mat-form-field class="selector-full-width-ps" appearance="fill">
            <mat-label>Period</mat-label>
            <mat-select [(ngModel)]="selectedPeriod" (selectionChange)="onPeriodChange($event)">
              <mat-option *ngFor="let period of period" [value]="period.value">
                {{ period.viewValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row1">
        <div class="forma-group1">
          <p-radioButton value="Pending" [(ngModel)]="ingredient" inputId="ingredient"></p-radioButton>
          <label for="ingredient" class="ml-2">Pending</label><br>
          <p-radioButton value="Accepted" [(ngModel)]="ingredient" inputId="ingredient"></p-radioButton>
          <label for="ingredient" class="ml-2">Accepted</label><br>
          <p-radioButton value="All" [(ngModel)]="ingredient" inputId="ingredient"></p-radioButton>
          <label for="ingredient" class="ml-2">All</label>
        </div>

        <div class="forma-group1">
          <p-radioButton value="Without SendOut" [(ngModel)]="ingredient1"
            inputId="ingredient1"></p-radioButton>
          <label for="ingredient1" class="ml-2">Without SendOut</label><br>
          <p-radioButton value="With SendOut" [(ngModel)]="ingredient1" inputId="ingredient1"></p-radioButton>
          <label for="ingredient1" class="ml-2">With SendOut</label><br>
          <p-radioButton value="All" [(ngModel)]="ingredient1" inputId="ingredient1"></p-radioButton>
          <label for="ingredient1" class="ml-2">All</label>
        </div>

        <div class="forma-group1">

        </div>
      </div>

      <!--            </mat-card-content>-->
      <!--      <div class="button-container">-->
      <button mat-raised-button class="view-more-btn" (click)="createPullSheet()">
        <mat-icon class="icon-spacing icon-scale">add</mat-icon>
        Create Pull Sheet
      </button>
      <button mat-raised-button class="view-more-btn2" (click)="onButtonPullSheetTemplateClick()">
        <mat-icon class="icon-spacing icon-scale1">settings_applications</mat-icon>
        Template Settings
      </button>
      <!--      &lt;!&ndash;      </div>&ndash;&gt;-->
      <!--      <button mat-raised-button class="view-more-btn-clear" type="button"-->
      <!--        (click)="onButtonPullSheetClearAllClick()">-->
      <!--        Clear All-->
      <!--      </button>-->
      <!--      <button mat-raised-button class="view-more-btn1" (click)="onButtonPullSheetSearchClick()">-->
      <!--        <mat-icon class="icon-spacing icon-scale">search</mat-icon>-->
      <!--        Search-->
      <!--      </button>-->
      <mat-divider [ngClass]="{
          'card-bottom-divider': samplemanager,
          'card-bottom-divider3': pullsheetmanager,
          'card-bottom-divider2': !samplemanager
        }"
      ></mat-divider>
    </mat-card>

    <table class="full-width-tableEmpty">
      <tr>
        <td></td>
        <td></td>
      </tr>
    </table>
    <div class="centered-container5">
      <app-selectpullsheet
        *ngIf="modalService.isSelectPullSheet$ | async"
      ></app-selectpullsheet>
    </div>
  </div>

  <div class="centered-div" *ngIf="cdllistmanager">
    <!--    <p-progressSpinner *ngIf="Speener.sendoutvisiblemode" class="spinner"></p-progressSpinner>-->
    <div class="centered-container4">
      <app-cdllist *ngIf="modalService.isCDLListVisibled$ | async">

      </app-cdllist>
    </div>
  </div>

  <div class="centered-div" *ngIf="contactmailmanager">
    <mat-card class="custom-mat-card2">
      <p class="sample-manager-text">Contact Mailing</p>
      <div class="black-background">
        <!--        1Line-->

      </div>

      <mat-card-content class="truncate-text">
        <!-- Card content for single cell -->
        <!-- {{ newsItem.content }} -->
      </mat-card-content>
      <!--      <div class="button-container">-->
      <button mat-raised-button class="view-more-btn" (click)="onMailEditorCreateClick()">
        <mat-icon class="icon-spacing icon-scale">add</mat-icon>
        Create Mailing
      </button>

<!--      <button mat-raised-button class="view-more-btn1" (click)="onButtonLookSearchClick()">-->
<!--        <mat-icon class="icon-spacing icon-scale">search</mat-icon>-->
<!--        Search-->
<!--      </button>-->

    </mat-card>
    <table class="full-width-tableEmpty">
      <tr>
        <td></td>
        <td></td>
      </tr>
    </table>
    <p-progressSpinner *ngIf="Speener.cmvisiblemode" class="spinner" styleClass="w-3rem h-3rem" strokeWidth="5"
      fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
    <div class="centered-container3">
      <app-contactemaillist
        *ngIf="modalService.iCMVisible$ | async"
      ></app-contactemaillist>
    </div>
  </div>

</div>
