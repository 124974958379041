import { Component } from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";

@Component({
  selector: 'app-createlsbframe',
  templateUrl: './createlsbframe.component.html',
  styleUrls: ['./createlsbframe.component.scss']
})
export class CreatelsbframeComponent {
  constructor(public modalService: ModalserviceService) {}
}
