<div class="black-background2">

  <div class="card example-container-cn mat-elevation-z3">
    <p-table
      #dt1
      [value]="firstContactsData"
      [paginator]="true"
      [rows]="10"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [rowsPerPageOptions]="[10, 20, 50, 100]"
      [styleClass]="'p-datatable-striped mat-elevation-z3 ' + selectedSize.class.toString()"
      selectionMode="single"
      [globalFilterFields]="['firstname', 'lastname', 'email', 'jobtitle', 'category', 'addressaddress1', 'addressaddress2', 'addresscity', 'addressstate', 'addresszipcode', 'mobile']"
      [tableStyle]="{'min-width': '50rem'}">
      <ng-template pTemplate="caption">
        <div class="flex">
          <!--                    <span class="p-inputgroup-addon custom-hover" matRipple (click)="onButtonAddClick()">-->
          <!--                        <i class="pi pi-plus" style="color: #000000"></i></span>-->
          <p-button class="info-btn" icon="pi pi-info" [rounded]="true" [outlined]="true" (click)="onInfoButtonClick()"/>
          <span class="p-input-icon-left ml-auto">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <i class="pi pi-search" style="color: #000000"></i>
              </span>
              <input pInputText type="text" (input)="onInput($event)" placeholder="Filter" [(ngModel)]="filtertext"/>
              <span class="p-inputgroup-addon custom-hover-red" matRipple (click)="clear(dt1)">
                <i class="pi pi-times" style="color: #000000"></i></span>
            </div>
          </span>

        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <!--                    -->
          <th style="width:5%">Options</th>
          <th style="width:8%">First Name</th>
          <th style="width:10%">Last Name</th>
          <th style="width:12%">Company Name</th>
          <th style="width:8%">Email</th>
          <th style="width:8%">Job Title</th>
          <th style="width:8%">Category</th>
          <th style="width:8%">Address 1</th>
          <th style="width:8%">Address 2</th>
          <th style="width:5%">City</th>
          <th style="width:5%">State</th>
          <th style="width:5%">Zip</th>
          <th style="width:30%">Direct Telefone</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-product>
        <tr [pSelectableRow]="product">
          <td>
            <div class="button-container-table">
              <p-button *ngIf="SelfData.selectlookmode != 0"
                label="Select" size="small" [outlined]="true" severity="secondary"
                [matMenuTriggerFor]="menu" (click)="currancecontact = product">
              </p-button>
            </div>
          </td>
          <td>{{ product?.["firstname"] }}</td>
          <td>
            <div class="vertical-center">
              {{ product?.["lastname"] }}
              <button *ngIf="product?.socialison" mat-icon-button class="center-icon"
                (click)="loadSocialMediaData(product); op.toggle($event)">
                <mat-icon class="scaled-icon">account_circle</mat-icon>
              </button>
            </div>

          </td>
          <td>{{ product?.["companyname"] }}</td>
          <td>{{ product?.["email"] }}</td>
          <td>{{product["jobtitle"]}}</td>
          <td>{{product["category"]}}</td>
          <td>{{product["addressaddress1"]}}</td>
          <td>{{product["addressaddress2"]}}</td>
          <td>{{product["addresscity"]}}</td>
          <td>{{product["addressstate"]}}</td>
          <td>{{product["addresszipcode"]}}</td>
          <td>{{product["mobile"]}}</td>

        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="onButtonEditViewClic(currancecontact, 1)">
    <mat-icon>visibility</mat-icon>
    <span>View Details</span>
  </button>
  <button mat-menu-item (click)="onButtonEditViewClic(currancecontact, 2)">
    <mat-icon>edit_note</mat-icon>
    <span>Edit Details</span>
  </button>
  <button mat-menu-item (click)="onDeleteButtonClick(currancecontact)">
    <mat-icon>delete</mat-icon>
    <span>Delete</span>
  </button>
</mat-menu>

<p-overlayPanel #op>
  <mat-card class="overlay-card">
    <div class="black-background-social">
      <div class="child-40">
        <div class="content-wrapper">
          <div class="thema-image" [style.background-image]="'url(' + defaultimagelink + ')'"></div>
        </div>
      </div>

      <div class="child-60">
        <div class="top-center-full-width">
          <span class="centered-text">{{ defaultname }}</span>
        </div>
        <mat-divider class="black-divider"></mat-divider>
        <div class="black-background-caption">
          <div class="caption-40">
            <span class="left-text-caption">CHANNEL</span>
          </div>
          <div class="caption-60">
            <span class="right-text-caption">FOLLOWERS</span>
          </div>
        </div>
        <mat-divider class="black-divider"></mat-divider>
        <div class="centered-div-social" *ngFor="let item of SelfData.socialmedia[0]?.data; let i = index"
          [ngStyle]="{ 'background-color': getColor(i) }">
          <span class="left-text" (click)="openLink(item.link)">{{ item.platform }}</span>
          <span class="right-text">{{ item.subscribers | formatCount }}</span>
        </div>

      </div>
    </div>

  </mat-card>
</p-overlayPanel>


<p-confirmDialog #cd [style]="{width: '25vw'}" [closable]="true">
  <ng-template pTemplate="footer">
    <div class="button-container-request">
      <button mat-raised-button class="button-secondary" style="margin-right: 10px; width: 50%" (click)="cd.reject()">
        No
      </button>
      <button mat-raised-button class="button-primary" style="margin-right: 0; width: 50%" (click)="cd.accept()">
        Yes
      </button>
    </div>
  </ng-template>
</p-confirmDialog>
