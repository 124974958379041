<form [formGroup]="myForm" (ngSubmit)="submit()" autocomplete="off">
    <button mat-icon-button type="button" class="close-btn" (click)="modalService.invoiceClose()">
        <mat-icon>close</mat-icon>
    </button>

    <p class="sample-manager-text">Add Final Prices</p>

    <div class="black-background-invoice-table">
        <div class="full-width-caption">
            <p class="caption-text">Add the final price expressed in {{SelfData.selcurrancy}} for all selected samples</p>

            <div class="card">
                <p-table
                        [value]="SelfData.setSelectedSample"
                        [paginator]="true"
                        [rows]="10"
                        [showCurrentPageReport]="true"
                        [scrollable]="true"
                        [scrollHeight]="'calc(80vh - 240px)'"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [rowsPerPageOptions]="[10, 20, 50, 100]"
                        [styleClass]="'p-datatable-striped mat-elevation-z3 ' + selectedSize.class.toString()"
                        selectionMode="single"
                        [tableStyle]="{'min-width': '50rem'}">
                    <ng-template pTemplate="header">
                        <tr>
                            <!--                    -->

                            <th style="width:130rem">Designer<br>Collection<br>Season</th>
                            <th style="width:130rem">Type<br>Barcode<br>Color</th>
                            <th style="width:130rem">Gender<br>Item Count<br>Region</th>
                            <th style="width:20%">Description</th>
                            <th style="width:10%">Image</th>
                            <th style="width:10%">Final Price {{SelfData.selcurrancy}}</th>
                            <th style="width:10%">Discount %</th>
                            <th style="width:100rem">Final {{SelfData.selcurrancy}}</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-product>
                        <tr [pSelectableRow]="product">
                            <td>{{product["Designer"]}}<br>{{product["Collection"]}}<br>{{product["Season"]}}</td>
                            <td>{{product["Type"]}}<br>{{product["barcode"]}}<br>{{product["Color"]}}</td>
                            <td>{{product["Gender"]}}<br>
                                {{product["ItemCount"]}}</td>
                            <td>{{product["Description"]}}</td>
                            <td><img [src]="product['Image Link']" alt="Image" class="image-size-80x80 shadow-4"></td>
                            <td><mat-form-field class="price-input" appearance="fill">
                                <mat-label>Enter price</mat-label>
                                <input matInput type="number" [min]="0" [value]="product['amount']" step="0.1" (change)="updateFinalValue($event, product)">
                            </mat-form-field></td>
                          <td><mat-form-field class="price-input" appearance="fill">
                            <mat-label>Discount</mat-label>
                            <input matInput [min]="0" [max]="100" type="number" [value]="product['discount']" step="5.0" (change)="defaultDiscountChanged($event, product)">
                          </mat-form-field></td>
<!--                            <td><mat-form-field class="price-input" appearance="fill">-->
<!--                                <mat-label>Discount</mat-label>-->
<!--                                <mat-select [(value)]="percentDiscount" (valueChange)="defaultDiscountChanged($event, product)">-->
<!--                                    <mat-option *ngFor="let discount of discounts" [value]="discount">{{discount}}</mat-option>-->
<!--                                </mat-select>-->
<!--                            </mat-form-field></td>-->
                          <td>{{ product["totalprice"] | customCurrency:SelfData.selcurrancy:'symbol-narrow':'1.2-2' }}</td>
                        </tr>
                    </ng-template>
                  <ng-template pTemplate="footer">
                    <tr>
                      <td colspan="3" class="text-right">Totals</td>
                      <td>{{lastYearTotal | customCurrency:SelfData.selcurrancy:'symbol-narrow':'1.2-2'}}</td>
<!--                      <td>{{thisYearTotal | currency: 'USD'}}</td>-->
                    </tr>
                  </ng-template>
                </p-table>
            </div>

        </div>
    </div>

    <div class="button-container-invoice">
        <button mat-raised-button class="button-settings-invoice" type="button" (click)="onButtonInvoiceCreateClick()">Create Invoice</button>
    </div>
</form>
