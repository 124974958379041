{
  "counters": {
    "u_row": 0,
    "u_column": 0,
    "u_content_menu": 0,
    "u_content_text": 0,
    "u_content_image": 0,
    "u_content_button": 0,
    "u_content_divider": 0,
    "u_content_heading": 0
  },
  "body": {
    "id": "empty-template",
    "rows": [],
    "values": {
      "backgroundColor": "#FFFFFF",
      "padding": "0px",
      "anchor": "",
      "hideDesktop": false,
      "displayCondition": null,
      "_meta": {
        "htmlID": "u_body",
        "htmlClassNames": "u_body"
      }
    },
    "headers": [],
    "footers": []
  },
  "schemaVersion": 12
}
