<button mat-icon-button type="button" class="close-btn" (click)="modalService.srFrameClose()">
  <mat-icon>close</mat-icon>
</button>
<p class="sample-manager-customer">{{ caption }}</p>
<div class="totalorder">
  <label class="txtdiv2"><span class="onlybold">${{totalpayment.toFixed(2)}}</span></label>
</div>
<div class="maneydiv">
  <div class="summary-row">
    <span class="summary-label">Amount given by customer</span>
    <nz-input-number
      [(ngModel)]="paymentvalue"
      [nzFormatter]="formatterDollar"
      nzSize="large"
      nzMin="0"
      class="summary-input">
    </nz-input-number>
  </div>
</div>
<div class="paydiv">
  <div class="pay-button-container">
    <button
      class="pay-button"
      (click)="processPaymentCash()"
      [disabled]="paymentvalue < totalpayment"
    >
      <div class="pay-left">
        <span class="pay-text">Cash</span>
      </div>
    </button>
  </div>
  <div class="pay-button-container">
    <button
      class="pay-button"
      (click)="processPaymentOther()"
      [disabled]="paymentvalue < totalpayment"
    >
      <div class="pay-left">
        <span class="pay-text">Other Method</span>
      </div>
    </button>
  </div>
</div>

