import {AfterViewInit, Component, Inject, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {animate, state, style, transition, trigger} from "@angular/animations";
import {DOCUMENT} from "@angular/common";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MessageService} from "primeng/api";
import {ServiceService} from "../../services/service.service";
import {HashService} from "../../services/hash.service";
import { HttpClient } from "@angular/common/http";
import {DeviceDetectorService} from "ngx-device-detector";
import {ModalserviceService} from "../../services/modalservice.service";
import {Subscription} from "rxjs";
import {SelfData} from "../../models/struct";

@Component({
  selector: 'app-qrcodescan',
  templateUrl: './qrcodescan.component.html',
  styleUrls: ['./qrcodescan.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0 }), animate(300)]),
      transition(':leave', [animate(300, style({ opacity: 0 }))]),
    ]),
  ],
})
export class QrcodescanComponent implements OnInit, OnDestroy, AfterViewInit {
  deviceClass: string = "";
  footerClass: string = "";
  deviceClassAddress: string = "";
  imageUrl: string = '';
  signatureimage: string = '';
  isLandscape: boolean = false;
  dateofpull: Date | null = null;
  dateoffitting: Date | null = null;
  dateofse: Date | null = null;
  dateofer: Date | null = null;
  dateoffr: Date | null = null;
  extdate: Date | null = null;
  numbervalue: string = ""
  numbervalue1: string = ""
  textvalue: string = ""
  textvalue1: string = ""
  textvalue2: string = ""
  textvalue3: string = ""
  textvalue4: string = ""
  textvalue5: string = ""
  textvalue6: string = ""
  textvalue7: string = ""
  textvalue8: string = ""
  signatureImg: string = "";
  selectedFile: File | null = null;
  isDesktopDevice: boolean = this.deviceService.isDesktop();
  constructor(private renderer: Renderer2, @Inject(DOCUMENT) private document: Document,
              private yourappsService: YourappsserviceService,
              private messageService: MessageService,
              private serviceservice: ServiceService,
              private hashService: HashService,
              private http: HttpClient,
              private deviceService: DeviceDetectorService, public modalService: ModalserviceService) {
    this.setDeviceClass();
  }

  setDeviceClass(): void {
    this.deviceClass = this.deviceService.isMobile() ? 'mobilemaincard' : 'pcmaincard';
    this.footerClass = this.deviceService.isMobile() ? 'mobilebutton-container-dm-ps' : 'pcbutton-container-dm-ps';
    this.deviceClassAddress = this.deviceService.isMobile() ? 'mobileaddressdiv' : 'pcaddressdiv';
    // this.isLandscape = window.innerWidth > window.innerHeight ? 'islandscape' : 'isnlandscape';
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  setOrientation() {
    this.isLandscape = window.innerWidth > window.innerHeight;
  }

  private subscription?: Subscription;

  ngOnInit(): void {
    this.subscription = this.yourappsService.logoimage$.subscribe((image) => {
      this.imageUrl = image
      console.log("this.imageUrl", this.imageUrl)
    });
    this.renderer.setStyle(this.document.body, 'background-color', 'rgba(150,150,150,0.5)');
    this.setOrientation();
    window.addEventListener('resize', this.setOrientation.bind(this))
  }

  openInstagramLink(seturl: string) {
    let url = seturl

    if (url && !url.startsWith('http')) {
      url = 'https://' + url;
    }

    if (url) {
      window.open(url, '_blank'); // This will open the link in a new tab
    }
  }


  protected readonly SelfData = SelfData;
}
