import { AfterViewInit, ChangeDetectorRef, Component, NgZone, OnInit, ViewChild } from '@angular/core';
import {
  GetLookList,
  GetLookListData,
  GetLookNameList,
  GetLookNameListData,
  SelfData,
  SetSampleListData,
  transformImageUrl
} from "../../models/struct";
import { ModalserviceService } from "../../services/modalservice.service";
import { FormBuilder } from "@angular/forms";
import { HashService } from "../../services/hash.service";
import { MessageboxComponent } from "../../modals/messagebox/messagebox.component";
import { ServiceService } from "../../services/service.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { YourappsserviceService } from "../../services/yourappsservice.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import {firstValueFrom} from "rxjs";

@Component({
    selector: 'app-looklist',
    templateUrl: './looklist.component.html',
    styleUrls: ['./looklist.component.scss']
})
export class LooklistComponent implements AfterViewInit, OnInit {
    @ViewChild('paginator') paginator: MatPaginator | null = null;
    @ViewChild('paginator1') paginator1: MatPaginator | null = null;
    loolname: String = ""
    sizes!: any[];

    selectedSize: any = '';
    currentSample: SetSampleListData | null = null;

    displayedColumns: string[] = [
        'sc',
        'lookname'
    ];

    displayedLookColumns: string[] = [
        'Designer',
        'Type',
        'Description',
        'Image',
    ];

    dataSource = new MatTableDataSource<GetLookNameListData>(SelfData.lookname[0].data);
    dataSourceList!: MatTableDataSource<GetLookListData>;

    constructor(
        public modalService: ModalserviceService,
        public fb: FormBuilder,
        private hashService: HashService,
        public messageBox: MessageboxComponent,
        private serviceservice: ServiceService,
        private _snackBar: MatSnackBar,
        private yourappsService: YourappsserviceService,
        private ngZone: NgZone,
        private cdr: ChangeDetectorRef
    ) {
        this.sizes = [
            { name: 'Small', class: 'p-datatable-sm' }
        ];
        this.selectedSize = this.sizes[0]
        this.dataSource = new MatTableDataSource<GetLookNameListData>(
            SelfData.lookname[0].data
        );
        // if (SelfData.lookname[0].data.length > 0) {
        //     this.onItemClick(SelfData.lookname[0].data[0])
        // }
    }

    public get setdataSource() {
        return this.dataSource;
    }

    ngAfterViewInit(): void {
        this.dataSource.paginator = this.paginator;
        if (this.dataSourceList && this.paginator1 && !this.dataSourceList.paginator) {
            this.dataSourceList.paginator = this.paginator1;
        }

        this.yourappsService.reloadLookNames.subscribe((description) => {
          if (SelfData && SelfData.lookname && SelfData.lookname[0] && SelfData.lookname[0].data) {
            // Теперь безопасно обращаемся к SelfData.lookname[0].data
            this.dataSource = new MatTableDataSource<GetLookNameListData>(
              SelfData.lookname[0]?.data ?? []
            );
            this.loolname = ""
            this.dataSource.paginator = this.paginator;
            // SelfData.looks[0].data = []
            if (SelfData.looks && SelfData.looks[0] && SelfData.looks[0].data) {
              this.dataSourceList = new MatTableDataSource<GetLookListData>(SelfData.looks[0].data);
            } else {
              // Обработайте случай, когда данные отсутствуют. Например, можете инициализировать dataSourceList пустым массивом.
              this.dataSourceList = new MatTableDataSource<GetLookListData>([]);
            }

            if (this.dataSourceList) {
              this.dataSourceList.paginator = this.paginator1;
            }
            if (SelfData.lookname[0].data.length > 0) {
              this.onItemClick(SelfData.lookname[0].data[0])
            }
            this.cdr.detectChanges();
          }
        });
    }

    get firstLookData(): GetLookListData[] {
        if (this.SelfData && this.SelfData.looks && this.SelfData.looks[0] && this.SelfData.looks[0].data) {
            return this.SelfData.looks[0].data;
        }
        return [];
    }

    onButtonSelectLookClick(element: any) {
        console.log(element);
        this.onItemClick({ data: element }).then(() => {
            SelfData.looks[0].data.forEach((checkedSample) => {
                let barcodeValue = checkedSample['barcode'];
                console.log(barcodeValue);
                this.yourappsService.setSelectedSample(barcodeValue);
            });
        });
        this.modalService.lookclose();
    }

    ngOnInit(): void {
        if (SelfData.lookname[0].data.length > 0) {
            this.onItemClick({ data: SelfData.lookname[0].data[0] });
        }
    }

    iLookResult: GetLookList[] = [];

    private getAccountID(): number {
        return SelfData.account[0].data[0].parentid === 0
            ? SelfData.account[0].data[0].id
            : SelfData.account[0].data[0].parentid;
    }

    onButtonEditClick(element: any) {
        this.onItemClick(element)
        this.modalService.createlookopen()
    }

    onItemClick(event: any): Promise<void> {
        console.log(event.data);
        return new Promise((resolve, reject) => {
            if (event && event.data) {
                console.log(event.data);
                this.loolname = event.data.description;

                let obs$ = this.serviceservice.setLookList({
                    accountid: this.getAccountID(),
                    lookid: event.data.id
                });

                firstValueFrom(obs$).then(value => {
                    if (value) {
                        this.iLookResult[0] = value;
                        SelfData.looks = this.iLookResult;
                        this.dataSourceList = new MatTableDataSource<GetLookListData>(SelfData.looks[0].data);
                        if (this.dataSourceList) {
                            this.dataSourceList.paginator = this.paginator1;
                        }
                        this.yourappsService.setLokkData(event, SelfData.looks[0].data);
                        resolve();
                    } else {
                        reject("No value received from obs$.");
                    }
                }).catch(err => reject(err));
            } else {
                reject("Event data is missing.");
            }
        });
    }

    protected readonly SelfData = SelfData;

  protected readonly transformImageUrl = transformImageUrl;
}
