<button mat-icon-button type="button" class="close-btn" (click)="onButtonCloseClick()">
    <mat-icon>close</mat-icon>
</button>
<p class="sample-manager-text">{{formcaption}}</p>

<div class="row">
    <div class="forma-group">
        <mat-form-field class="selector-full-width" appearance="fill">
            <mat-label>Description</mat-label>
            <input type="text" matInput [formControl]="collectionFormControl">
            <mat-error *ngIf="collectionFormControl.hasError('required')">
                Description is <strong>required</strong>
            </mat-error>
        </mat-form-field>
    </div>
    <div class="forma-group">

    </div>
</div>

<div class="imagelist">
    <div class="updatebutton">
        <button class="btnupdatte" mat-raised-button type="button" (click)="uploadImageFiles()">
            Add
<!--            <mat-icon class="center-icon">add</mat-icon>-->
        </button>
        <div class="progressbar">
            <smart-progress-bar theme="fm" *ngIf="progressbarshow" #progressbar id="progressbar1" class="progressbar"
                    [showProgressValue]="true"></smart-progress-bar>
        </div>
    </div>
    <div class="file-container-images">
        <mat-card *ngFor="let item of selectedImageFiles; let i = index" class="card-item shadow-4">
            <button mat-mini-fab type="button" class="close-button" (click)="deleteImageFile($event, i)">
                <mat-icon class="custom-icon">close</mat-icon>
            </button>
            <img [src]="item.url" (click)="showFullWindowImage(item.url)" class="thumbnail" alt="Image thumbnail">
        </mat-card>

        <!-- Single preview modal for full window image preview -->
        <div *ngIf="previewVisible" class="overlay" (click)="hideFullWindowImage()">
            <img [src]="previewUrl" class="overlay-content" alt="Full image preview"
                    (mousedown)="startDragging($event)" (wheel)="zoomImage($event)">
        </div>
    </div>
</div>

<div class="button-container">
    <button mat-raised-button class="button-settings" type="button" [disabled]="!uploadbuttonenable" (click)="onButtonUploadClick()">
        Confirm
    </button>
</div>

<p-confirmDialog *ngIf="showdialog" #cd [style]="{width: '25vw'}" [closable]="true">
    <!--  <ng-template pTemplate="header">-->
    <!--    <h3 class="red-text">This item is on loan!</h3>-->
    <!--  </ng-template>-->
    <ng-template pTemplate="footer">
        <!--        <button type="button" pButton label="No" (click)="cd.reject()"></button>-->
        <div class="button-container-request">
            <button mat-raised-button class="button-secondary" style="margin-right: 10px; width: 50%"
                    (click)="cd.reject()">
                No
            </button>
            <button mat-raised-button class="button-primary" style="margin-right: 0; width: 50%" (click)="cd.accept()">
                Yes
            </button>
        </div>
        <!--        <button type="button" pButton label="Yes" (click)="cd.accept()"></button>-->
    </ng-template>
</p-confirmDialog>
