import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {SamplesComponent} from "../../pages/samples/samples.component";
import {ModalserviceService} from "../../services/modalservice.service";
import {LocalStorageService} from "../../services/local-storage.service";
import {Router} from "@angular/router";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {SelfData, transformImageUrl} from "../../models/struct";

@Component({
  selector: 'app-top-panel-sale',
  templateUrl: './top-panel-sale.component.html',
  styleUrl: './top-panel-sale.component.scss'
})
export class TopPanelSaleComponent implements OnInit, AfterViewInit {
  @ViewChild(SamplesComponent, {static: false}) samplesComponent: SamplesComponent | undefined;
  title = 'Fashion Management';
  auth = false;
  accountname = '';
  rpmonitoring = 'Managment';
  events = 'Rental ShowRoom';
  samples = 'Managment';
  contacts = 'Rental ShowRoom';
  imageUrl: string = '';

  constructor(
    public modalService: ModalserviceService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private yourappsService: YourappsserviceService
  ) {
    this.setRoutesIfNotInitialPage();
    this.yourappsService.reloadAvatarImage.subscribe(() => {
      this.LoadAvatarImage();
    });
  }

  setRoutesIfNotInitialPage() {
    const currentUrl = this.router.url;
    if (currentUrl !== '/') {
      this.setRoutes();
    }
  }

  setRoutes() {
    const myAccountArray = this.localStorageService.getItem('myAccountArray');
    console.log("myAccountArray", myAccountArray)

    if (!myAccountArray) {
      this.router.navigate(['']);
    }
  }

  ngOnInit(): void {
    this.yourappsService.auth$.subscribe((auth) => {
      this.auth = auth;
    });
    this.yourappsService.accountname$.subscribe((account) => {
      this.accountname = account;
    });
    this.LoadAvatarImage();
  }

  logout() {
    window.location.reload();
    this.localStorageService.clear()
    this.yourappsService.updateProgrammsCount(0);
    this.modalService.programmsclose();
    this.modalService.rpmonitorinclose();
    this.modalService.eventsclose();
    this.modalService.samplesclose();
    this.modalService.contactsclose();
    this.yourappsService.setAuth(false);
    SelfData.account = [];
    this.yourappsService.setAccountName('');
    this.yourappsService.reloadDataRequest;
    this.yourappsService.reloadAvatarImage;
    this.LoadAvatarImage();
    this.router.navigate(['']);
    // this.yourappsService.setLogOut([], 1)
  }

  // transformImageUrl(url: string): string {
  //   if (url && url.startsWith('http://108.178.189.70:6330/')) {
  //     return url.replace('http://108.178.189.70:6330/', '/api/local/');
  //   }
  //   return url;
  // }

  LoadAvatarImage() {
    const timestamp = new Date().getTime(); // Get the current timestamp
    if (SelfData.account[0]?.data[0]?.imageLink) {
      this.imageUrl = transformImageUrl(`${SelfData.account[0].data[0].imageLink}?${timestamp}`); // serveri
      // this.imageUrl = `${SelfData.account[0].data[0].imageLink}?${timestamp}`; // lokali
    } else {
      this.imageUrl = ''; // Set imageUrl to an empty string if imageLink is undefined
    }
  }

  ngAfterViewInit(): void {

  }

  onNotificationsClick() {
    this.modalService.openCS()
  }

  onHelpClick() {
    window.open('https://fm.welcomeapps.net/Help.html', '_blank');
  }
}
