<div class="card flex justify-content-center">
  <!--  <button-->
  <!--    nz-button-->
  <!--    nzBlock-->
  <!--    nzType="primary"-->
  <!--    nzShape="round"-->
  <!--  >Primary</button>-->
  <!--  <p-panelMenu class="layout-menu" [model]="items" [multiple]="true"></p-panelMenu>-->
  <ul nz-menu nzMode="inline" nzTheme="light" [nzInlineCollapsed]="isCollapsed">
    <li
      nz-menu-item
      nz-tooltip
      nzTooltipPlacement="right"
      nzSelected
      (click)="onMainClick()"
    >
      <!--      <span nz-icon nzType="mail"></span>-->
      <span nz-icon nzType="home"></span>
      <span>Home</span>
    </li>
    <li
      nz-menu-item
      nz-tooltip
      nzTooltipPlacement="right"
      (click)="onSetupGeneralClick()"
    >
      <!--      <span nz-icon nzType="mail"></span>-->
      <span nz-icon nzType="product"></span>
      <span>General</span>
    </li>
    <li [nzDisabled]="SelfData.generalsetup[0]?.data?.length === 0" nz-submenu nzTitle="Sales" nzIcon="shopping-cart">
      <ul>
        <!--        <li nz-menu-item>პაციენტები</li>-->
<!--        <li nz-menu-item>Open/Close</li>-->
        <li nz-menu-item (click)="onSellClick()">Sell</li>
        <li nz-menu-item>Credit</li>
        <li nz-menu-item>Rent</li>
        <li nz-menu-item>Gift</li>
<!--        <li nz-menu-item>Sale history</li>-->
<!--        <li nz-menu-item>Cash managment</li>-->
<!--        <li nz-menu-item>Settings</li>-->
        <!--        <li nz-submenu nzTitle="კლასიფიკატორები">-->
        <!--          <ul>-->
        <!--&lt;!&ndash;            <li nz-menu-item><i nz-icon nzType="read" nzTheme="outline"></i> ICD10 </li>&ndash;&gt;-->
        <!--&lt;!&ndash;            <li nz-menu-item><i nz-icon nzType="read" nzTheme="outline"></i> NCSP</li>&ndash;&gt;-->
        <!--&lt;!&ndash;            <li nz-menu-item><i nz-icon nzType="read" nzTheme="outline"></i> ICPC2</li>&ndash;&gt;-->
        <!--&lt;!&ndash;            <li nz-menu-item><i nz-icon nzType="read" nzTheme="outline"></i> ლაბორატორია</li>&ndash;&gt;-->
        <!--          </ul>-->
        <!--        </li>-->
        <!--        <li nz-submenu nzTitle="სერვისები">-->
        <!--&lt;!&ndash;          <ul>&ndash;&gt;-->
        <!--&lt;!&ndash;            <li nz-menu-item (click)="onServicebiClick()">სერვისები</li>&ndash;&gt;-->
        <!--&lt;!&ndash;          </ul>&ndash;&gt;-->
        <!--&lt;!&ndash;          <ul>&ndash;&gt;-->
        <!--&lt;!&ndash;            <li nz-menu-item (click)="onServicebiGroupeClick()">სერვისების დაჯგუფება</li>&ndash;&gt;-->
        <!--&lt;!&ndash;          </ul>&ndash;&gt;-->
        <!--&lt;!&ndash;          <ul>&ndash;&gt;-->
        <!--&lt;!&ndash;            <li nz-menu-item (click)="onMachveneblebiClick()">მაჩვენებლები</li>&ndash;&gt;-->
        <!--&lt;!&ndash;          </ul>&ndash;&gt;-->
        <!--&lt;!&ndash;          <ul>&ndash;&gt;-->
        <!--&lt;!&ndash;            <li nz-menu-item (click)="onMedDocslebiClick()">სამედიცინო დოკუმენტები</li>&ndash;&gt;-->
        <!--&lt;!&ndash;          </ul>&ndash;&gt;-->
        <!--        </li>-->
        <!--        <li nz-menu-item (click)="onDrugsClick()">მედიკამენტები</li>-->
        <!--        <li nz-menu-item>საწყობი</li>-->
      </ul>
    </li>
    <!--    <span nz-icon nzType="audit" nzTheme="outline"></span>-->
    <!--    <li nz-submenu nzTitle="რეგისტრატურა" nzIcon="audit">-->
    <!--      <ul>-->
    <!--        <li nz-menu-item (click)="onVisitebiClick()">ვიზიტების მართვა</li>-->
    <!--      </ul>-->
    <!--    </li>-->
    <li [nzDisabled]="SelfData.generalsetup[0]?.data?.length === 0" nz-submenu nzTitle="Catalog" nzIcon="tag">
      <ul>
        <li nz-menu-item (click)="onProductClick()">Products</li>
<!--        <li nz-menu-item>Promotions</li>-->
<!--        <li nz-menu-item>Price books</li>-->
<!--        <li nz-menu-item>Brands</li>-->
        <li nz-menu-item (click)="onSuppliersClick()">Suppliers</li>
<!--        <li nz-menu-item>Product tag</li>-->
<!--        <li nz-menu-item>Product categories</li>-->
<!--        <li nz-menu-item>Gift cards</li>-->
      </ul>
    </li>
    <li [nzDisabled]="SelfData.generalsetup[0]?.data?.length === 0" nz-submenu nzTitle="Inventory" nzIcon="book">
      <ul>
        <li nz-menu-item (click)="onSCClick()">Stock control</li>
<!--        <li nz-menu-item>Audit</li>
        <li nz-menu-item>Traking number</li>-->
      </ul>
    </li>
    <li [nzDisabled]="SelfData.generalsetup[0]?.data?.length === 0" nz-submenu nzTitle="Customers" nzIcon="usergroup-add">
      <ul>
        <li nz-menu-item (click)="onCustomerClick()">Customers</li>
<!--        <li nz-menu-item>Groups</li>-->
      </ul>
    </li>
    <li [nzDisabled]="SelfData.generalsetup[0]?.data?.length === 0" nz-submenu nzTitle="Reporting" nzIcon="bar-chart">
      <ul>
        <li nz-menu-item (click)="onSalesReportClick()">Sales Report</li>
      </ul>
    </li>
    <li [nzDisabled]="SelfData.generalsetup[0]?.data?.length === 0" nz-submenu nzTitle="Settings" nzIcon="setting">
      <ul>
        <!--        <li nz-menu-item (click)="onUsersListClick()">Users</li>-->
        <li nz-menu-item (click)="onOutletsClick()">Outlets and registers</li>
        <!--        <li nz-menu-item>Payment types/On-account</li>-->
        <!--        <li nz-menu-item>On-account</li>-->
        <!--        <li nz-menu-item>Sales taxes</li>-->
        <!--        <li nz-menu-item>Loyalty</li>-->
        <!--        <li nz-menu-item>Users</li>-->
        <!--        <li nz-menu-item>Security</li>-->
      </ul>
    </li>
    <li [nzDisabled]="SelfData.generalsetup[0]?.data?.length === 0"
      nz-menu-item
      nz-tooltip
      nzTooltipPlacement="right"
      (click)="onMainClick()"
    >
      <!--      <span nz-icon nzType="mail"></span>-->
      <span nz-icon nzType="gold"></span>
      <span>Precious metals prices</span>
    </li>
  </ul>
</div>
