import {AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild,} from '@angular/core';
import {ModalserviceService} from '../../services/modalservice.service';
import {FormBuilder, FormControl, FormGroup, Validators,} from '@angular/forms';
import {HashService} from '../../services/hash.service';
import {MessageboxComponent} from '../../modals/messagebox/messagebox.component';
import {ServiceService} from '../../services/service.service';
import {YourappsserviceService} from '../../services/yourappsservice.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NgxImageCompressService} from 'ngx-image-compress';
import {
  GetAddressList,
  GetAddressListData,
  GetCompanyList,
  GetCompanyListData,
  GetContacList,
  GetContactCustomFieldsData,
  GetPronounList,
  PrcContacts,
  SelfData,
  SetContactsListData,
} from '../../models/struct';
import {MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger,} from '@angular/material/autocomplete';
import {BehaviorSubject, map, Observable, of, startWith, Subscription} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {NzNotificationService} from "ng-zorro-antd/notification";

@Component({
  selector: 'app-createcontactsdialog',
  templateUrl: './createcontactsdialog.component.html',
  styleUrls: ['./createcontactsdialog.component.scss'],
})
export class CreatecontactsdialogComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator | null = null;
  datemask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  isVisible: boolean = true;
  companyid: number = 0
  createbuttoncaption = 'Create';
  myForm: FormGroup;
  operationType: number = 0
  contactid: number = 0
  displayedColumns: string[] = ['id', 'description', 'actions'];
  filteredCountries: Observable<any[]> = of([]);
  filteredCountriesh: Observable<any[]> = of([]);
  filteredCountrieso: Observable<any[]> = of([]);
  filteredStates: Observable<any[]> = of([]);
  filteredStatesh: Observable<any[]> = of([]);
  filteredStateso: Observable<any[]> = of([]);
  countries: any[] = [];
  countriesh: any[] = [];
  countrieso: any[] = [];
  states: any[] = [];
  statesh: any[] = [];
  stateso: any[] = [];
  fieldscount: GetContactCustomFieldsData[] = [];
  autoCompletes: { [key: string]: MatAutocomplete } = {};
  ELEMENT_DATA: GetAddressListData[] = [];
  dataSource = new MatTableDataSource<GetAddressListData>(this.ELEMENT_DATA);
  inputType: 'text' | 'email' = 'text';
  sizes!: any[];

  selectedSize: any = '';
  selectedFile: File | null = null;
  customField: boolean = false; // Or false
  selectedImageFiles: { file: File | null; url: string; filename: string }[] =
    [];
  public captionDepartment: string = '';
  public captionPronoun: string = '';
  public captionSalutation: string = '';
  public captionSpouse: string = '';
  public captionFirstname: string = '';
  public captionAssistantName: string = '';
  public captionAssistantTitle: string = '';
  public captionLastName: string = '';
  public captionMobile: string = '';
  public captionAssistentMobile1: string = '';
  public captionAssistentMobile2: string = '';
  public captionEmail: string = '';
  public captionAltEmail: string = '';
  public captionAssistentEmail: string = '';
  public captionEICode: string = '';
  public captionvcemail: string = '';
  public captiontaxid: string = '';
  public captionComments: string = '';
  public captionSideNotes: string = '';
  public captionJobTitle: string = '';
  public captionCategory: string = '';
  public captionJD: string = '';
  public captionType: string = '';
  public captionRegion: string = '';
  public captionRB: string = '';
  public captionGroups: string = '';
  public captionAssociated: string = '';
  public captionInstagram: string = '';
  public captionFacebook: string = '';
  public captionTiktok: string = '';
  public captionYoutube: string = '';
  public captionTwitter: string = '';
  public captionLinkedln: string = '';
  public captionCompanyName: string = '';
  public captionZipCode: string = '';
  public captionCareOff: string = '';
  public captionCountri: string = '';
  public captionAddress1: string = '';
  public captionDirectPhone: string = '';
  public captionAddress2: string = '';
  public captionWebsite: string = '';
  public captionCity: string = '';
  public captioncontemail: string = '';
  public captioncontotheremail: string = '';
  public captionState: string = '';
  public captionCareOff1: string = '';
  public captionState1: string = '';
  public captionAddress11: string = '';
  public captionZipCode1: string = '';
  public captionAddress21: string = '';
  public captionCountri1: string = '';
  public captionCity1: string = '';
  public captionDirectPhone1: string = '';
  public captionCareOff2: string = '';
  public captionCountri2: string = '';
  public captionAddress12: string = '';
  public captioncontotheremail2: string = '';
  public captionAddress22: string = '';
  public captionDirectPhone2: string = '';
  public captionCity2: string = '';
  public captionGeneralPhone: string = '';
  public captionState2: string = '';
  public captionMobile1: string = '';
  public captionZipCode2: string = '';
  public captionMobile2: string = '';
  // departmentFormControl = new FormControl({ value: '', disabled: true }, [
  //   Validators.required,
  // ]);
  // salutationControl = new FormControl<string>('');
  // myPronounControl = new FormControl<string>('');
  // spouseControl = new FormControl<string>('');
  // assistantnameControl = new FormControl<string>('');
  // assistanttitleControl = new FormControl<string>('');
  // lastnameControl = new FormControl<string>('');
  // firstNameFormControl = new FormControl('', [Validators.required]);
  // contCategoryFormControl = new FormControl('', [Validators.required]);
  // mobileControl = new FormControl<string>('');
  // assistentmobile1Control = new FormControl<string>('');
  // assistentmobile2Control = new FormControl<string>('');
  // emailContolr = new FormControl<string>('');
  // emailAltContolr = new FormControl<string>('');
  // emailAssistentContolr = new FormControl<string>('');
  // eiCodeContolr = new FormControl<string>('');
  // vcemailContolr = new FormControl<string>('');
  // taxidContorl = new FormControl<string>('');
  // commentsControl = new FormControl<string>('');
  // sidenotesControl = new FormControl<string>('');
  // jobtitleControl = new FormControl<string>('');
  // contJDControl = new FormControl<string>('');
  // contTypeControl = new FormControl<string>('');
  // contRegionControl = new FormControl<string>('');
  // contRBControl = new FormControl<string>('');
  // contACControl = new FormControl<string>('');
  // contCGControl = new FormControl<string>('');
  // InstagraControl = new FormControl<string>('');
  // FacebookControl = new FormControl<string>('');
  // TiktokControl = new FormControl<string>('');
  // YoutubeControl = new FormControl<string>('');
  // TwitterControl = new FormControl<string>('');
  // LinkedlnControl = new FormControl<string>('');
  // companynameControl = new FormControl<string>('');
  // zipcodeControl = new FormControl<string>('');
  // careoffControl = new FormControl<string>('');
  // countriControl = new FormControl<string>('');
  // address1Control = new FormControl<string>('');
  // directPhoneControl = new FormControl<string>('');
  // address2Control = new FormControl<string>('');
  // websiteControl = new FormControl<string>('');
  // cityControl = new FormControl<string>('');
  // emailControl = new FormControl<string>('');
  // stateControl = new FormControl<string>('');
  // otherEmailControl = new FormControl<string>('');
  // state1Control = new FormControl<string>('');
  // countri1Control = new FormControl<string>('');
  // countri2Control = new FormControl<string>('');
  // state2Control = new FormControl<string>('');
  // cardNameControl = new FormControl<string>('');
  // cardNumberControl = new FormControl<string>('');
  // expirationDateControl = new FormControl<string>('');
  // cvcCodeControl = new FormControl<string>('');
  // descriptionFormControl = new FormControl({ value: '', disabled: false }, [
  //   Validators.required,
  // ]);
  //
  // careoff1Contro2 = new FormControl<string>('');
  // address12Control = new FormControl<string>('');
  // otherEmail2Control = new FormControl<string>('');
  // address22Control = new FormControl<string>('');
  // directPhone2Control = new FormControl<string>('');
  // city2Control = new FormControl<string>('');
  // generalPhoneControl = new FormControl<string>('');
  // state2Control_1 = new FormControl<string>('');
  // mobile1Control = new FormControl<string>('');
  // zipcode2Control = new FormControl<string>('');
  // mobile2Control = new FormControl<string>('');
  iPronounResult: GetPronounList[] = [{
    Code: 0,
    ErrorMessage: '',
    data: []
  }];
  iContCategoryResult: GetPronounList[] = [{
    Code: 0,
    ErrorMessage: '',
    data: []
  }];
  iContJDResult: GetPronounList[] = [{
    Code: 0,
    ErrorMessage: '',
    data: []
  }];
  iContTypeResult: GetPronounList[] = [{
    Code: 0,
    ErrorMessage: '',
    data: []
  }];
  iContRegionResult: GetPronounList[] = [{
    Code: 0,
    ErrorMessage: '',
    data: []
  }];
  iContRBResult: GetPronounList[] = [{
    Code: 0,
    ErrorMessage: '',
    data: []
  }];
  iContACResult: GetContacList[] = [{
    Code: 0,
    ErrorMessage: '',
    data: []
  }];
  iContCGResult: GetPronounList[] = [{
    Code: 0,
    ErrorMessage: '',
    data: []
  }];
  iAddressResult: GetAddressList[] = [{
    Code: 0,
    ErrorMessage: '',
    data: []
  }];
  iCompanyResult: GetCompanyList[] = [{
    code: 0,
    errorMessage: '',
    data: []
  }];
  selectedCompany: GetCompanyListData[] = []

  filteredPronounOptions?: Observable<string[]>;
  filteredContCategoryOptions?: Observable<string[]>;
  filteredContJDOptions?: Observable<string[]>;
  filteredContTypeOptions?: Observable<string[]>;
  filteredContRegionOptions?: Observable<string[]>;
  filteredContRBOptions?: Observable<string[]>;
  filteredContACOptions?: Observable<string[]>;
  filteredContCGOptions?: Observable<string[]>;
  private companyNameSubject = new BehaviorSubject<string[]>([]);
  filteredCompanyNameOptions: Observable<string[]> = this.companyNameSubject.asObservable();

  pronounoptions: string[] = [];
  contcategoryoptions: string[] = [];
  contjdoptions: string[] = [];
  conttypeoptions: string[] = [];
  contregionoptions: string[] = [];
  contrboptions: string[] = [];
  contacoptions: string[] = [];
  contcgoptions: string[] = [];
  companyoptions: string[] = [];
  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private hashService: HashService,
    public messageBox: MessageboxComponent,
    private serviceservice: ServiceService,
    private yourappsService: YourappsserviceService,
    private notification: NzNotificationService,
    private cdr: ChangeDetectorRef,
    private imageCompressService: NgxImageCompressService,
    private http: HttpClient
  ) {
    this.myForm = this.fb.group({});
    this.sizes = [
      { name: 'Small', class: 'p-datatable-sm' }
    ];
    this.selectedSize = this.sizes[0]
    if (
        SelfData.contactcustomfields &&
        SelfData.contactcustomfields.length > 0 &&
        Array.isArray(SelfData.contactcustomfields[0].data)
    ) {
      this.fieldscount = SelfData.contactcustomfields[0].data.map((field) => ({
        ...field,
        control: new FormControl('', field.requered ? Validators.required : null),
      }));
    } else {
      console.warn('Unexpected data format in SelfData.contactcustomfields');
    }

    // this.captionDepartment = SelfData.customfields[0].data[4].fieldlabel;
    // this.departmentFormControl.setValue(SelfData.account[0].data[0].company);
    this.captionDepartment = 'Department';
    this.captionPronoun = 'Pronoun';
    this.captionSalutation = 'Salutation';
    this.captionSpouse = 'Spouse';
    this.captionFirstname = 'First Name';
    this.captionAssistantName = 'Assistant Name';
    this.captionAssistantTitle = 'Assistant Title';
    this.captionLastName = 'Last Name';
    this.captionMobile = 'Mobile';
    this.captionAssistentMobile1 = 'Assistant Mobile 1';
    this.captionAssistentMobile2 = 'Assistant Mobile 2';
    this.captionEmail = 'Email';
    this.captionAltEmail = 'Alternative Email';
    this.captionAssistentEmail = 'Assistent Email';
    this.captionEICode = 'E-Invoicing Code';
    this.captionvcemail = 'Vip/Celebrity Email';
    this.captiontaxid = 'Tax ID';
    this.captionComments = 'Comments';
    this.captionSideNotes = 'Side Notes';
    this.captionJobTitle = 'Job Title';
    this.captionCategory = 'Category';
    this.captionJD = 'Job Department';
    this.captionType = 'Type';
    this.captionRegion = 'Region';
    this.captionRB = 'Reffered by';
    this.captionAssociated = 'Associated Contacts';
    this.captionGroups = 'Contact Groups';
    this.captionInstagram = 'Instagram Username';
    this.captionFacebook = 'Facebook Username';
    this.captionTiktok = 'TikTok Username';
    this.captionYoutube = 'Youtube Username';
    this.captionTwitter = 'X (Twitter) Username';
    this.captionLinkedln = 'Linkedln Username';
    this.captionCompanyName = 'Company Name';
    this.captionZipCode = 'Zip Code';
    this.captionZipCode1 = 'Zip Code';
    this.captionZipCode2 = 'Zip Code';
    this.captionCareOff = 'Care Of';
    this.captionCareOff1 = 'Care Of';
    this.captionCareOff2 = 'Care Of';
    this.captionCountri = 'Country';
    this.captionCountri1 = 'Country';
    this.captionCountri2 = 'Country';
    this.captionAddress1 = 'Address1';
    this.captionAddress12 = 'Address1';
    this.captionAddress11 = 'Address1';
    this.captionDirectPhone = 'Direct Phone';
    this.captionDirectPhone1 = 'Direct Phone';
    this.captionDirectPhone2 = 'Direct Phone';
    this.captionAddress2 = 'Address2';
    this.captionAddress22 = 'Address2';
    this.captionAddress21 = 'Address2';
    this.captionWebsite = 'Website';
    this.captionCity = 'City';
    this.captionCity1 = 'City';
    this.captionCity2 = 'City';
    this.captioncontemail = 'Email';
    this.captionState = 'State';
    this.captionState1 = 'State';
    this.captionState2 = 'State';
    this.captionGeneralPhone = 'General Phone';
    this.captionMobile1 = 'Mobail1';
    this.captionMobile2 = 'Mobail2';
    this.captionState2 = 'State';
    this.captionState2 = 'State';
    this.captioncontotheremail = 'Other Email';
    this.captioncontotheremail2 = 'Other Email';

    this.departmentstring = SelfData.account[0].data[0].company;

    var accountid: number = 0;
    if (SelfData.account[0].data[0].parentid == 0) {
      accountid = SelfData.account[0].data[0].id;
    } else {
      accountid = SelfData.account[0].data[0].parentid;
    }

    // this.serviceservice.setCompanyList({ accountid: accountid }).subscribe({
    //   next: (value: GetCompanyList) => {
    //     console.log(value)
    //     if (value && value.data) {
    //       this.iCompanyResult[0] = value;
    //       SelfData.company = this.iCompanyResult;
    //       this.companyNameSubject.next([]);
    //       console.log(">1")
    //       this.filteredCompanyNameOptions = this.setFullDataFilter(
    //           this.companynameControl,
    //           value.data,
    //           this.companyoptions,
    //           8
    //       );
    //     } else {
    //       console.log('The response is empty or undefined.');
    //     }
    //   },
    //   error: (error) => {
    //     console.error('Error occurred during the subscription:', error);
    //   },
    // });

    this.serviceservice
      .setPronounList({ accountid: accountid })
      .subscribe((value) => {
        this.iPronounResult[0] = value;
        SelfData.pronoun = this.iPronounResult;
        // this.filteredPronounOptions = this.setDataAndFilter(
        //   this.myPronounControl,
        //   value,
        //   this.pronounoptions,
        //   0
        // );
      });

    this.serviceservice
      .setContCategoryList({ accountid: accountid })
      .subscribe((value) => {
        this.iContCategoryResult[0] = value;
        SelfData.contcategory = this.iContCategoryResult;
        // this.filteredContCategoryOptions = this.setDataAndFilter(
        //   this.contCategoryFormControl,
        //   value,
        //   this.contcategoryoptions,
        //   1
        // );
      });

    this.serviceservice
      .setContJDList({ accountid: accountid })
      .subscribe((value) => {
        this.iContJDResult[0] = value;
        SelfData.contjd = this.iContJDResult;
        // this.filteredContJDOptions = this.setDataAndFilter(
        //   this.contJDControl,
        //   value,
        //   this.contjdoptions,
        //   2
        // );
      });

    this.serviceservice
      .setContTypeList({ accountid: accountid })
      .subscribe((value) => {
        this.iContTypeResult[0] = value;
        SelfData.conttype = this.iContTypeResult;
        // this.filteredContTypeOptions = this.setDataAndFilter(
        //   this.contTypeControl,
        //   value,
        //   this.conttypeoptions,
        //   3
        // );
      });

    this.serviceservice
      .setContRegionList({ accountid: accountid })
      .subscribe((value) => {
        this.iContRegionResult[0] = value;
        console.log("iContRegionResult", this.iContRegionResult[0])
        SelfData.contregion = this.iContRegionResult;
        // this.filteredContRegionOptions = this.setDataAndFilter(
        //   this.contRegionControl,
        //   value,
        //   this.contregionoptions,
        //   4
        // );
      });

    this.serviceservice
      .setContRBList({ accountid: accountid })
      .subscribe((value) => {
        this.iContRBResult[0] = value;
        SelfData.contrb = this.iContRBResult;
        // this.filteredContRBOptions = this.setDataAndFilter(
        //   this.contRBControl,
        //   value,
        //   this.contrboptions,
        //   5
        // );
      });

    this.serviceservice
      .setContACList({ accountid: accountid })
      .subscribe((value) => {
        this.iContACResult[0] = value;
        SelfData.contac = this.iContACResult;
        // this.filteredContACOptions = this.setDataAndFilter(
        //   this.contACControl,
        //   value,
        //   this.contacoptions,
        //   6
        // );
      });

    this.serviceservice
      .setContCGList({ accountid: accountid })
      .subscribe((value) => {
        this.iContCGResult[0] = value;
        SelfData.contcg = this.iContCGResult;
        // this.filteredContCGOptions = this.setDataAndFilter(
        //   this.contCGControl,
        //   value,
        //   this.contcgoptions,
        //   7
        // );
      });

    this.serviceservice
      .setAddressList({ accountid: accountid, contactsid: 0 })
      .subscribe((value) => {
        console.log(value);
        this.iAddressResult[0] = value;
        SelfData.addres = this.iAddressResult;
        this.ELEMENT_DATA = [...SelfData.addres[0].data]; // Create a new array reference
        this.dataSource = new MatTableDataSource<GetAddressListData>(
          this.ELEMENT_DATA
        );
        this.dataSource.paginator = this.paginator;
      });

    // this.ELEMENT_DATA = SelfData.addres[0].data;
    //
  }

  public get setdataSource() {
    return this.dataSource;
  }
  getAutoComplete(id: number): MatAutocomplete {
    return this.autoCompletes[`auto${id}`];
  }

  private setFullDataFilter(
      control: FormControl,
      response: any,
      options: string[],
      type: number
  ) {
    for (let data of response) {
      options.push(data.companyname);
    }
    return control.valueChanges.pipe(
        startWith(''),
        map((value) => this._myfilter(value || '', type))
    );
  }

  private _myfilter(value: string, type: number): string[] {
    const filterValue = value.toLowerCase();

    // replace 'this.companyoptions' with the correct array based on 'type'
    return this.companyoptions.filter((option) =>
        option.toLowerCase().includes(filterValue)
    );
  }

  private setDataAndFilter(
    control: FormControl,
    response: any,
    options: string[],
    type: number
  ) {
    let results = response.data;
    for (let data of results) {
      options.push(data.description);
    }
    return control.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || '', type))
    );
  }

  private _filter(value: string, type: number): string[] {
    // Ensure the input value is not null or undefined before proceeding
    if (value === null || value === undefined) {
      console.warn(`Input value is ${value}`);
      return [];
    }

    const filterValue = value.toLowerCase();

    const options = [
      this.pronounoptions,
      this.contcategoryoptions,
      this.contjdoptions,
      this.conttypeoptions,
      this.contregionoptions,
      this.contrboptions,
      this.contacoptions,
      this.contcgoptions
    ];

    if (type >= 0 && type < options.length) {
      return options[type].filter((option) => {
        // Check if 'option' is not null or undefined before calling 'toLowerCase' on it
        return option && typeof option === 'string' && option.toLowerCase().includes(filterValue);
      });
    } else {
      console.warn(`Unexpected filter type: ${type}`);
      return [];
    }
  }
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
        ? SelfData.account[0].data[0].id
        : SelfData.account[0].data[0].parentid;
  }

  private subscription?: Subscription;
  private subscription2?: Subscription;


  ngOnInit(): void {
    this.yourappsService.reloadArrayList.subscribe((description) => {
      this.reloadPronounList(this.getAccountID())
      this.reloadContJDList(this.getAccountID())
      this.reloadContRegionList(this.getAccountID())
      this.reloadContCategoryList(this.getAccountID())
      this.reloadContTypeList(this.getAccountID())
      this.reloadContRBList(this.getAccountID())
    });

    this.yourappsService.address$.subscribe((address) => {
      if (address && address[0] && address[0].data && address[0].data[0]) {
        this.iAddressResult = address;
      } else {
        // console.error('Received unexpected data format:', address);
      }
    });

    this.yourappsService.company$.subscribe((value) => {
      this.serviceservice.setCompanyList({ accountid: this.getAccountID() }).subscribe({
        next: (value: GetCompanyList) => {
          if (value && value.data) {
            this.iCompanyResult = []
            SelfData.company = []
            this.iCompanyResult[0] = value;
            SelfData.company = this.iCompanyResult;
            this.companyNameSubject.next([]);
            this.companyoptions = []
            // this.filteredCompanyNameOptions = this.setFullDataFilter(
            //     this.companynameControl,
            //     value.data,
            //     this.companyoptions,
            //     0
            // );
          } else {
            console.log('The response is empty or undefined.');
          }
        },
        error: (error) => {
          console.error('Error occurred during the subscription:', error);
        },
      });
    });

    // this.yourappsService.company$.subscribe((value) => {
    //   this.companyid = value
    //   this.serviceservice.setCompanyList({ accountid: this.getAccountID() }).subscribe({
    //     next: (value: GetCompanyList) => {
    //       if (value && value.data) {
    //         this.iCompanyResult = []
    //         this.iCompanyResult[0] = value;
    //         SelfData.company = this.iCompanyResult;
    //         this.companyNameSubject.next([]);
    //         console.log(">3")
    //         this.filteredCompanyNameOptions = this.setFullDataFilter(
    //             this.companynameControl,
    //             value.data,
    //             this.companyoptions,
    //             0
    //         );
    //       } else {
    //         console.log('The response is empty or undefined.');
    //       }
    //     },
    //     error: (error) => {
    //       console.error('Error occurred during the subscription:', error);
    //     },
    //   });
    // });

    this.setupValueChanges();
    this.reloadOptionsOnSignal();

    this.http.get<any>('assets/countries.json').subscribe((data) => {
      this.countries = data.countries;
      this.countriesh = data.countries;
      this.countrieso = data.countries;
      this.countrystring = "United States"
      this.country2string = "United States"
      // this.countriControl.setValue('United States');
      // this.countri1Control.setValue('United States');
      // this.countri2Control.setValue('United States');
      this.http.get<any>('assets/us-states.json').subscribe((data) => {
        this.states = data;
        this.statesh = data;
        this.stateso = data;
        this.onOptionSelected(this.countrystring)
        this.onOptionSelectedh(this.country2string)
        // this.filteredStates = this.stateControl.valueChanges.pipe(
        //   startWith(''),
        //   map((value) => this._filterStates(value || ''))
        // );
        // this.filteredStatesh = this.state1Control.valueChanges.pipe(
        //   startWith(''),
        //   map((value) => this._filterStatesh(value || ''))
        // );
        // this.filteredStateso = this.state2Control.valueChanges.pipe(
        //   startWith(''),
        //   map((value) => this._filterStateso(value || ''))
        // );
      });

      // this.filteredCountries = this.countriControl.valueChanges.pipe(
      //   startWith(''),
      //   map((value) => this._filterCountries(value || ''))
      // );
      //
      // this.filteredCountriesh = this.countri1Control.valueChanges.pipe(
      //   startWith(''),
      //   map((value) => this._filterCountriesh(value || ''))
      // );
      //
      // this.filteredCountrieso = this.countri2Control.valueChanges.pipe(
      //   startWith(''),
      //   map((value) => this._filterCountrieso(value || ''))
      // );
    });
    this.yourappsService.setContact$.subscribe((value: { data: SetContactsListData[] | SetContactsListData, type: number }) => {
      console.log("Received value:", value);

      let data: SetContactsListData[] = Array.isArray(value.data) ? value.data : [value.data];
      console.log("Data: ", data);

      if (value.type === 1 && data.length > 0) {
        this.operationType = value.type
        this.contactcaption = "View Contact";
        this.contactid = data[0]['id']
        this.companyid = data[0]['companyid']
        this.myPronounstring = data[0]['pronoum'];
        this.salutationstring = data[0]['salutation'];
        this.pousestring = data[0]['spouse'];
        this.firstNamestring = data[0]['firstname'];
        this.assistantnamestring = data[0]['assistantname'];
        this.lastnamestring = data[0]['lastname'];
        this.assistanttitlestring = data[0]['assistanttitle'];
        this.mobilestring  = data[0]['mobile'];
        this.assistentmobile1string = data[0]['assistantmobile1'];
        this.emailstring = data[0]['email'];
        this.emailrstring = data[0]['companyemail'];
        this.assistentmobile2string = data[0]['assistantmobile2'];
        this.emailAltstring = data[0]['alternativeemail'];
        this.emailAssistentstring = data[0]['assistentemail'];
        this.eiCodestring = data[0]['einvoicingcode'];
        this.vcemailstring = data[0]['vipcelebrityemail'];
        this.taxidstring  =data[0]['taxid'];
        this.commentsdstring = data[0]['comments'];
        this.sidenotesstring = data[0]['sidenotes'];
        this.jobtitlestring = data[0]['jobtitle'];
        this.categorystring = data[0]['category'];
        this.contJDstring = data[0]['jobdepartment'];
        this.contTypestring = data[0]['type'];
        this.regionstring = data[0]['region'];
        this.rbstring = data[0]['refferedby'];
        this.associatedstring = data[0]['associatedcontacts'];
        this.groupsstring = data[0]['contactgroups'];
        this.instagramstring = data[0]['instagram'];
        this.facebookstring = data[0]['facebook'];
        this.tiktokstring = data[0]['tiktok'];
        this.youtubestring = data[0]['youtube'];
        this.twitterstring = data[0]['twitter'];
        this.linkedinstring = data[0]['linkedin'];
        this.companystring = data[0]['companyname'];
        this.zipcodestring = data[0]['companyzipcode'];
        this.careoffstring = data[0]['companycareof'];
        this.countrystring = data[0]['companycountry'];
        this.address1string = data[0]['companyaddress1'];
        this.directphonestring = data[0]['companydirectphone'];
        this.address2string = data[0]['companyaddress2'];
        this.websitestring = data[0]['companywebsite'];
        this.citystring = data[0]['companycity'];
        this.statesstring = data[0]['companystate'];
        this.otheremailstring = data[0]['companyotheremail'];

        this.cardnamestring = data[0]['cardname'];
        this.cardnumberstring = data[0]['cardnumber'];
        this.expirationdate = data[0]['expdate'];
        this.cvcodestring = data[0]['cvccode'];
        this.address12string = data[0]['billingaddress1'];
        this.address22string = data[0]['billingaddress2'];
        this.city2string = data[0]['billingcity'];
        this.country2string = data[0]['billingcountry'];
        this.address12string = data[0]['billingstate'];
        this.zipcode2string = data[0]['billingzipcode'];

        this.clearAllisDisabled = true
        this.submitIsDisabled = true
      } else if (value.type === 2 && data.length > 0) {
        this.createbuttoncaption = "Update"
        this.operationType = value.type
        this.contactid = data[0]['id']
        this.companyid = data[0]['companyid']
        this.contactcaption = "Edit Contact";
        this.myPronounstring = data[0]['pronoum'];
        this.salutationstring = data[0]['salutation'];
        this.pousestring = data[0]['spouse'];
        this.firstNamestring = data[0]['firstname'];
        this.assistantnamestring = data[0]['assistantname'];
        this.lastnamestring = data[0]['lastname'];
        this.assistanttitlestring = data[0]['assistanttitle'];
        this.mobilestring  = data[0]['mobile'];
        this.assistentmobile1string = data[0]['assistantmobile1'];
        this.emailstring = data[0]['email'];
        this.emailrstring = data[0]['companyemail'];
        this.assistentmobile2string = data[0]['assistantmobile2'];
        this.emailAltstring = data[0]['alternativeemail'];
        this.emailAssistentstring = data[0]['assistentemail'];
        this.eiCodestring = data[0]['einvoicingcode'];
        this.vcemailstring = data[0]['vipcelebrityemail'];
        this.taxidstring  =data[0]['taxid'];
        this.commentsdstring = data[0]['comments'];
        this.sidenotesstring = data[0]['sidenotes'];
        this.jobtitlestring = data[0]['jobtitle'];
        this.categorystring = data[0]['category'];
        this.contJDstring = data[0]['jobdepartment'];
        this.contTypestring = data[0]['type'];
        this.regionstring = data[0]['region'];
        this.rbstring = data[0]['refferedby'];
        this.associatedstring = data[0]['associatedcontacts'];
        this.groupsstring = data[0]['contactgroups'];
        this.instagramstring = data[0]['instagram'];
        this.facebookstring = data[0]['facebook'];
        this.tiktokstring = data[0]['tiktok'];
        this.youtubestring = data[0]['youtube'];
        this.twitterstring = data[0]['twitter'];
        this.linkedinstring = data[0]['linkedin'];
        this.companystring = data[0]['companyname'];
        this.zipcodestring = data[0]['companyzipcode'];
        this.careoffstring = data[0]['companycareof'];
        this.countrystring = data[0]['companycountry'];
        this.address1string = data[0]['companyaddress1'];
        this.directphonestring = data[0]['companydirectphone'];
        this.address2string = data[0]['companyaddress2'];
        this.websitestring = data[0]['companywebsite'];
        this.citystring = data[0]['companycity'];
        this.statesstring = data[0]['companystate'];
        this.otheremailstring = data[0]['companyotheremail'];

        this.cardnamestring = data[0]['cardname'];
        this.cardnumberstring = data[0]['cardnumber'];
        this.expirationdate = data[0]['expdate'];
        this.cvcodestring = data[0]['cvccode'];
        this.address12string = data[0]['billingaddress1'];
        this.address22string = data[0]['billingaddress2'];
        this.city2string = data[0]['billingcity'];
        this.country2string = data[0]['billingcountry'];
        this.address12string = data[0]['billingstate'];
        this.zipcode2string = data[0]['billingzipcode'];

        this.clearAllisDisabled = false
        this.submitIsDisabled = false
      } else {
        this.contactcaption = "Create Contact";
        this.operationType = 0
        this.clearAllisDisabled = false
        this.submitIsDisabled = false
      }
    });
  }
  getCountryIdByName(countryName: string): number | null {
    const country = this.countries.find((c) => c.country === countryName);
    return country ? country.id : null;
  }

  findCompanyByName(companyName: string): GetCompanyListData[] {
    // Find the company list where the company name matches
    const foundCompanyList = this.iCompanyResult.find(companyList =>
        companyList.data.some(company =>
            company.companyname.toLowerCase() === companyName.toLowerCase()
        )
    );

    // If a matching company list is found, return the companies with that name
    if (foundCompanyList) {
      return foundCompanyList.data.filter(company =>
          company.companyname.toLowerCase() === companyName.toLowerCase()
      );
    }

    // If no matching company list is found, return an empty array
    return [];
  }

  onCompanySelect(event: string): void {
    this.selectedCompany = this.findCompanyByName(event)
    this.careoffstring = this.selectedCompany[0].careof
    this.address1string = this.selectedCompany[0].address1
    this.address2string = this.selectedCompany[0].address2
    this.citystring = this.selectedCompany[0].city
    this.statesstring = this.selectedCompany[0].state
    this.zipcodestring = this.selectedCompany[0].zipcode
    this.countrystring = this.selectedCompany[0].country
    this.directphonestring = this.selectedCompany[0].phone
    this.websitestring = this.selectedCompany[0].website
    this.emailrstring = this.selectedCompany[0].email
    this.otheremailstring = this.selectedCompany[0].otheremail
    this.companyid = this.selectedCompany[0].id
    console.log("this.selectedCompany", this.selectedCompany);
  }

  onOptionSelected(event: string): void {
    const countryId = this.getCountryIdByName(event);
    this.states = [];
    this.statesstring = "";
    console.log(countryId)
    if (countryId == 187) {
      this.http.get<any>('assets/us-states.json').subscribe((data) => {
        this.states = data;
        // this.filteredStates = this.stateControl.valueChanges.pipe(
        //   startWith(''),
        //   map((value) => this._filterStates(value || ''))
        // );
      });
    }
    console.log(this.states);
    // You can perform further actions using the selected country
  }

  onOptionSelectedh(event: string): void {
    const countryId = this.getCountryIdByName(event);
    this.statesh = [];
    this.country2string = "";
    if (countryId == 187) {
      this.http.get<any>('assets/us-states.json').subscribe((data) => {
        this.statesh = data;
        // this.filteredStatesh = this.state1Control.valueChanges.pipe(
        //   startWith(''),
        //   map((value) => this._filterStatesh(value || ''))
        // );
      });
    }
    // You can perform further actions using the selected country
  }

  onOptionSelectedo(event: MatAutocompleteSelectedEvent): void {
    const countryId = this.getCountryIdByName(event.option.value);
    this.stateso = [];
    this.address12string = ""
    if (countryId == 187) {
      this.http.get<any>('assets/us-states.json').subscribe((data) => {
        this.stateso = data;
        // this.filteredStateso = this.state2Control.valueChanges.pipe(
        //   startWith(''),
        //   map((value) => this._filterStateso(value || ''))
        // );
      });
    }
    // You can perform further actions using the selected country
  }

  private _filterCountries(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.countries.filter((country) =>
      country.country.toLowerCase().includes(filterValue)
    );
  }

  private _filterCountriesh(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.countriesh.filter((country) =>
      country.country.toLowerCase().includes(filterValue)
    );
  }

  private _filterCountrieso(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.countriesh.filter((country) =>
      country.country.toLowerCase().includes(filterValue)
    );
  }
  private _filterStates(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.states.filter((state) =>
      state.state.toLowerCase().includes(filterValue)
    );
  }

  private _filterStatesh(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.statesh.filter((state) =>
      state.state.toLowerCase().includes(filterValue)
    );
  }

  private _filterStateso(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.stateso.filter((state) =>
      state.state.toLowerCase().includes(filterValue)
    );
  }

  setupValueChanges(): void {
    this.yourappsService.selectedValue$.subscribe((description) => {
      switch (SelfData.modaltype) {
        case 14:
          this.myPronounstring = description;
          break;
        case 15:
          this.categorystring = description;
          break;
        case 16:
          this.contJDstring = description;
          break;
        case 17:
          this.contTypestring = description;
          break;
        case 18:
          this.regionstring = description;
          break;
        case 19:
          this.rbstring = description;
          break;
        case 20:
          this.associatedstring = description;
          break;
        case 21:
          this.groupsstring = description;
          break;
      }
    });
  }

  reloadOptionsOnSignal(): void {
    this.yourappsService.reloadArrayList.subscribe((description) => {
      let accountid: number = this.determineAccountId();
      // this.resetResults();

      // this.iPronounResult = [];
      // this.iContCategoryResult = [];
      // this.iContJDResult = [];
      // this.iContTypeResult = [];
      // this.iContRegionResult = [];
      // this.iContRBResult = [];
      // this.iContACResult = [];
      // this.iContCGResult = [];

      switch (SelfData.modaltype) {
        case 14:
          this.iPronounResult = [];
          this.reloadPronounList(accountid);
          break;
        case 15:
          this.iContCategoryResult = [];
          this.reloadContCategoryList(accountid);
          break;
        case 16:
          this.iContJDResult = [];
          this.reloadContJDList(accountid);
          break;
        case 17:
          this.iContTypeResult = [];
          this.reloadContTypeList(accountid);
          break;
        case 18:
          this.iContRegionResult = [];
          this.reloadContRegionList(accountid);
          break;
        case 19:
          this.iContRBResult = [];
          this.reloadContRBList(accountid);
          break;
        case 20:
          this.iContACResult = [];
          this.reloadContACList(accountid);
          break;
        case 21:
          this.iContCGResult = [];
          this.reloadContCGList(accountid);
          break;
      }
    });
  }

  reloadPronounList(accountid: number): void {
    this.serviceservice
      .setPronounList({ accountid: accountid })
      .subscribe((swsvalue) => {
        this.iPronounResult.push(swsvalue);
        SelfData.pronoun = this.iPronounResult;

        this.pronounoptions = this.iPronounResult[0].data.map(
          (item) => item.description
        );
        // this.filteredPronounOptions = this.myPronounControl.valueChanges.pipe(
        //   startWith(''),
        //   map((value) => this._filter(value || '', 0))
        // );
      });
  }

  getFilteredCCFVOptions(controllername: string): Observable<string[]> {
    let filteredArray: string[] = [];

    if (
      SelfData.cfv &&
      SelfData.cfv.length > 0 &&
      SelfData.cfv[0].data !== null &&
      SelfData.cfv[0].data !== undefined
    ) {
      filteredArray = SelfData.cfv[0].data
        .filter((item) => item.controllername === controllername)
        .map((item) => item.value);
    }

    return of(filteredArray);
  }

  customadd(event: MouseEvent): void {
    event.stopPropagation();
  }
  reloadContCategoryList(accountid: number): void {
    this.serviceservice
      .setContCategoryList({ accountid: accountid })
      .subscribe((value) => {
        this.iContCategoryResult.push(value);
        SelfData.contcategory = this.iContCategoryResult;

        this.contcategoryoptions = this.iContCategoryResult[0].data.map(
          (item) => item.description
        );
        // this.filteredContCategoryOptions =
        //   this.contCategoryFormControl.valueChanges.pipe(
        //     startWith(''),
        //     map((value) => this._filter(value || '', 1))
        //   );
      });
  }

  reloadContJDList(accountid: number): void {
    this.serviceservice
      .setContJDList({ accountid: accountid })
      .subscribe((value) => {
        this.iContJDResult.push(value);
        SelfData.contjd = this.iContJDResult;

        this.contjdoptions = this.iContJDResult[0].data.map(
          (item) => item.description
        );
        // this.filteredContJDOptions = this.contJDControl.valueChanges.pipe(
        //   startWith(''),
        //   map((value) => this._filter(value || '', 2))
        // );
      });
  }

  reloadContTypeList(accountid: number): void {
    this.serviceservice
      .setContTypeList({ accountid: accountid })
      .subscribe((value) => {
        this.iContTypeResult.push(value);
        SelfData.conttype = this.iContTypeResult;

        this.conttypeoptions = this.iContTypeResult[0].data.map(
          (item) => item.description
        );
        // this.filteredContTypeOptions = this.contTypeControl.valueChanges.pipe(
        //   startWith(''),
        //   map((value) => this._filter(value || '', 3))
        // );
      });
  }

  reloadContRegionList(accountid: number): void {
    this.serviceservice
      .setContRegionList({ accountid: accountid })
      .subscribe((value) => {
        this.iContRegionResult.push(value);
        SelfData.contregion = this.iContRegionResult;

        this.contregionoptions = this.iContRegionResult[0].data.map(
          (item) => item.description
        );
        // this.filteredContRegionOptions =
        //   this.contRegionControl.valueChanges.pipe(
        //     startWith(''),
        //     map((value) => this._filter(value || '', 4))
        //   );
      });
  }

  reloadContRBList(accountid: number): void {
    this.serviceservice
      .setContRBList({ accountid: accountid })
      .subscribe((value) => {
        this.iContRBResult.push(value);
        SelfData.contrb = this.iContRBResult;

        this.contrboptions = this.iContRBResult[0].data.map(
          (item) => item.description
        );
        // this.filteredContRBOptions = this.contRBControl.valueChanges.pipe(
        //   startWith(''),
        //   map((value) => this._filter(value || '', 5))
        // );
      });
  }

  reloadContACList(accountid: number): void {
    this.serviceservice
      .setContACList({ accountid: accountid })
      .subscribe((value) => {
        this.iContACResult.push(value);
        SelfData.contac = this.iContACResult;

        this.contacoptions = this.iContACResult[0].data.map(
          (item) => item.description
        );
        // this.filteredContACOptions = this.contACControl.valueChanges.pipe(
        //   startWith(''),
        //   map((value) => this._filter(value || '', 6))
        // );
      });
  }

  reloadContCGList(accountid: number): void {
    this.serviceservice
      .setContCGList({ accountid: accountid })
      .subscribe((value) => {
        this.iContCGResult.push(value);
        SelfData.contcg = this.iContCGResult;

        this.contcgoptions = this.iContCGResult[0].data.map(
          (item) => item.description
        );
        // this.filteredContCGOptions = this.contCGControl.valueChanges.pipe(
        //   startWith(''),
        //   map((value) => this._filter(value || '', 7))
        // );
      });
  }

  determineAccountId(): number {
    return SelfData.account[0].data[0].parentid === 0
        ? SelfData.account[0].data[0].id
        : SelfData.account[0].data[0].parentid;
  }

  searchIdByDescription(description: string, listName: string): number | null {
    let listToSearch: (GetPronounList | GetAddressList)[] = [];
    console.log(listName)

    if (!description) {
      // description is null or empty, return null or handle as needed
      console.log("!description", listName)
      return null;
    }
    switch (listName) {
      case 'iPronounResult':
        listToSearch = this.iPronounResult || [];
        break;
      case 'iContCategoryResult':
        listToSearch = this.iContCategoryResult || [];
        break;
      case 'iContJDResult':
        listToSearch = this.iContJDResult || [];
        break;
      case 'iContTypeResult':
        listToSearch = this.iContTypeResult || [];
        break;
      case 'iContRegionResult':
        listToSearch = this.iContRegionResult || [];
        break;
      case 'iContRBResult':
        listToSearch = this.iContRBResult || [];
        break;
      case 'iContACResult':
        listToSearch = this.iContACResult || [];
        break;
      case 'iContCGResult':
        listToSearch = this.iContCGResult || [];
        break;
      case 'iAddressResult':
        listToSearch = this.iAddressResult || [];
        break;
      default:
        console.log("Default")
        return null;
    }

    for (const listItem of listToSearch) {
      if (listItem && listItem.data) {
        for (const data of listItem.data) {
          if (data.description && data.description.toLowerCase() === description.toLowerCase()) {
            console.log(data.description.toLowerCase(), "<<<<>>>>", description.toLowerCase())
            return data.id;
          }
        }
      }
    }

    return null; // Return null if no matching description is found
  }

  submit(type: number = 0) {
    if (this.firstNamestring.trim() === "") {
      this.statusfirstname = 'error';
      this.notification.error(
        '<strong class="notification-error">Information</strong>',
        '<strong class="notification-error">You must specify the First Name</strong>'
      );
      return;
    }
    if (this.operationType === 2) {
      console.log("Log1", this.searchIdByDescription(this.associatedstring || "", "iContACResult"))
      console.log("Log2", this.searchIdByDescription(this.groupsstring || "", "iContCGResult"))
      this.serviceservice.prcContacts({
        ctrl: 1,
        id: this.contactid,
        department: this.departmentstring || '', // You can replace with appropriate form control value
        pronoumid: this.searchIdByDescription(this.myPronounstring || "", "iPronounResult") || 0,   // You can replace with appropriate form control value
        salutation: this.salutationstring || '',
        spouse: this.pousestring || '',
        firstname: this.firstNamestring || '',
        assistantname: this.assistantnamestring || '',
        lastname: this.lastnamestring || '',
        assistanttitle: this.assistanttitlestring || '',
        mobile: this.mobilestring || '',
        assistantmobile1: this.assistentmobile1string || '',
        assistantmobile2: this.assistentmobile2string || '',
        email: this.otheremailstring || '',
        alternativeemail: this.emailAltstring || '',
        assistentemail: this.emailAssistentstring || '',
        einvoicingcode: this.eiCodestring || '',
        vipcelebrityemail: this.vcemailstring || '',
        comments: this.commentsdstring || '',
        sidenotes: this.sidenotesstring || '',
        jobtitle: this.jobtitlestring || '',
        categoryid: this.searchIdByDescription(this.categorystring || "", "iContCategoryResult") || 0,
        jobdepartmentid: this.searchIdByDescription(this.contJDstring || "", "iContJDResult") || 0,
        typeid: this.searchIdByDescription(this.contTypestring || "", "iContTypeResult") || 0,
        regionid: this.searchIdByDescription(this.regionstring || "", "iContRegionResult") || 0,
        refferedbyid: this.searchIdByDescription(this.rbstring || "", "iContRBResult") || 0,
        associatedcontactsid: this.searchIdByDescription(this.associatedstring || "", "iContACResult") || 0,
        contactgroupsid: this.searchIdByDescription(this.groupsstring || "", "iContCGResult") || 0,
        instagram: this.instagramstring || '',
        facebook: this.facebookstring || '',
        tiktok: this.tiktokstring || '',
        youtube: this.youtubestring || '',
        twitter: this.twitterstring || '',
        linkedin: this.linkedinstring || '',
        primaryaddresstype: 0,  // You can replace with appropriate form control value
        companyinfoid: this.companyid,  // You can replace with appropriate form control value
        homeinfoid: 0,  // You can replace with appropriate form control value
        otherinfo: this.iAddressResult[0].data[0].id,  // You can replace with appropriate form control value
        activity: true,
        accountid: this.getAccountID(),
        taxid: this.taxidstring || "",
        companyname: this.companystring || "",
        companycareof: this.careoffstring || "",
        companyaddress1: this.address1string || "",
        companyaddress2: this.address2string || "",
        companycity: this.citystring || "",
        companystate: this.statesstring || "",
        companyzipcode: this.zipcodestring || "",
        companycountry: this.countrystring || "",
        companydirectphone: this.directphonestring || "",
        companywebsite: this.websitestring || "",
        companyemail: this.emailrstring || "",
        companyotheremail: this.otheremailstring || "",
        cardname: this.cardnamestring || "",
        cardnumber: this.cardnumberstring || "",
        expdate: this.expirationdate instanceof Date ? this.expirationdate.toISOString() : (this.expirationdate || ""),
        cvccode: this.cvcodestring || "",
        billingaddress1: this.address12string || "",
        billingaddress2: this.address22string || "",
        billingcity: this.city2string || "",
        billingcountry: this.country2string || "",
        billingstate: this.address12string || "",
        billingzipcode: this.zipcode2string || "",
      })
        .subscribe((value: PrcContacts) => {
          this.modalService.createcontactclose()
          this.yourappsService.setContract([]);
        });
    } else {
      this.serviceservice.prcContacts({
        ctrl: 0,
        id: 0,
        department: this.departmentstring || '', // You can replace with appropriate form control value
        pronoumid: this.searchIdByDescription(this.myPronounstring || "", "iPronounResult") || 0,   // You can replace with appropriate form control value
        salutation: this.salutationstring || '',
        spouse: this.pousestring || '',
        firstname: this.firstNamestring || '',
        assistantname: this.assistantnamestring || '',
        lastname: this.lastnamestring || '',
        assistanttitle: this.assistanttitlestring || '',
        mobile: this.mobilestring || '',
        assistantmobile1: this.assistentmobile1string || '',
        assistantmobile2: this.assistentmobile2string || '',
        email: this.otheremailstring || '',
        alternativeemail: this.emailAltstring || '',
        assistentemail: this.emailAssistentstring || '',
        einvoicingcode: this.eiCodestring || '',
        vipcelebrityemail: this.vcemailstring || '',
        comments: this.commentsdstring || '',
        sidenotes: this.sidenotesstring || '',
        jobtitle: this.jobtitlestring || '',
        categoryid: this.searchIdByDescription(this.categorystring || "", "iContCategoryResult") || 0,
        jobdepartmentid: this.searchIdByDescription(this.contJDstring || "", "iContJDResult") || 0,
        typeid: this.searchIdByDescription(this.contTypestring || "", "iContTypeResult") || 0,
        regionid: this.searchIdByDescription(this.regionstring || "", "iContRegionResult") || 0,
        refferedbyid: this.searchIdByDescription(this.rbstring || "", "iContRBResult") || 0,
        associatedcontactsid: this.searchIdByDescription(this.associatedstring || "", "iContACResult") || 0,
        contactgroupsid: this.searchIdByDescription(this.groupsstring || "", "iContCGResult") || 0,
        instagram: this.instagramstring || '',
        facebook: this.facebookstring || '',
        tiktok: this.tiktokstring || '',
        youtube: this.youtubestring || '',
        twitter: this.twitterstring || '',
        linkedin: this.linkedinstring || '',
        primaryaddresstype: 0,  // You can replace with appropriate form control value
        companyinfoid: this.companyid,  // You can replace with appropriate form control value
        homeinfoid: 0,  // You can replace with appropriate form control value
        otherinfo: this.iAddressResult[0].data[0].id,  // You can replace with appropriate form control value
        activity: true,
        accountid: this.getAccountID(),
        taxid: this.taxidstring || "",
        companyname: this.companystring || "",
        companycareof: this.careoffstring || "",
        companyaddress1: this.address1string || "",
        companyaddress2: this.address2string || "",
        companycity: this.citystring || "",
        companystate: this.statesstring || "",
        companyzipcode: this.zipcodestring || "",
        companycountry: this.countrystring || "",
        companydirectphone: this.directphonestring || "",
        companywebsite: this.websitestring || "",
        companyemail: this.emailrstring || "",
        companyotheremail: this.otheremailstring || "",
        cardname: this.cardnamestring || "",
        cardnumber: this.cardnumberstring || "",
        expdate: this.expirationdate instanceof Date ? this.expirationdate.toISOString() : (this.expirationdate || ""),
        cvccode: this.cvcodestring || "",
        billingaddress1: this.address12string || "",
        billingaddress2: this.address22string || "",
        billingcity: this.city2string || "",
        billingcountry: this.country2string || "",
        billingstate: this.address12string || "",
        billingzipcode: this.zipcode2string || "",
      })
        .subscribe((value: PrcContacts) => {
          this.modalService.createcontactclose()
          this.yourappsService.setContract([]);
        });
    }
  }

  addCompany(
    event: MouseEvent,
    type: number,
    autoInput: MatAutocompleteTrigger | null
  ): void {
    this.companyNameSubject.next([]);
    event.stopPropagation(); // This will stop the event from bubbling up
    SelfData.modaltype = type;
    if (autoInput != null) {
      autoInput.closePanel();
    }
    this.modalService.createcompanyopen();
  }

  getValue(sampleid: number) {
    let data = this.fieldscount;
    var accountid: number = 0;
    if (SelfData.account[0].data[0].parentid == 0) {
      accountid = SelfData.account[0].data[0].id;
    } else {
      accountid = SelfData.account[0].data[0].parentid;
    }
    for (let i = 0; i < data.length; i++) {
      const input = document.getElementById(
        data[i].fieldlabel
      ) as HTMLInputElement | null;
      if (input != null) {
        this.serviceservice
          .prcccfv({
            framename: data[i].framename,
            controllername: data[i].fieldlabel,
            accountid: accountid,
            value: input.value,
            sampleid: sampleid,
          })
          .subscribe((manuvalue) => {

          });
      }
    }
  }

  get firstAddressData(): GetAddressListData[] {
    if (this.SelfData && this.SelfData.addres && this.SelfData.addres[0] && this.SelfData.addres[0].data) {
      return this.SelfData.addres[0].data;
    }
    return [];
  }

  showAddEditFrame(
    showType: number,
    element: any,
    id: number = 0,
    description: string = ''
  ) {
    this.modalService.createaddressopen();
  }

  onCellDblClick(element: any) {
    console.log('Double clicked cell with data: ', element);
    this.yourappsService.setSelectedValue(element.description);
    this.modalService.closeSWS();
  }

  deleterecord(id: number) {
    this.yourappsService.setOKOption(2);
    this.yourappsService.setFrameid(id);
    this.messageBox.modalService.openMessageBox(
      'Information',
      'Are you sure you want to delete this entry?',
      'No',
      'Yes'
    );
  }
  add(
    event: MouseEvent,
    type: number,
    autoInput: MatAutocompleteTrigger | null
  ): void {
    event.stopPropagation(); // This will stop the event from bubbling up
    SelfData.modaltype = type;
    if (autoInput != null) {
      autoInput.closePanel();
    }
    // Determine the caption based on the provided type
    switch (type) {
      case 0:
        SelfData.modalformcaption = 'Pronoun';
        SelfData.frame = 0;
        SelfData.modaltype = 14;
        break;
      case 1:
        SelfData.modalformcaption = 'Category';
        SelfData.frame = 0;
        SelfData.modaltype = 15;
        break;
      case 2:
        SelfData.modalformcaption = 'Job Department';
        SelfData.frame = 0;
        SelfData.modaltype = 16;
        break;
      case 3:
        SelfData.modalformcaption = 'Type';
        SelfData.frame = 0;
        SelfData.modaltype = 17;
        break;
      case 4:
        SelfData.modalformcaption = 'Region';
        SelfData.frame = 0;
        SelfData.modaltype = 18;
        break;
      case 5:
        SelfData.modalformcaption = 'Reffered by';
        SelfData.frame = 0;
        SelfData.modaltype = 19;
        break;
      case 6:
        SelfData.modalformcaption = 'Associated Contacts';
        SelfData.frame = 0;
        SelfData.modaltype = 20;
        break;
      case 7:
        SelfData.modalformcaption = 'Contact Groups';
        SelfData.frame = 0;
        SelfData.modaltype = 21;
        break;
      case 9:
        SelfData.modalformcaption = 'Misc';
        SelfData.frame = 0;
        break;
      case 10:
        SelfData.modalformcaption = 'Type';
        SelfData.frame = 0;
        break;
      case 11:
        SelfData.modalformcaption = 'Size';
        SelfData.frame = 0;
        break;
      case 12:
        SelfData.modalformcaption = 'Look Book';
        SelfData.frame = 0;
        break;
      case 13:
        SelfData.modalformcaption = 'Size Range';
        SelfData.frame = 0;
        break;
      default:
        console.log(`Unsupported type: ${type}`);
        return;
    }

    // Invoke the modal
    this.modalService.createSWS();

    // your logic for when the add button is clicked
  }

  deleteImageFile(event: MouseEvent, index: number) {
    event.stopPropagation();
    this.selectedImageFiles.splice(index, 1);
  }

  zoomedIndex: number | null = null;
  zoomImage(index: number) {
    this.zoomedIndex = this.zoomedIndex === index ? null : index;
    SelfData.imageUrl = this.selectedImageFiles[index].url;
    this.modalService.createimagezoom();
  }

  onFileSelected(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0) {
      const file = inputElement.files[0];

      // Check if file type is an image
      if (file.type.startsWith('image/')) {
        this.selectedImageFiles = [];
        this.compressImage(file);
      } else {
        console.log('Please select an image file');
        this.selectedFile = null;
      }
    } else {
      this.selectedFile = null;
    }
  }

  base64ToFile(dataURI: string, fileName: string, mimeType: string): File {
    const byteString = atob(dataURI.split(',')[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    return new File([int8Array], fileName, { type: mimeType });
  }
  compressImage(file: File) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const image = e.target.result;
      this.imageCompressService.compressFile(image, -1, 50, 30).then(
        (result: string) => {
          // Convert the compressed image (base64) back to a File object and set it to selectedFile
          const compressedFile = this.base64ToFile(
            result,
            file.name,
            file.type
          );
          this.selectedFile = compressedFile;

          // Create an object with the compressed file, url, and filename
          const imageFileObject = {
            file: compressedFile,
            url: result,
            filename: file.name,
          };

          // Add the object to the selectedImageFiles array
          this.selectedImageFiles.push(imageFileObject);
        },
        (error: any) => {
          console.error('Error compressing image:', error);
        }
      );
    };
    reader.readAsDataURL(file);
  }

  onDateChange(result: Date): void {
    if (result) {
      this.expirationdate = result; // Keep it as a Date object
    }
  }

  getFormattedExpirationDate(): string | null {
    return this.expirationdate ? this.formatToMonthYear(this.expirationdate) : null;
  }

  formatToMonthYear(date: Date): string {
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Adds leading zero if necessary
    const year = date.getFullYear();
    return `${month}/${year}`;
  }

  protected readonly SelfData = SelfData;
  contactcaption: string = "Create Contact";
  statusfirstname: '' | 'error' | 'warning' = '';
  statuscategory: '' | 'error' | 'warning' = '';

  clearAllisDisabled: Boolean = false;
  submitIsDisabled: Boolean = false;
  departmentstring: string = "";
  myPronounstring: string = "";
  salutationstring: string = "";
  pousestring: string = "";
  firstNamestring: string = "";
  assistantnamestring: string = "";
  lastnamestring: string = "";
  assistanttitlestring: string = "";
  mobilestring: string = "";
  assistentmobile1string: string = "";
  emailstring: string = "";
  assistentmobile2string: string = "";
  emailAltstring: string = "";
  emailAssistentstring: string = "";
  eiCodestring: string = "";
  vcemailstring: string = "";
  taxidstring: string = "";
  commentsdstring: string = "";
  sidenotesstring: string = "";
  jobtitlestring: string = "";
  categorystring: string = "";
  contJDstring: string = "";
  contTypestring: string = "";
  regionstring: string = "";
  rbstring: string = "";
  associatedstring: string = "";
  groupsstring: string = "";
  instagramstring: string = "";
  facebookstring: string = "";
  youtubestring: string = "";
  tiktokstring: string = "";
  twitterstring: string = "";
  linkedinstring: string = "";
  companystring: string = "";
  zipcodestring: string = "";
  zipcode2string: string = "";
  careoffstring: string = "";
  countrystring: string = "";
  country2string: string = "";
  address1string: string = "";
  address12string: string = "";
  address2string: string = "";
  address22string: string = "";
  directphonestring: string = "";
  websitestring: string = "";
  citystring: string = "";
  city2string: string = "";
  emailrstring: string = "";
  statesstring: string = "";
  states2string: string = "";
  otheremailstring: string = "";
  cardnamestring: string = "";
  cardnumberstring: string = "";
  cvcodestring: string = "";
  expirationdate: Date | null = null;

  onInfoButtonClick() {
    window.open('https://fm.welcomeapps.net/FAQ/CreateContact.html', '_blank');
  }
}
