<form [formGroup]="myForm" (ngSubmit)="submit(1)" autocomplete="off">
    <button
            mat-icon-button
            type="button"
            class="close-btn"
            (click)="modalService.createnotsettingsclose()"
    >
        <mat-icon>close</mat-icon>
    </button>
    <p class="sample-manager-text">Notification Settings</p>
    <div class="black-background-contact">
        <div class="full-width-caption">
            <p class="caption-text">Late Returns</p>
            <div class="full-width-div-contact">
                <p class="caption-text1">
                    Late Returns - Select which days to receive reports
                </p>
                <!--        <p class="caption-text1">-->
                <!--          Select which days to send reminder after the deadline for returning items.-->
                <!--        </p>-->
            </div>
            <div class="full-width-div-1">
                <div class="full-width-div-2">
                    <mat-checkbox [formControl]="rsMonday">Monday</mat-checkbox>
                    <mat-checkbox [formControl]="rsTuesday">Tuesday</mat-checkbox>
                    <mat-checkbox [formControl]="rsWednesday">Wednesday</mat-checkbox>
                    <mat-checkbox [formControl]="rsThursday">Thursday</mat-checkbox>
                    <mat-checkbox [formControl]="rsFriday">Friday</mat-checkbox>
                    <mat-checkbox [formControl]="rsSaturday">Saturday</mat-checkbox>
                    <mat-checkbox [formControl]="rsSunday">Sunday</mat-checkbox>
                </div>
                <!--        <div class="vertical-divider"></div>-->
                <!--        <div class="full-width-div-2">-->
                <!--          <mat-checkbox [formControl]="osMonday">Monday</mat-checkbox>-->
                <!--          <mat-checkbox [formControl]="osTuesday">Tuesday</mat-checkbox>-->
                <!--          <mat-checkbox [formControl]="osWednesday">Wednesday</mat-checkbox>-->
                <!--          <mat-checkbox [formControl]="osThursday">Thursday</mat-checkbox>-->
                <!--          <mat-checkbox [formControl]="osFriday">Friday</mat-checkbox>-->
                <!--          <mat-checkbox [formControl]="osSaturday">Saturday</mat-checkbox>-->
                <!--          <mat-checkbox [formControl]="osSunday">Sunday</mat-checkbox>-->
                <!--        </div>-->
            </div>
            <!--      <div class="full-width-div-3">-->
            <!--        <mat-checkbox [checked]="true">Include sample details in returning samples report.</mat-checkbox>-->
            <!--      </div>-->
            <div class="full-width-div-4">
                <div>
                    <button
                            mat-raised-button
                            class="button-settings"
                            type="button"
                            (click)="sendLaTestMessage()"
                    >
                        Send test email
                    </button>
                    <button mat-raised-button class="button-settings" type="button">
                        Send notification to phone
                    </button>
                </div>
                <!--        <div>-->
                <!--          <button mat-raised-button class="button-settings" type="button">-->
                <!--            Send test email-->
                <!--          </button>-->
                <!--          <button mat-raised-button class="button-settings" type="button">-->
                <!--            Send test push in app-->
                <!--          </button>-->
                <!--        </div>-->
            </div>
            <div>
                <mat-checkbox [formControl]="email">Email</mat-checkbox>
                <br/>
                <mat-checkbox [formControl]="push">Notification to phone</mat-checkbox>
            </div>
        </div>
    </div>

    <div class="black-background-1">
        <div class="full-width-caption">
            <p class="caption-text">Sample Requests</p>
            <div>
                <mat-checkbox [formControl]="check1"
                >When new requests are made, I want to receive a
                    notification.
                </mat-checkbox
                >
                <mat-checkbox [formControl]="check2"
                >When new requests are changed, I want to receive a
                    notification.
                </mat-checkbox
                >
                <mat-checkbox [formControl]="check3"
                >When requests are declined, I want to receive a
                    notification.
                </mat-checkbox
                >
            </div>
        </div>
    </div>

    <div class="black-background-2">
        <div class="full-width-caption">
            <p class="caption-text">Sample Send-Outs</p>
            <div>
                <mat-checkbox [formControl]="check4"
                >When pending send-outs are recently created, I want to receive a
                    notification.
                </mat-checkbox
                >
                <mat-checkbox [formControl]="check5"
                >When a recent sample on my send-out has been deleted, I want to
                    receive a notification.
                </mat-checkbox
                >
            </div>
        </div>
    </div>
    <button mat-raised-button class="view-more-btn1">
        <!--    <mat-icon class="icon-spacing icon-scale">search</mat-icon>-->
        Submit
    </button>
    <mat-divider class="card-bottom-divider"></mat-divider>
</form>
