import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  NgZone,
  OnInit,
  Self,
  ViewChild,
} from '@angular/core';
import {
  GetContactCustomFieldsData,
  GetCustomFieldsData,
  GetSystemContactsFieldsData,
  SelfData,
} from '../../models/struct';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ModalserviceService } from '../../services/modalservice.service';
import { FormBuilder } from '@angular/forms';
import { HashService } from '../../services/hash.service';
import { MessageboxComponent } from '../../modals/messagebox/messagebox.component';
import { ServiceService } from '../../services/service.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { YourappsserviceService } from '../../services/yourappsservice.service';

@Component({
  selector: 'app-contactfieldsdialog',
  templateUrl: './contactfieldsdialog.component.html',
  styleUrls: ['./contactfieldsdialog.component.scss'],
})
export class ContactfieldsdialogComponent implements OnInit, AfterViewInit {
  ELEMENT_DATA: GetSystemContactsFieldsData[] = [];
  ELEMENT_CUSTOMDATA: GetCustomFieldsData[] = [];
  options: string[] = [];
  displayedColumns: string[] = ['fieldlabel', 'type', 'requered', 'actions'];

  iconMap: { [key: number]: string } = {
    1: 'text_fields',
    2: 'format_list_bulleted',
    3: 'calendar_month',
    4: 'checklist',
    5: 'flag',
    6: 'edit_attributes',
    7: '123',
  };

  dataSource = new MatTableDataSource<GetSystemContactsFieldsData>(
    this.ELEMENT_DATA
  );
  daCustomtaSource = new MatTableDataSource<GetContactCustomFieldsData>(
    this.ELEMENT_DATA
  );
  @ViewChild('paginator') paginator: MatPaginator | null = null;
  @ViewChild('customPaginator') customPaginator: MatPaginator | null = null;

  public get setdataSource() {
    return this.dataSource;
  }

  public get setCustomdataSource() {
    return this.daCustomtaSource;
  }

  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private hashService: HashService,
    public messageBox: MessageboxComponent,
    private serviceservice: ServiceService,
    private _snackBar: MatSnackBar,
    private yourappsService: YourappsserviceService,
    private ngZone: NgZone,
    private cdRef: ChangeDetectorRef
  ) {
    this.ELEMENT_DATA = SelfData.systemContactsfields[0].data;
    this.dataSource = new MatTableDataSource<GetSystemContactsFieldsData>(
      this.ELEMENT_DATA
    );
    this.ELEMENT_CUSTOMDATA = SelfData.contactcustomfields[0].data;
    this.daCustomtaSource = new MatTableDataSource<GetContactCustomFieldsData>(
      this.ELEMENT_CUSTOMDATA
    );
    // for (let i = 0; i < this.ELEMENT_DATA.length; i++) {
    //   this.options.push(this.ELEMENT_DATA[i].fieldlabel);
    // }

    this.yourappsService.reloadFieldList.subscribe((description) => {
      if (SelfData.fieldtype == 0 || SelfData.fieldtype == 2) {
        this.ELEMENT_CUSTOMDATA = SelfData.contactcustomfields[0].data;
        this.daCustomtaSource =
          new MatTableDataSource<GetContactCustomFieldsData>(
            this.ELEMENT_CUSTOMDATA
          );
        this.daCustomtaSource.paginator = this.customPaginator;
      } else if (SelfData.fieldtype == 1) {
        this.ELEMENT_DATA = SelfData.systemContactsfields[0].data;
        console.log(SelfData.systemContactsfields[0].data);
        this.dataSource = new MatTableDataSource<GetSystemContactsFieldsData>(
          this.ELEMENT_DATA
        );
        this.dataSource.paginator = this.paginator;
      }
    });
  }
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.daCustomtaSource.paginator = this.customPaginator;
  }

  ngOnInit(): void {}

  onCellDblClick(element: any) {
    console.log('Double clicked cell with data: ', element);
    // this.yourappsService.setSelectedValue(element.description);
    // this.modalService.closeSWS();
  }

  showAddEditFrame(
    showType: number,
    element: any,
    id: number = 0,
    description: string = ''
  ) {
    // console.log('clicked Edit: ', element);
    SelfData.formcontrol = 1;
    SelfData.fieldtype = showType;
    this.yourappsService.changeData(element);
    this.modalService.createFieldsEditor();
  }

  deleterecord(id: number) {
    // this.yourappsService.setOKOption(1);
    // this.yourappsService.setFrameid(id);
    // this.messageBox.modalService.openMessageBox(
    //   'Information',
    //   'Are you sure you want to delete this entry?',
    //   'No',
    //   'Yes'
    // );
  }
}
