import {AfterViewInit, ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder} from "@angular/forms";
import {HashService} from "../../services/hash.service";
import {MessageboxComponent} from "../../modals/messagebox/messagebox.component";
import {ServiceService} from "../../services/service.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MatTableDataSource} from "@angular/material/table";
import {
  CdlData, CdlResponse,
  FavoritesData,
  FavoritesValue,
  GetCollectionList,
  GetCollectionListData, GetDesignerList, GetDesignerListData,
  GetLookNameListData, GetSeasonList, GetSeasonListData,
  SelfData,
  SetContactsListData
} from "../../models/struct";
import {ConfirmationService} from "primeng/api";
import {Table} from "primeng/table";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-cdllist',
  templateUrl: './cdllist.component.html',
  styleUrls: ['./cdllist.component.scss']
})
export class CdllistComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('dt1') dt1!: Table;
  @ViewChild('dt2') dt2!: Table;
  @ViewChild('dt3') dt3!: Table;
  filtertext: string = ""
  filtertext1: string = ""
  filtertext2: string = ""
  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private hashService: HashService,
    public messageBox: MessageboxComponent,
    private serviceservice: ServiceService,
    private _snackBar: MatSnackBar,
    private yourappsService: YourappsserviceService,
    private ngZone: NgZone,
    private confirmationService: ConfirmationService,
    private cdRef: ChangeDetectorRef
  ) {

  }

  get collectionListData(): GetCollectionListData[] {
    if (this.SelfData && this.SelfData.collection && this.SelfData.collection[0] && this.SelfData.collection[0].data) {
      return this.SelfData.collection[0].data;
    }
    return [];
  }

  get designerListData(): GetDesignerListData[] {
    if (this.SelfData && this.SelfData.designer && this.SelfData.designer[0] && this.SelfData.designer[0].data) {
      return this.SelfData.designer[0].data;
    }
    return [];
  }

  get seasonListData(): GetSeasonListData[] {
    if (this.SelfData && this.SelfData.season && this.SelfData.season[0] && this.SelfData.season[0].data) {
      return this.SelfData.season[0].data;
    }
    return [];
  }

  private getAccountID(): number {
    return SelfData.account[0].data[0].parentid === 0
      ? SelfData.account[0].data[0].id
      : SelfData.account[0].data[0].parentid;
  }


  private subscription?: Subscription;
  iCollection: GetCollectionList[] = [];
  iDesignerResult: GetDesignerList[] = [];
  iSeasonResult: GetSeasonList[] = [];

  onButtonCollectionEditClick(item: any) {
    this.subscription = this.yourappsService.setUpdateValue$.subscribe((value: { data: string, type: number }) => {
      if (this.subscription) {
        if (value.type === 1) {
          console.log("value.data", value.data)
          const updateDetails: CdlData = {
            ctrl: value.type,
            id: item.id,
            description: value.data
          };
          this.serviceservice.updateCdl(updateDetails).subscribe({
            next: (value: CdlResponse) => {
              console.log("value", value)
            },
            error: (error: any) => {
              console.error('Error occurred:', error);
            },
            complete: () => {
              this.serviceservice
                .setCollectionList({ accountid: this.getAccountID() })
                .subscribe((collectionvalue) => {
                  this.iCollection[0] = collectionvalue;
                  SelfData.collection = this.iCollection;

                });
            }
          });
        }
        this.subscription.unsubscribe();
      }
    })
    this.yourappsService.setFrameCaption('Edit');
    this.yourappsService.setFrameDescription(item.description);
    this.yourappsService.setFrameid(item.id);
    this.yourappsService.setOperType(1);
    this.modalService.createAddEdit();
  }

  onButtonDesignerEditClick(item: any) {
    this.subscription = this.yourappsService.setUpdateValue$.subscribe((value: { data: string, type: number }) => {
      if (this.subscription) {
        if (value.type === 2) {
          console.log("value.data", value.data)
          const updateDetails: CdlData = {
            ctrl: value.type,
            id: item.id,
            description: value.data
          };
          this.serviceservice.updateCdl(updateDetails).subscribe({
            next: (value: CdlResponse) => {
              console.log("value", value)
            },
            error: (error: any) => {
              console.error('Error occurred:', error);
            },
            complete: () => {
              this.serviceservice
                .setDesignerList({ accountid: this.getAccountID() })
                .subscribe((desingervalue) => {
                  this.iDesignerResult[0] = desingervalue;
                  SelfData.designer = this.iDesignerResult;
                });
            }
          });
        }
        this.subscription.unsubscribe();
      }
    })
    this.yourappsService.setFrameCaption('Edit');
    this.yourappsService.setFrameDescription(item.description);
    this.yourappsService.setFrameid(item.id);
    this.yourappsService.setOperType(2);
    this.modalService.createAddEdit();
  }

  onButtonSeasonEditClick(item: any) {
    this.subscription = this.yourappsService.setUpdateValue$.subscribe((value: { data: string, type: number }) => {
      if (this.subscription) {
        if (value.type === 3) {
          console.log("value.data", value.data)
          const updateDetails: CdlData = {
            ctrl: value.type,
            id: item.id,
            description: value.data
          };
          this.serviceservice.updateCdl(updateDetails).subscribe({
            next: (value: CdlResponse) => {
              console.log("value", value)
            },
            error: (error: any) => {
              console.error('Error occurred:', error);
            },
            complete: () => {
              this.serviceservice
                .setSeasonList({ accountid: this.getAccountID() })
                .subscribe((seasonvalue) => {
                  this.iSeasonResult[0] = seasonvalue;
                  SelfData.season = this.iSeasonResult;
                });
            }
          });
        }
        this.subscription.unsubscribe();
      }
    })
    this.yourappsService.setFrameCaption('Edit');
    this.yourappsService.setFrameDescription(item.description);
    this.yourappsService.setFrameid(item.id);
    this.yourappsService.setOperType(3);
    this.modalService.createAddEdit();
  }


  onInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;
    this.dt1.filterGlobal(value, 'contains');
  }

  onInput1(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;
    this.dt2.filterGlobal(value, 'contains');
  }

  onInput2(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;
    this.dt3.filterGlobal(value, 'contains');
  }

  clear(table: Table) {
    table.clear();
    this.filtertext = ""
  }

  clear1(table: Table) {
    table.clear();
    this.filtertext1 = ""
  }

  clear2(table: Table) {
    table.clear();
    this.filtertext2 = ""
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnInit(): void {
  }

  protected readonly SelfData = SelfData;
}
