import { Component } from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";

@Component({
  selector: 'app-customersframe',
  templateUrl: './customersframe.component.html',
  styleUrl: './customersframe.component.scss'
})
export class CustomersframeComponent {
  constructor(public modalService: ModalserviceService) {}
}
