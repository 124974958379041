<form [formGroup]="myForm" (ngSubmit)="submit()" autocomplete="off">
  <button
    mat-icon-button
    type="button"
    class="close-btn"
    (click)="modalService.closeSeason()"
  >
    <mat-icon>close</mat-icon>
  </button>
  <p class="sample-manager-text">{{ frameCaption }}</p>
  <div class="black-background1">
    <mat-form-field class="selector-full-width" appearance="fill">
      <mat-label>Season Code</mat-label>
      <input
        type="text"
        placeholder=""
        aria-label=""
        matInput
        [formControl]="myControl"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="onOptionSelected($event)"
      >
        <mat-option
          *ngFor="let option of filteredOptions | async"
          [value]="option"
        >
          {{ option }}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="myControl.hasError('required')">
        Season Code is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </div>
  <div class="black-background">
    <mat-form-field class="selector-full-width" appearance="fill">
      <mat-label>Season Year</mat-label>
      <input
        type="text"
        placeholder=""
        aria-label=""
        matInput
        [formControl]="myYear"
        [matAutocomplete]="auto1"
      />
      <mat-autocomplete
        #auto1="matAutocomplete"
        (optionSelected)="onOptionSelected($event)"
      >
        <mat-option
          *ngFor="let option of filteredYearOptions | async"
          [value]="option"
        >
          {{ option }}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="myYear.hasError('required')">
        Season Year is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </div>

  <div class="black-background">
    <mat-form-field class="selector-full-width" appearance="fill">
      <mat-label>Season Name</mat-label>
      <input matInput [formControl]="myNameControl" />
      <mat-error *ngIf="myNameControl.hasError('required')">
        Season Name is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </div>

  <div class="button-container">
    <button mat-flat-button type="button" (click)="modalService.closeSeason()">
      Cancel
    </button>
    <button mat-flat-button type="submit">OK</button>
  </div>
</form>
