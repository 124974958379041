<div class="form-container">
  <table class="name-fields">
    <tr>
      <td>
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>First name</mat-label>
          <input matInput [formControl]="firstNameFormControl" required />
          <mat-error *ngIf="firstNameFormControl.hasError('required')">
            First name is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </td>
      <td>
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Last Name</mat-label>
          <input matInput [formControl]="lastNameFormControl" />
          <mat-error *ngIf="lastNameFormControl.hasError('required')">
            Last name is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </td>
    </tr>
    <tr>
      <td colspan="2">
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Working Email</mat-label>
          <input
            type="email"
            matInput
            [formControl]="emailFormControl"
            placeholder="Enter your Email"
          />
          <mat-error
            *ngIf="
              emailFormControl.hasError('email') &&
              !emailFormControl.hasError('required')
            "
          >
            Please enter a valid email address
          </mat-error>
          <mat-error *ngIf="emailFormControl.hasError('required')">
            Email is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </td>
    </tr>
    <tr>
      <td>
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Job Title</mat-label>
          <mat-select [formControl]="jobControl" name="Jobs" required>
            <mat-option>None</mat-option>
            <mat-option *ngFor="let job of iJobList" [value]="job.Description">
              {{ job.Description }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="jobControl.hasError('required')">
            Job title is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </td>
      <td>
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Industry</mat-label>
          <mat-select [formControl]="industryControl" name="Industry" required>
            <mat-option>None</mat-option>
            <mat-option
              *ngFor="let industry of iIndustry"
              [value]="industry.Description"
            >
              {{ industry.Description }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="industryControl.hasError('required')">
            Industry is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </td>
    </tr>
    <tr>
      <td>
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Country</mat-label>
          <mat-select
            [formControl]="countryFormControl"
            name="Country"
            required
          >
            <mat-option>None</mat-option>
            <mat-option
              *ngFor="let country of iCountry"
              [value]="country.Description"
            >
              {{ country.Description }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="countryFormControl.hasError('required')">
            Job title is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </td>
      <td>
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Company</mat-label>
          <input
            matInput
            [formControl]="companyFormControl"
            placeholder="Enter your company"
          />
          <mat-error *ngIf="companyFormControl.hasError('required')">
            Company is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </td>
    </tr>
    <tr>
      <td>
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Company Website</mat-label>
          <input
            matInput
            [formControl]="companyWebsiteFormControl"
            placeholder="Enter Company Website"
          />
          <mat-error *ngIf="companyWebsiteFormControl.hasError('required')">
            Company Website is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </td>
      <td>
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Number of Employees</mat-label>
          <mat-select [formControl]="noeFormControl" name="Noe" required>
            <mat-option>None</mat-option>
            <mat-option *ngFor="let noe of iNoe" [value]="noe.Description">
              {{ noe.Description }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="noeFormControl.hasError('required')">
            Number of Employees is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </td>
    </tr>
    <tr>
      <td>
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Phone</mat-label>
          <input
            matInput
            [formControl]="companyPhone"
            placeholder="Enter Phone number"
          />
          <mat-error *ngIf="companyPhone.hasError('pattern')">
            Must contain only numbers
          </mat-error>
        </mat-form-field>
      </td>
      <td>
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>How did you hear about us?</mat-label>
          <mat-select [formControl]="howdidFormControl" name="HowDid" required>
            <mat-option>None</mat-option>
            <mat-option
              *ngFor="let howdid of iAboutUS"
              [value]="howdid.Description"
            >
              {{ howdid.Description }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="howdidFormControl.hasError('required')">
            Value is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </td>
    </tr>
    <tr>
      <td colspan="1">
        <h5 class="text-stype">Which solutions interest you?</h5>
      </td>
    </tr>
    <tr></tr>
  </table>
  <section [formGroup]="toppings" class="checkbox-group">
    <mat-checkbox id="check1_1" class="checkbox1" formControlName="check1_1"
      >Samples</mat-checkbox
    >
    <br />
    <mat-checkbox id="check1_2" class="checkbox1" formControlName="check1_2"
      >Gallery</mat-checkbox
    >
    <br />
    <mat-checkbox id="check2" class="custom-checkbox" formControlName="check2"
      >Rental ShowRoom</mat-checkbox
    >
  </section>
  <!--  <button mat-raised-button class="view-more-btn" (click)="modalService.registrationopen()">Request a Demo</button>-->
</div>
