import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ModalserviceService } from '../../services/modalservice.service';
import { HashService } from '../../services/hash.service';
import { MessageboxComponent } from '../../modals/messagebox/messagebox.component';
import { ServiceService } from '../../services/service.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { YourappsserviceService } from '../../services/yourappsservice.service';
import { SwsdialogComponent } from '../swsdialog/swsdialog.component';
import { map, Observable, startWith } from 'rxjs';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import {
  GetCollectionList,
  GetDesignerList,
  GetSeasonList,
  GetSWSListData,
  SelfData,
} from '../../models/struct';
import {MessageService} from "primeng/api";

@Component({
  selector: 'app-collectiondialog',
  templateUrl: './collectiondialog.component.html',
  styleUrls: ['./collectiondialog.component.scss'],
})
export class CollectiondialogComponent implements OnInit {
  myForm: FormGroup;
  frameCaption: String = '1';
  frameid: number = 0;
  ELEMENT_DATA: GetSWSListData[] = [];
  myControl = new FormControl('');
  filteredDesignerOptions?: Observable<string[]>;
  filteredSeasonOptions?: Observable<string[]>;
  filteredCollectionOptions?: Observable<string[]>;
  designerptions: string[] = [];
  seasonoptions: string[] = [];
  collectionoptions: string[] = [];
  iDesignerResult: GetDesignerList[] = [];
  iSeasonResult: GetSeasonList[] = [];
  iCollection: GetCollectionList[] = [];
  desingerFormControl = new FormControl({ value: '', disabled: false }, [
    Validators.required,
  ]);
  seasonFormControl = new FormControl({ value: '', disabled: false }, [
    Validators.required,
  ]);
  collectionFormControl = new FormControl({ value: '', disabled: false }, [
    Validators.required,
  ]);

  myNameControl = new FormControl({ value: '', disabled: false }, [
    Validators.required,
  ]);
  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private hashService: HashService,
    public messageBox: MessageboxComponent,
    private serviceservice: ServiceService,
    private _snackBar: MatSnackBar,
    private messageService: MessageService,
    private yourappsService: YourappsserviceService
  ) {
    this.myForm = new FormGroup({
      // value: new FormControl(''),  // Initialize your form control
      // other form controls...
    });
    var accountid: number = 0;
    if (SelfData.account[0].data[0].parentid == 0) {
      accountid = SelfData.account[0].data[0].id;
    } else {
      accountid = SelfData.account[0].data[0].parentid;
    }
    this.serviceservice
      .setDesignerList({ accountid: accountid })
      .subscribe((desingervalue) => {
        this.iDesignerResult[0] = desingervalue;
        SelfData.designer = this.iDesignerResult;
        this.filteredDesignerOptions = this.setDataAndFilter(
          this.desingerFormControl,
          desingervalue,
          this.designerptions,
          2
        );
      });
    console.log(1, accountid)
    this.serviceservice
      .setCollectionList({ accountid: accountid })
      .subscribe((collectionvalue) => {
        this.iCollection[0] = collectionvalue;
        SelfData.collection = this.iCollection;
        this.filteredCollectionOptions = this.setDataAndFilter(
          this.collectionFormControl,
          collectionvalue,
          this.collectionoptions,
          5
        );
      });

    this.serviceservice
      .setSeasonList({ accountid: accountid })
      .subscribe((seasonvalue) => {
        this.iSeasonResult[0] = seasonvalue;
        SelfData.season = this.iSeasonResult;
        this.filteredSeasonOptions = this.setDataAndFilter(
          this.seasonFormControl,
          seasonvalue,
          this.seasonoptions,
          6
        );
      });
  }

  private setDataAndFilter(
    control: FormControl,
    response: any,
    options: string[],
    type: number
  ) {
    let results = response.data;
    for (let data of results) {
      options.push(data.description);
    }
    return control.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || '', type))
    );
  }

  private _filter(value: string, type: number): string[] {
    const filterValue = value.toLowerCase();
    switch (type) {
      case 2:
        return this.designerptions.filter((option) =>
          option.toLowerCase().includes(filterValue)
        );
      case 5:
        return this.collectionoptions.filter((option) =>
          option.toLowerCase().includes(filterValue)
        );
      case 6:
        return this.seasonoptions.filter((option) =>
          option.toLowerCase().includes(filterValue)
        );
      default:
        console.warn(`Unexpected filter type: ${type}`);
        return [];
    }
  }

  ngOnInit(): void {
    this.yourappsService.caption$.subscribe((caption) => {
      this.frameCaption = caption;
    });

    this.yourappsService.adddescription$.subscribe((description) => {
      var accountid: number = 0;
      if (description.trim() != '') {
        if (SelfData.account[0].data[0].parentid == 0) {
          accountid = SelfData.account[0].data[0].id;
        } else {
          accountid = SelfData.account[0].data[0].parentid;
        }
        switch (SelfData.modaltype) {
          case 2:
            this.serviceservice
              .setDesignerList({ accountid: accountid })
              .subscribe((designervalue) => {
                this.iDesignerResult[0] = designervalue;
                SelfData.designer = this.iDesignerResult;

                this.designerptions = this.iDesignerResult[0].data.map(
                  (item) => item.description
                );

                this.filteredDesignerOptions =
                  this.desingerFormControl.valueChanges.pipe(
                    startWith(''),
                    map((value) => this._filter(value || '', 2))
                  );

                this.ELEMENT_DATA = [...SelfData.designer[0].data]; // Create a new array reference
                this.yourappsService.getReloadArrayList();
                this.desingerFormControl.setValue(description);
                this._filter(description, 2);
              });
            break;
          default:
            return;
        }
      }
    });

    this.yourappsService.addSeasonName$.subscribe((description) => {
      var accountid: number = 0;
      if (description.trim() != '') {
        if (SelfData.account[0].data[0].parentid == 0) {
          accountid = SelfData.account[0].data[0].id;
        } else {
          accountid = SelfData.account[0].data[0].parentid;
        }
      }
      this.serviceservice
        .setSeasonList({ accountid: accountid })
        .subscribe((seasonvalue) => {
          this.iSeasonResult[0] = seasonvalue;
          SelfData.season = this.iSeasonResult;

          this.seasonoptions = this.iSeasonResult[0].data.map(
            (item) => item.description
          );

          this.filteredSeasonOptions = this.seasonFormControl.valueChanges.pipe(
            startWith(''),
            map((value) => this._filter(value || '', 6))
          );

          this.ELEMENT_DATA = [...SelfData.season[0].data]; // Create a new array reference
          this.yourappsService.getReloadArrayList();
          this.seasonFormControl.setValue(description);
          this._filter(description, 6);
        });
    });
  }

  add(
    event: MouseEvent,
    type: number,
    autoInput: MatAutocompleteTrigger
  ): void {
    event.stopPropagation(); // This will stop the event from bubbling up
    SelfData.modaltype = type;
    autoInput.closePanel();
    // Determine the caption based on the provided type
    switch (type) {
      case 2:
        this.showAddEditFrame(0);
        break;
      case 6:
        this.showSeasonAddEditFrame(0);
    }
  }

  showAddEditFrame(showType: number, id: number = 0, description: string = '') {
    if (showType === 0) {
      this.yourappsService.setFrameCaption('Add New');
      this.yourappsService.setFrameDescription('');
      this.yourappsService.setFrameid(id);
    } else if (showType === 1) {
      this.yourappsService.setFrameCaption('Edit');
      this.yourappsService.setFrameDescription(description);
      this.yourappsService.setFrameid(id);
    }
    this.modalService.createAddEdit();
  }

  showSeasonAddEditFrame(
    showType: number,
    id: number = 0,
    description: string = ''
  ) {
    console.dir(SelfData.frame);
    if (showType === 0) {
      this.yourappsService.setFrameCaption('Add New');
      this.yourappsService.setFrameDescription('');
      this.yourappsService.setFrameid(id);
    } else if (showType === 1) {
      this.yourappsService.setFrameCaption('Edit');
      this.yourappsService.setFrameDescription(description);
      this.yourappsService.setFrameid(id);
    }
    this.modalService.createSeason();
  }

  submit() {
    if (this.desingerFormControl.value?.trim() === "") {
      this.messageService.add({
        key: 'tc',
        severity: 'error',
        summary: 'Information',
        detail: 'Designer must be specified',
        life: 5000,
      });
      return
    }
    if (this.seasonFormControl.value?.trim() === "") {
      this.messageService.add({
        key: 'tc',
        severity: 'error',
        summary: 'Information',
        detail: 'Season must be specified',
        life: 5000,
      });
      return
    }
    if (this.collectionFormControl.value?.trim() === "") {
      this.messageService.add({
        key: 'tc',
        severity: 'error',
        summary: 'Information',
        detail: 'Collection must be specified',
        life: 5000,
      });
      return
    }
    this.modalService.closeCollection();
    let accountid: number = 0;
    if (SelfData.account[0].data[0].parentid == 0) {
      accountid = SelfData.account[0].data[0].id;
    } else {
      accountid = SelfData.account[0].data[0].parentid;
    }
    this.serviceservice
      .prccollection({
        ctrl: 0,
        id: 0,
        description: this.collectionFormControl?.value ?? undefined,
        accountid: accountid,
        desinger: this.desingerFormControl?.value ?? undefined,
        season: this.seasonFormControl?.value ?? undefined,
      })
      .subscribe((value) => {
        // this.yourappsService.setReloadCollection(
        //   this.desingerFormControl?.value ?? '',
        //   this.collectionFormControl?.value ?? '',
        //   this.seasonFormControl?.value ?? ''
        // );
        this.messageService.add({
          key: 'tc',
          severity: 'success',
          summary: 'Information',
          detail: 'Added successfully',
          life: 5000,
        });
        this.yourappsService.setAddFrameDescription(
          this.desingerFormControl?.value ?? ''
        );
        SelfData.modaltype = 5
        // this.yourappsService.setAddFrameSeason(this.myNameControl?.value || '')
      });
  }
}
