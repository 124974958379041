import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  NgZone,
  OnInit,
  Self,
  ViewChild,
} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {ModalserviceService} from '../../services/modalservice.service';
import {HashService} from '../../services/hash.service';
import {MessageboxComponent} from '../../modals/messagebox/messagebox.component';
import {ServiceService} from '../../services/service.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {
  GetAddressListData,
  GetCollectionList,
  GetCollectionListData,
  GetCompTypeList,
  GetCompTypeListData,
  GetContacList,
  GetContacListData,
  GetContCategoryList,
  GetContCategoryListData,
  GetContcgList,
  GetContcgListData,
  GetContjdList,
  GetContjdListData,
  GetContRbList,
  GetContRbListData,
  GetContRegionList,
  GetContRegionListData,
  GetContTypeList,
  GetContTypeListData,
  GetDesignerList,
  GetDesignerListData,
  GetFabricListData,
  GetManuList,
  GetManuListData,
  GetMiscList,
  GetMiscListData,
  GetPatternListData,
  GetPronounList,
  GetPronounListData,
  GetRegionList,
  GetRegionListData,
  GetSizeListData,
  GetSizeRangeListData,
  GetStyleListData,
  GetSWSList,
  GetSWSListData,
  GetTypeList,
  GetTypeListData,
  SelfData,
} from '../../models/struct';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {map, Observable, startWith, Subscription} from 'rxjs';
import {AddeditframedialogComponent} from '../addeditframedialog/addeditframedialog.component';
import {YourappsserviceService} from '../../services/yourappsservice.service';
import {Table} from "primeng/table";
import {Paginator} from "primeng/paginator";
import {MessageService} from "primeng/api";

@Component({
  selector: 'app-swsdialog',
  templateUrl: './swsdialog.component.html',
  styleUrls: ['./swsdialog.component.scss'],
  standalone: false,
})
export class SwsdialogComponent implements OnInit, AfterViewInit {
  @ViewChild('dt1') dt1!: Table;
  filtertext: string = '';
  value = 'Clear me';
  formCaption: string | undefined = '';
  displayedColumns: string[] = ['id', 'description', 'actions'];
  ELEMENT_DATA: GetSWSListData[] = [];
  ELEMENT_DATA_COL: GetCollectionListData[] = [];
  options: string[] = [];
  controller: number = 0;
  filteredOptions?: Observable<string[]>;
  dataSource = new MatTableDataSource<GetSWSListData>(this.ELEMENT_DATA);
  dataSourcecoll = new MatTableDataSource<GetCollectionListData>(
    this.ELEMENT_DATA_COL
  );
  myControl = new FormControl('');
  private subscription: Subscription | undefined;
  @ViewChild(MatPaginator) paginator: MatPaginator | null = null;
  @ViewChild(MatTable) table: MatTable<any> | undefined;

  iSWSResult: GetSWSList[] = [];
  iRegionResult: GetRegionList[] = [];
  iDesignerResult: GetDesignerList[] = [];
  iFabricResult: GetDesignerList[] = [];
  iManuResult: GetManuList[] = [];
  iStyleResult: GetManuList[] = [];
  iCollectionResult: GetCollectionList[] = [];
  iMiscResult: GetMiscList[] = [];
  iTypeResult: GetTypeList[] = [];
  iSizeResult: GetTypeList[] = [];
  iPatternResult: GetTypeList[] = [];
  iSizeRangeResult: GetTypeList[] = [];
  iPronounResult: GetPronounList[] = [];
  iContCategory: GetContCategoryList[] = [];
  iContJDResult: GetContjdList[] = [];
  iContTypeResult: GetContTypeList[] = [];
  iCompTypeResult: GetCompTypeList[] = [];
  iContRegionResult: GetContRegionList[] = [];
  iContRBResult: GetContRbList[] = [];
  iContACResult: GetContacList[] = [];
  iContCGResult: GetContcgList[] = [];
  sizes!: any[];
  selectedSize: any = '';

  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private hashService: HashService,
    public messageBox: MessageboxComponent,
    private serviceservice: ServiceService,
    private _snackBar: MatSnackBar,
    private yourappsService: YourappsserviceService,
    private messageService: MessageService,
    private ngZone: NgZone,
    private cdRef: ChangeDetectorRef
  ) {
    this.sizes = [
      {name: 'Small', class: 'p-datatable-sm'}
    ];
    this.selectedSize = this.sizes[0]
    this.formCaption = SelfData.modalformcaption;
    this.yourappsService.setAddFrameDescription('');
    switch (SelfData.modaltype) {
      case 0:
        this.ELEMENT_DATA = SelfData.sws[0].data;
        this.dataSource = new MatTableDataSource<GetSWSListData>(
          this.ELEMENT_DATA
        );
        for (let i = 0; i < this.ELEMENT_DATA.length; i++) {
          this.options.push(this.ELEMENT_DATA[i].description);
        }
        break;
      case 1:
        this.ELEMENT_DATA = SelfData.region[0].data;
        this.dataSource = new MatTableDataSource<GetRegionListData>(
          this.ELEMENT_DATA
        );
        for (let i = 0; i < this.ELEMENT_DATA.length; i++) {
          this.options.push(this.ELEMENT_DATA[i].description);
        }
        break;
      case 2:
        this.ELEMENT_DATA = SelfData.designer[0].data;
        this.dataSource = new MatTableDataSource<GetDesignerListData>(
          this.ELEMENT_DATA
        );
        for (let i = 0; i < this.ELEMENT_DATA.length; i++) {
          this.options.push(this.ELEMENT_DATA[i].description);
        }
        break;
      case 3:
        this.ELEMENT_DATA = SelfData.fabric[0].data;
        this.dataSource = new MatTableDataSource<GetFabricListData>(
          this.ELEMENT_DATA
        );
        for (let i = 0; i < this.ELEMENT_DATA.length; i++) {
          this.options.push(this.ELEMENT_DATA[i].description);
        }
        break;
      case 4:
        this.ELEMENT_DATA = SelfData.manu[0].data;
        this.dataSource = new MatTableDataSource<GetManuListData>(
          this.ELEMENT_DATA
        );
        break;
      case 5:
        this.ELEMENT_DATA_COL = SelfData.collection[0].data;
        this.dataSourcecoll = new MatTableDataSource<GetCollectionListData>(
          this.ELEMENT_DATA_COL
        );
        for (let i = 0; i < this.ELEMENT_DATA.length; i++) {
          this.options.push(this.ELEMENT_DATA[i].description);
        }
        break;
      case 7:
        this.ELEMENT_DATA = SelfData.style[0].data;
        this.dataSource = new MatTableDataSource<GetStyleListData>(
          this.ELEMENT_DATA
        );
        break;
      case 9:
        this.ELEMENT_DATA = SelfData.misc[0].data;
        this.dataSource = new MatTableDataSource<GetMiscListData>(
          this.ELEMENT_DATA
        );
        break;
      case 10:
        this.ELEMENT_DATA = SelfData.type[0].data;
        this.dataSource = new MatTableDataSource<GetTypeListData>(
          this.ELEMENT_DATA
        );
        break;
      case 11:
        this.ELEMENT_DATA = SelfData.size[0].data;
        this.dataSource = new MatTableDataSource<GetSizeListData>(
          this.ELEMENT_DATA
        );
        break;
      case 12:
        this.ELEMENT_DATA = SelfData.pattern[0].data;
        this.dataSource = new MatTableDataSource<GetPatternListData>(
          this.ELEMENT_DATA
        );
        break;
      case 13:
        this.ELEMENT_DATA = SelfData.sizerange[0].data;
        this.dataSource = new MatTableDataSource<GetSizeRangeListData>(
          this.ELEMENT_DATA
        );
        break;
      case 14:
        this.ELEMENT_DATA = SelfData.pronoun[0].data;
        this.dataSource = new MatTableDataSource<GetPronounListData>(
          this.ELEMENT_DATA
        );
        break;
      case 15:
        this.ELEMENT_DATA = SelfData.contcategory[0].data;
        this.dataSource = new MatTableDataSource<GetContCategoryListData>(
          this.ELEMENT_DATA
        );
        break;
      case 16:
        this.ELEMENT_DATA = SelfData.contjd[0].data;
        this.dataSource = new MatTableDataSource<GetContjdListData>(
          this.ELEMENT_DATA
        );
        break;
      case 17:
        this.ELEMENT_DATA = SelfData.conttype[0].data;
        this.dataSource = new MatTableDataSource<GetContTypeListData>(
          this.ELEMENT_DATA
        );
        break;
      case 18:
        this.ELEMENT_DATA = SelfData.contregion[0].data;
        this.dataSource = new MatTableDataSource<GetContRegionListData>(
          this.ELEMENT_DATA
        );
        break;
      case 19:
        this.ELEMENT_DATA = SelfData.contrb[0].data;
        this.dataSource = new MatTableDataSource<GetContRbListData>(
          this.ELEMENT_DATA
        );
        break;
      case 20:
        this.ELEMENT_DATA = SelfData.contac[0].data;
        this.dataSource = new MatTableDataSource<GetContacListData>(
          this.ELEMENT_DATA
        );
        break;
      case 21:
        this.ELEMENT_DATA = SelfData.contcg[0].data;
        this.dataSource = new MatTableDataSource<GetContcgListData>(
          this.ELEMENT_DATA
        );
        break;

      case 22:
        this.ELEMENT_DATA = SelfData.comptype[0].data;
        this.dataSource = new MatTableDataSource<GetCompTypeListData>(
          this.ELEMENT_DATA
        );
        break;

      default:
        return;
    }

    if (SelfData.modaltype >= 0) {
      this.dataSource = new MatTableDataSource<GetSWSListData>(
        this.ELEMENT_DATA
      );
      this.dataSourcecoll = new MatTableDataSource<GetCollectionListData>(
        this.ELEMENT_DATA_COL
      );
      this.controller = SelfData.frame;
    }
    // for (let i = 0; i < this.ELEMENT_DATA.length; i++) {
    //   this.options.push(this.ELEMENT_DATA[i].description);
    // }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  get firstswsData(): GetAddressListData[] {
    if (this.ELEMENT_DATA && this.SelfData.addres && this.SelfData.addres[0] && this.SelfData.addres[0].data) {
      return this.SelfData.addres[0].data;
    }
    return [];
  }

  public get setdataSource(): any[] {
    return this.controller === 0 ? this.dataSource.data : this.dataSourcecoll.data;
  }

  applyFilter(filterValue: string | null) {
    console.log(filterValue, SelfData.frame)
    if (SelfData.frame == 0) {
      if (filterValue) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
      } else {
        this.dataSource.filter = '';
      }
    } else if (SelfData.frame == 1) {
      if (filterValue) {
        this.dataSourcecoll.filter = filterValue.trim().toLowerCase();
      } else {
        this.dataSourcecoll.filter = '';
      }
    }
  }

  onInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;
    this.dt1.filterGlobal(value, 'contains');
  }

  clear(table: Table) {
    table.clear();
    this.filtertext = '';
  }

  ngOnInit(): void {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || ''))
    );

    if (SelfData.frame == 0) {
      this.dataSource.filterPredicate = (
        data: GetSWSListData,
        filter: string
      ) => {
        return data.description.toLowerCase().includes(filter);
      };
    } else if (SelfData.frame == 1) {
      this.dataSourcecoll.filterPredicate = (
        data: GetCollectionListData,
        filter: string
      ) => {
        return data.description.toLowerCase().includes(filter);
      };
    }

    this.yourappsService.reloadSWSList.subscribe(() => {
      var accountid: number = 0;
      if (SelfData.account[0].data[0].parentid == 0) {
        accountid = SelfData.account[0].data[0].id;
      } else {
        accountid = SelfData.account[0].data[0].parentid;
      }

      console.log("reloadSWSList", SelfData.modaltype)
      switch (SelfData.modaltype) {
        case 0:
          this.serviceservice
            .setSWSList({accountid: accountid})
            .subscribe((swsvalue) => {
              this.iSWSResult[0] = swsvalue;
              SelfData.sws = this.iSWSResult;
              this.ELEMENT_DATA = [...SelfData.sws[0].data]; // Create a new array reference
              this.refresh();
              this.myControl.setValue('');
              this.applyFilter('');
              this.yourappsService.getReloadArrayList();
            });
          break;
        case 1:
          this.serviceservice
            .setRegionList({accountid: accountid})
            .subscribe((regionvalue) => {
              this.iRegionResult[0] = regionvalue;
              SelfData.region = this.iRegionResult;
              this.ELEMENT_DATA = [...SelfData.region[0].data]; // Create a new array reference
              this.refresh();
              this.myControl.setValue('');
              this.applyFilter('');
              this.yourappsService.getReloadArrayList();
            });
          break;
        case 2:
          this.serviceservice
            .setDesignerList({accountid: accountid})
            .subscribe((designervalue) => {
              this.iDesignerResult[0] = designervalue;
              SelfData.designer = this.iDesignerResult;
              this.ELEMENT_DATA = [...SelfData.designer[0].data]; // Create a new array reference
              this.refresh();
              this.myControl.setValue('');
              this.applyFilter('');
            });
          break;
        case 3:
          this.serviceservice
            .setFabricList({accountid: accountid})
            .subscribe((fabricvalue) => {
              this.iFabricResult[0] = fabricvalue;
              SelfData.fabric = this.iFabricResult;
              this.ELEMENT_DATA = [...SelfData.fabric[0].data]; // Create a new array reference
              this.refresh();
              this.myControl.setValue('');
              this.applyFilter('');
              this.yourappsService.getReloadArrayList();
            });
          break;
        case 4:
          this.serviceservice
            .setManuList({accountid: accountid})
            .subscribe((manuvalue) => {
              this.iManuResult[0] = manuvalue;
              SelfData.manu = this.iManuResult;
              this.ELEMENT_DATA = [...SelfData.manu[0].data]; // Create a new array reference
              this.refresh();
              this.myControl.setValue('');
              this.applyFilter('');
              this.yourappsService.getReloadArrayList();
            });
          break;
        case 5:
          this.serviceservice
            .setCollectionList({accountid: accountid})
            .subscribe((collectionvalue) => {
              this.iCollectionResult[0] = collectionvalue;
              SelfData.collection = this.iCollectionResult;
              this.ELEMENT_DATA_COL = [...SelfData.collection[0].data]; // Create a new array reference
              this.refresh();
              this.myControl.setValue('');
              this.applyFilter('');
              this.yourappsService.getReloadArrayList();
            });
          break;
        case 7:
          this.serviceservice
            .setStyleList({accountid: accountid})
            .subscribe((value) => {
              this.iStyleResult[0] = value;
              SelfData.style = this.iStyleResult;
              this.ELEMENT_DATA = [...SelfData.style[0].data]; // Create a new array reference
              this.refresh();
              this.myControl.setValue('');
              this.applyFilter('');
              this.yourappsService.getReloadArrayList();
            });
          break;
        case 9:
          this.serviceservice
            .setMiscList({accountid: accountid})
            .subscribe((value) => {
              this.iMiscResult[0] = value;
              SelfData.misc = this.iMiscResult;
              this.ELEMENT_DATA = [...SelfData.misc[0].data]; // Create a new array reference
              this.refresh();
              this.myControl.setValue('');
              this.applyFilter('');
              this.yourappsService.getReloadArrayList();
            });
          break;
        case 10:
          this.serviceservice
            .setTypeList({accountid: accountid})
            .subscribe((value) => {
              this.iTypeResult[0] = value;
              SelfData.type = this.iTypeResult;
              this.ELEMENT_DATA = [...SelfData.type[0].data]; // Create a new array reference
              this.refresh();
              this.myControl.setValue('');
              this.applyFilter('');
              this.yourappsService.getReloadArrayList();
            });
          break;
        case 11:
          this.serviceservice
            .setSizeList({accountid: accountid})
            .subscribe((value) => {
              this.iSizeResult[0] = value;
              SelfData.size = this.iSizeResult;
              this.ELEMENT_DATA = [...SelfData.size[0].data]; // Create a new array reference
              this.refresh();
              this.myControl.setValue('');
              this.applyFilter('');
              this.yourappsService.getReloadArrayList();
            });
          break;
        case 12:
          this.serviceservice
            .setPatternList({accountid: accountid})
            .subscribe((value) => {
              this.iPatternResult[0] = value;
              SelfData.pattern = this.iPatternResult;
              this.ELEMENT_DATA = [...SelfData.pattern[0].data]; // Create a new array reference
              this.refresh();
              this.myControl.setValue('');
              this.applyFilter('');
              this.yourappsService.getReloadArrayList();
            });
          break;
        case 13:
          this.serviceservice
            .setSizeRangeList({accountid: accountid})
            .subscribe((value) => {
              this.iSizeRangeResult[0] = value;
              SelfData.sizerange = this.iSizeRangeResult;
              this.ELEMENT_DATA = [...SelfData.sizerange[0].data]; // Create a new array reference
              this.refresh();
              this.myControl.setValue('');
              this.applyFilter('');
              this.yourappsService.getReloadArrayList();
            });
          break;
        case 14:
          this.serviceservice
            .setPronounList({accountid: accountid})
            .subscribe((value) => {
              console.log(value)
              this.iPronounResult[0] = value;
              // SelfData.pronoun = this.iPronounResult;
              // this.ELEMENT_DATA = [...SelfData.pronoun[0].data]; // Create a new array reference
              this.refresh();
              this.myControl.setValue('');
              this.applyFilter('');
              this.yourappsService.getReloadArrayList();
            });
          break;
        case 15:
          this.serviceservice
            .setContCategoryList({accountid: accountid})
            .subscribe((value) => {
              this.iContCategory[0] = value;
              SelfData.contcategory = this.iContCategory;
              this.ELEMENT_DATA = [...SelfData.contcategory[0].data]; // Create a new array reference
              this.refresh();
              this.myControl.setValue('');
              this.applyFilter('');
              this.yourappsService.getReloadArrayList();
            });
          break;
        case 16:
          this.serviceservice
            .setContJDList({accountid: accountid})
            .subscribe((value) => {
              this.iContJDResult[0] = value;
              SelfData.contjd = this.iContJDResult;
              this.ELEMENT_DATA = [...SelfData.contjd[0].data]; // Create a new array reference
              this.refresh();
              this.myControl.setValue('');
              this.applyFilter('');
              this.yourappsService.getReloadArrayList();
            });
          break;
        case 17:
          this.serviceservice
            .setContTypeList({accountid: accountid})
            .subscribe((value) => {
              this.iContTypeResult[0] = value;
              SelfData.conttype = this.iContTypeResult;
              this.ELEMENT_DATA = [...SelfData.conttype[0].data]; // Create a new array reference
              this.refresh();
              this.myControl.setValue('');
              this.applyFilter('');
              this.yourappsService.getReloadArrayList();
            });
          break;
        case 18:
          this.serviceservice
            .setContRegionList({accountid: accountid})
            .subscribe((value) => {
              this.iContRegionResult[0] = value;
              SelfData.contregion = this.iContRegionResult;
              this.ELEMENT_DATA = [...SelfData.contregion[0].data]; // Create a new array reference
              this.refresh();
              this.myControl.setValue('');
              this.applyFilter('');
              this.yourappsService.getReloadArrayList();
            });
          break;
        case 19:
          this.serviceservice
            .setContRBList({accountid: accountid})
            .subscribe((value) => {
              this.iContRBResult[0] = value;
              SelfData.contrb = this.iContRBResult;
              this.ELEMENT_DATA = [...SelfData.contrb[0].data]; // Create a new array reference
              this.refresh();
              this.myControl.setValue('');
              this.applyFilter('');
              this.yourappsService.getReloadArrayList();
            });
          break;
        case 20:
          this.serviceservice
            .setContACList({accountid: accountid})
            .subscribe((value) => {
              this.iContACResult[0] = value;
              SelfData.contac = this.iContACResult;
              this.ELEMENT_DATA = [...SelfData.contac[0].data]; // Create a new array reference
              this.refresh();
              this.myControl.setValue('');
              this.applyFilter('');
              this.yourappsService.getReloadArrayList();
            });
          break;
        case 21:
          this.serviceservice
            .setContCGList({accountid: accountid})
            .subscribe((value) => {
              this.iContCGResult[0] = value;
              SelfData.contcg = this.iContCGResult;
              this.ELEMENT_DATA = [...SelfData.contcg[0].data]; // Create a new array reference
              this.refresh();
              this.myControl.setValue('');
              this.applyFilter('');
              this.yourappsService.getReloadArrayList();
            });
          break;
        case 22:
          this.serviceservice
            .setCompTypeList({accountid: accountid})
            .subscribe((value) => {
              this.iCompTypeResult[0] = value;
              SelfData.comptype = this.iCompTypeResult;
              this.ELEMENT_DATA = [...SelfData.comptype[0].data]; // Create a new array reference
              this.refresh();
              this.myControl.setValue('');
              this.applyFilter('');
              this.yourappsService.getReloadArrayList();
            });
          break;
        default:
          return;
      }
    });
    // return
    if (SelfData.frame == 0) {
      this.yourappsService.adddescription$.subscribe((description) => {
        var accountid: number = 0;
        // if (description.trim() != '') {
        if (SelfData.account[0].data[0].parentid == 0) {
          accountid = SelfData.account[0].data[0].id;
        } else {
          accountid = SelfData.account[0].data[0].parentid;
          // }
          switch (SelfData.modaltype) {
            case 0:
              this.serviceservice
                .setSWSList({accountid: accountid})
                .subscribe((swsvalue) => {
                  this.iSWSResult[0] = swsvalue;
                  SelfData.sws = this.iSWSResult;
                  this.ELEMENT_DATA = [...SelfData.sws[0].data]; // Create a new array reference
                  this.refresh();
                  this.yourappsService.getReloadArrayList();
                  this.myControl.setValue(description);
                  this.applyFilter(description);
                });
              break;
            case 1:
              this.serviceservice
                .setRegionList({accountid: accountid})
                .subscribe((regionvalue) => {
                  this.iRegionResult[0] = regionvalue;
                  SelfData.region = this.iRegionResult;
                  this.ELEMENT_DATA = [...SelfData.region[0].data]; // Create a new array reference
                  this.refresh();
                  this.yourappsService.getReloadArrayList();
                  this.myControl.setValue(description);
                  this.applyFilter(description);
                });
              break;
            case 2:
              this.serviceservice
                .setDesignerList({accountid: accountid})
                .subscribe((designervalue) => {
                  this.iDesignerResult[0] = designervalue;
                  SelfData.designer = this.iDesignerResult;
                  this.ELEMENT_DATA = [...SelfData.designer[0].data]; // Create a new array reference
                  this.refresh();
                  this.yourappsService.getReloadArrayList();
                  this.myControl.setValue(description);
                  this.applyFilter(description);
                });
              break;
            case 3:
              this.serviceservice
                .setFabricList({accountid: accountid})
                .subscribe((fabricvalue) => {
                  this.iFabricResult[0] = fabricvalue;
                  SelfData.fabric = this.iFabricResult;
                  this.ELEMENT_DATA = [...SelfData.fabric[0].data]; // Create a new array reference
                  this.refresh();
                  this.yourappsService.getReloadArrayList();
                  this.myControl.setValue(description);
                  this.applyFilter(description);
                });
              break;
            case 4:
              this.serviceservice
                .setManuList({accountid: accountid})
                .subscribe((manuvalue) => {
                  this.iManuResult[0] = manuvalue;
                  SelfData.manu = this.iManuResult;
                  this.ELEMENT_DATA = [...SelfData.manu[0].data]; // Create a new array reference
                  this.refresh();
                  this.yourappsService.getReloadArrayList();
                  this.myControl.setValue(description);
                  this.applyFilter(description);
                });
              break;
            case 5:
              this.serviceservice
                .setCollectionList({accountid: accountid})
                .subscribe((collectionvalue) => {
                  this.iCollectionResult[0] = collectionvalue;
                  SelfData.collection = this.iCollectionResult;
                  this.ELEMENT_DATA_COL = [...SelfData.collection[0].data]; // Create a new array reference
                  this.refresh();
                  this.yourappsService.getReloadArrayList();
                  this.myControl.setValue(description);
                  this.applyFilter(description);
                });
              break;
            case 7:
              this.serviceservice
                .setStyleList({accountid: accountid})
                .subscribe((value) => {
                  this.iStyleResult[0] = value;
                  SelfData.style = this.iStyleResult;
                  this.ELEMENT_DATA = [...SelfData.style[0].data]; // Create a new array reference
                  this.refresh();
                  this.yourappsService.getReloadArrayList();
                  this.myControl.setValue(description);
                  this.applyFilter(description);
                });
              break;
            case 9:
              this.serviceservice
                .setMiscList({accountid: accountid})
                .subscribe((value) => {
                  this.iMiscResult[0] = value;
                  SelfData.misc = this.iMiscResult;
                  this.ELEMENT_DATA = [...SelfData.misc[0].data]; // Create a new array reference
                  this.refresh();
                  this.yourappsService.getReloadArrayList();
                  this.myControl.setValue(description);
                  this.applyFilter(description);
                });
              break;
            case 10:
              this.serviceservice
                .setTypeList({accountid: accountid})
                .subscribe((value) => {
                  this.iTypeResult[0] = value;
                  SelfData.type = this.iTypeResult;
                  this.ELEMENT_DATA = [...SelfData.type[0].data]; // Create a new array reference
                  this.refresh();
                  this.yourappsService.getReloadArrayList();
                  this.myControl.setValue(description);
                  this.applyFilter(description);
                });
              break;
            case 11:
              this.serviceservice
                .setSizeList({accountid: accountid})
                .subscribe((value) => {
                  this.iSizeResult[0] = value;
                  SelfData.size = this.iSizeResult;
                  this.ELEMENT_DATA = [...SelfData.size[0].data]; // Create a new array reference
                  this.refresh();
                  this.yourappsService.getReloadArrayList();
                  this.myControl.setValue(description);
                  this.applyFilter(description);
                });
              break;
            case 12:
              this.serviceservice
                .setPatternList({accountid: accountid})
                .subscribe((value) => {
                  this.iPatternResult[0] = value;
                  SelfData.pattern = this.iPatternResult;
                  this.ELEMENT_DATA = [...SelfData.pattern[0].data]; // Create a new array reference
                  this.refresh();
                  this.yourappsService.getReloadArrayList();
                  this.myControl.setValue(description);
                  this.applyFilter(description);
                });
              break;
            case 13:
              this.serviceservice
                .setSizeRangeList({accountid: accountid})
                .subscribe((value) => {
                  this.iSizeRangeResult[0] = value;
                  SelfData.sizerange = this.iSizeRangeResult;
                  this.ELEMENT_DATA = [...SelfData.sizerange[0].data]; // Create a new array reference
                  this.refresh();
                  this.yourappsService.getReloadArrayList();
                  this.myControl.setValue(description);
                  this.applyFilter(description);
                });
              break;
            case 14:
              this.serviceservice
                .setPronounList({accountid: accountid})
                .subscribe((value) => {
                  this.iPronounResult[0] = value;
                  SelfData.pronoun = this.iPronounResult;
                  this.ELEMENT_DATA = [...SelfData.pronoun[0].data]; // Create a new array reference
                  this.refresh();
                  this.yourappsService.getReloadArrayList();
                  this.myControl.setValue(description);
                  this.applyFilter(description);
                });
              break;
            case 15:
              this.serviceservice
                .setContCategoryList({accountid: accountid})
                .subscribe((value) => {
                  this.iContCategory[0] = value;
                  SelfData.contcategory = this.iContCategory;
                  this.ELEMENT_DATA = [...SelfData.contcategory[0].data]; // Create a new array reference
                  this.refresh();
                  this.yourappsService.getReloadArrayList();
                  this.myControl.setValue(description);
                  this.applyFilter(description);
                });
              break;
            case 16:
              this.serviceservice
                .setContJDList({accountid: accountid})
                .subscribe((value) => {
                  this.iContJDResult[0] = value;
                  SelfData.contjd = this.iContJDResult;
                  this.ELEMENT_DATA = [...SelfData.contjd[0].data]; // Create a new array reference
                  this.refresh();
                  this.yourappsService.getReloadArrayList();
                  this.myControl.setValue(description);
                  this.applyFilter(description);
                });
              break;
            case 17:
              this.serviceservice
                .setContTypeList({accountid: accountid})
                .subscribe((value) => {
                  this.iContTypeResult[0] = value;
                  SelfData.conttype = this.iContTypeResult;
                  this.ELEMENT_DATA = [...SelfData.conttype[0].data]; // Create a new array reference
                  this.refresh();
                  this.yourappsService.getReloadArrayList();
                  this.myControl.setValue(description);
                  this.applyFilter(description);
                });
              break;
            case 18:
              this.serviceservice
                .setContRegionList({accountid: accountid})
                .subscribe((value) => {
                  this.iContRegionResult[0] = value;
                  SelfData.contregion = this.iContRegionResult;
                  this.ELEMENT_DATA = [...SelfData.contregion[0].data]; // Create a new array reference
                  this.refresh();
                  this.yourappsService.getReloadArrayList();
                  this.myControl.setValue(description);
                  this.applyFilter(description);
                });
              break;
            case 19:
              this.serviceservice
                .setContRBList({accountid: accountid})
                .subscribe((value) => {
                  this.iContRBResult[0] = value;
                  SelfData.contrb = this.iContRBResult;
                  this.ELEMENT_DATA = [...SelfData.contrb[0].data]; // Create a new array reference
                  this.refresh();
                  this.yourappsService.getReloadArrayList();
                  this.myControl.setValue(description);
                  this.applyFilter(description);
                });
              break;
            case 20:
              this.serviceservice
                .setContACList({accountid: accountid})
                .subscribe((value) => {
                  this.iContACResult[0] = value;
                  SelfData.contac = this.iContACResult;
                  this.ELEMENT_DATA = [...SelfData.contac[0].data]; // Create a new array reference
                  this.refresh();
                  this.yourappsService.getReloadArrayList();
                  this.myControl.setValue(description);
                  this.applyFilter(description);
                });
              break;
            case 21:
              this.serviceservice
                .setContCGList({accountid: accountid})
                .subscribe((value) => {
                  this.iContCGResult[0] = value;
                  SelfData.contcg = this.iContCGResult;
                  this.ELEMENT_DATA = [...SelfData.contcg[0].data]; // Create a new array reference
                  this.refresh();
                  this.yourappsService.getReloadArrayList();
                  this.myControl.setValue(description);
                  this.applyFilter(description);
                });
              break;
            case 22:
              this.serviceservice
                .setCompTypeList({accountid: accountid})
                .subscribe((value) => {
                  this.iCompTypeResult[0] = value;
                  SelfData.comptype = this.iCompTypeResult;
                  this.ELEMENT_DATA = [...SelfData.comptype[0].data]; // Create a new array reference
                  this.refresh();
                  this.yourappsService.getReloadArrayList();
                  this.myControl.setValue(description);
                  this.applyFilter(description);
                });
              break;
            default:
              return;
          }
        }
      });
    } else if (SelfData.frame == 1) {

      this.yourappsService.adddescription$.subscribe((description) => {
        var accountid: number = 0;
        // if (description.trim() != '') {
        if (SelfData.account[0].data[0].parentid == 0) {
          accountid = SelfData.account[0].data[0].id;
        } else {
          accountid = SelfData.account[0].data[0].parentid;
        }
        // }

        this.serviceservice
          .setCollectionList({accountid: accountid})
          .subscribe((collectionvalue) => {
            this.iCollectionResult[0] = collectionvalue;
            SelfData.collection = this.iCollectionResult;
            this.ELEMENT_DATA_COL = [...SelfData.collection[0].data]; // Create a new array reference
            this.refresh();
            this.yourappsService.getReloadArrayList();
            this.myControl.setValue(description);
            this.applyFilter(description);
          });
      });
    }
  }

  ngAfterViewInit() {
    if (SelfData.frame == 0) {
      this.dataSource.paginator = this.paginator;
    } else if (SelfData.frame == 1) {
      this.dataSourcecoll.paginator = this.paginator;
    }
  }

  clearValue() {
    this.myControl.setValue(''); // Clear the form control value
    this.applyFilter(this.myControl.value);
  }

  showAddEditFrame(
    showType: number,
    element: any,
    id: number = 0,
    description: string = ''
  ) {
    if (SelfData.frame == 0) {
      if (showType === 0) {
        this.yourappsService.setFrameCaption('Add New');
        this.yourappsService.setFrameDescription('');
        this.yourappsService.setFrameid(id);
      } else if (showType === 1) {
        this.yourappsService.setFrameCaption('Edit');
        this.yourappsService.setFrameDescription(description);
        this.yourappsService.setFrameid(id);
      }
      this.modalService.createAddEdit();
    } else if (SelfData.frame == 1) {
      if (showType === 0) {
        this.yourappsService.setFrameCaption('Add New');
        this.yourappsService.setFrameDescription('');
        this.yourappsService.setFrameid(id);
      } else if (showType === 1) {
        this.yourappsService.setFrameCaption('Edit');
        this.yourappsService.setFrameDescription(description);
        this.yourappsService.setFrameid(id);
      }
      this.modalService.createCollection();
    }
  }

  deleterecord(id: number) {
    this.yourappsService.setOKOption(1);
    this.yourappsService.setFrameid(id);
    this.messageBox.modalService.openMessageBox(
      'Information',
      'Are you sure you want to delete this entry?',
      'No',
      'Yes'
    );
  }

  refresh() {
    switch (SelfData.modaltype) {
      case 0:
        if (this.iSWSResult && this.iSWSResult.length > 0) {
          this.ngZone.run(() => {
            this.dataSource = new MatTableDataSource<GetSWSListData>(
              this.iSWSResult[0].data
            );
            this.dataSource.paginator = this.paginator;
          });
        }
        break;
      case 1:
        if (this.iRegionResult && this.iRegionResult.length > 0) {
          this.ngZone.run(() => {
            this.dataSource = new MatTableDataSource<GetRegionListData>(
              this.iRegionResult[0].data
            );
            this.dataSource.paginator = this.paginator;
          });
        }
        break;
      case 2:
        if (this.iDesignerResult && this.iDesignerResult.length > 0) {
          this.ngZone.run(() => {
            this.dataSource = new MatTableDataSource<GetDesignerListData>(
              this.iDesignerResult[0].data
            );
            this.dataSource.paginator = this.paginator;
          });
        }
        break;
      case 3:
        if (this.iFabricResult && this.iFabricResult.length > 0) {
          this.ngZone.run(() => {
            this.dataSource = new MatTableDataSource<GetFabricListData>(
              this.iFabricResult[0].data
            );
            this.dataSource.paginator = this.paginator;
          });
        }
        break;
      case 4:
        if (this.iManuResult && this.iManuResult.length > 0) {
          this.ngZone.run(() => {
            this.dataSource = new MatTableDataSource<GetManuListData>(
              this.iManuResult[0].data
            );
            this.dataSource.paginator = this.paginator;
          });
        }
        break;
      // ... similar blocks for cases 5 through 13 ...
      case 5:
        this.ngZone.run(() => {
          this.dataSourcecoll = new MatTableDataSource<GetCollectionListData>(
            this.iCollectionResult[0].data
          );
          this.dataSourcecoll.paginator = this.paginator;
        });
        break;

      case 7:
        this.ngZone.run(() => {
          this.dataSource = new MatTableDataSource<GetStyleListData>(
            this.iStyleResult[0].data
          );
          this.dataSource.paginator = this.paginator;
        });
        break;
      case 9:
        this.ngZone.run(() => {
          this.dataSource = new MatTableDataSource<GetMiscListData>(
            this.iMiscResult[0].data
          );
          this.dataSource.paginator = this.paginator;
        });
        break;
      case 10:
        if (this.iTypeResult && this.iTypeResult.length > 0 && this.iTypeResult[0].data) {
          this.ngZone.run(() => {
            this.dataSource = new MatTableDataSource<GetTypeListData>(
              this.iTypeResult[0].data
            );
            this.dataSource.paginator = this.paginator;
          });
        } else {
          console.error('iTypeResult[0] or its data property is undefined');
        }
        break;
      case 11:
        this.ngZone.run(() => {
          this.dataSource = new MatTableDataSource<GetSizeListData>(
            this.iSizeResult[0].data
          );
          this.dataSource.paginator = this.paginator;
        });
        break;
      case 12:
        this.ngZone.run(() => {
          this.dataSource = new MatTableDataSource<GetPatternListData>(
            this.iPatternResult[0].data
          );
          this.dataSource.paginator = this.paginator;
        });
        break;
      case 13:
        this.ngZone.run(() => {
          this.dataSource = new MatTableDataSource<GetSizeRangeListData>(
            this.iSizeRangeResult[0].data
          );
          this.dataSource.paginator = this.paginator;
        });
        break;

      case 14:
        if (this.iPronounResult && this.iPronounResult.length > 0) {
          this.ngZone.run(() => {
            this.dataSource = new MatTableDataSource<GetPronounListData>(
              this.iPronounResult[0].data
            );
            this.dataSource.paginator = this.paginator;
          });
        }
        break;
      case 15:
        if (this.iContCategory && this.iContCategory.length > 0) {
          this.ngZone.run(() => {
            this.dataSource = new MatTableDataSource<GetContCategoryListData>(
              this.iContCategory[0].data
            );
            this.dataSource.paginator = this.paginator;
          });
        }
        break;
      case 16:
        if (this.iContJDResult && this.iContJDResult.length > 0) {
          this.ngZone.run(() => {
            this.dataSource = new MatTableDataSource<GetContjdListData>(
              this.iContJDResult[0].data
            );
            this.dataSource.paginator = this.paginator;
          });
        }
        break;
      case 17:
        if (this.iContTypeResult && this.iContTypeResult.length > 0) {
          this.ngZone.run(() => {
            this.dataSource = new MatTableDataSource<GetContTypeListData>(
              this.iContTypeResult[0].data
            );
            this.dataSource.paginator = this.paginator;
          });
        }
        break;
      case 18:
        if (this.iContRegionResult && this.iContRegionResult.length > 0) {
          this.ngZone.run(() => {
            this.dataSource = new MatTableDataSource<GetContRegionListData>(
              this.iContRegionResult[0].data
            );
            this.dataSource.paginator = this.paginator;
          });
        }
        break;
      case 19:
        if (this.iContRBResult && this.iContRBResult.length > 0) {
          this.ngZone.run(() => {
            this.dataSource = new MatTableDataSource<GetContRbListData>(
              this.iContRBResult[0].data
            );
            this.dataSource.paginator = this.paginator;
          });
        }
        break;
      case 20:
        if (this.iContACResult && this.iContACResult.length > 0) {
          this.ngZone.run(() => {
            this.dataSource = new MatTableDataSource<GetContacListData>(
              this.iContACResult[0].data
            );
            this.dataSource.paginator = this.paginator;
          });
        }
        break;
      case 21:
        if (this.iContCGResult && this.iContCGResult.length > 0) {
          this.ngZone.run(() => {
            this.dataSource = new MatTableDataSource<GetContcgListData>(
              this.iContCGResult[0].data
            );
            this.dataSource.paginator = this.paginator;
          });
        }
        break;
      case 22:
        if (this.iCompTypeResult && this.iCompTypeResult.length > 0) {
          this.ngZone.run(() => {
            this.dataSource = new MatTableDataSource<GetCompTypeListData>(
              this.iCompTypeResult[0].data
            );
            this.dataSource.paginator = this.paginator;
          });
        }


        break;
      default:
        return;
    }
  }

  onCellDblClick(element: any) {
    console.log('Double clicked cell with data: ', element);
    this.yourappsService.setSelectedValue(element.description);

  }

  onRowDoubleClick(event: any): void {
    console.log('Row double-clicked', event, SelfData.frame);
    this.yourappsService.getCollectionsByID(event, 1)
    this.modalService.closeSWS();
    // Implement your logic here, for example, opening a dialog or navigating to another page
  }

  submit() {
    // Your form submission logic
  }

  protected readonly SelfData = SelfData;
}
