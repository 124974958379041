<form [formGroup]="myForm" (ngSubmit)="submit()" autocomplete="off">
  <button mat-icon-button type="button" class="close-btn" (click)="modalService.closePrinterFrame()">
    <mat-icon>close</mat-icon>
  </button>
  <p class="sample-manager-text-dm">Delivery Memo</p>
  <div id="scrollable-div-dm" class="scrollable-div-dm" [ngClass]="{'show-scroll': showScroll}">
    <div class="full-width-div-dm">
      <div class="form-field-spacing-contact form-field-h-dm"
           style="overflow-y: auto;border: 0 solid #ccc;padding: 5px;resize: none;">
        <p *ngFor="let msg of messages" [style.color]="msg.color" [innerHTML]="msg.text">

        </p>
      </div>
      <div class="barcode-div-dm">
        <div class="barcode-div-dm">
          <div class="red-overlay">
              <span class="centered-text">Delivery: {{SelfData.account[0].data[0].barcodeprefix + '-' + SelfData.sendoutid}}</span>
          </div>
          <app-barcode [element]="SelfData.account[0].data[0].barcodeprefix+SelfData.sendoutid" [text]="'fashion-m.com'" [height]="60" [width]="1.48" [marginBottom]="35"
                       [marginRight]="-0.00001" [fontSize]="14" [background]="'rgba(255,255,255,0)'" class="barcode-component"></app-barcode>
        </div>
      </div>
    </div>
    <!--2 line-->
    <div class="black-background1-dm">
      <div class="child-60-dm">
        <div class="full-width-div-dm1">
          <div class="red-overlay1"></div>
          <div class="div-dm1">
            <p class="sample-manager-text-dm1">Delivery</p>

          </div>

          <div class="div-dm1">
            <p class="sample-manager-text-dm2">Editor/Stylist: {{SendOut1Data.companynameControl}}</p>
          </div>

          <div class="form-field-spacing-contact form-field-h-dm"
               style="overflow-y: auto;border: 0px solid #ccc;padding: 5px;resize: none;">
            <p *ngFor="let msg of messages2" [style.color]="msg.color" [innerHTML]="msg.text">
            </p>
          </div>

          <div class="red-overlay2"></div>
        </div>
      </div>
      <div class="child-40-dm">
        <div class="form-field-spacing-contact form-field-h-dm"
             style="overflow-y: auto;border: 1px solid #ccc;padding: 5px;resize: none; background-color: #e5e5e5;">
          <p *ngFor="let msg of messages1" [style.color]="msg.color" [innerHTML]="msg.text">
          </p>
        </div>
      </div>
    </div>

    <!--        3 line-->
    <div class="full-width-div-dm2">
      <section class="example-container-print" tabindex="0">
        <table mat-table [dataSource]="setdataSource" class="example-container-print">
          <ng-container matColumnDef="Designer">
            <th class="field1 mat-header-row-dm" mat-header-cell *matHeaderCellDef>
              Designer
              <br/>
              Collection
              <br/>
              Season
            </th>
            <td mat-cell *matCellDef="let element">
              <span class="mat-text-row-dm">
                {{ element?.["Designer"] }}
                <br/>
                {{ element?.["Collection"] }}
                <br/>
                {{ element?.["Season"] }}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="Type">
            <th class="typefield mat-header-row-dm" mat-header-cell *matHeaderCellDef>
              Type <br/>
              Barcode <br/>
              Color <br/>
            </th>
            <td mat-cell *matCellDef="let element">
              <span class="mat-text-row-dm">
                {{ element?.["Type"] }}
                <br/>
                {{ element?.["barcode"] }}
                <br/>
                {{ element?.["Color"] }}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="Description">
            <th class="descriptionfield mat-header-row-dm" mat-header-cell *matHeaderCellDef>
              Description
            </th>
            <td mat-cell *matCellDef="let element">
                            <span class="mat-text-row-dm">
                            {{ element?.["Description"] }}
                            </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="Image">
            <th class="imagefield mat-header-row-dm" mat-header-cell *matHeaderCellDef>
              Image
            </th>
            <td mat-cell *matCellDef="let element" class="icon-buttons">
              <!--                [src]="element?.['Image Link']"-->
<!--              [src]="transformImageUrl(element?.['Image Link'])"-->
              <img
                [src]="transformImageUrl(element?.['Image Link'])"

                alt="Image"
                class="image-size-80x80-dm"
              />
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <!--                <mat-paginator class="sticky-paginator" [pageSizeOptions]="[5, 10, 20, 50, 100]"-->
        <!--                               aria-label="Select page of periodic elements">-->
        <!--                </mat-paginator>-->
      </section>
      <section class="example-container-print" tabindex="0">
        <div class="full-width-div-dm4">
          <span [innerHTML]="footertext"></span>
        </div>
      </section>
    </div>

  </div>
  <div class="button-container-dm-left">
    <button mat-raised-button class="button-settings-dm" type="button" (click)="onButtonExportToExcelClick()">
      Excel
      <mat-icon>heap_snapshot_large</mat-icon>
    </button>

    <button mat-raised-button class="button-settings-dm" type="button" (click)="onButtonEmailClick()">
      E-Mail
      <mat-icon>mail</mat-icon>
    </button>
    <button mat-raised-button class="button-settings-dm" type="button" (click)="onAppButtonClick()">
      App
      <mat-icon>edit</mat-icon>
    </button>
  </div>
  <div class="button-container-dm">
    <button mat-raised-button class="button-settings-dm" type="button" (click)="onButtonEditClick()">
      Edit
      <mat-icon>edit</mat-icon>
    </button>
    <button mat-raised-button class="button-settings-dm" type="button" (click)="onButtonPrintClick()">
      Print
      <mat-icon>print</mat-icon>
    </button>

    <button mat-raised-button class="button-settings-dm" type="button" (click)="onBarcodeClick()">
      Barcodes
      <mat-icon>barcode_scanner</mat-icon>
    </button>

  </div>
</form>
