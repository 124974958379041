import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import JsBarcode from 'jsbarcode';

@Component({
  selector: 'app-barcode',
  templateUrl: './barcode.component.html',
  styleUrls: ['./barcode.component.scss'],
})
export class BarcodeComponent implements AfterViewInit {
  @ViewChild('barcodeSvg') barcodeSvg!: ElementRef;
  @Input() element!: string;
  @Input() text!: string;
  @Input() fontSize: number = 12;
  @Input() textPosition: string = "bottom";
  @Input() fontOptions: string = "bold";
  @Input() width: number = 1;
  @Input() height: number = 40;
  @Input() textMargin: number = 0;
  @Input() textAlign: string = "center";
  @Input() format: string = "CODE128";
  @Input() font: string = "cursive";
  @Input() displayValue: boolean = true;
  @Input() lineColor: string = "#000";
  @Input() background: string = "#ffffff";
  @Input() marginLeft: number = 0;
  @Input() marginRight: number = 0;
  @Input() marginTop: number = 0;
  @Input() marginBottom: number = 0;

  ngAfterViewInit() {
    let barcodeElement: any = this.barcodeSvg.nativeElement;
    JsBarcode(barcodeElement, this.element, {
      format: this.format,
      lineColor: this.lineColor,
      textAlign: this.textAlign,
      textPosition: this.textPosition, // Corrected from "bootom" to "bottom"
      font: this.font,
      background: this.background,
      fontOptions: this.fontOptions,
      fontSize: this.fontSize,
      textMargin: this.textMargin,
      height: this.height,
      width: this.width,
      displayValue: this.displayValue,
      text: this.text,
      marginLeft: this.marginLeft,
      marginRight: this.marginRight,
      marginTop: this.marginTop,
      marginBottom: this.marginBottom,
    });
  }
}
