import { Component } from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";

@Component({
  selector: 'app-emailframe',
  templateUrl: './emailframe.component.html',
  styleUrls: ['./emailframe.component.scss']
})
export class EmailframeComponent {
  constructor(public modalService: ModalserviceService) {}
}
