<button mat-icon-button type="button" class="close-btn"  (click)="modalService.orClose()">
  <mat-icon>close</mat-icon>
</button>
<nz-steps nzType="navigation" nzSize="small" class="steps-content" [nzCurrent]="current">
  <nz-step class="steps-action" nzTitle="Location"></nz-step>
  <nz-step class="steps-action" nzTitle="Name"></nz-step>
<!--  <nz-step class="steps-action" nzTitle="Taxes"></nz-step>-->
  <nz-step class="steps-action" nzTitle="Registers"></nz-step>
  <nz-step class="steps-action" nzTitle="Payment types"></nz-step>
  <nz-step class="steps-action" nzTitle="On-account"></nz-step>
  <nz-step class="steps-action" nzTitle="Loyalty"></nz-step>
<!--  <nz-step class="steps-action" nzTitle="Users"></nz-step>-->
<!--  <nz-step class="steps-action" nzTitle="Security"></nz-step>-->
</nz-steps>
<div *ngIf="current === 0" class="locationdiv">
  <div class="headerdiv">
    <samp class="titlediv">Where is your outlet?</samp>
  </div>
  <div class="headerdiv">
    <samp class="titlediv1">Enter your store's address to configure the right settings for this outlet.</samp>
  </div>

  <div class="componentsdiv">
    <div class="componentsdiv2">
      <label class="txtdiv">Street</label>
      <nz-input-group>
        <input class="cmdiv" type="text" [(ngModel)]="address" nz-input/>
      </nz-input-group>

    </div>
    <div class="componentsdiv2">
      <label class="txtdiv">Street</label>
      <nz-input-group>
        <input class="cmdiv" type="text" [(ngModel)]="address2" nz-input/>
      </nz-input-group>
    </div>
  </div>

  <div class="componentsdiv">
    <div class="componentsdiv2">
      <label class="txtdiv">Suburb</label>
      <nz-input-group>
        <input class="cmdiv" type="text" [(ngModel)]="suburb" nz-input/>
      </nz-input-group>

    </div>
    <div class="componentsdiv2">
      <label class="txtdiv">City</label>
      <nz-input-group>
        <input class="cmdiv" type="text" [(ngModel)]="city" nz-input/>
      </nz-input-group>
    </div>
  </div>

  <div class="componentsdiv">
    <div class="componentsdiv2">
      <label class="txtdiv">ZIP code</label>
      <nz-input-group>
        <input class="cmdiv" type="text" [(ngModel)]="zipcode" nz-input/>
      </nz-input-group>

    </div>
    <div class="componentsdiv2">
      <label class="txtdiv">State</label>
      <nz-input-group nzSearch>
        <nz-select nzShowSearch [nzShowArrow]="true" class="cmdiv"
          [(ngModel)]="statesstring">
          <nz-option *ngFor="let option of (states ?? [])" [nzLabel]="option['state']"
            [nzValue]="option['state']"></nz-option>
        </nz-select>
      </nz-input-group>
    </div>
  </div>

  <div class="componentsdiv">
    <div class="componentsdiv2">
      <label class="txtdiv">Country</label>
      <nz-select nzShowSearch [nzShowArrow]="true" class="cmdiv"
        (ngModelChange)="onOptionSelected($event)"
        [(ngModel)]="countrystring">
        <nz-option *ngFor="let option of (countries ?? [])" [nzLabel]="option['country']"
          [nzValue]="option['country']"></nz-option>
      </nz-select>

    </div>
    <div class="componentsdiv2">
      <label class="txtdiv">Time zone</label>
      <nz-input-group nzSearch>
        <nz-select nzShowSearch [nzShowArrow]="true" class="cmdiv"
          [(ngModel)]="defaulttimezone">
          <nz-option *ngFor="let option of timezone" [nzLabel]="option.viewvalue" [nzValue]="option.value">
          </nz-option>
        </nz-select>
      </nz-input-group>
    </div>
  </div>

  <div class="lastdiv">

  </div>

</div>

<div *ngIf="current === 1" class="locationdiv">
  <div class="headerdiv">
    <samp class="titlediv">What is the name of this outlet?</samp>
  </div>
  <div class="headerdiv">
    <samp class="titlediv1">Outlet names are used in important places like inventory orders, reporting and sales history. Choose a name that's descriptive (i.e. including its location), so others can easily and quickly recognize it.</samp>
  </div>

  <div class="componentsdiv">
    <div class="componentsdiv2">
      <label class="txtdiv">Outlet name</label>
      <nz-input-group>
        <input class="cmdiv" type="text" [(ngModel)]="outletname" nz-input/>
      </nz-input-group>

    </div>
    <div class="componentsdiv2">

    </div>
  </div>

  <div class="lastdiv">

  </div>

</div>

<div *ngIf="current === 2" class="locationdiv">
  <div class="headerdiv">
    <samp class="titlediv">What registers does the "{{outletname}}" outlet need?</samp>
  </div>
  <div class="headerdiv">
    <samp class="titlediv1">Set up registers for the different departments, payment counters or POS machines this outlet may have so that you can accurately report on staff activity, sales performance, payments and more. If you have an integrated ecommerce store, create a register for that, too.</samp>
  </div>

  <div class="componentsdiv">
    <div class="componentsdiv2">
      <label class="txtdiv">Registers</label>
      <nz-input-group>
        <input class="cmdiv" type="text" [(ngModel)]="register" nz-input/>
      </nz-input-group>

    </div>
    <div class="componentsdiv2">

    </div>
  </div>

  <div class="lastdiv">

  </div>

</div>

<div *ngIf="current === 3" class="locationdiv">
  <div class="headerdiv">
    <samp class="titlediv">Payment type</samp>
  </div>
  <div class="headerdiv">
    <samp class="titlediv1">The payment type you use with your registrar</samp>
  </div>

  <div class="componentsdiv">
    <div class="componentsdiv2">
      <label class="txtdiv">Payment type (Cash)</label>
      <nz-input-group [nzAddOnAfter]="suffixIconButton">
        <input disabled = "true" class="cmdiv" type="text" [(ngModel)]="paymenttypecash" nz-input/>
      </nz-input-group>

      <ng-template #suffixIconButton>
<!--        <button class="buttonclass" nz-button nzType="default" nzSearch (click)="onTaxesClick()">-->
<!--          &lt;!&ndash;              <span nz-icon nzType="unordered-list" nzTheme="outline"></span>&ndash;&gt;-->
<!--          <i nz-icon nzType="ellipsis" nzTheme="outline"></i>-->
<!--        </button>-->
      </ng-template>

    </div>
    <div class="componentsdiv2">
      <label class="txtdiv">Payment type (Other)</label>
      <nz-input-group [nzAddOnAfter]="suffixIconButton">
        <input disabled = "true" class="cmdiv" type="text" [(ngModel)]="paymenttypeother" nz-input/>
      </nz-input-group>

      <ng-template #suffixIconButton>
<!--        <button class="buttonclass" nz-button nzType="default" nzSearch (click)="onTaxesClick()">-->
<!--          &lt;!&ndash;              <span nz-icon nzType="unordered-list" nzTheme="outline"></span>&ndash;&gt;-->
<!--          <i nz-icon nzType="ellipsis" nzTheme="outline"></i>-->
<!--        </button>-->
      </ng-template>
    </div>
  </div>

  <div class="lastdiv">

  </div>

</div>

<div *ngIf="current === 4" class="locationdiv">
  <div class="headerdiv">
    <samp class="titlediv">On-account</samp>
  </div>
  <div class="headerdiv">
    <samp class="titlediv1">Select the default setting for how new customers can use an on-account balance</samp>
  </div>

  <div class="componentsdiv_sub">
    <div class="componentsdiv2">
      <label class="txtdiv">Allow on-account balance</label>
      <nz-radio-group class="radiogroup" [(ngModel)]="radioValue">
        <label nz-radio nzValue="0">No</label>
        <label nz-radio nzValue="1">Yes, with no balance limit</label>
        <label nz-radio nzValue="2">Yes, but with a balance limit</label>
      </nz-radio-group>
      <nz-input-group *ngIf='radioValue === "2"'>
        <nz-input-number class="cmdiv" [(ngModel)]="limit" nzMin="1" />
      </nz-input-group>
    </div>
    <div class="componentsdiv2">

    </div>
  </div>

  <div class="lastdiv">

  </div>

</div>

<div *ngIf="current === 5" class="locationdiv">
  <div class="headerdiv">
    <samp class="titlediv">On-Loyalty</samp>
  </div>
  <div class="headerdiv">
    <samp class="titlediv1">Choose how much Loyalty customers earn as a percentage of the retail price of every sale in-store and through Lightspeed eCom (E-Series).</samp>
  </div>

  <div class="componentsdiv">
    <div class="componentsdiv2">
      <label class="txtdiv">Loyalty earned per sale</label>
      <nz-input-group >
        <nz-input-number class="cmdiv"
          [(ngModel)]="loyalty"
          [nzFormatter]="formatterPercent"
          [nzMin]="0"
          [nzStep]="1"></nz-input-number>
      </nz-input-group>
    </div>
    <div class="componentsdiv2">

    </div>
  </div>

  <div class="lastdiv">

  </div>

</div>

<!--Footer-->
<div class="footerdiv">
  @if (current > 0) {
    <button class="footerbuttondiv" nz-button nzType="default" (click)="pre()">
      <span>Previous</span>
    </button>
  }
  @if (current < 5) {
    <button class="footerbuttondiv" nz-button nzType="default" (click)="next()">
      <span>Next</span>
    </button>
  }
  @if (current === 5) {
    <button class="footerbuttondiv" nz-button nzType="primary" (click)="done()">
      <span>Done</span>
    </button>
  }
</div>
