<button mat-icon-button type="button" class="close-btn"  (click)="modalService.closeEmail()">
    <mat-icon>close</mat-icon>
</button>
<p class="sample-manager-editor">Send Email</p>

<div class="black-background-mail">
    <mat-form-field class="full-width">
        <mat-label>To</mat-label>
        <input matInput type="text" [formControl]="toControl" />
      <button mat-stroked-button type="button" class="add-button" matSuffix (click)="onEmailContactsClick()">
        <mat-icon class="center-icon">add</mat-icon>
      </button>
    </mat-form-field>
</div>

<div class="black-background-mail">
    <mat-form-field class="full-width">
        <mat-label>Subject</mat-label>
        <input matInput type="text" [formControl]="subjectControl" />
    </mat-form-field>
</div>

<div class="card-email">
    <p-editor [(ngModel)]="text" [style]="{ height: '185px' }">
<!--        <ng-template pTemplate="header">-->
<!--        <span class="ql-formats">-->
<!--            <button type="button" class="ql-bold" aria-label="Bold"></button>-->
<!--            <button type="button" class="ql-italic" aria-label="Italic"></button>-->
<!--            <button type="button" class="ql-underline" aria-label="Underline"></button>-->
<!--        </span>-->
<!--        </ng-template>-->
    </p-editor>
</div>

<div class="button-container-label">
    <button mat-raised-button class="button-settings" type="button" (click)="submit()">
        Send
    </button>
</div>
