<form [formGroup]="myForm" (ngSubmit)="submit()" autocomplete="off">
  <button
    mat-icon-button
    type="button"
    class="close-btn"
    (click)="modalService.closeAddEdit()"
  >
    <mat-icon>close</mat-icon>
  </button>
  <p class="sample-manager-text">{{ frameCaption }}</p>
  <div class="black-background1">
    <mat-form-field class="full-width">
      <mat-label>Description</mat-label>
      <input matInput type="text" [formControl]="myControl" />
      <button
        *ngIf="myControl.value"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="clearValue()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div class="button-container">
    <button mat-flat-button type="button" (click)="modalService.closeAddEdit()">
      Cancel
    </button>
    <button mat-flat-button type="submit">OK</button>
  </div>
</form>
