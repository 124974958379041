import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
    OnDestroy,
    OnInit,
    Renderer2, Self, ViewChild
} from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ConfirmationService, ConfirmEventType, MessageService} from "primeng/api";
import {NgxImageCompressService} from "ngx-image-compress";
import { HttpClient } from "@angular/common/http";
import {DOCUMENT} from "@angular/common";
import {CarouselComponent} from "smart-webcomponents-angular/carousel";
import {map, Observable, startWith, Subscription} from "rxjs";
import {
  DesignerAccountDetails,
  GetDesignerList,
  Getlbdesignerlist,
  LbMainData,
  LbMainDetails, LbSelectedDesigner, LbSelectedSeasonRequest,
  SelfData, transformImageUrl
} from "../../models/struct";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {MatCheckboxChange} from "@angular/material/checkbox";

@Component({
    selector: 'app-createcidialog',
    templateUrl: './createcidialog.component.html',
    styleUrls: ['./createcidialog.component.scss']
})
export class CreatecidialogComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('carousel', {read: CarouselComponent, static: false}) carousel!: CarouselComponent;
    formcaption: string = "Custom Collections";
    filteredDesignerOptions?: Observable<string[]>;
    iDesignerResult: Getlbdesignerlist[] = [{Code: 0, ErrorMessage: '', data: []}];
    designerptions: string[] = [];
    isChecked: boolean = false;
    isVisibled: boolean = false;
    pageindex: number = 0
    showdialog: boolean = false
    editDisabled: boolean = false;
    opertype: number = 0;
    private subscription?: Subscription;
    collectionFormControl = new FormControl({value: '', disabled: false}, [
        Validators.required,
    ]);
    desingerFormControl = new FormControl({value: '', disabled: false}, []);
    selectedImages: {
        index: number;
        file: File | null;
        url: string;
        filename: string;
        lbcatalogid: number;
        colection: string;
        designer: string;
        catalog: string;
        catalogyear: number;
        seasoncode: string;
    }[] = []
    selectedImageFiles: {
        file: File | null;
        url: string;
        filename: string;
        lbcatalogid: number;
        colection: string;
        designer: string;
        catalog: string;
        catalogyear: number;
        seasoncode: string;
    }[] = [];
    imageview: boolean = false;
    checkboxcaption: string = "Select";

    private getAccountID(): number {
        return SelfData.account[0].data[0].parentid === 0
            ? SelfData.account[0].data[0].id
            : SelfData.account[0].data[0].parentid;
    }

    constructor(
        public modalService: ModalserviceService,
        public fb: FormBuilder,
        private serviceservice: ServiceService,
        private yourappsService: YourappsserviceService,
        private _snackBar: MatSnackBar,
        private cdr: ChangeDetectorRef,
        private messageService: MessageService,
        private imageCompressService: NgxImageCompressService,
        private http: HttpClient,
        private confirmationService: ConfirmationService,
        private renderer: Renderer2, @Inject(DOCUMENT) private document: Document,
        private el: ElementRef
    ) {
        this.serviceservice
            .selectlbdesignerlist({accountid: this.getAccountID()})
            .subscribe((value) => {
                this.iDesignerResult[0] = value;
                SelfData.lbdesignerlist = this.iDesignerResult;
                console.log(value)
                this.filteredDesignerOptions = this.setDataAndFilter(
                    this.desingerFormControl,
                    value,
                    this.designerptions,
                    2
                );
            });
    }

    private setDataAndFilter(
        control: FormControl,
        response: any,
        options: string[],
        type: number
    ) {
        let results = response.data;
        for (let data of results) {
            options.push(data.designer);
        }
        return control.valueChanges.pipe(
            startWith(''),
            map((value) => this._filter(value || '', type))
        );
    }

    private _filter(value: string, type: number): string[] {
        const filterValue = value.toLowerCase();
        switch (type) {
            case 2:
                return this.designerptions.filter((option) =>
                    option.toLowerCase().includes(filterValue)
                );
            default:
                return []; // default return for other cases
        }
    }

    onDesignerSelected(event: MatAutocompleteSelectedEvent): void {
        const selectedDesigner = event.option.value;
        const designerObj = this.iDesignerResult[0].data.find(d => d.designer === selectedDesigner);
        if (designerObj) {
            console.log('Selected Designer ID:', designerObj.desingerid);
            this.loadCustomImageList(designerObj.desingerid)
            // You can now use designerObj.designerid as needed
        } else {
            console.log('Designer not found');
        }
    }

    loadCustomImageList(designerid: number): void {
        this.selectedImageFiles = []
        this.serviceservice.selectcustomimages({accountid: this.getAccountID(), designerid: designerid})
            .subscribe({
                next: (value: any) => {
                    if (value.data.length > 0) {
                        value.data.forEach((imageinfo: any) => {
                            const catalog = imageinfo.catalog
                            const catalogyear = imageinfo.catalogyear
                            const colection = imageinfo.colection
                            const designer = imageinfo.designer
                            const filedir = transformImageUrl(imageinfo.filedir)
                            // const filedir = imageinfo.filedir
                            const filename = imageinfo.filename
                            const lbcatalogid = imageinfo.lbcatalogid
                            const seasoncode = imageinfo.seasoncode
                            this.selectedImageFiles.push({
                                file: null,
                                url: filedir,
                                filename: filename,
                                catalog: catalog,
                                catalogyear: catalogyear,
                                colection: colection,
                                designer: designer,
                                lbcatalogid: lbcatalogid,
                                seasoncode: seasoncode,
                            });
                        })
                        this.isVisibled = true
                        if (this.findImageByIndex(0)) {
                            this.isChecked = true
                            this.checkboxcaption = "Remove"
                        } else {
                            this.isChecked = false
                            this.checkboxcaption = "Select"
                        }
                    } else {
                        this.isVisibled = false
                        this.selectedImageFiles = []
                    }
                    this.init(this.selectedImageFiles.length)
                },
                error: (error) => {
                    console.error('Error occurred during the subscription:', error);
                },
            });
    }

    ngAfterViewInit(): void {
        this.pageindex = 0
        this.carousel.addEventListener('changing', (event: Event) => {
            // Use type assertion to assert the event as a CustomEvent
            const customEvent = event as CustomEvent;
            const detail = customEvent.detail,
                index = detail.index,
                previousIndex = detail.previousIndex;
            this.pageindex = index
            this.isChecked = false
            this.checkboxcaption = "Select"
            if (this.findImageByIndex(index)) {
                this.isChecked = true
                this.checkboxcaption = "Remove"
            }
        });
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    ngOnInit(): void {
        this.showdialog = true
        this.subscription = this.yourappsService.setSelImages$.subscribe((value: { data: any, type: number }) => {
            if (value.data) {
                if (value.type === 1) {
                    this.editDisabled = true
                    this.opertype = 1
                    this.collectionFormControl.setValue(SelfData.lbdescription.trim())
                    if (value.data.length > 0) {
                        value.data.forEach((imageinfo: any, index: number) => {
                            const catalog = imageinfo.catalog;
                            const catalogyear = imageinfo.catalogyear;
                            const colection = imageinfo.colection;
                            const designer = imageinfo.designer;
                            const filedir = transformImageUrl(imageinfo.url);
                            // const filedir = imageinfo.url;
                            const filename = imageinfo.filename;
                            const lbcatalogid = imageinfo.lbcatalogid;
                            const seasoncode = imageinfo.seasoncode;
                            this.selectedImages.push({
                                index: index, // This line uses the index provided by forEach
                                filename: filename,
                                lbcatalogid: lbcatalogid,
                                url: filedir,
                                catalog: catalog,
                                catalogyear: catalogyear,
                                colection: colection,
                                designer: designer,
                                file: null,
                                seasoncode: seasoncode
                            });
                        });
                        this.selectedImages = this.selectedImages.map(item => ({
                            ...item,
                            index: Number(item.index) // Convert index to a number
                        }));
                    }
                } else if (value.type === 2) {
                } else {
                    this.editDisabled = false
                    this.opertype = 0
                    this.selectedImages = []
                }
            }
        })
    }

    findImageByIndex(index: number) {
        index = Number(index);
        console.log("selectedImageFiles", this.selectedImageFiles)
        let lbcatalogid = Number(this.selectedImageFiles[index].lbcatalogid);
        let filename = this.selectedImageFiles[index].filename;
        return this.selectedImages.find(image => image.filename === filename && image.lbcatalogid === lbcatalogid);
    }

    removeImageByIndex(index: number) {
        index = Number(index);
        let lbcatalogid = Number(this.selectedImageFiles[index].lbcatalogid);

        // Find the index of the image in the selectedImages array
        const imageIndex = this.selectedImages.findIndex(image => image.index === index && image.lbcatalogid === lbcatalogid);

        if (imageIndex !== -1) {
            // If the image is found, remove it using splice
            this.selectedImages.splice(imageIndex, 1);
        }
    }

    onCheckboxChange(event: MatCheckboxChange) {
        if (event.checked) {
            this.checkboxcaption = "Remove"
            const index = this.pageindex;
            const selectedFile = this.selectedImageFiles[index];


            if (selectedFile) {
                const imageUrl = selectedFile.url;
                const imagefilename = selectedFile.filename;
                const lbcatalogid = selectedFile.lbcatalogid;
                const catalog = selectedFile.catalog;
                const catalogyear = selectedFile.catalogyear;
                const colection = selectedFile.colection;
                const designer = selectedFile.designer;
                const seasoncode = selectedFile.seasoncode;

                this.selectedImages.push({
                    index: index,
                    filename: imagefilename,
                    lbcatalogid: lbcatalogid,
                    url: imageUrl,
                    catalog: catalog,
                    catalogyear: catalogyear,
                    colection: colection,
                    designer: designer,
                    file: null,
                    seasoncode: seasoncode
                });

                this.selectedImages = this.selectedImages.map(item => ({
                    ...item,
                    index: Number(item.index) // Convert index to a number
                }));
            } else {
                // Handle the scenario where selectedFile is undefined
                console.error('Selected file not found at index', index);
            }
            this.messageService.add({
                key: 'tc',
                severity: 'success',
                summary: 'Success',
                detail: 'Item added to list',
                life: 2000,
            });
        } else {
            this.checkboxcaption = "Select"
            this.removeImageByIndex(this.pageindex)
            this.messageService.add({
                key: 'tc',
                severity: 'success',
                summary: 'Success',
                detail: 'Item removed from list',
                life: 2000,
            });
        }
        // You can now use event.checked, which is a boolean indicating the checkbox's state
    }

    init(imagecount: number): void {
        // init code.
        const that = this,
            basePath = './../../../src/images/';

        that.carousel.dataSource = generateDataSource(imagecount, this.selectedImageFiles);

        function generateDataSource(items: number, selectedImageFiles: {
            file: File | null;
            url: string;
            filename: string;
            lbcatalogid: number;
            colection: string;
            designer: string;
            catalog: string;
            catalogyear: number;
            seasoncode: string
        }[]) {
            return Array(items).fill({}).map((element, index) => {
                // Use the image from selectedImageFiles if available; otherwise, use a placeholder
                const imageUrl = index < selectedImageFiles.length && selectedImageFiles[index].file ? selectedImageFiles[index].url : selectedImageFiles[index].url;
                const headlines = index < selectedImageFiles.length && selectedImageFiles[index].file ? selectedImageFiles[index].catalog : selectedImageFiles[index].catalog;
                return {image: transformImageUrl(imageUrl), label: headlines};
                // return {image: imageUrl, label: headlines};
            });
        }
    }

    onButtonCloseClick() {
        this.modalService.closeciframe()
    }

    onButtonUploadClick() {
        if (this.collectionFormControl.value?.trim() === "") {
            this.messageService.add({
                key: 'tc',
                severity: 'error',
                summary: 'Information',
                detail: 'Please indicate the catalog description.',
                life: 10000,
            });
            return
        }
        if (this.selectedImages.length === 0) {
            this.messageService.add({
                key: 'tc',
                severity: 'error',
                summary: 'Information',
                detail: 'You must select at least one photo.',
                life: 10000,
            });
            return
        }

        this.confirmationService.confirm({
            message: `<strong>Save selected data?</strong>`,
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                if (this.opertype === 0) {
                    var catalogDetails: LbMainDetails
                    if (SelfData.lbmode === 0) {
                        catalogDetails = {
                            id: 0,
                            description: this.collectionFormControl.value?.trim() || "",
                            fields: "Custom Look Book",
                            accountid: this.getAccountID(),
                            isactivity: true,
                            ctrl: 0,
                            lbtype: 1
                        };
                    } else {
                        catalogDetails = {
                            id: SelfData.lbmode,
                            description: this.collectionFormControl.value?.trim() || "",
                            fields: "Custom Look Book",
                            accountid: this.getAccountID(),
                            isactivity: true,
                            ctrl: 1,
                            lbtype: 1
                        };
                    }
                    this.serviceservice.prclbmain(catalogDetails).subscribe({
                        next: (value: LbMainData) => {
                            // Handle the response here
                            if (value.data > 0) {
                                this.selectedImages.forEach((filedate) => {
                                    const fileData = {
                                        userid: SelfData.account[0].data[0].id,
                                        accountid: this.getAccountID(),
                                        filename: filedate.filename,
                                        filedir: filedate.url,
                                        ctrl: 0,
                                        lbcatalogid: filedate.lbcatalogid,
                                        lbmainid: value.data,
                                        description: this.collectionFormControl.value?.trim()
                                    };
                                    this.serviceservice.prclbcimage(fileData).subscribe({
                                        next: (value) => {

                                        },
                                        error: (error: any) => {
                                            console.error('Error occurred:', error);
                                        },
                                        complete: () => {
                                            // console.log('Subscription completed');
                                        }
                                    });
                                })
                                this.yourappsService.getReloadCLBlist()
                                this.modalService.closeciframe()
                            }
                        },
                        error: (error: any) => {
                            console.error('Error occurred:', error);
                        },
                        complete: () => {
                        }
                    });
                } else {
                    this.yourappsService.setReturnedImages(this.selectedImages, 1)
                    this.modalService.closeciframe()
                }
            },
            reject: (type: any) => {
                if (type == ConfirmEventType.REJECT) {

                } else if (type == ConfirmEventType.CANCEL) {
                }
            }

        });
        setTimeout(() => {
            this.applyLocalRestoreStyles();
        }, 0);
    }

    private applyLocalRestoreStyles() {
        setTimeout(() => {
            const messageElements = document.querySelectorAll('.p-confirm-dialog-message');
            messageElements.forEach((element) => {
                element.setAttribute('style', 'font-size: 16px; color: #343a40;');
            });
        }, 0);

        const iconElements = document.querySelectorAll('.p-confirm-dialog-icon');
        iconElements.forEach((element) => {
            element.setAttribute('style', 'color: #343a40');
        });
    }
}
