import { Component } from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";

@Component({
  selector: 'app-createlsb',
  templateUrl: './createlsb.component.html',
  styleUrls: ['./createlsb.component.scss']
})
export class CreatelsbComponent {
  constructor(public modalService: ModalserviceService) {}
}
