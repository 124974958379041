import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder} from "@angular/forms";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {ConfirmationService, MessageService} from "primeng/api";
import {NgxImageCompressService} from "ngx-image-compress";
import {HttpClient} from "@angular/common/http";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-shipdatedialog',
  templateUrl: './shipdatedialog.component.html',
  styleUrl: './shipdatedialog.component.scss'
})
export class ShipdatedialogComponent implements OnInit, AfterViewInit, OnDestroy {
  date = null;
  setid: number = 0
  onButtonCloseClick() {
    this.modalService.closeshipdate()
  }

  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private serviceservice: ServiceService,
    private yourappsService: YourappsserviceService,
    private cdr: ChangeDetectorRef,
    private messageService: MessageService,
    private imageCompressService: NgxImageCompressService,
    private confirmationService: ConfirmationService,
    private http: HttpClient,
    private datePipe: DatePipe,
    private el: ElementRef
  ) {

  }

  onChange(result: Date): void {
    console.log('onChange: ', result);
  }


  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    this.yourappsService.currentSendOutData.subscribe((data) => {
      console.log(data)
      this.date = data?.["shipdate"];
      this.setid = data?.["id"];
    })
  }

  onButtonOKClick() {
    this.modalService.closeshipdate()
    if (this.date) {
      this.yourappsService.setShipDate(this.date, this.setid);
    } else {
      console.log('Date is null or undefined. Cannot proceed.');
      // Вы можете показать сообщение пользователю или выполнить другое действие
    }
  }
}
