<button mat-icon-button type="button" class="close-btn" (click)="onButtonCloseClick()">
  <mat-icon>close</mat-icon>
</button>
<p class="sample-manager-text">Artificial Intelligence (AI)</p>
<div class="gifdiv">
  <img class="gif" src="assets/AI.gif" alt="My GIF Image">
</div>
<div class="scrollable-div-step2 horizontal-container-contact">
  <div class="black-background-step2">
    <div class="child-40">
      <img class="selectimage shadow-4" [src]="transformImageUrl(imageurl)" alt="Descriptive text for the image">
<!--      <img class="selectimage shadow-4" [src]="imageurl" alt="Descriptive text for the image">-->
    </div>

    <div class="child-60">
      <div *ngIf="showspinner" class="spinner">
        <p-progressSpinner></p-progressSpinner>
      </div>
      <div *ngIf="!showspinner" class="subdiv">

        <div class="form-field-spacing-contact form-field-h"
          style="overflow-y: auto; border: 0 solid #ccc; padding: 5px; resize: none;">
          <p *ngFor="let msg of messages" class="message-text" [style.color]="msg.color" [innerHTML]="msg.text"></p>
        </div>

      </div>
    </div>
  </div>
</div>
<div *ngIf="chooevisible" class="captionclass">
<span class="captiontextclass">Select one item for detailed description</span>
</div>
<mat-form-field *ngIf="chooevisible" class="selector-full-width" appearance="fill">
  <mat-label>Select item</mat-label>
  <input type="text" matInput placeholder="Select or type..." [matAutocomplete]="auto1"
    [formControl]="myItemListControl" #autoInput1="matAutocompleteTrigger"/>
  <button mat-stroked-button type="button" class="add-button-contact"
    matSuffix
    (click)="add($event, 1, autoInput1)">
    Description
<!--    <mat-icon class="center-icon">edit</mat-icon>-->
  </button>
  <button mat-stroked-button type="button" class="add-button-contact" matSuffix
    (click)="addAccept($event, 0, autoInput1)">
    Accept
<!--    <mat-icon class="center-icon">add</mat-icon>-->
  </button>
  <mat-autocomplete #auto1="matAutocomplete">
    <mat-option *ngFor="let item of itemslist" [value]="item">
      {{ item }}
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="myItemListControl.hasError('noItems')">No items found</mat-error>
</mat-form-field>

