import { Component } from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";

@Component({
  selector: 'app-fullrequest',
  templateUrl: './fullrequest.component.html',
  styleUrl: './fullrequest.component.scss'
})
export class FullrequestComponent {
  constructor(public modalService: ModalserviceService) {}
}
