import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder, FormControl} from "@angular/forms";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ConfirmationService, MessageService} from "primeng/api";
import {NgxImageCompressService} from "ngx-image-compress";
import { HttpClient } from "@angular/common/http";
import {FavoritesData, FavoritesValue, SelfData} from "../../models/struct";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
  selector: 'app-favoritedialog',
  templateUrl: './favoritedialog.component.html',
  styleUrls: ['./favoritedialog.component.scss']
})
export class FavoritedialogComponent implements OnInit, AfterViewInit, OnDestroy {
  emaiControl = new FormControl<string>('');
  isDesktopDevice: boolean = this.deviceService.isDesktop();
  deviceClass: string = "";
  footerClass: string = "";
  deviceClassAddress: string = "";
  isLandscape: boolean = false;
  constructor(
    public modalService: ModalserviceService,
    public fb: FormBuilder,
    private serviceservice: ServiceService,
    private yourappsService: YourappsserviceService,
    private _snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef,
    private messageService: MessageService,
    private imageCompressService: NgxImageCompressService,
    private confirmationService: ConfirmationService,
    private http: HttpClient,
    private deviceService: DeviceDetectorService,
    private el: ElementRef
  ) {
    this.setDeviceClass();
  }

  setDeviceClass(): void {
    this.deviceClass = this.deviceService.isMobile() ? 'mobilemaincard' : 'pcmaincard';
    this.footerClass = this.deviceService.isMobile() ? 'mobilebutton-container-dm-ps' : 'pcbutton-container-dm-ps';
    this.deviceClassAddress = this.deviceService.isMobile() ? 'mobileaddressdiv' : 'pcaddressdiv';
    // this.isLandscape = window.innerWidth > window.innerHeight ? 'islandscape' : 'isnlandscape';
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
  }

  onButtonCloseClick() {
    this.modalService.closefavorite();
  }

  protected readonly SelfData = SelfData;

  onButtonAcceptClick() {
    var email: string = ""
    if (SelfData.lbsents[0].data[0].email.trim() !== "" && this.emaiControl.value?.trim() === "") {
      email = SelfData.lbsents[0].data[0].email
    } else {
      email = this.emaiControl.value?.trim() || ""
    }
    const catalogDetails: FavoritesData = {
      email: email,
      phone: "",
      designerid: SelfData?.tdesignerid,
      ctrl: 0,
      key: SelfData.lbkey
    };
    this.serviceservice.getFavorites(catalogDetails).subscribe({
      next: (value: FavoritesValue) => {
        this.yourappsService.getFavoritesAdd()
      },
      error: (error: any) => {
        console.error('Error occurred:', error);
      },
      complete: () => {
        this.modalService.closefavorite();
      }
    });
  }

  onButtonNoClick() {
    this.modalService.closefavorite()
  }
}
