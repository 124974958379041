import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ModalserviceService} from "../../services/modalservice.service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {HashService} from "../../services/hash.service";
import {MessageboxComponent} from "../../modals/messagebox/messagebox.component";
import {ServiceService} from "../../services/service.service";
import {YourappsserviceService} from "../../services/yourappsservice.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import { HttpClient } from "@angular/common/http";
import {
  GetCollectionList,
  GetDesignerList,
  GetFabricList, GetLookNameList,
  GetNotSettings,
  GetSeasonList,
  GetTypeList, SelfData, SetSampleList, Speener
} from "../../models/struct";
import {MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {map, Observable, startWith, Subscription} from "rxjs";

interface ColorArray {
    colorname: string;
    colorcode: string;
}

interface Gender {
    value: string;
    viewValue: string;
}

@Component({
  selector: 'app-lookdialog',
  templateUrl: './lookdialog.component.html',
  styleUrls: ['./lookdialog.component.scss']
})
export class LookdialogComponent implements OnInit, AfterViewInit, OnDestroy {
    myForm: FormGroup = new FormGroup({});

    desingerFormControl = new FormControl({value: '', disabled: false}, []);
    collectionFormControl = new FormControl({value: '', disabled: false}, []);
    seasonFormControl = new FormControl({value: '', disabled: false}, []);
    typeFormControl = new FormControl({value: '', disabled: false}, []);
    colorControl = new FormControl({value: '', disabled: false}, []);
    sizeControl = new FormControl({value: '', disabled: false}, []);
    fabricControl = new FormControl({value: '', disabled: false}, []);
    genderControl = new FormControl({value: '', disabled: false}, []);

    departmentFormControl = new FormControl({ value: '', disabled: true }, [
        Validators.required,
    ]);
    emailFormControl = new FormControl('');

    firstnameFormControl = new FormControl('');

    formcaption: string = ""

    selectedValueInventaryStatys: string | undefined;
    selectedValueStyle: string | undefined;
    selectedValueGender: string | undefined;
    selectedValueDesinger: string | undefined;
    selectedValueBarcode: string | undefined;
    selectedValueFabric: string | undefined;
    AvailabilitySselectedValuetatus: string | undefined;
    samplemanager = false;
    contactmanager = false;
    sendoutmanager = false;
    lookmanager = false
    iNotSettingsResult: GetNotSettings[] = [];

    formControls: {[key: string]: FormControl} = {
        desingerFormControl: this.desingerFormControl,
        collectionFormControl: this.collectionFormControl,
        seasonFormControl: this.seasonFormControl,
        typeFormControl: this.typeFormControl,
        colorControl: this.colorControl,
        sizeControl: this.sizeControl,
        fabricControl: this.fabricControl,
        genderControl: this.genderControl,
    };
    showButton: { [key: string]: boolean } = {
        desingerFormControl: false,
        collectionFormControl: false,
        seasonFormControl: false,
        typeFormControl: false,
        colorControl: false,
        sizeControl: false,
        fabricControl: false,
        genderControl: false
    };

    private subscription?: Subscription;
    private subscription1?: Subscription;

    filteredDesignerOptions?: Observable<string[]>;
    filteredCollectionOptions?: Observable<string[]>;
    filteredSeasonOptions?: Observable<string[]>;
    filteredTypeOptions?: Observable<string[]>;
    filteredSizeOptions?: Observable<string[]>;
    filteredFabricOptions?: Observable<string[]>;

    designerptions: string[] = [];
    collectionoptions: string[] = [];
    seasonoptions: string[] = [];
    typeoptions: string[] = [];
    sizeoptions: string[] = [];
    fabricoptions: string[] = [];

    colorarray: ColorArray[] = [
        {colorname: 'White', colorcode: '#ffffff'},
        {colorname: 'Black ', colorcode: '#000000'},
        {colorname: 'Yellow', colorcode: '#FFFF00'},
        {colorname: 'Purple', colorcode: '#800080'},
        {colorname: 'Orange ', colorcode: '#FFA500'},
        {colorname: 'Blue ', colorcode: '#2196f3'},
        {colorname: 'Light Blue ', colorcode: '#03a9f4'},
        {colorname: 'Red', colorcode: '#ff0000'},
        {colorname: 'Buff', colorcode: '#DAA06D'},
        {colorname: 'Gray ', colorcode: '#808080'},
        {colorname: 'Green ', colorcode: '#00FF00'},
        {colorname: 'Pink', colorcode: '#FFC0CB'},
        {colorname: 'Violet', colorcode: '#8F00FF'},
        {colorname: 'Brown', colorcode: '#964B00'},
        {colorname: 'Silver', colorcode: '#C0C0C0'},
        {colorname: 'Cream', colorcode: '#FFFDD0'},
        {colorname: 'Gold', colorcode: '#FFD700'},
        {colorname: 'Beige', colorcode: '#F5F5DC'},
        {colorname: 'Navy', colorcode: '#202A44'},
    ];

    gender: Gender[] = [
        {value: 'Man', viewValue: 'Man'},
        {value: 'Unisex', viewValue: 'Unisex'},
        {value: 'Women', viewValue: 'Women'},
    ];

    iDesignerResult: GetDesignerList[] = [];
    iCollection: GetCollectionList[] = [];
    iSeason: GetSeasonList[] = [];
    iType: GetTypeList[] = [];
    iSize: GetTypeList[] = [];
    iFabricResult: GetFabricList[] = [];

    private getAccountID(): number {
        return SelfData.account[0].data[0].parentid === 0
            ? SelfData.account[0].data[0].id
            : SelfData.account[0].data[0].parentid;
    }

    constructor(
        public modalService: ModalserviceService,
        public fb: FormBuilder,
        private hashService: HashService,
        public messageBox: MessageboxComponent,
        private serviceservice: ServiceService,
        private yourappsService: YourappsserviceService,
        private _snackBar: MatSnackBar,
        private cdr: ChangeDetectorRef,
        private http: HttpClient
    ) {
        // this.serviceservice
        //     .setDesignerList({accountid: this.getAccountID()})
        //     .subscribe((desingervalue) => {
        //         this.iDesignerResult[0] = desingervalue;
        //         SelfData.designer = this.iDesignerResult;
        //         this.filteredDesignerOptions = this.setDataAndFilter(
        //             this.desingerFormControl,
        //             desingervalue,
        //             this.designerptions,
        //             2
        //         );
        //     });
        //
        // this.serviceservice
        //     .setCollectionList({accountid: this.getAccountID()})
        //     .subscribe((collectionvalue) => {
        //         this.iCollection[0] = collectionvalue;
        //         SelfData.collection = this.iCollection;
        //         this.filteredCollectionOptions = this.setDataAndFilter(
        //             this.collectionFormControl,
        //             collectionvalue,
        //             this.collectionoptions,
        //             5
        //         );
        //     });
        //
        // this.serviceservice
        //     .setSeasonList({ accountid: this.getAccountID() })
        //     .subscribe((seasonvalue) => {
        //         this.iSeason[0] = seasonvalue;
        //         SelfData.season = this.iSeason;
        //         this.filteredSeasonOptions = this.setDataAndFilter(
        //             this.seasonFormControl,
        //             seasonvalue,
        //             this.seasonoptions,
        //             6
        //         );
        //     });
        //
        // this.serviceservice
        //     .setTypeList({ accountid: this.getAccountID() })
        //     .subscribe((value) => {
        //         this.iType[0] = value;
        //         SelfData.type = this.iType;
        //         this.filteredTypeOptions = this.setDataAndFilter(
        //             this.typeFormControl,
        //             value,
        //             this.typeoptions,
        //             10
        //         );
        //     });
        //
        // this.serviceservice
        //     .setSizeList({ accountid: this.getAccountID() })
        //     .subscribe((value) => {
        //         this.iSize[0] = value;
        //         SelfData.size = this.iSize;
        //         this.filteredSizeOptions = this.setDataAndFilter(
        //             this.sizeControl,
        //             value,
        //             this.sizeoptions,
        //             11
        //         );
        //     });
        //
        // this.serviceservice
        //     .setFabricList({ accountid: this.getAccountID() })
        //     .subscribe((fabricvalue) => {
        //         this.iFabricResult[0] = fabricvalue;
        //         SelfData.fabric = this.iFabricResult;
        //         this.filteredFabricOptions = this.setDataAndFilter(
        //             this.fabricControl,
        //             fabricvalue,
        //             this.fabricoptions,
        //             3
        //         );
        //     });
    }

    private setDataAndFilter(
        control: FormControl,
        response: any,
        options: string[],
        type: number
    ) {
        let results = response.data;
        for (let data of results) {
            options.push(data.description);
        }
        return control.valueChanges.pipe(
            startWith(''),
            map((value) => this._filter(value || '', type))
        );
    }

    private _filter(value: string, type: number): string[] {
        const filterValue = value.toLowerCase();

        // Map the type to the corresponding options array
        const optionsMap: { [key: number]: string[] } = {
            2: this.designerptions,
            3: this.fabricoptions,
            5: this.collectionoptions,
            6: this.seasonoptions,
            10: this.typeoptions,
            11: this.sizeoptions,
        };

        if (optionsMap[type]) {
            return optionsMap[type].filter((option) =>
                option.toLowerCase().includes(filterValue)
            );
        } else {
            console.warn(`Unexpected filter type: ${type}`);
            return [];
        }
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
      this.modalService.closeLookList()
      Speener.lookbookvisiblemode = true
      this.cdr.detectChanges();
      SelfData.selectlookmode = 1
      this.iLookNameResult = []
      this.serviceservice.setLookName({
        accountid: this.getAccountID(),
        designer: this.desingerFormControl.value?.trim() || "",
        collection: this.collectionFormControl.value?.trim() || "",
        season: this.seasonFormControl.value?.trim() || "",
        gender: this.genderControl.value?.trim() || "",
        type: this.typeFormControl.value?.trim() || "",
        color: this.colorControl.value?.trim() || "",
        size: this.sizeControl.value?.trim() || "",
        fabric: this.fabricControl.value?.trim() || ""
      }).subscribe({
        next: (value) => {
          if (value) {
            Speener.lookbookvisiblemode = false
            this.cdr.detectChanges();
            this.iLookNameResult[0] = value;
            SelfData.lookname = this.iLookNameResult;
            this.yourappsService.getReloadLookNames();
            this.modalService.closeSampleHeader();
            this.modalService.closeSampleLabel();
            this.modalService.closeSampleGrid();
            this.modalService.createLookList()
            if (this.subscription1) {
              this.subscription1.unsubscribe();
            }
          }
        }
      })
    }

    ngOnDestroy(): void {
    }

    submit() {

    }

    iSampleDataResult: SetSampleList[] = [];
    iLookNameResult: GetLookNameList[] = [];

    onButtonLookSearchClick() {

    }

    onOptionSelected(event: MatAutocompleteSelectedEvent, controlName: string) {
        this.showButton[controlName] = event.option.value != '';
    }

    onInputChange(event: Event, controlName: string) {
        this.showButton[controlName] = (event.target as HTMLInputElement).value != '';
    }

    clearValue(event: MouseEvent, controlName: string, autoInput: MatAutocompleteTrigger | null): void {
        event.stopPropagation();
        if (autoInput) autoInput.closePanel();
        this.formControls[controlName].setValue("");
        this.showButton[controlName] = false;
    }

  protected readonly Speener = Speener;
}
