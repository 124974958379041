<div class="card">
  <p class="sample-manager-customers">Sales Report</p>
  <div class="black-background-blt">
    <div class="example-container-blt">
      <div class="componentsdiv2">
        <label class="txtdiv">Date Range</label>
        <nz-range-picker class="datepick" [(ngModel)]="date" (ngModelChange)="onChange($event)"></nz-range-picker>
      </div>
    </div>
  </div>
  <div class="griddiv">
    <p-table
      [value]="iSalesReportResult"
      [paginator]="true"
      [rows]="100"
      rowGroupMode="subheader"
      groupRowsBy="designer"
      selectionMode="single"
      [(selection)]="selectedSalesReport"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [rowsPerPageOptions]="[100, 200, 500, 1000]"
      (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
      [styleClass]="'p-datatable-selectable p-datatable-striped mat-elevation-z1 ' + (selectedSize?.class || '')"
      [tableStyle]="{'min-width': '50rem'}">

      <ng-template pTemplate="header">
        <tr>
          <th style="width: 3%">Date</th>
          <th style="width: 5%">User</th>
          <th style="width: 5%">Outlet</th>
          <th style="width: 3%">Tax</th>
          <th style="width: 3%">Order total</th>
          <th style="width: 3%">Total discount</th>
          <th style="width: 3%">Taxa mount</th>
          <th style="width: 3%">Profit</th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-product>
        <tr [pSelectableRow]="product">
          <td>{{ product.operdate | date:'yyyy-MM-dd HH:mm' }}</td>
          <td>{{ product["user"] }}</td>
          <td>{{ product["OutletName"] }}</td>
          <td>{{ product["saletaxdisplay"] }}</td>
          <td>{{ product.ordertotal | currency:'USD':'symbol':'1.2-2' }}</td>
          <td>{{ product.totaldiscount | currency:'USD':'symbol':'1.2-2' }}</td>
          <td>{{ product.salestaxamount | currency:'USD':'symbol':'1.2-2' }}</td>
          <td>{{ product.profit | currency:'USD':'symbol':'1.2-2' }}</td>

        </tr>
      </ng-template>
      <ng-template pTemplate="groupfooter" let-catalog>
        <tr>

          <td colspan="5" class="text-right font-bold pr-3">Total: {{calculateTotal() | currency:'USD':'symbol':'1.2-2'}}</td>
          <td colspan="1" class="text-right font-bold pr-3">Total: {{calculateTotal1() | currency:'USD':'symbol':'1.2-2'}}</td>
          <td colspan="1" class="text-right font-bold pr-3">Total: {{calculateTotal2() | currency:'USD':'symbol':'1.2-2'}}</td>
          <td colspan="1" class="text-right font-bold pr-3">Total: {{calculateTotal3() | currency:'USD':'symbol':'1.2-2'}}</td>
<!--          <td colspan="8" class="text-right font-bold pr-3">Total Catalogs: </td>-->
        </tr>
      </ng-template>
    </p-table>

    <div class="fullline">

    </div>
  </div>
</div>

