import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {transformImageUrl} from "../../models/struct";

@Component({
  selector: 'app-whatsnew',
  templateUrl: './whatsnew.component.html',
  styleUrls: ['./whatsnew.component.scss'],
})
export class WhatsnewComponent implements OnInit, AfterViewInit {
  newsItems: any[];

  @ViewChild('centeredDiv', { static: false }) centeredDiv:
    | ElementRef
    | undefined;
  constructor() {
    this.newsItems = [
      {
        newsid: 1,
        thema: 'Pull Sheet',
        caption: 'Autogeneration of the document “Pull Sheet” in the program.',
        content: 'In the context of an agreement, a "Pull Sheet" is a document that outlines essential information and specifics related to a business or financial transaction. This could include details about the parties involved, terms of the agreement, financial figures, and other critical data points. Its primary purpose is to provide a clear and concise summary of complex transactions or agreements, making it easier for all involved parties to understand and reference the key elements of the deal or operation. This tool is particularly useful in environments where quick access to summarized, accurate information is crucial.',
        date: '2023-01-14',
        url: transformImageUrl('http://108.178.189.70:6330/IMNews/Pull%20Sheet.png'),
      },
      {
        newsid: 1,
        thema: 'Look Book',
        caption: 'Sending “Look Books” and selecting items from the program.',
        content: 'A Look Book is a collection of photographs displaying a fashion designer\'s or brand\'s new collection of clothing, accessories, or a particular style. Primarily used as a marketing tool, it showcases the latest trends and designs, providing visual inspiration and style guidance. Look Books are widely used in the fashion industry by designers, retailers, and stylists to highlight their creative vision and to appeal to customers and fashion enthusiasts.',
        date: '2023-01-22',
        url: transformImageUrl('http://108.178.189.70:6330/IMNews/lookbook.png'),
      },
      {
        newsid: 1,
        thema: 'Fashion Messenger',
        caption: 'Your business messages, are separate in our “Fashion Messenger”',
        content: '"Fashion Messenger" is a specialized digital application tailored specifically for the fashion industry. It is designed as a secure platform, ensuring that business messages, photos, and videos are distinct and separate from other instant messengers, shared emails, and texts. This platform serves as a central hub for all things fashion, catering to everyone from designers and stylists to fashion enthusiasts and influencers. It enables users to receive the latest fashion news, exchange information, and share related photos and videos. Key features include real-time updates on fashion trends, a dedicated space for community interaction, and tools for sharing visual content. It also offers the potential for virtual collaboration on fashion projects. Fashion Messenger is more than just an app; it\'s a vibrant community for those passionate about style and trends, providing a unique and secure environment for professional and personal fashion communication.',
        date: '2023-02-01',
        url: transformImageUrl('http://108.178.189.70:6330/IMNews/FashionMessanger.png'),
      },
      {
        newsid: 1,
        thema: 'Artificial Intelligence',
        caption: 'Image description and search with “Artificial Intelligence”',
        content: 'Our software uses artificial intelligence to provide detailed descriptions of items, making it easy to search and also suggest other similar items. It makes it especially easy to use this feature on things where it is difficult or impossible to use barcodes.',
        date: '2023-02-11',
        url: transformImageUrl('http://108.178.189.70:6330/IMNews/AI.png'),
      },
      {
        newsid: 1,
        thema: 'Rental Showroom',
        caption: 'Expand your business through a “Rental Showroom”',
        content: 'A Rental Showroom is a specialized retail space where customers can rent fashion items, such as dresses, suits, and accessories, instead of purchasing them. These showrooms offer a diverse range of high-end and designer clothing for various occasions, providing a cost-effective and flexible alternative to buying expensive attire. They are typically designed to offer a luxurious shopping experience, complete with stylish interiors, fitting areas, and a wide selection of contemporary and classic styles, catering to those who need temporary, fashionable outfits for events or special occasions.',
        date: '2023-03-13',
        url: transformImageUrl('http://108.178.189.70:6330/IMNews/RentalShowRoom.png'),
      },
      // {
      //   newsid: 1,
      //   thema: 'Discover',
      //   caption:
      //     'wer wqerwier oqweir owije rwqleklkj wqlekjrlwekjrl kwerlkj wqer',
      //   content: ' sdfgsfg sdfsdfg sdfgsdfg sdf ertwrt wertwert wert',
      //   date: '2023-03-21',
      //   url: 'https://btrnews.co.uk/wp-content/uploads/2021/05/Terrace-at-The-Lexington-BTR-scheme-Liverpool-Moda-Living-BTR-News-696x483.png',
      // },
      // {
      //   newsid: 1,
      //   thema: 'Galleryes',
      //   caption:
      //     'wer wqier uowqeiruqwoe rlqwekrjlqwekrj lwqekrjlqwekrj wqer mqwer',
      //   content: 'er twrt gfhdfg dfgh dfg yertyer ty erty gfhdfghsrtert wrt',
      //   date: '2023-04-11',
      //   url: 'https://apa.az/storage/news/2023/january/31/big/63d8d7662ed3363d8d7662ed34167515530263d8d7662ed3163d8d7662ed32.png',
      // },
      // Add more items as needed
    ];
  }

  ngOnInit(): void {
    // setTimeout(() => {
    //   let th = this.newsItems.length * 301;
    //   this.setCenteredDivHeight(th);
    // }, 0);
  }

  ngAfterViewInit() {}

  setCenteredDivHeight(height: number | string) {
    // if (this.centeredDiv) {
    //   console.dir(this.centeredDiv.nativeElement.style.height)
    //   this.centeredDiv.nativeElement.style.height = typeof height === 'number' ? `${height}px` : height;
    // }
  }
}
